<template>
    <v-row no-gutters>
        <v-col xs="12" sm="12" md="12" lg="12" ex="12">

              <v-row style="border-bottom:solid 1px;">
                  <v-col xs="5">
                      <h4><b><i>{{comp.CompanyName}}</i></b></h4>
                      {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}   <br />                
                      {{comp.CompanyPostcode}} {{comp.CompanyCity}}<br />
                      {{lang.VATID}} {{comp.CompanyVATID}}
                  </v-col>
                  <v-col xs="3">
                    <h2>Radni nalozi lista</h2>
                    {{head2}} <br />
                    {{head3}} <br />
                    {{head4}} <br />
                    {{head5}}
                  </v-col>
                  <v-col xs="4">
                    <p class="text-md-left">
                        {{langC.PrintDate}} {{PrintDate}}<br />
                        email: {{comp.Companyemail}}, {{comp.CompanyPhones}}<br />
                    </p>
                  </v-col>
              </v-row>
               <v-row>
                  <v-col cols="12" sm="9" md="9" xs="9" lg="9" xl="9">
  
                  </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                      <v-btn
                        text color="primary"
                        @click="downloadFile()">
                        excel
                      </v-btn>
                    <v-btn
                        text color="primary"
                        @click="printAna()">
                        {{langC.Print}}
                      </v-btn>
                    </v-col>
                </v-row>
          
            <v-row style="border-bottom:solid 1px; border-top:solid 1px;" class="mb-2">
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-md-left">
                   {{lang.documentId}} 
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    {{lang.documentDate1}}
                  </v-col>
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="text-left">
                    {{lang.partnerName}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left">
                    {{langC.UserId}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left">
                    
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left">
                    Trošak materijala:
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
                    Trošak radnika:
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
                    Ostali troškovi:
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
                    Dobit/Gubitak:
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
                    Proizvedeno:
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
                    Status
                  </v-col>
              </v-row>

                <v-row v-for="item in items" :key="item.id" dense>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-xs-left" style="font-size:10px;">
                      {{ item.documentId }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" style="font-size:10px;">
                      {{ formatDate(item.documentDate) }}
                    </v-col>
                    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="text-left" style="font-size:10px;">
                      {{ item.partnerName }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" style="font-size:10px;">
                      {{ item.email }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                     
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(item.FreeF1) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(item.FreeF5) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(item.FreeF3) }}
                    </v-col>
                     <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(item.profitLoss) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                       {{ localMoney(item.FreeF4) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ item.statusDoc2 }}
                    </v-col>                    
            </v-row>
            <v-row style="border-top:solid 1px;">
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
                .
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="text-right" style="font-size:10px;"> 
                .
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
                {{ localMoney(Total1) }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                {{ localMoney(Total2) }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
                {{ localMoney(Total3) }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                {{ localMoney(Total10) }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                {{ localMoney(Total4) }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
                
              </v-col>
            </v-row>

        <v-row>
          <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
            <DocumentPrintSalesListWO ref="prnt" />
          </v-col>
        </v-row>

        </v-col>
    </v-row>
</template>


<script>
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import moneyFormat from '@/snippets/moneyFormat'
import {parseNum} from '@/snippets/allSnippets'
import DocumentPrintSalesListWO from './DocumentPrintSalesListWO'
import xlsx from 'json-as-xlsx'
import dayjs from 'dayjs'

export default {
  name: 'salesanalistWO',
  props: ['items', 'head1', 'head2', 'head3', 'head4', 'head5', 'productiontypes'],
  data: ()=> ({
    comp: {},
    lang: {},
    langC: {},
    Total1: 0,
    Total2: 0,
    Total3: 0,
    Total4: 0,
    Total5: 0,
    Total6: 0,
    Total7: 0,
    Total8: 0,
    Total9: 0,
    Total10: 0,
    PrintDate: null,
    ana: {},
    excelItems: []
  }),
  beforeMount() {
    if (this.items) {
      this.items.map(ite => {
        this.Total1 += parseNum(ite.FreeF1, 2)
        this.Total2 += parseNum(ite.FreeF5, 2)
        this.Total3 += parseNum(ite.FreeF3, 2)
        this.Total4 += parseNum(ite.FreeF4, 2)          
      })
      this.Total10 += (this.Total4 - this.Total1 - this.Total2 - this.Total3)
    }
  },
  mounted () {
    this.comp = this.$store.state.companyActiv
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    this.PrintDate = dayjs().format('DD.MM.YYYY, H:mm')
  },
  methods: {
 
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    async printAna() {
      
      const newObj = {}
      this.ana = {...newObj}
      this.ana = {...this.documentAna}
      this.ana.items = this.items ? this.items : []
      this.ana.head1 = 'Radni nalozi lista'
      this.ana.head2 = this.head2
      this.ana.head3 = this.head3
      this.ana.head4 = this.head4
      this.ana.head5 = this.head5
      this.ana.type = 10
      this.ana.Total1 = this.Total1
      this.ana.Total2 = this.Total2
      this.ana.Total3 = this.Total3
      this.ana.Total4 = this.Total4
      this.ana.Total10 = this.Total10
      await this.$store.dispatch('setPrintObj', this.ana)
      await this.$refs.prnt.print()
    },
    downloadFile () {

      const alllines = []
      if (this.items) {
        this.items.map(ite => {
            const newItem = {}
            newItem.documentId = ite.documentId 
            newItem.documentDate = ite.documentDate
            newItem.partnerName = ite.partnerName
            newItem.email = ite.email 
            newItem.materijal = parseNum(ite.FreeF1, 2)
            newItem.radnici = parseNum(ite.FreeF5, 2)
            newItem.troskovi = parseNum(ite.FreeF3, 2)
            newItem.dobit = parseNum(ite.profitLoss, 2)
            newItem.proizvedeno = parseNum(ite.FreeF4, 2)
            newItem.statusDoc2 = ite.statusDoc2
            alllines.push(newItem)
        })
        const newItem = {}
        newItem.documentId = ''
        newItem.documentDate = ''
        newItem.partnerName = ''
        newItem.email = ''
        newItem.materijal = parseNum(this.Total1, 2)
        newItem.radnici = parseNum(this.Total2, 2)
        newItem.troskovi = parseNum(this.Total3, 2)
        newItem.dobit = parseNum(this.Total10, 2)
        newItem.proizvedeno = parseNum(this.Total4, 2)
        alllines.push(newItem)
      }
      this.topHeader = 'RadniNalozi' + this.$store.state.businessYear + this.lang.ReportCreated + dayjs().format('DD.MM.YYYY HH:MM')

      let sheetNm = ''
      let fileNm = ''
      const currDate = dayjs().format('DD_MM_YYYY_HH_MM')
      if (this.$store.state.language === 'EN') {
       sheetNm = 'WrkOrd_' + this.$store.state.businessYear + '_' + currDate
       fileNm = 'WrkOrd_' + this.$store.state.businessYear + '_' + currDate
      }
      if (this.$store.state.language === 'HR') {
       sheetNm = 'RadNal_' + this.$store.state.businessYear + '_' + currDate
       fileNm = 'RadNal_' + this.$store.state.businessYear + '_' + currDate
      }
      let data = [
        {
          sheet: sheetNm,
          columns: [
            { label: this.lang.documentId, value: 'documentId'},
            { label: this.lang.documentDate, value: 'documentDate'},
            { label: this.lang.partnerName, value: 'partnerName'},
            { label: this.lang.email, value: 'email' },
            { label: 'Materijal', value: 'materijal' },
            { label: 'Radnici', value: 'radnici' },
            { label: 'Troskovi', value: 'troskovi' },
            { label: 'Dobit', value: 'dobit' },
            { label: 'Proizvedeno', value: 'proizvedeno' },
            { label: 'Status', value: 'statusDoc2' },
          ],
          content: alllines
        },
      ]
      
      let settings = {
        fileName: fileNm, // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
      }

      xlsx(data, settings)

    }

  },
  computed: {
  },
  watch: {

  },
  components: {
     DocumentPrintSalesListWO
  }

}

</script>