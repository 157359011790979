<template>
  <div class="task">
    <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
          <v-alert type="warning" v-model="havealert" dismissible close-text="Close Alert">
        {{havemssgalert}}
    </v-alert>

  <v-row>
    <v-col cols="12" sm="12" > 
      <v-card>
        <v-row align="center" class="list px-3 mx-auto">
          <v-col cols="4" sm="2">
            <v-select
              v-model="searchOption"
              :items="searchOptions"
              :label="langC.SearchBy"
              clearable
              @change="handleSearchOption"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field v-model="searchName" :label="langC.SearchBy"></v-text-field>
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn small @click="page = 1; defaultInit();">
              {{langC.Search}}
            </v-btn>
          </v-col>
          <v-col cols="5" sm="5">
            <v-pagination
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            ></v-pagination>
            
          </v-col>
          <v-col cols="4" sm="1">
            Total:{{totalItems}}
          </v-col>
          <v-col cols="4" sm="1">
            <v-select
              v-model="pageSize"
              :items="pageSizes"
              :label="langC.ItemsPerPage"
              @change="handlePageSizeChange"
            ></v-select>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row> 

  <v-row>
    <v-col cols="12" sm="12"> 
      <v-card>
        <v-card-title>
          {{ documentNameLoc }}
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="30"
            @click:row="navigateTo"
            :mobile-breakpoint="0" 
          >

          <template v-slot:[`item.BookDate`]="{ item }">
              <span>{{ localDate(item.BookDate) }}</span>
          </template>
          <template v-slot:[`item.RecTotal`]="{ item }">
            <span class="title green--text">{{ formatPrice(item.RecTotal) }}</span>
          </template>
          <template v-slot:[`item.ExpTotal`]="{ item }">
            <span class="title indigo--text">{{ formatPrice(item.ExpTotal) }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row> 
  <v-row>
    <v-col>
      <v-btn
          text color="primary"
          @click="copyDocsToNewYear()">
          Prebaci SPISE u Novu Godinu
        </v-btn>
    </v-col>
  </v-row>
  <v-snackbar
      v-model="snackbar"
      bottom
      :timeout="timeout"
      :color="color"
      >
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import MainBookService from '@/services/MainBookService'
import ledgerEN from './mainbookDescEn'
import ledgerHR from './mainbookDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import { mapGetters } from 'vuex'
import { round, parseNum } from '@/snippets/allSnippets'
import dayjs from 'dayjs'

export default {
  name: 'mainbooklist',
  data () {
    return {
      documentName: '',
      documentNameLoc: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [30],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      havealert: false,
      havemssgalert: '',
      saving: false,
      searchName: "",
      page: 1,
      totalPages: 0,
      pageSize: 30,
      pageSizes: [10, 30, 50],
      searchOption: '',
      searchOptions: [],
      totalItems: 0,
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
    }
  },
  mounted () {  
    if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    this.defaultInit()
  },
  methods: {
    async handlePageChange(value) {      
      const query = this.$store.state.onPage
      query.page = value - 1;
      this.page = value
      await this.$store.dispatch('setOnPage', query)
      this.defaultInit();
    },

    async handlePageSizeChange(size) {
      const query = this.$store.state.onPage
      query.pageSize = size;
      query.page = 1;
      this.pageSize = size
      await this.$store.dispatch('setOnPage', query)
      this.defaultInit();
    },

    async handleSearchOption(option) {
      this.searchOption = option
      const query = this.$store.state.onPage
      query.searchItem = option
      query.page = 0
      await this.$store.dispatch('setOnPage', query)
    },

    setQuery() {
      const haveOnPage = this.$store.state.onPage
      const query = {}
      if (this.$store.state.companyid) {
        query.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        query.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentActiv) {
        query.documentName = this.$store.state.documentActiv
      }
      query.searchName = haveOnPage.searchName ? haveOnPage.searchName.trim() : null
      query.page = haveOnPage.page ? haveOnPage.page : 0
      this.page  = query.page + 1
      query.pageSize = haveOnPage.pageSize ? haveOnPage.pageSize : 30
      this.pageSize = query.pageSize
      query.searchItem = haveOnPage.searchItem ? haveOnPage.searchItem : null
      return query
    },
    async defaultInit() {
    try {
      this.items.length = 0
      this.headers.length = 0
      const query = this.setQuery()
      if (query.searchItem && this.searchName) {
        query.searchName =  this.searchName.trim()
        await this.$store.dispatch('setOnPage', query)
      }
      if (query.searchItem && query.searchName) {
        this.searchOption = query.searchItem
        this.searchName = query.searchName 
      }
      this.$store.dispatch('seteditItem', 0)
      this.saving = true
      await MainBookService.indexlist(query).then(res => {
        this.items = res.data.mainbooks ? res.data.mainbooks : []
        this.totalItems = res.data.totalPages
        this.totalPages = parseInt(res.data.totalPages / this.pageSize) + 1
      })
      .catch(err => {
        this.havealert = !this.havealert
        this.havemssgalert = 'Error' + err
      })
      this.saving = false
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      this.searchOptions.push(
        {text: this.lang.partnerName, value: 'partnerName'},
        {text: this.lang.BookDesc1, value: 'BookDesc1', width: '90px'},
        {text: this.lang.BookTypeName, value: 'BookTypeName', width: '90px'},
      )
      this.headers.push(
        { text: this.lang.BookDate, align: 'left', sortable: true, value: 'BookDate', width: '80px' },
        { text: this.lang.BookDesc1, align: 'center', value: 'BookDesc1', width: '100px' },
        { text: this.lang.RecTotal, align: 'right', sortable: true, value: 'RecTotal', width: '100px' },
        { text: this.lang.ExpTotal, align: 'right', sortable: true, value: 'ExpTotal', width: '100px' },
        { text: this.lang.HomeCurrency, align: 'left', sortable: true, value: 'HomeCurrency', width: '40px' },
        { text: this.lang.BookTypeName, align: 'left', sortable: true, value: 'BookTypeName', width: '40px' },
        { text: this.lang.PartnerName, align: 'left', value: 'partnerName', width: '220px' },
        { text: this.lang.PartnerVATID, align: 'left', value: 'partnerVATID', width: '80px' },
        { text: this.lang.RecSpecial, align: 'right', sortable: true, value: 'RecSpecial', width: '100px' },
        { text: 'Depozit', align: 'left', value: 'BookDocDesc', width: '220px' },
        { text: 'DbId', value: 'id', width: '60px' }
      )
    } catch(err) {
      // eslint-disable-next-line
      console.log(err)
    }
    },
    formatPrice (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async defaultQuery() {
      const query = {}
      if (this.$store.state.companyid) {
        query.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        query.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentActiv) {
        query.documentName = this.$store.state.documentActiv
      }
      query.searchName = null
      query.page =  0
      query.pageSize =  30
      query.searchItem =  null
      await this.$store.dispatch('setOnPage', query)
      this.searchName = ""
      this.page = 1
      this.totalPages = 0
      this.pageSize = 30
      this.mainQuery = query
    },
    async initAgain() {
      this.defaultQuery()
      const query = this.setQuery()
      this.items = (await MainBookService.indexlist(query)).data.mainbooks
    },
    async navigateTo (params) {

      await this.$store.dispatch('seteditItem', 1)
      await this.$store.dispatch('setretPath', 'mainbook')
      await this.$store.dispatch('setMainLedgerid', params.id)
      this.$router.push({
        name: 'mainbookcreate'
      })
    },
    localDate(dateToFormat) {
       const dateOnly = dayjs(dateToFormat).format('YYYY-MM-DD')
       return dateFormat(dateOnly)
    },
    async copyDocsToNewYear() {
      if (confirm('Da li ste sigurni da želite prebaciti spise kao početno stanje u ' + (this.$store.state.businessYear + 1)+ '?')) {
        this.defaultQuery()
        const newYearDocs= []
        const query = this.setQuery()
        query.BusinessYear = this.$store.state.businessYear
        query.BookTypeId = 180
        const litems = (await MainBookService.choose(query)).data.mainbooks
        if (litems && litems.length > 0) {
          litems.map(item => {
            const newItem = {...item}
            delete newItem.id
            newItem.BusinessYear = this.$store.state.businessYear + 1
            newItem.BookDesc1 = item.BookDesc1 + ', ' + this.localDate(item.BookDate)
            newItem.BookDate =  dayjs([this.$store.state.businessYear + 1]).startOf('year').format('YYYY-MM-DD')
            const newRec = this.$store.state.businessYear === 2022 ? round((parseNum(item.RecSpecial) / 7.5345),2) : round(parseNum(item.RecSpecial),2)
            newItem.RecSpecial = newRec
            newItem.RecTotal = newRec
            newItem.HomeCurrency = "EUR"
            newItem.ExRate = 1

            newYearDocs.push(newItem)
          })
        }
       //console.log('newYearDocs', newYearDocs)
        if (newYearDocs) {
          await MainBookService.saveitems(newYearDocs)
          .then(res => {
            this.text = 'Sucess! ' + res.message
            this.color = 'green'
            this.snackbar = true
          })
          .catch(err => {
            this.text = 'Error! Sorry... some error happend. Need to return.' + err
            this.color = 'red'
            this.snackbar = true
          })
        }
      }
    }
  },
  computed: {
   ...mapGetters({
      myResetList: 'NeedResetList'
    })
  },
  watch: {
    'myResetList' () {
      this.initAgain()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
