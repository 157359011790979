<template>
  <div>

  </div>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './salarieDescEn'
import langHr from './salarieDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'
// import { parseNum } from '@/snippets/allSnippets'

export default {
  name: 'salarieprintsignslist',
  props: [],
  data () {
    return {
      msg: '',
      documentName: '',
      documentName2: '',
      document: {},
      havePath: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locDocument: {},
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      comp: {},
      mainQuery: {},
      ana: {},
      totalIz: 0,
      totalNa: 0,
      Bruto1: 0,
      Bruto3: 0,
      Neto3: 0,
      FinBruto1: 0,
      dodIz: 0,
      FinBruto2: 0,
      dopIz: 0,
      FinNeto1ls: 0,
      FinFreeTotalToUse: 0,
      FinTax1Am: 0,
      FinTax12Am: 0,
      FinNeto2: 0,
      neoT: 0,
      obuT: 0,
      FinNeto3: 0,
      FinBruto3: 0
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = {...langEn}
      this.langC = {...commEn}
    }
    if (this.$store.state.language === 'HR') {
      this.lang = {...langHr}
      this.langC = {...commHr}
    }

  },
  methods: {
    async print () {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.BusinessYear = this.$store.state.businessYear
      }
      this.printDoc()
    },

    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    printDoc () {
      this.comp = this.$store.state.companyActiv
      this.ana = this.$store.state.printObj ? this.$store.state.printObj : {}

      this.initDocument()
      this.addHead()
      this.addLine()
      this.addItemsHeader()
      this.addLine()
      this.addItems()
      this.addLine()
      this.addItemsHeader()
      this.addItemsTotals()

  
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'landscape'
      this.docDefinition.pageMargins = [ 20, 20, 20, 40 ]
      this.content = []
    },
  
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 800,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },

    addHead() {
      const docPart = {
          columns: [
            {
              // % width
              width: '35%',
              text: [
                { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.Companyemail ? 'email:' +  this.comp.Companyemail + ' \n' : ' ') , fontSize: 8 },
                { text: 'OIB:' + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '45%',
              text: [
                { text: 'Plaća - lista \n', fontSize: 18, bold: true },
                { text: 'za ' + (this.ana.Month ? this.ana.Month + '/' : '') + (this.ana.Year ? this.ana.Year + ', ('  : '') + (this.ana.Order ? this.ana.Order + ')' : '') , fontSize: 12, bold: true },
                { text: (this.ana.head3 ? this.ana.head3 + ' \n' : ''), fontSize: 8, bold: true },
                { text: (this.ana.head4 ? this.ana.head4 + ' \n' : ''), fontSize: 8, bold: true },
                { text: (this.ana.head5 ? this.ana.head5 + ' \n' : ''), fontSize: 8, bold: true },
              ]
            },
            {
              // % width
              width: '20%',
              text: [                 
                { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },

    addItemsHeader () {
      const docPart = {
          columns: [
            {
              width: '2%',
              text: 'Rbr' + '\n', fontSize: 8 
            },
            {
              width: '10%',
              text: 'Radnik' + '\n', fontSize: 8
            },
            {
              width: '6%',
              text: [
                { text: 'Bruto 1' , fontSize: 8, alignment: 'right' },
              ]
            },
            {
              width: '6%',
              text: [
                { text: 'Bruto dod.' , fontSize: 8, alignment: 'right' },
              ]
            },
            {
              width: '6%',
              text: [
                { text: 'Bruto 2' , fontSize: 8, alignment: 'right' },
              ]
            },
            {
              width: '6%',
              text: [
                { text: 'Dop.IZ' , fontSize: 8, alignment: 'right' },
              ]
            },
            {
              width: '6%',
              text: [
                { text: 'Dohodak' , fontSize: 8, alignment: 'right' },
              ]
            },
            {
              width: '6%',
              text: [
                { text: 'Os.odb' , fontSize: 8, alignment: 'right' },
              ]
            },
            {
              width: '4%',
              text: [
                { text: 'Por.%' + '\n' , fontSize: 8, alignment: 'right' },
                { text: 'niž.st.' , fontSize: 6, alignment: 'right' },
              ]
            },
                        {
              width: '6%',
              text: [
                { text: 'Por.Izn.' , fontSize: 8, alignment: 'right' },
              ]
            },
            {
              width: '4%',
              text: [
                { text: 'Por%' + '\n' , fontSize: 8, alignment: 'right' },
                { text: 'viš.st.' , fontSize: 6, alignment: 'right' },
              ]
            },
            {
              width: '6%',
              text: [
                { text: 'Por2.Izn.' , fontSize: 8, alignment: 'right' },
              ]
            },
            {
              width: '6%',
              text: [
                { text: 'Neto' , fontSize: 8, alignment: 'right' },
              ]
            },
            {
              width: '6%',
              text: [
                { text: 'Neopo.' , fontSize: 8, alignment: 'right' },
              ]
            },
            {
              width: '6%',
              text: [
                { text: 'Obust.' , fontSize: 8, alignment: 'right' },
              ]
            },
            {
              width: '6%',
              text: [
                { text: 'Neto isplata' + '\n' , fontSize: 8, alignment: 'right' },
                { text: 'neto+neopo.-obu.' , fontSize: 6, alignment: 'right' },
              ]
            },
            {
              width: '6%',
              text: [
                { text: 'UK.trošak' + '\n', fontSize: 8, alignment: 'right', },
                { text: 'bruto 2+dopr.NA' , fontSize: 6, alignment: 'right' },
              ]
            }
          ]
        }
      this.content.push(docPart)
    },
    addItems() {
          this.order = 0
            this.Bruto1 = 0
            this.Bruto3 = 0
            this.Neto3 = 0
            this.FinBruto1 = 0
            this.dodIz = 0
            this.FinBruto2 = 0
            this.dopIz = 0
            this.FinNeto1ls = 0
            this.FinFreeTotalToUse = 0
            this.FinTax1Am = 0
            this.FinTax12Am = 0
            this.FinNeto2 = 0
            this.neoT = 0
            this.obuT = 0
            this.FinNeto3 = 0
            this.FinBruto3 = 0
            if (this.ana.head1) {
              this.ana.head1.map(item => {
                this.order += 1
                // const haveBrutoAddition1 = parseFloat(item.FinKoef1) 
                // const haveBrutoAddition2 = parseFloat(item.FinKoef2)
                // const haveBrutoAddition = haveBrutoAddition1 + haveBrutoAddition2
                this.Bruto1 += this.round(parseFloat(item.FinBruto2), 2)
                const dodIz1 = item.FinKoef1 && item.FinKoef1 !== 0 ? this.round(item.FinKoef1) : 0
                const dodIz2 = item.FinKoef2 && item.FinKoef2 !== 0 ? this.round(item.FinKoef2) : 0
                const dodIz = dodIz1 + dodIz2
                const dopIz1 = item.FinFrom1_Amount && item.FinFrom1_Amount !== 0 ? this.round(item.FinFrom1_Amount,2) : 0
                const dopIz2 = item.FinFrom2_Amount && item.FinFrom2_Amount !== 0 ? this.round(item.FinFrom2_Amount, 2) : 0
                const dopIz3 = item.FinFrom3_Amount && item.FinFrom3_Amount !== 0 ? this.round(item.FinFrom3_Amount,2) : 0
                const dopIz4 = item.FinFrom4_Amount && item.FinFrom4_Amount !== 0 ? this.round(item.FinFrom4_Amount,2) : 0
                const dopIz = dopIz1 + dopIz2 + dopIz3 +dopIz4
                const neo1 = item.FinAdd1_Amount && item.FinAdd1_Amount !== 0 ? this.round(item.FinAdd1_Amount,2) : 0
                const neo2 = item.FinAdd2_Amount && item.FinAdd2_Amount !== 0 ? this.round(item.FinAdd2_Amount,2) : 0
                const neo3 = item.FinAdd3_Amount && item.FinAdd3_Amount !== 0 ? this.round(item.FinAdd3_Amount,2) : 0
                const neo4 = item.FinAdd4_Amount && item.FinAdd4_Amount !== 0 ? this.round(item.FinAdd4_Amount,2) : 0
                const neoT = neo1 + neo2 + neo3 + neo4
                const obu1 = item.Fin1LoanBankAmount !== 0 ? this.round(item.Fin1LoanBankAmount,2) : 0
                const obu2 = item.Fin2LoanBankAmount !== 0 ? this.round(item.Fin2LoanBankAmount,2) : 0
                const obu3 = item.Fin3LoanBankAmount !== 0 ? this.round(item.Fin3LoanBankAmount,2) : 0
                const obuT = obu1 + obu2 + obu3
                this.Bruto3 += this.round(parseFloat(item.FinBruto3),2)
                this.Neto3 += this.round(parseFloat(item.FinNeto3),2)

                this.FinBruto1 += this.round(item.FinBruto1 ,2)
                this.dodIz += this.round(dodIz ,2)
                this.FinBruto2 += this.round(item.FinBruto2 ,2)
                this.dopIz += this.round(dopIz ,2)
                this.FinNeto1ls += this.round(item.FinNeto1ls ,2)
                this.FinFreeTotalToUse += this.round(item.FinFreeTotalToUse ,2)
                this.FinTax1Am += this.round(item.FinTax1Am ,2)
                this.FinTax12Am += this.round(item.FinTax12Am ,2)
                this.FinNeto2 += this.round(item.FinNeto2 ,2)
                this.neoT += this.round(neoT ,2)
                this.obuT += this.round(obuT ,2)
                this.FinNeto3 += this.round(item.FinNeto3 ,2)
                this.FinBruto3 += this.round(item.FinBruto3 ,2)

                const docPart = {
                columns: [
                    {
                      width: '2%',
                      fontSize: 7,
                      alignment: 'left',
                      text: this.order + '.'
                    },
                    {
                      width: '10%',
                      fontSize: 7,
                      alignment: 'left',
                      text:(item.First ? item.First + ' ' : '') + (item.Last ? item.Last  : '') + ' \n',
                    },
                    {
                      width: '6%',
                      text: [
                        { text: (item.FinBruto1 !== 0 ? this.formatPrice(item.FinBruto1) + ' \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '6%',
                      text: [
                        { text: (dodIz !== 0 ? this.formatPrice(dodIz) + ' \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '6%',
                      text: [
                        { text: (item.FinBruto2 !== 0 ? this.formatPrice(item.FinBruto2) + ' \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '6%',
                      text: [
                        { text: (dopIz !== 0 ? this.formatPrice(dopIz) + ' \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '6%',
                      text: [
                        { text: (item.FinNeto1ls !== 0 ? this.formatPrice(item.FinNeto1ls) + ' \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '6%',
                      text: [
                        { text: (item.FinFreeTotalToUse !== 0 ? this.formatPrice(item.FinFreeTotalToUse) + ' \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '4%',
                      text: [
                        { text: (item.FinTax1Per !== 0 ? this.formatPrice(item.FinTax1Per) + ' \n' : '') , fontSize: 7, alignment: 'right', },
                      ]
                    },
                    {
                      width: '6%',
                      text: [
                        { text: (item.FinTax1Am !== 0 ? this.formatPrice(item.FinTax1Am) + ' \n' : '') , fontSize: 7, alignment: 'right', },
                      ]
                    },
                    {
                      width: '4%',
                      text: [
                        { text: (item.FinTax2Per !== 0 ? this.formatPrice(item.FinTax2Per) + ' \n' : '') , fontSize: 7, alignment: 'right', },
                      ]
                    },
                    {
                      width: '6%',
                      text: [
                        { text: (item.FinTax2Am !== 0 ? this.formatPrice(item.FinTax2Am) + ' \n' : '') , fontSize: 7, alignment: 'right', },
                      ]
                    },
                    {
                      width: '6%',
                      text: [
                        { text: (item.FinNeto2 !== 0 ? this.formatPrice(item.FinNeto2) + ' \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '6%',
                      text: [
                        { text: (neoT !== 0 ? this.formatPrice(neoT) + ' \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '6%',
                      text: [
                        { text: (obuT !== 0 ? this.formatPrice(obuT) + ' \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '6%',
                      text: [
                        { text: (item.FinNeto3 !== 0 ? this.formatPrice(item.FinNeto3) + ' \n' : '') , fontSize: 7, alignment: 'right', bold: true },
                      ]
                    },
                     {
                      width: '6%',
                      text: [
                        { text: (item.FinBruto3 !== 0 ? this.formatPrice(item.FinBruto3) + ' \n' : '') , fontSize: 7, alignment: 'right', },
                      ]
                    }

                  ]
                }
              this.content.push(docPart)
              const docPart2 = {
                columns: [
                    {
                      width: '25%',
                      text: [
                        { text: ' \n', fontSize: 5 },
                      ]
                    }
                  ]
                }
              this.content.push(docPart2)
            })
          }
    },

    addItemsTotals() {
        const docPart = {
        columns: [
            {
              width: '2%',
              fontSize: 7,
              alignment: 'left',
              text:  ' '
            },
            {
              width: '10%',
              fontSize: 7,
              alignment: 'left',
              text: 'UKUPNO: \n',
            },
            {
              width: '6%',
              text: [
                { text: (this.FinBruto1 && this.FinBruto1 !== 0 ? this.formatPrice(this.FinBruto1) + ' \n' : '') , fontSize: 7, alignment: 'right' },
              ]
            },
            {
              width: '6%',
              text: [
                { text: (this.dodIz && this.dodIz !== 0 ? this.formatPrice(this.dodIz) + ' \n' : '') , fontSize: 7, alignment: 'right' },
              ]
            },
            {
              width: '6%',
              text: [
                { text: (this.FinBruto2 && this.FinBruto2 !== 0 ? this.formatPrice(this.FinBruto2) + ' \n' : '') , fontSize: 7, alignment: 'right' },
              ]
            },
            {
              width: '6%',
              text: [
                { text: (this.dopIz && this.dopIz !== 0 ? this.formatPrice(this.dopIz) + ' \n' : '') , fontSize: 7, alignment: 'right' },
              ]
            },
            {
              width: '6%',
              text: [
                { text: (this.FinNeto1ls && this.FinNeto1ls !== 0 ? this.formatPrice(this.FinNeto1ls) + ' \n' : '') , fontSize: 7, alignment: 'right' },
              ]
            },
            {
              width: '6%',
              text: [
                { text: (this.FinFreeTotalToUse && this.FinFreeTotalToUse !== 0 ? this.formatPrice(this.FinFreeTotalToUse) + ' \n' : '') , fontSize: 7, alignment: 'right' },
              ]
            },
            {
              width: '4%',
              text: [
                { text:  ' \n' , fontSize: 7, alignment: 'right', },
              ]
            },
            {
              width: '6%',
              text: [
                { text: (this.FinTax1Am && this.FinTax1Am !== 0 ? this.formatPrice(this.FinTax1Am) + ' \n' : '') , fontSize: 7, alignment: 'right', },
              ]
            },
            {
              width: '4%',
              text: [
                { text:  ' \n' , fontSize: 7, alignment: 'right', },
              ]
            },
            {
              width: '6%',
              text: [
                { text: (this.FinTax2Am && this.FinTax2Am !== 0 ? this.formatPrice(this.FinTax2Am) + ' \n' : '') , fontSize: 7, alignment: 'right', },
              ]
            },
            {
              width: '6%',
              text: [
                { text: (this.FinNeto2 && this.FinNeto2 !== 0 ? this.formatPrice(this.FinNeto2) + ' \n' : '') , fontSize: 7, alignment: 'right' },
              ]
            },
            {
              width: '6%',
              text: [
                { text: (this.neoT && this.neoT !== 0 ? this.formatPrice(this.neoT) + ' \n' : '') , fontSize: 7, alignment: 'right' },
              ]
            },
            {
              width: '6%',
              text: [
                { text: (this.obuT & this.obuT !== 0 ? this.formatPrice(this.obuT) + ' \n' : '') , fontSize: 7, alignment: 'right' },
              ]
            },
            {
              width: '6%',
              text: [
                { text: (this.FinNeto3 && this.FinNeto3 !== 0 ? this.formatPrice(this.FinNeto3) + ' \n' : '') , fontSize: 7, alignment: 'right', bold: true },
              ]
            },
              {
              width: '6%',
              text: [
                { text: (this.FinBruto3 && this.FinBruto3 !== 0 ? this.formatPrice(this.FinBruto3) + ' \n' : '') , fontSize: 7, alignment: 'right', },
              ]
            }
          ]
        }
      this.content.push(docPart)
    },


    addEmpty () {
      const docPart = {
        
          }
      this.content.push(docPart)
    },

  },
  computed: {
  },
  watch: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
