<template>
  <v-card>
      <v-card-title>
        {{lang.WorkHours}}
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          v-model="search"
        ></v-text-field>
      </v-card-title>

      <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"
          >
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
          From {{ pageStart }} to {{ pageStop }}
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import WorkHourService from '@/services/WorkHourService'
import langEn from './workhourDescEn'
import langHr from './workhourDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"


export default {
  name: 'workhourlist',
  props: ['monthSelected', 'yearSelected'],  
  data () {
    return {
      lang: {},
      langC: {},
      saving: false,
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      error: '',
      pagination: {},
      rowsPerPageItems: [25],
      headers: [],
      selectM: {},
      selectY: {},
      
      mainQuery: {}
    }
  },
  async mounted () {
    try {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      this.mainQuery.monthSelected = this.monthSelected ? this.monthSelected : null
      this.mainQuery.yearSelected = this.yearSelected ? this.yearSelected : null
      this.headers.push(
        { text: this.lang.Last, align: 'left',  sortable: true, value: 'Last'},
        { text: this.lang.First, sortable: true, value: 'First' },
        { text: this.lang.TotalHours, sortable: true, value: 'totalUkupno' },
        { text: this.lang.ForMonth, sortable: true, value: 'ForMonth' },
        { text: this.lang.ForYear, sortable: true, value: 'ForYear' },
        { text: 'ID', sortable: true, value: 'EmployeeId' },
      )

     this.items = (await WorkHourService.index(this.mainQuery)).data.workhours
    } catch (error) {
     // eslint-disable-next-line
     console.log(error)
    }
  },
  methods: {
    navigateTo (id) {
     
      //this.$store.dispatch('setWorkHourId', EmployeeId)
      const EmployeeId = id.EmployeeId
      const monthS = parseInt(id.ForMonth)
      const yearS = parseInt(id.ForYear)
      this.$router.push({
        name: 'whCRUD',
        params: {
          ForMonth: monthS,
          ForYear: yearS,
          EmployeeId: EmployeeId
        }
      })
    }
  },
  computed: {

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
