<template>
  <div>
        <v-row>

          <v-col xs="1" sm="2" md="2" lg="2" ex="2" class="mr-5">
            <v-text-field
              dense
              :label="lang.documentPOSCard"
              v-model.number="posCard"
              type="number"
              disabled
            ></v-text-field>
          </v-col>
          <v-col xs="3" sm="3" md="3" lg="3" ex="3">
              <v-select
                label="Vrsta kartice"
                :items="cardtype"
                v-model="selectCard"
                item-text="CardName"
                item-value="cardtype.id"
                single-line
                return-object
                persistent-hint
                clearable
                dense
              ></v-select>
          </v-col>
          <v-col xs="3" sm="3" md="3" lg="3" ex="3">
            <v-btn text color="primary" class="pt-3" @click="updateCardType()">{{langC.Save}}</v-btn>
          </v-col>
        </v-row>
        <v-snackbar
            v-model="snackbar"
            bottom
            :timeout="timeout"
            :color="color"
            >
            {{ text }}
      </v-snackbar>
  </div>
</template>

<script>
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import moneyFormat from '@/snippets/moneyFormat'
import CardTypeService from '@/services/CardTypeService'
import DocumentService from '@/services/DocumentService'

export default {
  data() {
    return {
      document: {},
      lang: {},
      langC: {},
      documentNameLoc: '',
      posCard: 0,
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      cardtype: [],
      selectCard: {},
      mainQuery: {}
    }
  },
  async mounted() {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.documentActivHead) {
      this.document = this.$store.state.documentActivHead
      this.documentNameLoc = this.$store.state.documentActivLoc
    }
    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    const {data} = await CardTypeService.index(this.mainQuery)
    if (data && data.cardtypes !== undefined){
      this.cardtype = data.cardtypes
    } else {
      this.cardtype = this.$store.state.cardtype
    }
    this.posCard = this.document.documentPOSCard ? parseFloat(this.document.documentPOSCard) : 0
    if (this.document.productiontype) {
      this.selectCard = this.cardtype.find(card => card.id === this.document.productiontype)
    }

  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    async updateCardType(){
      if (Object.keys(this.selectCard).length !== 0 && this.selectCard.constructor === Object) {
        this.document.productiontype = this.selectCard.id
        this.document.status = this.selectCard.CardName
        await DocumentService.put(this.document)
        .then(response => {
          this.text = this.lang.DocumentSavedOK + response.statusText
          this.color = 'green'
          this.snackbar = true
          this.timeout = 2000
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log(err)
          this.text = 'Error put!' + err
          this.color = 'red'
          this.snackbar = true
          this.timeout = 4000
        })
      }
    },
  },
  computed: {

  }
}
</script>

<style>

</style>