<template>
 <v-container fluid grid-list-md>
      <v-layout row>
      <v-flex d-flex xs12 sm6 md4>
          <v-btn color="primary" @click="tasktype" class="mr-2">
            {{lang.TaskTypes}}
          </v-btn>
          <v-btn color="primary" @click="taskdepartment" class="mr-2">
            {{lang.TaskDepartments}}
          </v-btn>         
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import langEn from './taskDescEn'
import langHr from './taskDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"

export default {
  name: 'accountchoose',
  data () {
    return {
      menu: false,
      modal: false,
      msg: '',
      valid: true,
      account: { },
      error: null,
      localError: false,
      privGroups: [],
      lang: {},
      langC: {},
      saving: false,
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
  },
  methods: {
    async tasktype () {
      try {
        await this.$router.push({
          name: 'tasktype'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async taskdepartment () {
      try {
        await this.$router.push({
          name: 'taskdepartment'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  computed: {
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
