<template>
  <div>
    <v-row no-gutters>
        <v-col cols="7">
          
        </v-col>
        <v-col cols="5">
            <div class="text-right display-2">
              {{ localMoney(total) }} {{currency}}
            </div>
        </v-col>
      </v-row>
  </div>
</template>

<script>
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import moneyFormat from '@/snippets/moneyFormat'

export default {
  data() {
    return {
      document: {},
      lang: {},
      langC: {},
      documentNameLoc: ''
    }
  },
  mounted() {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.documentActivHead) {
      this.document = this.$store.state.documentActivHead
      this.documentNameLoc = this.$store.state.documentActivLoc
    }
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
  },
  computed: {
    total () {
      return (this.$store.state.documentActivHead.documentTotalAmountToPay ? this.$store.state.documentActivHead.documentTotalAmountToPay : 0)
    },
    currency () {
      return (this.$store.state.companyActiv.currencyType ? this.$store.state.companyActiv.currencyType : 'EUR')
    }
  }
}
</script>

<style>

</style>