import Api from '@/services/Api'

export default {
  index (search) {
    return Api().get('account2', {
      params: {
        search: search
      }
    })
  },
  choose (query) {
    return Api().get('account2/choose', {
      params: query
    })
  },
  show (account2Id) {
    return Api().get(`account2/${account2Id}`)
  },
  post (newaccount2) {
    return Api().post('account2', newaccount2)
  },
  put (account2) {
    return Api().put(`account2/${account2.id}`, account2)
  },
  delete (account2Id) {
    return Api().delete(`account2/${account2Id}`)
  }
}
