<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="serials"
    >
      <template v-slot:item.itemDescription="props">
        <v-edit-dialog
          :return-value.sync="props.item.itemDescription"
          @save="save"
          @cancel="cancel"
          @open="open"
          @close="close"
        >
          {{ props.item.itemDescription }}
          <template v-slot:input>
            <v-text-field
              v-model="props.item.itemDescription"
              label="Edit"
              single-line
              counter
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

    </v-data-table>

    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          text
          @click="snack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import langEn from './serialsDescEn'
import langHr from './serialsDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"


  export default {
    props: ['serials'],
    data () {
      return {
        snack: false,
        snackColor: '',
        snackText: '',
        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [],
      }
    },
    async mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }

      this.headers.push(

        {text: this.lang.itemID, value: 'itemID', width: '50px',},
        {text: this.lang.itemName, value: 'itemName', width: '400px',},
        {text: this.lang.itemUnit, value: 'itemUnit', width: '50px', },
        {text: this.lang.itemDescription, value: 'itemDescription', width: '250px',},
        {text: this.lang.StartDate, value: 'StartDate', width: '100px', type: 'date', dateInputFormat: 'YYYY-MM-DD', dateOutputFormat: 'DD.MM.YYYY'},
        {text: this.lang.EndDate, value: 'EndDate', width: '100px', type: 'date', dateInputFormat: 'YYYY-MM-DD', dateOutputFormat: 'DD.MM.YYYY'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
      ) 
    },
    methods: {
      save () {
        // console.log('serials ', this.serials)
        // this.snack = true
        // this.snackColor = 'success'
        // this.snackText = 'Data saved'
      },
      cancel () {
        // this.snack = true
        // this.snackColor = 'error'
        // this.snackText = 'Canceled'
      },
      open () {
        // this.snack = true
        // this.snackColor = 'info'
        // this.snackText = 'Dialog opened'
      },
      close () {
        console.log('Dialog closed')
      },
    },
  }
</script>