<template>
  <div class="task">
    <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
      <v-card>
        <v-card-title>
          {{ lang.Companies }}
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="20"
            @click:row="navigateTo"
            :mobile-breakpoint="0"
          >
          <template v-slot:[`item.StartFrom`]="{ item }">
              <span>{{ localDate(item.StartFrom) }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>
  </div>
</template>

<script>
import CompService from '@/services/CompService'
import langEn from './companyDescEn'
import langHr from './companyDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dateFormat from '@/snippets/dateFormat'

export default {
  name: 'complist',
  data () {
    return {
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      error: '',
      pagination: {},
      rowsPerPageItems: [25],
      headers: [ ],
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: '',
        databaseDocID: 0
      },
    }
  },
  async mounted () {
    try {
       if (this.$store.state.language === 'EN') {
        this.lang = langEn
        this.langC = commEn
      }
      if (this.$store.state.language === 'HR') {
        this.lang = langHr
        this.langC = commHr
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      this.$store.dispatch('seteditItem', 0)
      this.saving = true
      this.items = (await CompService.index()).data.companys
      this.saving = false
      this.headers.push(
        {text: this.lang.CompanyName, value: 'CompanyName'},
        {text: this.lang.CompanyVATID, value: 'CompanyVATID', width: '100px'},
        {text: this.lang.CompanyNameShort, value: 'CompanyNameShort', width: '200px'},
        {text: this.lang.CompanyStreet, value: 'CompanyStreet'},
        {text: this.lang.CompanyCity, value: 'CompanyCity'},
        {text: this.lang.CompanyPostcode, value: 'CompanyPostcode', width: '50px'},
        {text: this.lang.CompanyCountyRegion, value: 'CompanyCountyRegion', width: '150px'},
        {text: this.lang.CompanyState, value: 'CompanyState', width: '150px'},
        {text: this.lang.CompanyDirectorFirst, value: 'CompanyDirectorFirst', width: '100px'},
        {text: this.lang.CompanyDirectorLast, value: 'CompanyDirectorLast', width: '100px'},
        {text: this.lang.StartFrom, value: 'StartFrom', width: '100px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'}
      )
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  },
  methods: {
    navigateTo (params) {
      // eslint-disable-next-line
      console.log(' navigate ', params)
      this.$store.dispatch('setCompanyid', params.id)
      this.$router.push({
        name: 'companyview'
      }).catch(err => {
          // eslint-disable-next-line
          console.log(err);
        })
    },
    formatFn (value) {
      if (value === true) {
        return 'Yes'
      } else {
        return ''
      }
    },
    formatFnHR (value) {
      if (value === true) {
        return 'Da'
      } else {
        return ''
      }
    },
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    }
  },
  computed: {

  },
  watch: {

  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
