<template>
  <v-layout row justify-center>
      <v-card>
        <v-card-title class="headline">Are you sure to delete Bank Account?</v-card-title>
        <v-card-text>We not recomend to delete Bank account. Better disable it. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" flat @click="deleteBankAccount">Delete</v-btn>
          <v-btn color="green darken-1" flat @click="bankAccountlist">Cancel</v-btn>
        </v-card-actions>
      </v-card>
  </v-layout>
</template>

<script>
import BankAccountService from '@/services/BankAccountService'

export default {
  name: 'bankaccountdelete',
  data () {
    return {
      msg: '',
      dialog: false
    }
  },
  methods: {
    async deleteBankAccount () {
      try {
        if (this.$store.state.bankaccountid) {
          await BankAccountService.delete(this.$store.state.bankaccountid)
          await this.$router.push({
            name: 'bankaccount'
          }).catch(err => {
            // eslint-disable-next-line
            console.log(err);
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async bankAccountlist () {
      try {
        await this.$router.push({
          name: 'bankaccount'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
