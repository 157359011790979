<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs12 sm12 m12 lg12 ex12>

            <MainBookCompCardHeader :type="type" :par="par" addHeader="" :showOpen="showOpen" :short="short"/>

            <v-row v-if='hideHeader'>
              <v-col cols="12" xs="0" sm="0" md="3" lg="3" xl="3" >

              </v-col>
              <v-col cols="12" xs="4" sm="4" md="1" lg="1" xl="1" >
                <span class="title indigo--text">{{ formatPrice(Total1) }}</span>
              </v-col>
              <v-col cols="12" xs="4" sm="4" md="1" lg="1" xl="1" >
                 <span class="title deep-orange--text">{{ formatPrice(Total2) }}</span>
              </v-col>
              <v-col cols="12" xs="4" sm="4" md="1" lg="1" xl="1" >
                <span class="title purple--text">{{ formatPrice(Total3) }}</span>
              </v-col>
              <v-col cols="12" xs="0" sm="0" md="3" lg="3" xl="3" >

              </v-col>
              <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" >
                <v-btn @click="print()" class="mr-5">Printaj</v-btn>
                <v-btn  @click="addDiff()" class="mr-5">Dodaj razliku</v-btn>
              </v-col>
            </v-row>

            <v-row v-if='!hideHeader'>
                <v-col cols="12" xs="0" sm="0" md="3" lg="3" xl="3" >

              </v-col>
                <v-col cols="12" xs="0" sm="0" md="3" lg="3" xl="3" >

              </v-col>
              <v-col cols="12" xs="0" sm="0" md="3" lg="3" xl="3" >
                <v-btn @click="print()" class="mr-5">Printaj</v-btn>
              </v-col>
              <v-col cols="12" xs="4" sm="4" md="1" lg="1" xl="1" class="text-right">
                <span class="indigo--text">{{ formatPrice(Total1) }}</span>
              </v-col>
              <v-col cols="12" xs="4" sm="4" md="1" lg="1" xl="1" class="text-right" >
                 <span class=" deep-orange--text">{{ formatPrice(Total2) }}</span>
              </v-col>
              <v-col cols="12" xs="4" sm="4" md="1" lg="1" xl="1" class="text-right">
                <span class=" purple--text">{{ formatPrice(Total3) }}</span>
              </v-col>
            

            </v-row>

            <v-row v-if='hideHeader'>
              <v-col xs="12" sm="12" md="8" lg="9"  xl="9" class="pr-3">
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="items"
                  :single-select="singleSelect"
                  :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50]
                  }"
                  :items-per-page="50"
                  item-key="idSel"
                  show-select
                  class="elevation-1"

                  >
                  <template v-slot:[`item.docID`]="{ item }">
                    {{ parseInt(item.docID) }}
                  </template>
                  <template v-slot:[`item.datum`]="{ item }">
                    <span class="title indigo--text">{{ localDate(item.datum) }}</span>
                  </template>
                  <template v-slot:[`item.debit`]="{ item }">
                    <span class="title indigo--text">{{ formatPrice(item.debit) }}</span>
                  </template>
                  <template v-slot:[`item.credit`]="{ item }">
                    <span class="title deep-orange--text">{{ formatPrice(item.credit) }}</span>
                  </template>
                   <template v-slot:[`item.CreditDebit`]="{ item }">
                    <span class="title purple--text">{{ formatPrice(item.CreditDebit) }}</span>
                  </template>
                  <template slot="pageText" slot-scope="{ pageStart, pageStop }">
                    From {{ pageStart }} to {{ pageStop }}
                  </template>
                </v-data-table>                  
              </v-col>
              <v-col xs="12" sm="12" md="4" lg="3"  xl="3">
                <MainBookCompCardSelected :selectedItems = "selectedItems" :type="type" :key="componentKey"/>
              </v-col>
            </v-row>

            <div v-if="!hideHeader">
            
            <v-layout row style="border-bottom:solid 1px; border-top:solid 1px;">

                    <v-flex xs1 class="text-left" >
                      Rb.  Vrsta
                    </v-flex>
                    <v-flex xs2 class="text-left" >
                      Dokument
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Veza
                    </v-flex>
                     <v-flex xs1 class="text-left" >
                      Datum knjiž
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Datum dok
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Rok plać.
                    </v-flex>
                    <v-flex xs2 class="text-left">
                      Opis
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                       <span v-if="type === 1">Isplate {{defCurrency}}</span>
                       <span v-if="type === 2">Računi {{defCurrency}}</span>
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      <span v-if="type === 1">Računi {{defCurrency}}</span>
                      <span v-if="type === 2">Uplate {{defCurrency}}</span>
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Saldo {{defCurrency}}
                    </v-flex>
                    
              </v-layout>

                <v-layout row v-for="item in items" :key="item.id">
                    <v-flex xs1 class="text-left" >
                      {{ item.itBookName }} {{ item.BookTypeName }}
                    </v-flex>
                  
                    <v-flex xs2 class="text-left" >
                      {{ (type === 2 && item.InvoiceNumber ?parseInt(item.InvoiceNumber) : item.InvoiceNumber)}}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ (item.docID ? parseInt(item.docID): null) }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ localDate(item.datum) }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ localDate(item.InvoiceDate) }}
                    </v-flex>
                     <v-flex xs1 class="text-left" >
                      {{ localDate(item.InvoicePaymentDeadlineDate) }}
                    </v-flex>
                    <v-flex xs2 class="text-left" style="font-size:12px;">
                      {{ item.desc1 }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(parseFloat(item.debit)) }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(parseFloat(item.credit)) }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(item.CreditDebit) }}
                    </v-flex>
                    
            </v-layout>
            <v-layout row style="border-top:solid 1px;">
              <v-flex xs9 class="text-xs-right">

              </v-flex>

              <v-flex xs1 class="text-right">
                {{ formatPrice(Total1) }}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(Total2) }}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(Total3) }}
              </v-flex>

            </v-layout>
            </div>
      </v-flex>
    </v-layout>
     <Mainbookcompcard2print ref="prnt" />
  </v-container>
</template>

<script>

import ledgerEN from '../MainBook/mainbookDescEn'
import ledgerHR from '../MainBook/mainbookDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import MainBookCompCardHeader from "./MainBookCompCardHeader"
import MainBookCompService from '@/services/MainBookCompService'
import Mainbookcompcard2print from './MainBookCompCard2Print'
import MainLedgerService from '@/services/MainLedgerService'
import MainBookCompCardSelected from "./MainBookCompCardSelected";
import {dynamicSort, parseNum, round, dateFormat} from '@/snippets/allSnippets'
import dayjs from 'dayjs'

export default {
  name: 'mainbookcompcard2',
  props: ['head', 'partnerVATID', 'type', 'defCurrency', 'hHeader', 'mainbook', 'showOpen'],
  data () {
    return {
      rowsPerPageItems: [50],
      modal: false, 
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      comp: {},
      error: null,
      localError: false,
      newUser: {},
      size: 'sm',
      havePath: '',
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      lang: {},
      langC: {},
      saving: false,
      pItems: [],
      sItems: [],
      items: [],
      mainbookAna:{},
      mledger: [],
      mbook: [],
      par: {},
      card: {},
      singleSelect: false,
      selected: [],
      headers: [],
      hideHeader: false,
      componentKey: 0,
      selectedItems: [],
      finalItems: [],
      onDebit: 0,
      onCredit: 0,
      onDebitCredit: 0,
      short: false
    }
  },
  components: {
    Mainbookcompcard2print,
    MainBookCompCardSelected,
    MainBookCompCardHeader
  },
  created() {

  },
  async mounted () {
    try {
      if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      if (this.hHeader) {
        this.hideHeader = this.hHeader
      }

      this.$store.dispatch('setMainLedgerDetailItems', [])
      this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}

      this.par = this.partnerVATID ? this.$store.state.partnerStore.find(partner => partner.partnerVATID === this.partnerVATID) : null
      
      await this.doInitLoad()
      if (!this.showOpen) {
        this.doTotalCard()
      }
      if (this.showOpen) {
        this.manageOpen()
      }
      
      this.headers.push(
        {text: 'Datum knjiž', align: 'right', sortable: true, value: 'datum', width: '100px' },
        {text: 'Dokument', align: 'right', sortable: true, value: 'docID', width: '160px' },
        {text: (this.type === 1 ? 'Isplate' : 'Računi' ), align: 'right', sortable: true, value: 'debit', width: '160px' },
        {text: (this.type === 1 ? 'Računi' : 'Uplate' ), align: 'right', sortable: true,value: 'credit', width: '160px' },
        {text: 'Saldo '  + this.defCurrency , value: 'CreditDebit', align: 'right', width: '160px' },
        {text: 'Opis', value: 'desc1', align: 'left', width: '550px' },
        {text: this.lang.id, value: 'idSel', width: '50px' }
      )

    } catch (err) {
      // eslint-disable-next-line
      console.log('Error ', err)
    }
    

  },
  computed: {

  },
  methods: {
    async doInitLoad() {
      this.mainbookAna.partnerVATID = this.partnerVATID ? this.partnerVATID : null
      this.mainbookAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
      this.mainbookAna.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
      this.mainbookAna.docFromDate = this.head && this.head.docFromDate ? dayjs(this.head.docFromDate).format('YYYY-MM-DD') : null
      this.mainbookAna.docToDate = this.head && this.head.docToDate ? dayjs(this.head.docToDate).format('YYYY-MM-DD') : null
      this.mainbookAna.MLType = this.type ? this.type : null
      const mbook = (await MainBookCompService.choose(this.mainbookAna)).data.mainbookcomps
      const mledgerAna = {}
      mledgerAna.partnerVATID = this.partnerVATID ? this.partnerVATID : null
      mledgerAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
      mledgerAna.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
      const mledger = (await MainLedgerService.books(this.mainbookAna)).data.documents

      this.pItems = []
      if (mbook && mbook.length > 0) {
        mbook.map(book => {
          let doIt = false
          if (this.type === 1 && round(parseNum(book.ExpTotal),2) !=0 ) {
            doIt = true
          }
          if (this.type === 2 && round(parseNum(book.RecTotal),2) !=0 ) {
            doIt = true
          }
          if (doIt){
            const object1 = {
              datum: book.BookDate,
              BookTypeName: 'Izvod',
              InvoiceNumber: book.bankStatementNumber ? book.bankStatementNumber : null,
              InvoiceDate: book.BookDate,
              InvoicePaymentDeadlineDate: book.InvoicePaymentDeadlineDate,
              debit: (this.type === 1 ? book.ExpTotal : book.RecTotal ),
              credit: 0,
              desc1: book.BookDesc1,
              desc2: book.BookDesc2,
              docID: (book.BookID ? parseInt(book.BookID) : book.id) ,
              datum2: dayjs(book.BookDate).format('YYYY-MM-DD'),
              type: 'payment'
            }
            this.pItems.push(object1)
          }
        })
      }
      if (mledger && mledger.length > 0) {
        mledger.map(book => {
          let runIt = true
          //const today = dayjs(new Date()).format('YYYY-MM-DD')
          // if (this.showOpen) {
          //   if (book.InvoicePaymentDeadlineDate > today) {
          //     runIt = false
          //   }
          // }
          const object1 = {}
          if (runIt) {
            object1.datum = book.BookingDate,
            object1.BookTypeName = 'Račun',
            object1.InvoiceNumber = book.InvoiceNumber,
            object1.InvoiceDate = book.InvoiceDate,
            object1.InvoicePaymentDeadlineDate = book.InvoicePaymentDeadlineDate,
            object1.debit = 0,
            object1.credit = book.InvoiceAmount,
            object1.desc1 = book.MLFree5,
            object1.desc2 = "",
            object1.docID = parseInt(book.BookID),
            object1.type = 'invoice',
            object1.datum2 = dayjs(book.BookingDate).format('YYYY-MM-DD')
          }
          if(Object.keys(object1).length) {
            this.pItems.push(object1)
          }
        })
      }

    },
    doTotalCard() {
      this.pItems.sort(dynamicSort("datum2"))

      this.Total1 = 0
      this.Total2 = 0
      this.Total3 = 0
      this.Total4 = 0
      let itLine = 0
      if (this.pItems) {
        this.pItems.map(item => {
          itLine += 1
          if (this.type === 1) {
            this.Total1 += parseFloat(item.debit)
            this.Total2 += parseFloat(item.credit)
          }
          if (this.type === 2) {
            this.Total1 += parseFloat(item.credit)
            this.Total2 += parseFloat(item.debit)
            
          }
          //this.Total3 += parseFloat(item.DebitForegin)
          //this.Total4 += parseFloat(item.CreditForegin)
          const object2 = {}

          object2.itBookName = itLine + '. '
          if (this.type === 1) {
            object2.debit = item.debit
            object2.credit = item.credit
          }
          if (this.type === 2) {
            object2.debit = item.credit
            object2.credit = item.debit
          }
          object2.CreditDebit= this.Total1 - this.Total2
          object2.idSel = itLine
          
          const object3 = {...item, ...object2}
          this.items.push(object3)
        })
      }
      this.Total3 = this.Total1 - this.Total2
    },
    manageOpen(){
      // do calc open amount on docID 
      const res = Array.from(this.pItems.reduce(
        (m, {docID, debit}) => m.set(docID, (m.get(docID) || 0) + parseNum(debit,2)), new Map
      ), ([docID, debit]) => ({docID, debit}));
      const res2 = Array.from(this.pItems.reduce(
        (m, {docID, credit}) => m.set(docID, (m.get(docID) || 0) + parseNum(credit,2)), new Map
      ), ([docID, credit]) => ({docID, credit}));

      
      const pItems2 = []

      this.pItems.map(pIt => {
        if (pIt.type === 'invoice') {
          const tDebit = res.find(itD => itD.docID === pIt.docID)
          const tCredit = res2.find(itC => itC.docID === pIt.docID)
          let newDebit = 0
          let newCredit = 0
          if (tDebit && tDebit.debit) {
            newDebit = round(tDebit.debit,2)
          }
          if (tCredit && tCredit.credit) {
            newCredit = round(tCredit.credit,2)
          }
          const diff = round((newDebit - newCredit), 2)
          if (diff != 0) {
            pIt.datum2 = dayjs(pIt.datum)
            pIt.debit = newDebit
            pIt.credit = newCredit
            pItems2.push(pIt)
          }
        }
      })

      this.pItems.map(pIt => {
          const tDebit = res.find(itD => itD.docID === pIt.docID)
          const tCredit = res2.find(itC => itC.docID === pIt.docID)
          let newDebit = 0
          let newCredit = 0
          if (tDebit && tDebit.debit) {
            newDebit = round(tDebit.debit,2)
          }
          if (tCredit && tCredit.credit) {
            newCredit = round(tCredit.credit,2)
          }
          // have payment but not invoice
          if (newDebit != 0 && newCredit === 0) {
            pIt.datum2 = dayjs(pIt.datum)
            pIt.debit = newDebit
            pIt.credit = newCredit
            pItems2.push(pIt)
          }
      })
      pItems2.sort(dynamicSort("datum2"))

      this.Total1 = 0
      this.Total2 = 0
      this.Total3 = 0
      this.Total4 = 0
      let itLine = 0
      if (pItems2) {
        pItems2.map(item => {
          itLine += 1
          if (this.type === 1) {
            this.Total1 += parseFloat(item.debit)
            this.Total2 += parseFloat(item.credit)
          }
          if (this.type === 2) {
            this.Total1 += parseFloat(item.credit)
            this.Total2 += parseFloat(item.debit)
            
          }
          //this.Total3 += parseFloat(item.DebitForegin)
          //this.Total4 += parseFloat(item.CreditForegin)
          const object2 = {}

          object2.itBookName = itLine + '. '
          if (this.type === 1) {
            object2.debit = item.debit
            object2.credit = item.credit
          }
          if (this.type === 2) {
            object2.debit = item.credit
            object2.credit = item.debit
          }
          object2.CreditDebit= this.Total1 - this.Total2
          object2.idSel = itLine
          
          const object3 = {...item, ...object2}
          this.items.push(object3)
        })
      }

      this.Total3 = this.Total1 - this.Total2
    },
    localDate(dateToFormat) {
      return (dateToFormat ? dateFormat(dateToFormat) : null)
    },
    forceRerender() {
      this.componentKey += 1;  
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },

    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    print () {
      this.card = {...this.head}
      this.card.items = this.items
      this.card.par = this.par
      this.card.docFromDate = this.mainbookAna.docFromDate
      this.card.docToDate = this.mainbookAna.docToDate
      this.card.defCurrency = this.defCurrency
      this.card.type = this.type
      this.card.open = false
      this.$refs.prnt.print(this.card)
    },

    close () {
      this.show = false
    },
    addDiff() {
      let defBookID = null
      let defBookDate = null
      const items = this.$store.getters.NeedMainLedgerDetailItems ? this.$store.getters.NeedMainLedgerDetailItems : []
      if (items) {
        this.selectedAmount = 0
        this.restAmonut = this.show === 1 ? this.mainbook.RecTotal : this.mainbook.ExpTotal
        items.map(item => {
          defBookID = parseInt(item.docID)
          defBookDate = item.datum2

          if (this.show === 1) {
            this.selectedAmount += item.RecTotal ? item.RecTotal : 0
            this.restAmonut = this.mainbook.RecTotal - this.selectedAmount
          }
          if (this.show === 2) {
            this.selectedAmount += item.ExpTotal ? item.ExpTotal : 0
            this.restAmonut = this.mainbook.ExpTotal - this.selectedAmount
          }
        })
      }
      
      if (round(this.restAmonut,2) !==0) {
        let newMB = {}
        if (this.mainbook) {
          newMB = {...this.mainbook}
        }
        newMB.BookID = defBookID
        newMB.BookDate = defBookDate ? defBookDate : new Date()
        newMB.InvoiceDate = null
        newMB.InvoicePaymentDeadlineDate = null
        newMB.FreeB1 = true
        
        if (this.type === 1) {
          newMB.RecBank = 0
          newMB.RecTotal = 0

          newMB.ExpBank = round(this.restAmonut,2)
          newMB.ExpTotal = round(this.restAmonut,2)
        }
        if (this.type === 2) {
          newMB.RecBank = round(this.restAmonut,2)
          newMB.RecTotal = round(this.restAmonut,2)
          newMB.ExpBank = 0
          newMB.ExpTotal = 0
        }
        items.push(newMB)
        this.selectedItems = [...items]
        this.$store.dispatch('setMainLedgerDetailItems', items)
        this.forceRerender()
      }
    },
    saveSelected() {
      if (this.selected) {

        // chek if is egible for selection
        let selectedAmount = 0
        let restAmount = this.type === 2 ? this.mainbook.RecTotal : this.mainbook.ExpTotal
        const newItems = []
        this.selected.map(sItem => {
          let amount = 0

          if (this.type === 1) {
            amount = sItem.credit ? parseNum(sItem.credit) : 0
          }
          if (this.type === 2) {
            amount = sItem.debit ? parseNum(sItem.debit) : 0
          }
          selectedAmount += amount
          if (round(selectedAmount,2) <= round(restAmount,2)) {
            let newMB = {}
            if (this.mainbook) {
              newMB = {...this.mainbook}
            }
            newMB.BookID = parseInt(sItem.docID)
            if (!newMB.BookDate){
              newMB.BookDate = sItem.datum2
            }
            newMB.InvoiceDate = null
            newMB.InvoicePaymentDeadlineDate = null
            newMB.FreeB1 = true
            
            if (this.type === 1) {
              newMB.RecBank = 0
              newMB.RecTotal = 0

              newMB.ExpBank = amount
              newMB.ExpTotal = amount
            }
            if (this.type === 2) {
              newMB.RecBank = amount
              newMB.RecTotal = amount
              newMB.ExpBank = 0
              newMB.ExpTotal = 0
            }
            newItems.push(newMB)
          }
          if (round(selectedAmount,2) > round(restAmount,2)) {
            if (round(selectedAmount - amount) <= round(restAmount,2)) {
              const haveRest = round(restAmount,2) - round((selectedAmount - amount),2)
              let newMB = {}
              if (this.mainbook) {
                newMB = {...this.mainbook}
              }
              newMB.BookID = parseInt(sItem.docID)
              if (!newMB.BookDate){
                newMB.BookDate = sItem.datum2
              }
              newMB.FreeB1 = true
              newMB.InvoiceDate = null
              newMB.InvoicePaymentDeadlineDate = null
              if (this.type === 1) {
                newMB.RecBank = 0
                newMB.RecTotal = 0

                newMB.ExpBank = round(haveRest,2)
                newMB.ExpTotal = round(haveRest,2)
              }
              if (this.type === 2) {
                newMB.RecBank = round(haveRest,2)
                newMB.RecTotal = round(haveRest,2)
                newMB.ExpBank = 0
                newMB.ExpTotal = 0
              }
              newItems.push(newMB)
            }
          }

        })
        if (newItems) {
          this.selectedItems = [...newItems]
          this.$store.dispatch('setMainLedgerDetailItems', newItems)
          this.forceRerender()
        }
        
      }
    }
  },
  watch: {
    'selected' () {
      console.log('this.selected', this.selected)
      this.saveSelected()

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>