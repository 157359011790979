<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs12 sm12 m12 lg12 ex12>

        <div id="printArea">

                <v-layout row style="border-bottom:solid 1px;">
                    <v-flex xs5>
                        <h4><b><i>{{comp.CompanyName}}</i></b></h4>
                        {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}   <br />                
                        {{comp.CompanyPostcode}} {{comp.CompanyCity}}<br />
                         OIB: {{comp.CompanyVATID}}
                    </v-flex>
                    <v-flex xs3>
                      <span v-if="this.$store.state.language == 'EN'">
                      <h2>Mainbook Card </h2>
                      </span>
                      <span v-if="this.$store.state.language == 'HR'">
                      <h2>Kartica</h2>
                      </span>
                      <p class="text-md-left">
                          {{par.partnerName}}<br />
                          OIB: {{par.partnerVATID}}<br />
                      </p>
                  </v-flex>
                    <v-flex xs 4>
                      <p class="text-md-left">
                          Datum izvještaja: {{PrintDate}}<br />
                          Telefon: {{comp.CompanyPhones}}<br />
                          email: {{comp.Companyemail}}<br />
                      </p>
                    </v-flex>
              </v-layout>

            <v-layout row style="border-bottom:solid 1px; border-top:solid 1px;">

                    <v-flex xs1 class="text-left" >
                     Rb.  
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Datum
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Dokument
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Duguje
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Potrazuje
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Saldo
                    </v-flex>
                    <v-flex xs3 class="text-center">
                       Opis
                    </v-flex>
                    <v-flex xs2 class="text-left">
                      ...
                    </v-flex>
    
              </v-layout>

                <v-layout row v-for="item in items" :key="item.id">
                    <v-flex xs1 class="text-left" >
                      {{ item.itBookName }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ localDate(item.date) }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ parseInt(item.docID) }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(parseFloat(item.debit)) }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(parseFloat(item.credit)) }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(item.CreditDebit) }}
                    </v-flex>
                    <v-flex xs3 class="text-center" style="font-size:12px;">
                      {{ item.desc1 }}
                    </v-flex>
                    <v-flex xs2 class="text-left">
                      {{ item.desc2 }}
                    </v-flex>

            </v-layout>
            <v-layout row style="border-top:solid 1px;">
              <v-flex xs3 class="text-xs-right">

              </v-flex>

              <v-flex xs1 class="text-right">
                {{ formatPrice(Total1) }}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(Total2) }}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(Total5) }}
              </v-flex>

            </v-layout>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ledgerEN from './mainbookDescEn'
import ledgerHR from './mainbookDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import MainBookService from '@/services/MainBookService'
import MainLedgerService from '@/services/MainLedgerService'
import PartnerService from '@/services/PartnerService'
import {sortByDate} from '@/snippets/allSnippets'
import dayjs from 'dayjs'

export default {
  name: 'mainbookcard2',
  props: ['startDate', 'endDate', 'partnerId', 'MLType', 'partnerVATID'],
  data () {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      comp: {},
      error: null,
      localError: false,
      newUser: {},
      size: 'sm',
      havePath: '',
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      pItems: [],
      sItems: [],
      items: [],
      mainbookAna:{},
      mledger: [],
      mbook: [],
      par: {}
    }
  },
  components: {
  },
  async mounted () {
    try {
      if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
      PartnerService.show(this.partnerVATID)
        .then(res => {
          if (res.data.partner) {
            const pr = res.data.partner
            this.par.partnerId = pr.id ? pr.id : null
            this.par.partnerName = pr.partnerName ? pr.partnerName : null
            this.par.partnerVATID = pr.partnerVATID ? pr.partnerVATID : null            
          }
        })
      
      this.mainbookAna.partnerId = this.partnerId ? this.partnerId : null
      this.mainbookAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
      this.mainbookAna.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
      this.mainbookAna.docFromDate = this.startDate ? dayjs(this.startDate).format('YYYY-MM-DD') : null
      this.mainbookAna.docToDate = this.endDate ? dayjs(this.endDate).format('YYYY-MM-DD') : null
      this.mainbookAna.MLType = this.MLType ? this.MLType : null
      this.mainbookAna.fromDate = this.startDate ? dayjs(this.startDate).format('YYYY-MM-DD') : null
      this.mainbookAna.toDate = this.endDate ? dayjs(this.endDate).format('YYYY-MM-DD') : null
      const mbook = (await MainBookService.choose(this.mainbookAna)).data.mainbooks
      const mledger = (await MainLedgerService.books(this.mainbookAna)).data.documents

      this.pItems = []
      if (mbook && mbook.length > 0) {
        mbook.map(book => {
          const object1 = {
            date: book.BookDate,
            debit: book.InvoiceAmount,
            credit: 0,
            desc1: book.BookDesc1,
            desc2: book.BookDesc2,
            docID: book.BookID
          }
          this.pItems.push(object1)
        })
      }

      if (mledger && mledger.length > 0) {
        mledger.map(book => {
          const object1 = {
            date: book.BookingDate,
            debit: 0,
            credit: book.InvoiceAmount,
            desc1: "",
            desc2: "",
            docID: book.BookID
          }
          this.pItems.push(object1)
        })
      }

      this.sItems = sortByDate(this.pItems, 'date')

      this.Total1 = 0
      this.Total2 = 0
      this.Total3 = 0
      this.Total4 = 0
      let itLine = 0
      if (this.sItems) {
        this.sItems.map(item => {
          itLine += 1
          this.Total1 += parseFloat(item.debit)
          this.Total2 += parseFloat(item.credit)
          //this.Total3 += parseFloat(item.DebitForegin)
          //this.Total4 += parseFloat(item.CreditForegin)
          const object2 = {
            itBookName: itLine + '. ',
            CreditDebit: this.Total1 - this.Total2
          }
          const object3 = {...item, ...object2}
          this.items.push(object3)
        })
      }
      this.Total5 = this.Total1 - this.Total2
      // eslint-disable-next-line
      console.log('items ', this.items)

    } catch (err) {
      // eslint-disable-next-line
      console.log('Error ', err)
    }
    

  },
  computed: {

  },
  methods: {
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    print () {
      // opens the "print dialog" of your browser to print the element
      // var bdhtml = window.document.body.innerHTML
      // window.document.body.innerHTML = document.getElementById('printArea').innerHTML
      // window.print()
      // window.document.body.innerHTML = bdhtml
      this.$htmlToPaper('printArea')
    },

    close () {
      this.show = false
    }
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
