<template>
  <v-layout row>
      <v-flex xs4>
        {{label}}
      </v-flex>
      <v-flex xs8>
         <v-icon >{{info ? 'mdi-check' : ''}} </v-icon>
      </v-flex>
    </v-layout>
</template>

<script>
export default {
  props: ['label', 'info']
}
</script>

<style>

</style>