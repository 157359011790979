<template>
  <div>

  </div>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './salarieDescEn'
import langHr from './salarieDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'
// import { parseNum } from '@/snippets/allSnippets'

export default {
  name: 'salarieprinttotal',
  props: [],
  data () {
    return {
      msg: '',
      documentName: '',
      documentName2: '',
      document: {},
      havePath: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locDocument: {},
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      comp: {},
      mainQuery: {},
      ana: {},
      totalIz: 0,
      totalNa: 0
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = {...langEn}
      this.langC = {...commEn}
    }
    if (this.$store.state.language === 'HR') {
      this.lang = {...langHr}
      this.langC = {...commHr}
    }

  },
  methods: {
    async print () {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.BusinessYear = this.$store.state.businessYear
      }
      this.printDoc()
    },

    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    printDoc () {
      this.comp = this.$store.state.companyActiv
      this.ana = this.$store.state.printObj ? this.$store.state.printObj : {}
      this.totalIz = 0
      this.totalNa = 0
      console.log('this.ana ', this.ana)
      if (this.ana.head1.TotalJson) {
        if (this.ana.head1.TotalJson.allFrom){
          this.ana.head1.TotalJson.allFrom.map(aFrom => {
            this.totalIz += aFrom.FinFrom_Amount ? aFrom.FinFrom_Amount : 0
          })
        }
        if (this.ana.head1.TotalJson.allTo){
          this.ana.head1.TotalJson.allTo.map(aTo => {
            this.totalNa += aTo.FinTo_Amount ? aTo.FinTo_Amount : 0
          })
        }
      }

      this.initDocument()
      this.addHead()
      this.addLine()
      this.addData()
      this.addLine()
      this.addEmptyRow()
      this.addItemsHeaderFrom()
      this.addItemsFrom()

      this.addEmptyRow()
      this.addItemsHeaderTax()
      this.addItemsTax()

      this.addEmptyRow()
      this.addItemsHeaderAdd()
      this.addItemsAdd()

      this.addEmptyRow()
      this.addItemsHeaderTo()
      this.addItemsTo()
  
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 40 ]
      this.content = []
    },
  
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },

    addHead() {
      const docPart = {
          columns: [
            {
              // % width
              width: '35%',
              text: [
                { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.Companyemail ? 'email:' +  this.comp.Companyemail + ' \n' : ' ') , fontSize: 8 },
                { text: 'OIB:' + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '45%',
              text: [
                { text: 'Rekapitulacija plaće \n', fontSize: 18, bold: true },
                { text: 'za ' + (this.ana.Month ? this.ana.Month + '/' : '') + (this.ana.Year ? this.ana.Year + ', ('  : '') + (this.ana.Order ? this.ana.Order + ')' : '') , fontSize: 12, bold: true },
                { text: (this.ana.head3 ? this.ana.head3 + ' \n' : ''), fontSize: 8, bold: true },
                { text: (this.ana.head4 ? this.ana.head4 + ' \n' : ''), fontSize: 8, bold: true },
                { text: (this.ana.head5 ? this.ana.head5 + ' \n' : ''), fontSize: 8, bold: true },
              ]
            },
            {
              // % width
              width: '20%',
              text: [                 
                { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },
    addData() {
      const aadFTax = this.ana.head1.TotalJson.addTaxReturn && this.ana.head1.TotalJson.addTaxReturn > 0 ? this.ana.head1.TotalJson.addTaxReturn : 0
 
      const docPart = {
          columns: [
            {
              // % width
              width: '45%',
              text: [
                { text: 'Ukupno BRUTO plaća:' + ' \n', fontSize: 10,  },
                { text: 'Ukupno doprinosi IZ plaća:' + ' \n', fontSize: 10,  },
                { text: 'Ukupno dohodak:' + ' \n', fontSize: 10,  },
                { text: 'Ukupno porez i prirez:' + ' \n', fontSize: 10,  },
                { text: (aadFTax ? 'Povrat poreza - godišnji iznos \n' : null ), fontSize:10},
                { text: 'Ukupno NETO plaća:' + ' \n', fontSize: 10,  },
                { text: 'Ukupno naknade i bolovanja:' + ' \n', fontSize: 10,  },
                { text: 'Ukupno ISPLATA:' + ' \n', fontSize: 10,  },
                { text: 'Ukupno doprinosi NA plaću:' + ' \n', fontSize: 10,  },
                { text: 'SVEUKUPNO (bruto plaća + naknade + doprinosi na):' + ' \n', fontSize: 10,  },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '15%',
              text: [
                { text: ' \n', fontSize: 10, alignment: 'right' },
              ]
            },
            {
              // % width
              width: '15%',
              text: [
                { text: ' \n', fontSize: 10, alignment: 'right' },                 
              ]   
            },
            {
              // % width
              width: '15%',
              text: [                 
                { text: this.formatPrice(this.ana.head1.TotalJson.FinBruto2) + ' \n', fontSize:10, alignment: 'right',},
                { text: this.formatPrice(this.totalIz) + ' \n', fontSize:10, alignment: 'right',},
                { text: this.formatPrice(this.ana.head1.TotalJson.FinNeto1ls) + ' \n', fontSize:10, alignment: 'right',},
                { text: this.formatPrice(this.ana.head1.TotalJson.FinTaxTotal) + ' \n', fontSize:10, alignment: 'right',},
                { text: (aadFTax ? this.formatPrice(aadFTax) + ' \n' : null ), fontSize:10, alignment: 'right',},
                { text: this.formatPrice(this.ana.head1.TotalJson.FinNeto2) + ' \n', fontSize:10, alignment: 'right',},
                { text: this.formatPrice(this.ana.head1.TotalJson.FinAddTotal) + ' \n', fontSize:10, alignment: 'right',},
                { text: this.formatPrice(this.ana.head1.TotalJson.FinNeto3) + ' \n', fontSize:10, alignment: 'right',},
                { text: this.formatPrice(this.totalNa) + ' \n', fontSize:10, alignment: 'right',},
                { text: this.formatPrice(this.ana.head1.TotalJson.FinBruto2 + this.ana.head1.TotalJson.FinAddTotal + this.totalNa + aadFTax) + ' \n', fontSize:10, alignment: 'right',},
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },

    addItemsHeaderFrom () {
      const docPart = {
          columns: [
            {
              width: '5%',
              text: 'Rbr' + '\n', fontSize: 8 
            },
            {
              width: '40%',
              text: 'Naziv doprinosa IZ' + '\n', fontSize: 8 
            },
            {
              width: '10%',
              text: '%:\n', fontSize: 8, alignment: 'right' 
            },
            {
              width: '10%',
              text: 'Iznos:\n', fontSize: 8, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart)
    },
    addItemsFrom() {
          this.order = 0
            if (this.ana.head1.TotalJson.allFrom) {
              this.ana.head1.TotalJson.allFrom.map(item => {
                this.order += 1
                const docPart = {
                columns: [
                    {
                      width: '5%',
                      fontSize: 8,
                      alignment: 'left',
                      text: this.order
                    },
                    {
                      width: '40%',
                      fontSize: 8,
                      alignment: 'left',
                      text: (item.descHR ? item.descHR : '')
                    },
                    {
                      width: '10%',
                      fontSize: 8,
                      alignment: 'right',
                      text: this.formatPrice(item.per)
                    },
                    {
                      width: '10%',
                      fontSize: 8,
                      alignment: 'right',
                      text: this.formatPrice(item.FinFrom_Amount)
                    },
                  ]
                }
              this.content.push(docPart)
            })
          }
    },
    addItemsHeaderTo () {
      const docPart = {
          columns: [
            {
              width: '5%',
              text: 'Rbr' + '\n', fontSize: 8 
            },
            {
              width: '40%',
              text: 'Naziv doprinosa NA' + '\n', fontSize: 8 
            },
            {
              width: '10%',
              text: '%:\n', fontSize: 8, alignment: 'right' 
            },
            {
              width: '10%',
              text: 'Iznos:\n', fontSize: 8, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart)
    },
    addItemsTo() {
          this.order = 0
            if (this.ana.head1.TotalJson.allTo) {
              this.ana.head1.TotalJson.allTo.map(item => {
                this.order += 1
                const docPart = {
                columns: [
                    {
                      width: '5%',
                      fontSize: 8,
                      alignment: 'left',
                      text: this.order
                    },
                    {
                      width: '40%',
                      fontSize: 8,
                      alignment: 'left',
                      text: (item.descHR ? item.descHR : '')
                    },
                    {
                      width: '10%',
                      fontSize: 8,
                      alignment: 'right',
                      text: this.formatPrice(item.per)
                    },
                    {
                      width: '10%',
                      fontSize: 8,
                      alignment: 'right',
                      text: this.formatPrice(item.FinTo_Amount)
                    },
                  ]
                }
              this.content.push(docPart)
            })
          }
    },
    addItemsHeaderTax () {
      const docPart = {
          columns: [
            {
              width: '5%',
              text: 'Rbr' + '\n', fontSize: 8 
            },
            {
              width: '40%',
              text: 'Grad/općina' + '\n', fontSize: 8 
            },
            {
              width: '10%',
              text: 'Oznaka:\n', fontSize: 8, alignment: 'right' 
            },
            {
              width: '10%',
              text: 'Iznos:\n', fontSize: 8, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart)
    },
    addItemsTax() {
          this.order = 0
            if (this.ana.head1.TotalJson.allTax) {
              this.ana.head1.TotalJson.allTax.map(item => {
                this.order += 1
                const docPart = {
                columns: [
                    {
                      width: '5%',
                      fontSize: 8,
                      alignment: 'left',
                      text: this.order
                    },
                    {
                      width: '40%',
                      fontSize: 8,
                      alignment: 'left',
                      text: (item.FinTax_CityName ? item.FinTax_CityName : '')
                    },
                    {
                      width: '10%',
                      fontSize: 8,
                      alignment: 'right',
                      text: (item.FinTax_CityCode)
                    },
                    {
                      width: '10%',
                      fontSize: 8,
                      alignment: 'right',
                      text: this.formatPrice(item.FinTaxTotal)
                    },
                  ]
                }
              this.content.push(docPart)
            })
          }
    },
    addItemsHeaderAdd () {
      const docPart = {
          columns: [
            {
              width: '5%',
              text: 'Rbr' + '\n', fontSize: 8 
            },
            {
              width: '40%',
              text: 'Naziv neoporezive naknade' + '\n', fontSize: 8 
            },
            {
              width: '10%',
              text: 'Oznaka:\n', fontSize: 8, alignment: 'right' 
            },
            {
              width: '10%',
              text: 'Iznos:\n', fontSize: 8, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart)
    },
     addItemsAdd() {
          this.order = 0
            if (this.ana.head1.TotalJson.allAdd) {
              this.ana.head1.TotalJson.allAdd.map(item => {
                this.order += 1
                const docPart = {
                columns: [
                    {
                      width: '5%',
                      fontSize: 8,
                      alignment: 'left',
                      text: this.order
                    },
                    {
                      width: '40%',
                      fontSize: 8,
                      alignment: 'left',
                      text: (item.text ? item.text : '')
                    },
                    {
                      width: '10%',
                      fontSize: 8,
                      alignment: 'right',
                      text: parseInt(item.FinAdd_id)
                    },
                    {
                      width: '10%',
                      fontSize: 8,
                      alignment: 'right',
                      text: this.formatPrice(item.FinAdd_Amount)
                    },
                  ]
                }
              this.content.push(docPart)
            })
          }
    },

    addTotal () {
      const docPart = {
          columns: [
            {
              width: '5%',
              fontSize: 8,
              text: ''
            },
            {
              width: '32%',
              fontSize: 8,
              text: ''
            },
            {
              width: '9%',
              fontSize: 8,
              text: ' '
            },
            {
              width: '9%',
              fontSize: 8,
              text: ' '
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total1) + '\n', fontSize: 8
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total2) + '\n', fontSize: 8
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total3) + '\n', fontSize: 8
            }
          ]
        }
      this.content.push(docPart)
      
    },
    addTotal10 () {
      const docPart = {
          columns: [
            {
              width: '5%',
              fontSize: 8,
              text: ''
            },
            {
              width: '10%',
              fontSize: 8,
              text: ''
            },
            {
              width: '22%',
              fontSize: 8,
              text: ''
            },
            {
              width: '10%',
              fontSize: 8,
              text: ''
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total1) + '\n', fontSize: 8
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total2) + '\n', fontSize: 8
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total3) + '\n', fontSize: 8
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total10) + '\n', fontSize: 8
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total4) + '\n', fontSize: 8
            }
          ]
        }
      this.content.push(docPart)
      
    },

    addSignature () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                 // { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________  \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },

    addEmpty () {
      const docPart = {
        
          }
      this.content.push(docPart)
    },

  },
  computed: {
  },
  watch: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
