import Api from '@/services/Api'

export default {
  index(search) {
    return Api().get('users', {
      params: {
        search: search
      }
    })
  },
  show(userId) {
    return Api().get(`users/show/${userId}`)
  },
  post(newUser) {
    return Api().post('users', newUser)
  },
  put(user) {
    return Api().put(`users/${user.id}`, user)
  },
  passupdate(user) {
    return Api().put(`users/passupdate/${user.id}`, user)
  },
  delete(userId) {
    return Api().delete(`users/${userId}`)
  },
  login(loginUser) {
    return Api().post('users/login', loginUser)
  },
  choose(query) {
    return Api().get('users/choose', {
      params: query
    })
  },
  makeactiv(user) {
    return Api().get(`users/makeactiv/${user.id}`, user)
  }
}