const docEN = {
    id:'Nr',
    MainBook:' Main book',
    MainBook2:' Income-expense',
    partnerId : '',
    partnerName: 'Partner name',
    partnerNameShort: '',
    BookRecExp: 'Rec / Exp', // 1 Rec, 2 Exp
    BookTypeId : 'Book Type Id',
    BookTypeName: 'Book Name',
    BookId : 'Id',
    BookDate: 'Date',
    InvoiceDate: 'Invoice Date',
    InvoicePaymentDeadlineDate: 'Deadline',
    BookDesc1: 'Description 1',
    BookDesc2: 'Description 2',
    Rec: 'Receivable',
    Rec2: 'Income',
    RecCash: 'Cash',
    RecBank: 'Bank',
    RecFree: 'Free',
    RecVAT: 'VAT',
    RecSpecial: 'Special',
    RecTotal: 'Total',
    RecTotal2: 'Total income',
    Exp:'Expendable',
    Exp2:'Expense',
    ExpCash: 'Cash',
    ExpBank: 'Bank',
    ExpFree: 'Free',
    ExpVAT: 'VAT',
    ExpSpecial: 'Special',
    ExpTotal: 'Total',
    ExpTotal2: 'Total expense',
    documentName: 'Document Name',
    documentIdGeneral: '',
    BookID: '',
    BookTypeID: '',
    BookName: '',
    BookIdGeneral: '',
    HomeCurrency: 'Home Cur.',
    HomeCurrencyPrimary: 'Prim.Curr',
    ForeignCurrency: 'For.Curr',
    ForeignCurrencyPrimary: '',
    ExRate: 'Ex.rate',
    VAT3Base: 'VAT 5% Base',
    VAT2Base: 'VAT 13% Base',
    VAT1Base: 'VAT 25% Base',
    VAT3Amount: '5% VAT amount',
    VAT2Amount: '13% VAT amount',
    VAT1Amount: '25% VAT amount',
    VAT3CanUse: '5% can use',
    VAT3CanTUse: '5% cant use',
    VAT2CanUse: '13% can use',
    VAT2CanTUse: '13% cant use',
    VAT1CanUse: '25% can use',
    VAT1CanTUse: '25% cant use',
    VAT4Per: 'Interstate no VAT',
    VAT4Base: 'Sales in other count.',
    VAT4Amount: 'Sales good in EU',
    VAT4PerCanUse: 'Sales services in EU',
    VAT4CanUse: 'Deliveris person no adress in Croatia ',
    VAT4CanTUse: 'good in other count',
    VAT5Per: 'Deliveries NPS in EU',
    VAT5Base: 'Local',
    VAT5Amount: 'Export',
    VAT5PerCanUse: 'Other liberation',
    AmountNetto: 'Netto Amount',
    AmountVAT: 'VAT',
    AmountBrutto: 'Brutto Amount',
    AddReceive: 'Add receive',
    AddExpense: 'Add expense',
    AddReceive2: 'Add receive',
    AddExpense2: 'Add expense',
    DoCount: 'Ana.period',
    AddInvoice: 'Invoice',
    AddRecivable: 'Recivable',
    CardInvoice: 'Card Inv',
    CardOpenInvoice: 'Card Open Inv',
    CardRecivable: 'Card Rec',
    CardOpenRecivable: 'Card Rec',
    DeleteReceive: 'Delete Income',
    DeleteReceive2: 'Delete Income',
    DeleteExpense: 'Delete Expense',
    DeleteExpense2: 'Delete Expense',
    PartnerSave: 'Save Partner',
    BookRec: 'VAT - Rec',
    BookExp: 'VAT - Exp',
    BookRec2: 'List Inv.',
    BookExp2: 'List Rec.',
    Book: 'KPI',
    Book2: 'Book',
    Book3: 'KPI Docs',
    Book4: 'KPI No Docs',
    BookInInvoices: 'Book In Invoices',
    BookOutInvoices: 'Book Out Invoices',
    MainBookTypes1: 'Types',
    MainBookTypes: 'Main Book Types',
    MainBookType: 'Main Book Type',
    MainBookTypeName: 'Type Name',
    MainBookTypeRec: 'Book type Receivable',
    MainBookTypeExp: 'Book type Expendables',
    NewMainBookType: 'New Main Book Type',
    EditMainBookType: 'Edit Main Book Type',
    Partneremail: 'email',
    PartnerName: 'Partner Name',
    PartnerVATID: 'Partner VATID',
    showView: 'Lists',
    showBank: 'Bank statements',
    showBook: 'Ledger',
    showStartSupp: 'Start Supp.',
    showStartBuyers: 'Start Buy.',
    MainBookDocs1: 'Doc type',
    MainBookDocs: 'Main Book Docs',
    MainBookDoc: 'Main Book Doc',
    NewMainBookDoc: 'New Main Book Doc',
    MainBookDocID: "Doc ID",
    MainBookDocName: "Doc Name",
    MainBookDocVATID: "VAT ID",
    MainBookDocDesc: "Doc description",
    MainBookDocOpenDate: "Doc Open Date",
    MainBookDocClosedDate: "Doc Close Date",
    EditMainBookDoc: 'Edit Main Book Type',
    MainBookDocsEmpty:' Empty list',
    MainBookDocIDError:'Error. NO ID!!',
    MainBookDocOpenDateErr: "Doc Open Date Error!",
    MainBookDocClosedDateErr: "Doc Close Date Error!",
    MainBookDocIDErrorExist: 'Error!! Doc ID already exist.',
    CloseRec:'Connect with Inv',
    CloseExp:'Connecti with Inv',
    BookEmpty: 'No Data',
    checkPartnerByIban: 'Check by IBAN',
    partnerExist: 'Parter with this IBAN exist',
    partnerNOTexist:'IBAN is not commited to any partner.',
    partnerCardSupp: 'Card Supp.',
    partnerCardBuy: 'Card Buy.',
    partnerCardOpenSupp: 'Card Open Supp.',
    partnerCardOpenBuy: 'Card Open Buy.',
    useBuySupp: 'Buy/Supp.',
    hideZeros:'No zeros'
}

export default docEN