<template>
  <div class="documentprintpurchase">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import {parseNum, round} from '@/snippets/allSnippets'
import dayjs from 'dayjs'
export default {
  name: 'documentprintsales',
  data () {
    return {
      msg: '',
      documentName: '',
      documentName2: '',
      document: {},
      havePath: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locDocument: {},
      fields1: [ 'itemName', 'ItemCode', 'itemUnit', 'itemService', 'itemPrice', 'itemPcsNeutral', 'itemBaseAmount', 'itemDiscountPercentage1', 'itemBaseAmountNoVAT', 'itemAmountWithVAT' ],
      items1: [
      ],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      fields2: [ 'vat', 'base', 'amount' ],
      items2: [
        { vat: '5%', base: 100.00, amount: 5.00 },
        { vat: '13%', base: 200.00, amount: 26.00 },
        { vat: '25%', base: 400.00, amount: 100.00 }
      ],
      comp: {},
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        databaseDocID: 0
      },
      order: 0,
      loCdocumentDate: '',
      loCorderDate: '',
      loCdocumentInvoiceStartDate: '',
      loCdocumentInvoiceTime: '',
      loCdocumentInvoiceEndDate: '',
      loCdocumentConnDate: '',
      loCdeliveryDate: '',
      loCorderTime: '',
      loCdocumentConnTime: '',
      loCdeliveryTime: '',
      locDocumentId: '',
      output: null,
      documentNameLoc: '',
      docDefinition: null,
      profitLoss: 0,
      profitLossP: '',
      pP1: [],
      pP2: [],
      pP3: [],
      pP4: [],
      pP5: [],
      pP6: [],
      pP7: [],
      pP8: [],
      pP9: [],
      pP10: [],
      pD1: [],
      pD2: [],
      pD3: [],
      pD4: [],
      pD5: [],
      pD6: [],
      pD7: [],
      pD8: [],
      pD9: [],
      pD10: [],
      pT1: [],
      pT2: [],
      pT3: [],
      pT4: [],
      pT5: [],
      pT6: [],
      pT7: [],
      pT8: [],
      pT9: [],
      pT10: [],
      pC1: [],
      pC2: [],
      pC3: [],
      pC4: [],
      pC5: [],
      pC6: [],
      pC7: [],
      pC8: [],
      pC9: [],
      pC10: [],
      pC11: [],
      pC12: [],
      pC1tot: 0,
      pC2tot: 0,
      pC3tot: 0,
      pC4tot: 0,
      pC5tot: 0,
      pC6tot: 0,
      pC7tot: 0,
      pC8tot: 0,
      pC9tot: 0,
      pC10tot: 0,
      pC11tot: 0,
      pcE1tot: 0,
      pcE2tot: 0,
      pcE3tot: 0,
      pD1tot: 0,
      pD2tot: 0,
      pD3tot: 0,
      pD4tot: 0,
      pD5tot: 0,
      pD6tot: 0,
      pD7tot: 0,
      pD8tot: 0,
      pD9tot: 0,
      pD10tot: 0,
      pD11tot: 0,
      pTtt1: 0,
      pTtt2: 0,
      pTtt3: 0,
      pTtt4: 0,
      pTtt5: 0,
      pTtt6: 0,
      pTtt7: 0,
      pTtt8: 0,
      pTtt9: 0,
      pTtt10: 0,
      pTtt11: 0,
      pSum1: 0,
      pSum2: 0,
      pSum3: 0,
      pSum4: 0,
      pSum5: 0,
      pSupp1: 0,
      pSupp2: 0,
      pSupp3: 0,
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
      docDefinition2: {},
      content2: [],
      logoType: 0,
      printSmall: false, 
      printNoPrice: false,
      oldSum1: 0,
      oldSum2: 0,
      oldSum3: 0,
      oldSum4: 0,
      newSum1: 0,
      newSum2: 0,
      newSum3: 0,
      newSum4: 0,
      diffSum1: 0,
      diffSum2: 0,
      diffSum3: 0,
      diffSum4: 0,
      diffSum5: 0,
      exportDoc: false
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.documentActivHead) {
      this.document = this.$store.state.documentActivHead
      this.documentNameLoc = this.$store.state.documentActivLoc
    }
  },
  methods: {
   print () {
      if (this.$store.state.printSmall === true) {
        this.printSmall = true 
      }
      if (this.$store.state.printNoPrice === true) {
        this.printNoPrice = true
      }
      this.exportDoc = this.$store.state.documentActivHead.exportDoc
      this.prepareData()
      if (this.printSmall === true) {
        this.printSmallDoc()
      } else {
        if (this.document.documentName === 'PriceChange') {
          this.printPriceChange()
        } else {
          this.printTestDoc()
        }
      }
      //this.print2()
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    prepareDataNormal(){
      if (this.$store.state.documentActivItems) {
        // this.items = (await DocumentDetailService.index(this.mainQuery)).data.documentDetails
        this.items = this.$store.state.documentActivItems
      }
      // console.log(' items imam ili ne', this.items)
      this.pC1 = []
      this.pC2 = []
      this.pC3 = []
      this.pC4 = []
      this.pC5 = []
      this.pC6 = []
      this.pC7 = []
      this.pC8 = []
      this.pC9 = []
      this.pC10 = []
      this.pC12 = []
      this.pC1tot = 0
      this.pC2tot = 0
      this.pC3tot = 0
      this.pC4tot = 0
      this.pC5tot = 0
      this.pC6tot = 0
      this.pC7tot = 0
      this.pC8tot = 0
      this.pC9tot = 0
      this.pC10tot = 0
      this.pC11tot = 0
      if (this.items) {
        this.items.map(item => {
          if (parseFloat(item.itemAmountWithVAT) !== 0) {
            this.order += 1
            this.pTtt3 = this.formatPrice(item.itemPrice)
            if (this.$store.state.documentSide === 2) {
              this.pTtt4 = this.formatPrice(item.ItemPcsMinus)
            }
            if (this.$store.state.documentSide === 3) {
              this.pTtt4 = this.formatPrice(item.itemPcsNeutral)
            }
            this.pTtt5 = this.formatPrice(item.itemBaseAmount)
            this.pTtt6 = this.formatPrice(item.itemDiscountPercentage1)
            this.pTtt7 = this.formatPrice(item.itemBaseAmountNoVAT)
            this.pTtt8 = this.formatPrice(item.itemVAT1Percentage)
            this.pTtt9 = this.formatPrice(item.itemAmountWithVAT)
            this.pC1.push({ text: this.order + '\n', fontSize: 7 })
            this.pC12.push({ text: item.itemID +'\n', fontSize: 7 })
            this.pC2.push({ text: item.itemName + '\n', fontSize: 8 })
            this.pC3.push({ text: this.pTtt3 + '\n', fontSize: 8, alignment: 'right' })
            this.pC4.push({ text: this.pTtt4 + '\n', fontSize: 8, alignment: 'right' })
            this.pC5.push({ text: this.pTtt5 + '\n', fontSize: 8, alignment: 'right' })
            this.pC6.push({ text: this.pTtt6 + '\n', fontSize: 8, alignment: 'right' })
            this.pC7.push({ text: this.pTtt7 + '\n', fontSize: 8, alignment: 'right' })
            this.pC8.push({ text: this.pTtt8 + '\n', fontSize: 8, alignment: 'right' })
            this.pC9.push({ text: this.pTtt9 + '\n', fontSize: 8, alignment: 'right' })
            this.pC10.push({ text: item.itemUnit + '\n', fontSize: 8, alignment: 'left' })
            // this.pC1.push({ text: this.order + '\n', fontSize: 8 })
            // this.pC2.push({ text: item.itemName + '\n', fontSize: 8 })
            // this.pC3.push({ text: item.itemPrice + '\n', fontSize: 8, alignment: 'right' })
            // this.pC4.push({ text: item.itemPcsNeutral + '\n', fontSize: 8, alignment: 'right' })
            // this.pC5.push({ text: item.itemBaseAmount + '\n', fontSize: 8, alignment: 'right' })
            // this.pC6.push({ text: item.itemDiscountPercentage1 + '\n', fontSize: 8, alignment: 'right' })
            // this.pC7.push({ text: item.itemBaseAmountNoVAT + '\n', fontSize: 8, alignment: 'right' })
            // this.pC8.push({ text: item.itemVAT1Percentage + '\n', fontSize: 8, alignment: 'right' })
            // this.pC9.push({ text: item.itemAmountWithVAT + '\n', fontSize: 8, alignment: 'right' })
            // this.pC10.push({ text: item.itemUnit + '\n', fontSize: 8, alignment: 'left' })
          }
          if (item.itemDescription) {
            this.order += 1
            this.pC1.push({ text: '\n', fontSize: 8 })
            this.pC2.push({ text: item.itemDescription + '\n', fontSize: 8 })
            this.pC3.push({ text: '\n', fontSize: 8, alignment: 'right' })
            this.pC4.push({ text: '\n', fontSize: 8, alignment: 'right' })
            this.pC5.push({ text: '\n', fontSize: 8, alignment: 'right' })
            this.pC6.push({ text: '\n', fontSize: 8, alignment: 'right' })
            this.pC7.push({ text: '\n', fontSize: 8, alignment: 'right' })
            this.pC8.push({ text: '\n', fontSize: 8, alignment: 'right' })
            this.pC9.push({ text: '\n', fontSize: 8, alignment: 'right' })
          }
          // console.log(' ovo imam za stavku ', item)
        })
      }
    },
    prepareDataPlus(){
      if (this.$store.state.documentActivItems) {
        // this.items = (await DocumentDetailService.index(this.mainQuery)).data.documentDetails
        this.items = this.$store.state.documentActivItems
      }
      // console.log(' items imam ili ne', this.items)
      this.pC1 = []
      this.pC2 = []
      this.pC3 = []
      this.pC4 = []
      this.pC5 = []
      this.pC6 = []
      this.pC7 = []
      this.pC8 = []
      this.pC9 = []
      this.pC10 = []
      this.pC11 = []
      this.pC12 = []
      this.pC1tot = 0
      this.pC2tot = 0
      this.pC3tot = 0
      this.pC4tot = 0
      this.pC5tot = 0
      this.pC6tot = 0
      this.pC7tot = 0
      this.pC8tot = 0
      this.pC9tot = 0
      this.pC10tot = 0
      this.pC11tot = 0
      this.pD1tot = 0
      this.pD2tot = 0
      this.pD3tot = 0
      this.pD4tot = 0
      this.pD5tot = 0
      this.pD6tot = 0
      this.pD7tot = 0
      this.pD8tot = 0
      this.pD9tot = 0
      this.pD10tot = 0
      this.pD11tot = 0
      if (this.items) {
        this.items.map(item => {
          if (parseFloat(item.itemAmountWithVAT) !== 0) {
            this.order += 1
            this.pTtt3 = this.formatPrice(item.itemPrice)
            if (this.$store.state.documentSide === 2) {
              this.pTtt4 = this.formatPrice(item.ItemPcsMinus)
            }
            if (this.$store.state.documentSide === 3) {
              this.pTtt4 = this.formatPrice(item.itemPcsNeutral)
            }
            this.pTtt5 = this.formatPrice(item.itemBaseAmount)
            this.pTtt6 = this.formatPrice(item.itemDiscountPercentage1)
            this.pTtt7 = this.formatPrice(item.itemBaseAmountNoVAT)
            this.pTtt8 = this.formatPrice(item.itemVAT1Percentage)
            this.pTtt9 = this.formatPrice(item.itemAmountWithVAT)
            const supp1 = this.formatPrice(item.itemSuppBasePrice)
            const supp12 = this.formatPrice(item.itemSuppBasePriceAmount)
            const supp2 = this.formatPrice(item.itemSuppDiscount)
            const supp22 = this.formatPrice(item.itemSuppDiscountAmount)
            const supp3 = this.formatPrice(item.itemSuppPrice)
            const supp32 = this.formatPrice(item.itemSuppPriceAmount)
            const supp4 = this.formatPrice(item.itemMargin)
            const supp42 = this.formatPrice(item.itemMarginAmount)
            const supp5 = this.formatPrice(item.itemPrice)
            const supp52 = this.formatPrice(item.itemBaseAmount)
            const supp6 = this.formatPrice(item.itemVAT1Percentage)
            const supp62 = this.formatPrice(item.itemVAT1Amount)
            const supp7 = this.formatPrice(item.itemDirectDuty1)
            const supp72 = this.formatPrice(item.itemDirectDuty1Amount)
            const supp8 = this.formatPrice(item.itemPriceWithVAT)
            const supp82 = this.formatPrice(item.itemAmountWithVAT)
            if (item.itemSuppPriceAmount) {
              this.pC6tot += parseNum(item.itemSuppPriceAmount)
            }
            if (item.itemMarginAmount) {
              this.pC7tot += parseNum(item.itemMarginAmount)
            }
            if (item.itemBaseAmount) {
              this.pC8tot += parseNum(item.itemBaseAmount)
            }
            if (item.itemVAT1Amount) {
              this.pC9tot += parseNum(item.itemVAT1Amount)
            }
            if (item.itemDirectDuty1Amount) {
              this.pC10tot += parseNum(item.itemDirectDuty1Amount)
            }
            if (item.itemAmountWithVAT) {
              this.pC11tot += parseNum(item.itemAmountWithVAT)
            }
            this.pC1.push({ text: this.order + '\n', fontSize: 7 })
            this.pC12.push({ text: item.itemID + '\n', fontSize: 7 })
            this.pC2.push({ text: item.itemName + '\n' + item.itemID + '\n', fontSize: 8 })
            this.pC3.push({ text: item.itemPcsPlus + '\n' + item.itemUnit + '\n', fontSize: 8, alignment: 'right' })
            this.pC4.push({ text: supp1 + '\n' + supp12 + '\n', fontSize: 8, alignment: 'right' })
            this.pC5.push({ text: supp2 + '\n' + supp22 + '\n', fontSize: 8, alignment: 'right' })
            this.pC6.push({ text: supp3 + '\n' + supp32 + '\n', fontSize: 8, alignment: 'right' })
            this.pC7.push({ text: supp4 + '\n' + supp42 + '\n', fontSize: 8, alignment: 'right' })
            this.pC8.push({ text: supp5 + '\n' + supp52 + '\n', fontSize: 8, alignment: 'right' })
            this.pC9.push({ text: supp6 + '\n' + supp62 + '\n', fontSize: 8, alignment: 'right' })
            this.pC10.push({ text: supp7 + '\n' + supp72 + '\n', fontSize: 8, alignment: 'right' })
            this.pC11.push({ text: supp8 + '\n' + supp82 + '\n', fontSize: 8, alignment: 'right' })
            // this.pC1.push({ text: this.order + '\n', fontSize: 8 })
            // this.pC2.push({ text: item.itemName + '\n', fontSize: 8 })
            // this.pC3.push({ text: item.itemPrice + '\n', fontSize: 8, alignment: 'right' })
            // this.pC4.push({ text: item.itemPcsNeutral + '\n', fontSize: 8, alignment: 'right' })
            // this.pC5.push({ text: item.itemBaseAmount + '\n', fontSize: 8, alignment: 'right' })
            // this.pC6.push({ text: item.itemDiscountPercentage1 + '\n', fontSize: 8, alignment: 'right' })
            // this.pC7.push({ text: item.itemBaseAmountNoVAT + '\n', fontSize: 8, alignment: 'right' })
            // this.pC8.push({ text: item.itemVAT1Percentage + '\n', fontSize: 8, alignment: 'right' })
            // this.pC9.push({ text: item.itemAmountWithVAT + '\n', fontSize: 8, alignment: 'right' })
            // this.pC10.push({ text: item.itemUnit + '\n', fontSize: 8, alignment: 'left' })
          }
          if (item.itemDescription) {
            this.order += 1
            this.pC1.push({ text: '\n', fontSize: 8 })
            this.pC2.push({ text: item.itemDescription + '\n', fontSize: 8 })
            this.pC3.push({ text: '\n', fontSize: 8, alignment: 'right' })
            this.pC4.push({ text: '\n', fontSize: 8, alignment: 'right' })
            this.pC5.push({ text: '\n', fontSize: 8, alignment: 'right' })
            this.pC6.push({ text: '\n', fontSize: 8, alignment: 'right' })
            this.pC7.push({ text: '\n', fontSize: 8, alignment: 'right' })
            this.pC8.push({ text: '\n', fontSize: 8, alignment: 'right' })
            this.pC9.push({ text: '\n', fontSize: 8, alignment: 'right' })
            this.pC10.push({ text: '\n', fontSize: 8, alignment: 'right' })
            this.pC11.push({ text: '\n', fontSize: 8, alignment: 'right' })
          }
          // console.log(' ovo imam za stavku ', item)
        })
      }
      this.pD6tot = this.formatPrice(round(this.pC6tot,2))
      this.pD7tot = this.formatPrice(this.pC7tot)
      this.pD8tot = this.formatPrice(this.pC8tot)
      this.pD9tot = this.formatPrice(this.pC9tot)
      this.pD10tot = this.formatPrice(this.pC10tot)
      this.pD11tot = this.formatPrice(this.pC11tot)
      this.pSupp1 = this.formatPrice(this.document.supplierAmount)
      this.pSupp2 = this.formatPrice(this.document.supplierDiscount)
      this.pSupp3 = this.formatPrice(this.document.supplierNetoAmount)
    },
    prepareData() {
      if (this.$store.state.documentSide === 1) {
        this.prepareDataPlus()
      }
      if (this.$store.state.documentSide === 2) {
        this.prepareDataNormal()
      }
      if (this.$store.state.documentSide === 3) {
        this.prepareDataNormal()
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentid) {
        this.mainQuery.databaseDocID = this.$store.state.documentid
      }
      if (this.$store.state.documentActivHead) {
        this.document = this.$store.state.documentActivHead
      }
      if (this.$store.state.companyActiv.CompanyLogo1) {
        this.logoType = 1
      }
      if (this.$store.state.companyActiv.CompanyLogo2) {
        this.logoType = 2
      }
      if (this.$store.state.companyActiv.CompanyLogo3) {
        this.logoType = 3
      }
      this.documentName2 = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      this.pSum1 = this.formatPrice(this.document.documentTotalAmountNetto)
      this.pSum2 = this.formatPrice(this.document.documentTotalAmountVAT)
      this.pSum3 = this.formatPrice(this.document.documentTotalAmountBrutto)
      this.pSum4 = this.formatPrice(this.document.documentTotalAmountPayInAdvance)
      this.pSum5 = this.formatPrice(this.document.documentTotalAmountToPay)
      this.oldSum1 = this.formatPrice(this.document.documentTotalAmountNetto)
      this.oldSum2 = this.formatPrice(this.document.documentTotalAmountVAT)
      this.oldSum3 = this.formatPrice(this.document.documentTotalAmountDirectD1)
      this.oldSum4 = this.formatPrice(this.document.documentTotalAmountBrutto)
      this.newSum1 = this.formatPrice(this.document.newBaseAmount)
      this.newSum2 = this.formatPrice(this.document.newVAT1Amount)
      this.newSum3 = this.formatPrice(this.document.newDirectDuty1)
      this.newSum4 = this.formatPrice(this.document.newFullTotal)
      this.diffSum1 = this.formatPrice(this.document.newBaseAmount - this.document.documentTotalAmountNetto)
      this.diffSum2 = this.formatPrice(this.document.newVAT1Amount - this.document.documentTotalAmountVAT)
      this.diffSum4 = this.formatPrice(this.document.newFullTotal - this.document.documentTotalAmountBrutto)
      this.comp = this.$store.state.companyActiv
      this.order = 0
      // console.log(' client loc document ', this.locDocument)
      // console.log(' client loc items ', this.items)
      // this.document = this.locDocument.data.document
      this.locCreatedAt = dayjs(this.document.CreatedAt).format('DD.MM.YYYY HH:MM')
      this.locUpdatedAt = dayjs(this.document.UpdatedAt).format('DD.MM.YYYY HH:MM')
      this.loCdocumentInvoiceTime = dayjs(this.document.UpdatedAt).format('HH:MM')
      this.loCdocumentDate = this.document.documentDate ? dayjs(this.document.documentDate).format('DD.MM.YYYY') : ' '
      this.loCorderDate = this.document.orderDate ? dayjs(this.document.orderDate).format('DD.MM.YYYY') : ' '
      this.loCdocumentInvoiceStartDate = this.document.documentInvoiceStartDate ? dayjs(this.document.documentInvoiceStartDate).format('DD.MM.YYYY') : ' '
      this.loCdocumentInvoiceEndDate = this.document.documentInvoiceEndDate ? dayjs(this.document.documentInvoiceEndDate).format('DD.MM.YYYY') : ' '      
      this.loCdocumentConnDate =  this.document.documentConnDate ? dayjs(this.document.documentConnDate).format('DD.MM.YYYY') : ' '
      this.loCdeliveryDate = this.document.deliveryDate ? dayjs(this.document.deliveryDate).format('DD.MM.YYYY') : ' '
      this.loCorderTime = this.document.orderTime ? dayjs(this.document.orderTime).format('HH:MM') : ' '
      this.loCdocumentConnTime = this.document.documentConnTime ?  dayjs(this.document.documentConnTime).format('HH:MM') : ' '
      this.loCdeliveryTime = this.document.deliveryTime ? dayjs(this.document.deliveryTime).format('HH:MM') : ' '
      if (this.$store.state.documentActiv === 'InvoiceSales') {
        this.locDocumentId = this.document.documentId + '/' + this.$store.state.companyActiv.CompanyBusinessSpace + '/' + this.$store.state.companyActiv.CompanyBusinessDevice
      } else {
        this.locDocumentId = this.document.documentId
      }
      this.profitLoss = this.document.FreeF4 - this.document.FreeF1 - this.document.FreeF2 - this.document.FreeF3
      this.profitLossP = this.profitLoss.toFixed(2)

    },
    addItemsPriceChange () {
           this.order = 0
            if (this.items) {
              this.items.map(item => {
                if (parseFloat(item.itemAmountWithVAT) !== 0) {

                  this.order += 1
                  this.pTtt3 = this.formatPrice(item.itemPrice)
                  this.pTtt11 = this.formatPrice(item.itemPrice * (1 + (item.itemVAT1Percentage/100)))
                  this.pTtt4 = item.itemPcsNeutral ? this.formatPrice(item.itemPcsNeutral) : 0
                  const priceNoVAT =  this.formatPrice(item.itemPrice) + '\n' + this.formatPrice(item.newitemPrice)
                  const amountNoVAT =  this.formatPrice(item.itemBaseAmount) + '\n' + this.formatPrice(item.newitemBaseAmount)
                  const perVAT =  this.formatPrice(item.itemVAT1Percentage) + '\n' + this.formatPrice(item.newitemVAT1Percentage)
                  const amountVAT =  this.formatPrice(item.itemVAT1Amount) + '\n' + this.formatPrice(item.newitemVAT1Amount)
                  const pricewithVAT =  this.formatPrice(item.itemPriceWithVAT) + '\n' + this.formatPrice(item.newitemPriceWithVAT)
                  const amountwithVAT =  this.formatPrice(item.itemFullTotal) + '\n' + this.formatPrice(item.newitemFullTotal)
                  const docPart = {
                  columns: [
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'left',
                          text: this.order
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.itemID
                        },
                        {
                          width: '30%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.itemName
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.itemUnit
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'left',
                          text: this.pTtt4
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: [
                            { text: priceNoVAT, fontSize: 8, alignment: 'right'}
                          ]
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: [
                            { text: amountNoVAT, fontSize: 8, alignment: 'right'}
                          ]
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: [
                            { text: perVAT, fontSize: 8, alignment: 'right'}
                          ]
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: [
                            { text: amountVAT, fontSize: 8, alignment: 'right'}
                          ]
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: [
                            { text: pricewithVAT, fontSize: 8, alignment: 'right'}
                          ]
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: [
                            { text: amountwithVAT, fontSize: 8, alignment: 'right'}
                          ]
                        },
                      ]
                    }
                  this.content.push(docPart)
                }
              })
          }
    },
    printPriceChange() {
      this.initDocument()
      this.addHeaderNoLogo()
      this.addLine()
      this.addPartnerOnly()
      this.addLine()
      this.addItemsHeaderPriceChange()
      this.addLine()
      this.addItemsPriceChange()
      this.addLine()
      this.addTotalPriceChange()
      this.addSignature1()
      this.addSignature2()
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    printSmallDoc () {
      this.initDocumentSmall()
       if (this.logoType === 0) {
        this.addHeaderNoLogo()
      }
      if (this.logoType === 1) {
        this.addHeaderWithLogoLeftSmall()
      }
      if (this.logoType === 2) {
        this.addHeaderWithLogoRight()
      }
      if (this.logoType === 3) {
        this.addHeaderWithLogoFull()
      }
      this.addPartnerOnly()
      this.addHeadCommentSmall()
      this.addSignature3()
      this.addSignature4()
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    printTestDoc () {
      // works perfect need update to customize for different types of documents
      this.initDocument()
      if (this.logoType === 0) {
        this.addHeaderNoLogo()
      }
      if (this.logoType === 1) {
        this.addHeaderWithLogoLeft()
      }
      if (this.logoType === 2) {
        this.addHeaderWithLogoRight()
      }
      if (this.logoType === 3) {
        this.addHeaderWithLogoFull()
      }
      this.addLine()
      // this.addEmptyRow()
      // this.addEmptyRow()
      this.addPartner()
      if (this.exportDoc) {
        this.addInoPartner()
      }
      // this.addEmptyRow()
       this.addEmptyRow()
      this.addLine()
      if (this.printNoPrice) {
        this.addItemsHeaderNoPrice()
        this.addLine()
        this.addItemsNoPrice()
        this.addLine()
      } else {
        if (this.$store.state.documentSide === 1) {
          this.addItemsHeaderPlus()
          this.addLine()
          this.addItemsPlusMap()
          this.addLine()
          this.addTotalPlus()
        }
        if (this.$store.state.documentSide === 2 || this.$store.state.documentSide === 3) {
          this.addItemsHeader()
          this.addLine()
          this.addItems()
          this.addLine()
          this.addTotal()
          this.addBankData()
        }        
      }
      this.addComment()
      this.addSignature1()
      this.addSignature2()
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.content = []
    },
    initDocumentSmall () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A6'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.content = []
    },
    addFooter () {
      this.docDefinition.footer = {
          columns: [ {
            text: [
              { text: '   ' + this.comp.CompanyName, fontSize: 8, bold: true, alignment: 'center' },
              { text: ',' + (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ', ' + (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') , fontSize: 7 },
              { text: ', ' + this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + ', email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ', ' + this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' ') + ' \n', fontSize: 7 },
              { text: '   ' + (this.comp.CompanyDescriptionUp ? this.comp.CompanyDescriptionUp : ' '), fontSize: 7, alignment: 'center' }
            ]
          }
          ]
        }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addLineSmall () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 100,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderWithLogoLeft () {
      const docPart = {
            columns: [
              {
                width: '7%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '51%',
                image: this.comp.CompanyLogo1,
                fit: [70, 70]
              },
              {
                // % width
                width: '30%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: 'email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeaderWithLogoLeftSmall () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '51%',
                image: this.comp.CompanyLogo1,
                fit: [70, 70]
              },
              {
                // % width
                width: '40%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: 'email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 1
          }
        this.content.push(docPart)
    },
    addHeaderWithLogoRight () {
      const docPart = {
            columns: [
              {
                width: '7%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                // % width
                width: '50%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: 'email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '31%',
                image: this.comp.CompanyLogo2,
                fit: [70, 70]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeaderWithLogoFull () {
      const docPart = {
            columns: [
              {
                width: '2%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '80%',
                image: this.comp.CompanyLogo3,
                fit: [800, 80]
              },
            ],
          }
        this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                width: '7%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '51%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 18, bold: true },
                ]
              },
              {
                // % width
                width: '30%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: 'email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addPartner () {
      const docPart = {
        columns: [
              {
                width: '10%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              },
              {
                width: '40%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: this.document.partnerName + ' \n', fontSize: 12, bold: true },
                  { text: (this.document.partnerStreet ? this.document.partnerStreet : ' ') + ' ' + (this.document.partnerBuildingNameNumber ? this.document.partnerBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.document.partnerPostcode ? this.document.partnerPostcode : ' ') + ' ' + (this.document.partnerCity ? this.document.partnerCity: ' ') + ' \n', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: this.lang.VATID + ' ' + (this.document.partnerVATID ? this.document.partnerVATID : ' ') + ' \n', fontSize: 8 }
                ]
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '30%',
                text: [
                  { text: this.documentNameLoc + ': ' + this.document.documentId + ' \n', fontSize: 15 },
                  { text: this.lang.DocumentDate + ': ' + this.loCdocumentDate + ',' + this.loCdocumentInvoiceTime + '\n', fontSize: 8, bold: true },
                  { text: this.lang.StartDate + ': ' + this.loCdocumentInvoiceStartDate + '\n', fontSize: 8 },
                  { text: this.lang.DeadLine + ': ' + this.loCdocumentInvoiceEndDate + '\n', fontSize: 8 },
                  { text: this.lang.Supplier + ': ' + (this.document.supplierDocument ?  ',' + this.document.supplierDocument: '') + (this.document.supplierDocumentNr ? ', ' + this.document.supplierDocumentNr : '' ) +  (this.document.supplierDocumentDate ? this.document.supplierDocumentDate : '') + '\n', fontSize: 8 },
                ]
              }
            ]
          }
      this.content.push(docPart)
      if (!this.exportDoc) {
        const docPart2 = {
        columns: [
              {
                width: '10%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              },
              {
                width: '40%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                ]
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '30%',
                text: [
                  { text: this.lang.supplierAmount + ' ' + this.pSupp1 + '\n', fontSize: 8 },
                  { text: this.lang.supplierDiscount + ' ' + this.pSupp2 + '\n', fontSize: 8 },
                  { text: this.lang.supplierNetoAmount + ' ' + this.pSupp3 + '\n', fontSize: 9, bold: true },
                ]
              }
            ]
          }
        this.content.push(docPart2)
      }
    },
    addInoPartner () {
      const docPart = {
        columns: [
              {
                width: '10%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              },
              {
                width: '40%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                  { text: this.lang.UCDnumber + ': ' + this.document.UCDnumber + ' \n', fontSize: 9 },
                  { text: this.lang.UCDdate + ': ' + (this.document.UCDdate ? dayjs(this.document.UCDdate).format('DD.MM.YYYY') : ' ') + '\n', fontSize: 9},
                  
                ]
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '30%',
                text: [
                  { text: this.lang.importAmount + ': ' + this.formatPrice(this.document.importAmount) + ' ' + this.document.documentHomeCurrency + '\n', fontSize: 8 },
                  { text: this.lang.exRate + ': ' + this.document.exRate + '\n', fontSize: 8 },
                  { text: this.lang.domecilAmount + ': ' + this.formatPrice(this.document.domecilAmount) + '\n', fontSize: 8 },
                  { text: this.lang.customAmount + ': ' + this.formatPrice(this.document.customAmount) + '\n', fontSize: 9 },
                  { text: this.lang.bankExp + ': ' + this.formatPrice(this.document.bankExp) + '\n', fontSize: 9 },
                  { text: this.lang.forwarderExp + ': ' + this.formatPrice(this.document.forwarderExp) + '\n', fontSize: 9 },
                  { text: this.lang.transportExp + ': ' + this.formatPrice(this.document.transportExp) + '\n', fontSize: 9 },
                  { text: this.lang.otherExp + ': ' + this.formatPrice(this.document.otherExp) + '\n', fontSize: 9 },
                  { text: 'Ukupno troskovi: ' + this.formatPrice(this.document.totalExp) + ' (' + this.formatPrice(this.document.expenesPercent) + '%)' + '\n', fontSize: 9, bold: true },
                  // { text: this.lang.supplierAmount + ' ' + this.pSupp1 + '\n', fontSize: 8 },
                  // { text: this.lang.supplierDiscount + ' ' + this.pSupp2 + '\n', fontSize: 8 },
                  // { text: this.lang.supplierNetoAmount + ' ' + this.pSupp3 + '\n', fontSize: 9, bold: true },
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addPartnerOnly () {
      const docPart = {
        columns: [
              {
                width: '10%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              },
              {
                width: '40%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                  { text: (this.document.partnerName ? this.document.partnerName : ' ') + ' \n', fontSize: 12, bold: true },
                  { text: (this.document.partnerStreet ? this.document.partnerStreet : ' ') + ' ' + (this.document.partnerBuildingNameNumber ? this.document.partnerBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.document.partnerPostcode ? this.document.partnerPostcode : ' ') + ' ' + (this.document.partnerCity ? this.document.partnerCity: ' ') + ' \n', fontSize: 8 },
                  { text: (this.document.partneremail ? 'email: ' + this.document.partneremail : ' ')  + ' \n', fontSize: 8 },
                  { text: (this.document.partnerSecretaryFirst ? this.document.partnerSecretaryFirst : ' ') + ' ' + (this.document.partnerSecretaryLast ? this.document.partnerSecretaryLast : ' ') + ' ' + (this.document.partnerSecretaryEmail ? this.document.partnerSecretaryEmail : ' ') + ' \n', fontSize: 8 },
                  { text: (this.document.partnerSecretaryPhone ? this.document.partnerSecretaryPhone : ' ') + ' ' + (this.document.partnerContactTime ? this.document.partnerContactTime : ' ')  + ' \n', fontSize: 8 },
                  { text: ' \n ' + (this.document.documentDescription4 ? this.document.documentDescription4 : ' ') , fontSize: 8 },
                ]
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '30%',
                text: [
                  { text: this.documentNameLoc + ': ' + this.document.documentId + ' \n', fontSize: 15 },
                  { text: this.lang.DocumentDate + ': ' + this.loCdocumentDate + ',' + this.loCdocumentInvoiceTime + '\n', fontSize: 8, bold: true },
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeadComment () {
      const docPart = {
        columns: [
              {
                width: '10%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: 'Predmet: ' + this.document.documentDescription4 + ' \n ', fontSize: 10, bold: true },
                  { text: ' \n ', fontSize: 8 },
                  { text: this.document.documentDescription1 + ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '10%',
                text: [
                  { text: ' \n', fontSize: 15 },
                  { text: ' \n', fontSize: 8, bold: true },
                  { text: ' \n', fontSize: 8 }
                ]
              }
            ]
      }
      this.content.push(docPart)
    },
    addHeadCommentSmall () {
      const docPart = {
        columns: [
              {
                width: '80%',
                text: [
                  { text: 'Predmet: ' + this.document.documentDescription4 + ' \n ', fontSize: 10, bold: true },
                  { text: ' \n ', fontSize: 8 },
                  { text: this.document.documentDescription1 + ' \n ', fontSize: 8 },
                  { text: this.document.documentDescription5 + ' \n ', fontSize: 8 }
                ]
              },
            ]
      }
      this.content.push(docPart)
    },
    addItemsHeaderPriceChange () {
      const docPart = {
            columns: [
              {
                width: '3%',
                text: { tex: this.lang.DocPrintOrder + '\n', fontSize: 8 }
              },
              {
                width: '35%',
                text: { text: this.lang.DocPrintName + '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '5%',
                text: { text: this.lang.DocPrintUnit + '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '8%',
                text: { text: this.lang.DocPrintPcs + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: this.lang.DocPrintoldPrice + '\n' + this.lang.DocPrintnewPrice, fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: this.lang.DocPrintoldAmount + '\n' + this.lang.DocPrintnewAmount, fontSize: 8, alignment: 'right' }
              },
              {
                width: '5%',
                text: { text: this.lang.DocPrintoldVAT + '\n' + this.lang.DocPrintnewVAT, fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: this.lang.DocPrintAmountoldVAT + '\n' + this.lang.DocPrintAmountnewVAT, fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: this.lang.DocPrintoldPriceVAT + '\n' + this.lang.DocPrintnewPriceVAT, fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: this.lang.DocPrintoldTotal + '\n' + this.lang.DocPrintnewTotal, fontSize: 8, alignment: 'right' }
              }
            ]
          }
      this.content.push(docPart)
    },
    addItemsHeader () {
      const docPart = {
                    columns: [
              {
                width: '5%',
                text: { tex: this.lang.DocPrintOrder + '\n', fontSize: 8 }
              },
              {
                width: '35%',
                text: { text: this.lang.DocPrintName + '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '5%',
                text: { text: this.lang.DocPrintUnit + '\n', fontSize: 8, alignment: 'center' }
              },
              {
                width: '8%',
                text: { text: this.lang.DocPrintPcs + '\n', fontSize: 8, alignment: 'center' }
              },
              {
                width: '8%',
                text: { text: this.lang.DocPrintPrice + '\n', fontSize: 8, alignment: 'center' }
              },
              {
                width: '8%',
                text: { text: this.lang.DocPrintAmount + '\n', fontSize: 8, alignment: 'center' }
              },
              {
                width: '5%',
                text: { text: this.lang.DocPrintDiscount + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: this.lang.DocPrintAmount2 + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '5%',
                text: { text: this.lang.DocPrintVAT + '\n', fontSize: 8, alignment: 'center' }
              },
              {
                width: '8%',
                text: { text: this.lang.DocPrintAmount3 + '\n', fontSize: 8, alignment: 'center' }
              }
            ]
          }
      this.content.push(docPart)
    },
    addItemsHeaderPlus () {
      if (!this.exportDoc) {
        const docPart = {
              columns: [
                {
                  width: '2%',
                  text: { text: this.lang.DocPrintOrder + '\n', fontSize: 8 }
                },
                {
                  width: '6%',
                  text: { text: '\n', fontSize: 8 }
                },
                {
                  width: '22%',
                  text: { text: this.lang.DocPrintName + '\n', fontSize: 8, alignment: 'left' }
                },
                {
                  width: '7%',
                  text: { text: this.lang.DocPrintPcs + '\n' + this.lang.DocPrintUnit + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '8%',
                  text: { text: this.lang.supplierAmount + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '7%',
                  text: { text: this.lang.supplierDiscount + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '8%',
                  text: { text: this.lang.supplierNetoAmount + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '7%',
                  text: { text: this.lang.supplierMargin + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '8%',
                  text: { text: this.lang.documentTotalAmountNetto + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '7%',
                  text: { text: this.lang.DocPrintVAT + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '5%',
                  text: { text: this.lang.directDuty1 + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '8%',
                  text: { text: this.lang.documentTotalAmountBrutto + '\n', fontSize: 8, alignment: 'right' }
                }
              ]
            }
        this.content.push(docPart)
      }
      if (this.exportDoc) {
        const docPart = {
              columns: [
                {
                  width: '2%',
                  text: { text: this.lang.DocPrintOrder + '\n', fontSize: 8 }
                },
                {
                  width: '22%',
                  text: { text: this.lang.DocPrintName + '\n', fontSize: 8, alignment: 'left' }
                },
                {
                  width: '7%',
                  text: { text: this.lang.DocPrintPcs + '\n' + this.lang.DocPrintUnit + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '8%',
                  text: { text: 'Cijena u val.' + '\n' + 'Iznos u val.', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '8%',
                  text: { text: 'Tečaj' + '\n' + 'Iznos u EUR', fontSize: 8, alignment: 'right' }
                },
                 {
                  width: '8%',
                  text: { text: 'Fik.troš.%' + '\n' + 'Fik.troš.Izn.', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '8%',
                  text: { text: this.lang.supplierNetoAmount + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '7%',
                  text: { text: this.lang.supplierMargin + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '8%',
                  text: { text: this.lang.documentTotalAmountNetto + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '7%',
                  text: { text: this.lang.DocPrintVAT + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '5%',
                  text: { text: this.lang.directDuty1 + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '8%',
                  text: { text: this.lang.documentTotalAmountBrutto + '\n', fontSize: 8, alignment: 'right' }
                }
              ]
            }
        this.content.push(docPart)
      }
    },
    addItemsPlusMap () {
      this.items = []
      if (this.$store.state.documentActivItems) {
        // this.items = (await DocumentDetailService.index(this.mainQuery)).data.documentDetails
        this.items = this.$store.state.documentActivItems
      }
      this.pC1tot = 0
      this.pC2tot = 0
      this.pC3tot = 0
      this.pC4tot = 0
      this.pC5tot = 0
      this.pC6tot = 0
      this.pC7tot = 0
      this.pC8tot = 0
      this.pC9tot = 0
      this.pC10tot = 0
      this.pC11tot = 0
      this.pD1tot = 0
      this.pD2tot = 0
      this.pD3tot = 0
      this.pD4tot = 0
      this.pD5tot = 0
      this.pD6tot = 0
      this.pD7tot = 0
      this.pD8tot = 0
      this.pD9tot = 0
      this.pD10tot = 0
      this.pD11tot = 0
      this.pcE1tot = 0
      this.pcE2tot = 0
      this.pcE3tot = 0
      if (this.items) {
        this.items.map(item => {
          if (parseFloat(item.itemAmountWithVAT) !== 0) {
            this.order += 1
            this.pTtt3 = this.formatPrice(item.itemPrice)
            if (this.$store.state.documentSide === 2) {
              this.pTtt4 = this.formatPrice(item.ItemPcsMinus)
            }
            if (this.$store.state.documentSide === 3) {
              this.pTtt4 = this.formatPrice(item.itemPcsNeutral)
            }
            this.pTtt5 = this.formatPrice(item.itemBaseAmount)
            this.pTtt6 = this.formatPrice(item.itemDiscountPercentage1)
            this.pTtt7 = this.formatPrice(item.itemBaseAmountNoVAT)
            this.pTtt8 = this.formatPrice(item.itemVAT1Percentage)
            this.pTtt9 = this.formatPrice(item.itemAmountWithVAT)
            const supp1 = this.formatPrice(item.itemSuppBasePrice)
            const supp12 = this.formatPrice(item.itemSuppBasePriceAmount)
            const supp2 = this.formatPrice(item.itemSuppDiscount)
            const supp22 = this.formatPrice(item.itemSuppDiscountAmount)
            const supp3 = this.formatPrice(item.itemSuppPrice)
            const supp32 = this.formatPrice(item.itemSuppPriceAmount)
            const supp4 = this.formatPrice(item.itemMargin)
            const supp42 = this.formatPrice(item.itemMarginAmount)
            const supp5 = this.formatPrice(item.itemPrice)
            const supp52 = this.formatPrice(item.itemBaseAmount)
            const supp6 = this.formatPrice(item.itemVAT1Percentage)
            const supp62 = this.formatPrice(item.itemVAT1Amount)
            const supp7 = this.formatPrice(item.itemDirectDuty1)
            const supp72 = this.formatPrice(item.itemDirectDuty1Amount)
            const supp8 = this.formatPrice(item.itemPriceWithVAT)
            const supp82 = this.formatPrice(item.itemAmountWithVAT)
            const suppE1 = this.formatPrice(round((item.itemSuppBaseForePrice * item.itemPcsPlus), 2))
            const suppE2 = this.formatPrice(round((item.itemSuppBaseForePrice * item.itemPcsPlus * item.ItemExRate), 2))
            const suppE3 = this.formatPrice(round(((item.itemSuppBaseForePrice * item.itemPcsPlus * item.ItemExRate) * (item.itemSuppBasePercentageExp/100)), 2))
            this.pcE1tot += round((item.itemSuppBaseForePrice * item.itemPcsPlus), 2)
            this.pcE2tot += round((item.itemSuppBaseForePrice * item.itemPcsPlus * item.ItemExRate), 2)
            this.pcE3tot += round(((item.itemSuppBaseForePrice * item.itemPcsPlus * item.ItemExRate) * (item.itemSuppBasePercentageExp/100)), 2)
            if (item.itemSuppPriceAmount) {
              this.pC6tot += parseNum(item.itemSuppPriceAmount)
            }
            if (item.itemMarginAmount) {
              this.pC7tot += parseNum(item.itemMarginAmount)
            }
            if (item.itemBaseAmount) {
              this.pC8tot += parseNum(item.itemBaseAmount)
            }
            if (item.itemVAT1Amount) {
              this.pC9tot += parseNum(item.itemVAT1Amount)
            }
            if (item.itemDirectDuty1Amount) {
              this.pC10tot += parseNum(item.itemDirectDuty1Amount)
            }
            if (item.itemAmountWithVAT) {
              this.pC11tot += parseNum(item.itemAmountWithVAT)
            }

            if (this.exportDoc) {
              const docPart = {
                columns: [
                  {
                    width: '2%',
                    text: { text: this.order + '\n', fontSize: 7 }
                  },
                  {
                    width: '22%',
                    text: { text: item.itemName + '\n' + item.itemID + '\n', fontSize: 8, alignment: 'left' }
                  },
                  {
                    width: '7%',
                    text: { text: this.formatPrice(item.itemPcsPlus) + '\n' + item.itemUnit + '\n', fontSize: 8, alignment: 'right' }
                  },
                   {
                    width: '8%',
                    text: { text: this.formatPrice(item.itemSuppBaseForePrice) + '\n' + suppE1 + '\n', fontSize: 8, alignment: 'right' }
                  },
                  {
                    width: '8%',
                    text: { text: item.ItemExRate+ '\n' + suppE2 + '\n', fontSize: 8, alignment: 'right' }
                  },
                  {
                    width: '7%',
                    text: { text: this.formatPrice(item.itemSuppBasePercentageExp) + '\n' + suppE3  + '\n', fontSize: 8, alignment: 'right' }
                  },
                  {
                    width: '8%',
                    text: { text: supp3 + '\n' + supp32 + '\n', fontSize: 8, alignment: 'right' }
                  },
                  {
                    width: '7%',
                    text: { text: supp4 + '\n' + supp42 + '\n', fontSize: 8, alignment: 'right' }
                  },
                  {
                    width: '8%',
                    text: { text: supp5 + '\n' + supp52 + '\n', fontSize: 8, alignment: 'right' }
                  },
                  {
                    width: '7%',
                    text: { text: supp6 + '\n' + supp62 + '\n', fontSize: 8, alignment: 'right' }
                  },
                  {
                    width: '5%',
                    text: { text: supp7 + '\n' + supp72 + '\n', fontSize: 8, alignment: 'right' }
                  },
                  {
                    width: '8%',
                    text: { text: supp8 + '\n' + supp82 + '\n', fontSize: 8, alignment: 'right' }
                  }
                 
                ]
              }
              this.content.push(docPart)
            }

            if (!this.exportDoc) {
              const docPart = {
                columns: [
                  {
                    width: '2%',
                    text: { text: this.order + '\n', fontSize: 7 }
                  },
                  {
                    width: '28%',
                    text: { text: item.itemName + '\n' + item.itemID + '\n', fontSize: 8, alignment: 'left' }
                  },
                  {
                    width: '7%',
                    text: { text: this.formatPrice(item.itemPcsPlus) + '\n' + item.itemUnit + '\n', fontSize: 8, alignment: 'right' }
                  },
                  {
                    width: '8%',
                    text: { text: supp1 + '\n' + supp12 + '\n', fontSize: 8, alignment: 'right' }
                  },
                  {
                    width: '8%',
                    text: { text: supp2 + '\n' + supp22 + + '\n', fontSize: 8, alignment: 'right' }
                  },
                  {
                    width: '8%',
                    text: { text: supp3 + '\n' + supp32 + '\n', fontSize: 8, alignment: 'right' }
                  },
                  {
                    width: '7%',
                    text: { text: supp4 + '\n' + supp42 + '\n', fontSize: 8, alignment: 'right' }
                  },
                  {
                    width: '8%',
                    text: { text: supp5 + '\n' + supp52 + '\n', fontSize: 8, alignment: 'right' }
                  },
                  {
                    width: '7%',
                    text: { text: supp6 + '\n' + supp62 + '\n', fontSize: 8, alignment: 'right' }
                  },
                  {
                    width: '5%',
                    text: { text: supp7 + '\n' + supp72 + '\n', fontSize: 8, alignment: 'right' }
                  },
                  {
                    width: '8%',
                    text: { text: supp8 + '\n' + supp82 + '\n', fontSize: 8, alignment: 'right' }
                  }
                ]
              }
              this.content.push(docPart)
            }

          }
          if (item.itemDescription) {
            const docPart2 = {
              columns: [
                {
                  width: '2%',
                  text: { text: '\n', fontSize: 7 }
                },
                {
                  width: '6%',
                  text: { text: '\n', fontSize: 7 }
                },
                {
                  width: '22%',
                  text: { text: item.itemDescription + '\n', fontSize: 7, alignment: 'left' }
                },
                {
                  width: '7%',
                  text: { text:  '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '8%',
                  text: { text: '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '7%',
                  text: { text: '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '8%',
                  text: { text:  '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '7%',
                  text: { text:  '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '8%',
                  text: { text:  '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '7%',
                  text: { text:  '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '5%',
                  text: { text:  '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '8%',
                  text: { text:  '\n', fontSize: 8, alignment: 'right' }
                }
              ]
            }
            this.content.push(docPart2)
            this.order += 1
          }
          // console.log(' ovo imam za stavku ', item)
        })
      }
      this.pD6tot = this.formatPrice(round(this.pC6tot,2))
      this.pD7tot = this.formatPrice(this.pC7tot)
      this.pD8tot = this.formatPrice(this.pC8tot)
      this.pD9tot = this.formatPrice(this.pC9tot)
      this.pD10tot = this.formatPrice(this.pC10tot)
      this.pD11tot = this.formatPrice(this.pC11tot)
      this.pSupp1 = this.formatPrice(this.document.supplierAmount)
      this.pSupp2 = this.formatPrice(this.document.supplierDiscount)
      this.pSupp3 = this.formatPrice(this.document.supplierNetoAmount)
    },
    addItemsHeaderNoPrice () {
      const docPart = {
          columns: [
              {
                width: '5%',
                text: { tex: this.lang.DocPrintOrder + '\n', fontSize: 8 }
              },
              {
                width: '65%',
                text: { text: this.lang.DocPrintName + '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '10%',
                text: { text: this.lang.DocPrintUnit + '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '10%',
                text: { text: this.lang.DocPrintPcs + '\n', fontSize: 8, alignment: 'right' }
              },
            ]
          }
      this.content.push(docPart)
    },
    addItemsNoPrice () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: this.pC1
              },
              {
                width: '65%',
                text: this.pC2
              },
              {
                width: '10%',
                text: this.pC10
              },
              {
                width: '10%',
                text: this.pC4
              },
            ]
          }
      this.content.push(docPart)
    },
    addItemsPlus () {
      const docPart = {
        columns: [
              {
                width: '2%',
                text: this.pC1
              },
              {
                width: '28%',
                text: this.pC2
              },
              {
                width: '7%',
                text: this.pC3
              },
              {
                width: '8%',
                text: this.pC4
              },
              {
                width: '7%',
                text: this.pC5
              },
              {
                width: '8%',
                text: this.pC6
              },
              {
                width: '7%',
                text: this.pC7
              },
              {
                width: '8%',
                text: this.pC8
              },
              {
                width: '7%',
                text: this.pC9
              },
              {
                width: '5%',
                text: this.pC10
              },
              {
                width: '8%',
                text: this.pC11
              }
            ]
          }
      this.content.push(docPart)
    },
    addItems () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: this.pC1
              },
              {
                width: '35%',
                text: this.pC2 + (this.pC12 ? '\n' + this.pC12 : '')
              },
              {
                width: '5%',
                text: this.pC10
              },
              {
                width: '8%',
                text: this.pC4
              },
              {
                width: '8%',
                text: this.pC3
              },
              {
                width: '8%',
                text: this.pC5
              },
              {
                width: '5%',
                text: this.pC6
              },
              {
                width: '8%',
                text: this.pC7
              },
              {
                width: '5%',
                text: this.pC8
              },
              {
                width: '8%',
                text: this.pC9
              }
            ]
          }
      this.content.push(docPart)
    },
    addTotalPriceChange () {
      const docPart = {
            columns: [
              
              {
                width: '38%',
                text: { text:  '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '5%',
                text: { text:  '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '8%',
                text: { text:  '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: '\n' , fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: this.oldSum1 + '\n' + this.newSum1 + '\n' + (this.diffSum1), fontSize: 8, alignment: 'right' }
              },
              {
                width: '5%',
                text: { text: '\n' , fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: this.oldSum2 + '\n' + this.newSum2 + '\n' + (this.diffSum2), fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: this.oldSum4 + '\n' + this.newSum4 + '\n' + (this.diffSum4), fontSize: 8, alignment: 'right' }
              }
            ]
          }
      this.content.push(docPart)
    },
    addTotalPlus () {
      if (!this.exportDoc) {
        const docPart = {
          columns: [
                {
                  width: '2%',
                  text: { text: ' \n ', fontSize: 8 },
                },
                {
                  width: '28%',
                  text: { text: ' \n ', fontSize: 8 },
                },
                {
                  width: '7%',
                  text: { text: ' \n ', fontSize: 8 },
                },
                {
                  width: '8%',
                  text: { text:  ' \n ', fontSize: 8 },
                },
                {
                  width: '8%',
                  text: { text:  ' \n ', fontSize: 8 },
                },
                {
                  width: '8%',
                  text: { text: this.pD6tot + ' \n ', fontSize: 8, alignment: 'right' },
                },
                {
                  width: '7%',
                  text: { text: this.pD7tot + ' \n ', fontSize: 8, alignment: 'right' },
                },
                {
                  width: '8%',
                  text: { text: this.pD8tot + ' \n ', fontSize: 8, alignment: 'right' },
                },
                {
                  width: '7%',
                  text: { text: this.pD9tot + ' \n ', fontSize: 8, alignment: 'right' },
                },
                {
                  width: '5%',
                  text: { text: this.pD10tot + ' \n ', fontSize: 8, alignment: 'right' },
                },
                {
                  width: '8%',
                  text: { text: this.pD11tot + ' \n ', fontSize: 8, alignment: 'right' },
                }
              ]
            }
        this.content.push(docPart)
      }
      if (this.exportDoc) {
        const docPart = {
          columns: [
                {
                  width: '2%',
                  text: { text: ' \n ', fontSize: 8 },
                },
                {
                  width: '22%',
                  text: { text: ' \n ', fontSize: 8 },
                },
                {
                  width: '7%',
                  text: { text: ' \n ', fontSize: 8 },
                },
                {
                  width: '8%',
                  text: { text: this.formatPrice(this.pcE1tot)  + ' \n ', fontSize: 8, alignment: 'right'},
                },
                {
                  width: '8%',
                  text: { text: this.formatPrice(this.pcE2tot) + ' \n ', fontSize: 8, alignment: 'right'},
                },
                {
                  width: '7%',
                  text: { text: this.formatPrice(this.pcE3tot) + ' \n ', fontSize: 8, alignment: 'right'},
                },
                {
                  width: '8%',
                  text: { text: this.pD6tot + ' \n ', fontSize: 8, alignment: 'right' },
                },
                {
                  width: '7%',
                  text: { text: this.pD7tot + ' \n ', fontSize: 8, alignment: 'right' },
                },
                {
                  width: '8%',
                  text: { text: this.pD8tot + ' \n ', fontSize: 8, alignment: 'right' },
                },
                {
                  width: '7%',
                  text: { text: this.pD9tot + ' \n ', fontSize: 8, alignment: 'right' },
                },
                {
                  width: '5%',
                  text: { text: this.pD10tot + ' \n ', fontSize: 8, alignment: 'right' },
                },
                {
                  width: '8%',
                  text: { text: this.pD11tot + ' \n ', fontSize: 8, alignment: 'right' },
                }
                // 2  22 7 8 8 7 8 7  8  7  5 8
              ]
            }
        this.content.push(docPart)
      }
    },
    addTotal () {
      const docPart = {
                    columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '40%',
                text: [
                  { text: this.lang.DocPrintVAT1 + ' ' + (this.document.documentTotalVAT1Amount ? this.document.documentTotalVAT1Amount : 0) + '\n', fontSize: 8 },
                  { text: this.lang.DocPrintVAT2 + ' ' + (this.document.documentTotalVAT2Amount ? this.document.documentTotalVAT2Amount : 0) + '\n', fontSize: 8 },
                  { text: this.lang.DocPrintVAT3 + ' ' + (this.document.documentTotalVAT3Amount ? this.document.documentTotalVAT3Amount : 0) + '\n', fontSize: 8 },
                  { text: this.lang.DocPrintVAT4 + ' ' + (this.document.FreeF4 ? this.document.FreeF4 : 0) + '\n', fontSize: 8 }
                ]
              },
              {
                width: '8%',
                text: ' '
              },
              {
                width: '21%',
                text: [
                  { text: this.lang.DocPrintTotal1 + (this.document.documentHomeCurrency ? this.document.documentHomeCurrency : '' ) + ') \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal2 + (this.document.documentHomeCurrency ? this.document.documentHomeCurrency : '') + '): \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal3 + (this.document.documentHomeCurrency ? this.document.documentHomeCurrency : '') + '): \n', fontSize: 8, bold: true },
                  { text: this.lang.DocPrintTotal4 + (this.document.documentHomeCurrency ? this.document.documentHomeCurrency : '') + '): \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal5 + (this.document.documentHomeCurrency ? this.document.documentHomeCurrency : '') + '): \n', fontSize: 12, bold: true }
                ]
              },
              {
                width: '8%',
                text: [
                  { text: ' ', alignment: 'right' }
                ]
              },
              {
                width: '13%',
                text: [
                  { text: this.pSum1 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum2 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum3 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum4 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum5 + '\n', fontSize: 12, bold: true, alignment: 'right' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addBankData () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '40%',
                text: [
                  { text: this.lang.DocPrintPayType + ' ' + '\n', fontSize: 8 },
                  { text: this.lang.DocPrintIdUser + ' ' + this.document.UserId + '\n', fontSize: 8 },
                  { text: this.lang.BankAccount + ' ' + (this.document.bankAccountNr ? this.document.bankAccountNr : ' ') + '\n', fontSize: 10, bold: true },
                  { text: this.lang.DocPrintPayUseNr + '   ' + this.document.documentId + this.document.BusinessYear + '\n', fontSize: 8 },
                  { text: (this.document.bankAccountName ? this.document.bankAccountName : ' ') + '\n', fontSize: 8 }
                ]
              },
              {
                width: '8%',
                text: ' '
              },
              {
                width: '21%',
                text: [
                  { text: ' \n', fontSize: 8 }
                ]
              },
              {
                width: '8%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '13%',
                text: [
                  { text: ' \n', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addComment () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '80%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text:  this.lang.DocPrintComment + ' ' + this.document.documentDescription5 + ' \n', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addSignature1 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: this.lang.DocPrintMadeBy + ' ' + (this.document.First ? this.document.First : ' ') + ' ' + (this.document.Last ? this.document.Last : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: this.lang.DocPrintControlBy + ' ' + (this.document.FirstControl ? this.document.FirstControl : ' ') + ' ' + (this.document.LastControl ? this.document.LastControl : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: this.lang.DocPrintCFO + ' ' + (this.document.FirstCFO ? this.document.FirstCFO : ' ') + ' ' + (this.document.LastCFO ? this.document.LastCFO : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addSignature2 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________  \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addSignature3 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: 'Primio na servis: ' + (this.document.First ? this.document.First : ' ') + ' ' + (this.document.Last ? this.document.Last : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: ' Predao : \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addSignature4 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addEmpty () {
      const docPart = {
        
          }
      this.content.push(docPart)
    },
    print3 () {
      this.docDefinition2 = {}
      this.docDefinition2.pageSize = 'A4'
      this.docDefinition2.pageOrientation = 'portrait'
      this.docDefinition2.pageMargins = [ 20, 20, 20, 20 ]
      this.docDefinition2.footer = {
          columns: [ {
            text: [
              { text: '   ' + this.comp.CompanyName, fontSize: 8, bold: true, alignment: 'center' },
              { text: ',' + (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ', ' + (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') , fontSize: 7 },
              { text: ', ' + this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + ', email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ', ' + this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' ') + ' \n', fontSize: 7 },
              { text: '   ' + (this.comp.CompanyDescriptionUp ? this.comp.CompanyDescriptionUp : ' '), fontSize: 7, alignment: 'center' }
            ]
          }
          ]
        }

      const docPart = {
            columns: [
              {
                width: '7%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '51%',
                image: this.comp.CompanyLogo1,
                fit: [70, 70]
              },
              {
                // % width
                width: '30%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: 'email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content2.push(docPart)
        // eslint-disable-next-line
        console.log('Content 2', this.content2)
        const docPart2 = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
        this.content2.push(docPart2)
        
    },
    print2 () {

      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {
        // a string or { width: number, height: number }
        pageSize: 'A4',

        // by default we use portrait, you can change it to landscape if you wish
        pageOrientation: 'portrait',

        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 20, 20, 20, 20 ],
        footer: {
          columns: [ {
            text: [
              { text: '   ' + this.comp.CompanyName, fontSize: 8, bold: true, alignment: 'center' },
              { text: ',' + (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ', ' + (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') , fontSize: 7 },
              { text: ', ' + this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + ', email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ', ' + this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' ') + ' \n', fontSize: 7 },
              { text: '   ' + (this.comp.CompanyDescriptionUp ? this.comp.CompanyDescriptionUp : ' '), fontSize: 7, alignment: 'center' }
            ]
          }
          ]
        },
        content: [
          // if you don't need styles, you can use a simple string to define a paragraph
          // using a { text: '...' } object lets you set styling properties
          {
            columns: [
              {
                width: '7%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '51%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
                //image: this.comp.CompanyLogo1,
                //fit: [70, 70]
              },
              {
                // % width
                width: '30%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: 'email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          },
          // if you set the value of text to an array instead of a string, you'll be able
          // to style any part individually
          // using a { text: '...' } object lets you set styling properties
          {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          },
          {
            columns: [
              {
                width: '10%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              },
              {
                width: '40%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: this.document.partnerName + ' \n', fontSize: 12, bold: true },
                  { text: (this.document.partnerStreet ? this.document.partnerStreet : ' ') + ' ' + (this.document.partnerBuildingNameNumber ? this.document.partnerBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.document.partnerPostcode ? this.document.partnerPostcode : ' ') + ' ' + (this.document.partnerCity ? this.document.partnerCity: ' ') + ' \n', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: this.lang.VATID + ' ' + (this.document.partnerVATID ? this.document.partnerVATID : ' ') + ' \n', fontSize: 8 },
                  { text: 'email: ' + (this.document.partneremail ? this.document.partneremail : ' ') + ' \n', fontSize: 8 }
                ]
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '30%',
                text: [
                  { text: this.documentNameLoc + ': ' + this.document.documentId + '/' + this.comp.CompanyBusinessSpace + '/' + this.comp.CompanyBusinessDevice + ' \n', fontSize: 15 },
                  { text: this.lang.DocumentDate + ' ' + this.loCdocumentDate + ',' + this.loCdocumentInvoiceTime + '\n', fontSize: 8, bold: true },
                  { text: this.lang.StartDate + ' ' + this.loCdocumentInvoiceStartDate + '\n', fontSize: 8 },
                  { text: this.lang.DeadLine + ' ' + this.loCdocumentInvoiceEndDate + '\n', fontSize: 8 }
                ]
              }
            ]
          },
          {
            columns: [
              {
                width: '10%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  // { text: 'Predmet: ' + this.document.documentDescription4 + ' \n ', fontSize: 10, bold: true },
                  { text: ' \n ', fontSize: 8 }
                  // { text: this.document.documentDescription1 + ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '10%',
                text: [
                  { text: ' \n', fontSize: 15 },
                  { text: ' \n', fontSize: 8, bold: true },
                  { text: ' \n', fontSize: 8 }
                ]
              }
            ]
          },
          {
            columns: [
              {
                width: '10%',
                text: [
                  { text: '', fontSize: 8 }
                ]
              },
              {
                width: '50%',
                text: [
                  { text: ' \n', fontSize: 10, bold: true },
                  { text: ' \n', fontSize: 8 },
                  { text: ' \n', fontSize: 8 },
                  { text: ' \n', fontSize: 8 }
                ]
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '20%',
                text: [
                  { text: ' ' + ' \n', fontSize: 8, bold: true }
                ]
              }
            ]
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          },

          // ---------------------------------------------------------------------
          // Za fakturu
          // ---------------------------------------------------------------------
          {
            columns: [
              {
                width: '5%',
                text: { tex: this.lang.DocPrintOrder + '\n', fontSize: 8 }
              },
              {
                width: '35%',
                text: { text: this.lang.DocPrintName + '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '5%',
                text: { text: this.lang.DocPrintUnit + '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '8%',
                text: { text: this.lang.DocPrintPcs + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: this.lang.DocPrintPrice + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: this.lang.DocPrintAmount + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '5%',
                text: { text: this.lang.DocPrintDiscount + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: this.lang.DocPrintAmount2 + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '5%',
                text: { text: this.lang.DocPrintVAT + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: this.lang.DocPrintAmount3 + '\n', fontSize: 8, alignment: 'right' }
              }
            ]
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: this.pC1
              },
              {
                width: '35%',
                text: this.pC2
              },
              {
                width: '5%',
                text: this.pC10
              },
              {
                width: '8%',
                text: this.pC4
              },
              {
                width: '8%',
                text: this.pC3
              },
              {
                width: '8%',
                text: this.pC5
              },
              {
                width: '5%',
                text: this.pC6
              },
              {
                width: '8%',
                text: this.pC7
              },
              {
                width: '5%',
                text: this.pC8
              },
              {
                width: '8%',
                text: this.pC9
              }
            ]
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '40%',
                text: [
                  { text: this.lang.DocPrintVAT1 + ' ' + (this.document.documentTotalVAT1Amount ? this.document.documentTotalVAT1Amount : 0) + '\n', fontSize: 8 },
                  { text: this.lang.DocPrintVAT2 + ' ' + (this.document.documentTotalVAT2Amount ? this.document.documentTotalVAT2Amount : 0) + '\n', fontSize: 8 },
                  { text: this.lang.DocPrintVAT3 + ' ' + (this.document.documentTotalVAT3Amount ? this.document.documentTotalVAT3Amount : 0) + '\n', fontSize: 8 },
                  { text: this.lang.DocPrintVAT4 + ' ' + (this.document.FreeF4 ? this.document.FreeF4 : 0) + '\n', fontSize: 8 }
                ]
              },
              {
                width: '8%',
                text: ' '
              },
              {
                width: '21%',
                text: [
                  { text: this.lang.DocPrintTotal1 + (this.document.documentHomeCurrency ? this.document.documentHomeCurrency : '' ) + ') \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal2 + (this.document.documentHomeCurrency ? this.document.documentHomeCurrency : '') + '): \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal3 + (this.document.documentHomeCurrency ? this.document.documentHomeCurrency : '') + '): \n', fontSize: 8, bold: true },
                  { text: this.lang.DocPrintTotal4 + (this.document.documentHomeCurrency ? this.document.documentHomeCurrency : '') + '): \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal5 + (this.document.documentHomeCurrency ? this.document.documentHomeCurrency : '') + '): \n', fontSize: 12, bold: true }
                ]
              },
              {
                width: '8%',
                text: [
                  { text: ' ', alignment: 'right' }
                ]
              },
              {
                width: '13%',
                text: [
                  { text: this.pSum1 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum2 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum3 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum4 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum5 + '\n', fontSize: 12, bold: true, alignment: 'right' }
                ]
              }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '40%',
                text: [
                  { text: this.lang.DocPrintPayType + ' ' + '\n', fontSize: 8 },
                  { text: this.lang.DocPrintIdUser + ' ' + this.document.UserId + '\n', fontSize: 8 },
                  { text: this.lang.BankAccount + ' ' + (this.document.bankAccountNr ? this.document.bankAccountNr : ' ') + '\n', fontSize: 10, bold: true },
                  { text: this.lang.DocPrintPayUseNr + '   ' + this.document.documentId + this.document.BusinessYear + '\n', fontSize: 8 },
                  { text: (this.document.bankAccountName ? this.document.bankAccountName : ' ') + '\n', fontSize: 8 }
                ]
              },
              {
                width: '8%',
                text: ' '
              },
              {
                width: '21%',
                text: [
                  { text: ' \n', fontSize: 8 }
                ]
              },
              {
                width: '8%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '13%',
                text: [
                  { text: ' \n', fontSize: 8 }
                ]
              }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '80%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text:  this.lang.DocPrintComment + ' ' + this.document.documentDescription5 + ' \n', fontSize: 8 }
                ]
              }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: this.lang.DocPrintMadeBy + ' ' + (this.document.First ? this.document.First : ' ') + ' ' + (this.document.Last ? this.document.Last : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: this.lang.DocPrintControlBy + ' ' + (this.document.FirstControl ? this.document.FirstControl : ' ') + ' ' + (this.document.LastControl ? this.document.LastControl : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: this.lang.DocPrintCFO + ' ' + (this.document.FirstCFO ? this.document.FirstCFO : ' ') + ' ' + (this.document.LastCFO ? this.document.LastCFO : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________  \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
        ]
      }
      // eslint-disable-next-line
      console.log(this.docDefinition)

      pdfMake.createPdf(this.docDefinition).open()
    }
  },
  computed: {
  },
  watch: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
