const docEN = {
  User: 'User',
  Users: 'Users',
  AddUser: 'Add New User',
  email: 'email',
  password: 'password',
  ConfirmPassword: 'Confirm Password',
  First: 'First name',
  Last: 'Last Name',
  StartFrom: 'Start From',
  Activ: 'Activ ',
  Admin: 'Admin Global',
  AccessLevel: 'Access Level (1 max, 5 low)',
  UserEmpty: 'Users are Empty',
  Actions: 'Actions',
  NewUser: 'New User',
  EditUser: 'Edit User',
  Priv1: '1 max privileges',
  Priv2: '2 medium to max privileges',
  Priv3: '3 medium privileges',
  Priv4: '4 low to midium privileges',
  Priv5: '5 low privileges',
  VATID: 'VAT ID',
  sales1: 'Sales',
  sales2: 'Hide purchase price',
  purchase1: 'Purchase',
  purchase2: 'Purchase Prof',
  crm1: 'CRM',
  crm2: 'CRM Prof',
  finance1: 'Finance',
  finance2: 'Finance Prof',
  finance3: 'Finance Adv',
  finance4: 'Fin.Buy/Supp',
  hr1: 'HR',
  hr2: 'HR Prof',
  admin1: 'Admin Local',
  admin2: 'Admin Local Prof',
  prod1: 'Production',
  prod2: 'Production Prof',
  privatePartner: 'Only Private Par',
  chart1: 'Dash Graph 1',
  chart2: 'Dash Graph 2',
  chart3: 'Dash Graph 3',
  chart4: 'Dash Graph 4',
  salesNumbers1: 'Table Sales1',
  salesNumbers2: 'Table Sales2',
  calendar1: 'Dash Kalendar 1',
  calendar2: 'Dash Kalendar 2',
  task1: 'Dash Zadaci 1',
  task2: 'Dash Zadaci 2',
  chat1: 'Dash Chat 1',
  chat2: 'Dash Chat 2',
  vehicles: 'vehicles'
}

export default docEN