<template>
    <v-container fluid grid-list-md>
    <MainBookHeader />
    </v-container>
</template>

<script>


import MainBookHeader from './MainBookHeader'
//import {checkPartners } from '../MainLedger/mainledgerHelper'
import { testLastUpdate } from '@/services/TestLastUpdate'

export default {
  name: 'mainbook',
  data () {
    return {
      lang: {},
      langC: {}
    }
  },
  async mounted () {
    await this.$store.dispatch('seteditItem', 0)
    // eslint-disable-next-line
    console.log('mainbook INIT ')
    // if (this.$store.state.language === "EN") {
    //   this.lang = ledgerEN
    //   this.langC = commEn
    // }
    // if (this.$store.state.language === "HR") {
    //   this.lang = ledgerHR
    //   this.langC = commHr
    // }
    await testLastUpdate()
  },
  methods: {

  },
  computed: {
  },
  watch: {

  },
  components: {
    MainBookHeader
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
