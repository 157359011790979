import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('workhour', {
      params: query 
    })
  },
  empmonth (query) {
    console.log('Service query', query)
    return Api().get('workhour/empmonth', {
      params: query
    })
  },
  show (workhourId) {
    return Api().get(`workhour/${workhourId}`)
  },
  post (newworkhour) {
    return Api().post('workhour', newworkhour)
  },
  saveitems (newworkhours) {
    return Api().post('workhour/saveitems', newworkhours)
  },
  deleteitems (query) {
    return Api().get('workhour/deleteitems', {
      params: query
    })
  },
  put (workhour) {
    return Api().put(`workhour/${workhour.id}`, workhour)
  },
  delete (workhourId) {
    return Api().delete(`workhour/${workhourId}`)
  }
}
