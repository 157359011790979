<template>
<div>
  <v-container fluid grid-list-md>

  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="users"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="50"
    :mobile-breakpoint="0" 
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{lang.CashDesk}}</v-toolbar-title>
          
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
        {{totalIN}} Ulaz - {{totalOUT}} Izlaz = {{totalSaldo}} Saldo blagajne

        <v-btn text color="primary" @click="displayTotals()">
            Total
        </v-btn>
        <v-btn text color="primary" @click="print()">
            Print
        </v-btn>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>


          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >

                  <v-row>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                      {{langC.Date}}: <input type="date" v-model="editedItem.documentDate" />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="9" md="9" xs="9" lg="9" xl="9" class="pr-1">
                     <v-text-field
                        :label="lang.Description"
                        v-model="editedItem.Description"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">

                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        :label="lang.CashIn"
                        v-model="editedItem.CashIn"
                        class="text-red"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">

                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <v-text-field
                        :label="lang.CashOut"
                        v-model="editedItem.CashOut"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-1">

                    </v-col>
                    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1" v-if="bYear < 2023">
                      <v-text-field
                        :label="lang.ExRate"
                        v-model="editedItem.ExRate"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row> 
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                        <v-autocomplete
                            :items="emps"
                            v-model="selectEmp"
                            :label="lang.SelectEmployee"
                            item-text="eemail"
                            item-value="id"
                            return-object
                            persistent-hint
                            clearable
                          ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                        <v-text-field class="pr-2"
                            :label="lang.First"
                            v-model="editedItem.First"
                            disabled
                          ></v-text-field> 
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                      <v-text-field class="pr-2"
                          :label="lang.Last"
                          v-model="editedItem.Last"
                          disabled
                        ></v-text-field> 
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                      <v-text-field class="pr-2"
                          :label="lang.EmployeeVatID"
                          v-model="editedItem.EmployeeVatID"
                          disabled
                        ></v-text-field> 
                    </v-col>
                  </v-row>

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn color="success" text @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.documentDate`]="{ item }">
        <span>{{ localDate(item.documentDate) }}</span>
    </template>
    <template v-slot:[`item.CashIn`]="{ item }">
        <span>{{ localMoney(item.CashIn) }}</span>
    </template>
    <template v-slot:[`item.CashInFore`]="{ item }">
        <span>{{ localMoney(item.CashInFore) }}</span>
    </template>
    <template v-slot:[`item.CashOut`]="{ item }">
        <span>{{ localMoney(item.CashOut) }}</span>
    </template>
    <template v-slot:[`item.CashOutFore`]="{ item }">
        <span>{{ localMoney(item.CashOutFore) }}</span>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      {{ lang.EmptyCashDesk }}
    </template>
  </v-data-table>
  <v-row no-gutters class="pa-0">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <div v-if="showTotals">
          <CashDeskTotals />
        </div>
      </v-col>
    </v-row>
    <CashDeskPrintAll ref="prnt" :card="card"  />
  </v-container>
  </div>
</template>

<script>
import langEn from './cashdeskDescEn'
import langHr from './cashdeskDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import CashDeskService from '@/services/CashDeskService'
import EmployeeService from '@/services/EmployeeService'
import { round, parseNum } from '@/snippets/allSnippets'
import dateFormat from '@/snippets/dateFormat'
import moneyFormat from '@/snippets/moneyFormat'
import CashDeskTotals from './CashDeskTotals'
import CashDeskPrintAll from './CashDeskPrintAll'
import dayjs from 'dayjs'

  export default {
    name: 'cashdeskCRUD',
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      modal1: false,
      dialog1: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: "",
        databaseDocID: 0
      },
      dialog: false,
      headers: [],
      users: [],
      emps: [],
      selectEmp: {},
      editedIndex: -1,
      editedItem: { },
      defaultItem: {
        WareHouseName: null,
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: null,
        email: '',
        documentDate: null,
        partnerId: '',
        partnerNameDatabase: '',
        Description: '',
        CashIn: 0,
        CashInFore: 0,
        CashOut: 0,
        CashOutFore: 0,
        HomeCurrency: 'kn',
        ForeignCurrency: 'EUR',
        ExRate: 1,     
        EmployeeId: 0,
        Employeeemail: '',
        Employeehashtag: '',
        EmployeeVatID: '',
        eemail: '',
        phone: '',
        First: '',
        Last: '',
        MainLedgerID: 0,
        MLType: 0,
        BookID: 0,
        BookTypeID: 0,
        BookName: '',
        BookSubTypeID: 0,
        BookSubName: '',
        BookIdGeneral: '',
        FreeS1: '',
        FreeS2: '',
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
      },
      privitems: [],
      valid: true,
      totalIN : 0,
      totalOUT : 0,
      totalSaldo: 0,
      showTotals: false,
      card: {},
      bYear: 0,
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewCashDesk: this.lang.EditCashDesk
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      'selectEmp' () {
        this.findEmp()
      },
    },
    components: {
      CashDeskTotals,
      CashDeskPrintAll
    },
    async mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
        this.bYear = this.$store.state.businessYear
      }
      const res = (await EmployeeService.choose(this.mainQuery))
      console.log('Emp res', res)
      if (res.data && res.data.employes && res.data.employes.length > 0) {
        this.emps = res.data.employes
      }

      this.headers.push(
        {text: this.lang.documentDate, value: 'documentDate', width: '120px'},
        {text: this.lang.Description, value: 'Description', width: '300px'},
        {text: this.lang.CashIn, value: 'CashIn', type: 'number', width: '110px'},
        {text: this.lang.CashOut, value: 'CashOut', type: 'number', width: '110px'},
        {text: this.lang.CashInFore, value: 'CashInFore', type: 'number', width: '110px'},
        {text: this.lang.CashOutFore, value: 'CashOutFore', type: 'number', width: '110px'},
        {text: this.lang.Employeeemail, value: 'Employeeemail', width: '120px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
        {text: this.lang.Actions, value: 'action', sortable: false },
      )
      this.defaultItem.documentDate = dayjs(new Date()).format('YYYY-MM-DD')
      this.defaultItem.documentTime = dayjs(new Date()).format('HH:mm:ss')
    },

    created () {
      this.initialize()
    },

    methods: {
      print(){
        this.$refs.prnt.print()
      },
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      localMoney(moneyToFormat) {
        return moneyFormat(moneyToFormat)
      },
      localDate(dateToFormat) {
        return dateFormat(dateToFormat)
      },
      displayTotals () {
        this.showTotals = !this.showTotals
      },
      async doSum() {
        this.totalIN = 0
        this.totalOUT = 0
        this.totalSaldo = 0
        this.totalINfore = 0
        this.totalOUTfore = 0
        this.totalSaldofore = 0
        if (this.users) {
          this.users.map(user => {
            if (user.CashIn) {
              this.totalIN += round(parseNum(user.CashIn), 2)
            }
            if (user.CashIn) {
              this.totalOUT += round(parseNum(user.CashOut), 2)
            }
            if (user.CashInFore) {
              this.totalINfore += round(parseNum(user.CashInFore), 2)
            }
            if (user.CashInFore) {
              this.totalOUTfore += round(parseNum(user.CashOutF), 2)
            }
          })
          this.totalSaldo = round((this.totalIN - this.totalOUT), 2)
          this.totalSaldofore = round((this.totalINfore - this.totalOUTfore), 2)

          this.card.totalIN = this.totalIN
          this.card.totalOUT = this.totalOUT
          this.card.totalSaldo = this.totalSaldo
          this.card.totalINfore = this.totalINfore
          this.card.totalOUTfore = this.totalOUTfore
          this.card.totalSaldofore = this.totalSaldofore
        }
      },
      async initialize () {
        try {
          this.saving = true
          this.defaultItem.ExRate = this.$store.getters.NeedDefExRate
          
          const query = {}
          query.CompanyId = this.$store.state.companyid ? this.$store.state.companyid: 0
          const res = await CashDeskService.index(query)
          if (res && res.data && res.data.cashdesk !== undefined){
            this.users = res.data.cashdesk
          }
          this.card = {}
          this.card.businessYear = this.$store.state.businessYear
          this.card.items = this.users
          this.doSum()
          
          this.saving = false
          this.errorMatch = false
          this.editedItem = Object.assign({}, this.defaultItem)
        } catch (err) {
          this.error = err
        }
      },
      editItem (item) {
        const newOb = {}
        this.editedItem = {...newOb}
        this.errorMatch = false
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.selectEmp = this.emps.find(emp => emp.id === this.editedItem.EmployeeId)
        this.dialog = true
        this.confpassword = ''
      },
      async deleteItem (item) {
        try {
          const index = this.users.indexOf(item)
          const warningMessage = item.Description
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.users.splice(index, 1)) {
            const query = {}
            query.CompanyId = this.$store.state.companyid ? this.$store.state.companyid: 0
            query.id = item.id  ? item.id : 0
            await CashDeskService.delete(query)
            this.initialize()
          }
        } catch(err) {
          this.error = err
        }
      },
      findEmp() {
        if (this.selectEmp && this.selectEmp.id) {
          const selEmp = this.emps.filter(emp => emp.id === this.selectEmp.id)
          const sE = selEmp[0]
          if (selEmp) {
            this.editedItem.EmployeeId = sE.id
            this.editedItem.Employeeemail = sE.eemail
            this.editedItem.First = sE.First
            this.editedItem.Last = sE.Last
            this.editedItem.phone = sE.phone
            this.editedItem.EmployeeVatID = sE.VatID
          }
        } else {
          this.editedItem.EmployeeId = null
          this.editedItem.Employeeemail = null
          this.editedItem.First = null
          this.editedItem.Last = null
          this.editedItem.phone = null
          this.editedItem.EmployeeVatID = null
        }
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      checkMatch () {
        if (this.editedItem.password && this.confpassword &&  this.confpassword === this.editedItem.password) {
          this.errorMatch = null
          this.save()
        } else {
          this.errorMatch = 'Error. Passoword and Confirmation Password are NOT equal!'
          this.valid = false        
        }
      },

      async save () {
        try {
          this.editedItem.documentTime = dayjs(new Date()).format('HH:mm:ss')
          if (this.editedItem.ExRate && this.editedItem.ExRate !== 0) {
            this.editedItem.CashInFore = this.editedItem.CashIn ? round((this.editedItem.CashIn / this.editedItem.ExRate),2) : 0
            this.editedItem.CashOutFore = this.editedItem.CashOut ? round((this.editedItem.CashOut / this.editedItem.ExRate),2) : 0
          }
           let doneOK = false
           if (this.editedIndex > -1) {
              await CashDeskService.put(this.editedItem)
              doneOK = true
            } else {
              delete this.editedItem.id
              this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
              this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              await CashDeskService.post(this.editedItem)
              doneOK = true
            }
            if (doneOK) {
              this.initialize()
              this.close()
            }
        } catch(err) {
          this.error = err
          
        }
       
      },
    },
  }
</script>