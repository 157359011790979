<template>
  <v-form 
    ref="form"
    v-model="valid"
    lazy-validation> 
   <v-container>
    <v-layout row wrap  justify-center>
      <v-flex xs12 sm12 md12 lg9 xl6>

          <v-layout row wrap>
            <h2>{{lang.BankAccounts}}</h2>
          </v-layout>

          <br />

        <v-layout row>
          <v-text-field
            v-model="bankaccount.BankName"
            :rules="[rules.required]"
            :label="lang.BankName"
          ></v-text-field>
        </v-layout>

        <v-layout row>
          <v-text-field
            v-model="bankaccount.IBAN"
            :rules="[rules.required]"
            :label="lang.IBAN"
          ></v-text-field>
        </v-layout>

        <v-layout row>
          <v-text-field
            v-model="bankaccount.SWIFT"
            :rules="[rules.required]"
            :label="lang.SWIFT"
          ></v-text-field>
        </v-layout>

          <v-layout row>
            <v-select :label="lang.PrimaryCurrency" :items="currnecyType" v-model="bankaccount.HomeCurrency" value="bankaccount.HomeCurrency"></v-select>
          </v-layout>

        <v-layout row>
           <v-text-field
              v-model="bankaccount.BankAccountDescription"
              :label="lang.BankAccountDescription"
            ></v-text-field>
        </v-layout>

          <v-layout row>
            <v-textarea :label="lang.BankAdress" required v-model="bankaccount.BankAdress"/>
          </v-layout>

          <v-layout row >
            <v-flex xs2 class="py-4 px-2">
                {{lang.AccountID}}
            </v-flex>
            <v-flex xs9 class="py-0 px-2">
              <v-autocomplete
                v-bind:items="account4Loc"
                v-model="selectA4"
                :label="lang.AccountID"
                item-text="acc4Acc"
                item-value="account4"
                return-object
                persistent-hint
              ></v-autocomplete>
            </v-flex>

            <v-flex xs1 class="py-0 px-2">

            </v-flex>

            <v-flex xs1 class="py-0 px-2">

            </v-flex>
          </v-layout>

          <v-checkbox v-model="bankaccount.DefaultAccount" :label="lang.DefaultAccount"></v-checkbox>
  
        <br />
        <div class="red" v-if="error">Server error: {{error}}</div>
        <br />

        <v-btn
        :disabled="!valid"
        color="success"
        class="mr-4"
        @click="validate">{{langC.Save}}</v-btn>

        <v-btn color="blue" @click="bankaccountList">{{langC.Back}}</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
  </v-form>
</template>

<script>
import BankAccountService from '@/services/BankAccountService'
import langEn from './BankAccountDescEN'
import langHr from './BankAccountDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'

export default {
  name: 'bankaccountcreate',
  data () {
    return {
      valid: true,
      lazy: false,
      rules: {
        required: value => !!value || '*'
      },
      menu: false,
      modal: false,
      msg: '',
      bankaccount: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        IBAN: '',
        SWIFT: '',
        BankName: '',
        BankAccountDescription: '',
        BankAdress: '',
        LedgerAccount: '',
        DefaultAccount: false,
        HomeCurrency: '',
        ForeignCurrency: '',
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: '',
        LastUpdate: null
      },
      account4Loc: [],
      selectA4: '',
      error: '',
      lang: {},
      langC: {},
      currnecyType: [
        { text: 'Kn', value: 'Kn' },
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      mainledgerAna: {}
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    this.mainledgerAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
    this.account4Loc = this.$store.state.account4s ? this.$store.state.account4s : []
    // I'm editing, need to load data for edit
    if (this.$store.state.editItem === 1) {
      if (this.$store.state.bankaccountid) {
        try {
          const dbBankAccount = await BankAccountService.show(this.$store.state.bankaccountid)
          this.bankaccount = dbBankAccount.data.BankAccount
          //this.locUpdatedAt = this.$moment(this.bankaccount.updatedAt).format('DD.MM.YYYY HH:MM')
          this.locUpdatedAt = new Date()
        } catch (error) {
          this.error = error
        }
      }
    }
  },
  methods: {
    validate () {
      if (this.$refs.form.validate()) {
        //this.snackbar = true
        this.register()
      }
    },
    async register () {
      try {
        // I'm not editing, fill all data
        if (this.$store.state.editItem !== 1) {
          if (this.$store.state.user.id) {
            this.bankaccount.UserId = this.$store.state.user.id
          }
          if (this.$store.state.user.email) {
            this.bankaccount.email = this.$store.state.user.email
          }
          if (this.$store.state.companyid) {
            this.bankaccount.CompanyId = this.$store.state.companyid
          }
          if (this.$store.state.companyName) {
            this.bankaccount.CompanyNameDatabase = this.$store.state.companyName
          }
          if (this.$store.state.businessYear) {
            this.bankaccount.BusinessYear = this.$store.state.businessYear
          }
        }
        if (this.selectA4.account4) {
          this.bankaccount.LedgerAccount = this.selectA4.account4
        }
        if (!this.bankaccount.BankName || !this.bankaccount.IBAN || !this.bankaccount.SWIFT) {
          this.error = this.lang.ErrorG
        }
        if (!this.error) {
          if (this.$store.state.editItem !== 1) {
            await BankAccountService.post(this.bankaccount)
          }
          if (this.$store.state.editItem === 1) {
            await BankAccountService.put(this.bankaccount)
          }
          this.$store.dispatch('seteditItem', 0)
          this.$router.push({
            name: 'bankaccount'
          }).catch(err => {
            // eslint-disable-next-line
            console.log(err);
          })
        }
      } catch (error) {
        this.error = error
      }
    },
    async bankaccountList () {
      try {
        this.$store.dispatch('seteditItem', 0)
        await this.$router.push({
          name: 'bankaccount'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  computed: {
    formIsValid () {
      return this.bankaccount.bankaccountDescription !== ''
    }
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
