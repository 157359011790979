<template>
 <v-container fluid grid-list-md>
      <v-layout row>
      <v-flex d-flex xs12 sm6 md4>
          <v-btn color="primary" @click="account1list" class="mr-2">
                <span v-if="this.$store.state.language == 'EN'">
                Account 1
                </span>
                <span v-if="this.$store.state.language == 'HR'">
                Klase (1)
                </span>
          </v-btn>
          <v-btn color="primary" @click="account2list" class="mr-2">
                <span v-if="this.$store.state.language == 'EN'">
                Account 2
                </span>
                <span v-if="this.$store.state.language == 'HR'">
                Grupe (2)
                </span>
          </v-btn>                    
          <v-btn color="primary" @click="account3list" class="mr-2">
                <span v-if="this.$store.state.language == 'EN'">
                Account 3
                </span>
                <span v-if="this.$store.state.language == 'HR'">
                Sintetika (3)
                </span>
          </v-btn>
          <v-btn color="primary" @click="account4list" class="mr-2">
                <span v-if="this.$store.state.language == 'EN'">
                Account 4 (or more)
                </span>
                <span v-if="this.$store.state.language == 'HR'">
                Analitika (4 ili vise)
                </span>
          </v-btn>
          <v-btn color="primary" @click="mainledgerprojecttype">
                <span v-if="this.$store.state.language == 'EN'">
                  Projects
                </span>
                <span v-if="this.$store.state.language == 'HR'">
                  Projekti
                </span>
          </v-btn>     

      </v-flex>
      <v-flex d-flex xs12 sm6 md4>
        
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  name: 'accountchoose',
  data () {
    return {
      menu: false,
      modal: false,
      msg: '',
      valid: true,
      account: { },
      error: null,
      localError: false,
      privGroups: []
    }
  },
  async mounted () {
  },
  methods: {
    async account1list () {
      try {
        await this.$router.push({
          name: 'account1'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async account2list () {
      try {
        await this.$router.push({
          name: 'account2'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async account3list () {
      try {
        await this.$router.push({
          name: 'account3'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async account4list () {
      try {
        await this.$router.push({
          name: 'account4'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async mainledgerprojecttype () {
      try {
        await this.$router.push({
          name: 'mainledgerprojecttype'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  computed: {
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
