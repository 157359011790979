import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('mainledgerprojecttype', {
      params: query
    })
  },
  index2 (query) {
    return Api().get('mainledgerprojecttype/activ', {
      params: query
    })
  },
  choose (query) {
    return Api().get('mainledgerprojecttype/choose', {
      params: query
    })
  },
  show (id) {
    return Api().get(`mainledgerprojecttype/${id}`)
  },
  post (newtype) {
    return Api().post('mainledgerprojecttype', newtype)
  },
  put (type) {
    return Api().put(`mainledgerprojecttype/${type.id}`, type)
  },
  delete (id) {
    return Api().delete(`mainledgerprojecttype/${id}`)
  },
}