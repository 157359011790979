<template>
  <v-row style="border-top:solid 1px;" no-gutters>
    <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="text-left">
    </v-col>

    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
      {{ formatPrice(total1 / exRate) }}
    </v-col>
    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
      {{ formatPrice(total2 / exRate) }}
    </v-col>
    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
      {{ formatPrice(total5 / exRate) }}
    </v-col>
  </v-row>
</template>


<script>


export default {
  name: '',
  props: ['total1', 'total2', 'total5', 'showEUR'],
  data: ()=> ({
    exRate: 1
  }),
  mounted () {
    this.exRate = this.$store.state.defExRate ? this.$store.state.defExRate : 1
    if (!this.showEUR) {
      this.exRate = 1
    }
  },
  methods: {
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },

  },
  computed: {
  },
  watch: {

  },
  components: {

  }

}

</script>