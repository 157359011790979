<template>
  <v-layout row justify-center>
    <v-flex xs12>
      <div>
        Tu treba ubaciti pdf make
    </div>
    </v-flex>
  </v-layout>
</template>

<script>


export default {
  name: 'documentpdf',
  data () {
    return {
      msg: '',
      dialog: false,
      currentPage: 0,
      pageCount: 0,
      src: undefined,
      numPages: undefined
    }
  },
  components: {
  },
  mounted () {
  },
  methods: {
    async deleteDocument () {
      try {
        if (this.$store.state.documentid) {
          await this.$router.push({
            name: 'document'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async documentlist () {
      try {
        await this.$router.push({
          name: 'document'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
