<template>
  <v-container fluid grid-list-md>
  <v-row no-gutters justify="center" class="pa-0">
    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
      <v-row>
        <v-flex xs3 sm3>
          <h2>
              <span v-if="this.$store.state.language == 'EN'">
              Employees diary
              </span>
              <span v-if="this.$store.state.language == 'HR'">
              Radnici dnevnik
              </span>
          </h2>
          <v-btn text color="success" @click="newTask()" class="mr-2">
              <span v-if="this.$store.state.language == 'EN'">
                Add new task
              </span>
              <span v-if="this.$store.state.language == 'HR'">
                Dodaj novi zadatak
              </span>
          </v-btn>
          <v-btn text color="success" @click="analysis()">
              <span v-if="this.$store.state.language == 'EN'">
                Analysis
              </span>
              <span v-if="this.$store.state.language == 'HR'">
                Analiziraj
              </span>
          </v-btn>
        </v-flex>
        <v-flex xs3 sm3>

        </v-flex>
        <v-flex xs6 sm6 class="text-xs-right">
          <router-link to="/crm/emplday/new" >
              <v-btn fab dark class="indigo">
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
          </router-link>
        </v-flex>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
          <empldaylist />
        </v-col>
      </v-row>

    </v-col>
   </v-row>
  </v-container>
</template>

<script>
import empldaylist from './EmplDayList'

export default {
  name: 'emplday',
  data () {
    return {
      msg: ''
    }
  },
  mounted () {

  },
  methods: {
    newTask () {
      this.$store.dispatch('setretPath', 'emplday')
      this.$router.push({
        name: 'taskcreate'
      })
    },
    analysis () {
      this.$store.dispatch('setretPath', 'emplday')
      this.$router.push({
        name: 'employeeanalysis'
      })
    }
  },
  computed: {

  },
  watch: {

  },
  components: {
    empldaylist
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
