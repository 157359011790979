<template>
  <v-container fluid grid-list-md>

  </v-container>
</template>

<script>
  // import LineChart from './LineChart.js'
    import MainLedgerService from '@/services/MainLedgerService'

  export default {
    components: {
      // LineChart
    },
    data () {
      return {
        mainledgerAna: {},
        datacollection: null,
        chartData: {
          labels: ["Siječanj",	"Veljača",	"Ožujak",	"Travanj",	"Svibanj",	"Lipanj",	"Srpanj", "Kolovoz", "Rujan", "Listopad", "Studeni", "Prosinac"],
          datasets: [
            {
              label: 'IRE - 2020',
              data: [5000,	2150,	1342,	4050,	3522,	5241,	2259,	2157,	6545, 5841, 1900, 2900],
              fill: false,
              borderColor: 'rgba(75, 192, 192, 1)',
              backgroundColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1
            },
            {
              label: 'IRE - 2019',
              data: [3500,	1000,	542,	6050,	4220,	3500,	1259,	250,	1800, 12000, 6000, 8000],
              fill: false,
              borderColor: 'rgba(255, 206, 86, 1)',
              backgroundColor: 'rgba(255, 206, 86, 1)',
              borderWidth: 1
            }
          ]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [ {
              gridLines: {
                display: false
              }
            }]
          },
          legend: {
            display: true
          },
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    mounted () {
      this.sumby()
    },
    methods: {
      async sumby() {
        const inCurrYear = []
        const inPrevYear = []
        this.mainledgerAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        this.mainledgerAna.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : null
        const currYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
        const prevYear = currYear - 1
        const divide = prevYear === 2022 ? 7.5345 : 1
        this.mainledgerAna.BusinessYear = currYear
        this.mainledgerAna.BookTypeID = 10
        await MainLedgerService.sumby(this.mainledgerAna)
        .then(res => {
          if (res.data && res.data.documents && res.data.documents.length > 0) {
            res.data.documents.map(doc=> {
              //let check = this.$moment(doc.month, 'YYYY-MM-DD')
              //let month = check.format('M')
              inCurrYear.push(parseFloat(doc.totalMonth))
            })
          }
        })

        this.mainledgerAna.BusinessYear = prevYear
        await MainLedgerService.sumby(this.mainledgerAna)
        .then(res => {
          if (res.data && res.data.documents && res.data.documents.length > 0) {
            res.data.documents.map(doc=> {
              //let check = this.$moment(doc.month, 'YYYY-MM-DD')
              //let month = check.format('M')
              inPrevYear.push(parseFloat(doc.totalMonth)/divide)
            })
          }
        })

        this.datacollection = {
          labels: ["Siječanj",	"Veljača",	"Ožujak",	"Travanj",	"Svibanj",	"Lipanj",	"Srpanj", "Kolovoz", "Rujan", "Listopad", "Studeni", "Prosinac"],
          datasets: [
            {
              label: 'Ire ' + currYear,
              data: [...inCurrYear],
              fill: false,
              borderColor: 'rgba(75, 192, 192, 1)',
              backgroundColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1
            }, {
              label: 'Ire ' + prevYear,
              data: [...inPrevYear],
              fill: false,
              borderColor: 'rgba(255, 206, 86, 1)',
              backgroundColor: 'rgba(255, 206, 86, 1)',
              borderWidth: 1
            }
          ]
        }

      },
      fillData () {
        this.datacollection = {
          labels: [this.getRandomInt(), this.getRandomInt()],
          datasets: [
            {
              label: 'Data One',
              backgroundColor: '#f87979',
              data: [this.getRandomInt(), this.getRandomInt()]
            }, {
              label: 'Data One',
              backgroundColor: '#f87979',
              data: [this.getRandomInt(), this.getRandomInt()]
            }
          ]
        }
      },
      getRandomInt () {
        return Math.floor(Math.random() * (50 - 5 + 1)) + 5
      }
    }
  }
</script>

<style>
  .small {
    max-width: 600px;
    margin:  150px auto;
  }
</style>
