const docEN = {
  WareHouse: 'Warehouse',
  WareHouses: 'Warehouses',
  WareHouseEmpty: 'Warehouse table is empty',
  AddWareHouse: 'Add New Warehouse',
  WareHouseName: 'WareHouse Name',
  WareHouseStatus: 'WareHouse Status',
  WareHouseLocked: 'WareHouse Locked',
  WareHouseLockedDate: 'WareHouse Locked Date',
  WareHouseLockedTime: 'WareHouse Locked Time',
  WareHouseLockedBy: 'WareHouse Locked By',
  WareHouseUpdateDate: 'WareHouse Start Date',
  WareHouseUpdateTime: 'WareHouse Update Time',
  WareHouseUpdateBy: 'WareHouse Update By',
  NewWareHouse: 'New Warehouse',
  EditWareHouse: 'Edit Warehouse',
  User: 'User',
  Users: 'Users/warehouse',
  Actions: 'Actions',
  UserId: 'User id',
  email: 'email',
  UserIdRight: 'User right',
  EnteredInDatabase: 'Entered date',
  AccessLevel: 'Access Level (1 max, 5 low)',
  Priv1: '1 max privileges',
  Priv2: '2 medium to max privileges',
  Priv3: '3 medium privileges',
  Priv4: '4 low to midium privileges',
  Priv5: '5 low privileges',
  CompanyId: 'Company Id',
  CompanyNameDatabase: 'Company Name Database',
  NewWareHouseUser: 'New WareHouse User',
  EditWareHouseUser: 'Edit WareHouse User',
  WareHouseId: 'WareHouse Id',
  SelectWarehouse: 'Select warehouse',
  SelectUser: 'Select user'
}

export default docEN