import Api from '@/services/Api'
// import { log } from 'util';

export default {
  index (query) {
    return Api().get('bankaccount', {
      params: query
    })
  },
  show (bankaccountid) {
    return Api().get(`bankaccount/${bankaccountid}`)
  },
  post (newbankaccount) {
    return Api().post('bankaccount', newbankaccount)
  },
  put (bankaccount) {
    return Api().put(`bankaccount/${bankaccount.id}`, bankaccount)
  },
  delete (bankaccountid) {
    return Api().delete(`bankaccount/${bankaccountid}`)
  },
  choose (query) {
    return Api().get('bankaccount/choose', { params: query })
  },
  isdefault (query) {
    return Api().get('bankaccount/isdefault', {
      params: query
    })
  }
}
