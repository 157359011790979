<template>
<div>
  <v-progress-circular
    v-if="this.saving"
    indeterminate
    color="green"
  ></v-progress-circular>
  <v-row align="center" class="list px-3 mx-auto">
  <v-col cols="4" sm="2">
      <v-select
        v-model="searchOption"
        :items="searchOptions"
        :label="langC.SearchBy"
        clearable
        @change="handleSearchOption"
      ></v-select>
    </v-col>
    <v-col cols="12" sm="2">
      <v-text-field v-model="searchName" :label="langC.SearchBy"></v-text-field>
    </v-col>
    <v-col cols="12" sm="1">
      <v-btn small @click="page = 1; retrievePartners();">
        {{langC.Search}}
      </v-btn>
    </v-col>
    <v-col cols="5" sm="5">
      <v-pagination
        v-model="page"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      ></v-pagination>
      
    </v-col>
    <v-col cols="4" sm="1">
      Total:{{totalItems}}
    </v-col>
    <v-col cols="4" sm="1">
      <v-select
        v-model="pageSize"
        :items="pageSizes"
        label="Items per Page"
        @change="handlePageSizeChange"
      ></v-select>
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="12" sm="12">
      <v-card class="mx-auto" tile>

        <v-data-table
          dense
          :headers="headers"
          :items="items"
          disable-pagination
          :hide-default-footer="true"
          class="elevation-1"
          @click:row="navigateTo"
        >
          <template v-slot:[`item.itemService`]="{ item }">
            <span v-if="item.itemService"><v-icon>mdi-check</v-icon></span>
          </template>
          <template v-slot:[`item.itemPrice`]="{ item }">
              <span>{{ localMoney(item.itemPrice) }}</span>
          </template>
          <template v-slot:[`item.itemPriceWithVAT`]="{ item }">
              <span>{{ localMoney(item.itemPriceWithVAT) }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
  </div>
</template>

<script>
import ItemService from '@/services/ItemService'
import langEn from './itemDescEn'
import langHr from './itemDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import moneyFormat from '@/snippets/moneyFormat'

export default {
  name: 'itemlist',
  data () {
    return {
      lang: {},
      langC: {},
      items: [],
      searchName: "",
      headers: [],
      page: 1,
      totalPages: 0,
      pageSize: 30,
      pageSizes: [10, 30, 50],
      searchOption: '',
      searchOptions: [],
      totalItems: 0,
      saving: false
    }
  },
  mounted () {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      this.searchOptions.push(
        {text: this.lang.ItemID, value: 'itemID', width: '200px'},
        {text: this.lang.ItemName, value: 'itemName'},
        {text: this.lang.GroupName, value: 'GroupName'},
        {text: this.lang.ItemCode, value: 'ItemCode', width: '150px'},
        {text: this.lang.ItemGroup, value: 'itemGroup', width: '50px'},
      )

      this.headers.push(
        {text: this.lang.ItemID, value: 'itemID', width: '200px'},
        {text: this.lang.ItemName, value: 'itemName'},
        {text: this.lang.GroupName, value: 'GroupName'},
        {text: this.lang.ItemCode, value: 'ItemCode', width: '150px'},
        {text: this.lang.ItemGroup, value: 'itemGroup', width: '50px'},
        {text: this.lang.ItemService, value: 'itemService', width: '50px'},
        {text: this.lang.ItemPrice, align: 'right', value: 'itemPrice', type: 'decimal', width: '150px'},
        {text: this.lang.ItemVAT1Percentage, value: 'itemVAT1Percentage', type: 'decimal', width: '50px'},
        {text: this.lang.ItemPriceWithVAT, align: 'right', value: 'itemPriceWithVAT', type: 'decimal', width: '150px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'}
      )
      this.$store.dispatch('seteditItem', 0)
      if (this.$store.state.searchState) {
        this.searchName = this.$store.state.searchState.searchName ? this.$store.state.searchState.searchName : null
        this.page = this.$store.state.searchState.page ? this.$store.state.searchState.page + 1 : 0
        this.pageSize = this.$store.state.searchState.pageSize ? this.$store.state.searchState.pageSize : 30
        this.searchItem = this.$store.state.searchState.searchOption ? this.$store.state.searchState.searchOption : null
        this.$store.dispatch('setSearchState', null)
      }
      this.retrievePartners()
  },
  methods: {
      localMoney(moneyToFormat) {
        return moneyFormat(moneyToFormat)
      },
      setQuery() {
        const query = {}
        query.CompanyId = this.$store.state.companyid
        query.searchName = this.searchName ? this.searchName.trim() : null
        query.page = this.page ? this.page - 1 : 0
        query.pageSize = this.pageSize ? this.pageSize : 30
        query.searchItem = this.searchOption ? this.searchOption : null
        return query
      },
      async retrievePartners() {
        
        const query = this.setQuery()
        this.saving = true
        const {data} = await ItemService.indexlist(query)
        this.saving = false

        if (data && data.items !== undefined && data.items.length > 0) {
          this.items = data.items
          this.totalItems = data.totalPages
          this.totalPages = parseInt(data.totalPages / this.pageSize) + 1
        }
      },

      handlePageChange(value) {
        this.page = value;
        this.retrievePartners();
      },

      handlePageSizeChange(size) {
        this.pageSize = size;
        this.page = 1;
        this.retrievePartners();
      },

      handleSearchOption(option) {
        this.searchOption = option
      },
      navigateTo (params) {
        this.$store.dispatch('setSearchState', this.setQuery())
        this.$store.dispatch('setItemid', params.id)
        this.$router.push({
          name: 'itemview'
        })
      }

  },
  computed: {
  },
  watch: {

  },
  components: {
 
  }
}
</script>
