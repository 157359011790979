<template>
    <v-container fluid grid-list-md>
      <v-row no-gutters justify="center" class="pa-0">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
          <v-row no-gutters>
            <h2>
              <span v-if="this.$store.state.editItem  === 0">
                {{langC.CreateNew}} - {{ haveForm }}
              </span>
              <span v-if="this.$store.state.editItem === 1">
                {{langC.Edit}} - {{ haveForm }}
              </span>                  
            </h2>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                {{lang.CompanyName}} <span style="font-weight:bold">{{travelorder.CompanyNameDatabase}}</span>
            </v-col>
            <v-col cols="12" sm="4" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                {{lang.BusinessYear}} <span style="font-weight:bold">{{travelorder.BusinessYear}}</span>
            </v-col>
            <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="pr-3" >

              </v-col>
              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="pr-3" >

              </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row no-gutters>
              <v-col cols="12" sm="6" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.PPO_1"
                    v-model="forms.tax1"
                  ></v-text-field>
              </v-col>
               <v-col cols="12" sm="6" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field class="pr-2"
                    :label="lang.PPO_2"
                    v-model="forms.tax2"
                  ></v-text-field>
               </v-col>
          </v-row>

           <v-row no-gutters>
              <v-col cols="12" sm="6" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.PPO_3"
                    v-model="forms.tax3"
                  ></v-text-field>
              </v-col>
               <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 px-2">
                  <v-text-field class="pr-2"
                    :label="lang.PPO_4"
                    v-model="forms.tax4"
                  ></v-text-field>
               </v-col>
          </v-row>

           <v-row no-gutters>
              <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.PPO_5"
                    v-model="forms.tax5"
                  ></v-text-field>
              </v-col>
               <v-col cols="12" sm="4" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field class="pr-2"
                    :label="lang.PPO_6"
                    v-model="forms.tax6"
                  ></v-text-field>
               </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="4" md="3" xs="3" lg="3" xl="3" class="py-0 px-2" v-if="formType == 1">
                <v-select
                  :items="quarters"
                  :label="lang.PPO_7"
                  dense
                  v-model="forms.quarters"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4" md="3" xs="3" lg="3" xl="3" class="py-0 px-2" v-if="formType != 1">
                <v-select
                  :items="months"
                  :label="lang.PDVS_7"
                  dense
                  v-model="forms.month"
                ></v-select>
              </v-col>
               <v-col cols="12" sm="4" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-select
                  :items="years"
                  :label="lang.PPO_71"
                  dense
                  v-model="forms.year"
                ></v-select>
               </v-col>
          </v-row>


         <v-divider></v-divider>
         <v-btn
            class="mr-3"
            color="primary"
            @click="register" >
            {{langC.Save}}
          </v-btn>

          <v-btn
            color="green"
            @click="formlist">
            {{langC.Back}}
          </v-btn>
      </v-col>
      </v-row>
       <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        bottom
        :color="color"
        >
        {{ text }}
      </v-snackbar>
    </v-container>
</template>

<script>

import FormService from '@/services/FormService'
import langEn from './formDescEn'
import langHR from './formDescHR'
import commEn from '@/language/commonEN'
import commHR from '@/language/commonHR'
import { defForm, defNeWForm } from '../MainLedger/mainledgerHelper'
import {months, years} from '@/snippets/allSnippets'
import dayjs from 'dayjs'

export default {
  name: 'formheadcreate',
  props: ['formType'],
  data () {
    return {
      menu: false,
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      lang: {},
      langC: {},
      travelorder: {},
      mainQuery: {
      },
      haveNew: false,
      required: (value) => !!value || 'Required.',
      rules: {
        required: value => !!value || '*'
      },
      all: 1,
      haveForm: '',
      forms: {},
      frm: {},
      months: [],
      years: [],
      quarters: [1,2,3,4]
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = langEn
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = langHR
      this.langC = commHR;
    }
     if (this.formType === 1) {
      this.haveForm = this.lang.FormPPO
    }
    if (this.formType === 2) {
      this.haveForm = this.lang.FormPDVS
    }
    if (this.formType === 3) {
      this.haveForm = this.lang.FormZP
    }
    this.months = months()
    this.years = years()
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc ? this.$store.state.documentActivLoc : ' '
    if (this.$store.state.companyid) {
      this.CompanyId = this.$store.state.companyid
      this.mainQuery.CompanyId = parseInt(this.$store.state.companyid)
    }
    
    if (this.$store.state.editItem === 0) {
      this.forms = {...defForm(), ...defNeWForm()}
      this.forms.tax3 = this.$store.state.companyActiv.CompanyVATID ? this.$store.state.companyActiv.CompanyVATID : ''
      this.forms.tax4 = this.$store.state.companyActiv.CompanyName ? this.$store.state.companyActiv.CompanyName : ''
      this.forms.tax5 = this.$store.state.companyActiv.CompanyStreet ? this.$store.state.companyActiv.CompanyStreet : ''
      this.forms.tax5 = this.$store.state.companyActiv.CompanyBuildingNameNumber ? this.forms.tax5 + ' ' + this.$store.state.companyActiv.CompanyBuildingNameNumber : this.forms.tax5
      this.forms.tax5 = this.$store.state.companyActiv.CompanyPostcode ? this.forms.tax5 + ', ' + this.$store.state.companyActiv.CompanyPostcode : this.forms.tax5
      this.forms.tax5 = this.$store.state.companyActiv.CompanyCity ? this.forms.tax5 + ' ' + this.$store.state.companyActiv.CompanyCity : this.forms.tax5
      this.forms.formDate = dayjs(new Date()).format('YYYY-MM-DD')
      //this.forms.formTime = dayjs(new Date()).format('HH:mm:SS')
      this.forms.formName = this.haveForm
      this.forms.formType = this.formType
      this.haveNew = true
    }
    if (this.$store.state.editItem === 1) {
      const {data} = await FormService.show(this.$store.state.mainledgerid)
      this.forms = data.form && Object.keys(data.form).length !== 0 ? data.form : {}
    }
  },
  methods: {

    async register() {
      if (this.$store.state.editItem === 0) {   
        await FormService.post(this.forms)
        .then(res => {
          this.forms = res.data.forms ? res.data.forms : {}
          if (this.$store.state.language === 'EN') {
            this.text = 'New form has been saved.'
            this.color = 'green'
            this.snackbar = true
          }
          if (this.$store.state.language === 'HR') {
            this.text = 'Nova obrazac je snimljen u bazu.'
            this.color = 'green'
            this.snackbar = true
          }
          this.timeout = 1000
          this.$router.push({
            name: 'formlist', params: {formType: this.formType }
          })
        })
        .catch(err => {
          this.timeout = 4000
          this.error = err.data
          this.text = 'Error1! Cannot save document to database. ' + err
          this.color = 'red'
          this.snackbar = true
        })
      }        
      if (this.$store.state.editItem === 1) {
        await FormService.put(this.forms)
        this.timeout = 1000
        this.$router.push({
          name: 'formlist', params: {formType: this.formType }
        })
      }
    },
    async formlist () {
      try {
        await this.$router.push({
          name: 'formlist', params: {formType: this.formType }
        })
      } catch (err) {
        this.error = err.data
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async deleteForm() {
      if (this.$store.state.editItem === 1) {
        try {
          const warningMessage = this.haveForm + ' za ' + (this.formType !== 1? this.forms.month : this.forms.quarters) + '/' + this.forms.year
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!')) {
            const { data } = await FormService.delete(this.forms.id)
            if (data.message) {
              if (this.$store.state.language === 'EN') {
                this.text = 'Form doc has been deleted.'
                this.color = 'green'
                this.snackbar = true
              }
              if (this.$store.state.language === 'HR') {
                this.text = 'Obrazac je obrisan iz baze.'
                this.color = 'green'
                this.snackbar = true
              }
              this.timeout = 1000
              this.$router.push({
                name: 'formlist', params: {formType: this.formType }
              })
            }
          }
        } catch(err) {
          this.text = 'Error! ' + err.data
          this.color = 'red'
          this.snackbar = true
        }
        
      } else {
          this.timeout = 4000
          this.text = 'Error! Ne mozete brisati NOVI nalog!! Cannot delete NEW travel order!!' 
          this.color = 'red'
          this.snackbar = true
        }


    }
  },
  computed: {
  },
  watch: {

  },
  components: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
