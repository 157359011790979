<template>
<div>
  <v-container fluid grid-list-md>
    <v-data-table
      :headers="headers"
      :items="itemsPOS"
      :search="search"
      sort-by="id"
      class="elevation-1 headline"
    >
      <template v-slot:[`item.itemName`]="{ item }">
        <span class="headline">{{ item.itemName }}</span>
      </template>
      <template v-slot:[`item.itemUnit`]="{ item }">
        <span class="headline">{{ item.itemUnit }}</span>
      </template>
      <template v-slot:[`item.itemPcsMinus`]="{ item }">
        <span class="headline">{{ item.itemPcsMinus }}</span>
      </template>
      <template v-slot:[`item.itemPriceWithVAT`]="{ item }">
        <span class="headline">{{ item.itemPriceWithVAT }}</span>
      </template>
      <template v-slot:[`item.itemDiscountPercentage1`]="{ item }">
        <span class="headline">{{ item.itemDiscountPercentage1 }}</span>
      </template>
      <template v-slot:[`item.itemVAT1Percentage`]="{ item }">
        <span class="headline">{{ item.itemVAT1Percentage }}</span>
      </template>
      <template v-slot:[`item.itemAmountWithVAT`]="{ item }">
        <span class="headline">{{ item.itemAmountWithVAT }}</span>
      </template>
      <template v-slot:[`item.itemService`]="{ item }">
          <span><v-icon >{{item.itemService ? 'mdi-check' : ''}} </v-icon></span>
      </template>
      <template v-slot:no-data>
        {{ lang.POSEmpty }}
      </template>
    </v-data-table>
  </v-container>
  </div>
</template>

<script>
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dateFormat from '@/snippets/dateFormat'

  export default {
    name: 'documentposagainitems',
    data: () => ({
      search: '',
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      lang: {},
      langC: {},
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: "",
        databaseDocID: 0
      },
      pcsLoc: 0,
      dialog: false,
      headers: [ 
      ],
      editedIndex: -1,
      editedItem: {},
      privitems: [ ],
      valid: true,
      select: { },
      itemsLoc: [],
      itemsPOS: [],
      itemLoc: {},
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewAccount4: this.lang.EditAccount4
      },
    },

    watch: {
    },
    mounted() {
      if (this.$store.state.language === 'EN') {
        this.lang = langEn
        this.langC = commEn
      }
      if (this.$store.state.language === 'HR') {
        this.lang = langHr
        this.langC = commHr
      }

      this.itemsPOS = this.$store.state.documentActivItems ? this.$store.state.documentActivItems : []
      this.headers.push(
        {
          text: this.lang.ItemName,
          align: 'left',
          sortable: true,
          value: 'itemName',
          width: '550px'
        },
        { text: this.lang.ItemUnit, align: 'center', value: 'itemUnit', width: '55px' },
        { text: this.lang.ItemService, align: 'center', value: 'itemService', width: '50px' },
        { text: this.lang.ItemPcsMinus, align: 'right', value: 'itemPcsMinus', width: '200px' },
        { text: this.lang.ItemPriceWithVAT, align: 'right', value: 'itemPriceWithVAT', width: '200px' },
        { text: this.lang.ItemDiscountPercentage1, align: 'right', value: 'itemDiscountPercentage1', width: '80px' },
        { text: this.lang.ItemVAT1Percentage, align: 'right', sortable: true, value: 'itemVAT1Percentage', width: '70px' },
        { text: this.lang.ItemAmountWithVAT, align: 'right', value: 'itemAmountWithVAT', width: '200px' },
        { text: this.lang.Actions, value: 'action', sortable: false },
      )
    },

    created () {
    },
    methods: {
      round (value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
      },
      getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min) ) + min;
      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      }
    },
  }
</script>