<template>
  <v-container fluid grid-list-md>
    <v-layout row>

      <v-flex xs12 sm10 md4 lg4 ex4>
        <v-card ref="form">
          <v-card-text>

              <v-layout row >
                <v-flex xs12 class="py-0 px-2">                
                  <span v-if="this.$store.state.language == 'EN'">
                    <h2>Employee Analysis </h2>
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    <h2>Radnici analiza</h2>
                    </span>
                </v-flex>
              </v-layout>

              <v-layout row >
                <v-flex xs4 class="py-0 px-2">                
                  <span v-if="this.$store.state.language == 'EN'">
                    Company:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Firma:
                    </span>
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{task.CompanyNameDatabase}}, Id:{{task.CompanyId}}</span>
                </v-flex>
              </v-layout>

                <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Business Year:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Poslovna godina:
                    </span>                  
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{task.BusinessYear}}</span>
                </v-flex>
              </v-layout>

              <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    User:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Korisnik:
                    </span>                  
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{task.First}} {{task.Last}}, {{task.email}}</span>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <br>

          <v-layout row v-if="this.$store.state.language == 'EN'">
              <v-flex xs6 sm6>
                <v-dialog
                  ref="dialog1"
                  persistent
                  v-model="modal1"
                  lazy
                  full-width
                  width="290px"
                  :return-value.sync="employeeAna.docFromDate"
                >
                  <v-text-field
                    slot="activator"
                    label="From date"
                    v-model="employeeAna.docFromDate"
                    prepend-icon="mdi-calendar"
                  ></v-text-field>
                  <v-date-picker v-model="employeeAna.docFromDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.dialog1.save(employeeAna.docFromDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs6 sm6>
                <v-dialog
                  ref="dialog2"
                  persistent
                  v-model="modal2"
                  lazy
                  full-width
                  width="290px"
                  :return-value.sync="employeeAna.docToDate"
                >
                  <v-text-field
                    slot="activator"
                    label="To date"
                    v-model="employeeAna.docToDate"
                    prepend-icon="mdi-calendar"
                  ></v-text-field>
                  <v-date-picker v-model="employeeAna.docToDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.dialog2.save(employeeAna.docToDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>

          </v-layout>           

            <v-layout row v-if="this.$store.state.language == 'HR'">
              <v-flex xs6 sm6>
                <v-dialog
                  ref="dialog1"
                  persistent
                  v-model="modal1"
                  lazy
                  full-width
                  width="290px"
                  :return-value.sync="employeeAna.docFromDate"
                >
                  <v-text-field
                    slot="activator"
                    label="Od datuma"
                    v-model="employeeAna.docFromDate"
                    prepend-icon="mdi-calendar"
                  ></v-text-field>
                  <v-date-picker v-model="employeeAna.docFromDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.dialog1.save(employeeAna.docFromDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs6 sm6>
                <v-dialog
                  ref="dialog2"
                  persistent
                  v-model="modal2"
                  lazy
                  full-width
                  width="290px"
                  :return-value.sync="employeeAna.docToDate"
                >
                  <v-text-field
                    slot="activator"
                    label="Do datuma"
                    v-model="employeeAna.docToDate"
                    prepend-icon="mdi-calendar"
                  ></v-text-field>
                  <v-date-picker v-model="employeeAna.docToDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.dialog2.save(employeeAna.docToDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
            </v-layout>

                 <v-layout row >
                  <v-flex xs3 class="py-4 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Employee Id:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Zaposlenik:
                    </span>                    
                  </v-flex>
                  <v-flex xs9 class="py-0 px-2">
                    <v-autocomplete
                      :items="employes"
                      v-model="selectEm"
                      label="Select employee"
                      item-text="hashtag"
                      item-value="id"
                      return-object
                      persistent-hint
                    ></v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row >
                  <v-flex xs3 class="py-4 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Enter type:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Vrsta upisa:
                    </span>
                  </v-flex>
                  <v-flex xs3 class="py-0 px-2">
                    <v-select
                      v-bind:items="actiontypes"
                      v-model="selectTT"
                      label="Select action type"
                      single-line
                      item-text="text"
                      item-value="id"
                      return-object
                      persistent-hint
                    ></v-select>
                  </v-flex>
                  <v-flex xs3 class="py-4 px-2">
                  </v-flex>
                </v-layout> 

                <v-layout row >
                  <v-flex xs3 class="py-4 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Work time:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Radno vrijeme:
                    </span>
                  </v-flex>
                  <v-flex xs5 class="py-0 px-2">
                    <v-select
                      v-bind:items="actionwork"
                      v-model="selectTD"
                      label="Select work type"
                      single-line
                      item-text="text"
                      item-value="id"
                      return-object
                      persistent-hint
                    ></v-select>
                  </v-flex>
                  <v-flex xs3 class="py-0 px-2">
                  </v-flex>
                </v-layout> 

                <div class="pink darken-2" v-html="error" />

          </v-card-text>
          <v-divider class="mt-1"></v-divider>
          <v-card-actions>

            <v-btn
              color="green"
              @click="employeelist">
              <span v-if="this.$store.state.language == 'EN'">
              Back
              </span>
              <span v-if="this.$store.state.language == 'HR'">
              Natrag
              </span>  
            </v-btn>

            <v-btn
              color="green"
              @click="print">
              <span v-if="this.$store.state.language == 'EN'">
              Print
              </span>
              <span v-if="this.$store.state.language == 'HR'">
              Printaj
              </span>  
            </v-btn>

            <v-btn
              color="green"
              text
              @click="rewind">
              Test
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              class="primary"
              :disabled="!formIsValid"
              @click="register">
              <span v-if="this.$store.state.language == 'EN'">
              Analysis
              </span>
              <span v-if="this.$store.state.language == 'HR'">
              Analiziraj
              </span>  
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-flex>

      <v-flex xs12 sm10 m8 lg8 ex8>

        <div id="printArea">

          <v-container >

                <v-layout row style="border-bottom:solid 1px;">
                    <v-flex xs5>
                        <h4><b><i>{{comp.CompanyName}}</i></b></h4>
                        {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}   <br />                
                        {{comp.CompanyPostcode}} {{comp.CompanyCity}}<br />
                         OIB: {{comp.CompanyVATID}}
                    </v-flex>
                    <v-flex xs3>
                      <span v-if="this.$store.state.language == 'EN'">
                      <h2>Employes Analysis </h2>
                      </span>
                      <span v-if="this.$store.state.language == 'HR'">
                      <h2>Radnici analiza</h2>
                      </span>
                  </v-flex>
                    <v-flex xs 4>
                      <p class="text-md-left">
                          Datum izvještaja: {{PrintDate}}<br />
                          Telefon: {{comp.CompanyPhones}}<br />
                          email: {{comp.Companyemail}}<br />
                      </p>
                    </v-flex>
              </v-layout>

            <v-layout row style="border-bottom:solid 1px; border-top:solid 1px;">
                  <v-flex xs2 class="text-md-left">
                    Prezime i ime
                  </v-flex>
                  <v-flex xs1>
                    Radnja
                  </v-flex>
                  <v-flex xs1 class="text-md-left">
                    Datum
                  </v-flex>
                  <v-flex xs2 class="text-md-left">
                    Oblik
                  </v-flex>
                  <v-flex xs1 class="text-md-left">
                    Radni sati
                  </v-flex>
                  <v-flex xs2 class="text-md-left">
                    Vrsta ugovora
                  </v-flex>
                  <v-flex xs1 class="text-md-right">
                    Trajanje
                  </v-flex>
                  <v-flex xs1 class="text-md-right">
                    Datum unosa
                  </v-flex>
                  <v-flex xs1 class="text-md-right">
                    
                  </v-flex>
                  <v-flex xs1 class="text-md-right">
                    
                  </v-flex>
              </v-layout>

                <v-layout row v-for="item in items" :key="item.id">
                  <v-flex xs1 class="text-xs-left" style="font-size:10px;">
                      {{ item.Last }}
                    </v-flex>
                    <v-flex xs1 class="text-xs-left" style="font-size:10px;">
                      {{ item.First }}
                    </v-flex>
                    <v-flex xs1 class="text-xs-left" style="font-size:10px;">
                      {{ item.Id }}
                      <span v-if="item.ActionTypeID === 1">
                        Prijava
                      </span>
                      <span v-if="item.ActionTypeID === 2">
                        Odjava
                      </span>
                      <span v-if="item.ActionTypeID === 3">
                        Promjena
                      </span>
                    </v-flex>
                    <v-flex xs1 class="text-xs-left" style="font-size:10px;">
                      {{ item.ForDate }}
                    </v-flex>
                    <v-flex xs2 class="text-xs-left" style="font-size:10px;">
                      {{ item.WorkingHoursTypeName }}
                    </v-flex>
                    <v-flex xs2 class="text-xs-center" style="font-size:10px;">
                      {{ localMoney( item.WorkingHours ) }}
                    </v-flex>

                    <v-flex xs2 class="text-xs-left" style="font-size:10px;">
                      <span v-if="item.AlarmDate">
                        Određeno
                      </span>
                      <span v-else>
                        Neodređeno
                      </span>
                    </v-flex>
                    <v-flex xs1 class="text-xs-left" style="font-size:10px;">
                      {{ item.AlarmDate }}
                    </v-flex>
                    <v-flex xs1 class="text-xs-left" style="font-size:10px;">
                      {{ item.OpenDate }}
                    </v-flex>
                    
                    <v-flex xs1 class="text-xs-right" style="font-size:10px;">
                      
                    </v-flex>
                    <v-flex xs1 class="text-xs-right" style="font-size:10px;">
                      
                    </v-flex>
         
            </v-layout>
            <v-layout row style="border-top:solid 1px;">
              <v-flex xs1  class="text-xs-right" style="font-size:10px;">

              </v-flex>
              <v-flex xs4 class="text-xs-right" style="font-size:10px;"> 

              </v-flex>

                    <v-flex xs1 class="text-xs-center" style="font-size:12px;">
                     {{ localMoney(Total1 ) }}
                    </v-flex>
                    <v-flex xs1 class="text-xs-right" style="font-size:12px;">
                      
                    </v-flex>
                    <v-flex xs1 class="text-xs-right" style="font-size:12px;">
                      
                    </v-flex>
                    <v-flex xs1 class="text-xs-right" style="font-size:12px;">
                      
                    </v-flex>
            </v-layout>

          </v-container>

            </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import UserService from '@/services/UserService'
import EmplDayService from '@/services/EmplDayService'
import EmployeeService from '@/services/EmployeeService'
//import {checkPartners} from '../MainLedger/mainledgerHelper'
import { testLastUpdate } from '@/services/TestLastUpdate'
import moneyFormat from '@/snippets/moneyFormat'

export default {
  name: 'employeeanalysis',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      task: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        First: '',
        Last: '',
        EmployeeId: 0,
        Employeeemail: '',
        Employeehashtag: '',
        partnerId: 0,
        partnerName: '',
        partnerNameShort: '',
        taskTypeID: 0,
        taskTypeDescription: '',
        taskDepartmentID: 0,
        taskDepartmentDescription: '',
        taskShortDescription: '',
        taskDetailDescription: '',
        taskOpenDate: null,
        taskOpenTime: null,
        taskForDate: null,
        taskForTime: null,
        taskAlarmDate: null,
        taskAlarmTime: null,
        taskFor1UserId: 0,
        taskFor1Useremail: '',
        taskFor1UserSendEmail: false,
        taskFor2UserId: 0,
        taskFor2Useremail: '',
        taskFor2UserSendEmail: false,
        taskFor3UserId: 0,
        taskFor3Useremail: '',
        taskFor3UserSendEmail: false,
        taskFor4UserId: 0,
        taskFor4Useremail: '',
        taskFor4UserSendEmail: false,
        taskFor5UserId: 0,
        taskFor5Useremail: '',
        taskFor5UserSendEmail: false,
        taskClosedDate: null,
        taskClosedTime: null,
        taskClosedUserId: 0,
        taskClosed: false,
        taskClosedSendEmail: false,
        taskSpentHours: 0,
        taskChargeHours: 0,
        taskCharged: false,
        taskChargedByUserId: 0,
        taskChargedByUseremail: '',
        taskChargedDate: null,
        taskChargedTime: null,
        taskNotFinished: false,
        taskNotFinishedReason: '',
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: '',
        LastUpdate: null
      },
      temptask: {},
      task2: {},
      actiontypes: [
        { text: 'Prijava (1)', value: 1 },
        { text: 'Odjava (2)', value: 2 },
        { text: 'Promjena (3)', value: 3 }
      ],
      actionwork: [
        { text: 'Puno radno vrijeme (1)', value: 1 },
        { text: 'Nepuno radno vrijeme (2)', value: 2 }
      ],
      employeeAna: {
        CompanyId: 0,
        BusinessYear: 0,
        UserId: 0,
        partnerId: 0,
        employeeName: '',
        docFromDate: null,
        docToDate: null
      },
      comp: {},
      error: null,
      localError: false,
      newUser: {},
      confpassword: '',
      size: 'sm',
      privitems: [
        { text: 'Upiti', value: 'InquirySales' },
        { text: 'Ponude', value: 'QuoteSales' },
        { text: 'Narudžbe', value: 'OrderSales' },
        { text: 'Otpremnice', value: 'DeliveryNoteSales' },
        { text: 'Fakture', value: 'InvoiceSales' },
        { text: 'Ugovori', value: 'ContractSales' }
      ],
      required: (value) => !!value || 'Required.',
      partners: [],
      employes: [],
      tasktypes: [],
      taskdepartments: [],
      users: [],
      items: [],
      CompanyId: 0,
      selectPa: {},
      selectEm: {},
      selectTT: {},
      selectTD: {},
      selectUs: {},
      selectUs2: {},
      selectUs3: {},
      mainQuery: {
        CompanyId: 1
      },
      havePath: '',
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      Total6: 0,
      PrintDate: null
    }
  },
  components: {
  },
  async mounted () {
    // need five arrays with partners, employes, tasktype, taskdepartment, users
    if (this.$store.state.companyid) {
      this.CompanyId = await this.$store.state.companyid
      this.employeeAna.CompanyId = await this.$store.state.companyid
      this.mainQuery.CompanyId = await this.$store.state.companyid
    }
    this.comp = this.$store.state.companyActiv
    if (this.CompanyId > 0) {
      //await checkPartners(0)
      await testLastUpdate()
      if (this.$store.state.partnerStore) {
        this.partners = this.$store.state.partnerStore
      }
      // this.partners = (await PartnerService.index()).data.partners
    }
    if (this.$store.state.user.id) {
      this.task.UserId = this.$store.state.user.id
      this.employeeAna.UserId = this.$store.state.user.id
    }
    if (this.$store.state.user.email) {
      this.task.email = this.$store.state.user.email
    }
    if (this.$store.state.user.First) {
      this.task.First = this.$store.state.user.First
    }
    if (this.$store.state.user.Last) {
      this.task.Last = this.$store.state.user.Last
    }
    if (this.$store.state.companyid) {
      this.task.CompanyId = this.$store.state.companyid
      this.employeeAna.CompanyId = this.$store.state.companyid
    }
    if (this.$store.state.companyName) {
      this.task.CompanyNameDatabase = this.$store.state.companyName
    }
    if (this.$store.state.businessYear) {
      this.task.BusinessYear = this.$store.state.businessYear
      this.employeeAna.BusinessYear = this.$store.state.businessYear
    }
    this.employes = (await EmployeeService.choose(this.mainQuery)).data.employes
    this.users = (await UserService.choose(this.mainQuery)).data.users
    let locDate1 = new Date()
    this.PrintDate = dayjs().format('DD.MM.YYYY, H:mm')
    locDate1.getDate()
    locDate1.getMonth() + 1
    locDate1.getFullYear()
    this.task.taskOpenDate = dayjs().format('YYYY-MM-DD')
  },
  computed: {
    formIsValid () {
      return this.employeeAna.docFromDate !== null &&
        this.employeeAna.docToDate !== null &&
        this.employeeAna.CompanyId !== ''
    }
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    print () {
      this.$htmlToPaper('printArea')
    },
    async register () {
      try {
        // do analysis from database
        this.localError = false
        if (this.selectPa.id) {
          this.employeeAna.partnerId = this.selectPa.id
        }
        if (this.selectUs.id) {
          this.employeeAna.UserId = this.selectUs.id
        }
        if (this.selectEm.id) {
          this.employeeAna.EmployeeId = this.selectEm.id
        }
        if (this.selectTT.value) {
          this.employeeAna.ActionTypeID = this.selectTT.value
        }
        if (this.selectTD.value) {
          this.employeeAna.WorkingHoursTypeID = this.selectTD.value
        }
        this.Total1 = 0
        this.Total2 = 0
        this.items = (await EmplDayService.employeeana(this.employeeAna)).data.employee
        if (this.items) {
          this.items.map(item => {
            this.Total1 += parseFloat(item.WorkingHours)
          })
        }
      } catch (error) {
        this.error = 'Greska u konekciji na bazu'
      }
    },
    close () {
      this.show = false
    },
    async employeelist () {
      try {
        this.havePath = this.$store.state.retPath
        this.$store.dispatch('setretPath', '')
        if (this.havePath !== 'employee' && this.havePath.length > 0) {
          await this.$router.push({
            name: this.havePath
          })
        } else {
          await this.$router.push({
            name: 'home'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async rewind () {
      try {
        await EmplDayService.rewind(this.mainQuery)
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
