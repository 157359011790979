<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs12 sm12 m12 lg12 ex12>
            <v-row style="border-bottom:solid 1px;">
                <v-flex xs5>
                    <h4><b><i>{{comp.CompanyNameShort}}</i></b></h4>
                    {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}   <br />                
                    {{comp.CompanyPostcode}} {{comp.CompanyCity}}<br />
                      OIB: {{comp.CompanyVATID}}
                </v-flex>
                <v-flex xs3>
                  <span v-if="this.$store.state.language == 'EN'">
                    <h2>Saldo by partners </h2>
                    <span v-if="type === 1"><h2>Suppliers</h2></span>
                    <span v-if="type === 2"><h2>Buyers</h2></span>
                    Od:{{formatDate(head.docFromDate)}} Do:{{formatDate(head.docToDate)}}
                  </span>
                  <span v-if="this.$store.state.language == 'HR'">
                    <h2>Stanje po partnerima</h2>
                    <span v-if="type === 1"><h2>Dobavljači</h2></span>
                    <span v-if="type === 2"><h2>Kupci</h2></span>
                    Od:{{formatDate(head.docFromDate)}} Do:{{formatDate(head.docToDate)}}
                  </span>
              </v-flex>
                <v-flex xs 4>
                  <p class="text-md-left">
                      Datum izvještaja: {{PrintDate}}<br />
                      Telefon: {{comp.CompanyPhones}}<br />
                      email: {{comp.Companyemail}}<br />
                      <v-btn @click="print()" class="mr-5">Printaj</v-btn>
                  </p>
                </v-flex>
          </v-row>

          <div v-if="showCard">
            <v-row justify="center">
              <v-dialog
                  v-model="showCard"
                  width="1200"
                >
                <v-card>
                  <v-card-title>
                    <span class="text-h5">.</span>
                  </v-card-title>
                  <v-card-text>
                    <MainBookCompCard2 :key="componentKey" :head="head" :partnerVATID= "partnerVATID" :type="type" :defCurrency="defCurrency" :showOpen="showOpen"/>
                  </v-card-text>
                  <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green-darken-1"
                    variant="text"
                    @click="showCard = false"
                  >
                    Zatvori
                  </v-btn>
                </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </div>

          <v-row style="border-bottom:solid 1px;" class="mt-5">
            <v-col  xs="12" sm="12"  md="12" lg="3" xl="3">
              Valuta: {{defCurrency}}
            </v-col>
             <v-col  xs="3" sm="3"  md="1" lg="1" xl="1" class="text-left">
              OIB
            </v-col>
            <v-col  xs="3" sm="3"  md="1" lg="1" xl="1" class="text-right">
              Uk.računi
            </v-col>
            <v-col  xs="3" sm="3"  md="1" lg="1" xl="1" class="text-right">
              <span v-if="type === 1">Isplate </span>
              <span v-if="type === 2">Računi dospjelo</span>
            </v-col>
            <v-col  xs="3" sm="3"  md="1" lg="1" xl="1" class="text-right">
              <span v-if="type === 1">Računi dospjelo</span>
              <span v-if="type === 2">Uplate</span>
            </v-col>
            <v-col  xs="3" sm="3"  md="3" lg="1" xl="1" class="text-right">
              Saldo dospjelo 
            </v-col>
            <v-col  xs="3" sm="3"  md="3" lg="1" xl="1" class="text-right">
              Nedospjelo 
            </v-col>
            <v-col  xs="3" sm="3"  md="3" lg="1" xl="1" class="text-right">
              Ukupni dug 
            </v-col>
          
          </v-row>
          <v-row dense class="mt-5">
            <v-col  xs="12" sm="12"  md="12" lg="4" xl="4">
              
            </v-col>
            <v-col  xs="3" sm="3"  md="1" lg="1" xl="1" class="text-right">
             1 (2+5)
            </v-col>
            <v-col  xs="3" sm="3"  md="1" lg="1" xl="1" class="text-right">
              2
            </v-col>
            <v-col  xs="3" sm="3"  md="1" lg="1" xl="1" class="text-right">
              3
            </v-col>
            <v-col  xs="3" sm="3"  md="3" lg="1" xl="1" class="text-right">
              4
            </v-col>
            <v-col  xs="3" sm="3"  md="3" lg="1" xl="1" class="text-right">
              5
            </v-col>
            <v-col  xs="3" sm="3"  md="3" lg="1" xl="1" class="text-right">
              6 (4+5)
            </v-col>
          
          </v-row>
          
          <v-row style="border-bottom:solid 1px;" class="mt-5">

            <v-col xs="12" sm="12"  md="12" lg="4" xl="4" class="text-left">
              Total:
            </v-col>
            <v-col  xs="3" sm="3"  md="1" lg="1" xl="1" class="text-right">
             {{ formatPrice(fTotal1) }}
            </v-col>
            <v-col  xs="3" sm="3"  md="1" lg="1" xl="1" class="text-right">
              <span v-if="type === 1"> {{ formatPrice(fTotal3) }} </span>
              <span v-if="type === 2"> {{ formatPrice(fTotal2) }} </span>
            </v-col>
            <v-col  xs="3" sm="3"  md="1" lg="1" xl="1" class="text-right">
              <span v-if="type === 1"> {{ formatPrice(fTotal2) }} </span>
             <span v-if="type === 2"> {{ formatPrice(fTotal3) }} </span>
            </v-col>
            <v-col  xs="3" sm="3"  md="3" lg="1" xl="1" class="text-right">
              {{ formatPrice(fTotal4) }}
            </v-col>
            <v-col  xs="3" sm="3"  md="3" lg="1" xl="1" class="text-right">
              {{ formatPrice(fTotal5) }}
            </v-col>
            <v-col  xs="3" sm="3"  md="3" lg="1" xl="1" class="text-right">
              {{ formatPrice(fTotal6) }}
            </v-col>
          
          </v-row>


          <v-row dense v-for="item in items" :key="item.id">
              <v-col cols="12" xs="12" sm="12"  md="12" lg="3" xl="3" class="text-left">
              <h4><b><i>{{item.partner && item.partner.partnerName ? item.partner.partnerName : ''}}</i></b></h4>
              </v-col>
              <v-col cols="12" xs="12" sm="12"  md="6" lg="1" xl="1" class="text-left">
               <small>{{item.partner && item.partner.partnerVATID ? item.partner.partnerVATID : ''}}</small>
              </v-col>
              <v-col  xs="3" sm="3"  md="1" lg="1" xl="1" class="text-right">
                {{ formatPrice(item.allInv) }}
              </v-col>
              <v-col  xs="3" sm="3"  md="1" lg="1" xl="1" class="text-right">
                <span v-if="type === 1"> {{ formatPrice(item.allPay) }} </span>
                <span v-if="type === 2"> {{ formatPrice(item.allDue) }}  </span>
              </v-col>
               <v-col  xs="3" sm="3"  md="1" lg="1" xl="1" class="text-right">
                <span v-if="type === 1"> {{ formatPrice(item.allDue) }}  </span>
                <span v-if="type === 2"> {{ formatPrice(item.allPay) }} </span>
              </v-col>
               <v-col  xs="3" sm="3"  md="3" lg="1" xl="1" class="text-right">
                {{ formatPrice(item.saldo) }}
              </v-col>
              <v-col  xs="3" sm="3"  md="3" lg="1" xl="1" class="text-right">
                {{ formatPrice(item.allNotDue) }}
              </v-col>
              <v-col  xs="3" sm="3"  md="3" lg="1" xl="1" class="text-right">
                {{ formatPrice(item.allOpen) }}
              </v-col>
               <v-col  xs="6" sm="6"  md="6" lg="1" xl="1" class="text-right" v-if="!hideLinks">
                <v-btn text color="primary" class="pb-1" @click="showDetailCard(item.partnerVATID, false)">Kartica</v-btn>
              </v-col>
              <v-col  xs="6" sm="6"  md="6" lg="1" xl="1" class="text-right" v-if="!hideLinks">
                <v-btn text color="primary" class="pb-1" @click="showDetailCard(item.partnerVATID, true)">Otvoreno</v-btn>
              </v-col>
            
        </v-row>

      </v-flex>
        <MainBookCompCardPrint ref="prnt" :card="card" :type="type" :fTotal1="fTotal1" :fTotal2="fTotal2" :fTotal3="fTotal3" :fTotal4="fTotal4" :fTotal5="fTotal5" :fTotal6="fTotal6" :defCurrency="defCurrency"/>
        <Mainbookcompcard2print ref="prnt2" />
    </v-layout>
  </v-container>
</template>

<script>
import ledgerEN from '../MainLedger/MainLedgerDescEN'
import ledgerHR from '../MainLedger/MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import MainBookCompCard2 from "./MainBookCompCard2"
import MainBookCompCardPrint from './MainBookCompCardPrint'
import Mainbookcompcard2print from './MainBookCompCard2Print'
// import MainLedgerService from '@/services/MainLedgerService'
import { dateFormat, parseNum } from '@/snippets/allSnippets'
import dayjs from 'dayjs'

export default {
  name: 'mainledgercompcard',
  props: ['items', 'head', 'fTotal1', 'fTotal2', 'fTotal3', 'fTotal4', 'fTotal5', 'fTotal6', 'type', 'defCurrency', 'hideLinks'],
  data () {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      comp: {},
      error: null,
      localError: false,
      newUser: {},
      size: 'sm',
      havePath: '',
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      PrintDate: null,
      PrintToday: null,
      lang: {},
      langC: {},
      saving: false,
      pItems: [],
      mainQuery2: {},
      partner: {},
      partners: [],
      card: {},
      mainQuery: {},
      printType: 1,
      ffTotal1: 0,
      ffTotal2: 0,
      ffTotal3: 0,
      ffTotal4: 0,
      ffTotal5: 0,
      showCard: false,
      showCard2: false,
      partnerVATID: '',
      componentKey: 0,
      showOpen: false
    }
  },
  components: {
    MainBookCompCardPrint,
    MainBookCompCard2,
    Mainbookcompcard2print
    // MainLedgerPrintCard10
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }

    this.PrintDate = dayjs(new Date()).format('llll')
    this.PrintToday = this.formatDate(dayjs(new Date()).format('YYYY-MM-DD'))
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    this.ffTotal1 = this.fTotal1 ? this.fTotal1 : 0
    this.ffTotal2 = this.fTotal2 ? this.fTotal2 : 0
    this.ffTotal3 = this.fTotal3 ? this.fTotal3 : 0
    this.card = {...this.head}
    this.card.items = this.items

    const typePartner = this.head.fromAccount ? this.head.fromAccount.substr(0,2) : ''
    this.showOpzstat = typePartner === '12' || typePartner === '16' ? true : false
  },
  computed: {

  },
  methods: {
    print(){
      this.printType = 1
      this.$refs.prnt.print()
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    showDetailCard(parVATID, showOpen) {
       if (!parVATID) {
        alert('ERROR! Partner not selected!')
        return
      }
      this.showCard = false
      this.partnerVATID = parVATID ? parVATID : null
      this.componentKey += 1
      this.showCard = true
      this.showOpen = showOpen

    },
    printCard() {
      this.card = {...this.head}
      this.card.items = this.items
      //this.card = {...this.card, ...this.lastOne}
      this.card.Total1 = this.Total1
      this.card.Total2 = this.Total2
      this.card.Total5 = this.Total5
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    parseNum2(param) {
      return parseNum(param)
    },

    close () {
      this.show = false
    },

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
