const docHR = {
  TravelOrder: 'Putni nalog',
  TravelOrders: 'Putni nalozi',
  partnerId : ' ',
  partnerName: 'Partner ',
  partnerNameShort: 'Partner',
  TravelOrderId : 'Broj naloga',
  OrderDate: 'Datum naloga',
  eId : 'Šifra',
  eemail: 'email',
  phone: 'Telefon ',
  eFirst: 'Ime',
  eLast: 'Prezime',
  eVatID: 'OIB',
  eJOPP: 'Broj JOPPa',
  WorkPlace: 'Na radom mjestu',
  TravelTo: 'Na službeno putovanje u',
  TravelDate: 'Otputovati će dana',
  TravelTask: 'Sa zadatkom',
  TravelLastDays: 'Trajanje Dana',
  TravelLastDaysLett: 'slovima ',
  TravelVeichle: 'Za prijevoz može koristiti',
  TravelVeichleBrand: 'Marka vozila',
  TravelVeichleReg: 'Reg.oznaka',
  ExpensesForwardHomeC: 'Predujam putnih troškova kn',
  ExpensesForwardForeC: 'Predujam putnih troškova EUR',
  ExpensesForwardHome: 'Predujam putnih troškova',
  ReportInDays: 'Nakon povratka sa puta u roku od',
  ApprovedBy: 'Odobrio',
  ApprovedByFirst: 'Odobrio Ime',
  ApprovedByLast: 'Odobrio Prezime',
  ApprovedByemail: 'email',
  TravelApproved: 'Odobreno',
  TravelCosting: 'Obračun putnih troškova',
  CostingDays: 'Obračun dnevnica',
  FinTravel1: 'Za obavljeno službeno putovanje',
  FinTravel2: 'Početak',
  Travel2Date: 'Krenula/o dana',
  FinTravel3: 'Završetak ',
  Travel3Date: 'Vratila/o dana',
  FinStartDate: 'Odlazak datum ',
  FinStartTime: 'Odlazak sat',
  FinEndDate: 'Dolazak datum ',
  FinEndTime: 'Dolazak sat',
  FinHours: 'Broj sati',
  FinDays: 'Broj Kn dnev.',
  FinDaysAmount: 'Iznos Kn dnev.',
  FinDaysTotal: 'Ukupna Kn svota',
  FinDaysEUR: 'Broj EUR dnev.',
  FinDaysAmountEUR: 'Iznos EUR dnev.',
  FinDaysTotalEUR: 'Ukupna EUR svota',
  CostingTravles: 'Obračun prijevoznih troškova',
  FinTrStart: 'Početno stanje brojila',
  FinTrEnd: 'Završno stanje brojila',
  CostByKm: 'Kuna/km',
  Relation: 'Relacija',
  FinRel1From: 'Od',
  FinRel1To: 'Do',
  FinRel1Km: 'km',
  FinRel1KmKn: 'kn/km',
  FinRel1KmEur: 'EUR/km',
  FinRel1Total: 'kn',
  FinRel1TotalEur: 'EUR',
  FinRel2From: 'Od',
  FinRel2To: 'Do',
  FinRel2Km: 'km',
  FinRel2KmKn: 'kn/km',
  FinRel2KmEur: 'EUR/km',
  FinRel2Total: 'kn',
  FinRel2TotalEur: 'EUR',
  FinRel3From: 'Od',
  FinRel3To: 'Do',
  FinRel3Km: 'km',
  FinRel3KmKn: 'kn/km',
  FinRel3KmEur: 'EUR/km',
  FinRel3Total: 'kn',
  FinRel3TotalEur: 'EUR',
  FinRel4From: 'Od',
  FinRel4To: 'Do',
  FinRel4Km: 'km',
  FinRel4KmKn: 'kn/km',
  FinRel4KmEur: 'EUR/km',
  FinRel4Total: 'kn',
  FinRel4TotalEur: 'EUR',
  FinRel5From: 'Od',
  FinRel5To: 'Do',
  FinRel5Km: 'km',
  FinRel5KmKn: 'kn/km',
  FinRel5KmEur: 'EUR/km',
  FinRel5Total: 'kn',
  FinRel5TotalEur: 'EUR',
  OtherExp: 'Ostali troškovi',
  FinOther1: 'Ostalo',
  FinOther1Kn: 'kn',
  FinOther1Eur: 'EUR',
  FinOther2: 'Ostalo',
  FinOther2Kn: 'kn',
  FinOther2Eur: 'EUR',
  FinOther3: 'Ostalo',
  FinOther3Kn: 'kn',
  FinOther3Eur: 'EUR',
  FinOther4: 'Ostalo',
  FinOther4Kn: 'kn',
  FinOther4Eur: 'EUR',
  FinOther5: 'Ostalo',
  FinOther5Kn: 'kn',
  FinOther5Eur: 'EUR',
  OtherExpTotal: 'Ostali troškovi ukupno:',
  FinTotal: 'Ukupno',
  FinTotalKn: 'kn',
  FinTotalEur: 'EUR',
  FinPayReturn: 'Za isplatu/povrat',
  FinPayReturnKn: 'kn',
  FinPayReturnEur: 'EUR',
  FinReport: 'Izvješće sa službenog putovanja',
  FinEndPlace: 'Mjesto obračuna',
  FinEndDate2: 'Datum obračuna',
  PrintMadeBySmall: 'Podnositelj',
  PrintControlBy: 'Kontrolirao:',
  PrintCFO: 'Nalogodavac:',
  ErrorUser: 'Greška! Ne možete izraditi putni nalog jer nemate upisane radnike!',
  SelectEmployee: 'Odaberite radnika',
  SelectApp: 'Odobrio',
  Finished: 'Zavrsen'
}

export default docHR