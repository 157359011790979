<template>
  <div>
    <v-container fluid grid-list-md>
    <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
    <v-btn text color="primary" @click="backTo">Natrag</v-btn>
    <v-card>
      <v-card-title>
        <span v-if="this.$store.state.language == 'EN'">
          List 2, Employee total: {{totals.total}}  Activ: <strong>{{totals.totalActiv}}</strong>  Full time/day: <strong>{{totals.totalFullTime}} (hours:{{totals.totalFullTimeWH}})</strong>  Part time/day: <strong>{{totals.totalPartTime}} (hours:{{totals.totalPartTimeWH}})</strong>
        </span>
        <span v-if="this.$store.state.language == 'HR'">
          Lista 2, Radnici ukupno: {{totals.total}}  Aktivnih: <strong>{{totals.totalActiv}}</strong>  Dnevno puno r.v.: <strong>{{totals.totalFullTime}} (sati:{{totals.totalFullTimeWH}})</strong>   Dnevno nepuno r.v.: <strong>{{totals.totalPartTime}} (sati:{{totals.totalPartTimeWH}})</strong>
        </span>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          v-model="search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        item-key="emplyeeid"
        :search="search"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
        :items-per-page="50"
        @click:row="navigateTo"
        :mobile-breakpoint="0" 
      >
        <template v-slot:[`item.Activ`]="{ item }">
          <span v-if="item.Activ"><v-icon>mdi-check</v-icon></span>
        </template>
        <template v-slot:[`item.Black`]="{ item }">
          <span v-if="item.Black"><v-icon>mdi-check</v-icon></span>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
          From {{ pageStart }} to {{ pageStop }}
        </template>
      </v-data-table>
    </v-card>
    </v-container>
  </div>
</template>

<script>
import EmployeeService from '@/services/EmployeeService'
// import langEn from './documentDescEn'
// import langHr from './documentDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"

export default {
  name: 'employeelist2',
  data () {
    return {
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      error: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
      totals: {}
    }
  },
  async mounted () {
    try {
      if (this.$store.state.language === "EN") {
        //this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        //this.lang = langHr
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      this.$store.dispatch("seteditItem", 0)
      this.saving = true
      this.items = (await EmployeeService.index2(this.mainQuery)).data.employees
      // eslint-disable-next-line
        console.log('items', this.items)
      this.totals = (await EmployeeService.choose2(this.mainQuery)).data.totalEmp
      // eslint-disable-next-line
        console.log('totals', this.totals)
      this.saving = false
      if (this.$store.state.language === 'EN') {
        this.headers.push(
          {text: 'Last name', value: 'Last', width: '150px'},
          {text: 'First name', value: 'First', width: '150px'},
          {text: 'email', value: 'eemail', width: '150px'},
          {text: 'Phone', value: 'phone', width: '150px'},
          {text: 'VAT id', value: 'VatID', width: '150px'},
          {text: 'Birth date', value: 'DateOfBirht', width: '50px', type: 'date', dateInputFormat: 'YYYY-MM-DD', dateOutputFormat: 'DD.MM.YYYY'},
          {text: 'Activ', value: 'Activ', type: 'boolean', formatFn: this.formatFn, width: '50px'},
          {text: 'Offline', value: 'Black', type: 'boolean', formatFn: this.formatFn, width: '50px'},
          {text: 'Company ID', value: 'CompanyId', type: 'number', width: '50px'},
          {text: 'DbId', value: 'id', type: 'number', width: '50px'}
        )
      }
      if (this.$store.state.language === 'HR') {
        // console.log(' radim push 2 hr naziva ', this.$store.state.language)
        this.headers.push(
          {text: 'Last name', value: 'Last', width: '150px'},
          {text: 'First name', value: 'First', width: '150px'},
          {text: 'Prezime i ime', value: 'employeeFullName', width: '300px'},
          {text: 'email', value: 'eemail', width: '150px'},
          {text: 'Telefon', value: 'phone', width: '150px'},
          {text: 'OIB', value: 'VatID', width: '150px'},
          {text: 'Datum rođenja', value: 'DateOfBirht', width: '50px', type: 'date', dateInputFormat: 'YYYY-MM-DD', dateOutputFormat: 'DD.MM.YYYY'},
          {text: 'Aktivan', value: 'Activ', type: 'boolean', formatFn: this.formatFn, width: '50px'},
          {text: 'Honorar', value: 'Black', type: 'boolean', formatFn: this.formatFn, width: '50px'},
          {text: 'Company ID', value: 'CompanyId', type: 'number', width: '50px'},
          {text: 'DbId', value: 'id', type: 'number', width: '50px'}
        )
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  },
  methods: {
    navigateTo (params) {
      this.$store.dispatch('setActionType', 1)
      this.$store.dispatch('setEmployeeid', params.id)
      this.$router.push({
        name: 'employeeview'
      })
    },
    backTo () {
      this.$store.dispatch('setEmployeeid', null)
      this.$router.push({
        name: 'employee'
      })
    },
    formatFn (value) {
      if (value === true) {
        return 'Yes'
      } else {
        return ''
      }
    },
    formatFnHR (value) {
      if (value === true) {
        return 'Da'
      } else {
        return ''
      }
    }
  },
  computed: {

  },
  watch: {

  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
