<template>
  <div class="task">
    <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
      <v-row no-gutters>
         <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="py-0 px-2">
          <h2>{{haveFormHead}}</h2>
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
          <v-btn
            text color="primary"
            @click="formindex()">
            {{langC.Back}}
          </v-btn>
        </v-col>
      </v-row>
      <v-card>
        <v-card-title>
          {{ documentNameLoc }}  {{haveForm}}
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
          <v-btn fab dark fixed right class="indigo" @click="newform">
              <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"
          >
          <template v-slot:[`item.RecTotal`]="{ item }">
            <span class="title green--text">{{ formatPrice(item.RecTotal) }}</span>
          </template>
          <template v-slot:[`item.ExpTotal`]="{ item }">
            <span class="title indigo--text">{{ formatPrice(item.ExpTotal) }}</span>
          </template>
 
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>
  </div>
</template>

<script>
// import axios from 'axios'
import FormService from '@/services/FormService'
import langEn from './formDescEn'
import langHR from './formDescHR'
import commEn from '@/language/commonEN'
import commHR from '@/language/commonHR'
import dateFormat from '@/snippets/dateFormat'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'formlist',
  props: ['formType'],
  data () {
    return {
      documentName: '',
      documentNameLoc: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {},
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
      required: (value) => !!value || 'Required.',
      rules: {
        required: value => !!value || '*'
      },
      haveFormHead: '',
      haveForm: ''
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = langEn
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = langHR
      this.langC = commHR;
    }
    if (this.formType === 1) {
      this.haveForm = this.lang.FormPPO
      this.haveFormHead = this.lang.FormPPODetail
    }
    if (this.formType === 2) {
      this.haveForm = this.lang.FormPDVS
      this.haveFormHead = this.lang.FormPDVSDetail
    }
    if (this.formType === 3) {
      this.haveForm = this.lang.FormZP
      this.haveFormHead = this.lang.FormZPDetail
    }

    this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
    this.mainQuery.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
    this.mainQuery.formType = this.formType

    this.saving = true
    const {data} = (await FormService.index(this.mainQuery))
    this.items = data.forms && data.forms.length > 0 ? data.forms : []
    
    this.saving = false
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    // PPO
    if (this.formType === 1) {
      this.headers.push(
        { text: this.lang.PPO_7, align: 'left', sortable: true, value: 'quarters', width: '80px' },
        { text: this.lang.PPO_71, align: 'left', sortable: true, value: 'year', width: '80px' },
        { text: this.lang.PPO_12, align: 'right', value: 'FreeTotal1', width: '100px' },
        { text: this.lang.PPO_13, align: 'right', value: 'FreeTotal2', width: '100px' },
        { text: this.lang.PPO_14, align: 'right', value: 'FreeTotal3', width: '100px' },
        { text: this.lang.PPO_15, align: 'right', value: 'FreeTotal4', width: '100px' },
        { text: 'DbId', value: 'id', width: '60px' }
      )
    }
    // PDVS
    if (this.formType === 2) {
      this.headers.push(
        { text: this.lang.PDVS_7, align: 'left', sortable: true, value: 'month', width: '80px' },
        { text: this.lang.PDVS_71, align: 'left', sortable: true, value: 'year', width: '80px' },
        { text: this.lang.PDVS_13, align: 'right', value: 'FreeTotal1', width: '100px' },
        { text: this.lang.PDVS_14, align: 'right', value: 'FreeTotal2', width: '100px' },
        { text: 'DbId', value: 'id', width: '60px' }
      )
    }
    // ZP
    if (this.formType === 3) {
      this.headers.push(
        { text: this.lang.ZP_7, align: 'left', sortable: true, value: 'month', width: '80px' },
        { text: this.lang.ZP_71, align: 'left', sortable: true, value: 'year', width: '80px' },
        { text: this.lang.ZP_11, align: 'right', value: 'FreeTotal1', width: '100px' },
        { text: this.lang.ZP_12, align: 'right', value: 'FreeTotal2', width: '100px' },
        { text: this.lang.ZP_13, align: 'right', value: 'FreeTotal3', width: '100px' },
        { text: this.lang.ZP_14, align: 'right', value: 'FreeTotal4', width: '100px' },
        { text: 'DbId', value: 'id', width: '60px' }
      )
    }
  },
  methods: {
    formatPrice (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async initAgain() {
      this.items = (await FormService.index(this.mainQuery)).data.forms
    },
    async formindex () {
      await this.$store.dispatch('seteditItem', 0)
      this.$store.dispatch('setretPath', null)
      await this.$store.dispatch('setMainLedgerid', null)
      this.$router.push({
        name: 'formindex'
      })
    },
    async newform () {
      await this.$store.dispatch('seteditItem', 0)
      this.$store.dispatch('setretPath', 'formlist')
      await this.$store.dispatch('setMainLedgerid', null)
      this.$router.push({
        name: 'formheadcreate', params: {formType: this.formType }
      })
    },
    async navigateTo (formHead) {
      await this.$store.dispatch('seteditItem', 1)
      this.$store.dispatch('setretPath', 'formlist')
      await this.$store.dispatch('setMainLedgerid', formHead.id)
      this.$router.push({
        name: 'formitemscrud', params: {formType: this.formType, formHead }
      })
    },
    localDate(dateToFormat) {
       const dateOnly = dayjs(dateToFormat).format('YYYY-MM-DD')
       return dateFormat(dateOnly)
      },
  },
  computed: {
   ...mapGetters({
      myResetList: 'NeedResetList'
    })
  },
  watch: {
    'myResetList' () {
      this.initAgain()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
