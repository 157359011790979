<template>
<div>
  <v-container fluid grid-list-md>
  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="account3s"
    :search="search"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="50"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{lang.Account3s}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
           append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="700px">

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                <v-row>
                    <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                      <v-text-field
                        v-model="editedItem.account1"
                        :label="lang.Account1"
                        required
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                      <v-text-field
                        v-model="localAcc1"
                        :label="lang.Account1Description"
                        required
                        disabled
                      ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                      <v-text-field
                        v-model="editedItem.account2"
                        :label="lang.Account2"
                        required
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                      <v-text-field
                        v-model="localAcc2"
                        :label="lang.Account2Description"
                        required
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                      <v-text-field
                        v-model="editedItem.account3"
                        :label="lang.Account3"
                        required
                        maxlength="3"
                        :rules="account3Rules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                      <v-text-field
                        v-model="editedItem.account3Description"
                        :label="lang.Account3Description"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="2" md="2" lg="2" xl="2">

                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                      <v-text-field
                        v-model="editedItem.account3Description2"
                        :label="lang.Account3Description2"
                      ></v-text-field>
                    </v-col>
                  </v-row>


                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn :disabled="!valid" color="success" text @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
 
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      {{ lang.Account3sEmpty }}
    </template>
  </v-data-table>
  </v-container>
  </div>
</template>

<script>
import Account3Service from '@/services/Account3Service'
import Account2Service from '@/services/Account2Service'
import Account1Service from '@/services/Account1Service'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'

  export default {
    name: 'account3CRUD',
    data: () => ({
      search: '',
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: "",
        databaseDocID: 0
      },
      dialog: false,
      headers: [ 
      ],
      account1s: [],
      account2s: [],
      account3s: [],
      localAcc1: '',
      localAcc2: '',
      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        account1: '',
        account1id: 0,
        account2: '',
        account2id: 0,
        account3: '',
        account3Description: '',
        account3Description2: '',
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: ''
      },
      confpassword: '',
      privitems: [ ],
      valid: true,
      nameRules: [
        v => !!v || 'Name is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password must be 6 or more characters',
      ],
      account3Rules: [
        v => !!v || 'Group3 is required',
        v => (v && v.length === 3) || 'Group3 must be 3 characters',
      ],
      locusers: [],
      select: { },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewAccount3: this.lang.EditAccount3
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      'editedItem.account3' () {
        this.choosedAcc(this.editedItem.account3)
      }
    },

    async mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
        this.defaultItem.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.companyName) {
        this.defaultItem.CompanyNameDatabase = this.$store.state.companyName
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }

      this.headers.push(
        {
          text: this.lang.Account3,
          align: 'left',
          sortable: true,
          value: 'account3',
          width: '80px'
        },
        {
          text: this.lang.Account2,
          align: 'left',
          sortable: true,
          value: 'account2',
          width: '80px'
        },
        {text: this.lang.Account3Description, value: 'account3Description', align: 'left', width: '750px' },
        {text: this.lang.Actions, value: 'action', sortable: false },
      )
      this.defaultItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
      this.defaultItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
      this.defaultItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
      this.defaultItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
      this.defaultItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : ''
      this.account1s = (await Account1Service.show()).data.account1s 
      this.account2s = (await Account2Service.show()).data.account2s 
    },

    created () {
      this.initialize()
    },

    methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      async initialize () {
        try {
          this.saving = true
          this.account3s = (await Account3Service.show()).data.account3s
          this.saving = false
          this.errorMatch = false
          this.editedItem = this.defaultItem
          this.select = {}
        } catch (err) {
          this.error = err
          alert(this.langC.ErrorCannotLoad)
        }
      },
      editItem (item) {
        this.errorMatch = false
        this.editedIndex = this.account3s.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      async deleteItem (item) {
        try {
          const index = this.account3s.indexOf(item)
          const warningMessage = item.account3 + ' ' + item.account3Description
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.account3s.splice(index, 1)) {
            await Account3Service.delete(item.id)
            this.initialize()
          }
        } catch(err) {
          this.error = err
          alert(this.langC.ErrorCannotDelete)
        }
        
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      async save () {
        try {
           let doneOK = false
           if (this.editedIndex > -1) {
              this.editedItem.account3Description = this.editedItem.account3Description ? (this.editedItem.account3Description).trim() : ''
              this.editedItem.account3Description2 = this.editedItem.accoun31Description2 ? (this.editedItem.account3Description2).trim() : ''
              if (this.editedItem.account3.trim().length === 3) {
                await Account3Service.put(this.editedItem)
                doneOK = true
              }
            } else {
              delete this.editedItem.id
              this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              this.editedItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
              this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
              this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              this.editedItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : ''
              this.editedItem.account3Description = this.editedItem.account3Description ? (this.editedItem.account3Description).trim() : ''
              this.editedItem.account3Description2 = this.editedItem.account3Description2 ? (this.editedItem.account3Description2).trim() : ''
              if (this.editedItem.account3.trim().length === 3) {
                await Account3Service.post(this.editedItem)
                doneOK = true
              }
            }
            if (doneOK) {
              this.initialize()
              this.close()
            }
        } catch(err) {
          this.error = err
          alert(this.langC.ErrorCannotSave)
        }
      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      },
      choosedAcc (choosedAcc) {
        if (this.account1s && this.account2s && choosedAcc) {
          const haveIt = choosedAcc.substr(0,1)
          this.account1s.forEach(element => {
            if (element.account1 === haveIt) {
              this.editedItem.account1 = element.account1
              this.editedItem.account1id = element.id
              this.localAcc1 = element.account1Description
            }
          })
          const haveIt2 = choosedAcc.substr(0,2)
          this.account2s.forEach(element => {
            if (element.account2 === haveIt2) {
              this.editedItem.account2 = element.account2
              this.editedItem.account2id = element.id
              this.localAcc2 = element.account2Description
            }
          })
        }
      }
    },
  }
</script>