import { render, staticRenderFns } from "./MainBookCompCardPrint.vue?vue&type=template&id=36baba9e&scoped=true"
import script from "./MainBookCompCardPrint.vue?vue&type=script&lang=js"
export * from "./MainBookCompCardPrint.vue?vue&type=script&lang=js"
import style0 from "./MainBookCompCardPrint.vue?vue&type=style&index=0&id=36baba9e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36baba9e",
  null
  
)

export default component.exports