<template>
  <v-container fluid>
    <v-row>
      <v-flex xs12 sm12 m12 lg12 ex12>

        <div v-if="showOpenCard">

                <v-layout row style="border-bottom:solid 1px;">
                    <v-flex xs4>
                        <h4><b><i>{{comp.CompanyNameShort}}</i></b></h4>
                        {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}   <br />                
                        {{comp.CompanyPostcode}} {{comp.CompanyCity}}<br />
                         OIB: {{comp.CompanyVATID}}
                    </v-flex>
                    <v-flex xs3>
                      <h4>{{headUp}} {{currency}}</h4>

                      <h4><b><i>{{partner.partnerName}}</i></b></h4>
                      {{partner.partnerStreet}} {{partner.partnerBuildingNameNumber}}   <br />                
                      {{partner.partnerPostcode}} {{partner.partnerCity}}<br />
                      OIB: {{partner.partnerVATID}}, {{partner.partneremail}}
                  </v-flex>
                    <v-flex xs 5>
                      <p class="text-md-left">
                          Datum izvještaja: {{PrintDate}}<br />
                          Telefon: {{comp.CompanyPhones}}<br />
                          email: {{comp.Companyemail}}<br />
                          <v-btn @click="print(1)" class="mr-2">Print</v-btn>
                          <span v-if="printOptions === 1">
                            <v-btn @click="print(2)" class="mr-2">Izv.Ot.Stavaka</v-btn>
                            <v-btn @click="print(3)" class="mr-2">Opomena</v-btn>
                          </span>
                          <v-btn @click="sortCard()" class="mr-2">Sort{{(sortType > 0 ? '(' + sortType + ')' : '')}}</v-btn>
                          <v-btn @click="connect" class="mr-2">Poveži</v-btn>
                      </p>
                    </v-flex>
              </v-layout>

            <v-layout row style="border-bottom:solid 1px; border-top:solid 1px;">

                    <v-flex xs1 class="text-left" >
                     Rb.  Vrsta
                    </v-flex>
                    <v-flex xs1 class="text-left">
                      Broj dok.
                    </v-flex>
                    <v-flex xs1 class="text-left">
                      Veza
                      <span v-if="sortType == 1" ><v-icon>mdi-arrow-down-bold</v-icon> </span>
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Datum knjiženja 
                      <span v-if="sortType == 0 || sortType == 2" ><v-icon>mdi-arrow-down-bold</v-icon> </span>
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Datum dokumenta
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Datum dospijeća
                    </v-flex>
                    <v-flex xs2 class="text-left">
                      Opis
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Konto
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Duguje
                      <span v-if="sortType == 3" ><v-icon>mdi-arrow-down-bold</v-icon> </span>
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Potražuje
                      <span v-if="sortType == 4" ><v-icon>mdi-arrow-down-bold</v-icon> </span>
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Saldo
                      <span v-if="sortType == 5" ><v-icon>mdi-arrow-down-bold</v-icon> </span>
                    </v-flex>

              </v-layout>

                <v-layout row v-for="item in pItems" :key="item.id">
                    <v-flex xs1 class="text-left" >
                      {{ item.itBookName }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ item.itInvoice }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ (item.account2 === '12'  ? (item.invoiceOutNumber ? item.invoiceOutNumber : '') : (item.invoiceInNumber ? item.invoiceInNumber : ''))  + (item.BusinessYearInvoice ?  '/' + item.BusinessYearInvoice : '') }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ localDate(item.BookingDate) }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ localDate(item.InvoiceDate) }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ localDate(item.InvoicePaymentDeadlineDate) }}
                    </v-flex>
                    <v-flex xs2 class="text-center" style="font-size:12px;">
                      {{ item.Description }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ item.account4 }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(parseFloat(item.pDebit)) }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(parseFloat(item.pCredit)) }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(parseFloat(item.CreditDebit)) }}
                    </v-flex>
                    

            </v-layout>
            <v-layout row style="border-top:solid 1px;">
              <v-flex xs9 class="text-xs-right">

              </v-flex>

              <v-flex xs1 class="text-right">
                {{ formatPrice(Total1) }}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(Total2) }}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(Total5) }}
              </v-flex>

            </v-layout>
        </div>
      </v-flex>
     
      <MainLedgerPrintCard3 ref="prnt" :card="card" :printType="printType"/>
    </v-row>
     <div v-if="!showOpenCard">
        <v-row>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" >
            <MainLedgerCard11ConnectCRUD :items="pItems" :head="head" :partner="partner" :key="key"/>
          </v-col>
        </v-row>
      </div>
  </v-container>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import MainLedgerPrintCard3 from './MainLedgerPrintCard3'
import MainLedgerCard11ConnectCRUD from './MainLedgerCard11ConnectCRUD'
//import {checkPartners} from '../MainLedger/mainledgerHelper'
import { testLastUpdate } from '@/services/TestLastUpdate'
import {dynamicSort, round, doZeros} from '@/snippets/allSnippets'
import dayjs from 'dayjs'

export default {
  name: 'mainledgercard3',
  props: ['items', 'head', 'showEUR'],
  data () {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      comp: {},
      error: null,
      localError: false,
      newUser: {},
      size: 'sm',
      havePath: '',
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      pItems: [],
      mainQuery2: {},
      partner: {},
      partners: [],
      card: {},
      mainQuery: {},
      printType: 1,
      typePartner: null,
      headUp: '',
      printOptions: 1,
      sortType: 0,
      exRate: 1,
      currency: 'EUR',
      showOpenCard: true,
      saldoItems: [],
      key: 1
    }
  },
  components: {
    MainLedgerPrintCard3,
    MainLedgerCard11ConnectCRUD
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    
    this.exRate = this.$store.state.defExRate ? this.$store.state.defExRate : 1

    if (!this.showEUR) {
      this.exRate = 1
    }
    this.currency = this.$store.state.businessYear >= 2023 ? 'EUR' : 'Kn'
    //console.log('card 3 mount head', this.head, this.items, this.exRate)
    const typePartner = this.head.fromAccount ? this.head.fromAccount.substr(0,2) : ''
    if (this.head.type && this.head.type === 3) {
      this.headUp = typePartner === '12' ? this.lang.OpenStatementBuyer : this.lang.OpenStatementSupp
      this.printOptions = 1
    }
    if (this.head.subtype && this.head.subtype === 6) {
      this.headUp = typePartner === '12' ? this.lang.CardBuyer : this.lang.CardSupp
      this.printOptions = 2
    }

    this.PrintDate = dayjs(new Date()).format('llll')
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    //await checkPartners(0)
    await testLastUpdate()
    if (this.$store.state.partnerStore) {
      this.partners = this.$store.state.partnerStore
    }
    //this.partners = (await PartnerService.index()).data.partners


    // check for open invoices
    const locItems =[]
    this.items.map(itm=> {
      const objUp = {
        newID: typePartner === '12' ? doZeros(round(itm.invoiceOutNumber,0).toString(), round(itm.BusinessYear,0).toString()) : doZeros(round(itm.invoiceInNumber,0).toString(), round(itm.BusinessYear,0).toString())
      }
      const objUp2 = {...itm, ...objUp}
      locItems.push(objUp2)
    })
    console.log('locItems', locItems)


//     const arr = [{"shape":"square","color":"red","used":1,"instances":1},{"shape":"square","color":"red","used":2,"instances":1},{"shape":"circle","color":"blue","used":0,"instances":0},{"shape":"square","color":"blue","used":4,"instances":4},{"shape":"circle","color":"red","used":1,"instances":1},{"shape":"circle","color":"red","used":1,"instances":0},{"shape":"square","color":"blue","used":4,"instances":5},{"shape":"square","color":"red","used":2,"instances":1}];

// const result = [...arr.reduce((r, o) => {
//   const key = o.shape + '-' + o.color;
  
//   const item = r.get(key) || Object.assign({}, o, {
//     used: 0,
//     instances: 0
//   });
  
//   item.used += o.used;
//   item.instances += o.instances;

//   return r.set(key, item);
// }, new Map).values()];

// console.log(result);


    const result = [...locItems.reduce((r, o) => {
      const key = o.newID;
      
      const item = r.get(key) || Object.assign({}, o, {
        Debit: 0,
        Credit: 0
      });
      
      item.Debit += o.Debit;
      item.Credit += o.Credit;

      return r.set(key, item);
    }, new Map).values()];
  

    result.map(r => {
      if (round(Math.abs(r.Debit)-Math.abs(r.Credit) , 2) !== 0) {
        this.saldoItems.push(r)
      }
    })
    
    this.Total1 = 0
    this.Total2 = 0
    this.Total3 = 0
    this.Total4 = 0
    let itLine = 0
    let partnerVATID = null

    if (this.saldoItems) {
      this.saldoItems.sort((a,b) => (a.BookingDate > b.BookingDate) ? 1 : ((b.BookingDate > a.BookingDate) ? -1 : 0))
      this.saldoItems.map(item => {
        itLine += 1
        const pDebit = item.totalDebit && item.totalDebit !== 0 ? round((parseFloat(item.totalDebit) / this.exRate),2) : round((parseFloat(item.Debit) / this.exRate),2)
        const pCredit = item.totalCredit && item.totalCredit !== 0 ? round((parseFloat(item.totalCredit) / this.exRate),2) : round((parseFloat(item.Credit) / this.exRate),2)
        this.Total1 += pDebit
        this.Total2 += pCredit
        //this.Total3 += parseFloat(item.DebitForegin)
        //this.Total4 += parseFloat(item.CreditForegin)
        partnerVATID = item.partnerVATID ? item.partnerVATID : null
        let inNr = ''
        if (typeof item.invoiceInNumber === 'number') {
          inNr = parseInt(item.invoiceInNumber)
        }
        if (typeof item.invoiceInNumber !== 'number') {
          inNr = item.invoiceInNumber && item.invoiceInNumber.trim().length > 0 ? item.invoiceInNumber.trim() : ''
        }
        let outNr = ''
        if (typeof item.invoiceOutNumber === 'number') {
          outNr = parseInt(item.invoiceOutNumber)
        }
        if (typeof item.invoiceOutNumber !== 'number') {
          outNr = item.invoiceOutNumber && item.invoiceOutNumber.trim().length > 0 ? item.invoiceOutNumber.trim() : ''
        }

        let itInvoice
        if (item.BookName.substring(0,3) === 'IRA' || item.BookName.substring(0,3) === 'URA') {
          itInvoice = item.BookName.substring(0,3) === 'URA'? inNr : outNr
        }
        if (item.BookName.substring(0,2) === 'PS') {
          itInvoice = parseInt(item.invoiceInNumber) > 0 ? inNr : outNr
        }
        if (item.BookName.substring(0,3) === 'TEM') {
          itInvoice = item.BookID ? parseInt(item.BookID) : ''
        }
         if (item.BookName.substring(0,2) === 'BS') {
          itInvoice = item.BookID ? parseInt(item.BookID) : ''
        }

        const bookName = item.BookName !== 'BS' ? item.BookName : 'Izv.'
        const object2 = {
          pDebit: pDebit,
          pCredit: pCredit, 
          itBookName: itLine + '. ' + bookName,
          CreditDebit: this.Total1 - this.Total2,
          itInvoice: itInvoice,
          locBookName: bookName
        }
        const object3 = {...item, ...object2}
        this.pItems.push(object3)
      })
    }
    
    this.Total5 = this.Total1 - this.Total2
    this.partner = partnerVATID ? this.partners.find(partner => (partner.partnerVATID === partnerVATID)) : {}
    this.card = {...this.head}
    this.card.partner = this.partner
    this.card.items = this.pItems
    //this.card = {...this.card, ...this.lastOne}
    this.card.Total1 = this.Total1
    this.card.Total2 = this.Total2
    this.card.Total5 = this.Total5
    this.card.headUp = this.headUp ? this.headUp : ''

    // if (partnerVATID) {
    //   this.mainQuery2.partnerVATID = partnerVATID
    // }
    // await PartnerService.check(this.mainQuery2)
    // .then(res => {
    //   if (res.data.partner) {
    //     this.partner = res.data.partner
    //   }                
    // })
    // .catch(err => {
    //   this.error = err.data
    // })

  },
  computed: {

  },
  methods: {
    print(type){
      this.printType = type
      this.$refs.prnt.print(type)
    },
    connect() {
      this.showOpenCard = false
    },
    sortCard() {
      this.sortType += 1
      if (this.sortType > 5) {
        this.sortType = 0
      }
      if (this.sortType === 1) {
        this.pItems.sort(dynamicSort("InvoiceNumber"))
      }
      if (this.sortType === 2 || this.sortType === 0) {
        this.pItems.sort(dynamicSort("BookingDate"))
      }
      if (this.sortType === 3) {
        this.pItems.sort(dynamicSort("pDebit"))
      }
      if (this.sortType === 4) {
        this.pItems.sort(dynamicSort("pCredit"))
      }
      if (this.sortType === 5) {
        this.pItems.sort(dynamicSort("CreditDebit"))
      }
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    close () {
      this.show = false
    }
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
