import store from '../../store/store'

const defJoppdA = () => {
  const defObj = {}
  defObj.CompanyId = 0
  defObj.CompanyNameDatabase = ''
  defObj.BusinessYear = 0
  defObj.UserId = 0
  defObj.email = ''
  defObj.First = ''
  defObj.Last = ''
  defObj.EvidenceType = 0
  defObj.hashtag = ''
  defObj.eemail = ''
  defObj.phone = ''
  defObj.Order = 0
  defObj.Month = 0
  defObj.Year = 0
  defObj.DatumIzvjesca = ''
  defObj.OznakaIzvjesca = ''
  defObj.VrstaIzvjesca = 0
  defObj.PodNaziv = ''
  defObj.PodAdrMjesto = ''
  defObj.PodAdrUlica = ''
  defObj.PodAdrBroj = ''
  defObj.PodEmail = ''
  defObj.PodOIB = ''
  defObj.PodOznaka = ''
  defObj.ObPlNaziv = ''
  defObj.ObPlAdrMjesto = ''
  defObj.ObPlAdrUlica = ''
  defObj.ObPlAdrBroj = ''
  defObj.ObPlEmail = ''
  defObj.ObPlOIB = ''
  defObj.BrojOsoba = 0
  defObj.BrojRedaka = 0

  defObj.PP_P1 = 0
  defObj.PP_P11 = 0
  defObj.PP_P12 = 0
  defObj.PP_P2 = 0
  defObj.PP_P3 = 0
  defObj.PP_P4 = 0
  defObj.PP_P5 = 0
  defObj.PP_P6 = 0

  defObj.GS_P1 = 0
  defObj.GS_P2 = 0
  defObj.GS_P3 = 0
  defObj.GS_P4 = 0
  defObj.GS_P5 = 0
  defObj.GS_P6 = 0
  defObj.GS_P7 = 0

  defObj.KS_P1 = 0
  defObj.KS_P2 = 0
  defObj.KS_P3 = 0
  defObj.KS_P4 = 0
  defObj.KS_P5 = 0
  defObj.KS_P6 = 0

  defObj.ZO_P1 = 0
  defObj.ZO_P2 = 0
  defObj.ZO_P3 = 0
  defObj.ZO_P4 = 0
  defObj.ZO_P5 = 0
  defObj.ZO_P6 = 0
  defObj.ZO_P7 = 0
  defObj.ZO_P8 = 0
  defObj.ZO_P9 = 0
  defObj.ZO_P10 = 0
  defObj.ZO_P11 = 0
  defObj.ZO_P12 = 0

  defObj.ZP_P1 = 0
  defObj.ZP_P2 = 0
  defObj.ZP_P3 = 0
  defObj.ZP_P4 = 0

  defObj.IsplaceniNeoporeziviPrimici = 0
  defObj.KamataMO2 = 0
  defObj.UkupniNeoporeziviPrimici = 0

  defObj.NZI_P1 = 0
  defObj.NZI_P2 = 0

  defObj.Ime = ''
  defObj.Prezime = ''

  defObj.DateOpen = null
  defObj.Locked = false
  defObj.LockedDate = null
  defObj.LockedTime = null
  defObj.LockedBy = null
  defObj.FreeF1 = 0
  defObj.FreeF2 = 0
  defObj.FreeF3 = 0
  defObj.FreeF4 = 0
  defObj.FreeF5 = 0
  defObj.FreeF6 = 0
  defObj.FreeF7 = ''
  defObj.FreeF8 = ''
  defObj.FreeF9 = ''
  defObj.FreeF10 = ''
  defObj.FreeF11 = ''
  defObj.FreeF12 = ''
  defObj.Activ= false
  defObj.Black= false
  defObj.EmployeeID1 = ''
  defObj.EmployeeID2 = ''
  defObj.EmployeeID3 = ''
  defObj.EmployeeID4 = ''
  defObj.originalname = ''
  defObj.filename = ''
  defObj.destination = ''
  defObj.path = ''
  defObj.typeDoc = ''
  defObj.LastUpdateBy = ''
  return defObj
}

const defNewJoppdA = () => {
  const defNew = {}
  defNew.CompanyId = store.state.companyid ? store.state.companyid : 0
  defNew.CompanyNameDatabase = store.state.companyName ? store.state.companyName : ''
  defNew.BusinessYear = store.state.businessYear ? store.state.businessYear : 2022
  defNew.UserId = store.state.user.id ? store.state.user.id : ''
  defNew.email = store.state.user.email ? store.state.user.email : ''
  defNew.First = store.state.user.First ? store.state.user.First : ''
  defNew.Last = store.state.user.Last ? store.state.user.Last : ''
  defNew.DatumIzvjesca = store.state.documentActivHead.JoppdDate
  defNew.OznakaIzvjesca = store.state.documentActivHead.BrojJoppd
  defNew.VrstaIzvjesca = 1
  defNew.PodNaziv = store.state.companyActiv.CompanyName ? store.state.companyActiv.CompanyName.trim() : ''
  defNew.PodAdrMjesto = (store.state.companyActiv.CompanyPostcode ? store.state.companyActiv.CompanyPostcode : '') +(store.state.companyActiv.CompanyCity ? store.state.companyActiv.CompanyCity : '')
  defNew.PodAdrUlica = store.state.companyActiv.CompanyStreet ? store.state.companyActiv.CompanyStreet : ''
  defNew.PodAdrBroj = store.state.companyActiv.CompanyBuildingNameNumber ? store.state.companyActiv.CompanyBuildingNameNumber : ''
  defNew.PodEmail = store.state.user.email ? store.state.user.email : ''
  defNew.PodOIB = store.state.companyActiv.CompanyVATID ? store.state.companyActiv.CompanyVATID.trim() : ''
  defNew.PodOznaka = 1
  defNew.EvidenceType = 1
  defNew.salarieID = store.state.documentActivHead.id
  defNew.Order = store.state.documentActivHead.Order
  defNew.Month = store.state.documentActivHead.Month
  defNew.Year = store.state.documentActivHead.Year
  return defNew
}

const defJoppdB = () => {
  const defObj = {}
  defObj.JoppdAID = 0
  defObj.CompanyId = 0
  defObj.CompanyNameDatabase = ''
  defObj.BusinessYear = 0
  defObj.UserId = 0
  defObj.email = ''
  defObj.First = ''
  defObj.Last = ''
  defObj.EvidenceType = 0
  defObj.hashtag = ''
  defObj.eemail = ''
  defObj.phone = ''
  defObj.First = ''
  defObj.Last = ''
  defObj.Gender = ''
  defObj.VatID = ''

  defObj.P1 = 0
  defObj.P2 = ''
  defObj.P3 = ''
  defObj.P4 = ''
  defObj.P5 = ''
  defObj.P61 = ''
  defObj.P62 = ''

  defObj.P71 = 0
  defObj.P72 = 0
  defObj.P8 = 0
  defObj.P9 = 0
  defObj.P10 = 0
  defObj.P100 = 0
  defObj.P101 = null
  defObj.P102 = null
  defObj.P11 = 0
  defObj.P12 = 0
  defObj.P121 = 0
  defObj.P122 = 0
  defObj.P123 = 0
  defObj.P124 = 0
  defObj.P125 = 0
  defObj.P126 = 0
  defObj.P127 = 0
  defObj.P128 = 0
  defObj.P129 = 0
  defObj.P131 = 0
  defObj.P132 = 0
  defObj.P133 = 0
  defObj.P134 = 0
  defObj.P135 = 0
  defObj.P141 = 0
  defObj.P142 = 0
  defObj.P151 = 0
  defObj.P152 = 0
  defObj.P161 = 0
  defObj.P162 = 0
  defObj.P17 = 0

  defObj.FreeF1 = 0
  defObj.FreeF2 = 0
  defObj.FreeF3 = 0
  defObj.FreeF4 = 0
  defObj.FreeF5 = 0
  defObj.FreeF6 = 0
  defObj.FreeF7 = ''
  defObj.FreeF8 = ''
  defObj.FreeF9 = ''
  defObj.FreeF10 = ''
  defObj.FreeF11 = ''
  defObj.FreeF12 = ''
  defObj.Activ = false
  defObj.Black = false
  defObj.EmployeeID1 = ''
  defObj.EmployeeID2 = ''
  defObj.EmployeeID3 = ''
  defObj.EmployeeID4 = ''
  defObj.originalname = ''
  defObj.filename = ''
  defObj.destination = ''
  defObj.path = ''
  defObj.typeDoc = ''
  defObj.LastUpdateBy = ''
  defObj.LastUpdate = null
  return defObj
}

const defNewJoppdB = () => {
  const defNew = {}
  defNew.JoppdAID = store.state.documentid  ? store.state.documentid  : 0
  defNew.CompanyId = store.state.companyid ? store.state.companyid : 0
  defNew.CompanyNameDatabase = store.state.companyName ? store.state.companyName : ''
  defNew.BusinessYear = store.state.businessYear ? store.state.businessYear : 2022
  defNew.UserId = store.state.user.id ? store.state.user.id : ''
  defNew.email = store.state.user.email ? store.state.user.email : ''
  defNew.First = store.state.user.First ? store.state.user.First : ''
  defNew.Last = store.state.user.Last ? store.state.user.Last : ''
  defNew.EvidenceType = 1
  defNew.salarieID = store.state.documentActivHead.id
  defNew.Order = store.state.documentActivHead.Order
  defNew.Month = store.state.documentActivHead.Month
  defNew.Year = store.state.documentActivHead.Year
  return defNew
}

const defBankOrder = () => {
  const defObj = {}
  defObj.CompanyId = 0,
  defObj.CompanyNameDatabase = '',
  defObj.UserId = null
  defObj.EmployeeId = 0
  defObj.email = ''
  defObj.First = ''
  defObj.Last = ''
  defObj.salarieID = 0
  defObj.Order = 0
  defObj.Month = 0
  defObj.Year = 0
  defObj.Grupa = 0
  defObj.Hitno = false
  defObj.ValutaPlacanja1 = ''
  defObj.Tecaj = 7.543
  defObj.ValutaPlacanja2 = ''
  defObj.Platitelj1 = ''
  defObj.Platitelj2 = ''
  defObj.Platitelj3 = ''
  defObj.PlatiteljIBAN = ''
  defObj.PlatiteljModel = ''
  defObj.PlatiteljPoziv = ''
  defObj.Opis1 = ''
  defObj.Opis2 = ''
  defObj.Opis3 = ''
  defObj.Iznos = 0
  defObj.Iznos2 = 0
  defObj.Primatelj1 = ''
  defObj.Primatelj2 = ''
  defObj.Primatelj3 = ''
  defObj.PrimateljIBAN = ''
  defObj.PrimateljModel = ''
  defObj.PrimateljPoziv = ''
  defObj.SifraNamjene = ''
  defObj.DatumIzvrsenja = null
  defObj.BrojJoppdid = 0
  defObj.BrojJoppd = ''
  defObj.bankAccountId = ''
  defObj.bankAccountName = ''
  defObj.bankAccountNr = ''
  defObj.LastUpdateBy = ''
  defObj.LastUpdate = null
  defObj.FreeF1 = 0
  defObj.FreeF2 = 0
  defObj.FreeF3 = 0
  defObj.FreeF4 = 0
  defObj.FreeF5 = ''
  defObj.FreeF6 = ''
  return defObj
}

const defNewBankOrder = () => {
  const defNew = {}
  defNew.CompanyId = store.state.companyid ? store.state.companyid : 0
  defNew.CompanyNameDatabase = store.state.companyName ? store.state.companyName : ''
  defNew.BusinessYear = store.state.businessYear ? store.state.businessYear : 2022
  defNew.UserId = store.state.user.id ? store.state.user.id : ''
  defNew.email = store.state.user.email ? store.state.user.email : ''
  defNew.First = store.state.user.First ? store.state.user.First : ''
  defNew.Last = store.state.user.Last ? store.state.user.Last : ''
  defNew.salarieID = store.state.documentActivHead.id
  defNew.Order = store.state.documentActivHead.Order
  defNew.Month = store.state.documentActivHead.Month
  defNew.Year = store.state.documentActivHead.Year
  defNew.Platitelj1 = store.state.companyActiv.CompanyName
  defNew.Platitelj2 = (store.state.companyActiv.CompanyStreet ? store.state.companyActiv.CompanyStreet : '') +  (store.state.companyActiv.CompanyBuildingNameNumber ? ' ' + store.state.companyActiv.CompanyBuildingNameNumber : '')
  defNew.Platitelj3 = store.state.companyActiv.CompanyCity ? store.state.companyActiv.CompanyCity : ''
  defNew.PlatiteljIBAN = store.state.documentActivHead.bankAccountNr ? store.state.documentActivHead.bankAccountNr : ''
  defNew.bankAccountId = store.state.documentActivHead.bankAccountId ? parseInt(store.state.documentActivHead.bankAccountId) : ''
  defNew.bankAccountName = store.state.documentActivHead.bankAccountName ? store.state.documentActivHead.bankAccountName : ''
  defNew.bankAccountNr = store.state.documentActivHead.bankAccountNr ? store.state.documentActivHead.bankAccountNr : ''
  return defNew
}

export {
  defJoppdA,
  defJoppdB,
  defNewJoppdA,
  defNewJoppdB,
  defBankOrder,
  defNewBankOrder
}