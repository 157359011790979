<template>
  <v-container>
    <v-row>
      <v-flex xs12 >
             <v-row>
              <v-flex xs3 class="headline px-2" >
                Uk.duguje: {{ localMoney(debitTotal)  }}
              </v-flex>              
              <v-flex xs3 class="headline">
                Uk.potražuje: {{ localMoney(creditTotal)  }}
              </v-flex>
              <v-flex xs1 >
                <span class="headline red white--text" v-if="diffTotal != 0">{{ localMoney(diffTotal) }}</span>
                <span class="headline green white--text" v-if="closedAccount">{{ localMoney(diffTotal) }}</span>
              </v-flex>
            </v-row>
      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import moneyFormat from '@/snippets/moneyFormat'

export default {
  name: 'mainledgerviewtotal',
  $_veeValidate: {
    validator: 'new'
  },
  props:['componentKey'],
  data () {
    return {
      lang: {},
      langC: {},
      saving: false,
      menu: false,
      menu2: false,
      menu3: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      date1: null,
      date2: null,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      msg: '',
      valid: true,
      documentName: '',
      documentNameLoc: '',
      mainledger: { },
      mainledgerActiv: {},
      error: null,
      localError: false,
      newDocument: {},
      confpassword: '',
      size: 'sm',
      required: (value) => !!value || 'Required.',
      partners: [],
      employes: [],
      banks: [],
      currnecy: [],
      users: [],
      CompanyId: 0,
      selectPa: {},
      selectBank: {},
      selectCR: {},
      selectUs: {},
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
        documentName: '',
        MLType: 0
      },
      currnecyType: [
        { text: 'Kn', value: 'Kn' },
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      bookType: [],
      money: {
        prefix: '= ',
        suffix: ' ',
        precision: 3
      },
      price: 7.500,
      locCreatedAt: '',
      locUpdatedAt: '',
      locBookingDate: '',
      locInvoiceDate: '',
      locInvoicePaymentDeadlineDate: '',
      locBookID: 0,
      locCanUse: 0,
      locCantUse: 0,
      debitTotal: 0,
      creditTotal: 0,
      diffTotal: 0,
      closedAccount: false,
      totalDebit: 0,
      totalCredit: 0,
      totalDiff: 0,
      totalClosed: false
    }
  },
  components: {
  },
  mounted () {
      if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      this.mainledger = this.$store.state.mainledgerActiv ? this.$store.state.mainledgerActiv : {}
      this.takeTotals()
  },
  computed: {

  },
  watch: {

  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },    
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    close () {
      this.show = false
    },
    async takeTotals() {   

      this.debitTotal =  this.$store.state.mainledgerActiv.DebitTotal ? this.$store.state.mainledgerActiv.DebitTotal : 0
      this.creditTotal = this.$store.state.mainledgerActiv.CreditTotal ? this.$store.state.mainledgerActiv.CreditTotal : 0
      this.diffTotal =  this.$store.state.mainledgerActiv.DiffTotal ? this.$store.state.mainledgerActiv.DiffTotal : 0
      this.closedAccount = this.$store.state.mainledgerActiv.ClosedAccount ? this.$store.state.mainledgerActiv.ClosedAccount : 0
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>

