<template>
<div>
  <v-container fluid grid-list-md>
  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    :headers="headers"
    :items="itemsPOS"
    :search="search"
    sort-by="id"
    class="elevation-1 headline"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{lang.itemsPOS}}</v-toolbar-title>
          
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
           <v-btn text small color="primary" @click="profitcard" class="mr-1">
            {{lang.ProfitCard}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn x-large color="success" @click="settypePOS" class="mr-5">ZAKLJUČI RAČUN</v-btn>
        
        <v-dialog v-model="dialog" max-width="700px">

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                    <v-row class="pa-0">
                      <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="pa-0">
                      <v-autocomplete
                          :items="itemsLoc"
                          v-model="selectIL"
                          :label="lang.ItemSelect"
                          item-text="itemByID"
                          item-value="itemID"
                          clearable
                          return-object
                          style="font-size: 23px"
                        ></v-autocomplete>

                      </v-col>
                  </v-row>

                  <v-row class="pa-0">
                      <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="mr-3 pa-0">
                        <v-text-field
                            :label="lang.ItemEANCode"
                            v-model="editedItem.ItemEANCode"
                          ></v-text-field>
                      </v-col>
                      
                      <v-col cols="12" sm="7" md="7" xs="7" lg="7" xl="7" class="pa-0">
                        <v-btn text small color="primary" @click="findEAN" class="mr-1">
                          {{lang.searchEAN}}
                        </v-btn>
                      </v-col>
                  </v-row>

                  <v-row class="pa-0">
                      <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="pa-0">
                        <v-text-field
                            dense
                            :label="lang.ItemName"
                            v-model="editedItem.itemName"
                            style="font-size: 23px"
                          ></v-text-field>    
                      </v-col>
                      <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pa-0">
                        <v-btn text small color="primary" @click="updateItemName" class="mr-1" v-if="haveItemSelected">
                          {{lang.UpdateItemName}}
                        </v-btn>
                      </v-col>
                  </v-row>

                  

                  <!-- <v-row class="pa-0">
                      <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pa-0">
                        <v-text-field
                            dense
                            :label="lang.ItemNameShort"
                            v-model="editedItem.itemNameShort"
                          ></v-text-field>    
                      </v-col>
                      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
      
                      </v-col>
                      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                        <v-text-field
                            dense
                            :label="lang.ItemGroup"
                            v-model="editedItem.itemBaseGroup"
                            type="number"
                            disabled
                          ></v-text-field>                   
                      </v-col>
                  </v-row> -->
                  <v-row class="pa-0">
                      <v-col cols="12" sm="8" md="8" xs="10" lg="8" xl="8" class="pa-0">
                        <v-btn text color="primary" @click="showOnDescription()" class="pb-3 pl-0">{{lang.Description}}<v-icon v-if="!showDescription">mdi-chevron-down</v-icon><v-icon v-if="showDescription">mdi-chevron-up</v-icon></v-btn>
                      </v-col>
                  </v-row>

                  <v-row class="pa-0" v-if="showDescription">
                      <v-col cols="12" sm="8" md="8" xs="10" lg="8" xl="8" class="pa-0">
                        <v-textarea
                            dense
                            :label="lang.ItemDescription"
                            v-model="editedItem.itemDescription"
                          ></v-textarea>
                      </v-col>
                  </v-row>

                  <v-row class="pa-0">
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-3 pa-0">
                        <v-text-field
                            :label="lang.ItemUnit"
                            v-model="editedItem.itemUnit"
                            style="font-size: 23px"
                          ></v-text-field>    
                      </v-col>
                      <v-col cols="12" sm="7" md="7" xs="7" lg="7" xl="7" class="pa-0">
                          <v-text-field
                            :label="lang.ItemSerialNr"
                            v-model="editedItem.itemSerialNr"
                          ></v-text-field>
                      </v-col>
                  </v-row>

                  <v-row>
                      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-3 pa-0">
                          <v-text-field
                            dense
                            style="font-size: 25px"
                            :label="lang.ItemPcsMinus"
                            v-model.number="editedItem.itemPcsMinus"
                            type="number"
                          ></v-text-field>
                      </v-col>
                       <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-3 pa-0">
                        <v-text-field
                            dense
                            :label="lang.itemFullPriceOriginal"
                            v-model.number="editedItem.itemFullPriceOriginal"
                            type="number"
                            disabled
                            style="font-size: 23px"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-3 pa-0">
                          <v-text-field
                          dense
                          :label="lang.ItemPriceWithVAT"
                          v-model.number="editedItem.itemPriceWithVAT"
                          type="number"
                          :disabled="fullpriceChange"
                          style="font-size: 23px"
                          
                        ></v-text-field>
                      </v-col>
                       <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
                          <v-icon @click="fullpriceSwitch" >
                              {{fullpriceChange ? 'mdi-pencil' : 'mdi-close'}}
                            </v-icon>
                      </v-col>
                       </v-row>
                      <v-row>
                       <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-3 pa-0">
                          <v-text-field
                          dense
                          :label="lang.ItemDiscountPercentage1"
                          v-model.number="editedItem.itemDiscountPercentage1"
                          type="number"
                          :disabled="!fullpriceChange"
                          style="font-size: 23px"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-3 pa-0">
                          <v-text-field
                          dense
                          :label="lang.ItemMaxDiscount"
                          v-model.number="editedItem.itemMaxDiscount"
                          type="number"
                          disabled
                          style="font-size: 23px"
                        ></v-text-field>
                      </v-col> 
                      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                        <v-text-field
                            dense
                            :label="lang.ItemFullTotal"
                            v-model.number="editedItem.itemFullTotal"
                            type="number"
                            disabled
                            style="font-size: 23px"
                          ></v-text-field> 
                      </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
                      <v-checkbox
                        v-model="editedItem.itemService"
                        label="Usluga"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1"  x-large @click="close" class="mr-3">{{langC.Cancel}}</v-btn>
                    <v-btn :disabled="!valid" x-large color="success"  @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
 
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:[`item.itemName`]="{ item }">
      <span class="headline">{{ item.itemName }}</span>
    </template>
    <template v-slot:[`item.itemUnit`]="{ item }">
      <span class="headline">{{ item.itemUnit }}</span>
    </template>
    <template v-slot:[`item.itemPcsMinus`]="{ item }">
      <span class="headline">{{ item.itemPcsMinus }}</span>
    </template>
    <template v-slot:[`item.itemFullPriceOriginal`]="{ item }">
      <span class="headline">{{ formatPrice(item.itemFullPriceOriginal) }}</span>
    </template>
    <template v-slot:[`item.itemDiscountPercentage1`]="{ item }">
      <span class="headline">{{ item.itemDiscountPercentage1 }}</span>
    </template>
    <template v-slot:[`item.itemVAT1Percentage`]="{ item }">
      <span class="headline">{{ item.itemVAT1Percentage }}</span>
    </template>
    <template v-slot:[`item.itemAmountWithVAT`]="{ item }">
      <span class="headline">{{ formatPrice(item.itemAmountWithVAT) }}</span>
    </template>
    <template v-slot:[`item.itemService`]="{ item }">
        <span><v-icon >{{item.itemService ? 'mdi-check' : ''}} </v-icon></span>
    </template>
    <template v-slot:no-data>
      {{ lang.POSEmpty }}
    </template>
  </v-data-table>
  <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.profcard">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <DocumentItemsProfitCard />
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row>
   <v-snackbar
        v-model="snackbar"
        bottom
        :timeout="timeout"
        :color="color"
        >
        {{ text }}
      </v-snackbar>
  </v-container>
  </div>
</template>

<script>
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dateFormat from '@/snippets/dateFormat'
import ItemService from '@/services/ItemService'
import DocumentItemsProfitCard from './DocumentItemsProfitCard'
import { mapGetters } from 'vuex'
import { newUpdates, testLastUpdate } from '../../services/TestLastUpdate'

  export default {
    name: 'documentPOSCRUD',
    data: () => ({
      fullpriceChange: true,
      fullpriceBtn: 'Change',
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      search: '',
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      pscWrongSide: false,
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: "",
        databaseDocID: 0
      },
      pcsLoc: 0,
      dialog: false,
      headers: [ 
      ],
      editedIndex: -1,
      editedItem: {},
      haveItemSelected: false,
      defaultItem: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        EmployeeId: null,
        hashtag: '',
        eemail: '',
        First: '',
        Last: '',
        empWorkDay: null,
        empStartTime: null,
        empEndTime: null,
        empWrkHrs: 0,
        empWrkExpHrs: 0,
        empExp1: 0,
        empExp2: 0,
        documentName: '',
        documentSide: 0,
        documentAnalysis: 0,
        documentId: 0,
        documentIdGeneral: '',
        documentDate: '',
        partnerId: '',
        partnerNameDatabase: '',
        HomeCurrency: '',
        ForeignCurrency: '',
        ExRate: 0,
        itemID: '',
        itemName: '',
        ItemNameShort: '',
        ItemCode: '',
        ItemEANCode: '',
        itemGroup: '',
        itemDescription: '',
        itemUnit: '',
        ItemSize: '',
        ItemWeight: 0,
        ItemWeightMeasure: '',
        ItemDimMeasure: '',
        ItemDimLenght: 0,
        ItemDimHeight: 0,
        ItemDimDepth: 0,
        ItemWarehouse: '',
        ItemWarehouseRow: '',
        ItemWarehouseShelf: '',
        ItemWahrehouseFloor: '',
        ItemPicture1: '',
        ItemPicture2: '',
        ItemPicture3: '',
        ItemPicture4: '',
        ItemPicture5: '',
        ItemPicture6: '',
        ItemPicture7: '',
        ItemBelongsToId: 0,
        itemService: false,
        itemWeb: false,
        itemNotActiv: false,
        itemHideForever: false,
        ItemHomeCurrency: '',
        ItemForeignCurrency: '',
        ItemExRate: 0,
        itemSuppBasePrice: 0,
        itemSuppDiscount: 0,
        itemSuppPrice: 0,
        ItemSuppCode: '',
        itemMargin: 0,
        itemMaxDiscount: 0,
        itemPrice: 0,
        itemVAT1Percetage: 0,
        itemVAT1Amount: 0,
        itemVAT2Percentge: 0,
        itemVAT2Amount: 0,
        itemDirectDuty1: 0,
        itemDirectDuty2: 0,
        itemPriceWithVAT: 0,
        itemForePrice: 0,
        itemForeVAT1Percentage: 0,
        itemForeVAT1Amount: 0,
        itemForeVAT2Percentage: 0,
        itemForeVAT2Amount: 0,
        itemForeDirectDuty1: 0,
        itemForeDirectDuty2: 0,
        itemForePriceWithVAT: 0,
        itemBaseAmount: 0,
        itemBaseForeAmount: 0,
        itemDiscountAmount1: 0,
        itemDiscountPercentage1: 0,
        itemDiscountAmount2: 0,
        itemDiscountPercentage2: 0,
        itemDiscountAmount3: 0,
        itemDiscountPercentage4: 0,
        itemDiscountPrice: 0,
        itemSerialNr: '',
        itemPcsPlus: 0,
        itemPcsMinus: 1,
        itemPcsNeutral: 0,
        itemBaseAmountNoVAT: 0,
        itemBaseForeAmountNoVAT: 0,
        itemBaseAmountWithVAT: 0,
        itemBaseForeAmountWithVAT: 0,
        itemAmountWithVAT: 0,
        itemFullTotal: 0,
        itemFullTotalFore: 0,
        itemForeAmountWithVAT: 0,
        itemDirectDuty1Amount: 0,
        itemDirectDuty2Amount: 0,
        itemDirectDuty1AmountFore: 0,
        itemDirectDuty2AmountFore: 0,
        itemPriceOriginal: 0,
        itemFullPriceOriginal: 0,
        EnteredInDatabase: null,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: ''
      },
      privitems: [ ],
      valid: true,
      locusers: [],
      select: { },
      itemsLoc: [],
      itemsPOS: [],
      itemLoc: {},
      selectIL: {},
      sumDoc: {
        documentTotalVAT1Per: 0,
        documentTotalVAT1Base: 0,
        documentTotalVAT1Amount: 0,
        documentTotalVAT2Per: 0,
        documentTotalVAT2Base: 0,
        documentTotalVAT2Amount: 0,
        documentTotalVAT3Per: 0,
        documentTotalVAT3Base: 0,
        documentTotalVAT3Amount: 0,
        documentTotalVAT4Per: 0,
        documentTotalVAT4Base: 0,
        documentTotalVAT4Amount: 0,
        documentTotalVAT5Per: 0,
        documentTotalVAT5Base: 0,
        documentTotalVAT5Amount: 0,
        documentTotalAmountNetto: 0,
        documentTotalAmountVAT: 0,
        documentTotalAmountBrutto: 0,
        documentTotalAmountPayInAdvance: 0,
        documentTotalAmountToPay: 0,
        documentTotalAmountNettoForeign: 0,
        documentTotalAmountVATForeign: 0,
        documentTotalAmountBruttoForeign: 0,
        documentTotalAmountPayInAdvanceForeign: 0,
        documentTotalAmountToPayForeign: 0,
        documentTotalAmountDirectD1: 0,
        documentTotalAmountDirectD2: 0,
        documentTotalAmountDirectD1Fore: 0,
        documentTotalAmountDirectD2Fore: 0
      },
      mainQuery2: {
        CompanyId: null,
        itemID: null
      },
      dummyObj: {},
      profcard: false,
      showDescription: false
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewAccount4: this.lang.EditAccount4
      },
      ...mapGetters({
        mySelectedItemId: 'NeedSelectedItemId'
      }),
    },
    components: {
      DocumentItemsProfitCard
    },

    watch: {
      dialog (val) {
        val || this.close()
      }, 
      'mySelectedItemId' () {      
        this.itemFromWarehouse()
      // this.partner()
      },
      'selectIL' () {
        if (this.selectIL) {
          this.findItem()
        //  this.calculatePrice()
        }
        if (!this.selectIL) {
          this.initialize()
        //  this.calculatePrice()
        }
      },
      'editedItem.itemPriceWithVAT' () {
         if (!this.fullpriceChange) {
          this.calculatePrice()
         }
      },
      'editedItem.itemDiscountPercentage1' () {
        this.checkDiscount()
        if (this.fullpriceChange) {
          this.calculateDiscount()
         }
      },
      'editedItem.itemPcsMinus' () {
         if (this.fullpriceChange) {
          this.calculateDiscount()
         }
          if (!this.fullpriceChange) {
          this.calculatePrice()
         }
      },
    },
    async mounted() {
      if (this.$store.state.language === 'EN') {
        this.lang = langEn
        this.langC = commEn
      }
      if (this.$store.state.language === 'HR') {
        this.lang = langHr
        this.langC = commHr
      }
      if (this.$store.state.itemStore) {
        this.itemsLoc = this.$store.state.itemStore
       }
      this.defaultItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : 0
      this.defaultItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
      this.defaultItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
      this.defaultItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
      this.defaultItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : ''
      this.defaultItem.documentSide = this.$store.state.documentSide ? this.$store.state.documentSide : 1
      this.defaultItem.documentAnalysis = this.$store.state.documentAnalysis ? this.$store.state.documentAnalysis : 1
      this.defaultItem.itemPcsPlus = 0
      this.defaultItem.itemPcsMinus = 1
      this.defaultItem.itemPcsNeutral = 0
      this.defaultItem.exRate = this.$store.getters.NeedDefExRate

      if (this.$store.state.documentSide === 2 || this.$store.state.documentSide === 3) {        
        this.pscWrongSide = true
      }
      if (this.$store.state.documentActivItems) {
        this.$store.state.documentActivItems.map(async (item) => {
          let newPcs = 1
          if (this.pscWrongSide && this.round(item.itemPcsPlus, 3) !== 0 ) {
            newPcs = this.round(item.itemPcsPlus, 3)
          }
          if (this.pscWrongSide && this.round(item.itemPcsNeutral, 3) !== 0 ) {
            newPcs = this.round(item.itemPcsNeutral, 3)
          }   
          item.id = null
          item.databaseDocID = null
          item.itemPcsPlus = 0
          item.itemPcsNeutral = 0
          item.itemPcsMinus = parseFloat(newPcs)
          this.editedItem = {...item}
          this.doParse()
          await this.itemsPOS.push(this.editedItem)
        })
        await this.$store.dispatch('setDocumentActivItems', [])
        await this.makeSum()
      }
      const newObj = {}
      this.editedItem = {...newObj}
      this.defaultItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
      this.defaultItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
      this.defaultItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
      this.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : ''
      this.documentNameLoc = this.$store.state.documentActivLoc ? this.$store.state.documentActivLoc : ''

      //this.itemsLoc = this.$store.state.itemStore ? await this.$store.state.itemStore : []
      //this.doParse()

      await this.headers.push(
        {
          text: this.lang.ItemName,
          align: 'left',
          sortable: true,
          value: 'itemName',
          width: '550px'
        },
        { text: this.lang.ItemUnit, align: 'center', value: 'itemUnit', width: '55px' },
        { text: this.lang.ItemService, align: 'center', value: 'itemService', width: '50px' },
        { text: this.lang.ItemPcsMinus, align: 'right', value: 'itemPcsMinus', width: '150px' },
        { text: this.lang.ItemPriceWithVAT, align: 'right', value: 'itemFullPriceOriginal', width: '200px' },
        { text: this.lang.ItemDiscountPercentage1, align: 'right', value: 'itemDiscountPercentage1', width: '80px' },
        { text: this.lang.ItemVAT1Percentage, align: 'right', sortable: true, value: 'itemVAT1Percentage', width: '70px' },
        { text: this.lang.ItemAmountWithVAT, align: 'right', value: 'itemAmountWithVAT', width: '200px' },
        { text: this.lang.Actions, value: 'action', sortable: false },
      )
    },

    created () {
      this.initialize()
    },

    methods: {
      showOnDescription() {
        this.showDescription = !this.showDescription
      },
      async profitcard() {
        if (this.itemsPOS) {
          await this.$store.dispatch('setDocumentActivItems', this.itemsPOS)
        } else {
          await this.$store.dispatch('setDocumentActivItems', [])
        }
        this.profcard = !this.profcard
      },
      async itemFromWarehouse() {
        if (this.$store.state.selectedItemId) {
          const emObj = {}
          this.mainQuery2 = {...emObj}
          this.mainQuery2.CompanyId = this.$store.state.companyid
          this.mainQuery2.itemID = this.$store.state.selectedItemId
          //this.$store.dispatch('setSelectedItemId', null)
          if (this.$store.state.newItemAround) {
            // disable new item added over another document and restore itemsLoc
            this.$store.dispatch('setnewItemAround', false)
            //this.itemsLoc = this.$store.state.itemStore ? this.$store.state.itemStore : []
          }
          await this.findIt()
          await this.save()
          //this.warehouseState()
          //this.statewhs = false
          //this.newItemCreate = false
        }
      },
      fullpriceSwitch() {
        this.fullpriceChange = !this.fullpriceChange
      },
      formatPrice (value) {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },

      async updateItemName() {
        if (this.haveItemSelected) {
          const updateItem = {}
          updateItem.itemID = this.editedItem.itemID
          updateItem.itemName = this.editedItem.itemName
          updateItem.itemDescription = this.editedItem.itemDescription
          updateItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
          const updateItems = []
          updateItems.push(updateItem)
          await ItemService.updateprices(updateItems)
          .then(async(res) => {
            this.dummyObj = res.data
            this.text = this.lang.UpdateItemNameUpdated
            this.color = 'green'
            this.snackbar = true
            await newUpdates(1)
            await testLastUpdate()
            // this.mainQuery.CompanyId = this.$store.state.companyid
            // this.itemsLoc = (await ItemService.index(this.mainQuery)).data.items
            // if (this.itemsLoc) {
            //   this.$store.dispatch('setActivItems', this.itemsLoc)
            // }
          })
          .catch(err => {
            console.log(' Error items ', err)
            this.text = this.lang.UpdateItemNameUpdatedError
            this.color = 'red'
            this.snackbar = true
          })
        }
      },
      doParse () {
        this.editedItem.ItemWeight = this.editedItem.ItemWeight ? parseFloat(this.editedItem.ItemWeight) : 0
        this.editedItem.ItemDimLenght = this.editedItem.ItemDimLenght ? parseFloat(this.editedItem.ItemDimLenght) : 0
        this.editedItem.ItemDimHeight = this.editedItem.ItemDimHeight ? parseFloat(this.editedItem.ItemDimHeight) : 0
        this.editedItem.ItemDimDepth = this.editedItem.ItemDimDepth ? parseFloat(this.editedItem.ItemDimDepth) : 0
        this.editedItem.ItemBelongsToId = this.editedItem.ItemBelongsToId ? parseFloat(this.editedItem.ItemBelongsToId) : 0
        this.editedItem.itemSuppBasePrice = this.editedItem.itemSuppBasePrice ? parseFloat(this.editedItem.itemSuppBasePrice) : 0
        this.editedItem.itemSuppDiscount = this.editedItem.itemSuppDiscount ? parseFloat(this.editedItem.itemSuppDiscount) : 0
        this.editedItem.itemSuppPrice = this.editedItem.itemSuppPrice ? parseFloat(this.editedItem.itemSuppPrice) : 0
        this.editedItem.ItemSuppCode = this.editedItem.ItemSuppCode ? parseFloat(this.editedItem.ItemSuppCode) : 0
        this.editedItem.itemMargin = this.editedItem.itemMargin ? parseFloat(this.editedItem.itemMargin) : 0
        this.editedItem.itemMaxDiscount = this.editedItem.itemMaxDiscount ? parseFloat(this.editedItem.itemMaxDiscount) : 0
        this.editedItem.itemPrice = this.editedItem.itemPrice ? parseFloat(this.editedItem.itemPrice) : 0
        this.editedItem.itemVAT1Percetage = this.editedItem.itemVAT1Percetage ? parseFloat(this.editedItem.itemVAT1Percetage) : 0
        this.editedItem.itemVAT1Amount = this.editedItem.itemVAT1Amount ? parseFloat(this.editedItem.itemVAT1Amount) : 0
        this.editedItem.itemVAT2Percentge =this.editedItem.itemVAT2Percentge ? parseFloat(this.editedItem.itemVAT2Percentge) : 0
        this.editedItem.itemVAT2Amount = this.editedItem.itemVAT2Amount ? parseFloat(this.editedItem.itemVAT2Amount) : 0
        this.editedItem.itemDirectDuty1 = this.editedItem.itemDirectDuty1 ? parseFloat(this.editedItem.itemDirectDuty1) : 0
        this.editedItem.itemDirectDuty2 = this.editedItem.itemDirectDuty2 ? parseFloat(this.editedItem.itemDirectDuty2) : 0
        this.editedItem.itemPriceWithVAT = this.editedItem.itemPriceWithVAT ? parseFloat(this.editedItem.itemPriceWithVAT) : 0
        this.editedItem.itemForePrice = this.editedItem.itemForePrice ? parseFloat(this.editedItem.itemForePrice) : 0
        this.editedItem.itemForeVAT1Percentage = this.editedItem.itemForeVAT1Percentage ? parseFloat(this.editedItem.itemForeVAT1Percentage) : 0
        this.editedItem.itemForeVAT1Amount = this.editedItem.itemForeVAT1Amount ? parseFloat(this.editedItem.itemForeVAT1Amount) : 0
        this.editedItem.itemForeVAT2Percentage = this.editedItem.itemForeVAT2Percentage ? parseFloat(this.editedItem.itemForeVAT2Percentage) : 0
        this.editedItem.itemForeVAT2Amount = this.editedItem.itemForeVAT2Amount ? parseFloat(this.editedItem.itemForeVAT2Amount) : 0
        this.editedItem.itemForeDirectDuty1 = this.editedItem.itemForeDirectDuty1 ? parseFloat(this.editedItem.itemForeDirectDuty1) : 0
        this.editedItem.itemForeDirectDuty2 = this.editedItem.itemForeDirectDuty2 ? parseFloat(this.editedItem.itemForeDirectDuty2) : 0
        this.editedItem.itemForePriceWithVAT = this.editedItem.itemForePriceWithVAT ? parseFloat(this.editedItem.itemForePriceWithVAT) : 0
        this.editedItem.itemPcsNeutral = this.editedItem.itemPcsNeutral ? parseFloat(this.editedItem.itemPcsNeutral) : 0
        this.editedItem.itemPcsMinus = this.editedItem.itemPcsMinus ? parseFloat(this.editedItem.itemPcsMinus) : 0
        this.editedItem.itemFullTotal = this.editedItem.itemFullTotal ? parseFloat(this.editedItem.itemFullTotal) : 0
        this.editedItem.itemFullTotalFore = this.editedItem.itemFullTotalFore ? parseFloat(this.editedItem.itemFullTotalFore) : 0
        this.editedItem.itemDirectDuty1Amount = this.editedItem.itemDirectDuty1Amount ? parseFloat(this.editedItem.itemDirectDuty1Amount) : 0
        this.editedItem.itemDirectDuty2Amount = this.editedItem.itemDirectDuty2Amount ? parseFloat(this.editedItem.itemDirectDuty2Amount) : 0
        this.editedItem.itemDirectDuty1AmountFore = this.editedItem.itemDirectDuty1AmountFore ? parseFloat(this.editedItem.itemDirectDuty1AmountFore) : 0
        this.editedItem.itemDirectDuty2AmountFore = this.editedItem.itemDirectDuty2AmountFore ? parseFloat(this.editedItem.itemDirectDuty2AmountFore) : 0
        this.editedItem.FreeF1 = this.editedItem.FreeF1 ? parseFloat(this.editedItem.FreeF1) : 0
        this.editedItem.FreeF2 = this.editedItem.FreeF2 ? parseFloat(this.editedItem.FreeF2) : 0
        this.editedItem.FreeF3 = this.editedItem.FreeF3 ? parseFloat(this.editedItem.FreeF3) : 0
        this.editedItem.FreeF4 = this.editedItem.FreeF4 ? parseFloat(this.editedItem.FreeF4) : 0
        this.editedItem.FreeF5 = this.editedItem.FreeF5 ? parseFloat(this.editedItem.FreeF5) : 0
        this.editedItem.itemAmountWithVAT = this.editedItem.itemAmountWithVAT ? parseFloat(this.editedItem.itemAmountWithVAT) : 0
        this.editedItem.itemBaseAmount = this.editedItem.itemBaseAmount ? parseFloat(this.editedItem.itemBaseAmount) : 0
        this.editedItem.itemBaseAmountNoVAT = this.editedItem.itemBaseAmountNoVAT ? parseFloat(this.editedItem.itemBaseAmountNoVAT) : 0
        this.editedItem.itemBaseAmountWithVAT = this.editedItem.itemBaseAmountWithVAT ? parseFloat(this.editedItem.itemBaseAmountWithVAT) : 0
        this.editedItem.itemBaseForeAmount = this.editedItem.itemBaseForeAmount ? parseFloat(this.editedItem.itemBaseForeAmount) : 0
        this.editedItem.itemBaseForeAmountNoVAT = this.editedItem.itemBaseForeAmountNoVAT ? parseFloat(this.editedItem.itemBaseForeAmountNoVAT) : 0
        this.editedItem.itemBaseForeAmountWithVAT = this.editedItem.itemBaseForeAmountWithVAT ? parseFloat(this.editedItem.itemBaseForeAmountWithVAT) : 0
        this.editedItem.itemDiscountAmount1 = this.editedItem.itemDiscountAmount1 ? parseFloat(this.editedItem.itemDiscountAmount1) : 0
        this.editedItem.itemDiscountAmount2 = this.editedItem.itemDiscountAmount2 ? parseFloat(this.editedItem.itemDiscountAmount2) : 0
        this.editedItem.itemDiscountAmount3 = this.editedItem.itemDiscountAmount3 ? parseFloat(this.editedItem.itemDiscountAmount3) : 0
        this.editedItem.itemDiscountForeAmount1 = this.editedItem.itemDiscountForeAmount1 ? parseFloat(this.editedItem.itemDiscountForeAmount1) : 0
        this.editedItem.itemDiscountForePrice = this.editedItem.itemDiscountForePrice ? parseFloat(this.editedItem.itemDiscountForePrice) : 0
        this.editedItem.itemDiscountPercentage1 = this.editedItem.itemDiscountPercentage1 ? parseFloat(this.editedItem.itemDiscountPercentage1) : 0
        this.editedItem.itemDiscountPercentage2 = this.editedItem.itemDiscountPercentage2 ? parseFloat(this.editedItem.itemDiscountPercentage2) : 0
        this.editedItem.itemDiscountPrice = this.editedItem.itemDiscountPrice ? parseFloat(this.editedItem.itemDiscountPrice) : 0
        this.editedItem.itemForeAmountWithVAT = this.editedItem.itemForeAmountWithVAT ? parseFloat(this.editedItem.itemForeAmountWithVAT) : 0
        this.editedItem.itemFullPriceOriginal = this.editedItem.itemFullPriceOriginal ? parseFloat(this.editedItem.itemFullPriceOriginal) : 0
        this.editedItem.itemMarginAmount = this.editedItem.itemMarginAmount ? parseFloat(this.editedItem.itemMarginAmount) : 0
        this.editedItem.itemSuppBaseForeAmount = this.editedItem.itemSuppBaseForeAmount ? parseFloat(this.editedItem.itemSuppBaseForeAmount) : 0
        this.editedItem.itemSuppBaseForePrice = this.editedItem.itemSuppBaseForePrice ? parseFloat(this.editedItem.itemSuppBaseForePrice) : 0
        this.editedItem.itemSuppBaseForePrice2 = this.editedItem.itemSuppBaseForePrice2 ? parseFloat(this.editedItem.itemSuppBaseForePrice2) : 0
        this.editedItem.itemSuppBasePercentageExp = this.editedItem.itemSuppBasePercentageExp ? parseFloat(this.editedItem.itemSuppBasePercentageExp) : 0
        this.editedItem.itemSuppBasePriceAmount = this.editedItem.itemSuppBasePriceAmount ? parseFloat(this.editedItem.itemSuppBasePriceAmount) : 0
        this.editedItem.itemSuppDiscount = this.editedItem.itemSuppDiscount ? parseFloat(this.editedItem.itemSuppDiscount) : 0
        this.editedItem.itemSuppDiscountAmount = this.editedItem.itemSuppDiscountAmount ? parseFloat(this.editedItem.itemSuppDiscountAmount) : 0
        this.editedItem.itemVAT1Percentage = this.editedItem.itemVAT1Percentage ? parseFloat(this.editedItem.itemVAT1Percentage) : 0
        this.editedItem.itemVAT2Percentage = this.editedItem.itemVAT2Percentage ? parseFloat(this.editedItem.itemVAT2Percentage) : 0
        this.editedItem.itemVAT3Percentage = this.editedItem.itemVAT3Percentage ? parseFloat(this.editedItem.itemVAT3Percentage) : 0
        this.editedItem.itemPriceOriginal = this.editedItem.itemPriceOriginal ? parseFloat(this.editedItem.itemPriceOriginal) : 0
      },
      async findItem() {
        if (this.selectIL.itemID) {
          const newObj = {}
          this.mainQuery2 = {...newObj}
          this.mainQuery2.CompanyId = this.$store.state.companyid
          this.mainQuery2.itemID = this.selectIL.itemID ? this.selectIL.itemID : null
          await this.findIt()
        }
      },
      async findEAN() {
        if (this.editedItem.ItemEANCode) {
          const newObj = {}
          this.mainQuery2 = {...newObj}
          this.mainQuery2.CompanyId = this.$store.state.companyid
          this.mainQuery2.ItemEANCode = this.editedItem.ItemEANCode ? this.editedItem.ItemEANCode : null
          await this.findIt()
        }
      },
      async findIt () {
        try {
          const newObj = {}
          this.editedItem = {...newObj}
          this.editedItem = {...this.defaultItem}
          this.itemLoc = {...newObj}
          await ItemService.choose(this.mainQuery2)
          .then(res => {
            this.itemLoc = res.data.item ? res.data.item : null
          })
          .catch(err => {
            console.log('NEMAM TOG ARTIKLA 1!!')
            this.text = this.lang.itemNotExist
            this.color = 'red'
            this.snackbar = true
            this.error = ' Greska 1. Ne mogu naci artikla!!' + err
            this.typeError = 'error'
            return
          })
          //this.itemLoc = (await ItemService.choose(this.mainQuery2)).data.item 
          //this.itemLoc = this.selectIL.itemID ? await this.itemsLoc.find(itemM => itemM.itemID === this.selectIL.itemID) : null
          if (this.itemLoc) {                    
              
              this.editedItem.itemID = this.itemLoc.itemID
              this.editedItem.itemName = this.itemLoc.itemName
              this.editedItem.ItemNameShort = this.itemLoc.ItemNameShort
              this.editedItem.ItemCode = this.itemLoc.ItemCode
              this.editedItem.ItemEANCode = this.itemLoc.ItemEANCode
              this.editedItem.itemGroup = this.itemLoc.itemGroup
              this.editedItem.itemDescription = this.itemLoc.itemDescription
              this.editedItem.itemUnit = this.itemLoc.itemUnit
              this.editedItem.ItemSize = this.itemLoc.ItemSize
              this.editedItem.ItemWeight = this.itemLoc.ItemWeight
              this.editedItem.ItemWeightMeasure = this.itemLoc.ItemWeightMeasure
              this.editedItem.ItemDimMeasure = this.itemLoc.ItemDimMeasure
              this.editedItem.ItemDimLenght = this.itemLoc.ItemDimLenght
              this.editedItem.ItemDimHeight = this.itemLoc.ItemDimHeight
              this.editedItem.ItemDimDepth = this.itemLoc.ItemDimDepth
              this.editedItem.ItemWarehouse = this.itemLoc.ItemWarehouse
              this.editedItem.ItemWarehouseRow = this.itemLoc.ItemWarehouseRow
              this.editedItem.ItemWarehouseShelf = this.itemLoc.ItemWarehouseShelf
              this.editedItem.ItemWahrehouseFloor = this.itemLoc.ItemWahrehouseFloor
              this.editedItem.ItemPicture1 = this.itemLoc.ItemPicture1
              this.editedItem.ItemPicture2 = this.itemLoc.ItemPicture2
              this.editedItem.ItemPicture3 = this.itemLoc.ItemPicture3
              this.editedItem.ItemPicture4 = this.itemLoc.ItemPicture4
              this.editedItem.ItemPicture5 = this.itemLoc.ItemPicture5
              this.editedItem.ItemPicture6 = this.itemLoc.ItemPicture6
              this.editedItem.ItemPicture7 = this.itemLoc.ItemPicture7
              this.editedItem.ItemBelongsToId = this.itemLoc.ItemBelongsToId
              this.editedItem.itemService = this.itemLoc.itemService
              this.editedItem.itemWeb = this.itemLoc.itemWeb
              this.editedItem.itemNotActiv = this.itemLoc.itemNotActiv
              this.editedItem.itemHideForever = this.itemLoc.itemHideForever
              this.editedItem.itemSuppBasePrice = parseFloat(this.itemLoc.itemSuppBasePrice)
              this.editedItem.itemSuppDiscount = parseFloat(this.itemLoc.itemSuppDiscount)
              this.editedItem.itemSuppPrice = this.itemLoc.itemSuppPrice ? this.round(parseFloat(this.itemLoc.itemSuppPrice), 2) : 0
              this.editedItem.ItemSuppCode = this.itemLoc.ItemSuppCode ? this.itemLoc.ItemSuppCode : 0
              this.editedItem.itemMargin = this.itemLoc.itemMargin ? this.round(parseFloat(this.itemLoc.itemMargin), 2) : 0
              this.editedItem.itemMaxDiscount = this.itemLoc.itemMaxDiscount ? this.round(parseFloat(this.itemLoc.itemMaxDiscount), 2) : 0
              this.editedItem.itemPrice = this.itemLoc.itemPrice ? this.round(parseFloat(this.itemLoc.itemPrice), 3) : 0
              this.editedItem.itemVAT1Percentage = this.itemLoc.itemVAT1Percentage ? this.round(parseFloat(this.itemLoc.itemVAT1Percentage), 2) : 0
              this.editedItem.itemVAT1Amount = this.itemLoc.itemVAT1Amount ? parseFloat(this.itemLoc.itemVAT1Amount) : 0
              this.editedItem.itemVAT2Percentge = this.itemLoc.itemVAT2Percentge ? parseFloat(this.itemLoc.itemVAT2Percentge) : 0
              this.editedItem.itemVAT2Amount = this.itemLoc.itemVAT2Amount ? parseFloat(this.itemLoc.itemVAT2Amount) : 0
              this.editedItem.itemDirectDuty1 = this.itemLoc.itemDirectDuty1 ? this.round(parseFloat(this.itemLoc.itemDirectDuty1), 2) : 0
              this.editedItem.itemDirectDuty2 = this.itemLoc.itemDirectDuty2 ? parseFloat(this.itemLoc.itemDirectDuty2) : 0
              this.editedItem.itemPriceWithVAT = this.itemLoc.itemPriceWithVAT ? this.round(parseFloat(this.itemLoc.itemPriceWithVAT), 2) : 0

              this.editedItem.itemPriceOriginal = this.itemLoc.itemPrice ? this.round(parseFloat(this.itemLoc.itemPrice), 2) : 0
              this.editedItem.itemFullPriceOriginal = this.itemLoc.itemPriceWithVAT ? this.round(parseFloat(this.itemLoc.itemPriceWithVAT), 2) : 0
              this.editedItem.itemBaseAmount = this.round((this.editedItem.itemPriceOriginal), 3)
              this.editedItem.itemAmountWithVAT = this.round((this.editedItem.itemFullPriceOriginal ), 2)

              this.editedItem.itemForePrice = this.itemLoc.itemForePrice ? parseFloat(this.itemLoc.itemForePrice) : 0
              this.editedItem.itemForeVAT1Percentage = this.itemLoc.itemForeVAT1Percentage ? parseFloat(this.itemLoc.itemForeVAT1Percentage) : 0
              this.editedItem.itemForeVAT1Amount = this.itemLoc.itemForeVAT1Amount ? parseFloat(this.itemLoc.itemForeVAT1Amount) : 0
              this.editedItem.itemForeVAT2Percentage = this.itemLoc.itemForeVAT2Percentage ? parseFloat(this.itemLoc.itemForeVAT2Percentage) : 0
              this.editedItem.itemForeVAT2Amount = this.itemLoc.itemForeVAT2Amount ? parseFloat(this.itemLoc.itemForeVAT2Amount) : 0
              this.editedItem.itemForeDirectDuty1 = this.itemLoc.itemForeDirectDuty ? parseFloat(this.itemLoc.itemForeDirectDuty) : 0
              this.editedItem.itemForeDirectDuty2 = this.itemLoc.itemForeDirectDuty2 ? parseFloat(this.itemLoc.itemForeDirectDuty2) : 0
              this.editedItem.itemForePriceWithVAT = this.itemLoc.itemForePriceWithVAT ? parseFloat(this.itemLoc.itemForePriceWithVAT) : 0
              const itemMargin = (this.editedItem.itemPrice - this.editedItem.itemSuppPrice)
              if (itemMargin > 0) {
                this.maxDiscPer = (this.editedItem.itemPriceWithVAT / 100) ? this.round((this.editedItem.itemPriceWithVAT / 100),3) : 0
                this.editedItem.itemMaxDiscount = ((itemMargin / this.maxDiscPer)) ? this.round(((itemMargin / this.maxDiscPer)),2) : 0
              }
              this.editedItem.itemDiscountPercentage1 = 0
              //this.doParse()
              await this.calculateDiscount()
              this.haveItemSelected = true
            }
       
        } catch (error) {
          console.log('NEMAM TOG ARTIKLA 2!!')
          // eslint-disable-next-line
          console.log(' greska Ne mogu naci artikla', error)
        }
      },
      initPcs() {
        this.pcsLoc = 1
        if (this.$store.state.documentSide === 1) {
          this.pcsLoc = this.editedItem.itemPcsPlus ? this.editedItem.itemPcsPlus : 0
        }
        if (this.$store.state.documentSide === 2) {
          this.pcsLoc = this.editedItem.itemPcsMinus ? this.editedItem.itemPcsMinus : 1
        }
        if (this.$store.state.documentSide === 3) {
          this.pcsLoc = this.editedItem.itemPcsNeutral ? this.editedItem.itemPcsNeutral : 0
        }
        this.pcsLoc = this.round(this.pcsLoc,2) === 0 ? 1 : this.pcsLoc
        this.pcsLoc = this.round(this.pcsLoc, 3)
      },
      calculatePrice() {
        
        this.initPcs()
        if (this.editedItem.itemPriceWithVAT === null || this.editedItem.itemPriceWithVAT === '') {
          this.editedItem.itemPriceWithVAT = this.editedItem.itemFullPriceOriginal
        }
         if (this.editedItem.itemPriceWithVAT > this.editedItem.itemFullPriceOriginal) {
          this.editedItem.itemPriceWithVAT = this.editedItem.itemFullPriceOriginal
        }
       
        this.editedItem.itemAmountWithVAT = this.round((this.editedItem.itemPriceWithVAT * this.pcsLoc), 2)

        const noDiscountAmount = this.round((this.editedItem.itemFullPriceOriginal * this.pcsLoc), 2)
        const percentage = noDiscountAmount / 100

        this.editedItem.itemDiscountAmount1 =  this.round((this.round((this.editedItem.itemFullPriceOriginal * this.pcsLoc), 2) - this.editedItem.itemAmountWithVAT),2)
        
        this.editedItem.itemDiscountPercentage1 = percentage > 0 ? this.round((this.editedItem.itemDiscountAmount1 / percentage), 2) : 0

        this.editedItem.itemDiscountPrice = this.editedItem.itemAmountWithVAT / this.pcsLoc
        this.editedItem.itemDiscountPrice = this.round(this.editedItem.itemPriceWithVAT, 2)
        this.editedItem.itemBaseAmountNoVAT = this.editedItem.itemAmountWithVAT / (1 + (this.editedItem.itemVAT1Percentage / 100))
        this.editedItem.itemBaseAmountNoVAT = this.round(this.editedItem.itemBaseAmountNoVAT, 2)
        this.editedItem.itemVAT1Amount = (this.editedItem.itemAmountWithVAT - this.editedItem.itemBaseAmountNoVAT)
        this.editedItem.itemVAT1Amount = this.round(this.editedItem.itemVAT1Amount, 2)
        
        this.editedItem.itemDirectDuty1Amount = this.round((this.editedItem.itemDirectDuty1 * this.pcsLoc), 2)
        this.editedItem.itemFullTotal = this.editedItem.itemAmountWithVAT + this.round((this.editedItem.itemDirectDuty1 * this.pcsLoc), 2)
        this.editedItem.itemFullTotal = this.round(this.editedItem.itemFullTotal, 2)

      },
      calculateDiscount () {
        this.initPcs()
       
        this.editedItem.itemDirectDuty1 = this.editedItem.itemDirectDuty1 ? this.round(this.editedItem.itemDirectDuty1, 2) : 0
        this.editedItem.itemVAT1Percentage = this.editedItem.itemVAT1Percentage ? this.round(this.editedItem.itemVAT1Percentage, 2) : 0
        this.editedItem.itemPrice = this.editedItem.itemPrice ? this.round(this.editedItem.itemPrice, 3) : 0
        this.editedItem.itemPriceWithVAT = this.editedItem.itemPriceWithVAT ? this.round(this.editedItem.itemPriceWithVAT, 2) : 0
        this.editedItem.itemBaseAmount = this.round((this.editedItem.itemPriceOriginal * this.pcsLoc), 3)
        this.editedItem.itemAmountWithVAT = this.round((this.editedItem.itemPriceWithVAT * this.pcsLoc), 2)
        
        if (this.editedItem.itemDiscountPercentage1 >= 0) {
          this.editedItem.itemAmountWithVAT = this.round((this.editedItem.itemFullPriceOriginal * this.pcsLoc), 2) - (this.round((this.editedItem.itemFullPriceOriginal * this.pcsLoc), 2) * (this.editedItem.itemDiscountPercentage1 / 100))
          this.editedItem.itemPriceWithVAT = this.pcsLoc && this.pcsLoc !== 0 ? this.editedItem.itemAmountWithVAT / this.pcsLoc : this.editedItem.itemAmountWithVAT
          this.editedItem.itemPriceWithVAT = this.round(this.editedItem.itemPriceWithVAT,2)
          this.editedItem.itemDiscountAmount1 = this.round((this.editedItem.itemFullPriceOriginal * this.pcsLoc), 2) - this.editedItem.itemAmountWithVAT
          this.editedItem.itemDiscountPrice = this.editedItem.itemAmountWithVAT / this.pcsLoc
          this.editedItem.itemDiscountPrice = this.round(this.editedItem.itemPriceWithVAT, 2)
        }
        
        this.editedItem.itemBaseAmountNoVAT = this.editedItem.itemAmountWithVAT / (1 + (this.editedItem.itemVAT1Percentage / 100))
        this.editedItem.itemBaseAmountNoVAT = this.round(this.editedItem.itemBaseAmountNoVAT, 2)
        this.editedItem.itemVAT1Amount = (this.editedItem.itemAmountWithVAT - this.editedItem.itemBaseAmountNoVAT)
        this.editedItem.itemVAT1Amount = this.round(this.editedItem.itemVAT1Amount, 2)
        
        //this.editedItem.itemDirectDuty1Amount = this.round((this.editedItem.itemDirectDuty1 * this.pcsLoc), 2)
        this.editedItem.itemFullTotal = (this.editedItem.itemAmountWithVAT)
        this.editedItem.itemFullTotal = this.round(this.editedItem.itemFullTotal, 2)

      },
      makeSum() {
        const exRate = this.$store.getters.NeedDefExRate
        const updateSumObject = {
          documentDescription9: 0,
          documentDescription10: 0,
          documentTotalVAT1Per: 0,
          documentTotalVAT1Base: 0,
          documentTotalVAT1Amount: 0,
          documentTotalVAT2Per: 0,
          documentTotalVAT2Base: 0,
          documentTotalVAT2Amount: 0,
          documentTotalVAT3Per: 0,
          documentTotalVAT3Base: 0,
          documentTotalVAT3Amount: 0,
          documentTotalVAT4Per: 0,
          documentTotalVAT4Base: 0,
          documentTotalVAT4Amount: 0,
          documentTotalVAT5Per: 0,
          documentTotalVAT5Base: 0,
          documentTotalVAT5Amount: 0,
          documentTotalAmountNetto: 0,
          documentTotalAmountVAT: 0,
          documentTotalAmountBrutto: 0,
          documentTotalAmountPayInAdvance: 0,
          documentTotalAmountToPay: 0,
          documentTotalAmountNettoForeign: 0,
          documentTotalAmountVATForeign: 0,
          documentTotalAmountBruttoForeign: 0,
          documentTotalAmountPayInAdvanceForeign: 0,
          documentTotalAmountToPayForeign: 0,
          documentTotalAmountDirectD1: 0,
          documentTotalAmountDirectD2: 0,
          documentTotalAmountDirectD1Fore: 0,
          documentTotalAmountDirectD2Fore: 0,
          FreeF1: 0,
          FreeF2: 0,
          FreeF3: 0,
          FreeF4: 0,
          FreeF5: 0,
          FreeF6: 0,
          documentHomeCurrency: 'Kn',
          documentForeignCurrency: 'EUR',
          documentExRate: exRate
        }
        
        const hrVat1 = 25
        const hrVat2 = 13
        const hrVat3 = 5
        let total1 = 0
        let total2 = 0
        let total3 = 0

        const documentsDet = this.itemsPOS ? this.itemsPOS : []
        if (documentsDet) {
          documentsDet.map(documentDet => {
            if (documentDet.itemBaseAmount) {
              updateSumObject.documentDescription9 += (documentDet.itemBaseAmount)
            }
            if (documentDet.itemBaseAmountNoVAT) {
              updateSumObject.documentTotalAmountNetto += (documentDet.itemBaseAmountNoVAT)
            }
            if (documentDet.itemVAT1Amount) {
              updateSumObject.documentTotalAmountVAT += (documentDet.itemVAT1Amount)
            }
            //const dD1 = documentDet.itemDirectDuty1Amount ? (documentDet.itemDirectDuty1Amount) : 0
            if (documentDet.itemFullTotal) {
              updateSumObject.documentTotalAmountBrutto += (documentDet.itemFullTotal) 
            }
            if (this.round(documentDet.itemVAT1Percentage, 2) === hrVat1) {
              total1 += this.round(documentDet.itemFullTotal, 2)
              //updateSumObject.documentTotalVAT1Amount += (documentDet.itemVAT1Amount)
            }
            if (this.round(documentDet.itemVAT1Percentage, 2) === hrVat2) {
              total2 += this.round(documentDet.itemFullTotal, 2)
              //updateSumObject.documentTotalVAT2Amount += (documentDet.itemVAT1Amount)
            }
            if (this.round(documentDet.itemVAT1Percentage, 2) === hrVat3) {
              total3 += this.round(documentDet.itemFullTotal, 2)
              //updateSumObject.documentTotalVAT3Amount += (documentDet.itemVAT1Amount)
            }
            if (documentDet.itemBaseAmount) {
              if (parseInt(documentDet.FreeF1) === 1) {
                updateSumObject.FreeF1 += (documentDet.itemBaseAmount)
              }
              if (parseInt(documentDet.FreeF1) === 2) {
                updateSumObject.FreeF2 += (documentDet.itemBaseAmount)
                updateSumObject.FreeF5 += (documentDet.empWrkHrs)
              }
              if (parseInt(documentDet.FreeF1) === 3) {
                updateSumObject.FreeF3 += (documentDet.itemBaseAmount)
              }
            }
            if (documentDet.itemBaseAmountNoVAT) {
              if (parseInt(documentDet.FreeF1) === 4) {
                updateSumObject.FreeF4 += (documentDet.itemBaseAmountNoVAT)
              }
            }
            if (documentDet.itemDirectDuty1Amount) {
              updateSumObject.documentTotalAmountDirectD1+= (documentDet.itemDirectDuty1Amount)
            }
          })
        }
        updateSumObject.documentTotalVAT1Per = hrVat1 ? this.round(hrVat1, 2) : 0
        updateSumObject.documentTotalVAT2Per = hrVat2 ? this.round(hrVat2, 2) : 0
        updateSumObject.documentTotalVAT3Per = hrVat3 ? this.round(hrVat3, 2) : 0

        updateSumObject.documentTotalVAT1Amount = this.round((total1 - (total1 / (1 +(hrVat1/100)))), 2)
        updateSumObject.documentTotalVAT2Amount = this.round((total2 - (total2 / (1 +(hrVat2/100)))), 2)
        updateSumObject.documentTotalVAT3Amount = this.round((total3 - (total3 / (1 +(hrVat3/100)))), 2)
        updateSumObject.documentTotalVAT1Base = this.round(total1 - updateSumObject.documentTotalVAT1Amount , 2)
        updateSumObject.documentTotalVAT2Base = this.round(total2 - updateSumObject.documentTotalVAT2Amount , 2)
        updateSumObject.documentTotalVAT3Base = this.round(total3 - updateSumObject.documentTotalVAT3Amount , 2)
        updateSumObject.documentTotalAmountVAT = updateSumObject.documentTotalVAT1Amount + updateSumObject.documentTotalVAT2Amount + updateSumObject.documentTotalVAT3Amount

        updateSumObject.documentTotalAmountBrutto = updateSumObject.documentTotalAmountBrutto ? this.round(updateSumObject.documentTotalAmountBrutto, 2) : 0
        updateSumObject.documentTotalAmountNetto = this.round(updateSumObject.documentTotalAmountBrutto - updateSumObject.documentTotalAmountVAT, 2)

        updateSumObject.documentDescription9 = updateSumObject.documentDescription9 ? this.round(updateSumObject.documentDescription9, 2) : 0
        
        updateSumObject.FreeF1 = updateSumObject.FreeF1 ? this.round(updateSumObject.FreeF1, 2) : 0
        updateSumObject.FreeF2 = updateSumObject.FreeF2 ? this.round(updateSumObject.FreeF2, 2) : 0
        updateSumObject.FreeF3 = updateSumObject.FreeF3 ? this.round(updateSumObject.FreeF3, 2) : 0
        updateSumObject.FreeF4 = updateSumObject.FreeF4 ? this.round(updateSumObject.FreeF4, 2) : 0
        updateSumObject.FreeF5 = updateSumObject.FreeF5 ? this.round(updateSumObject.FreeF5, 2) : 0
        updateSumObject.documentDescription10 = this.round((updateSumObject.documentDescription9 - updateSumObject.documentTotalAmountNetto),2)
        updateSumObject.documentTotalAmountPayInAdvance = 0
        updateSumObject.documentTotalAmountToPay = updateSumObject.documentTotalAmountBrutto ? this.round(updateSumObject.documentTotalAmountBrutto, 2) : 0
        updateSumObject.documentTotalAmountNettoForeign = updateSumObject.documentTotalAmountNetto ? this.round((updateSumObject.documentTotalAmountNetto / exRate), 2) : 0
        updateSumObject.documentTotalAmountVATForeign = updateSumObject.documentTotalAmountVAT ? this.round((updateSumObject.documentTotalAmountVAT / exRate), 2) : 0
        updateSumObject.documentTotalAmountBruttoForeign = updateSumObject.documentTotalAmountBrutto ? this.round((updateSumObject.documentTotalAmountBrutto / exRate), 2) : 0
        updateSumObject.documentTotalAmountPayInAdvanceForeign = 0
        updateSumObject.documentTotalAmountToPayForeign = updateSumObject.documentTotalAmountBrutto ? this.round((updateSumObject.documentTotalAmountBrutto / exRate), 2) : 0
        updateSumObject.documentTotalAmountDirectD1Fore = updateSumObject.documentTotalAmountDirectD1 ? this.round((updateSumObject.documentTotalAmountDirectD1 / exRate), 2) : 0
        updateSumObject.documentHomeCurrency = 'Kn'
        updateSumObject.documentForeignCurrency = 'EUR'
        updateSumObject.documentExRate =  this.$store.getters.NeedDefExRate
        const newHead = {...this.$store.state.documentActivHead, ...updateSumObject}
        if (newHead) {
          this.$store.dispatch('setDocumentActivHead', newHead)
        }        
      },
      round (value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
      },
      getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min) ) + min;
      },
      async settypePOS() {
        try {
          if (this.itemsPOS.length === 0) {
            this.text = 'Error! Ne može se zaključiti račun bez stavaka!!' 
            this.color = 'red'
            this.snackbar = true
            this.timeout = 4000
            return
          }
          if (this.itemsPOS) {
            await this.$store.dispatch('setDocumentActivItems', this.itemsPOS)
          } else {
            await this.$store.dispatch('setDocumentActivItems', [])
          }
          this.itemsPOS = []
          await this.$store.dispatch('settypePOS', 2)
        } catch (err) {
          // eslint-disable-next-line
          console.log(err)
        }
      },
      async initialize () {
         this.errorMatch = false
         const newObj = {}
         this.editedItem = {...newObj}
         this.editedItem = await {...this.defaultItem}
         this.select = {...newObj}
         this.selectIL = {...newObj}
         this.haveItemSelected = false
      },
      checkDiscount() {
         if (this.editedItem.itemDiscountPercentage1 < 0) {
            this.editedItem.itemDiscountPercentage1 = 0
          }
          if (this.editedItem.itemDiscountPercentage1 > 100) {
            this.editedItem.itemDiscountPercentage1 = 0
          }

      },
      editItem (item) {
        this.errorMatch = false
        this.editedIndex = this.itemsPOS.indexOf(item)
        this.editedItem = {...item}
        this.selectIL = item.itemID ? item.itemID : null
        this.haveItemSelected = true
        this.dialog = true
      },
      deleteItem (item) {
          const index = this.itemsPOS.indexOf(item)
          const warningMessage = item.itemName
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.itemsPOS.splice(index, 1)) {
            this.initialize()
            this.makeSum()
          }        
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          //this.editedItem = Object.assign({}, this.defaultItem)
          this.initialize()
          this.editedIndex = -1
        }, 300)
      },

     async save () {
        if (this.editedItem && this.editedItem.itemID && this.editedItem.itemID.length > 0 ) {
            if (this.editedItem.itemDiscountPercentage1 === null || this.editedItem.itemDiscountPercentage1 === '') {
              this.editedItem.itemDiscountPercentage1 = 0
              this.calculateDiscount()
            }

            if (this.editedIndex > -1) {
              Object.assign(this.itemsPOS[this.editedIndex], this.editedItem)
            } else {
              this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              this.editedItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
              this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
              this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              this.editedItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : ''
              // put in array
              await this.itemsPOS.push(this.editedItem)
              this.select = {}
              this.selectIL = {}
            }
        }
        this.fullpriceChange = true
        await this.makeSum()
        this.close()

      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      }
    },
  }
</script>