<template>
  <div>
    <p class="headline text--primary">
      {{lang.FinancialData}}
    </p>

      <v-row no-gutters>
        <v-col cols="4">                   
          {{lang.BankName}}
        </v-col>
        <v-col cols="8">
            <span style="font-weight:bold">{{document.bankAccountName}}, {{document.bankAccountId}}</span>
        </v-col>
      </v-row>

      <v-layout row >
        <v-col cols="4">
          {{lang.IBAN}}
        </v-col>
        <v-col cols="8">
            <span style="font-weight:bold">{{document.bankAccountNr}}</span>
        </v-col>
      </v-layout>

    <v-divider></v-divider>

        <v-row class="py-2" >

            <v-flex xs5 class="py-0 px-2">
                <span v-if="this.$store.state.language == 'EN'">
                  + To Invoicing:
                </span>
                <span v-if="this.$store.state.language == 'HR'">
                  + Proizvedeno:
                </span>
            </v-flex>

            <v-flex xs4 class="py-0 px-2 text-xs-right">
              <span >{{ localMoney(document.FreeF4 ) }}
              </span> 
            </v-flex>

          </v-row>

          <v-row >
            <v-flex xs5 class="py-0 px-2">
                <span v-if="this.$store.state.language == 'EN'">
                  - Material cost:
                </span>
                <span v-if="this.$store.state.language == 'HR'">
                  - Trošak materijala:
                </span>
            </v-flex>

            <v-flex xs4 class="py-0 px-2 text-xs-right">
              <span >{{localMoney( document.FreeF1 ) }}
              </span> 
            </v-flex>

          </v-row>

          <v-row >
            <v-flex xs5 class="py-0 px-2">
                <span v-if="this.$store.state.language == 'EN'">
                  - Workers cost: (work hrs: {{localMoney( document.FreeF5 ) }})
                </span>
                <span v-if="this.$store.state.language == 'HR'">
                  - Trošak radnika: (radni sati: {{ localMoney(document.FreeF5 ) }})
                </span>
            </v-flex>
            <v-flex xs4 class="py-0 px-2 text-xs-right">
                <span >{{ localMoney(document.FreeF2 ) }}
              </span> 
            </v-flex>
          </v-row>

          <v-row class="py-2">
            <v-flex xs5 class="py-0 px-2">
                <span v-if="this.$store.state.language == 'EN'">
                  - Other costs:
                </span>
                <span v-if="this.$store.state.language == 'HR'">
                  - Ostali troškovi:
                </span>
            </v-flex>
            <v-flex xs4 class="py-0 px-2 text-xs-right">
                <span >{{ localMoney(document.FreeF3 ) }}
                </span> 
            </v-flex>
          </v-row>

    <v-divider class="py-2"></v-divider>

      <v-row class="py-2">
        <v-flex xs5 class="headline">
            <span color="red darken-1" v-if="this.$store.state.language == 'EN'">
              = Profit/Loss:
            </span>
            <span color="red darken-1" v-if="this.$store.state.language == 'HR'">
              = Dobit/Gubitak:
            </span>
        </v-flex>
        <v-flex xs4 class="text-xs-right" >
          <span class="headline green white--text" v-if="profitLoss >= 0">{{ localMoney(profitLoss) }}</span>
          <span class="headline red white--text" v-if="profitLoss < 0">{{localMoney( profitLoss) }}</span>
        </v-flex>
      </v-row>

    <v-divider></v-divider>

  </div>
</template>

<script>
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import moneyFormat from '@/snippets/moneyFormat'

export default {
  data() {
    return {
      document: {},
      lang: {},
      langC: {},
      documentNameLoc: '',
      docSide: 3,
      profitLoss: 0,
      profitLossP: '',
    }
  },
  mounted() {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.documentActivHead) {
      this.document = this.$store.state.documentActivHead
      this.documentNameLoc = this.$store.state.documentActivLoc
    }
     if (this.$store.state.documentSide) {
        this.docSide = this.$store.state.documentSide
      }
     this.profitLoss = (this.document.FreeF4 ? parseFloat(this.document.FreeF4) : 0) - (this.document.FreeF1 ? parseFloat(this.document.FreeF1) : 0) - (this.document.FreeF2 ? parseFloat(this.document.FreeF2) : 0) - (this.document.FreeF3 ? parseFloat(this.document.FreeF3) : 0)
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
  } 
}
</script>

<style>

</style>