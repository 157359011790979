<template>
  <div class="task">
    <v-progress-circular v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>

  <v-row>
    <v-col cols="12" sm="12" > 
      <v-card>
        <v-row align="center" class="list px-3 mx-auto">
          <v-col cols="4" sm="2">
            <v-select
              v-model="searchOption"
              :items="searchOptions"
              :label="langC.SearchBy"
              clearable
              @change="handleSearchOption"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field v-model="searchName" :label="langC.SearchBy"></v-text-field>
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn small @click="page = 1; defaultInit();">
              {{langC.Search}}
            </v-btn>
          </v-col>
          <v-col cols="5" sm="4">
            <v-pagination
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            ></v-pagination>
            
          </v-col>
          <v-col cols="4" sm="1" >
            Total:{{totalItems}}
          </v-col>
          <v-col cols="4" sm="1" >
            <v-select
              v-model="pageSize"
              :items="pageSizes"
              :label="langC.ItemsPerPage"
              @change="handlePageSizeChange"
            ></v-select>
        </v-col>
      </v-row>
     </v-card>
    </v-col>
  </v-row> 

  <v-row>
    <v-col cols="12" sm="12"> 
      <v-card>
        <v-card-title>
          Joppd A 
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="documentId"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"
          >

          <template v-slot:[`item.DatumIzvjesca`]="{ item }">
              <span>{{ localDate(item.DatumIzvjesca) }}</span>
          </template>
          <template v-slot:[`item.Order`]="{ item }">
              <span>{{ parseInt(item.Order) }}</span>
          </template>
          <template v-slot:[`item.Locked`]="{ item }">
            <span v-if="item.Locked"><v-icon>mdi-check</v-icon></span>
          </template>          

          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>
     </v-col>
    </v-row> 
  </div>
</template>

<script>
import JoppdAService from '@/services/JoppdAService'
import ledgerEN from './salarieDescEn'
import ledgerHR from './salarieDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import moneyFormat from '@/snippets/moneyFormat'
import dayjs from 'dayjs'

export default {
  name: 'joppdalist',
  data () {
    return {
      documentName: '',
      documentNameLoc: '',
      docside: 0,
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        MLType: 1
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
      searchName: "",
      page: 1,
      totalPages: 0,
      pageSize: 30,
      pageSizes: [10, 30, 50],
      searchOption: '',
      searchOptions: [],
      totalItems: 0,
      bookTypeSelected: {},
      show: 0
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
    this.defaultInit()
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    localDate(dateToFormat) {
       return dayjs(dateToFormat).format('DD.MM.YYYY')
    },
    async defaultInit() {
      this.$store.dispatch('seteditItem', 0)
      this.headers = []
      this.searchOptions = []
       
      const query = this.setQuery()
      if (query.searchItem && this.searchName) {
        query.searchName =  this.searchName.trim()
        await this.$store.dispatch('setOnPage', query)
      }
      if (query.searchItem && query.searchName) {
        this.searchOption = query.searchItem
        this.searchName = query.searchName 
      }
      this.saving = true
 
      await JoppdAService.indexlist(query)
      .then(res => {
        this.items = res.data.joppda ? res.data.joppda : []
        this.totalItems = res.data.totalPages
        this.totalPages = parseInt(res.data.totalPages / this.pageSize) + 1
      })
      .catch(err => {
        this.havealert = !this.havealert
        this.havemssgalert = 'Error' + err
      })
      this.saving = false
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc

      this.headers.push(
        {
          text: 'DatumIzvješća', align: 'left', sortable: true, value: 'DatumIzvjesca', width: '60px'
        },
        { text: 'OznakaIzvješća', align: 'left', sortable: true, value: 'OznakaIzvjesca', width: '50px' },
        { text: 'VrstaIzvješća', align: 'left', sortable: true, value: 'VrstaIzvjesca', width: '50px' },
        { text: 'PodNaziv', align: 'left', sortable: true, value: 'PodNaziv', width: '280px' },
        { text: 'BrojOsoba', align: 'center', value: 'BrojOsoba', width: '100px' },
        { text: 'BrojRedaka', align: 'left', sortable: true, value: 'BrojRedaka', width: '80px' },
        { text: this.lang.Locked, align: 'left', sortable: true, value: 'Locked', width: '40px' },
        { text: 'DbId', value: 'id', width: '60px' }
      )
      this.searchOptions.push(
        {
          text: 'OznakaIzvjesca', align: 'left', sortable: true, value: 'OznakaIzvjesca', width: '60px'
        },
        { text: 'BrojOsoba', align: 'left', sortable: true, value: 'BrojOsoba', width: '50px' },
        { text: 'PodNaziv', align: 'center', value: 'PodNaziv', width: '100px' },
      )
    },
    async navigateTo (params) {
      console.log("🚀 ~ file: JoppdA.vue ~ line 220 ~ navigateTo ~ params", params)
      
      await this.$store.dispatch('seteditItem', 1)
      this.$store.dispatch('setretPath', 'joppda')
      await this.$store.dispatch('setDocumentid', params.id)
      this.$router.push({
        name: 'joppdadetail'
      })
    },

    async handlePageChange(value) {      
      const query = this.$store.state.onPage
      query.page = value - 1;
      this.page = value
      await this.$store.dispatch('setOnPage', query)
      this.defaultInit();
    },

    async handlePageSizeChange(size) {
      const query = this.$store.state.onPage
      query.pageSize = size;
      query.page = 1;
      this.pageSize = size
      await this.$store.dispatch('setOnPage', query)
      this.defaultInit();
    },

    async handleSearchOption(option) {
      this.searchOption = option
      const query = this.$store.state.onPage
      query.searchItem = option
      query.page = 0
      await this.$store.dispatch('setOnPage', query)
    },

    setQuery() {
      const haveOnPage = this.$store.state.onPage
      const query = {}
      if (this.$store.state.companyid) {
        query.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        query.BusinessYear = this.$store.state.businessYear
      }
      query.searchName = haveOnPage.searchName ? haveOnPage.searchName.trim() : null
      query.page = haveOnPage.page ? haveOnPage.page : 0
      this.page  = query.page + 1
      query.pageSize = haveOnPage.pageSize ? haveOnPage.pageSize : 30
      this.pageSize = query.pageSize
      query.searchItem = haveOnPage.searchItem ? haveOnPage.searchItem : null
      return query
    },
    async defaultQuery() {
      const query = {}
      if (this.$store.state.companyid) {
        query.CompanyId = this.$store.state.companyid
      }
      query.searchName = null
      query.page =  0
      query.pageSize =  30
      query.searchItem =  null
      await this.$store.dispatch('setOnPage', query)
      this.searchName = ""
      this.page = 1
      this.totalPages = 0
      this.pageSize = 30
      this.mainQuery = query
    },
    async initAgain () {
      this.defaultQuery()
      this.defaultInit()
    }
  },
  computed: {

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
