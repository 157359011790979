<template>

  <v-card color="grey lighten-4" flat>
    <v-card-text>
      <v-container fluid>

        <v-layout row>
          <v-flex xs5>
            <h3>Group details</h3>
          </v-flex>
          <v-flex xs5>
              <span v-if="this.$store.state.user.AccessLevel <= 3">
                <v-btn class="primary mr-1" @click="groupEdit">{{langC.Edit}}</v-btn>
              </span>
              <span v-if="this.$store.state.user.AccessLevel == 1">
                <v-btn class="red mr-1" @click="groupDelete">{{langC.Delete}}</v-btn>
              </span>
              <v-btn color="green" @click="grouplist">{{langC.Back}}</v-btn>
          </v-flex>
        </v-layout>

        <div class="pink darken-2" v-html="error" />

        <v-layout row>
          <v-flex xs2>
            Group name:
          </v-flex>
          <v-flex xs10>
              <span style="font-weight:bold">{{group.GroupName}}</span>
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex xs2>
            Group belongs to:
          </v-flex>
          <v-flex xs10>
              <span style="font-weight:bold">{{group.GroupBelongsTo}}</span>
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex xs2>
            Entered In Database:
          </v-flex>
          <v-flex xs10>
              <span style="font-weight:bold">{{group.EnteredInDatabase}}</span>
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex xs2>
            Entred by userid:
          </v-flex>
          <v-flex xs10>
              <span style="font-weight:bold">{{group.UserId}}</span>
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex xs2>
            Last updated by:
          </v-flex>
          <v-flex xs10>
              <span style="font-weight:bold">{{group.LastUpdateBy}}</span>
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex xs2>
            Updated At:
          </v-flex>
          <v-flex xs10>
              <span style="font-weight:bold">{{locUpdatedAt}}</span>
          </v-flex>
        </v-layout>

      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import GroupService from '@/services/GroupService'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'

export default {
  name: 'groupview',
  data () {
    return {
      lang: {},
      langC: {},
      msg: '',
      group: {
      },
      locUpdatedAt: '',
      error: null
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.langC = commHr
    }
    if (this.$store.state.groupid) {
      try {
        const dbGroup = await GroupService.show(this.$store.state.groupid)
        this.group = dbGroup.data.group
        this.locUpdatedAt = dayjs(this.group.updatedAt).format('DD.MM.YYYY HH:MM')
      } catch (err) {
        this.error = err
      }
    }
  },
  methods: {
    async grouplist () {
      try {
        await this.$router.push({
          name: 'group'
        })
      } catch (err) {
        this.error = err
      }
    },
    async groupDelete () {
      try {
        await this.$router.push({
          name: 'groupdelete'
        })
      } catch (err) {
        this.error = err
      }
    },
    async groupEdit () {
      try {
        this.$store.dispatch('seteditItem', 1)
        await this.$router.push({
          name: 'groupedit'
        })
      } catch (err) {
        this.error = err
      }
    }
  },
  computed: {
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
