
<template>
  <v-container>
    <v-layout row wrap  justify-center>
      <v-flex xs12 sm10 md8 lg6>
        <v-card ref="form">
          <v-card-text>

              <v-layout row >
                <v-flex xs12 class="py-0 px-2">                
                  <span v-if="this.$store.state.language == 'EN'">
                    <h2>Employee Diary Details</h2>
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    <h2>Radnik dnevnik detalji</h2>
                    </span>
                </v-flex>
              </v-layout>

          <v-layout row>
                <v-flex xs5>
                </v-flex>
                <v-flex xs7>       
                          
                    <span v-if="this.$store.state.user.AccessLevel <= 3">
                      <v-btn color="primary" @click="empldayEdit" class="mr-2">
                          <span v-if="this.$store.state.language == 'EN'">
                          Edit
                          </span>
                          <span v-if="this.$store.state.language == 'HR'">
                          Uredi
                          </span>
                      </v-btn>
                    </span>
                    <span v-if="this.$store.state.user.AccessLevel == 1">
                      <v-btn color="red" @click="empldayDelete" class="mr-2">
                          <span v-if="this.$store.state.language == 'EN'">
                          Delete
                          </span>
                          <span v-if="this.$store.state.language == 'HR'">
                          Obrisi
                          </span>
                      </v-btn>
                    </span>
                    <v-btn color="green" @click="empldayList">
                        <span v-if="this.$store.state.language == 'EN'">
                        Back
                        </span>
                        <span v-if="this.$store.state.language == 'HR'">
                        Natrag
                        </span>
                    </v-btn>
                </v-flex>
              </v-layout>

              <v-layout row >
                <v-flex xs4 class="py-0 px-2">                
                  <span v-if="this.$store.state.language == 'EN'">
                    Company:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Firma:
                    </span>
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{emplday.CompanyNameDatabase}}, Id:{{emplday.CompanyId}}</span>
                </v-flex>
              </v-layout>

              <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Business Year:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Poslovna godina:
                    </span>                  
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{emplday.BusinessYear}}</span>
                </v-flex>
              </v-layout>

              <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    User:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Korisnik:
                    </span>                  
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{emplday.email}}, {{emplday.UserId}}</span>
                </v-flex>
              </v-layout>

              <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Date in database:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Datum upisa u bazu:
                    </span>                  
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{locOpenDate}}</span>
                </v-flex>
              </v-layout>

              <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Last update database:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Datum zadnje promjene:
                    </span>                  
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{locUpdatedAt}}</span>
                </v-flex>
              </v-layout>

              <v-divider></v-divider>

                <v-layout row >
                  <v-flex xs3 class="py-4 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Employee Id:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Zaposlenik:
                    </span>                    
                  </v-flex>
                  <v-flex xs9 class="py-4 px-2">
                      {{emplday.First}} {{emplday.Last}}, {{emplday.Employeehashtag}}, {{emplday.EmployeeVatID}}
                  </v-flex>
                </v-layout>

                <v-layout row >
                  <v-flex xs3 class="py-4 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Enter type:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Vrsta upisa:
                    </span>
                  </v-flex>
                  <v-flex xs3 class="py-4 px-2">
                      {{emplday.ActionTypeName}}
                  </v-flex>
                  <v-flex xs3 class="py-4 px-2">
                  </v-flex>
                </v-layout> 

                <v-layout row >
                  <v-flex xs3 class="py-4 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Work time:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Radno vrijeme:
                    </span>
                  </v-flex>
                  <v-flex xs5 class="py-4 px-2">
                      {{emplday.WorkingHoursTypeName}}
                  </v-flex>
                  <v-flex xs2 class="py-4 px-2">
                    <span v-if="this.$store.state.language == 'EN'">
                    Work hours:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Broj sati:
                    </span>
                    {{emplday.WorkingHours}}
                  </v-flex>
                  <v-flex xs2 class="py-4 px-2">
                    <span v-if="this.$store.state.language == 'EN'">
                    Minutes:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Minuta:
                    </span>
                    {{emplday.FreeF1}}
                  </v-flex>
                </v-layout>                  

              <v-layout row >
              <v-flex xs11 sm6 class="py-4 px-2">
                    <span v-if="this.$store.state.language == 'EN'">
                     Date change status:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                     Datum pri/odj/prom:
                    </span>
                    {{locForDate}}
              </v-flex>
              </v-layout>
           

              <v-layout row>
              <v-flex xs11 sm6 class="py-4 px-2">
                    <span v-if="this.$store.state.language == 'EN'">
                     Date end status:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                     Datum kraja statusa:
                    </span>
                    {{locAlarmDate}}
              </v-flex>
              </v-layout>
          

                <v-layout row>
                  <v-flex xs3 class="py-4 px-2">
                    
                  <span v-if="this.$store.state.language == 'EN'">
                     Health service ID:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                     HZZO broj upisa ID:
                    </span>
                  </v-flex>
                  <v-flex xs9 class="py-4 px-2">
                      {{emplday.HealthServiceID}}  
                  </v-flex>
                </v-layout>

                <v-layout row>
                  <v-flex xs3 class="py-4 px-2">
                    
                  <span v-if="this.$store.state.language == 'EN'">
                    Pension fund id:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                     Mirovinsko ID:
                    </span>                  
                  </v-flex>
                  <v-flex xs9 class="py-4 px-2">
                    {{emplday.PensionFundID}}
                  </v-flex>
                </v-layout>

                <v-layout row>
                  <v-flex xs3 class="py-4 px-2">
                    
                  <span v-if="this.$store.state.language == 'EN'">
                    Diary Description:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                     Kratki opis upisa:
                    </span>                  
                  </v-flex >
                  <v-flex xs9 class="py-4 px-2">
                    {{emplday.EmplDayFree1}}
                  </v-flex>
                </v-layout>

                <v-chip v-if="error" label color="red" text-color="white">
                  {{error}}
                </v-chip>


          </v-card-text>
          <v-divider class="mt-1"></v-divider>

        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import EmplDayService from '@/services/EmplDayService'
import dayjs from 'dayjs'

export default {
  name: 'empldaycreate',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      emplday: { },
      error: null,
      localError: false,
      newUser: {},
      confpassword: '',
      size: 'sm',
      actiontypes: [
        { text: 'Prijava (1)', value: 1 },
        { text: 'Odjava (2)', value: 2 },
        { text: 'Promjena (3)', value: 3 }
      ],
      actionwork: [
        { text: 'Puno radno vrijeme (1)', value: 1 },
        { text: 'Nepuno radno vrijeme (2)', value: 2 }
      ],
      required: (value) => !!value || 'Required.',
      partners: [],
      employes: [],
      tasktypes: [],
      taskdepartments: [],
      users: [],
      CompanyId: 0,
      selectPa: {},
      selectEm: {},
      selectTT: {},
      selectTD: {},
      selectUs: {},
      selectUs2: {},
      selectUs3: {},
      mainQuery: {
        CompanyId: 1
      },
      havePath: '',
      locForDate: '',
      locAlarmDate: '',
      locOpenDate: '',
      locCreatedAt: '',
      locUpdatedAt: ''
    }
  },
  components: {
  },
  async mounted () {
    try {
      if (this.$store.state.empldayid) {
        const locEmplday = await EmplDayService.show(this.$store.state.empldayid)
        this.emplday = locEmplday.data.emplday
        if (this.emplday.OpenDate) {
          this.locOpenDate = dayjs(this.emplday.OpenDate).format('DD.MM.YYYY')
        }
        if (this.emplday.ForDate) {
          this.locForDate = dayjs(this.emplday.ForDate).format('DD.MM.YYYY')
        }
        if (this.emplday.AlarmDate) {
          this.locAlarmDate = dayjs(this.emplday.AlarmDate).format('DD.MM.YYYY')
        }
        this.locCreatedAt = dayjs(this.emplday.createdAt).format('DD.MM.YYYY HH:MM')
        this.locUpdatedAt = dayjs(this.emplday.updatedAt).format('DD.MM.YYYY HH:MM')
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log('greska kod monut dokumenta', error)
    }
  },
  computed: {
  },
  methods: {
    async empldayDelete () {
      try {
        await this.$router.push({
          name: 'empldaydelete'
        })
      } catch (error) {
        this.error = error
      }
    },
    async empldayEdit () {
      try {
        this.$store.dispatch('seteditItem', 1)
        await this.$router.push({
          name: 'empldaycreate'
        })
      } catch (error) {
        this.error = error
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    close () {
      this.show = false
    },
    async empldayList () {
      try {
        this.havePath = this.$store.state.retPath
        this.$store.dispatch('setretPath', '')
        if (this.havePath !== 'emplday' && this.havePath.length > 0) {
          await this.$router.push({
            name: this.havePath
          })
        } else {
          await this.$router.push({
            name: 'emplday'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
