<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar
          flat
        >
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            Danas
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Dan</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Tjedan</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mjesec</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 dana</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="gettasks"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >


          <v-card width="300" :color="selectedEvent.color">
            <v-card-title>{{selectedEvent.name}}</v-card-title>

            <v-card-text>
              {{selectedEvent.details}}
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                @click="selectedOpen = false"
              >
                X
              </v-btn>
              <v-btn
                text
                color="secondary"
                @click="editTask(selectedEvent)"
              >
                {{langC.Edit}}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="secondary"
                @click="markDone(selectedEvent)"
              >
                {{langC.Done}}
              </v-btn>
            </v-card-actions>
          </v-card>

        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import TaskService from '@/services/TaskService'
import langEn from '../Task/taskDescEn'
import langHr from '../Task/taskDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dayjs from 'dayjs'

  export default {
    data: () => ({
      focus: '',
      type: 'week',
      typeToLabel: {
        month: 'Mjesec',
        week: 'Tjedan',
        day: 'Dan',
        '4day': '4 Dana',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Sastanak', 'Praznik', 'Nesto', 'Putovanje', 'Dogadjaj', 'Rodjendan', 'Konferencija', 'Party'],
      details: ['Sastanak ured', 'Bozicni praznik', 'Nesto nesto', 'Putovanje - Rijeka', 'Neki dogadjaj', 'Rodjendan Marko', 'Konferencija Shift', 'Party - Zeljko dobio sina'],
      mainQuery: {},
      lang: {},
      langC: {},
      html: null
    }),
    mounted () {
       if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      

      this.$refs.calendar.checkChange()
    },
    methods: {
      editTask (task) {
        this.$store.dispatch('seteditItem', 1)
        this.$store.dispatch('setretPath', 'home')
        this.$store.dispatch('setTaskid', task.id)
        this.$router.push({
          name: 'taskcreate'
        })
      },
      async markDone (task) {
        this.task = {}
        const dbTask = await TaskService.show(task.id)
        const taskU = dbTask.data.task
        taskU.taskClosed = true
        const resTask = await TaskService.put(taskU)
        this.selectedOpen = false
        const index = this.events.indexOf(task)
        this.events.splice(index, 1)
        this.$refs.calendar.checkChange()
        console.log("🚀 ~  resTask", resTask)
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          console.log('this.selectedEvent', this.selectedEvent)
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      updateRange ({ start, end }) {
        this.events.length = 0
        const events = []

        const min = new Date(`${start.date}T07:00:00`)
        const max = new Date(`${end.date}T20:59:59`)
        const days = (max.getTime() - min.getTime()) / 86400000
        const eventCount = this.rnd(days, days + 20)

        for (let i = 0; i < eventCount; i++) {
          const allDay = this.rnd(0, 3) === 0
          const firstTimestamp = this.rnd(min.getTime(), max.getTime())
          const first = new Date(firstTimestamp - (firstTimestamp % 900000))
          const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
          const second = new Date(first.getTime() + secondTimestamp)

          events.push({
            name: this.names[this.rnd(0, this.names.length - 1)],
            start: first,
            end: second,
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            timed: !allDay,
            details: this.details[this.rnd(0, this.details.length - 1)],
          })
        }

        this.events = [...events]
      },

      async gettasks({ start, end }) {
        this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
        this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2023
        this.mainQuery.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
        this.mainQuery.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
        this.mainQuery.taskFromDate = dayjs(start.date).format('YYYY-MM-DD')
        this.mainQuery.taskToDate = dayjs(end.date).format('YYYY-MM-DD')
        this.mainQuery.taskClosed = false

        // const allDay = this.rnd(0, 3) === 0
        // const min = new Date(`${start.date}T00:00:00`)
        // const max = new Date(`${end.date}T23:59:59`)
        // const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        // const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        // const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        // const second = new Date(first.getTime() + secondTimestamp)
        // console.log('firstTimestamp', firstTimestamp)
        // console.log('first', first)
        // console.log('secondTimestamp', secondTimestamp)
        // console.log('second', second)
        this.events.length = 0
        this.saving = true
        const allDay = this.rnd(0, 3) === 0
        const res = await TaskService.taskana(this.mainQuery)
        const items =  res && res.data &&  res.data.task ? res.data.task : []
        console.log('items', items)
        const events = []
        if (items && items.length > 0) {
          items.map(item => {
            const forP = item.taskForTime ? item.taskForDate + " " + item.taskForTime.substr(0,5)  : item.taskForDate
            // const t1 = Date.parse(forP);
            // const first = new Date(t1)
            const first = forP
            let second
            if (item.taskEndDate || item.taskClosedTime) {
              const forS = (item.taskEndDate ? item.taskEndDate.substr(0,10) :item.taskForDate.substr(0,10)) + (item.taskClosedTime ? " " + item.taskClosedTime.substr(0,10) : item.taskForTime + " 08:00")
              // const t2 = Date.parse(forS);
              // second = new Date(t2)
              second = forS
            }
            if (!second) {
              second = first
            }
              events.push({
                name: (item.taskShortDescription ? item.taskShortDescription : '...'),
                start: first,
                end: second,
                color: item.taskColor,
                timed: !allDay,
                details: item.taskDetailDescription ? item.taskDetailDescription : '...',
                id: item.id
            })
          })
        }
        // const min = new Date()
        // const max = new Date()
        
        //const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        //const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        // const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        //const second = new Date(first.getTime() + secondTimestamp)
        // const t1 = Date.parse("2023-04-22T10:05:00.000+00:00");
        // const first = new Date(t1)

        // const tt = Date.parse("2023-04-22T12:15:00.000+00:00");
        // const second = new Date(tt)

        // events.push({
        //   name: 'Dodatno ime',
        //   start: first,
        //   end: second,
        //   color: this.colors[this.rnd(0, this.colors.length - 1)],
        //   timed: !allDay,
        //   id: 100
        // })

        this.events = [...events]
        this.saving = false
        console.log('this.events ', this.events )
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
    },
  }
</script>

