
<template>
  <v-container>
    <v-layout row wrap  justify-center>
      <v-flex xs12 sm10 md8 lg6>
        <v-card ref="form">
          <v-card-text>

              <v-layout row >
                <v-flex xs12 class="py-0 px-2">                
                  <span v-if="this.$store.state.language == 'EN'">
                    <h2>Employee Diary Edit</h2>
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    <h2>Radnik dnevnik uredi</h2>
                    </span>
                </v-flex>
              </v-layout>

              <v-layout row >
                <v-flex xs4 class="py-0 px-2">                
                  <span v-if="this.$store.state.language == 'EN'">
                    Company:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Firma:
                    </span>
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{emplday.CompanyNameDatabase}}, Id:{{emplday.CompanyId}}</span>
                </v-flex>
              </v-layout>

                <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Business Year:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Poslovna godina:
                    </span>                  
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{emplday.BusinessYear}}</span>
                </v-flex>
              </v-layout>

              <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    User:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Korisnik:
                    </span>                  
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{emplday.email}}, {{emplday.UserId}}</span>
                </v-flex>
              </v-layout>

              <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Date in database:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Datum upisa u bazu:
                    </span>                  
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{emplday.OpenDate}}</span>
                </v-flex>
              </v-layout>  
              <v-divider></v-divider>


                <v-layout row >
                  <v-flex xs3 class="py-4 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Employee Id:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Zaposlenik:
                    </span>                    
                  </v-flex>
                  <v-flex xs9 class="py-0 px-2">
                    <v-autocomplete
                      :items="employes"
                      v-model="selectEm"
                      label="Select employee"
                      item-text="hashtag"
                      item-value="id"
                      return-object
                      persistent-hint
                    ></v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row >
                  <v-flex xs3 class="py-4 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Enter type:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Vrsta upisa:
                    </span>
                  </v-flex>
                  <v-flex xs3 class="py-0 px-2">
                    <v-select
                      v-bind:items="actiontypes"
                      v-model="selectTT"
                      label="Select action type"
                      single-line
                      item-text="text"
                      item-value="id"
                      return-object
                      persistent-hint
                    ></v-select>
                  </v-flex>
                  <v-flex xs3 class="py-4 px-2">
                  </v-flex>
                </v-layout> 

                <v-layout row >
                  <v-flex xs3 class="py-4 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Work time:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Radno vrijeme:
                    </span>
                  </v-flex>
                  <v-flex xs5 class="py-0 px-2">
                    <v-select
                      v-bind:items="actionwork"
                      v-model="selectTD"
                      label="Select work type"
                      single-line
                      item-text="text"
                      item-value="id"
                      return-object
                      persistent-hint
                    ></v-select>
                  </v-flex>
                  <v-flex xs2 class="py-0 px-2">
                        <v-text-field
                          label="Work hours:"
                          v-model.number="emplday.WorkingHours"
                          mask="#"
                          v-if="this.$store.state.language == 'EN'"
                        ></v-text-field>
                        <v-text-field
                          label="Broj sati:"
                          v-model.number="emplday.WorkingHours"
                          mask="#"
                          v-if="this.$store.state.language == 'HR'"
                        ></v-text-field>
                  </v-flex>
                   <v-flex xs2 class="py-0 px-2">
                        <v-text-field
                          label="Minutes:"
                          v-model.number="emplday.FreeF1"
                          mask="##"
                          v-if="this.$store.state.language == 'EN'"
                        ></v-text-field>
                        <v-text-field
                          label="Minuta:"
                          v-model.number="emplday.FreeF1"
                          mask="##"
                          v-if="this.$store.state.language == 'HR'"
                        ></v-text-field>
                  </v-flex>
                </v-layout>                  

          <v-layout row v-if="this.$store.state.language == 'EN'">
              <v-flex xs11 sm4>
                <v-dialog
                  ref="dialog1"
                  persistent
                  v-model="modal1"
                  lazy
                  full-width
                  width="290px"
                 :return-value.sync="emplday.ForDate"
                >
                  <v-text-field
                    slot="activator"
                    label="Date change status"
                    v-model="emplday.ForDate"
                    prepend-icon="mdi-calendar"
                  ></v-text-field>
                  <v-date-picker v-model="emplday.ForDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.dialog1.save(emplday.ForDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
          </v-layout>           

            <v-layout row v-if="this.$store.state.language == 'HR'">

              <v-flex xs10 sm4>
                <v-dialog
                  ref="dialog1"
                  persistent
                  v-model="modal1"
                  lazy
                  full-width
                  width="290px"
                 :return-value.sync="emplday.ForDate"
                >
                  <v-text-field
                    slot="activator"
                    label="Datum prijave/odjave/promjene"
                    v-model="emplday.ForDate"
                    prepend-icon="mdi-calendar"
                  ></v-text-field>
                  <v-date-picker v-model="emplday.ForDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.dialog1.save(emplday.ForDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs10 sm4>
              </v-flex>

          </v-layout>  

          <v-layout row v-if="this.$store.state.language == 'EN'">
              <v-flex xs11 sm4>
                <v-dialog
                  ref="dialog2"
                  persistent
                  v-model="modal2"
                  lazy
                  full-width
                  width="290px"
                 :return-value.sync="emplday.AlarmDate"
                >
                  <v-text-field
                    slot="activator"
                    label="Date end status"
                    v-model="emplday.AlarmDate"
                    prepend-icon="mdi-calendar"
                  ></v-text-field>
                  <v-date-picker v-model="emplday.AlarmDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.dialog2.save(emplday.AlarmDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
          </v-layout>           

            <v-layout row v-if="this.$store.state.language == 'HR'">

              <v-flex xs10 sm4>
                <v-dialog
                  ref="dialog2"
                  persistent
                  v-model="modal2"
                  lazy
                  full-width
                  width="290px"
                 :return-value.sync="emplday.AlarmDate"
                >
                  <v-text-field
                    slot="activator"
                    label="Datum kraja statusa"
                    v-model="emplday.AlarmDate"
                    prepend-icon="mdi-calendar"
                  ></v-text-field>
                  <v-date-picker v-model="emplday.AlarmDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.dialog2.save(emplday.AlarmDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs10 sm4>
              </v-flex>

          </v-layout>
   
                <v-layout row>
                  <v-flex xs3 class="py-4 px-2">
                    
                  <span v-if="this.$store.state.language == 'EN'">
                     Health service ID:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                     HZZO broj upisa ID:
                    </span>                  
                  </v-flex>
                  <v-flex xs9>
                    <v-text-field
                        label="Short description"
                        v-model="emplday.HealthServiceID"
                      ></v-text-field>    
                  </v-flex>
                </v-layout>

                <v-layout row>
                  <v-flex xs3 class="py-4 px-2">
                    
                  <span v-if="this.$store.state.language == 'EN'">
                    Pension fund id:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                     Mirovinsko ID:
                    </span>                  
                  </v-flex>
                  <v-flex xs9>
                    <v-text-field
                        label="Short description"
                        v-model="emplday.PensionFundID"
                      ></v-text-field>    
                  </v-flex>
                </v-layout>

                <v-layout row>
                  <v-flex xs3 class="py-4 px-2">
                    
                  <span v-if="this.$store.state.language == 'EN'">
                    Diary Description:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                     Kratki opis upisa:
                    </span>                  
                  </v-flex>
                  <v-flex xs9>
                    <v-text-field
                        label="Short description"
                        v-model="emplday.EmplDayFree1"
                      ></v-text-field>    
                  </v-flex>
                </v-layout>

                <v-chip v-if="error" label color="red" text-color="white">
                  {{error}}
                </v-chip>


          </v-card-text>
          <v-divider class="mt-1"></v-divider>
          <v-card-actions>

            <v-btn
              color="green"
              @click="empldaylist">
              <span v-if="this.$store.state.language == 'EN'">
              Back
              </span>
              <span v-if="this.$store.state.language == 'HR'">
              Natrag
              </span>  
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              class="primary"
             :disabled="!formIsValid"
              @click="register">
              <span v-if="this.$store.state.language == 'EN'">
              Register
              </span>
              <span v-if="this.$store.state.language == 'HR'">
              Zapamti
              </span>  
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import EmployeeService from '@/services/EmployeeService'
import EmplDayService from '@/services/EmplDayService'
import TaskService from '@/services/TaskService'

export default {
  name: 'empldayedit',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      emplday: { },
      task: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        First: '',
        Last: '',
        EmployeeId: 0,
        Employeeemail: '',
        Employeehashtag: '',
        partnerId: 0,
        partnerName: '',
        partnerNameShort: '',
        taskTypeID: 0,
        taskTypeDescription: '',
        taskDepartmentID: 0,
        taskDepartmentDescription: '',
        taskShortDescription: '',
        taskDetailDescription: '',
        taskOpenDate: null,
        taskOpenTime: null,
        taskForDate: null,
        taskForTime: null,
        taskAlarmDate: null,
        taskAlarmTime: null,
        taskFor1UserId: 0,
        taskFor1Useremail: '',
        taskFor1UserSendEmail: false,
        taskFor2UserId: 0,
        taskFor2Useremail: '',
        taskFor2UserSendEmail: false,
        taskFor3UserId: 0,
        taskFor3Useremail: '',
        taskFor3UserSendEmail: false,
        taskFor4UserId: 0,
        taskFor4Useremail: '',
        taskFor4UserSendEmail: false,
        taskFor5UserId: 0,
        taskFor5Useremail: '',
        taskFor5UserSendEmail: false,
        taskClosedDate: null,
        taskClosedTime: null,
        taskClosedUserId: 0,
        taskClosed: false,
        taskClosedSendEmail: false,
        taskSpentHours: 0,
        taskChargeHours: 0,
        taskCharged: false,
        taskChargedByUserId: 0,
        taskChargedByUseremail: '',
        taskChargedDate: null,
        taskChargedTime: null,
        taskNotFinished: false,
        taskNotFinishedReason: '',
        taskPublic: false,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: '',
        LastUpdate: null
      },
      error: null,
      localError: false,
      newUser: {},
      confpassword: '',
      size: 'sm',
      actiontypes: [
        { text: 'Prijava (1)', value: 1 },
        { text: 'Odjava (2)', value: 2 },
        { text: 'Promjena (3)', value: 3 }
      ],
      actionwork: [
        { text: 'Puno radno vrijeme (1)', value: 1 },
        { text: 'Nepuno radno vrijeme (2)', value: 2 }
      ],
      required: (value) => !!value || 'Required.',
      partners: [],
      employes: [],
      tasktypes: [],
      taskdepartments: [],
      users: [],
      CompanyId: 0,
      selectPa: {},
      selectEm: {},
      selectTT: {},
      selectTD: {},
      selectUs: {},
      selectUs2: {},
      selectUs3: {},
      mainQuery: {
        CompanyId: 1
      },
      havePath: ''
    }
  },
  components: {
  },
  async mounted () {
    try {
      if (this.$store.state.editItem === 1) {
        if (this.$store.state.companyid) {
          this.CompanyId = await this.$store.state.companyid
          this.mainQuery.CompanyId = await this.$store.state.companyid
        }
        if (this.CompanyId > 0) {
          this.employes = (await EmployeeService.choose(this.mainQuery)).data.employes
        }
        const locEmplday = await EmplDayService.show(this.$store.state.empldayid)
        this.emplday = locEmplday.data.emplday
        if (parseFloat(this.emplday.FreeF1) > 0) {
          await TaskService.delete(this.emplday.FreeF1)
        }
        this.$store.dispatch('seteditItem', 0)
        if (this.emplday.ActionTypeID) {
          this.selectTT = {
            text: this.emplday.ActionTypeName,
            value: this.emplday.ActionTypeID
          }
        }
        if (this.emplday.WorkingHoursTypeID) {
          this.selectTD = {
            text: this.emplday.WorkingHoursTypeName,
            value: this.emplday.WorkingHoursTypeID
          }
        }
        if (this.emplday.EmployeeId) {
          this.selectEm = {
            id: this.emplday.EmployeeId,
            eemail: this.emplday.Employeeemail,
            VatID: this.emplday.EmployeeVatID,
            First: this.emplday.First,
            Last: this.emplday.Last,
            hashtag: this.emplday.Employeehashtag
          }
        }
        if (this.$store.state.user.id) {
          this.task.UserId = this.$store.state.user.id
        }
        if (this.$store.state.user.email) {
          this.task.email = this.$store.state.user.email
        }
        if (this.$store.state.user.First) {
          this.task.First = this.$store.state.user.First
        }
        if (this.$store.state.user.Last) {
          this.task.Last = this.$store.state.user.Last
        }
        if (this.$store.state.companyid) {
          this.task.CompanyId = this.$store.state.companyid
        }
        if (this.$store.state.companyName) {
          this.task.CompanyNameDatabase = this.$store.state.companyName
        }
        if (this.$store.state.businessYear) {
          this.task.BusinessYear = this.$store.state.businessYear
        }
      }
    } catch (error) {
      this.error = 'Error:' + error
    }
  },
  computed: {
    formIsValid () {
      return this.emplday.email !== '' &&
        this.emplday.EmployeeId !== '' &&
        this.emplday.OpenDate !== ''
    }
  },
  methods: {
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async register () {
      try {
        // write new task to database
        this.localError = false
        if (this.selectEm.id) {
          this.emplday.EmployeeId = this.selectEm.id
        }
        if (this.selectEm.eemail) {
          this.emplday.Employeeemail = this.selectEm.eemail
          this.emplday.eemail = this.selectEm.eemail
          this.emplday.phone = '385'
          this.emplday.EmployeeVatID = this.selectEm.VatID
        }
        if (this.selectEm.First) {
          this.emplday.First = this.selectEm.First
        }
        if (this.selectEm.Last) {
          this.emplday.Last = this.selectEm.Last
        }
        if (this.selectEm.hashtag) {
          this.emplday.Employeehashtag = this.selectEm.hashtag
        }
        if (this.selectTT.value) {
          this.emplday.ActionTypeID = this.selectTT.value
        }
        if (this.selectTT.text) {
          this.emplday.ActionTypeName = this.selectTT.text
        }
        if (this.selectTD.value) {
          this.emplday.WorkingHoursTypeID = this.selectTD.value
        }
        if (this.selectTD.text) {
          this.emplday.WorkingHoursTypeName = this.selectTD.text
        }
        if (!this.emplday.ForDate) {
          this.emplday.ForDate = dayjs().format('YYYY-MM-DD')
        }
        if (this.emplday.AlarmDate) {
          this.task.taskForDate = this.emplday.AlarmDate
          this.task.EmployeeId = this.emplday.EmployeeId
          this.task.Employeeemail = this.emplday.Employeeemail
          this.task.Employeehashtag = this.emplday.Employeehashtag
          this.task.taskShortDescription = '!!!! - ' + this.emplday.ActionTypeName
          const resTask = await TaskService.post(this.task)
          this.emplday.FreeF1 = resTask.data.id
        }
        await EmplDayService.put(this.emplday)
 
        this.havePath = this.$store.state.retPath
        this.$store.dispatch('setretPath', '')
        if (this.havePath !== 'emplday' && this.havePath.length > 0) {
          this.$router.push({
            name: this.havePath
          })
        } else {
          this.$router.push({
            name: 'emplday'
          })
        }
      } catch (error) {
        this.error = 'Greska u konekciji na bazu ! Pokusajte ponovno ili kontaktirajte administratora.' + error.response.data.fromServer
      }
    },
    close () {
      this.show = false
    },
    async empldaylist () {
      try {
        this.havePath = this.$store.state.retPath
        this.$store.dispatch('setretPath', '')
        if (this.havePath !== 'emplday' && this.havePath.length > 0) {
          await this.$router.push({
            name: this.havePath
          })
        } else {
          await this.$router.push({
            name: 'emplday'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
