<template>
  <v-container fluid grid-list-md>
    <v-layout row>

      <v-flex xs12 sm10 md4 lg4 ex4>
        <v-card ref="form">
          <v-card-text>

              <v-layout row >
                <v-flex xs12 class="py-0 px-2">                
                  <span v-if="this.$store.state.language == 'EN'">
                    <h2>Task Analysis </h2>
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    <h2>Zadaci analiza</h2>
                    </span>
                </v-flex>
              </v-layout>

              <v-layout row >
                <v-flex xs4 class="py-0 px-2">                
                  <span v-if="this.$store.state.language == 'EN'">
                    Company:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Firma:
                    </span>
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{task.CompanyNameDatabase}}, Id:{{task.CompanyId}}</span>
                </v-flex>
              </v-layout>

                <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Business Year:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Poslovna godina:
                    </span>                  
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{task.BusinessYear}}</span>
                </v-flex>
              </v-layout>

              <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    User:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Korisnik:
                    </span>                  
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{task.First}} {{task.Last}}, {{task.email}}</span>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <br>

          <v-layout row v-if="this.$store.state.language == 'EN'">
              <v-flex xs6 sm6>
                <v-dialog
                  ref="dialog1"
                  persistent
                  v-model="modal1"
                  lazy
                  full-width
                  width="290px"
                  :return-value.sync="taskAna.taskFromDate"
                >
                  <v-text-field
                    slot="activator"
                    label="From date"
                    v-model="taskAna.taskFromDate"
                    prepend-icon="mdi-calendar"
                  ></v-text-field>
                  <v-date-picker v-model="taskAna.taskFromDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.dialog1.save(taskAna.taskFromDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs6 sm6>
                <v-dialog
                  ref="dialog2"
                  persistent
                  v-model="modal2"
                  lazy
                  full-width
                  width="290px"
                  :return-value.sync="taskAna.taskToDate"
                >
                  <v-text-field
                    slot="activator"
                    label="To date"
                    v-model="taskAna.taskToDate"
                    prepend-icon="mdi-calendar"
                  ></v-text-field>
                  <v-date-picker v-model="taskAna.taskToDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.dialog2.save(taskAna.taskToDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>

          </v-layout>           

            <v-layout row v-if="this.$store.state.language == 'HR'">
              <v-flex xs6 sm6>
                <v-dialog
                  ref="dialog1"
                  persistent
                  v-model="modal1"
                  lazy
                  full-width
                  width="290px"
                  :return-value.sync="taskAna.taskFromDate"
                >
                  <v-text-field
                    slot="activator"
                    label="Od datuma"
                    v-model="taskAna.taskFromDate"
                    prepend-icon="mdi-calendar"
                  ></v-text-field>
                  <v-date-picker v-model="taskAna.taskFromDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.dialog1.save(taskAna.taskFromDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>

              <v-flex xs6 sm6>
                <v-dialog
                  ref="dialog2"
                  persistent
                  v-model="modal2"
                  lazy
                  full-width
                  width="290px"
                  :return-value.sync="taskAna.taskToDate"
                >
                  <v-text-field
                    slot="activator"
                    label="Do datuma"
                    v-model="taskAna.taskToDate"
                    prepend-icon="mdi-calendar"
                  ></v-text-field>
                  <v-date-picker v-model="taskAna.taskToDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.dialog2.save(taskAna.taskToDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
            </v-layout>

             <v-layout row >
                  <v-flex xs3 class="py-4 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Partner Id:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Partner:
                    </span>                    
                  </v-flex>
                  <v-flex xs9 class="py-0 px-2">
                    <v-autocomplete
                      :items="partners"
                      v-model="selectPa"
                      label="Partner"
                      item-text="partnerName"
                      item-value="id"
                      return-object
                      persistent-hint
                    ></v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row >
                  <v-flex xs3 class="py-4 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    User Id Attention:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Zadatak za Korisnika:
                    </span>                    
                  </v-flex>
                  <v-flex xs9 class="py-0 px-2">
                    <v-autocomplete
                      :items="users"
                      v-model="selectUs"
                      label="Select user"
                      item-text="email"
                      item-value="id"
                      return-object
                      persistent-hint
                    ></v-autocomplete>
                  </v-flex>
                </v-layout>


                <v-layout row >
                  <v-flex xs3 class="py-4 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Employee Id:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Zaposlenik:
                    </span>                    
                  </v-flex>
                  <v-flex xs9 class="py-0 px-2">
                    <v-autocomplete
                      :items="employes"
                      v-model="selectEm"
                      label="Select employee"
                      item-text="hashtag"
                      item-value="id"
                      return-object
                      persistent-hint
                    ></v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row >
                  <v-flex xs3 class="py-4 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Task Type:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Zadatak vrsta:
                    </span>
                  </v-flex>
                  <v-flex xs3 class="py-0 px-2">
                    <v-select
                      :items="tasktypes"
                      v-model="selectTT"
                      label="Select task type"
                      item-text="taskTypeDescription"
                      item-value="id"
                      return-object
                      persistent-hint
                    ></v-select>
                  </v-flex>
                  <v-flex xs3 class="py-4 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Task Department:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Zadatak odjel:
                    </span>                    
                  </v-flex>
                  <v-flex xs3 class="py-0 px-2">
                    <v-select
                      :items="taskdepartments"
                      v-model="selectTD"
                      label="Select task department"
                      item-text="taskDepartmentDescription"
                      item-value="id"
                      return-object
                      persistent-hint
                    ></v-select>
                  </v-flex>
                </v-layout>         

              <v-layout row wrap>
                <v-flex xs3>
                  <v-checkbox
                    v-model="task.taskClosed"
                    label="Task Closed"
                    v-if="this.$store.state.language == 'EN'"
                  ></v-checkbox>

                  <v-checkbox
                    v-model="task.taskClosed"
                    label="Zadatak je obavljen:"
                    v-if="this.$store.state.language == 'HR'"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs2>
  
                </v-flex>
                <v-flex xs1></v-flex>
                <v-flex xs2>

                </v-flex>
              </v-layout>
   

              <v-layout row wrap>
                <v-flex xs3>
                  <v-checkbox
                    v-model="task.taskNotFinished"
                    label="Task have problems"
                    color="warning"
                    v-if="this.$store.state.language == 'EN'"
                  ></v-checkbox>

                  <v-checkbox
                    v-model="task.taskNotFinished"
                    label="Zadatak je imao problema:"
                    color="warning"
                    v-if="this.$store.state.language == 'HR'"
                  ></v-checkbox>
                </v-flex>

                <v-flex xs9>
 
                </v-flex>

              </v-layout>

                <div class="pink darken-2" v-html="error" />

          </v-card-text>
          <v-divider class="mt-1"></v-divider>
          <v-card-actions>

            <v-btn
              color="green"
              @click="tasklist">
              <span v-if="this.$store.state.language == 'EN'">
              Back
              </span>
              <span v-if="this.$store.state.language == 'HR'">
              Natrag
              </span>  
            </v-btn>

            <v-btn
              color="green"
              @click="print">
              <span v-if="this.$store.state.language == 'EN'">
              Print
              </span>
              <span v-if="this.$store.state.language == 'HR'">
              Printaj
              </span>  
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              class="primary"
              :disabled="!formIsValid"
              @click="register">
              <span v-if="this.$store.state.language == 'EN'">
              Analysis
              </span>
              <span v-if="this.$store.state.language == 'HR'">
              Analiziraj
              </span>  
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-flex>

      <v-flex xs12 sm10 m8 lg8 ex8>

        <div id="printArea">

          <v-container >

                <v-layout row style="border-bottom:solid 1px;">
                    <v-flex xs5>
                        <h4><b><i>{{comp.CompanyName}}</i></b></h4>
                        {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}   <br />                
                        {{comp.CompanyPostcode}} {{comp.CompanyCity}}<br />
                         OIB: {{comp.CompanyVATID}}
                    </v-flex>
                    <v-flex xs3>
                      <span v-if="this.$store.state.language == 'EN'">
                      <h2>Task Analysis </h2>
                      </span>
                      <span v-if="this.$store.state.language == 'HR'">
                      <h2>Zadaci analiza</h2>
                      </span>
                  </v-flex>
                    <v-flex xs 4>
                      <p class="text-md-left">
                          Datum izvjestaja: {{PrintDate}}<br />
                          Telefon: {{comp.CompanyPhones}}<br />
                          email: {{comp.Companyemail}}<br />
                      </p>
                    </v-flex>
              </v-layout>

            <v-layout row style="border-bottom:solid 1px; border-top:solid 1px;">
                  <v-flex xs1 class="text-md-right">
                    Za dan
                  </v-flex>
                  <v-flex xs1 class="text-md-left">
                    email
                  </v-flex>
                  <v-flex xs1>
                    Partner
                  </v-flex>
                  <v-flex xs1 class="text-md-left">
                    Tip
                  </v-flex>
                  <v-flex xs1 class="text-md-left">
                    Odjel
                  </v-flex>
                  <v-flex xs4 class="text-md-left">
                    Kratki opis
                  </v-flex>
                  <v-flex xs1 class="text-md-right">
                    Otvoren dana
                  </v-flex>
                  
                  <v-flex xs1 class="text-md-right">
                    Utroseno sati
                  </v-flex>
                  <v-flex xs1 class="text-md-right">
                    Sati za naplatu
                  </v-flex>
              </v-layout>

                <v-layout row v-for="item in items" :key="item.id">
                    <v-flex xs1 class="text-xs-right" style="font-size:10px;">
                      {{ item.taskForDate }}
                    </v-flex>
                    <v-flex xs1 class="text-xs-left" style="font-size:10px;">
                      {{ item.Last }}
                    </v-flex>
                    <v-flex xs1 class="text-xs-left" style="font-size:10px;">
                      {{ item.partnerName }}
                    </v-flex>
                    <v-flex xs1 class="text-xs-left" style="font-size:10px;">
                      {{ item.taskTypeDescription }}
                    </v-flex>
                    <v-flex xs1 class="text-xs-left" style="font-size:10px;">
                      {{ item.taskDepartmentDescription }}
                    </v-flex>
                    <v-flex xs4 class="text-xs-left" style="font-size:10px;">
                      {{ item.taskShortDescription }}
                    </v-flex>
                    <v-flex xs1 class="text-xs-right" style="font-size:10px;">
                      {{ item.taskOpenDate }}
                    </v-flex>
                   
                    <v-flex xs1 class="text-xs-right" style="font-size:12px;">
                      {{ localMoney(item.taskSpentHours ) }}
                    </v-flex>
                    <v-flex xs1 class="text-xs-right" style="font-size:12px;">
                      {{ localMoney(item.taskChargeHours ) }}
                    </v-flex>
            </v-layout>
            <v-layout row style="border-top:solid 1px;">
              <v-flex xs5 xs1 class="text-xs-right" style="font-size:10px;">

              </v-flex>
              <v-flex xs5 xs1 class="text-xs-right" style="font-size:10px;"> 

              </v-flex>
               <v-flex xs1 class="text-xs-right" style="font-size:12px;">
                {{ localMoney(Total1 ) }}
              </v-flex>
              <v-flex xs1 class="text-xs-right" style="font-size:12px;">
                {{ localMoney(Total2 ) }}
              </v-flex>
            </v-layout>

          </v-container>

            </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import EmployeeService from '@/services/EmployeeService'
import TaskTypeService from '@/services/TaskTypeService'
import TaskDepartmentService from '@/services/TaskDepartmentService'
import UserService from '@/services/UserService'
import TaskService from '@/services/TaskService'
import { testLastUpdate } from '../../services/TestLastUpdate'
import moneyFormat from '@/snippets/moneyFormat'

export default {
  name: 'taskanalysis',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      task: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        First: '',
        Last: '',
        EmployeeId: 0,
        Employeeemail: '',
        Employeehashtag: '',
        partnerId: 0,
        partnerName: '',
        partnerNameShort: '',
        taskTypeID: 0,
        taskTypeDescription: '',
        taskDepartmentID: 0,
        taskDepartmentDescription: '',
        taskShortDescription: '',
        taskDetailDescription: '',
        taskOpenDate: null,
        taskOpenTime: null,
        taskForDate: null,
        taskForTime: null,
        taskAlarmDate: null,
        taskAlarmTime: null,
        taskFor1UserId: 0,
        taskFor1Useremail: '',
        taskFor1UserSendEmail: false,
        taskFor2UserId: 0,
        taskFor2Useremail: '',
        taskFor2UserSendEmail: false,
        taskFor3UserId: 0,
        taskFor3Useremail: '',
        taskFor3UserSendEmail: false,
        taskFor4UserId: 0,
        taskFor4Useremail: '',
        taskFor4UserSendEmail: false,
        taskFor5UserId: 0,
        taskFor5Useremail: '',
        taskFor5UserSendEmail: false,
        taskClosedDate: null,
        taskClosedTime: null,
        taskClosedUserId: 0,
        taskClosed: false,
        taskClosedSendEmail: false,
        taskSpentHours: 0,
        taskChargeHours: 0,
        taskCharged: false,
        taskChargedByUserId: 0,
        taskChargedByUseremail: '',
        taskChargedDate: null,
        taskChargedTime: null,
        taskNotFinished: false,
        taskNotFinishedReason: '',
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: '',
        LastUpdate: null
      },
      temptask: {},
      task2: {},
      taskAna: {
        CompanyId: 0,
        BusinessYear: 0,
        UserId: 0,
        EmployeeId: 0,
        partnerId: 0,
        taskTypeID: 0,
        taskDepartmentID: 0,
        taskFor2UserId: 0,
        taskFromDate: null,
        taskToDate: null,
        taskClosed: false,
        taskCharged: false,
        taskNotFinished: false
      },
      comp: {},
      error: null,
      localError: false,
      newUser: {},
      confpassword: '',
      size: 'sm',
      privitems: [
        { text: '1 max privileges', value: 1 },
        { text: '2 medium to max privileges', value: 2 },
        { text: '3 medium privileges', value: 3 },
        { text: '4 low to midium privileges', value: 4 },
        { text: '5 low privileges', value: 5 }
      ],
      required: (value) => !!value || 'Required.',
      partners: [],
      employes: [],
      tasktypes: [],
      taskdepartments: [],
      users: [],
      items: [],
      CompanyId: 0,
      selectPa: {},
      selectEm: {},
      selectTT: {},
      selectTD: {},
      selectUs: {},
      selectUs2: {},
      selectUs3: {},
      mainQuery: {
        CompanyId: 1
      },
      havePath: '',
      Total1: 0,
      Total2: 0,
      PrintDate: null
    }
  },
  components: {
  },
  async mounted () {
    // need five arrays with partners, employes, tasktype, taskdepartment, users
    if (this.$store.state.companyid) {
      this.CompanyId = await this.$store.state.companyid
      this.taskAna.CompanyId = await this.$store.state.companyid
      this.mainQuery.CompanyId = await this.$store.state.companyid
    }
    this.comp = this.$store.state.companyActiv
    if (this.CompanyId > 0) {
      await testLastUpdate()
      if (this.$store.state.partnerStore) {
        this.partners = this.$store.state.partnerStore
      }
      //this.partners = (await PartnerService.index()).data.partners
      this.employes = (await EmployeeService.choose(this.mainQuery)).data.employes
      this.tasktypes = (await TaskTypeService.choose(this.mainQuery)).data.taskTypes
      this.taskdepartments = (await TaskDepartmentService.choose(this.mainQuery)).data.taskDepartments
      this.users = (await UserService.choose(this.mainQuery)).data.users
      // console.log(this.partners)
      // console.log(this.tasktypes)
      // console.log(this.taskdepartments)
      // console.log(this.users)
    }
    if (this.$store.state.user.id) {
      this.task.UserId = this.$store.state.user.id
      this.taskAna.UserId = this.$store.state.user.id
    }
    if (this.$store.state.user.email) {
      this.task.email = this.$store.state.user.email
    }
    if (this.$store.state.user.First) {
      this.task.First = this.$store.state.user.First
    }
    if (this.$store.state.user.Last) {
      this.task.Last = this.$store.state.user.Last
    }
    if (this.$store.state.companyid) {
      this.task.CompanyId = this.$store.state.companyid
      this.taskAna.CompanyId = this.$store.state.companyid
    }
    if (this.$store.state.companyName) {
      this.task.CompanyNameDatabase = this.$store.state.companyName
    }
    if (this.$store.state.businessYear) {
      this.task.BusinessYear = this.$store.state.businessYear
      this.taskAna.BusinessYear = this.$store.state.businessYear
    }
    let locDate1 = new Date()
    this.PrintDate = dayjs().format('DD.MM.YYYY, H:mm')
    locDate1.getDate()
    locDate1.getMonth() + 1
    locDate1.getFullYear()
    this.task.taskOpenDate = dayjs().format('YYYY-MM-DD')
  },
  computed: {
    formIsValid () {
      return this.taskAna.taskFromDate !== null &&
        this.taskAna.taskToDate !== null &&
        this.taskAna.CompanyId !== ''
    }
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    print () {
      // opens the "print dialog" of your browser to print the element
      // var bdhtml = window.document.body.innerHTML
      // window.document.body.innerHTML = document.getElementById('printArea').innerHTML
      // window.print()
      // window.document.body.innerHTML = bdhtml
      this.$htmlToPaper('printArea')
    },
    async register () {
      try {
        // do analysis from database
        this.localError = false
        if (this.selectPa.id) {
          this.taskAna.partnerId = this.selectPa.id
        }
        if (this.selectEm.id) {
          this.taskAna.EmployeeId = this.selectEm.id
        }
        if (this.selectTT.id) {
          this.taskAna.taskTypeID = this.selectTT.id
        }
        if (this.selectTD.id) {
          this.taskAna.taskDepartmentID = this.selectTD.id
        }
        if (this.selectUs.id) {
          this.taskAna.taskFor2UserId = this.selectUs.id
        }
        this.Total1 = 0
        this.Total2 = 0
        this.items = (await TaskService.taskana(this.taskAna)).data.task
        if (this.items) {
          this.items.map(item => {
            this.Total1 += parseFloat(item.taskSpentHours)
            this.Total2 += parseFloat(item.taskChargeHours)
          })
        }
      } catch (error) {
        this.error = 'Greska u konekciji na bazu'
      }
    },
    close () {
      this.show = false
    },
    async tasklist () {
      try {
        this.havePath = this.$store.state.retPath
        this.$store.dispatch('setretPath', '')
        if (this.havePath !== 'task' && this.havePath.length > 0) {
          await this.$router.push({
            name: this.havePath
          })
        } else {
          await this.$router.push({
            name: 'task'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
