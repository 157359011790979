<template>
  <div>

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'

export default {
  name: 'documentproductionprint',
  props: ['showMat', 'showWork', 'showOther', 'showProduct'],
  data () {
    return {
      msg: '',
      documentName: '',
      documentName2: '',
      document: {},
      havePath: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locDocument: {},
      fields1: [ 'itemName', 'ItemCode', 'itemUnit', 'itemService', 'itemPrice', 'itemPcsNeutral', 'itemBaseAmount', 'itemDiscountPercentage1', 'itemBaseAmountNoVAT', 'itemAmountWithVAT' ],
      items1: [
      ],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      fields2: [ 'vat', 'base', 'amount' ],
      items2: [
        { vat: '5%', base: 100.00, amount: 5.00 },
        { vat: '13%', base: 200.00, amount: 26.00 },
        { vat: '25%', base: 400.00, amount: 100.00 }
      ],
      comp: {},
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        databaseDocID: 0
      },
      order: 0,
      loCdocumentDate: '',
      loCorderDate: '',
      loCdocumentInvoiceStartDate: '',
      loCdocumentInvoiceTime: '',
      loCdocumentInvoiceEndDate: '',
      loCdocumentConnDate: '',
      loCdeliveryDate: '',
      loCorderTime: '',
      loCdocumentConnTime: '',
      loCdeliveryTime: '',
      locDocumentId: '',
      output: null,
      documentNameLoc: '',
      docDefinition: null,
      profitLoss: 0,
      profitLossP: '',
      pP1: [],
      pP2: [],
      pP3: [],
      pP4: [],
      pP5: [],
      pP6: [],
      pP7: [],
      pP8: [],
      pP9: [],
      pP10: [],
      pD1: [],
      pD2: [],
      pD3: [],
      pD4: [],
      pD5: [],
      pD6: [],
      pD7: [],
      pD8: [],
      pD9: [],
      pD10: [],
      pT1: [],
      pT2: [],
      pT3: [],
      pT4: [],
      pT5: [],
      pT6: [],
      pT7: [],
      pT8: [],
      pT9: [],
      pT10: [],
      pC1: [],
      pC2: [],
      pC3: [],
      pC4: [],
      pC5: [],
      pC6: [],
      pC7: [],
      pC8: [],
      pC9: [],
      pC10: [],
      pC11: [],
      pC1tot: 0,
      pC2tot: 0,
      pC3tot: 0,
      pC4tot: 0,
      pC5tot: 0,
      pC6tot: 0,
      pC7tot: 0,
      pC8tot: 0,
      pC9tot: 0,
      pC10tot: 0,
      pD1tot: 0,
      pD2tot: 0,
      pD3tot: 0,
      pD4tot: 0,
      pD5tot: 0,
      pD6tot: 0,
      pD7tot: 0,
      pD8tot: 0,
      pD9tot: 0,
      pD10tot: 0,
      pTtt1: 0,
      pTtt2: 0,
      pTtt3: 0,
      pTtt4: 0,
      pTtt5: 0,
      pTtt6: 0,
      pTtt7: 0,
      pTtt8: 0,
      pTtt9: 0,
      pTtt10: 0,
      pTtt11: 0,
      pSum00: 0,
      pSum01: 0,
      pSum1: 0,
      pSum2: 0,
      pSum3: 0,
      pSum4: 0,
      pSum5: 0,
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
      docDefinition2: {},
      content2: [],
      logoType: 0,
      printSmall: false, 
      printNoPrice: false,
      itemdesc: '',
      suppAmount: 0,
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.documentActivHead) {
      this.document = this.$store.state.documentActivHead
      this.documentNameLoc = this.$store.state.documentActivLoc
    }
    this.printSmall = false
    if (this.$store.state.printSmall === true) {
      this.printSmall = true
    }
  },
  methods: {
    print () {
      this.printSmall = false
      this.printNoPrice = false
      if (this.$store.state.printSmall === true) {
        this.printSmall = true 
      }
      if (this.$store.state.printNoPrice === true) {
        this.printNoPrice = true
      }
      this.prepareData()
      this.printDoc()
    },
    printDoc () {
      // works perfect need update to customize for different types of documents
      this.initDocument()
      if (this.logoType === 0) {
        this.addHeaderNoLogo()
      }
      if (this.logoType === 1) {
        this.addHeaderWithLogoLeft()
      }
      if (this.logoType === 2) {
        this.addHeaderWithLogoRight()
      }
      if (this.logoType === 3) {
        this.addHeaderWithLogoFull()
      }
      this.addLine()
      this.addPartner()
      // this.addEmptyRow()
      // this.addEmptyRow()
      this.addHeadComment()
      this.addLine()
      
      if (this.showMat === true) {
        this.addEmptyRow()
        this.addItemsHeader3()
        this.addLine()
        this.addItems3()
        this.addLine()
      }
      if (this.showWork === true) {
        this.addEmptyRow()
        this.addItemsHeader31()
        this.addLine()
        this.addItems31()
        this.addLine()
      }
      if (this.showOther === true) {
        this.addEmptyRow()
        this.addItemsHeader32()
        this.addLine()
        this.addItems32()
        this.addLine()
      }

      if (this.showProduct === true) {
        this.addEmptyRow()
        this.addItemsHeader33()
        this.addLine()
        this.addItems33()
        this.addLine()
      }
      
      this.addComment()
      this.addSignature1()
      this.addSignature2()
      this.addFooter()
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    prepareData() {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentid) {
        this.mainQuery.databaseDocID = this.$store.state.documentid
      }
      if (this.$store.state.documentActivHead) {
        this.document = this.$store.state.documentActivHead
      }
      if (this.$store.state.companyActiv.CompanyLogo1) {
        this.logoType = 1
      }
      if (this.$store.state.companyActiv.CompanyLogo2) {
        this.logoType = 2
      }
      if (this.$store.state.companyActiv.CompanyLogo3) {
        this.logoType = 3
      }
      this.documentName2 = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      this.pSum00 = this.formatPrice(this.document.documentDescription9)
      this.pSum01 = this.formatPrice(this.document.documentDescription10)
      this.pSum1 = this.formatPrice(this.document.documentTotalAmountNetto)
      this.pSum2 = this.formatPrice(this.document.documentTotalAmountVAT)
      this.pSum3 = this.formatPrice(this.document.documentTotalAmountBrutto)
      this.pSum4 = this.formatPrice(this.document.documentTotalAmountPayInAdvance)
      this.pSum5 = this.formatPrice(this.document.documentTotalAmountToPay)
      this.comp = this.$store.state.companyActiv
      this.order = 0
      this.items = []
      if (this.$store.state.documentActivItems) {
        // this.items = (await DocumentDetailService.index(this.mainQuery)).data.documentDetails
        this.items = this.$store.state.documentActivItems
      }
      // console.log(' items imam ili ne', this.items)

      if (this.items) {
        this.items.map(item => {
          if (parseFloat(item.itemAmountWithVAT) !== 0) {
            this.order += 1
            this.pTtt3 = this.formatPrice(item.itemPrice)
            this.pTtt11 = this.formatPrice(item.itemPrice * (1 + (item.itemVAT1Percentage/100)))
            if (this.$store.state.documentSide === 2) {
              this.pTtt4 = item.itemPcsMinus ? this.formatPrice(item.itemPcsMinus) : 0
              // eslint-disable-next-line
              console.log(' have Pcs Minus ', item.ItemPcsMinus, ' pTtt4 ', this.pTtt4 )
            }
            if (this.$store.state.documentSide === 3) {
              this.pTtt4 = item.itemPcsNeutral ? this.formatPrice(item.itemPcsNeutral) : 0
            }
            this.pTtt5 = item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0
            this.pTtt6 = item.itemDiscountPercentage1 ? this.formatPrice(item.itemDiscountPercentage1) : 0
            this.pTtt7 = item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0
            this.pTtt8 = item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0
            this.pTtt9 = item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0
          }
        }) 
      }
      // console.log(' client loc document ', this.locDocument)
      // console.log(' client loc items ', this.items)
      // this.document = this.locDocument.data.document
      this.locCreatedAt = dayjs(this.document.CreatedAt).format('DD.MM.YYYY HH:MM')
      this.locUpdatedAt = dayjs(this.document.UpdatedAt).format('DD.MM.YYYY HH:MM')
      this.loCdocumentInvoiceTime = this.document.documentTime ? this.document.documentTime.substring(0, 5) : '10:00'
      this.loCdocumentDate = this.document.documentDate ? dayjs(this.document.documentDate).format('DD.MM.YYYY') : ' '
      this.loCorderDate = this.document.orderDate ? dayjs(this.document.orderDate).format('DD.MM.YYYY') : ' '
      this.loCdocumentInvoiceStartDate = this.document.documentInvoiceStartDate ? dayjs(this.document.documentInvoiceStartDate).format('DD.MM.YYYY') : ' '
      this.loCdocumentInvoiceEndDate = this.document.documentInvoiceEndDate ? dayjs(this.document.documentInvoiceEndDate).format('DD.MM.YYYY') : ' '      
      this.loCdocumentConnDate =  this.document.documentConnDate ? dayjs(this.document.documentConnDate).format('DD.MM.YYYY') : ' '
      this.loCdeliveryDate = this.document.deliveryDate ? dayjs(this.document.deliveryDate).format('DD.MM.YYYY') : ' '
      this.loCorderTime = this.document.orderTime ? dayjs(this.document.orderTime).format('HH:MM') : ' '
      this.loCdocumentConnTime = this.document.documentConnTime ?  dayjs(this.document.documentConnTime).format('HH:MM') : ' '
      this.loCdeliveryTime = this.document.deliveryTime ? dayjs(this.document.deliveryTime).format('HH:MM') : ' '
      if (this.$store.state.documentActiv === 'InvoiceSales') {
        this.locDocumentId = this.document.documentId + '/' + this.$store.state.companyActiv.CompanyBusinessSpace + '/' + this.$store.state.companyActiv.CompanyBusinessDevice
      } else {
        this.locDocumentId = this.document.documentId
      }
      this.profitLoss = (this.document.FreeF4 ? parseFloat(this.document.FreeF4) : 0) - (this.document.FreeF1 ? parseFloat(this.document.FreeF1) : 0) - (this.document.FreeF2 ? parseFloat(this.document.FreeF2) : 0) - (this.document.FreeF3 ? parseFloat(this.document.FreeF3) : 0)
      this.profitLossP = this.profitLoss.toFixed(2)
      this.suppAmount = (this.document.FreeF1 ? parseFloat(this.document.FreeF1) : 0) + (this.document.FreeF2 ? parseFloat(this.document.FreeF2) : 0) + (this.document.FreeF3 ? parseFloat(this.document.FreeF3) : 0)
    },
    addItemsHeader3 () {
      const docPart0 = {
            columns: [
              {
                width: '55%',
                text: { text: 'Utrošak materijala \n', fontSize: 12, bold: true }
              },
            ]
          }
      this.content.push(docPart0)
      const docPart = {
            columns: [
              {
                width: '5%',
                text: { text: this.lang.DocPrintOrder + '\n', fontSize: 8 }
              },
              {
                width: '45%',
                text: { text: this.lang.DocPrintName + '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '5%',
                text: { text: this.lang.DocPrintUnit + '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '10%',
                text: { text: this.lang.DocPrintPcs + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '10%',
                text: { text: 'Dob.cij.\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '10%',
                text: { text: 'Dob.iznos \n', fontSize: 8, alignment: 'right' }
              },
            ]
          }
      this.content.push(docPart)
    },
    addItems3 () {
           this.order = 0
           let total3 = 0
           this.items = this.$store.state.documentActivItems.filter(item => {
              return parseInt(item.FreeF6)  === 3
            })
            if (this.items) {
              this.items.map(item => {
                if (parseFloat(item.itemBaseAmount) !== 0) {

                  this.order += 1
                  this.pTtt3 = this.formatPrice(item.itemPrice)
                  this.pTtt11 = this.formatPrice(item.itemPrice * (1 + (item.itemVAT1Percentage/100)))

                  this.pTtt4 = item.itemPcsMinus ? this.formatPrice(item.itemPcsMinus) : 0

                  this.pTtt5 = item.itemSuppPrice ? this.formatPrice(item.itemSuppPrice) : 0
                  this.pTtt6 = item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0

                  total3 += parseFloat(item.itemBaseAmount)

                  const docPart = {
                  columns: [
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: this.order
                        },
                        {
                          width: '45%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.itemID + ', ' + item.itemName + (item.itemDescription ? '\n' + item.itemDescription : '') + (item.itemSerialNr ? '\n Ser.br.:' + item.itemSerialNr : '')
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.itemUnit
                        },
                        {
                          width: '10%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt4
                        },
                        {
                          width: '10%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt5
                        },
                        {
                          width: '10%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt6
                        },
                      ]
                    }
                  this.content.push(docPart)
                }
              })

            this.addLine()

            const docPart1 = {
              columns: [
                    {
                      width: '5%',
                      fontSize: 8,
                      alignment: 'left',
                      text: ' '
                    },
                    {
                      width: '45%',
                      fontSize: 8,
                      alignment: 'left',
                      text: ' '
                    },
                    {
                      width: '5%',
                      fontSize: 8,
                      alignment: 'left',
                      text: ' '
                    },
                    {
                      width: '10%',
                      fontSize: 8,
                      alignment: 'right',
                      text: ' '
                    },
                    {
                      width: '10%',
                      fontSize: 8,
                      alignment: 'right',
                      text: ' '
                    },
                    {
                      width: '10%',
                      fontSize: 8,
                      alignment: 'right',
                      bold: true,
                      text: this.formatPrice(total3)
                    },
                  ]
                }
              this.content.push(docPart1)
          }
    },
    addItemsHeader31 () {
      const docPart0 = {
            columns: [
              {
                width: '55%',
                text: { text: 'Trošak radnika \n', fontSize: 12, bold: true }
              },
            ]
          }
      this.content.push(docPart0)
      const docPart = {
            columns: [
              {
                width: '5%',
                text: { tex: this.lang.DocPrintOrder + '\n', fontSize: 8 }
              },
              {
                width: '38%',
                text: { text: 'Radnik \n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '5%',
                text: { text: 'Datum \n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '5%',
                text: { text: 'Radni sati \n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: 'Bruto satnica \n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: 'Dnevnice\n', fontSize: 8, alignment: 'center' }
              },
              {
                width: '8%',
                text: { text: 'Kilometri\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: 'Trošak radnika\n', fontSize: 8, alignment: 'right' }
              }
            ]
          }
      this.content.push(docPart)
    },
    addItems31 () {
           this.order = 0
           let total1 = 0
           let total2 = 0
           let total3 = 0
           let total4 = 0
           this.items = this.$store.state.documentActivItems.filter(item => {
            return parseInt(item.FreeF6)  === 31
            })
            if (this.items) {
              this.items.map(item => {
                if (parseFloat(item.itemAmountWithVAT) !== 0) {

                  this.order += 1
                  
                  this.pTtt3 = this.formatPrice(item.itemPrice)
                  this.pTtt11 = this.formatPrice(item.itemPrice * (1 + (item.itemVAT1Percentage/100)))

                  this.pTtt4 = item.empWorkDay ? this.formatPrice(item.empWorkDay) : 0
                  this.pTtt5 = item.empWrkHrs ? this.formatPrice(item.empWrkHrs) : 0
                  this.pTtt6 = item.empWrkExpHrs ? this.formatPrice(item.empWrkExpHrs) : 0
                  this.pTtt7 = item.empExp1 ? this.formatPrice(item.empExp1) : 0
                  this.pTtt8 = item.empExp2 ? this.formatPrice(item.empExp2) : 0
                  this.pTtt9 = item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0
                  total1 += item.empWrkHrs
                  total2 += item.empExp1
                  total3 += item.empExp2
                  total4 += item.itemAmountWithVAT
                  const docPart = {
                  columns: [
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: this.order
                        },
                        {
                          width: '38%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.hashtag
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.empWorkDay
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.empWrkHrs
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.empWrkExpHrs
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.empExp1
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.empExp2
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt5
                        },
                      ]
                    }
                  this.content.push(docPart)
                }
              })

              this.addLine()

              const docPart31 = {
               columns: [
                {
                  width: '5%',
                  text: ' '
                },
                {
                  width: '38%',
                  text: ' '
                },
                {
                  width: '5%',
                  text: ' '
                },
                {
                  width: '5%',
                  alignment: 'right',
                  fontSize: 8,
                  text: this.formatPrice(total1),
                },
                {
                  width: '8%',
                  text: ' '
                },
                {
                  width: '8%',
                  alignment: 'right',
                  fontSize: 8,
                  text: this.formatPrice(total2),
                },
                {
                  width: '8%',
                  alignment: 'right',
                  fontSize: 8,
                  text: this.formatPrice(total3),
                },
                {
                  width: '8%',
                  alignment: 'right',
                  fontSize: 8,
                  text: this.formatPrice(total4),
                  bold: true
                }
              ]
            }
            this.content.push(docPart31)
          }
    },
    addItemsHeader32 () {
      const docPart0 = {
            columns: [
              {
                width: '55%',
                text: { text: 'Ostali troškovi \n', fontSize: 12, bold: true }
              },
            ]
          }
      this.content.push(docPart0)
      const docPart = {
            columns: [
              {             
                width: '5%',
                text: { text: this.lang.DocPrintOrder + '\n', fontSize: 8 }
              },
              {
                width: '45%',
                text: { text: this.lang.DocPrintName + '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '5%',
                text: { text: this.lang.DocPrintUnit + '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '10%',
                text: { text: this.lang.DocPrintPcs + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '10%',
                text: { text: 'Dob.cij.\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '10%',
                text: { text: 'Dob.iznos \n', fontSize: 8, alignment: 'right' }
              },
            ]
          }
      this.content.push(docPart)
    },
    addItems32 () {
           this.order = 0
           let total32 = 0
           this.items = this.$store.state.documentActivItems.filter(item => {
              return parseInt(item.FreeF6)  === 32
            })
            if (this.items) {
              this.items.map(item => {
                if (parseFloat(item.itemAmountWithVAT) !== 0) {

                  this.order += 1
                  this.pTtt3 = this.formatPrice(item.itemSuppPrice)
                  this.pTtt11 = this.formatPrice(item.itemSuppPrice * (1 + (item.itemVAT1Percentage/100)))
                  this.pTtt4 = item.itemPcsMinus ? this.formatPrice(item.itemPcsMinus) : 0
                  this.pTtt5 = item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0
                  total32 += parseFloat(item.itemBaseAmount)
                  const docPart = {
                  columns: [
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: this.order
                        },
                        {
                          width: '45%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.itemID + ', ' + item.itemName + (item.itemDescription ? '\n' + item.itemDescription : '') + (item.itemSerialNr ? '\n Ser.br.:' + item.itemSerialNr : '')
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.itemUnit
                        },
                        {
                          width: '10%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt4
                        },
                        {
                          width: '10%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt3
                        },
                        {
                          width: '10%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt5
                        },
                      ]
                    }
                  this.content.push(docPart)
                }
              })
              this.addLine()

              const docPart = {
                  columns: [
                    {             
                      width: '5%',
                      text: { text: '', fontSize: 8 }
                    },
                    {
                      width: '45%',
                      text: { text:  ' ', fontSize: 8, alignment: 'left' }
                    },
                    {
                      width: '5%',
                      text: { text: ' ', fontSize: 8, alignment: 'left' }
                    },
                    {
                      width: '10%',
                      text: { text: ' ', fontSize: 8, alignment: 'right' }
                    },
                    {
                      width: '10%',
                      text: { text: '', fontSize: 8, alignment: 'right' }
                    },
                    {
                      width: '10%',
                      text: this.formatPrice(total32),
                      fontSize: 8,
                      alignment: 'right',
                      bold: true 
                    },
                  ]
                }
            this.content.push(docPart)
          }
    },
    addItemsHeader33 () {
      const docPart0 = {
            columns: [
              {
                width: '55%',
                text: { text: 'Proizvedeno/obavljena usluga \n', fontSize: 12, bold: true }
              },
            ]
          }
      this.content.push(docPart0)
      const docPart = {
            columns: [
              {
                width: '5%',
                text: { tex: this.lang.DocPrintOrder + '\n', fontSize: 8 }
              },
              {
                width: '28%',
                text: { text: this.lang.DocPrintName + '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '10%',
                text: { text: 'Dob.Cj./' + this.lang.DocPrintUnit + '\n', fontSize: 8, alignment: 'center' }
              },
              {
                width: '5%',
                text: { text: this.lang.DocPrintUnit + '\n', fontSize: 8, alignment: 'center' }
              },
              {
                width: '6%',
                text: { text: this.lang.DocPrintPcs + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '7%',
                text: { text: this.lang.DocPrintPrice + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '7%',
                text: { text: this.lang.DocPrintAmount + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '7%',
                text: { text: this.lang.DocPrintDiscount + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '7%',
                text: { text: this.lang.DocPrintAmount2 + '\n', fontSize: 8, alignment: 'center' }
              },
              {
                width: '4%',
                text: { text: this.lang.DocPrintVAT + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: this.lang.DocPrintAmount3 + '\n', fontSize: 8, alignment: 'right' }
              }
            ]
          }
      this.content.push(docPart)
    },
    addItems33 () {
           this.order = 0
           let total4 = 0
           let total5 = 0
           let total7 = 0
           
           let total9 = 0
           this.items = this.$store.state.documentActivItems.filter(item => {
              return parseInt(item.FreeF6)  === 33
            })
            if (this.items) {
              this.items.map(item => {
                if (parseFloat(item.itemAmountWithVAT) !== 0) {

                  this.order += 1
                  this.pTtt3 = this.formatPrice(item.itemPrice)
                  this.pTtt11 = this.formatPrice(item.itemPrice * (1 + (item.itemVAT1Percentage/100)))

                  this.pTtt4 = item.itemPcsPlus ? this.formatPrice(item.itemPcsPlus) : 0
                  
                  this.pTtt5 = item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0
                  this.pTtt6 = item.itemDiscountPercentage1 ? this.formatPrice(item.itemDiscountPercentage1) : 0
                  this.pTtt7 = item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0
                  this.pTtt8 = item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0
                  this.pTtt9 = item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0

                  total4 += item.itemPcsPlus
                  total5 += item.itemBaseAmount
                  total7 += item.itemBaseAmountNoVAT
                  
                  total9 += item.itemAmountWithVAT

                  const docPart = {
                  columns: [
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: this.order
                        },
                        {
                          width: '28%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.itemID + ', ' + item.itemName + (item.itemDescription ? '\n' + item.itemDescription : '') + (item.itemSerialNr ? '\n Ser.br.:' + item.itemSerialNr : '')
                        },
                        {
                          width: '10%',
                          fontSize: 8,
                          alignment: 'center',
                          text: ( this.suppAmount ? this.formatPrice(this.suppAmount/item.itemPcsPlus) : 0)
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'center',
                          text: item.itemUnit
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt4
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt3
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt5
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt6
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt7
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt8 
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt9
                        }
                      ]
                    }
                  this.content.push(docPart)
                }
              })

              this.addLine()

              const docPart33 = {
                    columns: [
                      {
                        width: '5%',
                        text: { tex: '\n', fontSize: 8 }
                      },
                      {
                        width: '38%',
                        text: { text: ' \n', fontSize: 8, alignment: 'left' }
                      },
                      {
                        width: '5%',
                        text: { text: '\n', fontSize: 8, alignment: 'left' }
                      },
                      {
                        width: '5%',
                        text: { text: this.formatPrice(total4) + '\n', fontSize: 8, alignment: 'right' }
                      },
                      {
                        width: '8%',
                        text: { text: '\n', fontSize: 8, alignment: 'right' }
                      },
                      {
                        width: '8%',
                        text: { text: this.formatPrice(total5) + '\n', fontSize: 8, alignment: 'right' }
                      },
                      {
                        width: '5%',
                        text: { text:  ' ', fontSize: 8, alignment: 'right' }
                      },
                      {
                        width: '8%',
                        text: { text: this.formatPrice(total7) , fontSize: 8, alignment: 'right' }
                      },
                      {
                        width: '5%',
                        text: { text: ' ', fontSize: 8, alignment: 'right' }
                      },
                      {
                        width: '8%',
                        text: { text: this.formatPrice(total9), fontSize: 8, alignment: 'right' }
                      }
                    ]
                  }
              this.content.push(docPart33)
          }
    },


    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 40 ]
      this.content = []
    },
    initDocumentSmall () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A6'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 10, 10, 10, 10 ]
      this.content = []
    },
    addFooter () {
      this.docDefinition.footer = {
          columns: [
             {
              width: '90%',
              text: [
                { text: '   ' + (this.comp.CompanyDescriptionUp ? this.comp.CompanyDescriptionUp : ' '), fontSize: 7, alignment: 'center' }
              ]
            },
          ]
        }
    },
    addFooterOld () {
      this.docDefinition.footer = {
          columns: [ {
            text: [
              { text: '   ' + this.comp.CompanyName, fontSize: 8, bold: true, alignment: 'center' },
              { text: ',' + (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ', ' + (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') , fontSize: 7 },
              { text: ', ' + this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + ', email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ', ' + this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' ') + ' \n', fontSize: 7 },
              { text: '   ' + (this.comp.CompanyDescriptionUp ? this.comp.CompanyDescriptionUp : ' '), fontSize: 7, alignment: 'center' }
            ]
          }
          ]
        }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderWithLogoLeft () {
      const docPart = {
            columns: [
              {
                width: '4%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '51%',
                image: this.comp.CompanyLogo1,
                fit: [70, 70]
              },
              {
                // % width
                width: '40%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: 'email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + ': ' +  (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeaderWithLogoLeftSmall () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '55%',
                image: this.comp.CompanyLogo1,
                fit: [70, 70]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 },
                  { text: this.lang.DocPrintRetail + + (this.comp.CompanyLogo5 ? this.comp.CompanyLogo5 : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 1
          }
        this.content.push(docPart)
    },
    addHeaderWithLogoRight () {
      const docPart = {
            columns: [
              {
                width: '7%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                // % width
                width: '50%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: 'email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '31%',
                image: this.comp.CompanyLogo2,
                fit: [70, 70]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeaderWithLogoFull () {
      const docPart = {
            columns: [
              {
                width: '7%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '81%',
                image: this.comp.CompanyLogo3,
                fit: [800, 80]
              },
            ],
          }
        this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '55%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 18, bold: true },
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones  + '\n' : ' '), fontSize: 8 },
                  { text: (this.comp.Companyemail ? 'email:' +  this.comp.Companyemail + ' \n' : ' ') , fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addPartner () {

      const docPart = {
        columns: [
              {
                width: '10%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              },
              {
                width: '40%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: this.document.partnerName + ' \n', fontSize: 12, bold: true },
                  { text: (this.document.partnerStreet ? this.document.partnerStreet : ' ') + ' ' + (this.document.partnerBuildingNameNumber ? this.document.partnerBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.document.partnerPostcode ? this.document.partnerPostcode : ' ') + ' ' + (this.document.partnerCity ? this.document.partnerCity: ' ') + ' \n', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '30%',
                text: [
                  { text: this.documentNameLoc + ': ' + this.document.documentId + '/' + (this.$store.state.documentActiv !== 'POS' ? this.comp.CompanyBusinessSpace : this.comp.CompanyBusinessSpaceRetail) + '/' + (this.$store.state.documentActiv !== 'POS' ? this.comp.CompanyBusinessDevice : this.comp.CompanyBusinessDeviceRetail) + ' \n', fontSize: 15 },
                  { text: this.lang.DocumentDate + ' ' + this.loCdocumentDate + ',' + this.loCdocumentInvoiceTime + '\n', fontSize: 8, bold: true },
                  { text: this.lang.StartDate + ' ' + this.loCdocumentInvoiceStartDate + '\n', fontSize: 8 },
                  { text: this.lang.DeadLine + ' ' + this.loCdocumentInvoiceEndDate + '\n', fontSize: 8 },
                  { text: this.lang.VATIDBuyer + ' ' + (this.document.partnerVATID ? this.document.partnerVATID : ' ') + ' \n', fontSize: 8 },
                  { text: (this.document.partneremail ? 'email: ' + this.document.partneremail : ' ') + ' \n', fontSize: 8 },
                  { text:  'Proizvedeno: ' + (this.document.FreeF4 ? this.formatPrice(this.document.FreeF4) : 0) + '\n', fontSize: 10 },
                  { text:  '- Trošak materijala:: ' + (this.document.FreeF1 ? this.formatPrice(this.document.FreeF1) : 0) + '\n', fontSize: 10, },
                  { text:  '- Trošak radnika (radni sati ' + (this.document.FreeF5 ? this.formatPrice(this.document.FreeF5) : 0)  + '): ' + (this.document.FreeF2 ? this.formatPrice(this.document.FreeF2) : 0) + '\n', fontSize: 10,  },
                  { text:  '- Ostali troškovi: ' + (this.document.FreeF3 ? this.formatPrice(this.document.FreeF3) : 0) + '\n', fontSize: 10,  },
                  { text:  ( this.profitLossP > 0 ? '= Dobit: ' : '=Gubitak: ') + ( this.profitLossP ? this.formatPrice(this.profitLossP) : 0) + '\n', fontSize: 10, bold: true },
                  { text:  '(svi iznosi su neto (bez PDVa) u kunama) \n', fontSize: 8 },
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addPartnerOnly () {
      const docPart = {
        columns: [
              {
                width: '70%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                  { text: this.document.partnerName + ' \n', fontSize: 12, bold: true },
                  { text: (this.document.partnerStreet ? this.document.partnerStreet  + ' ' : ' ') + (this.document.partnerBuildingNameNumber ? this.document.partnerBuildingNameNumber + ' \n' : '') , fontSize: 8 },
                  { text: (this.document.partnerPostcode ? this.document.partnerPostcode  + '' : '') + (this.document.partnerCity ? this.document.partnerCity + '\n' : '') , fontSize: 8 },
                  { text: (this.document.partnerPhones ? this.document.partnerPhones + ' ' : '') + (this.document.partneremail ? 'email: ' + this.document.partneremail + ' \n' : '') , fontSize: 8 },
                  { text: (this.document.partnerSecretaryFirst ? this.document.partnerSecretaryFirst + ' ' : ' ') + (this.document.partnerSecretaryLast ? this.document.partnerSecretaryLast + ' ' : '') + (this.document.partnerSecretaryEmail ? this.document.partnerSecretaryEmail + ' \n' : ' ') , fontSize: 8 },
                  { text: (this.document.partnerSecretaryPhone ? this.document.partnerSecretaryPhone + ' ' : ' ') + (this.document.partnerContactTime ? this.document.partnerContactTime + ' \n' : ' ') , fontSize: 8 },
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeadComment () {
      if (this.document.documentDescription4.trim().length > 0 || this.document.documentDescription1.trim().length >0 ) {
        const docPart = {
          columns: [
                {
                  width: '10%',
                  text: [
                    { text: ' ', fontSize: 8 }
                  ]
                },
                {
                  width: '60%',
                  text: [
                    { text: (this.document.documentDescription4 ? 'Predmet: ' + this.document.documentDescription4 : '') + ' \n ', fontSize: 10, bold: true },
                    { text: ' \n ', fontSize: 8 },
                    { text: (this.document.documentDescription1 ? this.document.documentDescription1 : ' ') + ' \n ', fontSize: 8 }
                  ]
                },
                {
                  width: '10%',
                  text: ' '
                },
                {
                  width: '10%',
                  text: [
                    { text: ' \n', fontSize: 15 },
                  ]
                }
              ]
        }
        this.content.push(docPart)
      }
    },

    addItemsHeader () {
      const docPart = {
            columns: [
              {
                width: '5%',
                text: { tex: this.lang.DocPrintOrder + '\n', fontSize: 8 }
              },
              {
                width: '38%',
                text: { text: this.lang.DocPrintName + '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '5%',
                text: { text: this.lang.DocPrintUnit + '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '6%',
                text: { text: this.lang.DocPrintPcs + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '7%',
                text: { text: this.lang.DocPrintPrice + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '7%',
                text: { text: this.lang.DocPrintAmount + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '7%',
                text: { text: this.lang.DocPrintDiscount + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '7%',
                text: { text: this.lang.DocPrintAmount2 + '\n', fontSize: 8, alignment: 'center' }
              },
              {
                width: '4%',
                text: { text: this.lang.DocPrintVAT + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: this.lang.DocPrintAmount3 + '\n', fontSize: 8, alignment: 'right' }
              }
            ]
          }
      this.content.push(docPart)
    },
    addItemsHeaderNoPrice () {
      const docPart = {
          columns: [
              {
                width: '5%',
                text: { tex: this.lang.DocPrintOrder + '\n', fontSize: 8 }
              },
              {
                width: '64%',
                text: { text: this.lang.DocPrintName + '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '10%',
                text: { text: this.lang.DocPrintUnit + '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '11%',
                text: { text: this.lang.DocPrintPcs + '\n', fontSize: 8, alignment: 'right' }
              },
            ]
          }
      this.content.push(docPart)
    },

    addTotal () {
      const docPart = {
                    columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '40%',
                text: [
                  { text: this.lang.DocPrintVAT1 + ' ' + (this.document.documentTotalVAT1Amount ? this.document.documentTotalVAT1Amount : 0) + '\n', fontSize: 8 },
                  { text: this.lang.DocPrintVAT2 + ' ' + (this.document.documentTotalVAT2Amount ? this.document.documentTotalVAT2Amount : 0) + '\n', fontSize: 8 },
                  { text: this.lang.DocPrintVAT3 + ' ' + (this.document.documentTotalVAT3Amount ? this.document.documentTotalVAT3Amount : 0) + '\n', fontSize: 8 },
                  { text: this.lang.DocPrintVAT4 + ' ' + (this.document.FreeF4 ? this.formatPrice(this.document.FreeF4) : 0) + '\n', fontSize: 8 }
                ]
              },
              {
                width: '8%',
                text: ' '
              },
              {
                width: '21%',
                text: [
                  { text: this.lang.DocPrintTotal00 + (this.document.documentHomeCurrency ? this.document.documentHomeCurrency : '') + '): \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal01 + (this.document.documentHomeCurrency ? this.document.documentHomeCurrency : '') + '): \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal1 + (this.document.documentHomeCurrency ? this.document.documentHomeCurrency : '') + '): \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal2 + (this.document.documentHomeCurrency ? this.document.documentHomeCurrency : '') + '): \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal3 + (this.document.documentHomeCurrency ? this.document.documentHomeCurrency : '') + '): \n', fontSize: 8, bold: true },
                  { text: this.lang.DocPrintTotal4 + (this.document.documentHomeCurrency ? this.document.documentHomeCurrency : '') + '): \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal5 + (this.document.documentHomeCurrency ? this.document.documentHomeCurrency : '') + '): \n', fontSize: 12, bold: true }
                ]
              },
              {
                width: '8%',
                text: [
                  { text: ' ', alignment: 'right' }
                ]
              },
              {
                width: '13%',
                text: [
                  { text: this.pSum00 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum01 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum1 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum2 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum3 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum4 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum5 + '\n', fontSize: 12, bold: true, alignment: 'right' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
 
    addBankData () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '40%',
                text: [
                  { text: this.lang.DocPrintPayType + ' ' + '\n', fontSize: 8 },
                  { text: this.lang.DocPrintIdUser + ' ' + this.document.UserId + '\n', fontSize: 8 },
                  { text: this.lang.BankAccount + ' ' + (this.document.bankAccountNr ? this.document.bankAccountNr : ' ') + '\n', fontSize: 10, bold: true },
                  { text: this.lang.DocPrintPayUseNr + '   ' + this.document.documentId + this.document.BusinessYear + '\n', fontSize: 8 },
                  { text: (this.document.bankAccountName ? this.document.bankAccountName : ' ') + '\n', fontSize: 8 }
                ]
              },
              {
                width: '8%',
                text: ' '
              },
              {
                width: '21%',
                text: [
                  { text: ' \n', fontSize: 8 }
                ]
              },
              {
                width: '8%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '13%',
                text: [
                  { text: ' \n', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },

    addComment () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '80%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text:  this.lang.DocPrintComment + ' ' + this.document.documentDescription5 + ' \n', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },

     addFooter3 () {
      const docPart = {
        columns: [
              {
                width: '80%',
                text: [
                  { text: (this.comp.CompanyDescriptionUp ? this.comp.CompanyInvoiceHint : ' ') + ' \n', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addSignature1 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: this.lang.DocPrintMadeBy + ' ' + (this.document.First ? this.document.First : ' ') + ' ' + (this.document.Last ? this.document.Last : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: this.lang.DocPrintControlBy + ' ' + (this.document.FirstControl ? this.document.FirstControl : ' ') + ' ' + (this.document.LastControl ? this.document.LastControl : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: this.lang.DocPrintCFO + ' ' + (this.document.FirstCFO ? this.document.FirstCFO : ' ') + ' ' + (this.document.LastCFO ? this.document.LastCFO : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addSignature2 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________  \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addSignature3 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: 'Primio na servis: ' + (this.document.First ? this.document.First : ' ') + ' ' + (this.document.Last ? this.document.Last : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: ' Predao : \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addSignature4 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addEmpty () {
      const docPart = {
        
          }
      this.content.push(docPart)
    },

  },
  computed: {
  },
  watch: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
