<template>
 <v-container fluid class="ma-2">
   <v-row no-gutters justify="center" class="pa-0">
    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">

    <v-row>
      <v-col cols="12" sm="12" md="4" xs="4" lg="4" xl="4" class="pa-0">
        <h3>{{lang.ItemDetails}}</h3>
      </v-col>
      <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4" class="pa-0">
          <span v-if="this.$store.state.user.AccessLevel <= 3">
            <v-btn class="primary mr-1" @click="itemEdit">
              {{langC.Edit}}
            </v-btn>
          </span>
          <span v-if="this.$store.state.user.AccessLevel == 1">
            <v-btn class="red mr-1" @click="itemDelete">
              {{langC.Delete}}
            </v-btn>
          </span>
          <v-btn class="green" @click="itemlist">
              {{langC.Back}}
         </v-btn>
      </v-col>
    </v-row>

    <v-row>
        <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4">
          <v-card >
            <v-card-title primary class="title py-2">{{lang.BasicData}}</v-card-title>
            <v-card-text>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                  {{lang.ItemID}}                   
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                  <span style="font-weight:bold">{{item.itemID}}</span>
                </v-flex>
              </v-row>

              <v-row >
                <v-flex xs4 class="py-0 px-2">
                  {{lang.ItemName}}                   
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                  <span style="font-weight:bold">{{item.itemName}}</span>
                </v-flex>
              </v-row>

              <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                  {{lang.ItemNameShort}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.ItemNameShort}}</span>
                </v-flex>
              </v-layout>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                  {{lang.ItemCode}}                  
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.ItemCode}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemEANCode}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.ItemEANCode}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemGroup}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemGroup}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemDescription}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemDescription}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemUnit}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemUnit}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemSize}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.ItemSize}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">          
                    {{lang.ItemWeight}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.ItemWeight}} {{item.ItemWeightMeasure}}</span>
                </v-flex>
              </v-row>

              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemDimLenght}}  {{item.ItemDimLenght}} {{item.ItemDimMeasure}}                  
                </v-flex>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemDimHeight}} {{item.ItemDimHeight}} {{item.ItemDimMeasure}}  
                </v-flex>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemDimDepth}} {{item.ItemDimDepth}} {{item.ItemDimMeasure}}  
                </v-flex>
              </v-row>

              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemWarehouse}}  {{item.ItemWarehouse}}             
                </v-flex>
                <v-flex xs3 class="py-0 px-2">
                    {{lang.ItemWarehouseRow}} {{item.ItemWarehouseRow}}
                </v-flex>
                <v-flex xs3 class="py-0 px-2">
                    {{lang.ItemWarehouseShelf}}  {{item.ItemWarehouseShelf}}
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    {{lang.ItemWahrehouseFloor}} {{item.ItemWahrehouseFloor}}
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemPicture1}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.ItemPicture1}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemPicture2}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.ItemPicture2}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemPicture3}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.ItemPicture3}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemBelongsToId}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.ItemBelongsToId}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemService}}                     
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    <v-icon v-if="item.itemService">mdi-check</v-icon>
                </v-flex>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemWeb}}                     
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    <v-icon v-if="item.itemWeb">mdi-check</v-icon>
                </v-flex>
              </v-row>

              <v-row >
                <v-flex xs4 class="py-0 px-2">
                  {{lang.ItemNotActiv}}                     
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                  <v-icon v-if="item.itemNotActiv">mdi-check</v-icon>
                </v-flex>
                <v-flex xs4 class="py-0 px-2">
                  {{lang.ItemHideForever}}                     
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                  <v-icon v-if="item.itemHideForever">mdi-check</v-icon>
                </v-flex>
              </v-row>

            </v-card-text>
          </v-card>

        </v-col>

        <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4">
          <v-card>
            <v-card-title primary class="title">
              {{lang.Price}}
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemPrice}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemPrice}} {{currency}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemVAT1Percentage}}                     
                </v-flex>
                <v-flex xs3 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemVAT1Percentage}}</span>
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    {{lang.ItemVAT1Amount}}                     
                </v-flex>
                <v-flex xs3 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemVAT1Amount}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemDirectDuty1}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemDirectDuty1}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemDirectDuty2}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemDirectDuty2}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemPriceWithVAT}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemPriceWithVAT}} {{currency}}</span>
                </v-flex>
              </v-row>

              <v-card-title primary class="title">
                {{lang.SupplierData}}
              </v-card-title>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemSuppBasePrice}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemSuppBasePrice}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemSuppDiscount}}%              
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemSuppDiscount}}%</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemSuppPrice}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemSuppPrice}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    Zadnja izmjena:                   
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.LastUpdateBy}}</span>
                </v-flex>
              </v-row>
                            
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemSuppCode}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.ItemSuppCode}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemMargin}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemMargin}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                  {{lang.ItemMaxDiscount}}                   
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemMaxDiscount}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemDiscountAmount1}}                    
                </v-flex>
                <v-flex xs3 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemDiscountAmount1}}</span>
                </v-flex>
                <v-flex xs3 class="py-0 px-2">
                    {{lang.ItemDiscountPercentage1}}                    
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemDiscountPercentage1}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemDiscountAmount2}}                    
                </v-flex>
                <v-flex xs3 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemDiscountAmount2}}</span>
                </v-flex>
                <v-flex xs3 class="py-0 px-2">
                    {{lang.ItemDiscountPercentage2}}                    
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemDiscountPercentage2}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemDiscountAmount3}}                    
                </v-flex>
                <v-flex xs3 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemDiscountAmount3}}</span>
                </v-flex>
                <v-flex xs3 class="py-0 px-2">
                    {{lang.ItemDiscountPercentage3}}                    
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemDiscountPercentage3}}</span>
                </v-flex>
              </v-row>
            </v-card-text>
            </v-card>
          </v-col>


          <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4">
            <v-card >
              <v-card-title primary class="title">
                {{lang.PriceExp}}
              </v-card-title>
            <v-card-text>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                  {{lang.ItemForePrice}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemForePrice}} {{currency}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                   {{lang.ItemForeVAT1Percentage}}                   
                </v-flex>
                <v-flex xs3 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemForeVAT1Percentage}}</span>
                </v-flex>
                <v-flex xs3 class="py-0 px-2">
                    {{lang.ItemForeVAT1Amount}}                     
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemForeVAT1Amount}}</span>
                </v-flex>
              </v-row>


              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemForeDirectDuty1}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemForeDirectDuty1}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemForeDirectDuty2}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemForeDirectDuty2}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                  {{lang.ItemForePriceWithVAT}}                   
                </v-flex>
                <v-flex xs4 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemForePriceWithVAT}} {{currency}}</span>
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                  {{lang.ItemExRate}}                    
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.ItemExRate}}</span>
                </v-flex>

              </v-row>
            </v-card-text>
            </v-card>
          </v-col>
        
        </v-row>

      </v-col>
   </v-row>
  </v-container>
</template>

<script>
import langEn from './itemDescEn'
import langHr from './itemDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import ItemService from '@/services/ItemService'

export default {
  name: 'itemview',
  data () {
    return {
      lang: {},
      langC: {},
      menu: false,
      modal: false,
      msg: '',
      valid: true,
      samoZaProbu: 0,
      item: {},
      error: null,
      localError: false,
      privitems: [
        { text: 'EUR', value: 'EUR' },
        { text: 'Kn', value: 'Kn' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' }
      ],
      privGroups: [],
      currency: ''
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.itemid) {
      try {
        const dbItem = await ItemService.show(this.$store.state.itemid)
        this.item = dbItem.data.item
        this.currency = this.$store.state.businessYear >= 2023 ? 'EUR' : 'Kn'

      } catch (error) {
        // eslint-disable-next-line
        console.log('error read from datebase')
      }
    }
  },
  methods: {
    async itemlist () {
      try {
        this.$store.dispatch('seteditItem', 0)
        await this.$router.push({
          name: 'item'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async itemDelete () {
      try {
        await this.$router.push({
          name: 'itemdelete'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async itemEdit () {
      try {
        this.$store.dispatch('seteditItem', 1)
        await this.$router.push({
          name: 'itemcreate'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  computed: {
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
