const banksEN = {
  BankAccounts: 'Bank Accounts',
  IBAN: 'IBAN *',
  SWIFT: 'SWIFT *',
  BankName: 'Banke name *',
  BankAccountDescription: 'Bank account desc.',
  BankAdress: 'Bank Adress',
  LedgerAccount: 'Ledger Account',
  DefaultAccount: 'Default account',
  Saved: 'New bank account has been saved.',
  Error: ' Error on save ...',
  AccountID: 'Account ID',
  PrimaryCurrency: 'Primary Currency',
  RequiredF: 'This field is required',
  ErrorG: 'Error! Some required fields are missing',
  BankAccountDetails: 'Bank Account Details',
  BankAccountID: 'Bank Account ID',
  id: 'DbId',
  createdAt: 'Created at'
}

export default banksEN
