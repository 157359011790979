import store from '../store/store'

const documentCurrency = () => {

  let localName = ''
  if (store.state.language === 'EN') {
    localName = 'EUR'
  }
  if (store.state.language === 'HR') {
    localName = 'Kn'
  }

  return localName
}

export default documentCurrency