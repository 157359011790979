<template>
  <div class="task">
    <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
      <v-card>
        <v-card-title>
          {{ documentNameLoc }}
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="documentId"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"
            :mobile-breakpoint="0" 
          >
          <template v-slot:[`item.BookID`]="{ item }">
              <span>{{ parseInt(item.BookID) }}</span>
          </template>
          <template v-slot:[`item.ClosedAccount`]="{ item }">
            <span v-if="item.ClosedAccount"><v-icon>mdi-check</v-icon></span>
          </template>
          <template v-slot:[`item.BookingDate`]="{ item }">
              <span>{{ localDate(item.BookingDate) }}</span>
          </template>
          <template v-slot:[`item.DebitTotal`]="{ item }">
              <span class="title indigo--text">{{ localMoney(item.DebitTotal) }}</span>
          </template>
          <template v-slot:[`item.CreditTotal`]="{ item }">
              <span class="title deep-orange--text">{{ localMoney(item.CreditTotal) }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>
  </div>
</template>

<script>
// import axios from 'axios'
import MainLedgerService from '@/services/MainLedgerService'
import MainLedgerDetailService from '@/services/MainLedgerDetailService'
import {checkBooks } from './mainledgerHelper'
import { mapGetters } from 'vuex'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import moneyFormat from '@/snippets/moneyFormat'

export default {
  name: 'mainledgerlist3',
  data () {
    return {
      documentName: '',
      documentNameLoc: '',
      locDocument: {},
      docside: 0,
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        MLType: 1
      },
      mainledger: {},
      lang: {},
      langC: {},
      saving: false,
      bookTypeSl: null,
      bookTypeSelected: {},
      show: 0
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    this.defaultInit()

  },
  methods: {
     localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    async defaultInit() {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentSide) {
        this.docSide = this.$store.state.documentSide
        this.mainQuery.MLType = this.$store.state.documentSide
      }
      if (!this.$store.state.bookTypeSelected) {
        this.show = this.$store.state.documentSide ? this.$store.state.documentSide : 1
        await checkBooks(this.show)
      }
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc

      if (this.$store.state.bookTypeSelected) {
        this.bookTypeSl = this.$store.state.bookTypeSelected
      }
      this.items = []
      const {data} = await MainLedgerService.index(this.mainQuery)
      if (data && data.documents !== undefined && data.documents.length > 0) {
        this.items = data.documents
      }

      this.headers.push(
        {
          text: this.lang.BookBase, align: 'left', sortable: true, value: 'BookID'
        },
        { text: this.lang.ClosedAccount, align: 'left', sortable: true, value: 'ClosedAccount' },
        { text: this.lang.BookTypeID, align: 'left', sortable: true, value: 'BookTypeID' },
        { text: this.lang.BookingName, align: 'left', sortable: true, value: 'BookName' },
        { text: this.lang.BookingDate, align: 'left', sortable: true, value: 'BookingDate' },
        { text: this.lang.MLFree1, align: 'left', sortable: true, value: 'MLFree1' },
        { text: this.lang.DebitTotal, align: 'left', sortable: true, value: 'DebitTotal' },
        { text: this.lang.CreditTotal, align: 'left', sortable: true, value: 'CreditTotal' },
        { text: 'DbId', value: 'id' }
      )
    },
    async navigateTo (params) {
      this.mldetails = []
      this.$store.dispatch('setMainLedgerTotals', 0)
      this.$store.dispatch('seteditItem', 0)
      this.$store.dispatch('setretPath', 'mainledger3')
      this.$store.dispatch('setMainLedgerid', params.id)
      const locDocument = await MainLedgerService.show(params.id)
      this.mainledger = locDocument.data.mainLedger
      this.mldetails = (await MainLedgerDetailService.index(params.id)).data.mainledgerDetails
      await this.$store.dispatch('setMainLedgerActiv', this.mainledger ? this.mainledger : {})
      await this.$store.dispatch('setMainLedgerItems', this.mldetails ? this.mldetails : [])
      this.bookTypeSelected.text = this.mainledger.BookName
      this.bookTypeSelected.value = parseInt(this.mainledger.BookTypeID)
      await this.$store.dispatch('setbookTypeSelected', this.bookTypeSelected)
      this.$router.push({
        name: 'mainledgerview'
      })
    },
    async mainledgerDetails (mainledgerid) {
      try {
        this.$store.dispatch('setMainLedgerid', mainledgerid)
        const locDocument = await MainLedgerService.show(this.$store.state.mainledgerid)
        this.mainledger = locDocument.data.mainLedger
        await this.$store.dispatch('setMainLedgerActiv', this.mainledger)
        this.$store.dispatch('seteditItem', 1)
        this.$store.dispatch('setretPath', 'mainledger3')
        await this.$router.push({
          name: 'mainledgerdetail'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    async initAgain () {

    }
  },
  computed: {
    ...mapGetters({
      bookTypeS: 'NeedMainLedgerBookTypeSelected'
    })
  },
  watch: {
    'bookTypeS' () {
      this.initAgain()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
