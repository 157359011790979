<template>
  <div class="mainledgerprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import PartnerService from '@/services/PartnerService'
import {parseNum, round} from '@/snippets/allSnippets'
import dayjs from 'dayjs'

export default {
  name: 'mainledgerprintcard3',
  props: ['card', 'printT'],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0,
      partner: {},
      debitTotal: 0,
      creditTotal: 0,
      debitCreditTotal: 0,
      printType: 1,
      currency: 'EUR',
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}

  },
  methods: {
    async print (type) {
      this.debitTotal = this.card.Total1 ? this.card.Total1 : 0
      this.creditTotal = this.card.Total2 ? this.card.Total2 : 0
      this.debitCreditTotal = this.card.Total5 ? this.card.Total5 : 0
      this.currency = this.$store.state.businessYear >= 2023 ? 'EUR' : 'Kn'
      if (this.card.partnerVATID) {
        const { data } = await PartnerService.show(this.card.partnerVATID)
        if (data.partner) {
          this.partner = data.partner
        }
      }
      this.printType = type
      this.initDocument()
      
      if (this.printType === 1) {
        this.addHeaderNoLogo()
      }
      if (this.printType === 2 || this.printType === 3) {
        this.addHeaderNoLogoBig()
      }
      this.addLine()
      this.addItemsHeader()
      this.addLine()
      this.addItems()
      this.addItemsFoter()
      this.addLine()
      if (this.printType === 2) {
        this.addFooter2()
      }
      if (this.printType === 3) {
        this.addFooter1()
      }
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.content = []
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addLineDash () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1,
                dash: { length: 1 },
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '35%',
                text: [
                  { text: this.comp.CompanyNameShort + ' \n', fontSize: 14, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + ' ' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text:  this.card.headUp + '\n', fontSize: 12 },
                  { text:  (this.card.partner.partnerName ? this.card.partner.partnerName : '' ) + ' \n', fontSize: 14, bold: true},
                  { text:  (this.card.partner.partnerStreet ? this.card.partner.partnerStreet : '') + (this.card.partner.partnerBuildingNameNumber ? ' ' + this.card.partner.partnerBuildingNameNumber : '') + (this.card.partner.partnerPostcode ? ', '+ this.card.partner.partnerPostcode : '') + (this.card.partner.partnerCity ? ' ' + this.card.partner.partnerCity : '')+' \n', fontSize: 8},
                  { text:  'OIB ' + (this.card.partner.partnerVATID ? this.card.partner.partnerVATID : '') + ' \n', fontSize: 8},
                  { text: ' Od konta : ' + this.card.fromAccount , fontSize: 8},
                  { text: ' Do konta : ' + this.card.toAccount+ '\n', fontSize: 8},
                  { text: ' Od datuma: ' + dayjs(this.card.docFromDate).format('DD.MM.YYYY')  + ' Do datuma: ' + dayjs(this.card.docToDate).format('DD.MM.YYYY'), fontSize:8}
                ]   
              },
              {
                // % width
                width: '20%',
                text: [                 
                  { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeaderNoLogoBig () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '35%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 14, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + ' ' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // % width
                width: '30%',
                text: [
                  { text:  '\n', fontSize: 18, bold: true },
                  
                ]   
              },
              {
                // % width
                width: '30%',
                text: [      
                  { text: ' Od konta : ' + this.card.fromAccount + '\n' , fontSize: 8},
                  { text: ' Do konta : ' + this.card.toAccount + '\n', fontSize: 8},
                  { text: ' Od datuma: ' + dayjs(this.card.docFromDate).format('DD.MM.YYYY') + '\n' + ' Do datuma: ' + dayjs(this.card.docToDate).format('DD.MM.YYYY') + '\n', fontSize:8},           
                  { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)

        const docPart2 = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '15%',
                text: [
                  { text:  ' \n', fontSize: 10, bold: true },
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text:  '\n', fontSize: 18, bold: true },
                  { text:  '\n', fontSize: 18, bold: true },
                  { text:  '\n', fontSize: 18, bold: true },
                  { text:   (this.card.partner.partnerName ? this.card.partner.partnerName : '' ) + ' \n', fontSize: 12},
                  { text:   (this.card.partner.partnerStreet ? this.card.partner.partnerStreet : '') + (this.card.partner.partnerBuildingNameNumber ? this.card.partner.partnerBuildingNameNumber : '') +' \n', fontSize: 8},
                  { text:   (this.card.partner.partnerPostcode ? this.card.partner.partnerPostcode : '') + (this.card.partner.partnerCity ? this.card.partner.partnerCity : '') +' \n', fontSize: 8},
                  { text:   'OIB ' + (this.card.partner.partnerVATID ? this.card.partner.partnerVATID : '') + ' \n', fontSize: 8},
                ]   
              },

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart2)

        if (this.printType === 2){
          const docPart3 = {
              columns: [
                {
                  // auto-sized columns have their widths based on their content
                  width: '25%',
                  text: [
                    { text:  ' \n', fontSize: 10, bold: true },
                  ]
                },
                {
                  // % width
                  width: '45%',
                  text: [
                    { text:  '\n', fontSize: 18, bold: true },
                    { text:  '\n', fontSize: 18, bold: true },
                    { text:  '\n', fontSize: 18, bold: true },
                    { text:  'Izvod otvorenih stavaka  ' + this.currency + '\n', fontSize: 18, bold: true },
                  ]   
                },
              ],
              // optional space between columns
              columnGap: 10
            }
          this.content.push(docPart3)
        }

        if (this.printType === 3){
          const docPart3 = {
              columns: [
                {
                  // auto-sized columns have their widths based on their content
                  width: '35%',
                  text: [
                    { text:  ' \n', fontSize: 10, bold: true },
                  ]
                },
                {
                  // % width
                  width: '45%',
                  text: [
                    { text:  '\n', fontSize: 18, bold: true },
                    { text:  '\n', fontSize: 18, bold: true },
                    { text:  '\n', fontSize: 18, bold: true },
                    { text:  'O P O M E N A \n', fontSize: 22, bold: true },
                  ]   
                },
              ],
              // optional space between columns
              columnGap: 10
            }
          this.content.push(docPart3)
        }

        const docPart5 = {
          columns: [
            {
              // auto-sized columns have their widths based on their content
              width: '5%',
              text: [
                { text:  ' \n', fontSize: 10, bold: true },
              ]
            },
            {
              // % width
              width: '85%',
              text: [
                { text:  'Poštovani,\n\n', fontSize: 10, },
                { text:  'Uvidom u evidenciju dugovanja na dan '  + dayjs().format('DD.MM.YYYY')  + ' utvrdili smo slijedeće otvorene stavke \n\n', fontSize: 10,  },
              ]   
            },
          ],
          // optional space between columns
          columnGap: 10
        }
      this.content.push(docPart5)        
    },
    addItemsHeader () {
        const docPart = {
        columns: [
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: 'Rb.  Vrsta'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: 'Broj dok.'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: 'Veza'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: 'Datum knjiženja'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: 'Datum dokumenta'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: 'Datum dospijeća'
              },
              {
                width: '16%',
                fontSize: 8,
                alignment: 'left',
                text: 'Opis'
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'left',
                text: 'Konto'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: 'Duguje'
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                text: 'Potražuje'
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                text: 'Saldo ' + this.currency
              },
            ]
          }
        this.content.push(docPart)        
    },
    addItems () {
           this.order = 0
           //const borderDate = new Date()
            if (this.printType === 2 || this.printType === 3) {
              this.debitTotal =  0
              this.creditTotal =  0
              this.debitCreditTotal =  0
            }
            if (this.card.items && this.card.items.length > 0) {
              this.card.items.map(item => {
                  this.order += 1
                  // this.pTtt5 = item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0
                  // this.pTtt6 = item.itemDiscountPercentage1 ? this.formatPrice(item.itemDiscountPercentage1) : 0
                  // this.pTtt7 = item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0
                  // this.pTtt8 = item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0
                  // this.pTtt9 = item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0
                  let addItem = true
                  if (this.printType === 2 || this.printType === 3) {
                    const borderDate = dayjs(item.InvoicePaymentDeadlineDate).isAfter()
                    if (borderDate) {
                      addItem = false
                    }
                    if (!borderDate) {
                      this.debitTotal +=  item.Debit ? round(parseNum(item.pDebit),2) : 0
                      this.creditTotal +=  item.Credit ? round(parseNum(item.pCredit),2) : 0
                      this.debitCreditTotal +=  item.CreditDebit ? round(parseNum(item.pDebit),2) - round(parseNum(item.pCredit),2) : 0
                    }
                  }
                  if (addItem) {
                      const docPart01 = {
                      columns: [
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'left',
                              text: item.itBookName
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'left',
                              text: item.itInvoice
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'left',
                              text: (item.account2 === '12'  ? (item.invoiceOutNumber ? item.invoiceOutNumber : '') : (item.invoiceInNumber ? item.invoiceInNumber : ''))  + (item.BusinessYearInvoice ?  '/' + item.BusinessYearInvoice : '')
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'left',
                              text: dayjs(item.BookingDate).format('DD.MM.YYYY')
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'left',
                              text: dayjs(item.InvoiceDate).format('DD.MM.YYYY')
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'left',
                              text: dayjs(item.InvoicePaymentDeadlineDate).format('DD.MM.YYYY')
                            },
                            {
                              width: '16%',
                              fontSize: 7,
                              alignment: 'left',
                              text: (item.Description && item.Description.trim().length > 24 ? item.Description.trim().substr(0,24): '')
                            },
                            {
                              width: '7%',
                              fontSize: 7,
                              alignment: 'left',
                              text: item.account4
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'right',
                              text: this.formatPrice(item.pDebit)
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'right',
                              text: this.formatPrice(item.pCredit)
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'right',
                              text: this.formatPrice(item.CreditDebit)
                            },
                          ]
                        }
                      this.content.push(docPart01)
                  }
                }
              )
          }
    },
    addItemsFoter () {
       
      this.addLine()

      const docPart01 = {
           columns: [
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '15%',
                fontSize: 8,
                alignment: 'left',
                text: 'UKUPNO:'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: ' '
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.debitTotal)
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.creditTotal)
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.debitCreditTotal)
              },
          ]
        }
      this.content.push(docPart01)
    },
    addFooter2() {
        const docPart = {
          columns: [
            {
              // auto-sized columns have their widths based on their content
              width: '5%',
              text: [
                { text:  ' \n', fontSize: 10, bold: true },
              ]
            },
            {
                width: '45%',
                text: [
                  { text: '\n\nPošiljalac izvoda ', fontSize: 10, alignment: 'center' },
                  { text: ' \n\n\n', fontSize: 10 },
                  { text: '_____________________________________  \n', fontSize: 8, alignment: 'center' }
                ]
            },
            {
                width: '45%',
                text: [
                  { text: '\n\nPotvrđujemo usklađenost otvorenih stavaka ', fontSize: 10, alignment: 'center' },
                  { text: ' \n\n\n', fontSize: 10 },
                  { text: '_____________________________________  \n', fontSize: 8, alignment: 'center' }
                ]
              }
          ],
          // optional space between columns
          columnGap: 10
        }
      this.content.push(docPart)
      const docPart2 = {
            columns: [
            {
              // auto-sized columns have their widths based on their content
              width: '5%',
              text: [
                { text:  ' \n', fontSize: 10, bold: true },
              ]
            },
            {
                width: '90%',
                text: [
                  { text: '\n\n Napomena dužnika: Osporavamo iskazano za iznos  ________________________________________________________', fontSize: 10 },
                  { text: ' \n\n\n', fontSize: 10 },
                  { text: 'iz  slijedećih razloga _______________________________________________________________________________________', fontSize: 10, alignment: 'left' },
                  
                ]
            },
            ],

        }
      this.content.push(docPart2)
      const docPart3 = {
          columns: [
            {
              // auto-sized columns have their widths based on their content
              width: '5%',
              text: [
                { text:  ' \n', fontSize: 10, bold: true },
              ]
            },
            {
                width: '45%',
                text: [
                  { text: '\n\n Mjesto i datum ', fontSize: 10, alignment: 'center' },
                  { text: ' \n\n\n', fontSize: 10 },
                  { text: '_____________________________________  \n', fontSize: 8, alignment: 'center' },
                  { text: ' \n Molimo da ovjereni primjerak ovog izvoda vratite vjerovniku', fontSize: 8, alignment: 'left' }
                ]
            },
            {
                width: '45%',
                text: [
                  { text: '\n\n Potvrđujemo usklađenost otvorenih stavaka ', fontSize: 10, alignment: 'center' },
                  { text: ' \n\n\n', fontSize: 10 },
                  { text: '_____________________________________  \n', fontSize: 8, alignment: 'center' }
                ]
              }
          ],
          // optional space between columns
          columnGap: 10
        }
      this.content.push(docPart3)
    },
    addFooter1() {
        const docPart = {
          columns: [
            {
              // auto-sized columns have their widths based on their content
              width: '5%',
              text: [
                { text:  ' \n', fontSize: 10, bold: true },
              ]
            },
            {
              // % width
              width: '85%',
              text: [
                { text:  '\nValuta: ' + this.currency + '\n', fontSize: 10, },
                { text:  '\nNavedeni dug je potrebno je uplatiti u roku od 3 radna dana, a potvrdu o plaćanju molim dostavite na email. Hvala.,\n', fontSize: 10, },
                { text:  'Ukoliko je navedeni dug u međuvremenu podmiren, primite naše isprike i zanemarite ovu obavijest.\n\n', fontSize: 10,  },
                { text:  'S poštovanjem,\n', fontSize: 10,  },
              ]   
            },
          ],
          // optional space between columns
          columnGap: 10
        }
      this.content.push(docPart)
      const docPart2 = {
            columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },

                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n\n\n\n', fontSize: 10 },
      
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n\n\n\n', fontSize: 10 },
                  { text: '____________________  \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ],

        }
      this.content.push(docPart2)
    }
  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>