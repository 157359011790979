<template>
<div>
  <v-container fluid grid-list-md>
  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="mldetails"
    :search="search"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="50"
    @click:row="haveAlert"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{lang.bankStatments}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
  
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="700px">

          <v-card>
            <v-card-title>
              <span class="headline">{{ lang.bankStatment }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                <v-row no-gutters>
                    <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="pr-2">
                      {{lang.bankStatment}}
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="10" xl="10">

                    </v-col>
                </v-row>
                <v-row no-gutters> 
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="pr-2">
                    <v-text-field
                      v-model="editedItem.HomeCurrency"
                      :label="lang.HomeCurrency"
                      required
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model="editedItem.Debit"
                      :label="lang.Debit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model="editedItem.Credit"
                      :label="lang.Credit"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters> 
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="pr-2">
                    <v-text-field
                      v-model="editedItem.ForeignCurrency"
                      :label="lang.ForeignCurrency"
                      required
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model="editedItem.DebitForegin"
                      :label="lang.DebitFr"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model="editedItem.CreditForegin"
                      :label="lang.CreditFr"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>

                  <v-row no-gutters>
                    <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="pr-2">
                      <v-text-field
                        v-model="editedItem.VATPer"
                        :label="lang.VATPer"
                        maxlength="2"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                      <v-text-field
                        v-model="editedItem.Description"
                        :label="lang.Description"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn :disabled="!valid" color="success" text @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
 
    <template v-slot:[`item.action`]="{ }">

    </template>
    <template v-slot:no-data>
      {{ lang.bankStatmentEmpty }}
    </template>
    <template v-slot:[`item.ClosedAccount`]="{ item }">
      <span v-if="item.ClosedAccount"><v-icon>mdi-check</v-icon></span>
    </template>
    <template v-slot:[`item.bankStatmentDebit`]="{ item }">
      <span class="indigo--text">{{ formatPrice(item.bankStatmentDebit) }}</span>
    </template>
    <template v-slot:[`item.bankStatmentCredit`]="{ item }">
      <span class="deep-orange--text">{{ formatPrice(item.bankStatmentCredit) }}</span>
    </template>
  </v-data-table>

  <v-dialog
        v-model="showAlert"
        max-width="350"
      >
      <v-card>
        <v-card-title class="headline">
          {{alertText1}}
        </v-card-title>

        <v-card-text>
          {{alertText2}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="showAlert = false"
          >
            {{langC.Cancel}}
          </v-btn>

          <v-btn
            color="blue"
            text
            @click="haveAlert2()"
          >
            {{langC.Continue}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  </div>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import MainLedgerService from '@/services/MainLedgerService'

  export default {
    name: 'mldetailbsCRUD',
    data: () => ({
      documentName: '',
      documentNameLoc: '',
      search: '',
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: "",
        databaseDocID: 0
      },
      dialog: false,
      headers: [ 
      ],
      mldetails: [],
      localAcc1: '',
      localAcc2: '',
      localAcc3: '',
      editedIndex: -1,
      editedItem: {},
      defaultItem: {
      },
      confpassword: '',
      privitems: [ ],
      valid: true,
      showDetails: true,
      nameRules: [
        v => !!v || 'Name is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password must be 6 or more characters',
      ],
      account4Rules: [
        v => !!v || 'Account is required',
        v => (v && v.length >= 4) || 'Account must be 4 to 6 characters',
      ],
      locusers: [],
      select: { },
      selectA4: {},

      sumML: {
        Debit: 0,
        Credit: 0,
        DebitForegin: 0,
        CreditForegin: 0
      },
      mainQuery2: {
        CompanyId: 1,
        account4: '',
        databaseMLID: 0,
        businessYear: 0
      },
      currnecyType: [
        { text: 'EUR', value: 'EUR' },
        { text: 'Kn', value: 'Kn' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      money: {
        prefix: '= ',
        suffix: ' ',
        precision: 3
      },
      price: 7.538,
      docSide: 0,
      account4: {},
      newItem: {},
      exRate: 7.538,
      showAlert: false,
      type: null,
      alertText1: null,
      alertText2: null,
      para: {}
    }),

    computed: {

    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    async mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
        this.defaultItem.CompanyId = this.$store.state.companyid
      }
      this.$store.dispatch('setMainLedgerSideItem', null)
      
      this.defaultItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
      this.mainQuery.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
      
      //this.mainQuery.databaseMLID = this.$store.state.mainledgerActiv.id ? this.$store.state.mainledgerActiv.id : 0
      this.mainQuery.databaseMLID = this.$store.state.mainledgerid ? this.$store.state.mainledgerid : 0
      const { data } = await MainLedgerService.indexbsdetails(this.mainQuery)
      if (data.mainledgerDetails && data.mainledgerDetails.length > 0) {
        this.mldetails = data.mainledgerDetails
        this.$store.dispatch('setMainLedgerSideItems', data.mainledgerDetails)
      }
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc

      this.headers.push(
        {
          text: this.lang.detailId,
          align: 'left',
          sortable: true,
          value: 'bankStatmentNumberDetail',
          width: '50px'
        },
        {
          text: this.lang.detailDone,
          align: 'left',
          sortable: true,
          value: 'ClosedAccount',
          width: '50px'
        },
        {
          text: this.lang.partnerName,
          align: 'left',
          sortable: true,
          value: 'partnerName',
          width: '350px'
        },
        {
          text: this.lang.bankStatmentDebit,
          align: 'right',
          sortable: true,
          value: 'bankStatmentDebit',
          width: '100px'
        },
        {
          text: this.lang.bankStatmentCredit,
          align: 'right',
          sortable: true,
          value: 'bankStatmentCredit',
          width: '100px'
        },
        {
          text: this.lang.partnerBankIBAN,
          align: 'left',
          sortable: true,
          value: 'partnerBankIBAN',
          width: '120px'
        },
        {
          text: '',
          align: 'left',
          sortable: true,
          value: 'bankReference1',
          width: '50px'
        },
        {
          text: this.lang.bankReference1,
          align: 'left',
          sortable: true,
          value: 'bankReference2',
          width: '200px'
        },
        {
          text: '',
          align: 'left',
          sortable: true,
          value: 'bankCall1',
          width: '50px'
        },
        {
          text: this.lang.bankReference2,
          align: 'left',
          sortable: true,
          value: 'bankCall2',
          width: '200px'
        },
        
        {
          text: this.lang.Description,
          align: 'left',
          sortable: true,
          value: 'bankDescription',
          width: '220px'
        },
        {text: this.lang.bankCode, value: 'bankCode', sortable: false },
      )
    },
    created () {
      this.initialize()
    },

    methods: {
      formatPrice (value) {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      formatPriceHR (value) {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      haveAlert(params) {
        // if is book in or book out
        this.para = params
        this.showAlert = false
        if (this.para.ClosedAccount) {
          if (this.$store.state.language === "EN") {
            this.alertText1 = 'Accounting warning!'
            this.alertText2 = 'You choose to book item that are already booked. Are you shure?'
          }
          if (this.$store.state.language === "HR") {
            this.alertText1 = 'Knjiženje - upozorenje!'
            this.alertText2 = 'Odabrali ste za želite knjižiti stavku koja je već proknjižena. Da li ste sigurni?'
          }
          this.showAlert = true
        }
        if (!this.showAlert) {
          this.navigateTo ()
        }
      },
      haveAlert2() {
        this.showAlert = false
        this.navigateTo ()
      },
      async navigateTo () {
        this.$store.dispatch('setMainLedgerSideItem', this.para)
        // this.$store.dispatch('setMainLedgerTotals', 1)
        this.$store.dispatch('setConnId', this.para.id)
        this.$store.dispatch('setConnName', this.$store.state.mainledgerActiv.documentIdGeneral ? this.$store.state.mainledgerActiv.documentIdGeneral : null) 
        this.$store.dispatch('seteditItem', 0)
        this.$router.push({
          name: 'mainledgerdetailcreate'
        })
        // this.$router.push({
        //   name: 'mainledgerview'
        // })
      },

      async initialize () {
        try {
          // this.saving = true
          // this.mldetails = (await MainLedgerDetailService.index(this.$store.state.mainledgerid)).data.mainledgerDetails
          // this.saving = false
          this.errorMatch = false
          this.editedItem = this.defaultItem
          this.select = {}
          this.selectA4 = {}
        } catch (err) {
          this.error = err
        }
      },
      editItem (item) {
        this.errorMatch = false
        this.editedIndex = this.mldetails.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
          const index = this.mldetails.indexOf(item)
          const warningMessage = item.account4 + ' ' + item.account4Description
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.mldetails.splice(index, 1)) {
            this.makesum()
          }
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.mldetails[this.editedIndex], this.editedItem)
        } else {
          this.mldetails.push(this.editedItem)
        }
      },

      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      },

    },
  }
</script>