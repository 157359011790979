const docHR = {
  TaskType: 'Zadatak / vrsta',
  TaskTypes: 'Zadaci / vrste',
  AddTaskType: 'Dodaj novi zadatak/vrstu',
  TaskTypeDesc: 'Zadatak/vrsta opis',
  TaskTypeEmpty: 'Nema zadataka/vrsta',
  Actions: 'Akcije',
  NewTaskType: 'Novi zadatak/vrsta',
  EditTaskType: 'Uredi zadatak/vrstu',
  TaskDepartment: 'Zadatak / odjel',
  TaskDepartments: 'Zadaci / odjeli',
  AddTaskDepartment: 'Dodaj novi zadatak/odjel',
  TaskDepartmentDesc: 'Zadatak/odjel opis',
  TaskDepartmentEmpty: 'Nema zadataka/odjela',
  NewTaskDepartment: 'Novi zadatak/odjel',
  EditTaskDepartment: 'Uredi zadatak/odjel',
  partnerName: 'Partner',
  taskTypeDescription: 'Zadatak vrsta',
  taskDepartmentDescription: 'Zadatak odjel',
  taskShortDescription: 'Zadatak kratki opis',
  taskOpenDate: 'Datum otvaranja',
  taskForDate: 'Za datum',
  taskAlarmDate: 'Datum Alarma',
  Tasks: 'Zadaci',
  NewTask: 'Novi Zadatak',
  TaskDate: 'Datum zadatka',
  OpenDate: 'Datum upisa',
  ForDate: 'Za datum',
  ForTime: 'Za vrijeme',
  FromTime: 'Od:',
  ToTime: 'Do:',
  ShortDesc: 'Kratki opis',
  DetDesc: 'Detaljni opis',
  TaskPartner: 'Za partnera',
  TaskForUser: 'Za korisnika',
  TaskInfoSend: 'Šalji info korisniku',
  TaskInfoSend2: 'Šalji info korisniku',
  TaskEmp: 'Veza na zaposlenika',
  TaskPublic: 'Zadatak je javan',
  TaskClosed: 'Zadatak je zatvoren',
  TaskDone: 'Obavljeno',
  ChargeHours: 'Sati za naplatu',
  SpentHours: 'Sati utrošeno',
  TaskHaveProblem: 'Bilo je problema',
  TaskProblem: 'Opis problema',
  TaskToDoList: 'Todo',
  ShowDetails: 'Detalji',
  HideDetails: 'Zatvori detalje'
}

export default docHR