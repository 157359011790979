<template>
  <v-container>
    <v-row no-gutters justify="center" class="pa-0">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">

          <v-form
            name="DocumentForm"
            autocomplete="off">
            <v-row no-gutters>
                <h2>
                  {{lang.EnterPayment}}
                </h2>
            </v-row>
            <br>

            <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-0">
                  {{lang.CompanyName}}
              </v-col>
              <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8" class="py-0 px-2">
                  <span style="font-weight:bold">{{defQuery.CompanyNameDatabase}}</span>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-0">
                  {{lang.BusinessYear}}
              </v-col>
              <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8"  class="py-0 px-2">
                  <span style="font-weight:bold">{{defQuery.BusinessYear}}</span>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-0">
                {{lang.User}}
              </v-col>
              <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8"  class="py-0 px-2">
                  <span style="font-weight:bold">{{defQuery.First}} {{defQuery.Last}}, {{defQuery.email}}, {{defQuery.UserId}}</span>
              </v-col>
            </v-row>

            <v-divider class="ma-2"></v-divider>

          <v-row no-gutters> 
            <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="py-4 px-0">
              <v-text-field
                      dense
                      label="Poslovna godina"
                      v-model="defQuery.BusinessYear"
                      type="number"
                  ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="py-4 px-2">
              <v-text-field
                      dense
                      label="Broj URE"
                      v-model="defQuery.BookID1"
                      type="number"
                  ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="py-4 px-2">
              <v-text-field
                      dense
                      label="Broj IRE"
                      v-model="defQuery.BookID2"
                      type="number"
                  ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-3 px-2">
                <v-autocomplete
                    :items="partners"
                    v-model="selectPa"
                    label="Partner"
                    item-text="partnerName"
                    item-value="id"
                    return-object
                    persistent-hint
                  ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="py-2 px-2">
              <v-checkbox
                v-model="defQuery.fullCard"
                label="Cijela kartica"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="py-0 px-0">

            </v-col>
            <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="py-0 px-0">
              <v-btn 
                class="primary mr-1"
                @click="findIn(1)" >
                URA
              </v-btn>

            </v-col>
            <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="py-0 px-0">
              <v-btn 
                class="primary mr-1"
                @click="findIn(2)" >
                IRA
              </v-btn>

            </v-col>

          </v-row>

          <v-divider class="ma-2"></v-divider>

          <div v-if="found">
             <p></p>
            <v-row no-gutters>

              <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="px-2" >
                {{mainledger.partnerName}}
              </v-col>
            </v-row>
             <v-row no-gutters>

              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="px-2" >
                <v-text-field
                    dense
                    label="Broj knjiženja"
                    v-model="locBookID"
                    disabled
                ></v-text-field>
              </v-col>

               <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="px-2" >
                <v-text-field
                    dense
                    :label="lang.InvoiceDate"
                    v-model="locCreatedAt"
                    disabled
                ></v-text-field>

              </v-col>
               <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="px-2" >
                <v-text-field
                    dense
                    :label="lang.InvoicePaymentDeadlineDate"
                    v-model="locUpdatedAt"
                    disabled
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="px-2">
                <v-text-field
                    dense
                    label="Bruto iznos"
                    v-model="mainledger.InvoiceAmount"
                    type="number"
                    disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="px-2">
                <v-text-field
                    dense
                    label="Bruto iznos INO"
                    v-model="mainledger.InvoiceForeginAmount"
                    type="number"
                    disabled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>

             <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                <v-dialog
                    ref="dialog2"
                    persistent
                    v-model="modal2"
                    width="290px"
                    :return-value.sync="mainledger.InvoiceDate"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="mainledger.paidDate"
                      :label="lang.PaidDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
                    <v-date-picker v-model="mainledger.paidDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal2 = false">{{langC.Cancel}}</v-btn>
                      <v-btn color="primary" @click="$refs.dialog2.save(mainledger.paidDate)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
             </v-col>
             <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="py-4 px-2">
                <v-text-field
                    dense
                    label="Placeno"
                    v-model="mainledger.paidHome"
                    type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="py-4 px-2">
                <v-text-field
                    dense
                    label="Placeno INO"
                    v-model="mainledger.paidFore"
                ></v-text-field>
              </v-col>

           </v-row>

          </div>

          <p></p>
          <p></p>
          
          <v-divider class="mt-1"></v-divider>
         
            <v-row no-gutters v-if="card">
              <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" class="px-2" >
                <MainLedgerPayList :items="items"></MainLedgerPayList>
              </v-col>
            </v-row>

          </v-form>
          <div class="pink" v-html="error" />

          <v-btn 
            class="primary mr-1"
            @click="register" >
            PROKNJIŽI
          </v-btn>
          <v-btn
            class="primary mr-1"
            @click="mainledgeragain">
            OTVORI NOVI
          </v-btn>
          <v-btn
            v-if="card"
            class="primary mr-1"
            @click="print">
            Print
          </v-btn>

          <v-btn
            color="green"
            @click="mainledgerlist">
            <span v-if="this.$store.state.language == 'EN'">
            Back
            </span>
            <span v-if="this.$store.state.language == 'HR'">
            Natrag
            </span>
          </v-btn>

      </v-col>
    </v-row>
    <MainLedgerPayPrint ref="prnt" :pay="pay"></MainLedgerPayPrint>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import MainLedgerService from '@/services/MainLedgerService'
import MainLedgerPayList from './MainLedgerPayList'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import { mapGetters } from 'vuex'
import MainLedgerPayPrint from './MainLedgerPayPrint'
import { testLastUpdate } from '../../services/TestLastUpdate'

// import dateFormat from '@/snippets/dateFormat'

export default {
  name: 'mainledgerpay',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      lang: {},
      langC: {},
      pay: {},
      valid: true,
      lazy: false,
      rules: {
        required: value => !!value || '*'
      },
      menu: false,
      menu2: false,
      menu3: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      date1: null,
      date2: null,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      found: false,
      card: false,
      msg: '',
      documentName: '',
      documentNameLoc: '',
      mainledger: { },
      error: null,
      localError: false,
      newDocument: {},
      confpassword: '',
      size: 'sm',
      required: (value) => !!value || 'Required.',
      partners: [],
      items: [],
      CompanyId: 0,
      selectPa: {},
      selectBank: {},
      selectCR: {},
      selectUs: {},
      defQuery: {},
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
        BookID: 0,
        BookTypeID: 1
      },
      currnecyType: [
        { text: 'Kn', value: 'Kn' },
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      money: {
        prefix: '= ',
        suffix: ' ',
        precision: 3
      },
      price: 7.500,
      localUser: '',
      bookType: [],
      bookTypeSelected: {
      },
      locCreatedAt: '',
      locUpdatedAt: '',
      locBookingDate: '',
      locInvoiceDate: '',
      locInvoicePaymentDeadlineDate: '',
      locBookID: 0,
      BussinesYear: 2020
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    const d = new Date()
    this.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : d.getFullYear()
    
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    if (this.$store.state.companyid) {
      this.CompanyId = this.$store.state.companyid
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    await testLastUpdate()
    if (this.$store.state.partnerStore) {
      this.partners = this.$store.state.partnerStore
    }
  //  if (this.CompanyId > 0) {
  //    this.partners = (await PartnerService.index()).data.partners
  //  }
    this.initData()
    // if (this.$store.state.editItem === 1) {
    //   const locDocument = await MainLedgerService.show(this.$store.state.mainledgerid)
    //   this.mainledger = locDocument.data.mainLedger
    // }
  },

  methods: {
    initData() {
      this.defQuery.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
      this.defQuery.email = this.$store.state.user.email ? this.$store.state.user.email : ''
      this.defQuery.First = this.$store.state.user.First ? this.$store.state.user.First : ''
      this.defQuery.Last = this.$store.state.user.Last ? this.$store.state.user.Last : ''
      this.defQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
      this.defQuery.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
      this.defQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
      this.defQuery.BookID1 = 0
      this.defQuery.BookID2 = 0
      this.defQuery.fullCard = false
      this.selectPa = {}
      this.mainledger = {}
      this.found = false
      this.error = null
      this.card = false
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    mainledgeragain () {
      this.initData()
    },
    async register () {
      try {       
        if (this.round(this.mainledger.InvoiceAmount) === this.round(this.mainledger.paidHome)) {
          this.mainledger.paidClosed = true
        }
        this.localError = false
        await MainLedgerService.put(this.mainledger)
        this.text = 'OK. Naplata proknjizena'
        this.color = 'green'
        this.snackbar = true
      } catch (error) {
        this.error = error.data
        this.text = 'Error! Cannot save document to database. ' + error
        this.color = 'red'
        this.snackbar = true
      }
    },
    close () {
      this.show = false
    },
    async findIn(side) {
      try {
        this.items = []
        this.found = false
        this.$store.dispatch('setMainLedgerActiv', {})
        this.$store.dispatch('setMainLedgerItems', [])
        this.$store.dispatch('setMainLedgerItem', {})
        this.$store.dispatch('setMainLedgerStatus', 0)
        this.mainQuery = {}
        this.mainQuery.CompanyId = this.defQuery.CompanyId ? this.defQuery.CompanyId : 0
        this.mainQuery.BusinessYear = this.defQuery.BusinessYear ? this.defQuery.BusinessYear : 2020
        this.mainQuery.fullCard = this.defQuery.fullCard ? this.defQuery.fullCard : false
        if (side === 1) {
          this.mainQuery.BookID = this.defQuery.BookID1 ? parseInt(this.defQuery.BookID1) : 0
          this.mainQuery.partnerId = this.selectPa.id ? this.selectPa.id : null
          this.mainQuery.MLType = 1
          this.$store.dispatch('setMainLedgerStatus', 1)
        } else {
          this.mainQuery.InvoiceNumber = this.defQuery.BookID2 ? parseInt(this.defQuery.BookID2) : 0
          this.mainQuery.partnerId = this.selectPa.id ? this.selectPa.id : null
          this.mainQuery.MLType = 2
          this.$store.dispatch('setMainLedgerStatus', 2)
        }
 
        const locDocument = (await MainLedgerService.find(this.mainQuery)).data.mainLedger
        if (locDocument) {
          this.found = true
          if (locDocument.length === 1) {
            this.mainledger = locDocument[0]
            this.locCreatedAt = dayjs(this.mainledger.InvoiceDate).format('DD.MM.YYYY')
            this.locUpdatedAt = dayjs(this.mainledger.InvoicePaymentDeadlineDate).format('DD.MM.YYYY')
            this.locBookingDate = dayjs(this.mainledger.BookingDate).format('DD.MM.YYYY')
            this.locInvoiceDate = dayjs(this.mainledger.InvoiceDate).format('DD.MM.YYYY')
            this.locInvoicePaymentDeadlineDate = dayjs(this.mainledger.InvoicePaymentDeadlineDate).format('DD.MM.YYYY')
            this.locBookID = parseInt(this.mainledger.BookID)
          } else {
            this.items = [...locDocument]
            this.card = true
            this.found = false
          }
        } else {
          this.text = side === 1 ? 'Error! Cannot find Ura with these number ' : 'Error! Cannot find Ira with these number '
          this.color = 'red'
          this.snackbar = true
          this.mainledger = {}
        }        
      } catch(err) {
        this.found = false
        this.error = err
        this.text = 'Error! Cannot find document with these number ' + err.data
        this.color = 'red'
        this.snackbar = true
        this.mainledger = {}
      }
    },
    async mainledgerlist () {
      try {
        await this.$router.push({
          name: 'mainledger'
        })
      } catch (err) {
        this.error = err.data
        // eslint-disable-next-line
        console.log(err)
      }
    },
    print(){
      this.initPayPrint()
      this.$refs.prnt.print()
    },
    initPayPrint() {
      this.pay = {}
      this.pay.OIB = this.$store.state.companyActiv.CompanyVATID ? this.$store.state.companyActiv.CompanyVATID : ''
      this.pay.Naziv = this.$store.state.companyActiv.CompanyName ? this.$store.state.companyActiv.CompanyName : ''
      this.pay.Mjesto = (this.$store.state.companyActiv.CompanyPostcode ? this.$store.state.companyActiv.CompanyPostcode : '') + ' ' +this.$store.state.companyActiv.CompanyCity ? this.$store.state.companyActiv.CompanyCity : ''
      this.pay.Ulica = this.$store.state.companyActiv.CompanyStreet ? this.$store.state.companyActiv.CompanyStreet : ''
      this.pay.Broj = this.$store.state.companyActiv.CompanyBuildingNameNumber ? this.$store.state.companyActiv.CompanyBuildingNameNumber : ''
      this.pay.DodatakKucnomBroju = ''
      this.pay.Ime = this.$store.state.user.First ? this.$store.state.user.First : ''
      this.pay.Prezime = this.$store.state.user.Last ? this.$store.state.user.Last : ''
      this.pay.Ukupno = {}
      this.pay.TotalVAT3Base = this.TotalVAT3Base ? this.TotalVAT3Base :  0
      this.pay.TotalVAT2Base = this.TotalVAT2Base ? this.TotalVAT2Base :  0
      this.pay.TotalVAT1Base = this.TotalVAT1Base ? this.TotalVAT1Base : 0
      this.pay.Items = this.items ? this.items.slice() : []
    },
    round(value, exp) {
      if (typeof exp === 'undefined' || +exp === 0)
        return Math.round(value)

      value = +value
      exp = +exp

      if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split('e')
      value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)))

      // Shift back
      value = value.toString().split('e')
      return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp))
    },
  },
  computed: {
    ...mapGetters({
      myMainLedgerItem: 'NeedMainLedgerItem'
    }),
    formIsValid () {
      return this.mainledger.email !== '' &&
        this.mainledger.First !== '' &&
        this.mainledger.Last !== '' &&
        this.mainledger.InvoiceDate !== '' &&
        this.mainledger.BookingDate !== ''
    }
  },
  watch: {
    'myMainLedgerItem' () {
      this.found = true
      this.mainledger = this.$store.state.mainledgerItem
      this.locCreatedAt = dayjs(this.mainledger.InvoiceDate).format('DD.MM.YYYY')
      this.locUpdatedAt = dayjs(this.mainledger.InvoicePaymentDeadlineDate).format('DD.MM.YYYY')
      this.locBookingDate = dayjs(this.mainledger.BookingDate).format('DD.MM.YYYY')
      this.locInvoiceDate = dayjs(this.mainledger.InvoiceDate).format('DD.MM.YYYY')
      this.locInvoicePaymentDeadlineDate = dayjs(this.mainledger.InvoicePaymentDeadlineDate).format('DD.MM.YYYY')
      this.locBookID = parseInt(this.mainledger.BookID)
      this.items = []
      this.card = false
    },

  },
  components: {
    MainLedgerPayList,
    MainLedgerPayPrint
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
