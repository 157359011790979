import Api from '@/services/Api'
// import { log } from 'util';

export default {
  index(search) {
    return Api().get('mainledger', {
      params: search
    })
  },
  indexlist(search) {
    return Api().get('mainledger/list', {
      params: search
    })
  },
  indexvat(query) {
    return Api().get('mainledger/vat', {
      params: query
    })
  },
  indexbs(query) {
    return Api().get('mainledger/bs', {
      params: query
    })
  },
  indexbsdetails(query) {
    return Api().get('mainledger/bsdetails', {
      params: query
    })
  },
  show(mainledgerid) {
    return Api().get(`mainledger/${mainledgerid}`)
  },
  showvat(mainledgerid) {
    return Api().get(`mainledgervat/${mainledgerid}`)
  },
  post(newmainledger) {
    return Api().post('mainledger', newmainledger)
  },
  postbulk(newmainledgers) {
    return Api().post('mainledger/bulk', newmainledgers)
  },
  postvat(newmainledger) {
    return Api().post('mainledgervat', newmainledger)
  },
  put(mainledger) {
    return Api().put(`mainledger/${mainledger.id}`, mainledger)
  },
  putvat(mainledger) {
    return Api().put(`mainledgervat/${mainledger.id}`, mainledger)
  },
  updatemainledgers(items) {
    if (items) {
      return Api().post('mainledger/updatemainledgers', items)
    }
    return
  },
  delete(mainledgerid) {
    return Api().delete(`mainledger/${mainledgerid}`)
  },
  deletevat(mainledgerid) {
    return Api().delete(`mainledgervat/${mainledgerid}`)
  },
  find(query) {
    return Api().get('mainledger/find', {
      params: query
    })
  },
  findlatest(query) {
    return Api().get('mainledger/latest', {
      params: query
    })
  },
  choose(query) {
    return Api().get('mainledger/choose', {
      params: query
    })
  },
  choosevat(query) {
    return Api().get('mainledgervat/choose', {
      params: query
    })
  },
  choosenext(query) {
    return Api().get('mainledger/choosenext', {
      params: query
    })
  },
  makesum(query) {
    return Api().get('mainledger/makesum', {
      params: query
    })
  },
  syncbooks(query) {
    return Api().get('mainledger/syncbooks', {
      params: query
    })
  },
  testbooks(query) {
    return Api().get('mainledger/testbooks', {
      params: query
    })
  },
  sumby(query) {
    return Api().get('mainledger/sumby', {
      params: query
    })
  },
  sumbyvat(query) {
    return Api().get('mainledger/sumbyvat', {
      params: query
    })
  },
  books(query) {
    return Api().get('mainledger/books', {
      params: query
    })
  },
  createvatform(data) {
    return Api().post(`mainledger/createvatform`, data)
  },
  uploadvatform(query) {
    return Api().get(`mainledger/uploadvatform`, {
      params: query
    }, {
      responseType: 'blob'
    })
  },
  createbook(data) {
    return Api().post(`mainledger/createbook`, data)
  },
  uploadbook(query) {
    return Api().get(`mainledger/uploadbook`, {
      params: query
    }, {
      responseType: 'blob'
    })
  },
  showPdf(query) {
    return Api().get(`mainledger/showpdf`, {
      params: query
    }, {
      responseType: 'blob'
    })
  },
  readbsml(query) {
    return Api().get(`mainledger/readbsml`, {
      params: query
    })
  },
  readxmlbs(query) {
    return Api().get(`mainledger/readxmlbs`, {
      params: query
    })
  },
  readxmleracun(query) {
    return Api().get(`mainledger/readxmleracun`, {
      params: query
    })
  },
  readpulsml(query) {
    return Api().get(`mainledger/readpulsml`, {
      params: query
    })
  },
  readexcel(query) {
    return Api().get(`mainledger/readexcel`, {
      params: query
    })
  },
  saveitems(items) {
    if (items) {
      return Api().post('mainledgerside/saveitems', items)
    }
    return
  },
  checkifexist(query) {
    return Api().get(`mainledger/checkifexist`, {
      params: query
    })
  },
}