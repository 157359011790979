import {round, dynamicSortMultiple} from '@/snippets/allSnippets'
import SalarieService from '@/services/SalarieService'

async function freeReport (companyid,fromDate, toDate, typeId, userId) {

  const newObj = {}
  const empF =[]

  newObj.CompanyId = companyid
  // newObj.fromDate = '2024-01-01' 
  // newObj.toDate = '2024-12-31'
  // newObj.typeId = 1
  newObj.fromDate = fromDate
  newObj.toDate = toDate
  newObj.typeId = typeId
  newObj.userId = userId && userId.id ? userId.id : null
  await SalarieService.choosedetail(newObj)
    .then(res => {
      const salH = res && res.data && res.data.salaries ? res.data.salaries : null
      if (salH) {
        salH.map(head=> {
          if (head && head.SalarieDetails) {
            const BrojJoppd = head.BrojJoppd
            const JoppdDate = head.JoppdDate
            const JoppdPoslanDate = head.JoppdPoslanDate
            const VrstaObracuna = head.FreeF7 ? head.FreeF7 : ''
            

            head.SalarieDetails.map (det => {
                

                const dodIz1 = det.FinKoef1 && det.FinKoef1 !== 0 ? round(det.FinKoef1) : 0
                const dodIz2 = det.FinKoef2 && det.FinKoef2 !== 0 ? round(det.FinKoef2) : 0
                const dodIz = dodIz1 + dodIz2
                const dopIz1 = det.FinFrom1_Amount && det.FinFrom1_Amount !== 0 ? round(det.FinFrom1_Amount,2) : 0
                const dopIz2 = det.FinFrom2_Amount && det.FinFrom2_Amount !== 0 ? round(det.FinFrom2_Amount, 2) : 0
                const dopIz3 = det.FinFrom3_Amount && det.FinFrom3_Amount !== 0 ? round(det.FinFrom3_Amount,2) : 0
                const dopIz4 = det.FinFrom4_Amount && det.FinFrom4_Amount !== 0 ? round(det.FinFrom4_Amount,2) : 0
                const dopIz = dopIz1 + dopIz2 + dopIz3 +dopIz4
                const neo1 = det.FinAdd1_Amount && det.FinAdd1_Amount !== 0 ? round(det.FinAdd1_Amount,2) : 0
                const neo2 = det.FinAdd2_Amount && det.FinAdd2_Amount !== 0 ? round(det.FinAdd2_Amount,2) : 0
                const neo3 = det.FinAdd3_Amount && det.FinAdd3_Amount !== 0 ? round(det.FinAdd3_Amount,2) : 0
                const neo4 = det.FinAdd4_Amount && det.FinAdd4_Amount !== 0 ? round(det.FinAdd4_Amount,2) : 0
                const neoT = neo1 + neo2 + neo3 + neo4
                const obu1 = det.Fin1LoanBankAmount !== 0 ? round(det.Fin1LoanBankAmount,2) : 0
                const obu2 = det.Fin2LoanBankAmount !== 0 ? round(det.Fin2LoanBankAmount,2) : 0
                const obu3 = det.Fin3LoanBankAmount !== 0 ? round(det.Fin3LoanBankAmount,2) : 0
                const obuT = obu1 + obu2 + obu3


                const haveEmp = {}
                haveEmp.VrstaObracuna = VrstaObracuna
                haveEmp.eemail = det.eemail ? det.eemail : ''
                haveEmp.First = det.First ? det.First : ''
                haveEmp.Last = det.Last ? det.Last : ''
                haveEmp.FullName = (det.Last ? det.Last + ' ' : '') + (det.First ? det.First : '')
                haveEmp.VatID = det.VatID ? det.VatID : null
                haveEmp.Order = det.Order ? det.Order : null
                haveEmp.Month = det.Month ? det.Month : null
                haveEmp.Year = det.Year ? det.Year : null
                haveEmp.salarieID = det.salarieID ? det.salarieID : null
                haveEmp.BrojJoppd = BrojJoppd
                haveEmp.JoppdDate = JoppdDate
                haveEmp.JoppdPoslanDate = JoppdPoslanDate
                haveEmp.FinBruto1 = round(det.FinBruto1 ,2)
                haveEmp.dodIz = round(dodIz ,2)
                haveEmp.FinBruto2 = round(det.FinBruto2 ,2)
                haveEmp.dopIz = round(dopIz ,2)
                haveEmp.FinNeto1ls = round(det.FinNeto1ls ,2)
                haveEmp.FinFreeTotalToUse = round(det.FinFreeTotalToUse ,2)
                haveEmp.FinTax1Am = round(det.FinTax1Am ,2)
                haveEmp.FinTax12Am = round(det.FinTax12Am ,2)
                haveEmp.FinNeto2 = round(det.FinNeto2 ,2)
                haveEmp.neoT = round(neoT ,2)
                haveEmp.obuT = round(obuT ,2)
                haveEmp.FinNeto3 = round(det.FinNeto3 ,2)
                haveEmp.FinBruto3 = round(det.FinBruto3 ,2)

                const rr = round((det.RedovanRad ? det.RedovanRad : 0) ,2)
                const pr = round((det.PrekovremeniRad ? det.PrekovremeniRad : 0) ,2)
                const od = round((det.GodisnjiOdmor ? det.GodisnjiOdmor : 0) ,2)  + round((det.PlaceniDopust ? det.PlaceniDopust : 0 ),2) + round((det.NeradniBlagdan ? det.NeradniBlagdan : 0 ) ,2) + round((det.BolovanjeDo42Dana ? det.BolovanjeDo42Dana : 0 ),2) 
                haveEmp.satiPunoRv = rr
                haveEmp.satiPrekovremeni = pr
                haveEmp.satiOdsutni = od
                haveEmp.satiUkupno = round(rr+ pr + od ,2)
                haveEmp.satiNaHZZO = round(det.BolovanjeOd42Dana ,2)
                haveEmp.satiKalendar = round(det.FinWH ,2)
                haveEmp.DateOfBirht = det.DateOfBirht ? det.DateOfBirht : null
                haveEmp.PersonalID2 = det.PersonalID2 ? det.PersonalID2 : null


                empF.push(haveEmp)
            }) 
          }
        })
        empF.sort(dynamicSortMultiple("FullName", "VatID"))
        console.log('empF', empF)
      }
      
    })
    .catch(err => {
      console.log('no salaries ', err)
      return err
    })
    return empF
}

export {freeReport}

