<template>
<div>
  <v-container fluid grid-list-md>
    <v-row>
      <app-account-choose></app-account-choose>
      <app-Account1-CRUD></app-Account1-CRUD>
    </v-row>
  </v-container>
  </div>
</template>

<script>
import Account1CRUD from './Account1CRUD'
import AccountChoose from './AccountChoose'

export default {
  name: 'account1',
  data () {
    return {
      msg: ''
    }
  },
  mounted () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  },
  components: {
    appAccount1CRUD: Account1CRUD,
    appAccountChoose: AccountChoose
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
