import Api from '@/services/Api'
// import { log } from 'util';

export default {
  index (search) {
    return Api().get('taskdepartment', {
      params: {
        search: search
      }
    })
  },
  show (taskdepartmentid) {
    return Api().get(`taskdepartment/${taskdepartmentid}`)
  },
  post (newtaskdepartment) {
    return Api().post('taskdepartment', newtaskdepartment)
  },
  put (taskdepartment) {
    return Api().put(`taskdepartment/${taskdepartment.id}`, taskdepartment)
  },
  delete (taskdepartmentid) {
    return Api().delete(`taskdepartment/${taskdepartmentid}`)
  },
  choose (query) {
    return Api().get('taskdepartment/choose', { params: query })
  }
}
