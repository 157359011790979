<template>
  <v-container fluid>
    <span v-if="show"><SelectCompensation /></span>
    <v-row class="mb-3">
      <v-col cols="12" sm="12" xs="12" md="4"  lg="2" xl="2">
        <h2>{{lang.Salarie}}</h2>
      </v-col>
      <v-col cols="12" sm="3" xs="6" md="2" lg="2" xl="2">
        <v-btn
            class="primary"
            @click="reports">
            Izvještaji
          </v-btn>
      </v-col>
      <v-col cols="12" sm="3" xs="6" md="2" lg="2" xl="2">

      </v-col>
       <v-progress-circular
        v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
      <v-col cols="12" sm="6" xs="6" md="1" lg="1" xl="1">
          <v-select
              label="Mjesec"
              :items="locMonths"
              v-model="monthSelected"
              single-line
              persistent-hint
              clearable
              dense
            ></v-select>
        </v-col>
        <v-col cols="12" sm="6" xs="6" md="1" lg="1" xl="1">
            <v-select
              label="Godina"
              :items="locYears"
              v-model="yearSelected"
              single-line
              return-object
              persistent-hint
              clearable
              dense
            ></v-select>
      </v-col>
      <v-col cols="12" sm="3" xs="6" md="2" lg="2" xl="2">
        <v-btn
            class="primary"
            @click="fillMonth">
            {{lang.Create}}
          </v-btn>
      </v-col>
    </v-row>

    <v-row no-gutters class="pa-0">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <SalarieList :key="refresh"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
        <v-btn text @click="salarietaxfree" class="mr-1">
            Baza neoporezivih primitaka {{$store.state.businessYear}}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import langEn from './salarieDescEn'
import langHr from './salarieDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import SalarieList from './SalarieList'
import {months, years} from '@/snippets/allSnippets.js'
import SalarieService from '@/services/SalarieService'
import CalendarService from '@/services/CalendarService'
import WorkHourService from '@/services/WorkHourService'
import BankAccountService from '@/services/BankAccountService'
import SelectCompensation from './SelectCompensation'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'salarie',
  data () {
    return {
      msg: '',
      lang: {},
      langC: {},
      locMonths: [],
      locYears: [],
      monthSelected: null,
      yearSelected: null,
      empSelected: null,
      allEmp: [],
      newWH: {},
      newWorkHours: [],
      mainQuery: {},
      days:[],
      refresh: 0,
      saving: false,
      postNew: false,
      workDays: 0,
      holiDays: 0,
      salarieID: null,
      updateObj: {},
      banks: [],
      selectBank: {},
      show: false
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    if (this.$store.state.businessYear) {
      this.mainQuery.businessYear = this.$store.state.businessYear
    }
    this.locMonths = months()
    this.locYears = years()
    const nD = new Date()
    this.monthSelected = nD.getMonth()
    this.yearSelected = nD.getFullYear()
    this.banks = (await BankAccountService.choose(this.mainQuery)).data.bankAccounts
    this.$store.dispatch('setTikTok', 1)

  },
  methods: {
    reports () {
      console.log('imam reports', this.$store.state.tikTok)
      if (this.$store.state.tikTok > 1) {
        this.show = !this.show
      }
      if (this.$store.state.tikTok === 1) {
        this.show = !this.show
      }
    },
    salarietaxfree() {
      this.$router.push({
        name: 'salarietaxfree'
      })
    },
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
    async fillMonth() {
      const warningMessage = this.monthSelected + '/' + this.yearSelected
      if (confirm(this.langC.AreYouShureToCreate + ' ' + warningMessage + '?')) {
        this.mainQuery.ForMonth = this.monthSelected
        this.mainQuery.ForYear = this.yearSelected
        const {data} = await CalendarService.index2(this.mainQuery)
        
        if (data && data.calendars !== undefined) {
          this.days = data.calendars
          this.workDays = 0
          this.holiDays = 0
          this.days.map(day => {              
              if (day.CalWorkDay === 'W') {
                this.workDays += 1
              }
              if (day.CalWorkDay === 'P') {
                this.holiDays += 1
              }
            })
        }
        console.log('1')

        // find last number 
        let lastNr = 0
        const query = {}
        query.CompanyId = this.$store.state.companyid
        query.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2022
        
        await SalarieService.index(query)
        .then(res => {
          const allSalaries = res && res.data &&  res.data.salaries ? res.data.salaries : []
          if (allSalaries) {
            allSalaries.map(salarie => {
              if (salarie.Order && salarie.Order > lastNr) {
                lastNr = salarie.Order
              }
            })
          }
        })
        .catch(err => {
          console.log('no salaries ', err)
        })
 
        const joppddate = dayjs().format('YYYY-MM-DD')
        // const y = joppddate.substr(0,4).substr(2,2)
        // const day = dayjs(joppddate).dayOfYear()
        const now = new Date(joppddate);
        const start = new Date(now.getFullYear(), 0, 0);
        const diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
        const oneDay = 1000 * 60 * 60 * 24;
        const day = Math.floor(diff / oneDay);

        const y = new Date().getFullYear().toString().substring(2)
        let newDay = day.toString()
        if (day.toString().length === 1) {
          newDay = '00' + day.toString()
        }
        if (day.toString().length === 2) {
          newDay = '0' + day.toString()
        }
        const joppd =  y + newDay
    
        const def = {}
        def.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        def.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
        def.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2022
        def.UserId = this.$store.state.user && this.$store.state.user.id ? this.$store.state.user.id : ''
        def.Order = lastNr + 1
        def.Month = this.monthSelected
        def.Year = this.yearSelected
        def.NumberOfEmp = 0
        def.HoursNormal = this.workDays * 8
        def.HoursHoliday = this.holiDays * 8
        def.HoursOther = 0

        if (this.banks && this.banks.length > 0) {
          this.selectBank = this.banks ? this.banks.find(bank => (bank.DefaultAccount === true)) : {}
        }
        if (this.banks.length === 0) {
          alert('Error! Bank accounst are not set. Greška! Molim da upišete žiro račun u postavke!')
          return
        }
        if (this.selectBank === null || Object.keys(this.selectBank).length === 0) {
          alert('Error! Default bank accounst it not set. Greška! Nije određen default žiro račun!')
          return
        }
        def.bankAccountId = this.selectBank && this.selectBank.id ? this.selectBank.id : null
        def.bankAccountName = this.selectBank && this.selectBank.BankName ? this.selectBank.BankName : null
        def.bankAccountNr = this.selectBank && this.selectBank.IBAN ? this.selectBank.IBAN : null
        def.bankPaymentDate = dayjs().format('YYYY-MM-DD')
        def.BrojJoppd = joppd
        def.JoppdDate = dayjs().format('YYYY-MM-DD')

        this.salarieID = null
        const res3 = await SalarieService.post(def)
        this.updateObj = res3.data.newSalarie ? res3.data.newSalarie : {}

        if (res3 && res3.data && res3.data.newSalarie) {
          this.salarieID = res3.data.newSalarie.id
        }
        this.saving = false
        this.refresh = this.getRndInteger(1000000000, 9000000000)
      }
    },
    async checkEmployee() {
        this.postNew = false
        const checkEmp = {
          EmployeeId: this.empSelected,
          ForMonth: this.monthSelected,
          ForYear: this.yearSelected,
          CompanyId : this.$store.state.companyid ? this.$store.state.companyid : ''
        }
        await WorkHourService.empmonth(checkEmp)
        .then(res => {
          if (res.data && res.data.workhours !== undefined && res.data.workhours.length > 0){
            this.postNew = false
          } else {
            this.postNew = true
          }
        }).catch(err => {
          this.postNew = true
          // eslint-disable-next-line
          console.log('Check  emp post NEW', err)
        })
    },


  },
  computed: {
    ...mapGetters({
      myTikTok: 'NeedTikTok'
    })
  },
  watch: {
    'myTikTok' () {
      console.log(' imam novi state', this.myTikTok)
      if (this.$store.state.tikTok > 1){
        this.reports()
      }
    },
    'show' () {
      console.log('this.show on watch', this.show)
    }
  },
  components: {
    SalarieList,
    SelectCompensation
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
