<template>
  <div class="worhhourprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './workhourDescEn'
import langHr from './workhourDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'

export default {
  name: 'mainledgerprint',
  props: ['wh'],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    
  },
  methods: {
    async print () {
      await this.initDocument()
      await this.addHeaderNoLogo()
      await this.addEmptyRow()
      await this.addLine()
      await this.addItemsHeader()
      await this.addLine()
      await this.addItems()
      await this.addLine()
 //     await this.addItemsFoter()
      await this.addLine()
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'landscape'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.content = []
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatPrice1 (value) {
      // eslint-disable-next-line
      console.log(' value  ', value, typeof value)
      if (parseFloat(value) !== 0) {
        if (this.$store.state.language === 'EN') {
          let val = (value / 1).toFixed(1).replace('.', ',')
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        }
        if (this.$store.state.language === 'HR') {
          let val = (value / 1).toFixed(1).replace('.', ',')
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        }
      }
      return ''
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 800,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '35%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text:  'Obračun radnih sati' + ' za ' + this.wh.ForMonth + '/' + this.wh.ForYear + '\n', fontSize: 18, bold: true },
                  { text:  this.wh.First + ' ' + this.wh.Last +  '\n', fontSize: 18, bold: true },
                  { text:  'email: ' + this.wh.email  , fontSize:10, bold: true}
                ]   
              },
              {
                // % width
                width: '20%',
                text: [                 
                  { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addItemsHeader () {
        const docPart = {
        columns: [
            {
              width: '6%',
              fontSize: 8,
              alignment: 'left',
              text: this.lang.WDate
            },
            {
              width: '7%',
              fontSize: 8,
              alignment: 'left',
              text: this.lang.WDay
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'left',
              text: this.lang.PocetakRada
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.ZavrsetakRada
            },
            {
              width: '4%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.TotalHours
            },
            {
              width: '4%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.RedovanRad
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.NocniRad
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.PrekovremeniRad
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.NedjeljaRad
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.BlagdanRad
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.TerenRad
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.PreraspodjelaRada
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.Pripravnost
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.DnevniOdmor
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.TjedniOdmor
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.NeradniBlagdan
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.GodisnjiOdmor
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.BolovanjeDo42Dana
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.BolovanjeOd42Dana
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              bold: true,
              text: this.lang.Rodiljni
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.PlaceniDopust
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.NeplaceniDopust
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.NenazocnostNaZahtjevRadnika
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.ZastojKrivnjomPoslodavca
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.Strajk
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.Lockout
            },
            {
              width: '3%',
              fontSize: 7,
              alignment: 'center',
              text: this.lang.VrijemeMirovanjaRadnogOdnosa
            }
            ]
          }
        this.content.push(docPart)        
    },
    addItems () {
            this.order = 0           
            this.expitems = this.wh.items ? this.wh.items: []
            
            if (this.expitems) {
              this.expitems.map(item => {
                  this.order += 1
                  // this.pTtt5 = item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0
                  // this.pTtt6 = item.itemDiscountPercentage1 ? this.formatPrice(item.itemDiscountPercentage1) : 0
                  // this.pTtt7 = item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0
                  // this.pTtt8 = item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0
                  // this.pTtt9 = item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0
                  // eslint-disable-next-line
                  console.log(' print item  ', item)
                  const docPart = {
                  columns: [
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'left',
                          text: dayjs(item.WDate).format('DD.MM.YYYY')
                        },
                        {
                          width: '7%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.WDay
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'left',
                          text: this.formatPrice(item.PocetakRada)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.ZavrsetakRada)
                        },
                        {
                          width: '4%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.Ukupno)
                        },
                        {
                          width: '4%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.RedovanRad)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.NocniRad)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.PrekovremeniRad)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.NedjeljaRad)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.BlagdanRad)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.TerenRad)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.PreraspodjelaRada)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.Pripravnost)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.DnevniOdmor)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.TjedniOdmor)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.NeradniBlagdan)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.GodisnjiOdmor)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.BolovanjeDo42Dana)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.BolovanjeOd42Dana)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          bold: true,
                          text: this.formatPrice1(item.Rodiljni)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.PlaceniDopust)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.NeplaceniDopust)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.NenazocnostNaZahtjevRadnika)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.ZastojKrivnjomPoslodavca)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.Strajk)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.Lockout)
                        },
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice1(item.VrijemeMirovanjaRadnogOdnosa)
                        }
                      ]
                    }
                  this.content.push(docPart)
                }
              )
          }
    },
    addItemsFoter () {
        const docPart = {
        columns: [
              {
                width: '4%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '13%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ira.TotalInvoiceAmount)
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ira.TotalVAT4Per)
              },
              {
                width: '4%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ira.TotalVAT4Base)
              },
              {
                width: '4%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ira.TotalVAT4Amount)
              },
              {
                width: '4%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ira.TotalVAT4PerCanUse)
              },
              {
                width: '4%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ira.TotalVAT4CanUse)
              },
              {
                width: '4%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ira.TotalVAT4CanTUse)
              },
              {
                width: '4%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ira.TotalVAT5Per)
              },
              {
                width: '4%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ira.TotalVAT5Base)
              },
              {
                width: '4%',
                fontSize: 8,
                alignment: 'right',
                bold: true,
                text: this.formatPrice(this.ira.TotalVAT5Amount)
              },
              {
                width: '4%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ira.TotalVAT5PerCanUse)
              },
              {
                width: '4%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ira.TotalVAT3Base)
              },
              {
                width: '4%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ira.TotalVAT3Amount)
              },
              {
                width: '4%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ira.TotalVAT2Base)
              },
              {
                width: '4%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ira.TotalVAT2Amount)
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ira.TotalVAT1Base)
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ira.TotalVAT1Amount)
              }
            ]
          }
        this.content.push(docPart)        
    },
  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>