<template>
  <div class="mainledgerprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'

export default {
  name: 'mainledgerprint',
  props: [],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0,
      bussYear: 0,
      vat: null
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    
  },
  methods: {
    async print (vat) {
      const newObj = {}
      this.vat = {...newObj}
      if (vat) {
        this.vat = {...vat}
      }
      console.log('Printam VAT')
      this.bussYear = this.$store.state.businessYear ? parseInt(this.$store.state.businessYear) : 2022
      this.initDocument()
      this.addHeaderNoLogo()
      this.addEmptyRow()
      this.addLine()
      this.addItemsHeader()
      this.addLine()
      this.addItems1()
      this.addLine()
      this.addItems2()
      this.addLine()
      this.addItems3()
      this.addLine()
      console.log('this.bussYear', this.bussYear)
      if (this.bussYear <= 2022) {
        this.addItems4()
        this.addLine()
        this.addItems5()
      }
      if (this.bussYear > 2022) {
        this.addItems401()
        this.addItems41()
        this.addLine()
       // this.addItems5()
      }
      this.addLine()
      this.addEmptyRow()
      this.addTotal()
      this.addEmptyRow()
      this.addSignature1()
      this.addEmptyRow()
      this.addSignature2()

      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.content = []
      // this.docDefinition.header = {
      //   columns: [
      //       {
      //           fontSize: 9,
      //           text:[
      //           {
      //           text: + '\n',               
      //           },
      //           {
      //           margin: [10, 10],
      //           text: 'Obrazac PDV ',
      //           }
      //           ],
      //           alignment: 'right'
      //       }
      //   ]
      // }
      // this.docDefinition.footer = function(currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
      // this.docDefinition.header = function(currentPage, pageCount, pageSize) {
      //     // you can apply any logic and return any valid pdfmake element
      //     return [
      //       { text: 'simple text', alignment: (currentPage % 2) ? 'left' : 'right' },
      //       { canvas: [ { type: 'rect', x: 170, y: 32, w: pageSize.width - 170, h: 40 } ] }
      //     ]
      //   },
      // this.footer: function(currentPage, pageCount) {
      //   return {
      //       margin:10,
      //       columns: [
      //       {
      //           fontSize: 9,
      //           text:[
      //           {
      //           text: '--------------------------------------------------------------------------' +
      //           '\n',
      //           margin: [0, 20]
      //           },
      //           {
      //           text: '© xyz pvt., ltd. ' + currentPage.toString() + ' of ' + pageCount,
      //           }
      //           ],
      //           alignment: 'center'
      //       }
      //       ]
      //   }
      // },
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 500,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '30%',
                text: [
                  { text: 'Porezni obveznik\n', fontSize: 12, bold: true },
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '30%',
                text: [
                  { text: 'Porezni zastupnik\n', fontSize: 12, bold: true },
                  { text: ' \n', fontSize: 10, bold: true },
                  { text: ' \n', fontSize: 8 },
                ]
              },

              {
                // % width
                width: '30%',
                text: [
                  { text: 'Nadležna ispostava\n', fontSize: 12, bold: true },
                  { text: ' \n', fontSize: 10},
                  { text: ' \n', fontSize: 10},
                  { text: 'Prijava PDVa za razdoblje\n', fontSize: 12, bold: true },                 
                  { text: 'Od datuma:' + dayjs(this.vat.fromDate).format('DD.MM.YYYY') + '\n', fontSize:8},
                  { text: 'Do datuma:' + dayjs(this.vat.toDate).format('DD.MM.YYYY'), fontSize:8}
                ]   
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addItemsHeader () {
        const docPart = {
        columns: [
              {
                width: '40%',
                fontSize: 8,
                alignment: 'center',
                text: 'OPIS\n', bold: true
              },
              {
                width: '25%',
                fontSize: 8,
                alignment: 'center',
                text: 'Porezna osnovica\n', bold: true
              },
              {
                width: '25%',
                fontSize: 8,
                alignment: 'center',
                text: 'PDV po stopi 5%,13% i 25%\n', bold: true
              },
            ]
          }
        this.content.push(docPart)        
    },
    addItems1 () {
      const docPart1 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text: this.lang.Podatak000d + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak000) + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart1)
        const docPart2 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak100d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak100) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart2) 
        const docPart3 = {
        columns: [
              {width: '6%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '34%',fontSize: 8, alignment: 'left', text: this.lang.Podatak101d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak101) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart3) 
        const docPart4 = {
        columns: [
              {width: '6%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '34%',fontSize: 8, alignment: 'left', text: this.lang.Podatak102d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak102) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart4) 
        const docPart5 = {
        columns: [
              {width: '6%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '34%',fontSize: 8, alignment: 'left', text: this.lang.Podatak103d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak103) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart5) 
        const docPart6 = {
        columns: [
              {width: '6%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '34%',fontSize: 8, alignment: 'left', text: this.lang.Podatak104d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak104) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart6)
        const docPart7 = {
        columns: [
              {width: '6%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '34%',fontSize: 8, alignment: 'left', text: this.lang.Podatak105d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak105) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart7) 
        const docPart8 = {
        columns: [
              {width: '6%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '34%',fontSize: 8, alignment: 'left', text: this.lang.Podatak106d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak106) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart8) 
        const docPart9 = {
        columns: [
              {width: '6%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '34%',fontSize: 8, alignment: 'left', text: this.lang.Podatak107d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak107) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart9) 
        const docPart10 = {
        columns: [
              {width: '6%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '34%',fontSize: 8, alignment: 'left', text: this.lang.Podatak108d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak108) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart10) 
        const docPart11 = {
        columns: [
              {width: '6%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '34%',fontSize: 8, alignment: 'left', text: this.lang.Podatak109d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak109) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart11) 
        const docPart12 = {
        columns: [
              {width: '6%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '34%',fontSize: 8, alignment: 'left', text: this.lang.Podatak110d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak110) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart12) 
    },
    addItems2 () {
      const docPart1 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text: this.lang.Podatak200d + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak200) + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez200) + '\n'},
            ]
          }
        this.content.push(docPart1) 
        const docPart3 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak201d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak201) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez201) + '\n'},
            ]
          }
        this.content.push(docPart3) 
        const docPart4 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak202d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak202) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez202) + '\n'},
            ]
          }
        this.content.push(docPart4) 
        const docPart5 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak203d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak203) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez203) + '\n'},
            ]
          }
        this.content.push(docPart5) 
        const docPart6 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak204d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak204) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez204) + '\n'},
            ]
          }
        this.content.push(docPart6)
        const docPart7 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak205d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak205) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez205) + '\n'},
            ]
          }
        this.content.push(docPart7) 
        const docPart8 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak206d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak206) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez206) + '\n'},
            ]
          }
        this.content.push(docPart8) 
        const docPart9 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak207d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak207) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez207) + '\n'},
            ]
          }
        this.content.push(docPart9) 
        const docPart10 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak208d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak208) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez208) + '\n'},
            ]
          }
        this.content.push(docPart10) 
        const docPart11 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak209d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak209) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez209) + '\n'},
            ]
          }
        this.content.push(docPart11) 
        const docPart12 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak210d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak210) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez210) + '\n'},
            ]
          }
        this.content.push(docPart12)
        const docPart13 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak211d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak211) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez211) + '\n'},
            ]
          }
        this.content.push(docPart13)
        const docPart14 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak212d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak212) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez212) + '\n'},
            ]
          }
        this.content.push(docPart14)
        const docPart15 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak213d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak213) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez213) + '\n'},
            ]
          }
        this.content.push(docPart15)
        const docPart16 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak214d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak214) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez214) + '\n'},
            ]
          }
        this.content.push(docPart16)
        const docPart17 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak215d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak215) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez215) + '\n'},
            ]
          }
        this.content.push(docPart17)
    },
    addItems3 () {
      const docPart1 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text: this.lang.Podatak300d + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak300) + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez300) + '\n'},
            ]
          }
        this.content.push(docPart1) 
        const docPart3 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak301d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak301) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez301) + '\n'},
            ]
          }
        this.content.push(docPart3) 
        const docPart4 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak302d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak302) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez302) + '\n'},
            ]
          }
        this.content.push(docPart4) 
        const docPart5 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak303d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak303) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez303) + '\n'},
            ]
          }
        this.content.push(docPart5) 
        const docPart6 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak304d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak304) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez304) + '\n'},
            ]
          }
        this.content.push(docPart6)
        const docPart7 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak305d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak305) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez305) + '\n'},
            ]
          }
        this.content.push(docPart7) 
        const docPart8 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak306d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak306) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez306) + '\n'},
            ]
          }
        this.content.push(docPart8) 
        const docPart9 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak307d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak307) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez307) + '\n'},
            ]
          }
        this.content.push(docPart9) 
        const docPart10 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak308d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak308) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez308) + '\n'},
            ]
          }
        this.content.push(docPart10) 
        const docPart11 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak309d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak309) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez309) + '\n'},
            ]
          }
        this.content.push(docPart11) 
        const docPart12 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak310d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak310) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez310) + '\n'},
            ]
          }
        this.content.push(docPart12)
        const docPart13 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak311d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak311) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez311) + '\n'},
            ]
          }
        this.content.push(docPart13)
        const docPart14 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak312d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak312) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez312) + '\n'},
            ]
          }
        this.content.push(docPart14)
        const docPart15 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak313d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak313) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez313) + '\n'},
            ]
          }
        this.content.push(docPart15)
        const docPart16 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak314d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak314) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Porez314) + '\n'},
            ]
          }
        this.content.push(docPart16)
        const docPart17 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak315d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak315) + '\n'},
            ]
          }
        this.content.push(docPart17)
    },
    addItems4 () {
      const docPart1 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text: this.lang.Podatak400d + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak400) + '\n'},
            ]
          }
        this.content.push(docPart1)
        const docPart2 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text: this.lang.Podatak500d + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak500) + '\n'},
            ]
          }
        this.content.push(docPart2)
        const docPart3 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text: this.lang.Podatak600d + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.vat.MLFree1 + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak600) + '\n'},
            ]
          }
        this.content.push(docPart3)
        const docPart4 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text: this.lang.Podatak700d + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak700) + '\n'},
            ]
          }
        this.content.push(docPart4) 
    },
    addItems401 () {
      const docPart1 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text: this.lang.Podatak400d + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak400) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n', bold: true},
            ]
          }
        this.content.push(docPart1)
        const docPart2 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text:  'V. IZNOS GODIŠNJEG RAZMJERNOG ODBITKA PRETPOREZA (%)\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak500) + '\n'},
            ]
          }
        this.content.push(docPart2)
    },
    addItems41 () {
      const docPart101 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text:  'VI. OSTALI PODACI\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
    this.content.push(docPart101)
      const docPart1 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text: this.lang.Podatak610 + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak610) + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart1)
        const docPart2 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text: this.lang.Podatak611 + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak611) + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart2)
        const docPart3 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text: this.lang.Podatak612 + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak612) + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart3)
        const docPart4 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text: this.lang.Podatak613 + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak613) + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart4)
        const docPart5 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text: this.lang.Podatak614 + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak614) + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart5)
        const docPart6 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text: this.lang.Podatak615 + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak615) + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart6)
        const docPart7 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text: this.lang.Podatak620 + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak620) + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart7)
        const docPart8 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text: this.lang.Podatak630 + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak630) + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart8)
        const docPart9 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text: this.lang.Podatak640 + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak640) + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart9)
        const docPart10 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text: this.lang.Podatak650 + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak650) + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart10)
        const descVAT = this.vat.Podatak660 ? 'DA' : 'NE'
        const docPart11 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text: this.lang.Podatak660 + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: descVAT +'\n'},
            ]
          }
        this.content.push(docPart11)

    },
    addItems5 () {
        const docPart1 = {
        columns: [
              {width: '40%',fontSize: 8, alignment: 'left', text: this.lang.Podatak000d + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak000) + '\n', bold: true},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart1)
        const docPart2 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak810d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak810) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart2) 
        const docPart3 = {
        columns: [
              {width: '6%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '34%',fontSize: 8, alignment: 'left', text: this.lang.Podatak811d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak811) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart3) 
        const docPart4 = {
        columns: [
              {width: '6%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '34%',fontSize: 8, alignment: 'left', text: this.lang.Podatak812d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak812) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart4) 
        const docPart5 = {
        columns: [
              {width: '6%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '34%',fontSize: 8, alignment: 'left', text: this.lang.Podatak813d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak813) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart5) 
        const docPart6 = {
        columns: [
              {width: '6%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '34%',fontSize: 8, alignment: 'left', text: this.lang.Podatak814d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak814) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart6)
        const docPart7 = {
        columns: [
              {width: '6%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '34%',fontSize: 8, alignment: 'left', text: this.lang.Podatak815d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak815) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart7) 
        const docPart8 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak820d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak820) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart8) 
        const docPart9 = {
        columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak830d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak830) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
          }
        this.content.push(docPart9) 
        const docPart10 = {
        columns: [
              {width: '6%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '34%',fontSize: 8, alignment: 'left', text: this.lang.Podatak831d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak831) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Broj831) + '\n'},
            ]
          }
        this.content.push(docPart10) 
        const docPart11 = {
        columns: [
              {width: '6%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '34%',fontSize: 8, alignment: 'left', text: this.lang.Podatak832d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podata832) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Broj832) + '\n'},
            ]
          }
        this.content.push(docPart11) 
        const docPart12 = {
          columns: [
              {width: '6%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '34%',fontSize: 8, alignment: 'left', text: this.lang.Podatak833d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak833) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Broj833) + '\n'},
            ]
        }
        this.content.push(docPart12)
        const docPart13 = {
          columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak840d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak840) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
        }
        this.content.push(docPart13) 
        const docPart14 = {
          columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak850d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak850) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
        }
        this.content.push(docPart14)
        const docPart15 = {
          columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak860d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: this.formatPrice(this.vat.Podatak860) + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
        }
        this.content.push(docPart15) 
        const docPart16 = {
          columns: [
              {width: '3%',fontSize: 8, alignment: 'left', text:'\n'},
              {width: '37%',fontSize: 8, alignment: 'left', text: this.lang.Podatak870d + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: (this.vat.Podatak870 ? '+' : '') + '\n'},
              {width: '25%',fontSize: 8, alignment: 'center', text: 'X X X X X\n'},
            ]
        }
        this.content.push(docPart16) 
    },

    addItemsFoter () {
        const docPart = {
        columns: [
              {
                width: '4%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
            
 
            ]
          }
        this.content.push(docPart)        
    },
    addTotal () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: (this.bussYear <=2022 ? 'Povrat Kn': 'Povrat EUR') + ': \n', fontSize: 10, alignment: 'center' },
                  { text: this.formatPrice(this.vat.Povrat2) + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: (this.bussYear <=2022 ? 'Predujam Kn': 'Predujam EUR') + '\n', fontSize: 10, alignment: 'center' },
                  { text: this.formatPrice(this.vat.Predujam2) + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: (this.bussYear <=2022 ? 'Ustup povrata Kn': 'Ustup povrata EUR') + '\n', fontSize: 10, alignment: 'center' },
                  { text: this.formatPrice(this.vat.UstupPovrata2) + ': \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },

    addSignature1 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: 'OBRAČUN SASTAVIO \n', fontSize: 10, alignment: 'center' },
                  { text: ' \n', fontSize: 10 },
                  { text: this.lang.DocPrintMadeBy + ' ' + (this.vat.First ? this.vat.First : ' ') + ' ' + (this.vat.Last ? this.vat.Last : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '25%',
                text: ' '
              },

              {
                width: '25%',
                text: [
                  { text: 'POTPIS \n', fontSize: 10, alignment: 'center' },
                  { text: ' \n', fontSize: 10 },
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addSignature2 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________  \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>