<template>
  <div class="bankaccount">
      <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
      <v-card>
        <v-card-title>
          {{ lang.Tasks }}
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search" 
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"            
          >
          <template v-slot:[`item.taskOpenDate`]="{ item }">
              <span>{{ localDate(item.taskOpenDate) }}</span>
          </template>
          <template v-slot:[`item.taskForDate`]="{ item }">
              <span>{{ localDate(item.taskForDate) }}</span>
          </template>
           <template v-slot:[`item.taskAlarmDate`]="{ item }">
              <span>{{ localDate(item.taskAlarmDate) }}</span>
          </template>
          <template v-slot:[`item.taskClosed`]="{ item }">
            <span v-if="item.taskClosed"><v-icon>mdi-check</v-icon></span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>
  </div>
</template>

<script>
// import axios from 'axios'
import TaskService from '@/services/TaskService'
import langEn from './taskDescEn'
import langHr from './taskDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dayjs from 'dayjs'

export default {
  name: 'tasklist',
  data () {
    return {
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [40],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        UserId: 1
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
    }
  },
  async mounted () {
    try {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
      this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
      this.mainQuery.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
      this.mainQuery.UserId = this.$store.state.user.id ? await this.$store.state.user.id : 0

      this.saving = true
      this.items = (await TaskService.index(this.mainQuery)).data.tasks
      this.saving = false
      this.headers.push(
        {
          text: 'User email',
          align: 'left',
          sortable: true,
          value: 'email', width: '150px'
        },
        { text: this.lang.partnerName, align: 'left', sortable: true, value: 'partnerName', width: '150px' },
        { text: this.lang.taskTypeDescription, align: 'left', value: 'taskTypeDescription', width: '150px' },
        { text: this.lang.taskDepartmentDescription, align: 'left', value: 'taskDepartmentDescription', width: '150px' },
        { text: this.lang.taskShortDescription, align: 'left', value: 'taskShortDescription',width: '150px' },
        { text: this.lang.taskOpenDate, align: 'center', sortable: true, value: 'taskOpenDate', width: '150px' },
        { text: this.lang.taskForDate, align: 'center', value: 'taskForDate', width: '150px' },
        { text: this.lang.taskAlarmDate, align: 'center', value: 'taskAlarmDate',width: '150px' },
        { text: this.lang.taskClosed, align: 'center', value: 'taskClosed', width: '50px' },
        { text: 'DbId', value: 'id' }
      )
 
    } catch (err) {
      // eslint-disable-next-line
      console.log(err)
    }
  },
  methods: {
    navigateTo (taskid) {
      this.$store.dispatch('seteditItem', 1)
      this.$store.dispatch('setTaskid', taskid)
      this.$router.push({
        name: 'taskcreate'
      })
    },
    localDate(dateToFormat) {
      return dayjs(dateToFormat).format('DD.MM.YYYY')
    }
  },
  computed: {

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
