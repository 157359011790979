import store from '../store/store'

const moneyFormat = (value) => {
  if (store.state.language === 'EN') {
    let val = (value / 1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }
  if (store.state.language === 'HR') {
    let val = (value / 1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }
}

export default moneyFormat