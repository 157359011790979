import Api from '@/services/Api'
// import { log } from 'util';

export default {
  index (search) {
    return Api().get('companyuser', {
      params: {
        search: search
      }
    })
  },
  show (companyuserid) {
    return Api().get(`companyuser/${companyuserid}`)
  },
  post (newcompany) {
    return Api().post('companyuser', newcompany)
  },
  put (company) {
    return Api().put(`companyuser/${company.id}`, company)
  },
  delete (companyuserid) {
    return Api().delete(`companyuser/${companyuserid}`)
  },
  choose (query) {
    return Api().get('companyuser/choose', {
      params: query
    })
  }
}
