const docEN = {
  Serials: 'Serial numbers',
  Serial: 'Serial numner',
  documentName: 'Document Name',
  documentSide: 'Document Side',
  documentId: 'Document Id',
  documentIdGeneral: 'Document General',
  documentDate: 'Document Date',
  documentTime: 'Time',
  partnerId: 'Partner ID',
  partnerNameDatabase: 'Partner name',
  HomeCurrency: 'Home currency',
  ForeignCurrency: 'Foreign currency',
  ExRate: '',
  itemID: 'Item ID',
  itemName: 'Item Name',
  ItemNameShort: '',
  ItemCode: 'Item Serial Nr',
  ItemEANCode: '',
  itemGroup: 'Group',
  itemGroupName: 'Group name',
  itemDescription: 'Description',
  itemUnit: 'Unit',
  ItemWarehouse: 'Warehouse',
  StartDate : 'Start date',
  StartTime : '',
  EndDate : 'End date',
  EndTime : '',
  WareHouseUpdateBy : 'Updated by',
  FreeF1: 'Months',
  SelectPartner: 'SelectPartner',
  showSerials: 'Show Serials',
  Stickers: 'Stickers',
  Sticker: 'Sticker',
  ItemSelect: 'Select',
  ItemName: 'Name',
  ItemUnit: 'Unit',
  ItemPrice: 'Price',
  ItemPriceWithVAT: 'Price with VAT',
  emptyLines:'Empty lines',
  printCashDiscount: 'Print Cash Disc'
}

export default docEN