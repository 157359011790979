<template>
  <v-container fluid>
        <v-row>
            <v-col cols="12" sm="4" md="4" lg="4" xl="4">
              <h1>Joppd B</h1>
            </v-col>
            <v-col cols="12" sm="1" md="1" lg="1" xl="1">
                 <v-btn text color="red" @click="joppdbDelete" class="mr-1">
                      {{langC.Delete}}
                  </v-btn>
            </v-col>
             <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="text-right">
                  <v-btn color="green" @click="joppdblist" class="mr-1">
                      {{langC.Back}}
                  </v-btn>
                  <v-btn color="green" @click="joppdbprint" class="mr-1" >
                      {{langC.Print}}
                  </v-btn>                
           </v-col>
            <v-col cols="12" sm="1" md="1" lg="1" xl="1">
              <v-btn fab dark fixed right class="indigo"  @click="newjoppdb">
                    <v-icon dark>mdi-plus</v-icon>
                </v-btn>
            </v-col>
           
           <v-progress-circular v-if="this.saving"
              indeterminate
              color="green"
            ></v-progress-circular>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <JoppdBDetailList key="refresh" />
          </v-col>
        </v-row>

        <v-dialog
          v-model="showAlert"
          max-width="290"
        >
          <v-card>
            <v-card-title class="headline">
             {{lang.Question1}}
            </v-card-title>

            <v-card-text>
              {{lang.Question2}}
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="showAlert = false"
              >
                {{langC.Cancel}}
              </v-btn>

              <v-btn
                color="blue"
                text
                @click="haveAlert2()"
              >
                {{langC.Continue}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          bottom
          :color="color"
          >
          {{ text }}
        </v-snackbar>
  </v-container>
</template>

<script>

import ledgerEN from './salarieDescEn'
import ledgerHR from './salarieDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import JoppdBDetailList from './JoppdBDetailList'

export default {
  name: 'joppdbdetail',
  data () {
    return {
      lang: {},
      langC: {},
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      msg: '',
      retPath: 'joppdb',
      show: 1,
      showAlert: false,
      saving: false,
      error: '',
      mainQuery: {
        CompanyId: 1,
        MLType: 1,
      },
      card: {},
      refresh: 0
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
   
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;  
    },
    joppdbDelete() {

    },
    joppdblist() {
      this.$router.push({
        name: 'joppdadetail'
      })
    },
    joppdbprint() {

    },
    async newjoppdb () {
      try {
        await this.$store.dispatch('seteditSubItem', 0)
        await this.$router.push({
          name: 'joppdbdetail'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },


  },
  computed: {

  },
  watch: {

  },
  components: {
    JoppdBDetailList,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
