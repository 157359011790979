<template>

    <v-container fluid grid-list-md>
    <v-layout row>
       <v-flex xs3 sm3 md3 lg3 xl3>
        <h1>{{this.$store.state.documentActivLoc}}</h1>
      </v-flex>
      <v-flex xs3 sm3 md3 lg3 xl3>
        <v-select
          label="Vrsta dok."
          :items="bookType"
          v-model="bookTypeSelected"
          value="bookType.value"
          single-line
          return-object
          persistent-hint
          clearable
          dense
          ></v-select>
      </v-flex>
      <v-flex xs6 sm6 md6 lg6 xl6 class="text-xs-right">
            <v-btn fab dark fixed right @click="newMl" class="indigo">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
      </v-flex>
    </v-layout>
      <app-main-ledger-list3></app-main-ledger-list3>
    </v-container>

</template>

<script>
import MainLedgerlist3 from './MainLedgerList3'
import { checkBooks} from './mainledgerHelper'
import { testLastUpdate } from '@/services/TestLastUpdate'

export default {
  name: 'mainledger3',
  data () {
    return {
      msg: '',
      MainLedgerName: '',
      MainLedgerNameLoc: '',
      mainledger: {},
      documentName: '',
      documentNameLoc: '',
      error: null,
      localError: false,
      newPath: '',
      show: 1,
      bookType: [],
      bookTypeSelected: {
      },
      bookTypeSelectedName: 'TEM'
    }
  },
  async mounted () {
    this.MainLedgerName = this.$store.state.MainLedgerActiv
    this.MainLedgerNameLoc = this.$store.state.MainLedgerActivLoc
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    this.show = 1
    if (this.$store.state.documentSide === 1 || this.$store.state.documentSide === 2) {
      this.show = 1
    }
    if (this.$store.state.documentSide === 3) {
      this.show = 3
    }
    if (this.$store.state.documentSide === 4) {
      this.show = 4
    }
    await this.$store.dispatch('setbookTypeSelected', null)
    await checkBooks(this.show)
    this.bookTypeSelected = this.$store.state.bookTypeSelected 

    this.bookType = []
    if (this.$store.state.booktype) {
      this.bookType = this.$store.state.booktype.filter(book => {
            return book.side === 3
          })
    }
    await this.$store.dispatch('setMainLedgerActiv', {})
    await this.$store.dispatch('seteditItem', 0)
    await this.$store.dispatch('setMainLedgerDetailid', null)
    await this.$store.dispatch('setPartnerid', null)
    await this.$store.dispatch('setPartnerVATID', null)
    await this.$store.dispatch('setMainLedgerItems', [])
    await this.$store.dispatch('setMainLedgerSideItem', null)
    
    if (this.$store.state.pathAgain) {
      this.newPath = this.$store.state.pathAgain
      this.$store.dispatch('setpathAgain', '')
      this.$router.push({
        name: this.newPath
      })
    }
    await testLastUpdate()
  },
  methods: {
     async newMl () {
      try {
        if (this.bookTypeSelected) {
          this.$store.dispatch('seteditItem', 0)
           await this.$router.push({
            name: 'mainledgercreate3'
          })
        } else {
          this.text = this.lang.BookTypeNotSelected
          this.color = 'red'
          this.snackbar = true
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },

  },
  computed: {
  },
  watch: {
    'bookTypeSelected' (val) {
        if (val) {
          if (parseInt(val.value) > 19) {
            this.bookTypeSelected.text = val.text
            this.bookTypeSelected.value = val.value
            this.$store.dispatch('setbookTypeSelected', this.bookTypeSelected)
          }
        }
    },
  },
  components: {
    appMainLedgerList3: MainLedgerlist3
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
