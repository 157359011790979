<template>
<v-container fluid class="ma-2">
   <v-row no-gutters justify="center" class="pa-0">
    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
      <v-row>
        <h2>Company logo</h2>
      </v-row>
      <v-row>
          <h3>Convert image to BASE64 on this link: </h3> <v-btn text color="primary" @click="base64">https://www.base64-image.de/</v-btn>          
      </v-row>
      <v-row>
        <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5">
          <v-textarea
            label="Logo top lef in BASE64 format"
            v-model="company.CompanyLogo1"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5">
          <v-textarea
            label="Logo top right in BASE64 format"
            v-model="company.CompanyLogo2"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="9" md="9" xs="9" lg="9" xl="9">
          <v-textarea
            label="Logo top full with A4 in BASE64 format"
            v-model="company.CompanyLogo3"
          ></v-textarea>
        </v-col>
      </v-row>
      
      <v-row>
          <div class="danger-alert" v-html="error" />
      </v-row>
          <br>
           <h3> After update logo please log out and log in again. Thank you.  </h3> 
      <v-row>
        <v-col cols="12" sm="9" md="9" xs="9" lg="9" xl="9">
          <v-btn
            dark
            class="blue mr-2"
            @click="update">
            Update
          </v-btn>
          <v-btn
            color="green"
            @click="compList">
            Back
          </v-btn>
        </v-col>
      </v-row>
      
      </v-col>
   </v-row>
  </v-container>
</template>

<script>
import CompService from '@/services/CompService'

export default {
  name: 'companyedit',
  data () {
    return {
      menu: false,
      modal: false,
      msg: '',
      newCompany: {},
      company: {},
      required: (value) => !!value || 'Required.',
      error: ''
    }
  },
  async mounted () {
    try {
      if (this.$store.state.editItem === 1) {
        const locComp = await CompService.show(this.$store.state.companyid)
        this.company = locComp.data.company
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log('greska kod monut dokumenta', error)
    }
  },
  methods: {
    async update () {
      try {
        await CompService.put(this.company)
        this.$router.push({
          name: 'appsettings'
        })
      } catch (error) {
        this.error = error.response.data.error
      }
    },
    async compList () {
      try {
        await this.$router.push({
          name: 'appsettings'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    base64() {
      window.open("https://www.base64-image.de/", "_blank");
    }
  },
  computed: {
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
