<template>
  <v-form
    ref="form"
    v-model="valid"
  >
   <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" xs="12" md="4"  lg="4" xl="4" class="mb-4">
        <h2>Obrazac Opzstat</h2>
      </v-col>

      <v-col cols="12" sm="12" xs="12" md="8"  lg="8" xl="8">
        <v-spacer></v-spacer>
        <v-btn
          text color="red"
          @click="deleteOpzstat()">
          Obriši
        </v-btn>

        <v-btn
          text color="primary"
          @click="createOpzstat()">
          Kreiraj
        </v-btn>
        <v-btn
          text color="primary"
          @click="downloadOpzstat()">
          Download
        </v-btn>
       <v-btn
          class="primary mr-2"
          color="success"
          @click="save">
          {{langC.Save}} Opzstat
        </v-btn>
        <v-btn
          class="primary mr-2"
          color="blue"
          @click="showItms">
          Opzstat stavke
        </v-btn>
        <v-btn
        class="mr-3"
        color="green"
        @click="goBack">
        {{langC.Back}}
      </v-btn>
      </v-col>
    </v-row>

    <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>

        <v-row no-gutters class="pa-0">

      <v-col cols="12" sm="12" md="12" xs="12" lg="5" xl="5" class="pr-4">
          O DOSPJELIM, A NENAPLAĆENIM RAČUNIMA NA DAN <input type="date" v-model="head.NaDan" />
      </v-col>
      <v-col cols="12" sm="12" md="12" xs="12" lg="5" xl="5">
          KOJI NISU NAPLAĆENI DO <input type="date" v-model="head.NisuNaplaceniDo" />
      </v-col>
    </v-row>

    <v-row no-gutters class="pt-4">
      <v-col cols="12" sm="12" md="12" xs="12" lg="5" xl="5" class="pr-4">
        Datum Od: <input type="date" v-model="head.DatumOd" />
      </v-col>
      <v-col cols="12" sm="12" md="12" xs="12" lg="5" xl="5">
        Datum Do: <input type="date" v-model="head.DatumDo" />
      </v-col>
    </v-row>

    <v-row no-gutters class="pt-4">

      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-4">
        <v-text-field
          dense
          :rules="reqRules"
          label="OIB"
          v-model="head.OIB"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
        <v-text-field
          dense
          :rules="reqRules"
          label="VRSTA IZVJEŠĆA"
          v-model.number="head.VrstaIzvjesca"
          type="number"
          required
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters class="pa-0">
      <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-4">
        <h4 class="mb-3">Podaci o podnositelju izvješća</h4>
      </v-col>
    </v-row>

    <v-row no-gutters class="pa-0">
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-4">
        <v-text-field
          dense
          :rules="reqRules"
          label="Naziv *"
          v-model="head.Naziv"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
        <v-text-field
          dense
          label="Mjesto"
          v-model="head.Mjesto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
        <v-text-field
          dense
          label="Ulica"
          v-model="head.Ulica"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-3">
        <v-text-field
          dense
          label="Kbr"
          v-model="head.Broj"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-4">
        <v-text-field
          dense
          label="email"
          v-model="head.Email"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters class="pa-0">
      <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-4">
        <h4 class="mb-3">Obračun sastavio</h4>
      </v-col>
    </v-row>

     <v-row no-gutters class="pa-0">
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-4">
        <v-text-field
          dense
          :rules="reqRules"
          label="Ime *"
          v-model="head.Ime"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
        <v-text-field
          dense
          label="Prezime"
          v-model="head.Prezime"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
        <v-text-field
          dense
          label="Telefon"
          v-model="head.Telefon"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-3">
        <v-text-field
          dense
          label="Sastavioemail"
          v-model="head.Sastavioemail"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="showItems">
        <OpzstatItemsIndex key="refresh" />
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>

  </v-container>
 </v-form>
</template>

<script>

import OpzstatHeadService from '@/services/OpzstatHeadService'
import OpzstatItemsIndex from './OpzstatItemsIndex.vue'
import OpzstatItemsService from '@/services/OpzstatItemsService'
import {defOpzstathead, defNewOpzstathead} from './formhelper'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import { v4 as uuidv4 } from 'uuid'
import FileSaver from 'file-saver'

export default {
  name: 'opzstatheaddetail',
  props: [],
  data: ()=> ({
    lang: {},
    langC: {},
    saving: false,
    valid: false,
    reqRules: [
      v => !!v || 'Obavezno polje',
    ],
    head: {},
    modal1: false,
    dialog1: false,
    modal2: false,
    dialog2: false,
    modal3: false,
    dialog3: false,
    modal4: false,
    dialog4: false,
    showItems: false,
    details: [],
    snackbar: false,
    text: '',
    timeout: 2000,
    color: 'green',
    mainQuery: {}
  }),
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.langC = commHr;
    }
    if (this.$store.state.editItem === 0) {
      this.head = defOpzstathead()
      this.head = {...this.head, ...defNewOpzstathead()}
    }
    if (this.$store.state.editItem === 1) {
      const res = await OpzstatHeadService.show(this.$store.state.documentid)
      console.log(res)
      this.head = res && res.data ? res.data : {}
      this.head.LastUpdateBy = this.$store.state.user.email
    }

  },
  methods: {
    validate () {
      this.$refs.form.validate()
    },
    showItms() {
      this.showItems = !this.showItems
    },
    async save() {
      try {

        this.saving = true
        if (this.$store.state.editItem === 0) {
          await OpzstatHeadService.post(this.head)
        }
        if (this.$store.state.editItem === 1) {
          await OpzstatHeadService.put(this.head)
        }
        this.saving = false
        this.goBack()
      }
      catch (err) {
        console.log(err)
      }
    },
    goBack (){
      this.$router.push({
          name: 'opzstatheadindex'
        })
    },
    goItems() {
      this.$router.push({
        name: 'opzstatitems'
      })
    },
    async deleteOpzstat() {
      const {data} = await OpzstatHeadService.delete(this.$store.state.documentid)
      if (data){
        this.goBack()
      }
    },
    async createOpzstat() {
      if (!this.head.NaDan) {
        alert('GREŠKA!! Nije upisan datum NaDan !')
        return
      }
      if (!this.head.NisuNaplaceniDo) {
        alert('GREŠKA!! Nije upisan datum NisuNaplaceniDo!')
        return
      }
      if (!this.head.DatumOd) {
        alert('GREŠKA!! Nije upisan datum Od!')
        return
      }
      if (!this.head.DatumDo) {
        alert('GREŠKA!! Nije upisan datum Do!')
        return
      }

      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentid) {
        this.mainQuery.OpzstatheadID = this.$store.state.documentid
      }
      const {data} = await OpzstatItemsService.index(this.mainQuery)
      if (data && data.opzstatitems !== undefined){
        this.details = data.opzstatitems
      }

      const opzs = {}
      opzs.head = this.head
      opzs.head.uui = uuidv4()
      opzs.items = this.details
      OpzstatHeadService.createopzstat(opzs)
      .then(res => {
        console.log(res)
        this.text = 'Obrazac je kreiran.'
        this.color = 'green'
        this.snackbar = true
        this.timeout = 2000
      })
      .catch(err => {
        console.log(err)
        this.text = 'Greska!! Obrazac NIJE kreiran.' + err
        this.color = 'red'
        this.snackbar = true
        this.timeout = 2000
      })
    },
     downloadOpzstat () {
        this.mainQuery.OIB = this.head.OIB
        this.mainQuery.DatumOd = this.head.DatumOd
        this.mainQuery.DatumDo = this.head.DatumDo
        const zoveSe = 'Opzstat_' + this.head.OIB + '_' + this.head.DatumOd + '_' + this.head.DatumDo + '_.xml'
        OpzstatHeadService.uploadopzstat(this.mainQuery)
        .then(res => {
          const data = res.data
          //const status = res.status
          const header = res.headers
          const fileType = header['content-type']
          const blob = new Blob([data], { type: fileType })
          FileSaver.saveAs(blob, zoveSe);
          //FileSaver.saveAs(res.data, zoveSe)
          // this.text = 'Spremite knjigu.'
          // this.color = 'green'
          // this.snackbar = true
          // this.timeout = 2000
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log(err)
          this.text = 'Greska!! Obrazac se NE moze ucitati.' + err
          this.color = 'red'
          this.snackbar = true
          this.timeout = 2000
        })
      },

  },
  computed: {
  },
  watch: {

  },
  components: {
    OpzstatItemsIndex
  }

}

</script>
