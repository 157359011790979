const docDE = {
  Salarie: 'Gehälter',
  SalarieList: 'Gehaltsliste',
  SalarieDetail: 'Gehalt',
  Create: 'Erstellen',
  Delete: 'Löschen',
  Order: 'Bestellnummer',
  Month: 'Monat',
  Year: 'Jahr',
  NumberOfEmp: 'Anzahl der Mitarbeiter',
  HoursNormal: 'Arbeitsstunden',
  HoursHoliday: 'Holiday',
  DateOpen: 'Erstellungsdatum',
  Locked: 'Gesperrt',
  LockedDate: 'Sperrdatum',
  Question1: 'Frage 1',
  Question2: 'Frage 2',
  hashtag: '#',
  eemail: 'email',
  First: 'Vorname',
  Last: 'Nachname',
  VatID: 'Umsatzsteuer-ID',
  FinBruto1: 'Brutto 1 €',
  FinBruto2: 'Brutto 2 €',
  FinNeto2: 'Netto 2 €',
  FinBruto3: 'Brutto 3 €',
  DeleteMonth: 'Löschen',
  Poslodavac: 'Arbeitgeber',
  SIfraObracuna: 'Abrechnungscode: ',
  Zaposlenik:'Mitarbeiter:',
  OIB: 'Umsatzsteuer-ID:',
  Racun: 'Konto:',
  ObrazacIP: 'Formular-IP',
  DatumIspisa:'Druckdatum:',
  ObracunPlaceZa: 'Gehaltsberechnung für ',
  VrstaRada: 'Art der Arbeit ',
  Sati: 'Stunden',
  Iznos:'Betrag',
  Bruto1:' Bruttogehalt 1 €',
  Add1:'Erste Addition zu Brutto 1',
  Add2:'Zweite Addition zu Brutto 1',
  Bruto2: 'Brutto zahlt 2 €',
  SalFrom: 'Gehaltsbeiträge',
  SalFrom1: 'Beigetragen von',
  SalFrom1Desc: 'Beitragsbeschreibung',
  SalFrom1Per: '%',
  SalFrom2: 'Beigetragen von',
  SalFrom2Desc: 'Beitragsbeschreibung',
  SalFrom2Per: '%',
  SalFrom2Amount: 'Betrag',
  FinNeto1ls: 'Einkommen €',
  Deduction: 'Persönlicher Abzug €',
  DeductionKids: 'Abzug für Kinder',
  DeductionFamily: 'Abzug für unterhaltsberechtigte Familienmitglieder',
  DeductionDisablity: 'Behinderung',
  DeductionFactor: 'Faktor',
  DeductionAmount: 'Abzugsbetrag',
  DeductionUse: '% verwendet',
  DeductionFinalAmounut: 'Gesamtbetrag des Abzugs €',
  DeductionDontUse: 'Abzugsbetrag NICHT verwendet',
  DeductionDontUseAtAll: 'Nicht verwendet',
  CityTaxPer: 'Zuschlagsprozentsatz',
  CityTaxId: 'Wohnortcode der Stadt/Gemeinde',
  CityTaxName: 'Name der Stadt/Gemeinde des Wohnsitzes',
  Neto: 'Nettogehalt €',
  NetoAdd: 'Nicht steuerpflichtige Gebühr €',
  NetoAddDesc: 'Arten und Beträge nicht steuerpflichtiger Vorteile',
  NetoAddType: 'Typ',
  NetoAddTypeName: 'Typname',
  NetoAddJoppd:'Joppd-Spalte',
  NetoAddAmounut: 'Höhe der steuerfreien Entschädigung €',
  FinNeto3: 'Bei Zahlung €',
  SalToHead: 'Gehaltsbeiträge',
  SalTo: 'Beitrag zu',
  SalToDesc: 'Beitragsbeschreibung',
  SalToPer: '%',
  SalToAmount: 'Betrag €',
  TaxTax: 'Steuer und Zusatzsteuer',
  Tax20h: 'Steuer 20%',
  Tax20: 'Steuerbasis 20%',
  Tax20Desc: '(bis zu 3981,69 € pro Monat)',
  Tax20Am: 'Steuerbetrag von 20%',
  Tax30h: 'Steuer 30%',
  Tax30: 'Steuerbasis 30%',
  Tax30Desc: '(über 3981,69 € pro Monat)',
  Tax30Am: '30% Steuerbetrag',
  TaxOnTax: 'Zuschlag',
  TaxOnTaxAm: 'Zuschlagsbetrag',
  TaxTotal: 'Steuer + Zuschlag gesamt €=',
  Bruto3: 'GESAMTARBEITSKOSTEN €',
  RedovanRad: 'Regelmäßige Arbeit',
  NocniRad: 'Nachtarbeit',
  PrekovremeniRad: 'Überstundenarbeit',
  Nedjelja: 'Sonntag',
  Blagdan:'Feiertag',
  Teren: 'Gelände',
  PreraspodjelaRada: 'Umverteilung der Arbeit“',
  Pripravnost: 'Bereitschaft',
  DnevniOdmor: 'Täglicher Urlaub',
  TjedniOdmor: 'Wöchentlicher Feiertag',
  NeradniBlagdan: 'Arbeitsfreier Feiertag',
  GodisnjiOdmor: 'Jahresurlaub',
  BolovanjeDo42Dana: 'Krankheitsurlaub bis 42 Tage',
  BolovanjeOd42Dana: 'Krankheitsurlaub von 42 Tagen',
  RodiljniDop: 'Mutterschaftsunterstützung',
  PlaćeniDopust: 'Bezahlter Urlaub',
  NeplaćeniDopust: 'Unbezahlter Urlaub',
  NenazočnostNaZahtjevRadnika: 'Abwesenheit auf Wunsch des Arbeitnehmers',
  ZastojKrivnjomPoslodavca: 'Betriebsunterbrechung durch Verschulden des Arbeitgebers',
  Strajk: 'Streik',
  Lockout: 'Sperre',
  VrijemeMirovanjaRadnogOdnosa: 'Die Ruhezeit im Arbeitsverhältnis',
  UKUPNO: 'GESAMT',
  PorezPrirezUkupno:'Steuer + Zuschlag gesamt:',
  PorezUkupno:'Gesamtsteuer:',
  NeoporeziviOdbitakFaktor: 'Nicht steuerpflichtiger Abzug (Faktor:',
  PoreznaOsnovica: 'Steuerbasis ',
  IsplataRedovanRacun: 'Regelmäßiges Zahlungskonto:',
  IsplataZasticeniRacun: 'Zahlungsgeschütztes Konto:',
}

export default docDE