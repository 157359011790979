<template>
    <v-container fluid grid-list-md>
    {{lang.TravelOrders}}
    <v-row no-gutters>
            <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">

            </v-col>
            <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="py-0 px-2">
               <v-btn fab dark fixed right class="indigo" @click="newdocument">
                 <v-icon dark>mdi-plus</v-icon>
                </v-btn>
            </v-col>
          </v-row>
            
    <TravelOrderList />
    </v-container>
</template>

<script>
import TravelOrderList from './TravelOrderList'
import ledgerEN from './travelorderDescEn'
import ledgerHR from './travelorderDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"

export default {
  name: 'travelorder',
  data () {
    return {
      lang: {},
      langC: {}
    }
  },
  mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr
    }
  },
  methods: {
    async newdocument () {
      try {
        this.$store.dispatch('seteditItem', 0)
        await this.$router.push({
          name: 'travelordercreate'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
  },
  computed: {
  },
  watch: {

  },
  components: {
    TravelOrderList
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
