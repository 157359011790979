import store from '../../store/store'
import DocumentService from '@/services/DocumentService'
import {wait} from '@/snippets/allSnippets'

const formatPrice = (value) => {
    let val = (value / 1).toFixed(2).replace(',', '.')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '')
}

const fiskalSave = async(dbID, document, type) => {
  const fisObject = {}
  fisObject.line1 = type
  const year = document.documentDate ? document.documentDate.slice(0,4) : ''
  const month = document.documentDate ? document.documentDate.slice(5,7) : ''
  const day = document.documentDate ? document.documentDate.slice(-2) : ''
  const dateFormated = day + '/' + month + '/' + year
  const tTime = document.documentTime.substr(0,8)
  const hours = tTime ? tTime.slice(0,2) : ''
  const minutes = tTime ? tTime.substr(3,2) : ''
  const seconds = tTime ? tTime.slice(-2) : ''
  const timeFormated = hours + ':' + minutes + ':' + seconds 
  fisObject.line2 = dateFormated + ' ' + timeFormated
  fisObject.line3 = document.documentId.toString()
  const VAT1 = document.documentTotalVAT1Base !== 0 ? (formatPrice(document.documentTotalVAT1Per) + '; ' + formatPrice(document.documentTotalVAT1Base) + '; ' + formatPrice(document.documentTotalVAT1Amount)) : null
  //const VAT2 = document.documentTotalVAT2Base !== 0 ? '; ' + round(document.documentTotalVAT2Per, 2) + '; ' + round(document.documentTotalVAT2Base, 2) + '; ' + round(document.documentTotalVAT2Amount, 2) : null
  //const VAT3 = document.documentTotalVAT3Base !== 0 ? '; ' + round(document.documentTotalVAT3Per, 2) + '; ' + round(document.documentTotalVAT3Base, 2) + '; '  + round(document.documentTotalVAT3Amount, 2) : null
  //fisObject.line4 = (VAT1 ? VAT1 : '') + (VAT2 ? VAT2 : '') + (VAT3 ? VAT3 : '')
  fisObject.line4 = (VAT1 ? VAT1 : '')
  fisObject.line5 = formatPrice(document.documentTotalAmountBrutto)
  const PAYWITH = document.documentPOSCash !== 0 ? 'G' : null
  const PAYWITH2 = document.documentPOSCard !== 0 ? 'K' : null
  fisObject.line6 = PAYWITH ? PAYWITH : PAYWITH2
  fisObject.line7 = document.uservatID ? document.uservatID : store.state.companyActiv.CompanyVATID
  fisObject.line8 = ''
  fisObject.line9 = ''
  fisObject.line10 = ''
  fisObject.line11 = ''
  fisObject.line12 = dbID.toString()
  fisObject.line13 = ''
  fisObject.line14 = ''
  fisObject.line15 = '1'
  fisObject.line16 = ''

  await DocumentService.documentfiskalsave(fisObject)

}

const fiskalRead = async (attempt, dbID) => {
    let doItAgain = false
    const fiskalStatus = {}
    await DocumentService.documentfiskalread()
    .then(async(res) => {
      fiskalStatus.status = res.data.status === 'OK' ? 'Fiskalizacija OK.' : 'GRESKA FISKALIZACIJE!!!' 
      //if (parseInt(res.data.dbID) === dbID) {
        const updateHead = {}
        updateHead.id = dbID ? dbID : null
        updateHead.documentJIR = res.data.documentJIR ? res.data.documentJIR : null
        updateHead.documentZKI = res.data.documentZKI ? res.data.documentZKI : null
        updateHead.documentUUID = res.data.documentUUID ? res.data.documentUUID : null
        fiskalStatus.JIR = updateHead.documentJIR
        fiskalStatus.ZKI = updateHead.documentZKI
        fiskalStatus.UUID = updateHead.documentUUID
        await DocumentService.put(updateHead)
      //}
    }) 
    .catch(err => {
      // eslint-disable-next-line
      console.log(err)
      doItAgain = true
    })

    if (doItAgain && attempt < 2) {
      fiskalRead(2)
      wait(2000)
    }

    return fiskalStatus
}

export {
  fiskalSave,
  fiskalRead
}