import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('salarietotal', {
      params: query
    })
  },
  indexlist(search) {
    return Api().get('salarietotal/list', {
      params: search
    })
  },
  choose (query) {
    return Api().get('salarietotal/choose', {
      params: query
    })
  },
  show (id) {
    return Api().get(`salarietotal/${id}`)
  },
  post (newsalarietotal) {
    return Api().post('salarietotal', newsalarietotal)
  },
  put (salarietotal) {
    return Api().put(`salarietotal/${salarietotal.id}`, salarietotal)
  },
  delete (id) {
    return Api().delete(`salarietotal/${id}`)
  },
  saveitems (newitems) {
    return Api().post('salarietotal/saveitems', newitems)
  },
}