const ledgerEN = {
  id: 'DB id',
  CompanyName: 'Company',
  BusinessYear: 'Business Year',
  User: 'User',
  AccountID: 'Account ID',
  Debit: 'Debit',
  Credit: 'Credit',
  DebitFr: 'Debit Foregin',
  CreditFr: 'Credit Foregin',
  CreditDebit: 'State',
  VAT: 'VAT %',
  Description: 'Ledger Account',
  DefaultAccount: 'Default account',
  Saved: 'New bank account has been saved.',
  ErrorMounted: ' Error, cant read data from database...',
  ErrorEdit: 'Error, cannot edit data ...',
  ErrorNewLine: 'Error, cannot open new line ....',
  ErrorSave: 'Error, cannot save data to database ...',
  SuccessSave: 'Main ledger item has been saved to database.',
  PrimaryCurrency: 'Primary Currency',
  RequiredF: 'This field is required',
  ErrorG: 'Error! Some required fields are missing',
  StatusNew: 'New item',
  StatusEdit: 'Edit item',
  Account1: 'Class',
  Account1s: 'Classes',
  NewAccount1: 'New Class',
  EditAccount1: 'Edit Class',
  Account1sEmpty: 'Classes table is empty',
  Account1Description: 'Class Description',
  Account1Description2: 'Class Description EN',
  Account2: 'Group',
  Account2s: 'Groups',
  NewAccount2: 'New Group (from 00 to 99)',
  EditAccount2: 'Edit Group',
  Account2sEmpty: 'Group table is empty',
  Account2Description: 'Group Description',
  Account2Description2: 'Group Description English',
  Account3: 'Group 3',
  Account3s: 'Groups 3',
  NewAccount3: 'New Group3 (from 000 to 999)',
  EditAccount3: 'Edit Group3',
  Account3sEmpty: 'Group3 table is empty',
  Account3Description: 'Group3 name',
  Account3Description2: 'Group3 name english',
  Account4: 'Account',
  Account4s: 'Accounts',
  NewAccount4: 'New Account (4 to 6 characters)',
  EditAccount4: 'Edit Account',
  Account4sEmpty: 'Account table is empty',
  Account4Description: 'Account name',
  Account4Description2: 'Account name english',
  BuyerSupplierAccount: '',
  Supplier: 'Supplier',
  Buyer: 'Buyer',
  SuppBookInv: 'Supp.Book Inv.',
  BookType: 'Book type',
  BookingName: 'Book Type Name',
  BookColumn: 'Book column',
  VATClaim: 'VAT % claim',
  VATCantClaim: 'VAT% Cant claim',
  BuyerBook: 'Buyer Book',
  BookType2: 'Book type',
  EUData: 'EU data',
  EUBookType: 'EU Book type',
  EUColumn: 'EU column',
  EUVATClaim: 'EU VAT % claim',
  EUVATCantClaim: 'EU VAT% Cant claim',
  EUBookS: 'EU Book S',
  EUVATRefound: 'EU VAT refound',
  EUPPO: 'EU PPO',
  BookID: 'Book ID',
  BookIDml: 'Book ID',
  BookID2: 'Invoice ID',
  BookIDml2: 'Invoice ID',
  BookBase: 'BookBase',
  ClosedAccount: 'Closed',
  BookTypeID: 'Book Type ID',
  BookingDate: 'Booking Date',
  InvoiceNumber: 'Invoice Number',
  InvoiceNumber2: 'Invoice Orig.Nr',
  InvoiceNumberml: 'Invoice Number',
  InvoiceDate: 'Invoice Date',
  InvoiceAmount: 'Invoice Amount',
  HomeCurrency: 'Home Currency',
  InvoiceForeginAmount: 'Invoice Foregin Amount',
  ForeignCurrency: 'Foreign Currency',
  PartnerName: 'Partner Name',
  PartnerVATID: 'Partner VATID',
  PartnerList: 'Partner List',
  PartnerOpen: 'Open statements',
  PartnerID: 'Partner ID',
  InvoicePaymentDeadlineDate: 'Invoice Payment Deadline Date',
  VATPer: 'VAT %',
  MLFree1: 'Intern description',
  MLFree2: 'Scan',
  DebitTotal: 'Debit Total',
  CreditTotal: 'Credit Total',
  MainLedgerAnalysis: 'MainLedger Analysis',
  DeleteItem: 'Are you sure to delete ?',
  DeleteItemMsg: 'If you are not shure, please contact your admin. Thanks',
  SyncBooks: 'Sync Book with Invoices',
  BookTypeNotSelected: 'Book Type NOT selected!',
  DocumentType: 'DocumentType',
  SelectPartner: 'Select Partner',
  BookInInvoices: 'Book In Invoices',
  BookOutInvoices: 'Book Out Invoices',
  partnerName: 'Partner name',
  VAT3Base: 'VAT 5% Base',
  VAT2Base: 'VAT 13% Base',
  VAT1Base: 'VAT 25% Base',
  VAT3Amount: '5% VAT amount',
  VAT2Amount: '13% VAT amount',
  VAT1Amount: '25% VAT amount',
  AmountVAT: 'Amount VAT',
  VAT3CanUse: '5% can use',
  VAT3CanTUse: '5% cant use',
  VAT2CanUse: '13% can use',
  VAT2CanTUse: '13% cant use',
  VAT1CanUse: '25% can use',
  VAT1CanTUse: '25% cant use',
  VAT4Per: 'Interstate no VAT',
  VAT4Base: 'Sales in other count.',
  VAT4Amount: 'Sales good in EU',
  VAT4PerCanUse: 'Sales services in EU',
  VAT4CanUse: 'Deliveris person no adress in Croatia ',
  VAT4CanTUse: 'good in other count',
  VAT5Per: 'Deliveries NPS in EU',
  VAT5Base: 'Local',
  VAT5Amount: 'Export',
  VAT5PerCanUse: 'Other liberation',
  VATID: 'VAT ID: ',
  VATStatements: 'VAT Statements',
  VATStatement: 'VAT Statement',
  Branch: 'NADLEŽNA ISPOSTAVA POREZNE UPRAVE',
  Podatak000: 'OBRAČUN PDV-a – UKUPNO',
  Podatak100: 'TRANSAKCIJE OSLOBOĐENE - UKUPNO',
  Podatak101: 'ISPORUKE U RH ',
  Podatak102: 'ISPORUKE DRUGIM DRŽAVAMA ČLANICAMA',
  Podatak103: 'ISPORUKE DOBARA UNUTAR EU',
  Podatak104: 'OBAVLJENE USLUGE UNUTAR EU',
  Podatak105: 'OBAVLJENE ISPORUKE OSOBAMA BEZ SJEDIŠTA U RH',
  Podatak106: 'SASTAVLJANJE I POSTAVLJANJE DOBARA U DRUGOJ DRŽAVI EU',
  Podatak107: 'ISPORUKE NOVIH PRIJEVOZNIH SREDSTAVA U EU',
  Podatak108: 'TUZEMNE ISPORUKE',
  Podatak109: 'IZVOZNE ISPORUKE',
  Podatak110: 'OSTALA OSLOBOĐENJA',
  Podatak111: 'ISPORUKE PO STOPI 0%',
  Podatak200: 'OPOREZIVE TRANSAKCIJE – UKUPNO',
  Porez200: 'OPOREZIVE TRANSAKCIJE – PDV',
  Podatak201: 'Osnovica 5%',
  Porez201: 'PDV 5%',
  Podatak202: 'Osnovica 13%',
  Porez202: 'PDV 13%',
  Podatak203: 'Osnovica 25%',
  Porez203: 'PDV 25%',
  Podatak204: 'Tuz.prij.por.obv. osn',
  Porez204: 'Tuz.priv.por.obv.PDV',
  Podatak205: 'Stjec.dob.unutar EU 5% osn',
  Porez205: 'Stjec.dob.unutar EU 5% PDV',
  Podatak206: 'Stjec.dob.unutar EU 13% osn',
  Porez206: 'Stjec.dob.unutar EU 13% PDV',
  Podatak207: 'Stjec.dob.unutar EU 25% osn',
  Porez207: 'Stjec.dob.unutar EU PDV',
  Podatak208: 'Prim.usl.iz EU 5% osn',
  Porez208: 'Prim.usl.iz EU 5% PDV',
  Podatak209: 'Prim.usl.iz EU 13% osn',
  Porez209: 'Prim.usl.iz EU 13% PDV',
  Podatak210: 'Prim.usl.iz EU 25% osn',
  Porez210: 'Prim.usl.iz EU 25% PDV',
  Podatak211: 'Bez sjed.u RH 5% osn',
  Porez211: 'Bez sjed.u RH 5% PDV',
  Podatak212: 'Bez sjed.u RH 13% osn',
  Porez212: 'Bez sjed.u RH 13% PDV',
  Podatak213: 'Bez sjed.u RH 25% osn',
  Porez213: 'Bez sjed.u RH 25% PDV',
  Podatak214: 'Nak.osl.izvoza osn',
  Porez214: 'Nak.osl.izvoza PDV',
  Podatak215: 'Obr.PDV pri uvozu osn',
  Porez215: 'Obr.PDV pri uvozu PDV',
  Podatak300: 'Pretporez UKUPNO osn',
  Porez300: 'Pretporez UKUPNO PDV',
  Podatak301: 'Pretp.isporuke TU 5% osn',
  Porez301: 'Pretp.isporuke TU 5% PDV',
  Podatak302: 'Pretp.isporuke TU 13% osn',
  Porez302: 'Pretp.isporuke TU 13% PDV',
  Podatak303: 'Pretp.isporuke TU 25% osn',
  Porez303: 'Pretp.isporuke TU 25% PDV',
  Podatak304: 'TU prijenos por.ob. osn',
  Porez304: 'TU prijenos por.ob.PDV',
  Podatak305: 'Stjec.dob. EU 5% osn',
  Porez305: 'Stjec.dob. EU 5% PDV',
  Podatak306: 'Stjec.dob. EU 13% osn',
  Porez306: 'Stjec.dob. EU 13% PDV',
  Podatak307: 'Stjec.dob. EU 25% osn',
  Porez307: 'Stjec.dob. EU 25% PDV',
  Podatak308: 'Stjec.usl.EU 5% osn',
  Porez308: 'Stjec.usl.EU 5% PDV',
  Podatak309: 'Stjec.usl.EU 13% osn',
  Porez309: 'Stjec.usl.EU 13% PDV',
  Podatak310: 'Stjec.usl.EU 25% osn',
  Porez310: 'Stjec.usl.EU 25% PDV',
  Podatak311: 'Dob.i usl.bez sj. 5% osn',
  Porez311: 'Dob.i usl.bez sj. 5% PDV',
  Podatak312: 'Dob.i usl.bez sj. 13% osn',
  Porez312: 'Dob.i usl.bez sj. 13% PDV',
  Podatak313: 'Dob.i usl.bez sj. 25% osn',
  Porez313: 'Dob.i usl.bez sj. 25% PDV',
  Podatak314: 'Pretp.uvoz osnovica',
  Porez314: 'Pretp.uvoz PDV',
  Podatak315: 'Ispravci pretporeza',
  Podatak400: 'Obveza PDVa',
  Podatak500: 'Po preth.obr.',
  Podatak600: 'Ukupno razlika',
  Podatak700: 'Razmjeni odbitak',
  Podatak810: 'Za Ispravak',
  Podatak811: 'Nab.nekretnina',
  Podatak812: 'Nab.os.auto',
  Podatak813: 'Prod.os.auto',
  Podatak814: 'Nab.dug.imo',
  Podatak815: 'Prod.dug.imo',
  Podatak820: 'Ot./stjec.gosp.cjel.',
  Podatak830: 'Isp.nekretnina',
  Podatak831: 'Isp.cl.40.st.1',
  Broj831: 'Broj.nek',
  Podatak832: 'Isp.cl.40.st.4',
  Broj832: 'Broj.nek',
  Podatak833: 'Isp.oporezivo',
  Broj833: 'Broj.nek',
  Podatak840: 'Uk.prim.usl',
  Podatak850: 'Uk.ob.usl',
  Podatak860: 'Prim.dobra',
  Podatak871: 'Post.opo.nap.nak',
  Podatak872: '',
  Podatak873: '',
  Povrat1: 'Povrat',
  Povrat2: 'Povrat',
  Predujam1: 'Predujam',
  Predujam2: 'Predujam',
  UstupPovrata1: 'Ustup',
  UstupPovrata2: 'Ustup',
  Podatak000d: 'OBRAČUN PDV-a U OBAVLJENIM TRANSAKCIJAMA DOBARA I USLUGA – UKUPNO (I. + II.)',
  Podatak100d: 'I. TRANSAKCIJE KOJE NE PODLIJEŽU OPOREZIVANJU I OSLOBOĐENE - UKUPNO (1.+2.+3.+4.+5.+6.+7.+8.+9.+10.)',
  Podatak101d: '1. ISPORUKE U RH ZA KOJE PDV OBRAČUNAVA PRIMATELJ (tuzemni prijenos porezne obveze) ',
  Podatak102d: '2. ISPORUKE DOBARA OBAVLJENE U DRUGIM DRŽAVAMA ČLANICAMA',
  Podatak103d: '3. ISPORUKE DOBARA UNUTAR EU',
  Podatak104d: '4. OBAVLJENE USLUGE UNUTAR EU',
  Podatak105d: '5. OBAVLJENE ISPORUKE OSOBAMA BEZ SJEDIŠTA U RH',
  Podatak106d: '6. SASTAVLJANJE I POSTAVLJANJE DOBARA U DRUGOJ DRŽAVI ČLANICI EU',
  Podatak107d: '7. ISPORUKE NOVIH PRIJEVOZNIH SREDSTAVA U EU',
  Podatak108d: '8. TUZEMNE ISPORUKE',
  Podatak109d: '9. IZVOZNE ISPORUKE',
  Podatak110d: '10. OSTALA OSLOBOĐENJA',
  Podatak111d: '11. ISPORUKE PO STOPI 0%',
  Podatak200d: 'II. OPOREZIVE TRANSAKCIJE – UKUPNO (1.+2.+3.+4.+5.+6.+7.+8.+9.+10.+11.+12.+13.+14.+15.)',
  Podatak201d: '1. ISPORUKE DOBARA I USLUGA U RH po stopi 5%',
  Podatak202d: '2. ISPORUKE DOBARA I USLUGA U RH po stopi 13%',
  Podatak203d: '3. ISPORUKE DOBARA I USLUGA U RH po stopi 25%',
  Podatak204d: '4. PRIMLJENE ISPORUKE U RH ZA KOJE PDV OBRAČUNAVA PRIMATELJ (tuzemni prijenos porezne obveze)',
  Podatak205d: '5. STJECANJE DOBARA UNUTAR EU po stopi 5%',
  Podatak206d: '6. STJECANJE DOBARA UNUTAR EU po stopi 13%',
  Podatak207d: '7. STJECANJE DOBARA UNUTAR EU po stopi 25%',
  Podatak208d: '8. PRIMLJENE USLUGE IZ EU po stopi 5%',
  Podatak209d: '9. PRIMLJENE USLUGE IZ EU po stopi 13%',
  Podatak210d: '10. PRIMLJENE USLUGE IZ EU po stopi 25%',
  Podatak211d: '11. PRIMLJENE ISPORUKE DOBARA I USLUGA OD POREZNIH OBVEZNIKA BEZ SJEDIŠTA U RH po stopi 5%',
  Podatak212d: '12. PRIMLJENE ISPORUKE DOBARA I USLUGA OD POREZNIH OBVEZNIKA BEZ SJEDIŠTA U RH po stopi 13%',
  Podatak213d: '13. PRIMLJENE ISPORUKE DOBARA I USLUGA OD POREZNIH OBVEZNIKA BEZ SJEDIŠTA U RH po stopi 25%',
  Podatak214d: '14. NAKNADNO OSLOBOĐENJE IZVOZA U OKVIRU OSOBNOG PUTNIČKOG PROMETA',
  Podatak215d: '15. OBRAČUNANI PDV PRI UVOZU',
  Podatak300d: 'III. OBRAČUNANI PRETPOREZ - UKUPNO(1.+2.+3.+4.+5.+6.+7.+8.+9.+10.+11.+12.+13.+14.+15.)',
  Podatak301d: '1. PRETPOREZ OD PRIMLJENIH ISPORUKA U TUZEMSTVU po stopi 5%',
  Podatak302d: '2. PRETPOREZ OD PRIMLJENIH ISPORUKA U TUZEMSTVU po stopi 13%',
  Podatak303d: '3. PRETPOREZ OD PRIMLJENIH ISPORUKA U TUZEMSTVU po stopi 25%',
  Podatak304d: '4. PRETPOREZ OD PRIMLJENIH ISPORUKA U RH ZA KOJE PDV OBRAČUNAVA PRIMATELJ (tuzemni prijenos porezne obveze)',
  Podatak305d: '5. PRETPOREZ OD STJECANJA DOBARA UNUTAR EU po stopi 5%',
  Podatak306d: '6. PRETPOREZ OD STJECANJA DOBARA UNUTAR EU po stopi 13%',
  Podatak307d: '7. PRETPOREZ OD STJECANJA DOBARA UNUTAR EU po stopi 25%',
  Podatak308d: '8. PRETPOREZ OD PRIMLJENIH USLUGA IZ EU po stopi 5%',
  Podatak309d: '9. PRETPOREZ OD PRIMLJENIH USLUGA IZ EU po stopi 13%',
  Podatak310d: '10. PRETPOREZ OD PRIMLJENIH USLUGA IZ EU po stopi 25%',
  Podatak311d: '11. PRETPOREZ OD PRIMLJENIH ISPORUKA DOBARA I USLUGA OD POREZNIH OBVEZNIKA BEZ SJEDIŠTA U RH po stopi 5%',
  Podatak312d: '12. PRETPOREZ OD PRIMLJENIH ISPORUKA DOBARA I USLUGA OD POREZNIH OBVEZNIKA BEZ SJEDIŠTA U RH po stopi 13%',
  Podatak313d: '13. PRETPOREZ OD PRIMLJENIH ISPORUKA DOBARA I USLUGA OD POREZNIH OBVEZNIKA BEZ SJEDIŠTA U RH po stopi 25%',
  Podatak314d: '14. PRETPOREZ PRI UVOZU',
  Podatak315d: '15. ISPRAVCI PRETPOREZA',
  Podatak400d: 'IV. OBVEZA PDV-a U OBRAČUNSKOM RAZDOBLJU: ZAUPLATU(II.-III.)ILIZAPOVRAT(III.-II.)',
  Podatak500d: 'V. PO PRETHODNOM OBRAČUNU: NEUPLAĆENI PDV DO DANA PODNOŠENJA OVE PRIJAVE – VIŠE UPLAĆENO – POREZNI KREDIT',
  Podatak600d: 'VI. UKUPNO RAZLIKA: ZA UPLATU/ZA POVRAT',
  Podatak610: '1. ZA ISPRAVAK PRETPOREZA (UKUPNO 1.1.+1.2.+1.3.+1.4.+1.5.)',
  Podatak611: '1.1. NABAVA NEKRETNINA',
  Podatak612: '1.2. NABAVA OSOBNIH AUTOMOBILA I DRUGIH SREDSTAVA ZA OSOBNI PRIJEVOZ',
  Podatak613: '1.3. PRODAJA OSOBNIH AUTOMOBILA I DRUGIH SREDSTAVA ZA OSOBNI PRIJEVOZ',
  Podatak614: '1.4. NABAVA OSTALE DUGOTRAJNE IMOVINE',
  Podatak615: '1.5. PRODAJA OSTALE DUGOTRAJNE IMOVINE',
  Podatak620: '2. OTUĐENJE/STJECANJE GOSPODARSKE CJELINE ILI POGONA',
  Podatak630: '3. UKUPNO PRIMLJENE USLUGE OD POREZNIH OBVEZNIKA BEZ SJEDIŠTA U RH (EU + TREĆE ZEMLJE)',
  Podatak640: '4. UKUPNO OBAVLJENE USLUGE POREZNIM OBVEZNICIMABEZ SJEDIŠTA U RH (EU + TREĆE ZEMLJE)',
  Podatak650: '5. PRIMLJENA DOBRA IZ EU U OKVIRU TROSTRANOG POSLA',
  Podatak660: '6. POSTUPAK OPOREZIVANJA PREMA NAPLAĆENIM NAKNADAMA',
  PovratIBAN: 'BROJ RAČUNA ZA POVRAT',
  Podatak700d: 'VII. IZNOS GODIŠNJEG RAZMJERNOG ODBITKA PRETPOREZA (%)',
  Podatak800d: 'VIII. OSTALI PODACI',
  Podatak810d: '1. ZA ISPRAVAK PRETPOREZA (UKUPNO 1.1.+1.2.+1.3.+1.4.+1.5.)',
  Podatak811d: '1.1. NABAVA NEKRETNINA',
  Podatak812d: '1.2. NABAVA OSOBNIH AUTOMOBILA I DRUGIH SREDSTAVA ZA OSOBNI PRIJEVOZ',
  Podatak813d: '1.3. PRODAJA OSOBNIH AUTOMOBILA I DRUGIH SREDSTAVA ZA OSOBNI PRIJEVOZ',
  Podatak814d: '1.4. NABAVA OSTALE DUGOTRAJNE IMOVINE',
  Podatak815d: '1.5. PRODAJA OSTALE DUGOTRAJNE IMOVINE',
  Podatak820d: '2. OTUĐENJE/STJECANJE GOSPODARSKE CJELINE ILI POGONA',
  Podatak830d: '3. ISPORUKA NEKRETNINA',
  Podatak831d: '3.1. ISPORUKA IZ ČLANKA 40. STAVKA 1. TOČAKA J) I K) ZAKONA',
  Podatak832d: '3.2. ISPORUKA IZ ČLANKA 40. STAVKA 4. ZAKONA',
  Podatak833d: '3.3. ISPORUKA NEKRETNINA KOJE SU OPOREZIVE PDV-om',
  Podatak840d: '4. UKUPNO PRIMLJENE USLUGE OD POREZNIH OBVEZNIKA BEZ SJEDIŠTA U RH (EU + TREĆE ZEMLJE)',
  Podatak850d: '5. UKUPNO OBAVLJENE USLUGE POREZNIM OBVEZNICIMABEZ SJEDIŠTA U RH (EU + TREĆE ZEMLJE)',
  Podatak860d: '6. PRIMLJENA DOBRA IZ EU U OKVIRU TROSTRANOG POSLA',
  Podatak871d: '7. POSTUPAK OPOREZIVANJA PREMA NAPLAĆENIM NAKNADAMA',
  Podatak872d: '',
  Podatak873d: '',
  DocPrintMadeBy: 'Made by:',
  EnterPayment: 'Enter Payments',
  PaidDate: 'Paid date',
  PaidHome: 'Home Currency',
  PaidFore: 'Fore.Currency',
  PaidClosed: 'Paid Closed',
  bankAccountID: 'Bank Account ID',
  bankAccount: 'Bank Account',
  bankStatmentNumber: 'Number',
  bankStatmentDate: 'Date',
  bankStatmentMoneyName: 'Money Name',
  bankStatmentStart: 'Start',
  bankStatmentDebit: 'Debit',
  bankStatmentCredit: 'Credit',
  bankStatmentFinish: 'Finish',
  bankStatment: 'Bank Statment',
  bankStatmentDetailNr: 'Bank Statement Detail Number',
  bankStatments: 'Bank Statments',
  NewbankStatment: 'New Bank Statment',
  EditbankStatment: 'Edit Bank Statment',
  bankStatmentEmpty: 'Bank Statment table is empty',
  bankStatmentDescription: 'Bank Statment name',
  bankStatmentDescription2: 'Bank Statment name english',
  partnerBankIBAN: 'IBAN',
  bankReference1: 'Bank Reference1',
  bankReference2: 'Bank Reference2',
  ExRate: 'Ex.Rate',
  invoiceInID: 'Book IN Nr',
  invoiceOutID: 'Invoice OUT Nr',
  invoiceInNumber: 'Book IN Nr',
  invoiceOutNumber: 'Invoice OUT Nr',
  alertError1: 'Error! Bank statment is NOT saved to server.',
  alertError2: 'Error! Can not find last number!',
  alertError3: 'Error! Can not save bank statemen!!',
  alertError4: 'Error! Read bank statement...',
  alertOK1: 'Great, bank statment is saved to server.',
  Items: 'items',
  BusinessYearInvoice: 'Business Year Invoice',
  noticeAdd: 'MainLedger item is updated.',
  noticeUpdate: 'MainLedger item has added',
  errorNoAccount: 'No account!',
  errorNoDebitCredit: ' No debit and credit amount!',
  errorZero: ' Debit and Credit are ZERO!',
  detailList: ' detail list',
  CreateDetails1: 'Create Details Default',
  CreateDetails2: 'Create Details As Last',
  Previous: 'Previous',
  PrevItems: 'Previous items on number',
  FromAccount: 'From Account',
  ToAccount: 'To Account',
  BookTypeAll: 'Book Type',
  BookTypeSelect: 'Select Book type',
  detailId: 'Id',
  detailDone: 'Done',
  checkPartnerByIban: 'Check Partner',
  copyPartnerIban: 'Copy IBAN to Partner',
  partnerNOTexist: 'Partner with selected IBAN, NOT exist in database!',
  partnerExist: 'Partner with selected IBAN exist.',
  closeBS: 'Close BS',
  BSfileError: 'Error! File input name is empty.',
  BSexistError: 'Error! Bank statement with this number already exist!.',
  NewLLA: 'New lla',
  EditLLA: 'Change lla',
  llaID: 'ID',
  llaCode: 'Code',
  llaName: 'Name',
  llaDocumentSource: 'Document Sourcea ID',
  llaDocumentSourceDate: 'Document Source Date', 
  llaBuyingValue: 'Buying Value', 
  llaBookValue : 'Book Value', 
  llaLasting: 'Lasting', 
  llaDepreciationPercent: 'Depreciation Percent%', 
  llaDepreciationAmountYear: 'Depreciation Amount Year', 
  llaDepreciationAmountTotal: 'Depreciation Amount Total',
  llaValueYearEnd: 'Value Year End', 
  llaMark: 'Mark', 
  llaSellDate: 'Sell Date',
  llaRegistrartionDate: 'Registrartion Date',
  llaRegistrartionContract: 'Registrartion Contract',
  llaStartDate: 'Dep.Start Date',
  llaEndDate: 'Dep.End Date',
  llaPieces: 'Pieces',
  lla: 'Long lastings',
  llaAcount4: 'Account',
  llaAccountDebit: 'Dep.Acc.Debit',
  llaAccountCredit: 'Dep.Acc.Credit',
  llaCard: 'History Card',
  PartnerSave: 'Save or update',
  Partneremail: 'email *',
  Partneradded: 'New partner is saved.',
  PrivateAccount: 'Private account',
  AccountError: 'Account error',
  TestInvoice: 'Test Invoice',
  MainLedgerProjectType: 'Project',
  MainLedgerProjectTypes: 'Projects',
  MainLedgerProjectTypeName: 'Project Name',
  MainLedgerProjectTypeEmpty: 'No projects',
  MainLedgerQues1: 'Difference!!',
  MainLedgerQues2: 'Difference between invoice amount and main ledger totals!!',
  MainLedgerQues3: 'Buyer/Supplier account error!',
  MainLedgerQues4: 'You enter buyer/supplier account without partner',
  Locked: 'Locked',
  LockedDate: 'Locked date',
  ConnName: 'Connection',
  ConnId: 'Conn Nr',
  PartnerErrorVAT: 'Partner exist!',
  OpenStateBuyers: 'Buyers - open statments',
  OpenStateSupp: 'Suppliers - open statements',
  OpenStateBuyers2: 'Open Buy.',
  OpenStateSupp2: 'Open Supp',
  OpenStatement: 'Open statements',
  CardsBuyers: 'Buyers - cards',
  CardsSupp: 'Suppliers - cards',
  CardBuyer: 'Buyer - card',
  CardSupp: 'Supplier - card',
  OpenStatementBuyer: 'Open - buyer',
  OpenStatementSupp: 'Open - supp',
  LatestBooking: 'Latest booking in main ledger',
  LatestBookIn: 'Recievables:',
  LatestBookOut: 'Invoices:',
  LatestBookBS: 'Bank statements:',
  LatestNoData: 'No data',
  FinishBalanceSheet: '2.Close balance sheet',
  CreateFinish: '3.Create finish statement',
  CloseYear:'4.Close year',
  AllPartnersBankList: 'All partners bank list',
  Total30: 'Up to 30 days',
  Total60: 'From 30 to 60 days',
  Total90: 'From 60 to 90 days',
  Total120: 'From 90 to 120 days',
  TotalOver: 'Over 120 days',
  AllPartnerCard: 'All partners cards',
  FinishYear: 'Finish Buss.Year',
  closeBuyerSupplier: '1.Copy open stat.to next year',
  importDOC: 'Import/Export',
  PartnerErrorVAT3: 'Number of letters in VATID is not regular!',
  ReturnUnsaved: 'Return NOT saved',
  CopytoNewYear: 'Copy to New Year',
  prepareBuyerSupplier: '1.Prepare file Buy/Supp',
  PayedVAT: 'Pay VAT, when Inv.is paid',
  fromDate: 'From date',
  toDate:'To date',
  VATZero:'0% base',
  eInvoice: 'eInvoice',
  mark: 'OK',
  diff: 'Diff.',
  partnerNotInVAT:'Not in VAT'
}

export default ledgerEN