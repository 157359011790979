import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('travelorder', {
      params: query
    })
  },
  choose (query) {
    return Api().get('travelorder/choose', {
      params: query
    })
  },
  show (id) {
    return Api().get(`travelorder/${id}`)
  },
  post (newmainbook) {
    return Api().post('travelorder', newmainbook)
  },
  put (travelorder) {
    return Api().put(`travelorder/${travelorder.id}`, travelorder)
  },
  delete (id) {
    return Api().delete(`travelorder/${id}`)
  }
}