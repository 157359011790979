<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs12 sm12 m12 lg12 ex12>

        <div id="printArea">

                <v-layout row style="border-bottom:solid 1px;">
                    <v-flex xs5>
                        <h4><b><i>{{comp.CompanyName}}</i></b></h4>
                        {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}<br />
                        {{comp.CompanyPostcode}} {{comp.CompanyCity}}<br />
                         OIB: {{comp.CompanyVATID}}
                    </v-flex>
                    <v-flex xs3>
                      <span v-if="this.$store.state.language == 'EN'">
                      <h2>Totals</h2>
                      </span>
                      <span v-if="this.$store.state.language == 'HR'">
                      <h2>Knjiga uplate isplate</h2>
                      </span>

                      <h4><b><i>{{partner.partnerName}}</i></b></h4>
                      {{partner.partnerStreet}} {{partner.partnerBuildingNameNumber}}<br />
                      {{partner.partnerPostcode}} {{partner.partnerCity}}<br />
                      OIB: {{partner.partnerVATID}}, {{partner.partneremail}}
                  </v-flex>
                    <v-flex xs 4>
                      <p class="text-md-left">
                          Datum izvještaja: {{PrintDate}}<br />
                          Telefon: {{comp.CompanyPhones}}<br />
                          email: {{comp.Companyemail}}<br />
                      </p>
                    </v-flex>
              </v-layout>


            <v-layout row style="border-bottom:solid 1px; border-top:solid 1px;">
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  Žiro račun
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  Gotovina
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  Ukupno 
                </v-col>
            </v-layout>

              <v-row style="border-top:solid 1px;">
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <h4><b><i>Uplate {{defCurrency}}</i></b></h4>
                  </v-col>
              </v-row>
              <v-layout row style="border-bottom:solid 1px;">
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  {{formatPrice(RecBank)}}
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  {{formatPrice(RecCash)}}
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                   {{formatPrice(RecTotal)}}
                </v-col>

            </v-layout>

            <v-row style="border-top:solid 1px;">
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <h4><b><i>Isplate{{defCurrency}}</i></b></h4>
                  </v-col>
              </v-row>
            <v-layout row style="border-bottom:solid 1px;">
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  {{formatPrice(ExpBank)}}
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  {{formatPrice(ExpCash)}}
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  {{formatPrice(ExpTotal)}}
                </v-col>

            </v-layout>
            
            <v-row style="border-top:solid 1px;">
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <h4><b><i>Saldo{{defCurrency}}</i></b></h4>
                  </v-col>
              </v-row>
            <v-layout row style="border-bottom:solid 1px;">
               <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  {{formatPrice(Total2)}}
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  {{formatPrice(Total1)}}
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  {{formatPrice(Total6)}}
                </v-col>

            </v-layout>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ledgerEN from '../MainBook/mainbookDescEn'
import ledgerHR from '../MainBook/mainbookDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import {dateFormat, parseNum, round} from '@/snippets/allSnippets'
import dayjs from 'dayjs'
//import PartnerService from '@/services/PartnerService'

export default {
  name: 'mainledgercompcard1',
  props: ['items', 'exRate', 'defCurrency'],
  data () {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      comp: {},
      error: null,
      localError: false,
      newUser: {},
      size: 'sm',
      havePath: '',
      RecCash: 0,
      RecBank: 0,
      RecFree: 0,
      RecVAT: 0,
      RecSpecial: 0,
      RecTotal: 0,
      ExpCash: 0,
      ExpBank: 0,
      ExpFree: 0,
      ExpVAT: 0,
      ExpSpecial: 0,
      ExpTotal: 0,
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      Total6: 0,
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      pItems: [],
      mainQuery2: {},
      partner: {},
      partners: [],
    }
  },
  components: {
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    this.PrintDate = dayjs(new Date()).format('llll')
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    
    this.Total1 = 0
    this.Total2 = 0
    this.Total3 = 0
    this.Total4 = 0
    this.Total5 = 0
    this.Total6 = 0

    if (this.items) {
      this.items.map(item => {
        this.RecCash += round((parseNum(item.RecCash) / this.exRate) ,2)
        this.RecBank += round((parseNum(item.RecBank)  / this.exRate) ,2)
        this.RecFree += round((parseNum(item.RecFree) / this.exRate) ,2)
        this.RecVAT += round((parseNum(item.RecVAT) / this.exRate) ,2)
        this.RecTotal += round((parseNum(item.RecTotal) / this.exRate) ,2)

        this.ExpCash += round((parseNum(item.ExpCash) / this.exRate) ,2)
        this.ExpBank += round((parseNum(item.ExpBank) / this.exRate) ,2)
        this.ExpFree += round((parseNum(item.ExpFree) / this.exRate) ,2)
        this.ExpVAT += round((parseNum(item.ExpVAT) / this.exRate) ,2)
        this.ExpSpecial += round((parseNum(item.ExpSpecial) / this.exRate) ,2)
        this.ExpTotal += round((parseNum(item.ExpTotal) / this.exRate) ,2)
        // this.RecCash += parseNum(item.RecCash)
        // this.RecBank += parseNum(item.RecBank) 
        // this.RecFree += parseNum(item.RecFree)
        // this.RecVAT += parseNum(item.RecVAT)
        // this.RecSpecial += parseNum(item.RecSpecial)
        // this.RecTotal += parseNum(item.RecTotal)

        // this.ExpCash += parseNum(item.ExpCash)
        // this.ExpBank += parseNum(item.ExpBank)
        // this.ExpFree += parseNum(item.ExpFree)
        // this.ExpVAT += parseNum(item.ExpVAT)
        // this.ExpSpecial += parseNum(item.ExpSpecial)
        // this.ExpTotal += parseNum(item.ExpTotal)
      })
    }
    this.Total1 = this.RecCash - this.ExpCash
    this.Total2 = this.RecBank - this.ExpBank
    this.Total3 = this.RecFree - this.ExpFree
    this.Total4 = this.RecVAT - this.ExpVAT
    this.Total5 = this.RecSpecial - this.ExpSpecial
    this.Total6 = this.RecTotal - this.ExpTotal

  },
  computed: {

  },
  methods: {
    isNumeric(num){
      return !isNaN(num)
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    print () {
      // opens the "print dialog" of your browser to print the element
      // var bdhtml = window.document.body.innerHTML
      // window.document.body.innerHTML = document.getElementById('printArea').innerHTML
      // window.print()
      // window.document.body.innerHTML = bdhtml
      this.$htmlToPaper('printArea')
    },

    close () {
      this.show = false
    }
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
