<template>
<div>
  <v-container fluid grid-list-md>
  <v-row>
      <appAppSettings></appAppSettings>
    </v-row>
  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="warehouses"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="20"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{lang.WareHouses}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>


          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-row>
                    <v-col cols="6" xs="6" sm="6" md="3" lg="3" xl="3">
                      <v-text-field
                        :label="lang.WareHouseId"
                        v-model="editedItem.WareHouseId"
                        type="number"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="8" md="8" lg="8" xl="8">
                      <v-text-field
                        :label="lang.WareHouseName"
                        v-model="editedItem.WareHouseName"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      {{lang.WareHouseUpdateDate}}:<input type="date" v-model="editedItem.WareHouseUpdateDate" /> 
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                     <v-checkbox
                        v-model="editedItem.WareHouseLocked"
                        label="WareHouse Locked"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      {{lang.WareHouseLockedDate}}:<input type="date" v-model="editedItem.WareHouseLockedDate" /> 
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn :disabled="!valid" color="success" text @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.WareHouseUpdateDate`]="{ item }">
        <span>{{ (item.WareHouseUpdateDate ? localDate(item.WareHouseUpdateDate) : null) }}</span>
    </template>
    <template v-slot:[`item.WareHouseLockedDate`]="{ item }">
        <span>{{ (item.WareHouseLockedDate ? localDate(item.WareHouseLockedDate) : null) }}</span>
    </template>
    <template v-slot:[`item.WareHouseLocked`]="{ item }">
      <span v-if="item.WareHouseLocked"><v-icon>mdi-check</v-icon></span>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      {{ lang.WareHouseEmpty }}
    </template>
  </v-data-table>
  </v-container>
  </div>
</template>

<script>
import WareHouseMarkService from '@/services/WareHouseMarkService'
import langEn from './warehouseDescEn'
import langHr from './warehouseDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import AppSettings from '../AppSettings'
import dayjs from 'dayjs'

  export default {
    name: 'warehousemarkCRUD',
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: "",
        databaseDocID: 0
      },
      dialog: false,
      headers: [
        
      ],
      warehouses: [],
      editedIndex: -1,
      editedItem: { },
      defaultItem: {
        WareHouseId: 1,
        WareHouseName: '',
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        WareHouseStatus: '',
        WareHouseLocked: false,
        WareHouseLockedDate: null,
        WareHouseLockedTime: null,
        WareHouseLockedBy: '',
        WareHouseUpdateDate: null,
        WareHouseUpdateTime: null,
        WareHouseUpdateBy: '',
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: '',
        LastUpdate: null
      },
      confpassword: '',
      privitems: [ ],
      valid: true,
      warehouseIdRules: [
        v => !!v || 'ID is required',
      ],
      nameRules: [
        v => !!v || 'Name is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password must be 6 or more characters',
      ],
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewWareHouse: this.lang.EditWareHouse
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    components: {
      appAppSettings: AppSettings
    },

    mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      let locDate1 = new Date()
      locDate1 = dayjs().set({ 'year': locDate1, 'month': 0, 'date': 1 })
      this.defaultItem.startFrom = dayjs(locDate1).format('YYYY-MM-DD')

      this.headers.push(
          {text: this.lang.WareHouseId, value: 'WareHouseId'},
          {text: this.lang.WareHouseName, value: 'WareHouseName'},
          {text: this.lang.WareHouseUpdateDate, value: 'WareHouseUpdateDate', width: '150px', type: 'date', dateInputFormat: 'YYYY-MM-DD', dateOutputFormat: 'DD.MM.YYYY'},
          {text: this.lang.WareHouseStatus, value: 'WareHouseStatus', width: '50px'},
          {text: this.lang.WareHouseLockedDate, value: 'WareHouseLockedDate', type: 'date', dateInputFormat: 'YYYY-MM-DD', dateOutputFormat: 'DD.MM.YYYY'},
          {text: this.lang.WareHouseLockedTime, value: 'WareHouseLockedTime', width: '100px'},
          {text: this.lang.WareHouseLockedBy, value: 'WareHouseLockedBy', width: '50px'},
          {text: 'DbId', value: 'id', type: 'number', width: '50px'},
          {text: this.lang.Actions, value: 'action', sortable: false },
      ) 
    },

    created () {
      this.initialize()
    },

    methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      async initialize () {
        try {
          this.saving = true
          this.mainQuery.CompanyId = this.$store.state.companyid
          this.warehouses = (await WareHouseMarkService.index(this.mainQuery)).data.wareHouseMarks           
          this.saving = false
          this.errorMatch = false
        } catch (err) {
          this.error = err
          
        }
      },
      editItem (item) {
        this.errorMatch = false
        this.editedIndex = this.warehouses.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      async deleteItem (item) {
        try {
          const index = this.warehouses.indexOf(item)
          const warningMessage = item.WareHouseName
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.warehouses.splice(index, 1)) {
            await WareHouseMarkService.delete(item.id)
            this.initialize()
          }
        } catch(err) {
          this.error = err
          
        }
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      async save () {
        try {
           let doneOK = false
           if (this.editedIndex > -1) {
              await WareHouseMarkService.put(this.editedItem)
              doneOK = true
            } else {
              const haveWhID = this.warehouses.find(id => id.WareHouseId === this.editedItem.WareHouseId)
              if (haveWhID) {
                alert('Error!! Greška!! Taj broj skladišta već postoji!! WhareHouse ID exist already!')
                this.close()
              }
              if (!haveWhID) {
                this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
                this.editedItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
                this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
                this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
                this.editedItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : ''
                await WareHouseMarkService.post(this.editedItem)
                await WareHouseMarkService.index(this.mainQuery).then(res => {
                  if (res.data.wareHouseMarks) {
                    this.$store.dispatch('setWarehouseState', res.data.wareHouseMarks)
                  }
                })
                .catch(err => {
                  console.log('Error init setWarehouseState ', err)
                })
                doneOK = true
              }
            }
            if (doneOK) {
              this.initialize()
              this.close()
            }
        } catch(err) {
          this.error = err
          
        }
       
      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      }
    },
  }
</script>



