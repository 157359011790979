const docEN = {
  CashDesk: 'Cash Desk',
  documentDate: 'Date',
  Description: 'Description',
  CashIn: "Cash IN",
  CashInFore: 'Cash IN Fore',
  CashOut: 'Cash OUT',
  CashOutFore: 'Cash OUT Fore',
  CashSaldo: 'Cash Saldo',
  CashSaldoFore: 'Cash Saldo Fore',
  Employeeemail: 'Employee',
  NewCashDesk: 'New',
  EditCashDesk: 'Edit',
  EmptyCashDesk: 'No entries',
  Discount: 'Discount',
  CurrentMonth: 'Month',
  ReportCreated: ' report, created'
}

export default docEN