<template>
<div>
  <v-container fluid grid-list-md>

  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="users"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="50"
    @click:row="navigateTo"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{lang.DocDescList}}</v-toolbar-title>
         <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

        <v-spacer></v-spacer>
         
          <!-- <v-btn
              color="blue"
              text
              @click="vehicleprnt"
            >
              PRINT
            </v-btn> -->
         

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px">  

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container class="pa-0 ma-0">
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >

                  <v-row no-gutters>
                     <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pa-1">
                     <v-text-field
                        dense
                        :label="lang.docsmall"
                        v-model="editedItem.docsmall"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" class="pa-1">
                      <v-textarea
                        auto-grow
                        :label="lang.Description"
                        v-model="editedItem.description"
                      ></v-textarea>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                     <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pa-1">
                      {{lang.docOnly}}
                    </v-col>
                      <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
                          <v-autocomplete
                            :items="items"
                            v-model="selectDest"
                            :label="lang.docOnly"
                            item-text="title"
                            item-value="doc"
                            return-object
                            persistent-hint
                            clearable
                          ></v-autocomplete>
                      </v-col>
                  </v-row>

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1"  @click="close" class="mr-3">{{langC.Cancel}}</v-btn>
                    <v-btn color="success"  @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      {{ lang.DocDescListEmpty }}
    </template>
  </v-data-table>

  </v-container>
  </div>
</template>

<script>
import DocDescService from '@/services/DocDescService'
import langEn from './docdescEN'
import langHr from './docdescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import langEnm from '../mainDescEN'
import langHrm from '../mainDescHR'

  export default {
    name: 'docdescCRUD',
    data: () => ({
      card: {},
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      modal1: false,
      dialog1: false,
      lang: {},
      langm: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {},
      dialog: false,
      headers: [],
      users: [],
      selectPa: {},
      selectDest: {},
      partners: [],
      search: null,
      editedIndex: -1,
      editedItem: { },
      defaultItem: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        UserId: null,
        EmployeeId: 0,
        email: '',
        First: '',
        Last: '',
        docsmall: '',
        description: '',
        doc: '',
        docLoc: '',
        docSide: 0,
        LastUpdateBy: '',
        LastUpdate: null,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: '',
        FreeF6: '',
        FreeF7: false
      },
      privitems: [],
      items: [],
      valid: true,
      total: 0
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.Description: this.lang.EditDocDesc
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      'selectDest' (val) {
        if (val) {
          this.editedItem.doc = val.doc
          this.editedItem.docLoc = val.docLoc
        }
        if (!val) {
          this.editedItem.doc = null
          this.editedItem.docLoc = null
        }
      },
    },
    components: {
    },
    async mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langm = langEnm
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langm = langHrm
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }

      //this.mainQuery.salarieID = this.$store.state.documentActivHead.id

      this.headers.push(
        {text: this.lang.docsmall, value: 'docsmall', width: '200px'},
        {text: this.lang.Doc, value: 'description', width: '550px'},
        {text: this.lang.docOnly, value: 'docLoc', width: '200px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
        {text: this.lang.Actions, value: 'action', sortable: false },
      )
      this.items.push(
          { title: this.langm.Inquirys, path: '/crm/document', doc: 'InquirySales', docLoc: this.langm.Inquirys, docSide: 3 },
          { title: this.langm.Quotes, path: '/crm/document', doc: 'QuoteSales', docLoc: this.langm.Quotes, docSide: 3 },
          { title: this.langm.Order, path: '/crm/document', doc: 'OrderSales', docLoc: this.langm.Order, docSide: 3 },
          { title: this.langm.DeliveryNote, path: '/crm/document', doc: 'DeliveryNoteSales', docLoc: this.langm.DeliveryNote, docSide: 2 },
          { title: this.langm.InternalDeliveryNotes, path: '/crm/document', doc: 'InternalDeliveryNotes', docLoc: this.langm.InternalDeliveryNotes, docSide: 2 },
          { title: this.langm.ServiceNotes, path: '/crm/document', doc: 'ServiceNotes', docLoc: this.langm.ServiceNotes, docSide: 2 },
          { title: this.langm.InvoicesAdvance, path: '/crm/document', doc: 'InvoiceAdvance', docLoc: this.langm.InvoicesAdvance, docSide: 3 },
          { title: this.langm.Invoices, path: '/crm/document', doc: 'InvoiceSales', docLoc: this.langm.Invoices, docSide: 3 },
          { title: this.langm.Contracts, path: '/crm/document', doc: 'ContractSales', docLoc: this.langm.Contracts, docSide: 3 },
          { title: this.langm.WorkingOrder, path: '/crm/document3', doc: 'WorkingOrder', docLoc: this.langm.Production, docSide: 3 },
          { title: this.langm.POS, path: '/crm/document/pos', doc: 'POS', docLoc: this.langm.POS, docSide: 2 },
          { title: this.langm.Requests, path: '/crm/document', doc: 'RequestPurchase', docLoc: this.langm.Inquirys, docSide: 3 },
          { title: this.langm.Inquirys, path: '/crm/document', doc: 'InquiryPurchase', docLoc: this.langm.Requests, docSide: 3 },
          { title: this.langm.Order, path: '/crm/document', doc: 'OrderPurchase', docLoc: this.langm.Order, docSide: 3 },
          { title: this.langm.CalculationsPurchase, path: '/crm/document', doc: 'ReceiptPurchase', docLoc: this.langm.CalculationsPurchase, docSide: 1 },
          { title: this.langm.Returns, path: '/crm/document', doc: 'ReturnPurchase', docLoc: this.langm.Returns, docSide: 2 },
          { title: this.langm.Inventory, path: '/crm/document', doc: 'Inventory', docLoc: this.langm.Inventory, docSide: 1 },
       )   
      this.initialize()
    },

    created () {
    },

    methods: {
      navigateTo (params) {
        this.$store.dispatch('setVehicleid', params.id)
      },
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      async initialize () {
        try {
          this.selectDest = null
          this.saving = true
          const {data} = await DocDescService.index(this.mainQuery)
          if (data && data.docdescs !== undefined){
            this.users = data.docdescs
          }
          this.saving = false
          this.errorMatch = false
          this.editedItem = Object.assign({}, this.defaultItem)
        } catch (err) {
          this.error = err
        }
      },
      editItem (item) {
        this.errorMatch = false
        this.selectDest = {}
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        if (this.editedItem.doc && this.editedItem.doc.trim().length > 0 ) {    
          this.selectDest = this.items.find(item => (item.doc === this.editedItem.doc))
        }
        this.dialog = true
      },
      async deleteItem (item) {
        try {
          const index = this.users.indexOf(item)
          const warningMessage = item.docsmall + ', ' + item.docLoc 
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.users.splice(index, 1)) {
            await DocDescService.delete(item.id)
            this.initialize()
          }
        } catch(err) {
          this.error = err
        }
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      checkMatch () {
        if (this.editedItem.password && this.confpassword &&  this.confpassword === this.editedItem.password) {
          this.errorMatch = null
          this.save()
        } else {
          this.errorMatch = 'Error. !'
          this.valid = false        
        }
      },

      async save () {
        try {
           let doneOK = false
           if (this.editedIndex > -1) {
              if (!this.selectDest) {
                this.editedItem.doc = null
                this.editedItem.docLoc = null
              }
              this.editedItem.LastUpdateBy = this.$store.state.user.email ? this.$store.state.user.email : 'test@cegrupa.com'
              this.editedItem.LastUpdate = new Date()
              await DocDescService.put(this.editedItem)
              doneOK = true
            } else {
              this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
              this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              this.editedItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear  : 2022
              this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              this.editedItem.email = this.$store.state.user.email ? this.$store.state.user.email : 'test@cegrupa.com'
              this.editedItem.First = this.$store.state.user.First ? this.$store.state.user.First : 'NoFirst'
              this.editedItem.Last = this.$store.state.user.Last ? this.$store.state.user.Last : 'NoLast'
              await DocDescService.post(this.editedItem)
              doneOK = true
            }
            if (doneOK) {
              this.initialize()
              this.close()
            }
        } catch(err) {
          this.error = err
          
        }
       
      },

      clearPartner() {
        this.selectDest = null
      },
    },
  }
</script>