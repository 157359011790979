import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('mainbooktypes', {
      params: query
    })
  },
  choose (query) {
    return Api().get('mainbooktype/choose', {
      params: query
    })
  },
  show (id) {
    return Api().get(`mainbooktype/${id}`)
  },
  post (newmainbook) {
    return Api().post('mainbooktype', newmainbook)
  },
  put (mainbook) {
    return Api().put(`mainbooktype/${mainbook.id}`, mainbook)
  },
  delete (id) {
    return Api().delete(`mainbooktype/${id}`)
  },
}