<template>
  <div class="warehousestatelist">
    <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
      <v-card>
          <v-container>
            <v-row no-gutters>
              <v-col cols="6">
                  <p class="title">
                  {{lang.ProfitCard}}
                  </p>
              </v-col>
              <v-col cols="6">
              </v-col>
            </v-row>
          </v-container>        

        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"
          >
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
          <v-card-text>
            <span v-if="totalSupp"> {{lang.ItemSuppPriceAmount}}: {{formatPrice(totalSupp)}} {{defCurr}},</span>
            <span v-if="totalMargin"> {{lang.ItemMarginAmount}}: {{formatPrice(totalMargin)}} {{defCurr}},</span>
            <span v-if="totalNetto"> {{lang.ItemBaseAmountNoVAT}}: {{formatPrice(totalNetto)}} {{defCurr}},</span>
            <span v-if="totalVAT"> {{lang.ItemVAT1Amount}}: {{formatPrice(totalVAT)}} {{defCurr}},</span> 
            <span v-if="totalTotal"> {{lang.ItemFullTotal}}: {{formatPrice(totalTotal)}} {{defCurr}}</span>            
          </v-card-text>
      </v-card>
   
  </div>
</template>

<script>
// import axios from 'axios'
import ItemService from '@/services/ItemService'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'

export default {
  name: 'documentwarehousestate',
  data () {    
    return {
      items: [],
      documentName: '',
      documentNameLoc: '',
      msg: '',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: '',
        databaseDocID: 0
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
      documentAna: {
        CompanyId: 0,
        BusinessYear: 0,
        UserId: 0,
        partnerId: 0,
        documentName: '',
        docFromDate: null,
        docToDate: null,
        itemID: null,
        warehouseID: null
      },
      totalPcs: 0,
      totalSupp: 0,
      totalMargin: 0,
      totalNetto: 0,
      totalVAT: 0,
      totalDirect: 0, 
      totalTotal: 0,
      defCurr: 'Kn',
      iID: null,
      iName: null,
      exportDoc: false,
      ExRate: this.$store.getters.NeedDefExRate,
      haveCurrency: '',
    }
  },
  
  async mounted () {
    try {
     
        // do analysis from database
        if (this.$store.state.language === 'EN') {
          this.lang = langEn
          this.langC = commEn
        }
        if (this.$store.state.language === 'HR') {
          this.lang = langHr
          this.langC = commHr
        }
        this.exportDoc = this.$store.state.documentActivHead.exportDoc
        this.ExRate = parseFloat(this.$store.state.documentActivHead.documentExRate)
        if (this.exportDoc === false) {
          this.haveCurrency = this.$store.state.documentActivHead.documentHomeCurrency ? (' ' + this.$store.state.documentActivHead.documentHomeCurrency) : ''
        } else {
          this.haveCurrency = this.$store.state.documentActivHead.documentForeignCurrency ? (' ' + this.$store.state.documentActivHead.documentForeignCurrency) : ''
        }
        //this.defCurr = this.haveCurrency
        
        this.headers.push(
          {text: this.lang.ItemName, value: 'itemName', width: '110px'},
          {text: this.lang.documentDate1, value: 'documentDate', width: '110px'},
          {text: this.lang.documentId, value: 'documentId', width: '80px'},
          {text: this.langC.DocumentName, value: 'documentName', width: '80px'},
          {text: this.lang.partnerName, value: 'partnerNameDatabase', width: '150px'},
          {text: this.lang.ItemPcsNeutral, value: 'itemPcs', type: 'decimal', width: '100px'},
          {text: this.lang.ItemSuppPrice + this.haveCurrency, value: 'itemSuppPrice', type: 'decimal', width: '100px'},
          {text: this.lang.ItemMarginAmount + this.haveCurrency, value: 'itemMargin', type: 'decimal', width: '60px'},
          {text: this.lang.ItemPrice + this.haveCurrency, value: 'itemPrice', type: 'decimal', width: '100px'},
          {text: this.lang.ItemPriceWithVAT + this.haveCurrency, value: 'itemPriceWithVAT', type: 'decimal', width: '100px'},
          {text: this.lang.id, value: 'id', width: '50px'}
        )
        
        // do analysis from database
        this.localError = false
        this.error = null
        this.items = []
        
        //this.documentAna.BusinessYear = this.$store.state.documentAna.BusinessYear ? this.$store.state.documentAna.BusinessYear : 2020
        //this.documentAna.itemID = this.$store.state.documentAna.itemID ? this.$store.state.documentAna.itemID : ''

        // this.documentAna.docFromDate = this.$store.state.documentAna.docFromDate ? this.$store.state.documentAna.docFromDate : dayjs([this.documentAna.BusinessYear - 2]).startOf('year').format('YYYY-MM-DD')
        // this.documentAna.docFromDate = dayjs([this.documentAna.BusinessYear - 2]).startOf('year').format('YYYY-MM-DD')
        // this.documentAna.docToDate = this.$store.state.documentAna.docToDate ? this.$store.state.documentAna.docToDate : dayjs([this.documentAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
        this.saving = true
        const tempItems = this.$store.state.documentActivItems
        
        this.saving = false
        let iid = 0
        this.totalPcs = 0
        this.totalSupp = 0
        this.totalMargin = 0
        this.totalNetto = 0
        this.totalVAT = 0
        this.totalDirect = 0
        this.totalTotal = 0

        if (tempItems) {
          tempItems.map(async(item) => {
            const newEntry = {}
            let pcs = 0
            if (this.$store.state.documentSide === 1) {
              pcs = item.itemPcsPlus ? item.itemPcsPlus : 0
            }
            if (this.$store.state.documentSide === 2) {
              pcs = item.itemPcsMinus ? item.itemPcsMinus : 0
            }
            if (this.$store.state.documentSide === 3) {
              pcs = item.itemPcsNeutral ? item.itemPcsNeutral : 0
            }
            pcs = this.round(pcs, 3)
            this.documentAna = {}

            this.documentAna.CompanyId = this.$store.state.companyid? this.$store.state.companyid: 0
            this.documentAna.itemID = item.itemID

            const {data} = (await ItemService.choose(this.documentAna))
            let itemS = {}
            if (data && data.item) {
              itemS = data.item
            }
      
            if (pcs !== 0) {
               
              newEntry.itemID = item.itemID ? item.itemID : ''
              newEntry.itemName = item.itemName ? item.itemName : ''
              newEntry.documentDate = itemS.ItemSuppDate ? itemS.ItemSuppDate : ''
              //newEntry.documentName = itemS.ItemSuppDoc ? documentLocalName(itemS.ItemSuppDoc) : ''
              newEntry.documentName = itemS.ItemSuppDoc ? itemS.ItemSuppDoc : ''
              newEntry.documentId = itemS.ItemSuppDocNr ? itemS.ItemSuppDocNr : ''
              newEntry.partnerNameDatabase = itemS.ItemSupppartnerName ? itemS.ItemSupppartnerName : ''
              newEntry.itemPcs = pcs ? pcs : 0
              if (!this.exportDoc) {
                newEntry.itemSuppPrice = itemS.itemSuppPrice ? this.round(parseFloat(itemS.itemSuppPrice), 2) : 0
                newEntry.itemMargin = this.round((parseFloat(item.itemBaseAmountNoVAT) - (newEntry.itemSuppPrice * pcs)),2)
                newEntry.itemPrice = item.itemBaseAmountNoVAT ? this.round((parseFloat(item.itemBaseAmountNoVAT) / pcs), 2) : 0
                newEntry.itemPriceWithVAT = item.itemPriceWithVAT ? parseFloat(item.itemPriceWithVAT) : 0
              } else {
                newEntry.itemSuppPrice = itemS.itemSuppPrice ? this.round(parseFloat(itemS.itemSuppPrice / this.ExRate), 2) : 0
                newEntry.itemMargin = this.round((parseFloat(item.itemBaseForeAmountNoVAT) - (newEntry.itemSuppPrice * pcs)),2)
                newEntry.itemPrice = item.itemBaseForeAmountNoVAT ? this.round((parseFloat(item.itemBaseForeAmountNoVAT) / pcs), 2) : 0
                newEntry.itemPriceWithVAT = item.itemForePriceWithVAT ? parseFloat(item.itemForePriceWithVAT) : 0
              }
              
              if (!this.iID) {
                this.iID = newEntry.itemID
              }
              if (!this.iName) {
                this.iName = newEntry.itemName
              }
              
              this.totalPcs += pcs
              newEntry.TotalPlus = this.totalPcs
              if (newEntry.itemSuppPrice !== 0 ) {
                this.totalSupp += (newEntry.itemSuppPrice * pcs)
              }
              if ((newEntry.itemMargin) !== 0 ) {
                this.totalMargin += (newEntry.itemMargin)
              }
              if (newEntry.itemPrice) {
                this.totalNetto += (newEntry.itemPrice * pcs)
              }
              // if (item.itemVAT1Amount) {
              //   this.totalVAT += (item.itemVAT1Amount)
              // }
              if (newEntry.itemPriceWithVAT) {
                this.totalTotal += (pcs) * (newEntry.itemPriceWithVAT)
              }
              iid += 1
              newEntry.id = iid
              this.items.push(newEntry)
            }
          })
          this.totalSupp = this.round(this.totalSupp, 2)
          this.totalMargin = this.round(this.totalMargin, 2)
          this.totalNetto = this.round(this.totalNetto, 2)
          this.totalVAT = this.round((this.totalTotal - this.totalNetto), 2)
          this.totalTotal = this.round(this.totalTotal, 2)
        }
        this.typeAna = 4
      } catch (error) {
        this.error = 'Greska u konekciji na bazu' + error.detail
      }      
  },
  methods: {     
    navigateTo () {
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    formatPrice (value) {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
  },
  computed: {

  },
  watch: {

  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>


