<template>
  <v-container fluid>
    <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
    ></v-progress-circular>
    <v-alert type="warning" v-model="havealert" dismissible close-text="Close Alert">
        {{havemssgalert}}
    </v-alert>

  <v-row>
    <v-col cols="12" sm="12" > 
      <v-card>
        <v-row align="center" class="list px-3 mx-auto">
          <v-col cols="4" sm="2">
            <v-select
              v-model="searchOption"
              :items="searchOptions"
              :label="langC.SearchBy"
              clearable
              @change="handleSearchOption"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field v-model="searchName" :label="langC.SearchBy"></v-text-field>
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn small @click="page = 1; defaultInit();">
              {{langC.Search}}
            </v-btn>
          </v-col>
          <v-col cols="5" sm="5">
            <v-pagination
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            ></v-pagination>
            
          </v-col>
          <v-col cols="4" sm="1">
            Total:{{totalItems}}
          </v-col>
          <v-col cols="4" sm="1">
            <v-select
              v-model="pageSize"
              :items="pageSizes"
              :label="langC.ItemsPerPage"
              @change="handlePageSizeChange"
            ></v-select>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row> 

  <v-row>
    <v-col cols="12" sm="12">  
      <v-card>
        <v-card-title>
          {{ this.$store.state.documentActivLoc}}
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
          <span v-if="documentName === 'POS'">
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
          </span>
          
        </v-card-title>

        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"
            :mobile-breakpoint="0"
          >
          
          <template v-slot:[`item.documentDate`]="{ item }">
              <span>{{ localDate(item.documentDate) }}</span>
          </template>
          <template v-slot:[`item.partnerVATID`]="{ item }">
              <span>{{ displayPartnerOnly(item.partnerVATID, item.partnerName, item.partnerSecretaryFirst, item.partnerSecretaryLast) }}</span>
          </template>
          <template v-slot:[`item.documentInvoiceEndDate`]="{ item }">
              <span>{{ localDate(item.documentInvoiceEndDate) }}</span>
          </template>
           <template v-slot:[`item.FreeF4`]="{ item }">
              <h3><span>{{ localMoney(item.FreeF4) }}</span></h3>
          </template>
          <template v-slot:[`item.documentTotalAmountBrutto`]="{ item }">
              <h3><span>{{ localMoney(item.documentTotalAmountBrutto) }}</span></h3>
          </template>
          <template v-slot:[`item.documentTotalAmountBruttoForeign`]="{ item }">
              <span>{{ localMoney(item.documentTotalAmountBruttoForeign) }}</span>
          </template>
          <template v-slot:[`item.exportDoc`]="{ item }">
            <span v-if="item.exportDoc"><v-icon>mdi-check</v-icon></span>
          </template>
          <template v-slot:[`item.documentConnName`]="{ item }">
              <span v-if="item.documentName === 'DeliveryNoteSales'  && !item.documentConnName">
                <v-chip class="ma-2" color="orange" text-color="white"> ??? </v-chip>
              </span>
              <span v-else>{{localName(item.documentConnName)}}</span>
          </template>
           <template v-slot:[`item.documentConnNameFrom`]="{ item }">
              <span>{{localName(item.documentConnNameFrom)}}</span>
          </template>
          <template v-slot:[`item.productiontype`]="{ item }">
            <v-chip :color="getColor(item.productiontype)" dark>{{ localStatus(item.productiontype) }}</v-chip>
          </template>
          <template v-slot:[`item.docStatus`]="{ item }">
            <span v-if="item.docStatus && item.documentName !== 'ContractSales'">
              <v-chip :color="item.docColor" dark>{{ item.docStatus }}</v-chip>
            </span>
            <span v-if="item.documentName === 'ContractSales'  && item.validContract">
                <v-chip class="ma-2" color="green" text-color="white"> Activ </v-chip>
            </span>
          </template>


          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>  
  </v-container>
</template>

<script>
// import axios from 'axios'
import DocumentService from '@/services/DocumentService'
import DocumentDetailService from '@/services/DocumentDetailService'
import { mapGetters } from 'vuex'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dateFormat from '@/snippets/dateFormat'
import moneyFormat from '@/snippets/moneyFormat'
import documentLocalName from '@/snippets/documentLocalName'


export default {
  name: 'documentlist',
  props: ['productiontype'],
  data () {
    return {
      documentName: '',
      documentNameLoc: '',
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: '',
        databaseDocID: 0
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
      have1: true, 
      have2: true,
      have3: true,
      have4: true,
      have5: false,
      lang1: '',
      lang2: '',
      lang3: '',
      lang4: '',
      lang5: '',
      havealert: false,
      havemssgalert: '',
      state: [],
      statusType: [
        { text: 'Prijavljeno', value: 'Reported' },
        { text: 'Zaprimljeno', value: 'Received' },
        { text: 'U tijeku', value: 'InProgress' },
        { text: 'Završeno', value: 'Done' },
        { text: 'Preuzeto', value: 'Delivered' }
      ],
      searchName: "",
      page: 1,
      totalPages: 0,
      pageSize: 30,
      pageSizes: [10, 30, 50],
      searchOption: '',
      searchOptions: [],
      totalItems: 0,
      showOnlyEUR: true
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    
    this.defaultInit()
  },
  methods: {
     localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    localName(nameToFormat) {
      return documentLocalName(nameToFormat)
    },
    displayPartnerOnly( partnerVAT, partnerName,First, Last) {
      if (partnerVAT !== '00000000000')
        { 
          return partnerName 
        }
      if (partnerVAT === '00000000000')
        {return (First ? First : 'Privatni') + ' ' + (Last ? Last : ' kupac')}
    },

    async handlePageChange(value) {      
      const query = this.$store.state.onPage
      query.page = value - 1;
      this.page = value
      await this.$store.dispatch('setOnPage', query)
      this.defaultInit();
    },

    async handlePageSizeChange(size) {
      const query = this.$store.state.onPage
      query.pageSize = size;
      query.page = 1;
      this.pageSize = size
      await this.$store.dispatch('setOnPage', query)
      this.defaultInit();
    },

    async handleSearchOption(option) {
      this.searchOption = option
      const query = this.$store.state.onPage
      query.searchItem = option
      query.page = 0
      await this.$store.dispatch('setOnPage', query)
    },

    setQuery() {
      const haveOnPage = this.$store.state.onPage
      const query = {}
      if (this.$store.state.companyid) {
        query.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        query.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentActiv) {
        query.documentName = this.$store.state.documentActiv
      }
      const docSide = this.$store.state.documentSide ? this.$store.state.documentSide : 0
      if (docSide === 1 || docSide === 2) {
        query.WareHouseId = this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1
      }

      // query.searchItem = null
      query.searchName = haveOnPage.searchName ? haveOnPage.searchName.trim() : null
      query.page = haveOnPage.page ? haveOnPage.page : 0
      this.page  = query.page + 1
      query.pageSize = haveOnPage.pageSize ? haveOnPage.pageSize : 30
      this.pageSize = query.pageSize
      query.searchItem = haveOnPage.searchItem ? haveOnPage.searchItem : null
      return query
    },

    async defaultInit() {
      this.showOnlyEUR = this.$store.state.businessYear && this.$store.state.businessYear <= 2022 ? false : true
      this.documentName = null
      this.documentNameLoc = null
      this.items.length = 0
      this.headers.length = 0
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      const query = this.setQuery()
      if (query.searchItem && this.searchName) {
        query.searchName =  this.searchName.trim()
        await this.$store.dispatch('setOnPage', query)
      }
      
      if (query.searchItem && query.searchName) {
        this.searchOption = query.searchItem
        this.searchName = query.searchName 
      }
      
      if (this.productiontype) {
        if (this.productiontype === 1 || this.productiontype === 5) {
          this.mainQuery.productiontype = this.productiontype
        }
      }
      if (this.documentName === 'POS') {
        if (this.$store.state.typePOSPrint === 10) {   
          // this.mainQuery.fromDate = new Date().toLocaleDateString()
          // this.mainQuery.toDate = new Date().toLocaleDateString()
          this.mainQuery.fromDate = new Date()
          this.mainQuery.toDate = new Date()
        }
        if (this.$store.state.typePOSPrint === 11) { 
          // yesterday
          let datePOS = new Date()
          datePOS.setDate(datePOS.getDate() - 1)
          this.mainQuery.fromDate = datePOS
          this.mainQuery.toDate = datePOS
        }
      }
      this.$store.dispatch('seteditItem', 0)
      this.saving = true
      await DocumentService.indexlist(query).then(res => {
        this.items = res.data.documents ? res.data.documents : []
        console.log('this.items', this.items)
        this.totalItems = res.data.totalPages
        this.totalPages = parseInt(res.data.totalPages / this.pageSize) + 1
      })
      .catch(err => {
        this.havealert = !this.havealert
        this.havemssgalert = 'Error' + err
      })

      this.saving = false
      
      this.lang1 = this.lang.Description4
      this.lang2 = this.lang.Description1
      this.lang3 = this.lang.Description5
      this.lang4 = this.lang.Description3

      this.searchOptions.push(
        {text: this.lang.partnerName, value: 'partnerName'},
        {text: this.lang.documentId, value: 'documentId', width: '90px'},
        {text: 'Status', value: 'status', width: '90px'},
        {text: this.lang.email, value: 'email', width: '150px'},
      )
      if (this.documentName === 'ServiceNotes') {
        if (this.$store.state.user && this.$store.state.user.vehicles && this.$store.state.user.vehicles === true) {
          this.searchOptions.push(
            {text: this.lang.vehicleDesc, value: 'vehicleDesc'},
          )
        }
      }

      this.headers.push(
        {text: this.lang.documentId, value: 'documentId', width: '90px'},
        {text: this.lang.documentDate1, value: 'documentDate', width: '50px'},
        {text: this.lang.documentInvoiceEndDate, value: 'documentInvoiceEndDate', width: '50px'},
        {text: this.lang.partnerName, value:'partnerVATID', width: '250px', align: 'left'},
      )
  
     if (this.documentName !== 'WorkingOrder') { 
       this.headers.push(
        {text: this.lang.documentTotalAmountBrutto, value: 'documentTotalAmountBrutto', type: 'decimal', width: '120px', align: 'right'},
        {text: this.lang.documentHomeCurrency, value: 'documentHomeCurrency', width: '40px'}
       )
     }
      if (this.documentName === 'ServiceNotes') {
          if (this.$store.state.user && this.$store.state.user.vehicles && this.$store.state.user.vehicles === true) {
            this.headers.push(
              {text: 'Vozilo', value: 'vehicleDesc', width: '250px'},
            )
          }
          this.headers.push(
            {text: this.lang.ServiceItem, value: 'documentDescription4', width: '250px'},
            {text: this.lang.ServiceProblem, value: 'documentDescription1', width: '350px'},
            {text: this.lang.ServiceStatus, value: 'status', width: '100px'},
            {text: this.lang.email, value: 'email', width: '150px'},
            {text: this.lang.id, value: 'id', width: '50px'}
          )
          this.searchOptions.push(
            {text: this.lang.ServiceStatus, value: 'status', width: '100px'},
          )
      }
      if (this.documentName === 'Inventory') {
          this.headers.push(
            {text: this.lang.email, value: 'email', width: '150px'},
            {text: this.lang.id, value: 'id', width: '50px'}
          )
      }
      if (this.documentName === 'PriceChange') {
          this.headers.push(          
            {text: this.lang.newFullTotal, value: 'newFullTotal', width: '100px', align: 'right'},
            {text: this.lang.Description4, value: 'documentDescription4', width: '450px'},
            {text: this.lang.id, value: 'id', width: '50px'}
          )
      }
      if (this.documentName === 'ReceiptPurchase') {
          this.headers.push(    
             {text: this.lang.exportDoc, value: 'exportDoc', width: '40px'},      
            {text: this.lang.supplierDocument, value: 'supplierDocument', width: '100px', align: 'right'},
            {text: this.lang.supplierDocumentNr, value: 'supplierDocumentNr', width: '150px'},
            {text: this.lang.supplierDocumentDate, value: 'supplierDocumentDate', width: '150px'},
            {text: this.lang.documentConnNameFrom, value: 'documentConnNameFrom', width: '150px'},
            {text: this.lang.documentConnName, value: 'documentConnName', width: '150px'},
            {text: 'Status', value: 'docStatus', width: '120px'},
            {text: this.lang.id, value: 'id', width: '50px'}
          )
          this.searchOptions.push(
            {text: this.lang.supplierDocument, value: 'supplierDocument', width: '100px', align: 'right'},
            {text: this.lang.supplierDocumentNr, value: 'supplierDocumentNr', width: '150px'},
            {text: this.lang.supplierDocumentDate, value: 'supplierDocumentDate', width: '150px'},
            {text: 'Status', value: 'docStatus', width: '120px'},
          )
      }
      if (this.documentName === 'WorkingOrder') {
        this.headers.push(
          {text: this.lang.FreeF4WO, value: 'FreeF4', width: '110px', align: 'right'},
          {text: this.lang.documentHomeCurrency, value: 'documentHomeCurrency', width: '40px'},
          // {text: this.lang.documentTotalAmountBruttoForeign, value: 'documentTotalAmountBruttoForeign', type: 'decimal', width: '100px'},
          // {text: this.lang.documentForeignCurrency, value: 'documentForeignCurrency', width: '40px'},
          {text: this.lang.bankAccountName, value: 'bankAccountName', width: '140px'},
          {text: this.lang.email, value: 'email', width: '200px'},
          {text: this.lang.exportDoc, value: 'exportDoc', width: '40px'},
          {text: 'Status', value: 'productiontype', width: '120px'},
          {text: this.lang.documentConnNameFrom, value: 'documentConnNameFrom', width: '150px'},
          {text: this.lang.documentConnName, value: 'documentConnName', width: '150px'},
          {text: this.lang.id, value: 'id', width: '50px'}
        )
        this.searchOptions.push(
          {text: 'Status', value: 'productiontype', width: '120px'},
          {text: this.lang.documentConnNameFrom, value: 'documentConnNameFrom', width: '150px'},
          {text: this.lang.documentConnName, value: 'documentConnName', width: '150px'},
        )
      }
      if (this.documentName !== 'ServiceNotes' && this.documentName !== 'Inventory' && this.documentName !== 'PriceChange' && this.documentName !== 'ReceiptPurchase' && this.documentName !== 'WorkingOrder') {
        if (!this.showOnlyEUR) {
          this.headers.push(
            {text: this.lang.documentTotalAmountBruttoForeign, value: 'documentTotalAmountBruttoForeign', type: 'decimal', width: '100px'},
            {text: this.lang.documentForeignCurrency, value: 'documentForeignCurrency', width: '40px'},
          )
        }
        this.headers.push(
          {text: this.lang.bankAccountName, value: 'bankAccountName', width: '150px'},
          {text: this.lang.email, value: 'email', width: '120px'},
          {text: this.lang.exportDoc, value: 'exportDoc', width: '40px'},
          {text: 'Status', value: 'docStatus', width: '120px'},
          {text: this.lang.documentConnNameFrom, value: 'documentConnNameFrom', width: '150px'},
          {text: this.lang.documentConnName, value: 'documentConnName', width: '150px'},
          {text: this.lang1, value: 'documentDescription4', width: '250px'},
          {text: this.lang.id, value: 'id', width: '50px'}
        )
       
        this.searchOptions.push(
          {text: 'Status', value: 'docStatus', width: '120px'},
          {text: this.lang.documentConnNameFrom, value: 'documentConnNameFrom', width: '150px'},
          {text: this.lang.documentConnName, value: 'documentConnName', width: '150px'},
        )
      }
    },
    async navigateTo (params) {
      try {                
        
        const dbDocument = await DocumentService.show(params.id)
        if (dbDocument) {
          this.$store.dispatch('setDocumentActivHead', dbDocument.data.document)
        } else {
          this.$store.dispatch('setDocumentActivHead', {})
        }
        await this.$store.dispatch('setDocumentid', params.id)
        await this.$store.dispatch('setDatabaseDocID', params.id)
        this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
        this.mainQuery.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
        this.mainQuery.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : null
        this.mainQuery.databaseDocID = params.id
        this.docSide = this.$store.state.documentSide ? this.docSide = this.$store.state.documentSide : null

        this.items = (await DocumentDetailService.index(this.mainQuery)).data.documentDetails
        if (this.items) {
          this.$store.dispatch('setDocumentActivItems', this.items)
        } else {
          this.$store.dispatch('setDocumentActivItems', [])
        }
        this.$store.dispatch('setprintSmall', false)
        this.$store.dispatch('setprintNoPrice', false)
        this.$store.dispatch('settypePOSPrint', null)
        if (this.$store.state.documentActiv !== 'WorkingOrder') {
            this.$router.push({
            name: 'documentview'
          })
          } else {
            this.$router.push({
            name: 'documentview3'
          })
        }
        
      } catch (err) {
        // eslint-disable-next-line
        console.log('error read from database', err)
      }      
    },
    async defaultQuery() {
      const query = {}
      if (this.$store.state.companyid) {
        query.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        query.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentActiv) {
        query.documentName = this.$store.state.documentActiv
      }
      query.searchName = null
      query.page =  0
      query.pageSize =  30
      query.searchItem =  null
      await this.$store.dispatch('setOnPage', query)
      this.page = 1
      this.totalPages = 0
      this.pageSize = 30
      this.mainQuery = query
    },
    async initAgain () {
      this.defaultQuery()
      this.defaultInit()
    },

    formatFn (value) {
      if (value === true) {
        return 'Yes'
      } else {
        return ''
      }
    },
    formatFnHR (value) {
      if (value === true) {
        return 'Da'
      } else {
        return ''
      }
    },
    getColor (status) {
        if (status === 1) return 'green lighten-2'
        else if (status === 2) return 'green darken-2'
        else if (status === 3) return 'yellow darken-2'
        else if (status === 4) return 'light-blue darken-4'
        else return 'light-blue lighten-3'
      },
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
    localStatus(status) {
      if (status === 1) return 'Priprema'
      else if (status === 2) return 'Za proizvodnju'
      else if (status === 3) return 'U tijeku'
      else if (status === 4) return 'Proizvedeno'
      else return 'Otpremljeno'
      // console.log('status ', status)
      // const value = status ? status : 'Received'
      // const result = this.statusType.filter(status => status.value === value)
      // return result[0].text
    }
  },
  computed: {
    ...mapGetters({
      myDocumentActivState: 'NeedDocumentActiv'
    }),
    isVisible() {
      return this.items.length > 0;
    },
  },
  watch: {
    'myDocumentActivState' () {
      this.initAgain()
    }
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>


