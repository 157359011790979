const banksHR = {
  BankAccounts: 'Žiro računi',
  IBAN: 'IBAN *',
  SWIFT: 'SWIFT *',
  BankName: 'Naziv banke *',
  BankAccountDescription: 'Opis računa',
  BankAdress: 'Sjedište banke',
  LedgerAccount: 'Konto',
  DefaultAccount: 'Default račun',
  Saved: 'Žiro račun uspješno snimljen u bazu.',
  Error: ' Greška kod snimanja ...',
  AccountID: 'Konto',
  PrimaryCurrency: 'Primarna valuta',
  RequiredF: 'Ovo polje je obavezno!',
  ErrorG: 'Greska! Nisu popunjena sva obavezna polja!',
  BankAccountDetails: 'Žiro račun - detaljno',
  BankAccountID: 'Žiro račun - baza broj',
  id: 'DbId',
  createdAt: 'Kreiran'
}

export default banksHR
