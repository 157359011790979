<template>
    <v-container fluid grid-list-md>
      <v-row no-gutters justify="center" class="pa-0">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
          <v-row no-gutters>
            <h2>
              <span v-if="this.$store.state.editItem  === 0">
                {{langC.CreateNew}} - {{ lang.TravelOrder }}
              </span>
              <span v-if="this.$store.state.editItem === 1">
                {{langC.Edit}} - {{ lang.TravelOrder }}
              </span>                  
            </h2>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                {{lang.CompanyName}} <span style="font-weight:bold">{{travelorder.CompanyNameDatabase}}</span>
            </v-col>
            <v-col cols="12" sm="4" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                {{lang.BusinessYear}} <span style="font-weight:bold">{{travelorder.BusinessYear}}</span>
            </v-col>
            <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="pr-3" >
                <v-btn
                  text color="primary"
                  @click="printTrvl(1)">
                  Print nalog
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="pr-3" >
                <v-btn
                  text color="primary"
                  @click="printTrvl(2)">
                  Print nalog i obračun
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="pr-3" >
                <v-btn
                  text color="green"
                  @click="duplicate()">
                  Dupliciraj
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="pr-3" >
                <v-btn
                  text color="red"
                  @click="deleteTO()">
                 {{langC.Delete}}
                </v-btn>
              </v-col>
          </v-row>
          <v-divider class="py-3"></v-divider>

          <v-row no-gutters>
              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.TravelOrderId"
                    v-model.number="travelorder.TravelOrderId"
                    type="number"
                  ></v-text-field>
              </v-col>
               <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                  <v-dialog
                    ref="dialog7"
                    persistent
                    v-model="modal7"
                    width="290px"
                    :return-value.sync="travelorder.OrderDate"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="travelorder.OrderDate"
                      :label="lang.OrderDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
                    <v-date-picker v-model="travelorder.OrderDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal7 = false">{{langC.Cancel}}</v-btn>
                      <v-btn color="primary" @click="$refs.dialog7.save(travelorder.OrderDate)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
               </v-col>
               <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                  <v-autocomplete
                      :items="emps"
                      v-model="selectEmp"
                      :label="lang.SelectEmployee"
                      item-text="eemail"
                      item-value="id"
                      return-object
                      persistent-hint
                      clearable
                    ></v-autocomplete>
               </v-col>
               <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.eFirst"
                      v-model="travelorder.eFirst"
                      disabled
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.eLast"
                      v-model="travelorder.eLast"
                      disabled
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.eVatID"
                      v-model="travelorder.eVatID"
                      disabled
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.WorkPlace"
                      v-model="travelorder.WorkPlace"
                      disabled
                    ></v-text-field> 
                </v-col>
          </v-row>
          <v-row no-gutters>
                <v-col cols="12" sm="6" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.TravelTo"
                      v-model="travelorder.TravelTo"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="6" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.TravelTask"
                      v-model="travelorder.TravelTask"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                  <v-dialog
                    ref="dialog1"
                    persistent
                    v-model="modal1"
                    width="290px"
                    :return-value.sync="travelorder.TravelDate"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="travelorder.TravelDate"
                      :label="lang.TravelDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
                    <v-date-picker v-model="travelorder.TravelDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal1 = false">{{langC.Cancel}}</v-btn>
                      <v-btn color="primary" @click="$refs.dialog1.save(travelorder.TravelDate)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.TravelLastDays"
                      v-model="travelorder.TravelLastDays"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.TravelLastDaysLett"
                      v-model="travelorder.TravelLastDaysLett"
                    ></v-text-field> 
                </v-col>
                
          </v-row>

          <v-row no-gutters>
                <v-col cols="12" sm="6" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.TravelVeichle"
                      v-model="travelorder.TravelVeichle"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="6" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.TravelVeichleBrand"
                      v-model="travelorder.TravelVeichleBrand"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.TravelVeichleReg"
                      v-model="travelorder.TravelVeichleReg"
                    ></v-text-field> 
                </v-col>
          </v-row> 
          <v-row no-gutters>
              <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.ExpensesForwardHomeC"
                    v-model.number="travelorder.ExpensesForwardHomeC"
                    type="number"
                  ></v-text-field> 
              </v-col>
              <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.ExpensesForwardForeC"
                    v-model.number="travelorder.ExpensesForwardForeC"
                    type="number"
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.ReportInDays"
                    v-model.number="travelorder.ReportInDays"
                    type="number"
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                <v-autocomplete
                      :items="emps"
                      v-model="selectEmp2"
                      :label="lang.SelectApp"
                      item-text="eemail"
                      item-value="id"
                      return-object
                      persistent-hint
                      clearable
                    ></v-autocomplete>
                </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.ApprovedByFirst"
                    v-model="travelorder.ApprovedByFirst"
                    disabled
                  ></v-text-field> 
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.ApprovedByLast"
                    v-model="travelorder.ApprovedByLast"
                    disabled
                  ></v-text-field> 
              </v-col>
          </v-row>

          <v-divider class="py-3"></v-divider>
            <v-row no-gutters>
              <h3>
                {{ lang.TravelCosting }}
              </h3>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" sm="6" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.FinTravel1"
                      v-model="travelorder.FinTravel1"
                    ></v-text-field> 
                </v-col>

                <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                  <v-dialog
                    ref="dialog2"
                    persistent
                    v-model="modal2"
                    width="290px"
                    :return-value.sync="travelorder.Travel2Date"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="travelorder.Travel2Date"
                      :label="lang.Travel2Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                    <v-date-picker v-model="travelorder.Travel2Date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal2 = false">{{langC.Cancel}}</v-btn>
                      <v-btn color="primary" @click="$refs.dialog2.save(travelorder.Travel2Date)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                  <v-dialog
                    ref="dialog3"
                    persistent
                    v-model="modal3"
                    width="290px"
                    :return-value.sync="travelorder.Travel3Date"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="travelorder.Travel3Date"
                      :label="lang.Travel3Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                    <v-date-picker v-model="travelorder.Travel3Date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal3 = false">{{langC.Cancel}}</v-btn>
                      <v-btn color="primary" @click="$refs.dialog3.save(travelorder.Travel3Date)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
          </v-row>

            <v-row no-gutters class="py-3">
              <h3>
                {{ lang.CostingDays }}
              </h3>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                  <v-dialog
                    ref="dialog4"
                    persistent
                    v-model="modal4"
                    width="290px"
                    :return-value.sync="travelorder.FinStartDate"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="travelorder.FinStartDate"
                      :label="lang.FinStartDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                    <v-date-picker v-model="travelorder.FinStartDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal4 = false">{{langC.Cancel}}</v-btn>
                      <v-btn color="primary" @click="$refs.dialog4.save(travelorder.FinStartDate)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                  <v-dialog
                    ref="dialog5"
                    persistent
                    v-model="modal5"
                    width="290px"
                    :return-value.sync="travelorder.FinEndDate"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="travelorder.FinEndDate"
                      :label="lang.FinEndDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                    <v-date-picker v-model="travelorder.FinEndDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal5 = false">{{langC.Cancel}}</v-btn>
                      <v-btn color="primary" @click="$refs.dialog5.save(travelorder.FinEndDate)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                 <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1">
                     <v-dialog
                      ref="dialogTS"
                      v-model="modalTS"
                      :return-value.sync="travelorder.FinStartTime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="travelorder.FinStartTime"
                          :label="lang.FinStartTime"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="modalTS"
                        v-model="travelorder.FinStartTime"
                        full-width
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modalTS = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.dialogTS.save(travelorder.FinStartTime)">OK</v-btn>
                      </v-time-picker>
                    </v-dialog>
                </v-col>

                 <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1">
                     <v-dialog
                      ref="dialogTE"
                      v-model="modalTE"
                      :return-value.sync="travelorder.FinEndTime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="travelorder.FinEndTime"
                          :label="lang.FinEndTime"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="modalTE"
                        v-model="travelorder.FinEndTime"
                        full-width
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modalTE = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.dialogTE.save(travelorder.FinEndTime)">OK</v-btn>
                      </v-time-picker>
                    </v-dialog>
                </v-col>

                <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.FinHours"
                      v-model.number="travelorder.FinHours"
                      type = "number"
                    ></v-text-field> 
                </v-col>

                <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.FinDays"
                      v-model="travelorder.FinDays"
                      type = "number"
                    ></v-text-field> 
                </v-col>

                <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.FinDaysAmount"
                      v-model="travelorder.FinDaysAmount"
                      type = "number"
                    ></v-text-field> 
                </v-col>

                <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.FinDaysTotal"
                      v-model="travelorder.FinDaysTotal"
                      type = "number"
                      disabled
                    ></v-text-field> 
                </v-col>

          </v-row>
            <v-row no-gutters class="py-3">
              <h3>
                {{ lang.CostingTravles }}
              </h3>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinTrStart"
                    v-model.number="travelorder.FinTrStart"
                    type = "number"
                  ></v-text-field> 
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinTrEnd"
                    v-model="travelorder.FinTrEnd"
                    type = "number"
                  ></v-text-field> 
              </v-col>
              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    label="Km"
                    v-model="counterKm"
                    type = "number"
                    disabled
                  ></v-text-field> 
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.CostByKm"
                    v-model="travelorder.CostByKm"
                    type = "number"
                  ></v-text-field>
              </v-col>

               <v-col cols="12" sm="6" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">

                </v-col>
              
               <v-col cols="12" sm="4" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.FinDaysEUR"
                      v-model="travelorder.FinDaysEUR"
                      type = "number"
                    ></v-text-field> 
                </v-col>

                <v-col cols="12" sm="4" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.FinDaysAmountEUR"
                      v-model="travelorder.FinDaysAmountEUR"
                      type = "number"
                    ></v-text-field> 
                </v-col>

                <v-col cols="12" sm="4" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                  <v-text-field class="pr-2"
                      :label="lang.FinDaysTotalEUR"
                      v-model="travelorder.FinDaysTotalEUR"
                      type = "number"
                      disabled
                    ></v-text-field> 
                </v-col>

          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="6" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel1From"
                    v-model="travelorder.FinRel1From"
                  ></v-text-field> 
              </v-col>

              <v-col cols="12" sm="6" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel1To"
                    v-model="travelorder.FinRel1To"
                  ></v-text-field> 
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel1Km"
                    v-model.number="travelorder.FinRel1Km"
                    type = "number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel1KmKn"
                    v-model.number="travelorder.FinRel1KmKn"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel1KmEur"
                    v-model.number="travelorder.FinRel1KmEur"
                    type = "number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel1Total"
                    v-model.number="travelorder.FinRel1Total"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel1TotalEur"
                    v-model.number="travelorder.FinRel1TotalEur"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="6" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel2From"
                    v-model="travelorder.FinRel2From"
                  ></v-text-field> 
              </v-col>

              <v-col cols="12" sm="6" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel2To"
                    v-model="travelorder.FinRel2To"
                  ></v-text-field> 
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel2Km"
                    v-model.number="travelorder.FinRel2Km"
                    type = "number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel2KmKn"
                    v-model.number="travelorder.FinRel2KmKn"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel2KmEur"
                    v-model.number="travelorder.FinRel2KmEur"
                    type = "number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel2Total"
                    v-model.number="travelorder.FinRel2Total"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel2TotalEur"
                    v-model.number="travelorder.FinRel2TotalEur"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="6" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel3From"
                    v-model="travelorder.FinRel3From"
                  ></v-text-field> 
              </v-col>

              <v-col cols="12" sm="6" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel3To"
                    v-model="travelorder.FinRel3To"
                  ></v-text-field> 
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel3Km"
                    v-model.number="travelorder.FinRel3Km"
                    type = "number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel3KmKn"
                    v-model.number="travelorder.FinRel3KmKn"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel3KmEur"
                    v-model.number="travelorder.FinRel3KmEur"
                    type = "number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel3Total"
                    v-model.number="travelorder.FinRel3Total"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel3TotalEur"
                    v-model.number="travelorder.FinRel3TotalEur"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="6" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel4From"
                    v-model = "travelorder.FinRel4From"
                  ></v-text-field> 
              </v-col>

              <v-col cols="12" sm="6" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel4To"
                    v-model="travelorder.FinRel4To"
                  ></v-text-field> 
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel4Km"
                    v-model.number="travelorder.FinRel4Km"
                    type = "number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel4KmKn"
                    v-model.number="travelorder.FinRel4KmKn"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel4KmEur"
                    v-model.number="travelorder.FinRel4KmEur"
                    type = "number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel4Total"
                    v-model.number="travelorder.FinRel4Total"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel4TotalEur"
                    v-model.number="travelorder.FinRel4TotalEur"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 px-2">
                  {{lang.CostingTravles}}
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinRel5Km"
                    v-model.number = "travelorder.FinRel5Km"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">

              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">

              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label = "lang.FinRel5Total"
                    v-model.number = "travelorder.FinRel5Total"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label = "lang.FinRel5TotalEur"
                    v-model = "travelorder.FinRel5TotalEur"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <h4>
                {{ lang.OtherExp }}
              </h4>
            </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="6" md="9" xs="9" lg="9" xl="9" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label = "lang.FinOther1"
                    v-model = "travelorder.FinOther1"
                  ></v-text-field> 
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label = "lang.FinOther1Kn"
                    v-model.number = "travelorder.FinOther1Kn"
                    type = "number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label = "lang.FinOther1Eur"
                    v-model.number = "travelorder.FinOther1Eur"
                    type = "number"
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="6" md="9" xs="9" lg="9" xl="9" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label = "lang.FinOther2"
                    v-model = "travelorder.FinOther2"
                  ></v-text-field> 
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label = "lang.FinOther2Kn"
                    v-model.number = "travelorder.FinOther2Kn"
                    type = "number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label = "lang.FinOther2Eur"
                    v-model.number = "travelorder.FinOther2Eur"
                    type = "number"
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="6" md="9" xs="9" lg="9" xl="9" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label = "lang.FinOther3"
                    v-model = "travelorder.FinOther3"
                  ></v-text-field> 
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label = "lang.FinOther3Kn"
                    v-model.number = "travelorder.FinOther3Kn"
                    type = "number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label = "lang.FinOther3Eur"
                    v-model.number = "travelorder.FinOther3Eur"
                    type = "number"
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="6" md="9" xs="9" lg="9" xl="9" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label = "lang.FinOther4"
                    v-model = "travelorder.FinOther4"
                  ></v-text-field> 
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label = "lang.FinOther4Kn"
                    v-model.number = "travelorder.FinOther4Kn"
                    type = "number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label = "lang.FinOther4Eur"
                    v-model.number = "travelorder.FinOther4Eur"
                    type = "number"
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="6" md="9" xs="9" lg="9" xl="9" class="py-0 px-2">

              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label = "lang.FinOther5Kn"
                    v-model.number = "travelorder.FinOther5Kn"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label = "lang.FinOther5Eur"
                    v-model.number = "travelorder.FinOther5Eur"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="6" md="9" xs="9" lg="9" xl="9" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label = "lang.FinTotal"
                    v-model = "travelorder.FinTotal"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label = "lang.FinTotalKn"
                    v-model = "travelorder.FinTotalKn"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label = "lang.FinTotalEur"
                    v-model = "travelorder.FinTotalEur"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label = "lang.FinEndPlace"
                    v-model = "travelorder.FinEndPlace"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                  <v-dialog
                    ref="dialog6"
                    persistent
                    v-model="modal6"
                    width="290px"
                    :return-value.sync="travelorder.FinEndDate2"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="travelorder.FinEndDate2"
                      :label="lang.FinEndDate2"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                    <v-date-picker v-model="travelorder.FinEndDate2" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal6 = false">{{langC.Cancel}}</v-btn>
                      <v-btn color="primary" @click="$refs.dialog6.save(travelorder.FinEndDate2)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

              <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                <v-select
                  :items="users"
                  v-model="select"
                  :label="lang.PrintControlBy"
                  item-text="email"
                  item-value="id"
                  return-object
                  persistent-hint
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinPayReturn"
                    v-model="travelorder.FinPayReturn"
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" >
                <v-checkbox
                    v-model="travelorder.Finished"
                    :label="lang.Finished"
                    class="mt-0 pt-0"
                  ></v-checkbox>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinPayReturnKn"
                    v-model="travelorder.FinPayReturnKn"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="py-0 px-2">
                <v-text-field class="pr-2"
                    :label="lang.FinPayReturnEur"
                    v-model="travelorder.FinPayReturnEur"
                    type = "number"
                    disabled
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="6" md="9" xs="9" lg="9" xl="9" class="py-0 px-2">
              <v-textarea
                v-model="travelorder.FinReport"
                auto-grow
                filled
                color="deep-purple"
                :label="lang.FinReport"
                rows="2"
              ></v-textarea>
              </v-col>
          </v-row>


         <v-divider></v-divider>
         <v-btn
            class="mr-3"
            color="primary"
            @click="register" >
            {{langC.Save}}
          </v-btn>

          <v-btn
            color="green"
            @click="travelorderlist">
            {{langC.Back}}
          </v-btn>
      </v-col>
      </v-row>
       <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        bottom
        :color="color"
        >
        {{ text }}
      </v-snackbar>
      <TravelOrderPrint ref="prnt" :trvl="trvl" :all="all" />
    </v-container>
</template>

<script>

import TravelOrderService from '@/services/TravelOrderService'
import EmployeeService from '@/services/EmployeeService'
import UserService from '@/services/UserService'
import TravelOrderPrint from './TravelOrderPrint'
import ledgerEN from './travelorderDescEn'
import ledgerHR from './travelorderDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import { defTravelOrder, defNeWTravelOrder } from '../MainLedger/mainledgerHelper'
import { round, parseNum} from '@/snippets/allSnippets'
import dayjs from 'dayjs'

export default {
  name: 'travelorder',
  data () {
    return {
      menu: false,
      modal1: false,
      dialog1: false,
      modal2: false,
      dialog2: false,
      modal3: false,
      dialog3: false,
      modal4: false,
      dialog4: false,
      modal5: false,
      dialog5: false,
      modal6: false,
      dialog6: false,
      modal7: false,
      dialog7: false,
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      lang: {},
      langC: {},
      travelorder: {},
      mainQuery: {
      },
      haveNew: false,
      required: (value) => !!value || 'Required.',
      rules: {
        required: value => !!value || '*'
      },
      selectEmp: {},
      selectEmp2: {},
      emps: [],
      users: [],
      select: { },
      trvl: {},
      all: 1,
      modalTS: false,
      dialogTS: false,
      modalTE: false,
      dialogTE: false,
      counterKm: 0
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc ? this.$store.state.documentActivLoc : ' '
    if (this.$store.state.companyid) {
      this.CompanyId = this.$store.state.companyid
      this.mainQuery.CompanyId = parseInt(this.$store.state.companyid)
    }
    this.mainQuery.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2023

    const {data} = (await UserService.index())
    if (data.users && data.users.length > 0) {
      this.users = data.users
    }
    const data2 = (await EmployeeService.choose(this.mainQuery)).data
    if (data2.employes && data2.employes.length > 0) {
      this.emps = data2.employes
    } else {
      this.timeout = 2000
      this.text = this.lang.ErrorUser
      this.color = 'red'
      this.snackbar = true
    }
    
    if (this.$store.state.editItem === 0) {
      this.travelorder = {...defTravelOrder(), ...defNeWTravelOrder()}
      this.localUser = this.travelorder.First + ' ' + this.travelorder.Last + ', ' + this.travelorder.email
      const {data} = await TravelOrderService.choose(this.mainQuery)
      this.travelorder.TravelOrderId = data.docNumber && !isNaN(data.docNumber) ? parseInt(data.docNumber) : 1
      this.travelorder.ReportInDays = 1
      this.haveNew = true
      this.travelorder.CostByKm = 0.40
    }
    if (this.$store.state.editItem === 1) {
      const {data} = await TravelOrderService.show(this.$store.state.mainledgerid)
      this.travelorder = data.travelorder && Object.keys(data.travelorder).length !== 0 ? data.travelorder : {}
      //console.log('ucitao ', data)
      this.counterKm = (this.travelorder.FinTrEnd ? this.travelorder.FinTrEnd : 0) - (this.travelorder.FinTrStart ? this.travelorder.FinTrStart : 0)

      const selUsr = this.users.filter(user => user.id === this.travelorder.UserIdControl)
      const sU = selUsr[0]
      if (sU) {
        this.select = sU.id
      }

      const selEmp = this.emps.filter(emp => emp.id === this.travelorder.eId)
      if (selEmp[0]) {
        this.selectEmp = selEmp[0]
      }

      const selEmp2 = this.emps.filter(emp => emp.id === this.travelorder.ApprovedBy)
      if (selEmp2[0]) {
        this.selectEmp2 = selEmp2[0]
      }

     this.doParse()
    }
  },
  methods: {
    async printTrvl(type){
      this.trvl = {...this.travelorder}
      this.all = type
      await this.$refs.prnt.print()
    },
    ifParse(nr, dec){
      if (!nr) {
        return 0
      }
      if (isNaN(nr)) {
        return 0
      }
      if (nr.typeof === 'boolean') {
        return 0
      }
      if (nr.typeof === 'string') {
        
        return round(parseFloat(nr), dec)
      }
      if (nr.typeof === 'number') {
        return round(nr, dec)
      }
    },
    doParse() {
      console.log('travelorder', this.travelorder)
      this.travelorder.OrderDate = this.travelorder.OrderDate ? dayjs(this.travelorder.OrderDate).format('YYYY-MM-DD') : null
      this.travelorder.TravelDate = this.travelorder.TravelDate ? dayjs(this.travelorder.TravelDate).format('YYYY-MM-DD') : null
      this.travelorder.Travel2Date = this.travelorder.Travel2Date ? dayjs(this.travelorder.Travel2Date).format('YYYY-MM-DD') : null
      this.travelorder.Travel3Date = this.travelorder.Travel3Date ? dayjs(this.travelorder.Travel3Date).format('YYYY-MM-DD') : null
      this.travelorder.FinStartDate = this.travelorder.FinStartDate ? dayjs(this.travelorder.FinStartDate).format('YYYY-MM-DD') : null
      this.travelorder.FinEndDate = this.travelorder.FinEndDate ? dayjs(this.travelorder.FinEndDate).format('YYYY-MM-DD') : null
      this.travelorder.FinEndDate2 = this.travelorder.FinEndDate2 ? dayjs(this.travelorder.FinEndDate2).format('YYYY-MM-DD') : null

      this.travelorder.TravelLastDays = parseNum(this.travelorder.TravelLastDays, 0)
      this.travelorder.TravelLastDays = parseNum(this.travelorder.TravelLastDays,0)
      this.travelorder.ExpensesForwardHomeC = parseNum(this.travelorder.ExpensesForwardHomeC, 2)
      this.travelorder.ExpensesForwardForeC = parseNum(this.travelorder.ExpensesForwardForeC, 2)
      this.travelorder.ReportInDays = parseNum(this.travelorder.ReportInDays, 0)
      this.travelorder.FinHours = parseNum(this.travelorder.FinHours, 2)
      this.travelorder.FinDays = parseNum(this.travelorder.FinDays,2 )
      this.travelorder.FinDaysAmount = parseNum(this.travelorder.FinDaysAmount, 2)
      this.travelorder.FinDaysTotal = parseNum(this.travelorder.FinDaysTotal, 2)
      this.travelorder.FinDaysEUR = parseNum(this.travelorder.FinDaysEUR, 2)
      this.travelorder.FinDaysAmountEUR = parseNum(this.travelorder.FinDaysAmountEUR, 2)
      this.travelorder.FinDaysTotalEUR = parseNum(this.travelorder.FinDaysTotalEUR, 2)
      this.travelorder.FinTrStart = parseNum(this.travelorder.FinTrStart,2 )
      this.travelorder.CostByKm = parseNum(this.travelorder.CostByKm, 2)
      this.travelorder.FinRel1Km = parseNum(this.travelorder.FinRel1Km, 2)
      this.travelorder.FinRel1KmKn = parseNum(this.travelorder.FinRel1KmKn, 2)
      this.travelorder.FinRel1KmEur = parseNum(this.travelorder.FinRel1KmEur, 2)
      this.travelorder.FinRel1Total = parseNum(this.travelorder.FinRel1Total, 2)
      this.travelorder.FinRel1TotalEur = parseNum(this.travelorder.FinRel1TotalEur, 2)
      this.travelorder.FinRel2Km = parseNum(this.travelorder.FinRel2Km, 2)
      this.travelorder.FinRel2KmKn = parseNum(this.travelorder.FinRel2KmKn, 2)
      this.travelorder.FinRel2KmEur = parseNum(this.travelorder.FinRel2KmEur, 2)
      this.travelorder.FinRel2Total = parseNum(this.travelorder.FinRel2Total, 2)
      this.travelorder.FinRel2TotalEur = parseNum(this.travelorder.FinRel2TotalEur, 2)
      this.travelorder.FinRel3Km = parseNum(this.travelorder.FinRel3Km, 2)
      this.travelorder.FinRel3KmKn = parseNum(this.travelorder.FinRel3KmKn, 2)
      this.travelorder.FinRel3KmEur = parseNum(this.travelorder.FinRel3KmEur, 2)
      this.travelorder.FinRel3Total = parseNum(this.travelorder.FinRel3Total, 2)
      this.travelorder.FinRel3TotalEur = parseNum(this.travelorder.FinRel3TotalEur, 2)

      this.travelorder.FinRel4Km = parseNum(this.travelorder.FinRel4Km, 2)
      this.travelorder.FinRel4KmKn = parseNum(this.travelorder.FinRel4KmKn, 2)
      this.travelorder.FinRel4KmEur = parseNum(this.travelorder.FinRel4KmEur, 2)
      this.travelorder.FinRel4Total = parseNum(this.travelorder.FinRel4Total, 2)
      this.travelorder.FinRel4TotalEur = parseNum(this.travelorder.FinRel4TotalEur, 2)

      this.travelorder.FinRel5Km = parseNum(this.travelorder.FinRel5Km, 2)
      this.travelorder.FinRel5KmKn = parseNum(this.travelorder.FinRel5KmKn, 2)
      this.travelorder.FinRel5KmEur = parseNum(this.travelorder.FinRel5KmEur, 2)
      this.travelorder.FinRel5Total = parseNum(this.travelorder.FinRel5Total, 2)
      this.travelorder.FinRel5TotalEur = parseNum(this.travelorder.FinRel5TotalEur, 2)

      this.travelorder.FinOther1Kn = parseNum(this.travelorder.FinOther1Kn, 2)
      this.travelorder.FinOther1Eur = parseNum(this.travelorder.FinOther1Eur, 2)

      this.travelorder.FinOther2Kn = parseNum(this.travelorder.FinOther2Kn, 2)
      this.travelorder.FinOther2Eur = parseNum(this.travelorder.FinOther2Eur, 2)

      this.travelorder.FinOther3Kn = parseNum(this.travelorder.FinOther3Kn, 2)
      this.travelorder.FinOther3Eur = parseNum(this.travelorder.FinOther3Eur, 2)

      this.travelorder.FinOther4Kn = parseNum(this.travelorder.FinOther4Kn, 2)
      this.travelorder.FinOther4Eur = parseNum(this.travelorder.FinOther4Eur, 2)

      this.travelorder.FinOther5Kn = parseNum(this.travelorder.FinOther5Kn, 2)
      this.travelorder.FinOther5Eur = parseNum(this.travelorder.FinOther5Eur, 2)

      this.travelorder.FinTotalKn = parseNum(this.travelorder.FinTotalKn, 2)
      this.travelorder.FinTotalEur = parseNum(this.travelorder.FinTotalEur, 2)

      this.travelorder.FinPayReturnKn = parseNum(this.travelorder.FinPayReturnKn, 2)
      this.travelorder.FinPayReturnEur = parseNum(this.travelorder.FinPayReturnEur, 2)

      this.travelorder.FreeF1 = parseNum(this.travelorder.FreeF1, 2)
      this.travelorder.FreeF2 = parseNum(this.travelorder.FreeF2, 2)
      this.travelorder.FreeF3 = parseNum(this.travelorder.FreeF3, 2)
      this.travelorder.FreeF4 = parseNum(this.travelorder.FreeF4, 2)
      this.travelorder.FreeF5 = parseNum(this.travelorder.FreeF5, 2)

    },
    async register() {
      if (this.$store.state.editItem === 0) {   
        await TravelOrderService.post(this.travelorder)
        .then(res => {
          this.travelorder = res.data.travelorder ? res.data.travelorder : {}
          if (this.$store.state.language === 'EN') {
            this.text = 'New travelorder doc has been saved.'
            this.color = 'green'
            this.snackbar = true
          }
          if (this.$store.state.language === 'HR') {
            this.text = 'Nova putni nalog je snimljen u bazu.'
            this.color = 'green'
            this.snackbar = true
          }
          this.timeout = 1000
          this.$router.push({
            name: 'travelorder'
          })
        })
        .catch(err => {
          this.timeout = 4000
          this.error = err.data
          this.text = 'Error1! Cannot save document to database. ' + err
          this.color = 'red'
          this.snackbar = true
        })
      }        
      if (this.$store.state.editItem === 1) {
        await TravelOrderService.put(this.travelorder)
        this.timeout = 1000
        this.$router.push({
          name: 'travelorder'
        })
      }
    },
    async travelorderlist () {
      try {
        await this.$router.push({
          name: 'travelorder'
        })
      } catch (err) {
        this.error = err.data
        // eslint-disable-next-line
        console.log(err)
      }
    },
    findEmp() {
      if (this.selectEmp && this.selectEmp.id) {
        const selEmp = this.emps.filter(emp => emp.id === this.selectEmp.id)
        const sE = selEmp[0]
        if (selEmp) {
          this.travelorder.eId = sE.id
          this.travelorder.eemail = sE.eemail
          this.travelorder.phone = sE.phone
          this.travelorder.eFirst = sE.First
          this.travelorder.eLast = sE.Last
          this.travelorder.eVatID = sE.VatID
          this.travelorder.WorkPlace = sE.WorkPlace
        }
      } else {
        this.travelorder.eId = null
        this.travelorder.eemail = null
        this.travelorder.phone = null
        this.travelorder.eFirst = null
        this.travelorder.eLast = null
        this.travelorder.eVatID = null
        this.travelorder.WorkPlace = null
      }
    },
    findEmp2() {
      if (this.selectEmp2 && this.selectEmp2.id) {
        const selEmp = this.emps.filter(emp => emp.id === this.selectEmp2.id)
        const sE = selEmp[0]
        if (selEmp) {
          this.travelorder.ApprovedBy = sE.id
          this.travelorder.ApprovedByemail = sE.eemail
          this.travelorder.ApprovedByFirst = sE.First
          this.travelorder.ApprovedByLast = sE.Last
        }
      } else {
        this.travelorder.ApprovedBy = null
        this.travelorder.ApprovedByemail = null
        this.travelorder.ApprovedByFirst = null
        this.travelorder.ApprovedByLast = null
      }
    },
    findUser() {
      if (this.select && this.select.id) {
        const selUsr = this.users.filter(user => user.id === this.select.id)
        const sU = selUsr[0]
        if (sU) {
          this.travelorder.UserIdControl = sU.id
          this.travelorder.emailControl = sU.eemail
          this.travelorder.FirstControl = sU.First
          this.travelorder.LastControl = sU.Last
        }
      } else {
        this.travelorder.UserIdControl = null
        this.travelorder.emailControl = null
        this.travelorder.FirstControl = null
        this.travelorder.LastControl = null
      }
    },
    calcAll() {

      this.travelorder.FinDaysTotal = (this.travelorder.FinDays ? this.travelorder.FinDays : 0) * (this.travelorder.FinDaysAmount ? this.travelorder.FinDaysAmount : 0 )
      this.travelorder.FinDaysTotalEUR = (this.travelorder.FinDaysEUR ? this.travelorder.FinDaysEUR : 0) * (this.travelorder.FinDaysAmountEUR ? this.travelorder.FinDaysAmountEUR : 0 )
      // if (this.travelorder.FinTrStart && this.travelorder.FinTrStart !==0 && this.travelorder.FinTrEnd && this.travelorder.FinTrEnd !== 0 && this.travelorder.FinTrEnd > this.travelorder.FinTrStart) {
      //   this.travelorder.FinRel1Km = (this.travelorder.FinTrEnd - this.travelorder.FinTrStart)
      // }
      this.travelorder.FinRel1KmKn = (this.travelorder.CostByKm ? this.travelorder.CostByKm : 0)
      this.travelorder.FinRel2KmKn = (this.travelorder.CostByKm ? this.travelorder.CostByKm : 0)
      this.travelorder.FinRel3KmKn = (this.travelorder.CostByKm ? this.travelorder.CostByKm : 0)
      this.travelorder.FinRel4KmKn = (this.travelorder.CostByKm ? this.travelorder.CostByKm : 0)

      this.travelorder.FinRel1Total = (this.travelorder.FinRel1Km ? this.travelorder.FinRel1Km : 0) * (this.travelorder.CostByKm ? this.travelorder.CostByKm : 0)
      this.travelorder.FinRel2Total = (this.travelorder.FinRel2Km ? this.travelorder.FinRel2Km : 0) * (this.travelorder.CostByKm ? this.travelorder.CostByKm : 0)
      this.travelorder.FinRel3Total = (this.travelorder.FinRel3Km ? this.travelorder.FinRel3Km : 0) * (this.travelorder.CostByKm ? this.travelorder.CostByKm : 0)
      this.travelorder.FinRel4Total = (this.travelorder.FinRel4Km ? this.travelorder.FinRel4Km : 0) * (this.travelorder.CostByKm ? this.travelorder.CostByKm : 0)

      this.travelorder.FinRel1TotalEur = (this.travelorder.FinRel1Km ? this.travelorder.FinRel1Km : 0) * (this.travelorder.FinRel1KmEur ? this.travelorder.FinRel1KmEur : 0)
      this.travelorder.FinRel2TotalEur = (this.travelorder.FinRel2Km ? this.travelorder.FinRel2Km : 0) * (this.travelorder.FinRel2KmEur ? this.travelorder.FinRel2KmEur : 0)
      this.travelorder.FinRel3TotalEur = (this.travelorder.FinRel3Km ? this.travelorder.FinRel3Km : 0) * (this.travelorder.FinRel3KmEur ? this.travelorder.FinRel3KmEur : 0)
      this.travelorder.FinRel4TotalEur = (this.travelorder.FinRel4Km ? this.travelorder.FinRel4Km : 0) * (this.travelorder.FinRel4KmEur ? this.travelorder.FinRel4KmEur : 0)

      this.travelorder.FinRel5Km = parseNum(this.travelorder.FinRel1Km, 2) + parseNum(this.travelorder.FinRel2Km, 2) + parseNum(this.travelorder.FinRel3Km, 2) + parseNum(this.travelorder.FinRel4Km, 2)
      this.travelorder.FinRel5Total = parseNum(this.travelorder.FinRel1Total, 2) + parseNum(this.travelorder.FinRel2Total, 2) + parseNum(this.travelorder.FinRel3Total, 2) + parseNum(this.travelorder.FinRel4Total, 2)
      this.travelorder.FinRel5TotalEur = parseNum(this.travelorder.FinRel1TotalEur, 2) + parseNum(this.travelorder.FinRel2TotalEur, 2) + parseNum(this.travelorder.FinRel3TotalEur, 2) + parseNum(this.travelorder.FinRel4TotalEur, 2)

      this.travelorder.FinOther5Kn = parseNum(this.travelorder.FinOther1Kn, 2) + parseNum(this.travelorder.FinOther2Kn,2) + parseNum(this.travelorder.FinOther3Kn, 2) + parseNum(this.travelorder.FinOther4Kn,2)
      this.travelorder.FinOther5Eur = parseNum(this.travelorder.FinOther1Eur, 2) + parseNum(this.travelorder.FinOther2Eur, 2) + parseNum(this.travelorder.FinOther3Eur, 2) + parseNum(this.travelorder.FinOther4Eur,2)

      this.travelorder.FinTotalKn = parseNum(this.travelorder.FinDaysTotal,2) + parseNum(this.travelorder.FinRel5Total,2) + parseNum(this.travelorder.FinOther5Kn,2)
      this.travelorder.FinTotalEur = parseNum(this.travelorder.FinDaysTotalEUR,2) + parseNum(this.travelorder.FinRel5TotalEur,2) + parseNum(this.travelorder.FinOther5Eur,2)

      this.travelorder.FinPayReturnKn = parseNum(this.travelorder.ExpensesForwardHomeC,2) - parseNum(this.travelorder.FinTotalKn,2)
      this.travelorder.FinPayReturnEur = parseNum(this.travelorder.ExpensesForwardForeC,2) - parseNum(this.travelorder.FinTotalEur,2)

      if (this.travelorder.FinPayReturnKn > 0) {
        this.travelorder.FinPayReturn = 'Povrat'
      } else {
        this.travelorder.FinPayReturn = 'Isplatu'
      }
    },
    enterDays() {
      this.travelorder.TravelLastDaysLett = this.travelorder.TravelLastDays === 1 ? 'jedan' : ''
      this.travelorder.TravelLastDaysLett = this.travelorder.TravelLastDays === 2 ? 'dva' : ''
      this.travelorder.TravelLastDaysLett = this.travelorder.TravelLastDays === 3 ? 'tri' : ''
      this.travelorder.TravelLastDaysLett = this.travelorder.TravelLastDays === 4 ? 'četiri' : ''
      this.travelorder.TravelLastDaysLett = this.travelorder.TravelLastDays === 5 ? 'pet' : ''
      this.travelorder.TravelLastDaysLett = this.travelorder.TravelLastDays === 6 ? 'šest' : ''
      this.travelorder.TravelLastDaysLett = this.travelorder.TravelLastDays === 7 ? 'sedam' : ''
      this.travelorder.TravelLastDaysLett = this.travelorder.TravelLastDays === 8 ? 'osam' : ''
      this.travelorder.TravelLastDaysLett = this.travelorder.TravelLastDays === 9 ? 'devet' : ''
      this.travelorder.TravelLastDaysLett = this.travelorder.TravelLastDays === 10 ? 'deset' : ''
    },
    dates1() {
      if (this.travelorder.TravelDate) {
        this.travelorder.Travel2Date = this.travelorder.TravelDate
        this.travelorder.FinStartDate = this.travelorder.TravelDate
      }
    },
    dates2() {
      if (this.travelorder.Travel3Date) {
        this.travelorder.FinEndDate = this.travelorder.Travel3Date
      }
    },
    async duplicate() {
      if (this.$store.state.editItem === 1) {
        const dupTO = {...this.travelorder}
        delete dupTO.id
        const {data} = await TravelOrderService.choose(this.mainQuery)
        dupTO.TravelOrderId = data.docNumber && !isNaN(data.docNumber) ? parseInt(data.docNumber) : 1
        await TravelOrderService.post(dupTO)
          .then(res => {
            if (this.$store.state.language === 'EN') {
              this.text = 'New travelorder doc has been saved.' + res.data.newTravelOrder ? res.data.newTravelOrder.id : ''
              this.color = 'green'
              this.snackbar = true
            }
            if (this.$store.state.language === 'HR') {
              this.text = 'Nova putni nalog je snimljen u bazu.' + res.data.newTravelOrder ? res.data.newTravelOrder.id : ''
              this.color = 'green'
              this.snackbar = true
            }
            this.timeout = 1000
            this.$router.push({
              name: 'travelorder'
            })
          })
          .catch(err => {
            this.timeout = 4000
            this.error = err.data
            this.text = 'Error1! Cannot save document to database. ' + err
            this.color = 'red'
            this.snackbar = true
          })
        } else {
          this.timeout = 4000
          this.text = 'Error! Ne mozete duplicirati NOVI nalog!! Cannot duplicate NEW travel order!!' 
          this.color = 'red'
          this.snackbar = true
        }
    },
    async deleteTO() {
      if (this.$store.state.editItem === 1) {
        try {
          const warningMessage = this.travelorder.TravelOrderId + ' ' + this.travelorder.OrderDate
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!')) {
            const { data } = await TravelOrderService.delete(this.travelorder.id)
            if (data.message) {
              if (this.$store.state.language === 'EN') {
                this.text = 'New travelorder doc has been deleted.'
                this.color = 'green'
                this.snackbar = true
              }
              if (this.$store.state.language === 'HR') {
                this.text = 'Nova putni nalog je obrisan iz baze.'
                this.color = 'green'
                this.snackbar = true
              }
              this.timeout = 1000
              this.$router.push({
                name: 'travelorder'
              })
            }
          }
        } catch(err) {
          this.text = 'Error! ' + err.data
          this.color = 'red'
          this.snackbar = true
        }
        
      } else {
          this.timeout = 4000
          this.text = 'Error! Ne mozete brisati NOVI nalog!! Cannot delete NEW travel order!!' 
          this.color = 'red'
          this.snackbar = true
        }


    }
  },
  computed: {
  },
  watch: {
    'selectEmp' () {
      this.findEmp()
    },
    'selectEmp2' () {
      this.findEmp2()
    },
    'select' () {
      this.findUser()
    },
    'travelorder.FinDays' () {
      if (!this.travelorder.FinDays) {
        this.travelorder.FinDays = 0
      }
      this.calcAll()
    },
    'travelorder.FinDaysAmount' () {
      if (!this.travelorder.FinDaysAmount) {
        this.travelorder.FinDaysAmount = 0
      }
      this.calcAll()
    },
    'travelorder.FinRel1Km' () {
      if (!this.travelorder.FinRel1Km) {
        this.travelorder.FinRel1Km = 0
      }
      this.calcAll()
    },
    'travelorder.FinRel2Km' () {
      if (!this.travelorder.FinRel2Km) {
        this.travelorder.FinRel2Km = 0
      }
      this.calcAll()
    },
    'travelorder.FinRel3Km' () {
      if (!this.travelorder.FinRel3Km) {
        this.travelorder.FinRel3Km = 0
      }
      this.calcAll()
    },
    'travelorder.FinRel4Km' () {
      if (!this.travelorder.FinRel4Km) {
        this.travelorder.FinRel4Km = 0
      }
      this.calcAll()
    },
    'travelorder.FinRel1KmEur' () {
      if (!this.travelorder.FinRel1KmEur) {
        this.travelorder.FinRel1KmEur = 0
      }
      this.calcAll()
    },
    'travelorder.FinRel2KmEur' () {
      if (!this.travelorder.FinRel2KmEur) {
        this.travelorder.FinRel2KmEur = 0
      }
      this.calcAll()
    },
    'travelorder.FinRel3KmEur' () {
      if (!this.travelorder.FinRel3KmEur) {
        this.travelorder.FinRel3KmEur = 0
      }
      this.calcAll()
    },
    'travelorder.FinRel4KmEur' () {
      if (!this.travelorder.FinRel4KmEur) {
        this.travelorder.FinRel4KmEur = 0
      }
      this.calcAll()
    },
    'travelorder.FinOther1Kn' () {
      if (!this.travelorder.FinOther1Kn) {
        this.travelorder.FinOther1Kn = 0
      }
      this.calcAll()
    },
    'travelorder.FinOther2Kn' () {
      if (!this.travelorder.FinOther2Kn) {
        this.travelorder.FinOther2Kn = 0
      }
      this.calcAll()
    },
    'travelorder.FinOther3Kn' () {
      if (!this.travelorder.FinOther3Kn) {
        this.travelorder.FinOther3Kn = 0
      }
      this.calcAll()
    },
    'travelorder.FinOther4Kn' () {
      if (!this.travelorder.FinOther4Kn) {
        this.travelorder.FinOther4Kn = 0
      }
      this.calcAll()
    },
    'travelorder.FinOther1Eur' () {
      if (!this.travelorder.FinOther1Eur) {
        this.travelorder.FinOther1Eur = 0
      }
      this.calcAll()
    },
    'travelorder.FinOther2Eur' () {
      if (!this.travelorder.FinOther2Eur) {
        this.travelorder.FinOther2Eur = 0
      }
      this.calcAll()
    },
    'travelorder.FinOther3Eur' () {
      if (!this.travelorder.FinOther3Eur) {
        this.travelorder.FinOther3Eur = 0
      }
      this.calcAll()
    },
    'travelorder.FinOther4Eur' () {
      if (!this.travelorder.FinOther4Eur) {
        this.travelorder.FinOther4Eur = 0
      }
      this.calcAll()
    },
    'travelorder.ExpensesForwardHomeC' () {
      if (!this.travelorder.ExpensesForwardHomeC) {
        this.travelorder.ExpensesForwardHomeC = 0
      }
      this.calcAll()
    },
    'travelorder.ExpensesForwardForeC' () {
      if (!this.travelorder.ExpensesForwardForeC) {
        this.travelorder.ExpensesForwardForeC = 0
      }
      this.calcAll()
    },
    'travelorder.TravelLastDays' () {
      this.enterDays()
    },
    'travelorder.TravelDate' () {
      this.dates1()
    },
    'travelorder.Travel3Date' () {
      this.dates2()
    },
    'travelorder.FinEndTime' () {
      // const timeStart = new Date("01/01/2007 " + this.travelorder.FinStartTime).getHours();
      // const timeEnd = new Date("01/01/2007 " + this.travelorder.FinEndTime).getHours();

     // this.travelorder.FinHours = timeEnd - timeStart;
    },
    'travelorder.FinTrEnd' () {
      if (this.travelorder.FinTrEnd > this.travelorder.FinTrStart) {
        this.counterKm = (this.travelorder.FinTrEnd ? this.travelorder.FinTrEnd : 0) - (this.travelorder.FinTrStart ? this.travelorder.FinTrStart : 0) 
      }
    }
  },
  components: {
    TravelOrderPrint
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
