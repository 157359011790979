const docEN = {
  Salarie: 'Salarie',
  SalarieList: 'Salarie list',
  SalarieDetail: 'Salarie Details',
  Create: 'Create',
  Delete: 'Delete',
  Order: 'Order',
  Month: 'Month',
  Year: 'Year',
  NumberOfEmp: 'Number of emp.',
  HoursNormal: 'Normal hours',
  HoursHoliday: 'Holiday hours',
  DateOpen: 'Date Open',
  Locked: 'Locked',
  LockedDate: 'Locked Date',
  Question1: 'Q1',
  Question2: 'Q2',
  hashtag: '#',
  eemail: 'email',
  First: 'First',
  Last: 'Last',
  VatID: 'VAT id',
  FinBruto1: 'Bruto 1 €',
  FinBruto2: 'Bruto 2 €',
  FinNeto1ls: 'Neto 1 €',
  FinNeto2: 'Neto 2 €',
  FinNeto3: 'Neto 3 €',
  FinBruto3: 'Bruto 3 €',
  DeleteMonth: 'Delete'
}

export default docEN