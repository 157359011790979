const mainEN = {
  Dashboard: 'Radna ploča',
  Sales: 'Prodaja',
  Inquirys: 'Upiti',
  Quotes: 'Ponuda',
  QuoteSales: 'Ponuda',
  Orders: 'Narudžba',
  DeliveryNote: 'Otpremnica',
  DeliveryNoteSales: 'Otpremnica',
  InternalDeliveryNotes: 'Interna Otpremnica',
  Invoices: 'Račun',
  InvoiceSales: 'Račun',
  InvoicesAdvance: 'Račun za predujam',
  Contracts: 'Ugovor',
  WorkingOrder: 'Radni nalog',
  Production: 'Radni nalog',
  Analysis: 'Analiza',
  Purchase: 'Nabava',
  Requests: 'Zahtjevi',
  Order: 'Narudžba',
  CalculationsPurchase: 'Primka',
  ReceiptPurchase: 'Primka',
  Returns: 'Povrat',
  CRM: 'CRM',
  Lead: 'Lead',
  Prospect: 'Prospect',
  Opportunity: 'Opportunity',
  ClosedWonOpportunity: 'Closed Won Opportunity',
  Customer: 'Customer',
  Quote: 'Quote',
  SalesOrder: 'Sales order',
  PurchaseOrder: 'Purchase Order',
  Finance: 'Financije',
  BookInInvoices: 'Knjiga URA',
  BookOutInvoices: 'Knjiga IRA',
  Ledger: 'Temeljnice',
  BankStatements: 'Izvodi',
  BalanceSheet: 'Bilance',
  FinanceAdvanced: 'Financije napredno',
  Reports: 'Izvještaji',
  OsnovnaSredstva: 'Osnovna sredstva',
  SmallTools: 'Sitni inventar',
  Closing: 'Zatvaranja',
  LedgerAccounts: 'Kontni plan',
  HumanResources: 'Ljudski resursi',
  Emplyees: 'Radnici',
  Salaries: 'Plaće',
  EmployeeDiary: 'Dnevnik',
  Travels: 'Putovanja',
  Administration: 'Administracija',
  Items: 'Artikli/usluge',
  Groups: 'Grupe',
  Partner: 'Partneri',
  Warehouse: 'Skladišta',
  WarehouseUser: 'Skladišta/korisnici',
  Company: 'Tvrtke',
  CompanyBusinessYear: 'Tvrtke/poslovne godine',
  CompanyUsers: 'Tvrtke/korisnici',
  Users: 'Korisnici',
  TaskType: 'Zadaci/vrste',
  TaskDepartment: 'Zadaci/odjeli',
  Tasks: 'Zadaci',
  BankAccount: 'Žiro računi',
  Settings: 'Settings',
  ServiceNotes: 'Servisni nalog',
  Inventory: 'Inventura',
  POS: 'Kasa',
  PriceChange: 'Zap.o pr.cijena',
  Calendar: 'Kalendar',
  CopyThisDocument: 'Kopiraj ovaj dokument u novi dokument:',
  CopyDestination: 'Odaberite ciljni dokument',
  CopyDestination2: '- kopiraj u novi dokument',
  MainBooks:'Knjiga primitaka-izdataka',
  MainBookComps:'Knjiga uplata-isplata',
  checkVATID : 'Provjeri OIB',
  Serials: 'Serijski brojevi',
  WorkHours: 'Radni sati',
  CardType:'Kartice POS',
  createSerials: 'Kreiraj serijske',
  serialsCreatedOK: 'Serijski brojeve su kreirani',
  Forms: 'Obrasci',
  ReturnPurchase: 'Povrat',
  OrderPurchase: 'Narudzba',
  InquiryPurchase: 'Upit',
  ItemsPerPage: 'Redova po stranici',
  createStickersAll: 'Kreiraj nalj.za sve',
  createStickersOne: 'Kreiraj nalj.po jednu',
  clearStickers: 'Obriši naljepnice',
  Terms: 'Uvjeti korištenja',
  CashDesk: 'Blagajna',
  City: 'Grad/općina',
  Vehicles: 'Vozila',
  DocDesc: 'Napomene'
}

export default mainEN