<template>
<div>
  <v-container fluid grid-list-md>
  <appTaskChoose></appTaskChoose>
  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="taskdepartments"
    sort-by="id"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{lang.TaskDepartments}}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="8" md="8">
                    <v-text-field v-model="editedItem.taskDepartmentDescription" :label="lang.TaskDepartmentDesc"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
              <v-btn color="blue darken-1" text @click="save">{{langC.Save}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      {{ lang.TaskDepartmentEmpty }}
    </template>
  </v-data-table>
  </v-container>
  </div>
</template>

<script>
import TaskDepartmentService from '@/services/TaskDepartmentService'
import TaskChoose from './TaskChoose'
import langEn from './taskDescEn'
import langHr from './taskDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"

  export default {
    name: 'tasktypeCRUD',
    data: () => ({
      lang: {},
      langC: {},
      saving: false,
      error: null,
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: "",
        databaseDocID: 0
      },
      dialog: false,
      headers: [
        
      ],
      taskdepartments: [],
      editedIndex: -1,
      editedItem: { },
      defaultItem: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        taskDepartmentDescription: '',
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: '',
        LastUpdate: null
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewTaskDepartment: this.lang.EditTaskDepartment
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },
    components: {
      appTaskChoose: TaskChoose
    },

    mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      this.headers.push(
        {
          text: this.lang.TaskDepartments,
          align: 'left',
          sortable: false,
          value: 'taskDepartmentDescription',
        },
        { text: 'DbId', value: 'id' },
        { text: this.lang.Actions, value: 'action', sortable: false },
      ) 
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        try {
          this.saving = true
          this.taskdepartments = (await TaskDepartmentService.index()).data.taskDepartments            
          this.saving = false
        } catch (err) {
          this.error = err
          alert(this.langC.ErrorCannotLoad)
        }
      },
      editItem (item) {
        this.editedIndex = this.taskdepartments.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      async deleteItem (item) {
        try {
          const index = this.taskdepartments.indexOf(item)
          if (confirm(this.langC.AreYouShureToDelete) && this.taskdepartments.splice(index, 1)) {
            await TaskDepartmentService.delete(item.id)
            this.initialize()
          }
        } catch(err) {
          this.error = err
          alert(this.langC.ErrorCannotDelete)
        }
        
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      async save () {
        try {
           if (this.editedIndex > -1) {
              await TaskDepartmentService.put(this.editedItem)
              this.initialize()
            } else {
              this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              this.editedItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
              this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
              this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              this.editedItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : ''
              await TaskDepartmentService.post(this.editedItem)
              this.initialize()
            }
            this.close()
        } catch(err) {
          this.error = err
         alert(this.langC.ErrorCannotSave)
        }
       
      },
    },
  }
</script>