const docHR = {
  BasicData: 'Osnovni podaci',
  FinancialData: 'Financijski podaci',
  CreateNew: ' Kreiraj novi dokument - ',
  CompanyName: 'Tvrtka',
  BusinessYear: 'Poslovna godina',
  User: 'Korisnik',
  DocumentDate: 'Datum dokumenta',
  StartDate: 'Datum isporuke',
  DeadLine: 'Rok plaćanja',
  Partner: 'Partner',
  SelectPartner: 'Odaberi partnera',
  VATID: 'OIB ',
  VATIDBuyer: 'OIB kupca*:',
  PartnerName: 'Naziv partnera *',
  PartnerEmail: 'Partner email',
  PartnerPhones: 'Partner telefoni',
  PartnerBankName: 'Primarna banka',
  PartnerBankIBAN: 'Primarni IBAN',
  IBAN: 'IBAN',
  Bank: 'Banka',
  BankName: 'Naziv banke',
  Street: 'Ulica',
  BuildingNameNumber: 'Broj',
  City: 'Grad',
  County: 'Regija/Županija',
  Postcode: 'Poštanski broj',
  CityName: 'Grad naziv ili dio naziva',
  ContactPerson: 'Kontakt osoba',
  SecretaryTitle: 'Titula',
  SecretaryFirst: 'Ime',
  SecretaryLast: 'Prezime',
  ContactTime: 'Vrijeme kontakta',
  ContactChannel: 'Kanal kontakta',
  CompanyEmail: 'Partner email',
  SecretaryEmail: 'Email',
  SecretaryPhone: 'Telefon:',
  SecretaryContactOption: 'Kontakt-opcije',
  SecretaryBusinessOccupation: 'Poslovna pozicija',
  BankAccount: 'IBAN',
  Description4: 'Predmet',
  Description1: 'Uvodna bilješka',
  Description5: 'Napomena',
  Description3: 'Interna bilješka',
  Description4show: 'Dodaj Predmet',
  Description1show: 'Dodaj Uvodnu bilješku',
  Description5show: 'Dodaj Napomenu',
  Description3show: 'Dodaj Internu bilješku',
  HomeCurrency: 'Primarna valuta',
  ExchangeRate: 'Tečaj',
  SecodnaryCurrency: 'Sekundarna valuta',
  DocumentSavedOK: 'Novi dokument je spremljenu u bazu.',
  DocumentSaveError: 'Greška! Novi dokument se NE MOŽE spremiti u bazu.',
  documentId: 'Broj dok.:',
  documentDate1: 'Datum',
  documentTime: 'Vrijeme',
  documentInvoiceEndDate: 'Rok plać.',
  partnerName: 'Partner',
  documentHomeCurrency: 'Dom. val',
  documentTotalVAT1Amount: 'Uk.PDV',
  documentTotalVAT2Amount: 'Uk.PDV',
  documentTotalVAT3Amount: 'Uk.PDV',
  documentTotalAmountDirectD1: 'Uk.iznos naknade',
  documentTotalAmountDirectD1Fore: 'Uk.iznos naknade',
  documentTotalAmountNetto: 'Uk.iznos netto',
  documentTotalAmountVAT: 'Uk.PDV',
  documentTotalAmountBrutto: 'Uk.izn. brutto',
  documentTotalAmountPayInAdvance: 'Plaćeni avans',
  documentTotalAmountToPay: 'ZA UPLATU',
  documentForeignCurrency: 'Str. val',
  documentTotalAmountNettoForeign: 'Ino.Uk. iznos netto',
  documentTotalAmountVATForeign: 'Ino.Uk. PDV',
  documentTotalAmountBruttoForeign: 'Ino.Uk. iznos brutto',
  documentTotalAmountPayInAdvanceForeign: 'Ino Plaćeni avans',
  documentTotalAmountToPayForeign: 'Ino ZA UPLATU',
  newdocumentTotalVAT1Amount: 'Novi Ukupan PDV',
  newdocumentTotalAmountDirectD1: 'Novi Ukupan iznos naknade',
  newdocumentTotalAmountNetto: 'Novi Ukupan iznos netto',
  newdocumentTotalAmountVAT: 'Novi Ukupan PDV',
  newdocumentTotalAmountBrutto: 'Novi Ukupan iznos brutto',
  diffDD: 'Razlika Naknada',
  diffNeto: 'Razlika Neto',
  diffVAT: 'Razlika PDV',
  diffBrutto: 'Razlika Bruto',
  bankAccountName: 'Naziv banke',
  email: 'Izradio',
  Document: 'Dokument',
  documentConnName: 'Veza NA',
  documentConnNameFrom: 'Veza IZ',
  documentConnId: 'Veza na dok. Id',
  documentDate: 'Veza na dok. datum',
  id: 'DbId',
  Items: 'Artikli',
  ItemID: 'Šifra',
  ItemSelect: 'Odaberi šifru',
  ItemFor: 'Artikal/usluga za',
  ItemName: 'Naziv',
  ItemNameShort: 'Naziv skraćeni',
  ItemCode: 'Kod',
  ItemEANCode: 'EAN kod',
  ItemGroup: 'Grupa',
  ItemDescription: 'Opis',
  ItemUnit: 'Mjera',
  ItemSize: 'Veličina',
  ItemWeight: 'Težina',
  ItemWeightMeasure: 'Mjera težine',
  ItemDimMeasure: 'Mjera dimenzije',
  ItemDimLenght: 'Širina',
  ItemDimHeight: 'Visina',
  ItemDimDepth: 'Dubina',
  ItemWarehouse: 'Skladište',
  ItemWarehouseRow: 'Red',
  ItemWarehouseShelf: 'Polica',
  ItemWahrehouseFloor: 'Kat',
  ItemPicture: 'Slika',
  ItemBelongsToId: 'Pripada šifri',
  ItemService: 'Usl.',
  ItemWeb: 'Web',
  ItemNotActiv: 'Nije aktivan',
  ItemHideForever: 'Sakrij zauvijek',
  ItemHomeCurrency: 'Domaća valuta',
  ItemForeignCurrency: 'Strana valuta',
  ItemExRate: 'Tečaj',
  ItemSuppBasePrice: 'Cijena dobavljača',
  ItemSuppBasePriceAmount: 'Iznos dobavljača',
  ItemSuppDiscount: 'Rab.% dob',
  ItemSuppDiscountAmount: 'Iznos rab.dob.',
  ItemSuppPrice: 'Dobavna cijena',
  ItemSuppPriceAmount: 'Dob. iznos',
  ItemSuppCode: 'Kod dobavljača',
  ItemSuppAmount: 'Dob.vri.',
  ItemMargin: 'Marža%',
  ItemMarginAmount: 'Marža iznos',
  ItemMaxDiscount: 'Maks.rab%',
  ItemPrice: 'Cijena',
  ItemVAT1Percentage: 'PDV %',
  ItemVAT1Amount: 'PDV Iznos',
  ItemVAT2Percentge: '',
  ItemVAT2Amount: '',
  ItemDirectDuty1: 'Direktna naknada',
  ItemDirectDuty2: 'Direktna naknada',
  ItemPriceWithVAT: 'Cijena sa PDVom',
  ItemForePrice: 'Cijena valuta',
  ItemForeVAT1Percentage: 'PDV %',
  ItemForeVAT1Amount: 'PDV Iznos',
  ItemForeVAT2Percentage: '',
  ItemForeVAT2Amount: '',
  ItemForeDirectDuty1: 'Direktna naknada',
  ItemForeDirectDuty2: 'Direktna naknada',
  ItemForePriceWithVAT: 'Cijena valuta sa PDVom',
  ItemBaseAmount: 'Neto iznos 1',
  ItemBaseForeAmount: 'Dobavni iznos valuta',
  ItemDiscountAmount1: 'Rabat iznos',
  ItemDiscountPercentage1: 'Rabat %',
  ItemDiscountAmount2: 'Rabat iznos',
  ItemDiscountPercentage2: 'Rabat %',
  ItemDiscountAmount3: '',
  ItemDiscountPercentage4: '',
  ItemDiscountPrice: 'Cijena sa rabatom',
  ItemSerialNr: 'Serijski broj/LOT',
  ItemPcsPlus: 'Količina zaduži',
  ItemPcsMinus: 'Količina razduži',
  ItemPcsNeutral: 'Količina',
  ItemBaseAmountNoVAT: 'Netto iznos 2',
  ItemBaseForeAmountNoVAT: 'Netto iznos 2 valuta',
  ItemBaseAmountWithVAT: '',
  ItemBaseForeAmountWithVAT: '',
  ItemAmountWithVAT: 'Brutto 1 iznos',
  ItemFullTotal: 'Brutto 2 iznos',
  ItemForeAmountWithVAT: 'Brutto iznos valuta',
  ItemDirectDuty1Amount: 'Direktna nak. ukupno',
  ItemDirectDuty2Amount: '',
  ItemDirectDuty1AmountFore: 'Direktna nak.uk.val.',
  ItemDirectDuty2AmountFore: '',
  itemTotalPlus: 'Zaduženje:',
  itemTotalMinus: 'Razduženje:',
  itemTotal: 'Stanje:',
  itemState: 'Stanje',
  itemCard: 'Kartica artikla',
  FreeF1: 'Marža %',
  FreeF2: 'Marža/kom',
  FreeF5: '',
  suppNetoAmount: 'Dob.neto iznos',
  SalesAnalysis: 'Analiza prodaje',
  SalesPOSAnalysis: 'Lista računa kase',
  ItemCard: 'Kartica artikla',
  ItemCardPurchase: 'Kartica nab',
  ItemCardSales: 'Kartica raz',
  ItemCardInv: 'Kartica prod',
  DocumentType: 'Vrsta dokumenta',
  Inventory: 'Inventura',
  InquirySales: 'Upiti',
  QuoteSales: 'Ponude',
  OrderSales: 'Narudžbe',
  DeliveryNoteSales: 'Otpremnice',
  InvoiceSales: 'Fakture',
  ContractSales: 'Ugovori',
  PurchaseAnalysis: 'Nabava analiza',
  RequestPurchase: 'Zahtjevi',
  InquiryPurchase: 'Upiti',
  OrderPurchase: 'Narudzbe',
  ReceiptPurchase: 'Primke',
  ReturnPurchase: 'Povrati',
  WorkingOrder: 'Radni nalozi',
  POS: 'POS',
  DocPrintPlace: 'Mjesto izdavanja:',
  DocPrintOrder: 'Rb.',
  DocPrintName: 'Naziv',
  DocPrintUnit: 'Jmj',
  DocPrintPcs: 'Količina',
  DocPrintPcsSmall: 'Kol.',
  DocPrintPrice: 'Cijena',
  DocPrintAmount: 'Iznos',
  DocPrintDiscount: 'Rabat%',
  DocPrintDiscountSmall: 'Rab.%',
  DocPrintAmount2: 'Izn.bez PDVa',
  DocPrintVAT: 'PDV%',
  DocPrintAmount3: 'Iznos sa PDVom',
  DocPrintAmount3Small: 'Izn.sa PDVom',
  DocPrintVAT1: 'PDV 25%:',
  DocPrintVAT2: 'PDV 13%:',
  DocPrintVAT3: 'PDV 5%:',
  DocPrintVAT4: 'Neoporezivo:',
  DocPrintBase: 'Osn.:',
  DocPrintTotal00: 'Ukupan iznos ',
  DocPrintTotal01: 'Rabat iznos',
  DocPrintTotal1: 'Ukupan iznos bez PDVa ',
  DocPrintTotal2: 'Ukupan iznos PDVa ',
  DocPrintTotal3: 'Ukupan iznos sa PDVom ',
  DocPrintTotal1Small: 'Osnovica',
  DocPrintTotal2Small: 'PDV',
  DocPrintTotal3Small: 'Ukupno',
  DocPrintTotal4: 'Plaćeni avans ',
  DocPrintTotal5: 'ZA UPLATU ',
  DocPrintPayType: 'Način plaćanja: transakcijski račun',
  DocPrintPayTypeSmall1: 'Način plaćanja: novčanice',
  DocPrintPayTypeSmall2: 'Način plaćanja: kartica',
  DocPrintIdUser: 'Oznaka operatera: ',
  DocPrintPayUseNr: 'Poziv na broj: 02',
  DocPrintComment: 'Napomena:',
  DocPrintMadeBy: 'Izradio:',
  DocPrintMadeBySmall: 'Oznaka operatera:',
  DocPrintControlBy: 'Kontrolirao:',
  DocPrintCFO: 'Direktor:',
  DocPrintPOS: 'Račun broj',
  DocPrintDocumentDate: 'Datum,vrijeme',
  DocPrintRetail: 'Poslovnica:',
  DocPrintZKI: 'Zaš.kod',
  DocPrintJIR: 'JIR',
  DocPrintoldPrice: 'Stara VPC',
  DocPrintnewPrice: 'Nova VPC',
  DocPrintoldAmount: 'Stari Iznos',
  DocPrintnewAmount: 'Novi Iznos',
  DocPrintoldVAT: 'PDV%',
  DocPrintnewVAT: 'PDV%',
  DocPrintAmountoldVAT: 'St.izn.PDVa',
  DocPrintAmountnewVAT: 'Nov.izn.PDV',
  DocPrintoldPriceVAT: 'Stara MPC',
  DocPrintnewPriceVAT: 'Nova MPC',
  DocPrintoldTotal: 'Stari total',
  DocPrintnewTotal: 'Novi total',
  WarehousePlus: 'Skladište zaduženje +',
  WarehouseMinus: 'Skladište razduženje -',
  WarehouseState: 'Skladište broj ',
  WarehouseState2: 'Skladište ',
  WarehouseState3: ' stanje',
  ServiceItem: 'Predmet servisa',
  ServiceProblem: 'Opis problema i predani dodaci',
  ServiceDescription: 'Opis servisa',
  ServiceStatus: 'Status servisa',
  ServiceDoneBy: 'Servis obavio',
  PartnerSave: 'Snimi',
  POSEmpty: 'Nema stavaka u kasi',
  paid1Home: 'Plać.dom.valuta',
  paid1Date: 'Datum plaćanja',
  paid1Fore: 'Plaćeno strana valuta',
  paid2Home: '',
  paid2Date: '',
  paid2Fore: '',
  unpaidHome: 'Neplaćeno dom.valuta',
  unpaidFore: 'Neplaćeno strana valuta',
  status: 'Status',
  WareHouseId: 'Skladište šifra',
  WareHouseName: 'Skladište naziv',
  uservatID: 'Korisnik OIB',
  documentIdGeneral: 'Glavni dokument',
  BookID: 'Broj knjiženja',
  supplierDocument: 'Dokument dobavljača',
  supplierDocumentNr: 'Broj dokumenta dob.',
  supplierDocumentDate: 'Datum dok.dob.',
  supplierAmount: 'Dob. izn.',
  supplierDiscount: 'Dob. popust',
  supplierNetoAmount: 'Dob.neto iznos',
  supplierMargin: 'Marža',
  directDuty1: 'Pov. nak.',
  Supplier: 'Dobavljač',
  SupplierCard: 'Kartica Dobavljača',
  SellerCard: 'Kartica Razduzenja',
  SalesCard: 'Kartica Prodaje',
  TotalPlus: 'Ukupno zaduzenje',
  TotalMinus: 'Ukupno razduzenje',
  TotalNeutral: 'Ukupno',
  itemNotSelected: 'Greška! Nije odabrana niti jedna šifra!',
  selectGroup: 'Odaberi grupu',
  PartnerErrorName: 'Greška!! Partneru nije upisan naziv!',
  PartnerErrorVAT: 'Greška!! Partner sa tim OIBom već postoji!',
  PartnerErroremail: 'Greška!! Partner sa tim email-om već postoji!',
  PartnerErrorVAT2: 'Greška!! OIB je OBAVEZAN!',
  PartnerErrorVAT3: 'Greška!! OIB nema ispravan broj znakova!',
  Error1: 'Greška!! ID stavke nije definiran!',
  SaveItem: 'Spremi artikal/uslugu na ',
  privatePerson: 'Privatna osoba',
  addedPartner: 'Partner je upisan u bazu partnera!',
  Error2: 'Greška! Vaš dokument zahtijeva OIB!',
  Error3: 'Greška! Ne možete koristiti privatnog partnera bez OIBa u tom dokumentu!',
  newitemPrice: 'Nova Cijena',
  newitemBaseAmount: 'Novi VPC iznos',
  newitemVAT1Percentage: 'Novi PDV %',
  newitemVAT1Amount: 'Novi PDV iznos',
  newitemAmountWithVAT: 'Novi iznos sa PDVom',
  newitemPriceWithVAT: 'Nova cijena sa PDVom',
  newitemDirectDuty1: 'Nova Naknada',
  newitemFullTotal: 'Novi uk.iznos',
  newBaseAmount: 'Novi VPC iznos',
  newVAT1Amount: 'Novi PDV iznos',
  newAmountWithVAT: 'Novi iznos sa PDVom',
  newDirectDuty1: 'Novi iznos naknade',
  newFullTotal: 'Novi uk.total',
  olditemPrice: 'Stara Cijena',
  olditemBaseAmount: 'Stari VPC iznos',
  olditemVAT1Percentage: 'Stari PDV %',
  olditemVAT1Amount: 'Stari PDV iznos',
  olditemAmountWithVAT: 'Stari iznos sa PDVom',
  olditemPriceWithVAT: 'Stara cijena sa PDVom',
  olditemDirectDuty1: 'Stara Naknada',
  olditemFullTotal: 'Stari uk.iznos',
  oldBaseAmount: 'Stari VPC iznos',
  oldVAT1Amount: 'Stari PDV iznos',
  oldAmountWithVAT: 'Stari iznos sa PDVom',
  oldDirectDuty1: 'Stari iznos naknade',
  oldFullTotal: 'Stari uk.total',
  PriceChangeEmpty: 'Nema stavka u zapisniku',
  Employee: 'Zaposlenik',
  Description: 'Opis',
  WorkHours: 'Radni sati',
  WorkHourPrice: 'Bruto cijena sata',
  TravelCost: 'Dnevnica',
  TravelKm: 'Prijevoz ukupno (km  * kn)',
  ExpensesTotal: 'Trošak radnika',
  SelectEmployee: 'Odaberi radnika',
  EmployeeCost: 'Trošak radnika',
  POStoday: 'Lista računa danas',
  POSyesterday: 'Lista računa jučer',
  POStotal: 'Obračun danas',
  POScheck: 'Provjeri fiskalizaciju',
  CopyThisDocument: 'Kopiraj ovaj dokument u novi dokument:',
  CopyDestination: 'Odaberite ciljni dokument',
  CopyDestination2: '- kopiraj u novi dokument',
  documentJIR: 'JIR',
  documentZKI: 'Zaš.kod',
  documentPOSCash: 'Gotovina',
  documentPOSCard: 'Kartica',
  documentPOSOther: 'Ostalo',
  cashReceived: 'Primljeno',
  cashToReturn: 'Za vratiti',
  newPOS: 'Novi račun',
  returnPOS : 'Vrati se na račun',
  finishReceive: 'Zaključi račun',
  printPOS: 'Isprintaj',
  printPOS2: 'Isprintaj R1',
  printPOS3: 'Isprintaj A4',
  printPOS4: 'Isprintaj A4 R1',
  addPOSpartner: 'Dodaj partnera za R1',
  editPOSpartner: 'Uredi partnera za R1',
  printPOSTotal: 'Isprintaj Obračun',
  documentTypes: 'Vrste statusa dokumenta',
  documentType: 'Status dokument',
  documentColor: 'Boja status',
  docStatus: 'Status',
  documentTypesHeader: 'Statusi',
  OpenStatement: 'Otvoreno',
  exportDoc: 'Ino',
  PPO: 'PPO',
  createSerials: 'Kreiraj serijske',
  serialsCreatedOK: 'Serijski brojeve su kreirani',
  ProfitCard: 'Kartica Nabave',
  itemUpdated: 'Stavka na dokumentu je izmijenjena.',
  itemAdded: 'Dodana je nova stavka na dokument',
  itemFullPriceOriginal: 'Orig.cj.sa PDVom',
  showSerials: 'Prikazi serijske',
  MainLedgerProjectType: 'Projekt',
  MainLedgerProjectTypes: 'Projekti',
  MainLedgerProjectTypeName: 'Naziv projekta',
  MainLedgerProjectTypeEmpty: 'Nema projekata',
  UpdateItemName: 'Naziv update',
  UpdateItemNameUpdated: 'Naziv i opis su izmjenjeni u bazi.',
  UpdateItemNameUpdatedError: 'Greska!! Naziv i opis NISU izmjenjeni u bazi.',
  TotalDiscount: 'Popust',
  TotalFullDiscount: 'Ukupno popust',
  TotalServices: 'Usluge',
  TotalFullServices: 'Ukupno usluge',
  AddZero: 'Prikaži i 0',
  OnlyZero: 'Samo 0 prikaži',
  GroupsTran: 'Promet po grupama',
  pricechangeCreatedOK: 'Zapisnik je kreiran.',
  TotalDiff: 'Marža na robi',
  Service: 'Usl.',
  UCDnumber: 'UCD broj',
  UCDdate: 'UCD datum',
  importCurrency: 'valuta',
  importAmount: 'Devizni iznos',
  exRate: 'Tečaj',
  domecilAmount: 'EUR iznos',
  customAmount: 'Carina(EUR)',
  bankExp: 'Bankovni trošak(EUR)',
  forwarderExp: 'Špediterski trošak(EUR)',
  transportExp: 'Transportni trošak(EUR)',
  otherExp: 'Ostali troškovi(EUR)',
  totalExp: 'Ukupno troškovi(EUR)',
  expenesPercent: 'Postotak troška%',
  itemSuppBaseForePrice: 'Cijena u val.',
  itemSuppBasePercentageExp: 'Zav.tr.%',
  itemDependablesAmount: 'Zav.trošak',
  GroupName: 'Grupa',
  FreeF3: 'Pakiranje',
  FreeF4: 'Paketa',
  FreeF4WO: 'Proizvedeno VPC',
  PrintCustom: 'Prilagođen',
  addDescription: 'Dodaj opis',
  printItemID: 'Šifre',
  PcsHome: 'Kol.dom.',
  PcsExport: 'Kol.Export',
  SuppAmountHome: 'Dob.izn.dom.',
  NetoSalesAmountHome: 'Prod.izn.dom.',
  SuppAmountExport: 'Dob.izn.exp.',
  NetoSalesAmountExport: 'Prod.izn.exp.',
  ProfitHome: 'Raz.dom.',
  ProfitExport: 'Raz.Export',
  ProfitTotal: 'Raz.UK.',
  ruc: 'RUC',
  suppPrice: 'Pr.Dob.Cj.',
  wrongBussinesYear: 'Godina dokumenta je u krivoj poslovnoj godini!!',
  errorSuppAvgPrice: 'Greške u izračunu prosječne dobavne cijene',
  errorWorkingOrder: 'Greške u radnim nalozima, oznaka proizvedeno',
  lastPurchase: 'Zadnja Nabava',
  showItemCardd: 'Detaljnije',
  noData: 'Dokument nema podataka ',
  noDataList: 'Lista nema podataka ',
  DigitalAr: 'Digitalna Arhiva',
  VehiclesShow: 'Baza Vozila',
  VehicleNew: 'Novo vozilo',
  VehicleEdit: 'Uredi vozilo',
  dontHaveVehicle: 'Nema vozilo',
  vehicleDesc: 'Vozilo',
  vehicleHands: 'Iznos usluga',
  vehicleParts: 'Iznos dijelova',
  vehicleTotalService: 'Ukupno servis',
  searchEAN: 'Traži po EANu',
  itemNotExist: 'Greška! Traženi artikal ne postoji!',
  noTotal: 'Bez Totala',
  forceNewPrintPage: 'Na novu str.',
  createInv:'Kreiraj rač.',
  createInv2:'Kreiraj račune iz ugovora',
  validContract: 'Ug.traje',
  grIn:'Ulaz za grupu',
  grOut: 'Izlaz za grupu'
}

export default docHR