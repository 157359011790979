<template>
<div>
  <v-container fluid grid-list-md>
  <v-row>
      <appAppSettings></appAppSettings>
    </v-row>
  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="users"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="50"
    :mobile-breakpoint="0" 
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{lang.CardType}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>


          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >

                  <v-row>
                    <v-col cols="12" sm="9" md="9" xs="9" lg="9" xl="9" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.CardName"
                        v-model="editedItem.CardName"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.Discount"
                        v-model="editedItem.Discount"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn color="success" text @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>


    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      {{ lang.EmptyCardType }}
    </template>
  </v-data-table>
  </v-container>
  </div>
</template>

<script>
import CardTypeService from '@/services/CardTypeService'
import langEn from './cardtypeDescEn'
import langHr from './cardtypeDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import AppSettings from '../AppSettings'

  export default {
    name: 'cardtypeCRUD',
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      modal1: false,
      dialog1: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: "",
        databaseDocID: 0
      },
      dialog: false,
      headers: [],
      users: [],
      editedIndex: -1,
      editedItem: { },
      defaultItem: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        UserId: null,
        EmployeeId: 0,
        email: '',
        First: '',
        Last: '',
        CardName: '',
        DescHR: '',
        DescEN: '',
        Discount: 0,
        Member: false,
        MemberID: 0,
        Memberemail: '',
        MemberFirst: '',
        MemberLast: '',
        MemberPhone: '',
        MemberSince: null,
        Side: 1,
        Ukupno: 0,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: '',
        FreeF6: '',
      },
      privitems: [],
      valid: true,
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewCardType: this.lang.EditCardType
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },
    components: {
      appAppSettings: AppSettings
    },

    mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }

      this.headers.push(
        {text: this.lang.CardName, value: 'CardName', width: '200px'},
        {text: this.lang.Discount, value: 'Discount', width: '70px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
        {text: this.lang.Actions, value: 'action', sortable: false },
      )
    },

    created () {
      this.initialize()
    },

    methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      async initialize () {
        try {
          this.saving = true
          const {data} = await CardTypeService.index(this.mainQuery)
          if (data && data.cardtypes !== undefined){
            this.users = data.cardtypes
          }
          this.saving = false
          this.errorMatch = false
          this.editedItem = Object.assign({}, this.defaultItem)
        } catch (err) {
          this.error = err
        }
      },
      editItem (item) {
        this.errorMatch = false
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
        this.confpassword = ''
      },
      async deleteItem (item) {
        try {
          
          const index = this.users.indexOf(item)
          const warningMessage = item.CardName
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.users.splice(index, 1)) {
            await CardTypeService.delete(item.id)
            this.initialize()
          }
        } catch(err) {
          this.error = err
        }
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      checkMatch () {
        if (this.editedItem.password && this.confpassword &&  this.confpassword === this.editedItem.password) {
          this.errorMatch = null
          this.save()
        } else {
          this.errorMatch = 'Error. Passoword and Confirmation Password are NOT equal!'
          this.valid = false        
        }
      },

      async save () {
        try {
           let doneOK = false
           if (this.editedIndex > -1) {
              await CardTypeService.put(this.editedItem)
              doneOK = true
            } else {
              this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
              this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              await CardTypeService.post(this.editedItem)
              doneOK = true
            }
            if (doneOK) {
              this.initialize()
              this.close()
            }
        } catch(err) {
          this.error = err
          
        }
       
      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      }
    },
  }
</script>