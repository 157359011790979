<template>
  <div class="formprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './formDescEn'
import langHR from './formDescHR'
import commEn from '@/language/commonEN'
import commHR from '@/language/commonHR'
import {moneyFormat} from '@/snippets/allSnippets'
import dayjs from 'dayjs'

export default {
  name: 'formprint',
  props: ['frm', 'all'],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0
    }
  },
  mounted () {
    if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHR
        this.langC = commHR
      }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    
  },
  methods: {
    async print () {
      if (this.all === 1) {
        await this.initDocument()
        await this.addFullTopPPO()
        await this.addEmptyRow()
        await this.addHeaderNoLogo()
        await this.addHeaderTopPPO()
        await this.addLine()
        await this.addHeaderPPO()
      // await this.addHeader()
        await this.addLine()
        await this.addItemsPPO1(1)
        await this.addLine()
        await this.addItemsPPO1(2)
        await this.addLine()
        await this.addItemsPPO1(3)
        await this.addLine()
        await this.addLine()
        await this.addTotalPPO1()
        await this.addEmptyRow()
        await this.addEmptyRow()
        await this.addEmptyRow()
        await this.addSignature()
      }
      if (this.all === 2) {
        await this.initDocument()
        await this.addFullTopPDVS()
        await this.addEmptyRow()
        await this.addHeaderNoLogo()
        await this.addHeaderTopPDVS()
        await this.addLine()
        await this.addHeaderPDVS()
      // await this.addHeader()
        await this.addLine()
        await this.addItemsPDVS()
        await this.addLine()
        await this.addLine()
        await this.addTotalPDVS()
        await this.addEmptyRow()
        await this.addEmptyRow()
        await this.addEmptyRow()
        await this.addSignature()
      }
      if (this.all === 3) {
        await this.initDocument()
        await this.addFullTopZP()
        await this.addEmptyRow()
        await this.addHeaderNoLogo()
        await this.addHeaderTopZP()
        await this.addLine()
        await this.addHeaderZP()
      // await this.addHeader()
        await this.addLine()
        await this.addItemsZP()
        await this.addLine()
        await this.addLine()
        await this.addTotalZP()
        await this.addEmptyRow()
        await this.addEmptyRow()
        await this.addEmptyRow()
        await this.addSignature()
      }
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    localMoney (money) {
      return moneyFormat(money)
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 35, 20, 20, 20 ]
      this.content = []
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 500,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '40%',
                text: [
                  { text: this.lang.PPO_1 + ':\n' + (this.frm.tax1 ? this.frm.tax1 : '') + ' \n', fontSize: 10, bold: true },
                  { text: this.lang.PPO_2 + ':\n' + (this.frm.tax2 ? this.frm.tax2 : '') + ' \n', fontSize: 10, bold: true },
                ]
              },
              {
                // % width
                width: '25%',
                text: [
                  { text:  '\n', fontSize: 14, bold: true },
                ]   
              },
              {
                // % width
                width: '30%',
                text: [         
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: 'OIB:' + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' ') + ' \n', fontSize: 8},        
                  { text: 'Datum ispisa: ' + dayjs().format('DD.MM.YYYY, HH:mm'), fontSize: 7}
                ]   
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
     addFullTopPPO () {
      const docPart = {
            columns: [
              {
                width: '30%',
                text: [
                  { text:' \n', fontSize: 10, bold: true },
                ]
              },
              {
                width: '62%',
                text: [
                  { text:  'OBRAZAC PPO\n', fontSize: 14, alignment: 'right',bold: true },
                ]   
              },

            ],
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addFullTopPDVS () {
      const docPart = {
            columns: [
              {
                width: '30%',
                text: [
                  { text:' \n', fontSize: 10, bold: true },
                ]
              },
              {
                width: '62%',
                text: [
                  { text:  'OBRAZAC PDV-S\n', fontSize: 14, alignment: 'right',bold: true },
                ]   
              },

            ],
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addFullTopZP () {
      const docPart = {
            columns: [
              {
                width: '30%',
                text: [
                  { text:' \n', fontSize: 10, bold: true },
                ]
              },
              {
                width: '62%',
                text: [
                  { text:  'OBRAZAC ZP\n', fontSize: 14, alignment: 'right',bold: true },
                ]   
              },

            ],
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeaderTopPPO () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '30%',
                text: [
                  { text:' \n', fontSize: 10, bold: true },
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text:  'Pregled tuzemnih isporuka s prijenosom porezne obveze\n', fontSize: 14, bold: true },
                  { text:  'Za tromjesečje: ' + parseInt(this.frm.quarters) +  ' godina: ' + (this.frm.year), fontSize:10, bold: true}
                ]   
              },

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeaderTopPDVS () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '30%',
                text: [
                  { text:' \n', fontSize: 10, bold: true },
                ]
              },
              {
                // % width
                width: '40%',
                text: [
                  { text:  'Prijava za stjecanje dobara i usluga iz drugih država članice Europske unije\n', fontSize: 14, alignment: 'center', bold: true },
                  { text:  'Za mjesec: ' + parseInt(this.frm.month) +  ' godina: ' + (this.frm.year), fontSize:10, alignment: 'center',bold: true}
                ]   
              },

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeaderTopZP () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '30%',
                text: [
                  { text:' \n', fontSize: 10, bold: true },
                ]
              },
              {
                // % width
                width: '40%',
                text: [
                  { text:  'Zbrina prijava za isporuke dobara i usluga u druge države članice Europske unije\n', fontSize: 14, alignment: 'center', bold: true },
                  { text:  'Za mjesec: ' + parseInt(this.frm.month) +  ' godina: ' + (this.frm.year), fontSize:10, alignment: 'center',bold: true}
                ]   
              },

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeaderPPO () {
        const docPart = {
        columns: [
              {
                width: '15%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.PPO_8
              },
              {
                width: '20%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.PPO_9
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.PPO_10
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.PPO_11
              },
            ]
          }
        this.content.push(docPart)
    },
    addHeaderPDVS () {
        const docPart = {
        columns: [
               {
                width: '15%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.PDVS_8
              },
              {
                width: '15%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.PDVS_9
              },
              {
                width: '20%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.PDVS_10
              },
              {
                width: '22%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.PDVS_11
              },
              {
                width: '22%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.PDVS_12
              },
            ]
          }
        this.content.push(docPart)
    },
    addHeaderZP () {
        const docPart = {
        columns: [
               {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.ZP_8
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.ZP_9
              },
              {
                width: '15%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.ZP_10
              },
              {
                width: '13%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.ZP_11
              },
              {
                width: '13%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.ZP_12
              },
              {
                width: '13%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.ZP_13
              },
              {
                width: '13%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.ZP_14
              },
            ]
          }
        this.content.push(docPart)
    },
    addItemsPPO1(forMonth) {
      if (this.frm.items) {
        const firstQ = this.frm.items.filter(item => item.deliveredMonth === forMonth)
        let TotalQ = 0
        if (forMonth === 1) {
          TotalQ =  this.localMoney(this.frm.FreeTotal2)
        }
        if (forMonth === 2) {
          TotalQ =  this.localMoney(this.frm.FreeTotal3)
        }
        if (forMonth === 3) {
          TotalQ =  this.localMoney(this.frm.FreeTotal4)
        }
        if (firstQ && firstQ.length > 0) {
          let inLine = 0
          firstQ.map(item => {
            inLine += 1
            const docPart = {
            columns: [
                  {

                    width: '15%',
                    fontSize: 8,
                    alignment: 'left',
                    text: item.deliveredMonth + '\n'
                  },
                  {
                    width: '20%',
                    fontSize: 8,
                    alignment: 'center',
                    text: inLine + '. \n'
                  },
                  {
                    width: '30%',
                    fontSize: 8,
                    alignment: 'left',
                    text: item.partnerVATID + '\n'
                  },
                  {
                    width: '20%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.FreeF1) + '\n'
                  },
                ]
              }
            this.content.push(docPart)
          })

          const docPart2 = {
          columns: [
              {
                width: '15%',
                fontSize: 8,
                alignment: 'left',
                text:  '\n'
              },
              {
                width: '20%',
                fontSize: 8,
                alignment: 'center',
                text: '\n'
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.PPO_15 + ' trom.' + forMonth +' =' + '\n'
              },
              {
                width: '20%',
                fontSize: 10,
                alignment: 'right',
                bold: true,
                text: TotalQ + '\n'
              },
            ]
          }
          this.content.push(docPart2)
       }

      }
    },
    addItemsPDVS() {
      if (this.frm.items) {
          let inLine = 0
          this.frm.items.map(item => {
            inLine += 1
            const docPart = {
            columns: [
                  {
                    width: '15%',
                    fontSize: 8,
                    alignment: 'left',
                    text: inLine + '. \n'
                  },
                  {
                    width: '15%',
                    fontSize: 8,
                    alignment: 'left',
                    text: item.FreeS1 + '\n'
                  },
                  {
                    width: '20%',
                    fontSize: 8,
                    alignment: 'center',
                    text: item.FreeS2 + '\n'
                  },
                  {
                    width: '22%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.FreeF1) + '\n'
                  },
                  {
                    width: '20%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.FreeF2) + '\n'
                  },
                ]
              }
            this.content.push(docPart)
          })
      }
    },
    addItemsZP() {
      if (this.frm.items) {
          let inLine = 0
          this.frm.items.map(item => {
            inLine += 1
            const docPart = {
            columns: [
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'left',
                    text: inLine + '. \n'
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'left',
                    text: item.FreeS1 + '\n'
                  },
                  {
                    width: '15%',
                    fontSize: 8,
                    alignment: 'left',
                    text: item.FreeS2 + '\n'
                  },
                  {
                    width: '13%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.FreeF1) + '\n'
                  },
                  {
                    width: '13%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.FreeF2) + '\n'
                  },
                  {
                    width: '13%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.FreeF3) + '\n'
                  },
                  {
                    width: '13%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.FreeF4) + '\n'
                  },
                ]
              }
            this.content.push(docPart)
          })
      }
    },
    addTotalPPO1() {
      if (this.frm.items) {

          const docPart = {
          columns: [
              {
                width: '15%',
                fontSize: 8,
                alignment: 'left',
                text:  '\n'
              },
              {
                width: '20%',
                fontSize: 8,
                alignment: 'center',
                text: '\n'
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.PPO_15 + '\n'
              },
              {
                width: '20%',
                fontSize: 12,
                alignment: 'right',
                bold: true,
                text: this.localMoney(this.frm.FreeTotal1) + '\n'
              },
            ]
          }
          this.content.push(docPart)
      }
    },
    addTotalPDVS() {
      if (this.frm.items) {
          const docPart = {
          columns: [
              {
                width: '15%',
                fontSize: 8,
                alignment: 'left',
                text:  '\n'
              },
              {
                width: '15%',
                fontSize: 8,
                alignment: 'left',
                text:  '\n'
              },
              {
                width: '20%',
                fontSize: 8,
                alignment: 'center',
                text: '\n'
              },
              {
                width: '22%',
                fontSize: 10,
                alignment: 'right',
                bold: true,
                text: this.localMoney(this.frm.FreeTotal1) + '\n'
              },
              {
                width: '20%',
                fontSize: 10,
                alignment: 'right',
                bold: true,
                text: this.localMoney(this.frm.FreeTotal2) + '\n'
              },
            ]
          }
          this.content.push(docPart)
      }
    },
    addTotalZP() {
      if (this.frm.items) {
          const docPart = {
          columns: [
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text:  '\n'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text:  '\n'
              },
              {
                width: '15%',
                fontSize: 8,
                alignment: 'center',
                text: '\n'
              },
              {
                width: '13%',
                fontSize: 10,
                alignment: 'right',
                bold: true,
                text: this.localMoney(this.frm.FreeTotal1) + '\n'
              },
              {
                width: '13%',
                fontSize: 10,
                alignment: 'right',
                bold: true,
                text: this.localMoney(this.frm.FreeTotal2) + '\n'
              },
              {
                width: '13%',
                fontSize: 10,
                alignment: 'right',
                bold: true,
                text: this.localMoney(this.frm.FreeTotal3) + '\n'
              },
              {
                width: '13%',
                fontSize: 10,
                alignment: 'right',
                bold: true,
                text: this.localMoney(this.frm.FreeTotal4) + '\n'
              },
            ]
          }
          this.content.push(docPart)
      }
    },
    addSignature () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: this.lang.PPO_16 + '\n' + (this.frm.First ? this.frm.First + ' ' : '') + (this.frm.Last ? this.frm.Last + ' ' : '') + ' \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: this.lang.PPO_17 + '\n' + '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    }

  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>