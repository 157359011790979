<template>
<div>
  <v-container fluid grid-list-md>

  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="users"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="50"
    @click:row="navigateTo"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{lang.VehicleList}}</v-toolbar-title>
         <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mt-2 pr-1">
            <v-text-field
              dense
              :label="lang.MarkType"
              v-model="searchItem.MarkType"
              clearable
              @keypress.enter="trigger"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mt-2 pa-1">
            <v-text-field
              dense
              :label="lang.Chasis"
              v-model="searchItem.Chasis"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mt-2 pr-1">
            <v-text-field
              dense
              :label="lang.TypeVeichle"
              v-model="searchItem.TypeVeichle"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mt-2 pr-1">
            <v-text-field
              dense
              :label="lang.Serie"
              v-model="searchItem.Serie"
              clearable
            ></v-text-field>
          </v-col>
           <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mt-2 pr-1">
            <v-text-field
              dense
              :label="lang.Registration"
              v-model="searchItem.Registration"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mt-2 pr-1">
            <v-text-field
              dense
              :label="lang.partnerName"
              v-model="searchItem.partnerName"
              clearable
            ></v-text-field>
          </v-col>
          <v-btn
            color="blue"
            text
            @click="initializeSearch"
          >
            TRAZI
          </v-btn>
           <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

        <v-spacer></v-spacer>
         
          <!-- <v-btn
              color="blue"
              text
              @click="vehicleprnt"
            >
              PRINT
            </v-btn> -->
         

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px">  

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container class="pa-0 ma-0">
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >

                  <v-row no-gutters>
                     <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pa-1">
                     <v-text-field
                        dense
                        :label="lang.MarkType"
                        v-model="editedItem.MarkType"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pa-1">
                      <v-text-field
                        dense
                        :label="lang.Chasis"
                        v-model="editedItem.Chasis"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pa-1">
                     <v-text-field
                        dense
                        :label="lang.TypeVeichle"
                        v-model="editedItem.TypeVeichle"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pa-1">
                      <v-text-field
                        dense
                        :label="lang.Serie"
                        v-model="editedItem.Serie"
                        outlined
                      ></v-text-field>
                    </v-col>
                     <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pa-1">
                      <v-text-field
                        dense
                        :label="lang.Engine"
                        v-model="editedItem.Engine"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                     <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.ChasisModel"
                        v-model="editedItem.ChasisModel"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.Registration"
                        v-model="editedItem.Registration"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                     <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.Colour"
                        v-model="editedItem.Colour"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.Km"
                        v-model="editedItem.Km"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                     <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.MonthManufacture"
                        v-model="editedItem.MonthManufacture"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.YearManufacture"
                        v-model="editedItem.YearManufacture"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.StateManufacture"
                        v-model="editedItem.StateManufacture"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                      <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
                            <v-autocomplete
                            class="pr-3"
                            :items="partners"
                            v-model="selectPa"
                            :label="lang.SelectPartner"
                            :search-input.sync="search"
                            item-text="partnerName"
                            item-value="id"
                            return-object
                            persistent-hint
                            clearable
                            outlined
                          ></v-autocomplete>                  
                      </v-col>
                      <v-col cols="12" sm="3" md="3" xs="6" lg="3" xl="3">
                          <v-text-field class="pr-2"
                            :label="lang.VATID"
                            v-model="editedItem.partnerVATID"
                            outlined
                          ></v-text-field> 
                      </v-col>
                      <v-col cols="12" sm="4" md="4" xs="6" lg="4" xl="4">

                      </v-col>
                  </v-row>

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1"  @click="close" class="mr-3">{{langC.Cancel}}</v-btn>
                    <v-btn color="success"  @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.Iznos`]="{ item }"> 
      {{ localMoney(item.Iznos) }}
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      {{ lang.VehicleListEmpty }}
    </template>
  </v-data-table>

  <VehiclePrint ref="prnt" :card="card" />
  </v-container>
  </div>
</template>

<script>
import VehicleService from '@/services/VehicleService'
import PartnerService from '@/services/PartnerService'
import VehiclePrint from './VehiclePrint.vue'
import langEn from './vehicleDescEn'
import langHr from './vehicleDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import moneyFormat from '@/snippets/moneyFormat'
import { testLastUpdate } from '../../services/TestLastUpdate'

  export default {
    name: 'vehicleCRUD',
    data: () => ({
      card: {},
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      modal1: false,
      dialog1: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {},
      dialog: false,
      headers: [],
      users: [],
      selectPa: {},
      partners: [],
      search: null,
      editedIndex: -1,
      searchItem: {
        CompanyId: null,
        MarkType: '',
        Chasis: '',
        TypeVeichle: '',
        Serie: '',
        Registration: '',
        partnerName: ''
      },
      editedItem: { },
      defaultItem: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        UserId: null,
        EmployeeId: 0,
        email: '',
        First: '',
        Last: '',
        MarkType: '',
        Chasis: '',
        TypeVeichle: '',
        Serie: '',
        Engine: '',
        ChasisModel: '',
        Registration: '',
        Colour: '',
        Km: '',
        YearManufacture: '',
        MonthManufacture: '',
        StateManufacture: '',
        PartnerId: null,
        partnerName: '',
        partnerVATID: '',
        privatePerson: false,
        LastUpdateBy: '',
        LastUpdate: null,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: '',
        FreeF6: '',
      },
      privitems: [],
      valid: true,
      total: 0
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.Vehicle: this.lang.EditVehicle
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      'selectPa' (val) {
        if (val) {
          val.partnerVATID && this.findPartner(val.partnerVATID)
        }
        if (!val) {
          this.clearPartner()
        }
      },
    },
    components: {
      VehiclePrint
    },

    async mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
        this.searchItem.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      await testLastUpdate()
      if (this.$store.state.partnerStore) {
        this.partners = this.$store.state.partnerStore
      }
      //this.mainQuery.salarieID = this.$store.state.documentActivHead.id

      this.headers.push(
        {text: this.lang.MarkType, value: 'MarkType', width: '200px'},
        {text: this.lang.Chasis, value: 'Chasis', width: '150px'},
        {text: this.lang.TypeVeichle, value: 'TypeVeichle', width: '200px'},
        {text: this.lang.Serie, align: 'right',value: 'Serie', width: '100px'},
        {text: this.lang.Engine, value: 'Engine', width: '100px'},
        {text: this.lang.ChasisModel, value: 'ChasisModel', width: '100px'},
        {text: this.lang.Registration, value: 'Registration', width: '100px'},
        {text: this.lang.partnerName, value: 'partnerName', width: '150px'},
        {text: this.lang.Colour, value: 'Colour', width: '100px'},
        {text: this.lang.Km, value: 'Km', width: '100px'},
        {text: this.lang.YearManufacture, value: 'YearManufacture', width: '100px'},
        {text: this.lang.MonthManufacture, value: 'MonthManufacture', width: '100px'},
        {text: this.lang.StateManufacture, value: 'StateManufacture', width: '100px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
        {text: this.lang.Actions, value: 'action', sortable: false },
      )
      this.initialize()
    },

    created () {
    },

    methods: {
      navigateTo (params) {
        this.$store.dispatch('setVehicleid', params.id)
      },
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      trigger () {
        console.log('imam enter YESSS')
      },
      localMoney(moneyToFormat) {
        return moneyFormat(moneyToFormat)
      },
      async initialize () {
        try {
          this.saving = true
          const {data} = await VehicleService.index(this.mainQuery)
          if (data && data.vehicles !== undefined){
            this.users = data.vehicles
          }
          this.saving = false
          this.errorMatch = false
          this.editedItem = Object.assign({}, this.defaultItem)
        } catch (err) {
          this.error = err
        }
      },
      async initializeSearch () {
        try {
          this.saving = true
          const {data} = await VehicleService.choose(this.searchItem)
          if (data && data.vehicles !== undefined){
            this.users = data.vehicles
          }
          this.saving = false
          this.errorMatch = false
          this.editedItem = Object.assign({}, this.defaultItem)
        } catch (err) {
          this.error = err
        }
      },
      async vehicleprnt () {
        const newObj = {}
        this.card = {...newObj}
        this.card = {...this.$store.state.documentActivHead}
        this.card.head1 = this.users ? this.users : []
        this.card.head2 = ''
        this.card.head3 = null
        this.card.head4 = null
        this.card.head5 = null
        await this.$store.dispatch('setPrintObj', this.card)
        await this.$refs.prnt.print()
      },
      editItem (item) {
        this.errorMatch = false
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        if (this.editedItem.PartnerId && this.editedItem.PartnerId.trim().length > 0 ) {    
          // this.selectId = parseInt(this.editedItem.partnerId)
          this.selectPa = this.partners.find(partner => (partner.id === parseInt(this.editedItem.PartnerId)))
        }
        this.dialog = true
      },
      async deleteItem (item) {
        try {
          const index = this.users.indexOf(item)
          const warningMessage = item.MarkType + ' ' + item.Chasis + ' ' + item.TypeVeichle
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.users.splice(index, 1)) {
            await VehicleService.delete(item.id)
            this.initialize()
          }
        } catch(err) {
          this.error = err
        }
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      checkMatch () {
        if (this.editedItem.password && this.confpassword &&  this.confpassword === this.editedItem.password) {
          this.errorMatch = null
          this.save()
        } else {
          this.errorMatch = 'Error. !'
          this.valid = false        
        }
      },

      async save () {
        try {
           let doneOK = false
           if (this.editedIndex > -1) {
              this.editedItem.LastUpdateBy = this.$store.state.user.email ? this.$store.state.user.email : 'test@cegrupa.com'
              this.editedItem.LastUpdate = new Date()
              await VehicleService.put(this.editedItem)
              doneOK = true
            } else {
              this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
              this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              this.editedItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear  : 2022
              this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              this.editedItem.email = this.$store.state.user.email ? this.$store.state.user.email : 'test@cegrupa.com'
              this.editedItem.First = this.$store.state.user.First ? this.$store.state.user.First : 'NoFirst'
              this.editedItem.Last = this.$store.state.user.Last ? this.$store.state.user.Last : 'NoLast'
              await VehicleService.post(this.editedItem)
              doneOK = true
            }
            if (doneOK) {
              this.initialize()
              this.close()
            }
        } catch(err) {
          this.error = err
          
        }
       
      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      },
      async findPartner (partnerVATID) {
        if (partnerVATID) {
          // in partners array I dont have all required data, for faster serach 
          // const locPartner = this.partners.find(partner => (partner.id === partnerId)) and then need to contact API
          if ((partnerVATID) !== (this.editItem.partnerVATID)) {
            this.saving = true
            const locPartner = (await PartnerService.show(partnerVATID)).data.partner
            this.saving = false
            let haveAlert = false
            // if (this.documentName === 'QuoteSales' || this.documentName === 'ServiceNotes') {
            //   haveAlert = false
            // } else {
            //   if (locPartner.privatePerson) {
            //     alert(this.lang.Error3)
            //     this.clearPartner()
            //     haveAlert = true
            //     return
            //   }
            //   if (!locPartner.privatePerson && (!locPartner.partnerVATID  || locPartner.partnerVATID.trim().length === 0)) {
            //     alert(this.lang.Error2)
            //     this.clearPartner()
            //     haveAlert = true
            //     return
            //   }
            // }

            if (!haveAlert) {
              this.editedItem.PartnerId = locPartner.id
              this.editedItem.partnerName = locPartner.partnerName
              this.editedItem.partnerVATID = locPartner.partnerVATID
              this.editedItem.privatePerson = locPartner.privatePerson
            }
          }
        }
      },
      clearPartner() {
        this.selectPa = null
        this.editedItem.partnerId = null
        this.editedItem.partnerName = null
        this.editedItem.partnerVATID = null
        this.editedItem.privatePerson = false
      },
    },
  }
</script>