<template>
  <v-container fluid>
        <v-row>
            <v-col cols="12" sm="4" md="4" lg="4" xl="4">
              <h1>{{documentNameLoc}} {{bookTypeSelected.text}}</h1>
            </v-col>
            <v-col cols="12" sm="1" md="1" lg="1" xl="1">
                 <v-btn text color="red" @click="mainledgerDelete" class="mr-1" v-if="show === 4">
                      {{langC.Delete}}
                  </v-btn>
            </v-col>
            <v-col cols="12" sm="7" md="7" lg="7" xl="7" class="text-right">

                  <v-btn color="green" @click="mainledgerlist" class="mr-1">
                      {{langC.BackToList}}
                  </v-btn>                  
                  <v-btn color="green" @click="haveAlert(1)" class="mr-1">
                      {{langC.Save}}
                  </v-btn> 
           </v-col>
           <v-progress-circular v-if="this.saving"
              indeterminate
              color="green"
            ></v-progress-circular>
        </v-row>

        <v-row>
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-data-table
                dense
                :headers="headers"
                :items="mldetails"
                sort-by="id"
                class="elevation-1"
                :footer-props="{
                  'items-per-page-options': [10, 20, 30, 40, 50]
                }"
                :items-per-page="50"
                @click:row="doDetail"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-toolbar-title>{{lang.bankStatments}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
            
                <template v-slot:[`item.action`]="{ }">

                </template>
                <template v-slot:no-data>
                  {{ lang.bankStatmentEmpty }}
                </template>
                <template v-slot:[`item.ClosedCompAcc`]="{ item }">
                  <span v-if="item.ClosedCompAcc"><v-icon>mdi-check</v-icon></span>
                </template>
                <template v-slot:[`item.bankStatmentDebit`]="{ item }">
                  <span class="indigo--text">{{ formatPrice(item.bankStatmentDebit) }}</span>
                </template>
                <template v-slot:[`item.bankStatmentCredit`]="{ item }">
                  <span class="deep-orange--text">{{ formatPrice(item.bankStatmentCredit) }}</span>
                </template>
              </v-data-table>
          </v-col>
        </v-row>


        <v-dialog
          v-model="showAlert"
          max-width="290"
        >
          <v-card>
            <v-card-title class="headline">
             {{lang.MainLedgerQues1}}
            </v-card-title>

            <v-card-text>
              {{lang.MainLedgerQues2}}
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="showAlert = false"
              >
                {{langC.Cancel}}
              </v-btn>

              <v-btn
                color="blue"
                text
                @click="haveAlert2()"
              >
                {{langC.Continue}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          bottom
          :color="color"
          >
          {{ text }}
        </v-snackbar>

        <div v-if="showItem">
          <MainBookCompCreate :head="head" :hHeader="true" />
        </div>

  </v-container>
</template>

<script>
import MainLedgerService from '@/services/MainLedgerService'
//import MainLedgerDetailService from '@/services/MainLedgerDetailService'
import MainBookCompCreate from '../MainBookComp/MainBookCompCreate'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import {parseNum, round} from '@/snippets/allSnippets'
import { mapGetters } from 'vuex'
import MainLedgerSideService from '@/services/MainLedgerSideService'

export default {
  name: 'mainledgercompindex',
  data () {
    return {
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      msg: '',
      search: '',
      documentName2: '',
      document: {},
      mainledger: {},
      locDocument: {},
      documentName: '',
      documentNameLoc: '',
      retPath: 'mainledger4',
      show: 1,
      error: '',
      mainQuery: {},
      locCreatedAt: '',
      locUpdatedAt: '',
      locBookingDate: '',
      locInvoiceDate: '',
      locInvoicePaymentDeadlineDate: '',
      locBookID: 0,
      locCanUse: 0,
      locCantUse: 0,
      headers: [],
      mldetails: [],
      account4s: [],
      lang: {},
      langC: {},
      saving: false,
      showTotals: false,
      bookTypeSelected: {
      },
      havePrevious: false,
      haveDiff: false,
      showAlert: false,
      type: null,
      DebitTotal: 0,
      CreditTotal: 0,
      DiffTotal: 0,
      ClosedAccount: false,
      componentKey: 0,
      databaseMLID: 0,
      card: {},
      BookTypeID: null,
      defaultItem: {},
      showErrorItems: false,
      head: {},
      showItem: false,
      editedIndex : null
    }
  },
  async mounted () {
     if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
        this.defaultItem.CompanyId = this.$store.state.companyid
      }
      this.$store.dispatch('setMainLedgerSideItem', null)
      this.$store.dispatch('setMainLedgerDetailItems', null)
      
      this.defaultItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
      this.mainQuery.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
      this.mainQuery.databaseMLID = this.$store.state.mainledgerid ? this.$store.state.mainledgerid : 0
      const { data } = await MainLedgerService.indexbsdetails(this.mainQuery)
      if (data.mainledgerDetails && data.mainledgerDetails.length > 0) {
        this.mldetails = data.mainledgerDetails
        this.$store.dispatch('setMainLedgerSideItems', data.mainledgerDetails)
      }
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc

      this.headers.push(
        {
          text: this.lang.detailId,
          align: 'left',
          sortable: true,
          value: 'bankStatmentNumberDetail',
          width: '50px'
        },
        {
          text: this.lang.detailDone,
          align: 'left',
          sortable: true,
          value: 'ClosedCompAcc',
          width: '50px'
        },
        {
          text: this.lang.partnerName,
          align: 'left',
          sortable: true,
          value: 'partnerName',
          width: '350px'
        },
        {
          text: this.lang.bankStatmentDebit,
          align: 'right',
          sortable: true,
          value: 'bankStatmentDebit',
          width: '100px'
        },
        {
          text: this.lang.bankStatmentCredit,
          align: 'right',
          sortable: true,
          value: 'bankStatmentCredit',
          width: '100px'
        },
        {
          text: this.lang.partnerBankIBAN,
          align: 'left',
          sortable: true,
          value: 'partnerBankIBAN',
          width: '120px'
        },
        {
          text: '',
          align: 'left',
          sortable: true,
          value: 'bankReference1',
          width: '50px'
        },
        {
          text: this.lang.bankReference1,
          align: 'left',
          sortable: true,
          value: 'bankReference2',
          width: '200px'
        },
        {
          text: '',
          align: 'left',
          sortable: true,
          value: 'bankCall1',
          width: '50px'
        },
        {
          text: this.lang.bankReference2,
          align: 'left',
          sortable: true,
          value: 'bankCall2',
          width: '200px'
        },
        
        {
          text: this.lang.Description,
          align: 'left',
          sortable: true,
          value: 'bankDescription',
          width: '220px'
        },
        {text: this.lang.bankCode, value: 'bankCode', sortable: false },
      )
  },
  methods: {
    doDetail(params) {
      if (params.ClosedCompAcc) {
        if (confirm('Ova stavka je već proknjižena!! Ponovno knjiženje može duplirati podatke!! Da li ste SIGURNI da želite ponovno knjižiti?'))
        {
          this.markClosedDetailBSItem(false)
          this.doDetail2(params)
        }
      }
      if (!params.ClosedCompAcc) {
        this.doDetail2(params)
      }
    },
    doDetail2(params) {
      this.editedIndex = this.mldetails.indexOf(params)
      const newObj = {}
      this.head = {...newObj}
      this.head = {...params}
      this.$store.dispatch('setretPath', 'mainledgercompindex')
      this.$store.dispatch('setTikTok', 99999)
      if (round(parseFloat(params.bankStatmentDebit),2) !== 0) {
        this.$store.dispatch('setTaskTypeid', 2)
      }
      if (round(parseFloat(params.bankStatmentCredit),2) !== 0) {
        this.$store.dispatch('setTaskTypeid', 1)
      }
      this.showItem = true
    },
    formatPrice (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    forceRerender() {
      this.componentKey += 1;  
    },
    previtems() {
      this.havePrevious = !this.havePrevious
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
    retrieveErrorItems() {
      if (this.$store.state.mainledgerErrorItems && this.$store.state.mainledgerErrorItems.length > 0) {
        this.$store.dispatch('setMainLedgerItems', this.$store.state.mainledgerErrorItems)
        this.showErrorItems = false
        this.forceRerender()
      }
    },
    async mainledgerlist () {
      try {
        await this.SaveIt()
        this.$store.dispatch('setMainLedgerStatus', 1)
        await this.$router.push({
          name: this.retPath
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async mainLedgerAdd () {
      try {
        this.$store.dispatch('seteditItem', 0)
        this.$store.dispatch('setMainLedgerStatus', 1)
      } catch (error) {
        this.text = error.data
        this.color = 'red'
        this.snackbar = true
        this.error = error.data
      }
    },
    haveAlert(type) {
      // if is book in or book out
      this.showAlert = false
      this.type = type
      const mustBe = round(parseNum(this.$store.state.mainledgerActiv.InvoiceAmount),2)
      if (this.$store.state.mainledgerActiv.BookTypeID <= 10 && mustBe !== 0) {
        const debitTotal = this.$store.state.mainledgerActiv.DebitTotal ? round(parseNum(this.$store.state.mainledgerActiv.DebitTotal),2) : 0
        const creditTotal = this.$store.state.mainledgerActiv.CreditTotal ? round(parseNum(this.$store.state.mainledgerActiv.CreditTotal),2) : 0
        if (mustBe !== debitTotal || mustBe !== creditTotal) {
          this.showAlert = true
        }
      }
      if (!this.showAlert) {
        if (this.type === 1) {
          this.type = 0
          this.mainledgerSaveClose()
        }
        if (this.type === 2) {
          this.type = 0
          this.mainledgerSaveNext()
        }
      }
    },
    haveAlert2() {
      this.showAlert = false
      if (this.type === 1) {
        this.mainledgerSaveClose()
      }
      if (this.type === 2) {
        this.mainledgerSaveNext()
      }
    },
    async SaveIt() {
      if (this.mldetails && this.mldetails.length) {
        let markBSFinished = true
        this.mldetails.map(mlitem => {
          if (mlitem.ClosedCompAcc === false){
            markBSFinished = false
          }
        })
        if (markBSFinished && this.$store.state.mainledgerActiv) {
          const query = {...this.$store.state.mainledgerActiv}
          query.ClosedCompAcc = true
          await MainLedgerService.put(query)
        }
      }
    },

    async mainledgerSaveClose () {
      await this.SaveIt()
    },

    async makeSum() {
      const updateSumObject = {
        DebitTotal: 0,
        CreditTotal: 0,
        DebitForeginTotal: 0,
        CreditForeginTotal: 0,
        DiffTotal: 0,
        ClosedAccount: false
      }
      let status = false
      let nrItems = 0
      if (this.$store.state.mainledgerItems) {
        this.$store.state.mainledgerItems.map(mldetail => {
          updateSumObject.DebitTotal += parseNum(mldetail.Debit, 2)
          updateSumObject.CreditTotal += parseNum(mldetail.Credit, 2)
          updateSumObject.DebitForeginTotal += parseNum(mldetail.DebitForegin, 2)
          updateSumObject.CreditForeginTotal += parseNum(mldetail.DebitForegin, 2)
          nrItems += 1
        })
      }
      // if debit and credit are diff than 0, then check if they are same 
      // if they are same then account is OK, closed, if they are diff, then
      // account is still not same ...
      const debitTotal = updateSumObject.DebitTotal ? round(Math.abs(updateSumObject.DebitTotal),2) : 0
      const creditTotal = updateSumObject.CreditTotal ? round(Math.abs(updateSumObject.CreditTotal),2) : 0
      const newItems = []
      if (debitTotal !== 0 || creditTotal !== 0) {
        updateSumObject.DiffTotal = round(debitTotal,2) - round(creditTotal,2)
        if (updateSumObject.DiffTotal === 0) {
          updateSumObject.ClosedAccount = true
          status = true
        }
      }
      // if have items but totals are 0
      if (debitTotal === 0 && creditTotal === 0 && nrItems > 0) {
        updateSumObject.DiffTotal = round(debitTotal,2) - round(creditTotal,2)
        if (updateSumObject.DiffTotal === 0) {
          updateSumObject.ClosedAccount = true
          status = true
        }
      }
      const newMainLedgerActiv = {...this.$store.state.mainledgerActiv, ...updateSumObject}
      if (this.$store.state.mainledgerItems) {
        this.$store.state.mainledgerItems.map(mldetail => {
          const updateStatus = {
            ClosedAccount: status
          }
          const newItem = {...mldetail, ...updateStatus}
          newItems.push(newItem)
        })
      }
      await this.$store.dispatch('setMainLedgerActiv', newMainLedgerActiv)
    },

    async mainledgerDelete () {
      try {
        await this.$router.push({
          name: 'mainledgerdelete'
        })
      } catch (err) {
        this.text = err.data
        this.color = 'red'
        this.snackbar = true
      }
    },
    async markClosedDetailBSItem(ClosedAccount) {
      const newObj = {}
      this.mainQuery = {...newObj}
      this.mainQuery.id = this.head.id
      this.mainQuery.ClosedCompAcc = ClosedAccount
      await MainLedgerSideService.markcompclosed(this.mainQuery)
      try { 
        // eslint-disable-next-line
        console.log(' mark closed ok ')
        this.mldetails[this.editedIndex].ClosedCompAcc = true
        
      } catch(err) {
        // eslint-disable-next-line
        console.log(' Error on mark closed ', err)
      }
    },

  },
  computed: {
    ...mapGetters({
      myTikTok: 'NeedTikTok'
    }),

  },
  watch: {
    'myTikTok' () {
      console.log('myTikTok', this.myTikTok)
      if (this.myTikTok === 100000 || this.myTikTok === 100001) {
        if (this.myTikTok === 100001) {
          this.markClosedDetailBSItem(true)
        }
        this.$store.dispatch('setTaskTypeid', 0)
        this.$store.dispatch('setTikTok', 0)
        this.showItem = false
      }
    }
  },
  components: {
    MainBookCompCreate
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
