<template>
  <div class="task">
    <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
      <v-snackbar
        v-model="snackbar"
        bottom
        :timeout="timeout"
        :color="color"
        >
        {{ text }}
      </v-snackbar>

  <v-row>
    <v-col cols="12" sm="12" > 
      <v-card>
        <v-row align="center" class="list px-3 mx-auto">
          <v-col cols="4" sm="2">
            <v-select
              v-model="searchOption"
              :items="searchOptions"
              :label="langC.SearchBy"
              clearable
              @change="handleSearchOption"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field v-model="searchName" :label="langC.SearchBy"></v-text-field>
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn small @click="page = 1; defaultInit();">
              {{langC.Search}}
            </v-btn>
          </v-col>
          <v-col cols="5" sm="4">
            <v-pagination
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            ></v-pagination>
            
          </v-col>
          <v-col cols="4" sm="1" >
            Total:{{totalItems}}
          </v-col>
          <v-col cols="4" sm="1" >
            <v-select
              v-model="pageSize"
              :items="pageSizes"
              :label="langC.ItemsPerPage"
              @change="handlePageSizeChange"
            ></v-select>
        </v-col>
      </v-row>
     </v-card>
    </v-col>
  </v-row> 

      <v-card>
        <v-card-title>
          <v-row>
            
            <v-col cols="3" >
              <v-autocomplete
                :items="banks"
                v-model="localSelectBank"
                :label="lang.BankName"
                item-text="BankName"
                item-value="id"
                return-object
                persistent-hint
              ></v-autocomplete>
            </v-col>
            <v-col cols="3" >
              <v-file-input v-model="somefile" single label="File input"></v-file-input>
            </v-col>
            <v-col cols="2" >
              <v-btn
                class="primary mr-1"
                @click="onUpload">
                Upload
              </v-btn>
            </v-col>
            <v-col cols="2" >
              <v-text-field
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                v-model="search"
              ></v-text-field>
            </v-col>
            <v-col cols="1" >
              <v-btn color="primary" @click="fixnumbers" v-if="admin">
                Fix.Nr.
              </v-btn>
            </v-col>
          </v-row>          
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="documentId"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"
            :mobile-breakpoint="0" 
          >
          <template v-slot:[`item.BookID`]="{ item }">
              <span>{{ parseInt(item.BookID) }}</span>
          </template>
          <template v-slot:[`item.ClosedAccount`]="{ item }">
            <span v-if="item.ClosedAccount"><v-icon>mdi-check</v-icon></span>
          </template>
          <template v-slot:[`item.ClosedCompAcc`]="{ item }">
            <span v-if="item.ClosedCompAcc"><v-icon>mdi-check</v-icon></span>
          </template>
           <template v-slot:[`item.bankStatmentNumber`]="{ item }">
              <span class="title indigo--text">{{ item.bankStatmentNumber }}</span>
          </template>
          <template v-slot:[`item.bankStatmentStart`]="{ item }">
              <span>{{ formatPrice(item.bankStatmentStart) }}</span>
          </template>
          <template v-slot:[`item.bankStatmentDebit`]="{ item }">
              <span>{{ formatPrice(item.bankStatmentDebit) }}</span>
          </template>
          <template v-slot:[`item.bankStatmentCredit`]="{ item }">
              <span>{{ formatPrice(item.bankStatmentCredit) }}</span>
          </template>
          <template v-slot:[`item.bankStatmentFinish`]="{ item }">
              <span>{{ formatPrice(item.bankStatmentFinish) }}</span>
          </template>
          <template v-slot:[`item.bankStatmentDate`]="{ item }">
              <span>{{ localDate(item.bankStatmentDate) }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>
  </div>
</template>

<script>
// import axios from 'axios'
import MainLedgerService from '@/services/MainLedgerService'
import MainLedgerDetailService from '@/services/MainLedgerDetailService'
import PartnerService from '@/services/PartnerService'
import {checkBooks } from './mainledgerHelper'
import { mapGetters } from 'vuex'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import Api from '@/services/Api'
import { defNew, defMainLedger } from './mainledgerHelper'
import {dynamicSort} from '@/snippets/allSnippets'

export default {
  name: 'mainledgerlist4',
  props: ['banks', 'selectBank', 'selectBankId'],
  data () {
    return {
      documentName: '',
      documentNameLoc: '',
      docside: 0,
      msg: '',
      items: [],
      bsitems: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
        MLType: 1,
        selectBankId: null,
        somefile: null,
        MLFree2: null,
        MLFree3: null,
        MLFree4: null,
        MLFree5: null
      },
      lang: {},
      langC: {},
      saving: false,      
      firstTimeBank: true,
      somefile: null,
      MLFree2: null,
      MLFree3: null,
      MLFree4: null,
      MLFree5: null,
      headId: null,
      locError: null,
      ml: null,
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      searchName: "",
      page: 1,
      totalPages: 0,
      pageSize: 30,
      pageSizes: [10, 30, 50],
      searchOption: '',
      searchOptions: [],
      totalItems: 0,
      bookTypeSelected:{},
      admin: false,
      updatedHeads: [],
      localSelectBank: {},
      localSelectBankId: 0,
    }
  },
  mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
     if (this.$store.state.user.Admin) {
      this.admin = this.$store.state.user.Admin
    }
    if (this.selectBank) {
      this.localSelectBank = this.selectBank
    }
    if (this.selectBankId) {
      this.localSelectBankId = this.selectBankId
    }
    this.defaultInit()
  },
  methods: {
    setQuery() {
      const haveOnPage = this.$store.state.onPage
      const query = {}
      if (this.$store.state.companyid) {
        query.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        query.BusinessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentActiv) {
        query.documentName = this.$store.state.documentActiv
      }
      if (this.$store.state.bookTypeSelected) {
        query.BookTypeID = this.$store.state.bookTypeSelected.value
      }
      if (haveOnPage.selectBankId) {
        query.selectBankId = haveOnPage.selectBankId
        this.localSelectBankId = haveOnPage.selectBankId
      }
      query.searchName = haveOnPage.searchName ? haveOnPage.searchName.trim() : null
      query.page = haveOnPage.page ? haveOnPage.page : 0
      this.page  = query.page + 1
      query.pageSize = haveOnPage.pageSize ? haveOnPage.pageSize : 30
      this.pageSize = query.pageSize
      query.searchItem = haveOnPage.searchItem ? haveOnPage.searchItem : null
      return query
    },
    async defaultInit() {
      this.$store.dispatch('seteditItem', 0)
      await this.$store.dispatch('setbookTypeSelected', null)
      this.$store.dispatch('setDocumentSide', 4)
      this.show = this.$store.state.documentSide ? this.$store.state.documentSide : 4
      await checkBooks(this.show)
      const query = await this.setQuery()
      if (query.searchItem && this.searchName) {
        query.searchName =  this.searchName.trim()
        await this.$store.dispatch('setOnPage', query)
      }
      if (query.searchItem && query.searchName) {
        this.searchOption = query.searchItem
        this.searchName = query.searchName 
      }
      this.saving = true
    
      await MainLedgerService.indexbs(query)
      .then(res => {
        this.items = res.data && res.data.documents && res.data.documents.length > 0 ? res.data.documents : []
        this.totalItems = res.data && res.data.totalPages ? res.data.totalPages : 0
        this.totalPages = parseInt(this.totalItems / this.pageSize) + 1
      })
      .catch(err=> {
        console.log('Error find bank statements ', err)
        this.text = 'Bank statements are empty! Nema izvoda za ovu banku!'
        this.color = 'pink'
        this.snackbar = true
        this.timeout = 4000
        })
        
      this.saving = false
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      this.headers = []
      this.headers.push(      
        { text: this.lang.bankStatmentNumber, align: 'left', sortable: true, value: 'bankStatmentNumber' })

      if (this.$store.state.user.finance3) {
        this.headers.push(   
        { text: this.lang.ClosedAccount, align: 'left', sortable: true, value: 'ClosedAccount' })
      }
      if (this.$store.state.user.finance4) {
        this.headers.push(   
        { text: this.lang.ClosedAccount, align: 'left', sortable: true, value: 'ClosedCompAcc' })
      }
      this.headers.push(
        { text: this.lang.bankStatmentDate, align: 'left', sortable: true, value: 'bankStatmentDate' },
        { text: this.lang.bankStatmentMoneyName, align: 'left', sortable: true, value: 'bankStatmentMoneyName' },
        { text: this.lang.bankStatmentStart, align: 'right', value: 'bankStatmentStart' },
        { text: this.lang.bankStatmentDebit, align: 'right', sortable: true, value: 'bankStatmentDebit' },
        { text: this.lang.bankStatmentCredit, align: 'right', sortable: true, value: 'bankStatmentCredit' },
        { text: this.lang.bankStatmentFinish, align: 'right', sortable: true, value: 'bankStatmentFinish' },      
        { text: this.lang.bankAccountID, align: 'left', sortable: true, value: 'bankAccountID' }, 
        { text: this.lang.id, align: 'left', sortable: true, value: 'BookID' },    
        { text: 'DbId', value: 'id' }
      )
      this.searchOptions.push(
          { text: this.lang.bankStatmentNumber, align: 'left', sortable: true, value: 'bankStatmentNumber' },
          { text: this.lang.bankStatmentDate, align: 'left', sortable: true, value: 'bankStatmentDate' },
          { text: this.lang.bankStatmentStart, align: 'right', value: 'bankStatmentStart' },
          { text: this.lang.bankStatmentDebit, align: 'right', sortable: true, value: 'bankStatmentDebit' },
          { text: this.lang.bankStatmentCredit, align: 'right', sortable: true, value: 'bankStatmentCredit' },
        )
    },
    async navigateTo (params) {
      this.mldetails = []
      this.$store.dispatch('setMainLedgerActiv', {})
      this.$store.dispatch('setMainLedgerItems', [])
      this.$store.dispatch('setMainLedgerTotals', 0)
      this.$store.dispatch('seteditItem', 0)
      this.$store.dispatch('setMainLedgerid', params.id)
      this.$store.dispatch('setMainLedgerTotals', 1)
      const locDocument = await MainLedgerService.show(params.id)
      this.mainledger = locDocument.data.mainLedger
      this.mldetails = (await MainLedgerDetailService.index(params.id)).data.mainledgerDetails
      await this.$store.dispatch('setMainLedgerActiv', this.mainledger ? this.mainledger : {})
      await this.$store.dispatch('setMainLedgerItems', this.mldetails ? this.mldetails : [])
      this.bookTypeSelected.text = this.mainledger.BookName
      this.bookTypeSelected.value = parseInt(this.mainledger.BookTypeID)
      await this.$store.dispatch('setbookTypeSelected', this.bookTypeSelected)
      if (this.$store.state.user.finance3) {
        this.$router.push({
          name: 'mainledgerdetail'
        })
      }
      if (this.$store.state.user.finance4) {

      this.$store.dispatch('setretPath', 'mainledger4')
        this.$router.push({
          name: 'mainledgercompindex'
        })
      }
    },
    async fixnumbers() {
      try {
        const query = {}
        if (this.$store.state.companyid) {
          query.CompanyId = this.$store.state.companyid
        }
        if (this.$store.state.businessYear) {
          query.BusinessYear = this.$store.state.businessYear
        }
        if (this.$store.state.documentActiv) {
          query.documentName = this.$store.state.documentActiv
        }
        if (this.$store.state.documentSide) {
          query.MLType = this.$store.state.documentSide
        }
        if (this.$store.state.bookTypeSelected) {
          query.BookTypeID = this.$store.state.bookTypeSelected.value
        }
        query.bankAccountID  = this.selectBank ? parseInt(this.selectBank.id) : null
        this.updatedHeads = []

        const {data} = await MainLedgerService.index(query)
        
        if (data && data.documents && data.documents.length > 0) {
          data.documents.map(doc => {
            const newDoc = {...doc}
            newDoc.BookID = doc.bankStatmentNumber
            this.updatedHeads.push(newDoc)
          })
          
          await MainLedgerService.updatemainledgers(this.updatedHeads)
          alert('Update OK.')
        }

      } catch(err) {
        this.locError = 'Error ' + err
        this.text = 'Error ' + err
        this.color = 'red'
        this.snackbar = true
        alert(this.locError)
      }
    },
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
    async handlePageChange(value) {      
      const query = this.$store.state.onPage
      query.page = value - 1;
      this.page = value
      await this.$store.dispatch('setOnPage', query)
      this.defaultInit();
    },

    async handlePageSizeChange(size) {
      const query = this.$store.state.onPage
      query.pageSize = size;
      query.page = 1;
      this.pageSize = size
      await this.$store.dispatch('setOnPage', query)
      this.defaultInit();
    },

    async handleSearchOption(option) {
      this.searchOption = option
      const query = this.$store.state.onPage
      query.searchItem = option
      query.page = 0
      await this.$store.dispatch('setOnPage', query)
    },

    async defaultQuery() {
      const query = {}
      if (this.$store.state.companyid) {
        query.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        query.BusinessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentActiv) {
        query.documentName = this.$store.state.documentActiv
      }
      if (this.$store.state.bookTypeSelected) {
        query.BookTypeID = this.$store.state.bookTypeSelected.value
      }
      if (this.$store.state.bankActiv) {
        this.localSelectBankId = this.$store.state.bankActiv.id ? parseInt(this.$store.state.bankActiv.id) : null
        query.selectBankId = this.$store.state.bankActiv.id ? parseInt(this.$store.state.bankActiv.id) : null
      }
      query.searchName = null
      query.page =  0
      query.pageSize =  30
      query.searchItem =  null
      await this.$store.dispatch('setOnPage', query)
      this.searchName = ""
      this.page = 1
      this.totalPages = 0
      this.pageSize = 30
      this.mainQuery = query
    },
    async initAgain () {
      console.log('LIST init again !!')
      this.defaultQuery()
      this.defaultInit()
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    findBank (bankId) {
      if (bankId) {
        // if bank is selected different from selected in store then do something
        const query = this.$store.state.onPage
        if (typeof(query.selectBankId) !== 'undefined' && query.selectBankId !== bankId) {
          const locBank = bankId ? this.banks.find(bank => (bank.id === bankId)) : null
          this.$store.dispatch('setBankActiv', locBank)
          console.log('LIST find bank init again')
          this.initAgain()
        }
        // this.localSelectBankId = locBank.id ? parseInt(locBank.id) : null
        // this.mainQuery.selectBankId = locBank.id ? parseInt(locBank.id) : null
        
      }
      this.firstTimeBank = false
    },
    onUpload() {
        this.MLFree2 = ''
        this.MLFree3 = ''
        this.MLFree4 = ''
        this.MLFree5 = ''
        if (this.somefile) {
          let fd = new FormData()
          fd.append('companyId', this.$store.state.companyid)
          fd.append('originalName', this.somefile.name)
          fd.append('typeDoc', 'BS')
          fd.append('myFile', this.somefile, this.somefile.name)
          this.somefile = null

          Api().post('bookin/invoice', fd).then(async(response) => {
              this.data = response.data
              if (this.data) {
                console.log('Ucitana datoteka this.data', this.data)
                this.mainQuery.MLFree2 = this.data.originalname ? this.data.originalname : ''
                this.mainQuery.MLFree3 = this.data.filename ? this.data.filename : ''
                this.mainQuery.MLFree4 = this.data.destination ? this.data.destination : ''
                this.mainQuery.MLFree5 = this.data.path ? this.data.path : ''
                this.mainQuery.bankAccountID = this.localSelectBankId ? this.localSelectBankId : ''
                if (this.data.mimetype !==  'text/xml') {
                  await this.readbsml(1)
                }
                if (this.data.mimetype ===  'text/xml') {
                  await this.readbsml(2)
                }
              }
          }).catch(error => {
              console.log('Error uplodad ', error)
              alert(this.lang.alertError1)
              if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
              }
          });
          this.initAgain()
        } else {
          this.text = this.lang.BSfileError
          this.color = 'red'
          this.snackbar = true
          this.timeout = 4000
        }
    },
    async readbsml(type) {
      try {

        console.log('this.mainQuery', this.mainQuery, 'type', type)

        let data
        if (type === 1) {
           //data = await MainLedgerService.readbsml(this.mainQuery).res.data
           await MainLedgerService.readbsml(this.mainQuery)
          .then (res => {
            data = res && res.data && res.data
            console.log('res od servera', res)
          })
          .catch(err => {
            console.log('Greska ', err )
          })
        }
        if (type === 2) {
          await MainLedgerService.readxmlbs(this.mainQuery)
          .then (res => {
            data = res && res.data && res.data
            console.log('res od servera', res)
          })
          .catch(err => {
            console.log('Greska ', err )
          })

          // data = await MainLedgerService.readxmlbs(this.mainQuery).res.data
        }
        console.log('data', data)
        
        if (data) {
          this.ml = data.allData ? data.allData : {}
          console.log('this.ml -->>', this.ml)

          if (this.ml) {
            this.ml.nine3.map(async(nine3) => {

              const valuta = nine3.ValutaTransakcijskogRacuna4 === 'HRK' ? 'Kn' : nine3.ValutaTransakcijskogRacuna4
              const bank = this.banks ? this.banks.find(bank => (bank.IBAN === nine3.TransakcijskiRacunKlijenta3 && bank.HomeCurrency === valuta)) : {}
              
              if (bank) {
                const checkBS = {
                  CompanyId: this.$store.state.companyid,
                  BusinessYear: this.$store.state.businessYear,
                  bankAccountID: bank.id,
                  bankStatmentNumber: nine3.RedniBrojIzvatka9,
                  bankStatmentMoneyName: valuta,
                  BookTypeID: 100,
                  BookName: 'BS'
                }
                await MainLedgerService.checkifexist(checkBS)
                  .then(async(res) => {
                    if (res.data.ifexist) {
                      this.text = this.lang.BSexistError
                      this.color = 'red'
                      this.snackbar = true
                      this.timeout = 4000
                    } else {
                      const nine7 = this.ml.nine7 ? this.ml.nine7.find(item => (item.TransakcijskiRacunKlijenta1 === bank.IBAN && item.ValutaTransakcijskogRacuna2 === nine3.ValutaTransakcijskogRacuna4 )) : {}
                      await this.saveHeadNew(bank, nine3, nine7)
                      await this.saveItems(bank, nine3)
                      if (!this.locError) {
                        await this.initAgain()
                        this.somefile = null
                        alert(this.lang.alertOK1 + ', ' + nine3.RedniBrojIzvatka9 + ', ' + bank.IBAN + ' ' + nine3.ValutaTransakcijskogRacuna4)
                      }
                    }
                  })
                  .catch(err => {
                    // eslint-disable-next-line
                    console.log('Error imam ', err)
                  })
              }
              if (!bank) {
                alert('GREŠKA!! NIJE DEFINIRAN U BAZI,  ŽIRO RAČUN IBAN = ' + nine3.TransakcijskiRacunKlijenta3 + ' ' + nine3.ValutaTransakcijskogRacuna4)
              }
            })
          }
        }
      } catch(err) {
        this.locError = this.lang.alertError4
        this.text = 'Error ' + err
        this.color = 'red'
        this.snackbar = true
        alert(this.locError)
      }
    },
    async saveHeadNew(bank, nine3, nine7) {
      this.mainledger = defMainLedger()
      this.mainledger = {...this.mainledger, ...defNew()}
      this.mainledger.MLFree2 = this.mainQuery.MLFree2
      this.mainledger.MLFree3 = this.mainQuery.MLFree3
      this.mainledger.MLFree4 = this.mainQuery.MLFree4
      this.mainledger.MLFree5 = this.mainQuery.MLFree5
      this.mainledger.bankAccountID = bank.id
      this.mainledger.bankAccountName = bank.bankAccountName
      this.mainledger.bankAccountNr = nine7.TransakcijskiRacunKlijenta1
      this.mainledger.bankStatmentNumber = nine7.RedniBrojIzvatka4
      this.mainledger.bankStatmentDate = nine7.DatumIzvatka6
      this.mainledger.bankStatmentMoneyName = nine7.ValutaTransakcijskogRacuna2 === 'HRK' ? 'Kn' : nine7.ValutaTransakcijskogRacuna2
      this.mainledger.bankStatmentStart = nine7.PrethodnoStanje9
      this.mainledger.bankStatmentDebit = nine7.UkupniDugovniPromet18
      this.mainledger.bankStatmentCredit = nine7.UkupniPotrazniPromet20
      this.mainledger.bankStatmentFinish = nine7.NovoStanje22
      //this.findLast()
      this.mainledger.documentIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-' + this.$store.state.documentActiv + '-' + bank.id + '-' + nine7.RedniBrojIzvatka4
      this.mainledger.BookID = nine7.RedniBrojIzvatka4
      this.mainledger.BookTypeID = 100
      this.mainledger.BookName = 'BS'
      await this.saveHead()
    },
    async checkBSifExist(checkBS) {
      try {
        await MainLedgerService.checkifexist(checkBS)
          .then(res => {
            if (res.data.ifexist) {
              console.log("🚀 ~ chek ima")
              return true
            }
          })  
      } catch (err) {
        console.log('check NEMA ', err)
        return false
      }
    },
    async saveHeadBS () {

    },
    async readbsmlOLD() {
      try {
        
        await MainLedgerService.readbsml(this.mainQuery).then(async(res) => {

          this.ml = res.data.allData

          this.mainQuery.bankAccountNr = this.ml.nine7.TransakcijskiRacunKlijenta1
          this.mainQuery.bankStatmentNumber = this.ml.nine7.RedniBrojIzvatka4
          this.mainQuery.bankStatmentMoneyName= this.ml.nine7.ValutaTransakcijskogRacuna2

          const bank = this.banks ? this.banks.find(bank => (bank.IBAN === this.mainQuery.bankAccountNr)) : {}

          this.mainledger = defMainLedger()
          this.mainledger = {...this.mainledger, ...defNew()}
          this.mainledger.MLFree2 = this.mainQuery.MLFree2
          this.mainledger.MLFree3 = this.mainQuery.MLFree3
          this.mainledger.MLFree4 = this.mainQuery.MLFree4
          this.mainledger.MLFree5 = this.mainQuery.MLFree5
          this.mainledger.bankAccountID = bank.id
          this.mainledger.bankAccountName = bank.bankAccountName
          this.mainledger.bankAccountNr = this.ml.nine7.TransakcijskiRacunKlijenta1
          this.mainledger.bankStatmentNumber = this.ml.nine7.RedniBrojIzvatka4
          this.mainledger.bankStatmentDate = this.ml.nine7.DatumIzvatka6
          this.mainledger.bankStatmentMoneyType= this.ml.nine5.ValutaPokrica8 
          this.mainledger.bankStatmentMoneyName = this.ml.nine7.ValutaTransakcijskogRacuna2
          this.mainledger.bankStatmentStart = this.ml.nine7.PrethodnoStanje9
          this.mainledger.bankStatmentDebit = this.ml.nine7.UkupniDugovniPromet18
          this.mainledger.bankStatmentCredit = this.ml.nine7.UkupniPotrazniPromet20
          this.mainledger.bankStatmentFinish = this.ml.nine7.NovoStanje22
          // this.findLast() - why I put these here ??? - 
          this.mainledger.documentIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-' + this.$store.state.documentActiv + '-' + this.mainledger.bankAccountID + '-' + this.mainledger.bankStatmentNumber
          this.mainledger.BookID = this.mainledger.bankStatmentNumber
          this.mainledger.BookTypeID = 100
          this.mainledger.BookName = 'BS'
          const checkBSifexist = {
            CompanyId: this.$store.state.companyid,
            BusinessYear: this.$store.state.businessYear,
            bankAccountID: bank.id,
            bankStatmentNumber: this.ml.nine7.RedniBrojIzvatka4,
            bankStatmentMoneyName: this.ml.nine7.ValutaTransakcijskogRacuna2,
            BookTypeID: 100,
            BookName: 'BS'
          }
          await MainLedgerService.checkifexist(checkBSifexist)
          .then(async(res) => {
            if (res.data.ifexist) {
              this.text = this.lang.BSexistError
              this.color = 'red'
              this.snackbar = true
              this.timeout = 4000
            } else {
              await this.saveHead()
              await this.saveItems()
              if (!this.locError) {
                await this.initAgain()
                this.somefile = null
                const alertMsg = this.lang.alertOK1 + this.mainledger.bankStatmentNumber + ' ' + this.mainledger.bankStatmentMoneyName
                alert(alertMsg)
              }
            }
          })
          .catch(err => {
            // eslint-disable-next-line
            console.log('Error imam ', err)
          })

        }).catch(error => {
          // eslint-disable-next-line
          console.log('Error ', error)
        })         
      } catch (error) {
        this.locError = this.lang.alertError4
        this.text = 'Error ' + error
        this.color = 'red'
        this.snackbar = true
        alert(this.locError)
      }
    },
    async findLast() {
      try {
        this.mainledger.BookID = (await MainLedgerService.choose(this.mainQuery)).data.docNumber
      } catch (error) {
        this.locError = this.lang.alertError2
        // eslint-disable-next-line
        console.log('Error on find ID ', error)
        alert(this.locError)
      }
    },
    async saveHead() {
      try {
        this.headId = (await MainLedgerService.post(this.mainledger)).data.mainledger.id
      } catch (error) {
        this.locError = this.lang.alertError3
        // eslint-disable-next-line
        console.log('Error on save head ', error)
        alert(this.locError)
      }
    },
    async saveItems(bank, nine3) {
      try {
        this.bsitems.length = 0
        let nr = 0
        if (this.ml.nine5 && this.headId) {
          this.ml.nine5.sort(dynamicSort("RedniBrojStavke"))

          this.ml.nine5.map(nine5 => {
            if (nine5.TransakcijskiRacunKlijenta3 === bank.IBAN && nine5.ValutaTransakcijskogRacuna4 === nine3.ValutaTransakcijskogRacuna4 ) {
              const mlSide = defNew()
              nr += 1
              mlSide.databaseMLID = this.headId
              mlSide.documentIdGeneral = this.mainledger.documentIdGeneral 
              mlSide.partnerName  = nine5.NazivPrimatelja3 ? nine5.NazivPrimatelja3 : ''
              mlSide.partnerBankIBAN  = nine5.RacunPrimatelja2 ? nine5.RacunPrimatelja2 : ''
              mlSide.bankStatmentNumberDetail = nr
              mlSide.bankReference1  = nine5.PozivNaBrojPlatitelja14.substr(0, 4) ? nine5.PozivNaBrojPlatitelja14.substr(0, 4) : ''
              mlSide.bankReference2  = nine5.PozivNaBrojPlatitelja14 && nine5.PozivNaBrojPlatitelja14.length > 4 ? nine5.PozivNaBrojPlatitelja14.substr(4, nine5.PozivNaBrojPlatitelja14.length) : ''
              mlSide.bankCall1  = nine5.PozivNaBrojPrimatelja15.substr(0, 4) ? nine5.PozivNaBrojPrimatelja15.substr(0, 4) : ''
              mlSide.bankCall2  = nine5.PozivNaBrojPrimatelja15 && nine5.PozivNaBrojPrimatelja15.length > 4 ? nine5.PozivNaBrojPrimatelja15.substr(4, nine5.PozivNaBrojPrimatelja15.length) : ''
              mlSide.bankStatmentDebit  = nine5.OznakaTransakcije1 === "10" &&  nine5.Iznos13 ? nine5.Iznos13 : 0
              mlSide.bankStatmentCredit  = nine5.OznakaTransakcije1 === "20" &&  nine5.Iznos13 ? nine5.Iznos13 : 0
              mlSide.bankDescription  = nine5.OpisPlacanja17 ? nine5.OpisPlacanja17 : ''
              mlSide.bankStatmentAccount  = nine5.TransakcijskiRacunKlijenta3 ? nine5.TransakcijskiRacunKlijenta3 : ''
              mlSide.bankStatmentReference  = nine5.ValutaTransakcijskogRacuna4 ? nine5.ValutaTransakcijskogRacuna4 : ''
              mlSide.FreeF6  = nine5.RedniBrojIzvatka9 ? nine5.RedniBrojIzvatka9 : ''

              if (mlSide.partnerBankIBAN){
                const cIban = {
                  partnerBankIBAN: mlSide.partnerBankIBAN
                }
                PartnerService.checkIBAN(cIban)
                .then(res => {
                  if (res.data.partner) {
                    const pr = res.data.partner
                    mlSide.partnerId = pr.id ? pr.id : null
                    mlSide.partnerName = pr.partnerName ? pr.partnerName : null
                    mlSide.partnerNameShort = pr.partnerNameShort ? pr.partnerNameShort : null
                    mlSide.partnerNameDatabase = pr.partnerNameDatabase ? pr.partnerNameDatabase : null
                    mlSide.partnerVATID = pr.partnerVATID ? pr.partnerVATID : null
                  }
                })
              }
              this.bsitems.push(mlSide)
            }
          })
        }
        if (this.bsitems.length > 0) {
          await MainLedgerService.saveitems(this.bsitems)
        }
      } catch (error) {
        this.locError = this.lang.alertError3
        // eslint-disable-next-line
        console.log('Error on save items ', error)
        alert(this.locError)
      }
    }
  },
  computed: {
    ...mapGetters({
      myDocumentActivState: 'NeedDocumentActiv'
    })
  },
  watch: {
    'myDocumentActivState' () {
      console.log('LIST myDocumentActivState init again')
      this.initAgain(this.myDocumentActivState)
    },
    'localSelectBank' (val) {
      if (val) {
        val.id && this.findBank(val.id) 
      }           
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* OznakaTransakcije1: "10"
RacunPrimatelja2: "HR7023400091160427720"
NazivPrimatelja3: "VIRTUALNI URED, VL. DANIJELA KOVA�"
AdresaPrimatelja4: "A.STAR�EVI�A 43"
SjedistePrimatelja5: "�AKOVEC"
DatumValute6: "2020-01-12T23:00:00.000Z"
DatumIzvrsenja7: "2020-01-12T23:00:00.000Z"
ValutaPokrica8: "HRK"
Tecaj9: 0
PredznakValuta10: "+"
IznosUValuti11: 1850
PredznakLokalno12: "+"
Iznos13: 1850
PozivNaBrojPlatitelja14: "HR99"
PozivNaBrojPrimatelja15: "HR99"
SifraNamjene16: ""
OpisPlacanja17: "Racun"
IdentifikatorTransakcijeUnutarFine18: ""
IdentifikatorTransakcijeVanFine19: "2020013687383"
Rezerva20: ""
TipSloga21: ""
__proto__: Object
1: {OznakaTransakcije1: "10", RacunPrimatelja2: "HR8523600001500074255", NazivPrimatelja3: "HT D.D.-USLUGE FIKSNE MRE�E", AdresaPrimatelja4: "A.STAR�EVI�A 43", SjedistePrimatelja5: "ZAGREB", …}
2: {OznakaTransakcije1: "10", RacunPrimatelja2: "HR7023400091160427720", NazivPrimatelja3: "VIRTUALNI URED, VL. DANIJELA KOVA�", AdresaPrimatelja4: "A.STAR�EVI�A 43", SjedistePrimatelja5: "�AKOVEC", …}
3:
OznakaTransakcije1: "20"
RacunPrimatelja2: "HR5525030071100097172"
NazivPrimatelja3: "LL GRUPA dru�tvo s ograni�enom odgovorno��u za proizvodnju, trgovinu i"
AdresaPrimatelja4: "Kalni�ka 63"
SjedistePrimatelja5: "40000 �akovec"
DatumValute6: "2020-01-12T23:00:00.000Z"
DatumIzvrsenja7: "2020-01-12T23:00:00.000Z"
ValutaPokrica8: "HRK"
Tecaj9: 0
PredznakValuta10: "+"
IznosUValuti11: 950
PredznakLokalno12: "+"
Iznos13: 950
PozivNaBrojPlatitelja14: "HR99"
PozivNaBrojPrimatelja15: "HR0242020"
SifraNamjene16: ""
OpisPlacanja17: "RA�UN BR.4/1/1"
IdentifikatorTransakcijeUnutarFine18: ""
IdentifikatorTransakcijeVanFine19: "2020013916149"
Rezerva20: ""
TipSloga21: "" */
</style>

