const docHR = {
  CashDesk: 'Blagajna',
  documentDate: 'Datum',
  Description: 'Opis',
  CashIn: "Uplata",
  CashInFore: 'Uplata Ino',
  CashOut: 'Isplata',
  CashOutFore: 'Isplata Ino',
  CashSaldo: 'Saldo',
  CashSaldoFore: 'Saldo Ino',
  Employeeemail: 'Zaposlenik',
  NewCashDesk: 'Nova uplatnica/isplatnica',
  EditCashDesk: 'Uredi uplatnicu/isplatnicu',
  EmptyCashDesk: 'Nema upisanih uplatnica/isplatnica',
  Discount: 'Popust',
  CurrentMonth: 'Mjesec',
  ReportCreated: ' izvještaj, kreiran:  '
}

export default docHR