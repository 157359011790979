<template>
  <div class="task">

    <v-card>
       <v-toolbar flat color="white" >
          <v-toolbar-title>{{documentNameLoc}} - stavke prethodne</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-toolbar>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="20"
            item-key="id"
          >
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
          <template v-slot:[`item.documentDate`]="{ item }">
              <span>{{ localDate(item.documentDate) }}</span>
          </template>
          <template v-slot:[`item.itemPrice`]="{ item }">
              <span>{{ localMoney(item.itemPrice) }}</span>
          </template>
          <template v-slot:[`item.itemBaseAmount`]="{ item }">
              <span>{{ localMoney(item.itemBaseAmount) }}</span>
          </template>
          <template v-slot:[`item.itemBaseAmountNoVAT`]="{ item }">
              <span>{{ localMoney(item.itemBaseAmountNoVAT) }}</span>
          </template>
          <template v-slot:[`item.itemAmountWithVAT`]="{ item }">
              <span>{{ localMoney(item.itemAmountWithVAT) }}</span>
          </template>
           <template v-slot:[`item.documentName`]="{ item }">
              <span>{{ localName(item.documentName) }}</span>
          </template>
        </v-data-table>
      </v-card>
  </div>
</template>

<script>
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import DocumentDetailService from '@/services/DocumentDetailService'
import dateFormat from '@/snippets/dateFormat'
import moneyFormat from '@/snippets/moneyFormat'
import documentLocalName from '@/snippets/documentLocalName'

export default {
  name: 'documentdetaillistprevious',
  data () {
    return {
      documentNameLoc: '',
      lang: {},
      langC: {},
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: '',
        databaseDocID: 0,
        partnerId: 0
      }
    }
  },
  async mounted () {
      this.documentNameLoc = this.$store.state.documentActivLoc
      if (this.$store.state.language === 'EN') {
        this.lang = langEn
        this.langC = commEn
      }
      if (this.$store.state.language === 'HR') {
        this.lang = langHr
        this.langC = commHr
      }
      if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentActiv) {
        this.mainQuery.documentName = this.$store.state.documentActiv
      }
      if (this.$store.state.documentActivHead) {
        this.mainQuery.partnerId = this.$store.state.documentActivHead.partnerId ? this.$store.state.documentActivHead.partnerId : ''
      }
      if (this.$store.state.documentActivHead) {
        this.mainQuery.partnerVATID = this.$store.state.documentActivHead.partnerVATID ? this.$store.state.documentActivHead.partnerVATID : ''
      }
      this.items = (await DocumentDetailService.olddocs(this.mainQuery)).data.documentDetails
      this.items.sort((a,b) => (a.documentDate < b.documentDate) ? 1 : ((b.documentDate < a.documentDate) ? -1 : 0))

      if (this.$store.state.documentSide === 1) {
        this.headers.push(
          {
            text: this.lang.ItemName,
            align: 'left',
            sortable: true,
            value: 'itemName',
            width: '350px'
          },
          { text: this.lang.ItemCode, align: 'left', sortable: true, value: 'ItemCode', width: '50px' },
          { text: this.lang.ItemUnit, align: 'center', value: 'itemUnit', width: '35px' },
          { text: this.lang.ItemService, align: 'center', value: 'itemService', width: '50px' },
          { text: this.lang.ItemPcsPlus, align: 'right', value: 'itemPcsPlus', width: '100px' },
          { text: this.lang.ItemPrice, align: 'right', value: 'itemPrice', width: '100px' },        
          { text: this.lang.ItemBaseAmount, align: 'right', sortable: true, value: 'itemBaseAmount', width: '100px' },
          { text: this.lang.ItemDiscountPercentage1, align: 'right', value: 'itemDiscountPercentage1', width: '30px' },
          { text: this.lang.ItemBaseAmountNoVAT, align: 'right', value: 'itemBaseAmountNoVAT', width: '100px' },
          { text: this.lang.ItemVAT1Percentage, align: 'right', sortable: true, value: 'itemVAT1Percentage', width: '30px' },
          { text: this.lang.ItemAmountWithVAT, align: 'right', value: 'itemAmountWithVAT', width: '100px' },
          { text: this.lang.id, value: 'id', width: '50px' }
        )
      }
      if (this.$store.state.documentSide === 2) {
        this.headers.push(
          {
            text: this.lang.ItemName,
            align: 'left',
            sortable: true,
            value: 'itemName',
            width: '350px'
          },
          { text: this.lang.ItemCode, align: 'left', sortable: true, value: 'ItemCode', width: '50px' },
          { text: this.lang.ItemUnit, align: 'center', value: 'itemUnit', width: '35px' },
          { text: this.lang.ItemService, align: 'center', value: 'itemService', width: '50px' },
          { text: this.lang.ItemPcsMinus, align: 'right', value: 'itemPcsMinus', width: '100px' },
          { text: this.lang.ItemPrice, align: 'right', value: 'itemPrice', width: '100px' },        
          { text: this.lang.ItemBaseAmount, align: 'right', sortable: true, value: 'itemBaseAmount', width: '100px' },
          { text: this.lang.ItemDiscountPercentage1, align: 'right', value: 'itemDiscountPercentage1', width: '30px' },
          { text: this.lang.ItemBaseAmountNoVAT, align: 'right', value: 'itemBaseAmountNoVAT', width: '100px' },
          { text: this.lang.ItemVAT1Percentage, align: 'right', sortable: true, value: 'itemVAT1Percentage', width: '30px' },
          { text: this.lang.ItemAmountWithVAT, align: 'right', value: 'itemAmountWithVAT', width: '100px' },
          { text: this.lang.id, value: 'id', width: '50px' }
        )
      }
      if (this.$store.state.documentSide === 3) {
        this.headers.push(
          { text: this.lang.email, align: 'left', sortable: true, value: 'email', width: '80px' },
          { text: this.lang.documentName, align: 'left', sortable: true, value: 'documentName', width: '60px' },
          { text: this.lang.documentId, align: 'left', sortable: true, value: 'documentId', width: '50px' },
          { text: this.lang.DocumentDate, align: 'left', sortable: true, value: 'documentDate', width: '120px' },
          { text: this.lang.partnerId, align: 'left', sortable: true, value: 'partnerId', width: '50px' },
          { text: this.lang.partnerNameDatabase, align: 'left', sortable: true, value: 'partnerNameDatabase', width: '80px' },
          { text: this.lang.HomeCurrency, align: 'left', sortable: true, value: 'HomeCurrency', width: '50px' },
          {
            text: this.lang.ItemName,
            align: 'left',
            sortable: true,
            value: 'itemName',
            width: '250px'
          },
          { text: this.lang.ItemCode, align: 'left', sortable: true, value: 'ItemCode', width: '50px' },
          { text: this.lang.ItemUnit, align: 'center', value: 'itemUnit', width: '35px' },
          { text: this.lang.ItemService, align: 'center', value: 'itemService', width: '50px' },
          { text: this.lang.ItemPcsNeutral, align: 'right', value: 'itemPcsNeutral', width: '100px' },
          { text: this.lang.ItemPrice, align: 'right', value: 'itemPrice', width: '100px' },        
          { text: this.lang.ItemBaseAmount, align: 'right', sortable: true, value: 'itemBaseAmount', width: '100px' },
          { text: this.lang.ItemDiscountPercentage1, align: 'right', value: 'itemDiscountPercentage1', width: '30px' },
          { text: this.lang.ItemBaseAmountNoVAT, align: 'right', value: 'itemBaseAmountNoVAT', width: '100px' },
          { text: this.lang.ItemVAT1Percentage, align: 'right', sortable: true, value: 'itemVAT1Percentage', width: '30px' },
          { text: this.lang.ItemAmountWithVAT, align: 'right', value: 'itemAmountWithVAT', width: '100px' },
          { text: this.lang.id, value: 'id', width: '50px' }
        )
      }

  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
    localName(name) {
      console.log("🚀 ~ localName ~ name", name)
      return documentLocalName(name)
    },
    

  },
  computed: {

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
