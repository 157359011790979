const commEN = {
  Company: 'Company',
  BusinessYear: 'BusinessYear',
  Number: 'Number',
  User: 'User',
  UserId: 'Created by User ID',
  UserEmail: 'User email',
  CreatedAt: 'Created At',
  UpdatedAt: 'Updated At',
  Save: 'SAVE',
  SaveItem: 'SAVE ITEM',
  Edit: 'EDIT',
  Delete: 'DELETE',
  Back: 'BACK',
  Error: 'Error',
  Copy: 'COPY',
  Saved: ' Data saved succesfully',
  Cancel: 'Cancel',
  Find: 'Find',
  CreateNew: 'Create New',
  SaveAndClose: 'Save and Close',
  SaveAndNext: 'Save and Next',
  CreateDetails: 'Create Details',
  DocumentSaved: 'Document has been saved.',
  Print: 'Print',
  Analysis: 'Analysis',
  FromDate: 'From date',
  ToDate: 'To date',
  StartDate: 'Start date',
  EndDate: 'End date',
  PrintDate: 'Print Date:',
  AreYouShureToDelete: 'Are you sure you want to delete this item?',
  AreYouShureToCreate: 'Are you sure you want to create ',
  ErrorCannotSave: 'Error! Cannot save to database.',
  ErrorCannotUpdate: 'Error! Cannot update in database.',
  ErrorCannotDelete: 'Error! Cannot delete in database.',
  ErrorCannotLoad: 'Error! Cannot load data from database.',
  InProgress: 'Uppsss. Sorry function is in development!!',
  BackToList: 'BACK to list',
  HidePrice: 'Hide price',
  SmallPrint: 'Print on A6',
  Previous: 'Previous',
  Warehouse: 'Warehouse',
  Warehouse2: 'WH',
  DocumentName: 'Document Name',
  Locked: 'Locked',
  LockedDate: 'Locked Date',
  Date: 'Date',
  LastOne: 'Sorry, this is the last one',
  Partner: 'Partner ID',
  Partners: 'Partners',
  SelectPartner: 'Select Partner',
  BalanceSheet: 'Balance Sheet',
  PartnerCard: 'Partner Card',
  PartnerOpen: 'Partner Open statement',
  AllPartnerOpen: 'All Partners Open',
  AllPartnerOpenDet: 'All Partn.Open Det',
  VAT: 'VAT id ',
  NumberNotExist: 'Sorry. Entered number not exist! ',
  AccountMissing: 'Sorry. Account is missing ',
  AccountIsNotSupplierBuyer: 'Sorry. Account is NOT buyer/supplier ',
  InvoiceNumberMissing: 'Sorry. Invoice number missing ',
  DatabaseError: 'Connection to database error ',
  ErrorE: 'Error ',
  Today: 'Today',
  Day: 'Day',
  Week: 'Week',
  Month: 'Month',
  FourDays: '4 days',
  useMemo: 'Use memo',
  checkVATID: 'Check VAT ID',
  Continue: 'Continue',
  ShowAll: 'Show All',
  Search: 'Search',
  SearchBy: 'Search by',
  showSerials: 'Show Serials',
  Disabled: 'Disabled',
  ItemsPerPage: 'Items per page',
  PriceChange: 'Price Chng.Create',
  FillData: 'Fill DAta',
  NewTask: 'New Task',
  Done: 'Done',
  Close: 'Close',
  DocumentSaveError: 'Erorr!! Document is not saved!!',
  DeleteSelected: 'Delete Selected',
  JustWait: 'Please wait ...'
}

export default commEN