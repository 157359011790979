<template>
 <v-container fluid grid-list-md>
      <v-layout row>
      <v-flex d-flex xs12 sm6 md4 xl4>
          <div class="headline">{{langC.InProgress}}</div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"

export default {
  name: 'appinprogress',
  data () {
    return {
      menu: false,
      modal: false,
      msg: '',
      valid: true,
      account: { },
      error: null,
      localError: false,
      privGroups: [],
      lang: {},
      langC: {},
      saving: false,
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.langC = commHr;
      }
  },
  methods: {

  },
  computed: {
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
