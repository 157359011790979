import Api from '@/services/Api'

export default {
  index(query) {
    return Api().get('partner', {
      params: query
    })
  },
  index2(query) {
    return Api().get('partner/index2', {
      params: query
    })
  },
  indextotal(query) {
    return Api().get('partner/indextotal', {
      params: query
    })
  },
  index2total(query) {
    return Api().get('partner/index2total', {
      params: query
    })
  },
  testvatid(query) {
    return Api().get('partner/testvatid', {
      params: query
    })
  },
  findbyid(partnerId) {
    return Api().get(`partner/findbyid/${partnerId}`)
  },
  show(partnerVATID) {
    return Api().get(`partner/${partnerVATID}`)
  },
  post(newpartner) {
    return Api().post('partner', newpartner)
  },
  put(partner) {
    return Api().put(`partner/${partner.id}`, partner)
  },
  delete(partnerId) {
    return Api().delete(`partner/${partnerId}`)
  },
  choose(query) {
    return Api().get('partner/choose', {
      params: query
    })
  },
  check(query) {
    return Api().get('partner/check', {
      params: query
    })
  },
  checkIBAN(query) {
    return Api().get('partner/checkiban', {
      params: query
    })
  }
}