<template>
  <v-row style="border-bottom:solid 1px; border-top:solid 1px;" no-gutters>
      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left">
        Rb.  Vrsta
      </v-col>
      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left">
        Broj
      </v-col>
      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left">
        Datum knjiž.
      </v-col>
      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left">
        Broj dok.
      </v-col>
      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
        Duguje
      </v-col>
      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
        Potražuje
      </v-col> 
      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-center">
        Saldo
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="text-center">
        Opis
      </v-col>
      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" v-if="type == 4">
        Partner
      </v-col>
      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" v-if="type == 4">
        Projekt
      </v-col>
      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" v-if="type == 6">
        Datum rač.
      </v-col>
      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" v-if="type == 6">
        Datum dosp.
      </v-col>
      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left">
        Veza
      </v-col>

  </v-row>
</template>


<script>

export default {
  name: '',
  props: ['type'],
  data: ()=> ({
    
  }),
  mounted () {

  },
  methods: {

  },
  computed: {
  },
  watch: {

  },
  components: {

  }

}

</script>