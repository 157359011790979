import {round, dynamicSortMultiple} from '@/snippets/allSnippets'
import SalarieService from '@/services/SalarieService'
import EmployeeService from '@/services/EmployeeService'

async function freeRAD1 (companyid,fromDate, toDate, typeId, userId) {

  // userId as a single user cannot be selected on RAD1 form

  const newObj = {}
  const empF =[]
  const mainQuery = {}
  let eemp = null
  let filEmp = []
  const filEmpT1 = []
  const filEmpT2 = []
  const filEmpT3456 = []
  const filEmpT7 = []
  const retObject = {}

  const table1 = {}
  const table3 = {}
  const table4 = {}
  const table5 = {}
  const table6 = {}
  const table7 = {}
  let uk7 = 0 
  let uk7K = 0
  let izvrseni = 0
  let izvrseniK = 0
  let neizvrseni = 0
  let neizvrseniK = 0
  let neizvrseniVan = 0
  let neizvrseniVanK = 0
  let neplaceni = 0
  let neplaceniK = 0
  let prekovremeni = 0
  let prekovremeniK = 0
  let strajk = 0
  let strajkK = 0
  let prosjek = 0
  let prosjekK = 0
  let prosjekBroj = 0
  let prosjekBrojK = 0
  
  mainQuery.CompanyId = companyid
  await EmployeeService.index(mainQuery)
      .then(res => {
        if (res.data && res.data.employees) {
          eemp = res.data.employees
        }
      })
      .catch (err => {
        // eslint-disable-next-line
        console.log(err)
      })

  if (!eemp.length) {
    return empF
  }

  let haveError = false
  if (eemp.length > 0) {
    eemp.map(emp => {
      haveError = false
      const endDate = emp.EndDateInCompany !== undefined &&  emp.EndDateInCompany !== null? new Date(emp.EndDateInCompany) : null
      // left company before endDate
      if (endDate & endDate >= toDate) {
        haveError = true
      }
      const startDate = emp.StartInCompany !== undefined &&  emp.StartInCompany !== null ? new Date(emp.StartInCompany) : null
      // start in company after Start date
      if (startDate & startDate <= fromDate) {
        haveError = true
      }
      if(emp.black) {
        haveError = true
      }
      if(emp.Gender === undefined &&  emp.Gender === null) {
        haveError = true
      }
      if (haveError === false) {
        // puno radno vrijeme
        if (parseInt(emp.VrstaRadnogVremena) === 1) {
          filEmpT1.push(emp)
        }
        // nepuno radno vrijeme
        if (parseInt(emp.VrstaRadnogVremena) === 2) {
          filEmpT2.push(emp)
        }
      }
    })

    const borderDate = new Date("2024-03-31")
    eemp.map(emp => {
      haveError = false
      const endDate = emp.EndDateInCompany !== undefined &&  emp.EndDateInCompany !== null? new Date(emp.EndDateInCompany) : null
      // left company before borderdate
      if (endDate & endDate < borderDate) {
        haveError = true
      }
      const startDate = emp.StartInCompany !== undefined &&  emp.StartInCompany !== null ? new Date(emp.StartInCompany) : null
      // start in company after border date
      if (startDate & startDate > borderDate) {
        haveError = true
      }
      if(emp.black === true) {
        haveError = true
      }
      if(emp.Gender === undefined || emp.Gender === null) {
        haveError = true
      }
      if (haveError === false) {
          filEmpT3456.push(emp)
      }
    })

    eemp.map(emp => {
      haveError = false
      if(emp.black === true) {
        haveError = true
      }
      if(emp.Gender === undefined || emp.Gender === null) {
        haveError = true
      }
      if (haveError === false) {
          filEmpT7.push(emp)
      }
    })

  }

  function doTable7(em, vrstaRV) {


        if(vrstaRV === 1) {
          if (em.RedovanRad !== undefined &&  em.RedovanRad !== null) {
            izvrseni += em.RedovanRad
          }

          let neLok = 0
          if (em.GodisnjiOdmor !== undefined &&  em.GodisnjiOdmor !== null) {
            neLok += em.GodisnjiOdmor
          }
          if (em.BolovanjeDo42Dana !== undefined &&  em.BolovanjeDo42Dana !== null) {
            neLok += em.BolovanjeDo42Dana
          }
          if (em.BlagdanRad !== undefined &&  em.BlagdanRad !== null) {
            neLok += em.BlagdanRad
          }
          if (em.NeradniBlagdan !== undefined &&  em.NeradniBlagdan !== null) {
            neLok += em.NeradniBlagdan
          }
          neizvrseni += neLok

          if (em.Rodiljni !== undefined &&  em.Rodiljni !== null) {
            neizvrseniVan += em.Rodiljni
          }
          if (em.BolovanjeOd42Dana !== undefined &&  em.BolovanjeOd42Dana !== null) {
            neizvrseniVan += em.BolovanjeOd42Dana
          }

          if (em.NeplaceniDopust !== undefined &&  em.NeplaceniDopust !== null) {
            neplaceni += em.NeplaceniDopust
          }

          if (em.NenazocnostNaZahtjevRadnika !== undefined &&  em.NenazocnostNaZahtjevRadnika !== null) {
            neplaceni += em.NeplaceniDopust
          }

          if (em.PrekovremeniRad !== undefined &&  em.PrekovremeniRad !== null) {
            prekovremeni += em.NeplaceniDopust
          }

          if (em.Strajk !== undefined &&  em.Strajk !== null) {
            strajk += em.NeplaceniDopust
          }

          uk7 = izvrseni + neizvrseni + neizvrseniVan + neplaceni + prekovremeni + strajk
          prosjekBroj = uk7 / 2088
          prosjek = prosjekBroj !== null && prosjekBroj !== 0 ? uk7 /prosjekBroj : 0

        }
        if(vrstaRV === 2 || vrstaRV === 3) {

          if (em.RedovanRad !== undefined &&  em.RedovanRad !== null) {
            izvrseniK += em.RedovanRad
          }
          let neLok = 0
          if (em.GodisnjiOdmor !== undefined &&  em.GodisnjiOdmor !== null) {
            neLok += em.GodisnjiOdmor
          }
          if (em.BolovanjeDo42Dana !== undefined &&  em.BolovanjeDo42Dana !== null) {
            neLok += em.BolovanjeDo42Dana
          }
          if (em.BlagdanRad !== undefined &&  em.BlagdanRad !== null) {
            neLok += em.BlagdanRad
          }
          if (em.NeradniBlagdan !== undefined &&  em.NeradniBlagdan !== null) {
            neLok += em.NeradniBlagdan
          }
          neizvrseniK += neLok

          if (em.Rodiljni !== undefined &&  em.Rodiljni !== null) {
            neizvrseniVanK += em.Rodiljni
          }
          if (em.BolovanjeOd42Dana !== undefined &&  em.BolovanjeOd42Dana !== null) {
            neizvrseniVanK += em.BolovanjeOd42Dana
          }

          if (em.NeplaceniDopust !== undefined &&  em.NeplaceniDopust !== null) {
            neplaceniK += em.NeplaceniDopust
          }

          if (em.NenazocnostNaZahtjevRadnika !== undefined &&  em.NenazocnostNaZahtjevRadnika !== null) {
            neplaceniK += em.NeplaceniDopust
          }

          if (em.PrekovremeniRad !== undefined &&  em.PrekovremeniRad !== null) {
            prekovremeniK += em.NeplaceniDopust
          }

          if (em.Strajk !== undefined &&  em.Strajk !== null) {
            strajkK += em.NeplaceniDopust
          }

          uk7K = izvrseniK + neizvrseniK + neizvrseniVanK + neplaceniK + prekovremeniK + strajkK
          prosjekBrojK = uk7K / 2088
          prosjekK = prosjekBrojK !== null && prosjekBrojK !== 0 ? uk7K /prosjekBrojK : 0

        }

        table7.uk7 = uk7
        table7.uk7K = uk7K
        table7.izvrseni = izvrseni
        table7.izvrseniK = izvrseniK
        table7.neizvrseni = neizvrseni
        table7.neizvrseniK = neizvrseniK
        table7.neizvrseniVan = neizvrseniVan
        table7.neizvrseniVanK = neizvrseniVanK
        table7.neplaceni = neplaceni
        table7.neplaceniK = neplaceniK
        table7.prekovremeni = prekovremeni
        table7.prekovremeniK = prekovremeniK
        table7.strajk = strajk
        table7.strajkK = strajkK
        table7.prosjek = prosjek
        table7.prosjekK = prosjekK
        table7.prosjekBroj = prosjekBroj
        table7.prosjekBrojK = prosjekBrojK
      

    
  }

  function doTable3456() {
    let do18 = 0
    let od19 = 0
    let od25 = 0
    let od30 = 0
    let od35 = 0
    let od40 = 0
    let od45 = 0
    let od50 = 0
    let od55 = 0
    let od60 = 0
    let od65 = 0
    let do18z = 0
    let od19z = 0
    let od25z = 0
    let od30z = 0
    let od35z = 0
    let od40z = 0
    let od45z = 0
    let od50z = 0
    let od55z = 0
    let od60z = 0
    let od65z = 0

    let oDok = 0
    let oDokZ = 0
    let oMag = 0
    let oMagZ = 0
    let oPre = 0
    let oPreZ = 0
    let oStr = 0
    let oStrZ = 0
    let oGim = 0
    let oGimZ = 0
    let oSS4 = 0
    let oSS4Z = 0
    let oSS3 = 0
    let oSS3Z = 0
    let oPri = 0
    let oPriZ = 0
    let oOsn = 0
    let oOsnZ = 0
    let oBez = 0
    let oBezZ = 0

    let neoBr = 0
    let neoBrZ = 0
    let odrBr = 0
    let odrBrZ = 0
    let priBr = 0
    let priBrZ = 0
    
    let punoBr = 0
    let punoBrZ = 0
    let nepunoBr = 0
    let nepunoBrZ = 0
    let skracBr = 0
    let skracBrZ = 0

    filEmpT3456.map(em => {
      let zena = false
      if (em.Gender !== undefined && em.Gender !== null  && em.Gender === 'Z') {
        zena = true
      }
      // izracunaj godine starosti
      if (em.DateOfBirht !== undefined &&  em.DateOfBirht !== null) {
        const date_1 = new Date(em.DateOfBirht)
        const date_2 = new Date()
        const difference = date_2.getTime() - date_1.getTime();
        const TotalDays = Math.ceil(difference / (1000 * 3600 * 24))
        const allYears = parseInt(TotalDays/365)

        if (allYears <= 18) {
          do18 += 1
          if (zena) {
            do18z +=1
          }
        }
        if (allYears >= 19 &&  allYears <= 24) {
          od19 += 1
          if (zena) {
            od19z +=1
          }
        }
        if (allYears >= 25 &&  allYears <= 29) {
          od25 += 1
          if (zena) {
            od25z +=1
          }
        }
        if (allYears >= 30 &&  allYears <= 34) {
          od30 += 1
          if (zena) {
            od30z +=1
          }
        }
        if (allYears >= 35 &&  allYears <= 39) {
          od35 += 1
          if (zena) {
            od35z +=1
          }
        }
        if (allYears >= 40 &&  allYears <= 44) {
          od40 += 1
          if (zena) {
            od40z +=1
          }
        }
        if (allYears >= 45 &&  allYears <= 49) {
          od45 += 1
          if (zena) {
            od45z +=1
          }
        }
        if (allYears >= 50 &&  allYears <= 54) {
          od50 += 1
          if (zena) {
            od50z +=1
          }
        }
        if (allYears >= 55 &&  allYears <= 59) {
          od55 += 1
          if (zena) {
            od55z +=1
          }
        }
        if (allYears >= 60 &&  allYears <= 64) {
          od60 += 1
          if (zena) {
            od60z +=1
          }
        }
        if (allYears >= 65) {
          od65 += 1
          if (zena) {
            od65z +=1
          }
        }

        table3.do18 = do18
        table3.od19 = od19
        table3.od25 = od25
        table3.od30 = od30
        table3.od35 = od35
        table3.od40 = od40
        table3.od45 = od45
        table3.od50 = od50
        table3.od55 = od55
        table3.od60 = od60
        table3.od65 = od65
        table3.do18z = do18z
        table3.od19z = od19z
        table3.od25z = od25z
        table3.od30z = od30z
        table3.od35z = od35z
        table3.od40z = od40z
        table3.od45z = od45z
        table3.od50z = od50z
        table3.od55z = od55z
        table3.od60z = od60z
        table3.od65z = od65z
        table3.ukupno = do18 + od19 + od25 + od30 + od35 + od40 + od45 + od50 + od55 + od60 + od65
        table3.ukupnoZ = do18z + od19z + od25z + od30z + od35z + od40z + od45z + od50z + od55z + od60z + od65z

      }

      if(em.Obrazovanje !== undefined &&  em.Obrazovanje !== null) {
        let obrUv = parseInt(em.Obrazovanje)
        if(obrUv === 1) {
          oDok += 1
          if (zena) {oDokZ += 1}
        }
        if(obrUv === 2) {
          oMag += 1
          if (zena) {oMagZ += 1}
        }
        if(obrUv === 3) {
          oPre += 1
          if (zena) {oPreZ += 1}
        }
        if(obrUv === 4) {
          oStr += 1
          if (zena) {oStrZ += 1}
        }
        if(obrUv === 5) {
          oGim += 1
          if (zena) {oGimZ += 1}
        }
        if(obrUv === 6) {
          oSS4 += 1
          if (zena) {oSS4Z += 1}
        }
        if(obrUv === 7) {
          oSS3 += 1
          if (zena) {oSS3Z += 1}
        }
        if(obrUv === 8) {
          oPri += 1
          if (zena) {oPriZ += 1}
        }
        if(obrUv === 9) {
          oOsn += 1
          if (zena) {oOsnZ += 1}
        }
        if(obrUv === 10) {
          oBez += 1
          if (zena) {oBezZ += 1}
        }

        table4.oDok = oDok
        table4.oDokZ = oDokZ
        table4.oMag = oMag
        table4.oMagZ = oMagZ
        table4.oPre = oPre
        table4.oPreZ = oPreZ
        table4.oStr = oStr
        table4.oStrZ = oStrZ
        table4.oGim = oGim
        table4.oGimZ = oGimZ
        table4.oSS4 = oSS4
        table4.oSS4Z = oSS4Z
        table4.oSS3 = oSS3
        table4.oSS3Z = oSS3Z
        table4.oPri = oPri
        table4.oPriZ = oPriZ
        table4.oOsn = oOsn
        table4.oOsnZ = oOsnZ
        table4.oBez = oBez
        table4.oBezZ = oBezZ

        table4.red2 = oDok + oMag
        table4.red2Z = oDokZ + oMagZ

        table4.red1 = oDok + oMag + oPre + oStr + oGim + oSS4 + oSS3 + oPri + oOsn + oBez
        table4.red1Z = oDokZ + oMagZ + oPreZ + oStrZ + oGimZ + oSS4Z + oSS3Z + oPriZ + oOsnZ + oBezZ
      }

      if(em.VrstaRadnogOdnosa !== undefined &&  em.VrstaRadnogOdnosa !== null) {
        let vrstaRO = parseInt(em.VrstaRadnogOdnosa)
        if(vrstaRO === 1) {
          neoBr += 1
          if (zena) {neoBrZ += 1}
        }
        if(vrstaRO === 2) {
          odrBr += 1
          if (zena) {odrBrZ += 1}
        }
        if(vrstaRO === 3) {
          priBr += 1
          if (zena) {priBrZ += 1}
        }

        table5.neoBr = neoBr
        table5.neoBrZ = neoBrZ
        table5.odrBr = odrBr
        table5.odrBrZ = odrBrZ
        table5.priBr = priBr
        table5.priBrZ = priBrZ
        table5.red1 = neoBr + odrBr + priBr
        table5.red1Z = neoBrZ + odrBrZ + priBrZ

      }

      if(em.VrstaRadnogVremena !== undefined &&  em.VrstaRadnogVremena !== null) {
        let vrstaRV = parseInt(em.VrstaRadnogVremena)
        if(vrstaRV === 1) {
          punoBr += 1
          if (zena) {punoBrZ += 1}
        }
        if(vrstaRV === 2) {
          nepunoBr += 1
          if (zena) {nepunoBrZ += 1}
        }
        if(vrstaRV === 3) {
          skracBr += 1
          if (zena) {skracBrZ += 1}
        }

        table6.punoBr = punoBr
        table6.punoBrZ = punoBrZ
        table6.nepunoBr = nepunoBr
        table6.nepunoBrZ = nepunoBrZ
        table6.skracBr = skracBr
        table6.skracBrZ = skracBrZ
        table6.red1 = punoBr + nepunoBr + skracBr
        table6.red1Z = punoBrZ + nepunoBrZ + skracBrZ
      }

    })

    retObject.table3 = {...table3}

    retObject.table4 = {...table4}

    retObject.table5 = {...table5}

    retObject.table6 = {...table6}
  }


  // do filter on emp. ==>> only 
  // newObj.fromDate = '2024-01-01' 
  // newObj.toDate = '2024-12-31'
  // newObj.typeId = 1
  newObj.CompanyId = companyid
  newObj.fromDate = fromDate
  newObj.toDate = toDate
  newObj.typeId = typeId
  newObj.userId = userId && userId.id ? userId.id : null

  await SalarieService.choosedetail(newObj)
    .then(res => {
      const salH = res && res.data && res.data.salaries ? res.data.salaries : null

      // napravi tabelu 3, 4, 5 i 6
      doTable3456()

      // zaposleni za tabelu 7 (svi zaposleni - ne govori se o zaposlenima za cijelu godinu)
      if (filEmpT7 && filEmpT7.length > 0) {
        filEmpT7.map(e7 => {
          if (salH && salH.length > 0) {
            salH.map(head => { 
              if (head.SalarieDetails && head.SalarieDetails.length > 0) {
                head.SalarieDetails.map(det => {
                  if (det.empID === e7.id) {
                    if(e7.VrstaRadnogVremena !== undefined &&  e7.VrstaRadnogVremena !== null) {
                      let vrstaRV = parseInt(e7.VrstaRadnogVremena)
                      doTable7(det, vrstaRV)
                    }
                  }
                })
              }
            })
          }
        })
        retObject.table7 = {...table7}
      }

      for (let i = 1; i < 3; i++) {

        filEmp.length = 0;
        if (i === 1) {
          filEmp = JSON.parse(JSON.stringify(filEmpT1));
        }
        if (i === 2) {
          filEmp = JSON.parse(JSON.stringify(filEmpT2));
        }
        
        let godBrutoM = 0
        let godBrutoZ = 0
        let godNetoM = 0
        let godNetoZ = 0
        let brM = 0
        let brZ = 0 

        let diplomskiBruto = 0
        let diplomskiNeto = 0
        let diplomskiBroj = 0

        let prijedipBruto = 0
        let prijedipNeto = 0
        let prijedipBroj = 0

        let sssBruto = 0
        let sssNeto = 0
        let sssBroj = 0

        let nizestrucnoBruto = 0
        let nizestrucnoNeto = 0
        let nizestrucnoBroj = 0

        let visokokvBruto = 0
        let visokokvNeto = 0
        let visokokvBroj = 0

        let srednjastrukBruto = 0
        let srednjastrukNeto = 0
        let srednjastrukBroj = 0

        let priuceniBruto = 0
        let priuceniNeto = 0
        let priuceniBroj = 0

        let nekvalBruto = 0
        let nekvalNeto = 0
        let nekvalBroj = 0
        console.log('-->>> da vidim koliko ih ima filEmpT1', filEmpT1)
        console.log('-->>> da vidim koliko ih ima filEmpT2', filEmpT2)
        console.log('-->>> da vidim koliko ih ima filEmp', filEmp)
        // zaposleni za tabelu 1 i tabelu 2
        filEmp.map(em => {
          if (em.Gender !== undefined && em.Gender !== null && em.Gender === 'M') {
            brM +=1
          }
          if (em.Gender !== undefined && em.Gender !== null  && em.Gender === 'Z') {
            brZ +=1
          }
          const obraz = em.Obrazovanje ? parseInt(em.Obrazovanje) : 0
          if(obraz === 1 || obraz === 2) {
            diplomskiBroj +=1
          }
          if(obraz === 3 || obraz === 4) {
            prijedipBroj +=1
          }
          if(obraz === 5 || obraz === 6) {
            sssBroj +=1
          }
          if(obraz === 7) {
            srednjastrukBroj +=1
          }
          if(obraz === 8) {
            priuceniBroj +=1
          }
          if(obraz === 9 || obraz === 10) {
            nekvalBroj +=1
          }
          if (salH) {
            salH.map(head => { 
              if (head.SalarieDetails && head.SalarieDetails.length > 0) {

                head.SalarieDetails.map(det => {
                  
                  if (det.empID === em.id) {

                    if (em.Gender !== undefined && em.Gender !== null && em.Gender === 'M') {
                      godBrutoM += round(det.FinBruto2 ,2)
                      godNetoM += round(det.FinNeto2 ,2)
                    }
                    if (em.Gender !== undefined && em.Gender !== null  && em.Gender === 'Z') {
                      godBrutoZ += round(det.FinBruto2 ,2)
                      godNetoZ += round(det.FinNeto2 ,2)
                    }
                    const obraz = em.Obrazovanje ? parseInt(em.Obrazovanje) : 0
                    if(obraz === 1 || obraz === 2) {
                      diplomskiBruto += round(det.FinBruto2 ,2)
                      diplomskiNeto += round(det.FinNeto2 ,2)
                    }
                    if(obraz === 3 || obraz === 4) {
                      prijedipBruto += round(det.FinBruto2 ,2)
                      prijedipNeto += round(det.FinNeto2 ,2)
                    }
                    if(obraz === 5 || obraz === 6) {
                      sssBruto += round(det.FinBruto2 ,2)
                      sssNeto += round(det.FinNeto2 ,2)
                    }
                    if(obraz === 7) {
                      srednjastrukBruto += round(det.FinBruto2 ,2)
                      srednjastrukNeto += round(det.FinNeto2 ,2)
                    }
                    if(obraz === 8) {
                      priuceniBruto += round(det.FinBruto2 ,2)
                      priuceniNeto += round(det.FinNeto2 ,2)
                    }
                    if(obraz === 9 || obraz === 10) {
                      nekvalBruto += round(det.FinBruto2 ,2)
                      nekvalNeto += round(det.FinNeto2 ,2)
                    }
                  }
                })
              }
            })
          }

        })

        table1.total1 = {}
        table1.total1.bruto = Math.round(godBrutoM) + Math.round(godBrutoZ)
        table1.total1.neto = Math.round(godNetoM) + Math.round(godNetoZ)
        table1.total1.nr = Math.round(brM) + Math.round(brZ)

        table1.men = {}
        table1.men.bruto = Math.round(godBrutoM)
        table1.men.neto = Math.round(godNetoM)
        table1.men.nr = Math.round(brM)

        table1.women = {}
        table1.women.bruto = Math.round(godBrutoZ)
        table1.women.neto = Math.round(godNetoZ)
        table1.women.nr = Math.round(brZ)
        
        table1.total2 = {}
        table1.total2.bruto = Math.round(diplomskiBruto) + Math.round(prijedipBruto) + Math.round(sssBruto) + Math.round(nizestrucnoBruto) + Math.round(visokokvBruto) + Math.round(srednjastrukBruto) + Math.round(priuceniBruto) + Math.round(nekvalBruto)
        table1.total2.neto = Math.round(diplomskiNeto) + Math.round(prijedipNeto) + Math.round(sssNeto) + Math.round(nizestrucnoNeto) + Math.round(visokokvNeto) + Math.round(srednjastrukNeto) + Math.round(priuceniNeto) + Math.round(nekvalNeto)
        table1.total2.nr = Math.round(diplomskiBroj) + Math.round(prijedipBroj) + Math.round(sssBroj) + Math.round(nizestrucnoBroj) + Math.round(visokokvBroj) + Math.round(srednjastrukBroj) + Math.round(priuceniBroj) + Math.round(nekvalBroj)

        table1.diplomskiBruto = Math.round(diplomskiBruto)
        table1.diplomskiNeto = Math.round(diplomskiNeto)
        table1.diplomskiBroj = Math.round(diplomskiBroj)

        table1.prijedipBruto = Math.round(prijedipBruto)
        table1.prijedipNeto = Math.round(prijedipNeto)
        table1.prijedipBroj = Math.round(prijedipBroj)

        table1.sssBruto = Math.round(sssBruto)
        table1.sssNeto = Math.round(sssNeto)
        table1.sssBroj = Math.round(sssBroj)

        table1.nizestrucnoBruto = Math.round(nizestrucnoBruto)
        table1.nizestrucnoNeto = Math.round(nizestrucnoNeto)
        table1.nizestrucnoBroj = Math.round(nizestrucnoBroj)

        table1.visokokvBruto = Math.round(visokokvBruto)
        table1.visokokvNeto = Math.round(visokokvNeto)
        table1.visokokvBroj = Math.round(visokokvBroj)

        table1.srednjastrukBruto = Math.round(srednjastrukBruto)
        table1.srednjastrukNeto = Math.round(srednjastrukNeto)
        table1.srednjastrukBroj = Math.round(srednjastrukBroj)

        table1.priuceniBruto = Math.round(priuceniBruto)
        table1.priuceniNeto = Math.round(priuceniNeto)
        table1.priuceniBroj = Math.round(priuceniBroj)

        table1.nekvalBruto = Math.round(nekvalBruto)
        table1.nekvalNeto = Math.round(nekvalNeto)
        table1.nekvalBroj = Math.round(nekvalBroj)

        if (i === 1) {
          retObject.table1 = {...table1}
        }
        if (i === 2) {
          retObject.table2 = {...table1}
        }
        
      }
      console.log('retObject ', retObject)
    })
    .catch(err => {
      console.log('no salaries ', err)
      return err
    })
    return retObject
}

export {freeRAD1}

