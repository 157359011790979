import Api from '@/services/Api'
// import { log } from 'util';

export default {
  index(detailQuery) {
    return Api().get('documentdetail', {
      params: detailQuery
    })
  },
  salesana(query) {
    return Api().get('documentdetail/salesana', {
      params: query
    })
  },
  updateitems(query) {
    return Api().get('documentdetail/updateitems', {
      params: query
    })
  },
  show(documentdetailid) {
    return Api().get(`documentdetail/${documentdetailid}`)
  },
  post(newdocumentdetail) {
    return Api().post('documentdetail', newdocumentdetail)
  },
  put(documentdetail) {
    return Api().put(`documentdetail/${documentdetail.id}`, documentdetail)
  },
  delete(documentdetailid) {
    return Api().delete(`documentdetail/${documentdetailid}`)
  },
  choose(query) {
    return Api().get('documentdetail/choose', {
      params: query
    })
  },
  saveitems(items) {
    if (items) {
      return Api().post('documentdetail/saveitems', items)
    }
    return
  },
  saveallitems(items) {
    if (items) {
      return Api().post('documentdetail/saveallitems', items)
    }
    return
  },
  olddocs(query) {
    return Api().get('documentdetail/olddocs', {
      params: query
    })
  },
  alldocdetails(query) {
    return Api().get('documentdetail/alldocdetails', {
      params: query
    })
  },
}