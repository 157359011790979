const docHR = {
  id:'Rbr',
  MainBook: 'Knjiga primitaka - izdataka',
  MainBook2: 'Knjiga uplata-isplata',
  partnerId : '',
  partnerNameShort: '',
  BookRecExp: 'Rec / Exp', // 1 Rec, 2 Exp
  BookTypeId : 'Šifra vrste',
  BookTypeName: 'Vrsta',
  BookId : 'Id',
  BookDate: 'Datum',
  InvoiceDate: 'Datum DVO',
  InvoicePaymentDeadlineDate: 'Rok plaćanja',
  BookDesc1: 'Opis 1',
  BookDesc2: 'Opis 2',
  Rec: 'Primitak',
  Rec2: 'Uplata',
  RecCash: 'Gotovina',
  RecBank: 'Žiro račun',
  RecFree: 'U naravi',
  RecVAT: 'PDV',
  RecSpecial: 'Nepriznato',
  RecTotal: 'Ukupno primitak',
  RecTotal2: 'Ukupno uplata',
  Exp:'Izdatak',
  Exp2:'Isplata',
  ExpCash: 'Gotovina',
  ExpBank: 'Žiro račun',
  ExpFree: 'U naravi',
  ExpVAT: 'PDV',
  ExpSpecial: 'Čl.33.ST.1.T.1.I 5.',
  ExpTotal: 'Ukupno izdatak',
  ExpTotal2: 'Ukupno isplata',
  documentName: 'Document Name',
  documentIdGeneral: '',
  BookID: '',
  BookTypeID: '',
  BookName: '',
  BookIdGeneral: '',
  HomeCurrency: 'Valuta',
  HomeCurrencyPrimary: 'Prim.Curr',
  ForeignCurrency: 'For.Curr',
  ForeignCurrencyPrimary: '',
  ExRate: 'Tečaj',
  partnerName: 'Partner naziv',
  VAT3Base: '5% osnovica',
  VAT2Base: '13% osnovica',
  VAT1Base: '25% osnovica',
  VAT3Amount: '5% porez',
  VAT2Amount: '13% porez',
  VAT1Amount: '25% porez',
  VAT3CanUse: '5% može odbiti',
  VAT3CanTUse: '5% ne može odbiti',
  VAT2CanUse: '13% može odbiti',
  VAT2CanTUse: '13% ne može odbiti',
  VAT1CanUse: '25% može odbiti',
  VAT1CanTUse: '25% ne može odbiti',
  VAT4Per: 'Tuzemni prijenos porezne obveze',
  VAT4Base: 'Isporuke dobara u drugim državama članicama',
  VAT4Amount: 'Isporuke dobara unutar EU',
  VAT4PerCanUse: 'Obavljene usluge unutar EU',
  VAT4CanUse: 'Obavljene isporuke osobama bez sjedišta u RH',
  VAT4CanTUse: 'Sastavljanje i postavljanje dobara u drugoj državi članici',
  VAT5Per: 'Isporuke NPS u EU',
  VAT5Base: 'U tuzemstvu',
  VAT5Amount: 'Izvozne isporuke',
  VAT5PerCanUse: 'Ostala oslobođenja',
  VATID: 'OIB: ',
  AmountNetto: 'Neto iznos',
  AmountVAT: 'PDV',
  AmountBrutto: 'Bruto iznos',
  AddReceive: 'Dodaj primitak',
  AddExpense: 'Dodaj izdatak',
  AddReceive2: 'Dodaj uplatu',
  AddExpense2: 'Dodaj isplatu',
  DoCount: 'Ukupno',
  AddInvoice: 'Ira',
  AddRecivable: 'Ura',
  CardInvoice: 'Kart.Ira',
  CardOpenInvoice: 'Otv.Ire',
  CardRecivable: 'Kart.Ura',
  CardOpenRecivable: 'Otv.Ure',
  DeleteReceive: 'Obriši izdatak',
  DeleteReceive2: 'Obriši isplatu',
  DeleteExpense: 'Obriši primitak',
  DeleteExpense2: 'Obriši uplatu',
  PartnerSave: 'Snimi Partnera',
  BookRec: 'PDV - Ire',
  BookExp: 'PDV - Ure',
  BookRec2: 'Lista Ire',
  BookExp2: 'Lista Ure',
  Book: 'KPI',
  Book2: 'Knjiga',
  Book3: 'KPI DEPOZITI',
  Book4: 'KPI BEZ DEPOZITA',
  BookInInvoices: 'Knjiga URA',
  BookOutInvoices: 'Knjiga IRA',
  MainBookTypes1: 'Vrste',
  MainBookTypes: 'Vrste primtaka/izdataka',
  MainBookType: 'Vrsta',
  MainBookTypeName: 'Naziv vrste',
  MainBookTypeRec: 'Vrsta primitka',
  MainBookTypeExp: 'Vrsta izdatka',
  NewMainBookType: 'Nova vrsta prim/izdatka',
  EditMainBookType: 'Uredi vrstu',
  Partneremail: 'email',
  PartnerName: 'Partner',
  PartnerVATID: 'Partner OIB',
  showView: 'Pregledi',
  showBank: 'Izvodi',
  showBook: 'Knjiga',
  showStartSupp: 'PS.Dob.',
  showStartBuyers: 'PS.Kupci',
  MainBookDocs1: 'Baza spisa',
  MainBookDocs: 'Baza spisa',
  MainBookDoc: 'Spis',
  NewMainBookDoc: 'Novi spis',
  MainBookDocID: "Šifra spisa",
  MainBookDocName: "Naziv spisa",
  MainBookDocVATID: "OIB",
  MainBookDocDesc: "Opis spisa",
  MainBookDocOpenDate: "Datum otvaranja",
  MainBookDocClosedDate: "Datum zaključenja",
  EditMainBookDoc: 'Uredi spis',
  MainBookDocsEmpty:'Prazna lista',
  MainBookDocIDError:'Greška!! Nije upisan broj spisa.',
  MainBookDocOpenDateErr: "Greška! Datum Otvaranja je van granica!",
  MainBookDocClosedDateErr: "Greška! Datum Zatvranja je van granica!",
  MainBookDocIDErrorExist:'Greška!! Broj spisa vec postoji.',
  CloseRec:'Zatvori IRU br',
  CloseExp:'Zatvori URU br',
  BookEmpty:'Nema podataka',
  checkPartnerByIban: 'Provjeri IBAN',
  partnerExist: 'Partner sa tim IBANom postoji.',
  partnerNOTexist:'IBAN nije pridružen niti jednom partneru!.',
  partnerCardSupp: 'Kar.DOB.',
  partnerCardBuy: 'Kar.KUP.',
  partnerCardOpenSupp: 'Kar.Otv.DOB.',
  partnerCardOpenBuy: 'Kar.Otv.KUP.',
  useBuySupp: 'Salda Konti',
  hideZeros:'Bez Nula'
}

export default docHR