import Api from '@/services/Api'

export default {
  index(query) {
    return Api().get('serials', {
      params: query    
    })
  },
  index2(query) {
    return Api().get('serials/showall', {
      params: query    
    })
  },
  show(id) {
    return Api().get(`serials/${id}`)
  },
  post(newserial) {
    return Api().post('serials', newserial)
  },
  put(serials) {
    return Api().put(`serials/${serials.id}`, serials)
  },
  delete(query) {
    return Api().delete('serials/delete', {params: query})
  },
  choose(query) {
    return Api().get('serials/choose', {
      params: query
    })
  },
  saveitems(items) {
    if (items) {
      return Api().post('serials/saveitems', items)
    }
    return
  },
}