<template>
  <div class="documentprint">
        <v-btn color="green" @click="documentlist">
            <span v-if="this.$store.state.language == 'EN'">
            Back to List
            </span>
            <span v-if="this.$store.state.language == 'HR'">
            Natrag na listu
            </span>
      </v-btn>
      <v-btn color="green" @click="print2">
            <span v-if="this.$store.state.language == 'EN'">
            Print document 2
            </span>
            <span v-if="this.$store.state.language == 'HR'">
            Printaj 2
            </span>
      </v-btn>

      <div id="printArea">


          <v-container >


          </v-container>

       </div>

  </div>
  
</template>

<script>
import DocumentService from '@/services/DocumentService'
import DocumentDetailService from '@/services/DocumentDetailService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import dayjs from 'dayjs'

export default {
  name: 'documentprint2',
  data () {
    return {
      msg: '',
      documentName: '',
      documentName2: '',
      document: {},
      locCreatedAt: '',
      locUpdatedAt: '',
      locDocument: {},
      fields1: [ 'itemName', 'ItemCode', 'itemUnit', 'itemService', 'itemPrice', 'itemPcsNeutral', 'itemBaseAmount', 'itemDiscountPercentage1', 'itemBaseAmountNoVAT', 'itemAmountWithVAT' ],
      items1: [
      ],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      fields2: [ 'vat', 'base', 'amount' ],
      items2: [
        { vat: '5%', base: 100.00, amount: 5.00 },
        { vat: '13%', base: 200.00, amount: 26.00 },
        { vat: '25%', base: 400.00, amount: 100.00 }
      ],
      comp: {},
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        databaseDocID: 0,
        FreeF1: 1
      },
      order: 0,
      loCdocumentDate: '',
      loCorderDate: '',
      loCdocumentInvoiceStartDate: '',
      loCdocumentInvoiceTime: '',
      loCdocumentInvoiceEndDate: '',
      loCdocumentConnDate: '',
      loCdeliveryDate: '',
      loCorderTime: '',
      loCdocumentConnTime: '',
      loCdeliveryTime: '',
      locDocumentId: '',
      output: null,
      documentNameLoc: '',
      docDefinition: null,
      profitLoss: 0,
      profitLossP: '',
      pP1: [],
      pP2: [],
      pP3: [],
      pP4: [],
      pP5: [],
      pP6: [],
      pP7: [],
      pP8: [],
      pP9: [],
      pP10: [],
      pD1: [],
      pD2: [],
      pD3: [],
      pD4: [],
      pD5: [],
      pD6: [],
      pD7: [],
      pD8: [],
      pD9: [],
      pD10: [],
      pT1: [],
      pT2: [],
      pT3: [],
      pT4: [],
      pT5: [],
      pT6: [],
      pT7: [],
      pT8: [],
      pT9: [],
      pT10: [],
      pC1: [],
      pC2: [],
      pC3: [],
      pC4: [],
      pC5: [],
      pC6: [],
      pC7: [],
      pC8: [],
      pC9: [],
      pC10: [],
      pC1tot: 0,
      pC2tot: 0,
      pC3tot: 0,
      pC4tot: 0,
      pC5tot: 0,
      pC6tot: 0,
      pC7tot: 0,
      pC8tot: 0,
      pC9tot: 0,
      pC10tot: 0,
      pD1tot: 0,
      pD2tot: 0,
      pD3tot: 0,
      pD4tot: 0,
      pD5tot: 0,
      pD6tot: 0,
      pD7tot: 0,
      pD8tot: 0,
      pD9tot: 0,
      pD10tot: 0
    }
  },
  async mounted () {
    try {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentid) {
        this.mainQuery.databaseDocID = this.$store.state.documentid
      }
      this.mainQuery.FreeF1 = 1
      this.documentName2 = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      this.comp = this.$store.state.companyActiv
      this.locDocument = await DocumentService.show(this.$store.state.documentid)
      this.items = (await DocumentDetailService.index(this.mainQuery)).data.documentDetails
      if (this.items) {
        this.items.map(item => {
          this.order += 1
          this.pP1.push({ text: this.order + '\n', fontSize: 8 })
          this.pP2.push({ text: item.itemName + '\n', fontSize: 8, alignment: 'left' })
          this.pP3.push({ text: item.itemUnit + '\n', fontSize: 8, alignment: 'center' })
          this.pP4.push({ text: item.itemSuppPrice + '\n', fontSize: 8, alignment: 'right' })
          this.pP5.push({ text: item.itemPcsMinus + '\n', fontSize: 8, alignment: 'right' })
          this.pP6.push({ text: item.itemBaseAmount + '\n', fontSize: 8, alignment: 'right' })
          // console.log(' ovo imam za stavku ', item)
        })
      }
      this.mainQuery.FreeF1 = 2
      this.order = 0
      this.items = (await DocumentDetailService.index(this.mainQuery)).data.documentDetails
      if (this.items) {
        this.items.map(item => {
          this.order += 1
          this.pD1.push({ text: this.order + '\n', fontSize: 8 })
          this.pD2.push({ text: item.hashtag + '\n', fontSize: 8 })
          this.pD3.push({ text: item.empWorkDay + '\n', fontSize: 8 })
          this.pD4.push({ text: item.empWrkHrs + '\n', fontSize: 8, alignment: 'right' })
          this.pD5.push({ text: item.empWrkExpHrs + '\n', fontSize: 8, alignment: 'right' })
          this.pD6.push({ text: item.empExp1 + '\n', fontSize: 8, alignment: 'right' })
          this.pD7.push({ text: item.empExp2 + '\n', fontSize: 8, alignment: 'right' })
          this.pD8.push({ text: item.itemBaseAmount + '\n', fontSize: 8, alignment: 'right' })
          // console.log(' ovo imam za stavku ', item)
        })
      }
      this.mainQuery.FreeF1 = 3
      this.order = 0
      this.items = (await DocumentDetailService.index(this.mainQuery)).data.documentDetails
      if (this.items) {
        this.items.map(item => {
          this.order += 1
          this.pT1.push({ text: this.order + '\n', fontSize: 8 })
          this.pT2.push({ text: item.itemName + '\n', fontSize: 8 })
          this.pT3.push({ text: item.itemUnit + '\n', fontSize: 8, alignment: 'center' })
          this.pT4.push({ text: item.itemSuppPrice + '\n', fontSize: 8, alignment: 'right' })
          this.pT5.push({ text: item.itemPcsMinus + '\n', fontSize: 8, alignment: 'right' })
          this.pT6.push({ text: item.itemBaseAmount + '\n', fontSize: 8, alignment: 'right' })
          // console.log(' ovo imam za stavku ', item)
        })
      }
      this.mainQuery.FreeF1 = 4
      this.order = 0
      this.items = (await DocumentDetailService.index(this.mainQuery)).data.documentDetails
      if (this.items) {
        this.items.map(item => {
          this.order += 1
          this.pC1.push({ text: this.order + '\n', fontSize: 8 })
          this.pC2.push({ text: item.itemName + '\n', fontSize: 8 })
          this.pC3.push({ text: item.itemPrice + '\n', fontSize: 8, alignment: 'right' })
          this.pC4.push({ text: item.itemPcsNeutral + '\n', fontSize: 8, alignment: 'right' })
          this.pC5.push({ text: item.itemBaseAmount + '\n', fontSize: 8, alignment: 'right' })
          this.pC6.push({ text: item.itemDiscountPercentage1 + '\n', fontSize: 8, alignment: 'right' })
          this.pC7.push({ text: item.itemBaseAmountNoVAT + '\n', fontSize: 8, alignment: 'right' })
          this.pC8.push({ text: item.itemVAT1Percentage + '\n', fontSize: 8, alignment: 'right' })
          this.pC9.push({ text: item.itemAmountWithVAT + '\n', fontSize: 8, alignment: 'right' })
          // console.log(' ovo imam za stavku ', item)
        })
      }
      // console.log(' client loc document ', this.locDocument)
      // console.log(' client loc items ', this.items)
      this.document = this.locDocument.data.document
      this.locCreatedAt = dayjs(this.document.CreatedAt).format('DD.MM.YYYY HH:MM')
      this.locUpdatedAt = dayjs(this.document.UpdatedAt).format('DD.MM.YYYY HH:MM')
      this.loCdocumentInvoiceTime = dayjs(this.document.UpdatedAt).format('HH:MM')
      if (this.document.documentDate) {
        this.loCdocumentDate = dayjs(this.document.documentDate).format('DD.MM.YYYY')
      }
      if (this.document.orderDate) {
        this.loCorderDate = dayjs(this.document.orderDate).format('DD.MM.YYYY')
      }
      if (this.document.documentInvoiceStartDate) {
        this.loCdocumentInvoiceStartDate = dayjs(this.document.documentInvoiceStartDate).format('DD.MM.YYYY')
      }
      if (this.document.documentInvoiceEndDate) {
        this.loCdocumentInvoiceEndDate = dayjs(this.document.documentInvoiceEndDate).format('DD.MM.YYYY')
      }
      if (this.document.documentConnDate) {
        this.loCdocumentConnDate = dayjs(this.document.documentConnDate).format('DD.MM.YYYY')
      }
      if (this.document.deliveryDate) {
        this.loCdeliveryDate = dayjs(this.document.deliveryDate).format('DD.MM.YYYY')
      }
      if (this.document.orderTime) {
        this.loCorderTime = dayjs(this.document.orderTime).format('HH:MM')
      }
      if (this.document.documentConnTime) {
        this.loCdocumentConnTime = dayjs(this.document.documentConnTime).format('HH:MM')
      }
      if (this.document.deliveryTime) {
        this.loCdeliveryTime = dayjs(this.document.deliveryTime).format('HH:MM')
      }
      if (this.$store.state.documentActiv === 'InvoiceSales') {
        this.locDocumentId = this.document.documentId + '/' + this.$store.state.companyActiv.CompanyBusinessSpace + '/' + this.$store.state.companyActiv.CompanyBusinessDevice
      } else {
        this.locDocumentId = this.document.documentId
      }
      this.profitLoss = this.document.FreeF4 - this.document.FreeF1 - this.document.FreeF2 - this.document.FreeF3
      this.profitLossP = this.profitLoss.toFixed(2)
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  },
  methods: {
    print () {
      // opens the "print dialog" of your browser to print the element
      // var bdhtml = window.document.body.innerHTML
      // window.document.body.innerHTML = document.getElementById('printArea').innerHTML
      // window.print()
      // window.document.body.innerHTML = bdhtml
      this.$htmlToPaper('printArea')
    },
    async documentlist () {
      try {
        await this.$router.push({
          name: 'document'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    print2 () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {
        // a string or { width: number, height: number }
        pageSize: 'A4',

        // by default we use portrait, you can change it to landscape if you wish
        pageOrientation: 'portrait',

        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 20, 20, 20, 20 ],
        footer: {
          columns: [ {
            text: [
              { text: '   ' + this.comp.CompanyName, fontSize: 8, bold: true, alignment: 'center' },
              { text: ',' + this.comp.CompanyStreet + ' ' + this.comp.CompanyBuildingNameNumber + ', ' + this.comp.CompanyPostcode + ' ' + this.comp.CompanyCity, fontSize: 7 },
              { text: ', Tel:' + this.comp.CompanyPhones + ', email:' + this.comp.Companyemail + ', OIB:' + this.comp.CompanyVATID, fontSize: 7 }
            ]
          }
          ]
        },
        content: [
          // if you don't need styles, you can use a simple string to define a paragraph
          // using a { text: '...' } object lets you set styling properties
          {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '70%',
                text: [
                  { text: this.comp.CompanyName, fontSize: 25 }
                ]
              },
              {
                // % width
                width: '20%',
                text: [
                  { text: this.comp.CompanyStreet + ' ' + this.comp.CompanyBuildingNameNumber + '\n', fontSize: 8 },
                  { text: this.comp.CompanyPostcode + ' ' + this.comp.CompanyCity + '\n', fontSize: 8 },
                  { text: 'Telefon:' + this.comp.CompanyPhones + '\n', fontSize: 8 },
                  { text: 'email:' + this.comp.Companyemail + '\n', fontSize: 8 },
                  { text: 'OIB:' + this.comp.CompanyVATID, fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          },

          // if you set the value of text to an array instead of a string, you'll be able
          // to style any part individually
          // using a { text: '...' } object lets you set styling properties
          { text: this.documentNameLoc + ': ' + this.locDocumentId, fontSize: 15 },
          {
            columns: [
              {
                width: '10%',
                text: [
                  { text: 'Naručitelj:', fontSize: 8 }
                ]
              },
              {
                width: '50%',
                text: [
                  { text: this.document.partnerName + '\n', fontSize: 10, bold: true },
                  { text: this.document.partnerStreet + ' ' + this.document.partnerBuildingNameNumber + ',' + this.document.partnerPostcode + ' ' + this.document.partnerCity + '\n', fontSize: 8 },
                  { text: 'OIB: ' + document.partnerVATID + ', email: ' + this.document.partneremail, fontSize: 8 }
                ]
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '20%',
                text: [
                  { text: 'Datum dokumenta: ' + this.loCdocumentDate + '\n', fontSize: 8, bold: true },
                  { text: 'Datum početka: ' + this.loCdocumentInvoiceStartDate + '\n', fontSize: 8 },
                  { text: 'Datum završetka: ' + this.loCdocumentInvoiceEndDate + '\n', fontSize: 8 }
                ]
              }

            ]
          },
          {
            columns: [
              {
                width: '10%',
                text: [
                  { text: 'Kontakt:', fontSize: 8 }
                ]
              },
              {
                width: '50%',
                text: [
                  { text: this.document.partnerSecretaryTitle + ' ' + this.document.partnerSecretaryFirst + ' ' + this.document.partnerSecretaryLast + '\n', fontSize: 10, bold: true },
                  { text: 'email: ' + this.document.partnerSecretaryEmail + ' telefon:' + this.document.partnerSecretaryPhone + '\n', fontSize: 8 }
                ]
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '20%',
                text: [
                  { text: 'Primarna valuta: ' + this.document.documentHomeCurrency + '\n', fontSize: 8, bold: true },
                  { text: 'Tečaj: ' + this.document.documentExRate + '\n', fontSize: 8 },
                  { text: 'Sekundarna valuta: ' + this.document.documentForeignCurrency + '\n', fontSize: 8 }
                ]
              }

            ]
          },
          {
            columns: [
              {
                width: '10%',
                text: [
                  { text: 'Lokacija:' + '\n', fontSize: 8 },
                  { text: 'Opis:' + '\n', fontSize: 8 },
                  { text: 'Napomena:' + '\n', fontSize: 8 },
                  { text: 'Na temelju:' + '\n', fontSize: 8 },
                  { text: 'Pred.resursi:' + '\n', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: this.document.documentDescription4 + '\n', fontSize: 8, bold: true },
                  { text: this.document.documentDescription1 + '\n', fontSize: 8 },
                  { text: this.document.documentDescription5 + '\n', fontSize: 8 },
                  { text: this.document.orderDocument + '\n', fontSize: 8 },
                  { text: this.document.documentDescription3 + '\n', fontSize: 8 }
                ]
              },
              {
                width: '20%',
                text: [
                  { text: '+ Za fakturu:' + '\n', fontSize: 8 },
                  { text: '- Trošak materijala:' + '\n', fontSize: 8 },
                  { text: '- Trošak radnika (sati:' + this.document.FreeF5 + '): ' + '\n', fontSize: 8 },
                  { text: '- Ostali troškovi:' + '\n', fontSize: 8 },
                  { text: '= Dobit/Gubitak' + '\n', fontSize: 10, bold: true }
                ]
              },
              {
                width: '10%',
                text: [
                  { text: this.document.FreeF4 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.document.FreeF1 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.document.FreeF2 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.document.FreeF3 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.profitLossP + '\n', fontSize: 10, bold: true, alignment: 'right' }
                ]
              }
            ]
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          },

          // ---------------------------------------------------------------------
          // Za fakturu
          // ---------------------------------------------------------------------
          {
            text: [
              { text: 'Za fakturu: ', fontSize: 15 }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: { tex: 'Rb. \n', fontSize: 8 }
              },
              {
                width: '40%',
                text: { text: 'Naziv \n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '8%',
                text: { text: 'Cijena \n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: 'Količina \n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: 'Iznos \n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '5%',
                text: { text: 'Popust %\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: 'Iznos bez PDVa\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '5%',
                text: { text: 'PDV % \n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: 'Iznos sa PDVom\n', fontSize: 8, alignment: 'right' }
              }
            ]
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: this.pC1
              },
              {
                width: '40%',
                text: this.pC2
              },
              {
                width: '8%',
                text: this.pC3
              },
              {
                width: '8%',
                text: this.pC4
              },
              {
                width: '8%',
                text: this.pC5
              },
              {
                width: '5%',
                text: this.pC6
              },
              {
                width: '8%',
                text: this.pC7
              },
              {
                width: '5%',
                text: this.pC8
              },
              {
                width: '8%',
                text: this.pC9
              }
            ]
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '40%',
                text: ' '
              },
              {
                width: '8%',
                text: ' '
              },
              {
                width: '8%',
                text: ' '
              },
              {
                width: '8%',
                text: ' '
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '8%',
                text: [
                  { text: this.document.FreeF4, alignment: 'right' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '8%',
                text: ' '
              }
            ]
          },

          // ---------------------------------------------------------------------
          // Trošak materijala
          // ---------------------------------------------------------------------
          {
            text: [
              { text: 'Trošak materijala ', fontSize: 15 }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: { tex: 'Rb. \n', fontSize: 8 }
              },
              {
                width: '50%',
                text: { text: 'Naziv \n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '10%',
                text: { text: 'Jmj\n', fontSize: 8, alignment: 'center' }
              },
              {
                width: '10%',
                text: { text: 'Količina\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '10%',
                text: { text: 'Dob.cijena\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '10%',
                text: { text: 'Dob.iznos\n', fontSize: 8, alignment: 'right' }
              }
            ]
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: this.pP1
              },
              {
                width: '50%',
                text: this.pP2
              },
              {
                width: '10%',
                text: this.pP3
              },
              {
                width: '10%',
                text: this.pP4
              },
              {
                width: '10%',
                text: this.pP5
              },
              {
                width: '10%',
                text: this.pP6
              }
            ]
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '50%',
                text: ' '
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '10%',
                text: [
                  { text: this.document.FreeF1, alignment: 'right' }
                ]
              }
            ]
          },

          // ---------------------------------------------------------------------
          // Trošak radnika
          // ---------------------------------------------------------------------
          {
            text: [
              { text: 'Trošak radnika: ', fontSize: 15 }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: { tex: 'Rb. \n', fontSize: 8 }
              },
              {
                width: '30%',
                text: { text: 'Radnik \n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '10%',
                text: { text: 'Datum \n', fontSize: 8, alignment: 'center' }
              },
              {
                width: '10%',
                text: { text: 'Radni sati\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '10%',
                text: { text: 'Bruto satnica\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '10%',
                text: { text: 'Dnevnice \n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '10%',
                text: { text: 'Kilometri \n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '10%',
                text: { text: 'Trošak radnika \n', fontSize: 8, alignment: 'right' }
              }

            ]
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: this.pD1
              },
              {
                width: '30%',
                text: this.pD2
              },
              {
                width: '10%',
                text: this.pD3
              },
              {
                width: '10%',
                text: this.pD4
              },
              {
                width: '10%',
                text: this.pD5
              },
              {
                width: '10%',
                text: this.pD6
              },
              {
                width: '10%',
                text: this.pD7
              },
              {
                width: '10%',
                text: this.pD8
              }
            ]
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '30%',
                text: ' '
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '10%',
                text: [
                  { text: this.document.FreeF5, alignment: 'right' }
                ]
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '10%',
                text: [
                  { text: this.document.FreeF2, alignment: 'right' }
                ]
              }
            ]
          },

          // ---------------------------------------------------------------------
          // Ostali troškovi:
          // ---------------------------------------------------------------------
          {
            text: [
              { text: 'Ostali troškovi: ', fontSize: 15 }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: { tex: 'Rb. \n', fontSize: 8 }
              },
              {
                width: '50%',
                text: { text: 'Naziv \n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '10%',
                text: { text: 'Jmj\n', fontSize: 8, alignment: 'center' }
              },
              {
                width: '10%',
                text: { text: 'Količina\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '10%',
                text: { text: 'Dob.cijena\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '10%',
                text: { text: 'Dob.iznos\n', fontSize: 8, alignment: 'right' }
              }
            ]
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: this.pT1
              },
              {
                width: '50%',
                text: this.pT2
              },
              {
                width: '10%',
                text: this.pT3
              },
              {
                width: '10%',
                text: this.pT4
              },
              {
                width: '10%',
                text: this.pT5
              },
              {
                width: '10%',
                text: this.pT6
              }
            ]
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '50%',
                text: ' '
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '10%',
                text: [
                  { text: this.document.FreeF3, alignment: 'right' }
                ]
              }
            ]
          }
        ]
      }
      pdfMake.createPdf(this.docDefinition).open()
    }
  },
  computed: {
  },
  watch: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
