<template>
    <v-row no-gutters>
        <v-col xs="12" sm="12" md="12" lg="12" ex="12">

              <v-row style="border-bottom:solid 1px;">
                  <v-col xs="5">
                      <h4><b><i>{{comp.CompanyName}}</i></b></h4>
                      {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}   <br />                
                      {{comp.CompanyPostcode}} {{comp.CompanyCity}}<br />
                      {{lang.VATID}} {{comp.CompanyVATID}}
                  </v-col>
                  <v-col xs="3">
                    <h2 v-if="type === 1">Lista Artikli zbrojeni po grupi</h2>
                    <h2 v-if="type === 2">Lista Artikli zbrojeni po mjeri</h2>
                    {{head2}} <br />
                    {{head3}} <br />
                    {{head4}} <br />
                    {{head5}}
                  </v-col>
                  <v-col xs="4">
                    <p class="text-md-left">
                        {{langC.PrintDate}} {{PrintDate}}<br />
                        email: {{comp.Companyemail}}, {{comp.CompanyPhones}}<br />
                    </p>
                  </v-col>
              </v-row>
                <v-row>
                  <v-col cols="12" sm="9" md="9" xs="9" lg="9" xl="9">
  
                  </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                      <v-btn
                        text color="primary"
                        @click="downloadFile()">
                        excel
                      </v-btn>
                    <v-btn
                        text color="primary"
                        @click="printAna()">
                        {{langC.Print}}
                      </v-btn>
                    </v-col>
                </v-row>
          
                <v-row style="border-bottom:solid 1px; border-top:solid 1px;">
                    
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" style="font-size:10px;">
                      {{ lang.ItemID }}
                    </v-col>
                    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="text-left" style="font-size:10px;">
                      {{ lang.ItemName }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{lang.ItemUnit}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ lang.PcsHome }}
                    </v-col>
                     <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ lang.PcsExport }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ lang.SuppAmountHome }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ lang.NetoSalesAmountHome }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ lang.SuppAmountExport }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ lang.NetoSalesAmountExport }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
                      {{lang.suppPrice}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
                      {{lang.ruc}}
                    </v-col>
                </v-row>

                <div v-for="item in finalItems" :key="item.id">

                <v-row  v-if="item.grFirst" class="mt-2">
                  <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="text-left" style="font-size:10px;">
                     <h2> {{ item.itemGroupName }} </h2>
                  </v-col>
                </v-row>

                <v-row style="border-top:dotted 1px;" v-if="item.grFirst" >
   
                 </v-row> 

                 <v-row style="border-top:dotted 1px;" v-if="item.groupEnd" >
   
                 </v-row>
                 <v-row  v-if="item.groupEnd">
                    <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="text-left" style="font-size:10px;">
                     <h3> {{ item.itemID }}{{ item.itemGroupName }}, {{ item.itemName }}, {{item.itemUnit}} </h3>
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      <h3> {{ item.pscHome && item.pscHome !== 0 ? localMoney(item.pscHome) : '' }}</h3>
                    </v-col>
                     <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      <h3> {{ item.pscExport && item.pscExport !==0 ? localMoney(item.pscExport) : '' }} </h3>
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      <h3> {{ item.itemSuppBasePriceAmountHome && item.itemSuppBasePriceAmountHome !== 0 ? localMoney(item.itemSuppBasePriceAmountHome) : '' }} </h3>
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                     <h3> {{ item.itemBaseAmountNoVATHome && item.itemBaseAmountNoVATHome !==0 ? localMoney(item.itemBaseAmountNoVATHome) : ''}}</h3>
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      <h3> {{ item.itemSuppBasePriceAmountExport && item.itemSuppBasePriceAmountExport !==0 ? localMoney(item.itemSuppBasePriceAmountExport) : '' }} </h3>
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      <h3> {{ item.itemBaseAmountNoVATExport && item.itemBaseAmountNoVATExport !==0 ? localMoney(item.itemBaseAmountNoVATExport) : ''}} </h3>
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      <h3> {{ item.suppPrice && item.suppPrice !==0 ? localMoney(item.suppPrice) : ''}}</h3>
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      <h3> {{ item.ruc && item.ruc !==0 ? localMoney(item.ruc) : ''}}</h3>
                    </v-col>
                 </v-row>
                 <v-row  v-if="!item.groupEnd">
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" style="font-size:10px;">
                      {{ item.itemID }}
                    </v-col>
                    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="text-left" style="font-size:10px;">
                      {{ item.itemName }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{item.itemUnit}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ item.pscHome && item.pscHome !== 0 ? localMoney(item.pscHome) : '' }}
                    </v-col>
                     <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ item.pscExport && item.pscExport !==0 ? localMoney(item.pscExport) : '' }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ item.itemSuppBasePriceAmountHome && item.itemSuppBasePriceAmountHome !== 0 ? localMoney(item.itemSuppBasePriceAmountHome) : '' }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ item.itemBaseAmountNoVATHome && item.itemBaseAmountNoVATHome !==0 ? localMoney(item.itemBaseAmountNoVATHome) : ''}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ item.itemSuppBasePriceAmountExport && item.itemSuppBasePriceAmountExport !==0 ? localMoney(item.itemSuppBasePriceAmountExport) : '' }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ item.itemBaseAmountNoVATExport && item.itemBaseAmountNoVATExport !==0 ? localMoney(item.itemBaseAmountNoVATExport) : ''}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ item.suppPrice && item.suppPrice !==0 ? localMoney(item.suppPrice) : ''}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ item.ruc && item.ruc !==0 ? localMoney(item.ruc) : ''}}
                    </v-col>
                </v-row>
              </div>
            <v-row style="border-top:solid 1px;">
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;"> 
                .
              </v-col>

              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">

              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                {{ Total1 && Total1 !==0 ? localMoney(Total1) : '' }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                {{ Total4 && Total4 !== 0 ? localMoney(Total4) : '' }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                {{ Total2 && Total2 !== 0 ? localMoney(Total2) : ''  }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                {{ Total3 && Total3 !== 0 ? localMoney(Total3) : ''  }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                {{ Total5 && Total5 !== 0 ? localMoney(Total5) : ''  }}
              </v-col>

              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                {{ Total6 && Total6 !== 0 ? localMoney(Total6) : ''  }}
              </v-col>
            </v-row>
            <v-row style="border-top:solid 1px;">
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;"> 
                .
              </v-col>

              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="text-right" style="font-size:10px;">

              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                Uk.Kol:
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                {{ localMoney(Total4 + Total1) }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                Dobit dom:
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                {{ localMoney(Total3 - Total2) }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                Dobit exp:
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                {{ localMoney(Total6 - Total5) }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                {{ localMoney((Total6 - Total5) + (Total3 - Total2)) }}
              </v-col>
            </v-row>
 

            <v-row>
              <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
                <DocumentPrintSalesListInv ref="prnt" />
              </v-col>
            </v-row>

            <v-row v-if="errorItems.length > 0">
              <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
                <DocumentSalesErrorList :errorItems="errorItems" />
              </v-col>
            </v-row>

        </v-col>
    </v-row>
</template>


<script>
import DocumentPrintSalesListInv from './DocumentPrintSalesListInv'
import DocumentSalesErrorList from './DocumentSalesErrorList'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import moneyFormat from '@/snippets/moneyFormat'
import {parseNum, dynamicSort} from '@/snippets/allSnippets'
import xlsx from 'json-as-xlsx'
import dayjs from 'dayjs'

export default {
  name: 'salesanalistInv',
  props: ['allitems', 'avgPrices', 'head1', 'head2', 'head3', 'head4', 'head5', 'type'],
  data: ()=> ({
    comp: {},
    lang: {},
    langC: {},
    Total1: 0,
    Total2: 0,
    Total3: 0,
    Total4: 0,
    Total5: 0,
    Total6: 0,
    Total7: 0,
    Total8: 0,
    Total9: 0,
    Total10: 0,
    PrintDate: null,
    ana: {},
    finalItems: [],
    errorItems: []
  }),
  beforeMount() {
    // const sideItems = this.allitems.filter(item => {
    //   return parseInt(item.FreeF6)  === (this.type === 1 ? 33 : 3)
    // })
    const itemsLoc = this.$store.state.itemStore ? this.$store.state.itemStore : []
    const midItems = []
    const res = Array.from(this.allitems.reduce(
      (m, {itemID, itemBaseAmountNoVAT}) => m.set(itemID, (m.get(itemID) || 0) + parseNum(itemBaseAmountNoVAT,2)), new Map
    ), ([itemID, itemBaseAmountNoVAT]) => ({itemID, itemBaseAmountNoVAT}));


    if (res && this.allitems) {
        let nr = 0
        let errorNr = 0
        
        res.map(item => {
          const onlyItems = this.allitems.filter(subitem => subitem.itemID === item.itemID)
          let itemTotal1 = 0
          let itemTotal2 = 0
          let itemTotal3 = 0
          let itemTotal4 = 0
          let itemTotal5 = 0
          let itemTotal6 = 0
          let suppPriceItem = 0
          let suppPriceAvg = 0
          let multiAvg = 0

          const avgItem = this.avgPrices.find(avgitem => avgitem.itemID === item.itemID )
          if (avgItem && avgItem.suppPriceAvg) {
            suppPriceAvg = parseNum(avgItem.suppPriceAvg,2)
          }
          const fItem = itemsLoc.find(fitem => fitem.itemID === item.itemID )
          if (fItem && fItem.itemSuppPrice) {
            suppPriceItem = parseNum(fItem.itemSuppPrice,2)
          }
          multiAvg = suppPriceAvg
          if (parseNum(suppPriceAvg, 2) === 0) {
            multiAvg = suppPriceItem
          }

          onlyItems.map(ite => {
            if (!ite.exportDoc) {
              // in case there is no supp price, check if is in items, if is, then multiply pcs * multiAvg
             // const suppAm = parseNum(ite.itemSuppBasePriceAmount, 2) !==0 && parseNum(ite.itemSuppBasePriceAmount, 2) < parseNum( ite.itemBaseAmountNoVAT ,2) ? parseNum(ite.itemSuppBasePriceAmount, 2) : (multiAvg && multiAvg !==0 ? multiAvg * parseNum(ite.itemPcsNeutral, 2): 0)
              const suppAm = multiAvg * parseNum(ite.itemPcsNeutral, 2)
              itemTotal1 += parseNum(ite.itemPcsNeutral, 2)
              itemTotal2 += suppAm < parseNum(ite.itemBaseAmountNoVAT, 2) ? suppAm : parseNum(ite.itemBaseAmountNoVAT, 2)
              itemTotal3 += parseNum(ite.itemBaseAmountNoVAT, 2)
            }
            if (ite.exportDoc) {
            //  const suppAm = parseNum(ite.itemSuppBasePriceAmount, 2) !==0 && parseNum(ite.itemSuppBasePriceAmount, 2) < parseNum( ite.itemBaseAmountNoVAT ,2) ? parseNum(ite.itemSuppBasePriceAmount, 2) : (multiAvg && multiAvg !==0 ? multiAvg * parseNum(ite.itemPcsNeutral, 2): 0)
              const suppAm = multiAvg * parseNum(ite.itemPcsNeutral, 2)
              itemTotal4 += parseNum(ite.itemPcsNeutral, 2)
              itemTotal5 += suppAm < parseNum(ite.itemBaseAmountNoVAT, 2) ? suppAm : parseNum(ite.itemBaseAmountNoVAT, 2)
              itemTotal6 += parseNum(ite.itemBaseAmountNoVAT, 2)
            }
          })

          //const totalSupp = parseFloat(itemTotal2) + parseFloat(itemTotal5)
          //const totalPcs = parseFloat(itemTotal1) + parseFloat(itemTotal4)

          //const suppPriceAvg = totalPcs !==0 ?  this.round((totalSupp / totalPcs) ,2) : 0
          let suppAvg = suppPriceAvg
          if (parseNum(suppPriceAvg, 2) === 0) {
            suppAvg = suppPriceItem && suppPriceItem !==0 ? suppPriceItem : 0
            if (parseNum(suppAvg,2) === 0) {
              errorNr += 1
              const errorItem = {}
              errorItem.id = errorNr
              errorItem.itemID = item.itemID
              errorItem.itemGroup = onlyItems[0].itemGroup
              errorItem.itemGroupName = onlyItems[0].itemGroupName
              errorItem.itemName = onlyItems[0].itemName
              errorItem.itemUnit = onlyItems[0].itemUnit        
              errorItem.pscHome = itemTotal1
              errorItem.itemSuppBasePriceAmountHome = itemTotal2
              errorItem.itemBaseAmountNoVATHome = itemTotal3
              errorItem.pscExport = itemTotal4
              errorItem.itemSuppBasePriceAmountExport = itemTotal5
              errorItem.itemBaseAmountNoVATExport = itemTotal6
              errorItem.suppPrice = suppPriceItem
              errorItem.suppPriceAvg = suppAvg
              this.errorItems.push(errorItem)
            }
          }
          nr += 1
          const newTotalItem = {}
          newTotalItem.id = nr
          newTotalItem.itemID = item.itemID
          newTotalItem.itemGroup = onlyItems[0].itemGroup
          newTotalItem.itemGroupName = onlyItems[0].itemGroupName
          newTotalItem.itemName = onlyItems[0].itemName
          newTotalItem.itemUnit = onlyItems[0].itemUnit        
          newTotalItem.pscHome = itemTotal1
          newTotalItem.itemSuppBasePriceAmountHome = itemTotal2
          newTotalItem.itemBaseAmountNoVATHome = itemTotal3
          newTotalItem.pscExport = itemTotal4
          newTotalItem.itemSuppBasePriceAmountExport = itemTotal5
          newTotalItem.itemBaseAmountNoVATExport = itemTotal6
          newTotalItem.suppPrice = suppAvg
          midItems.push(newTotalItem)
        })
      }

      if (this.type === 1) {
        midItems.sort(dynamicSort("itemGroup"))
      }
      if (this.type === 2) {
        midItems.sort(dynamicSort("itemUnit"))
      }


      if (midItems) {
        
        let gCond = null
        let gName = null
        let gTotal1 = 0
        let gTotal2 = 0
        let gTotal3 = 0
        let gTotal4 = 0 
        let gTotal5 = 0
        let gTotal6 = 0
        let gHave = 0
        let nr = 0
        let prNr = 0
        let grFirst = false
        let fullFirst = true
        midItems.map(itm => {
          grFirst = false
          if (!gCond) {
            gCond = (this.type === 1 ? itm.itemGroup : itm.itemUnit)
            grFirst = true
            gName = itm.itemGroupName
          }
          if (this.type === 1 ? gCond !== itm.itemGroup : gCond !== itm.itemUnit) {
           
            const gFinal = {}
            nr +=1
            gFinal.id = nr
            gFinal.prNr = ''
            gFinal.itemID = 'UK:'
            gFinal.itemGroup = gCond
            gFinal.itemGroupName = gName
            gFinal.itemName = 'zap.:' + gHave
            gFinal.itemUnit = 'Gr.Kol:' + this.localMoney(gTotal4 + gTotal1)
            gFinal.pscHome = gTotal1
            gFinal.itemSuppBasePriceAmountHome = gTotal2
            gFinal.itemBaseAmountNoVATHome = gTotal3
            gFinal.pscExport = gTotal4
            gFinal.itemSuppBasePriceAmountExport = gTotal5
            gFinal.itemBaseAmountNoVATExport = gTotal6 
            gFinal.ruc = (gTotal6 - gTotal5) + (gTotal3 - gTotal2)
            gFinal.groupEnd = true
            gFinal.suppPrice = ''
            gFinal.grFirst = grFirst
            this.finalItems.push(gFinal)

            gCond = (this.type === 1 ? itm.itemGroup : itm.itemUnit)
            gName = itm.itemGroupName
            gHave = 0
            gTotal1 = 0
            gTotal2 = 0
            gTotal3 = 0
            gTotal4 = 0 
            gTotal5 = 0
            gTotal6 = 0
            grFirst = true
          }

          if (fullFirst) {
            grFirst = true
            fullFirst = false
          }
          const newFinal = {}
          nr +=1
          prNr += 1
          newFinal.id = nr
          newFinal.prNr = prNr
          newFinal.itemID = itm.itemID
          newFinal.itemGroup = itm.itemGroup
          newFinal.itemGroupName = itm.itemGroupName
          newFinal.itemName = itm.itemName
          newFinal.itemUnit = itm.itemUnit        
          newFinal.pscHome = itm.pscHome
          newFinal.itemSuppBasePriceAmountHome = itm.itemSuppBasePriceAmountHome
          newFinal.itemBaseAmountNoVATHome = itm.itemBaseAmountNoVATHome
          newFinal.pscExport = itm.pscExport
          newFinal.itemSuppBasePriceAmountExport = itm.itemSuppBasePriceAmountExport
          newFinal.itemBaseAmountNoVATExport = itm.itemBaseAmountNoVATExport
          newFinal.ruc = (parseNum(itm.itemBaseAmountNoVATExport, 2) - parseNum(itm.itemSuppBasePriceAmountExport, 2)) + (parseNum(itm.itemBaseAmountNoVATHome, 2) - parseNum(itm.itemSuppBasePriceAmountHome, 2))
          newFinal.groupEnd = false
          newFinal.suppPrice = itm.suppPrice
          newFinal.grFirst = grFirst
          this.finalItems.push(newFinal)

          gTotal1 += parseNum(itm.pscHome, 2) 
          gTotal2 += parseNum(itm.itemSuppBasePriceAmountHome, 2)
          gTotal3 += parseNum(itm.itemBaseAmountNoVATHome, 2)
          gTotal4 += parseNum(itm.pscExport, 2) 
          gTotal5 += parseNum(itm.itemSuppBasePriceAmountExport, 2)
          gTotal6 += parseNum(itm.itemBaseAmountNoVATExport, 2)
          gHave += 1
          this.Total1 += parseNum(itm.pscHome, 2) 
          this.Total2 += parseNum(itm.itemSuppBasePriceAmountHome, 2)
          this.Total3 += parseNum(itm.itemBaseAmountNoVATHome, 2)
          this.Total4 += parseNum(itm.pscExport, 2) 
          this.Total5 += parseNum(itm.itemSuppBasePriceAmountExport, 2)
          this.Total6 += parseNum(itm.itemBaseAmountNoVATExport, 2)
        })
        const gFinal = {}
        nr +=1
        gFinal.id = nr
        gFinal.prNr = ''
        gFinal.itemID = 'Uk:'
        gFinal.itemGroup = gCond
        gFinal.itemGroupName = gName
        gFinal.itemName = 'zap.:' + gHave
        gFinal.itemUnit = 'Gr.Kol:' + this.localMoney(gTotal4 + gTotal1)
        gFinal.pscHome = gTotal1
        gFinal.itemSuppBasePriceAmountHome = gTotal2
        gFinal.itemBaseAmountNoVATHome = gTotal3 
        gFinal.pscExport = gTotal4
        gFinal.itemSuppBasePriceAmountExport = gTotal5 
        gFinal.itemBaseAmountNoVATExport = gTotal6 
        gFinal.ruc = (gTotal6 - gTotal5) + (gTotal3 - gTotal2)
        gFinal.groupEnd = true
        gFinal.suppPrice = ''
        this.finalItems.push(gFinal)
      }
      this.PrintDate = dayjs().format('DD.MM.YYYY, H:mm')

  },
  mounted () {
    this.comp = this.$store.state.companyActiv
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
     round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },

    async printAna() {
      const newObj = {}
      this.ana = {...newObj}
      this.ana = {...this.documentAna}
      this.ana.items = this.finalItems ? this.finalItems : []
      this.ana.head1 = this.type === 1 ? 'Lista Artikli zbrojeni po grupi' : 'Lista Artikli zbrojeni po grupi'
      this.ana.head2 = this.head2
      this.ana.head3 = this.head3
      this.ana.head4 = this.head4
      this.ana.head5 = this.head5
      this.ana.type = this.type
      this.ana.Total1 = this.Total1
      this.ana.Total2 = this.Total2
      this.ana.Total3 = this.Total3
      this.ana.Total4 = this.Total4
      this.ana.Total5 = this.Total5
      this.ana.Total6 = this.Total6
      await this.$store.dispatch('setPrintObj', this.ana)
      await this.$refs.prnt.print()
    },
    downloadFile () {

      this.topHeader = 'IRE' + this.$store.state.businessYear + this.lang.ReportCreated + dayjs().format('DD.MM.YYYY HH:MM')
      const final2 = [...this.finalItems]
      const newObj = {}
      newObj.id = ''
      newObj.itemID = ''
      newObj.itemGroup = ''
      newObj.itemGroupName = ''
      newObj.itemName = ''
      newObj.itemUnit = ''
      newObj.pscHome = this.Total1
      newObj.pscExport = this.Total4
      newObj.itemSuppBasePriceAmountHome = this.Total2
      newObj.itemBaseAmountNoVATHome = this.Total3
      newObj.itemSuppBasePriceAmountExport = this.Total5
      newObj.itemBaseAmountNoVATExport = this.Total6
      newObj.ruc = ''
      newObj.suppPrice = ''
      final2.push(newObj)

      const newObj2 = {}
      newObj2.id = ''
      newObj2.itemID = ''
      newObj2.itemGroup = ''
      newObj2.itemGroupName = ''
      newObj2.itemName = ''
      newObj2.itemUnit = ''
      newObj2.pscHome = 'UK.Kol'
      newObj2.pscExport = this.Total4 + this.Total1
      newObj2.itemSuppBasePriceAmountHome = 'Dobit dom:'
      newObj2.itemBaseAmountNoVATHome = this.Total3 - this.Total2
      newObj2.itemSuppBasePriceAmountExport = 'Dobit exp:'
      newObj2.itemBaseAmountNoVATExport = this.Total6 - this.Total5
      newObj2.ruc = (this.Total3 - this.Total2) + (this.Total6 - this.Total5)
      newObj2.suppPrice = ''
      final2.push(newObj2)

      let sheetNm = ''
      let fileNm = ''
      const currDate = dayjs().format('DD_MM_YYYY_HH_MM')
      if (this.$store.state.language === 'EN') {
       sheetNm = this.type === 1 ? 'Invoice_' + currDate : 'Inv_itm_' + currDate
       fileNm = this.type === 1 ? 'Invoice_' + currDate : 'Inv_itm' + currDate 
      }
      if (this.$store.state.language === 'HR') {
       sheetNm = this.type === 1 ? 'IRE_' + currDate : 'Ire_art_' + currDate
       fileNm = this.type === 1 ? 'IRE_' + currDate : 'Ire_art_' + currDate 
      }
      let data = [
        {
          sheet: sheetNm,
          columns: [
            { label: this.lang.DocPrintOrder, value: 'prNr'},
            { label: this.lang.ItemID, value: 'itemID'},
            { label: this.lang.ItemGroup, value: 'itemGroup'},
            { label: this.lang.ItemGroup, value: 'itemGroupName'},
            { label: this.lang.ItemName, value: 'itemName' },
            { label: this.lang.ItemUnit, value: 'itemUnit' },
            { label: this.lang.PcsHome, value: 'pscHome' },
            { label: this.lang.PcsExport, value: 'pscExport' },
            { label: this.lang.SuppAmountHome, value: 'itemSuppBasePriceAmountHome' },
            { label: this.lang.NetoSalesAmountHome, value: 'itemBaseAmountNoVATHome' },
            { label: this.lang.SuppAmountExport, value: 'itemSuppBasePriceAmountExport' },
            { label: this.lang.NetoSalesAmountExport, value: 'itemBaseAmountNoVATExport' },
            { label: this.lang.suppPrice, value: 'suppPrice' },
            { label: this.lang.ruc, value: 'ruc' },
          ],
          content: final2
        },
      ]
      
      let settings = {
        fileName: fileNm, // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
      }

      xlsx(data, settings)

    }

  },
  computed: {
  },
  watch: {

  },
  components: {
    DocumentPrintSalesListInv,
    DocumentSalesErrorList
  }

}

</script>