const docHR = {
  WorkHour: 'Radni sati',
  WorkHours: 'Radni sati',
  EmployeeId: 'Radnik',
  email: 'email',
  First: 'Ime',
  Last: 'Prezime',
  ForWeek: 'Za tjedan',
  ForMonth: 'Za mjesec',
  ForYear: 'Za godinu',
  WDate: 'Datum',
  WDay: 'Dan',
  PocetakRada: 'Početak rada',
  ZavrsetakRada: 'Završetak rada',
  RedovanRad: 'Redovan rad',
  NocniRad: 'Noćni rad',
  PrekovremeniRad: 'Prekovremeni rad',
  NedjeljaRad: 'Rad nedjeljom',
  BlagdanRad: 'Rad blagdanom',
  TerenRad: 'Terenski rad',
  PreraspodjelaRada: 'Preraspodjela rada',
  Pripravnost: 'Pripravnost',
  DnevniOdmor: 'Dnevni odmor',
  TjedniOdmor: 'Tjedni odmor',
  NeradniBlagdan: 'Neradni blagdan',
  GodisnjiOdmor: 'Godišnji odmor',
  BolovanjeDo42Dana: 'Bolovanje DO 42 dana',
  BolovanjeOd42Dana: 'Bolovanje OD 42 dana',
  Rodiljni: 'Rodiljni dopust',
  PlaceniDopust: 'Plaćeni dopust',
  NeplaceniDopust: 'Neplaćeni dopust',
  NenazocnostNaZahtjevRadnika: 'Nenazočnost na zahtjev radnika',
  ZastojKrivnjomPoslodavca: 'Zastoj krivnjom poslodavca',
  Strajk: 'Štrajk',
  Lockout: 'Lockout',
  VrijemeMirovanjaRadnogOdnosa: 'Mirovanje radnog odnosa',
  TotalHours: 'Ukupno sati',
  Create: 'Napravi default',
  DeleteMonth: 'Obriši mjesec',
  WhEmpty: 'Nema podataka'
}

export default docHR