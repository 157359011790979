<template>
  <div>

  </div>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './salarieDescEn'
import langHr from './salarieDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'
// import { parseNum } from '@/snippets/allSnippets'

export default {
  name: 'salarieprintsignslist',
  props: [],
  data () {
    return {
      msg: '',
      documentName: '',
      documentName2: '',
      document: {},
      havePath: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locDocument: {},
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      comp: {},
      mainQuery: {},
      ana: {},
      totalIz: 0,
      totalNa: 0,
      Bruto1: 0,
      Bruto3: 0,
      Neto3: 0
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = {...langEn}
      this.langC = {...commEn}
    }
    if (this.$store.state.language === 'HR') {
      this.lang = {...langHr}
      this.langC = {...commHr}
    }

  },
  methods: {
    async print () {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.BusinessYear = this.$store.state.businessYear
      }
      this.printDoc()
    },

    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    printDoc () {
      this.comp = this.$store.state.companyActiv
      this.ana = this.$store.state.printObj ? this.$store.state.printObj : {}

      this.initDocument()
      this.addHead()
      this.addLine()
      this.addItemsHeader()
      this.addLine()
      this.addItems()
      this.addLine()
      this.addEmptyRow()
      this.addLine()
      this.addItemsTotals()

  
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'landscape'
      this.docDefinition.pageMargins = [ 20, 20, 20, 40 ]
      this.content = []
    },
  
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 800,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },

    addHead() {
      const docPart = {
          columns: [
            {
              // % width
              width: '35%',
              text: [
                { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.Companyemail ? 'email:' +  this.comp.Companyemail + ' \n' : ' ') , fontSize: 8 },
                { text: 'OIB:' + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '45%',
              text: [
                { text: '\n', fontSize: 10},
              ]
            },
            {
              // % width
              width: '20%',
              text: [                 
                { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a')+ ' \n', fontSize:8},
                { text: ' Porez 20% na osnovicu od: 0 do 47.780,28 €' , fontSize:8},
                { text: ' Porez 30% od: 47.780,29 € ' , fontSize:8}
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
        const docPart2 = {
          columns: [
            {
              // % width
              width: '25%',
              text: [
                { text: ' \n', fontSize: 10, bold: true },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '55%',
              text: [
                { text: (this.ana.head2 ? this.ana.head2 + ',€  \n' : ''), fontSize: 12, bold: true, alignment: 'center'  },
                { text: '(svi prikazani iznosi su zbroj cijele godine) \n', fontSize: 10, bold: true, alignment: 'center' },
              ]
            },
            {
              // % width
              width: '10%',
              text: [                 
                { text: '  ', fontSize:8}
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart2)
    },

    addItemsHeader () {
      const docPart = {
          columns: [
            {
              width: '3%',
              text: 'Rbr' + '\n', fontSize: 8 
            },
            {
              width: '15%',
              text: 'Radnik' + '\n', fontSize: 8
            },
            {
              width: '7%',
              text: [
                { text: 'Bruto' , fontSize: 8, alignment: 'right' },
              ]
            },
            {
              width: '7%',
              text: [
                { text: 'Doprinosi'  + '\n' , fontSize: 8, alignment: 'right' },
                { text: 'iz plaće' , fontSize: 8, alignment: 'right' },
              ]
            },
            {
              width: '7%',
              text: [
                { text: 'Dohodak' + '\n' , fontSize: 8, alignment: 'right' },
              ]
            },
            {
              width: '7%',
              text: [
                { text: 'Os.odbitak' + '\n', fontSize: 8, alignment: 'right', },
              ]
            },
            {
              width: '7%',
              text: [
                { text: 'Porezna' + '\n', fontSize: 8, alignment: 'right', },
                { text: 'osnovica', fontSize: 8, alignment: 'right', },
              ]
            },
            {
              width: '7%',
              text: [
                { text: 'Porez 20%' + '\n', fontSize: 8, alignment: 'right', },
                { text: 'uplaćen' + '\n', fontSize: 8, alignment: 'right', },
              ]
            },
            {
              width: '6%',
              text: [
                { text: 'Porez 30%' + '\n', fontSize: 8, alignment: 'right', },
                { text: 'uplaćen' + '\n', fontSize: 8, alignment: 'right', },
              ]
            },
            {
              width: '5%',
              text: [
                { text: 'Prirez' + '\n', fontSize: 8, alignment: 'right', },
                { text: 'uplaćen' + '\n', fontSize: 8, alignment: 'right', },
              ]
            },            
            {
              width: '7%',
              text: [
                { text: 'Porez 20%' + '\n', fontSize: 8, alignment: 'right', },
                { text: 'god.obračun' + '\n', fontSize: 8, alignment: 'right', },
              ]
            },
            {
              width: '6%',
              text: [
                { text: 'Porez 30%' + '\n', fontSize: 8, alignment: 'right', },
                { text: 'god.obračun' + '\n', fontSize: 8, alignment: 'right', },
              ]
            },
            {
              width: '5%',
              text: [
                { text: 'Prirez' + '\n', fontSize: 8, alignment: 'right', },
                { text: 'god.obrač.' + '\n', fontSize: 8, alignment: 'right', },
              ]
            },
            {
              width: '6%',
              text: [
                { text: 'Razlika €' + '\n', fontSize: 8, alignment: 'right', },
              ]
            },
          ]
        }
      this.content.push(docPart)
    },
    addItems() {
          this.order = 0
            this.Bruto1 = 0
            this.Bruto3 = 0
            this.Neto3 = 0
            if (this.ana.head1) {
              this.ana.head1.map(item => {
                this.order += 1
                // const haveBrutoAddition1 = parseFloat(item.FinKoef1) 
                // const haveBrutoAddition2 = parseFloat(item.FinKoef2)
                // const haveBrutoAddition = haveBrutoAddition1 + haveBrutoAddition2
                // this.Bruto1 += this.round(parseFloat(item.FinBruto2), 2)
                // this.Bruto3 += this.round(parseFloat(item.FinBruto3),2)
                // this.Neto3 += this.round(parseFloat(item.FinNeto3),2)

                const docPart = {
                columns: [
                    {
                      width: '3%',
                      fontSize: 8,
                      alignment: 'left',
                      text: this.order + '.'
                    },
                    {
                      width: '15%',
                      fontSize: 9,
                      alignment: 'left',
                      text:(item.First ? item.First + ' ' : '') + (item.Last ? item.Last  : '') + ' \n',
                    },
                    {
                      width: '7%',
                      text: [
                        { text: (item.FinBruto2  ? this.formatPrice(item.FinBruto2) + ' \n' : '') , fontSize: 9, alignment: 'right' },
                      ]
                    },
                    {
                    width: '7%',
                      text: [
                        { text: (item.DoprinosiIZTotal ? this.formatPrice(item.DoprinosiIZTotal) + ' \n' : '') , fontSize: 9, alignment: 'right' },
                      ]
                    },
                     {
                      width: '7%',
                      text: [
                        { text: (item.FinNeto1ls ? this.formatPrice(item.FinNeto1ls) + ' \n' : '') , fontSize: 9, alignment: 'right' },
                      ]
                    },
                     {
                      width: '7%',
                      text: [
                        { text: (item.FinFreeTotalToUse ? this.formatPrice(item.FinFreeTotalToUse) + ' \n' : '') , fontSize: 9, alignment: 'right', },
                      ]
                    },
                    {
                      width: '7%',
                      text: [
                        { text: (item.FinNeto1ls ? this.formatPrice(item.FinNeto1ls - item.FinFreeTotalToUse) + ' \n': '') , fontSize: 9, alignment: 'right', },
                      ]
                    },
                    {
                      width: '7%',
                      text: [
                        { text: (item.FinTax1Am  ? this.formatPrice(item.FinTax1Am) + ' \n' : '') , fontSize: 9, alignment: 'right', },
                      ]
                    },
                      {
                      width: '6%',
                      text: [
                        { text: (item.FinTax2Am  ? this.formatPrice(item.FinTax2Am) + ' \n' : '') , fontSize: 9, alignment: 'right', },
                      ]
                    },
                    {
                      width: '5%',
                      text: [
                        { text: (item.StariPrirez  ? this.formatPrice(item.StariPrirez) + ' \n' : '') , fontSize: 9, alignment: 'right', },
                      ]
                    },
                    {
                      width: '7%',
                      text: [
                        { text: (item.Porez20  ? this.formatPrice(item.Porez20) + ' \n' : '') , fontSize: 9, alignment: 'right', },
                      ]
                    },
                    {
                      width: '6%',
                      text: [
                        { text: (item.Porez30  ? this.formatPrice(item.Porez30) + ' \n' : '') , fontSize: 9, alignment: 'right', },
                      ]
                    },
                    {
                      width: '5%',
                      text: [
                        { text: (item.NoviPrirez  ? this.formatPrice(item.NoviPrirez) + ' \n' : '') , fontSize: 9, alignment: 'right', },
                      ]
                    },
                    {
                      width: '6%',
                      text: [
                        { text: (item.razlikaPoreza ? this.formatPrice(item.razlikaPoreza) + ' € \n' : '') , fontSize: 9, alignment: 'right', },
                      ]
                    },

                  ]
                }
                this.content.push(docPart)
            })
          }
    },

    addItemsTotals() {
        const docPart = {
        columns: [
            {
              width: '3%',
              fontSize: 10,
              alignment: 'left',
              text:  ' '
            },
            {
              width: '24%',
              fontSize: 10,
              alignment: 'left',
              text: 'UKUPNO ZA SVE DJELATNIKE: \n',
            },

            {
              width: '18%',
              text: [
                { text: 'Porez uplaćen kroz godinu:\n' , fontSize: 9, alignment: 'right', },
                { text: (this.ana.head3.FinTax ? this.formatPrice(this.ana.head3.FinTax) + ' \n' : '') , fontSize: 9, alignment: 'right', },
              ]
            },
              {
              width: '9%',
              text: [
                { text:  ' \n' , fontSize: 9, alignment: 'right', },
              ]
            },
            {
              width: '18%',
              text: [
                { text:  'Porez po godišnjem obračunu:\n' , fontSize: 9, alignment: 'right', },
                { text: (this.ana.head3.Porez ? this.formatPrice(this.ana.head3.Porez) + ' \n' : '') , fontSize: 9, alignment: 'right', },
              ]
            },
            {
              width: '17%',
              text: [
                { text: (this.ana.head3.razlikaPoreza ? 'Razlika za uplatu/povrat: \n'  +this.formatPriceHR(this.ana.head3.razlikaPoreza) + ' \n' : '') , fontSize: 9, alignment: 'right', },
              ]
            },
          ]
        }
      this.content.push(docPart)
    },


    addEmpty () {
      const docPart = {
        
          }
      this.content.push(docPart)
    },

  },
  computed: {
  },
  watch: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
