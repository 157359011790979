<template>
  <div class="mainledgerprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
//import PartnerService from '@/services/PartnerService'
import {parseNum, round} from '@/snippets/allSnippets'
import dayjs from 'dayjs'


export default {
  name: 'mainledgerprintcard10',
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0,
      partner: {},
      debitTotal: 0,
      creditTotal: 0,
      debitCreditTotal: 0,
      headline: '',
      card: {}
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    

  },
  methods: {
    print (card) {
      //console.log('printam card',card)
      this.card = card
      this.debitTotal = this.card.Total1 ? this.card.Total1 : 0
      this.creditTotal = this.card.Total2 ? this.card.Total2 : 0
      this.debitCreditTotal = this.card.Total5 ? this.card.Total5 : 0
      const typePartner = this.card.fromAccount ? this.card.fromAccount.substr(0,2) : ''
      if (this.card.subtype && this.card.subtype === 5) {
        this.headline = typePartner === '12' ? this.lang.OpenStateBuyers : this.lang.OpenStateSupp
      }
      if (this.card.subtype && this.card.subtype === 6) {
        this.headline = typePartner === '12' ? this.lang.CardsBuyers : this.lang.CardsSupp
      }
 
      this.initDocument()
      this.addHeaderNoLogo()
      this.addLine()
      this.addItems()
      this.addLine()
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.content = []
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 558,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addLineDash () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1,
                dash: { length: 1 },
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '35%',
                text: [
                  { text: this.comp.CompanyNameShort + ' \n', fontSize: 14, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text:  this.headline + '\n', fontSize: 18, bold: true },
                  { text: ' Od konta : ' + this.card.fromAccount  + ' Do konta : ' + this.card.toAccount+ '\n' , fontSize: 8},
                  { text: ' Od datuma: ' + dayjs(this.card.docFromDate).format('DD.MM.YYYY')  + ' Do datuma: ' + dayjs(this.card.docToDate).format('DD.MM.YYYY'), fontSize:8}
                ]   
              },
              {
                // % width
                width: '20%',
                text: [                 
                  { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
        this.addLine()
        this.addItemsHeader()
        const docPart02 = {
          columns: [
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '15%',
                fontSize: 8,
                alignment: 'left',
                bold: true,
                text: 'SVEUKUPNO:'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: ' '
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                bold: true,
                text: this.card.Total1 ? this.formatPrice(this.card.Total1)  : ''
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                bold: true,
                text: this.card.Total2 ? this.formatPrice(this.card.Total2) : ''
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                bold: true,
                text: this.card.Total5 ? this.formatPrice(this.card.Total5) : ''
              },
          ]
        }
        this.content.push(docPart02)
        this.addLine()

    },

    addItemsHeader () {
        const docPart04 = {
        columns: [
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '15%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: 'Dug. ' + this.card.currency 
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                text: 'Pot.' + this.card.currency 
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                text: 'Saldo' + this.card.currency
              },
            ]
          }
        this.content.push(docPart04)        
    },
    addItemsHeader2 () {
        const docPart = {
        columns: [
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: 'Rb.  Vrsta'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: 'Broj dok.'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: 'Veza'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: 'Datum knjiženja'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: 'Datum dokumenta'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: 'Datum dospijeća'
              },
              {
                width: '20%',
                fontSize: 8,
                alignment: 'left',
                text: 'Opis'
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'left',
                text: 'Konto'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: 'Duguje'
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                text: 'Potražuje'
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                text: 'Saldo'
              },
            ]
          }
        this.content.push(docPart)        
    },
    addItems () {
           this.order = 0
 
            if (this.card.items) {

              const locItems = JSON.parse(JSON.stringify(this.card.items))
              locItems.map(item => {
                  this.order += 1
                  const docPartHead = {
                  columns: [
                    {
                      // % width
                      width: '75%',
                      text: [
                        { text: ' \n' + (item.partner && item.partner.partnerName ? item.partner.partnerName : '' ) + ' \n', fontSize: 12},
                        { text: (item.partner && item.partner.partnerStreet ? item.partner.partnerStreet : '') + (item.partner && item.partner.partnerBuildingNameNumber ? ' ' + item.partner.partnerBuildingNameNumber : '') +', ', fontSize: 8},
                        { text: (item.partner && item.partner.partnerPostcode ? item.partner.partnerPostcode : '') + (item.partner && item.partner.partnerCity ? ' ' +item.partner.partnerCity : '') + ', OIB ' + ( item.partnerVATID ? item.partnerVATID : '') + ' \n', fontSize: 8},
                      ]   
                    },

                  ],
                }
                this.content.push(docPartHead)
                this.addLine()
                this.addItemsHeader2()
                this.addLine()
                if (item.detItems && item.detItems.length > 0) {

                  this.debitTotal = 0
                  this.creditTotal = 0
                  this.debitCreditTotal = 0

                  item.detItems.map(detItem => {
   
                    this.debitTotal +=  detItem.Debit ? round(parseNum(detItem.Debit),2) : 0
                    this.creditTotal +=  detItem.Credit ? round(parseNum(detItem.Credit),2) : 0
                    this.debitCreditTotal +=  detItem.Debit || detItem.Credit ? round(parseNum(detItem.Debit),2) - round(parseNum(detItem.Credit),2) : 0
                    const docPart010 = {
                      columns: [
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'left',
                              text: detItem.itBookName
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'left',
                              text: detItem.itInvoice
                            },
                            {
                              width: '10%',
                              fontSize: 7,
                              alignment: 'left',
                              text: (detItem.account2 === '12'  ? (detItem.invoiceOutNumber ? detItem.invoiceOutNumber : '') : (detItem.invoiceInNumber ? detItem.invoiceInNumber : ''))  + (detItem.BusinessYearInvoice ?  '/' + detItem.BusinessYearInvoice : '')
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'left',
                              text: dayjs(detItem.BookingDate).format('DD.MM.YYYY')
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'left',
                              text: dayjs(detItem.InvoiceDate).format('DD.MM.YYYY')
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'left',
                              text: dayjs(detItem.InvoicePaymentDeadlineDate).format('DD.MM.YYYY')
                            },
                            {
                              width: '20%',
                              fontSize: 7,
                              alignment: 'left',
                              text: (detItem.Description && detItem.Description.length < 35 ? detItem.Description : detItem.Description.substr(0, 35))
                            },
                            {
                              width: '6%',
                              fontSize: 7,
                              alignment: 'left',
                              text: detItem.account4
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'right',
                              text: this.formatPrice(detItem.Debit)
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'right',
                              text: this.formatPrice(detItem.Credit)
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'right',
                              text: this.formatPrice(this.debitCreditTotal )
                            },
                          ]
                        }
                      this.content.push(docPart010)
                  })

                  this.addLine()

                  const docPart020 = {
                      columns: [
                          {
                            width: '8%',
                            fontSize: 8,
                            alignment: 'left',
                            text: ' '
                          },
                          {
                            width: '8%',
                            fontSize: 8,
                            alignment: 'left',
                            text: ' '
                          },
                          {
                            width: '8%',
                            fontSize: 8,
                            alignment: 'left',
                            text: ' '
                          },
                          {
                            width: '8%',
                            fontSize: 8,
                            alignment: 'left',
                            text: ' '
                          },
                          {
                            width: '8%',
                            fontSize: 8,
                            alignment: 'left',
                            text: ' '
                          },
                          {
                            width: '8%',
                            fontSize: 8,
                            alignment: 'left',
                            text: ' '
                          },
                          {
                            width: '22%',
                            fontSize: 8,
                            alignment: 'left',
                            text: 'UKUPNO:'
                          },
                          {
                            width: '6%',
                            fontSize: 8,
                            alignment: 'right',
                            text: ' '
                          },
                          {
                            width: '8%',
                            fontSize: 7,
                            alignment: 'right',
                            text: this.formatPrice(this.debitTotal)
                          },
                          {
                            width: '8%',
                            fontSize: 7,
                            alignment: 'right',
                            text: this.formatPrice(this.creditTotal)
                          },
                          {
                            width: '8%',
                            fontSize: 7,
                            alignment: 'right',
                            text: this.formatPrice(this.debitCreditTotal)
                          },
                      ]
                    }
                  this.content.push(docPart020)

                 }

                }
              )
          }
    },


  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>