<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="390"
    >
      <v-card>
        <v-card-title class="text-h5">
         {{header}}
        </v-card-title>
        <v-card-text>{{message}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="dcolor"
            
            @click="dialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>


export default {
  name: '',
  props: ['header', 'message', 'dcolor', 'showDialog'],
  data: ()=> ({
    dialog: true
  }),
  created () {
    this.dialog = this.showDialog
  },


}

</script>