<template>

  <v-container>
    <v-row>
      <v-flex xs12 >

            <v-row>
              <v-flex xs3 class="px-2 headline" >
                Broj izvoda:
              </v-flex>
              
              <v-flex xs2 >
                  <span class="headline">{{ locBookID }}
                  </span>
              </v-flex>
               <v-flex xs4 class="py-2 px-0">
                <span v-if="this.$store.state.language == 'EN'">
                    Created At: <span style="font-weight:bold">{{locCreatedAt}}</span>
                   </span>
                   <span v-if="this.$store.state.language == 'HR'">
                    Kreiran: <span style="font-weight:bold">{{locCreatedAt}}</span>
                   </span>
              </v-flex>

              <v-flex xs3 class="py-2 px-0">
                <span v-if="this.$store.state.language == 'EN'">
                    Updated At: <span style="font-weight:bold">{{locUpdatedAt}}</span>
                   </span>
                   <span v-if="this.$store.state.language == 'HR'">
                    Izmjena: <span style="font-weight:bold">{{locUpdatedAt}}</span>
                   </span>
              </v-flex>

            </v-row>
            <v-divider class="mt-2"></v-divider>
            <v-row class="pt-3">
              <v-flex xs10 class="py-0 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Company name: <span style="font-weight:bold">{{mainledger.CompanyNameDatabase}}</span>
                   </span>
                   <span v-if="this.$store.state.language == 'HR'">
                    Tvrtka naziv: <span style="font-weight:bold">{{mainledger.CompanyNameDatabase}}</span>
                   </span>
              </v-flex>
              <v-flex xs2 >
                  
              </v-flex>
            </v-row>

            <v-row >
              <v-flex xs5 class="py-0 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Business Year: <span style="font-weight:bold">{{parseInt(mainledger.BusinessYear)}}</span>
                   </span>
                   <span v-if="this.$store.state.language == 'HR'">
                    Poslovna godina: <span style="font-weight:bold">{{parseInt(mainledger.BusinessYear)}}</span>
                   </span>
              </v-flex>

               <v-flex xs6 >
                  <span v-if="this.$store.state.language == 'EN'">
                    User: <span style="font-weight:bold">{{mainledger.First}} {{mainledger.Last}}, {{mainledger.email}}, {{mainledger.UserId}}</span>
                   </span>
                   <span v-if="this.$store.state.language == 'HR'">
                    Korisnik: <span style="font-weight:bold">{{mainledger.First}} {{mainledger.Last}}, {{mainledger.email}}, {{mainledger.UserId}}</span>
                   </span>
              </v-flex>
            </v-row>

            <v-divider class="mt-2"></v-divider>

            <v-row class="pt-3">
              <v-col cols="12" sm="3" md="3" xs="6" lg="3" xl="3">
                .
              </v-col>
            </v-row>
            <v-row>
              <v-flex xs3 class="py-0 px-2 pr-2">
                <v-text-field
                    dense
                    :label="lang.bankAccount"
                    v-model="bankAccount"
                    disabled
                ></v-text-field>
              </v-flex>

             <v-flex xs3 class="pr-2">
                <v-text-field
                    dense
                    :label="lang.bankStatmentNumber"
                    v-model="mainledger.bankStatmentNumber"
                    type="number"
                    disabled
                ></v-text-field>
             </v-flex>

              <v-flex xs3 class="pr-2">
                <v-text-field
                    dense
                    :label="lang.bankStatmentDate"
                    v-model="locInvoiceDate"
                    disabled
                ></v-text-field>
             </v-flex>

             <v-flex xs3 class="pr-2">
                <v-text-field
                    dense
                    :label="lang.bankStatmentMoneyName"
                    v-model="mainledger.bankStatmentMoneyName"
                    style="font-weight:bold"
                    disabled
                ></v-text-field>
             </v-flex>

           </v-row>

           <v-row>
              <v-flex xs3 class="py-0 px-2 pr-2">
                {{lang.bankStatmentStart}}: <span style="font-weight:bold">{{startBS}}</span>
              </v-flex>

             <v-flex xs3 class="pr-2">
               {{lang.bankStatmentDebit}}: <span style="font-weight:bold">{{debitBS}}</span>
             </v-flex>

              <v-flex xs3 class="pr-2">
                {{lang.bankStatmentCredit}}: <span style="font-weight:bold">{{creditBS}}</span>
             </v-flex>

             <v-flex xs3 class="pr-2">
               {{lang.bankStatmentFinish}}: <span style="font-weight:bold">{{finishBS}}</span>
             </v-flex>

           </v-row>

            <v-divider class="mt-2"></v-divider>
                   
            <v-divider class="mt-2"></v-divider>

          <div class="pink darken-2" v-html="error" />

      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
import MainLedgerService from '@/services/MainLedgerService'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dayjs from 'dayjs'

export default {
  name: 'mainledgerview4',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      lang: {},
      langC: {},
      saving: false,
      menu: false,
      menu2: false,
      menu3: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      date1: null,
      date2: null,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      msg: '',
      valid: true,
      documentName: '',
      documentNameLoc: '',
      mainledger: { },
      mainledgerActiv: {},
      error: null,
      localError: false,
      newDocument: {},
      confpassword: '',
      size: 'sm',
      required: (value) => !!value || 'Required.',
      partners: [],
      employes: [],
      banks: [],
      currnecy: [],
      users: [],
      CompanyId: 0,
      selectPa: {},
      selectBank: {},
      selectCR: {},
      selectUs: {},
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
        documentName: '',
        MLType: 0
      },
      currnecyType: [
        { text: 'Kn', value: 'Kn' },
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      bookType: [],
      money: {
        prefix: '= ',
        suffix: ' ',
        precision: 3
      },
      price: 7.500,
      locCreatedAt: '',
      locUpdatedAt: '',
      locBookingDate: '',
      locInvoiceDate: '',
      locInvoicePaymentDeadlineDate: '',
      locBookID: 0,
      locCanUse: 0,
      locCantUse: 0,
      startBS: 0,
      debitBS: 0,
      creditBS: 0,
      finishBS: 0,
      bankAccount:''
    }
  },
  components: {
  },
  async mounted () {
    try {
      if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      const locDocument = await MainLedgerService.show(this.$store.state.mainledgerid)
      this.mainledger = locDocument.data.mainLedger

      this.$store.dispatch('setMainLedgerActiv', this.mainledger)
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      if (this.$store.state.companyid) {
        this.CompanyId = await this.$store.state.companyid
        this.mainQuery.CompanyId = await this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.BusinessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentActiv) {
        this.mainQuery.documentName = this.$store.state.documentActiv
      }
      if (this.$store.state.companyid) {
        // this.partners = (await PartnerService.index()).data.partners
        // console.log(this.partners)
        // console.log(this.tasktypes)
        // console.log(this.taskdepartments)
        // console.log(this.users)
      }
      this.locCreatedAt = dayjs(this.mainledger.createdAt).format('DD.MM.YYYY HH:MM')
      this.locUpdatedAt = dayjs(this.mainledger.updatedAt).format('DD.MM.YYYY HH:MM')
      this.locBookingDate = dayjs(this.mainledger.BookingDate).format('DD.MM.YYYY')
      this.locInvoiceDate = dayjs(this.mainledger.bankStatmentDate).format('DD.MM.YYYY')
      this.locInvoicePaymentDeadlineDate = dayjs(this.mainledger.InvoicePaymentDeadlineDate).format('DD.MM.YYYY')
      this.locBookID = parseInt(this.mainledger.bankStatmentNumber)
      this.locCanUse = parseFloat(this.mainledger.VAT1CanUse) + parseFloat(this.mainledger.VAT2CanUse) + parseFloat(this.mainledger.VAT3CanUse)
      this.locCantUse = parseFloat(this.mainledger.VAT1CanTUse) + parseFloat(this.mainledger.VAT2CanTUse) + parseFloat(this.mainledger.VAT3CanTUse)
      this.startBS = this.formatPrice(this.mainledger.bankStatmentStart)
      this.debitBS = this.formatPrice(this.mainledger.bankStatmentDebit)
      this.creditBS = this.formatPrice(this.mainledger.bankStatmentCredit)
      this.finishBS = this.formatPrice(this.mainledger.bankStatmentFinish)
      this.bankAccount = (this.$store.state.bankActiv && this.$store.state.bankActiv.BankName ? this.$store.state.bankActiv.BankName : '') + (this.mainledger.bankAccountNr ? ', ' + this.mainledger.bankAccountNr : '')
    } catch (error) {
      // eslint-disable-next-line
      console.log('greska kod monut dokumenta', error)
    }
  },
  computed: {
  },
  methods: {
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    close () {
      this.show = false
    },
    async mainledgeragain () {
      try {
        this.$forceUpdate()
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>

