<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
        <h3>
            <span v-if="this.$store.state.language == 'EN'">
            <h1>Employees</h1>
            </span>
            <span v-if="this.$store.state.language == 'HR'">
            <h1>Radnici</h1>
            </span>
        </h3>
      </v-col>
      <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
        <v-btn color="success" @click="empList2()">
            <span v-if="this.$store.state.language == 'EN'">
              List all Employees
            </span>
            <span v-if="this.$store.state.language == 'HR'">
              Lista SVIH djelatnika
            </span>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="text-right">
        <router-link to="/crm/employee/new" >
            <v-btn fab dark class="indigo">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
      <app-Employee-list></app-Employee-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Employeelist from './EmployeeList'
export default {
  name: 'employee',
  data () {
    return {
      msg: ''
    }
  },
  mounted () {

  },
  methods: {
    empList2 () {
      this.$store.dispatch('setretPath', 'employee')
      this.$router.push({
        name: 'employeelist2'
      })
    }
  },
  computed: {

  },
  watch: {

  },
  components: {
    appEmployeeList: Employeelist
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
