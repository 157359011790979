<template>
  <v-container fluid class="ma-2">
   <v-row no-gutters justify="center" class="pa-0">
    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">

    <v-row>
      <v-flex xs5>
          <h2>{{lang.PartnerDetails}}</h2>
        <br>
        <div class="danger-alert" v-html="error" />

      </v-flex>
       <v-flex xs5>
            <span v-if="this.$store.state.user.AccessLevel <= 3">
              <v-btn color="primary" @click="partnerEdit" class="mr-1">{{langC.Edit}}</v-btn>
            </span>
            <span v-if="this.$store.state.user.AccessLevel == 1 && admin">
              <v-btn color="red" @click="partnerDelete" class="mr-1">{{langC.Delete}}</v-btn>
            </span>
            <v-btn color="green" @click="partnerlist">{{langC.Back}}</v-btn>
        </v-flex>
    </v-row>

    <v-row>
         <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4">
            <v-card >
              <v-card-title primary class="title py-2">
                {{lang.BasicData}}
              </v-card-title>
              <v-card-text>
                <app-pres-row :label="lang.PartnerName" :info="partner.partnerName" />
                <app-pres-row :label="lang.PartnerNameShort" :info="partner.partnerNameShort" />
                <app-pres-row :label="lang.PartnerAdress1" :info="adress1" />
                <app-pres-row :label="lang.PartnerAdress2" :info="adress2" />
                <app-pres-row :label="lang.PartnerVATID" :info="partner.partnerVATID" />
                <app-pres-row :label="lang.Partneremail" :info="partner.partneremail" /> 
                <app-pres-row :label="lang.PartnerPhones" :info="partner.partnerPhones" />
                <app-pres-row :label="lang.PartnerBankName" :info="bank" />
                <app-pres-row :label="lang.PartnerBankIBAN" :info="partner.partnerBankIBAN" />
                <app-pres-row :label="lang.PartnerBankSWIFT" :info="partner.partnerBankSWIFT" />
              </v-card-text>
            </v-card>
         </v-col>

           <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4" v-if="!privateP">
            <v-card>
              <v-card-title primary class="title">
                {{lang.PartnerCEO}}
              </v-card-title>
              <v-card-text>
                <app-pres-row :label="lang.PartnerAdress2" :info="director" />
                <app-pres-row :label="lang.PartnerAdress2" :info="directorBorn" />
                <app-pres-row :label="lang.PartnerDirectorBusinessOccupation" :info="partner.partnerDirectorBusinessOccupation" />
                <app-pres-row :label="lang.PartnerAdress1" :info="adress3" />
                <app-pres-row :label="lang.PartnerAdress2" :info="adress4" />
                <app-pres-row :label="lang.PartnerDirectorEmail" :info="partner.partnerDirectorEmail" />
                <app-pres-row :label="lang.PartnerDirectorPhone" :info="partner.partnerDirectorPhone" />
                <app-pres-row :label="lang.PartnerDirectorContactOption" :info="partner.partnerDirectorContactOption" />
                
                <v-card-title primary class="title">
                  {{lang.PartnerSecretary}}
                </v-card-title>
                <app-pres-row :label="lang.PartnerAdress2" :info="contact" />
                <app-pres-row :label="lang.PartnerAdress2" :info="contactBorn" />
                <app-pres-row :label="lang.PartnerDirectorBusinessOccupation" :info="partner.partnerSecretaryBusinessOccupation" />
                <app-pres-row :label="lang.PartnerAdress1" :info="adress5" />
                <app-pres-row :label="lang.PartnerAdress2" :info="adress6" />
                <app-pres-row :label="lang.PartnerSecretaryEmail" :info="partner.partnerSecretaryEmail" />
                <app-pres-row :label="lang.PartnerSecretaryPhone" :info="partner.partnerSecretaryPhone" />
                <app-pres-row :label="lang.PartnerSecretaryContactOption" :info="partner.partnerSecretaryContactOption" />
              </v-card-text>
            </v-card>
          </v-col>

           <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4" v-if="!privateP">
            <v-card >
              <v-card-title primary class="title">
                {{lang.PartnerSettings}}
              </v-card-title>
              <v-card-text>
                <app-pres-row :label="lang.PartnerInvoiceMemo" :info="partner.partnerInvoiceMemo" />
                <app-pres-row :label="lang.PartnerInvoiceForeginMemo" :info="partner.partnerInvoiceForeginMemo" />
                <app-pres-row :label="lang.PartnerDeliveryNoteMemo" :info="partner.partnerDeliveryNoteMemo" />
                <app-pres-row :label="lang.PartnerDeliveryNoteForeginMemo" :info="partner.partnerDeliveryNoteForeginMemo" />
                <app-pres-row :label="lang.PartnerInvoiceDefDays" :info="partner.partnerInvoiceDefDays" />
                <app-pres-row :label="lang.PartnerInvoicePlace" :info="partner.partnerInvoicePlace" />
                <app-pres-row :label="lang.PartnerInvoiceType" :info="partner.partnerInvoiceType" />
                <app-pres-row :label="lang.PartnerInvoiceHint" :info="partner.partnerInvoiceHint" />
                <app-pres-row :label="lang.PartnerInvoiceSpeed" :info="partner.partnerInvoiceSpeed" />
                <app-pres-row3 :label="lang.PartnerUseRetailPrice" :info="partner.partnerUseRetailPrice" />
                <app-pres-row3 :label="lang.PartnerShowLastSaleBuyer" :info="partner.partnerShowLastSaleBuyer" />
                <app-pres-row3 :label="lang.PartnerShowWarehouseState" :info="partner.partnerShowWarehouseState" />
                <app-pres-row3 :label="lang.PartnerShowInputPrice" :info="partner.partnerShowInputPrice" />
                <app-pres-row3 :label="lang.PartnerVATonPay" :info="partner.partnerVATonPay" />
                <app-pres-row3 :label="lang.PartnerRetail" :info="partner.partnerRetail" />
                <app-pres-row :label="lang.PartnerDefaultWarehouse" :info="partner.partnerDefaultWarehouse" />
                <app-pres-row :label="lang.PartnerPrinterSize" :info="partner.partnerPrinterSize" />
                <app-pres-row :label="lang.PartnerInputFilesPath" :info="partner.partnerInputFilesPath" />
                <app-pres-row :label="lang.PartnerOutputFilesPath" :info="partner.partnerOutputFilesPath" />
                <app-pres-row :label="lang.PartnerTransferDataFilesPath" :info="partner.partnerTransferDataFilesPath" />
                <app-pres-row :label="lang.PartnerStartInvoiceNumber" :info="partner.partnerStartInvoiceNumber" />
                <app-pres-row :label="lang.PartnerStartBillNumber" :info="partner.partnerStartBillNumber" />
                <app-pres-row :label="lang.PartnerType" :info="partner.partnerType" />
                <app-pres-row :label="lang.PartnerStartFrom" :info="locStartFrom" />
                <app-pres-row3 :label="lang.PartnerActiv" :info="partner.Activ" />
                <app-pres-row3 :label="lang.privatePerson" :info="partner.privatePerson" />
                <app-pres-row3 :label="lang.specialCustomer" :info="partner.specialCustomer" />
                <app-pres-row3 :label="lang.PartnerLockedCredit" :info="partner.lockedCredit" />
                <app-pres-row3 :label="lang.PartnerlockedDebit" :info="partner.lockedDebit" /> 
                <app-pres-row :label="langC.CreatedAt" :info="locCreatedAt" />
                <app-pres-row :label="langC.UpdatedAt" :info="locUpdatedAt" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

      </v-col>
   </v-row>
  </v-container>
</template>

<script>
import PartnerService from '@/services/PartnerService'
import PresRow2 from '@/snippets/PressRow2'
import PresRow3 from '@/snippets/PressRow3'
import langEn from './PartnerDescEN'
import langHr from './PartnerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'

export default {
  name: 'partnerview',
  data () {
    return {
      msg: '',
      partner: {},
      locStartFrom: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locLastLogin: '',
      locForgotDate: '',
      haveRight: 4,
      error: '',
      lang: {},
      langC: {},
      privateP: true,
      admin: false,
      adress1: '',
      adress2: '',
      adress3: '',
      adress4: '',
      adress5: '',
      adress6: '',
      bank: '',
      director: '',
      directorBorn: '',
      contact: '',
      contactBorn: ''
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.user.Admin) {
        this.admin = this.$store.state.user.Admin
      }
    this.privateP = this.$store.state.user ? this.$store.state.user.privatePartner : true

      const query = {}
      query.partnerVATID = this.$store.state.partnerVATID ? this.$store.state.partnerVATID.toString() : null
      try {
        const dbPartner = await PartnerService.show(
          this.$store.state.partnerVATID.toString()
        )
        console.log('dbPartner ', dbPartner)
        this.partner = dbPartner && dbPartner.data && dbPartner.data.partner ?  dbPartner.data.partner: {}
        this.locStartFrom = dayjs(this.partner.StartFrom).format(
          'DD.MM.YYYY'
        )
        this.locCreatedAt = dayjs(this.partner.createdAt).format(
          'DD.MM.YYYY HH:MM'
        )
        this.locUpdatedAt = dayjs(this.partner.updatedAt).format(
          'DD.MM.YYYY HH:MM'
        )
      } catch (error) {
        this.error = error
      }
    

    this.bank = (this.partner.partnerBankName ? this.partner.partnerBankName : '') + (this.partner.partnerBankAddress ? ', ' + this.partner.partnerBankAddress : '')
    this.adress1 = (this.partner.partnerStreet ? this.partner.partnerStreet : '') + ' ' + (this.partner.partnerBuildingNameNumber ? this.partner.partnerBuildingNameNumber : '')
    this.adress2 = (this.partner.partnerPostcode ? this.partner.partnerPostcode : '') + ' ' + (this.partner.partnerCity ? this.partner.partnerCity : '') + (this.partner.partnerCountyRegion ? ', ' + this.partner.partnerCountyRegion : '') + (this.partner.partnerState ? ', ' + this.partner.partnerState : '') 

    this.director = (this.partner.partnerDirectorTitle ? this.partner.partnerDirectorTitle : '') + ' ' + (this.partner.partnerDirectorFirst ? this.partner.partnerDirectorFirst : '') + ' ' + (this.partner.partnerDirectorLast ? this.partner.partnerDirectorLast : '') + ' ' + (this.partner.partnerDirectorFormer ? this.partner.partnerDirectorFormer : '')
    this.directorBorn = (this.partner.partnerDirectorDateOfBirth ? this.partner.partnerDirectorDateOfBirth : '') + (this.partner.PartnerDirectorNationality ? ', ' + this.partner.PartnerDirectorNationality : '')
    this.adress3 = (this.partner.partnerDirectorAdressStreet ? this.partner.partnerDirectorAdressStreet : '') + ' ' + (this.partner.partnerDirectorAdressBuildingNameNumber ? this.partner.partnerDirectorAdressBuildingNameNumber : '')
    this.adress4 = (this.partner.partnerDirectorPostcode ? this.partner.partnerDirectorPostcode : '') + ' ' + (this.partner.partnerDirectorCity ? this.partner.partnerDirectorCity : '') + (this.partner.partnerDirectorCountyRegion ? ', ' + this.partner.partnerDirectorCountyRegion : '')+ (this.partner.partnerDirectorStateResidence ? ', ' + this.partner.partnerDirectorStateResidence : '') 

    this.contact = (this.partner.partneSecretaryTitle ? this.partner.partneSecretaryTitle : '') + ' ' + (this.partner.partneSecretaryFirst ? this.partner.partneSecretaryFirst : '') + ' ' + (this.partner.partneSecretaryLast ? this.partner.partneSecretaryLast : '') + ' ' + (this.partner.partneSecretaryFormer ? this.partner.partneSecretaryFormer : '')
    this.contactBorn = (this.partner.partnerSecretaryDateOfBirth ? this.partner.partnerSecretaryDateOfBirth : '') + (this.partner.PartnerSecretaryNationality ? ', ' + this.partner.PartnerSecretaryNationality : '')
    this.adress5 = (this.partner.partnerSecretaryAdressStreet ? this.partner.partnerSecretaryAdressStreet : '') + ' ' + (this.partner.partnerSecretaryAdressBuildingNameNumber ? this.partner.partnerSecretaryAdressBuildingNameNumber : '')
    this.adress6 = (this.partner.partnerSecretaryPostcode ? this.partner.partnerSecretaryPostcode : '') + ' ' + (this.partner.partnerSecretaryCity ? this.partner.partnerSecretaryCity : '') + (this.partner.partnerSecretaryCountyRegion ? ', ' + this.partner.partnerSecretaryCountyRegion : '')+ (this.partner.partnerSecretaryStateResidence ? ', ' + this.partner.partnerSecretaryStateResidence : '')

  },
  methods: {
    async partnerlist () {
      try {
        await this.$router.push({
          name: 'partner'
        })
      } catch (err) {
        this.error = err
      }
    },
    async partnerDelete () {
      try {
        await this.$router.push({
          name: 'partnerdelete'
        })
      } catch (error) {
        this.error = error
      }
    },
    async partnerEdit () {
      try {
        this.$store.dispatch('seteditItem', 1)
        await this.$router.push({
          name: 'partnercreate'
        })
      } catch (error) {
        this.error = error
      }
    }
  },
  computed: {},
  watch: {},
  components: {
    appPresRow: PresRow2,
    appPresRow3: PresRow3
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
