import store from '../../store/store'

const defSerials = () => {
  const defObj = {}
  defObj.CompanyId = 0
  defObj.CompanyNameDatabase = ''
  defObj.BusinessYear = 0
  defObj.UserId = 0
  defObj.email = ''
  defObj.documentName = null
  defObj.documentSide = null
  defObj.documentAnalysis = null
  defObj.documentId = null
  defObj.documentIdGeneral = null
  defObj.documentDate = null
  defObj.documentTime = null
  defObj.partnerId = null
  defObj.partnerNameDatabase = null
  defObj.HomeCurrency = null
  defObj.ForeignCurrency = null
  defObj.ExRate = null
  defObj.itemID = null
  defObj.itemName = null
  defObj.ItemNameShort = null
  defObj.ItemCode = null
  defObj.ItemEANCode = null
  defObj.itemGroup = null
  defObj.itemGroupName = null
  defObj.itemDescription = null
  defObj.itemUnit = null
  defObj.ItemWarehouse = null
  defObj.StartDate = null
  defObj.StartTime = null
  defObj.EndDate = null
  defObj.EndTime = null
  defObj.WareHouseUpdateBy = null
  defObj.FreeF1 = 0
  defObj.FreeF2 = 0
  defObj.FreeF3 = 0
  defObj.FreeF4 = 0
  defObj.FreeF5 = 0
  defObj.FreeF6 = 0
  return defObj
}

const defNewSerials = () => {
  const defaultObj = {}
  defaultObj.CompanyId = store.state.companyid ? store.state.companyid : 1
  defaultObj.CompanyNameDatabase = store.state.companyName ? store.state.companyName : ''
  defaultObj.BusinessYear = store.state.businessYear ? store.state.businessYear : 2020
  defaultObj.UserId = store.state.user.id ? store.state.user.id : ''
  defaultObj.email = store.state.user.email ? store.state.user.email : ''
  return defaultObj
}

export {
  defSerials,
  defNewSerials,
}