<template>
  <div class="mainledgerprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'

export default {
  name: 'mainledgerprintcurrent',
  props: ['card', 'type'],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0,
      partner: {}
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}

  },
  methods: {
    async print () {
      if (this.card) {
         await this.initDocument()
        this.addEmptyRow()
        this.addHeaderNoLogo()
        this.addLine()
        this.addHeader12()
        this.addLine()
        this.addItemsHeader()
        this.addLine()
        this.addItems()
        this.addLine()
        this.addItemsFoter(this.card.head.CreditTotal, this.card.head.DebitTotal, this.card.head.DiffTotal)
   
        this.docDefinition.content = this.content
        pdfMake.createPdf(this.docDefinition).open()
      }
      
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.content = []
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addLineDash () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1,
                dash: { length: 1 },
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '45%',
                text: [
                  { text: this.comp.CompanyNameShort + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              // {
              //   // % width
              //   width: '45%',
              //   text: [
              //     { text: 'Konto: ' + card.account4 + '\n' , fontSize: 12, bold: true},
              //     { text: '' + card.account4Description+ '\n', fontSize: 8},
              //   ]   
              // },
              {
                // % width
                width: '40%',
                text: [                 
                  { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeader12() {
      const locCreatedAt = dayjs(this.card.head.createdAt).format('DD.MM.YYYY HH:MM')
      const locUpdatedAt = dayjs(this.card.head.updatedAt).format('DD.MM.YYYY HH:MM')
      const locBookingDate = dayjs(this.card.head.BookingDate).format('DD.MM.YYYY')
      const locInvoiceDate = dayjs(this.card.head.InvoiceDate).format('DD.MM.YYYY')
      const locInvoicePaymentDeadlineDate = dayjs(this.card.head.InvoicePaymentDeadlineDate).format('DD.MM.YYYY')
      const locBookID = parseInt(this.card.head.BookID)
     // const locCanUse = parseFloat(this.card.head.VAT1CanUse) + parseFloat(this.card.head.VAT2CanUse) + parseFloat(this.card.head.VAT3CanUse)
     // const locCantUse = parseFloat(this.card.head.VAT1CanTUse) + parseFloat(this.card.head.VAT2CanTUse) + parseFloat(this.card.head.VAT3CanTUse)
      const docPart = {
        columns: [
          {
            // auto-sized columns have their widths based on their content
            width: '45%',
            text: [
              { text: 'Broj knjiženja:' + locBookID +  ' \n', fontSize: 10, bold: true },
              { text: 'Vrsta: ' + this.card.head.BookTypeID + ', ' + this.card.head.BookName + ' \n', fontSize: 8 },
              { text: 'Kreiran: ' + locCreatedAt + ', Izmjena:' + locUpdatedAt + ' \n', fontSize: 8 },
              { text: 'Korisnik: ' + this.card.head.First + ' ' + this.card.head.Last + ', ' + this.card.head.email + ' \n', fontSize: 8 },
            ]
          },
          // {
          //   // % width
          //   width: '45%',
          //   text: [
          //     { text: 'Konto: ' + card.account4 + '\n' , fontSize: 12, bold: true},
          //     { text: '' + card.account4Description+ '\n', fontSize: 8},
          //   ]   
          // },
          {
            // % width
            width: '40%',
            text: [                 
              { text: ' Datum knjiženja: ' + locBookingDate +  ' \n', fontSize:8},
              { text: ' Datum računa: ' + locInvoiceDate +  ' \n', fontSize:8},
              { text: ' Rok plaćanja: ' + locInvoicePaymentDeadlineDate +  ' \n', fontSize:8},
              { text: ' Partner: ' + this.card.head.partnerName +  ' \n', fontSize:8, bold: true},
              { text: ' Partner OIB: ' + this.card.head.partnerVATID +  ' \n', fontSize:8},
            ]   
          }

        ],
        // optional space between columns
        columnGap: 10
      }
    this.content.push(docPart)
    },
    addItemsHeader () {
      const docPart = {
        columns: [
              {
                width: '5%',
                fontSize: 8,
                alignment: 'left',
                text: 'Rb.'
              },
               {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: 'Konto'
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'right',
                text: 'Duguje'
              },
               {
                width: '9%',
                fontSize: 8,
                alignment: 'right',
                text: 'Potražuje'
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'right',
                text: 'Saldo'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: 'Broj URE'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: 'Broj IRE'
              },
              {
                width: '25%',
                fontSize: 7,
                alignment: 'center',
                text: 'Partner'
              },
              {
                width: '6%',
                fontSize: 7,
                alignment: 'center',
                text: 'Opis'
              },
            ]
           
      }
      this.content.push(docPart)   
    },
    addItem (crd) {
      if (this.type === 4) {
        const docPart01 = {
        columns: [
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: crd.Line + '. ' + crd.BookName
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'left',
                text: (crd.BookID ? parseInt(crd.BookID) : '')
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: dayjs(crd.BookingDate).format('DD.MM.YYYY')
              },
              {
                width: '12%',
                fontSize: 6,
                alignment: 'left',
                text: (crd.InvoiceNumber ? crd.InvoiceNumber : '')
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(crd.Debit)
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(crd.Credit)
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(crd.Total5)
              },
              {
                width: '1%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '17%',
                fontSize: 7,
                alignment: 'left',
                text: (crd.Description ? crd.Description.substr(0,25) : '')
              },
              {
                width: '18%',
                fontSize: 8,
                alignment: 'left',
                text: (crd.partnerName ? crd.partnerName.substr(0,20) : '')
              }
            ]
          }
        this.content.push(docPart01)
      }
      if (this.type === 6) {
        const docPart01 = {
        columns: [
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: crd.Line + '. ' + crd.BookName
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'left',
                text: (crd.BookID ? parseInt(crd.BookID) : '')
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: dayjs(crd.BookingDate).format('DD.MM.YYYY')
              },
              {
                width: '12%',
                fontSize: 6,
                alignment: 'left',
                text: (crd.InvoiceNumber ? crd.InvoiceNumber : '')
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(crd.Debit)
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(crd.Credit)
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(crd.Total5)
              },
              {
                width: '1%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '17%',
                fontSize: 7,
                alignment: 'left',
                text: (crd.Description ? crd.Description.substr(0,25) : '')
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: dayjs(crd.InvoiceDate).format('DD.MM.YYYY')
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: dayjs(crd.InvoicePaymentDeadlineDate).format('DD.MM.YYYY')
              },

            ]
          }
        this.content.push(docPart01)
      }

    },
    addItems () {
          console.log('add items card', this.card)
           this.order = 0           
            if (this.card.items && this.card.items.length > 0) {
              this.card.items.map(item => {
                  this.order += 1
                  const diff = parseFloat(item.Debit) - parseFloat(item.Credit)
                  // this.pTtt5 = item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0
                  // this.pTtt6 = item.itemDiscountPercentage1 ? this.formatPrice(item.itemDiscountPercentage1) : 0
                  // this.pTtt7 = item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0
                  // this.pTtt8 = item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0
                  // this.pTtt9 = item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0

                      const docPart01 = {
                      columns: [
                            {
                              width: '5%',
                              fontSize: 8,
                              alignment: 'left',
                              text: parseInt(this.order) + '.'
                            },
                            {
                              width: '10%',
                              fontSize: 8,
                              alignment: 'left',
                              text: item.account4
                            },
                            {
                              width: '9%',
                              fontSize: 8,
                              alignment: 'right',
                              text: this.formatPrice(item.Debit)
                            },
                            {
                              width: '9%',
                              fontSize: 8,
                              alignment: 'right',
                              text: this.formatPrice(item.Credit)
                            },
                            {
                              width: '9%',
                              fontSize: 8,
                              alignment: 'right',
                              text: this.formatPrice(diff)
                            },
                            {
                              width: '8%',
                              fontSize: 8,
                              alignment: 'right',
                              text: (item.invoiceInNumber ? item.invoiceInNumber : '')
                            },
                            {
                              width: '8%',
                              fontSize: 8,
                              alignment: 'right',
                              text: (item.invoiceOutNumber ? item.invoiceOutNumber : '')
                            },
                            {
                              width: '1%',
                              fontSize: 8,
                              alignment: 'right',
                              text:''
                            },
                            {
                              width: '25%',
                              fontSize: 8,
                              alignment: 'left',
                              text: ' ' + (item.partnerName ? item.partnerName.substring(0,15) : '') + (item.partnerVATID ? ' ,OIB:' + item.partnerVATID : '')
                            },
                            {
                              width: '7%',
                              fontSize: 7,
                              alignment: 'left',
                              text: item.Description
                            },
                          ]
                        }
                      this.content.push(docPart01)
                }
              )
          }
    },
    addItemsFoter (total1, total2, total5) {
       
      this.addLine()

      const docPart01 = {
           columns: [
              { 
                width: '5%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(total1)
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(total2)
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(total5)
              },
          ]
        }
      this.content.push(docPart01)
    },
  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>