import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('mainbook', {
      params: query
    })
  },
  indexlist(search) {
    return Api().get('mainbook/list', {
      params: search
    })
  },
  choose (query) {
    return Api().get('mainbook/choose', {
      params: query
    })
  },
  show (id) {
    return Api().get(`mainbook/${id}`)
  },
  post (newmainbook) {
    return Api().post('mainbook', newmainbook)
  },
  put (mainbook) {
    return Api().put(`mainbook/${mainbook.id}`, mainbook)
  },
  delete (id) {
    return Api().delete(`mainbook/${id}`)
  },
  saveitems (newitems) {
    return Api().post('mainbook/saveitems', newitems)
  },
  sumbyvat (query) {
    return Api().get('mainbook/sumbyvat', {
      params: query
    })
  },
}