import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('joppda/showall', {
      params: query
    })
  },
  indexlist(search) {
    return Api().get('joppda', {
      params: search
    })
  },
  choose (query) {
    return Api().get('joppda/choose', {
      params: query
    })
  },
  show (id) {
    return Api().get(`joppda/${id}`)
  },
  post (newjoppda) {
    return Api().post('joppda', newjoppda)
  },
  put (joppda) {
    return Api().put(`joppda/${joppda.id}`, joppda)
  },
  delete (id) {
    return Api().delete(`joppda/delete/${id}`)
  },
  delete2 (id) {
    return Api().delete(`joppda/delete2/${id}`)
  },
  saveitems (newitems) {
    return Api().post('joppda/saveitems', newitems)
  },
  createjoppd(data) {
    return Api().post(`joppda/createjoppd`, data)
  },
  uploadjoppd(query) {
    return Api().get(`uploadjoppd`, {
      params: query
    }, {
      responseType: 'blob'
    })
  },
}