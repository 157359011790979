<template>
  <div class="grey lighten-4">
 
    <v-container grid-list-md text-xs-center>
         <h2>Firma</h2>
      <v-row>

       <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4" class="pr-3">
        
          <h3 class="text-xs-left pb-3">Osnovni Podaci</h3>
           <v-row no-gutters>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="12"  lg="12" xl="12">
                <v-text-field
                    label="Company name:"
                    required
                    :rules="[required]"
                    v-model="company.CompanyName"
                    disabled
                    outlined
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="10"  lg="10" xl="10" class="pr-3">
                <v-text-field
                  label="Ulica:"
                  v-model="company.CompanyStreet"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                <v-text-field
                  label="Kbr:"
                  v-model="company.CompanyBuildingNameNumber"
                  outlined
                ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="pr-3">
                <v-text-field
                  label="Poštanski broj"
                  v-model="company.CompanyPostcode"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="5"  lg="5" xl="5" class="pr-3">
                <v-text-field
                  label="Grad"
                  v-model="company.CompanyCity"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                  label="Županija"
                  v-model="company.CompanyCountyRegion"
                  outlined
                ></v-text-field>
              </v-col>
          </v-row>
          
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="pr-3">
              <v-text-field
                label="Država"
                v-model="company.CompanyState"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="pr-3">
              <v-text-field
                label="OIB:"
                required
                :rules="[required]"
                v-model="company.CompanyVATID"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pr-3">
              <v-text-field
                label="email firme *:"
                required
                :rules="[required]"
                v-model="company.Companyemail"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pr-3">
              <v-text-field
                label="Telefon:"
                v-model="company.CompanyPhones"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                <v-text-field
                  label="Trasakcijski račun, oznaka posl.prostora:"
                  v-model="company.CompanyBusinessSpace"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                  <v-text-field
                    label="Transakcijski račun, oznaka napl.uređaja:"
                    v-model="company.CompanyBusinessDevice"
                    outlined
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
                 <v-checkbox
                 class="shrink mr-0 mt-0 ml-3"
                  label="Obrt"
                  v-model="company.showMainBook"
                ></v-checkbox>
            </v-col>
          </v-row>


          <br>
          <div class="danger-alert" v-html="error" />
          <br>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
              <v-btn
                dark
                class="blue mr-2"
                :disabled="!formIsValid"
                @click="register">
                Spremi
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
              <v-btn
                color="green"
                @click="compList">
                Natrag
              </v-btn>
            </v-col>
          </v-row>
      </v-col>



      <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4" class="pr-3">
        <v-row no-gutters>
          <h3 class="text-xs-left pb-3">Osobe</h3>
        </v-row>
        <v-row no-gutters>
          <h5 class="text-xs-left">Direktor firme</h5>
        </v-row>
          
          <v-row no-gutters>
            
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                <v-text-field
                  label="Titula"
                  v-model="company.CompanyDirectorTitle"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-3">
                <v-text-field
                  label="Ime"
                  v-model="company.CompanyDirectorFirst"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-3">
                <v-text-field
                  label="Prezime"
                  v-model="company.CompanyDirectorLast"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
                <v-text-field
                  label="Bivše prezime"
                  v-model="company.CompanyDirectorFormer"
                  outlined
                ></v-text-field>
              </v-col>
          </v-row>
          
          <v-row no-gutters>
              <h5 class="text-xs-left">Kontakt osoba/odgovorna osoba</h5>
          </v-row>
          

            <v-row no-gutters>
      
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                <v-text-field
                  label="Titula:"
                  v-model="company.CompanySecretaryTitle"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-3">
                <v-text-field
                  label="Ime:"
                  v-model="company.CompanySecretaryFirst"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-3">
                <v-text-field
                  label="Prezime:"
                  v-model="company.CompanySecretaryLast"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
                <v-text-field
                  label="Bivše prezime:"
                  v-model="company.CompanySecretaryFormer"
                  outlined
                ></v-text-field>
              </v-col>
          </v-row>

        </v-col>

        <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4" class="pr-3">
          <h3 class="text-xs-left pb-3">Postavke tvrtke</h3>
          <v-textarea
            label="Memo tekst na računu:"
            v-model="company.CompanyInvoiceMemo"
            outlined
          ></v-textarea>
          <v-textarea
            label="Memo tekst na izvoznom računu:"
            v-model="company.InvoiceForeginMemo"
            outlined
          ></v-textarea>
          <v-textarea
            label="Otpremnica memo tekst:"
            v-model="company.CompanyDeliveryNoteMemo"
            outlined
          ></v-textarea>
          <v-textarea
            label="Memo tekst na izvoznoj otpremnici:"
            v-model="company.CompanyDeliveryNoteForeginMemo"
            outlined
          ></v-textarea>

          <v-row dense>
            <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
              <v-text-field
                label="Opis za ispis na zaglavlju:"
                v-model="company.CompanyDescriptionUp"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
              <v-text-field
                label="Opis za ispis na podnožju:"
                v-model="company.CompanyDescriptionDown"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
                 <v-checkbox
                  label="Prikaži podnožje opis:"
                  v-model="company.showFooter1"
                ></v-checkbox>
            </v-col>
          </v-row>    

           <v-row dense>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                <v-text-field
                  label="Račun default broj dana plaćanja:"
                  v-model="company.CompanyInvoiceDefDays"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" >
                 <v-text-field
                  label="Mjesto izdavanja računa:"
                  v-model="company.CompanyInvoicePlace"
                  outlined
                ></v-text-field>
              </v-col>
          </v-row>

          <v-row dense>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                <v-text-field
                  label="Oznaka statistike/PDV:"
                  v-model="company.CompanyInvoiceType"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
                  <v-text-field
                    label="Kod statistike/PDV:"
                    v-model="company.CompanyInvoiceHint"
                    outlined
                  ></v-text-field>
              </v-col>
          </v-row>

           <v-row dense>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-3">
                <v-checkbox
                  class="shrink mr-0 mt-0 ml-3"
                  label="PDV po naplati:"
                  v-model="company.CompanyVATonPay"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" >
                  <v-checkbox
                    class="shrink mr-0 mt-0 ml-3"
                    label="Računaj na cijene SA PDVom:"
                    v-model="company.CompanyUseRetailPrice"
                  ></v-checkbox>
              </v-col>
          </v-row>
          
      

          <v-row no-gutters class="ma-0 pa-0">
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                <v-text-field
                  label="Kasa Oznaka Poslovnog prostora:"
                  v-model="company.CompanyBusinessSpaceRetail"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" >
                  <v-text-field
                    label="Kasa oznaka naplatanog uređaja:"
                    v-model="company.CompanyBusinessDeviceRetail"
                    outlined
                  ></v-text-field>
              </v-col>
          </v-row>

           <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                <v-text-field
                  label="Type:"
                  v-model="company.CompanyType"
                  type="number"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" >
                  <v-text-field
                    label="Retail space description:"
                    v-model="company.CompanyLogo5"
                    outlined
                  ></v-text-field>
              </v-col>
          </v-row>


        </v-col>

      </v-row>

       <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>
    </v-container>

  </div>
</template>

<script>
import CompService from '@/services/CompService'

export default {
  name: 'companycreateuser',
  data () {
    return {
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      menu: false,
      modal: false,
      modal1: false,
      dialog1: false,
      msg: '',
      newCompany: {},
      company: {},
      required: (value) => !!value || 'Required.',
      error: '',
      currencyType: [
        { text: 'Kn', value: 'Kn' },
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      account4s: [],
    }
  },
  async mounted () {
    try {
      const dbCompany = await CompService.show(this.$store.state.companyid)
      this.company = dbCompany.data.company

    } catch (error) {
      // eslint-disable-next-line
      console.log('greska kod monut dokumenta', error)
    }
  },
  methods: {
    async register () {
      try {

        await CompService.put(this.company)
        this.text = 'Company updated'
        this.color = 'green'
        this.snackbar = true

        this.$store.dispatch('seteditItem', 0)
        this.$router.push({
          name: 'appsettings'
        })
      } catch (error) {
        this.error = error.response.data.error
        this.text = 'Error on save'
        this.color = 'red'
        this.snackbar = true
      }
    },
    async compList () {
      try {
        await this.$router.push({
          name: 'appsettings'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  computed: {
    formIsValid () {
      return this.company.CompanyName !== '' &&
        this.company.CompanyNameShort !== '' &&
        this.company.CompanyNameDatabase !== '' &&
        this.company.CompanyVATID !== '' &&
        this.company.Companyemail !== ''
    }
  },
  watch: {
    'company.CompanyName' () {
      if (this.company.CompanyName) {
        this.company.CompanyNameDatabase =  this.company.CompanyName.replace(/[\W_]/g, '')
        this.company.CompanyNameShort = this.company.CompanyName
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
