<template>
  <div class="task">
      <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>

  <v-row>
    <v-col cols="12" sm="12" > 
      <v-card>
        <v-row align="center" class="list px-3 mx-auto">
          <v-col cols="4" sm="2">
            <v-select
              v-model="searchOption"
              :items="searchOptions"
              :label="langC.SearchBy"
              clearable
              @change="handleSearchOption"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field v-model="searchName" :label="langC.SearchBy"></v-text-field>
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn small @click="page = 1; defaultInit();">
              {{langC.Search}}
            </v-btn>
          </v-col>
          <v-col cols="5" sm="4">
            <v-pagination
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            ></v-pagination>
            
          </v-col>
          <v-col cols="4" sm="1" >
            Total:{{totalItems}}
          </v-col>
          <v-col cols="4" sm="1" >
            <v-select
              v-model="pageSize"
              :items="pageSizes"
              :label="langC.ItemsPerPage"
              @change="handlePageSizeChange"
            ></v-select>
        </v-col>
      </v-row>
     </v-card>
    </v-col>
  </v-row> 

  <v-row>
    <v-col cols="12" sm="12"> 
      <v-card>
        <v-card-title>
          {{ documentNameLoc }}
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="documentId"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"
            :mobile-breakpoint="0" 
          >

          <template v-slot:[`item.BookID`]="{ item }">
              <span>{{ parseInt(item.BookID) }}</span>
          </template>
          <template v-slot:[`item.ClosedAccount`]="{ item }">
            <span v-if="item.ClosedAccount"><v-icon>mdi-check</v-icon></span>
          </template>
          <template v-slot:[`item.InvoiceAmount`]="{ item }">
             <h3><span>{{ localMoney(item.InvoiceAmount) }}</span></h3>
          </template>
          <template v-slot:[`item.InvoiceForeginAmount`]="{ item }">
              <span>{{ localMoney(item.InvoiceForeginAmount) }}</span>
          </template>

          <template v-slot:[`item.BookingDate`]="{ item }">
              <span>{{ localDate(item.BookingDate) }}</span>
          </template>
          <template v-slot:[`item.InvoiceDate`]="{ item }">
              <span>{{ localDate(item.InvoiceDate) }}</span>
          </template>
          <template v-slot:[`item.MLFree2`]="{ item }">
            <span v-if="item.MLFree2"><v-icon>mdi-check</v-icon></span>
          </template>
          <template v-slot:[`item.paidClosed`]="{ item }">
            <span v-if="item.paidClosed"><v-chip color="green" dark>{{paid}}</v-chip></span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>
     </v-col>
    </v-row> 
  </div>
</template>

<script>
// import axios from 'axios'
import MainLedgerService from '@/services/MainLedgerService'
import MainLedgerDetailService from '@/services/MainLedgerDetailService'
import {checkBooks } from './mainledgerHelper'
import { mapGetters } from 'vuex'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import moneyFormat from '@/snippets/moneyFormat'

export default {
  name: 'MainLedgerlist',
  data () {
    return {
      documentName: '',
      documentNameLoc: '',
      docside: 0,
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        MLType: 1
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
      searchName: "",
      page: 1,
      totalPages: 0,
      pageSize: 30,
      pageSizes: [10, 30, 50],
      searchOption: '',
      searchOptions: [],
      totalItems: 0,
      bookTypeSelected: {},
      show: 0,
      paid: ''
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
    this.defaultInit()
  },
  methods: {
     localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    async defaultInit() {
      this.$store.dispatch('seteditItem', 0)
      this.headers = []
      this.searchOptions = []
      this.paid = this.lang.PaidClosed
     
      if (!this.$store.state.bookTypeSelected) {
        await this.$store.dispatch('setbookTypeSelected', null)
        this.show = this.$store.state.documentSide ? this.$store.state.documentSide : 1
        await checkBooks(this.show)
      }      
      const query = this.setQuery()
      if (query.searchItem && this.searchName) {
        query.searchName =  this.searchName.trim()
        await this.$store.dispatch('setOnPage', query)
      }
      if (query.searchItem && query.searchName) {
        this.searchOption = query.searchItem
        this.searchName = query.searchName 
      }
      this.saving = true

      await MainLedgerService.indexlist(query)
      .then(res => {
        this.items = res.data.documents ? res.data.documents : []
        this.totalItems = res.data.totalPages
        this.totalPages = parseInt(res.data.totalPages / this.pageSize) + 1
      })
      .catch(err => {
        this.havealert = !this.havealert
        this.havemssgalert = 'Error' + err
      })
      this.saving = false
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      if (this.$store.state.documentSide === 1) {
        this.headers.push(
          {
            text: this.lang.BookID, align: 'left', sortable: true, value: 'BookID', width: '60px'
          },
          { text: this.lang.ClosedAccount, align: 'left', sortable: true, value: 'ClosedAccount', width: '50px' },
          { text: this.lang.BookTypeID, align: 'left', sortable: true, value: 'BookName', width: '50px' },
          { text: this.lang.BookingDate, align: 'left', sortable: true, value: 'BookingDate', width: '80px' },
          { text: this.lang.InvoiceNumber, align: 'center', value: 'InvoiceNumber', width: '100px' },
          { text: this.lang.InvoiceDate, align: 'left', sortable: true, value: 'InvoiceDate', width: '80px' },
          { text: this.lang.InvoiceAmount, align: 'right', sortable: true, value: 'InvoiceAmount', width: '100px' },
          { text: this.lang.HomeCurrency, align: 'left', sortable: true, value: 'HomeCurrency', width: '40px' },
          { text: this.lang.InvoiceForeginAmount, align: 'right', sortable: true, value: 'InvoiceForeginAmount', width: '100px' },
          { text: this.lang.ForeignCurrency, align: 'left', sortable: true, value: 'ForeignCurrency', width: '40px' },
          { text: this.lang.PartnerName, align: 'left', value: 'partnerName', width: '260px' },
          { text: this.lang.PartnerVATID, align: 'left', value: 'partnerVATID', width: '80px' },
          { text: this.lang.ConnName, align: 'left', value: 'ConnName', width: '80px' },
          { text: this.lang.ConnId, align: 'left', value: 'ConnId', width: '80px' },
          { text: this.lang.PaidClosed, align: 'left', value: 'paidClosed', width: '80px' },
          { text: this.lang.MLFree2, align: 'left', value: 'MLFree2', width: '80px' },
          { text: 'DbId', value: 'id', width: '60px' }
        )
        this.searchOptions.push(
          {
            text: this.lang.BookID, align: 'left', sortable: true, value: 'BookID', width: '60px'
          },
          { text: this.lang.BookTypeID, align: 'left', sortable: true, value: 'BookName', width: '50px' },
          { text: this.lang.InvoiceNumber, align: 'center', value: 'InvoiceNumber', width: '100px' },
          { text: this.lang.InvoiceAmount, align: 'right', sortable: true, value: 'InvoiceAmount', width: '100px' },
          { text: this.lang.InvoiceForeginAmount, align: 'right', sortable: true, value: 'InvoiceForeginAmount', width: '100px' },
          { text: this.lang.PartnerName, align: 'left', value: 'partnerName', width: '260px' },
          { text: this.lang.PartnerVATID, align: 'left', value: 'partnerVATID', width: '80px' },
          { text: this.lang.ConnName, align: 'left', value: 'ConnName', width: '80px' },
          { text: this.lang.ConnId, align: 'left', value: 'ConnId', width: '80px' },
        )
      }
      if (this.$store.state.documentSide === 2) {
        this.headers.push(
          {
            text: this.lang.BookID2, align: 'left', sortable: true, value: 'BookID', width: '60px'
          },
          { text: this.lang.ClosedAccount, align: 'left', sortable: true, value: 'ClosedAccount', width: '50px' },
          { text: this.lang.BookTypeID, align: 'left', sortable: true, value: 'BookName', width: '50px' },
          { text: this.lang.BookingDate, align: 'left', sortable: true, value: 'BookingDate', width: '80px' },
          { text: this.lang.InvoiceDate, align: 'left', sortable: true, value: 'InvoiceDate', width: '80px' },
          { text: this.lang.InvoiceAmount, align: 'right', sortable: true, value: 'InvoiceAmount', width: '100px' },
          { text: this.lang.HomeCurrency, align: 'left', sortable: true, value: 'HomeCurrency', width: '40px' },
          { text: this.lang.InvoiceForeginAmount, align: 'right', sortable: true, value: 'InvoiceForeginAmount', width: '100px' },
          { text: this.lang.ForeignCurrency, align: 'left', sortable: true, value: 'ForeignCurrency', width: '40px' },
          { text: this.lang.PartnerName, align: 'left', value: 'partnerName', width: '260px' },
          { text: this.lang.PartnerVATID, align: 'left', value: 'partnerVATID', width: '80px' },
          { text: this.lang.ConnName, align: 'left', value: 'ConnName', width: '80px' },
          { text: this.lang.ConnId, align: 'left', value: 'ConnId', width: '80px' },
          { text: this.lang.PaidClosed, align: 'left', value: 'paidClosed', width: '80px' },
          { text: this.lang.MLFree2, align: 'left', value: 'MLFree2', width: '80px' },
          { text: 'DbId', value: 'id', width: '60px' }
        )
        this.searchOptions.push(
          {
            text: this.lang.BookID2, align: 'left', sortable: true, value: 'BookID', width: '60px'
          },
          { text: this.lang.BookTypeID, align: 'left', sortable: true, value: 'BookName', width: '50px' },
          { text: this.lang.InvoiceAmount, align: 'right', sortable: true, value: 'InvoiceAmount', width: '100px' },
          { text: this.lang.InvoiceForeginAmount, align: 'right', sortable: true, value: 'InvoiceForeginAmount', width: '100px' },
          { text: this.lang.PartnerName, align: 'left', value: 'partnerName', width: '260px' },
          { text: this.lang.ConnName, align: 'left', value: 'ConnName', width: '80px' },
          { text: this.lang.PartnerVATID, align: 'left', value: 'partnerVATID', width: '80px' },
          { text: this.lang.ConnId, align: 'left', value: 'ConnId', width: '80px' },
        )
      }
      if (this.$store.state.documentSide === 3 || this.$store.state.documentSide === 4) {
        this.headers.push(
          {
            text: this.lang.BookID, align: 'left', sortable: true, value: 'BookID'
          },
          { text: this.lang.ClosedAccount, align: 'left', sortable: true, value: 'ClosedAccount' },
          { text: this.lang.BookTypeID, align: 'left', sortable: true, value: 'BookName' },
          { text: this.lang.BookingName, align: 'left', sortable: true, value: 'BookName' },
          { text: this.lang.BookingDate, align: 'left', sortable: true, value: 'BookingDate' },
          { text: this.lang.InvoiceNumber, align: 'center', value: 'InvoiceNumber' },
          { text: this.lang.MLFree1, align: 'left', sortable: true, value: 'MLFree1' },
          { text: this.lang.DebitTotal, align: 'left', sortable: true, value: 'DebitTotal' },
          { text: this.lang.CreditTotal, align: 'left', sortable: true, value: 'CreditTotal' },
          { text: 'DbId', value: 'id' }
        )
        this.searchOptions.push(
          {
            text: this.lang.BookID, align: 'left', sortable: true, value: 'BookID', width: '60px'
          },
          { text: this.lang.BookTypeID, align: 'left', sortable: true, value: 'BookName', width: '50px' },
          { text: this.lang.BookingName, align: 'left', sortable: true, value: 'BookName' },
          { text: this.lang.InvoiceNumber, align: 'center', value: 'InvoiceNumber' },
          { text: this.lang.MLFree1, align: 'left', sortable: true, value: 'MLFree1' },
          { text: this.lang.DebitTotal, align: 'left', sortable: true, value: 'DebitTotal' },
          { text: this.lang.CreditTotal, align: 'left', sortable: true, value: 'CreditTotal' },
        )
      }
    },
    async navigateTo (params) {
      await this.$store.dispatch('setMainLedgerTotals', 0)
      await this.$store.dispatch('seteditItem', 0)
      this.$store.dispatch('setretPath', 'mainledger')
      await this.$store.dispatch('setMainLedgerid', params.id)
      const locDocument = await MainLedgerService.show(params.id)
      this.mainledger = locDocument.data.mainLedger
      this.mldetails = (await MainLedgerDetailService.index(params.id)).data.mainledgerDetails
      await this.$store.dispatch('setMainLedgerActiv', this.mainledger ? this.mainledger : {})
      await this.$store.dispatch('setMainLedgerItems', this.mldetails ? this.mldetails : [])
      this.bookTypeSelected.text = this.mainledger.BookName
      this.bookTypeSelected.value = parseInt(this.mainledger.BookTypeID)
      await this.$store.dispatch('setbookTypeSelected', this.bookTypeSelected)
      this.$router.push({
        name: 'mainledgerview'
      })
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    // handlePageChange(value) {
    //   this.page = value;
    //   this.defaultInit();
    // },
    // handlePageSizeChange(size) {
    //   this.pageSize = size;
    //   this.page = 1;
    //   this.defaultInit();
    // },
    // handleSearchOption(option) {
    //   this.searchOption = option
    // },
    // setQuery() {
    //   const query = {}
    //   if (this.$store.state.companyid) {
    //     query.CompanyId = this.$store.state.companyid
    //   }
    //   if (this.$store.state.businessYear) {
    //     query.BusinessYear = this.$store.state.businessYear
    //   }
    //   if (this.$store.state.documentActiv) {
    //     query.documentName = this.$store.state.documentActiv
    //   }
    //   if (this.$store.state.documentSide) {
    //     this.docSide = this.$store.state.documentSide
    //     query.MLType = this.$store.state.documentSide
    //   }
    //   // if (this.$store.state.bookTypeSelected) {
    //   //   query.BookTypeID = this.$store.state.bookTypeSelected.value
    //   // }
    //   query.searchName = this.searchName ? this.searchName.trim() : null
    //   query.page = this.page ? this.page - 1 : 0
    //   query.pageSize = this.pageSize ? this.pageSize : 30
    //   query.searchItem = this.searchOption ? this.searchOption : null
    //   return query
    // },
    async handlePageChange(value) {      
      const query = this.$store.state.onPage
      query.page = value - 1;
      this.page = value
      await this.$store.dispatch('setOnPage', query)
      this.defaultInit();
    },

    async handlePageSizeChange(size) {
      const query = this.$store.state.onPage
      query.pageSize = size;
      query.page = 1;
      this.pageSize = size
      await this.$store.dispatch('setOnPage', query)
      this.defaultInit();
    },

    async handleSearchOption(option) {
      this.searchOption = option
      const query = this.$store.state.onPage
      query.searchItem = option
      query.page = 0
      await this.$store.dispatch('setOnPage', query)
    },

    setQuery() {
      const haveOnPage = this.$store.state.onPage
      const query = {}
      if (this.$store.state.companyid) {
        query.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        query.BusinessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentActiv) {
        query.documentName = this.$store.state.documentActiv
      }
      if (this.$store.state.bookTypeSelected) {
        query.BookTypeID = this.$store.state.bookTypeSelected.value ? this.$store.state.bookTypeSelected.value : ''
      }
      query.searchName = haveOnPage.searchName ? haveOnPage.searchName.trim() : null
      query.page = haveOnPage.page ? haveOnPage.page : 0
      this.page  = query.page + 1
      query.pageSize = haveOnPage.pageSize ? haveOnPage.pageSize : 30
      this.pageSize = query.pageSize
      query.searchItem = haveOnPage.searchItem ? haveOnPage.searchItem : null
      return query
    },
    async defaultQuery() {
      const query = {}
      if (this.$store.state.companyid) {
        query.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        query.BusinessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentActiv) {
        query.documentName = this.$store.state.documentActiv
      }
      if (this.$store.state.bookTypeSelected) {
        query.BookTypeID = this.$store.state.bookTypeSelected.value ? this.$store.state.bookTypeSelected.value : ''
      }
      query.searchName = null
      query.page =  0
      query.pageSize =  30
      query.searchItem =  null
      await this.$store.dispatch('setOnPage', query)
      this.searchName = ""
      this.page = 1
      this.totalPages = 0
      this.pageSize = 30
      this.mainQuery = query
    },
    async initAgain () {
      this.defaultQuery()
      this.defaultInit()
    }
  },
  computed: {
    ...mapGetters({
      myDocumentActivState: 'NeedDocumentActiv',
      myDocumentSide: 'NeedDocumentSide',
      myBookSelected: 'NeedMainLedgerBookTypeSelected'
    })
  },
  watch: {
    'myDocumentActivState' () {
      this.defaultInit()
      //this.initAgain(this.myDocumentActivState)
    },
    'myBookSelected' () {
      console.log('watch default init myBookSelected')
      this.defaultInit()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
