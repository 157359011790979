<template>
<div>
  <v-container fluid grid-list-md>
  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-row>
  <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" class="pr-1">
  <v-data-table
    dense
    :headers="headers"
    :items="toVerify"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="50"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Import eRacuni datoteke u IRE</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

        <v-spacer></v-spacer>
          <v-btn 
            class="primary mr-1"
            @click="saveToBooks" >
            PROKNJIŽI
          </v-btn>

        <v-dialog v-model="dialog" max-width="700px">

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>


          <v-card>
            <v-card-title>
              <span class="headline">Izlazni racun</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >

                  <v-row>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        label="Ira"
                        v-model="editedItem.irabroj"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        label="Ukupno"
                        v-model.number="editedItem.InvoiceAmount"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        label="PDV"
                        v-model.number="editedItem.VAT1Amount"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        label="Osnovica"
                        v-model.number="editedItem.VAT1Base"
                        type="number"
                      ></v-text-field>
                    </v-col>

                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8" class="pr-1">
                     <v-text-field
                        dense
                        label="Partner eRacun"
                        v-model="editedItem.partnerIm"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="mt-2 pr-1">
                      <v-btn color="blue darken-1" text @click="saveNewPartner">Snimi partnera</v-btn>
                    </v-col>
                  </v-row>

                  <v-row>
                     <v-col cols="12" sm="8" md="8" lg="8" xl="8">
                      <v-autocomplete
                        :items="partners"
                        v-model="selectPartner"
                        label="Partner"
                        item-text="partnerName"
                        item-value="id"
                        return-object
                        persistent-hint
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="mt-2 pr-1">
                     <v-text-field
                        dense
                        label="OIB"
                        v-model="editedItem.partnerVATID"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-checkbox
                        v-model="editedItem.partnerOK"
                        label="Uskladjeni"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">

                    </v-col>
                  </v-row>
                  


                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn color="success" text @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.InvoiceAmount`]="{ item }">
      <h3><span>{{ localMoney(item.InvoiceAmount) }}</span></h3>
    </template>
    <template v-slot:[`item.fa_datum`]="{ item }">
        <span>{{ localDate(item.fa_datum) }}</span>
    </template>
    <template v-slot:[`item.partnerOK`]="{ item }">
      <span v-if="item.partnerOK"><v-icon>mdi-check</v-icon></span>
    </template>
    <template v-slot:[`item.saved`]="{ item }">
      <span v-if="item.saved"><v-icon>mdi-check</v-icon></span>
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      Datoteka za import je prazna
    </template>
  </v-data-table>
  </v-col>
  </v-row>
   <div v-if="showDialog">
      <JustWait :message="message" :showDialog="showDialog" />
    </div>
  </v-container>
  </div>
</template>

<script>
import MainLedgerService from '@/services/MainLedgerService'
import PartnerService from '@/services/PartnerService'
import langEn from './MainLedgerDescEN'
import langHr from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import moneyFormat from '@/snippets/moneyFormat'
import {parseNum} from '@/snippets/allSnippets'
import { defNewpartner,  defMainLedger, defNew, } from './mainledgerHelper'
import { testLastUpdate, newUpdates } from '@/services/TestLastUpdate'
import JustWait from '../../snippets/JustWait'

  export default {
    name: 'mainledgerimportexcel',
    props : ['toVerify'],
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      modal1: false,
      dialog1: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: "",
        databaseDocID: 0
      },
      dialog: false,
      headers: [],
      editedIndex: -1,
      editedItem: { },
      defaultItem: {
        irabroj: null,
        datum: null,
        InvoiceAmount: 0,
        VAT1Amount: 0,
        VAT1Base: 0,
        partnerIm: '',
        partnerName: '',
        partnerVATID: '',
        partnerOK: false,
        saved: false
      },
      privitems: [],
      valid: true,
      RecTrue: true,
      ExpTrue: false,
      partners: [],
      selectPartner: {},
      mainledger: {},
      newPartners: [],
      bookTypeSelected: {},
      oldPartners: 0,
      allHeads: [],
      savedInv: 0,
      message: '',
      showDialog: false
    }),

    computed: {

    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      'selectPartner' (partner) {
        if (partner) {
        this.editedItem.partnerName = partner.partnerName
        this.editedItem.partnerVATID = partner.partnerVATID
        this.editedItem.partnerOK = true
        //this.choosedComp(comp.id)
        }
        if (!partner) {
          this.editedItem.partnerName = null
          this.editedItem.partnerVATID = null
          this.editedItem.partnerOK = false
        }
      },
    },
    components: {
      JustWait
    },

    mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      this.message = this.langC.JustWait
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      this.partners = this.$store.state.partnerStore ? this.$store.state.partnerStore : []
      this.bookTypeSelected = this.$store.state.bookTypeSelected

      this.headers.push(
        {text: 'IRA', value: 'originalBroj', width: '130px'},
        {text: 'Datum', value: 'fa_datum', width: '100px'},
        {text: 'IRA', value: 'irabroj', width: '100px'},
        {text: 'Iznos UK', value: 'InvoiceAmount', width: '100px'},
        {text: 'PDV 25%', value: 'VAT1Amount', sortable: false, width: '100px' },
        {text: 'PDV 13%', value: 'VAT2Amount', sortable: false, width: '100px' },
        {text: 'PDV 5%', value: 'VAT3Amount', sortable: false, width: '100px' },
        {text: 'eRacun Partner', value: 'partnerIm', sortable: true, width: '200px' },
        {text: 'Partner', value: 'partnerName', sortable: true, width: '200px' },
        {text: 'OIB', value: 'partnerVATID', sortable: true, width: '100px' },
        {text: 'Uskladjeni', value: 'partnerOK', sortable: true, width: '50px' },
        {text: 'Snimljeno', value: 'saved', sortable: false, width: '50px' },
        {text: this.lang.Actions, value: 'action', sortable: false,  width: '90px' },
        {text: 'id', value: 'id', width: '50px'},
      ) 
      
    //  this.defaultItem = {...defMainLedgerProjectType(), ...defNewMainLedgerProjectType()}
    },

    created () {
      this.initialize()
    },

    methods: {
      async saveToBooks() {
        this.showDialog = true
        this.savedInv = 0
        this.newPartners = []
        this.oldPartners = 0
        this.allHeads = []
        await this.checkPartners()
        await this.savePartners()
        await this.refreshAllPartners()
        await this.createAllHeads()
        await this.saveAllHead()
        this.showDialog = false
        alert('Snimljeno racuna u knjigu IRA ' + this.savedInv )
      },
      localMoney(moneyToFormat) {
        return moneyFormat(moneyToFormat)
      },
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      async saveNewPartner(){
        try {

          const haveIt = this.partners.find(partner => partner.partnerVATID ===   this.editedItem.partnerVATID)
          if (haveIt) {
            alert('Partner sa tim OIBm postoji u bazi!! ' + haveIt.partnerName)
          } else {
            const addeditPartner = {...defNewpartner()}
            addeditPartner.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
            addeditPartner.email = this.$store.state.user.email ? this.$store.state.user.email : ''
            addeditPartner.First = this.$store.state.user.First ? this.$store.state.user.First : ''
            addeditPartner.Last = this.$store.state.user.Last ? this.$store.state.user.Last : ''
            addeditPartner.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
            addeditPartner.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
            addeditPartner.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
            addeditPartner.partnerName = this.editedItem.partnerIm
            addeditPartner.partnerNameShort = this.editedItem.partnerIm.replace(/[\W_]/g, '')
            addeditPartner.partnerNameDatabase = this.editedItem.partnerIm.replace(/[\W_]/g, '')
            addeditPartner.partnerVATID = this.editedItem.partnerVATID
            this.editedItem.partnerName = this.editedItem.partnerIm
            this.editedItem.partnerOK = true
            const res = await PartnerService.post(addeditPartner)
            console.log('Novi partner', res)
            alert('Novi partner upisan u bazu ')
            await this.refreshAllPartners()
            this.selectPartner = this.partners.find(partner => partner.partnerVATID === this.editedItem.partnerVATID)
          }
        }
        catch(err) {
          alert('Greska kod snimanja partnera ' + err)
        }
      },
      async initialize () {
        try {
          // this.saving = true
          // this.toVerify = (await MainLedgerProjectTypeService.index(this.mainQuery)).data.mainledgerprojecttype
          // this.saving = false
          this.errorMatch = false
          this.selectPartner = {}
          this.editedItem = {...this.defaultItem}
        } catch (err) {
          this.error = err
        }
      },
      editItem (item) {
        this.errorMatch = false
        this.editedIndex = this.toVerify.indexOf(item)
        this.editedItem = Object.assign({}, item)
        if (this.editedItem.partnerName) {
          this.selectPartner = this.partners.find(partner => partner.partnerVATID === this.editedItem.partnerVATID)
        }
        this.dialog = true
        this.confpassword = ''
      },

      async deleteItem (item) {
        try {
          const index = this.toVerify.indexOf(item)
          const warningMessage = item.TypeName
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.toVerify.splice(index, 1)) {
            delete this.toVerify[index]
            this.initialize()
          }
        } catch(err) {
          this.error = err
        }
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = {}
          this.editedIndex = -1
          this.selectPartner = {}
        }, 300)
      },

      checkMatch () {
        if (this.editedItem.password && this.confpassword &&  this.confpassword === this.editedItem.password) {
          this.errorMatch = null
          this.save()
        } else {
          this.errorMatch = 'Error. Passoword and Confirmation Password are NOT equal!'
          this.valid = false        
        }
      },

      async save () {
        try {
          if (this.editedIndex > -1) {

            if (this.editedItem.partnerVATID && this.editedItem.partnerVATID.length !== 11){
              alert('Upozorenje!! OIB NEMA 11 znakova!! Upisano znakova: ' + this.editedItem.partnerVATID.length )
            }
            this.toVerify.splice(this.editedIndex, 1, this.editedItem)
          }
          this.close()
        } catch(err) {
          this.error = err
        }
       
      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      },
      async refreshAllPartners() {
        await newUpdates(2)
        await testLastUpdate()
      },
      async savePartners() {
        if (this.newPartners.length > 0) {
          this.newPartners.map(async(partner) => {
            await PartnerService.post(partner)
          })
        }
      },
      async checkPartners() {
        try {
          this.toVerify.map(head => {
            if (head.partnerOK) {
              if (head.partnerVATID && head.partnerVATID > 5) {
                const newObj = {}
                newObj.oib = head.partnerVATID
                newObj.firma = head.partnerName
                const haveIt = this.partners.find(partner => partner.partnerVATID === head.partnerVATID)
                if (haveIt) {
                  this.oldPartners += 1
                } else {
                  const addeditPartner = {...defNewpartner()}
                  addeditPartner.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
                  addeditPartner.email = this.$store.state.user.email ? this.$store.state.user.email : ''
                  addeditPartner.First = this.$store.state.user.First ? this.$store.state.user.First : ''
                  addeditPartner.Last = this.$store.state.user.Last ? this.$store.state.user.Last : ''
                  addeditPartner.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
                  addeditPartner.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
                  addeditPartner.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
                  addeditPartner.partnerName = head.partnerIm
                  addeditPartner.partnerNameShort = head.partnerIm.replace(/[\W_]/g, '')
                  addeditPartner.partnerNameDatabase = head.partnerIm.replace(/[\W_]/g, '')
                  addeditPartner.partnerVATID = head.partnerVATID
                  this.newPartners.push(addeditPartner)
                }
              }
             }
            })
        }
        catch(err) {
          console.log('Error checkPartners', err)
        }
      },
      async createAllHeads() {
        try {
          const query = {}
          if (this.$store.state.companyid) {
            query.CompanyId = this.$store.state.companyid
          }
          if (this.$store.state.businessYear) {
            query.BusinessYear = this.$store.state.businessYear
          }
          if (this.$store.state.documentActiv) {
            query.documentName = this.$store.state.documentActiv
          }
          if (this.$store.state.bookTypeSelected) {
            query.BookTypeID = this.$store.state.bookTypeSelected.value ? this.$store.state.bookTypeSelected.value : ''
          }

          const res = await MainLedgerService.index(query)

          const allInv = res && res.data && res.data.documents ? res.data.documents : []

          if (this.toVerify && this.toVerify.length > 0) {
            this.toVerify.map(item => {
              if (item.partnerOK) {

                const ifIndex = this.toVerify.indexOf(item)
                this.toVerify[ifIndex].saved = true

                const documentIdGeneral = item.documentIdGeneral
                const haveIT = allInv.find(inv => inv.documentIdGeneral === documentIdGeneral)

                if (!haveIT) {
                  const newObj = {}
                  this.mainledger = {...newObj}
                  this.mainledger = defMainLedger()
                  this.mainledger = {...this.mainledger, ...defNew()}
                  this.firstNew += 1
                  this.mainledger.BookID = item.irabroj
                  this.mainledger.documentIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-' + this.$store.state.documentActiv + '-' + item.irabroj 
                  const locPartner = this.partners.find(partner => partner.partnerVATID === item.partnerVATID)
                  if (locPartner) {
                    this.mainledger.partnerId = locPartner.id
                    this.mainledger.partnerName = locPartner.partnerName
                    this.mainledger.partnerNameShort = locPartner.partnerNameShort
                    this.mainledger.partnerNameDatabase = locPartner.partnerNameDatabase
                    this.mainledger.partnerBuildingNameNumber = locPartner.partnerBuildingNameNumber
                    this.mainledger.partnerStreet = locPartner.partnerStreet
                    this.mainledger.partnerCity = locPartner.partnerCity
                    this.mainledger.partnerPostcode = locPartner.partnerPostcode
                    this.mainledger.partnerCountyRegion = locPartner.partnerCountyRegion
                    this.mainledger.partnerState = locPartner.partnerState
                    this.mainledger.partnerVATID = locPartner.partnerVATID
                    this.mainledger.partneremail = locPartner.partneremail
                    this.mainledger.partnerPhones = locPartner.partnerPhones
                    this.mainledger.partnerBankName = locPartner.partnerBankName
                    this.mainledger.partnerBankAddress = locPartner.partnerBankAddress
                    this.mainledger.partnerBankIBAN = locPartner.partnerBankIBAN
                    this.mainledger.partnerBankSWIFT = locPartner.partnerBankSWIFT
                  }
                  this.mainledger.InvoiceNumber = item.irabroj
                  this.mainledger.BookingDate = item.fa_datum
                  this.mainledger.InvoiceDate = item.fa_datum
                  this.mainledger.InvoicePaymentDeadlineDate = item.racrokpl
                  this.mainledger.BookTypeID = parseInt(this.bookTypeSelected.value)
                  this.mainledger.BookName = this.bookTypeSelected.text
                  this.mainledger.ConnId = parseInt(item.irabroj)
                  this.mainledger.InvoiceAmount = item.InvoiceAmount && item.InvoiceAmount !== 0 ? parseNum(item.InvoiceAmount, 2) : 0
                  this.mainledger.InvoiceForeginAmount =  this.mainledger.InvoiceAmount / 7.5345
                  this.mainledger.VAT1Base = item.VAT1Base  ? item.VAT1Base  : 0
                  this.mainledger.VAT1Amount = item.VAT1Amount ? item.VAT1Amount : 0
                  this.mainledger.VAT1PerCanUse = 100
                  this.mainledger.VAT1CanUse = item.VAT1Amount ? item.VAT1Amount : 0

                  this.mainledger.VAT2Base = item.VAT2Base  ? item.VAT2Base  : 0
                  this.mainledger.VAT2Amount = item.VAT2Amount ? item.VAT2Amount : 0
                  this.mainledger.VAT2PerCanUse = 100
                  this.mainledger.VAT2CanUse = item.VAT2Amount ? item.VAT2Amount : 0

                  this.mainledger.VAT3Base = item.VAT3Base  ? item.VAT3Base  : 0
                  this.mainledger.VAT3Amount = item.VAT3Amount ? item.VAT3Amount : 0
                  this.mainledger.VAT3PerCanUse = 100
                  this.mainledger.VAT3CanUse = item.VAT3Amount ? item.VAT3Amount : 0

                  this.allHeads.push(this.mainledger)
                  this.savedInv += 1
                }
              }
            })
            console.log('Za spremiti ', this.allHeads)
          }
        }
        catch(err) {
          console.log('Error createAllHeads', err)
        }
      },
      async saveAllHead() {
        try {
          if (this.allHeads.length > 0) {
            const {data} = (await MainLedgerService.postbulk(this.allHeads))
            if (data && data.mainledgers) {
              this.allSavedHeads = [...data.mainledgers]
            } else {
              console.log('Error on save bulk mainledgers')
            }
          }
        } catch (error) {
          this.locError = this.lang.alertError3
          // eslint-disable-next-line
          console.log('Error saveAllHead ', error)
          alert(this.locError)
        }
      },
    },
  }
</script>