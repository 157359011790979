<template>
    <v-container fluid grid-list-md>
      <v-row no-gutters class="pa-0">
        <v-col cols="12" xl="6" lg="6" xs="6" sm="6" md="6">
          <div class="text-right">
            <appTaskChoose></appTaskChoose>
          </div>
        </v-col>
        <v-col cols="12" xl="6" lg="6" xs="6" sm="6" md="6">
          <div class="text-right">
              <v-btn fab dark class="indigo" @click="newtask">
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
          </div>
        </v-col>
      </v-row>
      <app-Task-list></app-Task-list>
      <v-row>
        <TaskToDoList />
      </v-row>
      
    </v-container>
</template>

<script>
import Tasklist from './TaskList'
import TaskChoose from './TaskChoose'
import TaskToDoList from './TaskToDoList'

export default {
  name: 'taskindex',
  data () {
    return {
      msg: ''
    }
  },
  mounted () {

  },
  methods: {
    async newtask () {
      try {
        await this.$store.dispatch('seteditItem', 0)
        await this.$router.push({
          name: 'taskcreate'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async tasktypes () {
      try {
        await this.$router.push({
          name: 'tasktype'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async taskdepartment () {
      try {
        await this.$router.push({
          name: 'taskdepartment'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  computed: {

  },
  watch: {

  },
  components: {
    appTaskList: Tasklist,
    appTaskChoose: TaskChoose,
    TaskToDoList
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
