<template>
  <div class="documentprint">
        <v-btn color="green" @click="documentlist">
            <span v-if="this.$store.state.language == 'EN'">
            Back to List
            </span>
            <span v-if="this.$store.state.language == 'HR'">
            Natrag na listu
            </span>
      </v-btn>
      <v-btn color="green" @click="print2">
            <span v-if="this.$store.state.language == 'EN'">
            Print document 3
            </span>
            <span v-if="this.$store.state.language == 'HR'">
            Printaj 3
            </span>
      </v-btn>

      <div id="printArea">
          <v-container >
          </v-container>
       </div>

  </div>
  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import DocumentDetailService from '@/services/DocumentDetailService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import dayjs from 'dayjs'

export default {
  name: 'documentprint2',
  data () {
    return {
      msg: '',
      documentName: '',
      documentName2: '',
      document: {},
      havePath: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locDocument: {},
      fields1: [ 'itemName', 'ItemCode', 'itemUnit', 'itemService', 'itemPrice', 'itemPcsNeutral', 'itemBaseAmount', 'itemDiscountPercentage1', 'itemBaseAmountNoVAT', 'itemAmountWithVAT' ],
      items1: [
      ],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      fields2: [ 'vat', 'base', 'amount' ],
      items2: [
        { vat: '5%', base: 100.00, amount: 5.00 },
        { vat: '13%', base: 200.00, amount: 26.00 },
        { vat: '25%', base: 400.00, amount: 100.00 }
      ],
      comp: {},
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        databaseDocID: 0
      },
      order: 0,
      loCdocumentDate: '',
      loCorderDate: '',
      loCdocumentInvoiceStartDate: '',
      loCdocumentInvoiceTime: '',
      loCdocumentInvoiceEndDate: '',
      loCdocumentConnDate: '',
      loCdeliveryDate: '',
      loCorderTime: '',
      loCdocumentConnTime: '',
      loCdeliveryTime: '',
      locDocumentId: '',
      output: null,
      documentNameLoc: '',
      docDefinition: null,
      profitLoss: 0,
      profitLossP: '',
      pP1: [],
      pP2: [],
      pP3: [],
      pP4: [],
      pP5: [],
      pP6: [],
      pP7: [],
      pP8: [],
      pP9: [],
      pP10: [],
      pD1: [],
      pD2: [],
      pD3: [],
      pD4: [],
      pD5: [],
      pD6: [],
      pD7: [],
      pD8: [],
      pD9: [],
      pD10: [],
      pT1: [],
      pT2: [],
      pT3: [],
      pT4: [],
      pT5: [],
      pT6: [],
      pT7: [],
      pT8: [],
      pT9: [],
      pT10: [],
      pC1: [],
      pC2: [],
      pC3: [],
      pC4: [],
      pC5: [],
      pC6: [],
      pC7: [],
      pC8: [],
      pC9: [],
      pC10: [],
      pC1tot: 0,
      pC2tot: 0,
      pC3tot: 0,
      pC4tot: 0,
      pC5tot: 0,
      pC6tot: 0,
      pC7tot: 0,
      pC8tot: 0,
      pC9tot: 0,
      pC10tot: 0,
      pD1tot: 0,
      pD2tot: 0,
      pD3tot: 0,
      pD4tot: 0,
      pD5tot: 0,
      pD6tot: 0,
      pD7tot: 0,
      pD8tot: 0,
      pD9tot: 0,
      pD10tot: 0,
      pTtt1: 0,
      pTtt2: 0,
      pTtt3: 0,
      pTtt4: 0,
      pTtt5: 0,
      pTtt6: 0,
      pTtt7: 0,
      pTtt8: 0,
      pTtt9: 0,
      pTtt10: 0,
      pSum1: 0,
      pSum2: 0,
      pSum3: 0,
      pSum4: 0,
      pSum5: 0
    }
  },
  async mounted () {
    try {
      // console.log(' print4  mounted')
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentid) {
        this.mainQuery.databaseDocID = this.$store.state.documentid
      }
      if (this.$store.state.documentActivData) {
        this.document = this.$store.state.documentActivData
      }
      this.documentName2 = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      if (this.$store.state.language === 'EN') {
        this.pSum1 = this.formatPrice(this.document.documentTotalAmountNetto)
        this.pSum2 = this.formatPrice(this.document.documentTotalAmountVAT)
        this.pSum3 = this.formatPrice(this.document.documentTotalAmountBrutto)
        this.pSum4 = this.formatPrice(this.document.documentTotalAmountPayInAdvance)
        this.pSum5 = this.formatPrice(this.document.documentTotalAmountToPay)
      }
      if (this.$store.state.language === 'HR') {
        this.pSum1 = this.formatPriceHR(this.document.documentTotalAmountNetto)
        this.pSum2 = this.formatPriceHR(this.document.documentTotalAmountVAT)
        this.pSum3 = this.formatPriceHR(this.document.documentTotalAmountBrutto)
        this.pSum4 = this.formatPriceHR(this.document.documentTotalAmountPayInAdvance)
        this.pSum5 = this.formatPriceHR(this.document.documentTotalAmountToPay)
      }
      this.comp = this.$store.state.companyActiv
      this.order = 0
      this.items = (await DocumentDetailService.index(this.mainQuery)).data.documentDetails
      // console.log(' items imam ili ne', this.items)
      if (this.items) {
        this.items.map(item => {
          if (parseFloat(item.itemAmountWithVAT) !== 0) {
            this.order += 1
            if (this.$store.state.language === 'EN') {
              // console.log(' im done EN format')
              this.pTtt3 = this.formatPrice(item.itemPrice)
              this.pTtt4 = this.formatPrice(item.itemPcsNeutral)
              this.pTtt5 = this.formatPrice(item.itemBaseAmount)
              this.pTtt6 = this.formatPrice(item.itemDiscountPercentage1)
              this.pTtt7 = this.formatPrice(item.itemBaseAmountNoVAT)
              this.pTtt8 = this.formatPrice(item.itemVAT1Percentage)
              this.pTtt9 = this.formatPrice(item.itemAmountWithVAT)
            }
            if (this.$store.state.language === 'HR') {
              // console.log(' im done HR format')
              this.pTtt3 = this.formatPriceHR(item.itemPrice)
              this.pTtt4 = this.formatPriceHR(item.itemPcsNeutral)
              this.pTtt5 = this.formatPriceHR(item.itemBaseAmount)
              this.pTtt6 = this.formatPriceHR(item.itemDiscountPercentage1)
              this.pTtt7 = this.formatPriceHR(item.itemBaseAmountNoVAT)
              this.pTtt8 = this.formatPriceHR(item.itemVAT1Percentage)
              this.pTtt9 = this.formatPriceHR(item.itemAmountWithVAT)
            }
            this.pC1.push({ text: this.order + '\n', fontSize: 8 })
            this.pC2.push({ text: item.itemName + '\n', fontSize: 8 })
            this.pC3.push({ text: this.pTtt3 + '\n', fontSize: 8, alignment: 'right' })
            this.pC4.push({ text: this.pTtt4 + '\n', fontSize: 8, alignment: 'right' })
            this.pC5.push({ text: this.pTtt5 + '\n', fontSize: 8, alignment: 'right' })
            this.pC6.push({ text: this.pTtt6 + '\n', fontSize: 8, alignment: 'right' })
            this.pC7.push({ text: this.pTtt7 + '\n', fontSize: 8, alignment: 'right' })
            this.pC8.push({ text: this.pTtt8 + '\n', fontSize: 8, alignment: 'right' })
            this.pC9.push({ text: this.pTtt9 + '\n', fontSize: 8, alignment: 'right' })
            this.pC10.push({ text: item.itemUnit + '\n', fontSize: 8, alignment: 'left' })
            // this.pC1.push({ text: this.order + '\n', fontSize: 8 })
            // this.pC2.push({ text: item.itemName + '\n', fontSize: 8 })
            // this.pC3.push({ text: item.itemPrice + '\n', fontSize: 8, alignment: 'right' })
            // this.pC4.push({ text: item.itemPcsNeutral + '\n', fontSize: 8, alignment: 'right' })
            // this.pC5.push({ text: item.itemBaseAmount + '\n', fontSize: 8, alignment: 'right' })
            // this.pC6.push({ text: item.itemDiscountPercentage1 + '\n', fontSize: 8, alignment: 'right' })
            // this.pC7.push({ text: item.itemBaseAmountNoVAT + '\n', fontSize: 8, alignment: 'right' })
            // this.pC8.push({ text: item.itemVAT1Percentage + '\n', fontSize: 8, alignment: 'right' })
            // this.pC9.push({ text: item.itemAmountWithVAT + '\n', fontSize: 8, alignment: 'right' })
            // this.pC10.push({ text: item.itemUnit + '\n', fontSize: 8, alignment: 'left' })
          }
          if (item.itemDescription) {
            this.order += 1
            this.pC1.push({ text: '\n', fontSize: 8 })
            this.pC2.push({ text: item.itemDescription + '\n', fontSize: 8 })
            this.pC3.push({ text: '\n', fontSize: 8, alignment: 'right' })
            this.pC4.push({ text: '\n', fontSize: 8, alignment: 'right' })
            this.pC5.push({ text: '\n', fontSize: 8, alignment: 'right' })
            this.pC6.push({ text: '\n', fontSize: 8, alignment: 'right' })
            this.pC7.push({ text: '\n', fontSize: 8, alignment: 'right' })
            this.pC8.push({ text: '\n', fontSize: 8, alignment: 'right' })
            this.pC9.push({ text: '\n', fontSize: 8, alignment: 'right' })
          }
          // console.log(' ovo imam za stavku ', item)
        })
      }
      // console.log(' client loc document ', this.locDocument)
      // console.log(' client loc items ', this.items)
      // this.document = this.locDocument.data.document
      this.locCreatedAt = dayjs(this.document.CreatedAt).format('DD.MM.YYYY HH:MM')
      this.locUpdatedAt = dayjs(this.document.UpdatedAt).format('DD.MM.YYYY HH:MM')
      this.loCdocumentInvoiceTime = dayjs(this.document.UpdatedAt).format('HH:MM')
      if (this.document.documentDate) {
        this.loCdocumentDate = dayjs(this.document.documentDate).format('DD.MM.YYYY')
      }
      if (this.document.orderDate) {
        this.loCorderDate = dayjs(this.document.orderDate).format('DD.MM.YYYY')
      }
      if (this.document.documentInvoiceStartDate) {
        this.loCdocumentInvoiceStartDate = dayjs(this.document.documentInvoiceStartDate).format('DD.MM.YYYY')
        // console.log(' datum lokalno i iz dokumenta, ', this.document.documentInvoiceStartDate, this.loCdocumentInvoiceStartDate)
      }
      if (this.document.documentInvoiceEndDate) {
        this.loCdocumentInvoiceEndDate = dayjs(this.document.documentInvoiceEndDate).format('DD.MM.YYYY')
      }
      if (this.document.documentConnDate) {
        this.loCdocumentConnDate = dayjs(this.document.documentConnDate).format('DD.MM.YYYY')
      }
      if (this.document.deliveryDate) {
        this.loCdeliveryDate = dayjs(this.document.deliveryDate).format('DD.MM.YYYY')
      }
      if (this.document.orderTime) {
        this.loCorderTime = dayjs(this.document.orderTime).format('HH:MM')
      }
      if (this.document.documentConnTime) {
        this.loCdocumentConnTime = dayjs(this.document.documentConnTime).format('HH:MM')
      }
      if (this.document.deliveryTime) {
        this.loCdeliveryTime = dayjs(this.document.deliveryTime).format('HH:MM')
      }
      if (this.$store.state.documentActiv === 'InvoiceSales') {
        this.locDocumentId = this.document.documentId + '/' + this.$store.state.companyActiv.CompanyBusinessSpace + '/' + this.$store.state.companyActiv.CompanyBusinessDevice
      } else {
        this.locDocumentId = this.document.documentId
      }
      this.profitLoss = this.document.FreeF4 - this.document.FreeF1 - this.document.FreeF2 - this.document.FreeF3
      this.profitLossP = this.profitLoss.toFixed(2)
      // console.log(' state document activ', this.locCreatedAt, this.locUpdatedAt)
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  },
  methods: {
    print () {
      // opens the "print dialog" of your browser to print the element
      // var bdhtml = window.document.body.innerHTML
      // window.document.body.innerHTML = document.getElementById('printArea').innerHTML
      // window.print()
      // window.document.body.innerHTML = bdhtml
      this.$htmlToPaper('printArea')
    },
    formatPrice (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async documentlist () {
      try {
        this.havePath = this.$store.state.retPath
        this.$store.dispatch('setretPath', '')
        if (this.havePath !== 'document' && this.havePath.length > 0) {
          await this.$router.push({
            name: this.havePath
          })
        } else {
          await this.$router.push({
            name: 'document'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    print2 () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {
        // a string or { width: number, height: number }
        pageSize: 'A4',

        // by default we use portrait, you can change it to landscape if you wish
        pageOrientation: 'portrait',

        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 20, 20, 20, 20 ],
        footer: {
          columns: [ {
            text: [
              { text: '   ' + this.comp.CompanyName, fontSize: 8, bold: true, alignment: 'center' },
              { text: ',' + this.comp.CompanyStreet + ' ' + this.comp.CompanyBuildingNameNumber + ', ' + this.comp.CompanyPostcode + ' ' + this.comp.CompanyCity, fontSize: 7 },
              { text: ', Tel:' + this.comp.CompanyPhones + ', email:' + this.comp.Companyemail + ', OIB:' + this.comp.CompanyVATID + ' \n', fontSize: 7 },
              { text: '   ' + this.comp.CompanyDescriptionUp, fontSize: 7, alignment: 'center' }
            ]
          }
          ]
        },
        content: [
          // if you don't need styles, you can use a simple string to define a paragraph
          // using a { text: '...' } object lets you set styling properties
          {
            columns: [
              {
                width: '7%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '51%',
                image: this.comp.CompanyLogo1,
                fit: [70, 70]
              },
              {
                // % width
                width: '30%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: this.comp.CompanyStreet + ' ' + this.comp.CompanyBuildingNameNumber + ' \n', fontSize: 8 },
                  { text: this.comp.CompanyPostcode + ' ' + this.comp.CompanyCity + ' \n', fontSize: 8 },
                  { text: 'Telefon:' + this.comp.CompanyPhones + '\n', fontSize: 8 },
                  { text: 'email:' + this.comp.Companyemail + ' \n', fontSize: 8 },
                  { text: 'OIB:' + this.comp.CompanyVATID, fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          },
          // if you set the value of text to an array instead of a string, you'll be able
          // to style any part individually
          // using a { text: '...' } object lets you set styling properties
          {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          },
          {
            columns: [
              {
                width: '10%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              },
              {
                width: '40%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: this.document.partnerName + ' \n', fontSize: 12, bold: true },
                  { text: this.document.partnerStreet + ' ' + this.document.partnerBuildingNameNumber + ' \n', fontSize: 8 },
                  { text: this.document.partnerPostcode + ' ' + this.document.partnerCity + ' \n', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: 'OIB: ' + this.document.partnerVATID + ' \n', fontSize: 8 },
                  { text: 'email: ' + this.document.partneremail + ' \n', fontSize: 8 }
                ]
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '30%',
                text: [
                  { text: this.documentNameLoc + ': ' + this.document.documentId + '/' + this.comp.CompanyBusinessSpace + '/' + this.comp.CompanyBusinessDevice + ' \n', fontSize: 15 },
                  { text: 'Datum dokumenta: ' + this.loCdocumentDate + ',' + this.loCdocumentInvoiceTime + '\n', fontSize: 8, bold: true },
                  { text: 'Rok plaćanja: ' + this.loCdocumentInvoiceStartDate + '\n', fontSize: 8 }
                ]
              }
            ]
          },
          {
            columns: [
              {
                width: '10%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  // { text: 'Predmet: ' + this.document.documentDescription4 + ' \n ', fontSize: 10, bold: true },
                  { text: ' \n ', fontSize: 8 }
                  // { text: this.document.documentDescription1 + ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '10%',
                text: [
                  { text: ' \n', fontSize: 15 },
                  { text: ' \n', fontSize: 8, bold: true },
                  { text: ' \n', fontSize: 8 }
                ]
              }
            ]
          },
          {
            columns: [
              {
                width: '10%',
                text: [
                  { text: '', fontSize: 8 }
                ]
              },
              {
                width: '50%',
                text: [
                  { text: ' \n', fontSize: 10, bold: true },
                  { text: ' \n', fontSize: 8 },
                  { text: ' \n', fontSize: 8 },
                  { text: ' \n', fontSize: 8 }
                ]
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '20%',
                text: [
                  { text: ' ' + ' \n', fontSize: 8, bold: true }
                ]
              }
            ]
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          },

          // ---------------------------------------------------------------------
          // Za fakturu
          // ---------------------------------------------------------------------
          {
            columns: [
              {
                width: '5%',
                text: { tex: 'Rb. \n', fontSize: 8 }
              },
              {
                width: '35%',
                text: { text: 'Naziv \n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '5%',
                text: { text: 'Jmj\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '8%',
                text: { text: 'Cijena \n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: 'Količina \n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: 'Iznos \n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '5%',
                text: { text: 'Popust %\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: 'Iznos bez PDVa\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '5%',
                text: { text: 'PDV % \n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '8%',
                text: { text: 'Iznos sa PDVom\n', fontSize: 8, alignment: 'right' }
              }
            ]
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: this.pC1
              },
              {
                width: '35%',
                text: this.pC2
              },
              {
                width: '5%',
                text: this.pC10
              },
              {
                width: '8%',
                text: this.pC3
              },
              {
                width: '8%',
                text: this.pC4
              },
              {
                width: '8%',
                text: this.pC5
              },
              {
                width: '5%',
                text: this.pC6
              },
              {
                width: '8%',
                text: this.pC7
              },
              {
                width: '5%',
                text: this.pC8
              },
              {
                width: '8%',
                text: this.pC9
              }
            ]
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '40%',
                text: [
                  { text: 'PDV 25%: ' + this.document.documentTotalVAT1Amount + '\n', fontSize: 8 },
                  { text: 'PDV 13%: ' + this.document.documentTotalVAT2Amount + '\n', fontSize: 8 },
                  { text: 'PDV 5% : ' + this.document.documentTotalVAT3Amount + '\n', fontSize: 8 },
                  { text: 'Neoporezivo: ' + this.document.FreeF4 + '\n', fontSize: 8 }
                ]
              },
              {
                width: '8%',
                text: ' '
              },
              {
                width: '21%',
                text: [
                  { text: 'Ukupan iznos bez PDVa (' + this.document.documentHomeCurrency + ') \n', fontSize: 8 },
                  { text: 'Ukupan iznos PDVa (' + this.document.documentHomeCurrency + '): \n', fontSize: 8 },
                  { text: 'Ukupan iznos sa PDVom (' + this.document.documentHomeCurrency + '): \n', fontSize: 8, bold: true },
                  { text: 'Plaćeni avans (' + this.document.documentHomeCurrency + '): \n', fontSize: 8 },
                  { text: 'ZA UPLATU (' + this.document.documentHomeCurrency + '): \n', fontSize: 12, bold: true }
                ]
              },
              {
                width: '8%',
                text: [
                  { text: ' ', alignment: 'right' }
                ]
              },
              {
                width: '13%',
                text: [
                  { text: this.pSum1 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum2 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum3 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum4 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum5 + '\n', fontSize: 12, bold: true, alignment: 'right' }
                ]
              }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '40%',
                text: [
                  { text: 'Način plaćanja: transakcijski račun ' + '\n', fontSize: 8 },
                  { text: 'Šifra operatera: ' + this.document.UserId + '\n', fontSize: 8 },
                  { text: 'IBAN: ' + this.document.bankAccountNr + '\n', fontSize: 10, bold: true },
                  { text: 'Poziv na broj: 02   ' + this.document.documentId + this.document.BusinessYear + '\n', fontSize: 8 },
                  { text: this.document.bankAccountName + '\n', fontSize: 8 }
                ]
              },
              {
                width: '8%',
                text: ' '
              },
              {
                width: '21%',
                text: [
                  { text: ' \n', fontSize: 8 }
                ]
              },
              {
                width: '8%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '13%',
                text: [
                  { text: ' \n', fontSize: 8 }
                ]
              }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '80%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: 'Napomena: ' + this.document.documentDescription5 + ' \n', fontSize: 8 }
                ]
              }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: 'Izradio: ' + this.document.First + ' ' + this.document.Last + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: 'Kontrolirao: \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: 'Direktor: \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          },
          {
            columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________  \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
        ]
      }
      pdfMake.createPdf(this.docDefinition).open()
    }
  },
  computed: {
  },
  watch: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
