import Api from '@/services/Api'

export default {
  index (query) {
    console.log('query', query)
    return Api().get('mainbookdocs', {params: query})
  },
  choose (query) {
    return Api().get('mainbookdoc/choose', {
      params: query
    })
  },
  show (id) {
    return Api().get(`mainbookdoc/${id}`)
  },
  post (newmainbook) {
    return Api().post('mainbookdoc', newmainbook)
  },
  put (mainbook) {
    return Api().put(`mainbookdoc/${mainbook.id}`, mainbook)
  },
  delete (id) {
    return Api().delete(`mainbookdoc/${id}`)
  },
}