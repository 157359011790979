<template>
    <v-container fluid grid-list-md>
      <v-layout row>
        <v-flex xs3 sm3 md3 lg3 xl3>
          <h1>{{this.lang.VATStatements}}</h1>
        </v-flex>
        <v-flex xs3 sm3 md3 lg3 xl3>
        </v-flex>
        <v-flex xs6 sm6 md6 lg6 xl6 class="text-xs-right">
            <v-btn fab dark fixed right class="indigo" @click="newdocument">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
        </v-flex>
      </v-layout>

      <appMainLedgerListVAT ref="form"></appMainLedgerListVAT>

      <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>
    </v-container>
</template>

<script>
import MainLedgerlistVAT from './MainLedgerListVAT'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import { defMainQuery } from './mainledgerHelper'

export default {
  name: 'mainledger',
  data () {
    return {
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      msg: '',
      MainLedgerName: '',
      MainLedgerNameLoc: '',
      mainledger: {},
      documentName: '',
      documentNameLoc: '',
      error: null,
      localError: false,
      newPath: '',
      show: 1,
      lang: {},
      langC: {},
      mount: true,
      mainQuery: {
      },
      bookType: [],
      bookTypeSelected: {
      },
    }
  },
  mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.mainQuery = defMainQuery()
    this.MainLedgerName = this.$store.state.MainLedgerActiv
    this.MainLedgerNameLoc = this.$store.state.MainLedgerActivLoc
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    this.$store.dispatch('setMainLedgerActiv', {})

    if (this.$store.state.pathAgain) {
      this.newPath = this.$store.state.pathAgain
      this.$store.dispatch('setpathAgain', '')
      this.$router.push({
        name: this.newPath
      })
    }
  },
  methods: {
     async newdocument () {
      try {
        this.$store.dispatch('seteditItem', 0)
        await this.$router.push({
          name: 'mainledgercreatevat'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  computed: {
  },
  watch: {

  },
  components: {
    appMainLedgerListVAT: MainLedgerlistVAT
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
