<template>
    <div class="itemedit">
    <h3>
      Edit item
    </h3>

    <v-container fluid grid-list-md>
      <v-layout row wrap>

        <v-flex xs12 sm6 md4 lg4 xl4 >
          <h5 class="text-xs-left">
            <span v-if="this.$store.state.language == 'EN'">
            Basic data
            </span>
            <span v-if="this.$store.state.language == 'HR'">
            Osnovni podaci
            </span>
          </h5>

          <v-text-field
            label="Item ID:"
            required
            :rules="[required]"
            v-model="item.itemID"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Sifra:"
            required
            :rules="[required]"
            v-model="item.itemID"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Name:"
            required
            :rules="[required]"
            v-model="item.itemName"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Naziv artikla/usluge:"
            required
            :rules="[required]"
            v-model="item.itemName"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Name Short:"
            required
            v-model="item.ItemNameShort"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Naziv skraceno:"
            required
            v-model="item.ItemNameShort"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="ItemCode:"
            v-model="item.ItemCode"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Kod:"
            v-model="item.ItemCode"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="ItemEANCode:"
            v-model="item.ItemEANCode"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="EAN barkod:"
            v-model="item.ItemEANCode"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Group:"
            v-model="item.itemGroup"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Grupa:"
            v-model="item.itemGroup"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Description:"
            v-model="item.itemDescription"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Opis:"
            v-model="item.itemDescription"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Unit:"
            v-model="item.itemUnit"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Jedinica mjere:"
            v-model="item.itemUnit"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Size:"
            v-model="item.ItemSize"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Velicina:"
            v-model="item.ItemSize"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Item Weight:"
            required
            v-model="item.ItemWeight"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Tezina:"
            required
            v-model="item.ItemWeight"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>

          <v-text-field
            label="Weight Measure:"
            required
            v-model="item.ItemWeightMeasure"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Oznaka tezina (kg,g,t,..):"
            required
            v-model="item.ItemWeightMeasure"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Dim Measure:"
            v-model="item.ItemDimMeasure"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Oznaka dimenzije (cm,m,km,..):"
            v-model="item.ItemDimMeasure"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Dim Lenght:"
            v-model="item.ItemDimLenght"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Dimenzija duzina:"
            v-model="item.ItemDimLenght"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Dim Height:"
            v-model="item.ItemDimHeight"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Dimenzija visina:"
            v-model="item.ItemDimHeight"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Dim Depth:"
            v-model="item.ItemDimDepth"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Dimenzija sirina:"
            v-model="item.ItemDimDepth"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Warehouse:"
            v-model="item.ItemWarehouse"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Skladiste:"
            v-model="item.ItemWarehouse"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Warehouse Row:"
            v-model="item.ItemWarehouseRow"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Skladiste red:"
            v-model="item.ItemWarehouseRow"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Warehouse Shelf:"
            v-model="item.ItemWarehouseShelf"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Skladiste Polica:"
            v-model="item.ItemWarehouseShelf"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Wahrehouse Floor:"
            v-model="item.ItemWahrehouseFloor"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Skladiste Kat:"
            v-model="item.ItemWahrehouseFloor"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Link Picture 1:"
            v-model="item.ItemPicture1"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Link Slika 1:"
            v-model="item.ItemPicture1"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>

          <v-text-field
            label="Link Picture 2:"
            v-model="item.ItemPicture2"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Link Slika 2:"
            v-model="item.ItemPicture2"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>

          <v-text-field
            label="Link Picture3:"
            v-model="item.ItemPicture3"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Link Slika 3:"
            v-model="item.ItemPicture3"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>

          <v-text-field
            label="Belongs To Id:"
            v-model="item.ItemBelongsToId"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Pripada sifri:"
            v-model="item.ItemBelongsToId"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-checkbox
            v-model="item.itemService"
            label="Item as Service"
            v-if="this.$store.state.language == 'EN'"
          ></v-checkbox>
          <v-checkbox
            v-model="item.itemService"
            label="Artikl je usluga"
            v-if="this.$store.state.language == 'HR'"
          ></v-checkbox>          

          <v-checkbox
            v-model="item.itemWeb"
            label="Show on Web:"
            v-if="this.$store.state.language == 'EN'"
          ></v-checkbox>
          <v-checkbox
            v-model="item.itemWeb"
            label="Prikazi na internetu:"
            v-if="this.$store.state.language == 'HR'"
          ></v-checkbox>          

          <v-checkbox
            v-model="item.itemNotActiv"
            label="Not Activ:"
            v-if="this.$store.state.language == 'EN'"
          ></v-checkbox>
          <v-checkbox
            v-model="item.itemNotActiv"
            label="Stari artikal:"
            v-if="this.$store.state.language == 'HR'"
          ></v-checkbox>          

          <v-checkbox
            v-model="item.itemHideForever"
            label="Hide Forever:"
            v-if="this.$store.state.language == 'EN'"
          ></v-checkbox>
          <v-checkbox
            v-model="item.itemHideForever"
            label="Sakrij artikl zauvijek:"
            v-if="this.$store.state.language == 'HR'"
          ></v-checkbox>          


        </v-flex>

       <v-flex xs1 >
       </v-flex>

        <v-flex xs12 sm3 md3 lg3 xl3 >
          <h5 class="text-xs-left">
            <span v-if="this.$store.state.language == 'EN'">
            Item price home
            </span>
            <span v-if="this.$store.state.language == 'HR'">
            Cijena
            </span>             
          </h5>

          <v-layout v-if="this.$store.state.language == 'EN'">
              <v-flex xs6>
                <v-card-actions>
                  <v-select label="Home Currency" :items="privitems" v-model="item.ItemHomeCurrency" value="item.ItemHomeCurrency"></v-select>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-flex>
              <v-flex xs6>
              </v-flex>
          </v-layout>

          <v-layout v-if="this.$store.state.language == 'HR'">
              <v-flex xs6>
                <v-card-actions>
                  <v-select label="Domaca valuta" :items="privitems" v-model="item.ItemHomeCurrency" value="item.ItemHomeCurrency"></v-select>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-flex>
              <v-flex xs6>
              </v-flex>
          </v-layout>          

          <v-text-field
            label="Price:"
            class="yellow lighten-4 text-xs-right"
            v-model.number="item.itemPrice"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Cijena bez PDVa:"
            class="yellow lighten-4 text-xs-right"
            v-model.number="item.itemPrice"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="VAT1 %:"
            align="right"
            v-model.number="item.itemVAT1Percentage"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="PDV1 %:"
            align="right"
            v-model.number="item.itemVAT1Percentage"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="VAT1 Amount:"
            v-model.number="item.itemVAT1Amount"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="PDV1 Iznos:"
            v-model.number="item.itemVAT1Amount"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="VAT2 %:"
            v-model.number="item.itemVAT2Percentage"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="PDV2 %:"
            v-model.number="item.itemVAT2Percentage"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="VAT2 Amount:"
            v-model.number="item.itemVAT2Amount"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="PDV2 Iznos:"
            v-model.number="item.itemVAT2Amount"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>

          <v-text-field
            label="Direct Duty1:"
            v-model.number="item.itemDirectDuty1"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Direktna naknada 1:"
            v-model.number="item.itemDirectDuty1"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Direct Duty2:"
            v-model.number="item.itemDirectDuty2"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Dirketna naknada 2:"
            v-model.number="item.itemDirectDuty2"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Price With VAT:"
            class="yellow lighten-4"
            v-model.number="item.itemPriceWithVAT"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Cijena sa PDVom:"
            class="yellow lighten-4"
            v-model.number="item.itemPriceWithVAT"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <br>
          <h5 class="text-xs-left">
            <span v-if="this.$store.state.language == 'EN'">
            Supplier data
            </span>
            <span v-if="this.$store.state.language == 'HR'">
            Dobavljac - podaci
            </span>            
          </h5>
          <v-text-field
            label="Supplier Price:"
            v-model.number="item.itemSuppPrice"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Dobavna cijena:"
            v-model.number="item.itemSuppPrice"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>

          <v-text-field
            label="Supplier base price:"
            v-model.number="item.itemSuppBasePrice"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Dobavna cijena sa popustom:"
            v-model.number="item.itemSuppBasePrice"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>

          <v-text-field
            label="Supplier Discount:"
            v-model.number="item.itemSuppDiscount"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Popust dobavljaca:"
            v-model.number="item.itemSuppDiscount"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          


          <v-text-field
            label="Supplier Code:"
            v-model="item.ItemSuppCode"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Sifra dobavljaca:"
            v-model="item.ItemSuppCode"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Margin:"
            v-model.number="item.itemMargin"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Marza:"
            v-model.number="item.itemMargin"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Max Discount%:"
            v-model.number="item.itemMaxDiscount"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Maximalni popust%:"
            v-model.number="item.itemMaxDiscount"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          


          <v-text-field
            label="Discount Amount1:"
            v-model.number="item.itemDiscountAmount1"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
         <v-text-field
            label="Popust na kolicinu 1:"
            v-model.number="item.itemDiscountAmount1"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Discount1 %:"
            v-model.number="item.itemDiscountPercentage1"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Popust na kolicinu1 %:"
            v-model.number="item.itemDiscountPercentage1"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          


          <v-text-field
            label="Discount Amount2:"
            v-model.number="item.itemDiscountAmount2"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Popust na kolicinu 2:"
            v-model.number="item.itemDiscountAmount2"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>

          <v-text-field
            label="Discount2 %:"
            v-model.number="item.itemDiscountPercentage2"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Popust na kolicinu2 %:"
            v-model.number="item.itemDiscountPercentage2"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Discount Amount3:"
            v-model.number="item.itemDiscountAmount3"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Popust na kolicinu 3:"
            v-model.number="item.itemDiscountAmount3"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Discount3 %:"
            v-model.number="item.itemDiscountPercentage4"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Popust na kolicinu 3 %:"
            v-model.number="item.itemDiscountPercentage4"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

        </v-flex>

       <v-flex xs1 >
       </v-flex>

        <v-flex xs12 sm3 md3 lg3 xl3 >
          <h5 class="text-xs-left">
            <span v-if="this.$store.state.language == 'EN'">
            Item price foregin
            </span>
            <span v-if="this.$store.state.language == 'HR'">
            Cijena artikla izvoz
            </span> 
          </h5>
         <v-layout>
           <v-flex xs6>
             <v-card-actions>
               <v-select label="Foreign Currency" :items="privitems" v-model="item.ItemForeignCurrency" value="item.ItemForeignCurrency"></v-select>
               <v-spacer></v-spacer>
             </v-card-actions>
           </v-flex>

           <v-flex xs6>
             <v-text-field
               label="Ex.Rate:"
               v-model.number="item.ItemExRate"
               v-if="this.$store.state.language == 'EN'"
             ></v-text-field>
             <v-text-field
               label="Tecaj:"
               v-model.number="item.ItemExRate"
               v-if="this.$store.state.language == 'HR'"
             ></v-text-field>             
           </v-flex>
         </v-layout>

          <v-text-field
            label="Foregin Price:"
            v-model.number="item.itemForePrice"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Izvoz cijena neto:"
            v-model.number="item.itemForePrice"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Foregin VAT1 %:"
            v-model.number="item.itemForeVAT1Percentage"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Izvoz PDV1 %:"
            v-model.number="item.itemForeVAT1Percentage"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Foregin VAT1 Amount:"
            v-model.number="item.itemForeVAT1Amount"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Izvoz PDV1 iznos:"
            v-model.number="item.itemForeVAT1Amount"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Foregin VAT2 %:"
            v-model.number="item.itemForeVAT2Percentage"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Izvoz PDV2 %:"
            v-model.number="item.itemForeVAT2Percentage"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Foregin VAT2 Amount:"
            v-model.number="item.itemForeVAT2Amount"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Izvoz PDV2 Iznos:"
            v-model.number="item.itemForeVAT2Amount"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          


          <v-text-field
            label="Foregin Direct Duty1:"
            v-model.number="item.itemForeDirectDuty1"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Izvoz direktna naknada1:"
            v-model.number="item.itemForeDirectDuty1"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <v-text-field
            label="Foregin Direct Duty2:"
            v-model.number="item.itemForeDirectDuty2"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Izvoz direktna naknada2:"
            v-model.number="item.itemForeDirectDuty2"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>

          <v-text-field
            label="Foregin Price With VAT:"
            v-model.number="item.itemForePriceWithVAT"
            v-if="this.$store.state.language == 'EN'"
          ></v-text-field>
          <v-text-field
            label="Izvoz cijena sa PDVom:"
            v-model.number="item.itemForePriceWithVAT"
            v-if="this.$store.state.language == 'HR'"
          ></v-text-field>          

          <br>
          <div class="pink darken-2" v-html="error" />
          <br>
          <v-btn
            class="primary"
             :disabled="!formIsValid"
            @click="updateItem">
            <span v-if="this.$store.state.language == 'EN'">
            Update item
            </span>
            <span v-if="this.$store.state.language == 'HR'">
            Zapamti promjene
            </span> 
          </v-btn>
          <v-btn
            color="green"
            @click="itemlist">
            <span v-if="this.$store.state.language == 'EN'">
            Back
            </span>
            <span v-if="this.$store.state.language == 'HR'">
            Natrag
            </span> 
          </v-btn>
        </v-flex>

      </v-layout>
    </v-container>
 
  </div>
</template>

<script>

import GroupService from '@/services/GroupService'
import ItemService from '@/services/ItemService'
import { newUpdates, testLastUpdate } from '../../services/TestLastUpdate'

export default {
  name: 'itemedit',
  $validates: true,
  data () {
    return {
      menu: false,
      modal: false,
      msg: '',
      valid: true,
      samoZaProbu: 0,
      item: { },
      error: null,
      localError: false,
      privitems: [
        { text: 'Kn', value: 'Kn' },
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' }
      ],
      privGroups: [],
      required: (value) => !!value || 'Required.',
      mainQuery: {}
    }
  },
  components: {
  },
  async mounted () {
    try {
      if (this.$store.state.editItem === 1) {
        this.privGroups = (await GroupService.index()).data.groups
        // console.log('privgroups', this.privGroups)
        const dbItem = await ItemService.show(this.$store.state.itemid)
        this.item = dbItem.data.item
        this.$store.dispatch('seteditItem', 0)
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log('greska kod monut dokumenta', error)
    }
  },
  computed: {
    formIsValid () {
      return this.item.GroupId !== '' &&
        this.item.itemID !== '' &&
        this.item.itemName !== ''
    }
  },
  methods: {
    async updateItem () {
      try {
        // treba sad tu sloziti da provjeri da li su password i
        // this password isti
        this.localError = false
        if (this.$store.state.user.email) {
          this.item.LastUpdateBy = this.$store.state.user.email
          this.item.email = this.$store.state.user.email
        }
        await ItemService.put(this.item)
        await newUpdates(1)
        await testLastUpdate()

        // if (this.$store.state.companyid) {
        //     this.mainQuery.CompanyId = await this.$store.state.companyid
        //   }
        //   this.itemsLoc = (await ItemService.index(this.mainQuery)).data.items
        //   if (this.itemsLoc) {
        //     this.$store.dispatch('setActivItems', this.itemsLoc)
        //   }
        //   if (this.newItemAround) {
        //     this.$store.dispatch('setSelectedItemId', this.item.itemID)
        //   }
        this.$router.push({
          name: 'item'
        })
      } catch (error) {
        this.error = 'Greska u konekciji na bazu'
      }
    },
    close () {
      this.show = false
    },
    async itemlist () {
      try {
        await this.$router.push({
          name: 'item'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    calculatePrice () {
      this.item.itemPriceWithVAT = parseFloat((this.item.itemPrice + (this.item.itemPrice * (this.item.itemVAT1Percentage / 100))).toFixed(2))
    }
  },
  watch: {
    'item.itemPrice' () {
      this.calculatePrice()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
