<template>
 <v-container fluid>
      <v-row no-gutters justify="center">
          <v-col cols="4">
              <div class="text-left display-2">
                {{lang.documentTotalAmountToPay}}  {{ localMoney(total) }} {{currency ? currency : ''}}
              </div>
          </v-col>
      </v-row>
      
      <v-divider class="mt-1"></v-divider>
      <div v-if="notsavedPOS">
        <v-row class="mt-10" justify="center">
          <v-col xs="1" sm="1" md="1" lg="1" ex="1">
            <v-checkbox v-model="cash" label="Gotovina:" class="mt-1"></v-checkbox>
          </v-col>
          <v-col xs="1" sm="1" md="1" lg="1" ex="1" class="mr-2 headline">
            <v-text-field
              :label="lang.documentPOSCash"
              v-model.number="posCash"
              type="number"
              class="text-h6"
            ></v-text-field>
          </v-col>
          <v-col xs="1" sm="1" md="1" lg="1" ex="1" class="mr-2 headline">
            <v-text-field
              headline
              :label="lang.cashReceived"
              v-model.number="cashReceived"
              type="number"
              class="text-h6"
            ></v-text-field>
          </v-col>
          <v-col xs="2" sm="2" md="2" lg="2" ex="2"  class="mt-1 text-h6">
              {{lang.cashToReturn}}: {{localMoney(cashToReturn)}}
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col xs="1" sm="1" md="1" lg="1" ex="1">
            <v-checkbox v-model="cards" label="Kartice:" class="mt-1"></v-checkbox>
          </v-col>
          <v-col xs="1" sm="1" md="1" lg="1" ex="1" class="mr-5">
            <v-text-field
              dense
              :label="lang.documentPOSCard"
              v-model.number="posCard"
              type="number"
              class="text-h6"
            ></v-text-field>
          </v-col>
          <v-col xs="3" sm="3" md="3" lg="3" ex="3" v-if="cards">
            <span >
              <v-select
                label="Vrsta kartice"
                :items="cardtype"
                v-model="selectCard"
                item-text="CardName"
                item-value="cardtype.id"
                single-line
                return-object
                persistent-hint
                clearable
                dense
              ></v-select>
              </span>
          </v-col>
          <v-col xs="3" sm="3" md="3" lg="3" ex="3" v-if="!cards">
          </v-col>
        </v-row>
      </div>

      <div v-if="notsavedPOS === false">
        <v-row justify="center">
          <v-col xs="2" sm="2" md="2" lg="2" ex="2" class="headline">
            Gotovina
          </v-col>
          <v-col xs="1" sm="1" md="1" lg="1" ex="1" class="mr-2 headline">
            {{localMoney(posCash)}}
          </v-col>
          <v-col xs="2" sm="2" md="1" lg="2" ex="2" class="mr-2 headline">
             {{lang.cashReceived}}: {{localMoney(cashReceived)}}
          </v-col>
          <v-col xs="2" sm="2" md="2" lg="2" ex="2"  class="mr-2 headline">
              {{lang.cashToReturn}}: {{localMoney(cashToReturn)}}
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col xs="2" sm="2" md="2" lg="2" ex="2" class="headline">
            Kartice
          </v-col>
          <v-col xs="1" sm="1" md="1" lg="1" ex="1" class="headline">
             {{localMoney(posCard)}}
          </v-col>
          <v-col xs="3" sm="3" md="3" lg="3" ex="3" class="headline">
             {{document.status}}
          </v-col>
          <v-col xs="1" sm="1" md="1" lg="1" ex="1">
            
          </v-col>
        </v-row>

      </div>

      <v-row justify="center">
          <v-col xs="2" sm="2" md="2" lg="2" ex="2">
            <div v-if="this.saving" class="text-center headline">
              MOLIM PRIČEKAJTE. FISKALIZIRAM ...
            </div>
          </v-col>
        </v-row>
     
      <v-row justify="center">
        <v-col class="text-center headline" cols="12" sm="3">
          <div :class="fiskalColorStatus" v-if="fiskalStatus">
            {{fiskalStatus.status}}
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="text-center headline" cols="12" sm="3">
          <div class="my-2" v-if="fiskalStatus">
            JIR:{{fiskalStatus.JIR}}
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="text-center headline" cols="12" sm="3">
          <div class="my-2" v-if="fiskalStatus">
            ZKI:{{fiskalStatus.ZKI}}
          </div>
        </v-col>
      </v-row>


      <v-snackbar
            v-model="snackbar"
            bottom
            :timeout="timeout"
            :color="color"
            >
            {{ text }}
      </v-snackbar>
      <v-row justify="center">
         <v-col class="text-center" cols="12" sm="3">
          <div class="my-2" v-if="notsavedPOS">
            <v-btn x-large block color="success" @click="saveIt">{{lang.finishReceive}}</v-btn>
          </div>
          <div class="my-2" v-if="notsavedPOS">
            <v-btn x-large block @click="returnToPos">{{lang.returnPOS}}</v-btn>
          </div>
          <div class="my-2">
            <v-btn x-large block @click="documentprint(false)">{{lang.printPOS}}</v-btn>
          </div>
          <div class="my-2">
            <v-btn x-large block @click="documentprint(true)">{{lang.printPOS2}}</v-btn>
          </div>
          <div class="my-2">
           <v-btn x-large block color="blue" @click="newPOS">{{lang.newPOS}}</v-btn>
          </div>
        </v-col>
      </v-row>
      <app-document-print-sales ref="prnt"></app-document-print-sales>
  </v-container>
</template>

<script>
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import DocumentService from '@/services/DocumentService'
import DocumentDetailService from '@/services/DocumentDetailService'
import DocumentPrintSales from './DocumentPrintSales'
import CardTypeService from '@/services/CardTypeService'
import moneyFormat from '@/snippets/moneyFormat'
//import md5 from "md5"
import { defDocument } from './documentHelper'
import {round, wait} from '@/snippets/allSnippets'
import {fiskalSave, fiskalRead} from './fiskal'
import dayjs from 'dayjs'

export default {
  data() {
    return {
      document: {},
      lang: {},
      langC: {},
      documentNameLoc: '',
      cash: true,
      cards: false,
      mainQuery: {},
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      done: false,
      cashReceived: 0,
      cashToReturn: 0,
      cardtype: [],
      selectCard: {},
      notsavedPOS: true,
      posCash: 0,
      posCard: 0,
      saving: false,
      fiskalStatus: null,
      fiskalColorStatus: 'red',
      dbID: null,
      updateHead: {},
      doFiskal: 0
    }
  },
  async mounted() {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.documentActivHead) {
      this.document = defDocument()
      this.document = {...this.$store.state.documentActivHead}
      this.documentNameLoc = this.$store.state.documentActivLoc
      this.document.documentPOSCash = this.$store.state.documentActivHead.documentTotalAmountToPay ? this.$store.state.documentActivHead.documentTotalAmountToPay : 0
      // if head is copied from other document, remove id of former document
      if (this.document.id) {
        delete this.document.id
      }
      // reset status to null (type of card)
      this.document.status = null
    }
    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }

    this.posCash = parseFloat(this.$store.state.documentActivHead.documentTotalAmountToPay)
    this.posCard = 0
    const {data} = await CardTypeService.index(this.mainQuery)
      if (data && data.cardtypes !== undefined){
        this.cardtype = data.cardtypes
      } else {
        this.cardtype = this.$store.state.cardtype
      }
    
  },
  methods: {
    async returnToPos(){
      await this.$store.dispatch('settypePOS', 1)
    },
    showCircular(how, type) {
       this.doFiskal = how
       this.saving = type
    },
    async saveIt() {
      try {
        
        if (this.cards && Object.keys(this.selectCard).length === 0 && this.selectCard.constructor === Object) {
          this.text = 'Error! Nije odabrana vrsta kartice!!' 
          this.color = 'red'
          this.snackbar = true
          this.timeout = 4000
          return
        }
        if ((this.posCash + this.posCard) !== this.$store.state.documentActivHead.documentTotalAmountToPay) {
          this.text = 'Error! Iznosi po vrstama plaćanja su različiti od iznosa računa!!' 
          this.color = 'red'
          this.snackbar = true
          this.timeout = 4000
          return
        }
        this.showCircular(10, true)
       
        console.log('radim save ')
        
        if (this.$store.state.companyid) {
          this.CompanyId = await this.$store.state.companyid
          this.mainQuery.CompanyId = await this.$store.state.companyid
        }
        this.document.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
        this.document.email = this.$store.state.user.email ? this.$store.state.user.email : 'test@cegrupa.com'
        this.document.First = this.$store.state.user.First ? this.$store.state.user.First : 'NoFirst'
        this.document.Last = this.$store.state.user.Last ? this.$store.state.user.Last : 'NoLast'
        this.document.uservatID = this.$store.state.user.vatID ? this.$store.state.user.vatID : null
        this.document.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        this.document.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
        this.document.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
        this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
        this.mainQuery.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : ''
        this.document.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : ''
        this.docName = this.$store.state.documentActiv
        this.document.documentAnalysis = 1
        this.document.documentSide = this.$store.state.documentSide ? this.$store.state.documentSide : 2  
        let locDate1 = new Date()
        locDate1.getDate()
        locDate1.getMonth() + 1
        locDate1.getFullYear()
        this.document.documentDate = dayjs(new Date()).format('YYYY-MM-DD')
        this.document.documentTime = dayjs().format('HH:mm:ss')
        this.document.documentLockedTime = dayjs().format('HH:mm:ss')
        this.document.documentUpdateTime = dayjs().format('HH:mm:ss')
        this.document.documentConnTime = dayjs().format('HH:mm:ss')
        this.document.documentConnTimeFrom = dayjs().format('HH:mm:ss')
        this.document.deliveryTime = dayjs().format('HH:mm:ss')
        this.document.orderTime = dayjs().format('HH:mm:ss')
        this.document.documentInvoiceStartDate = dayjs(new Date()).format('YYYY-MM-DD')
        this.document.documentInvoiceEndDate = dayjs(new Date()).add(15, 'days').format('YYYY-MM-DD')
        this.document.documentHomeCurrency = 'Kn'
        this.document.documentForeignCurrency = 'EUR'
        this.document.documentExRate = this.$store.getters.NeedDefExRate
        this.document.WareHouseId = 1
        this.document.documentPOSCash = parseFloat(this.posCash)
        this.document.documentPOSCard = parseFloat(this.posCard)
        if (Object.keys(this.selectCard).length !== 0 && this.selectCard.constructor === Object) {
          this.document.productiontype = this.selectCard.id
          this.document.status = this.selectCard.CardName
        }
        if (!this.$store.state.documentActivHead.partnerName)
          {
            this.document.partnerName = 'POS'
            this.document.partnerNameShort = 'POS'
            this.document.partnerNameDatabase = 'POS'
        }
        const side = this.$store.state.documentSide ? this.$store.state.documentSide : 0
         if (side === 1 || side === 2) {
          this.mainQuery.WareHouseId = this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1
        }
        this.document.documentId = parseInt((await DocumentService.choose(this.mainQuery)).data.docNumber)
        this.document.documentIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-' + this.$store.state.documentActiv + '-' + this.document.documentId + '-' + (this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1)
        this.document.WareHouseId = (this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1)
        const whObj =  this.$store.state.warehouseState ? this.$store.state.warehouseState.find(whID => whID.WareHouseId === this.$store.state.warehousemarkid) : []
        this.document.WareHouseName = whObj && whObj.WareHouseName ? whObj.WareHouseName : ''
        //this.document.documentZKI = md5(this.document.documentId + this.document.documentDate + this.document.documentTime + this.document.CompanyNameDatabase + this.$store.state.documentActivHead.documentTotalAmountToPay)
        const newHead = {...this.$store.state.documentActivHead, ...this.document}
        await this.$store.dispatch('setDocumentActivHead', newHead)
        
        this.showCircular(20, true)
        this.done = false
        await DocumentService.post(newHead)
          .then(response => {
            newHead.databaseDocID = response.data.newDoc.id
            this.dbID = response.data.newDoc.id
            this.done = true
          })
          .catch(err => {
            // eslint-disable-next-line
            console.log(err)
            this.text = 'Error!' + err
            this.color = 'red'
            this.snackbar = true
            this.timeout = 4000
          })

          if (this.done === true) {
            let newItems = []
            if (this.$store.state.documentActivItems) {
              this.$store.state.documentActivItems.map(item => {
                let oldObject = item
                const newObject = {...oldObject, ...newHead}
                delete newObject.id
                newItems.push(newObject)
              })
            }
           this.showCircular(40, true)
            await DocumentDetailService.saveitems(newItems)
            this.notsavedPOS = false

            this.text = this.lang.DocumentSavedOK
            this.color = 'green'
            this.snackbar = true
            this.timeout = 2000      
            this.showCircular(50, true)

            // const fiskalSave = {}
            // fiskalSave.line1 = 'R'
            // const year = this.document.documentDate ? this.document.documentDate.slice(0,4) : ''
            // const month = this.document.documentDate ? this.document.documentDate.slice(5,7) : ''
            // const day = this.document.documentDate ? this.document.documentDate.slice(-2) : ''
            // const dateFormated = day + '/' + month + '/' + year
            // const hours = this.document.documentTime ? this.document.documentTime.slice(0,2) : ''
            // const minutes = this.document.documentTime ? this.document.documentTime.substr(3,2) : ''
            // const seconds = this.document.documentTime ? this.document.documentTime.slice(-2) : ''
            // const timeFormated = hours + ':' + minutes + ':' + seconds 
            // fiskalSave.line2 = dateFormated + ' ' + timeFormated
            // fiskalSave.line3 = this.document.documentId
            // const VAT1 = this.document.documentTotalVAT1Base !== 0 ? (round(this.document.documentTotalVAT1Per, 2) + '; ' + round(this.document.documentTotalVAT1Base, 2) + '; ' + round(this.document.documentTotalVAT1Amount, 2)) : null
            // const VAT2 = this.document.documentTotalVAT2Base !== 0 ? '; ' + round(this.document.documentTotalVAT2Per, 2) + '; ' + round(this.document.documentTotalVAT2Base, 2) + '; ' + round(this.document.documentTotalVAT2Amount, 2) : null
            // const VAT3 = this.document.documentTotalVAT3Base !== 0 ? '; ' + round(this.document.documentTotalVAT3Per, 2) + '; ' + round(this.document.documentTotalVAT3Base, 2) + '; '  + round(this.document.documentTotalVAT3Amount, 2) : null
            // fiskalSave.line4 = (VAT1 ? VAT1 : '') + (VAT2 ? VAT2 : '') + (VAT3 ? VAT3 : '')
            // fiskalSave.line5 = round(this.document.documentTotalAmountBrutto, 2)
            // const PAYWITH = this.document.documentPOSCash !== 0 ? 'G' : null
            // const PAYWITH2 = this.document.documentPOSCard !== 0 ? 'K' : null
            // fiskalSave.line6 = PAYWITH ? PAYWITH : PAYWITH2
            // fiskalSave.line7 = this.document.uservatID ? this.document.uservatID : this.$store.state.companyActiv.CompanyVATID
            // fiskalSave.line8 = ''
            // fiskalSave.line9 = ''
            // fiskalSave.line10 = ''
            // fiskalSave.line11 = ''
            // fiskalSave.line12 = this.dbID
            // fiskalSave.line13 = ''
            // fiskalSave.line14 = ''
            // fiskalSave.line15 = '1'
            // fiskalSave.line16 = ''
            // // eslint-disable-next-line
            // console.log('fiskal Save', fiskalSave )
            await fiskalSave(this.dbID, this.document, 'R')

            //await DocumentService.documentfiskalsave(fiskalSave)
            // wait 2 seconds and try read reponse
            console.log('cekam 1 ')
            wait(1000)
            this.showCircular(60, true)
            console.log('cekam 2 ')
            wait(1000)
            this.showCircular(80, true)
            console.log('cekam 3 ')
            wait(1000)
            this.showCircular(90, true)
            this.fiskalStatus = await fiskalRead(1, this.dbID)
            this.document.documentJIR = this.fiskalStatus.JIR ? this.fiskalStatus.JIR : null
            this.document.documentZKI = this.fiskalStatus.ZKI ? this.fiskalStatus.ZKI : null
            this.document.documentUUID = this.fiskalStatus.UUID ? this.fiskalStatus.UUID : null
            const newHead2 = {...this.$store.state.documentActivHead, ...this.document}
            await this.$store.dispatch('setDocumentActivHead', newHead2)
            //this.fiskalReadResponse(1)
          }
        console.log('zavrsio save ')
        this.showCircular(100, false)
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }   
    },
    async fiskalReadResponse(attempt) {
      let doItAgain = false
      // eslint-disable-next-line
      console.log('fiskal Read', attempt)
      await DocumentService.documentfiskalread()
      .then(async(res) => {
        this.fiskalStatus = res.data.status === 'OK' ? 'Fiskalizacija OK.' : 'GRESKA FISKALIZACIJE!!!' 
        this.fiskalColorStatus = res.data.status === 'OK' ? 'green' : 'red' 
        if (parseInt(res.data.dbID) === this.dbID) {
          this.updateHead = {}
          this.updateHead.id = res.data.dbID ? res.data.dbID : null
          this.updateHead.documentJIR = res.data.documentJIR ? res.data.documentJIR : null
          this.updateHead.documentZKI = res.data.documentZKI ? res.data.documentZKI : null
          this.updateHead.documentUUID = res.data.documentUUID ? res.data.documentUUID : null
          await DocumentService.put(this.updateHead)
        }
      }) 
      .catch(err => {
        this.fiskalStatus = 'GRESKA FISKALIZACIJE!!!' 
        this.fiskalColorStatus =  'red' 
        // eslint-disable-next-line
        console.log(err)
        doItAgain = true
      })

      if (doItAgain && attempt < 2) {
        this.fiskalReadResponse(2)
        wait(2000)
        
      }
    },
    async documentprint(withPartner) {
      const fiskalPrint = {}
      fiskalPrint.document = this.document
      fiskalPrint.items = this.$store.state.documentActivItems ? this.$store.state.documentActivItems : []
      fiskalPrint.company = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
      await DocumentService.documentfiskalprint(fiskalPrint)

      await this.$store.dispatch('setprintSmall', true)
      if (withPartner === false) {
        await this.$store.dispatch('settypePOSPrint', 2)
      } else {
        await this.$store.dispatch('settypePOSPrint', 3)
      }
      if (this.$store.state.documentSide === 2 || this.$store.state.documentSide === 3) {
        this.$refs.prnt.print()
      }
    },
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    newPOS () {
      this.$store.dispatch('seteditItem', 0)
      this.$store.dispatch('setDocumentActivHead', {})
      this.$store.dispatch('setDocumentActivItems', [])
      this.$store.dispatch('settypePOS', 1)
      // this.$router.push({
      //     name: 'documentpos'
      //   })
    },
    async normalizeCash() {
      if ((this.posCash + this.posCard) > this.$store.state.documentActivHead.documentTotalAmountToPay) {
        this.posCash = await round(this.$store.state.documentActivHead.documentTotalAmountToPay, 2)
        this.posCard = 0
        this.cash = true
        this.cards = false
      }
    },
    async normalizeCard() {
      if ((this.posCard + this.posCash) > this.$store.state.documentActivHead.documentTotalAmountToPay) {
        this.posCard = await round(this.$store.state.documentActivHead.documentTotalAmountToPay, 2)
        this.posCash = 0
        this.cash = false
        this.cards = true
      }
    },
    async putOnCash() {
      if (this.cash === true) {
        if ((this.posCard) === this.$store.state.documentActivHead.documentTotalAmountToPay) {
          this.posCash = await round(this.$store.state.documentActivHead.documentTotalAmountToPay, 2)
          this.posCard = 0
          this.cards = false
          this.cash = true
        }
        if (this.posCard !==0 && (this.posCard < this.$store.state.documentActivHead.documentTotalAmountToPay)) {
          this.posCard = await round(this.$store.state.documentActivHead.documentTotalAmountToPay, 2) -  this.posCash
          this.cards = true
        }
        if (this.posCash === 0 && (this.posCard === this.$store.state.documentActivHead.documentTotalAmountToPay)) {
          this.posCard = await round(this.$store.state.documentActivHead.documentTotalAmountToPay, 2)
          this.cards = true
          this.cash = false
        }
      }
      if (this.cash === false) {
          this.posCash = 0
          this.posCard = await round(this.$store.state.documentActivHead.documentTotalAmountToPay, 2)
          this.cards = true
      }
    },
    async putOnCard() {
      if (this.cards === true) {
        if ((this.posCash) === this.$store.state.documentActivHead.documentTotalAmountToPay) {
          this.posCard = await round(this.$store.state.documentActivHead.documentTotalAmountToPay, 2)
          this.posCash = 0
          this.cards = true
          this.cash = false
        }
        if (this.posCash !== 0 && (this.posCash < this.$store.state.documentActivHead.documentTotalAmountToPay)) {
          this.posCard = await round(this.$store.state.documentActivHead.documentTotalAmountToPay, 2) -  this.posCash
          this.cards = true
        }
        if (this.posCash === 0 && (this.posCard === this.$store.state.documentActivHead.documentTotalAmountToPay)) {
          this.posCard = await round(this.$store.state.documentActivHead.documentTotalAmountToPay, 2)
          this.cards = true
          this.cash = false
        }
      }
      if (this.cards === false) {
          this.posCard = 0  
          this.posCash = await round(this.$store.state.documentActivHead.documentTotalAmountToPay, 2)
          this.cash = true
      }
    }

  },
  computed: {
    total () {
      return (this.$store.state.documentActivHead.documentTotalAmountToPay ? this.$store.state.documentActivHead.documentTotalAmountToPay : 0)
    },
    currency () {
      return (this.$store.state.documentActivHead.documentHomeCurrency ? this.$store.state.documentActivHead.documentHomeCurrency : 0)
    },    
  },
   components: {
    appDocumentPrintSales: DocumentPrintSales,
  },
  watch: {
    'cashReceived' () {
      if (this.cashReceived > 0) {
        this.cashToReturn = this.cashReceived - this.posCash
      } else {
        this.cashToReturn = 0
      }
    },
    'posCash' () {
      this.normalizeCash()
    },
    'posCard' () {
      this.normalizeCard()
    },
    'cards' () {
      this.putOnCard()
    },
    'cash' () {
      this.putOnCash()
    }
  }
}
</script>

<style>
.v-input--checkbox .v-label {
    font-size: 18px;
  }

</style>