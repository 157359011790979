<template>
  <div class="mainledgerprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'

export default {
  name: 'mainledgerprintcard5',
  props: ['card'],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0,
      partner: {},
      printObj: {},
      headline: ''
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    this.printObj = this.$store.state.printObj ? this.$store.state.printObj : {}
  },
  methods: {
    print () {
      console.log('Print card 5 ', this.card)
      const typePartner = this.card.fromAccount ? this.card.fromAccount.substr(0,2) : ''
      if (this.card.subtype && this.card.subtype === 5) {
        this.headline = typePartner === '12' ? this.lang.OpenStateBuyers : this.lang.OpenStateSupp
      }
      if (this.card.subtype && this.card.subtype === 6) {
        this.headline = typePartner === '12' ? this.lang.CardsBuyers : this.lang.CardsSupp
      }

      this.initDocument()
      this.addHeaderNoLogo()
      this.addLine()
      this.addItemsHeader()
      this.addLine()
      this.addItems()
      this.addItemsFoter()
      this.addLine()
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.content = []
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addLineDash () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1,
                dash: { length: 1 },
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '35%',
                text: [
                  { text: this.comp.CompanyNameShort + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // % width
                // { text: ' Od datuma: ' + dayjs(this.card.docFromDate).format('DD.MM.YYYY')  + ' Do datuma: ' + dayjs(this.card.docToDate).format('DD.MM.YYYY'), fontSize:8}
                width: '45%',
                text: [
                  { text:  this.headline + '\n', fontSize: 18, bold: true },
                  { text: ' Od konta : ' + this.card.fromAccount , fontSize: 8},
                  { text: ' Do konta : ' + this.card.toAccount+ '\n', fontSize: 8},
                  { text: ' U izvještaj su uključeni podaci od početka poslovne godine do: \n', fontSize: 8},
                  { text: this.printObj && this.printObj.head1 ? this.printObj.head1 + '\n' : '' , fontSize: 8},
                  { text: this.printObj && this.printObj.head2 ? this.printObj.head2 + '\n' : '' , fontSize: 8},
                  { text: this.printObj && this.printObj.head3 ? this.printObj.head3 + '\n' : '' , fontSize: 8},
                ]   
              },
              {
                // % width
                width: '20%',
                text: [                 
                  { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addItemsHeader () {
        const docPart = {
        columns: [
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: 'Rb.'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: 'Konto'
              },
              {
                width: '25%',
                fontSize: 8,
                alignment: 'left',
                text: 'Partner - naziv'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: 'OIB'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'right',
                text: 'Duguje'
              },
              {
                width: '10%',
                fontSize: 7,
                alignment: 'right',
                text: 'Potražuje'
              },
              {
                width: '10%',
                fontSize: 7,
                alignment: 'right',
                text: 'Saldo'
              },
            ]
          }
        this.content.push(docPart)        
    },
    addItems () {
           this.order = 0           
            if (this.card.items && this.card.items.length > 0) {
              this.card.items.map(item => {
                  this.order += 1
                  // this.pTtt5 = item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0
                  // this.pTtt6 = item.itemDiscountPercentage1 ? this.formatPrice(item.itemDiscountPercentage1) : 0
                  // this.pTtt7 = item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0
                  // this.pTtt8 = item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0
                  // this.pTtt9 = item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0

                      const docPart01 = {
                      columns: [
                            {
                              width: '10%',
                              fontSize: 8,
                              alignment: 'left',
                              text: item.itLine
                            },
                            {
                              width: '10%',
                              fontSize: 8,
                              alignment: 'left',
                              text: item.account4
                            },
                            {
                              width: '25%',
                              fontSize: 8,
                              alignment: 'left',
                              text: item.partnerName
                            },
                            {
                              width: '10%',
                              fontSize: 8,
                              alignment: 'left',
                              text: item.partnerVATID
                            },
                            {
                              width: '10%',
                              fontSize: 8,
                              alignment: 'right',
                              text: this.formatPrice(item.totalDebit)
                            },
                            {
                              width: '10%',
                              fontSize: 8,
                              alignment: 'right',
                              text: this.formatPrice(item.totalCredit)
                            },
                            {
                              width: '10%',
                              fontSize: 8,
                              alignment: 'right',
                              text: this.formatPrice(item.CreditDebit)
                            },
                          ]
                        }
                      this.content.push(docPart01)
                }
              )
          }
    },
    addItemsFoter () {
       
      this.addLine()

      const docPart01 = {
           columns: [
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '25%',
                fontSize: 8,
                alignment: 'left',
                text: 'UKUPNO:'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'right',
                text: ' '
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.card.Total1)
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.card.Total2)
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.card.Total5)
              },
          ]
        }
      this.content.push(docPart01)
    },
  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>