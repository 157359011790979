import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('salariedetail', {
      params: query
    })
  },
  indexlist(search) {
    return Api().get('salariedetail/list', {
      params: search
    })
  },
  choose (query) {
    return Api().get('salariedetail/choose', {
      params: query
    })
  },
  show (id) {
    return Api().get(`salariedetail/${id}`)
  },
  post (newsalariedetail) {
    return Api().post('salariedetail', newsalariedetail)
  },
  put (salariedetail) {
    return Api().put(`salariedetail/${salariedetail.id}`, salariedetail)
  },
  delete (id) {
    return Api().delete(`salariedetail/${id}`)
  },
  saveitems (newitems) {
    return Api().post('salariedetail/saveitems', newitems)
  },
}