<template>
  <div class="task">
     <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
      <v-card>
        <v-card-title>
          <span v-if="docSide === 1"> {{lang.SuppBookInv }} </span>
          <span v-if="docSide === 2"> {{lang.BuyerBook }} </span>
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="documentId"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
          >

          <template v-slot:[`item.BookID`]="{ item }">
              <span>{{ parseInt(item.BookID) }}</span>
          </template>
          <template v-slot:[`item.ClosedAccount`]="{ item }">
            <span v-if="item.ClosedAccount"><v-icon>mdi-check</v-icon></span>
          </template>

          <template v-slot:[`item.BookingDate`]="{ item }">
              <span>{{ localDate(item.BookingDate) }}</span>
          </template>
          <template v-slot:[`item.InvoiceDate`]="{ item }">
              <span>{{ localDate(item.InvoiceDate) }}</span>
          </template>
          <template v-slot:[`item.MLFree2`]="{ item }">
            <span v-if="item.MLFree2"><v-icon>mdi-check</v-icon></span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>
  </div>
</template>

<script>
// import axios from 'axios'
import MainLedgerService from '@/services/MainLedgerService'
//import MainLedgerDetailService from '@/services/MainLedgerDetailService'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'

export default {
  name: 'MainLedgerlistFast',
  props: ['partnerId', 'docSide'],
  data () {
    return {
      documentName: '',
      documentNameLoc: '',
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false
    }
  },
  async mounted () {
    
    if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    if (this.$store.state.businessYear) {
      this.mainQuery.businessYear = this.$store.state.businessYear
    }
    if (this.docSide) {
      this.mainQuery.MLType = this.docSide
    }
    if (this.partnerId) {
      this.mainQuery.partnerId = this.partnerId
    }

    this.saving = true
    await MainLedgerService.index(this.mainQuery)
    .then(res => {
      // eslint-disable-next-line
      console.log(' res.data  ', res.data.documents)
      this.items = res.data.documents ? res.data.documents : []
    })
    .catch(err => {
      // eslint-disable-next-line
      console.log(' have error  ', err)
    })
    //this.items = (await MainLedgerService.index(this.mainQuery)).data.documents
    this.saving = false
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    if (this.$store.state.documentSide === 1) {
      this.headers.push(
        {
          text: this.lang.BookID, align: 'left', sortable: true, value: 'BookID', width: '60px'
        },
        { text: this.lang.ClosedAccount, align: 'left', sortable: true, value: 'ClosedAccount', width: '50px' },
        { text: this.lang.BookTypeID, align: 'left', sortable: true, value: 'BookName', width: '50px' },
        { text: this.lang.BookingDate, align: 'left', sortable: true, value: 'BookingDate', width: '80px' },
        { text: this.lang.InvoiceNumber, align: 'center', value: 'InvoiceNumber', width: '100px' },
        { text: this.lang.InvoiceDate, align: 'left', sortable: true, value: 'InvoiceDate', width: '80px' },
        { text: this.lang.InvoiceAmount, align: 'right', sortable: true, value: 'InvoiceAmount', width: '100px' },
        { text: this.lang.HomeCurrency, align: 'left', sortable: true, value: 'HomeCurrency', width: '40px' },
        { text: this.lang.InvoiceForeginAmount, align: 'right', sortable: true, value: 'InvoiceForeginAmount', width: '100px' },
        { text: this.lang.ForeignCurrency, align: 'left', sortable: true, value: 'ForeignCurrency', width: '40px' },
        { text: this.lang.PartnerName, align: 'left', value: 'partnerName', width: '260px' },
        { text: this.lang.PartnerVATID, align: 'left', value: 'partnerVATID', width: '80px' },
        { text: this.lang.MLFree2, align: 'left', value: 'MLFree2', width: '80px' },
        { text: 'DbId', value: 'id', width: '60px' }
      )
    }
    if (this.$store.state.documentSide === 2) {
      this.headers.push(
        {
          text: this.lang.BookID2, align: 'left', sortable: true, value: 'BookID', width: '60px'
        },
        { text: this.lang.ClosedAccount, align: 'left', sortable: true, value: 'ClosedAccount', width: '50px' },
        { text: this.lang.BookTypeID, align: 'left', sortable: true, value: 'BookName', width: '50px' },
        { text: this.lang.BookingDate, align: 'left', sortable: true, value: 'BookingDate', width: '80px' },
        { text: this.lang.InvoiceDate, align: 'left', sortable: true, value: 'InvoiceDate', width: '80px' },
        { text: this.lang.InvoiceAmount, align: 'right', sortable: true, value: 'InvoiceAmount', width: '100px' },
        { text: this.lang.HomeCurrency, align: 'left', sortable: true, value: 'HomeCurrency', width: '40px' },
        { text: this.lang.InvoiceForeginAmount, align: 'right', sortable: true, value: 'InvoiceForeginAmount', width: '100px' },
        { text: this.lang.ForeignCurrency, align: 'left', sortable: true, value: 'ForeignCurrency', width: '40px' },
        { text: this.lang.PartnerName, align: 'left', value: 'partnerName', width: '260px' },
        { text: this.lang.PartnerVATID, align: 'left', value: 'partnerVATID', width: '80px' },
        { text: this.lang.MLFree2, align: 'left', value: 'MLFree2', width: '80px' },
        { text: 'DbId', value: 'id', width: '60px' }
      )
    }
    if (this.$store.state.documentSide === 3 || this.$store.state.documentSide === 4) {
      this.headers.push(
        {
          text: this.lang.BookID, align: 'left', sortable: true, value: 'BookID'
        },
        { text: this.lang.ClosedAccount, align: 'left', sortable: true, value: 'ClosedAccount' },
        { text: this.lang.BookTypeID, align: 'left', sortable: true, value: 'BookName' },
        { text: this.lang.BookingName, align: 'left', sortable: true, value: 'BookName' },
        { text: this.lang.BookingDate, align: 'left', sortable: true, value: 'BookingDate' },
        { text: this.lang.InvoiceNumber, align: 'center', value: 'InvoiceNumber' },
        { text: this.lang.MLFree1, align: 'left', sortable: true, value: 'MLFree1' },
        { text: this.lang.DebitTotal, align: 'left', sortable: true, value: 'DebitTotal' },
        { text: this.lang.CreditTotal, align: 'left', sortable: true, value: 'CreditTotal' },
        { text: 'DbId', value: 'id' }
      )
    }

  },
  methods: {
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      },
  },
  computed: {
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
