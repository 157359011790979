const docEN = {
  EmployeeList: 'Employee list',
  EmployeeCreate: 'Employee create',
  EmployeeDetails: 'Employee details',
  BasicData: 'Basic data',
  AdditionalData: 'Additional Data',
  WorkStatus: 'Work status',
  WorkEnvironment: 'Work environment',
  CompanyName: 'Company ',
  Edit2: 'Edit salarie',
  Edit3: 'Edit aditional data',
  Tasks: 'Tasks',
  hashtag: '#hashtag *',
  eemail: 'email',
  phone: 'Phone',
  First: 'First name *',
  Last: 'Last name *',
  Gender: 'Gender',
  VatID: 'VAT ID *',
  PersonalID1: 'ID number of document',
  PersonalID2: 'Health insurance ID',
  PersonalID3: '',
  PersonalID4: '',
  PersonalID5: '',
  DateOfBirht: 'Date of birth',
  PlaceOfBirht: 'Place Of Birht',
  PlaceOfBirthState: 'Place Of Birht State',
  Foriner: 'Foriner',
  ForinerLicense: 'Foriner License',
  PlaceOfLive: 'Place of live',
  Occupation: 'Occupation',
  Degree: 'Degree',
  SpecialKnowledge: 'Special Knowledge',
  StartInCompany: 'Start in Company',
  WorkForOtherCompany: 'Work For Other Company',
  WorkForOtherCompanyAbroad: 'Work For Other Company Abroad',
  WorkForOtherConnectedCompany: 'Work For Other Connected Company',
  WorkHardConditions: 'Work Hard Conditions',
  WorkPlace: 'WorkPlace',
  WeeklyWorkHours: 'Weekly Work Hours',
  NotWorkingTime: 'Not Working Time',
  EndDateInCompany: 'End date in company',
  EndReason: 'End Reason',
  OtherData: 'Other data',
  InternalNotes: 'Internal notes',
  Activ: 'Activ',
  Black: 'Work off line',
  WorkBefore: 'Work Before',
  WorkYearsBefore: 'Work Years Before',
  WorkMonthsBefore: 'Work Months Before',
  WorkDaysBefore: 'Work Days Before',
  WorkInCityCodeID: 'Work In City Code ID',
  WorkInCityCode: 'Work In City Code',
  WorkInCityName: 'Work In City Name',
  Pregnancy: 'Pregnancy',
  Maternity: 'Maternity',
  Breastfeeding: 'Breastfeeding',
  OnlyParent: 'Only Parent',
  AdoptionStatus: 'Adoption Status',
  ProfessionalIllnes: 'Professionall Illnes',
  HurtOnWork: 'Hurt On Work',
  ProfessionalCantWork: 'Professionall Cant Work',
  ProfessionalLowerAbbilities: 'Professionall Lower Abbilities',
  ProfessionalLoseWrokAbbility: 'Professionall Lose Wrok Abbility',
  DangerOfLosingProfAbbilityInjury: 'Danger Of Losing Prof Abbility Injury',
  DangerOfHaveDisability: 'Danger Of Have Disability',
  Disability: 'Disability',
  DisabilityPension: 'Disability Pension',
  DisabilityWorkTIme: 'Disability Work Time',
  WorkForOtherEmployee: 'Work For Other Employee',
  PersonalData: 'Personal',
  PersonalData2: 'Height, Shirt, Pants, Shoes',
  PersonalHeight: 'Personal Height',
  PersonalSizeUp: 'Personal Size Shirt',
  PersonalSizeDown: 'Personal Size Pants',
  PersonalShoes: 'Personal Shoes',
  EmployeeDiaryCreate: 'Employee Diary Create',
  Dateindatabase: 'Date in database',
  Employee: 'Employee',
  SelectEmployee: 'Select Employee',
  ActionType: 'Action type',
  ActionTypeSelect: 'Select Action Type',
  WorkType: 'Work type',
  WorkTypeSelect: 'Select Work Type',
  WorkHours: 'Hours',
  WorkMinutes: 'Minutes',
  ForDate: 'Date change status',
  AlarmDate: 'Date end status',
  HealthServiceID: 'Health Service ID',
  PensionFundID: 'Pension Fund ID',
  EmplDayFree1: 'Kratki opis upisa',
  SalarieDefinition: 'Salarie definitio',
  Bruto1: 'Bruto 1 €',
  Add1:'Addition 1',
  Add2:'Addition 2',
  Bruto2: 'Bruto 2 €',
  SalFrom: 'From',
  SalFrom1: 'From 1',
  SalFrom1Desc: 'Description 1',
  SalFrom1Per: '%',
  SalFrom2: 'From 2',
  SalFrom2Desc: 'Description 2',
  SalFrom2Per: '%',
  SalFrom2Amount: 'Amount €',
  FinNeto1ls: 'Neto 1 €',
  Deduction: 'Personal Deduction',
  DeductionKids: 'Deduction kids',
  DeductionFamily: 'Deduction family',
  DeductionDisablity: 'Deduction disability',
  DeductionFactor: 'Factor',
  DeductionAmount: 'Deduciton Amount',
  DeductionUse: '% usage',
  DeductionFinalAmounut: 'Deductin Final Amount',
  DeductionDontUse: 'Dont use deduction',
  DeductionDontUseAtAll: 'Dont use at all',
  CityTaxPer: 'Percent',
  CityTaxId: 'City Code',
  CityTaxName: 'City Name',
  Neto: 'Neto Salarie €',
  NetoAdd: 'No tax addition',
  NetoAddDesc: 'Description',
  NetoAddType: 'Type',
  NetoAddTypeName: 'Type Name',
  NetoAddJoppd:'Joppd column',
  NetoAddAmounut: 'Amount add',
  FinNeto3: 'To pay out',
  SalToHead: 'To',
  SalTo: 'To',
  SalToDesc: 'To description',
  SalToPer: '%',
  SalToAmount: 'Amount €',
  TaxTax: 'Tax and Tax on Tax',
  Tax20: 'Tax 20% base',
  Tax20Desc: '',
  Tax20Am: 'Tax 20% amount',
  Tax30: 'Tax 30% base',
  Tax30Desc: '',
  Tax30Am: 'Tax 30% amount',
  TaxOnTax: 'Tax on Tax %',
  TaxOnTaxAm: 'Tax on Tax Amount',
  TaxTotal: 'Tax Total',
  Bruto3: 'Total amount for salaire €'
}

export default docEN