import store from '../../store/store'

const defWorkHour = () => {
  const def = {}
  def.EmployeeId = ''
  def.email = ''
  def.First = ''
  def.Last = ''
  def.ForWeek = 0
  def.ForMonth = 0
  def.ForYear = 0
  def.WDate = null
  def.WDay = ''
  def.PocetakRada = 7
  def.ZavrsetakRada = 15
  def.RedovanRad = 0
  def.NocniRad = 0
  def.PrekovremeniRad = 0
  def.NedjeljaRad = 0
  def.BlagdanRad = 0
  def.TerenRad = 0
  def.PreraspodjelaRada = 0
  def.Pripravnost = 0
  def.DnevniOdmor = 0
  def.TjedniOdmor = 0
  def.NeradniBlagdan = 0
  def.GodisnjiOdmor = 0
  def.BolovanjeDo42Dana = 0
  def.BolovanjeOd42Dana = 0
  def.Rodiljni = 0
  def.PlaceniDopust = 0
  def.NeplaceniDopust = 0
  def.NenazocnostNaZahtjevRadnika = 0
  def.ZastojKrivnjomPoslodavca = 0
  def.Strajk = 0
  def.Lockout = 0
  def.VrijemeMirovanjaRadnogOdnosa = 0
  def.Ukupno = 0
  return def
}

const defNeWWorkHour = () => {
  const def = {}
  def.CompanyId = store.state.companyid ? store.state.companyid : 1
  def.CompanyNameDatabase = store.state.companyName ? store.state.companyName : ''
  def.BusinessYear = store.state.businessYear ? store.state.businessYear : 2020
  def.UserId = store.state.user.id ? store.state.user.id : ''
  return def
}

export {
  defWorkHour,
  defNeWWorkHour,
}