import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('account4', {
      params: query
    })
  },
  index2 (query) {
    return Api().get('account4/index2', {
      params: query
    })
  },
  firstaccount4 (query) {
    return Api().get('account4/firstaccount4', {
      params: query
    })
  },
  lastaccount4 (query) {
    return Api().get('account4/lastaccount4', {
      params: query
    })
  },
  choose (query) {
    return Api().get('account4/choose', {
      params: query
    })
  },
  show (account4Id) {
    return Api().get(`account4/${account4Id}`)
  },
  post (newaccount4) {
    return Api().post('account4', newaccount4)
  },
  put (account4) {
    return Api().put(`account4/${account4.id}`, account4)
  },
  delete (account4Id) {
    return Api().delete(`account4/${account4Id}`)
  }
}
