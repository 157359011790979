<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col  cols="12" sm="2" class="ml-2 mr -2" >
        {{lang.llaBuyingValue}}: <br >
          {{llaBuyingValue ? formatPrice(llaBuyingValue) : 0}}
      </v-col>              
      <v-col  cols="12" sm="2" class="ml-2 mr -2" >
        {{lang.llaDepreciationAmountTotal}}:<br>
          {{llaDepreciationAmountTotal ? formatPrice(llaDepreciationAmountTotal) : 0}}
      </v-col> 
      <v-col  cols="12" sm="2" class="ml-2 mr -2" >
        {{lang.llaBookValue}}:<br>
          {{llaBookValue ? formatPrice(llaBookValue) : 0}}
      </v-col> 
      <v-col  cols="12" sm="2" class="ml-2 mr -2" >
        {{lang.llaDepreciationAmountYear}}:<br>
          {{llaDepreciationAmountYear ? formatPrice(llaDepreciationAmountYear) : 0}}
      </v-col> 
      <v-col  cols="12" sm="2" class="ml-2 mr -2" >
        {{lang.llaValueYearEnd}}:<br>
          {{llaValueYearEnd ? formatPrice(llaValueYearEnd) : 0}}
      </v-col> 
    </v-row>
  </v-container>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import { parseNum } from '@/snippets/allSnippets'

export default {
  name: 'mainledgerLLAtotals',
  props:['users'],
  data () {
    return {
      lang: {},
      langC: {},
      saving: false,
      llaBuyingValue: 0,
      llaDepreciationAmountTotal: 0,
      llaBookValue: 0,
      llaDepreciationAmountYear: 0,
      llaValueYearEnd: 0,
    }
  },
  components: {
  },
  mounted () {
      if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      this.mainledger = this.$store.state.mainledgerActiv ? this.$store.state.mainledgerActiv : {}
      this.takeTotals()
  },
  computed: {

  },
  watch: {

  },
  methods: {
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    close () {
      this.show = false
    },
    async takeTotals() {   
      if (this.users) {
        this.users.map(item => {
          if (!item.mark) {
            this.llaBuyingValue += parseNum(item.llaBuyingValue)
            this.llaDepreciationAmountTotal += parseNum(item.llaDepreciationAmountTotal)
            this.llaBookValue += parseNum(item.llaBookValue)
            this.llaDepreciationAmountYear += parseNum(item.llaDepreciationAmountYear)
            this.llaValueYearEnd += parseNum(item.llaValueYearEnd)
          }
        })
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>

