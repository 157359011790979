<template>
<div>
  <v-container fluid grid-list-md>

  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="users"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="50"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Opzstat stavke</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="700px">

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>


          <v-card>
            <v-card-title>
              <span class="headline">Tvrtka</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >

                  <v-row dense>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-1">
                     <v-text-field
                        dense
                        label="Ozn.por.br. *"
                        v-model="editedItem.K2"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <v-text-field
                        dense
                        label="Porezni broj *"
                        v-model="editedItem.K3"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                     <v-text-field
                        dense
                        label="Naziv /Ime i prezime kupca *"
                        v-model="editedItem.K4"
                      ></v-text-field>
                    </v-col>
                  </v-row>


                  <v-row dense>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        label="Broj računa"
                        v-model="editedItem.R2"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <v-dialog
                        ref="dialog1"
                        persistent
                        v-model="modal1"                   
                        width="290px"
                        :return-value.sync="editedItem.R3"
                      >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedItem.R3"
                          label="Datum računa"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                        <v-date-picker v-model="editedItem.R3" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" @click="modal1 = false">{{langC.Cancel}}</v-btn>
                          <v-btn color="primary" @click="$refs.dialog1.save(editedItem.R3)">OK</v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>

                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <v-dialog
                        ref="dialog2"
                        persistent
                        v-model="modal2"                   
                        width="290px"
                        :return-value.sync="editedItem.R4"
                      >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedItem.R4"
                          label="Valuta plaćanja računa"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                        <v-date-picker v-model="editedItem.R4" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" @click="modal2 = false">{{langC.Cancel}}</v-btn>
                          <v-btn color="primary" @click="$refs.dialog2.save(editedItem.R4)">OK</v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                     <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
   
                    </v-col>
                  </v-row>


                  <v-row dense>

                    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
                      <v-text-field
                        dense
                        label="Iznos računa"
                        v-model.number="editedItem.R6"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
                      <v-text-field
                        dense
                        label="Iznos PDVa"
                        v-model.number="editedItem.R7"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
                      <v-text-field
                        dense
                        label="Ukupan iznos računa"
                        v-model.number="editedItem.R8"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
                      <v-text-field
                        dense
                        label="Plaćeni iznos"
                        v-model.number="editedItem.R9"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
                      <v-text-field
                        dense
                        label="Neplaćeni"
                        v-model.number="editedItem.R10"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Odustani</v-btn>
                    <v-btn color="success" text @click="save">Spremi</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>


    <template v-slot:[`item.R3`]="{ item }">
        <span>{{ localDate(item.R3) }}</span>
    </template>
    <template v-slot:[`item.R4`]="{ item }">
        <span>{{ localDate(item.R4) }}</span>
    </template>

    <template v-slot:[`item.R6`]="{ item }">
        <span>{{ localMoney(item.R6) }}</span>
    </template>
    <template v-slot:[`item.R7`]="{ item }">
        <span>{{ localMoney(item.R7) }}</span>
    </template>
    <template v-slot:[`item.R8`]="{ item }">
        <span>{{ localMoney(item.R8) }}</span>
    </template>
    <template v-slot:[`item.R9`]="{ item }">
        <span>{{ localMoney(item.R9) }}</span>
    </template>
    <template v-slot:[`item.R10`]="{ item }">
        <span>{{ localMoney(item.R10) }}</span>
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
       Nema Podataka
    </template>
  </v-data-table>

  <v-row class="mt-5">

    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
      <v-text-field
        dense
        label="Iznos računa"
        v-model.number="tR6"
        type="number"
        disabled
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
      <v-text-field
        dense
        label="Iznos PDVa"
        v-model.number="tR7"
        type="number"
        disabled
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
      <v-text-field
        dense
        label="Ukupan iznos računa"
        v-model.number="tR8"
        type="number"
        disabled
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
      <v-text-field
        dense
        label="Plaćeni iznos"
        v-model.number="tR9"
        type="number"
        disabled
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
      <v-text-field
        dense
        label="Neplaćeni"
        v-model.number="tR10"
        type="number"
        disabled
      ></v-text-field>
    </v-col>
  </v-row>
  </v-container>
  </div>
</template>

<script>
import OpzstatItemsService from '@/services/OpzstatItemsService'
import {defOpzstatitems, defNewOpzstatitems} from './formhelper'
import moneyFormat from '@/snippets/moneyFormat'
import dateFormat from '@/snippets/dateFormat'

  export default {
    name: 'opzstatitemsCRUD',
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      menu2: false,
      modal1: false,
      dialog1: false,
      modal2: false,
      dialog2: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {},
      dialog: false,
      headers: [],
      users: [],
      editedIndex: -1,
      editedItem: { },
      defaultItem: {},
      privitems: [],
      valid: true,
      tR6: 0,
      tR7: 0,
      tR8: 0,
      tR9: 0,
      tR10: 0,
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewCity: this.lang.EditCity
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },
    components: {

    },
    beforeMount() {
      this.defaultItem  = defOpzstatitems()
      this.defaultItem  = {...this.defaultItem , ...defNewOpzstatitems()}

      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentid) {
        this.mainQuery.OpzstatheadID = this.$store.state.documentid
      }
      this.initialize()
    },
    mounted() {

      this.headers.push(
        {text: 'Oznaka Poreznog broja', value: 'K2', width: '50px', sortable: true},
        {text: 'Porezni broj', value: 'K3', width: '120px', sortable: true},
        {text: 'Naziv', value: 'K4', width: '150px', sortable: true},
        {text: 'Broj izdanog računa', value: 'R2', width: '120px', sortable: true},
        {text: 'Datum izdanog računa', value: 'R3', width: '110px'},
        {text: 'Valuta plaćanja računa', value: 'R4', width: '110px'},
        {text: 'Iznos računa', value: 'R6', width: '110px', align: 'right'},
        {text: 'Iznos PDVa', value: 'R7', width: '110px', align: 'right'},
        {text: 'Ukupan iznos računa sa PDVom', value: 'R8', width: '110px', sortable: true, align: 'right'},
        {text: 'Plaćeni iznos računa', value: 'R9', width: '110px', align: 'right'},
        {text: 'Neplaćeni iznos računa', value: 'R10', width: '110px', align: 'right'},
        {text: 'Uredi', value: 'action', sortable: false, width: '80px' },
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
      )
      
    },

    created () {

    },

    methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      async initialize () {
        try {
          this.saving = true
          console.log('mainQuery ', this.mainQuery)
          const {data} = await OpzstatItemsService.index(this.mainQuery)
          if (data && data.opzstatitems !== undefined){
            this.users = data.opzstatitems
            if (data.opzstatitems) {
              data.opzstatitems.map(user => {
                this.tR6 += user.R6
                this.tR7 += user.R7
                this.tR8 += user.R8
                this.tR9 += user.R9
                this.tR10 += user.R10
              })
            }
          }
          this.saving = false
          this.errorMatch = false
          this.editedItem = Object.assign({}, this.defaultItem)
        } catch (err) {
          this.error = err
        }
      },
      editItem (item) {
        this.errorMatch = false
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      async deleteItem (item) {
        try {
          
          const index = this.users.indexOf(item)
          const warningMessage = item.Tvrtka
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.users.splice(index, 1)) {
            await OpzstatItemsService.delete(item.id)
            this.initialize()
          }
        } catch(err) {
          this.error = err
        }
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      async save () {
        try {
           if (!this.editedItem.K3) {
             alert('GREŠKA!! Nije upisana OIB !')
             return
           }
           if (!this.editedItem.K4) {
             alert('GREŠKA!! Nije upisan naziv!')
             return
           }
           if (!this.editedItem.R2) {
             alert('GREŠKA!! Nije upisan broj racuna!')
             return
           }
           if (!this.editedItem.R3) {
             alert('GREŠKA!! Nije upisan datum racuna!')
             return
           }
           if (!this.editedItem.R4) {
             alert('GREŠKA!! Nije upisana valuta!')
             return
           }

           let doneOK = false
           this.editedItem.LastUpdateBy = this.$store.state.user.email ? this.$store.state.user.email : 'test@cegrupa.com'
           this.editedItem.LastUpdate = new Date()
           this.editedItem.R10 = (this.editedItem.R8 ? this.editedItem.R8 : 0) - (this.editedItem.R9 ? this.editedItem.R9 : 0)
           if (this.editedIndex > -1) {
              await OpzstatItemsService.put(this.editedItem)
              doneOK = true
            } else {
              await OpzstatItemsService.post(this.editedItem)
              doneOK = true
            }
            if (doneOK) {
              this.initialize()
              this.close()
            }
        } catch(err) {
          this.error = err
          
        }
       
      },
      localMoney(moneyToFormat) {
        return moneyFormat(moneyToFormat)
      },
      localDate(dateToFormat) {
        return dateFormat(dateToFormat)
      },
    },
  }
</script>