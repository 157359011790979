<template>
  <div class="mainledgerprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ledgerEN from '../MainLedger/MainLedgerDescEN'
import ledgerHR from '../MainLedger/MainLedgerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'
//import PartnerService from '@/services/PartnerService'
//import {parseNum, round} from '@/snippets/allSnippets'

export default {
  name: 'mainbookcompcardprint',
  props: ['card', 'fTotal1', 'fTotal2', 'fTotal3', 'fTotal4', 'fTotal5', 'fTotal6', 'type', 'defCurrency'],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0,
      partner: {},
      debitTotal: 0,
      creditTotal: 0,
      debitCreditTotal: 0,
      headline: '',
      page: 1,
      pages: 1
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    

  },
  methods: {
    print () {
      this.page = 1
      const locItems = JSON.parse(JSON.stringify(this.card.items))
      this.pages = Math.floor(locItems.length / 60) + 1
      this.headline = this.type === 1 ? 'Dobavljači' : ' Kupci'
      this.initDocument()
      this.addHeaderNoLogo()
      this.addLine()
      this.addItems()
      this.addLine()
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.content = []
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addLineDash () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1,
                dash: { length: 1 },
              }
            ]
          }
      this.content.push(docPart)
    },
    skipNewPage() {
      const docPart01 = {
      columns: [
          {
            width: '40%',
            fontSize: 7,
            bold: true,
            alignment: 'left',
            text: '  ',
            pageBreak: 'after'
          },
        ]
      }
      this.content.push(docPart01)
    },
    addSmallHeader() {
            const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '35%',
                text: [
                  { text: this.comp.CompanyNameShort + ' \n', fontSize: 12, bold: true },
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text:  'Stanje - ' + this.headline + '\n', fontSize: 12, bold: true },
                ]   
              },
              {
                // % width
                width: '20%',
                text: [                 
                  { text: ' Stranica: ' + this.page+ '/' + this.pages, fontSize:8}
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '35%',
                text: [
                  { text: this.comp.CompanyNameShort + ' \n', fontSize: 14, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text:  'Stanje - ' + this.headline + '\n', fontSize: 18, bold: true },
                  { text:  'Valuta: ' + this.defCurrency + '\n', fontSize: 8 },
                  { text: ' Od datuma: ' + dayjs(this.card.docFromDate).format('DD.MM.YYYY')  + ' Do datuma: ' + dayjs(this.card.docToDate).format('DD.MM.YYYY'), fontSize:8}
                  // { text: ' računi do datuma: ' + dayjs(new Date()).format('DD.MM.YYYY'), fontSize:8}
                ]   
              },
              {
                // % width
                width: '20%',
                text: [                 
                  { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a') + '\n', fontSize:8},
                  { text: ' Stranica: ' + this.page + '/' + this.pages, fontSize:8}
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
        this.addLine()
        this.addItemsHeader()
        this.addItemsHeader2()
        const docPart02 = {
          columns: [
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '40%',
                fontSize: 8,
                alignment: 'right',
                bold: true,
                text: 'TOTAL:' + this.defCurrency
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                bold: true,
                text: this.formatPrice(this.fTotal1) 
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                bold: true,
                text: (this.type ===1 ? this.formatPrice(this.fTotal3) : this.formatPrice(this.fTotal2))
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                bold: true,
                text: (this.type ===1 ? this.formatPrice(this.fTotal2) : this.formatPrice(this.fTotal3))
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                bold: true,
                text: this.formatPrice(this.fTotal4)
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                bold: true,
                text: this.formatPrice(this.fTotal5)
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                bold: true,
                text: this.formatPrice(this.fTotal6)
              },
          ]
        }
        this.content.push(docPart02)
        this.addLine()

    },
    addItemsHeader () {
        const docPart = {
        columns: [
              {
                width: '5%',
                fontSize: 8,
                alignment: 'left',
                text: 'Rb.'
              },
              {
                width: '43%',
                fontSize: 8,
                alignment: 'left',
                text: 'Partner'
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                text: 'Uk.računi' + ' ' + this.defCurrency
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                text: (this.type ===1 ? 'Uplate' : 'Računi dos.') 
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                text: (this.type ===1 ? 'Računi dos.' : 'Uplate' )
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                text: 'Saldo dosp.'
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                text: 'Nedospjelo'
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                text: 'Ukupni dug'
              },
            ]
          }
        this.content.push(docPart)        
    },
    addItemsHeader2 () {
        const docPart = {
        columns: [
              {
                width: '5%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '43%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                text: '1 (2+5)'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: '2'
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                text: '3'
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                text: '4'
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                text: '5'
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                text: '6(4+5)'
              },
            ]
          }
        this.content.push(docPart)        
    },
    addItems() {
      if (this.card.items) {
              this.order = 0
              let rows = 0
              const locItems = JSON.parse(JSON.stringify(this.card.items))
              locItems.map(item => {
                  this.order += 1
                  rows +=1
                  if (rows > 61) {
                    this.page +=1
                    this.addLine()
                    this.skipNewPage()
                    this.addSmallHeader()
                    this.addLine()
                    this.addItemsHeader()
                    this.addItemsHeader2()
                    this.addLine()
                    rows =0
                  }
                  const docPart = {
                  columns: [
                    {
                      width: '5%',
                      fontSize: 7,
                      alignment: 'left',
                      text: this.order
                    },
                    {
                      // % width
                      width: '43%',
                      text: [
                        { text: (item.partner && item.partner.partnerName ? item.partner.partnerName.substr(0,40) : '' ) + ' \n', fontSize: 10},
                      ]   
                    },
                    {
                      width: '8%',
                      fontSize: 7,
                      alignment: 'right',
                      text: this.formatPrice(item.allInv)
                    },
                    {
                      width: '8%',
                      fontSize: 7,
                      alignment: 'right',
                      text: (this.type === 1 ? this.formatPrice(item.allPay) :this.formatPrice(item.allDue) ) 
                    },
                    {
                      width: '8%',
                      fontSize: 7,
                      alignment: 'right',
                      text: (this.type === 1 ? this.formatPrice(item.allDue) :this.formatPrice(item.allPay) )
                    },
                    {
                      width: '8%',
                      fontSize: 7,
                      alignment: 'right',
                      text: this.formatPrice(item.saldo)
                    },
                    {
                      width: '8%',
                      fontSize: 7,
                      alignment: 'right',
                      text: this.formatPrice(item.allNotDue)
                    },
                    {
                      width: '8%',
                      fontSize: 7,
                      alignment: 'right',
                      text: this.formatPrice(item.allOpen)
                    },
                  ],
                }
                this.content.push(docPart)   
              })
      }
    },


  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
