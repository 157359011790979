
<template>
  <v-container>
    <v-layout row wrap  justify-center>
      <v-flex xs12 sm10 md8 lg6>
        <v-card ref="form">
          <v-card-text>

              <v-layout row >
                <v-flex xs12 class="py-0 px-2">
                  <h2>{{lang.EmployeeDiaryCreate}}</h2>
                </v-flex>
              </v-layout>

              <v-layout row >
                <v-flex xs4 class="py-0 px-2">                
                  {{langC.Company}}
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{emplday.CompanyNameDatabase}}, Id:{{emplday.CompanyId}}</span>
                </v-flex>
              </v-layout>

                <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                  {{langC.BusinessYear}}                 
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{emplday.BusinessYear}}</span>
                </v-flex>
              </v-layout>

              <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                  {{langC.User}}                 
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{emplday.email}}, {{emplday.UserId}}</span>
                </v-flex>
              </v-layout>

              <v-divider class="pa-3 mt-3"></v-divider>

              <v-layout row >
                <v-flex xs3 class="py-0 px-2">
                  {{lang.Dateindatabase}}                 
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                  <input type="date" v-model="emplday.OpenDate" />
                </v-flex>
              </v-layout>  



                <v-layout row >
                  <v-flex xs3 class="py-4 px-2">
                    {{lang.Employee}}                    
                  </v-flex>
                  <v-flex xs9 class="py-0 px-2">
                    <v-autocomplete
                      :items="employes"
                      v-model="selectEm"
                      :label="lang.SelectEmployee"
                      single-line
                      item-text="hashtag"
                      item-value="id"
                      return-object
                      persistent-hint
                      clearable
                    ></v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-layout row >
                  <v-flex xs3 class="py-4 px-2">
                    {{lang.ActionType}}
                  </v-flex>
                  <v-flex xs4 class="py-0 px-2">
                    <v-select
                      :items="actiontypes"
                      v-model="selectTT"
                      :label="lang.ActionTypeSelect"
                      single-line
                      item-text="text"
                      item-value="id"
                      return-object
                      persistent-hint
                      clearable
                    ></v-select>
                  </v-flex>

                </v-layout> 

                <v-layout row >
                  <v-flex xs3 class="py-4 px-2">
                    {{lang.WorkType}}
                  </v-flex>
                  <v-flex xs5 class="py-0 px-2">
                    <v-select
                      :items="actionwork"
                      v-model="selectTD"
                      :label="lang.WorkTypeSelect"
                      single-line
                      item-text="text"
                      item-value="id"
                      return-object
                      persistent-hint
                      clearable
                    ></v-select>
                  </v-flex>
                 <v-flex xs2 class="py-0 px-2">
                        <v-text-field
                          :label="lang.WorkHours"
                          v-model.number="emplday.WorkingHours"
                          mask="##"
                        ></v-text-field>
                  </v-flex>
                  <v-flex xs2 class="py-0 px-2">
                        <v-text-field
                          :label="lang.WorkMinutes"
                          v-model.number="emplday.FreeF1"
                          mask="##"
                        ></v-text-field>
                  </v-flex>
                </v-layout>                  

          <v-layout row>
              <v-flex xs3 class="py-4 px-2">
                {{lang.ForDate}}
              </v-flex>
              <v-flex xs3 sm6 class="py-4 px-2">
                <input type="date" v-model="emplday.ForDate" />
              </v-flex>
          </v-layout>           

          <v-layout row>
              <v-flex xs3 class="py-4 px-2">
               {{lang.AlarmDate}}
              </v-flex>
              <v-flex xs3 sm6 class="py-4 px-2">
                <input type="date" v-model="emplday.AlarmDate" />
              </v-flex>
          </v-layout>           
   
          <v-layout row>
            <v-flex xs3 class="py-4 px-2">
              {{lang.HealthServiceID}}                 
            </v-flex>
            <v-flex xs9 class="py-4 px-2">
              <v-text-field
                  :label="lang.HealthServiceID"
                  v-model="emplday.HealthServiceID"
                ></v-text-field>    
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex xs3 class="py-4 px-2">
              {{lang.PensionFundID}}                 
            </v-flex>
            <v-flex xs9>
              <v-text-field
                  :label="lang.PensionFundID"
                  v-model="emplday.PensionFundID"
                ></v-text-field>    
            </v-flex>
          </v-layout> 

          <v-layout row>
            <v-flex xs3 class="py-4 px-2">                    
              {{lang.EmplDayFree1}}                  
            </v-flex>
            <v-flex xs9>
              <v-text-field
                  :label="lang.EmplDayFree1"
                  v-model="emplday.EmplDayFree1"
                ></v-text-field>    
            </v-flex>
          </v-layout>

          <v-chip v-if="error" label color="red" text-color="white">
            {{error}}
          </v-chip>


          </v-card-text>
          <v-divider class="mt-1"></v-divider>
          <v-card-actions>

            <v-btn
              color="green"
              @click="empldaylist">
              {{langC.Back}} 
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              class="primary"
             :disabled="!formIsValid"
              @click="register">
              {{langC.Save}} 
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import EmployeeService from '@/services/EmployeeService'
import EmplDayService from '@/services/EmplDayService'
import TaskService from '@/services/TaskService'
import langEn from './empDescEn'
import langHr from './empDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dayjs from 'dayjs'


export default {
  name: 'empldaycreate',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      lang: {},
      langC: {},
      saving: false,
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      emplday: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        EmployeeId: 0,
        Employeeemail: '',
        Employeehashtag: '',
        EmployeeVatID: '',
        eemail: '',
        phone: '',
        First: '',
        Last: '',
        partnerId: 0,
        partnerName: '',
        partnerNameShort: '',
        ActionTypeID: 0,
        ActionTypeName: '',
        CompanyVATID: '',
        HealthServiceID: '',
        HealthServiceStartDate: null,
        HealthServiceEndDate: null,
        HealthServiceChangeDate: null,
        PensionFundID: '',
        PensionFundStartDate: null,
        PensionFundEndDate: null,
        PensionFundChangeDate: null,
        WorkingHours: 0,
        WorkingHoursTypeID: 0,
        WorkingHoursTypeName: '',
        OtherID1: '',
        OtherID2: '',
        OtherID3: '',
        EnteredInDatabase: null,
        OpenDate: null,
        OpenTime: null,
        ForDate: null,
        ForTime: null,
        AlarmDate: null,
        AlarmTime: null,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        EmplDayFree1: '',
        EmplDayFree2: '',
        EmplDayFree3: '',
        EmplDayFree4: '',
        EmplDayFree5: '',
        EmplDayBool1: false,
        EmplDayBool2: false,
        EmplDayBool3: false,
        EmplDayBool4: false,
        EmplDayBool5: false,
        LastUpdateBy: ''
      },
      task: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        First: '',
        Last: '',
        EmployeeId: 0,
        Employeeemail: '',
        Employeehashtag: '',
        partnerId: 0,
        partnerName: '',
        partnerNameShort: '',
        taskTypeID: 0,
        taskTypeDescription: '',
        taskDepartmentID: 0,
        taskDepartmentDescription: '',
        taskShortDescription: '',
        taskDetailDescription: '',
        taskOpenDate: null,
        taskOpenTime: null,
        taskForDate: null,
        taskForTime: null,
        taskAlarmDate: null,
        taskAlarmTime: null,
        taskFor1UserId: 0,
        taskFor1Useremail: '',
        taskFor1UserSendEmail: false,
        taskFor2UserId: 0,
        taskFor2Useremail: '',
        taskFor2UserSendEmail: false,
        taskFor3UserId: 0,
        taskFor3Useremail: '',
        taskFor3UserSendEmail: false,
        taskFor4UserId: 0,
        taskFor4Useremail: '',
        taskFor4UserSendEmail: false,
        taskFor5UserId: 0,
        taskFor5Useremail: '',
        taskFor5UserSendEmail: false,
        taskClosedDate: null,
        taskClosedTime: null,
        taskClosedUserId: 0,
        taskClosed: false,
        taskClosedSendEmail: false,
        taskSpentHours: 0,
        taskChargeHours: 0,
        taskCharged: false,
        taskChargedByUserId: 0,
        taskChargedByUseremail: '',
        taskChargedDate: null,
        taskChargedTime: null,
        taskNotFinished: false,
        taskNotFinishedReason: '',
        taskPublic: false,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: '',
        LastUpdate: null
      },
      error: null,
      localError: false,
      newUser: {},
      confpassword: '',
      size: 'sm',
      actiontypes: [
        { text: 'Prijava (1)', value: 1 },
        { text: 'Odjava (2)', value: 2 },
        { text: 'Promjena (3)', value: 3 }
      ],
      actionwork: [
        { text: 'Puno radno vrijeme (1)', value: 1 },
        { text: 'Nepuno radno vrijeme (2)', value: 2 }
      ],
      required: (value) => !!value || 'Required.',
      partners: [],
      employes: [],
      tasktypes: [],
      taskdepartments: [],
      users: [],
      CompanyId: 0,
      selectPa: {},
      selectEm: {},
      selectTT: {},
      selectTD: {},
      selectUs: {},
      selectUs2: {},
      selectUs3: {},
      mainQuery: {
        CompanyId: 1
      },
      havePath: ''
    }
  },
  components: {
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = langEn
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = langHr
      this.langC = commHr;
    }
    if (this.$store.state.editItem === 1) {
        if (this.$store.state.companyid) {
          this.CompanyId = await this.$store.state.companyid
          this.mainQuery.CompanyId = await this.$store.state.companyid
        }
        if (this.CompanyId > 0) {
          this.employes = (await EmployeeService.choose(this.mainQuery)).data.employes
        }
        const locEmplday = await EmplDayService.show(this.$store.state.empldayid)
        this.emplday = locEmplday.data.emplday
       
        if (this.emplday.ActionTypeID) {
          this.selectTT = {
            text: this.emplday.ActionTypeName,
            value: this.emplday.ActionTypeID
          }
        }
        if (this.emplday.WorkingHoursTypeID) {
          this.selectTD = {
            text: this.emplday.WorkingHoursTypeName,
            value: this.emplday.WorkingHoursTypeID
          }
        }
        if (this.emplday.EmployeeId) {
          this.selectEm = {
            id: this.emplday.EmployeeId,
            eemail: this.emplday.Employeeemail,
            VatID: this.emplday.EmployeeVatID,
            First: this.emplday.First,
            Last: this.emplday.Last,
            hashtag: this.emplday.Employeehashtag
          }
        }
        if (this.$store.state.user.id) {
          this.task.UserId = this.$store.state.user.id
        }
        if (this.$store.state.user.email) {
          this.task.email = this.$store.state.user.email
        }
        if (this.$store.state.user.First) {
          this.task.First = this.$store.state.user.First
        }
        if (this.$store.state.user.Last) {
          this.task.Last = this.$store.state.user.Last
        }
        if (this.$store.state.companyid) {
          this.task.CompanyId = this.$store.state.companyid
        }
        if (this.$store.state.companyName) {
          this.task.CompanyNameDatabase = this.$store.state.companyName
        }
        if (this.$store.state.businessYear) {
          this.task.BusinessYear = this.$store.state.businessYear
        }
      }
    if (this.$store.state.editItem === 0) {
      // need five arrays with partners, employes, tasktype, taskdepartment, users
      if (this.$store.state.companyid) {
        this.CompanyId = await this.$store.state.companyid
        this.mainQuery.CompanyId = await this.$store.state.companyid
      }
      if (this.CompanyId > 0) {
        this.employes = (await EmployeeService.choose(this.mainQuery)).data.employes
      }
      if (this.$store.state.user.id) {
        this.emplday.UserId = this.$store.state.user.id
        this.task.UserId = this.$store.state.user.id
      }
      if (this.$store.state.user.email) {
        this.emplday.email = this.$store.state.user.email
        this.task.email = this.$store.state.user.email
      }
      if (this.$store.state.user.First) {
        this.task.First = this.$store.state.user.First
        this.emplday.First = this.$store.state.user.First
      }
      if (this.$store.state.user.Last) {
        this.task.Last = this.$store.state.user.Last
        this.emplday.Last = this.$store.state.user.Last
      }
      if (this.$store.state.companyid) {
        this.emplday.CompanyId = this.$store.state.companyid
        this.task.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.companyName) {
        this.emplday.CompanyNameDatabase = this.$store.state.companyName
        this.task.CompanyNameDatabase = this.$store.state.companyName
      }
      if (this.$store.state.businessYear) {
        this.emplday.BusinessYear = this.$store.state.businessYear
        this.task.BusinessYear = this.$store.state.businessYear
      }

      let locDate1 = new Date()
      locDate1.getDate()
      locDate1.getMonth() + 1
      locDate1.getFullYear()
      this.emplday.OpenDate = dayjs().format('YYYY-MM-DD')
    }
  },
  computed: {
    formIsValid () {
      return this.emplday.email !== '' &&
        this.emplday.EmployeeId !== '' &&
        this.emplday.OpenDate !== ''
    }
  },
  methods: {
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async register () {
      try {
        // write new task to database
        this.localError = false
        if (this.selectEm.id) {
          this.emplday.EmployeeId = this.selectEm.id
        }
        if (this.selectEm.eemail) {
          this.emplday.Employeeemail = this.selectEm.eemail
          this.emplday.eemail = this.selectEm.eemail
          this.emplday.phone = '385'
          this.emplday.EmployeeVatID = this.selectEm.VatID
        }
        if (this.selectEm.First) {
          this.emplday.First = this.selectEm.First
        }
        if (this.selectEm.Last) {
          this.emplday.Last = this.selectEm.Last
        }
        if (this.selectEm.hashtag) {
          this.emplday.Employeehashtag = this.selectEm.hashtag
        }
        if (this.selectTT.value) {
          this.emplday.ActionTypeID = this.selectTT.value
        }
        if (this.selectTT.text) {
          this.emplday.ActionTypeName = this.selectTT.text
        }
        if (this.selectTD.value) {
          this.emplday.WorkingHoursTypeID = this.selectTD.value
        }
        if (this.selectTD.text) {
          this.emplday.WorkingHoursTypeName = this.selectTD.text
        }
        if (!this.emplday.ForDate) {
          this.emplday.ForDate = dayjs().format('YYYY-MM-DD')
        }
        if (this.emplday.AlarmDate) {
          this.task.taskForDate = this.emplday.AlarmDate
          this.task.EmployeeId = this.emplday.EmployeeId
          this.task.Employeeemail = this.emplday.Employeeemail
          this.task.Employeehashtag = this.emplday.Employeehashtag
          this.task.taskShortDescription = '!!!! - ' + this.emplday.ActionTypeName
          const resTask = await TaskService.post(this.task)
          this.emplday.FreeF1 = resTask.data.id
        }
        if (this.$store.state.editItem === 0) {
          await EmplDayService.post(this.emplday)
        }
        if (this.$store.state.editItem === 1) {
          await EmplDayService.put(this.emplday)
        }


        this.$store.dispatch('seteditItem', 0)
        this.havePath = this.$store.state.retPath
        this.$store.dispatch('setretPath', '')
        if (this.havePath !== 'emplday' && this.havePath.length > 0) {
          this.$router.push({
            name: this.havePath
          })
        } else {
          this.$router.push({
            name: 'emplday'
          })
        }
      } catch (error) {
        this.error = 'Greska u konekciji na bazu ! Pokusajte ponovno ili kontaktirajte administratora.' + error.response.data.fromServer
      }
    },
    close () {
      this.show = false
    },
    async empldaylist () {
      try {
        this.havePath = this.$store.state.retPath
        this.$store.dispatch('setretPath', '')
        if (this.havePath !== 'emplday' && this.havePath.length > 0) {
          await this.$router.push({
            name: this.havePath
          })
        } else {
          await this.$router.push({
            name: 'emplday'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
