<template>
  <div class="documentprintsales">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import BankAccountService from '@/services/BankAccountService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import { parseNum, round } from '@/snippets/allSnippets'
import documentExportName from '@/snippets/documentExportName'
import generateBarcode from "pdf417"
import dayjs from 'dayjs'

export default {
  name: 'documentprintsales',
  props: ['useMemo', 'printID', 'noTotal'],
  data () {
    return {
      msg: '',
      documentName: '',
      documentName2: '',
      document: {},
      havePath: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locDocument: {},
      fields1: [ 'itemName', 'ItemCode', 'itemUnit', 'itemService', 'itemPrice', 'itemPcsNeutral', 'itemBaseAmount', 'itemDiscountPercentage1', 'itemBaseAmountNoVAT', 'itemAmountWithVAT' ],
      items1: [
      ],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      fields2: [ 'vat', 'base', 'amount' ],
      items2: [
        { vat: '5%', base: 100.00, amount: 5.00 },
        { vat: '13%', base: 200.00, amount: 26.00 },
        { vat: '25%', base: 400.00, amount: 100.00 }
      ],
      comp: {},
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        databaseDocID: 0
      },
      order: 0,
      locDocumentName: '',
      loCdocumentDate: '',
      loCorderDate: '',
      loCdocumentInvoiceStartDate: '',
      loCdocumentInvoiceTime: '',
      loCdocumentInvoiceEndDate: '',
      locDocumentConnFrom: null,
      loCdocumentConnDate: '',
      loCdeliveryDate: '',
      loCorderTime: '',
      loCdocumentConnTime: '',
      loCdeliveryTime: '',
      locDocumentId: '',
      output: null,
      documentNameLoc: '',
      documentNameExport: '',
      docDefinition: null,
      profitLoss: 0,
      profitLossP: '',
      haveSmallLogo: false,
      pP1: [],
      pP2: [],
      pP3: [],
      pP4: [],
      pP5: [],
      pP6: [],
      pP7: [],
      pP8: [],
      pP9: [],
      pP10: [],
      pD1: [],
      pD2: [],
      pD3: [],
      pD4: [],
      pD5: [],
      pD6: [],
      pD7: [],
      pD8: [],
      pD9: [],
      pD10: [],
      pT1: [],
      pT2: [],
      pT3: [],
      pT4: [],
      pT5: [],
      pT6: [],
      pT7: [],
      pT8: [],
      pT9: [],
      pT10: [],
      pC1: [],
      pC2: [],
      pC3: [],
      pC4: [],
      pC5: [],
      pC6: [],
      pC7: [],
      pC8: [],
      pC9: [],
      pC10: [],
      pC11: [],
      pC1tot: 0,
      pC2tot: 0,
      pC3tot: 0,
      pC4tot: 0,
      pC5tot: 0,
      pC6tot: 0,
      pC7tot: 0,
      pC8tot: 0,
      pC9tot: 0,
      pC10tot: 0,
      pD1tot: 0,
      pD2tot: 0,
      pD3tot: 0,
      pD4tot: 0,
      pD5tot: 0,
      pD6tot: 0,
      pD7tot: 0,
      pD8tot: 0,
      pD9tot: 0,
      pD10tot: 0,
      pTtt1: 0,
      pTtt2: 0,
      pTtt3: 0,
      pTtt4: 0,
      pTtt5: 0,
      pTtt6: 0,
      pTtt7: 0,
      pTtt8: 0,
      pTtt9: 0,
      pTtt10: 0,
      pTtt11: 0,
      pSum00: 0,
      pSum01: 0,
      pSum1: 0,
      pSum2: 0,
      pSum3: 0,
      pSum4: 0,
      pSum5: 0,
      peSum00: 0,
      peSum01: 0,
      peSum1: 0,
      peSum2: 0,
      peSum3: 0,
      peSum4: 0,
      peSum5: 0,
      pNumSum5: 0,
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      langE: {},
      saving: false,
      docDefinition2: {},
      content2: [],
      logoType: 0,
      printSmall: false, 
      printNoPrice: false,
      itemdesc: '',
      paycash: '',
      paycard: '',
      payother: '',
      Canvas1: null,
      Canvas2: null,
      slika: null,
      tekstHUB: null,
      exportDoc: false, 
      printItemID: true,
      desDocumentDate: '',
      desStartDate: '',
      desDeadLine: '',
      hmCR: '',
      hmCReur: 'EUR',
      exCR: '',
      banks: [],
      bankDesc: null,
      SWIFT: '',
      businessYear: 2022
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = {...langEn}
      this.langC = {...commEn}
    }
    if (this.$store.state.language === 'HR') {
      this.lang = {...langHr}
      this.langC = {...commHr}
    }
 
    if (this.$store.state.documentActivHead) {
      this.document = await this.$store.state.documentActivHead
      this.documentNameLoc = await this.$store.state.documentActivLoc
    }
    this.exportDoc = this.$store.state.documentActivHead.exportDoc
    this.hmCR = this.document.documentHomeCurrency ? this.document.documentHomeCurrency : 'Kn'
    this.exCR = this.document.documentForeignCurrency ? this.document.documentForeignCurrency : 'EUR'

    this.langE = {...langEn}
    

    this.printSmall = false
    if (this.$store.state.printSmall === true) {  
      this.printSmall = true
    }

  },
  methods: {
    async print () {

      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.BusinessYear = this.$store.state.businessYear
        this.businessYear = parseInt(this.$store.state.businessYear)
      }
      this.banks = (await BankAccountService.choose(this.mainQuery)).data.bankAccounts

      this.printSmall = false
      this.printNoPrice = false
      this.exportDoc = this.$store.state.documentActivHead.exportDoc
      if( this.$store.state.documentActivHead.documentName === 'InvoiceSales' || this.$store.state.documentActivHead.documentName === 'QuoteSales') {
        this.printItemID = this.printID && this.printID === true ? true : false
      }
      if (this.exportDoc) {
        this.desDocumentDate = this.exportDoc ? this.lang.DocumentDate + '\n' + 'Issue date:' : this.lang.DocumentDate
        this.desStartDate = this.exportDoc ? this.lang.StartDate + '\n' + 'From date:' : this.lang.StartDate
        this.desDeadLine = this.exportDoc ? this.lang.DeadLine + '\n' + 'Currency date:' : this.lang.DeadLine
      }

      if (this.$store.state.printSmall === true) {
        this.printSmall = true 
      }
      if (this.$store.state.printNoPrice === true) {
        this.printNoPrice = true
      }
      this.prepareData()
      if (this.$store.state.typePOSPrint === 100) {
        this.printCustomDoc()
      }
      if (this.$store.state.typePOSPrint !== 100) {
        if (this.printSmall === true) {
          this.printSmallDoc()
        } else {
          this.printTestDoc()
        }
      }
    },

    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    prepareData() {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentid) {
        this.mainQuery.databaseDocID = this.$store.state.documentid
      }
      if (this.$store.state.documentActivHead) {
        this.document = this.$store.state.documentActivHead
        this.paycash = this.lang.documentPOSCash + ': ' + (this.document.documentPOSCash ? this.formatPriceHR(this.document.documentPOSCash) : 0) + ' \n '
        this.paycard = this.lang.documentPOSCard + ': ' + (this.document.documentPOSCard ? this.formatPriceHR(this.document.documentPOSCard) : 0) + (this.document.status ? ', ' + this.document.status : '')
        const selectedBank = this.banks ? this.banks.find(bank => bank.IBAN === this.document.bankAccountNr) : null
        this.bankDesc = selectedBank && selectedBank.BankAccountDescription ? selectedBank.BankAccountDescription : null
        this.SWIFT = selectedBank && selectedBank.SWIFT ? selectedBank.SWIFT : null
      }
      if (this.$store.state.companyActiv.CompanyLogo1) {
        this.logoType = 1
        this.haveSmallLogo = true
      }
      if (this.$store.state.companyActiv.CompanyLogo2) {
        this.logoType = 2
      }
      if (this.$store.state.companyActiv.CompanyLogo3) {
        this.logoType = 3
      }
      this.documentName2 = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      this.documentNameExport = documentExportName(this.$store.state.documentActiv)
      if (this.documentNameExport === 'Quote') {
        this.documentNameExport = 'Proforma invoice'
      }

      this.pSum00 = this.formatPrice(this.document.documentDescription9)
      this.pSum01 = this.formatPrice(this.document.documentDescription10)
      this.pSum1 = this.formatPrice(this.document.documentTotalAmountNetto)
      this.pSum2 = this.formatPrice(this.document.documentTotalAmountVAT)
      this.pSum3 = this.formatPrice(this.document.documentTotalAmountBrutto)
      this.pSum4 = this.formatPrice(this.document.documentTotalAmountPayInAdvance)
      this.pSum5 = this.formatPrice(this.document.documentTotalAmountToPay)
      this.pNumSum5 = parseNum(this.document.documentTotalAmountToPay)
      if (this.document.documentExRate && parseFloat(this.document.documentExRate) !== 0) {
        this.peSum00 = this.formatPrice(this.document.documentDescription9 / parseFloat(this.document.documentExRate))
        this.peSum01 = this.formatPrice(this.document.documentDescription10 / parseFloat(this.document.documentExRate))
      }
      this.peSum1 = this.formatPrice(this.document.documentTotalAmountNettoForeign)
      this.peSum2 = this.formatPrice(this.document.documentTotalAmountVATForeign)
      this.peSum3 = this.formatPrice(this.document.documentTotalAmountBruttoForeign)
      this.peSum4 = this.formatPrice(this.document.documentTotalAmountPayInAdvanceForeign)
      this.peSum5 = this.formatPrice(this.document.documentTotalAmountToPayForeign)

      this.comp = this.$store.state.companyActiv
      this.order = 0
      this.items = []
      if (this.$store.state.documentActivItems) {
        // this.items = (await DocumentDetailService.index(this.mainQuery)).data.documentDetails
        this.items = this.$store.state.documentActivItems
      }
      // console.log(' items imam ili ne', this.items)
      this.pC1 = []
      this.pC2 = []
      this.pC3 = []
      this.pC4 = []
      this.pC5 = []
      this.pC6 = []
      this.pC7 = []
      this.pC8 = []
      this.pC9 = []
      this.pC10 = []
      this.pC11 = []

      if (this.items) {
        this.items.map(item => {
          if (parseFloat(item.itemAmountWithVAT) !== 0) {

            this.order += 1
            this.pTtt3 = this.formatPrice(item.itemPrice)
            this.pTtt11 = this.formatPrice(item.itemPrice * (1 + (item.itemVAT1Percentage/100)))
            if (this.$store.state.documentSide === 2) {
              this.pTtt4 = item.itemPcsMinus ? this.formatPrice(item.itemPcsMinus) : 0
            }
            if (this.$store.state.documentSide === 3) {
              this.pTtt4 = item.itemPcsNeutral ? this.formatPrice(item.itemPcsNeutral) : 0
            }
            this.pTtt5 = item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0
            this.pTtt6 = item.itemDiscountPercentage1 ? this.formatPrice(item.itemDiscountPercentage1) : 0
            this.pTtt7 = item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0
            this.pTtt8 = item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0
            this.pTtt9 = item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0
            this.pC1.push({ text: this.order + '', fontSize: 8 })
            this.pC2.push({ text: item.itemName + '', fontSize: 8 })
            this.pC3.push({ text: this.pTtt3 + '', fontSize: 8, alignment: 'right' })
            this.pC4.push({ text: this.pTtt4 + '', fontSize: 8, alignment: 'right' })
            this.pC5.push({ text: this.pTtt5 + '', fontSize: 8, alignment: 'right' })
            this.pC6.push({ text: this.pTtt6 + '', fontSize: 8, alignment: 'right' })
            this.pC7.push({ text: this.pTtt7 + '', fontSize: 8, alignment: 'right' })
            this.pC8.push({ text: this.pTtt8 + '', fontSize: 8, alignment: 'right' })
            this.pC9.push({ text: this.pTtt9 + 'n', fontSize: 8, alignment: 'right' })
            this.pC10.push({ text: item.itemUnit + '', fontSize: 8, alignment: 'left' })
            this.pC11.push({ text: this.pTtt11 + '', fontSize: 8, alignment: 'right' })
            // this.pC1.push({ text: this.order + '\n', fontSize: 8 })
            // this.pC2.push({ text: item.itemName + '\n', fontSize: 8 })
            // this.pC3.push({ text: item.itemPrice + '\n', fontSize: 8, alignment: 'right' })
            // this.pC4.push({ text: item.itemPcsNeutral + '\n', fontSize: 8, alignment: 'right' })
            // this.pC5.push({ text: item.itemBaseAmount + '\n', fontSize: 8, alignment: 'right' })
            // this.pC6.push({ text: item.itemDiscountPercentage1 + '\n', fontSize: 8, alignment: 'right' })
            // this.pC7.push({ text: item.itemBaseAmountNoVAT + '\n', fontSize: 8, alignment: 'right' })
            // this.pC8.push({ text: item.itemVAT1Percentage + '\n', fontSize: 8, alignment: 'right' })
            // this.pC9.push({ text: item.itemAmountWithVAT + '\n', fontSize: 8, alignment: 'right' })
            // this.pC10.push({ text: item.itemUnit + '\n', fontSize: 8, alignment: 'left' })
          }
          // if (item.itemDescription) {
          //   //this.order += 1
          //   this.pC1.push({ text: '', fontSize: 8 })
          //   this.pC2.push({ text: item.itemDescription + '', fontSize: 8 })
          //   this.pC3.push({ text: '', fontSize: 8, alignment: 'right' })
          //   this.pC4.push({ text: '', fontSize: 8, alignment: 'right' })
          //   this.pC5.push({ text: '', fontSize: 8, alignment: 'right' })
          //   this.pC6.push({ text: '', fontSize: 8, alignment: 'right' })
          //   this.pC7.push({ text: '', fontSize: 8, alignment: 'right' })
          //   this.pC8.push({ text: '', fontSize: 8, alignment: 'right' })
          //   this.pC9.push({ text: '', fontSize: 8, alignment: 'right' })
          //   this.pC10.push({ text: '', fontSize: 8, alignment: 'right' })
          //   this.pC11.push({ text: '', fontSize: 8, alignment: 'right' })
          // }
          // console.log(' ovo imam za stavku ', item)
        })
      }
      // console.log(' client loc document ', this.locDocument)
      // console.log(' client loc items ', this.items)
      // this.document = this.locDocument.data.document
      this.locDocumentName = (this.$store.state.documentActiv != 'POS' ? this.documentNameLoc: 'Račun') + ': ' + this.document.documentId + '/' + (this.$store.state.documentActiv !== 'POS' ? (this.comp.CompanyBusinessSpace ? this.comp.CompanyBusinessSpace : '_')  : this.comp.CompanyBusinessSpaceRetail) + '/' + (this.$store.state.documentActiv !== 'POS' ? (this.comp.CompanyBusinessDevice ? this.comp.CompanyBusinessDevice : '_') : this.comp.CompanyBusinessDeviceRetail)
      this.locCreatedAt = dayjs(this.document.CreatedAt).format('DD.MM.YYYY HH:MM')
      this.locUpdatedAt = dayjs(this.document.UpdatedAt).format('DD.MM.YYYY HH:MM')
      this.loCdocumentInvoiceTime = this.document.documentTime ? this.document.documentTime.substring(0, 5) : '10:00'
      this.loCdocumentDate = this.document.documentDate ? dayjs(this.document.documentDate).format('DD.MM.YYYY') : ' '
      this.loCorderDate = this.document.orderDate ? dayjs(this.document.orderDate).format('DD.MM.YYYY') : ' '
      this.loCdocumentInvoiceStartDate = this.document.documentInvoiceStartDate ? dayjs(this.document.documentInvoiceStartDate).format('DD.MM.YYYY') : ' '
      this.loCdocumentInvoiceEndDate = this.document.documentInvoiceEndDate ? dayjs(this.document.documentInvoiceEndDate).format('DD.MM.YYYY') : ' '      
      this.loCdocumentConnDate =  this.document.documentConnDate ? dayjs(this.document.documentConnDate).format('DD.MM.YYYY') : ' '
      this.loCdeliveryDate = this.document.deliveryDate ? dayjs(this.document.deliveryDate).format('DD.MM.YYYY') : ' '
      this.loCorderTime = this.document.orderTime ? dayjs(this.document.orderTime).format('HH:MM') : ' '
      this.loCdocumentConnTime = this.document.documentConnTime ?  dayjs(this.document.documentConnTime).format('HH:MM') : ' '
      this.loCdeliveryTime = this.document.deliveryTime ? dayjs(this.document.deliveryTime).format('HH:MM') : ' '
      
      if (this.document.documentConnNameFrom && this.document.documentConnNameFrom.trim().length > 2) {
        if (this.document.documentConnNameFrom.substring(0, 17) === 'DeliveryNoteSales') {
          const connNr = this.document.documentConnNameFrom.substring(18, (this.document.documentConnNameFrom.trim().length))
          this.locDocumentConnFrom = 'Veza: Otpremnica - ' + connNr  + (this.document.documentConnDate ? ', od: ' + dayjs(this.document.documentConnDateFrom).format('DD.MM.YYYY') : ' ')
        }
      }

      if (this.$store.state.documentActiv === 'InvoiceSales') {
        this.locDocumentId = this.document.documentId + '/' + this.$store.state.companyActiv.CompanyBusinessSpace + '/' + this.$store.state.companyActiv.CompanyBusinessDevice
      } else {
        this.locDocumentId = this.document.documentId
      }
      this.profitLoss = this.document.FreeF4 - this.document.FreeF1 - this.document.FreeF2 - this.document.FreeF3
      this.profitLossP = this.profitLoss.toFixed(2)
    },
    addItemsNoPrice () {
           this.order = 0
            if (this.items) {
              this.items.map(item => {
                if (parseFloat(item.itemAmountWithVAT) !== 0) {

                  this.order += 1
                  if (!this.exportDoc) {
                    this.pTtt3 = this.formatPrice(item.itemPrice)
                    this.pTtt11 = this.formatPrice(item.itemPrice * (1 + (item.itemVAT1Percentage/100)))
                  } else {
                    this.pTtt3 = (this.formatPrice(item.itemForePrice)) + '\n' + (this.formatPrice(item.itemPrice)) 
                    this.pTtt11 = (this.formatPrice(item.itemForePrice * (1 + (item.itemVAT1Percentage/100)))) + '\n' +  (this.formatPrice(item.itemPrice * (1 + (item.itemVAT1Percentage/100))))  
                  }
                  if (this.$store.state.documentSide === 2) {
                    this.pTtt4 = item.itemPcsMinus ? this.formatPrice(item.itemPcsMinus) : 0
                  }
                  if (this.$store.state.documentSide === 3) {
                    this.pTtt4 = item.itemPcsNeutral ? this.formatPrice(item.itemPcsNeutral) : 0
                  }
                  if (!this.exportDoc) {
                    this.pTtt5 = item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0
                    this.pTtt6 = item.itemDiscountPercentage1 ? this.formatPrice(item.itemDiscountPercentage1) : 0
                    this.pTtt7 = item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0
                    this.pTtt8 = item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0
                    this.pTtt9 = item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0
                  } else {
                    this.pTtt5 = (item.itemBaseForeAmount ? this.formatPrice(item.itemBaseForeAmount) : 0 ) + '\n' + (item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0 )
                    this.pTtt6 = (item.itemDiscountPercentage1 ? this.formatPrice(item.itemDiscountPercentage1) : 0) 
                    this.pTtt7 = (item.itemBaseForeAmountNoVAT ? this.formatPrice(item.itemBaseForeAmountNoVAT) : 0) + '\n' + (item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0)
                    this.pTtt8 = (item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0) 
                    this.pTtt9 = (item.itemForeAmountWithVAT ? this.formatPrice(item.itemForeAmountWithVAT) : 0) + '\n' + (item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0)
                  }
                  const docPart = {
                  columns: [
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: this.order
                        },
                        {
                          width: '65%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.itemName + (item.itemDescription ? '\n' + item.itemDescription : '') + (item.itemID ? '\n' + item.itemID : '') + (item.itemSerialNr ? '\n Ser.br.:' + item.itemSerialNr : '')
                        },
                        {
                          width: '10%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.itemUnit
                        },
                        {
                          width: '10%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt4
                        },
                      ]
                    }
                  this.content.push(docPart)
                }

                let printEmpty = false
                
                if (this.$store.state.documentSide === 2 && parseFloat(item.itemPcsMinus) === 0) {
                  printEmpty = true
                }
                if (this.$store.state.documentSide === 3 && parseFloat(item.itemPcsNeutral) === 0) {
                  printEmpty = true
                }
                if (printEmpty) {
                  const docPart = {
                  columns: [
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: ''
                        },
                        {
                          width: '65%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.itemName + (item.itemDescription ? '\n' + item.itemDescription : '') + (item.itemSerialNr ? '\n Ser.br.:' + item.itemSerialNr : '')
                        },
                      ]
                    }
                  this.content.push(docPart)
                }
              })
          }
    },
    addItems2 () {
           this.order = 0
           // check if there is any description in all items
           let haveDescriptions = false
           if (this.items) {
              this.items.map(item => {
                if (item.itemDescription) {
                  haveDescriptions = true
                }
              })
           }
           const amDiscount = parseNum(this.document.documentDescription10)
            if (this.items) {
              this.items.map(item => {
                if (item.forceNewPrintPage) {
                    this.addLine()
                    const docPart01 = {
                    columns: [
                        {
                          width: '40%',
                          fontSize: 7,
                          bold: true,
                          alignment: 'left',
                          text: 'www.roboot.net',
                          pageBreak: 'after'
                        },
                      ]
                    }
                    this.content.push(docPart01)
                     this.docDefinition.header = function(currentPage, pageCount) {
      // you can apply any logic and return any valid pdfmake element
      return [
        { text: '\n Str.:' + currentPage + ' od ' + pageCount + '\u200B\t', alignment: 'right', fontSize: 7, margin: [0,0,30] },
      ]
      }
                    if (this.$store.state.documentActiv !== 'POS') {
                        if (!this.exportDoc) {
                          this.addPartner() 
                        }
                        if (this.exportDoc) {
                          this.addPartnerExport()
                        }
                      }
                      else {
                        if (this.$store.state.typePOSPrint === 2) {
                          this.addPartnerPOSA4()
                        }
                        if (this.$store.state.typePOSPrint === 3) {
                          this.addPartner()
                        }
                      }
                      this.addHeadComment()
                      this.addEmptyRow()
                      this.addLine()
                }
                if (parseFloat(item.itemAmountWithVAT) !== 0) {

                  this.order += 1
                  if (!this.exportDoc) {
                    this.pTtt3 = this.formatPrice(item.itemPrice)
                    this.pTtt11 = this.formatPrice(item.itemPrice * (1 + (item.itemVAT1Percentage/100)))
                  } else {
                    this.pTtt3 = (this.formatPrice(item.itemPrice)) 
                    this.pTtt11 = (this.formatPrice(item.itemPrice * (1 + (item.itemVAT1Percentage/100))))  
                  }
                  if (this.$store.state.documentSide === 2) {
                    this.pTtt4 = item.itemPcsMinus ? this.formatPrice(item.itemPcsMinus) : 0
                  }
                  if (this.$store.state.documentSide === 3) {
                    this.pTtt4 = item.itemPcsNeutral ? this.formatPrice(item.itemPcsNeutral) : 0
                  }
                  if (!this.exportDoc) {
                    this.pTtt5 = item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0
                    this.pTtt6 = item.itemDiscountPercentage1 ? this.formatPrice(item.itemDiscountPercentage1) : 0
                    this.pTtt7 = item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0
                    this.pTtt8 = item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0
                    this.pTtt9 = item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0
                  } else {
                    this.pTtt5 = (item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0 ) 
                    this.pTtt6 = (item.itemDiscountPercentage1 ? this.formatPrice(item.itemDiscountPercentage1) : 0) 
                    this.pTtt7 = (item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0)
                    this.pTtt8 = (item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0) 
                    this.pTtt9 = (item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0)
                  }
                  const itemText = []
                  if (!haveDescriptions) {
                    const add1 = { text: item.itemName, fontSize: 9, bold: false}
                    itemText.push(add1)
                  }
                  
                  if (haveDescriptions) {
                    const add1 = { text: item.itemName, fontSize:10, bold: true}
                    itemText.push(add1)
                    if (item.itemDescription) {
                      const add2 = { text: '\n' + item.itemDescription, fontSize: 9, bold: false }
                      itemText.push(add2)
                    }
                  }
                  if (this.printItemID) {
                    const add2 = { text: '\n' + item.itemID, fontSize: 9, bold: false }
                     itemText.push(add2)
                  }
                  if (item.itemSerialNr) {
                    const add2 = { text: '\n Ser.br.:' + item.itemSerialNr, fontSize: 9, bold: false }
                    itemText.push(add2)
                  }

                  if (amDiscount > 0) {
                    const docPart = {
                    columns: [
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: this.order
                        },
                        {
                          width: '45%',
                          fontSize: 8,
                          alignment: 'left',
                          text: itemText
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.itemUnit
                        },
                        {
                          width: '7%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt4
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt3
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt5
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt6
                        },
                        {
                          width: '9%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt7
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt8 
                        },
                        {
                          width: '4%',
                          text: { text: '\n', fontSize: 9, bold:true,  alignment: 'center' }
                        }
                      ]
                    }
                    this.content.push(docPart)
                  }
                  if (amDiscount === 0) {
                  const docPart = {
                  columns: [
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: this.order
                        },
                        {
                          width: '55%',
                          fontSize: 8,
                          alignment: 'left',
                          text: itemText
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.itemUnit
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt4
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt3
                        },
                        {
                          width: '9%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt7
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt8 
                        },
                        {
                          width: '4%',
                          text: { text: (this.exportDoc ? this.hmCR : '') + '\n', fontSize: 9, bold:true,  alignment: 'center' }
                        }
                      ]
                    }
                    this.content.push(docPart)
                  }
                }

                let printEmpty = false
                
                if (this.$store.state.documentSide === 2 && parseFloat(item.itemPcsMinus) === 0) {
                  printEmpty = true
                }
                if (this.$store.state.documentSide === 3 && parseFloat(item.itemPcsNeutral) === 0) {
                  printEmpty = true
                }
                if (printEmpty) {
                  const docPart = {
                  columns: [
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: ''
                        },
                        {
                          width: '38%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.itemName + (item.itemDescription ? '\n' + item.itemDescription : '') + (item.itemSerialNr ? '\n Ser.br.:' + item.itemSerialNr : '')
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: ''
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: ''
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: ''
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: ''
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: ''
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: ''
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: ''
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: ''
                        }
                      ]
                    }
                  this.content.push(docPart)
                }
              })
          }
    },
    printSmallDoc () {
      this.initDocumentSmall()
      if (!this.useMemo) {
        let haveIt = false
        if (this.logoType === 0) {
          this.addHeaderNoLogo()
          haveIt = true
        }
        if (this.logoType === 1 || this.haveSmallLogo) {
          this.addHeaderWithLogoLeftSmall()
          haveIt = true
        }
        if (this.logoType === 2) {
          this.addHeaderWithLogoRight()
          haveIt = true
        }
        if (!haveIt) {
          this.addHeaderNoLogo()
        }
        // if (this.logoType === 3) {
        //   this.addHeaderWithLogoFull()
        // }
        
        this.addLineSmall2()
        this.addHeadSmall()
      }
      if (this.useMemo) {
        this.addEmptyRow()
        this.addEmptyRow()
        this.addEmptyRow()
        this.addEmptyRow()
        this.addEmptyRow()
        this.addEmptyRow()
      }
      // POS small partner
      if (this.$store.state.typePOSPrint === 3) {
        this.addLineSmall()
        this.addPartnerPOS()
        this.addLineSmall()
        // this.addItemsHeader()
        // this.addLine()
        // this.addItems()
        // this.addLine()
        // this.addTotal()
      }
      // Service
      if (this.$store.state.typePOSPrint === 1) {
        this.addPartnerOnly()
        this.addHeadCommentSmall()
      }
      if (this.printNoPrice) {
         if (this.items.length > 0) {
          this.addItemsHeaderNoPrice()
          this.addLine()
          this.addItemsNoPrice()
          this.addLine()
         }
      } else {
         if (this.items.length > 0) {
          this.addLineSmall2()
          this.addItemsHeaderSmall()
          this.addLineSmall2()
          this.addItemsSmall()
          this.addLineSmall2()
          this.addTotalSmall()
         
          // Service
          if (this.$store.state.typePOSPrint !== 1) {
            this.addFooterSmall()
            if (this.document.documentJIR && this.document.documentJIR.length > 3) {
              this.addQRSmall()
            }
          }
        }
      }
      
      // Service
      if (this.$store.state.typePOSPrint === 1) {
        this.addSignature3()
        //this.addSignature4()
      }
      // POS small 
      if (this.$store.state.typePOSPrint === 2) {
        //this.addLineSmall()
        // this.addItemsHeader()
        // this.addLine()
        // this.addItems()
        // this.addLine()
        // this.addTotal()
      }
      
      this.docDefinition.content = this.content
      //document.setMargins(36, 36, 55, 36)
      pdfMake.createPdf(this.docDefinition).open()
    },
    printTestDoc () {
      // works perfect need update to customize for different types of documents
      if (this.useMemo) {
        this.initDocumentMemo()
      }
      if (!this.useMemo) {
        this.initDocument()
        if (this.logoType === 0) {
          this.addHeaderNoLogo()
        }
        if (this.logoType === 1) {
          this.addHeaderWithLogoLeft()
        }
        if (this.logoType === 2) {
          this.addHeaderWithLogoRight()
        }
        if (this.logoType === 3) {
          this.addHeaderWithLogoFull()
        }
        this.addEmptyRow()
        this.addLine()
      }
      if (this.useMemo) {
        // this.addEmptyRow()
        // this.addEmptyRow()
        // this.addEmptyRow()
        // this.addEmptyRow()
      }
      if (!this.useMemo) {
        this.addEmptyRow()
        this.addEmptyRow()
      }
      if (this.$store.state.documentActiv !== 'POS') {
          if (!this.exportDoc) {
            this.addPartner() 
          }
          if (this.exportDoc) {
            this.addPartnerExport()
          }
        }
        else {
          if (this.$store.state.typePOSPrint === 2) {
            this.addPartnerPOSA4()
          }
          if (this.$store.state.typePOSPrint === 3) {
            this.addPartner()
          }
        }
      // this.addEmptyRow()
      // this.addEmptyRow()
      this.addHeadComment()
      this.addEmptyRow()
      this.addLine()
      if (this.printNoPrice) {
        this.addItemsHeaderNoPrice()
        this.addLine()
        this.addItemsNoPrice()
        this.addLine()
      } else {
        if (!this.exportDoc) {
          this.addItemsHeader()
        }
        if (this.exportDoc) {
          this.addItemsHeaderExport()
        }
        this.addLine()
        this.addItems2()
        this.addLine()

        if (!this.exportDoc) {
          if (!this.noTotal) {
            this.addTotal()
          }
        }
        if (this.exportDoc) {
          if (!this.noTotal) {
            this.addTotalExport()
          }
        }
        if (this.$store.state.documentActiv !== 'POS') {
          this.addBankData()
        }
        else {
          this.addBankDataPOS()
          this.addFooterSmall()
          if (this.document.documentJIR && this.document.documentJIR.length > 3) {
            this.addQRSmall()
          }
        }
      }
      this.addComment()
      
      const noSignature = this.comp.showFooter1 ? true : false
      if (!noSignature) {
        this.addSignature1()
        this.addSignature2()
      }
      if (this.$store.state.documentActiv === 'InvoiceSales' || this.$store.state.documentActiv === 'QuoteSales') {
        if (!this.exportDoc) {
          this.add2Dcode()
          if (noSignature) {
            this.addPaymentCodeWithRow()
          }
          if (!noSignature) {
            this.addPaymentCode()
          }
        }
        if (this.exportDoc && noSignature) {
          this.addSignature5()
        }
      }
      this.addFooter()
      if (this.useMemo) {
        this.addFooterMemo()
      }
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 40 ]
      this.docDefinition.header = function(currentPage, pageCount) {
      return [
        { text: '\n Str.:' + currentPage + ' od ' + pageCount + '\u200B\t', alignment: 'right', fontSize: 7, margin: [0,0,30] },
      ]
      }
      this.content = []
    },
    initDocumentMemo () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 130, 20, 100 ]
      this.docDefinition.header = function(currentPage, pageCount) {
      return [
        { text: '\n Str.:' + currentPage + ' od ' + pageCount + '\u200B\t', alignment: 'right', fontSize: 7, margin: [0,0,30] },
      ]
      }
      this.content = []
    },
    initDocumentSmall () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A6'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 15, 15, 15, 15 ]
      this.content = []
    },
    addFooterMemo () {
      if (this.useMemo) {
          const docPart = {
          columns: [
            {
              width: '5%',
              text: [
                { text: '  ', fontSize: 7, alignment: 'left' }
              ]
            },
            {
              width: '78%',
              text: [
                { text: ' \n ', fontSize: 8 },
                { text: ' \n ', fontSize: 8 },
                { text: ' \n ', fontSize: 8 },
                { text: ' \n ', fontSize: 8 },
                { text: ' \n ', fontSize: 8 },
                { text: ' \n ', fontSize: 8 },
                { text: ' \n ', fontSize: 8 },
                { text: ' \n ', fontSize: 8 },
                { text: ' \n ', fontSize: 8 },
                { text: ' \n ', fontSize: 8 },
                { text: '  ' + (this.comp.CompanyDescriptionUp ? this.comp.CompanyDescriptionUp : ' ') + ' \n ', fontSize: 7, alignment: 'center' },
              ]
            },
          ]
        }
      this.content.push(docPart)
      }
    },
    addFooter () {
      if (!this.useMemo) {
      this.docDefinition.footer = {
          columns: [
            {
              width: '4%',
              text: [
                { text: '  ', fontSize: 7, alignment: 'left' }
              ]
            },
             {
              width: '90%',
              text: [
                { text: '  ' + (this.comp.CompanyDescriptionUp ? this.comp.CompanyDescriptionUp : ' '), fontSize: 7, alignment: 'center' }
              ]
            },
          ]
        }
      }
    },
     addFooterOld () {
      this.docDefinition.footer = {
          columns: [ {

            text: [
              { text: '   ' + this.comp.CompanyName, fontSize: 8, bold: true, alignment: 'center' },
              { text: ',' + (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ', ' + (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') , fontSize: 7 },
              { text: ', ' + this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + ', email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ', ' + this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' ') + ' \n', fontSize: 7 },
              { text: '   ' + (this.comp.CompanyDescriptionUp ? this.comp.CompanyDescriptionUp : ' '), fontSize: 7, alignment: 'center' }
            ]
          }
          ]
        }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addLineSmall () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 100,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addLineSmall2 () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 260,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderWithLogoLeft () {
      const docPart = {
            columns: [
              {
                width: '4%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '51%',
                image: this.comp.CompanyLogo1,
                fit: [70, 70]
              },
              {
                // % width
                width: '40%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: 'email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + ': ' +  (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    add2Dcode() {
      const decPart = this.pSum5.substring(this.pSum5.length - 2)
      const numPart = parseInt(this.document.documentTotalAmountToPay).toString()
      const numLength = numPart.length + 2
      const fullNum = '0'.repeat(15 - numLength) + numPart + decPart
      const code2 = "HRVHUB30\nEUR\n" +
        fullNum + "\n"+
        (this.document.partnerName ? this.document.partnerName: ' ') + "\n"+
        (this.document.partnerStreet ? this.document.partnerStreet : ' ') + ' ' + (this.document.partnerBuildingNameNumber ? this.document.partnerBuildingNameNumber : ' ') + "\n"+
        (this.document.partnerPostcode ? this.document.partnerPostcode : ' ') + ' ' + (this.document.partnerCity ? this.document.partnerCity: ' ') + "\n"+
        this.comp.CompanyName + "\n"+
        (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + "\n"+
        (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + "\n"+
        (this.document.bankAccountNr ? this.document.bankAccountNr : ' ') + "\n"+
        "HR02\n"+
        this.document.documentId + this.document.BusinessYear + "\n"+
        "COST\n"+
        this.locDocumentName + "\n"
        this.Canvas1 = generateBarcode(code2, 4, 2)
    },
    addPaymentCode () {
      this.addEmptyRow()
      const docPart = {
        columns: [
            {
              image: this.Canvas1,
              fit: [175, 92]
            }
        ]
            
        }
      this.content.push(docPart)
    },
    addPaymentCodeWithRow () {
      this.addEmptyRow()
      const docPart = {
        columns: [
            {
              image: this.Canvas1,
              fit: [175, 92]
            },

            {
              width: '25%',
              text: [
                { text: ' \n', fontSize: 10 },
                { text: '____________________  \n', fontSize: 8, alignment: 'center' }
              ]
            }
        ]
            
        }
      this.content.push(docPart)
    },
    addPaymentCodeOld () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '55%',
                image: this.Canvas1,
                fit: [300, 100]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text: ' ', fontSize: 10, bold: true },
                ]
              }
            ],
            // optional space between columns
            columnGap: 1
          }
        this.content.push(docPart)
    },
    addHeaderWithLogoLeftSmall () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '55%',
                image: this.comp.CompanyLogo1,
                fit: [70, 70]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: (this.exportDoc ? this.langE.VATID : this.lang.VATID) + + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 },
                  { text: this.lang.DocPrintRetail + + (this.comp.CompanyLogo5 ? this.comp.CompanyLogo5 : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 1
          }
        this.content.push(docPart)
    },
    addHeaderWithLogoRight () {
      const docPart = {
            columns: [
              {
                width: '7%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                // % width
                width: '50%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: 'email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: (this.exportDoc ? this.langE.VATID : this.lang.VATID) + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '31%',
                image: this.comp.CompanyLogo2,
                fit: [70, 70]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeaderWithLogoFull () {
      const docPart = {
            columns: [
              {
                width: '1%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '78%',
                image: this.comp.CompanyLogo3,
                fit: [540, 78]
              },
            ],
          }
        this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '55%',
                text: [
                  { text: (this.document.documentName === 'ServiceNotes' ? this.comp.CompanyNameShort : this.comp.CompanyName) + ' \n', fontSize: 18, bold: true },
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text: (this.document.documentName === 'ServiceNotes' ? this.comp.CompanyNameShort : this.comp.CompanyName) + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones  + '\n' : ' '), fontSize: 8 },
                  { text: (this.comp.Companyemail ? 'email:' +  this.comp.Companyemail + ' \n' : ' ') , fontSize: 8 },
                  { text: (this.exportDoc ? this.langE.VATID + 'HR' : this.lang.VATID) + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addPartner () {
      let pName = ''
      let haveName = true
      if (this.document.partnerVATID && this.document.partnerVATID.length > 0) {
        if (this.document.partnerVATID === '00000000000' && this.document.partnerSecretaryFirst && this.document.partnerSecretaryFirst.length > 0 && this.document.partnerSecretaryLast && this.document.partnerSecretaryLast.length > 0) {
          pName = (this.document.partnerSecretaryTitle ? this.document.partnerSecretaryTitle + ' ' : '') + this.document.partnerSecretaryFirst + ' ' + this.document.partnerSecretaryLast
          haveName = false
        }
        if (this.document.partnerVATID !== '00000000000')
        {
          pName = (this.document.partnerName ? this.document.partnerName: ' ')
        }
      }
      const docPart = {
        columns: [
              {
                width: '10%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              },
              {
                width: '40%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: pName + ' \n', fontSize: 12, bold: true },
                  { text: (this.document.partnerStreet ? this.document.partnerStreet : ' ') + ' ' + (this.document.partnerBuildingNameNumber ? this.document.partnerBuildingNameNumber : ' ') + ' \n', fontSize: 11 },
                  { text: (this.document.partnerPostcode ? this.document.partnerPostcode : ' ') + ' ' + (this.document.partnerCity ? this.document.partnerCity: ' ') + ' \n', fontSize: 11 },
                  { text: (this.document.partnerState ? this.document.partnerState + ' \n' : ' '), fontSize: 11 },
                  { text: (this.document.partnerSecretaryFirst && haveName? this.document.partnerSecretaryFirst + ' ' : ' ') + (this.document.partnerSecretaryLast && haveName ? this.document.partnerSecretaryLast + ' ' : '') + (this.document.partnerSecretaryEmail & haveName? this.document.partnerSecretaryEmail + ' \n' : ' ') , fontSize: 8 },
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '30%',
                text: [
                  { text: this.locDocumentName + ' \n', fontSize: 15 },
                  { text: this.lang.DocumentDate + ' ' + this.loCdocumentDate + ',' + this.loCdocumentInvoiceTime + '\n', fontSize: 8, bold: true },
                  { text: this.lang.StartDate + ' ' + this.loCdocumentInvoiceStartDate + '\n', fontSize: 8 },
                  { text: (this.$store.state.typePOSPrint != 3 ? this.lang.DeadLine + ' ' + this.loCdocumentInvoiceEndDate : '') + '\n', fontSize: 8 },
                  { text: this.lang.VATIDBuyer + ' ' + (this.document.partnerVATID ? this.document.partnerVATID : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.DocPrintPlace + ' ' + (this.comp.CompanyInvoicePlace ? this.comp.CompanyInvoicePlace : (this.comp.CompanyCity ? this.comp.CompanyCity : ' ')) + ' \n', fontSize: 8 },
                  { text: (this.locDocumentConnFrom ? this.locDocumentConnFrom + ' \n' : '') , fontSize: 9, bold: true },
                  { text: (this.document.partneremail ? 'email: ' + this.document.partneremail : ' ') + ' \n', fontSize: 8 },
                  { text: (this.document.partnerPhones && (this.document.documentName === 'ServiceNotes' || this.document.documentName === 'QuoteSales') ? 'Tel: ' + this.document.partnerPhones : ' ') + ' \n', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addPartnerExport () {
      const docPart = {
        columns: [
              {
                width: '10%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              },
              {
                width: '40%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: (this.document.partnerName ? this.document.partnerName: ' ') + ' \n', fontSize: 12, bold: true },
                  { text: (this.document.partnerStreet ? this.document.partnerStreet : ' ') + ' ' + (this.document.partnerBuildingNameNumber ? this.document.partnerBuildingNameNumber : ' ') + ' \n', fontSize: 11 },
                  { text: (this.document.partnerPostcode ? this.document.partnerPostcode : ' ') + ' ' + (this.document.partnerCity ? this.document.partnerCity: ' ') + ' \n', fontSize: 11 },
                  { text: (this.document.partnerState ? this.document.partnerState + ' \n' : ' '), fontSize: 11 },
                  { text: (this.document.partnerSecretaryFirst ? this.document.partnerSecretaryFirst + ' ' : ' ') + (this.document.partnerSecretaryLast ? this.document.partnerSecretaryLast + ' ' : '') + (this.document.partnerSecretaryEmail ? this.document.partnerSecretaryEmail + ' \n' : ' ') , fontSize: 8 },
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: (this.$store.state.documentActiv != 'POS' ?  this.documentNameExport: 'Invoice') + ': ' + this.document.documentId + '/' + (this.$store.state.documentActiv !== 'POS' ? (this.comp.CompanyBusinessSpace ? this.comp.CompanyBusinessSpace : '_' ) : this.comp.CompanyBusinessSpaceRetail) + '/' + (this.$store.state.documentActiv !== 'POS' ? (this.comp.CompanyBusinessDevice ? this.comp.CompanyBusinessDevice : '_') : this.comp.CompanyBusinessDeviceRetail) + ' \n', fontSize: 15 },
                  { text: this.lang.DocumentDate + '/' + this.langE.DocumentDate + ' ' + this.loCdocumentDate + ',' + this.loCdocumentInvoiceTime + '\n', fontSize: 8, bold: true },
                  { text: this.lang.StartDate + '/' + this.langE.StartDate + ' ' + this.loCdocumentInvoiceStartDate + '\n', fontSize: 8 },
                  { text: (this.$store.state.typePOSPrint != 3 ? this.lang.DeadLine + '/' + this.langE.DeadLine + ' ' + this.loCdocumentInvoiceEndDate : '') + '\n', fontSize: 8 },
                  { text: this.lang.VATIDBuyer + '/' + this.langE.VATIDBuyer + ' ' + (this.document.partnerVATID ? this.document.partnerVATID : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.DocPrintPlace + ' ' + (this.comp.CompanyInvoicePlace ? this.comp.CompanyInvoicePlace : (this.comp.CompanyCity ? this.comp.CompanyCity : ' ')) + ' \n', fontSize: 8 },
                  { text: (this.locDocumentConnFrom ? this.locDocumentConnFrom + ' \n' : '') , fontSize: 9, bold: true },
                  { text: (this.document.partneremail ? 'email: ' + this.document.partneremail : ' ') + ' \n', fontSize: 8 },
                  { text: (this.document.partnerPhones && (this.document.documentName === 'ServiceNotes' || this.document.documentName === 'QuoteSales') ? 'Tel/Phone:' + this.document.partnerPhones : ' ') + ' \n', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addPartnerPOSA4 () {
      const docPart = {
        columns: [
              {
                width: '10%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              },
              {
                width: '40%',
                text: [
                  { text: ' ', fontSize: 8 },
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '30%',
                text: [
                  { text: 'Račun: ' + this.document.documentId + '/' + (this.$store.state.documentActiv !== 'POS' ? this.comp.CompanyBusinessSpace : this.comp.CompanyBusinessSpaceRetail) + '/' + (this.$store.state.documentActiv !== 'POS' ? this.comp.CompanyBusinessDevice : this.comp.CompanyBusinessDeviceRetail) + ' \n', fontSize: 15 },
                  { text: this.lang.DocumentDate + ' ' + this.loCdocumentDate + ',' + this.loCdocumentInvoiceTime + '\n', fontSize: 8, bold: true },
                  { text: this.lang.StartDate + ' ' + this.loCdocumentInvoiceStartDate + '\n', fontSize: 8 },
                  { text: (this.document.partnerVATID ? this.lang.VATIDBuyer + ':' + this.document.partnerVATID : ' ') + ' \n', fontSize: 8 },
                  { text: (this.document.partneremail ? 'email: ' + this.document.partneremail : ' ') + ' \n', fontSize: 8 },
                  { text: (this.document.partnerPhones && this.document.documentName === 'ServiceNotes' ? 'Tel: ' + this.document.partnerPhones : ' ') + ' \n', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addPartnerOnly () {
      const docPart = {
        columns: [
              {
                width: '70%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                  { text: this.document.partnerName + ' \n', fontSize: 12, bold: true },
                  { text: (this.document.partnerStreet ? this.document.partnerStreet  + ' ' : ' ') + (this.document.partnerBuildingNameNumber ? this.document.partnerBuildingNameNumber + ' \n' : '') , fontSize: 8 },
                  { text: (this.document.partnerPostcode ? this.document.partnerPostcode  + '' : '') + (this.document.partnerCity ? this.document.partnerCity + '\n' : '') , fontSize: 8 },
                  { text: (this.document.partnerPhones ? this.document.partnerPhones + ' ' : '') + (this.document.partneremail && this.document.partneremail.trim().length > 0? 'email: ' + this.document.partneremail + ' \n' : '') , fontSize: 8 },
                  { text: (this.document.partnerSecretaryFirst ? this.document.partnerSecretaryFirst + ' ' : ' ') + (this.document.partnerSecretaryLast ? this.document.partnerSecretaryLast + ' ' : '') + (this.document.partnerSecretaryEmail ? this.document.partnerSecretaryEmail + ' \n' : ' ') , fontSize: 8 },
                  { text: (this.document.partnerSecretaryPhone ? this.document.partnerSecretaryPhone + ' ' : ' ') + (this.document.partnerContactTime ? this.document.partnerContactTime + ' \n' : ' ') , fontSize: 8 },
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeadSmall () {
      const docPart = {
        columns: [
              {
                width: '56%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                  { text: this.documentNameLoc + ':' + this.document.documentId + (this.comp.CompanyBusinessSpaceRetail ? '/' + this.comp.CompanyBusinessSpaceRetail : '') + (this.comp.CompanyBusinessDeviceRetail ? '/' + this.comp.CompanyBusinessDeviceRetail : '') + ' \n', fontSize: 14 },
                  { text: this.lang.DocPrintDocumentDate + ':' + this.loCdocumentDate + ',' + this.loCdocumentInvoiceTime + '\n', fontSize: 8, bold: true },
                ]
              },
              {
                width: '42%',
                text: [
                  { text: this.lang.DocPrintRetail + ' ' + (this.comp.CompanyLogo5 ? this.comp.CompanyLogo5 : ' ') +  ' \n', fontSize: 7 },
                  { text: this.lang.DocPrintMadeBySmall + ' ' + (this.document.First ? this.document.First : ' ') + ' ' + (this.document.Last ? this.document.Last : ' ') + ': \n', fontSize: 7, alignment: 'left' },
                  { text: this.paycash + this.paycard + ' \n', fontSize: 7, alignment: 'left' },                  
                ]
              },
            ]
          }
      this.content.push(docPart)
    },
    addQRSmall () {
      //1) Za račun izdan 21.09.2020. u 06:30 sati na iznos 1.525,99 kn link se formira u obliku:
      //https://porezna.gov.hr/rn?jir=12345678-1234-1234-1234-123456789012&datv=20200921_0630&izn=152599
      //2) Za račun izdan 10.01.2021. u 14:00 sati na iznos -105,50 kn (stornirani račun) link se formira u obliku:
      //https://porezna.gov.hr/rn?jir=12345678-1234-1234-1234-123456789012&datv=20210110_1400&izn=-10550
      //3)Primjer QR koda za ZKI s računa:
      //Za račun izdan 05.02.2021. u 08:01 sati na iznos 5,10 kuna link se formira u obliku:
      //https://porezna.gov.hr/rn?zki=12345678123412341234123456789012&datv=20210205_0801&izn=510
      const part1 = 'https://porezna.gov.hr/rn?'
      const part2 = this.document.documentJIR ? 'jir=' + this.document.documentJIR + '&' : (this.document.documentZKI ? 'zki=' + this.document.documentZKI + '&' : '')
      const year = this.document.documentDate ? this.document.documentDate.slice(0,4) : ''
      const month = this.document.documentDate ? this.document.documentDate.slice(5,7) : ''
      const day = this.document.documentDate ? this.document.documentDate.slice(-2) : ''
      const hours = this.document.documentTime ? this.document.documentTime.slice(0,2) : ''
      const minutes = this.document.documentTime ? this.document.documentTime.substr(3,2) : ''
      const part3 = 'datv=' + year + month + day + '_' + hours + minutes + '&'
      const f1 = parseFloat(this.pSum3) < 0 ? '-' : ''
      const f2 = parseInt(parseFloat(this.pSum3))
      const f3 = this.pSum3.slice(-2)
      const part4 = 'izn='+ f1 + f2 + f3
      const fullQR = part1 + part2 + part3 + part4
      const docPart = {qr: fullQR,  fit: '70'}
      this.content.push(docPart)
    },
    addPartnerPOS () {
      const docPart = {
        columns: [
              {
                width: '70%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                  { text: this.document.partnerName + ' \n', fontSize: 12, bold: true },
                  { text: (this.document.partnerStreet ? this.document.partnerStreet : ' ') + ' ' + (this.document.partnerBuildingNameNumber ? this.document.partnerBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.document.partnerPostcode ? this.document.partnerPostcode : ' ') + ' ' + (this.document.partnerCity ? this.document.partnerCity: ' ') + ' \n', fontSize: 8 },
                  { text: (this.document.partneremail ? 'email: ' + this.document.partneremail : ' ') + ' \n', fontSize: 8 },
                  { text: (this.document.partnerPhones && this.document.documentName === 'ServiceNotes' ? 'Tel: ' + this.document.partnerPhones : ' ') + ' \n', fontSize: 8 }
                ]
              },
              {
                width: '30%',
                text: [
                  { text: this.lang.VATIDBuyer + ' ' + (this.document.partnerVATID ? this.document.partnerVATID : ' ') + ' \n', fontSize: 8 },
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeadComment () {
      if (this.document.documentDescription4 || this.document.documentDescription1) {
        const docPart = {
          columns: [
                {
                  width: '10%',
                  text: [
                    { text: ' ', fontSize: 8 }
                  ]
                },
                {
                  width: '60%',
                  text: [
                    { text: ' \n ', fontSize: 8 },
                    { text: ' \n ', fontSize: 8 },
                    { text: (this.document.documentDescription4 ? (this.exportDoc ? 'Predmet/Subject: ': 'Predmet: ') + this.document.documentDescription4 : '') + ' \n ', fontSize: 10, bold: true },
                    { text: ' \n ', fontSize: 8 },
                    { text: (this.document.documentDescription1 ? this.document.documentDescription1 : ' ') + ' \n ', fontSize: 8 }
                  ]
                },
                {
                  width: '10%',
                  text: ' '
                },
                {
                  width: '10%',
                  text: [
                    { text: ' \n', fontSize: 15 },
                    { text: ' \n', fontSize: 8, bold: true },
                    { text: ' \n', fontSize: 8 }
                  ]
                }
              ]
        }
        this.content.push(docPart)
      }
    },
    addHeadCommentSmall () {
      const docPart = {
        columns: [
              {
                width: '80%',
                text: [
                  { text: 'Predmet: ' + this.document.documentDescription4 + ' \n ', fontSize: 10, bold: true },
                  { text: (this.document.documentDescription1 ? this.document.documentDescription1 + '\n ' : '' ) , fontSize: 8 },
                  { text: (this.document.deliveryCurrier && this.document.documentName === 'ServiceNotes' ? 'Serviser:' + this.document.deliveryCurrier : ' ') + ' \n', fontSize: 8 }
                ]
              },
            ]
      }
      this.content.push(docPart)
    },
    addItemsHeader () {
      const amDiscount = parseNum(this.document.documentDescription10)
      if (amDiscount > 0) {
        const docPart = {
              columns: [
                {
                  width: '5%',
                  text: { tex: this.lang.DocPrintOrder + '\n', fontSize: 8 }
                },
                {
                  width: '45%',
                  text: { text: this.lang.DocPrintName + '\n', fontSize: 8, alignment: 'left' }
                },
                {
                  width: '5%',
                  text: { text: this.lang.DocPrintUnit + '\n', fontSize: 8, alignment: 'left' }
                },
                {
                  width: '7%',
                  text: { text: this.lang.DocPrintPcs + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '8%',
                  text: { text: this.lang.DocPrintPrice + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '8%',
                  text: { text: this.lang.DocPrintAmount + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '5%',
                  text: { text: this.lang.DocPrintDiscount + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '9%',
                  text: { text: this.lang.DocPrintAmount2 + '\n', fontSize: 8, alignment: 'center' }
                },
                {
                  width: '4%',
                  text: { text: this.lang.DocPrintVAT + '\n', fontSize: 8, alignment: 'right' }
                },
              ]
            }
        this.content.push(docPart)
      }
      if (amDiscount === 0) {
        const docPart = {
              columns: [
                {
                  width: '5%',
                  text: { tex: this.lang.DocPrintOrder + '\n', fontSize: 8 }
                },
                {
                  width: '55%',
                  text: { text: this.lang.DocPrintName + '\n', fontSize: 8, alignment: 'left' }
                },
                {
                  width: '5%',
                  text: { text: this.lang.DocPrintUnit + '\n', fontSize: 8, alignment: 'left' }
                },
                {
                  width: '8%',
                  text: { text: this.lang.DocPrintPcs + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '8%',
                  text: { text: this.lang.DocPrintPrice + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '9%',
                  text: { text: this.lang.DocPrintAmount2 + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '5%',
                  text: { text: this.lang.DocPrintVAT + '\n', fontSize: 8, alignment: 'right' }
                },
              ]
            }
        this.content.push(docPart)
      }
    },
    addItemsHeaderExport () {
      const amDiscount = parseNum(this.document.documentDescription10)
      if (amDiscount > 0) {
        const docPart = {
              columns: [
                {
                  width: '5%',
                  text: { tex: this.lang.DocPrintOrder + '\n' + 'Nr.:' + '\n', fontSize: 8 }
                },
                {
                  width: '45%',
                  text: { text: this.lang.DocPrintName + '\n' + 'Name:' + '\n', fontSize: 8, alignment: 'left' }
                },
                {
                  width: '6%',
                  text: { text: this.lang.DocPrintUnit + '\n' + 'Unit:' + '\n', fontSize: 8, alignment: 'left' }
                },
                {
                  width: '6%',
                  text: { text: this.lang.DocPrintPcs + '\n' + 'Peaces:' + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '7%',
                  text: { text: this.lang.DocPrintPrice + '\n' + 'Price'  + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '7%',
                  text: { text: this.lang.DocPrintAmount + '\n'  + 'Amount'  + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '7%',
                  text: { text: this.lang.DocPrintDiscount + '\n' + 'Disc.%:' + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '9%',
                  text: { text: this.lang.DocPrintAmount2 + '\n' + 'Am.No VAT' + '\n', fontSize: 8, alignment: 'center' }
                },
                {
                  width: '4%',
                  text: { text: this.lang.DocPrintVAT + '\n' + 'VAT%:' + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '5%',
                  text: { text: this.hmCR + '\n', fontSize: 9, bold:true,  alignment: 'center' }
                },
              ]
            }
        this.content.push(docPart)
      }
      if (amDiscount === 0) {
        const docPart = {
              columns: [
                {
                  width: '5%',
                  text: { tex: this.lang.DocPrintOrder + '\n' + 'Nr.:' + '\n', fontSize: 8 }
                },
                {
                  width: '58%',
                  text: { text: this.lang.DocPrintName + '\n' + 'Name:' + '\n', fontSize: 8, alignment: 'left' }
                },
                {
                  width: '5%',
                  text: { text: this.lang.DocPrintUnit + '\n' + 'Unit:' + '\n', fontSize: 8, alignment: 'left' }
                },
                {
                  width: '6%',
                  text: { text: this.lang.DocPrintPcs + '\n' + 'Peaces:' + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '7%',
                  text: { text: this.lang.DocPrintPrice + '\n' + 'Price'  + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '9%',
                  text: { text: this.lang.DocPrintAmount2 + '\n' + 'Am.No VAT' + '\n', fontSize: 8, alignment: 'center' }
                },
                {
                  width: '4%',
                  text: { text: this.lang.DocPrintVAT + '\n' + 'VAT%:' + '\n', fontSize: 8, alignment: 'right' }
                },
                {
                  width: '4%',
                  text: { text: this.hmCR + '\n', fontSize: 9, bold:true,  alignment: 'center' }
                },
              ]
            }
        this.content.push(docPart)
      }
    },
    addItemsHeaderSmall () {
      const docPart = {
            columns: [
              {
                width: '4%',
                text: { tex: this.lang.DocPrintOrder + '\n', fontSize: 8 }
              },
              {
                width: '32%',
                text: { text: this.lang.DocPrintName + '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '15%',
                text: { text: this.lang.DocPrintPcsSmall + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '13%',
                text: { text: this.lang.DocPrintPrice + '\n', fontSize: 8, alignment: 'right' }
              },
              {
                width: '10%',
                text: { text: this.lang.DocPrintDiscountSmall + '\n', fontSize: 8, alignment: 'center' }
              },
              {
                width: '9%',
                text: { text: this.lang.DocPrintVAT + '\n', fontSize: 8, alignment: 'center' }
              },
              {
                width: '14%',
                text: { text: this.lang.DocPrintAmount3Small + '\n', fontSize: 8, alignment: 'right' }
              }
            ]
          }
      this.content.push(docPart)
    },
    addItemsHeaderNoPrice () {
      const docPart = {
          columns: [
              {
                width: '5%',
                text: { tex: this.lang.DocPrintOrder + '\n', fontSize: 8 }
              },
              {
                width: '64%',
                text: { text: this.lang.DocPrintName + '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '10%',
                text: { text: this.lang.DocPrintUnit + '\n', fontSize: 8, alignment: 'left' }
              },
              {
                width: '11%',
                text: { text: this.lang.DocPrintPcs + '\n', fontSize: 8, alignment: 'right' }
              },
            ]
          }
      this.content.push(docPart)
    },
    addItemsNoPriceOLD () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: this.pC1
              },
              {
                width: '65%',
                text: this.pC2
              },
              {
                width: '10%',
                text: this.pC10
              },
              {
                width: '10%',
                text: this.pC4
              },
            ]
          }
      this.content.push(docPart)
    },
    addItems () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: this.pC1
              },
              {
                width: '35%',
                text: this.pC2
              },
              {
                width: '5%',
                text: this.pC10
              },
              {
                width: '8%',
                text: this.pC4
              },
              {
                width: '8%',
                text: this.pC3
              },
              {
                width: '8%',
                text: this.pC5
              },
              {
                width: '5%',
                text: this.pC6
              },
              {
                width: '8%',
                text: this.pC7
              },
              {
                width: '5%',
                text: this.pC8
              },
              {
                width: '8%',
                text: this.pC9
              }
            ]
          }
      this.content.push(docPart)
    },
    addItemsSmall () {
          this.order = 0
            if (this.items) {
              this.items.map(item => {
                if (parseFloat(item.itemAmountWithVAT) !== 0) {

                  this.order += 1
                  this.pTtt3 = this.formatPrice(item.itemPrice)
                  this.pTtt11 = this.formatPrice(item.itemPrice * (1 + (item.itemVAT1Percentage/100)))
                  if (this.$store.state.documentSide === 2) {
                    this.pTtt4 = item.itemPcsMinus ? this.formatPrice(item.itemPcsMinus) : 0
                    // eslint-disable-next-line
                    console.log(' have Pcs Minus ', item.ItemPcsMinus, ' pTtt4 ', this.pTtt4 )
                  }
                  if (this.$store.state.documentSide === 3) {
                    this.pTtt4 = item.itemPcsNeutral ? this.formatPrice(item.itemPcsNeutral) : 0
                  }
                  this.pTtt5 = item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0
                  this.pTtt6 = item.itemDiscountPercentage1 ? this.formatPrice(item.itemDiscountPercentage1) : 0
                  this.pTtt7 = item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0
                  this.pTtt8 = item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0
                  this.pTtt9 = item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0
                  const docPart = {
                  columns: [
                        {
                          width: '4%',
                          fontSize: 8,
                          alignment: 'left',
                          text: this.order
                        },
                        {
                          width: '32%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.itemName + (item.itemDescription ? '\n' + item.itemDescription : '') + (item.itemSerialNr ? '\n Ser.br.:' + item.itemSerialNr : '') + (item.itemID ? '\n' + item.itemID : '')
                        },
                        {
                          width: '15%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt4 + ' ' + item.itemUnit
                        },
                        {
                          width: '13%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt11
                        },
                        {
                          width: '10%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt6
                        },
                        {
                          width: '9%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt8 
                        },
                        {
                          width: '14%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.pTtt9
                        }
                      ]
                    }
                  this.content.push(docPart)
                }
              })
          }
    },
    addTotal () {
      const amDiscount = parseNum(this.document.documentDescription10)
      const amInAdvance = parseNum(this.document.documentTotalAmountPayInAdvance)
      let descTotal = []
      let amTotal = []
      // if have advanced payment
      if (amInAdvance !== 0)
      {
        // if no rabat 
        if (amDiscount === 0) {
        const decTotalFull = [
                  { text: this.lang.DocPrintTotal1 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal2 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal3 + ': \n', fontSize: 8, bold: true },
                  { text: this.lang.DocPrintTotal4 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal5 + '(' + this.hmCR + '): \n', fontSize: 12, bold: true }
                ]
        const amTotalFull = [
                  { text: this.pSum1 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum2 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum3 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum4 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum5 + '\n', fontSize: 12, bold: true, alignment: 'right' }
                ]
        descTotal = [...decTotalFull]
        amTotal = [...amTotalFull]
        }
          // if rabat 
        if (amDiscount !== 0) {
        const decTotalFull = [
                  { text: this.lang.DocPrintTotal00 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal01 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal1 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal2 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal3 + ': \n', fontSize: 8, bold: true },
                  { text: this.lang.DocPrintTotal4 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal5 + '(' + this.hmCR + '): \n', fontSize: 12, bold: true }
                ]
        const amTotalFull = [
                  { text: this.pSum00 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum01 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum1 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum2 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum3 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum4 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum5 + '\n', fontSize: 12, bold: true, alignment: 'right' }
                ]
        descTotal = [...decTotalFull]
        amTotal = [...amTotalFull]
        }

      }
      // if dont have advanced payment
      if (amInAdvance === 0)
      {
        const eur2022 = (this.businessYear === 2022 ? '(' + round((this.pNumSum5/ 7.5345), 2) + ' EUR)' : '') 
        // if no rabat x
        if (amDiscount === 0) {
          const decTotalFull = [
                    { text: this.lang.DocPrintTotal1 + ': \n', fontSize: 8 },
                    { text: this.lang.DocPrintTotal2 + ': \n', fontSize: 8 },
                    { text: this.lang.DocPrintTotal5 + '(' + this.hmCR + '): \n', fontSize: 12, bold: true }
                  ]
             
          const amTotalFull = [
                    { text: this.pSum1 + '\n', fontSize: 8, alignment: 'right' },
                    { text: this.pSum2 + '\n', fontSize: 8, alignment: 'right' },
                    { text: this.pSum5 + '\n', fontSize: 12, bold: true, alignment: 'right' },
                   // { text: eur2022 + '\n', fontSize: 8, alignment: 'right' }
                  ]
          
          descTotal = [...decTotalFull]
          amTotal = [...amTotalFull]
        }
        // if rabat if > 0 
        if (amDiscount > 0) {
          const decTotalFull = [
                    { text: this.lang.DocPrintTotal00 + ': \n', fontSize: 8 },
                    { text: this.lang.DocPrintTotal01 + ': \n', fontSize: 8 },
                    { text: this.lang.DocPrintTotal1 + ': \n', fontSize: 8 },
                    { text: this.lang.DocPrintTotal2 + ': \n', fontSize: 8 },
                    { text: this.lang.DocPrintTotal5 + '(' + this.hmCR + '): \n', fontSize: 12, bold: true }
                  ]
          const amTotalFull = [
                    { text: this.pSum00 + '\n', fontSize: 8, alignment: 'right' },
                    { text: this.pSum01 + '\n', fontSize: 8, alignment: 'right' },
                    { text: this.pSum1 + '\n', fontSize: 8, alignment: 'right' },
                    { text: this.pSum2 + '\n', fontSize: 8, alignment: 'right' },
                    { text: this.pSum5 + '\n', fontSize: 12, bold: true, alignment: 'right' },
                    //{ text: eur2022 + '\n', fontSize: 8, alignment: 'right' }
                  ]
          descTotal = [...decTotalFull]
          amTotal = [...amTotalFull]
        }
      } 


      const docPart = {
          columns: [
            {
              width: '5%',
              text: ' '
            },
            {
              width: '40%',
              text: [
                { text: (parseNum(this.document.documentTotalVAT1Amount) !== 0 ? this.lang.DocPrintVAT1 + ' ' + this.formatPrice(this.document.documentTotalVAT1Amount) : '') + '\n', fontSize: 8 },
                { text: (parseNum(this.document.documentTotalVAT2Amount) !== 0 ? this.lang.DocPrintVAT2 + ' ' + this.formatPrice(this.document.documentTotalVAT2Amount) : '') + '\n', fontSize: 8 },
                { text: (parseNum(this.document.documentTotalVAT3Amount) !== 0 ? this.lang.DocPrintVAT3 + ' ' + this.formatPrice(this.document.documentTotalVAT3Amount) : '') + '\n', fontSize: 8 },
                { text: (parseNum(this.document.FreeF4) !== 0 ? this.lang.DocPrintVAT4 + ' ' + this.formatPrice(this.document.FreeF4) : '') + '\n', fontSize: 8 }
              ]
            },
            {
              width: '8%',
              text: ' '
            },
            {
              width: '21%',
              text: descTotal
            },
            {
              width: '8%',
              text: [
                { text: ' ', alignment: 'right' }
              ]
            },
            {
              width: '13%',
              text: amTotal
            }
          ]
        }
      this.content.push(docPart)
      
    },
    addTotalExport () {
      const amDiscount = parseNum(this.document.documentDescription10)
      const amInAdvance = parseNum(this.document.documentTotalAmountPayInAdvance)
      let descTotal = []
      let amTotalHR = []
      //let amTotalEUR = []
      // if have advanced payment
      if (amInAdvance !== 0)
      {
        // if no rabat 
        if (amDiscount === 0) {
          const decTotalFull = [
                  { text:  ' \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal1 + '/' + this.langE.DocPrintTotal1 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal2 + '/' + this.langE.DocPrintTotal2 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal3 + '/' + this.langE.DocPrintTotal3 + ': \n', fontSize: 8, bold: true },
                  { text: this.lang.DocPrintTotal4 + '/' + this.langE.DocPrintTotal4 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal5 + '/' + this.langE.DocPrintTotal5 + ': \n', fontSize: 10, bold: true },
                ]
          const amTotalFullHR = [
                  { text: this.hmCR + '\n', bold: true, fontSize: 9, alignment: 'right' },
                  { text: this.pSum1 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum2 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum3 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum4 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum5 + '\n', fontSize: 12, bold: true, alignment: 'right' },
                ]
          // const amTotalFullEUR = [
          //         { text: this.exCR + '\n', fontSize: 8, bold: true,alignment: 'right' },
          //         { text: this.peSum1 + '\n', fontSize: 8, alignment: 'right' },
          //         { text: this.peSum2 + '\n', fontSize: 8, alignment: 'right' },
          //         { text: this.peSum3 + '\n', fontSize: 8, alignment: 'right' },
          //         { text: this.peSum4 + '\n', fontSize: 8, alignment: 'right' },
          //         { text: this.peSum5 + '\n', fontSize: 12, bold: true, alignment: 'right' },
          //       ]
          descTotal = [...decTotalFull]
          amTotalHR = [...amTotalFullHR]
          // amTotalEUR = [...amTotalFullEUR]
        }
          // if rabat 
        if (amDiscount !== 0) {
          const decTotalFull = [
                  { text:  ' \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal00 + '/' + this.langE.DocPrintTotal00 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal01 + '/' + this.langE.DocPrintTotal01 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal1 + '/' + this.langE.DocPrintTotal1 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal2 + '/' + this.langE.DocPrintTotal2 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal3 + '/' + this.langE.DocPrintTotal3 + ': \n', fontSize: 8, bold: true },
                  { text: this.lang.DocPrintTotal4 + '/' + this.langE.DocPrintTotal4 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal5 + '/' + this.langE.DocPrintTotal5 + ': \n', fontSize: 10, bold: true },
                ]
          const amTotalFullHR = [
                  { text: this.hmCR + '\n', bold: true, fontSize: 9, alignment: 'right' },
                  { text: this.pSum00 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum01 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum1 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum2 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum3 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum4 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum5 + '\n', fontSize: 12, bold: true, alignment: 'right' },
                ]
          // const amTotalFullEUR = [
          //         { text: this.exCR + '\n', fontSize: 8, bold: true,alignment: 'right' },
          //         { text: this.peSum00 + '\n', fontSize: 8, alignment: 'right' },
          //         { text: this.peSum01 + '\n', fontSize: 8, alignment: 'right' },
          //         { text: this.peSum1 + '\n', fontSize: 8, alignment: 'right' },
          //         { text: this.peSum2 + '\n', fontSize: 8, alignment: 'right' },
          //         { text: this.peSum3 + '\n', fontSize: 8, alignment: 'right' },
          //         { text: this.peSum4 + '\n', fontSize: 8, alignment: 'right' },
          //         { text: this.peSum5 + '\n', fontSize: 12, bold: true, alignment: 'right' },
          //       ]      
          descTotal = [...decTotalFull]
          amTotalHR = [...amTotalFullHR]
         // amTotalEUR = [...amTotalFullEUR]
        }

      }
      // if dont have advanced payment
      if (amInAdvance === 0)
      {
        // if no rabat 
        if (amDiscount === 0) {
          const decTotalFull = [
                  { text:  ' \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal1 + '/' + this.langE.DocPrintTotal1 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal2 + '/' + this.langE.DocPrintTotal2 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal5 + '/' + this.langE.DocPrintTotal5 + ': \n', fontSize: 10, bold: true },
                ]
          const amTotalFullHR = [
                  { text: this.hmCR + '\n', bold: true, fontSize: 9, alignment: 'right' },
                  { text: this.pSum1 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum2 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum5 + '\n', fontSize: 12, bold: true, alignment: 'right' },
                ]
          // const amTotalFullEUR = [
          //         { text: this.exCR + '\n', fontSize: 8, bold: true,alignment: 'right' },
          //         { text: this.peSum1 + '\n', fontSize: 8, alignment: 'right' },
          //         { text: this.peSum2 + '\n', fontSize: 8, alignment: 'right' },
          //         { text: this.peSum5 + '\n', fontSize: 12, bold: true, alignment: 'right' },
          //       ]      
          descTotal = [...decTotalFull]
          amTotalHR = [...amTotalFullHR]
         // amTotalEUR = [...amTotalFullEUR]
        }
        // if rabat if > 0 
        if (amDiscount > 0) {
          const decTotalFull = [
                  { text:  ' \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal00 + '/' + this.langE.DocPrintTotal00 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal01 + '/' + this.langE.DocPrintTotal01 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal1 + '/' + this.langE.DocPrintTotal1 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal2 + '/' + this.langE.DocPrintTotal2 + ': \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal5 + '/' + this.langE.DocPrintTotal5 + ': \n', fontSize: 10, bold: true },
                ]
          const amTotalFullHR = [
                  { text: this.hmCR + '\n', bold: true, fontSize: 9, alignment: 'right' },
                  { text: this.pSum00 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum01 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum1 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum2 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum5 + '\n', fontSize: 12, bold: true, alignment: 'right' },
                ]
          // const amTotalFullEUR = [
          //         { text: this.exCR + '\n', fontSize: 8, bold: true,alignment: 'right' },
          //         { text: this.peSum00 + '\n', fontSize: 8, alignment: 'right' },
          //         { text: this.peSum01 + '\n', fontSize: 8, alignment: 'right' },
          //         { text: this.peSum1 + '\n', fontSize: 8, alignment: 'right' },
          //         { text: this.peSum2 + '\n', fontSize: 8, alignment: 'right' },
          //         { text: this.peSum5 + '\n', fontSize: 12, bold: true, alignment: 'right' },
          //       ]      
          descTotal = [...decTotalFull]
          amTotalHR = [...amTotalFullHR]
         // amTotalEUR = [...amTotalFullEUR]
        }
      } 
      

      const docPart = {
            columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '30%',
                text: [
                  { text: (parseNum(this.document.documentTotalVAT1Amount) !== 0 ? this.lang.DocPrintVAT1 + ' ' + this.document.documentTotalVAT1Amount : '') + '\n', fontSize: 8 },
                  { text: (parseNum(this.document.documentTotalVAT2Amount) !== 0 ? this.lang.DocPrintVAT2 + ' ' + this.document.documentTotalVAT2Amount : '') + '\n', fontSize: 8 },
                  { text: (parseNum(this.document.documentTotalVAT3Amount) !== 0 ? this.lang.DocPrintVAT3 + ' ' +this.document.documentTotalVAT3Amount : '') + '\n', fontSize: 8 },
                  { text: (parseNum(this.document.FreeF4) !== 0 ? this.lang.DocPrintVAT4 + ' ' + this.formatPrice(this.document.FreeF4) : '') + '\n', fontSize: 8 }
                ]
              },
              {
                width: '38%',
                text: descTotal
              },
              {
              width: '24%',
                text: amTotalHR
              },
              // {
              //   width: '12%',
              //   text: amTotalEUR
              // },
            ]
          }
      this.content.push(docPart)
    },
    addTotalSmall () {
      const docPart = {
            columns: [
              {
                width: '45%',
                text: [
                  { text: this.lang.DocPrintVAT1 + ' ' + (this.document.documentTotalVAT1Amount ? this.formatPrice(this.document.documentTotalVAT1Amount) : 0) + ';' + this.lang.DocPrintBase + ' ' + (this.document.documentTotalVAT1Base ? this.formatPrice(this.document.documentTotalVAT1Base) : 0) + '\n', fontSize: 7 },
                  { text: this.lang.DocPrintVAT2 + ' ' + (this.document.documentTotalVAT2Amount ? this.formatPrice(this.document.documentTotalVAT2Amount) : 0) + ';' + this.lang.DocPrintBase + ' ' + (this.document.documentTotalVAT2Base ? this.formatPrice(this.document.documentTotalVAT2Base) : 0) + '\n', fontSize: 7 },
                  { text: this.lang.DocPrintVAT3 + ' ' + (this.document.documentTotalVAT3Amount ? this.formatPrice(this.document.documentTotalVAT3Amount) : 0) + ';' + this.lang.DocPrintBase + ' ' + (this.document.documentTotalVAT3Base ? this.formatPrice(this.document.documentTotalVAT3Base) : 0) + '\n', fontSize: 7 },
                  { text: this.lang.DocPrintVAT4 + ' ' + (this.document.FreeF4 ? this.formatPrice(this.document.FreeF4) : 0) + '\n', fontSize: 7 }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: this.lang.DocPrintTotal1Small + (this.document.documentHomeCurrency ? this.document.documentHomeCurrency : '' ) + ') \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal2Small + (this.document.documentHomeCurrency ? this.document.documentHomeCurrency : '') + '): \n', fontSize: 8 },
                  { text: this.lang.DocPrintTotal3Small + (this.document.documentHomeCurrency ? this.document.documentHomeCurrency : '') + '): \n', fontSize: 12, bold: true},
                ]
              },
              {
                width: '27%',
                text: [
                  { text: this.pSum1 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum2 + '\n', fontSize: 8, alignment: 'right' },
                  { text: this.pSum3 + '\n', fontSize: 12, alignment: 'right', bold: true },
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addBankData () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '40%',
                text: [
                  { text: this.lang.DocPrintPayType + ' ' + '\n', fontSize: 8 },
                  { text: this.lang.DocPrintIdUser + ' ' + this.document.UserId + '\n', fontSize: 8 },
                  { text: this.lang.BankAccount + ' ' + (this.document.bankAccountNr ? this.document.bankAccountNr : ' ') + '\n', fontSize: 10, bold: true },
                  { text: this.lang.DocPrintPayUseNr + '   ' + this.document.documentId + this.document.BusinessYear + '\n', fontSize: 8 },
                  { text: (this.bankDesc ? this.bankDesc : (this.document.bankAccountName ? this.document.bankAccountName : ' ')) + '\n', fontSize: 8 },
                  { text: (this.exportDoc && this.SWIFT ? this.SWIFT : '') + '\n', fontSize: 8 }
                ]
              },
              {
                width: '8%',
                text: ' '
              },
              {
                width: '21%',
                text: [
                  { text: ' \n', fontSize: 8 }
                ]
              },
              {
                width: '8%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '13%',
                text: [
                  { text: ' \n', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addBankDataPOS () {
      const docPart = {
        columns: [
 
              {
                width: '40%',
                text: [
                  { text: this.paycash + this.paycard + ' \n', fontSize: 7, alignment: 'left' }, 
                  { text: this.lang.DocPrintIdUser + ' ' + this.document.UserId + '\n', fontSize: 8 },
                ]
              },
              {
                width: '8%',
                text: ' '
              },
              {
                width: '21%',
                text: [
                  { text: ' \n', fontSize: 8 }
                ]
              },
              {
                width: '8%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '13%',
                text: [
                  { text: ' \n', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addComment () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '80%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: (this.lang.DocPrintComment ? this.lang.DocPrintComment: '') + ' ' + (this.document.documentDescription5 ? this.document.documentDescription5 : '') + ' \n', fontSize: 10 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addFooterSmall () {
      const docPart = {
        columns: [
              {
                width: '90%',
                text: [
                  { text: this.lang.DocPrintZKI + ':' + (this.document.documentJIR ? this.document.documentZKI : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.DocPrintJIR + ':' + (this.document.documentJIR ? this.document.documentJIR : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyInvoiceHint ? this.comp.CompanyInvoiceHint : ' ') + ' \n', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addFooter3 () {
      const docPart = {
        columns: [
              {
                width: '80%',
                text: [
                  { text: (this.comp.CompanyDescriptionUp ? this.comp.CompanyInvoiceHint : ' ') + ' \n', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addSignature1 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
   
                  { text: ' \n', fontSize: 10 },
                 // { text: this.lang.DocPrintMadeBy + ' ' + (this.document.First ? this.document.First : ' ') + ' ' + (this.document.Last ? this.document.Last : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                //  { text: this.lang.DocPrintControlBy + ' ' + (this.document.FirstControl ? this.document.FirstControl : ' ') + ' ' + (this.document.LastControl ? this.document.LastControl : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: this.lang.DocPrintCFO + ' ' + (this.document.FirstCFO ? this.document.FirstCFO : ' ') + ' ' + (this.document.LastCFO ? this.document.LastCFO : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addSignature2 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                 // { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________  \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addSignature3 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: 'Primio na servis: ' + (this.document.First ? this.document.First : ' ') + ' ' + (this.document.Last ? this.document.Last : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: ' Predao : \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addSignature4 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addSignature5 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '55%',
                text: [
                  { text: ' \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addEmpty () {
      const docPart = {
        
          }
      this.content.push(docPart)
    },
    printCustomDoc() {
      this.initDocument()

      const docPart = {
        columns: [
              {
                width: '60%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                ]
              },
              {
                width: '40%',
                text: [

                  { text: ' \n ', fontSize: 6 },
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: (this.document.documentDate ? dayjs(this.document.documentDate).format('YYYY') : ' ') + '-' + this.document.documentId + ' \n', fontSize: 12 },

                ]
              }
            ]
          }
      this.content.push(docPart)

      const docPart2 = {
        columns: [
              {
                width: '18%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                ]
              },
              {
                width: '40%',
                text: [
                  { text: ' \n ', fontSize: 7 },
                  { text: ' \n ', fontSize: 7 },
                  { text: ' \n ', fontSize: 9 },
                  { text: this.document.partnerName + ' \n', fontSize: 12 },
                  { text: ' \n ', fontSize: 7 },
                  { text: (this.document.partnerStreet ? this.document.partnerStreet  + ' ' : ' ') + (this.document.partnerBuildingNameNumber ? this.document.partnerBuildingNameNumber : '') + ', '+ (this.document.partnerPostcode ? this.document.partnerPostcode : ' ') + ' ' + (this.document.partnerCity ? this.document.partnerCity: ' ') + ' \n', fontSize: 9 },
                ]
              },
              {
                width: '12%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                ]
              },
              {
                width: '30%',
                text: [
                  { text: ' \n ', fontSize: 7 },
                  { text: ' \n ', fontSize: 7 },
                  { text: ' \n ', fontSize: 9 },
                  { text: this.loCdocumentDate + '\n', fontSize: 12},
                  { text: ' \n ', fontSize: 7 },
                  { text: this.loCdocumentInvoiceEndDate + '\n', fontSize: 12 },
                ]
              }
            ]
          }
      this.content.push(docPart2)
      const docPart3 = {
        columns: [
              {
                width: '60%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                ]
              },
              {
                width: '40%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                ]
              }
            ]
          }
      this.content.push(docPart3)
      this.order = 0
      if (this.items) {
        this.items.map(item => {
          this.order += 1
            const docPart4 = {
              columns: [
                  {
                    width: '6%',
                    fontSize: 8,
                    alignment: 'left',
                    text: ' '
                  },
                  {
                    width: '5%',
                    fontSize: 9,
                    alignment: 'left',
                    text: this.order
                  },
                  {
                    width: '10%',
                    fontSize: 9,
                    alignment: 'left',
                    text: item.itemID
                  },
                  {
                    width: '32%',
                    fontSize: 9,
                    alignment: 'left',
                    text: item.itemName
                  },
                  {
                    width: '5%',
                    fontSize: 9,
                    alignment: 'left',
                    text: item.itemUnit
                  },
                  {
                    width: '12%',
                    fontSize: 8,
                    alignment: 'left',
                    text: '(' + Math.trunc(parseNum(item.ItemSize)) + '*' + Math.trunc(parseNum(item.FreeF4)) + ')'
                  },
                  {
                    width: '12%',
                    fontSize: 9,
                    alignment: 'right',
                    text: Math.trunc(parseNum(item.itemPcsMinus))
                  },
                ]
              }
              this.content.push(docPart4)
              const docPart5 = {
                columns: [
                      {
                        width: '60%',
                        text: [
                          { text: ' \n ', fontSize: 5 },
                        ]
                      },
                    ]
                  }
              this.content.push(docPart5)
            
        })
      } 
      if (this.order < 10) {
        for (let i = 0; i < (10 - this.order); i++) {
          const docPart6 = {
            columns: [
                  {
                    width: '60%',
                    text: [
                      { text: ' \n ', fontSize: 14 },
                    ]
                  },
                ]
              }
          this.content.push(docPart6)
        } 
      }

      const docPart7 = {
        columns: [
            {
                width: '55%',
                text: [
                  { text: ' \n ', fontSize: 5 },
                ]
              },
              {
                width: '35%',
                text: [
                  // { text: ' \n ', fontSize: 14 },
                  // { text: ' \n ', fontSize: 14 },
                  // { text: ' \n ', fontSize: 14 },
                  // { text: ' \n ', fontSize: 14 },
                  // { text: ' \n ', fontSize: 14 },
                  { text: ' \n ', fontSize: 14 },
                  { text: ' \n ', fontSize: 14 },
                  { text: ' \n ', fontSize: 14 },

                  { text: (this.document.documentDescription5 ? this.document.documentDescription5 : '')  + ' \n ', fontSize: 9 },
                ]
              },
            ]
          }
      this.content.push(docPart7)

      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    print3 () {
      this.docDefinition2 = {}
      this.docDefinition2.pageSize = 'A4'
      this.docDefinition2.pageOrientation = 'portrait'
      this.docDefinition2.pageMargins = [ 20, 20, 20, 20 ]
      this.docDefinition2.footer = {
          columns: [ {
            text: [
              { text: '   ' + this.comp.CompanyName, fontSize: 8, bold: true, alignment: 'center' },
              { text: ',' + (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ', ' + (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') , fontSize: 7 },
              { text: ', ' + this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') +  (this.comp.Companyemail ? ', email:' + this.comp.Companyemail : ' ') + ', ' + this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' ') + ' \n', fontSize: 7 },
              { text: '   ' + (this.comp.CompanyDescriptionUp ? this.comp.CompanyDescriptionUp : ' '), fontSize: 7, alignment: 'center' }
            ]
          }
          ]
        }

      const docPart = {
            columns: [
              {
                width: '7%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '51%',
                image: this.comp.CompanyLogo1,
                fit: [70, 70]
              },
              {
                // % width
                width: '30%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: 'email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content2.push(docPart)
        // eslint-disable-next-line
        console.log('Content 2', this.content2)
        const docPart2 = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
        this.content2.push(docPart2)
        
    },

  },
  computed: {
  },
  watch: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
