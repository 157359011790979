const docEN = {
  Calendar: 'Calendar',
  CalDate: 'Date',
  CalDay: 'Day ',
  CalMonth: 'Month ',
  CalYear: 'Year ',
  CalDayInYear: 'Day in Year ',
  CalWeek: 'Week ',
  CalDescription: 'Description ',
  CalWorkDay: 'WorkDay ',
  CalHolidayDescription: 'Holiday Description ',
  FreeF1: 'Day in a week ',
  CalEmpty: 'Calendar is empty'
}

export default docEN