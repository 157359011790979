const docHR = {
  DigitalAr: 'Digitalna Arhiva',
  ShortDesc: 'Kratki opis',
  Desc: 'Opis',
  Name: 'Naziv',
  Saved : 'Snimljeno',
  Actions: 'Obriši',
  Docs: 'dokumenata',
  Empty: 'prazna',
  SavedDoc: 'Skenirani dokument je snimljen',
  CannotFind: 'Sorry,dokumenat nije definiran. Ne mogu ga otvoriti.',
  DigitalArList: 'Digitalna Arhiva Lista',
  typeDoc: 'Vrsta dokumenta',
  DateOpen: 'Kreirano',
  documentId: 'Broj dokumenta',
}

export default docHR