const documentExportName = (inputName) => {

  let exportName = ''
  switch (inputName) {
    case 'InquirySales':
      exportName = 'Inquiry'
      break
    case 'QuoteSales':
      exportName = 'Quote'
      break
    case 'OrderSales':
      exportName = 'Order'
      break;
    case 'DeliveryNoteSales':
      exportName = 'Delivery'
      break;
    case 'InternalDeliveryNotes':
      exportName = 'Internal Delivery'
      break;
    case 'ServiceNotes':
      exportName = 'Service Notes'
      break;
    case 'InvoiceAdvance':
      exportName = 'Proforma Invoice'
      break;
    case 'InvoiceSales':
      exportName = 'Invoice'
      break;
    case 'ContractSales':
      exportName = 'Contract'
      break;
    case 'WorkingOrder':
      exportName = 'Working Order'
      break;
    case 'POS':
      exportName = 'POS'
      break;
    case 'SaleAnalysis':
      exportName = 'Sales Analysis'
      break;
    case 'RequestPurchase':
      exportName = 'Request'
      break;
    case 'InquiryPurchase':
      exportName = 'Inquiry'
      break;
    case 'OrderPurchase':
      exportName = 'Order'
      break;
    case 'ReceiptPurchase':
      exportName = 'Receipt'
      break;
    case 'ReturnPurchase':
      exportName = 'Return'
      break;
    case 'Inventory':
      exportName = 'Inventoru'
      break;
    case 'PurchaseAnalysis':
      exportName = 'Purchase Analysis'
      break;
    default:
      exportName = ''
  }
  return exportName
}

export default documentExportName