<template>
  <v-container>
    <v-row no-gutters justify="center" class="pa-0">
      <v-col cols="12" sm="12" md="12" xs="12" lg="11" xl="11">

          <v-form
            name="DocumentForm"
            autocomplete="off">
            <v-row no-gutters>
              <v-col cols="12" sm="7" md="7" xs="7" lg="7" xl="7" class="py-0 px-2">
                <h2>
                  <span v-if="disStatus">
                     {{ lang.VATStatement }}
                  </span>
                  <span v-if="!disStatus">
                    {{langC.Edit}} {{ lang.VATStatement }}
                  </span>
                </h2>
               </v-col>
               <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="py-0 px-2">
                  <v-btn text color="primary" @click="fillForm" class="mr-2" v-if="!disStatus">
                    Napuni obrazac
                  </v-btn>
                  <v-btn text @click="createvatform" class="mr-2">1.Kreiraj export</v-btn>
                  <v-btn text @click="uploadvatform">2.Download</v-btn>
              </v-col>

            </v-row>
            <br>

            <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  {{lang.CompanyName}}
              </v-col>
              <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8" class="py-0 px-2">
                  <span style="font-weight:bold">{{mainledger.CompanyNameDatabase}}</span>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  {{lang.BusinessYear}}
              </v-col>
              <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8"  class="py-0 px-2">
                  <span style="font-weight:bold">{{mainledger.BusinessYear}}</span>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                {{lang.User}}
              </v-col>
              <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8"  class="py-0 px-2">
                  <span style="font-weight:bold">{{mainledger.First}} {{mainledger.Last}}, {{mainledger.email}}, {{mainledger.UserId}}</span>
              </v-col>
            </v-row>

            <v-divider class="mt-1"></v-divider>

          <v-row no-gutters class="mt-3">
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pa-0">

              </v-col>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pa-0" v-if="!disStatus">
                {{lang.fromDate}}:<input type="date" v-model="mainledger.fromDate" />
             </v-col>
             <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pa-0" v-if="disStatus">
              {{lang.fromDate}}:{{localDate(mainledger.fromDate)}}
             </v-col>

             <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pa-0" v-if="!disStatus">
                {{lang.toDate}}:<input type="date" v-model="mainledger.toDate" />
             </v-col>
             <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pa-0" v-if="disStatus">
              {{lang.fromDate}}:{{localDate(mainledger.toDate)}}
             </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pa-0">
                <v-text-field
                      :label="lang.Branch"
                      v-model="mainledger.Branch"
                      :disabled = "disStatus"
                    ></v-text-field>
             </v-col>

           </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2">
                   {{lang.Podatak000d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-0">
                <v-text-field
                      dense
                      :label="lang.Podatak000"
                      v-model.number="mainledger.Podatak000"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-2 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak100d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak100"
                      v-model.number="mainledger.Podatak100"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-2 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-6">
                   {{lang.Podatak101d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak101"
                      v-model="mainledger.Podatak101"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-2 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-6">
                   {{lang.Podatak102d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak102"
                      v-model.number="mainledger.Podatak102"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-2 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

             <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-6">
                   {{lang.Podatak103d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak103"
                      v-model.number="mainledger.Podatak103"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-2 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

             <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-6">
                   {{lang.Podatak104d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak104"
                      v-model.number="mainledger.Podatak104"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-2 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

             <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-6">
                   {{lang.Podatak105d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak105"
                      v-model.number="mainledger.Podatak105"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-2 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

             <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-6">
                   {{lang.Podatak106d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak106"
                      v-model.number="mainledger.Podatak106"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-2 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

             <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-6">
                   {{lang.Podatak107d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak107"
                      v-model.number="mainledger.Podatak107"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-2 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

             <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-6">
                   {{lang.Podatak108d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak108"
                      v-model.number="mainledger.Podatak108"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-2 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

             <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-6">
                   {{lang.Podatak109d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak109"
                      v-model.number="mainledger.Podatak109"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-2 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

             <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-6">
                   {{lang.Podatak110d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak110"
                      v-model.number="mainledger.Podatak110"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-2 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-6">
                   {{lang.Podatak110d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak110"
                      v-model.number="mainledger.Podatak110"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-2 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

             <v-row no-gutters v-if="bussYear >= 2023">
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-6">
                   {{lang.Podatak111d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak111"
                      v-model.number="mainledger.Podatak111"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-2 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" >
                   <v-divider class="mt-1"></v-divider>
              </v-col>
          </v-row>


            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2">
                   {{lang.Podatak200d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-0">
                <v-text-field
                      dense
                      :label="lang.Podatak200"
                      v-model.number="mainledger.Podatak200"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez200"
                      v-model.number="mainledger.Porez200"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak201d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak201"
                      v-model.number="mainledger.Podatak201"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez201"
                      v-model.number="mainledger.Porez201"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak202d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak202"
                      v-model.number="mainledger.Podatak202"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez202"
                      v-model.number="mainledger.Porez202"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak203d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak203"
                      v-model.number="mainledger.Podatak203"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez203"
                      v-model.number="mainledger.Porez203"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak204d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak204"
                      v-model.number="mainledger.Podatak204"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez204"
                      v-model.number="mainledger.Porez204"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak205d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak205"
                      v-model.number="mainledger.Podatak205"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez205"
                      v-model.number="mainledger.Porez205"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak206d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak206"
                      v-model.number="mainledger.Podatak206"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez206"
                      v-model.number="mainledger.Porez206"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak207d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak207"
                      v-model.number="mainledger.Podatak207"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez207"
                      v-model.number="mainledger.Porez207"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak208d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak208"
                      v-model.number="mainledger.Podatak208"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez208"
                      v-model.number="mainledger.Porez208"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak209d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak209"
                      v-model.number="mainledger.Podatak209"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez209"
                      v-model.number="mainledger.Porez209"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak210d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak210"
                      v-model.number="mainledger.Podatak210"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez210"
                      v-model.number="mainledger.Porez210"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak211d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak211"
                      v-model.number="mainledger.Podatak211"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez211"
                      v-model.number="mainledger.Porez211"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak212d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak212"
                      v-model.number="mainledger.Podatak212"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez212"
                      v-model.number="mainledger.Porez212"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak213d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak213"
                      v-model.number="mainledger.Podatak213"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez213"
                      v-model.number="mainledger.Porez213"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak214d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak214"
                      v-model.number="mainledger.Podatak214"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez214"
                      v-model.number="mainledger.Porez214"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak215d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak215"
                      v-model.number="mainledger.Podatak215"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez215"
                      v-model.number="mainledger.Porez215"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

            </v-row>


          <v-row>
            <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" >
                   <v-divider class="mt-1"></v-divider>
              </v-col>
          </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2">
                   {{lang.Podatak300d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-0">
                <v-text-field
                      dense
                      :label="lang.Podatak300"
                      v-model.number="mainledger.Podatak300"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez300"
                      v-model.number="mainledger.Porez300"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak301d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak301"
                      v-model.number="mainledger.Podatak301"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez301"
                      v-model.number="mainledger.Porez301"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak302d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak302"
                      v-model.number="mainledger.Podatak302"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez3012"
                      v-model.number="mainledger.Porez302"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak303d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak303"
                      v-model.number="mainledger.Podatak303"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez303"
                      v-model.number="mainledger.Porez303"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak304d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak304"
                      v-model.number="mainledger.Podatak304"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez304"
                      v-model.number="mainledger.Porez304"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak305d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak305"
                      v-model.number="mainledger.Podatak305"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez305"
                      v-model.number="mainledger.Porez305"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak306d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak306"
                      v-model.number="mainledger.Podatak306"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez306"
                      v-model.number="mainledger.Porez306"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak307d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak307"
                      v-model.number="mainledger.Podatak307"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez307"
                      v-model.number="mainledger.Porez307"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak308d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak308"
                      v-model.number="mainledger.Podatak308"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez308"
                      v-model.number="mainledger.Porez308"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak309d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak309"
                      v-model.number="mainledger.Podatak309"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez309"
                      v-model.number="mainledger.Porez309"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak310d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak310"
                      v-model.number="mainledger.Podatak310"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez310"
                      v-model.number="mainledger.Porez310"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak311d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak311"
                      v-model.number="mainledger.Podatak311"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez311"
                      v-model.number="mainledger.Porez311"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak312d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak312"
                      v-model.number="mainledger.Podatak312"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez312"
                      v-model.number="mainledger.Porez312"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak313d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak313"
                      v-model.number="mainledger.Podatak313"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez313"
                      v-model.number="mainledger.Porez313"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak314d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak314"
                      v-model.number="mainledger.Podatak314"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Porez314"
                      v-model.number="mainledger.Porez314"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak315d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                    XXXXXXX
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Podatak315"
                      v-model.number="mainledger.Podatak315"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" >
                   <v-divider class="mt-1"></v-divider>
              </v-col>
           </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak400d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                    XXXXXXX
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Podatak400"
                      v-model.number="mainledger.Podatak400"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

          <div v-if="bussYear >= 2023">

            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak700d}}
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Podatak500"
                      v-model.number="mainledger.Podatak500"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   VI. OSTALI PODACI
              </v-col>
            </v-row>

           <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   1. ZA ISPRAVAK PRETPOREZA (UKUPNO 1.1.+1.2.+1.3.+1.4.+1.5.)
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3" class="py-0 pr-2">
                  <v-text-field
                    dense
                    label=""
                    v-model="mainledger.Podatak610"
                    disabled
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" xs="0" sm="0" md="1" lg="1" xl="1" class="py-0 pr-2 pl-3">
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   1.1. NABAVA NEKRETNINA
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3" class="py-0 pr-2">
                  <v-text-field
                    dense
                    label=""
                    v-model="mainledger.Podatak611"
                    :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" xs="0" sm="0" md="1" lg="1" xl="1" class="py-0 pr-2 pl-3">
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   1.2. NABAVA OSOBNIH AUTOMOBILA I DRUGIH SREDSTAVA ZA OSOBNI PRIJEVOZ
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3" class="py-0 pr-2">
                  <v-text-field
                    dense
                    label=""
                    v-model="mainledger.Podatak612"
                    :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" xs="0" sm="0" md="1" lg="1" xl="1" class="py-0 pr-2 pl-3">
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   1.3. PRODAJA OSOBNIH AUTOMOBILA I DRUGIH SREDSTAVA ZA OSOBNI PRIJEVOZ
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3" class="py-0 pr-2">
                  <v-text-field
                    dense
                    label=""
                    v-model="mainledger.Podatak613"
                    :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" xs="0" sm="0" md="1" lg="1" xl="1" class="py-0 pr-2 pl-3">
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   1.4. NABAVA OSTALE DUGOTRAJNE IMOVINE
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3" class="py-0 pr-2">
                  <v-text-field
                    dense
                    label=""
                    v-model="mainledger.Podatak614"
                    :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" xs="0" sm="0" md="1" lg="1" xl="1" class="py-0 pr-2 pl-3">
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   1.5. PRODAJA OSTALE DUGOTRAJNE IMOVINE
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3" class="py-0 pr-2">
                  <v-text-field
                    dense
                    label=""
                    v-model="mainledger.Podatak615"
                    :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   2. OTUĐENJE/STJECANJE GOSPODARSKE CJELINE ILI POGONA
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3" class="py-0 pr-2">
                  <v-text-field
                    dense
                    label=""
                    v-model="mainledger.Podatak620"
                    :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   3. UKUPNO PRIMLJENE USLUGE OD POREZNIH OBVEZNIKA BEZ SJEDIŠTA U RH (EU + TREĆE ZEMLJE)
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3" class="py-0 pr-2">
                  <v-text-field
                    dense
                    label=""
                    v-model="mainledger.Podatak630"
                    :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   4. UKUPNO OBAVLJENE USLUGE POREZNIM OBVEZNICIMABEZ SJEDIŠTA U RH (EU + TREĆE ZEMLJE)
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3" class="py-0 pr-2">
                  <v-text-field
                    dense
                    label=""
                    v-model="mainledger.Podatak640"
                    :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   5. PRIMLJENA DOBRA IZ EU U OKVIRU TROSTRANOG POSLA
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3" class="py-0 pr-2">
                  <v-text-field
                    dense
                    label=""
                    v-model="mainledger.Podatak650"
                    :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   6. POSTUPAK OPOREZIVANJA PREMA NAPLAĆENIM NAKNADAMA
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3" class="py-0 pr-2">
                <v-checkbox
                    label="PREMA NAPLAĆENIM NAKNADAMA"
                    v-model="mainledger.Podatak660"
                  ></v-checkbox>
              </v-col>
            </v-row>



         </div>

        <div v-if="bussYear <=2022">
            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak500d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                    XXXXXXX
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Podatak500"
                      v-model.number="mainledger.Podatak500"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak600d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                    <v-text-field
                      dense
                      label=""
                      v-model="mainledger.MLFree1"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Podatak600"
                      v-model.number="mainledger.Podatak600"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

             <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak700d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                    XXXXXXX
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Podatak700"
                      v-model.number="mainledger.Podatak700"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

           <v-row>
              <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" >
                <v-divider class="mt-1"></v-divider>
              </v-col>
           </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   VIII OSTALI PODACI
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
              </v-col>
            </v-row>


            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak810d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak810"
                      v-model.number="mainledger.Podatak810"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-6">
                   {{lang.Podatak811d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak811"
                      v-model.number="mainledger.Podatak811"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-6">
                   {{lang.Podatak812d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak812"
                      v-model.number="mainledger.Podatak812"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-6">
                   {{lang.Podatak813d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak813"
                      v-model.number="mainledger.Podatak813"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-6">
                   {{lang.Podatak814d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak814"
                      v-model.number="mainledger.Podatak814"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-6">
                   {{lang.Podatak815d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak815"
                      v-model.number="mainledger.Podatak815"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  XXXXXXX
              </v-col>
            </v-row>


          <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak820d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak820"
                      v-model.number="mainledger.Podatak820"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak830d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak830"
                      v-model.number="mainledger.Podatak830"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

             <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-6">
                   {{lang.Podatak831d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak831"
                      v-model.number="mainledger.Podatak831"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Broj831"
                      v-model.number="mainledger.Broj831"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-6">
                   {{lang.Podatak832d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak832"
                      v-model.number="mainledger.Podatak832"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Broj832"
                      v-model.number="mainledger.Broj832"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-6">
                   {{lang.Podatak833d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak833"
                      v-model.number="mainledger.Podatak833"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Broj833"
                      v-model.number="mainledger.Broj833"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>


            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak840d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak840"
                      v-model.number="mainledger.Podatak840"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak850d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak850"
                      v-model.number="mainledger.Podatak850"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak860d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.Podatak860"
                      v-model.number="mainledger.Podatak860"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  XXXXXXX
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 pr-2 pl-3">
                   {{lang.Podatak870d}}
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-checkbox
                  :label="lang.Podatak870"
                  v-model="mainledger.Podatak870"
                  :disabled = "disStatus"
                ></v-checkbox>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">

              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      :label="lang.Povrat2"
                      v-model.number="mainledger.Povrat2"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2 pl-3">
                <v-text-field
                      dense
                      :label="lang.Predujam2"
                      v-model.number="mainledger.Predujam2"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      :label="lang.UstupPovrata2"
                      v-model.number="mainledger.UstupPovrata2"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>
            </div>
            <div v-if="bussYear >= 2023">
              <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-text-field
                      dense
                      label="Povrat EURa"
                      v-model.number="mainledger.Povrat2"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2 pl-3">
                <v-text-field
                      dense
                      label="Predujam EUR"
                      v-model.number="mainledger.Predujam2"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 pr-2">
                <v-text-field
                      dense
                      label="Ustup Povrata EUR"
                      v-model.number="mainledger.UstupPovrata2"
                      type="number"
                      :disabled = "disStatus"
                  ></v-text-field>
              </v-col>
            </v-row>
            </div>


          </v-form>
          <div class="pink" v-html="error" />
          <div>
            <span v-if = "!disStatus">
              <v-btn
                class="primary mr-2"
                :disabled="!formIsValid"
                @click="register" >
                {{langC.Save}}
              </v-btn>
            </span>
            <span v-if="this.$store.state.user.AccessLevel <= 3">
                <v-btn color="primary" @click="mainledgerEnableEdit" class="mr-2">
                  {{langC.Edit}}
                </v-btn>
            </span>
             <span v-if="this.$store.state.user.AccessLevel == 1">
                <v-btn color="red" @click="mainledgerDelete" class="mr-2">
                  {{langC.Delete}}
                </v-btn>
            </span>
            <v-btn
              color="blue"
              class="mr-2"
              @click="print">
                {{langC.Print}}
            </v-btn>
            <v-btn
              color="green"
              @click="mainledgerlist">
                {{langC.Back}}
            </v-btn>
          </div>
      </v-col>
    </v-row>
    <MainLedgerPrintVAT ref="prnt" />
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>
  </v-container>
</template>

<script>

import MainLedgerService from '@/services/MainLedgerService'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import { defNew, defMainQuery, defMainLedgerVAT, defMainLedgerVATFill } from './mainledgerHelper'
import MainLedgerPrintVAT from './MainLedgerPrintVAT'
import FileSaver from 'file-saver'
import { v4 as uuidv4 } from 'uuid'
import dateFormat from '@/snippets/dateFormat'
import {parseNum} from '@/snippets/allSnippets'

// import dateFormat from '@/snippets/dateFormat'

export default {
  name: 'mainledgercreatevat',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      lang: {},
      langC: {},
      valid: true,
      lazy: false,
      disStatus: false,
      rules: {
        required: value => !!value || '*'
      },
      menu: false,
      menu2: false,
      menu3: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      date1: null,
      date2: null,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      msg: '',
      documentName: '',
      documentNameLoc: '',
      mainledger: { },
      ml:{},
      error: null,
      localError: false,
      newDocument: {},
      confpassword: '',
      size: 'sm',
      required: (value) => !!value || 'Required.',
      partners: [],
      employes: [],
      banks: [],
      currnecy: [],
      users: [],
      CompanyId: 0,
      selectPa: {},
      selectBank: {},
      selectCR: {},
      selectUs: {},
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
      },
      currnecyType: [
        { text: 'EUR', value: 'EUR' },
        { text: 'Kn', value: 'Kn' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      money: {
        prefix: '= ',
        suffix: ' ',
        precision: 3
      },
      price: 7.500,
      localUser: '',
      bookType: [],
      bookTypeSelected: {
      },
      vat: {},
      bussYear: 2022
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    if (this.$store.state.companyid) {
      this.CompanyId = this.$store.state.companyid
    }
    this.bussYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2022

    if (this.$store.state.editItem === 0) {
      this.mainledger = defMainLedgerVAT()
      this.mainledger = {...this.mainledger, ...defNew()}
      this.mainledger.CompanyVATID = this.$store.state.companyActiv.CompanyVATID ? this.$store.state.companyActiv.CompanyVATID : ''
      this.mainledger.MLFree3 = this.$store.state.companyActiv.CompanyName ? this.$store.state.companyActiv.CompanyName : ''
      this.mainledger.CompanyPlace = (this.$store.state.companyActiv.CompanyPostcode ? this.$store.state.companyActiv.CompanyPostcode : '') + ' ' +this.$store.state.companyActiv.CompanyCity ? this.$store.state.companyActiv.CompanyCity : ''
      this.mainledger.CompanyStreet = this.$store.state.companyActiv.CompanyStreet ? this.$store.state.companyActiv.CompanyStreet : ''
      this.mainledger.CompanyStreetNumber = this.$store.state.companyActiv.CompanyBuildingNameNumber ? this.$store.state.companyActiv.CompanyBuildingNameNumber : ''
      this.mainledger.MLFree2 = uuidv4()
      this.mainQuery = {...defMainQuery()}
      this.localUser = this.mainledger.First + ' ' + this.mainledger.Last + ', ' + this.mainledger.email
      this.disStatus = false
    }
    if (this.$store.state.editItem === 1) {
      const locDocument = await MainLedgerService.showvat(this.$store.state.mainledgerid)
      this.mainledger = locDocument.data.mainLedger
      this.disStatus = true
    }
  },
  computed: {
    formIsValid () {
      return this.mainledger.email !== '' &&
        this.mainledger.First !== '' &&
        this.mainledger.Last !== '' &&
        this.mainledger.InvoiceDate !== '' &&
        this.mainledger.BookingDate !== ''
    }
  },
  methods: {
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    fillForm() {
      if (confirm('Da li ste sigurni da želite ispuniti PDV obrazac (postojeći podaci će se obrisati) ?!')) {
        this.fillForm2()
      }
    },
    async fillForm2() {
      const newObj = {}
      this.mainQuery = {...newObj}

      this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
      this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2022
      this.mainQuery.docFromDate = this.mainledger.fromDate
      this.mainQuery.docToDate = this.mainledger.toDate
      this.mainQuery.docToDate = this.mainledger.toDate
      this.mainQuery.PayedVAT = false
      console.log('this.mainQuery', this.mainQuery)
      this.ml = defMainLedgerVATFill()
      this.ml.Podatak111 = 0
      const res1 = await MainLedgerService.books(this.mainQuery)
      this.mainQuery.PayedVAT = true
      const res2 = await MainLedgerService.books(this.mainQuery)
      for (let i = 1; i < 3; i++) {
        const data = i === 1 ? res1.data : res2.data
        if (data && data.documents) {
          const onlyInv = data.documents.filter(docs => docs.BookTypeID < 21)
          onlyInv.map(doc => {
            const BookTypeID = parseInt(doc.BookTypeID)
            const BookSubTypeID = parseInt(doc.BookSubTypeID)
            const VAT1Per = parseFloat(doc.VAT1Per)
            const VAT2Per = parseFloat(doc.VAT2Per)
            const VAT3Per = parseFloat(doc.VAT3Per)
            const VAT1CanUse = this.round(parseFloat(doc.VAT1CanUse),2)
            const VAT2CanUse = this.round(parseFloat(doc.VAT2CanUse),2)
            const VAT3CanUse = this.round(parseFloat(doc.VAT3CanUse),2)
            const InvoiceAmount = this.round(parseFloat(doc.InvoiceAmount),2)
            const Goods25 = this.round(parseFloat(doc.Goods25),2)
            const Goods13 = this.round(parseFloat(doc.Goods13),2)
            const Goods5 = this.round(parseFloat(doc.Goods5),2)
            const Services25 = this.round(parseFloat(doc.Services25),2)
            const Services13 = this.round(parseFloat(doc.Services13),2)
            const Services5 = this.round(parseFloat(doc.Services5),2)
            console.log('doc.VATZero', doc.VATZero, typeof doc.VATZero, parseNum(doc.VATZero), typeof parseNum(doc.VATZero))
            console.log('1 this.ml.Podatak111', this.ml.Podatak111, typeof this.ml.Podatak111)
            this.ml.Podatak111 += doc.VATZero ? parseNum(doc.VATZero) : 0
            console.log('2 this.ml.Podatak111', this.ml.Podatak111, typeof this.ml.Podatak111)


            if (BookTypeID === 10) {
              if (BookSubTypeID === 1) {
                this.ml.Podatak101 += InvoiceAmount
              } else {
                // ira domaca
                if (VAT3Per === 5) {
                  this.ml.Porez201 += VAT3CanUse
                }
                if (VAT2Per === 13) {
                  this.ml.Porez202 += VAT2CanUse
                }
                if (VAT1Per === 25) {
                  this.ml.Porez203 += VAT1CanUse
                }
              }
            }
            if (BookTypeID === 11 || BookTypeID === 12 || BookTypeID === 13) {
              // ira bez pdva
              if (BookSubTypeID === 1) {
                this.ml.Podatak101 += InvoiceAmount
              }
              if (BookSubTypeID === 2) {
                this.ml.Podatak102 += InvoiceAmount
              }
              if (BookSubTypeID === 3) {
                this.ml.Podatak103 += InvoiceAmount
              }
              if (BookSubTypeID === 4) {
                this.ml.Podatak104 += InvoiceAmount
              }
              if (BookSubTypeID === 5) {
                this.ml.Podatak105 += InvoiceAmount
              }
              if (BookSubTypeID === 6) {
                this.ml.Podatak106 += InvoiceAmount
              }
              if (BookSubTypeID === 7) {
                this.ml.Podatak107 += InvoiceAmount
              }
              if (BookSubTypeID === 8) {
                this.ml.Podatak108 += InvoiceAmount
              }
              if (BookSubTypeID === 9) {
                this.ml.Podatak109 += InvoiceAmount
              }
              if (BookSubTypeID === 10) {
                this.ml.Podatak110 += InvoiceAmount
              }
            }
            if (BookTypeID === 1) {
              // ura domaca
              if (VAT3Per === 5) {
                this.ml.Porez301 += VAT3CanUse
              }
              if (VAT2Per === 13) {
                this.ml.Porez302 += VAT2CanUse
              }
              if (VAT1Per === 25) {
                this.ml.Porez303 += VAT1CanUse
              }
              if (this.round(parseFloat(doc.FreeF2),2) !== 0) {
                this.ml.Porez204 += this.round(parseFloat(doc.FreeF2),2)
                this.ml.Porez304 += this.round(parseFloat(doc.FreeF2),2)
              }

            }
            if (BookTypeID === 2) {
              // ura ppo
              this.ml.Porez304 += VAT1CanUse
              this.ml.Porez204 += VAT1CanUse
            }
            if (BookTypeID === 3 || BookTypeID === 4) {
              // ura URA_SD_EU
              if (VAT3Per === 5) {
                this.ml.Porez305 += Goods5
                this.ml.Porez205 += Goods5
              }
              if (VAT2Per === 13) {
                this.ml.Porez306 += Goods13
                this.ml.Porez206 += Goods13
              }
              if (VAT1Per === 25) {
                this.ml.Porez307 += Goods25
                this.ml.Porez207 += Goods25
              }
              // ura URA_SU_EU
              if (VAT3Per === 5) {
                this.ml.Porez308 += Services5
                this.ml.Porez208 += Services5
              }
              if (VAT2Per === 13) {
                this.ml.Porez309 += Services13
                this.ml.Porez209 += Services13
              }
              if (VAT1Per === 25) {
                this.ml.Porez310 += Services25
                this.ml.Porez210 += Services25
              }
            }
            if (BookTypeID === 5) {
              // ura URA_SDU_NORH
              if (VAT3Per === 5) {
                this.ml.Porez311 += VAT3CanUse
                this.ml.Porez211 += VAT3CanUse
              }
              if (VAT2Per === 13) {
                this.ml.Porez312 += VAT2CanUse
                this.ml.Porez212 += VAT2CanUse
              }
              if (VAT1Per === 25) {
                this.ml.Porez313 += VAT1CanUse
                this.ml.Porez213 += VAT1CanUse
              }
            }
            if (BookTypeID === 6) {
              // ura URA_PDV_UVOZ
              this.ml.Porez314 += VAT1CanUse
              this.ml.Porez215 += VAT1CanUse
            }

          })

          // check if there is retail data saved for VAT form - it can be more then one, if there is more retail spaces
          this.mainQuery.BookTypeID = 28
          const dataRET = await MainLedgerService.books(this.mainQuery)
          if (dataRET && dataRET.data && dataRET.data.documents) {
            dataRET.data.documents.map(doc => {
              const BookTypeID = parseInt(doc.BookTypeID)
              const BookSubTypeID = parseInt(doc.BookSubTypeID)
              const VAT1Per = doc.VAT1Per ? parseFloat(doc.VAT1Per) : 0
              const VAT2Per = doc.VAT2Per ? parseFloat(doc.VAT2Per) : 0
              const VAT3Per = doc.VAT3Per ? parseFloat(doc.VAT3Per) : 0
              const VAT1CanUse = doc.VAT1CanUse ? this.round(parseFloat(doc.VAT1CanUse),2) : 0
              const VAT2CanUse = doc.VAT2CanUse ? this.round(parseFloat(doc.VAT2CanUse),2) : 0
              const VAT3CanUse = doc.VAT3CanUse ? this.round(parseFloat(doc.VAT3CanUse),2) : 0
              const InvoiceAmount = doc.InvoiceAmount ? this.round(parseFloat(doc.InvoiceAmount),2) : 0

              // const Goods25 = this.round(parseFloat(doc.Goods25),2)
              // const Goods13 = this.round(parseFloat(doc.Goods13),2)
              // const Goods5 = this.round(parseFloat(doc.Goods5),2)
              // const Services25 = this.round(parseFloat(doc.Services25),2)
              // const Services13 = this.round(parseFloat(doc.Services13),2)
              // const Services5 = this.round(parseFloat(doc.Services5),2)

              if (BookTypeID === 28) {
                // maloprodaja
                if (VAT3Per === 5) {
                  this.ml.Porez201 += VAT3CanUse
                }
                if (VAT2Per === 13) {
                  this.ml.Porez202 += VAT2CanUse
                }
                if (VAT1Per === 25) {
                  this.ml.Porez203 += VAT1CanUse
                }
              }
              if (BookTypeID === 11 || BookTypeID === 12 || BookTypeID === 13) {
                // ira bez pdva
                if (BookSubTypeID === 1) {
                  this.ml.Podatak101 += InvoiceAmount
                }
                if (BookSubTypeID === 2) {
                  this.ml.Podatak102 += InvoiceAmount
                }
                if (BookSubTypeID === 3) {
                  this.ml.Podatak103 += InvoiceAmount
                }
                if (BookSubTypeID === 4) {
                  this.ml.Podatak104 += InvoiceAmount
                }
                if (BookSubTypeID === 5) {
                  this.ml.Podatak105 += InvoiceAmount
                }
                if (BookSubTypeID === 6) {
                  this.ml.Podatak106 += InvoiceAmount
                }
                if (BookSubTypeID === 7) {
                  this.ml.Podatak107 += InvoiceAmount
                }
                if (BookSubTypeID === 8) {
                  this.ml.Podatak108 += InvoiceAmount
                }
                if (BookSubTypeID === 9) {
                  this.ml.Podatak109 += InvoiceAmount
                }
                if (BookSubTypeID === 10) {
                  this.ml.Podatak110 += InvoiceAmount
                }
              }

            })
          }

          this.ml.Porez201 = this.round(this.ml.Porez201, 2)
          this.ml.Porez202 = this.round(this.ml.Porez202, 2)
          this.ml.Porez203 = this.round(this.ml.Porez203, 2)
          this.ml.Porez205 = this.round(this.ml.Porez205, 2)
          this.ml.Porez206 = this.round(this.ml.Porez206, 2)
          this.ml.Porez207 = this.round(this.ml.Porez207, 2)
          this.ml.Porez208 = this.round(this.ml.Porez208, 2)
          this.ml.Porez209 = this.round(this.ml.Porez209, 2)
          this.ml.Porez210 = this.round(this.ml.Porez210, 2)
          this.ml.Porez211 = this.round(this.ml.Porez211, 2)
          this.ml.Porez212 = this.round(this.ml.Porez212, 2)
          this.ml.Porez213 = this.round(this.ml.Porez213, 2)
          this.ml.Porez214 = this.round(this.ml.Porez214, 2)
          this.ml.Porez215 = this.round(this.ml.Porez215, 2)

          this.ml.Porez200 = this.ml.Porez201 + this.ml.Porez202 + this.ml.Porez203 + this.ml.Porez204 + this.ml.Porez205 + this.ml.Porez206 + this.ml.Porez207 + this.ml.Porez208 + this.ml.Porez209 + this.ml.Porez210 + this.ml.Porez211 + this.ml.Porez212  + this.ml.Porez213 + this.ml.Porez214 + this.ml.Porez215

          this.ml.Porez301 = this.round(this.ml.Porez301, 2)
          this.ml.Porez302 = this.round(this.ml.Porez302, 2)
          this.ml.Porez303 = this.round(this.ml.Porez303, 2)
          this.ml.Porez305 = this.round(this.ml.Porez305, 2)
          this.ml.Porez306 = this.round(this.ml.Porez306, 2)
          this.ml.Porez307 = this.round(this.ml.Porez307, 2)
          this.ml.Porez308 = this.round(this.ml.Porez308, 2)
          this.ml.Porez309 = this.round(this.ml.Porez309, 2)
          this.ml.Porez310 = this.round(this.ml.Porez310, 2)
          this.ml.Porez311 = this.round(this.ml.Porez311, 2)
          this.ml.Porez312 = this.round(this.ml.Porez312, 2)
          this.ml.Porez313 = this.round(this.ml.Porez313, 2)
          this.ml.Porez300 = this.ml.Porez301 + this.ml.Porez302 + this.ml.Porez303 + this.ml.Porez304 + this.ml.Porez305 + this.ml.Porez306 + this.ml.Porez307 + this.ml.Porez308 + this.ml.Porez309 + this.ml.Porez310 + this.ml.Porez311 + this.ml.Porez312 + this.ml.Porez313 + this.ml.Porez314 + this.ml.Porez315

          this.ml.Podatak201 = this.round((this.ml.Porez201 / 0.5),2)
          this.ml.Podatak202 = this.round((this.ml.Porez202 / 0.13),2)
          this.ml.Podatak203 = this.round((this.ml.Porez203 / 0.25),2)

          this.ml.Podatak204 = this.round((this.ml.Porez204 / 0.25),2)

          this.ml.Podatak205 = this.round((this.ml.Porez205 / 0.5),2)
          this.ml.Podatak206 = this.round((this.ml.Porez206 / 0.13),2)
          this.ml.Podatak207 = this.round((this.ml.Porez207 / 0.25),2)

          this.ml.Podatak208 = this.round((this.ml.Porez208 / 0.5),2)
          this.ml.Podatak209 = this.round((this.ml.Porez209 / 0.13),2)
          this.ml.Podatak210 = this.round((this.ml.Porez210 / 0.25),2)

          this.ml.Podatak211 = this.round((this.ml.Porez211 / 0.5),2)
          this.ml.Podatak212 = this.round((this.ml.Porez212 / 0.13),2)
          this.ml.Podatak213 = this.round((this.ml.Porez213 / 0.25),2)

          this.ml.Podatak200 = this.ml.Podatak201 + this.ml.Podatak202 + this.ml.Podatak203 + this.ml.Podatak204 + this.ml.Podatak205 + this.ml.Podatak206 + this.ml.Podatak207 + this.ml.Podatak208 + this.ml.Podatak209 + this.ml.Podatak210 + this.ml.Podatak211 + this.ml.Podatak212  + this.ml.Podatak213 + this.ml.Podatak214 + this.ml.Podatak215

          this.ml.Podatak301 = this.round((this.ml.Porez301 / 0.5),2)
          this.ml.Podatak302 = this.round((this.ml.Porez302 / 0.13),2)
          this.ml.Podatak303 = this.round((this.ml.Porez303 / 0.25),2)

          this.ml.Podatak304 = this.round((this.ml.Porez304 / 0.25),2)

          this.ml.Podatak305 = this.round((this.ml.Porez305 / 0.5),2)
          this.ml.Podatak306 = this.round((this.ml.Porez306 / 0.13),2)
          this.ml.Podatak307 = this.round((this.ml.Porez307 / 0.25),2)

          this.ml.Podatak308 = this.round((this.ml.Porez308 / 0.5),2)
          this.ml.Podatak309 = this.round((this.ml.Porez309 / 0.13),2)
          this.ml.Podatak310 = this.round((this.ml.Porez310 / 0.25),2)

          this.ml.Podatak311 = this.round((this.ml.Porez311 / 0.5),2)
          this.ml.Podatak312 = this.round((this.ml.Porez312 / 0.13),2)
          this.ml.Podatak313 = this.round((this.ml.Porez313 / 0.25),2)
          this.ml.Podatak300 = this.ml.Podatak301 + this.ml.Podatak302 + this.ml.Podatak303 + this.ml.Podatak304 + this.ml.Podatak305 + this.ml.Podatak306 + this.ml.Podatak307 + this.ml.Podatak308 + this.ml.Podatak309 + this.ml.Podatak310 + this.ml.Podatak311 + this.ml.Podatak312 + this.ml.Podatak313 + this.ml.Podatak314

          this.ml.Podatak100 = this.ml.Podatak101 + this.ml.Podatak102 + this.ml.Podatak103 + this.ml.Podatak104 + this.ml.Podatak105 + this.ml.Podatak106 + this.ml.Podatak107 + this.ml.Podatak108 + this.ml.Podatak109 + this.ml.Podatak110 + this.ml.Podatak111

          this.ml.Podatak000 = this.ml.Podatak100 + this.ml.Podatak200

          this.ml.MLFree1 = ''
          this.ml.Podatak400 = 0
          this.ml.Podatak610 = this.round(this.ml.Podatak611,2)
          this.ml.Podatak600 = 0
          this.ml.Podatak400 = this.ml.Porez200 - this.ml.Porez300
          this.ml.Podatak600 = this.ml.Porez200 - this.ml.Porez300
          if (this.ml.Porez300 > this.ml.Porez200) {
            this.ml.MLFree1 = 'ZA POVRAT'
          }
          if (this.ml.Porez300 < this.ml.Porez200) {
            this.ml.MLFree1 = 'ZA UPLATU'
          }

          const updateML = {...this.mainledger}
          this.mainledger = {...updateML, ...this.ml}

        }
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    mainledgerEnableEdit(){
      this.disStatus = false
      this.text = 'Edit enabled'
      this.color = 'green'
      this.snackbar = true
      this.timeout = 2000
    },
    async mainledgerDelete() {
      try {
          const warningMessage = ''
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!')) {
             await MainLedgerService.deletevat(this.$store.state.mainledgerid).then(res => {
              this.er2 = res.data
              this.mainledgerlist()
            }).catch((error) => {
              this.text = error.response.data.message ? error.response.data.message : ''
              this.text = error.response.data.error ? this.text + ', ' + error.response.data.error : this.error
              this.color = 'red'
              this.snackbar = true
              this.timeout = 4000
            })
          }
        } catch(err) {
          this.error = err
          this.text = err.response.data.message ? err.response.data.message : ''
          this.text = err.response.data.error ? this.text + ', ' + err.response.data.error : this.error
          this.color = 'red'
          this.snackbar = true
          this.timeout = 4000
        }

    },
    async register () {
      try {
         this.localError = false
         const newObj = {}
          this.mainLedger = {...newObj}

        if (this.$store.state.editItem === 0 && this.mainledger.BookID !== 0) {
          this.text = 'Greska! Dokument je vec snimljen!! '
          this.color = 'red'
          this.snackbar = true
        }
        if (this.$store.state.editItem === 0 && this.mainledger.BookID === 0) {
          const newObj = {}
          this.mainQuery = {...newObj}
          this.mainQuery.CompanyId  = this.$store.state.companyid ? this.$store.state.companyid : 1
          this.mainQuery.BusinessYear  = this.$store.state.businessYear ? this.$store.state.businessYear : 1
          this.mainQuery.MLType  = this.$store.state.documentSide ? this.$store.state.documentSide : 1
          this.mainLedger.MLType = this.$store.state.documentSide ? this.$store.state.documentSide : 1
          this.mainledger.BookID = (await MainLedgerService.choosevat(this.mainQuery)).data.docNumber
          this.mainledger.documentIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-' + this.$store.state.documentActiv + '-' + this.mainledger.BookID
          this.mainledger.BookIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-' + this.$store.state.documentActiv + '-' + this.mainledger.BookID
          this.mainledger.MLFree2 = uuidv4()
          await MainLedgerService.postvat(this.mainledger)
           if (this.$store.state.language === 'EN') {
            this.text = 'VAT form has been saved.'
            this.color = 'green'
            this.snackbar = true
          }
          if (this.$store.state.language === 'HR') {
            this.text = 'PDV obrazac je snimljen u bazu.'
            this.color = 'green'
            this.snackbar = true
          }
        }
        if (this.$store.state.editItem === 1) {
          this.mainledger.CompanyVATID = this.$store.state.companyActiv.CompanyVATID ? this.$store.state.companyActiv.CompanyVATID : ''
          this.mainledger.MLFree3 = this.$store.state.companyActiv.CompanyName ? this.$store.state.companyActiv.CompanyName : ''
          this.mainledger.CompanyPlace = (this.$store.state.companyActiv.CompanyPostcode ? this.$store.state.companyActiv.CompanyPostcode : '') + ' ' +this.$store.state.companyActiv.CompanyCity ? this.$store.state.companyActiv.CompanyCity : ''
          this.mainledger.CompanyStreet = this.$store.state.companyActiv.CompanyStreet ? this.$store.state.companyActiv.CompanyStreet : ''
          this.mainledger.CompanyStreetNumber = this.$store.state.companyActiv.CompanyBuildingNameNumber ? this.$store.state.companyActiv.CompanyBuildingNameNumber : ''
          this.mainledger.MLFree2 = uuidv4()
          await MainLedgerService.putvat(this.mainledger)
            if (this.$store.state.language === 'EN') {
            this.text = ' VAT form has been updated.'
            this.color = 'green'
            this.snackbar = true
          }
          if (this.$store.state.language === 'HR') {
            this.text = 'PDV obrazac je snimljen.'
            this.color = 'green'
            this.snackbar = true
          }
        }

      } catch (error) {
        this.error = error.data
        this.text = 'Error! Cannot save document to database. ' + error.data
        this.color = 'red'
        this.snackbar = true
      }
    },
    close () {
      this.show = false
    },
    print(){
      this.vat = this.mainledger ? this.mainledger : {}
      this.$refs.prnt.print(this.vat)
    },
    calculateVATAmount () {
      this.mainledger.AmountVAT = this.round((parseFloat(this.mainledger.VAT1Amount) + parseFloat(this.mainledger.VAT2Amount) + parseFloat(this.mainledger.VAT3Amount)),2)
      this.mainledger.AmountNetto = this.round((parseFloat(this.mainledger.InvoiceAmount) - this.mainledger.AmountVAT - parseFloat(this.mainledger.VAT4Base)),2)
    },
    doParse() {
      this.mainledger.InvoiceAmount = this.mainledger.InvoiceAmount ? parseFloat(this.mainledger.InvoiceAmount) : 0
      this.mainledger.InvoiceForeginAmount = this.mainledger.InvoiceForeginAmount ? parseFloat(this.mainledger.InvoiceForeginAmount) : 0
    },
    async mainledgerlist () {
      try {
        this.$store.dispatch('seteditItem', 0)
        await this.$router.push({
          name: 'mainledgervat'
        })
      } catch (err) {
        this.error = err.data
        // eslint-disable-next-line
        console.log(err)
      }
    },
    createvatform(){

      MainLedgerService.createvatform(this.mainledger)
      .then(res => {
        console.log(res)
        this.text = 'Obrazac je kreiran.'
        this.color = 'green'
        this.snackbar = true
        this.timeout = 2000
      })
      .catch(err => {
        console.log(err)
        this.text = 'Greska!! Obrazac NIJE kreiran.' + err
        this.color = 'red'
        this.snackbar = true
        this.timeout = 2000
      })
    },
    uploadvatform(){
      this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
      this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
      this.mainQuery.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : ''
      this.mainQuery.VATID = this.$store.state.companyActiv.CompanyVATID? this.$store.state.companyActiv.CompanyVATID: ''
      this.mainQuery.fromDate = this.mainledger.fromDate ? this.mainledger.fromDate : ''
      this.mainQuery.toDate = this.mainledger.toDate  ? this.mainledger.toDate  : ''
      const zoveSe = 'PDVobrazac_' + this.mainQuery.VATID + '_' + this.mainQuery.fromDate + '_' + this.mainQuery.toDate + '_.xml'
      // eslint-disable-next-line
        console.log(this.mainQuery)
      MainLedgerService.uploadvatform(this.mainQuery)
      .then(res => {
        // eslint-disable-next-line
        console.log(' have file ', res)
        const data = res.data
        //const status = res.status
        const header = res.headers
        const fileType = header['content-type']
        const blob = new Blob([data], { type: fileType })
        FileSaver.saveAs(blob, zoveSe);
        //FileSaver.saveAs(res.data, zoveSe)
        // this.text = 'Spremite knjigu.'
        // this.color = 'green'
        // this.snackbar = true
        // this.timeout = 2000
      })
      .catch(err => {
        // eslint-disable-next-line
        console.log(err)
        this.text = 'Greska!! Obrazac se NE moze ucitati.' + err
        this.color = 'red'
        this.snackbar = true
        this.timeout = 2000
      })
    },
    round(value, exp) {
      if (typeof exp === 'undefined' || +exp === 0)
        return Math.round(value)

      value = +value
      exp = +exp

      if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split('e')
      value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)))

      // Shift back
      value = value.toString().split('e')
      return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp))
    },
    calFirst() {

      const f1 = this.mainledger.Podatak101 ? this.mainledger.Podatak101 : 0
      const f2 = this.mainledger.Podatak102 ? this.mainledger.Podatak102 : 0
      const f3 = this.mainledger.Podatak103 ? this.mainledger.Podatak103 : 0
      const f4 = this.mainledger.Podatak104 ? this.mainledger.Podatak104 : 0
      const f5 = this.mainledger.Podatak105 ? this.mainledger.Podatak105 : 0
      const f6 = this.mainledger.Podatak106 ? this.mainledger.Podatak106 : 0
      const f7 = this.mainledger.Podatak107 ? this.mainledger.Podatak107 : 0
      const f8 = this.mainledger.Podatak108 ? this.mainledger.Podatak108 : 0
      const f9 = this.mainledger.Podatak109 ? this.mainledger.Podatak109 : 0
      const f10 = this.mainledger.Podatak110 ? this.mainledger.Podatak110 : 0
      this.mainledger.Podatak100 = parseFloat(f1) + parseFloat(f2) + parseFloat(f3) + parseFloat(f4) + parseFloat(f5) + parseFloat(f6) + parseFloat(f7) + parseFloat(f8) + parseFloat(f9) + parseFloat(f10)
    },
    calSecond() {
      const s1 = this.mainledger.Podatak201 ? this.mainledger.Podatak201 : 0
      const s2 = this.mainledger.Podatak202 ? this.mainledger.Podatak202 : 0
      const s3 = this.mainledger.Podatak203 ? this.mainledger.Podatak203 : 0
      const s4 = this.mainledger.Podatak204 ? this.mainledger.Podatak204 : 0
      const s5 = this.mainledger.Podatak205 ? this.mainledger.Podatak205 : 0
      const s6 = this.mainledger.Podatak206 ? this.mainledger.Podatak206 : 0
      const s7 = this.mainledger.Podatak207 ? this.mainledger.Podatak207 : 0
      const s8 = this.mainledger.Podatak208 ? this.mainledger.Podatak208 : 0
      const s9 = this.mainledger.Podatak209 ? this.mainledger.Podatak209 : 0
      const s10 = this.mainledger.Podatak210 ? this.mainledger.Podatak210 : 0
      const s11 = this.mainledger.Podatak211 ? this.mainledger.Podatak211 : 0
      const s12 = this.mainledger.Podatak212 ? this.mainledger.Podatak212 : 0
      const s13 = this.mainledger.Podatak213 ? this.mainledger.Podatak213 : 0
      const s14 = this.mainledger.Podatak214 ? this.mainledger.Podatak214 : 0
      const s15 = this.mainledger.Podatak215 ? this.mainledger.Podatak215 : 0
      this.mainledger.Podatak200 = parseFloat(s1) + parseFloat(s2) + parseFloat(s3) + parseFloat(s4) + parseFloat(s5) + parseFloat(s6) + parseFloat(s7) + parseFloat(s8) + parseFloat(s9) + parseFloat(s10) + parseFloat(s11) + parseFloat(s12) + parseFloat(s13) + parseFloat(s14) + parseFloat(s15)
      const ss1 = this.mainledger.Porez201 ? this.mainledger.Porez201 : 0
      const ss2 = this.mainledger.Porez202 ? this.mainledger.Porez202 : 0
      const ss3 = this.mainledger.Porez203 ? this.mainledger.Porez203 : 0
      const ss4 = this.mainledger.Porez204 ? this.mainledger.Porez204 : 0
      const ss5 = this.mainledger.Porez205 ? this.mainledger.Porez205 : 0
      const ss6 = this.mainledger.Porez206 ? this.mainledger.Porez206 : 0
      const ss7 = this.mainledger.Porez207 ? this.mainledger.Porez207 : 0
      const ss8 = this.mainledger.Porez208 ? this.mainledger.Porez208 : 0
      const ss9 = this.mainledger.Porez209 ? this.mainledger.Porez209 : 0
      const ss10 = this.mainledger.Porez210 ? this.mainledger.Porez210 : 0
      const ss11 = this.mainledger.Porez211 ? this.mainledger.Porez211 : 0
      const ss12 = this.mainledger.Porez212 ? this.mainledger.Porez212 : 0
      const ss13 = this.mainledger.Porez213 ? this.mainledger.Porez213 : 0
      const ss14 = this.mainledger.Porez214 ? this.mainledger.Porez214 : 0
      const ss15 = this.mainledger.Porez215 ? this.mainledger.Porez215 : 0

      this.mainledger.Porez200 = parseFloat(ss1) + parseFloat(ss2) + parseFloat(ss3) + parseFloat(ss4) + parseFloat(ss5) + parseFloat(ss6) + parseFloat(ss7) + parseFloat(ss8) + parseFloat(ss9) + parseFloat(ss10) + parseFloat(ss11) + parseFloat(ss12) + parseFloat(ss13) + parseFloat(ss14) + parseFloat(ss15)
    },
    calThird() {
      const t1 = this.mainledger.Podatak301 ? this.mainledger.Podatak301 : 0
      const t2 = this.mainledger.Podatak302 ? this.mainledger.Podatak302 : 0
      const t3 = this.mainledger.Podatak303 ? this.mainledger.Podatak303 : 0
      const t4 = this.mainledger.Podatak304 ? this.mainledger.Podatak304 : 0
      const t5 = this.mainledger.Podatak305 ? this.mainledger.Podatak305 : 0
      const t6 = this.mainledger.Podatak306 ? this.mainledger.Podatak306 : 0
      const t7 = this.mainledger.Podatak307 ? this.mainledger.Podatak307 : 0
      const t8 = this.mainledger.Podatak308 ? this.mainledger.Podatak308 : 0
      const t9 = this.mainledger.Podatak309 ? this.mainledger.Podatak309 : 0
      const t10 = this.mainledger.Podatak310 ? this.mainledger.Podatak310 : 0
      const t11 = this.mainledger.Podatak311 ? this.mainledger.Podatak311 : 0
      const t12 = this.mainledger.Podatak312 ? this.mainledger.Podatak312 : 0
      const t13 = this.mainledger.Podatak313 ? this.mainledger.Podatak313 : 0
      const t14 = this.mainledger.Podatak314 ? this.mainledger.Podatak314 : 0
      this.mainledger.Podatak300 = parseFloat(t1) + parseFloat(t2) + parseFloat(t3) + parseFloat(t4) + parseFloat(t5) + parseFloat(t6) + parseFloat(t7) + parseFloat(t8) + parseFloat(t9) + parseFloat(t10) + parseFloat(t11) + parseFloat(t12) + parseFloat(t13) + parseFloat(t14)
      const tt1 = this.mainledger.Porez301 ? this.mainledger.Porez301 : 0
      const tt2 = this.mainledger.Porez302 ? this.mainledger.Porez302 : 0
      const tt3 = this.mainledger.Porez303 ? this.mainledger.Porez303 : 0
      const tt4 = this.mainledger.Porez304 ? this.mainledger.Porez304 : 0
      const tt5 = this.mainledger.Porez305 ? this.mainledger.Porez305 : 0
      const tt6 = this.mainledger.Porez306 ? this.mainledger.Porez306 : 0
      const tt7 = this.mainledger.Porez307 ? this.mainledger.Porez307 : 0
      const tt8 = this.mainledger.Porez308 ? this.mainledger.Porez308 : 0
      const tt9 = this.mainledger.Porez309 ? this.mainledger.Porez309 : 0
      const tt10 = this.mainledger.Porez310 ? this.mainledger.Porez310 : 0
      const tt11 = this.mainledger.Porez311 ? this.mainledger.Porez311 : 0
      const tt12 = this.mainledger.Porez312 ? this.mainledger.Porez312 : 0
      const tt13 = this.mainledger.Porez313 ? this.mainledger.Porez313 : 0
      const tt14 = this.mainledger.Porez314 ? this.mainledger.Porez314 : 0
      const tt15 = this.mainledger.Porez315 ? this.mainledger.Porez315 : 0
      this.mainledger.Porez300 = parseFloat(tt1) + parseFloat(tt2) + parseFloat(tt3) + parseFloat(tt4) + parseFloat(tt5) + parseFloat(tt6) + parseFloat(tt7) + parseFloat(tt8) + parseFloat(tt9) + parseFloat(tt10) + parseFloat(tt11) + parseFloat(tt12) + parseFloat(tt13) + parseFloat(tt14) + parseFloat(tt15)
    },
     calEight() {
      const ei1 = this.mainledger.Podatak811 ? this.mainledger.Podatak811 : 0
      const ei2 = this.mainledger.Podatak812 ? this.mainledger.Podatak812 : 0
      const ei3 = this.mainledger.Podatak813 ? this.mainledger.Podatak813 : 0
      const ei4 = this.mainledger.Podatak814 ? this.mainledger.Podatak814 : 0
      const ei5 = this.mainledger.Podatak815 ? this.mainledger.Podatak815 : 0
      this.mainledger.Podatak810 = parseFloat(ei1) + parseFloat(ei2) + parseFloat(ei3) + parseFloat(ei4) + parseFloat(ei5)
    },
    calNine() {
      const ei1 = this.mainledger.Podatak611 ? this.mainledger.Podatak611 : 0
      const ei2 = this.mainledger.Podatak612 ? this.mainledger.Podatak612 : 0
      const ei3 = this.mainledger.Podatak613 ? this.mainledger.Podatak613 : 0
      const ei4 = this.mainledger.Podatak614 ? this.mainledger.Podatak614 : 0
      const ei5 = this.mainledger.Podatak615 ? this.mainledger.Podatak615 : 0
      this.mainledger.Podatak610 = parseFloat(ei1) + parseFloat(ei2) + parseFloat(ei3) + parseFloat(ei4) + parseFloat(ei5)
    },
    calAll() {
      this.calFirst()
      this.calSecond()
      this.calThird()
      this.calEight()
      this.calNine()
      this.mainledger.Podatak000 = this.mainledger.Podatak100 + this.mainledger.Podatak200

      this.mainledger.mainledgerFree1 = ''
      this.mainledger.Podatak400 = 0
      this.mainledger.Podatak600 = 0
      this.mainledger.Podatak400 = this.mainledger.Porez200 - this.mainledger.Porez300
      this.mainledger.Podatak600 = this.mainledger.Porez200 - this.mainledger.Porez300
      if (this.mainledger.Porez300 > this.mainledger.Porez200) {
        this.mainledger.MLFree1 = 'ZA POVRAT'
      }
      if (this.mainledger.Porez300 < this.mainledger.Porez200) {
        this.mainledger.MLFree1 = 'ZA UPLATU'
      }
    }
  },
  watch: {
    'mainledger.Podatak101' () {
      if (isNaN(this.mainledger.Podatak101)) {
        this.mainledger.Podatak101 = 0
      }
      this.calAll()
    },
    'mainledger.Podatak102' () {
      if (isNaN(this.mainledger.Podatak102)) {
        this.mainledger.Podatak102 = 0
      }
      this.calAll()
    },
    'mainledger.Podatak103' () {
      if (isNaN(this.mainledger.Podatak103)) {
        this.mainledger.Podatak103 = 0
      }
      this.calAll()
    },
    'mainledger.Podatak104' () {
      if (isNaN(this.mainledger.Podatak104)) {
        this.mainledger.Podatak104 = 0
      }
      this.calAll()
    },
    'mainledger.Podatak105' () {
      if (isNaN(this.mainledger.Podatak105)) {
        this.mainledger.Podatak105 = 0
      }
      this.calAll()
    },
    'mainledger.Podatak106' () {
      if (isNaN(this.mainledger.Podatak106)) {
        this.mainledger.Podatak106 = 0
      }
      this.calAll()
    },
    'mainledger.Podatak107' () {
      if (isNaN(this.mainledger.Podatak107)) {
        this.mainledger.Podatak107 = 0
      }
      this.calAll()
    },
    'mainledger.Podatak108' () {
      if (isNaN(this.mainledger.Podatak108)) {
        this.mainledger.Podatak108 = 0
      }
      this.calAll()
    },
    'mainledger.Podatak109' () {
      if (isNaN(this.mainledger.Podatak109)) {
        this.mainledger.Podatak109 = 0
      }
      this.calAll()
    },
    'mainledger.Podatak110' () {
      if (isNaN(this.mainledger.Podatak110)) {
        this.mainledger.Podatak110 = 0
      }
      this.calAll()
    },
    'mainledger.Porez201' () {
      if (isNaN(this.mainledger.Porez201)) {
        this.mainledger.Porez201 = 0
      }
      const tempP = this.round(this.mainledger.Porez201, 2)
      this.mainledger.Podatak201 = this.round((tempP / (0.05)), 2)
      this.calAll()
    },
    'mainledger.Porez202' () {
      if (isNaN(this.mainledger.Porez202)) {
        this.mainledger.Porez202 = 0
      }
      this.mainledger.Porez202 = this.round(this.mainledger.Porez202, 2)
      this.mainledger.Podatak202 = this.round((this.mainledger.Porez202 / (0.13)),2)
      this.calAll()
    },
    'mainledger.Porez203' () {
      if (isNaN(this.mainledger.Porez203)) {
        this.mainledger.Porez203 = 0
      }
      this.mainledger.PP = this.round(this.mainledger.PP)
      this.mainledger.Podatak203 = this.mainledger.Porez203 / (0.25)
      this.calAll()
    },
    'mainledger.Porez205' () {
      if (isNaN(this.mainledger.Porez205)) {
        this.mainledger.Porez205 = 0
      }
      this.mainledger.Podatak205 = this.mainledger.Porez205 / (0.05)
      this.calAll()
    },
    'mainledger.Porez206' () {
      if (isNaN(this.mainledger.Porez206)) {
        this.mainledger.Porez206 = 0
      }
      this.mainledger.Podatak206 = this.mainledger.Porez206 / (0.13)
      this.calAll()
    },
    'mainledger.Porez207' () {
      if (isNaN(this.mainledger.Porez207)) {
        this.mainledger.Porez207 = 0
      }
      this.mainledger.Podatak207 = this.mainledger.Porez207 / (0.25)
      this.calAll()
    },
    'mainledger.Porez208' () {
      if (isNaN(this.mainledger.Porez208)) {
        this.mainledger.Porez208 = 0
      }
      this.mainledger.Podatak208 = this.mainledger.Porez208 / (0.05)
      this.calAll()
    },
    'mainledger.Porez209' () {
      if (isNaN(this.mainledger.Porez209)) {
        this.mainledger.Porez209 = 0
      }
      this.mainledger.Podatak209 = this.mainledger.Porez209 / (0.13)
      this.calAll()
    },
    'mainledger.Porez210' () {
      if (isNaN(this.mainledger.Porez210)) {
        this.mainledger.Porez210 = 0
      }
      this.mainledger.Podatak210 = this.mainledger.Porez210 / (0.25)
      this.calAll()
    },
    'mainledger.Porez211' () {
      if (isNaN(this.mainledger.Porez211)) {
        this.mainledger.Porez211 = 0
      }
      this.mainledger.Podatak211 = this.mainledger.Porez211 / (0.05)
      this.calAll()
    },
    'mainledger.Porez212' () {
      if (isNaN(this.mainledger.Porez212)) {
        this.mainledger.Porez212 = 0
      }
      this.mainledger.Podatak212 = this.mainledger.Porez212 / (0.13)
      this.calAll()
    },
    'mainledger.Porez213' () {
      if (isNaN(this.mainledger.Porez213)) {
        this.mainledger.Porez213 = 0
      }
      this.mainledger.Podatak213 = this.mainledger.Porez213 / (0.25)
      this.calAll()
    },
    'mainledger.Porez301' () {
      if (isNaN(this.mainledger.Porez301)) {
        this.mainledger.Porez301 = 0
      }
      this.mainledger.Podatak301 = this.mainledger.Porez301 / (0.05)
      this.calAll()
    },
    'mainledger.Porez302' () {
      if (isNaN(this.mainledger.Porez302)) {
        this.mainledger.Porez302 = 0
      }
      this.mainledger.Podatak302 = this.mainledger.Porez302 / (0.13)
      this.calAll()
    },
    'mainledger.Porez303' () {
      if (isNaN(this.mainledger.Porez303)) {
        this.mainledger.Porez303 = 0
      }
      this.mainledger.Podatak303 = this.mainledger.Porez303 / (0.25)
      this.calAll()
    },
    'mainledger.Porez305' () {
      if (isNaN(this.mainledger.Porez305)) {
        this.mainledger.Porez305 = 0
      }
      this.mainledger.Podatak305 = this.mainledger.Porez305 / (0.05)
      this.calAll()
    },
    'mainledger.Porez306' () {
      if (isNaN(this.mainledger.Porez306)) {
        this.mainledger.Porez306 = 0
      }
      this.mainledger.Podatak306 = this.mainledger.Porez306 / (0.13)
      this.calAll()
    },
    'mainledger.Porez307' () {
      if (isNaN(this.mainledger.Porez307)) {
        this.mainledger.Porez307 = 0
      }
      this.mainledger.Podatak307 = this.mainledger.Porez307 / (0.25)
      this.calAll()
    },
    'mainledger.Porez308' () {
      if (isNaN(this.mainledger.Porez308)) {
        this.mainledger.Porez308 = 0
      }
      this.mainledger.Podatak308 = this.mainledger.Porez308 / (0.05)
      this.calAll()
    },
    'mainledger.Porez309' () {
      if (isNaN(this.mainledger.Porez309)) {
        this.mainledger.Porez309 = 0
      }
      this.mainledger.Podatak309 = this.mainledger.Porez309 / (0.13)
      this.calAll()
    },
    'mainledger.Porez310' () {
      if (isNaN(this.mainledger.Porez310)) {
        this.mainledger.Porez310 = 0
      }
      this.mainledger.Podatak310 = this.mainledger.Porez310 / (0.25)
      this.calAll()
    },
    'mainledger.Porez311' () {
      if (isNaN(this.mainledger.Porez311)) {
        this.mainledger.Porez311 = 0
      }
      this.mainledger.Podatak311 = this.mainledger.Porez311 / (0.05)
      this.calAll()
    },
    'mainledger.Porez312' () {
      if (isNaN(this.mainledger.Porez312)) {
        this.mainledger.Porez312 = 0
      }
      this.mainledger.Podatak312 = this.mainledger.Porez312 / (0.13)
      this.calAll()
    },
    'mainledger.Porez313' () {
      if (isNaN(this.mainledger.Porez313)) {
        this.mainledger.Porez313 = 0
      }
      this.mainledger.Podatak313 = this.mainledger.Porez313 / (0.25)
      this.calAll()
    },
    'mainledger.Podatak611' () {
      if (isNaN(this.mainledger.Podatak611)) {
        this.mainledger.Podatak611 = 0
      }
      this.calAll()
    },
    'mainledger.Podatak612' () {
      if (isNaN(this.mainledger.Podatak612)) {
        this.mainledger.Podatak612 = 0
      }
      this.calAll()
    },
    'mainledger.Podatak613' () {
      if (isNaN(this.mainledger.Podatak613)) {
        this.mainledger.Podatak613 = 0
      }
      this.calAll()
    },
    'mainledger.Podatak614' () {
      if (isNaN(this.mainledger.Podatak614)) {
        this.mainledger.Podatak614 = 0
      }
      this.calAll()
    },
    'mainledger.Podatak615' () {
      if (isNaN(this.mainledger.Podatak615)) {
        this.mainledger.Podatak615 = 0
      }
      this.calAll()
    },
  },
  components: {
    MainLedgerPrintVAT
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
