<template>
  <div class="mainledgerprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './cashdeskDescEn'
import langHr from './cashdeskDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import { parseNum } from '@/snippets/allSnippets'
import dateFormat from '@/snippets/dateFormat'
import dayjs from 'dayjs'

export default {
  name: 'cashdeskprintall',
  props: ['card'],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0,
      partner: {}
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}

  },
  methods: {
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    async print () {
     
      await this.initDocument()
      await this.addHeaderNoLogo()
      await this.addLine()
      await this.addItemsHeader()
      await this.addLine()
      await this.addItems()
      await this.addLine()
      await this.addItemsFoter()
      await this.addLine()
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.content = []
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addLineDash () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1,
                dash: { length: 1 },
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '35%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text:  'Blagajna \n', fontSize: 18, bold: true },
                  { text:   (this.card.businessYear ? this.card.businessYear : '' ) + ' \n', fontSize: 12},
                ]   
              },
              {
                // % width
                width: '20%',
                text: [                 
                  { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addItemsHeader () {
        const docPart = {
        columns: [
                  {
                    width: '6%',
                    fontSize: 8,
                    alignment: 'left',
                    text: 'Rb'
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'left',
                    text: this.lang.documentDate
                  },
                  {
                    width: '25%',
                    fontSize: 8,
                    alignment: 'left',
                    text: this.lang.Description
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'left',
                    text: this.lang.Employeeemail
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.lang.CashIn
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.lang.CashOut
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.lang.CashSaldo
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.lang.CashInFore
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.lang.CashOutFore
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.lang.CashSaldoFore
                  },

            ]
          }
        this.content.push(docPart)        
    },
    addItems () {
           this.order = 0           
            if (this.card.items && this.card.items.length > 0) {
              let totalIn = 0
              let totalOut = 0
              let totalInFore = 0
              let totalOutFore = 0
              let currSaldo = 0
              let currSaldoFore = 0
              this.card.items.map(item => {
                  this.order += 1
                  totalIn += parseNum(item.CashIn)
                  totalOut += parseNum(item.CashOut)
                  totalInFore += parseNum(item.CashInFore)
                  totalOutFore += parseNum(item.CashOutFore)
                  currSaldo = totalIn - totalOut
                  currSaldoFore = totalInFore - totalOutFore

                  const docPart01 = {
                  columns: [
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'left',
                          text: this.order
                        },
                        {
                          width: '10%',
                          fontSize: 8,
                          alignment: 'left',
                          text: this.localDate(item.documentDate)
                        },
                        {
                          width: '25%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.Description
                        },
                        {
                          width: '10%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.Employeeemail
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.CashIn)
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.CashOut)
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(currSaldo)
                        },
                                                    {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.CashInFore)
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.CashOutFore)
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(currSaldoFore)
                        },
                      ]
                    }
                  this.content.push(docPart01)
                }
              )
          }
    },
    addItemsFoter () {
       
      this.addLine()

      const docPart01 = {
           columns: [
              {
                width: '6%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: ' ' 
              },
              {
                width: '25%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: 'Ukupno:'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.card.totalIN)
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.card.totalOUT)
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.card.totalSaldo)
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.card.totalINfore)
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.card.totalOUTfore)
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.card.totalSaldofore)
              },
          ]
        }
      this.content.push(docPart01)
    },
  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>