const mainEN = {
  Dashboard: 'Dashboard',
  Sales: 'Sales',
  Inquirys: 'Inquiry',
  Quotes: 'Quote',
  QuoteSales: 'Quote Sales',
  Orders: 'Order',
  DeliveryNote: 'Delivery Note',
  DeliveryNoteSales: 'Delivery Note',
  InternalDeliveryNotes: 'Internal Delivery Note',
  Invoices: 'Invoice',
  InvoiceSales: 'Invoice',
  InvoicesAdvance: 'Invoice for advance',
  Contracts: 'Contract',
  WorkingOrder: 'Working order',
  Production: 'Production',
  Analysis: 'Analysis',
  Purchase: 'Purchase',
  Requests: 'Request',
  Order: 'Order',
  CalculationsPurchase: 'CalculationsPurchase',
  ReceiptPurchase: 'Purchase',
  Returns: 'Returns',
  CRM: 'CRM',
  Lead: 'Lead',
  Prospect: 'Prospect',
  Opportunity: 'Opportunity',
  ClosedWonOpportunity: 'Closed Won Opportunity',
  Customer: 'Customer',
  Quote: 'Quote',
  SalesOrder: 'Sales order',
  PurchaseOrder: 'Purchase Order',
  Finance: 'Finance',
  BookInInvoices: 'Book In Invoices',
  BookOutInvoices: 'Book Out Invoices',
  Ledger: 'Ledger',
  BankStatements: 'Bank Statements',
  BalanceSheet: 'BalanceSheet',
  FinanceAdvanced: 'Finance advanced',
  Reports: 'Reports',
  OsnovnaSredstva: 'Osnovna sredstva',
  SmallTools: 'SmallTools',
  Closing: 'Closing',
  LedgerAccounts: 'Ledger Accounts',
  HumanResources: 'Human resources',
  Emplyees: 'Emplyees',
  Salaries: 'Salaries',
  EmployeeDiary: 'Employee Diary',
  Travels: 'Travels',
  Administration: 'Administration',
  Items: 'Items',
  Groups: 'Groups',
  Partner: 'Partner',
  Warehouse: 'Warehouse',
  WarehouseUser: 'WarehouseUser',
  Company: 'Company',
  CompanyBusinessYear: 'CompanyBusinessYear',
  CompanyUsers: 'CompanyUsers',
  Users: 'Users',
  TaskType: 'TaskType',
  TaskDepartment: 'TaskDepartment',
  Tasks: 'Tasks',
  BankAccount: 'BankAccount',
  Settings: 'Settings',
  ServiceNotes: 'Service Notes',
  Inventory: 'Inventory',
  POS: 'POS',
  PriceChange: 'Price Change',
  Calendar: 'Calendar',
  CopyThisDocument: 'Copy this document to new document:',
  CopyDestination: 'Select destination document',
  CopyDestination2: '- copy to new document',
  MainBooks:'Main Book',
  MainBookComps:'Book bank statements',
  checkVATID : 'Check VAT ID',
  Serials: 'Serials',
  WorkHours: 'Work Hours',
  CardType:'Card Types',
  createSerials: 'Kreiraj serijske',
  serialsCreatedOK: 'Serials created OK',
  Forms: 'Forms',
  ReturnPurchase: 'Return',
  OrderPurchase: 'Order',
  InquiryPurchase: 'Inquiry',
  ItemsPerPage: 'Items Per Page',
  createStickersAll: 'Create stickers for all',
  createStickersOne: 'Create stickers by one',
  clearStickers: 'Clear Stickers',
  Terms: 'Terms of use',
  CashDesk: 'Cash Desk',
  City: 'City',
  Vehicles: 'Vehicles',
  DocDesc: 'Document Descriptions'
}

export default mainEN