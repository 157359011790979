<template>
    <v-container fluid grid-list-md>
      <h1>{{lang.Serials}}</h1>
      <SerialsList />
    </v-container>
</template>

<script>

import SerialsList from './SerialsList'
import langEn from './serialsDescEn'
import langHr from './serialsDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"

export default {
  name: 'serials',
  data () {
    return {
      lang: {},
      langC: {}
    }
  },
  mounted () {
    if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
    this.$store.dispatch('seteditItem', 0)
  },
  methods: {

  },
  computed: {
  },
  watch: {

  },
  components: {
    SerialsList
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
