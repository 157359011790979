import Api from '@/services/Api'
// import { log } from 'util';

export default {
  index (query) {
    return Api().get('task', {
      params: query
    })
  },
  tasktoday (query) {
    return Api().get(`tasktoday`, {
      params: query
    })
  },
  taskweek (query) {
    return Api().get(`taskweek`, {
      params: query
    })
  },
  taskalarmopen (query) {
    return Api().get(`taskalarmopen`, {
      params: query
    })
  },
  tasklast50 (query) {
    return Api().get(`tasklast50`, {
      params: query
    })
  },
  taskana (query) {
    return Api().get(`taskana`, {
      params: query
    })
  },
  taskemployee (employeeid) {
    return Api().get(`taskemployee/${employeeid}`)
  },
  show (taskid) {
    return Api().get(`task/${taskid}`)
  },
  post (newtask) {
    return Api().post('task', newtask)
  },
  put (task) {
    return Api().put(`task/${task.id}`, task)
  },
  delete (taskid) {
    return Api().delete(`task/${taskid}`)
  }
}
