import Api from '@/services/Api'
// import { log } from 'util';

export default {
  index (search) {
    return Api().get('tasktype', {
      params: {
        search: search
      }
    })
  },
  show (tasktypeid) {
    return Api().get(`tasktype/${tasktypeid}`)
  },
  post (newtasktype) {
    return Api().post('tasktype', newtasktype)
  },
  put (tasktype) {
    return Api().put(`tasktype/${tasktype.id}`, tasktype)
  },
  delete (tasktypeid) {
    return Api().delete(`tasktype/${tasktypeid}`)
  },
  choose (query) {
    return Api().get('tasktype/choose', { params: query })
  }
}
