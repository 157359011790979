<template>
  <v-container>
    <v-row>
      <v-col cols="4" sm="12" md="6" xs="12" lg="6" xl="6" v-if="bookIn">
        <h4><b>{{lang.LatestBooking}}</b></h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" sm="12" md="8" xs="12" lg="8" xl="8" v-if="bookIn">
        <b><i>{{lang.LatestBookIn}}</i></b> {{ parseInt(bookIn.BookID)}} - {{formatDate(bookIn.BookingDate)}}
      </v-col>
      <v-col cols="4" sm="12" md="8" xs="12" lg="8" xl="8" v-else>
        <b><i>{{lang.LatestBookIn}}</i></b> {{ lang.LatestNoData }} 
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" sm="12" md="8" xs="12" lg="8" xl="8" v-if="bookOut">
        <b><i>{{lang.LatestBookOut}}</i></b> {{ parseInt(bookOut.BookID)}} - {{formatDate(bookOut.BookingDate)}}
      </v-col>
      <v-col cols="4" sm="12" md="8" xs="12" lg="8" xl="8" v-else>
        <b><i>{{lang.LatestBookOut}}</i></b>{{ lang.LatestNoData }} 
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" sm="12" md="8" xs="12" lg="8" xl="8" v-if="bookBS">
        <b><i>{{lang.LatestBookBS}}</i></b> {{ parseInt(bookBS.bankStatmentNumber)}} - {{formatDate(bookBS.bankStatmentDate)}}
      </v-col>
      <v-col cols="4" sm="12" md="8" xs="12" lg="8" xl="8" v-else>
        <b><i>{{lang.LatestBookBS}}</i></b> {{ lang.LatestNoData }} 
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import MainLedgerService from '@/services/MainLedgerService'

export default {
  name: 'mainledgerlatest',
  data () {
    return {
      lang: {},
      langC: {},
      saving: false,
      mainledgerAna: {},
      bookIn: null,
      bookOut: null,
      bookBS: null
    }
  },
  components: {
  },
  async mounted () {
      if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      this.mainledgerAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
      this.mainledgerAna.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : null
      this.mainledgerAna.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
      this.mainledgerAna.type = 5
      const mlAna1 = {...this.mainledgerAna}
      mlAna1.MLType = 1
      const mlAna2 = {...this.mainledgerAna}
      mlAna2.MLType = 2
      const mlAna4 = {...this.mainledgerAna}
      mlAna4.MLType = 4
      this.bookIn = null
      this.bookOut = null
      this.bookBS = null
      const res1 = (await MainLedgerService.findlatest(mlAna1))
      if (res1 && res1.data && res1.data.mainLedger && res1.data.mainLedger.length > 0) {
        this.bookIn = res1.data.mainLedger[0]
      }
      const res2 = (await MainLedgerService.findlatest(mlAna2))
      if (res2 && res2.data && res2.data.mainLedger && res2.data.mainLedger.length > 0) {
        this.bookOut = res2.data.mainLedger[0]
      }
      const res4 = (await MainLedgerService.findlatest(mlAna4))
      if (res4 && res4.data && res4.data.mainLedger && res4.data.mainLedger.length > 0) {
        this.bookBS = res4.data.mainLedger[0]
      }
      const newObj = {}
      if (this.bookIn) {
        newObj.head1 = this.lang.LatestBookIn + parseInt(this.bookIn.BookID) + ', ' + this.formatDate(this.bookIn.BookingDate)
      }
      if (!this.bookIn) {
        newObj.head1 = this.lang.LatestBookIn + this.lang.LatestNoData
      }
      if (this.bookOut) {
        newObj.head2 = this.lang.LatestBookOut + parseInt(this.bookOut.BookID) + ', ' +  this.formatDate(this.bookOut.BookingDate)
      }
      if (!this.bookOut) {
        newObj.head2 = this.lang.LatestBookOut + this.lang.LatestNoData
      }
      if (this.bookBS) {
        newObj.head3 = this.lang.LatestBookBS + parseInt(this.bookBS.bankStatmentNumber) +  ', ' + this.formatDate(this.bookBS.bankStatmentDate)
      }
      if (!this.bookBS) {
        newObj.head3 = this.lang.LatestBookBS + this.lang.LatestNoData
      }
      await this.$store.dispatch('setPrintObj',newObj)

  },
  computed: {

  },
  watch: {

  },
  methods: {
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    close () {
      this.show = false
    },

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>

