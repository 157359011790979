const docEN = {
  WorkHour: 'Workhour',
  WorkHours: 'Work Hours',
  EmployeeId: 'Employee',
  email: 'email',
  First: 'First name',
  Last: 'Last name',
  ForWeek: 'For week',
  ForMonth: 'For month',
  ForYear: 'For year',
  WDate: 'Date',
  WDay: 'Day',
  PocetakRada: 'Start time',
  ZavrsetakRada: 'End time',
  RedovanRad: 'Normal work',
  NocniRad: 'Night work',
  PrekovremeniRad: 'Overtime work',
  NedjeljaRad: 'Work sunday',
  BlagdanRad: 'Work holiday',
  TerenRad: 'Work field',
  PreraspodjelaRada: 'Change work',
  Pripravnost: 'Prepare',
  DnevniOdmor: 'Day break',
  TjedniOdmor: 'Week break',
  NeradniBlagdan: 'Nonwork holiday',
  GodisnjiOdmor: 'Vacation',
  BolovanjeDo42Dana: 'Seek leave to 42 days',
  BolovanjeOd42Dana: 'Seek leave over 42 days',
  Rodiljni: 'Maternity leave',
  PlaceniDopust: 'Paid vacation',
  NeplaceniDopust: 'Unpaid vacation',
  NenazocnostNaZahtjevRadnika: 'Leave on request worker',
  ZastojKrivnjomPoslodavca: 'Stop employer quilty',
  Strajk: 'Straik',
  Lockout: 'Lockout',
  VrijemeMirovanjaRadnogOdnosa: 'Suspension of employment',
  TotalHours: 'Total hours',
  Create: 'Create default',
  DeleteMonth: 'Delete month',
  WhEmpty: 'Empty table'
}

export default docEN