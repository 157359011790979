const docHR = {
  Item: 'Artikal/usluga',
  Items: 'Artikli/usluge',
  ItemDetails: 'Artikl/usluga detalji',
  ItemCreate: 'Upis artikla/usluge',
  SupplierData: 'Dobavljač podaci',
  CalcPrice: 'Izracunaj cijenu',
  Saved: 'Artikl/usluga spremljeni u bazu',
  Price: 'Cijena',
  PriceExp: 'Cijena izvoz',
  BasicData: 'Osnovni podaci',
  AddItem: 'Dodaj novi artikal/uslugu',
  EditItem: 'Uredi artikal/uslugu',
  GroupId: 'Grupa',
  GroupName: 'Grupa naziv',
  ItemID: 'Šifra',
  ItemName: 'Naziv',
  ItemNameShort: 'Naziv skraćen',
  ItemCode: 'Kod',
  ItemEANCode: 'EAN kod',
  ItemGroup: 'Grupa',
  ItemDescription: 'Opis',
  ItemUnit: 'Mjera',
  ItemSize: 'Pakiranje',
  ItemWeight: 'Težina',
  ItemWeightMeasure: 'Mjera težine',
  ItemDimMeasure: 'Dim mjera',
  ItemDimLenght: 'Dužina',
  ItemDimHeight: 'Visina',
  ItemDimDepth: 'Dubina',
  ItemWarehouse: 'Skladište',
  ItemWarehouseRow: 'Red',
  ItemWarehouseShelf: 'Polica',
  ItemWahrehouseFloor: 'Kat',
  ItemPicture1: 'Slika 1 link',
  ItemPicture2: 'Slika 2 link',
  ItemPicture3: 'Slika 3 link',
  ItemPicture4: 'Slika 4 link',
  ItemPicture5: 'Slika 5 link',
  ItemPicture6: 'Slika 6 link',
  ItemPicture7: 'Slika 7 link',
  ItemBelongsToId: 'Pripada šifri',
  ItemService: 'Usluga',
  ItemWeb: 'Web',
  ItemNotActiv: 'Nije aktivan',
  ItemHideForever: 'Sakrij zauvijek',
  ItemHomeCurrency: 'Domaća valuta',
  ItemForeignCurrency: 'Strana valuta',
  ItemExRate: 'Tečaj',
  ItemSuppPrice: 'Cijena dobavljača',
  ItemSuppBasePrice: 'Cijena dob.sa popustom',
  ItemSuppDiscount: 'Popust dobavljača',
  ItemSuppCode: 'Šifra dobavljača',
  ItemMargin: 'Marža',
  ItemMaxDiscount: 'Max Popust',
  ItemPrice: 'Cijena',
  ItemVAT1Percentage: 'PDV %',
  ItemVAT1Amount: 'PDV iznos',
  ItemVAT2Percentage: 'PDV % dva',
  ItemVAT2Amount: 'PDV iznos dva',
  ItemDirectDuty1: 'Direktna naknada 1',
  ItemDirectDuty2: 'Direktna naknada 2',
  ItemPriceWithVAT: 'Cijena sa PDVom',
  ItemForePrice: 'Cijena ino',
  ItemForeVAT1Percentage: 'PDV % ino',
  ItemForeVAT1Amount: 'PDV iznos ino',
  ItemForeVAT2Percentage: 'PDV% drugi ino',
  ItemForeVAT2Amount: 'PDV iznos drugi ino',
  ItemForeDirectDuty1: 'Direktna naknada ino',
  ItemForeDirectDuty2: 'Direktna naknada ino',
  ItemForePriceWithVAT: 'Cijena sa PDVom ino',
  ItemDiscountAmount1: 'Kolčina za popust 1',
  ItemDiscountPercentage1: 'Popust % ',
  ItemDiscountAmount2: 'Količina za popust 2',
  ItemDiscountPercentage2: 'Popust %',
  ItemDiscountAmount3: 'Količina za popust 3',
  ItemDiscountPercentage3: 'Popust % ',
  ItemWarranty: 'Jamstvo mj.'
}

export default docHR