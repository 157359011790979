const docEN = {
  docsmall: 'Small description',
  DocDescList: 'Lista of descriptions',
  Description: 'New description',
  DocDesc: 'Description',
  EditDocDesc: 'Edit description',
  DocDescListEmpty: 'Lista is empty',
  Doc: 'Document name',
  DocLoc: 'Document name',
  docOnly: 'Use only in document',
}

export default docEN