import Api from '@/services/Api'
// import { log } from 'util';

export default {
  index(detQuery) {
    return Api().get(`mainledgerdetail/${detQuery}`)
  },
  show(mainledgerdetailid) {
    return Api().get(`mainledgerdetail/${mainledgerdetailid}`)
  },
  post(newmainledgerdetail) {
    return Api().post('mainledgerdetail', newmainledgerdetail)
  },
  put(mainledgerdetail) {
    return Api().put(`mainledgerdetail/${mainledgerdetail.id}`, mainledgerdetail)
  },
  delete(databaseMLID) {
    return Api().delete(`mainledgerdetail/${databaseMLID}`)
  },
  deleteSide(databaseMLID) {
    return Api().delete(`mainledgerside/${databaseMLID}`)
  },
  choose(query) {
    return Api().get('mainledgerdetail/choose', {
      params: query
    })
  },
  saveitems(items) {
    if (items) {
      return Api().post('mainledgerdetail/saveitems', items)
    }
    return
  },
  olditems(query) {
    return Api().get('mainledgerdetailolditems', {
      params: query
    })
  },
  previtems(query) {
    return Api().get('mainledgerdetailprevitems', {
      params: query
    })
  },
  card(query) {
    return Api().get('mainledgerdetailcard', {
      params: query
    })
  },
  findinvoice(query) {
    return Api().get('mainledgerdetailfindinvoice', {
      params: query
    })
  },
  analyze(query) {
    return Api().get('mainledgerdetailanalyze', {
      params: query
    })
  },
}