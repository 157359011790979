<template>
<v-container >
    <v-row class="pt-3" align="center" justify="center">
      <v-flex xs12 sm12 md12 lg10 xl10>

            <v-layout row>
                <v-flex xs11>
                <span v-if="this.$store.state.user.AccessLevel <= 3 && allowFin">
                    <v-btn color="primary" @click="mainledgerDetails" class="mr-1">
                        <span v-if="this.$store.state.language == 'EN'">
                        {{documentNameLoc}} - details
                      </span>
                      <span v-if="this.$store.state.language == 'HR'">
                        {{documentNameLoc}} - detalji
                      </span>
                    </v-btn>
                </span>
                
                <span v-if="this.$store.state.user.AccessLevel <= 3">
                    <v-btn color="primary" @click="mainledgerEdit" class="mr-1">
                        <span v-if="this.$store.state.language == 'EN'">
                        Edit
                      </span>
                      <span v-if="this.$store.state.language == 'HR'">
                        Uredi
                      </span>
                    </v-btn>
                </span>
                
                  <v-btn color="green" @click="mainledgerlist">
                      <span v-if="this.$store.state.language == 'EN'">
                      Back
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                      Natrag
                    </span>
                  </v-btn>

                </v-flex>
                <v-flex xs1>
                <span v-if="this.$store.state.user.AccessLevel <= 2">
                    <v-btn text small color="red" @click="mainledgerDelete" class="mr-1">
                        <span v-if="this.$store.state.language == 'EN'">
                        Delete
                      </span>
                      <span v-if="this.$store.state.language == 'HR'">
                        Obriši
                      </span>
                    </v-btn>
                </span>

              </v-flex>
            </v-layout>

            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <v-card outlined class="mx-auto">
                  <v-card-text>
                  <span v-if="show == 1 || show == 2">
                    <app-main-ledger-view2></app-main-ledger-view2> 
                  </span>
                  <span v-if="show == 3">
                    <app-main-ledger-view3></app-main-ledger-view3>
                  </span>
                  <span v-if="show == 4">
                    <app-main-ledger-view4></app-main-ledger-view4>
                  </span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <div class="pink darken-2" v-html="error" />

      </v-flex>
    </v-row>
</v-container>

</template>

<script>
import MainLedgerView2 from './MainLedgerView2'
import MainLedgerView3 from './MainLedgerView3'
import MainLedgerView4 from './MainLedgerView4'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"

export default {
  name: 'mainledgerview',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      mainledger: {},
      documentName: '',
      documentNameLoc: '',
      error: null,
      localError: false,
      mainledgerActiv: {},
      havePath: null,
      show: 1,
      retPath: 'mainledger',
      lang: null,
      langC: null,
      privateP: false,
      finance3: false,
      allowFin: false
    }
  },
  components: {
    appMainLedgerView2: MainLedgerView2,
    appMainLedgerView3: MainLedgerView3,
    appMainLedgerView4: MainLedgerView4,
  },
  mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    this.privateP = "privatePartner" in this.$store.state.user ? this.$store.state.user.privatePartner : true
    this.finance3 = "finance3" in this.$store.state.user ? this.$store.state.user.finance3 : true
    if (!this.privateP) {
      this.allowFin = true
    }
    if (this.finance3) {
      this.allowFin = true
    }

    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    if (this.$store.state.documentSide === 1 || this.$store.state.documentSide === 2) {
      this.show = 1
      this.retPath = 'mainledger'
    }
    if (this.$store.state.documentSide === 3) {
      this.show = 3
      this.retPath = 'mainledger3'
    }
    if (this.$store.state.documentSide === 4) {
      this.show = 4
      this.retPath = 'mainledger4'
    }
    this.havePath = this.retPath ? this.retPath : ''
  },
  computed: {
  },
  methods: {
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    close () {
      this.show = false
    },
    async mainledgerlist () {
      try {
        this.havePath = this.retPath ? this.retPath : ''
        this.$store.dispatch('setretPath', '')
        if (this.havePath !== 'mainledger' && this.havePath.length > 0) {
          await this.$router.push({
            name: this.havePath
          })
        } else {
          await this.$router.push({
            name: 'mainledger'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async mainledgerDelete () {
      try {
        await this.$router.push({
          name: 'mainledgerdelete'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async mainledgerEdit () {
      try {
        this.$store.dispatch('seteditItem', 1)
        if(this.havePath) {
          if (this.havePath !== 'mainledger' && this.havePath.length > 0) {
            await this.$router.push({
              name: 'mainledgercreate3'
            })
          } else {
            await this.$router.push({
              name: 'mainledgercreate'
            })
          }
        } else {
          await this.$router.push({
              name: 'mainledgercreate'
          })
        }
        
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async mainledgerDetails () {
      try {
        this.$store.dispatch('setMainLedgerTotals', 1)
        await this.$router.push({
          name: 'mainledgerdetail'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async mainledgeragain () {
      try {
        this.$forceUpdate()
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>

