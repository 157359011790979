<template>
<div>
  <v-container fluid grid-list-md>
  <v-row>
      <appAppSettings></appAppSettings>
    </v-row>
  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="users"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="30"
    :mobile-breakpoint="0" 
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{lang.Users}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

        <v-spacer></v-spacer>
        <span v-if="changePass">PASSWORD CHANGE  &nbsp;&nbsp; </span>
        <v-dialog v-model="dialog" max-width="500px">

          <!-- <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template> -->


          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-row>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-text-field
                        v-model="editedItem.email"
                        label="E-mail"
                        :rules="emailRules"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="4" md="4" lg="4" xl="4" v-if="changePass">
                      <span v-if="headAdmin">
                        <v-text-field
                          label="Password"
                          :type="show1 ? 'text' : 'password'"
                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          v-model="editedItem.password"
                          :rules="passwordRules"
                          required
                          counter
                          @click:append="show1 = !show1"
                        ></v-text-field>
                      </span>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4" v-if="changePass">
                      <span v-if="headAdmin">
                        <v-text-field
                          :label="lang.ConfirmPassword"
                          :type="show2 ? 'text' : 'password'"
                          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                          v-model="confpassword"
                          :rules="passwordRules"
                          required
                          counter
                          @click:append="show2 = !show2"
                        ></v-text-field>
                      </span>
                    </v-col>
                  </v-row>

                  <div v-if="!changePass">
                  <v-row dense>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-text-field
                        :label="lang.First"
                        v-model="editedItem.First"
                        :rules="nameRules"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-text-field
                        :label="lang.Last"
                        v-model="editedItem.Last"
                        :rules="nameRules"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="editedItem.StartFrom"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="editedItem.StartFrom"
                              :label="lang.StartFrom"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
    
                            <v-date-picker v-model="editedItem.StartFrom" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="menu = false">Cancel</v-btn>
                            <v-btn color="primary" @click="$refs.menu.save(editedItem.StartFrom)">OK</v-btn>
                            </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-switch
                        v-model="editedItem.Activ"
                        :label="lang.Activ"
                      ></v-switch>
                    </v-col>
                     <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-switch
                          v-model="editedItem.admin1"
                          :label="lang.admin1"
                        ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <span v-if="headAdmin">
                        <v-switch
                          v-model="editedItem.Admin"
                          :label="lang.Admin"
                        ></v-switch>
                      </span>
                    </v-col>
                    
                    
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-switch
                        v-model="editedItem.sales1"
                        :label="lang.sales1"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-switch
                          v-model="editedItem.purchase1"
                          :label="lang.purchase1"
                        ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-switch
                          v-model="editedItem.hr1"
                          :label="lang.hr1"
                        ></v-switch>
                    </v-col>
                    
                   
                  </v-row>
                  <v-row dense>
                     <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-switch
                          v-model="editedItem.finance1"
                          :label="lang.finance1"
                        ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-switch
                          v-model="editedItem.finance3"
                          :label="lang.finance3"
                        ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-switch
                          v-model="editedItem.finance4"
                          :label="lang.finance4"
                        ></v-switch>
                    </v-col>
                   
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-switch
                          v-model="editedItem.beton"
                          label="Obrt"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-switch
                          v-model="editedItem.privatePartner"
                          :label="lang.privatePartner"
                        ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-switch
                          v-model="editedItem.crm1"
                          :label="lang.crm1"
                        ></v-switch>
                    </v-col>
                  </v-row>

                  <v-row>
                     <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-switch
                          v-model="editedItem.prod1"
                          :label="lang.prod1"
                        ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-switch
                          v-model="editedItem.prod2"
                          :label="lang.prod2"
                        ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-switch
                        v-model="editedItem.sales2"
                        :label="lang.sales2"
                      ></v-switch>
                    </v-col>
                  </v-row>

                  <v-row>                    
                    
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-text-field
                        :label="lang.VATID"
                        v-model="editedItem.vatID"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-select
                        menu-props="auto"
                        single-line
                        :label="lang.AccessLevel"
                        :items="privitems"
                        v-model="editedItem.AccessLevel"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row> 
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-switch
                          v-model="editedItem.chart1"
                          :label="lang.chart1"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-switch
                          v-model="editedItem.chart2"
                          :label="lang.chart2"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-switch
                          v-model="editedItem.salesNumbers1"
                          :label="lang.salesNumbers1"
                        ></v-switch>
                    </v-col>
                  </v-row>

                  <v-row> 
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-switch
                          v-model="editedItem.calendar1"
                          :label="lang.calendar1"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-switch
                          v-model="editedItem.task1"
                          :label="lang.task1"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-switch
                          v-model="editedItem.vehicles"
                          :label="lang.vehicles"
                      ></v-switch>
                    </v-col>
                    
                  </v-row>
                   <v-row> 
                    
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">

                    </v-col>
                  </v-row>
                  </div>

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <span v-if="headAdmin">
                      <v-btn :disabled="!valid" color="success" text @click="checkMatch">{{langC.Save}}</v-btn> 
                    </span>
                    <span v-if="!headAdmin">
                      <v-btn color="success" text @click="save">{{langC.Save}}</v-btn> 
                    </span>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.StartFrom`]="{ item }">
        <span>{{ localDate(item.StartFrom) }}</span>
    </template>
    <template v-slot:[`item.Activ`]="{ item }">
      <span v-if="item.Activ"><v-icon>mdi-check</v-icon></span>
    </template>
    <template v-slot:[`item.Admin`]="{ item }">
      <span v-if="item.Admin"><v-icon>mdi-check</v-icon></span>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
      <v-icon
        small
        @click="changePassword(item)"
      >
        mdi-lock
      </v-icon>
      <!-- <v-icon
        
        @click="deleteItem(item)"
        class="pl-2"
      >
        mdi-plus-circle-outline 
      </v-icon> -->
    </template>
    <template v-slot:no-data>
      {{ lang.UsersEmpty }}
    </template>
  </v-data-table>
  </v-container>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import langEn from './userDescEn'
import langHr from './userDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import AppSettings from '../AppSettings'
import dayjs from 'dayjs'

  export default {
    name: 'userCRUD',
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      headAdmin: false,
      changePass: false,
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: "",
        databaseDocID: 0
      },
      dialog: false,
      headers: [
        
      ],
      users: [],
      editedIndex: -1,
      editedItem: { },
      defaultItem: {},
      confpassword: '',
      privitems: [ ],
      valid: true,
      nameRules: [
        v => !!v || 'Name is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password must be 6 or more characters',
      ],
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewUser: this.lang.EditUser
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      'editedItem.password' () {
        this.errorMatch = false
      },
      'confpassword' () {
        this.errorMatch = false
      },
      'editedItem.finance4' () {
        if (this.editedItem.finance4) {
          this.editItem.finance3 = false
        }
      }
    },
    components: {
      appAppSettings: AppSettings
    },

    mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      let locDate1 = new Date()
      locDate1 = dayjs().set({ 'year': locDate1, 'month': 0, 'date': 1 })
      this.defaultItem.startFrom = dayjs(locDate1).format('YYYY-MM-DD')

      this.privitems.push(
        { text: this.lang.Priv1, value: 1 },
        { text: this.lang.Priv2, value: 2 },
        { text: this.lang.Priv3, value: 3 },
        { text: this.lang.Priv4, value: 4 },
        { text: this.lang.Priv5, value: 5 }
      )

      this.headers.push(
        {text: this.lang.Last, value: 'Last', width: '200px'},
        {text: this.lang.First, value: 'First', width: '200px'},
        {text: 'email', value: 'email', width: '200px'},
        {text: this.lang.StartFrom, value: 'StartFrom', width: '50px', type: 'date', dateInputFormat: 'YYYY-MM-DD', dateOutputFormat: 'DD.MM.YYYY'},
        {text: this.lang.Activ, value: 'Activ', type: 'boolean', width: '50px'},
        {text: this.lang.Admin, value: 'Admin', type: 'boolean', width: '50px'},
        {text: this.lang.AccessLevel, value: 'AccessLevel', type: 'number', width: '50px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
        {text: this.lang.Actions, value: 'action', sortable: false },
      ) 
    },

    created () {
      this.initialize()
    },

    methods: {
      changePassword() {
        this.changePass = !this.changePass
      },
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      async initialize () {
        try {
          this.saving = true
          this.users = (await UserService.index()).data.users       
          this.saving = false
          this.errorMatch = false
          this.editedItem = {}
        } catch (err) {
          this.error = err
          
        }
      },
      editItem (item) {
        this.errorMatch = false
        this.headAdmin = false
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = {...item}

        this.editedItem.AccessLevel = this.editedItem.AccessLevel ? parseInt(this.editedItem.AccessLevel) : 5
        if (this.$store.state.user.Admin) {
          if (this.changePass) {
            this.editedItem.password = ''
            this.confpassword = ''
          }
          this.headAdmin = true
          // const newAccLevel = { AccessLevel: accLevel,
          //                     password: ''}
          // this.editedItem = Object.assign({}, this.editedItem, newAccLevel)
        }
        this.dialog = true
       
      },

      async deleteItem (item) {
        try {
           if (this.$store.state.user.Admin) {
            const index = this.users.indexOf(item)
            const warningMessage = item.First + ' ' + item.Last
            if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.users.splice(index, 1)) {
              await UserService.delete(item.id)
              this.initialize()
            }
           }
        } catch(err) {
          this.error = err
         
        }
        
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      checkMatch () {
        if (this.changePass) {
        if (this.editedItem.password && this.confpassword &&  this.confpassword === this.editedItem.password) {
          this.errorMatch = null
          this.save()
        } else {
          this.errorMatch = 'Error. Passoword and Confirmation Password are NOT equal!'
          this.valid = false        
        }
        }
        if (!this.changePass) {
          this.save()
        }
      },

      async save () {
        try {
          if (this.editedItem.finance4) {
            this.editItem.finance3 = false
          }
           let doneOK = false
           if (this.editedIndex > -1) {
              if (!this.changePass){
                await UserService.put(this.editedItem)}
              if (this.changePass){
                await UserService.passupdate(this.editedItem)}
              doneOK = true
            } else {
              //this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              //this.editedItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
              this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
              this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              //this.editedItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : ''
              await UserService.post(this.editedItem)
              doneOK = true
            }
            if (doneOK) {
              this.initialize()
              this.close()
            }
        } catch(err) {
          this.error = err
          
        }
       
      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      }
    },
  }
</script>