<template>

  <v-layout row>
      <v-card>
        <v-card-title class="headline">
           {{topHeader}}
           <v-spacer></v-spacer>
            <v-btn
              text color="primary"
              @click="downloadFile()">
              download
            </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="fullYear"
            :items-per-page="20"
            class="elevation-1"
          >
            <template v-slot:[`item.invoicesTotal`]="{ item }">
                <span  style="color:blue; font-weight:bold">{{ localMoney(item.invoicesTotal) }}</span>
            </template>
            <template v-slot:[`item.invoicesGoods`]="{ item }">
                <span>{{ localMoney(item.invoicesGoods) }}</span>
            </template>
            <template v-slot:[`item.invoicesGoodsEarn`]="{ item }">
                <span>{{ localMoney(item.invoicesGoodsEarn) }}</span>
            </template>
            <template v-slot:[`item.invoicesServices`]="{ item }">
                <span>{{ localMoney(item.invoicesServices) }}</span>
            </template>
            <template v-slot:[`item.invoicesTotalEarn`]="{ item }">
                <span style="color:green; font-weight:bold">{{ localMoney(item.invoicesTotalEarn) }}</span>
            </template>
            <template v-slot:[`item.posTotal`]="{ item }">
                <span style="color:#6A1B9A; font-weight:bold">{{ localMoney(item.posTotal) }}</span>
            </template>
            <template v-slot:[`item.posGoods`]="{ item }">
                <span>{{ localMoney(item.posGoods) }}</span>
            </template>
            <template v-slot:[`item.posGoodsEarn`]="{ item }">
                <span>{{ localMoney(item.posGoodsEarn) }}</span>
            </template>
            <template v-slot:[`item.posServices`]="{ item }">
                <span>{{ localMoney(item.posServices) }}</span>
            </template>
            <template v-slot:[`item.posTotalEarn`]="{ item }">
                <span style="color:green; font-weight:bold">{{ localMoney(item.posTotalEarn) }}</span>
            </template>
            <template v-slot:[`item.totalTotal`]="{ item }">
                <span style="color:#4527A0; font-weight:bold">{{ localMoney(item.totalTotal) }}</span>
            </template>
            <template v-slot:[`item.totalGoods`]="{ item }">
                <span>{{ localMoney(item.totalGoods) }}</span>
            </template>
            <template v-slot:[`item.totalGoodsEarn`]="{ item }">
                <span>{{ localMoney(item.totalGoodsEarn) }}</span>
            </template>
            <template v-slot:[`item.totalServices`]="{ item }">
                <span>{{ localMoney(item.totalServices) }}</span>
            </template>
             <template v-slot:[`item.totalTotalEarn`]="{ item }">
                <span style="color:green; font-weight:bold">{{ localMoney(item.totalTotalEarn) }}</span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>

        </v-card-actions>
      </v-card>
  </v-layout>
</template>

<script>
import DocumentService from '@/services/DocumentService'
import DocumentDetailService from '@/services/DocumentDetailService'
import {months} from '@/snippets/allSnippets'
import moneyFormat from '@/snippets/moneyFormat'
import xlsx from 'json-as-xlsx'
import dayjs from 'dayjs'

export default {
  name: 'documentdashboardtotals',
  data () {
    return {
      msg: '',
      dialog: false,
      documentAna: {},
      fullYear: [],
      headers: [],
      details: [],
      TotalDiscount: 0,
      TotalServices: 0,
      TotalNettoServices: 0,
      TotalDiff: 0,
      typePcs: 1,
      topHeader: ''
    }
  },
  mounted() {
    this.sumsales()
    if (this.$store.state.language === 'EN') {
      this.topHeader = 'Sales ' + this.$store.state.businessYear + ' report, created: ' + dayjs().format('DD.MM.YYYY HH:MM')
      this.headers.push(
        { text: 'Month', align: 'start', sortable: true, value: 'lang',},
        { text: 'Invoices total', value: 'invoicesTotal', align: 'end', sortable: true},
        { text: 'Invoices - goods', value: 'invoicesGoods', align: 'end', sortable: true },
        { text: 'Invoices - goods profit', value: 'invoicesGoodsEarn', align: 'end', sortable: true },
        { text: 'Invoices - services', value: 'invoicesServices', align: 'end', sortable: true },
        { text: 'Invoices - total profit netto', value: 'invoicesTotalEarn', align: 'end', sortable: true, bold: true },
        { text: 'POS - total', value: 'posTotal', align: 'end', sortable: true, bold:true },
        { text: 'POS - goods', value: 'posGoods', align: 'end', sortable: true },
        { text: 'POS - goods profit', value: 'posGoodsEarn', align: 'end', sortable: true },
        { text: 'POS - services', value: 'posServices', align: 'end', sortable: true },
        { text: 'POS - total profit netto', value: 'posTotalEarn', align: 'end', sortable: true, bold: true },
        { text: 'Total ', value: 'totalTotal', align: 'end', sortable: true, bold:true },
        { text: 'Total goods', value: 'totalGoods', align: 'end', sortable: true },
        { text: 'Total goods profit', value: 'totalGoodsEarn', align: 'end', sortable: true },
        { text: 'Total services', value: 'totalServices', align: 'end', sortable: true },
        { text: 'Total profit - netto', value: 'totalTotalEarn', align: 'end', sortable: true, bold: true },
      )
    }
    if (this.$store.state.language === 'HR') {
      this.topHeader = 'Prodaja ' + this.$store.state.businessYear + ' izvještaj, kreiran: ' + dayjs().format('DD.MM.YYYY HH:MM')
      this.headers.push(
        { text: 'Mjesec', align: 'start', sortable: true, value: 'lang',},
        { text: 'IRE Ukupno', value: 'invoicesTotal', align: 'end', sortable: true, bold:true },
        { text: 'IRE - roba', value: 'invoicesGoods', align: 'end', sortable: true },
        { text: 'IRE - roba zarada', value: 'invoicesGoodsEarn', align: 'end', sortable: true },
        { text: 'IRE - usluge', value: 'invoicesServices', align: 'end', sortable: true },
        { text: 'IRE - uk.zarada netto', value: 'invoicesTotalEarn', align: 'end', sortable: true, bold: true },
        { text: 'POS - ukupno', value: 'posTotal', align: 'end', sortable: true, bold:true },
        { text: 'POS - roba', value: 'posGoods', align: 'end', sortable: true },
        { text: 'POS - roba zarada', value: 'posGoodsEarn', align: 'end', sortable: true },
        { text: 'POS - usluge', value: 'posServices', align: 'end', sortable: true },
        { text: 'POS - uk.zarada netto', value: 'posTotalEarn', align: 'end', sortable: true, bold: true },
        { text: 'Ukupno', value: 'totalTotal', align: 'end', sortable: true, bold:true },
        { text: 'Ukupno - roba', value: 'totalGoods', align: 'end', sortable: true },
        { text: 'Ukupno - roba zarada', value: 'totalGoodsEarn', align: 'end', sortable: true },
        { text: 'Ukupno - usluge', value: 'totalServices', align: 'end', sortable: true },
        { text: 'Ukupno - zarada - netto', value: 'totalTotalEarn', align: 'end', sortable: true, bold: true },
      )
    }
    
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    async sumsales() {
      const monthsHR = ["Siječanj",	"Veljača",	"Ožujak",	"Travanj",	"Svibanj",	"Lipanj",	"Srpanj", "Kolovoz", "Rujan", "Listopad", "Studeni", "Prosinac"]
      const monthsEN = ["January",	"February",	"March",	"April",	"May",	"June",	"July", "August", "September", "October", "November", "December"]
      
      const mmonths = months()
      mmonths.map(mm => {
        let currMon = ''
        if (this.$store.state.language === 'EN') {
          currMon = monthsEN[mm-1]
        }
        if (this.$store.state.language === 'HR') {
          currMon = monthsHR[mm-1]
        }
        const m1 = {
          month: mm,
          lang: currMon,
          invoicesTotal: 0,
          invoicesGoods: 0,
          invoicesGoodsEarn: 0,
          invoicesServices: 0,
          invoicesTotalEarn: 0,
          posTotal: 0,
          posGoods: 0,
          posGoodsEarn: 0,
          posServices: 0,
          posTotalEarn: 0,
          totalTotal: 0,
          totalGoods: 0,
          totalGoodsEarn: 0,
          totalServices: 0,
          totalTotalEarn: 0,
        }
        this.fullYear.push(m1)
      })
      const m1 = {
          month: 'TOTAL:',
          lang: 'TOTAL:' + this.$store.state.businessYear,
          invoicesTotal: 0,
          invoicesGoods: 0,
          invoicesGoodsEarn: 0,
          invoicesServices: 0,
          invoicesTotalEarn: 0,
          posTotal: 0,
          posGoods: 0,
          posGoodsEarn: 0,
          posServices: 0,
          posTotalEarn: 0,
          totalTotal: 0,
          totalGoods: 0,
          totalGoodsEarn: 0,
          totalServices: 0,
          totalTotalEarn: 0,
        }
      this.fullYear.push(m1)

      this.TotalServices = 0
      this.TotalNettoServices = 0
      this.TotalDiff = 0
      this.documentAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
      this.documentAna.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : null
      const currYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
      this.documentAna.BusinessYear = currYear
      this.documentAna.docFromDate = dayjs([this.documentAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
      this.documentAna.docToDate = dayjs([this.documentAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
      this.documentAna.documentName = 'InvoiceSales'
      this.typePcs = 2

      await DocumentDetailService.salesana(this.documentAna)
      .then(res => {
        const haveMonth = res.data.document ? res.data.document : []
        if (haveMonth) {
            haveMonth.map(detMonth => {
              const itemMonth = dayjs(detMonth.documentDate, 'YYYY-MM-DD').format('M')
              let totalEarn = 0
              if (detMonth.itemService === true) {
                this.fullYear[itemMonth - 1].invoicesServices += parseFloat(detMonth.itemFullTotal)
                this.fullYear[itemMonth - 1].invoicesNetoServices += parseFloat(detMonth.itemBaseAmountNoVAT)
                this.TotalServices += parseFloat(detMonth.itemFullTotal)
                this.TotalNettoServices += parseFloat(detMonth.itemBaseAmountNoVAT)
                totalEarn += parseFloat(detMonth.itemBaseAmountNoVAT)
              }

              if (detMonth.itemService === false) {
                if (parseFloat(detMonth.itemBaseAmountNoVAT) >= (parseFloat(detMonth.itemPcsNeutral) * parseFloat(detMonth.itemSuppPrice))) {
                  this.TotalDiff += parseFloat(detMonth.itemBaseAmountNoVAT) - (parseFloat(detMonth.itemPcsNeutral) * parseFloat(detMonth.itemSuppPrice))
                  this.fullYear[itemMonth - 1].invoicesGoodsEarn += parseFloat(detMonth.itemBaseAmountNoVAT) - (parseFloat(detMonth.itemPcsNeutral) * parseFloat(detMonth.itemSuppPrice))
                  totalEarn += parseFloat(detMonth.itemBaseAmountNoVAT) - (parseFloat(detMonth.itemPcsNeutral) * parseFloat(detMonth.itemSuppPrice))
                }
              }
              this.fullYear[itemMonth - 1].invoicesTotalEarn += totalEarn

            })
        }
      })
      .catch((err) => {
        console.log(err)
      })
      
      this.documentAna.documentName = 'POS'
      await DocumentDetailService.salesana(this.documentAna)
      .then(res => {
        const haveMonth = res.data.document ? res.data.document : []
        if (haveMonth) {
            haveMonth.map(detMonth => {
              const itemMonth = dayjs(detMonth.documentDate, 'YYYY-MM-DD').format('M')
              let totalEarn = 0
              if (detMonth.itemService === true) {
                this.fullYear[itemMonth - 1].posServices += parseFloat(detMonth.itemFullTotal)
                this.fullYear[itemMonth - 1].posNetoServices += parseFloat(detMonth.itemBaseAmountNoVAT)
                this.TotalServices += parseFloat(detMonth.itemFullTotal)
                this.TotalNettoServices += parseFloat(detMonth.itemBaseAmountNoVAT)
                totalEarn += parseFloat(detMonth.itemBaseAmountNoVAT)
              }

              if (detMonth.itemService === false) {
                if (parseFloat(detMonth.itemBaseAmountNoVAT) >= (parseFloat(detMonth.itemPcsMinus) * parseFloat(detMonth.itemSuppPrice))) {
                  this.TotalDiff += parseFloat(detMonth.itemBaseAmountNoVAT) - (parseFloat(detMonth.itemPcsMinus) * parseFloat(detMonth.itemSuppPrice))
                  this.fullYear[itemMonth - 1].posGoodsEarn += parseFloat(detMonth.itemBaseAmountNoVAT) - (parseFloat(detMonth.itemPcsMinus) * parseFloat(detMonth.itemSuppPrice))
                  totalEarn += parseFloat(detMonth.itemBaseAmountNoVAT) - (parseFloat(detMonth.itemPcsMinus) * parseFloat(detMonth.itemSuppPrice))
                }
              }
              this.fullYear[itemMonth - 1].posTotalEarn += totalEarn
            })
        }
      })
      .catch((err) => {
        console.log(err)
      })

      this.documentAna.documentName = 'InvoiceSales'
      await DocumentService.sumby(this.documentAna)
      .then(res => {
        if (res.data && res.data.documents && res.data.documents.length > 0) {
        
          res.data.documents.map(async(doc)=> {
            let check = dayjs(doc.month, 'YYYY-MM-DD')
            let month = check.format('M')
            this.fullYear[month - 1].invoicesTotal = parseFloat(doc.totalMonth)
          })
        }
      })

      this.documentAna.documentName = 'POS'
      await DocumentService.sumby(this.documentAna)
      .then(res => {
        if (res.data && res.data.documents && res.data.documents.length > 0) {
          res.data.documents.map(doc=> {
            let check = dayjs(doc.month, 'YYYY-MM-DD')
            let month = check.format('M')
            this.fullYear[month - 1].posTotal = parseFloat(doc.totalMonth)
          })
        }
      })

      for(let i = 0; i < 12; i++) {
        this.fullYear[i].invoicesGoods = this.fullYear[i].invoicesTotal - this.fullYear[i].invoicesServices
        this.fullYear[i].posGoods = this.fullYear[i].posTotal - this.fullYear[i].posServices
        this.fullYear[i].totalTotal = this.fullYear[i].invoicesTotal + this.fullYear[i].posTotal
        this.fullYear[i].totalGoods = this.fullYear[i].invoicesGoods + this.fullYear[i].posGoods
        this.fullYear[i].totalGoodsEarn = this.fullYear[i].invoicesGoodsEarn + this.fullYear[i].posGoodsEarn
        this.fullYear[i].totalServices = this.fullYear[i].invoicesServices + this.fullYear[i].posServices
        this.fullYear[i].totalTotalEarn = this.fullYear[i].invoicesTotalEarn  + this.fullYear[i].posTotalEarn

        this.fullYear[12].invoicesTotal += this.fullYear[i].invoicesTotal
        this.fullYear[12].invoicesGoods += this.fullYear[i].invoicesGoods
        this.fullYear[12].invoicesGoodsEarn += this.fullYear[i].invoicesGoodsEarn
        this.fullYear[12].invoicesServices += this.fullYear[i].invoicesServices
        this.fullYear[12].invoicesTotalEarn += this.fullYear[i].invoicesTotalEarn
        this.fullYear[12].posTotal += this.fullYear[i].posTotal
        this.fullYear[12].posGoods += this.fullYear[i].posGoods
        this.fullYear[12].posGoodsEarn += this.fullYear[i].posGoodsEarn
        this.fullYear[12].posServices += this.fullYear[i].posServices
        this.fullYear[12].posTotalEarn += this.fullYear[i].posTotalEarn
        this.fullYear[12].totalTotal += this.fullYear[i].totalTotal
        this.fullYear[12].totalGoods += this.fullYear[i].totalGoods
        this.fullYear[12].totalGoodsEarn += this.fullYear[i].totalGoodsEarn
        this.fullYear[12].totalServices += this.fullYear[i].totalServices
        this.fullYear[12].totalTotalEarn += this.fullYear[i].totalTotalEarn
      }

    },
    downloadFile () {
      const columns = []

      if (this.$store.state.language === 'EN') {
        this.topHeader = 'Sales ' + this.$store.state.businessYear + ' report, created: ' + dayjs().format('DD.MM.YYYY HH:MM')
        columns.push(
          { label: 'Month', value: 'lang',},
          { label: 'Invoices total', value: 'invoicesTotal'},
          { label: 'Invoices - goods', value: 'invoicesGoods' },
          { label: 'Invoices - goods profit', value: 'invoicesGoodsEarn' },
          { label: 'Invoices - services', value: 'invoicesServices' },
          { label: 'Invoices - total profit netto', value: 'invoicesTotalEarn' },
          { label: 'POS - total', value: 'posTotal'},
          { label: 'POS - goods', value: 'posGoods'},
          { label: 'POS - goods profit', value: 'posGoodsEarn' },
          { label: 'POS - services', value: 'posServices' },
          { label: 'POS - total profit netto', value: 'posTotalEarn' },
          { label: 'Total ', value: 'totalTotal' },
          { label: 'Total goods', value: 'totalGoods' },
          { label: 'Total goods profit', value: 'totalGoodsEarn' },
          { label: 'Total services', value: 'totalServices' },
          { label: 'Total profit - netto', value: 'totalTotalEarn' },
        )
      }
      if (this.$store.state.language === 'HR') {
        this.topHeader = 'Prodaja ' + this.$store.state.businessYear + ' izvještaj, kreiran: ' + dayjs().format('DD.MM.YYYY HH:MM')
        columns.push(
          { label: 'Mjesec',  value: 'lang',},
          { label: 'IRE Ukupno', value: 'invoicesTotal' },
          { label: 'IRE - roba', value: 'invoicesGoods' },
          { label: 'IRE - roba zarada', value: 'invoicesGoodsEarn'},
          { label: 'IRE - usluge', value: 'invoicesServices' },
          { label: 'IRE - uk.zarada netto', value: 'invoicesTotalEarn' },
          { label: 'POS - ukupno', value: 'posTotal' },
          { label: 'POS - roba', value: 'posGoods' },
          { label: 'POS - roba zarada', value: 'posGoodsEarn' },
          { label: 'POS - usluge', value: 'posServices' },
          { label: 'POS - uk.zarada netto', value: 'posTotalEarn' },
          { label: 'Ukupno', value: 'totalTotal' },
          { label: 'Ukupno - roba', value: 'totalGoods' },
          { label: 'Ukupno - roba zarada', value: 'totalGoodsEarn' },
          { label: 'Ukupno - usluge', value: 'totalServices' },
          { label: 'Ukupno - zarada - netto', value: 'totalTotalEarn' },
        )
      }
      let sheetNm = ''
      let fileNm = ''
      const currDate = dayjs().format('DD_MM_YYYY_HH_MM') 
      if (this.$store.state.language === 'EN') {
       sheetNm = 'Sales_' + this.$store.state.businessYear + '_Created_' + currDate
       fileNm = 'Sales_' + this.$store.state.businessYear + '_Created_' + currDate
      }
      if (this.$store.state.language === 'HR') {
       sheetNm = 'Prodaja_' + this.$store.state.businessYear + '_Kreiran_' + currDate
       fileNm = 'Prodaja_' + this.$store.state.businessYear + '_Kreiran_' + currDate
      }

           
      const content = this.fullYear
      const settings = {
        sheetName: sheetNm,
        fileName: fileNm
      }
      xlsx(columns, content, settings)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
