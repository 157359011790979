<template>
  <v-container>
    <v-row no-gutters class="pa-0" v-if="!showDigital">
       <v-col c ols="12" sm="4" md="4" xs="4" lg="4" xl="4">
           <v-btn
          color="blue"
          text
          @click="hideDigital">
          {{lang.DigitalAr}} {{haveDescription}}
        </v-btn>
       </v-col>
    </v-row>
    <div v-if="showDigital">
    <v-progress-circular v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>

    <v-row no-gutters justify="center" class="pa-0">
      <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4">
         <v-text-field
            :label="lang.ShortDesc"
            v-model="desc"
          ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
        <v-file-input v-model="somefile" single label="File input"></v-file-input>
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
        <v-btn
          color="blue"
          text
          @click="onSubmit">
          Upload
        </v-btn>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col cols="12" sm="12" class="pa-0"> 
        <v-data-table
          dense
          :headers="headers"
          :items="items"
          item-key="newId"
          class="elevation-1"
          @click:row="showPdf"
          :mobile-breakpoint="0" 
        >
          <template v-slot:[`item.LastUpdate`]="{ item }">
              <span>{{ localDate(item.LastUpdate) }}</span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-trash-can
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row> 
   </div>
 </v-container>
</template>

<script>
import DigitalDocService from '@/services/DigitalDocService'
import MainLedgerService from '@/services/MainLedgerService'
import Api from '@/services/Api'
import {defDigitalDoc, defNewDigitalDoc } from './DigitalDocHelper'
import langEn from './digitaldocDescEn'
import langHr from './digitaldocDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dayjs from 'dayjs'
 
export default {
  name: 'digitaldocshortlist',
  data () {
    return {
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      somefile: null,
      errors: null,
      data: null,
      attachment : { name : null,file: null },
      documentName: '',
      documentNameLoc: '',
      docside: 0,
      msg: '',
      items: [],
      tmp: '',
      search: '',
      headers: [ ],
      mainQuery: {},
      lang: {},
      langC: {},
      saving: false,
      searchName: "",
      havealert: false,
      havemssgalert: '',
      query: {},
      desc: '',
      haveItems: 0,
      showDigital: false,
      haveDescription: '',
      doDelete: false,
    }
  },
  async beforemounted() {
    await this.defaultInit()
   
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = langEn
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = langHr
      this.langC = commHr;
    }
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc

    this.headers.push(
      {
        text: this.lang.Desc, align: 'left', sortable: true, value: 'Description', width: '130px'
      },
      { text: this.lang.Name, align: 'left', sortable: true, value: 'originalname', width: '100px' },
      { text: this.lang.Saved , align: 'left', sortable: true, value: 'LastUpdate', width: '100px' },
      { text: this.lang.Actions, value: 'action', sortable: false, width: '10px' },
    )
    this.defaultInit()
  },
  methods: {
    localDate(dateToFormat) {
      return dayjs(dateToFormat).format('DD.MM.YYYY HH:MM:SS')
    },
    hideDigital () {
      this.showDigital = !this.showDigital
    },  
    async defaultInit() {
      const emptyObj = {}
      this.query = {...emptyObj}
      this.query.CompanyId = this.$store.state.companyid
      this.query.BusinessYear = this.$store.state.businessYear
      this.query.documentSide = this.$store.state.documentSide
      this.query.productiontypes = this.$store.state.productiontype
      this.query.documentName = this.$store.state.documentActivHead.documentName
      this.query.documentNameLoc = this.$store.state.documentActivLoc
      this.query.documentIdGeneral = this.$store.state.documentActivHead.documentIdGeneral

      this.saving = true
      await DigitalDocService.index(this.query)
      .then(res => {
        this.items = res.data.digitaldocs ? res.data.digitaldocs : []
        this.haveItems = this.items.length
        this.haveDescription = this.haveItems > 0 ? '(' + this.lang.Docs + ': ' + this.haveItems.toString() + ' )' : '(' + this.lang.Empty + ')'
      })
      .catch(err => {
        this.havealert = !this.havealert
        this.havemssgalert = 'Error' + err
      })
      this.saving = false
      
    },
    async initAgain () {
      this.defaultInit()
    },
    onSubmit() {
      const newId = this.getRndInteger(100000, 900000)
      let fd = new FormData()
      fd.append('companyId', this.$store.state.companyid)
      fd.append('newId', newId)
      fd.append('originalName', this.somefile.name)
      fd.append('typeDoc', this.$store.state.documentActivHead.documentName)
      fd.append('myFile', this.somefile, this.somefile.name)
      Api().post('bookin/invoice', fd).then(response => {
          this.data = response.data
            if (this.data) {
              const newD = defDigitalDoc()
              const newDD = {...newD, ...defNewDigitalDoc()}
              newDD.originalname = this.data.originalname ? this.data.originalname : ''
              newDD.filename = this.data.filename ? this.data.filename : ''
              newDD.destination = this.data.destination ? this.data.destination : ''
              newDD.path = this.data.path ? this.data.path : ''
              newDD.typeDoc = this.$store.state.documentActivHead.documentName ? this.$store.state.documentActivHead.documentName : ''
              newDD.documentIdGeneral = this.query.documentIdGeneral ? this.query.documentIdGeneral : ''
              newDD.documentName = this.query.documentName
              newDD.documentSide = this.query.documentSide
              newDD.documentId = this.$store.state.documentActivHead.documentId ? parseInt(this.$store.state.documentActivHead.documentId) : ''
              newDD.Description = this.desc ? this.desc : ''
              newDD.newId = newId
              this.updateDB(newDD)
              this.somefile = null
              this.desc = ''
          }
      }).catch(error => {
          this.text = 'Error ' + error
          this.color = 'red'
          this.snackbar = true
          if (error.response.status === 422) {
              this.errors = error.response.data.errors || {};
          }
      });
    },
    async updateDB(newDD) {
      try {
        await DigitalDocService.post(newDD).then(res => {
          this.text = this.lang.SavedDoc + res.data.message
          this.color = 'green'
          this.snackbar = true
          this.defaultInit()
        }).catch(error => {
          this.text = 'Error ' + error
          this.color = 'red'
          this.snackbar = true
        })         
      } catch (error) {
        this.text = 'Error ' + error
        this.color = 'red'
        this.snackbar = true
      }
    },
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
    async showPdf(params) {
      if (this.doDelete) {
        return
      }
      if (params.path) {
        const showFile ={
          showFile: params.path
        }
        await MainLedgerService.showPdf(showFile)
        .then(res => {
          const data = res.data
          var byteCharacters = atob(data);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], { type: "application/pdf" });

          //FileSaver.saveAs(blob, "invoice.pdf")

          // // const zoveSe = this.mainledger.MLFree2 ? this.mainledger.MLFree2 : 'Book'
          //const header = res.headers
          //const fileType = header['content-type']
          //const file = new Blob([data], {type: fileType})
          // eslint-disable-next-line
          //console.log(' have file ', file)
          const fileurl = window.URL.createObjectURL(blob)
          window.open(fileurl)
          //FileSaver.saveAs(file, 'invoice.pdf');
        }).catch(error => {
          this.text = 'Error ' + error
          this.color = 'red'
          this.snackbar = true
        })
      } else {
        alert(this.lang.CannotFind)
      }
    },
    async deleteItem (item) {
      try {
        this.doDelete = true
        const warningMessage = item.originalname
        if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!')) {
          const updateItem = {...item}
          updateItem.Deleted = true
          await DigitalDocService.put(updateItem)
          await this.defaultInit()
        }
        this.doDelete = false
      } catch(err) {
        this.error = err
      }
    },
  },
  computed: {

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
