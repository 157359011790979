import Api from '@/services/Api'
// import { log } from 'util';

export default {
  index (query) {
    return Api().get('warehousemark', {
      params: query
    })
  },
  show (warehousemarkId) {
    return Api().get(`warehousemark/${warehousemarkId}`)
  },
  post (newwarehousemark) {
    return Api().post('warehousemark', newwarehousemark)
  },
  put (warehousemark) {
    return Api().put(`warehousemark/${warehousemark.id}`, warehousemark)
  },
  delete (warehousemarkId) {
    return Api().delete(`warehousemark/${warehousemarkId}`)
  },
  choose () {
    return Api().get('warehousemark/choose')
  }
}
