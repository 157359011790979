<template>
<div>
  <v-container fluid grid-list-md>

  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="users"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="50"
    @click:row="navigateTo"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Neoporezivi primici</v-toolbar-title>
         <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

        <v-spacer></v-spacer>
         
          <!-- <v-btn
              color="blue"
              text
              @click="vehicleprnt"
            >
              PRINT
            </v-btn> -->
         

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="800px">  

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">Neoporezivi Primitak</span>
            </v-card-title>

            <v-card-text>
              <v-container class="pa-0 ma-0">
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >

                  <v-row no-gutters>
                     <v-col cols="12" sm="4" md="4" xs="4" lg="12" xl="12" class="pa-1">
                     <v-text-field
                        dense
                        label="Opis"
                        v-model="editedItem.Description"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="12" sm="12" md="12" xs="12" lg="2" xl="2" class="pa-1">
                      <v-text-field
                        dense
                        label="Broj"
                        v-model="editedItem.Value"
                        number
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                     <v-col cols="12" sm="6" md="6" xs="6" lg="3" xl="3" class="mr-2">
                      <v-text-field
                        dense
                        label="Mjesečno do €"
                        v-model="editedItem.OnMonth"
                        number
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" xs="6" lg="3" xl="3" class="mr-2">
                        <v-text-field
                        dense
                        label="Godišnje do €"
                        v-model="editedItem.OnYear"
                        number
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" xs="6" lg="3" xl="3">
                        <v-text-field
                        dense
                        label="Jednokratno €"
                        v-model="editedItem.OnOnce"
                        number
                        outlined
                      ></v-text-field>
                    </v-col>

                  </v-row>

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1"  @click="close" class="mr-3">{{langC.Cancel}}</v-btn>
                    <v-btn color="success"  @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      Lista je prazna
    </template>
  </v-data-table>

  </v-container>
  </div>
</template>

<script>
import SalarieTaxFreeService from '@/services/SalarieTaxFreeService'
import { round, parseNum } from '@/snippets/allSnippets'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"

  export default {
    name: 'salarietaxfreeCRUD',
    data: () => ({
      card: {},
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      modal1: false,
      dialog1: false,
      lang: {},
      langm: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {},
      dialog: false,
      headers: [],
      users: [],
      selectPa: {},
      selectDest: {},
      partners: [],
      search: null,
      editedIndex: -1,
      editedItem: { },
      defaultItem: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        UserId: null,
        email: '',
        Description: '',
        Value: 0,
        JoopdCol: 0,
        OnMonth: 0,
        OnYear: 0,
        OnOnce: 0,
        On: 0,
        ForeignCurrency : '',    
        LastUpdateBy: '',
        LastUpdate: null,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: '',
        FreeF5: '',
        FreeF6: '',
        FreeF7: false,
        FreeF8: false
      },
      privitems: [],
      items: [],
      valid: true,
      total: 0
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.Description: this.lang.EditDocDesc
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      'selectDest' (val) {
        if (val) {
          this.editedItem.doc = val.doc
          this.editedItem.docLoc = val.docLoc
        }
        if (!val) {
          this.editedItem.doc = null
          this.editedItem.docLoc = null
        }
      },
    },
    components: {
    },
    async mounted() {
      if (this.$store.state.language === "EN") {
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.BusinessYear = this.$store.state.businessYear
      }

      this.headers.push(
        {text: 'Opis', value: 'Description', width: '900px'},
        {text: 'Broj', value: 'Value', width: '80px'},
        {text: 'Joppd Kol', value: 'JoopdCol', width: '80px'},
        {text: 'Mjesečno do', value: 'OnMonth', width: '80px'},
        {text: 'Godišnje do', value: 'OnYear', width: '80px'},
        {text: 'Jednokratno', value: 'OnOnce', width: '100px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
        {text: this.lang.Actions, value: 'action', sortable: false },
      ) 
      this.initialize()
    },

    created () {
    },

    methods: {
      navigateTo (params) {
        this.$store.dispatch('setVehicleid', params.id)
      },
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      async initialize () {
        try {
          this.selectDest = null
          this.saving = true
          const {data} = await SalarieTaxFreeService.index(this.mainQuery)
          if (data && data.salarieTaxFrees !== undefined){
            this.users = data.salarieTaxFrees
          }
          this.saving = false
          this.errorMatch = false
          this.editedItem = Object.assign({}, this.defaultItem)
        } catch (err) {
          this.error = err
        }
      },
      editItem (item) {
        this.errorMatch = false
        this.selectDest = {}
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.editItem.OnMonth = round(parseNum(this.editItem.OnMonth), 2)
        this.editItem.OnYear = round(parseNum(this.editItem.OnYear), 2)
        this.editItem.OnOnce = round(parseNum(this.editItem.OnOnce), 2)
        if (this.editedItem.doc && this.editedItem.doc.trim().length > 0 ) {    
          this.selectDest = this.items.find(item => (item.doc === this.editedItem.doc))
        }
        this.dialog = true
      },
      async deleteItem (item) {
        try {
          const index = this.users.indexOf(item)
          const warningMessage = item.docsmall + ', ' + item.docLoc 
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.users.splice(index, 1)) {
            await SalarieTaxFreeService.delete(item.id)
            this.initialize()
          }
        } catch(err) {
          this.error = err
        }
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      checkMatch () {
        if (this.editedItem.password && this.confpassword &&  this.confpassword === this.editedItem.password) {
          this.errorMatch = null
          this.save()
        } else {
          this.errorMatch = 'Error. !'
          this.valid = false        
        }
      },

      async save () {
        try {
           let doneOK = false
           if (this.editedIndex > -1) {
              if (!this.selectDest) {
                this.editedItem.doc = null
                this.editedItem.docLoc = null
              }
              this.editedItem.LastUpdateBy = this.$store.state.user.email ? this.$store.state.user.email : 'test@cegrupa.com'
              this.editedItem.LastUpdate = new Date()
              await SalarieTaxFreeService.put(this.editedItem)
              doneOK = true
            } else {
              this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
              this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              this.editedItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear  : 2022
              this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              this.editedItem.email = this.$store.state.user.email ? this.$store.state.user.email : 'test@cegrupa.com'
              this.editedItem.First = this.$store.state.user.First ? this.$store.state.user.First : 'NoFirst'
              this.editedItem.Last = this.$store.state.user.Last ? this.$store.state.user.Last : 'NoLast'
              await SalarieTaxFreeService.post(this.editedItem)
              doneOK = true
            }
            if (doneOK) {
              this.initialize()
              this.close()
            }
        } catch(err) {
          this.error = err
          
        }
       
      },

      clearPartner() {
        this.selectDest = null
      },
    },
  }
</script>