import Api from '@/services/Api'

export default {
  index (search) {
    return Api().get('account3', {
      params: {
        search: search
      }
    })
  },
  choose (query) {
    return Api().get('account3/choose', {
      params: query
    })
  },
  show (account3Id) {
    return Api().get(`account3/${account3Id}`)
  },
  post (newaccount3) {
    return Api().post('account3', newaccount3)
  },
  put (account3) {
    return Api().put(`account3/${account3.id}`, account3)
  },
  delete (account3Id) {
    return Api().delete(`account3/${account3Id}`)
  }
}
