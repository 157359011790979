<template>
  <v-card
    class="mx-auto"
    max-width="500"
  >
    <v-toolbar
      :color="hColor"
      dark
    >
      <v-toolbar-title>{{hHeader}}</v-toolbar-title>
      <v-spacer></v-spacer>

    </v-toolbar>

    <v-list two-line>
      <v-list-item-group
        v-model="selected"
        color="blue"
      >
        <template v-for="item in items" >
          <v-list-item :key="item.id" @click="navigateTo(item)">


              <v-list-item-content>

                <v-list-item-title text="item.text"></v-list-item-title>

                <v-list-item-subtitle
                  class="text--primary"
                >{{item.partnerName}}</v-list-item-subtitle>

                <v-list-item-subtitle>{{item.documentDescription4}}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <span>{{item.documentDate}}</span>
                <v-list-item-action-text ></v-list-item-action-text>
              </v-list-item-action>
          </v-list-item>
          <v-divider :key="parseInt(item.documentId) + 123456789"></v-divider>

        </template>
      </v-list-item-group>
    </v-list>
  </v-card>

</template>

<script>
import DocumentService from '@/services/DocumentService'
import DocumentDetailService from '@/services/DocumentDetailService'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dateFormat from '@/snippets/dateFormat'
// import moneyFormat from '@/snippets/moneyFormat'

export default {
  name: 'documentproductionlist',
  props: ['hColor', 'hHeader', 'productiontype'],
  data () {
    return {
      msg: '',
      saving: false,
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {},
      selected: [2],
      items: [],
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    this.defaultInit()

  },
  methods: {
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
    async defaultInit() {
      this.documentName = null
      this.documentNameLoc = null
      this.items.length = 0
      this.headers.length = 0
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
      this.mainQuery.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
      this.mainQuery.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : null
   
      if (this.productiontype) {
          this.mainQuery.productiontype = this.productiontype
      }
      this.$store.dispatch('seteditItem', 0)
      this.saving = true
      await DocumentService.index(this.mainQuery).then(res => {
        this.items = res.data.documents ? res.data.documents : []
      })
      .catch(err => {
        this.havealert = !this.havealert
        this.havemssgalert = 'Error' + err
      })
      this.saving = false
    },
    async navigateTo (params) {
      try {                
        const dbDocument = await DocumentService.show(params.id)
        if (dbDocument) {
          this.$store.dispatch('setDocumentActivHead', dbDocument.data.document)
        } else {
          this.$store.dispatch('setDocumentActivHead', {})
        }
        this.$store.dispatch('setDocumentid', params.id)
        this.$store.dispatch('setDatabaseDocID', params.id)
        this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
        this.mainQuery.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
        this.mainQuery.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : null
        this.mainQuery.databaseDocID = this.$store.state.databaseDocID ? this.$store.state.databaseDocID : null
        this.docSide = this.$store.state.documentSide ? this.docSide = this.$store.state.documentSide : null
        const { data } = await DocumentDetailService.index(this.mainQuery)
        if (data.documentDetails && data.documentDetails.length > 0) {
          this.$store.dispatch('setDocumentActivItems', data.documentDetails)
        } else {
          this.$store.dispatch('setDocumentActivItems', [])
        }

        this.$store.dispatch('setprintSmall', false)
        this.$store.dispatch('setprintNoPrice', false)
        this.$router.push({name: 'documentview3'})
        
      } catch (err) {
        // eslint-disable-next-line
        console.log('error read from database', err)
      }      
    },

  },
  computed: {

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
