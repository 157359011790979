<template>
<div>
  <v-container fluid grid-list-md>
    <app-account-choose></app-account-choose>
    <app-Account2-CRUD></app-Account2-CRUD>
  </v-container>
  </div>
</template>

<script>
import Account2CRUD from './Account2CRUD'
import AccountChoose from './AccountChoose'

export default {
  name: 'account2',
  data () {
    return {
      msg: ''
    }
  },
  mounted () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  },
  components: {
    appAccount2CRUD: Account2CRUD,
    appAccountChoose: AccountChoose
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
