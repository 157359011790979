const ledgerHR = {
  id: 'DB id',
  CompanyName: 'Tvrtka',
  BusinessYear: 'Poslovna godina',
  User: 'Korisnik',
  AccountID: 'Konto',
  Debit: 'Duguje',
  Credit: 'Potražuje',
  DebitFr: 'Duguje ino',
  CreditFr: 'Potražuje ino',
  CreditDebit: 'Saldo',
  VAT: 'PDV %',
  Description: 'Opis',
  DefaultAccount: 'Default konto',
  Saved: 'Knjiženje uspješno snimljeno u bazu.',
  ErrorMounted: ' Greška, ne mogu pročitati podatke iz baze...',
  ErrorEdit: 'Greška, ne mogu uređivati podatke ...',
  ErrorNewLine: 'Greška, ne mogu otvoriti novi red knjiženja ....',
  ErrorSave: 'Greška, ne mogu spremiti podatke u bazu ...',
  SuccessSave: 'Podaci su uspješno zapisani u bazu.',
  PrimaryCurrency: 'Primarna valuta',
  RequiredF: 'Ovo polje je obavezno',
  ErrorG: 'Greška! Nedostaju neka obavezna polja',
  StatusNew: 'Nova stavka',
  StatusEdit: 'Uređujem stavku',
  Account1: 'Razred',
  Account1s: 'Razredi',
  NewAccount1: 'Novi Razred',
  EditAccount1: 'Uredi Razred',
  Account1sEmpty: 'Tabela Razreda je prazna',
  Account1Description: 'Naziv Razreda',
  Account1Description2: 'Naziv Razreda Engleski',
  Account2: 'Grupa',
  Account2s: 'Grupe',
  NewAccount2: 'Nova Grupa (od 00 do 99)',
  EditAccount2: 'Uredi Grupu',
  Account2sEmpty: 'Tabela Grupa je prazna',
  Account2Description: 'Naziv Grupe',
  Account2Description2: 'Naziv Grupe Engleski',
  Account3: 'Sintetika',
  Account3s: 'Sintetika',
  NewAccount3: 'Nova Sintetika (od 000 do 999)',
  EditAccount3: 'Uredi Sintetiku',
  Account3sEmpty: 'Tabela Sintetike je prazna',
  Account3Description: 'Naziv Sintetike',
  Account3Description2: 'Naziv Sintetike Engleski',
  Account4: 'Konto',
  Account4s: 'Konta',
  NewAccount4: 'Novi Konto (4 do 6 brojeva)',
  EditAccount4: 'Uredi konto',
  Account4sEmpty: 'Tabela konta je prazna',
  Account4Description: 'Konto naziv',
  Account4Description2: 'Konto naziv engleski',
  BuyerSupplierAccount: '',
  Supplier: 'Dobavljač',
  Buyer: 'Kupac',
  SuppBookInv: 'Knjga URA',
  BookType: 'Vrsta knjige URA(1-4)',
  BookColumn: 'Kolona u knjizi:',
  VATClaim: 'PDV % može se odbiti',
  VATCantClaim: 'PDV % NE može se odbiti',
  BuyerBook: 'Knjga IRA',
  BookType2: 'Vrsta knjige IRA(1-4)',
  EUData: 'EU podaci',
  EUBookType: 'EU Vrsta',
  EUColumn: 'EU broj u vrsti',
  EUVATClaim: 'EU PDV % može se odbiti',
  EUVATCantClaim: 'EU PDV % NE može se odbiti',
  EUBookS: 'EU Stjecanje',
  EUVATRefound: 'EU ZP',
  EUPPO: 'EU PPO',
  BookID: 'Broj URE',
  BookIDml: 'Broj URE',
  BookID2: 'Broj IRE',
  BookIDml2: 'Broj IRE',
  BookBase: 'Broj Temeljnice',
  ClosedAccount: 'Proknjižen',
  BookTypeID: 'Vrsta knjige',
  BookingName: 'Naziv knjige',
  BookingDate: 'Datum knjiž.',
  InvoiceNumber: 'Broj računa',
  InvoiceNumber2: 'Broj orig.računa',
  InvoiceNumberml: 'Broj IRE',
  InvoiceDate: 'Datum računa',
  InvoiceAmount: 'Iznos računa',
  HomeCurrency: 'Dom.Valuta',
  InvoiceForeginAmount: 'Iznos strana valuta',
  ForeignCurrency: 'Strana valuta',
  PartnerName: 'Partner',
  PartnerVATID: 'Partner OIB',
  PartnerList: 'Baza partnera',
  PartnerOpen: 'Otvorene stavke',
  PartnerID: 'Partner Šifra',
  InvoicePaymentDeadlineDate: 'Rok plaćanja',
  VATPer: 'PDV %',
  MLFree1: 'Interni opis',
  MLFree2: 'Sken',
  DebitTotal: 'Duguje UK',
  CreditTotal: 'Potrazuje UK',
  MainLedgerAnalysis: 'Glavna knjiga analiza',
  DeleteItem: 'Da li ste sigurni da želite obrisati knjiženje?',
  DeleteItemMsg: 'Ako niste sigurni, kontaktirajte asministratora. Hvala.',
  SyncBooks: 'Uskladi IRE i Knjigu IRA',
  BookTypeNotSelected: 'NIJE odabrana vrsta knjige! NE možete upisati novi dokument!',
  DocumentType: 'Vrsta dokumenta',
  SelectPartner: 'Odaberi partnera',
  BookInInvoices: 'Knjiga URA',
  BookOutInvoices: 'Knjiga IRA',
  partnerName: 'Partner naziv',
  VAT3Base: '5% osnovica',
  VAT2Base: '13% osnovica',
  VAT1Base: '25% osnovica',
  VAT3Amount: '5% porez',
  VAT2Amount: '13% porez',
  VAT1Amount: '25% porez',
  AmountVAT: 'PDV iznos',
  VAT3CanUse: '5% može odbiti',
  VAT3CanTUse: '5% ne može odbiti',
  VAT2CanUse: '13% može odbiti',
  VAT2CanTUse: '13% ne može odbiti',
  VAT1CanUse: '25% može odbiti',
  VAT1CanTUse: '25% ne može odbiti',
  VAT4Per: 'Tuzemni prijenos porezne obveze',
  VAT4Base: 'Isporuke dobara u drugim državama članicama',
  VAT4Amount: 'Isporuke dobara unutar EU',
  VAT4PerCanUse: 'Obavljene usluge unutar EU',
  VAT4CanUse: 'Obavljene isporuke osobama bez sjedišta u RH',
  VAT4CanTUse: 'Sastavljanje i postavljanje dobara u drugoj državi članici',
  VAT5Per: 'Isporuke NPS u EU',
  VAT5Base: 'U tuzemstvu',
  VAT5Amount: 'Izvozne isporuke',
  VAT5PerCanUse: 'Ostala oslobođenja',
  VATID: 'OIB: ',
  VATStatements: 'PDV obrasci',
  VATStatement: 'PDV obrazac',
  Branch: 'NADLEŽNA ISPOSTAVA POREZNE UPRAVE',
  Podatak000: 'OBRAČUN PDV-a – UKUPNO',
  Podatak100: 'TRANSAKCIJE OSLOBOĐENE - UKUPNO',
  Podatak101: 'ISPORUKE U RH ',
  Podatak102: 'ISPORUKE DRUGIM DRŽAVAMA ČLANICAMA',
  Podatak103: 'ISPORUKE DOBARA UNUTAR EU',
  Podatak104: 'OBAVLJENE USLUGE UNUTAR EU',
  Podatak105: 'OBAVLJENE ISPORUKE OSOBAMA BEZ SJEDIŠTA U RH',
  Podatak106: 'SASTAVLJANJE I POSTAVLJANJE DOBARA U DRUGOJ DRŽAVI EU',
  Podatak107: 'ISPORUKE NOVIH PRIJEVOZNIH SREDSTAVA U EU',
  Podatak108: 'TUZEMNE ISPORUKE',
  Podatak109: 'IZVOZNE ISPORUKE',
  Podatak110: 'OSTALA OSLOBOĐENJA',
  Podatak111: 'ISPORUKE PO STOPI 0%',
  Podatak200: 'OPOREZIVE TRANSAKCIJE – UKUPNO',
  Porez200: 'OPOREZIVE TRANSAKCIJE – PDV',
  Podatak201: 'Osnovica 5%',
  Porez201: 'PDV 5%',
  Podatak202: 'Osnovica 13%',
  Porez202: 'PDV 13%',
  Podatak203: 'Osnovica 25%',
  Porez203: 'PDV 25%',
  Podatak204: 'Tuz.prij.por.obv. osn',
  Porez204: 'Tuz.priv.por.obv.PDV',
  Podatak205: 'Stjec.dob.unutar EU 5% osn',
  Porez205: 'Stjec.dob.unutar EU 5% PDV',
  Podatak206: 'Stjec.dob.unutar EU 13% osn',
  Porez206: 'Stjec.dob.unutar EU 13% PDV',
  Podatak207: 'Stjec.dob.unutar EU 25% osn',
  Porez207: 'Stjec.dob.unutar EU PDV',
  Podatak208: 'Prim.usl.iz EU 5% osn',
  Porez208: 'Prim.usl.iz EU 5% PDV',
  Podatak209: 'Prim.usl.iz EU 13% osn',
  Porez209: 'Prim.usl.iz EU 13% PDV',
  Podatak210: 'Prim.usl.iz EU 25% osn',
  Porez210: 'Prim.usl.iz EU 25% PDV',
  Podatak211: 'Bez sjed.u RH 5% osn',
  Porez211: 'Bez sjed.u RH 5% PDV',
  Podatak212: 'Bez sjed.u RH 13% osn',
  Porez212: 'Bez sjed.u RH 13% PDV',
  Podatak213: 'Bez sjed.u RH 25% osn',
  Porez213: 'Bez sjed.u RH 25% PDV',
  Podatak214: 'Nak.osl.izvoza osn',
  Porez214: 'Nak.osl.izvoza PDV',
  Podatak215: 'Obr.PDV pri uvozu osn',
  Porez215: 'Obr.PDV pri uvozu PDV',
  Podatak300: 'Pretporez UKUPNO osn',
  Porez300: 'Pretporez UKUPNO PDV',
  Podatak301: 'Pretp.isporuke TU 5% osn',
  Porez301: 'Pretp.isporuke TU 5% PDV',
  Podatak302: 'Pretp.isporuke TU 13% osn',
  Porez302: 'Pretp.isporuke TU 13% PDV',
  Podatak303: 'Pretp.isporuke TU 25% osn',
  Porez303: 'Pretp.isporuke TU 25% PDV',
  Podatak304: 'TU prijenos por.ob. osn',
  Porez304: 'TU prijenos por.ob.PDV',
  Podatak305: 'Stjec.dob. EU 5% osn',
  Porez305: 'Stjec.dob. EU 5% PDV',
  Podatak306: 'Stjec.dob. EU 13% osn',
  Porez306: 'Stjec.dob. EU 13% PDV',
  Podatak307: 'Stjec.dob. EU 25% osn',
  Porez307: 'Stjec.dob. EU 25% PDV',
  Podatak308: 'Stjec.usl.EU 5% osn',
  Porez308: 'Stjec.usl.EU 5% PDV',
  Podatak309: 'Stjec.usl.EU 13% osn',
  Porez309: 'Stjec.usl.EU 13% PDV',
  Podatak310: 'Stjec.usl.EU 25% osn',
  Porez310: 'Stjec.usl.EU 25% PDV',
  Podatak311: 'Dob.i usl.bez sj. 5% osn',
  Porez311: 'Dob.i usl.bez sj. 5% PDV',
  Podatak312: 'Dob.i usl.bez sj. 13% osn',
  Porez312: 'Dob.i usl.bez sj. 13% PDV',
  Podatak313: 'Dob.i usl.bez sj. 25% osn',
  Porez313: 'Dob.i usl.bez sj. 25% PDV',
  Podatak314: 'Pretp.uvoz osnovica',
  Porez314: 'Pretp.uvoz PDV',
  Podatak315: 'Ispravci pretporeza',
  Podatak400: 'Obveza PDVa',
  Podatak500: 'Po preth.obr.',
  Podatak600: 'Ukupno razlika',
  Podatak700: 'Razmjeni odbitak',
  Podatak810: 'Za Ispravak',
  Podatak811: 'Nab.nekretnina',
  Podatak812: 'Nab.os.auto',
  Podatak813: 'Prod.os.auto',
  Podatak814: 'Nab.dug.imo',
  Podatak815: 'Prod.dug.imo',
  Podatak820: 'Ot./stjec.gosp.cjel.',
  Podatak830: 'Isp.nekretnina',
  Podatak831: 'Isp.cl.40.st.1',
  Broj831: 'Broj.nek',
  Podatak832: 'Isp.cl.40.st.4',
  Broj832: 'Broj.nek',
  Podatak833: 'Isp.oporezivo',
  Broj833: 'Broj.nek',
  Podatak840: 'Uk.prim.usl',
  Podatak850: 'Uk.ob.usl',
  Podatak860: 'Prim.dobra',
  Podatak871: 'Post.opo.nap.nak',
  Podatak872: '',
  Podatak873: '',
  Povrat1: 'Povrat',
  Povrat2: 'Povrat',
  Predujam1: 'Predujam',
  Predujam2: 'Predujam',
  UstupPovrata1: 'Ustup',
  UstupPovrata2: 'Ustup',
  Podatak000d: 'OBRAČUN PDV-a U OBAVLJENIM TRANSAKCIJAMA DOBARA I USLUGA – UKUPNO (I. + II.)',
  Podatak100d: 'I. TRANSAKCIJE KOJE NE PODLIJEŽU OPOREZIVANJU I OSLOBOĐENE - UKUPNO (1.+2.+3.+4.+5.+6.+7.+8.+9.+10.+11.)',
  Podatak101d: '1. ISPORUKE U RH ZA KOJE PDV OBRAČUNAVA PRIMATELJ (tuzemni prijenos porezne obveze) ',
  Podatak102d: '2. ISPORUKE DOBARA OBAVLJENE U DRUGIM DRŽAVAMA ČLANICAMA',
  Podatak103d: '3. ISPORUKE DOBARA UNUTAR EU',
  Podatak104d: '4. OBAVLJENE USLUGE UNUTAR EU',
  Podatak105d: '5. OBAVLJENE ISPORUKE OSOBAMA BEZ SJEDIŠTA U RH',
  Podatak106d: '6. SASTAVLJANJE I POSTAVLJANJE DOBARA U DRUGOJ DRŽAVI ČLANICI EU',
  Podatak107d: '7. ISPORUKE NOVIH PRIJEVOZNIH SREDSTAVA U EU',
  Podatak108d: '8. TUZEMNE ISPORUKE',
  Podatak109d: '9. IZVOZNE ISPORUKE',
  Podatak110d: '10. OSTALA OSLOBOĐENJA',
  Podatak111d: '11. ISPORUKE PO STOPI 0%',
  Podatak200d: 'II. OPOREZIVE TRANSAKCIJE – UKUPNO (1.+2.+3.+4.+5.+6.+7.+8.+9.+10.+11.+12.+13.+14.+15.)',
  Podatak201d: '1. ISPORUKE DOBARA I USLUGA U RH po stopi 5%',
  Podatak202d: '2. ISPORUKE DOBARA I USLUGA U RH po stopi 13%',
  Podatak203d: '3. ISPORUKE DOBARA I USLUGA U RH po stopi 25%',
  Podatak204d: '4. PRIMLJENE ISPORUKE U RH ZA KOJE PDV OBRAČUNAVA PRIMATELJ (tuzemni prijenos porezne obveze)',
  Podatak205d: '5. STJECANJE DOBARA UNUTAR EU po stopi 5%',
  Podatak206d: '6. STJECANJE DOBARA UNUTAR EU po stopi 13%',
  Podatak207d: '7. STJECANJE DOBARA UNUTAR EU po stopi 25%',
  Podatak208d: '8. PRIMLJENE USLUGE IZ EU po stopi 5%',
  Podatak209d: '9. PRIMLJENE USLUGE IZ EU po stopi 13%',
  Podatak210d: '10. PRIMLJENE USLUGE IZ EU po stopi 25%',
  Podatak211d: '11. PRIMLJENE ISPORUKE DOBARA I USLUGA OD POREZNIH OBVEZNIKA BEZ SJEDIŠTA U RH po stopi 5%',
  Podatak212d: '12. PRIMLJENE ISPORUKE DOBARA I USLUGA OD POREZNIH OBVEZNIKA BEZ SJEDIŠTA U RH po stopi 13%',
  Podatak213d: '13. PRIMLJENE ISPORUKE DOBARA I USLUGA OD POREZNIH OBVEZNIKA BEZ SJEDIŠTA U RH po stopi 25%',
  Podatak214d: '14. NAKNADNO OSLOBOĐENJE IZVOZA U OKVIRU OSOBNOG PUTNIČKOG PROMETA',
  Podatak215d: '15. OBRAČUNANI PDV PRI UVOZU',
  Podatak300d: 'III. OBRAČUNANI PRETPOREZ - UKUPNO(1.+2.+3.+4.+5.+6.+7.+8.+9.+10.+11.+12.+13.+14.+15.)',
  Podatak301d: '1. PRETPOREZ OD PRIMLJENIH ISPORUKA U TUZEMSTVU po stopi 5%',
  Podatak302d: '2. PRETPOREZ OD PRIMLJENIH ISPORUKA U TUZEMSTVU po stopi 13%',
  Podatak303d: '3. PRETPOREZ OD PRIMLJENIH ISPORUKA U TUZEMSTVU po stopi 25%',
  Podatak304d: '4. PRETPOREZ OD PRIMLJENIH ISPORUKA U RH ZA KOJE PDV OBRAČUNAVA PRIMATELJ (tuzemni prijenos porezne obveze)',
  Podatak305d: '5. PRETPOREZ OD STJECANJA DOBARA UNUTAR EU po stopi 5%',
  Podatak306d: '6. PRETPOREZ OD STJECANJA DOBARA UNUTAR EU po stopi 13%',
  Podatak307d: '7. PRETPOREZ OD STJECANJA DOBARA UNUTAR EU po stopi 25%',
  Podatak308d: '8. PRETPOREZ OD PRIMLJENIH USLUGA IZ EU po stopi 5%',
  Podatak309d: '9. PRETPOREZ OD PRIMLJENIH USLUGA IZ EU po stopi 13%',
  Podatak310d: '10. PRETPOREZ OD PRIMLJENIH USLUGA IZ EU po stopi 25%',
  Podatak311d: '11. PRETPOREZ OD PRIMLJENIH ISPORUKA DOBARA I USLUGA OD POREZNIH OBVEZNIKA BEZ SJEDIŠTA U RH po stopi 5%',
  Podatak312d: '12. PRETPOREZ OD PRIMLJENIH ISPORUKA DOBARA I USLUGA OD POREZNIH OBVEZNIKA BEZ SJEDIŠTA U RH po stopi 13%',
  Podatak313d: '13. PRETPOREZ OD PRIMLJENIH ISPORUKA DOBARA I USLUGA OD POREZNIH OBVEZNIKA BEZ SJEDIŠTA U RH po stopi 25%',
  Podatak314d: '14. PRETPOREZ PRI UVOZU',
  Podatak315d: '15. ISPRAVCI PRETPOREZA',
  Podatak400d: 'IV. OBVEZA PDV-a U OBRAČUNSKOM RAZDOBLJU: ZAUPLATU(II.-III.)ILIZAPOVRAT(III.-II.)',
  Podatak500d: 'V. PO PRETHODNOM OBRAČUNU: NEUPLAĆENI PDV DO DANA PODNOŠENJA OVE PRIJAVE – VIŠE UPLAĆENO – POREZNI KREDIT',
  Podatak600d: 'VI. UKUPNO RAZLIKA: ZA UPLATU/ZA POVRAT',
  Podatak700d: 'VII. IZNOS GODIŠNJEG RAZMJERNOG ODBITKA PRETPOREZA (%)',
  Podatak800d: 'VIII. OSTALI PODACI',
  Podatak810d: '1. ZA ISPRAVAK PRETPOREZA (UKUPNO 1.1.+1.2.+1.3.+1.4.+1.5.)',
  Podatak811d: '1.1. NABAVA NEKRETNINA',
  Podatak812d: '1.2. NABAVA OSOBNIH AUTOMOBILA I DRUGIH SREDSTAVA ZA OSOBNI PRIJEVOZ',
  Podatak813d: '1.3. PRODAJA OSOBNIH AUTOMOBILA I DRUGIH SREDSTAVA ZA OSOBNI PRIJEVOZ',
  Podatak814d: '1.4. NABAVA OSTALE DUGOTRAJNE IMOVINE',
  Podatak815d: '1.5. PRODAJA OSTALE DUGOTRAJNE IMOVINE',
  Podatak820d: '2. OTUĐENJE/STJECANJE GOSPODARSKE CJELINE ILI POGONA',
  Podatak830d: '3. ISPORUKA NEKRETNINA',
  Podatak831d: '3.1. ISPORUKA IZ ČLANKA 40. STAVKA 1. TOČAKA J) I K) ZAKONA',
  Podatak832d: '3.2. ISPORUKA IZ ČLANKA 40. STAVKA 4. ZAKONA',
  Podatak833d: '3.3. ISPORUKA NEKRETNINA KOJE SU OPOREZIVE PDV-om',
  Podatak840d: '4. UKUPNO PRIMLJENE USLUGE OD POREZNIH OBVEZNIKA BEZ SJEDIŠTA U RH (EU + TREĆE ZEMLJE)',
  Podatak850d: '5. UKUPNO OBAVLJENE USLUGE POREZNIM OBVEZNICIMABEZ SJEDIŠTA U RH (EU + TREĆE ZEMLJE)',
  Podatak860d: '6. PRIMLJENA DOBRA IZ EU U OKVIRU TROSTRANOG POSLA',
  Podatak871d: '7. POSTUPAK OPOREZIVANJA PREMA NAPLAĆENIM NAKNADAMA',
  Podatak872d: '',
  Podatak873d: '',
  Podatak610: '1. ZA ISPRAVAK PRETPOREZA (UKUPNO 1.1.+1.2.+1.3.+1.4.+1.5.)',
  Podatak611: '1.1. NABAVA NEKRETNINA',
  Podatak612: '1.2. NABAVA OSOBNIH AUTOMOBILA I DRUGIH SREDSTAVA ZA OSOBNI PRIJEVOZ',
  Podatak613: '1.3. PRODAJA OSOBNIH AUTOMOBILA I DRUGIH SREDSTAVA ZA OSOBNI PRIJEVOZ',
  Podatak614: '1.4. NABAVA OSTALE DUGOTRAJNE IMOVINE',
  Podatak615: '1.5. PRODAJA OSTALE DUGOTRAJNE IMOVINE',
  Podatak620: '2. OTUĐENJE/STJECANJE GOSPODARSKE CJELINE ILI POGONA',
  Podatak630: '3. UKUPNO PRIMLJENE USLUGE OD POREZNIH OBVEZNIKA BEZ SJEDIŠTA U RH (EU + TREĆE ZEMLJE)',
  Podatak640: '4. UKUPNO OBAVLJENE USLUGE POREZNIM OBVEZNICIMABEZ SJEDIŠTA U RH (EU + TREĆE ZEMLJE)',
  Podatak650: '5. PRIMLJENA DOBRA IZ EU U OKVIRU TROSTRANOG POSLA',
  Podatak660: '6. POSTUPAK OPOREZIVANJA PREMA NAPLAĆENIM NAKNADAMA',
  PovratIBAN: 'BROJ RAČUNA ZA POVRAT',
  DocPrintMadeBy: 'Ime,prezime:',
  EnterPayment: 'Upiši plaćanja',
  PaidDate: 'Datum uplate',
  PaidHome: 'Plać.iznos',
  PaidFore: 'INO plać.izn',
  PaidClosed: 'Plaćen',
  bankAccountID: 'Šifra žiro računa',
  bankAccount: 'Žiro račun',
  bankStatmentNumber: 'Broj izvoda',
  bankStatmentDate: 'Datum',
  bankStatmentMoneyName: 'Valuta',
  bankStatmentStart: 'Poč.stanje',
  bankStatmentDebit: 'Duguje - Isplata',
  bankStatmentCredit: 'Potražuje - Uplata',
  bankStatmentFinish: 'Završno stanje',
  bankStatment: 'Izvod',
  bankStatments: 'Izvod-detalji',
  bankStatmentDetailNr: 'stavka broj:',
  NewbankStatment: 'Novi Izvod',
  EditbankStatment: 'Uredi Izvod',
  bankStatmentEmpty: 'Table izvoda je prazna',
  bankStatmentDescription: 'Izvod naziv',
  bankStatmentDescription2: 'Izvod naziv engleski',
  partnerBankIBAN: 'IBAN',
  bankReference1: 'Poziv na broj platitelja',
  bankReference2: 'Poziv na broj primatelja',
  ExRate: 'Tečaj',
  invoiceInID: 'Broj URE',
  invoiceOutID: 'Broj IRE',
  invoiceInNumber: 'Broj URE',
  invoiceOutNumber: 'Broj IRE',
  alertError1: 'Greška! Izvod NIJE snimljen na server.',
  alertError2: 'Greška! Ne mogu pronaći zadnji broj.',
  alertError3: 'Greška! Ne mogu snimiti izvod.',
  alertError4: 'Greška! U čitanju izvoda.',
  alertOK1: 'OK. Izvod je uspješno učitan.',
  Items: 'stavke',
  BusinessYearInvoice: 'Poslovna godina',
  noticeAdd: 'Dodana je nova stavka na temeljnicu',
  noticeUpdate: 'Stavka na temeljnici je izmijenjena.',
  errorNoAccount: 'Nema konta!',
  errorNoDebitCredit: ' Nema dugovnog ni potraznog iznos!',
  errorZero: ' Duguje i potrazuje su nula!',
  detailList: ' ',
  CreateDetails1: 'Razradi default',
  CreateDetails2: 'Razradi kao zadnju',
  Previous: 'Prethodna',
  PrevItems: 'Stavke na prethodnoj fakturi br.:',
  FromAccount: 'Od Konta',
  ToAccount: 'Do Konta',
  BookTypeAll: 'Vrsta knjige',
  BookTypeSelect: 'Odaberi vrstu knjige',
  detailId: 'Rbr',
  detailDone: 'Prok',
  checkPartnerByIban: 'Provjeri IBAN',
  copyPartnerIban: 'Kopiraj IBAN u Partnera',
  partnerNOTexist: 'Partner sa tim IBAN-om NE postoji u bazi!',
  partnerExist: 'Partner sa tim IBAN-om postoji u bazi!',
  closeBS: 'Zatvori Izvod',
  BSfileError: 'Greška! Nije odabrana datoteka za učitavanje.',
  BSexistError: 'Greška! Izvod s tim brojem je već postoji!.',
  NewLLA: 'Novo osnovno sredstvo',
  EditLLA: 'Izmjeni podatke - osnovno sredstvo',
  llaID: 'Šifra int.',
  llaCode: 'AOP oznaka',
  llaName: 'Naziv os.sredstva',
  llaDocumentSource: 'Dokument nabave',
  llaDocumentSourceDate: 'Datum nabave', 
  llaBuyingValue: 'Nabavna vrijednost', 
  llaBookValue : 'Knjig.vr.pocetak ', 
  llaLasting: 'Vijek trajanja mj.', 
  llaDepreciationPercent: 'Stopa otpisa%', 
  llaDepreciationAmountYear: 'Amo.u godini', 
  llaDepreciationAmountTotal: 'Akomulirana Amo.',
  llaValueYearEnd: 'Sadasnja vr.', 
  llaMark: 'Isključi', 
  llaSellDate: 'Datum prodaje',
  llaRegistrartionDate: 'Datum registracije',
  llaRegistrartionContract: 'Ugovor registracije',
  lla: 'Osnovna sredstva',
  llaStartDate: 'Amo.početak',
  llaEndDate: 'Amo.kraj god.',
  llaPieces: 'Količina',
  llaAcount4: 'Konto OS',
  llaAccountDebit: 'Amo.knt.Duguje',
  llaAccountCredit: 'Amo.knt.Potrazuje',
  llaCard: 'Povijesna kartica',
  PartnerSave: 'Snimi part.',
  Partneremail: 'email *',
  Partneradded: 'Novi partner je snimljen.',
  PrivateAccount: 'Privatni konto',
  AccountError: 'Greska! Konto vec postoji.',
  TestInvoice: 'Test IRE',
  MainLedgerProjectType: 'Projekt',
  MainLedgerProjectTypes: 'Projekti',
  MainLedgerProjectTypeName: 'Naziv projekta',
  MainLedgerProjectTypeEmpty: 'Nema projekata',
  MainLedgerQues1: 'Razlika !!',
  MainLedgerQues2: 'Razlika u iznosu računa i proknjiženom iznosu!!',
  MainLedgerQues3: 'Saldakontni konto greška!',
  MainLedgerQues4: 'Upisali ste saldakontni konto a niste odabrali partnera!',
  Locked: 'Zakljucano',
  LockedDate: 'Zakljucano datuma',
  ConnName: 'Veza',
  ConnId: 'Veza Broj',
  PartnerErrorVAT: 'Partner vec postoji!',
  OpenStateBuyers: 'Otvorene stavke kupci',
  OpenStateSupp: 'Otvorene stavke dobavljači',
  OpenStateBuyers2: 'Otv.Kupci',
  OpenStateSupp2: 'Otv.Dob.',
  OpenStatement: 'Otvorene stavke',
  CardsBuyers: 'Kupci - kartice',
  CardsSupp: 'Dobavljači - kartice',
  CardBuyer: 'Kupac - kartica',
  CardSupp: 'Dobavljač - kartica',
  OpenStatementBuyer: 'Otvorene stavke - kupac',
  OpenStatementSupp: 'Otvorene stavke - dob.',
  LatestBooking: 'Zadnja knjiženja u glavnu knjigu',
  LatestBookIn: 'Ulazni račun:',
  LatestBookOut: 'Izlazni račun:',
  LatestBookBS: 'Izvod banke:',
  LatestNoData: 'Nema knjiženja',
  FinishBalanceSheet: '3.Zatvori bilancu',
  CreateFinish: '4.Napravi zavrsnu temeljnicu',
  CloseYear:'5.Prebaci godinu',
  AllPartnersBankList: 'Svi par.ot.banka',
  Total30: 'Do 30 dana',
  Total60: 'Od 30 do 60 dana',
  Total90: 'Od 60 do 90 dana',
  Total120: 'Od 90 do 120 dana',
  TotalOver: 'Preko 120 dana',
  AllPartnerCard: 'Svi par.kartice',
  FinishYear: 'Zatvori ',
  prepareBuyerSupplier: '1.Pripremi datoteku S/K',
  closeBuyerSupplier: '2.Prebaci SaldaKonte u novu godinu',
  importDOC: 'Ino',
  PartnerErrorVAT3: 'Greška!! OIB nema ispravan broj znakova!',
  ReturnUnsaved: 'Povrat NE snimljenog',
  CopytoNewYear: 'Prebaci u novu poslovnu godinu',
  PayedVAT: 'Obr.PDV-a prema nap.nak.',
  fromDate: 'Od datuma',
  toDate:' Do datuma',
  VATZero:'0% osnovica',
  eInvoice: 'eRačun',
  mark: 'OK',
  diff: 'Razlika',
  partnerNotInVAT:'Nije u sustavu PDVa'
}

export default ledgerHR