<template>
 <v-container fluid class="ma-2">
   <v-row no-gutters justify="center" class="pa-0">
    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">

    <h2>
      <span v-if="this.$store.state.language == 'EN'">
      Partner create
      </span>
      <span v-if="this.$store.state.language == 'HR'">
      Upis partnera
      </span>        
    </h2>

    <v-row>
      <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4">
        <v-card outlined>
          <v-card-title primary class="title py-2">{{lang.BasicData}}</v-card-title>
          <v-card-text>

            <v-row dense>
              <v-flex xs12>
                <v-text-field
                    :label="lang.PartnerName"
                    required
                    :rules="[required]"
                    v-model="partner.partnerName"
                ></v-text-field>
              </v-flex>
            </v-row>
            <v-row dense>
              <v-flex xs12>
                <v-text-field
                  :label="lang.PartnerNameShort"
                  required
                  :rules="[required]"
                  v-model="partner.partnerNameShort"
                ></v-text-field>
              </v-flex>
            </v-row>

            <v-row dense>
                <v-flex xs8 class="pr-2">
                  <v-text-field
                    :label="lang.PartnerStreet"
                    v-model="partner.partnerStreet"
                  ></v-text-field>
                </v-flex>
                <v-flex xs4 class="pr-2">
                  <v-text-field
                    :label="lang.PartnerBuildingNameNumber"
                    v-model="partner.partnerBuildingNameNumber"
                  ></v-text-field>
                </v-flex>
            </v-row>
            <v-row dense>
                <v-flex xs8 class="pr-2">
                  <v-text-field
                    :label="lang.PartnerCity"
                    v-model="partner.partnerCity"
                  ></v-text-field>
                </v-flex>
                <v-flex xs4 class="pr-2">
                  <v-text-field
                    :label="lang.PartnerPostcode"
                    v-model="partner.partnerPostcode"
                  ></v-text-field>
                </v-flex>
            </v-row>

            <v-row dense>
              <v-flex xs6 class="pr-2">            
                <v-text-field
                  :label="lang.PartnerCountyRegion"
                  v-model="partner.partnerCountyRegion"
                ></v-text-field> 
              </v-flex>
              <v-flex xs6>    
                <v-text-field
                  :label="lang.PartnerState"
                  v-model="partner.partnerState"
                ></v-text-field>
              </v-flex>
            </v-row>

            <v-row dense>
              <v-flex xs12> 
                <v-text-field
                  :label="lang.PartnerVATID"
                  v-model="partner.partnerVATID"
                ></v-text-field>
            </v-flex>
            </v-row>
            <v-row dense>
              <v-flex xs12>      
                <v-text-field
                  :label="lang.Partneremail"
                  v-model="partner.partneremail"
                ></v-text-field>
            </v-flex>
            </v-row>
            <v-row dense>
              <v-flex xs12> 
                <v-text-field
                  :label="lang.PartnerPhones"
                  v-model="partner.partnerPhones"
                ></v-text-field>
            </v-flex>
            </v-row>
            <v-row dense>
              <v-flex xs12>        
                <v-text-field
                  :label="lang.PartnerBankName"
                  v-model="partner.partnerBankName"
                ></v-text-field>
            </v-flex>
            </v-row>
            <v-row dense>
              <v-flex xs12>     
                <v-text-field
                  :label="lang.PartnerBankAddress"
                  v-model="partner.partnerBankAddress"
                ></v-text-field>
            </v-flex>
            </v-row>
            <v-row dense>
              <v-flex xs12>       
                <v-text-field
                  :label="lang.PartnerBankIBAN"
                  v-model="partner.partnerBankIBAN"
                ></v-text-field>
            </v-flex>
            </v-row>
            <v-row dense>
              <v-flex xs12> 
                <v-text-field
                  :label="lang.PartnerBankSWIFT"
                  v-model="partner.partnerBankSWIFT"
                ></v-text-field>
            </v-flex>
            </v-row>    

            <br>
            <div class="danger-alert" v-html="error" />
            <div v-if="this.locError">
                <v-layout row>
                  <v-flex xs4>
                    <v-subheader color="red">{{locError}}</v-subheader>
                  </v-flex>
                  <v-flex xs8>

                  </v-flex>
                </v-layout>
            </div>
            <br>
            <v-btn
              dark
              color="blue"
              :disabled="!formIsValid"
              @click="register" class="mr-2">
              {{langC.Save}}
            </v-btn>
            <v-btn
              color="green"
              @click="partnerList">
              {{langC.Back}}
            </v-btn>

          </v-card-text>
        </v-card>
      </v-col>


      <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4" v-if="!privateP">
        <v-card outlined>
          <v-card-title primary class="title py-2">{{lang.PartnerCEO}}</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-flex xs12> 
                  <v-btn text color="primary" @click="showOnDirector()" class="pb-3 pl-0">{{lang.PartnerCEO}} <v-icon v-if="!showDirector">mdi-chevron-down</v-icon><v-icon v-if="showDirector">mdi-chevron-up</v-icon></v-btn>
                </v-flex>
            </v-row>
            <div v-if="showDirector">
            <v-row dense>
              <v-flex xs2 class="pr-2">
                <v-text-field
                  :label="lang.PartnerDirectorTitle"
                  v-model="partner.partnerDirectorTitle"
                ></v-text-field>
              </v-flex>
              <v-flex xs5 class="pr-2">
                <v-text-field
                  :label="lang.PartnerDirectorFirst"
                  v-model="partner.partnerDirectorFirst"
                ></v-text-field> 
                </v-flex>
                <v-flex xs5>
                  <v-text-field
                    :label="lang.PartnerDirectorLast"
                    v-model="partner.partnerDirectorLast"
                  ></v-text-field> 
                </v-flex>  
            </v-row>

            <v-row dense>
              <v-flex xs4 class="pr-2">
                <v-text-field
                  :label="lang.PartnerDirectorFormer"
                  v-model="partner.partnerDirectorFormer"
                ></v-text-field>
              </v-flex>
              <v-flex xs4 class="pr-2">
                <v-text-field
                  :label="lang.PartnerDirectorStateResidence"
                  v-model="partner.partnerDirectorStateResidence"
                ></v-text-field> 
              </v-flex>
              <v-flex xs4 >
                 <v-text-field
                  :label="lang.PartnerDirectorNationality"
                  v-model="partner.partnerDirectorNationality"
                ></v-text-field>
              </v-flex>
            </v-row> 

            <v-row dense>
              <v-flex xs4 class="pr-2">
                <v-text-field
                  :label="lang.PartnerDirectorDateOfBirth"
                  v-model="partner.partnerDirectorDateOfBirth"
                ></v-text-field>
              </v-flex>
              <v-flex xs8 >
                <v-text-field
                  :label="lang.PartnerDirectorBusinessOccupation"
                  v-model="partner.partnerDirectorBusinessOccupation"
                ></v-text-field>
              </v-flex>
            </v-row>

            <v-row dense>
              <v-flex xs8 class="pr-2">
                <v-text-field
                  :label="lang.PartnerDirectorAdressStreet"
                  v-model="partner.partnerDirectorAdressStreet"
                ></v-text-field>
              </v-flex>
              <v-flex xs4 >
                <v-text-field
                  :label="lang.PartnerDirectorAdressBuildingNameNumber"
                  v-model="partner.partnerDirectorAdressBuildingNameNumber"
                ></v-text-field>
              </v-flex>
            </v-row>

            <v-row dense>
              <v-flex xs8 class="pr-2">
                <v-text-field
                  :label="lang.PartnerDirectorCity"
                  v-model="partner.partnerDirectorCity"
                ></v-text-field>
              </v-flex>
              <v-flex xs4 >
                <v-text-field
                  :label="lang.PartnerDirectorPostcode"
                  v-model="partner.partnerDirectorPostcode"
                ></v-text-field>
              </v-flex>
            </v-row>


          <v-row dense>
            <v-flex xs12> 
          <v-text-field
            :label="lang.PartnerDirectorCountyRegion"
            v-model="partner.partnerDirectorCountyRegion"
          ></v-text-field>
            </v-flex>
          </v-row>
          <v-row dense>
            <v-flex xs12> 

          <v-text-field
            :label="lang.PartnerDirectorEmail"
            v-model="partner.partnerDirectorEmail"
          ></v-text-field>
          </v-flex>
          </v-row>
          <v-row dense>
            <v-flex xs12> 
          <v-text-field
            :label="lang.PartnerDirectorPhone"
            v-model="partner.partnerDirectorPhone"
          ></v-text-field>
          </v-flex>
          </v-row>
          <v-row dense>
            <v-flex xs12>       

          <v-text-field
            :label="lang.PartnerDirectorContactOption"
            v-model="partner.partnerDirectorContactOption"
          ></v-text-field>
          </v-flex>
          </v-row>
          </div>

          <v-card-title primary class="title py-2">{{lang.PartnerSecretary}}</v-card-title>

           <v-row dense>
              <v-flex xs12> 
                  <v-btn text color="primary" @click="showOnContact()" class="pb-3 pl-0">{{lang.PartnerSecretary}}<v-icon v-if="!showContact">mdi-chevron-down</v-icon><v-icon v-if="showContact">mdi-chevron-up</v-icon></v-btn>
                </v-flex>
            </v-row>
          <div v-if="showContact">
          <v-row dense>
              <v-flex xs2 class="pr-2">
                <v-text-field
                  :label="lang.PartnerSecretaryTitle"
                  v-model="partner.partnerSecretaryTitle"
                ></v-text-field>
              </v-flex>
              <v-flex xs5 class="pr-2">
                <v-text-field
                  :label="lang.PartnerSecretaryFirst"
                  v-model="partner.partnerSecretaryFirst"
                ></v-text-field>
              </v-flex>
              <v-flex xs5 >
                <v-text-field
                  :label="lang.PartnerSecretaryLast"
                  v-model="partner.partnerSecretaryLast"
                ></v-text-field>
              </v-flex>
          </v-row>

          <v-row dense>
              <v-flex xs4 class="pr-2">
                <v-text-field
                  :label="lang.PartnerSecretaryFormer"
                  v-model="partner.partnerSecretaryFormer"
                ></v-text-field>
              </v-flex>
              <v-flex xs4 class="pr-2">
                <v-text-field
                  :label="lang.PartnerSecretaryStateResidence"
                  v-model="partner.partnerSecretaryStateResidence"
                ></v-text-field>
              </v-flex>
              <v-flex xs4 >
                <v-text-field
                  :label="lang.PartnerSecretaryNationality"
                  v-model="partner.partnerSecretaryNationality"
                ></v-text-field>
              </v-flex>
          </v-row>

          <v-row dense>
              <v-flex xs4 class="pr-2">
                <v-text-field
                  :label="lang.PartnerSecretaryDateOfBirth"
                  v-model="partner.partnerSecretaryDateOfBirth"
                ></v-text-field>
              </v-flex>
              <v-flex xs8 >
                <v-text-field
                  :label="lang.PartnerSecretaryBusinessOccupation"
                  v-model="partner.partnerSecretaryBusinessOccupation"
                ></v-text-field>
              </v-flex>
          </v-row>

          <v-row dense>
              <v-flex xs8 class="pr-2">
                <v-text-field
                  :label="lang.PartnerSecretaryAdressStreet"
                  v-model="partner.partnerSecretaryAdressStreet"
                ></v-text-field> 
              </v-flex>
              <v-flex xs4 >
                 <v-text-field
                  :label="lang.PartnerSecretaryAdressBuildingNameNumber"
                  v-model="partner.partnerSecretaryAdressBuildingNameNumber"
                ></v-text-field>
              </v-flex>
          </v-row>

          <v-row dense>
              <v-flex xs8 class="pr-2">
                 <v-text-field
                  :label="lang.PartnerSecretaryCity"
                  v-model="partner.partnerSecretaryCity"
                ></v-text-field>
              </v-flex>
              <v-flex xs4 >
                <v-text-field
                  :label="lang.PartnerSecretaryPostcode"
                  v-model="partner.partnerSecretaryPostcode"
                ></v-text-field>
              </v-flex>
          </v-row>

          <v-row dense>
            <v-flex xs12> 
              <v-text-field
                :label="lang.PartnerSecretaryCountyRegion"
                v-model="partner.partnerSecretaryCountyRegion"
              ></v-text-field>
          </v-flex>
          </v-row>
          <v-row dense>
            <v-flex xs12> 
              <v-text-field
                :label="lang.PartnerSecretaryEmail"
                v-model="partner.partnerSceretaryEmail"
              ></v-text-field>
          </v-flex>
          </v-row>
          <v-row dense>
            <v-flex xs12> 
              <v-text-field
                :label="lang.PartnerSecretaryPhone"
                v-model="partner.partnerSecretaryPhone"
              ></v-text-field>
          </v-flex>
          </v-row>
          <v-row dense>
            <v-flex xs12> 
              <v-text-field
                :label="lang.PartnerSecretaryContactOption"
                v-model="partner.partnerSecretaryContactOption"
              ></v-text-field>
          </v-flex>
          </v-row>
        </div>
        </v-card-text>
        </v-card>

        </v-col>


        <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4" v-if="!privateP">
          <v-card outlined>
            <v-card-title>{{lang.PartnerSettings}}</v-card-title>
            <v-card-text>
 
            <v-row dense>
              <v-flex xs12> 
                <v-btn text color="primary" @click="showOn1()" class="pb-3 pl-0" >{{lang.PartnerInvoiceMemo}} <v-icon v-if="!show1">mdi-chevron-down</v-icon><v-icon v-if="show1">mdi-chevron-up</v-icon></v-btn>
                <v-textarea
                  :label="lang.PartnerInvoiceMemo"
                  v-model="partner.partnerInvoiceMemo"
                  v-if="show1"
                ></v-textarea>
              </v-flex>
            </v-row>
            <v-row dense>
              <v-flex xs12>
                <v-btn text color="primary" @click="showOn2()" class="pb-3 pl-0" >{{lang.PartnerInvoiceForeginMemo}} <v-icon v-if="!show2">mdi-chevron-down</v-icon><v-icon v-if="show2">mdi-chevron-up</v-icon></v-btn>      
                <v-textarea
                  :label="lang.PartnerInvoiceForeginMemo"
                  v-model="partner.partnerInvoiceForeginMemo"
                   v-if="show2"
                ></v-textarea>
                
                </v-flex>
            </v-row>
            <v-row dense>
              <v-flex xs12>
                <v-btn text color="primary" @click="showOn3()" class="pb-3 pl-0" >{{lang.PartnerDeliveryNoteMemo}} <v-icon v-if="!show3">mdi-chevron-down</v-icon><v-icon v-if="show3">mdi-chevron-up</v-icon></v-btn>       
                  <v-textarea
                    :label="lang.PartnerDeliveryNoteMemo"
                    v-model="partner.partnerDeliveryNoteMemo"
                     v-if="show3"
                  ></v-textarea>
                  
                </v-flex>
            </v-row>
            <v-row dense>
              <v-flex xs12> 
                 <v-btn text color="primary" @click="showOn4()" class="pb-3 pl-0" >{{lang.PartnerDeliveryNoteForeginMemo}} <v-icon v-if="!show4">mdi-chevron-down</v-icon><v-icon v-if="show4">mdi-chevron-up</v-icon></v-btn>
                  <v-textarea
                    :label="lang.PartnerDeliveryNoteForeginMemo"
                    v-model="partner.partnerDeliveryNoteForeginMemo"
                     v-if="show4"
                  ></v-textarea>
                 
                </v-flex>
            </v-row>
            <v-row dense>
              <v-flex xs12> 
                  <v-btn text color="primary" @click="showOn5()" class="pb-3 pl-0" >{{lang.otherSettings}} <v-icon v-if="!show5">mdi-chevron-down</v-icon><v-icon v-if="show5">mdi-chevron-up</v-icon></v-btn>
                </v-flex>
            </v-row>
            <div v-if="show5">
              <v-row dense>
                  <v-flex xs4 class="pr-2">
                    <v-text-field
                      :label="lang.PartnerInvoiceType"
                      v-model="partner.partnerInvoiceType"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4 class="pr-2">
                    <v-text-field
                      :label="lang.PartnerInvoicePlace"
                      v-model="partner.partnerInvoicePlace"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4 >
                    <v-text-field
                      :label="lang.PartnerInvoiceDefDays"
                      v-model="partner.partnerInvoiceDefDays"
                    ></v-text-field>
                  </v-flex>
              </v-row>

              <v-text-field
                :label="lang.PartnerInvoiceHint"
                v-model="partner.partnerInvoiceHint"
              ></v-text-field>        

              <v-row>
                  <v-flex xs4 class="pr-2">
                    <v-text-field
                      :label="lang.PartnerInvoiceSpeed"
                      v-model="partner.partnerInvoiceSpeed"
                    ></v-text-field> 
                  </v-flex>
                  <v-flex xs4 class="pr-2">
                    <v-text-field
                      :label="lang.PartnerDefaultWarehouse"
                      v-model="partner.partnerDefaultWarehouse"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4 >
                    <v-text-field
                      :label="lang.PartnerPrinterSize"
                      v-model="partner.partnerPrinterSize"
                    ></v-text-field>
                  </v-flex>
              </v-row>

              <v-row>
                  <v-flex xs4 class="pr-2">
                    <v-checkbox
                      :label="lang.PartnerUseRetailPrice"
                      v-model="partner.partnerUseRetailPrice"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs4 class="pr-2">
                    <v-checkbox
                      :label="lang.PartnerShowLastSaleBuyer"
                      v-model="partner.partnerShowLastSaleBuyer"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs4>
                    <v-checkbox
                      :label="lang.PartnerShowWarehouseState"
                      v-model="partner.partnerShowWarehouseState"
                    ></v-checkbox> 
                  </v-flex>
              </v-row>

              <v-row>
                  <v-flex xs4 class="pr-2">
                    <v-checkbox
                      :label="lang.PartnerShowInputPrice"
                      v-model="partner.partnerShowInputPrice"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs4 class="pr-2">
                    <v-checkbox
                      :label="lang.PartnerVATonPay"
                      v-model="partner.partnerVATonPay"
                    ></v-checkbox> 
                  </v-flex>
                  <v-flex xs4 >
                    <v-checkbox
                      :label="lang.PartnerRetail"
                      v-model="partner.partnerRetail"
                    ></v-checkbox> 
                  </v-flex>
              </v-row>

              <v-row>
                  <v-flex xs4 class="pr-2">
                    <v-checkbox
                      :label="lang.PartnerActiv"
                      v-model="partner.Activ"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs4 class="pr-2">
                    <v-checkbox
                      :label="lang.PartnerLockedCredit"
                      v-model="partner.lockedCredit"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs4 >
                    <v-checkbox
                      :label="lang.PartnerlockedDebit"
                      v-model="partner.lockedDebit"
                    ></v-checkbox> 
                  </v-flex>
              </v-row>

               <v-row>
                  <v-flex xs4 class="pr-2">
                    <v-checkbox
                      :label="lang.privatePerson"
                      v-model="partner.privatePerson"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs4 class="pr-2">
                    <v-checkbox
                      :label="lang.specialCustomer"
                      v-model="partner.specialCustomer"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs4 >
                  </v-flex>
              </v-row>

              <v-text-field
                :label="lang.PartnerInputFilesPath"
                v-model="partner.partnerInputFilesPath"
              ></v-text-field>         

              <v-text-field
                :label="lang.PartnerOutputFilesPath"
                v-model="partner.partnerOutputFilesPath"
              ></v-text-field>       

              <v-text-field
                :label="lang.PartnerTransferDataFilesPath"
                v-model="partner.partnerTransferDataFilesPath"
              ></v-text-field>          

              <v-row>
                  <v-flex xs4 class="pr-2">
                    <v-text-field
                      :label="lang.PartnerStartInvoiceNumber"
                      v-model="partner.partnerStartInvoiceNumber"
                    ></v-text-field> 
                  </v-flex>
                  <v-flex xs4 class="pr-2">
                    <v-text-field
                      :label="lang.PartnerStartBillNumber"
                      v-model="partner.partnerStartBillNumber"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4 class="pr-2">
                    <v-text-field
                      :label="lang.PartnerType"
                      v-model="partner.partnerType"
                    ></v-text-field>
                  </v-flex>
              </v-row>

              <v-row>
                <v-flex xs11 sm5>
                   <v-dialog
                    ref="dialog1"
                    persistent
                    v-model="modal1"                   
                    width="290px"
                    :return-value.sync="partner.StartFrom"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="partner.StartFrom"
                      :label="lang.PartnerStartFrom"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                    <v-date-picker v-model="partner.StartFrom" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal = false">{{langC.Cancel}}</v-btn>
                      <v-btn color="primary" @click="$refs.dialog1.save(partner.StartFrom)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-flex>
               </v-row> 
              </div>

              </v-card-text>
          </v-card>
        </v-col>

          <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            bottom
            :color="color"
            >
            {{ text }}
          </v-snackbar>
      </v-row>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
import PartnerService from '@/services/PartnerService'
import PartnerCompanyService from '@/services/PartnerCompanyService'
import langEn from './PartnerDescEN'
import langHr from './PartnerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import { testLastUpdate, newUpdates } from '@/services/TestLastUpdate'

export default {
  name: 'partnercreate',
  data () {
    return {
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      showDirector: false,
      showContact: false,
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      menu: false,
      modal: false,
      modal1: false,
      dialog1: false,
      locError: null,
      msg: '',
      newPartner: {},
      partner: {
        CompanyId: 1,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 1,
        email: '',
        First: '',
        Last: '',
        partnerName: '',
        partnerNameShort: '',
        partnerNameDatabase: '',
        partnerBuildingNameNumber: '',
        partnerStreet: '',
        partnerCity: '',
        partnerPostcode: '',
        partnerCountyRegion: '',
        partnerState: '',
        partnerVATID: '',
        partneremail: '',
        partnerPhones: '',
        partnerBankName: '',
        partnerBankAddress: '',
        partnerBankIBAN: '',
        partnerBankSWIFT: '',
        partnerDirectorTitle: '',
        partnerDirectorFirst: '',
        partnerDirectorLast: '',
        partnerDirectorFormer: '',
        partnerDirectorStateResidence: '',
        partnerDirectorNationality: '',
        partnerDirectorDateOfBirth: '',
        partnerDirectorBusinessOccupation: '',
        partnerDirectorAdressBuildingNameNumber: '',
        partnerDirectorAdressStreet: '',
        partnerDirectorCity: '',
        partnerDirectorPostcode: '',
        partnerDirectorCountyRegion: '',
        partnerDirectorEmail: '',
        partnerDirectorPhone: '',
        partnerDirectorContactOption: '',
        partnerSecretaryTitle: '',
        partnerSecretaryFirst: '',
        partnerSecretaryLast: '',
        partnerSecretaryFormer: '',
        partnerSecretaryStateResidence: '',
        partnerSecretaryNationality: '',
        partnerSecretaryDateOfBirth: '',
        partnerSecretaryBusinessOccupation: '',
        partnerSecretaryAdressBuildingNameNumber: '',
        partnerSecretaryAdressStreet: '',
        partnerSecretaryCity: '',
        partnerSecretaryPostcode: '',
        partnerSecretaryCountyRegion: '',
        partnerSecretaryEmail: '',
        partnerSecretaryPhone: '',
        partnerSecretaryContactOption: '',
        partnerInvoiceMemo: '',
        partnerInvoiceForeginMemo: '',
        partnerDeliveryNoteMemo: '',
        partnerDeliveryNoteForeginMemo: '',
        partnerInvoiceDefDays: 15,
        partnerInvoicePlace: '',
        partnerInvoiceType: '',
        partnerInvoiceHint: '',
        partnerInvoiceSpeed: 1,
        partnerUseRetailPrice: false,
        partnerShowLastSaleBuyer: false,
        partnerShowWarehouseState: false,
        partnerShowInputPrice: false,
        partnerVATonPay: false,
        partnerRetail: false,
        partnerDefaultWarehouse: 1,
        partnerPrinterSize: 1,
        partnerInputFilesPath: '',
        partnerOutputFilesPath: '',
        partnerTransferDataFilesPath: '',
        partnerStartInvoiceNumber: 1,
        partnerStartBillNumber: 1,
        partnerType: 1,
        StartFrom: null,
        Activ: false,
        lockedCredit: false,
        lockedDebit: false,
        memberOfGroup: '',
        EnteredInDatabase: null,
        LastUpdateInDatabase: null,
        privatePerson: false,
        specialCustomer: false,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: ''
      },
      required: (value) => !!value || 'Required.',
      error: '',
      lang: {},
      langC: {},
      mainQuery: {},
      haveItem: {},
      privateP: true
    }
  },
  async mounted () {
    try {
      this.locError = null
      if (this.$store.state.language === 'EN') {
        this.lang = langEn
        this.langC = commEn
      }
      if (this.$store.state.language === 'HR') {
        this.lang = langHr
        this.langC = commHr
      }
      this.privateP = this.$store.state.user ? this.$store.state.user.privatePartner : true
      if (this.$store.state.editItem === 1) {
        // this.privGroups = (await PartnerService.index()).data.groups
        // console.log('privgroups', this.privGroups)
        const dbPartner = await PartnerService.show(this.$store.state.partnerVATID)
        this.partner = dbPartner.data.partner
      }
    } catch (error) {
      this.error = error
    }
  },
  methods: {
    showOn1() {
      this.show1 = !this.show1
    },
    showOn2() {
      this.show2 = !this.show2
    },
    showOn3() {
      this.show3 = !this.show3
    },
    showOn4() {
      this.show4 = !this.show4
    },
    showOn5() {
      this.show5 = !this.show5
    },
    showOnDirector() {
      this.showDirector = !this.showDirector
    },
    showOnContact() {
      this.showContact = !this.showContact
    },
    async register () {
      try {
        this.locError = null
        if (this.$store.state.editItem === 0) {
          this.locError = null
          if (this.$store.state.user.id) {
            this.partner.UserId = this.$store.state.user.id
          }
          if (this.$store.state.user.email) {
            this.partner.email = this.$store.state.user.email
            this.partner.LastUpdateBy = this.$store.state.user.email
          }
          if (this.$store.state.companyid) {
            this.partner.CompanyId = this.$store.state.companyid
            this.mainQuery.CompanyId = this.$store.state.companyid
          }
          if (this.$store.state.companyName) {
            this.partner.CompanyNameDatabase = this.$store.state.companyName
          }
          if (this.$store.state.businessYear) {
            this.partner.BusinessYear = this.$store.state.businessYear
          }
          // if (!this.partner.partneremail) {
          //   this.locError = 'Error partner dot have email ! '
          // }
          if (this.partner.privatePerson === false) {
            if (!this.partner.partnerVATID) {
              this.locError = this.locError + this.lang.alertError1
            }
            if (this.partner.partnerVATID) {
              this.mainQuery.partnerVATID = this.partner.partnerVATID
            }
            // if (this.partner.partneremail) {
            //   this.mainQuery.partneremail = this.partner.partneremail
            // }
            if (!this.locError) {
              await PartnerService.check(this.mainQuery)
                .then(res => {
                  if (res.data.partner) {
                    this.haveItem = res.data.partner
                    this.locError = this.lang.PartnerErrorVAT + (this.haveItem.partnerName ? this.haveItem.partnerName : '')
                  }
                  if (res.data.partner2) {
                    this.haveItem = res.data.partner2
                    this.locError = this.lang.PartnerErroremail + (this.haveItem.partnerName ? this.haveItem.partnerName : '')
                  }                
                })
                .catch(err => {
                  this.error = err.data
                  this.locError = false
                })
            }
          }
          if (!this.locError) {
            const {data} = await PartnerService.post(this.partner)
            if (data.partner) {
              const newPrivatePartner = {}
              newPrivatePartner.CompanyId = data.partner.CompanyId
              newPrivatePartner.CompanyNameDatabase = data.partner.CompanyNameDatabase
              newPrivatePartner.BusinessYear = data.partner.BusinessYear
              newPrivatePartner.UserId = data.partner.UserId ? data.partner.UserId : ''
              newPrivatePartner.email = data.partner.email ? data.partner.email : ''
              newPrivatePartner.partnerId = data.partner.id ? data.partner.id : ''
              newPrivatePartner.partnerNameDatabase = data.partner.partnerNameDatabase ? data.partner.partnerNameDatabase : ''
              await PartnerCompanyService.post(newPrivatePartner)
            }
            this.text = this.lang.alertOK1
            this.color = 'green'
            this.snackbar = true
            // await checkPartners(1)
            await newUpdates(2)
            await testLastUpdate()
            if (this.$store.state.partnerStore) {
              this.partners = this.$store.state.partnerStore
            }
          } else {
            this.text = this.locError
            this.color = 'red'
            this.snackbar = true
          }
        }
        if (this.$store.state.editItem === 1) {
            if (this.partner.privatePerson === false) {
              if (!this.partner.partnerVATID) {
                this.locError = this.locError + this.lang.alertError1
              }
            }
            if (!this.locError) {
              await PartnerService.put(this.partner)
              this.text = this.lang.alertOK2
              this.color = 'green'
              this.snackbar = true
              // await checkPartners(1)
              await newUpdates(2)
              await testLastUpdate()
              if (this.$store.state.partnerStore) {
                this.partners = this.$store.state.partnerStore
              }
            } else {
               this.text = this.locError
               this.color = 'red'
               this.snackbar = true
            }
          
        }
      } catch (error) {
        this.error = error
      }
    },
    async partnerList () {
      try {
        if (this.$store.state.editItem === 1) {
          this.$store.dispatch('seteditItem', 0)
        }
        await this.$router.push({
          name: 'partner'
        })
      } catch (err) {
        this.error = err
      }
    }
  },
  computed: {
    formIsValid () {
      return this.partner.partnerName !== '' &&
        this.partner.partnerNameShort !== '' &&
        this.partner.partnerNameDatabase !== '' &&
        this.partner.partnerVATID !== '' 
    }
  },
  watch: {
    'partner.partnerName' () {
      if (this.partner.partnerName) {
        this.partner.partnerNameDatabase =  this.partner.partnerName.replace(/[\W_]/g, '')
        this.partner.partnerNameShort = this.partner.partnerName
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
