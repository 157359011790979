<template>
<div>
  <v-container fluid grid-list-md>
  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    :headers="headers"
    :items="itemsPOS"
    :search="search"
    sort-by="id"
    class="elevation-1 headline"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{lang.itemsPOS}}</v-toolbar-title>
          
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
        
        <v-dialog v-model="dialog" max-width="700px">

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                    <v-row class="pa-0">
                      <v-col cols="12" sm="8" md="8" xs="10" lg="8" xl="8" class="pa-0">
                        <v-autocomplete
                          :items="itemsLoc"
                          v-model="selectIL"
                          :label="lang.ItemSelect"
                          item-text="itemByID"
                          item-value="itemID"
                          clearable
                          return-object
                        ></v-autocomplete>
                      </v-col>
                  </v-row>

                  <v-row class="pa-0">
                      <v-col cols="12" sm="8" md="8" xs="10" lg="8" xl="8" class="pa-0">
                        <v-text-field
                            dense
                            :label="lang.ItemName"
                            v-model="editedItem.itemName"
                          ></v-text-field>    
                      </v-col>
                  </v-row>

                  <v-row class="pa-0">
                      <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pa-0">
                        <v-text-field
                            dense
                            :label="lang.ItemNameShort"
                            v-model="editedItem.itemNameShort"
                          ></v-text-field>    
                      </v-col>
                      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
      
                      </v-col>
                      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                        <v-text-field
                            dense
                            :label="lang.ItemGroup"
                            v-model="editedItem.itemBaseGroup"
                            type="number"
                            disabled
                          ></v-text-field>                   
                      </v-col>
                  </v-row>

                  <v-row class="pa-0">
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-3 pa-0">
                        <v-text-field
                            :label="lang.ItemUnit"
                            v-model="editedItem.itemUnit"
                          ></v-text-field>    
                      </v-col>
                      <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="mr-3 pa-0">
                        <v-text-field
                            :label="lang.ItemEANCode"
                            v-model="editedItem.ItemEANCode"
                          ></v-text-field>
                      </v-col>
                      
                      <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pa-0">
                          <v-text-field
                            :label="lang.ItemSerialNr"
                            v-model="editedItem.itemSerialNr"
                          ></v-text-field>
                      </v-col>
                  </v-row>

                  <v-row>
                      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-3 pa-0">
                          <v-text-field
                            dense
                            :label="lang.ItemPcsNeutral"
                            v-model="editedItem.itemPcsNeutral"
                            type="number"
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-3 pa-0">
                          <v-text-field
                          dense
                          :label="lang.ItemPrice"
                          v-model="editedItem.itemPrice"
                          type="number"
                          disabled
                        ></v-text-field>
                      </v-col>
                       <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-3 pa-0">
                          <v-text-field
                          dense
                          :label="lang.ItemVAT1Percentage"
                          v-model="editedItem.itemVAT1Percentage"
                          type="number"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-3 pa-0">
                          <v-text-field
                          dense
                          :label="lang.ItemPriceWithVAT"
                          v-model="editedItem.itemPriceWithVAT"
                          type="number"
                          disabled
                        ></v-text-field>
                      </v-col> 
                      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                        <v-text-field
                            dense
                            :label="lang.ItemFullTotal"
                            v-model="editedItem.itemFullTotal"
                            type="number"
                            disabled
                          ></v-text-field> 
                      </v-col>
                  </v-row>

                  <v-row>
                      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-3 pa-0">
       
                      </v-col>
                      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-3 pa-0">
                          <v-text-field
                          dense
                          :label="lang.newitemPrice"
                          v-model="editedItem.newitemPrice"
                          type="number"
                        ></v-text-field>
                      </v-col>
                       <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-3 pa-0">
                          <v-text-field
                          dense
                          :label="lang.newitemVAT1Percentage"
                          v-model="editedItem.newitemVAT1Percentage"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-3 pa-0">
                          <v-text-field
                          dense
                          :label="lang.newitemPriceWithVAT"
                          v-model="editedItem.newitemPriceWithVAT"
                          type="number"
                        ></v-text-field>
                      </v-col> 
                      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                        <v-text-field
                            dense
                            :label="lang.newitemFullTotal"
                            v-model="editedItem.newitemFullTotal"
                            type="number"
                            disabled
                          ></v-text-field> 
                      </v-col>
                  </v-row>


                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn :disabled="!valid" color="success" text @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
 
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        edit
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        delete
      </v-icon>
    </template>

    <template v-slot:[`item.itemPriceWithVAT`]="{ item }">
      <span class="deep-purple--text">{{ item.itemPriceWithVAT }}</span>
    </template>
    <template v-slot:[`item.itemVAT1Percentage`]="{ item }">
      <span class="deep-purple--text">{{ item.itemVAT1Percentage }}</span>
    </template>
    <template v-slot:[`item.itemAmountWithVAT`]="{ item }">
      <span class="deep-purple--text">{{ item.itemAmountWithVAT }}</span>
    </template>
    <template v-slot:no-data>
      {{ lang.PriceChangeEmpty }}
    </template>
  </v-data-table>
  </v-container>
  </div>
</template>

<script>
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dateFormat from '@/snippets/dateFormat'

  export default {
    name: 'documentPOSCRUD',
    data: () => ({
      search: '',
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: "",
        databaseDocID: 0
      },
      pcsLoc: 0,
      dialog: false,
      headers: [ 
      ],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        EmployeeId: null,
        hashtag: '',
        eemail: '',
        First: '',
        Last: '',
        empWorkDay: null,
        empStartTime: null,
        empEndTime: null,
        empWrkHrs: 0,
        empWrkExpHrs: 0,
        empExp1: 0,
        empExp2: 0,
        documentName: '',
        documentSide: 0,
        documentAnalysis: 0,
        documentId: 0,
        documentIdGeneral: '',
        documentDate: '',
        partnerId: '',
        partnerNameDatabase: '',
        HomeCurrency: '',
        ForeignCurrency: '',
        ExRate: 0,
        itemID: '',
        itemName: '',
        ItemNameShort: '',
        ItemCode: '',
        ItemEANCode: '',
        itemGroup: '',
        itemDescription: '',
        itemUnit: '',
        ItemSize: '',
        ItemWeight: 0,
        ItemWeightMeasure: '',
        ItemDimMeasure: '',
        ItemDimLenght: 0,
        ItemDimHeight: 0,
        ItemDimDepth: 0,
        ItemWarehouse: '',
        ItemWarehouseRow: '',
        ItemWarehouseShelf: '',
        ItemWahrehouseFloor: '',
        ItemPicture1: '',
        ItemPicture2: '',
        ItemPicture3: '',
        ItemPicture4: '',
        ItemPicture5: '',
        ItemPicture6: '',
        ItemPicture7: '',
        ItemBelongsToId: 0,
        itemService: false,
        itemWeb: false,
        itemNotActiv: false,
        itemHideForever: false,
        ItemHomeCurrency: '',
        ItemForeignCurrency: '',
        ItemExRate: 0,
        itemSuppBasePrice: 0,
        itemSuppDiscount: 0,
        itemSuppPrice: 0,
        ItemSuppCode: '',
        itemMargin: 0,
        itemMaxDiscount: 0,
        itemPrice: 0,
        itemVAT1Percetage: 0,
        itemVAT1Amount: 0,
        itemVAT2Percentge: 0,
        itemVAT2Amount: 0,
        itemDirectDuty1: 0,
        itemDirectDuty2: 0,
        itemPriceWithVAT: 0,
        itemForePrice: 0,
        itemForeVAT1Percentage: 0,
        itemForeVAT1Amount: 0,
        itemForeVAT2Percentage: 0,
        itemForeVAT2Amount: 0,
        itemForeDirectDuty1: 0,
        itemForeDirectDuty2: 0,
        itemForePriceWithVAT: 0,
        itemBaseAmount: 0,
        itemBaseForeAmount: 0,
        itemDiscountAmount1: 0,
        itemDiscountPercentage1: 0,
        itemDiscountAmount2: 0,
        itemDiscountPercentage2: 0,
        itemDiscountAmount3: 0,
        itemDiscountPercentage4: 0,
        itemDiscountPrice: 0,
        itemSerialNr: '',
        itemPcsPlus: 0,
        itemPcsMinus: 1,
        itemPcsNeutral: 0,
        itemBaseAmountNoVAT: 0,
        itemBaseForeAmountNoVAT: 0,
        itemBaseAmountWithVAT: 0,
        itemBaseForeAmountWithVAT: 0,
        itemAmountWithVAT: 0,
        itemFullTotal: 0,
        itemFullTotalFore: 0,
        itemForeAmountWithVAT: 0,
        itemDirectDuty1Amount: 0,
        itemDirectDuty2Amount: 0,
        itemDirectDuty1AmountFore: 0,
        itemDirectDuty2AmountFore: 0,
        newitemPrice: 0,
        newitemBaseAmount: 0,
        newitemVAT1Percentage: 0,
        newitemVAT1Amount: 0,
        newitemAmountWithVAT: 0,
        newitemPriceWithVAT: 0,
        newitemDirectDuty1: 0,
        newitemFullTotal: 0,
        EnteredInDatabase: null,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: ''
      },
      privitems: [ ],
      valid: true,
      locusers: [],
      select: { },
      itemsLoc: [],
      itemsPOS: [],
      itemLoc: {},
      selectIL: {},
      sumDoc: {
        documentTotalVAT1Per: 0,
        documentTotalVAT1Base: 0,
        documentTotalVAT1Amount: 0,
        documentTotalVAT2Per: 0,
        documentTotalVAT2Base: 0,
        documentTotalVAT2Amount: 0,
        documentTotalVAT3Per: 0,
        documentTotalVAT3Base: 0,
        documentTotalVAT3Amount: 0,
        documentTotalVAT4Per: 0,
        documentTotalVAT4Base: 0,
        documentTotalVAT4Amount: 0,
        documentTotalVAT5Per: 0,
        documentTotalVAT5Base: 0,
        documentTotalVAT5Amount: 0,
        documentTotalAmountNetto: 0,
        documentTotalAmountVAT: 0,
        documentTotalAmountBrutto: 0,
        documentTotalAmountPayInAdvance: 0,
        documentTotalAmountToPay: 0,
        documentTotalAmountNettoForeign: 0,
        documentTotalAmountVATForeign: 0,
        documentTotalAmountBruttoForeign: 0,
        documentTotalAmountPayInAdvanceForeign: 0,
        documentTotalAmountToPayForeign: 0,
        documentTotalAmountDirectD1: 0,
        documentTotalAmountDirectD2: 0,
        documentTotalAmountDirectD1Fore: 0,
        documentTotalAmountDirectD2Fore: 0
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewAccount4: this.lang.EditAccount4
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      'selectIL' () {
        this.findItem()
        this.calculatePrice()
      },
      'editedItem.newitemPrice' () {
        this.initPcs()
        this.editedItem.newitemPrice = this.editedItem.newitemPrice ? this.round(this.editedItem.newitemPrice, 2) : 0
        this.editedItem.newitemVAT1Percentage = this.editedItem.newitemVAT1Percentage ? this.editedItem.newitemVAT1Percentage : 0
        this.editedItem.newitemPriceWithVAT = this.round((this.editedItem.newitemPrice * ( 1 + (this.editedItem.newitemVAT1Percentage /100))),2)
        this.calculatePrice()
      },
      'editedItem.newitemPriceWithVAT' () {
        this.initPcs() 
        this.editedItem.newitemPriceWithVAT = this.editedItem.newitemPriceWithVAT ? this.round(this.editedItem.newitemPriceWithVAT, 2) : 0
        this.editedItem.newitemVAT1Percentage = this.editedItem.newitemVAT1Percentage ? this.editedItem.newitemVAT1Percentage : 0
        this.editedItem.newitemPrice = this.round((this.editedItem.newitemPriceWithVAT / ( 1 + (this.editedItem.newitemVAT1Percentage /100))),2)
        this.calculatePrice()
      },
      'editedItem.newitemVAT1Percentage' () {
        this.initPcs() 
        this.editedItem.newitemPriceWithVAT = this.editedItem.newitemPriceWithVAT ? this.round(this.editedItem.newitemPriceWithVAT, 2) : 0
        this.editedItem.newitemVAT1Percentage = this.editedItem.newitemVAT1Percentage ? this.editedItem.newitemVAT1Percentage : 0
        this.editedItem.newitemPrice = this.round((this.editedItem.newitemPriceWithVAT / ( 1 + (this.editedItem.newitemVAT1Percentage /100))),2)
        this.calculatePrice()
      },
      'editedItem.itemPcsNeutral' () {
        this.calculatePrice()
      },
    },

    async mounted() {
      if (this.$store.state.language === 'EN') {
        this.lang = langEn
        this.langC = commEn
      }
      if (this.$store.state.language === 'HR') {
        this.lang = langHr
        this.langC = commHr
      }
      if (this.$store.state.itemStore) {
        this.itemsLoc = this.$store.state.itemStore
       }

      this.defaultItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
      this.defaultItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
      this.defaultItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
      this.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : ''
      this.documentNameLoc = this.$store.state.documentActivLoc ? this.$store.state.documentActivLoc : ''

      this.headers.push(
        {
          text: this.lang.ItemName,
          align: 'left',
          sortable: true,
          value: 'itemName',
          width: '550px'
        },
        { text: this.lang.ItemUnit, align: 'center', value: 'itemUnit', width: '55px' },
        { text: this.lang.ItemPcsNeutral, align: 'right', value: 'itemPcsNeutral', width: '120px' },
        { text: this.lang.olditemPriceWithVAT, align: 'right', value: 'itemPriceWithVAT', width: '120px', class: 'deep-purple--text' },
        { text: this.lang.olditemVAT1Percentage, align: 'right', sortable: true, value: 'itemVAT1Percentage', width: '70px', class: 'deep-purple--text' },
        { text: this.lang.olditemAmountWithVAT, align: 'right', value: 'itemAmountWithVAT', width: '120px', class: 'deep-purple--text' },
        { text: this.lang.newitemPriceWithVAT, align: 'right', value: 'newitemPriceWithVAT', width: '120px' },
        { text: this.lang.newitemVAT1Percentage, align: 'right', sortable: true, value: 'newitemVAT1Percentage', width: '70px' },
        { text: this.lang.newitemAmountWithVAT, align: 'right', value: 'newitemAmountWithVAT', width: '120px' },
        { text: this.lang.Actions, value: 'action', sortable: false },
      )

      this.itemsPOS = this.$store.state.documentActivItems ? this.$store.state.documentActivItems : []
    },
   
    created () {
      this.initialize()
    },

    methods: {
       adddefaultItem(){
        this.defaultItem.CompanyId = this.$store.state.documentActivHead.CompanyId
        this.defaultItem.CompanyNameDatabase = this.$store.state.documentActivHead.CompanyNameDatabase
        this.defaultItem.BusinessYear = this.$store.state.documentActivHead.BusinessYear
        this.defaultItem.UserId = this.$store.state.documentActivHead.UserId
        this.defaultItem.email = this.$store.state.documentActivHead.email
        this.defaultItem.documentName = this.$store.state.documentActivHead.documentName
        this.defaultItem.documentId = parseInt(this.$store.state.documentActivHead.documentId)
        this.defaultItem.documentIdGeneral = this.$store.state.documentActivHead.documentIdGeneral
        this.defaultItem.documentDate = this.$store.state.documentActivHead.documentDate
        this.defaultItem.partnerId = this.$store.state.documentActivHead.partnerId
        this.defaultItem.partnerNameDatabase = this.$store.state.documentActivHead.partnerNameDatabase
        this.defaultItem.HomeCurrency = this.$store.state.documentActivHead.documentHomeCurrency
        this.defaultItem.ForeignCurrency = this.$store.state.documentActivHead.documentForeignCurrency
        this.defaultItem.ExRate = parseFloat(this.$store.state.documentActivHead.documentExRate)
        this.defaultItem.ItemHomeCurrency = this.$store.state.documentActivHead.documentHomeCurrency
        this.defaultItem.ItemForeignCurrency = this.$store.state.documentActivHead.documentForeignCurrency
        this.defaultItem.ItemExRate = parseFloat(this.$store.state.documentActivHead.documentExRate)
        if (this.$store.state.documentSide) {
          this.defaultItem.documentSide = this.$store.state.documentSide
        }
        if (this.$store.state.documentAnalysis) {
          this.defaultItem.documentAnalysis = this.$store.state.documentAnalysis
        }          
        this.defaultItem.databaseDocID = this.$store.state.databaseDocID
        if (this.$store.state.documentSide === 1) {
          this.defaultItem.itemPcsPlus = 1
        }
        if (this.$store.state.documentSide === 2) {
          this.defaultItem.itemPcsMinus = 1
        }
        if (this.$store.state.documentSide === 3) {
          this.defaultItem.itemPcsNeutral = 1
        }
        // there is no item.id - new item in document
        const newId = this.getRndInteger(1000000000, 9000000000)
        this.defaultItem.id = newId
        this.$store.dispatch('setDocumentDetailid', newId)
      },
      doParse () {
        this.editedItem.ItemWeight = this.editedItem.ItemWeight ? parseFloat(this.editedItem.ItemWeight) : 0
        this.editedItem.ItemDimLenght = this.editedItem.ItemDimLenght ? parseFloat(this.editedItem.ItemDimLenght) : 0
        this.editedItem.ItemDimHeight = this.editedItem.ItemDimHeight ? parseFloat(this.editedItem.ItemDimHeight) : 0
        this.editedItem.ItemDimDepth = this.editedItem.ItemDimDepth ? parseFloat(this.editedItem.ItemDimDepth) : 0
        this.editedItem.ItemBelongsToId = this.editedItem.ItemBelongsToId ? parseFloat(this.editedItem.ItemBelongsToId) : 0
        this.editedItem.itemSuppBasePrice = this.editedItem.itemSuppBasePrice ? parseFloat(this.editedItem.itemSuppBasePrice) : 0
        this.editedItem.itemSuppDiscunt = this.editedItem.itemSuppDiscunt ? parseFloat(this.editedItem.itemSuppDiscunt) : 0
        this.editedItem.itemSuppPrice = this.editedItem.itemSuppPrice ? parseFloat(this.editedItem.itemSuppPrice) : 0
        this.editedItem.ItemSuppCode = this.editedItem.ItemSuppCode ? parseFloat(this.editedItem.ItemSuppCode) : 0
        this.editedItem.itemMargin = this.editedItem.itemMargin ? parseFloat(this.editedItem.itemMargin) : 0
        this.editedItem.itemMaxDiscount = this.editedItem.itemMaxDiscount ? parseFloat(this.editedItem.itemMaxDiscount) : 0
        this.editedItem.itemPrice = this.editedItem.itemPrice ? parseFloat(this.editedItem.itemPrice) : 0
        this.editedItem.itemVAT1Percetage = this.editedItem.itemVAT1Percetage ? parseFloat(this.editedItem.itemVAT1Percetage) : 0
        this.editedItem.itemVAT1Amount = this.editedItem.itemVAT1Amount ? parseFloat(this.editedItem.itemVAT1Amount) : 0
        this.editedItem.itemVAT2Percentge =this.editedItem.itemVAT2Percentge ? parseFloat(this.editedItem.itemVAT2Percentge) : 0
        this.editedItem.itemVAT2Amount = this.editedItem.itemVAT2Amount ? parseFloat(this.editedItem.itemVAT2Amount) : 0
        this.editedItem.itemDirectDuty1 = this.editedItem.itemDirectDuty1 ? parseFloat(this.editedItem.itemDirectDuty1) : 0
        this.editedItem.itemDirectDuty2 = this.editedItem.itemDirectDuty2 ? parseFloat(this.editedItem.itemDirectDuty2) : 0
        this.editedItem.itemPriceWithVAT = this.editedItem.itemPriceWithVAT ? parseFloat(this.editedItem.itemPriceWithVAT) : 0
        this.editedItem.itemForePrice = this.editedItem.itemForePrice ? parseFloat(this.editedItem.itemForePrice) : 0
        this.editedItem.itemForeVAT1Percentage = this.editedItem.itemForeVAT1Percentage ? parseFloat(this.editedItem.itemForeVAT1Percentage) : 0
        this.editedItem.itemForeVAT1Amount = this.editedItem.itemForeVAT1Amount ? parseFloat(this.editedItem.itemForeVAT1Amount) : 0
        this.editedItem.itemForeVAT2Percentage = this.editedItem.itemForeVAT2Percentage ? parseFloat(this.editedItem.itemForeVAT2Percentage) : 0
        this.editedItem.itemForeVAT2Amount = this.editedItem.itemForeVAT2Amount ? parseFloat(this.editedItem.itemForeVAT2Amount) : 0
        this.editedItem.itemForeDirectDuty1 = this.editedItem.itemForeDirectDuty1 ? parseFloat(this.editedItem.itemForeDirectDuty1) : 0
        this.editedItem.itemForeDirectDuty2 = this.editedItem.itemForeDirectDuty2 ? parseFloat(this.editedItem.itemForeDirectDuty2) : 0
        this.editedItem.itemForePriceWithVAT = this.editedItem.itemForePriceWithVAT ? parseFloat(this.editedItem.itemForePriceWithVAT) : 0
        this.editedItem.itemPcsNeutral = this.editedItem.itemPcsNeutral ? parseFloat(this.editedItem.itemPcsNeutral) : 0
        this.editedItem.itemPcsMinus = this.editedItem.itemPcsMinus ? parseFloat(this.editedItem.itemPcsMinus) : 0
        this.editedItem.itemFullTotal = this.editedItem.itemFullTotal ? parseFloat(this.editedItem.itemFullTotal) : 0
        this.editedItem.itemFullTotalFore = this.editedItem.itemFullTotalFore ? parseFloat(this.editedItem.itemFullTotalFore) : 0
        this.editedItem.itemDirectDuty1Amount = this.editedItem.itemDirectDuty1Amount ? parseFloat(this.editedItem.itemDirectDuty1Amount) : 0
        this.editedItem.itemDirectDuty2Amount = this.editedItem.itemDirectDuty2Amount ? parseFloat(this.editedItem.itemDirectDuty2Amount) : 0
        this.editedItem.itemDirectDuty1AmountFore = this.editedItem.itemDirectDuty1AmountFore ? parseFloat(this.editedItem.itemDirectDuty1AmountFore) : 0
        this.editedItem.itemDirectDuty2AmountFore = this.editedItem.itemDirectDuty2AmountFore ? parseFloat(this.editedItem.itemDirectDuty2AmountFore) : 0
        this.editedItem.FreeF1 = this.editedItem.FreeF1 ? parseFloat(this.editedItem.FreeF1) : 0
        this.editedItem.FreeF2 = this.editedItem.FreeF2 ? parseFloat(this.editedItem.FreeF2) : 0
        this.editedItem.FreeF3 = this.editedItem.FreeF3 ? parseFloat(this.editedItem.FreeF3) : 0
        this.editedItem.FreeF4 = this.editedItem.FreeF4 ? parseFloat(this.editedItem.FreeF4) : 0
        this.editedItem.FreeF5 = this.editedItem.FreeF5 ? parseFloat(this.editedItem.FreeF5) : 0
      },
      async findItem () {
        try {
          this.itemLoc = this.selectIL.itemID ? await this.itemsLoc.find(itemM => itemM.itemID === this.selectIL.itemID) : null
          if (this.itemLoc) {                    
              this.editedItem.itemID = this.itemLoc.itemID
              this.editedItem.itemName = this.itemLoc.itemName
              this.editedItem.ItemNameShort = this.itemLoc.ItemNameShort
              this.editedItem.ItemCode = this.itemLoc.ItemCode
              this.editedItem.ItemEANCode = this.itemLoc.ItemEANCode
              this.editedItem.itemGroup = this.itemLoc.itemGroup
              this.editedItem.itemDescription = this.itemLoc.itemDescription
              this.editedItem.itemUnit = this.itemLoc.itemUnit
              this.editedItem.ItemSize = this.itemLoc.ItemSize
              this.editedItem.ItemWeight = this.itemLoc.ItemWeight
              this.editedItem.ItemWeightMeasure = this.itemLoc.ItemWeightMeasure
              this.editedItem.ItemDimMeasure = this.itemLoc.ItemDimMeasure
              this.editedItem.ItemDimLenght = this.itemLoc.ItemDimLenght
              this.editedItem.ItemDimHeight = this.itemLoc.ItemDimHeight
              this.editedItem.ItemDimDepth = this.itemLoc.ItemDimDepth
              this.editedItem.ItemWarehouse = this.itemLoc.ItemWarehouse
              this.editedItem.ItemWarehouseRow = this.itemLoc.ItemWarehouseRow
              this.editedItem.ItemWarehouseShelf = this.itemLoc.ItemWarehouseShelf
              this.editedItem.ItemWahrehouseFloor = this.itemLoc.ItemWahrehouseFloor
              this.editedItem.ItemPicture1 = this.itemLoc.ItemPicture1
              this.editedItem.ItemPicture2 = this.itemLoc.ItemPicture2
              this.editedItem.ItemPicture3 = this.itemLoc.ItemPicture3
              this.editedItem.ItemPicture4 = this.itemLoc.ItemPicture4
              this.editedItem.ItemPicture5 = this.itemLoc.ItemPicture5
              this.editedItem.ItemPicture6 = this.itemLoc.ItemPicture6
              this.editedItem.ItemPicture7 = this.itemLoc.ItemPicture7
              this.editedItem.ItemBelongsToId = this.itemLoc.ItemBelongsToId
              this.editedItem.itemService = this.itemLoc.itemService
              this.editedItem.itemWeb = this.itemLoc.itemWeb
              this.editedItem.itemNotActiv = this.itemLoc.itemNotActiv
              this.editedItem.itemHideForever = this.itemLoc.itemHideForever
              this.editedItem.itemSuppBasePrice = this.itemLoc.itemSuppBasePrice
              this.editedItem.itemSuppDiscunt = this.itemLoc.itemSuppDiscunt
              this.editedItem.itemSuppPrice = this.itemLoc.itemSuppPrice
              this.editedItem.ItemSuppCode = this.itemLoc.ItemSuppCode
              this.editedItem.itemMargin = this.itemLoc.itemMargin
              this.editedItem.itemMaxDiscount = this.itemLoc.itemMaxDiscount
              this.editedItem.itemPrice = this.round(this.itemLoc.itemPrice, 3)
              this.editedItem.itemVAT1Percentage = this.round(this.itemLoc.itemVAT1Percentage, 2)
              this.editedItem.itemVAT1Amount = this.itemLoc.itemVAT1Amount
              this.editedItem.itemVAT2Percentge = this.itemLoc.itemVAT2Percentge
              this.editedItem.itemVAT2Amount = this.itemLoc.itemVAT2Amount
              this.editedItem.itemDirectDuty1 = this.round(this.itemLoc.itemDirectDuty1, 2)
              this.editedItem.itemDirectDuty2 = this.itemLoc.itemDirectDuty2
              this.editedItem.itemPriceWithVAT = this.itemLoc.itemPriceWithVAT
              this.editedItem.itemForePrice = this.itemLoc.itemForePrice
              this.editedItem.itemForeVAT1Percentage = this.itemLoc.itemForeVAT1Percentage
              this.editedItem.itemForeVAT1Amount = this.itemLoc.itemForeVAT1Amount
              this.editedItem.itemForeVAT2Percentage = this.itemLoc.itemForeVAT2Percentage
              this.editedItem.itemForeVAT2Amount = this.itemLoc.itemForeVAT2Amount
              this.editedItem.itemForeDirectDuty1 = this.itemLoc.itemForeDirectDuty1
              this.editedItem.itemForeDirectDuty2 = this.itemLoc.itemForeDirectDuty2
              this.editedItem.itemForePriceWithVAT = this.itemLoc.itemForePriceWithVAT
              this.editedItem.newitemPrice = this.round(this.itemLoc.itemPrice, 3)
              this.editedItem.newitemVAT1Percentage = this.round(this.itemLoc.itemVAT1Percentage, 2)
              this.editedItem.newitemVAT1Amount = this.itemLoc.itemVAT1Amount
              this.editedItem.newitemDirectDuty1 = this.round(this.itemLoc.itemDirectDuty1, 2)
              this.editedItem.newitemPriceWithVAT = this.itemLoc.itemPriceWithVAT

              this.editedItem.itemAmountWithVAT = 0
              this.editedItem.itemBaseAmountNoVAT = 0
              this.editedItem.itemVAT1Amount = 0
              this.editedItem.itemBaseAmount = 0
              this.editedItem.itemDirectDuty1Amount = 0
              this.editedItem.itemFullTotal = 0
              this.editedItem.newitemAmountWithVAT = 0
              this.editedItem.newitemBaseAmount = 0
              this.editedItem.newitemVAT1Amount = 0
              this.editedItem.newitemFullTotal = 0

              this.doParse()
              this.calculatePrice()
            }
            // console.log(' nasao sam artikla ', this.itemLoc.itemName)        
        } catch (error) {
          // eslint-disable-next-line
          console.log(' greska Ne mogu naci artikla', error)
        }
      },
      initPcs() {
        this.pcsLoc = 0
        if (this.$store.state.documentSide === 1) {
          this.pcsLoc = this.editedItem.itemPcsPlus ? this.editedItem.itemPcsPlus : 0
        }
        if (this.$store.state.documentSide === 2) {
          this.pcsLoc = this.editedItem.itemPcsMinus ? this.editedItem.itemPcsMinus : 1
        }
        if (this.$store.state.documentSide === 3) {
          this.pcsLoc = this.editedItem.itemPcsNeutral ? this.editedItem.itemPcsNeutral : 0
        }
        this.pcsLoc = this.round(this.pcsLoc, 3)
      },
      calculatePrice () {
        
        this.initPcs()     
        this.editedItem.itemDirectDuty1 = this.editedItem.itemDirectDuty1 ? this.round(this.editedItem.itemDirectDuty1, 2) : 0
        this.editedItem.itemVAT1Percentage = this.editedItem.itemVAT1Percentage ? this.round(this.editedItem.itemVAT1Percentage, 2) : 0
        this.editedItem.itemPrice = this.editedItem.itemPrice ? this.round(this.editedItem.itemPrice, 3) : 0
        this.editedItem.itemPriceWithVAT = this.editedItem.itemPriceWithVAT ? this.round(this.editedItem.itemPriceWithVAT, 3) : 0

        this.editedItem.itemAmountWithVAT = this.round((this.editedItem.itemPriceWithVAT * this.pcsLoc), 2)
        
        this.editedItem.itemBaseAmountNoVAT = this.editedItem.itemAmountWithVAT / (1 + (this.editedItem.itemVAT1Percentage / 100))
        this.editedItem.itemBaseAmountNoVAT = this.round(this.editedItem.itemBaseAmountNoVAT, 2)
        this.editedItem.itemVAT1Amount = (this.editedItem.itemAmountWithVAT - this.editedItem.itemBaseAmountNoVAT)
        this.editedItem.itemVAT1Amount = this.round(this.editedItem.itemVAT1Amount, 2)
        this.editedItem.itemBaseAmount = this.round((this.editedItem.itemBaseAmountNoVAT), 3)
        this.editedItem.itemDirectDuty1Amount = this.round((this.editedItem.itemDirectDuty1 * this.pcsLoc), 2)
        this.editedItem.itemFullTotal = (this.editedItem.itemAmountWithVAT + this.editedItem.itemDirectDuty1Amount)
        this.editedItem.itemFullTotal = this.round(this.editedItem.itemFullTotal, 2)

        this.editedItem.newitemDirectDuty1 = this.editedItem.newitemDirectDuty1 ? this.round(this.editedItem.newitemDirectDuty1, 2) : 0
        this.editedItem.newitemVAT1Percentage = this.editedItem.newitemVAT1Percentage ? this.round(this.editedItem.newitemVAT1Percentage, 2) : 0
        this.editedItem.newitemPrice = this.editedItem.newitemPrice ? this.round(this.editedItem.newitemPrice, 3) : 0
        this.editedItem.newitemPriceWithVAT = this.editedItem.newitemPriceWithVAT ? this.round(this.editedItem.newitemPriceWithVAT, 3) : 0

        this.editedItem.newitemAmountWithVAT = this.round((this.editedItem.newitemPriceWithVAT * this.pcsLoc), 2)

        this.editedItem.newitemBaseAmount = this.editedItem.newitemAmountWithVAT / (1 + (this.editedItem.newitemVAT1Percentage / 100))
        this.editedItem.newitemBaseAmount = this.round(this.editedItem.newitemBaseAmount, 2)
        this.editedItem.newitemVAT1Amount = (this.editedItem.newitemAmountWithVAT - this.editedItem.newitemBaseAmount)
        this.editedItem.newitemVAT1Amount = this.round(this.editedItem.newitemVAT1Amount, 2)
        this.editedItem.newitemFullTotal = (this.editedItem.newitemAmountWithVAT) + this.round((this.editedItem.newitemDirectDuty1 * this.pcsLoc), 2)
        this.editedItem.newitemFullTotal = this.round(this.editedItem.newitemFullTotal, 2)

      },
      makeSum() {
        const exRate = this.$store.getters.NeedDefExRate
        const updateSumObject = {
          documentDescription9: 0,
          documentDescription10: 0,
          documentTotalVAT1Per: 0,
          documentTotalVAT1Base: 0,
          documentTotalVAT1Amount: 0,
          documentTotalVAT2Per: 0,
          documentTotalVAT2Base: 0,
          documentTotalVAT2Amount: 0,
          documentTotalVAT3Per: 0,
          documentTotalVAT3Base: 0,
          documentTotalVAT3Amount: 0,
          documentTotalVAT4Per: 0,
          documentTotalVAT4Base: 0,
          documentTotalVAT4Amount: 0,
          documentTotalVAT5Per: 0,
          documentTotalVAT5Base: 0,
          documentTotalVAT5Amount: 0,
          documentTotalAmountNetto: 0,
          documentTotalAmountVAT: 0,
          documentTotalAmountBrutto: 0,
          documentTotalAmountPayInAdvance: 0,
          documentTotalAmountToPay: 0,
          documentTotalAmountNettoForeign: 0,
          documentTotalAmountVATForeign: 0,
          documentTotalAmountBruttoForeign: 0,
          documentTotalAmountPayInAdvanceForeign: 0,
          documentTotalAmountToPayForeign: 0,
          documentTotalAmountDirectD1: 0,
          documentTotalAmountDirectD2: 0,
          documentTotalAmountDirectD1Fore: 0,
          documentTotalAmountDirectD2Fore: 0,
          FreeF1: 0,
          FreeF2: 0,
          FreeF3: 0,
          FreeF4: 0,
          FreeF5: 0,
          FreeF6: 0,
          documentHomeCurrency: 'Kn',
          documentForeignCurrency: 'EUR',
          documentExRate: exRate,
          newBaseAmount: 0,
          newVAT1Amount: 0,
          newAmountWithVAT: 0,
          newDirectDuty1: 0,
          newFullTotal: 0,
        }
        
        const hrVat1 = 25
        const hrVat2 = 13
        const hrVat3 = 5
        const documentsDet = this.itemsPOS ? this.itemsPOS : []
        if (documentsDet) {
          documentsDet.map(documentDet => {
            if (documentDet.itemBaseAmount) {
              updateSumObject.documentDescription9 += parseFloat(documentDet.itemBaseAmount)
            }
            if (documentDet.itemBaseAmountNoVAT) {
              updateSumObject.documentTotalAmountNetto += parseFloat(documentDet.itemBaseAmountNoVAT)
            }
            if (documentDet.itemVAT1Amount) {
              updateSumObject.documentTotalAmountVAT += parseFloat(documentDet.itemVAT1Amount)
            }
            const dD1 = documentDet.itemDirectDuty1Amount ? parseFloat(documentDet.itemDirectDuty1Amount) : 0
            if (documentDet.itemAmountWithVAT) {
              updateSumObject.documentTotalAmountBrutto += parseFloat(documentDet.itemAmountWithVAT) + dD1
            }
            if (parseFloat(documentDet.itemVAT1Percentage) === hrVat1) {
              updateSumObject.documentTotalVAT1Amount += parseFloat(documentDet.itemVAT1Amount)
            }
            if (parseFloat(documentDet.itemVAT1Percentage) === hrVat2) {
              updateSumObject.documentTotalVAT2Amount += parseFloat(documentDet.itemVAT1Amount)
            }
            if (parseFloat(documentDet.itemVAT1Percentage) === hrVat3) {
              updateSumObject.documentTotalVAT3Amount += parseFloat(documentDet.itemVAT1Amount)
            }
            if (documentDet.itemBaseAmount) {
              if (parseInt(documentDet.FreeF1) === 1) {
                updateSumObject.FreeF1 += parseFloat(documentDet.itemBaseAmount)
              }
              if (parseInt(documentDet.FreeF1) === 2) {
                updateSumObject.FreeF2 += parseFloat(documentDet.itemBaseAmount)
                updateSumObject.FreeF5 += parseFloat(documentDet.empWrkHrs)
              }
              if (parseInt(documentDet.FreeF1) === 3) {
                updateSumObject.FreeF3 += parseFloat(documentDet.itemBaseAmount)
              }
            }
            if (documentDet.itemBaseAmountNoVAT) {
              if (parseInt(documentDet.FreeF1) === 4) {
                updateSumObject.FreeF4 += parseFloat(documentDet.itemBaseAmountNoVAT)
              }
            }
            if (documentDet.itemDirectDuty1Amount) {
              updateSumObject.documentTotalAmountDirectD1+= parseFloat(documentDet.itemDirectDuty1Amount)
            }
            if (documentDet.newitemBaseAmount) {
              updateSumObject.newBaseAmount+= parseFloat(documentDet.newitemBaseAmount)
            }
            if (documentDet.newitemVAT1Amount) {
              updateSumObject.newVAT1Amount+= parseFloat(documentDet.newitemVAT1Amount)
            }
            if (documentDet.newitemAmountWithVAT) {
              updateSumObject.newAmountWithVAT+= parseFloat(documentDet.newitemAmountWithVAT)
            }
            if (documentDet.newitemDirectDuty1) {
              updateSumObject.newDirectDuty1+= parseFloat(documentDet.newitemDirectDuty1)
            }
            if (documentDet.newitemFullTotal) {
              updateSumObject.newFullTotal+= parseFloat(documentDet.newitemFullTotal)
            }
          })
        }
        updateSumObject.documentDescription9 = updateSumObject.documentDescription9 ? this.round(updateSumObject.documentDescription9, 2) : 0
        updateSumObject.documentTotalAmountNetto = updateSumObject.documentTotalAmountNetto ? this.round(updateSumObject.documentTotalAmountNetto, 2) : 0
        updateSumObject.documentTotalAmountBrutto = updateSumObject.documentTotalAmountBrutto ? this.round(updateSumObject.documentTotalAmountBrutto, 2) : 0
        updateSumObject.documentTotalVAT1Amount = updateSumObject.documentTotalVAT1Amount ? this.round(updateSumObject.documentTotalVAT1Amount, 2) : 0
        updateSumObject.documentTotalVAT2Amount = updateSumObject.documentTotalVAT2Amount ? this.round(updateSumObject.documentTotalVAT2Amount, 2) : 0
        updateSumObject.documentTotalVAT3Amount = updateSumObject.documentTotalVAT3Amount ? this.round(updateSumObject.documentTotalVAT3Amount, 2) : 0
        updateSumObject.documentTotalVAT1Per = hrVat1 ? this.round(hrVat1, 2) : 0
        updateSumObject.documentTotalVAT2Per = hrVat2 ? this.round(hrVat2, 2) : 0
        updateSumObject.documentTotalVAT3Per = hrVat3 ? this.round(hrVat3, 2) : 0
        updateSumObject.documentTotalVAT1Base = hrVat1 && updateSumObject.documentTotalVAT1Amount ? this.round(updateSumObject.documentTotalVAT1Amount / (hrVat1/100), 2) : 0
        updateSumObject.documentTotalVAT2Base = hrVat2 && updateSumObject.documentTotalVAT2Amount ? this.round(updateSumObject.documentTotalVAT2Amount / (hrVat2/100), 2) : 0
        updateSumObject.documentTotalVAT3Base = hrVat3 && updateSumObject.documentTotalVAT3Amount ? this.round(updateSumObject.documentTotalVAT3Amount / (hrVat3/100), 2) : 0
        updateSumObject.FreeF1 = updateSumObject.FreeF1 ? this.round(updateSumObject.FreeF1, 2) : 0
        updateSumObject.FreeF2 = updateSumObject.FreeF2 ? this.round(updateSumObject.FreeF2, 2) : 0
        updateSumObject.FreeF3 = updateSumObject.FreeF3 ? this.round(updateSumObject.FreeF3, 2) : 0
        updateSumObject.FreeF4 = updateSumObject.FreeF4 ? this.round(updateSumObject.FreeF4, 2) : 0
        updateSumObject.FreeF5 = updateSumObject.FreeF5 ? this.round(updateSumObject.FreeF5, 2) : 0
        updateSumObject.documentDescription10 = updateSumObject.documentDescription9 - updateSumObject.documentTotalAmountNetto
        updateSumObject.documentTotalAmountPayInAdvance = 0
        updateSumObject.documentTotalAmountToPay = updateSumObject.documentTotalAmountBrutto ? this.round(updateSumObject.documentTotalAmountBrutto, 2) : 0
        updateSumObject.documentTotalAmountNettoForeign = updateSumObject.documentTotalAmountNetto ? this.round((updateSumObject.documentTotalAmountNetto / exRate), 2) : 0
        updateSumObject.documentTotalAmountVATForeign = updateSumObject.documentTotalAmountVAT ? this.round((updateSumObject.documentTotalAmountVAT / exRate), 2) : 0
        updateSumObject.documentTotalAmountBruttoForeign = updateSumObject.documentTotalAmountBrutto ? this.round((updateSumObject.documentTotalAmountBrutto / exRate), 2) : 0
        updateSumObject.documentTotalAmountPayInAdvanceForeign = 0
        updateSumObject.documentTotalAmountToPayForeign = updateSumObject.documentTotalAmountBrutto ? this.round((updateSumObject.documentTotalAmountBrutto / exRate), 2) : 0
        updateSumObject.documentTotalAmountDirectD1Fore = updateSumObject.documentTotalAmountDirectD1 ? this.round((updateSumObject.documentTotalAmountDirectD1 / exRate), 2) : 0
        updateSumObject.documentHomeCurrency = 'Kn'
        updateSumObject.documentForeignCurrency = 'EUR'
        updateSumObject.documentExRate =  this.$store.getters.NeedDefExRate
        updateSumObject.newBaseAmount = updateSumObject.newBaseAmount ? this.round(updateSumObject.newBaseAmount, 2) : 0
        updateSumObject.newVAT1Amount = updateSumObject.newVAT1Amount ? this.round(updateSumObject.newVAT1Amount, 2) : 0
        updateSumObject.newAmountWithVAT = updateSumObject.newAmountWithVAT ? this.round(updateSumObject.newAmountWithVAT, 2) : 0
        updateSumObject.newDirectDuty1 = updateSumObject.newDirectDuty1 ? this.round(updateSumObject.newDirectDuty1, 2) : 0
        updateSumObject.newFullTotal = updateSumObject.newFullTotal ? this.round(updateSumObject.newFullTotal, 2) : 0
        const newHead = {...this.$store.state.documentActivHead, ...updateSumObject}
        if (newHead) {
          this.$store.dispatch('setDocumentActivHead', newHead)
        } else {
          this.$store.dispatch('setDocumentActivHead', {})
        }
      },
      round (value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
      },
      getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min) ) + min;
      },

      initialize () {
         this.errorMatch = false
         this.adddefaultItem()
         this.editedItem = Object.assign({}, this.defaultItem)
         this.select = {}
         this.selectIL = {}     
      },
      editItem (item) {
        this.errorMatch = false
        this.editedIndex = this.itemsPOS.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
          const index = this.itemsPOS.indexOf(item)
          const warningMessage = item.itemName
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.itemsPOS.splice(index, 1)) {
            this.initialize()
            this.makeSum()
          }        
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

     save () {

        //let doneOK = false
        if (this.editedIndex > -1) {
          // edit in array
          Object.assign(this.itemsPOS[this.editedIndex], this.editedItem)
        } else {
          this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
          this.editedItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
          this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
          this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
          this.editedItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : ''
          // put in array
          this.itemsPOS.push(this.editedItem)
        }
        if (this.itemsPOS) {
          this.$store.dispatch('setDocumentActivItems', this.itemsPOS)
        }
        this.makeSum()
        this.initialize()
        this.close()
      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      }
    },
  }
</script>