<template>
    <v-row no-gutters>
        <v-col xs="12" sm="12" md="12" lg="12" ex="12">

              <v-row style="border-bottom:solid 1px;">
                  <v-col xs="5">
                      <h4><b><i>{{comp.CompanyName}}</i></b></h4>
                      {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}   <br />                
                      {{comp.CompanyPostcode}} {{comp.CompanyCity}}<br />
                      {{lang.VATID}} {{comp.CompanyVATID}}
                  </v-col>
                  <v-col xs="3">
                    <h2 v-if="type === 1">Lista proizvedenog prema radnim nalozima</h2>
                    <h2 v-if="type === 2">Lista razduženog prema radnim nalozima</h2>
                    {{head2}} <br />
                    {{head3}} <br />
                    {{head4}} <br />
                    {{head5}}
                  </v-col>
                  <v-col xs="4">
                    <p class="text-md-left">
                        {{langC.PrintDate}} {{PrintDate}}<br />
                        email: {{comp.Companyemail}}, {{comp.CompanyPhones}}<br />
                    </p>
                  </v-col>
              </v-row>
                <v-row>
                  <v-col cols="12" sm="9" md="9" xs="9" lg="9" xl="9">
  
                  </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                      <v-btn
                        text color="primary"
                        @click="downloadFile()">
                        excel
                      </v-btn>
                    <v-btn
                        text color="primary"
                        @click="printAna()">
                        {{langC.Print}}
                      </v-btn>
                    </v-col>
                </v-row>
          
                <v-row style="border-bottom:solid 1px; border-top:solid 1px;" class="mb-2">
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-xs-left" style="font-size:10px;">
                      {{lang.DocPrintOrder}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" style="font-size:10px;">
                      {{ lang.ItemID }}
                    </v-col>
                    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="text-left" style="font-size:10px;">
                      {{ lang.ItemGroup }}
                    </v-col>
                    <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="text-left" style="font-size:10px;">
                      {{ lang.ItemName }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{lang.ItemUnit}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ type ===1 ? lang.ItemPcsPlus : lang.ItemPcsMinus }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ lang.ItemSuppBasePriceAmount }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ lang.ItemBaseAmount }}
                    </v-col>
                </v-row>

                <v-row v-for="item in finalItems" :key="item.id" dense>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-xs-left" style="font-size:10px;">
                      {{item.id}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" style="font-size:10px;">
                      {{ item.itemID }}
                    </v-col>
                    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="text-left" style="font-size:10px;">
                      {{ item.itemGroupName }}
                    </v-col>
                    <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="text-left" style="font-size:10px;">
                      {{ item.itemName }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{item.itemUnit}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(item.psc) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(item.itemSuppBasePriceAmount) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(item.itemBaseAmount) }}
                    </v-col>
               
            </v-row>
            <v-row style="border-top:solid 1px;">
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;"> 
                .
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="text-right" style="font-size:10px;">

              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="text-right" style="font-size:12px;">

              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
                {{ localMoney(Total1) }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                {{ localMoney(Total2) }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                {{ localMoney(Total4) }}
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
                <DocumentPrintSalesListWO ref="prnt" />
              </v-col>
            </v-row>

            <v-row v-if="errorItems.length > 0">
              <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
                <DocumentSalesErrorListWO :errorItems="errorItems" />
              </v-col>
            </v-row>

        </v-col>
    </v-row>
</template>


<script>
import DocumentPrintSalesListWO from './DocumentPrintSalesListWO'
import DocumentSalesErrorListWO from './DocumentSalesErrorListWO'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import moneyFormat from '@/snippets/moneyFormat'
import { parseNum} from '@/snippets/allSnippets'
import xlsx from 'json-as-xlsx'
import dayjs from 'dayjs'

export default {
  name: 'salesanalistWO_2',
  props: ['allitems', 'headitems', 'head1', 'head2', 'head3', 'head4', 'head5', 'type', 'productiontypes'],
  data: ()=> ({
    comp: {},
    lang: {},
    langC: {},
    Total1: 0,
    Total2: 0,
    Total3: 0,
    Total4: 0,
    Total5: 0,
    Total6: 0,
    Total7: 0,
    Total8: 0,
    Total9: 0,
    Total10: 0,
    PrintDate: null,
    ana: {},
    finalItems: [],
    errorItems: []
  }),
  beforeMount() {
    console.log('before mounted allItems', this.allItems, 'headitems', this.headitems)
    const condition1 = (this.type === 1 ? 33 : 3)

    const sideItems = this.allitems.filter(allitem =>  (parseInt(allitem.FreeF6) === condition1 &&  parseInt(allitem.statusDoc2) > 3))
    const testItems = this.allitems.filter(allitem =>  (allitem.statusDoc2 > 3))

    const groupDoc = Array.from(testItems.reduce(
      (m, {documentIdGeneral, itemID}) => m.set(documentIdGeneral, (m.get(documentIdGeneral) || 0) + parseNum(itemID,2)), new Map
    ), ([documentIdGeneral, itemID]) => ({documentIdGeneral, itemID}));
    
    let total3 = 0
    let total33 = 0
    let total333 = 0
    groupDoc.map(item => {
      const onlyDoc = this.allitems.filter(doc => doc.documentIdGeneral === item.documentIdGeneral)
      const onlyHead = this.headitems.find(doc => doc.documentIdGeneral === item.documentIdGeneral)

      total3 = 0
      total33 = 0
      total333 = 0

      onlyDoc.map(subDoc => {
        if (parseInt(subDoc.FreeF6) === 3) {
          total3 += parseNum(subDoc.itemSuppBasePriceAmount,2)
        }
        if (parseInt(subDoc.FreeF6) === 33){
          total33 += parseNum(subDoc.itemSuppBasePriceAmount,2)
          total333 += parseNum(subDoc.itemBaseAmountNoVAT,2)
        }
      })
     
      if (Math.abs(parseNum(total3,2) - parseNum(total33,2)) > 0.04) {
        const newDoc = {}
        newDoc.documentIdGeneral = item.documentIdGeneral
        newDoc.supp3 = total3
        newDoc.supp33 = total33
        newDoc.diff = parseNum(total3,2) - parseNum(total33,2) 
        this.errorItems.push(newDoc) 
      }
     // const razPro = parseNum(onlyHead.FreeF4,2) - parseNum(total333,2)
      // if (razPro !== 0) {
      // console.log(onlyHead.documentId, 'proiz glava = ', parseNum(onlyHead.FreeF4,2), ' stavke=', parseNum(total333,2), 'raz = ', razPro)
      // }
      if (onlyHead) {
        if (Math.abs(parseNum((onlyHead.FreeF4 ? onlyHead.FreeF4 : 0) ,2) - parseNum(total333,2)) > 0.04) {
          const newDoc = {}
          newDoc.documentIdGeneral = item.documentIdGeneral + ' proiz'
          newDoc.supp3 = parseNum(onlyHead.FreeF4)
          newDoc.supp33 = total333
          newDoc.diff = parseNum(onlyHead.FreeF4,2) - parseNum(total333,2) 
          this.errorItems.push(newDoc) 
        }
        //const razMat = parseNum(onlyHead.FreeF1,2) - parseNum(total3,2)
        // if (razMat !== 0) {
        // console.log(onlyHead.documentId, 'materijal glava = ', parseNum(onlyHead.FreeF1,2), ' stavke=', parseNum(total3,2), 'raz = ', razMat)}
        if (Math.abs(parseNum(onlyHead.FreeF1,2) - parseNum(total3,2)) > 0.04) {
          const newDoc = {}
          newDoc.documentIdGeneral = item.documentIdGeneral + ' mat'
          newDoc.supp3 = parseNum(onlyHead.FreeF1)
          newDoc.supp33 = total3
          newDoc.diff = parseNum(onlyHead.FreeF1,2) - parseNum(total3,2) 
          this.errorItems.push(newDoc) 
        }
      }
    })
    
    const res = Array.from(sideItems.reduce(
      (m, {itemID, itemSuppPriceAmount}) => m.set(itemID, (m.get(itemID) || 0) + parseNum(itemSuppPriceAmount,2)), new Map
    ), ([itemID, itemSuppPriceAmount]) => ({itemID, itemSuppPriceAmount}));

    this.Total1 = 0
    this.Total2 = 0
    this.Total3 = 0
    this.Total4 = 0
    this.Total5 = 0

    if (res && this.allitems) {
        let nr = 0
        
        res.map(item => {
          const onlyItems = sideItems.filter(subitem => subitem.itemID === item.itemID)
          let itemTotal1 = 0
          let itemTotal2 = 0
          let itemTotal3 = 0
          let itemTotal4 = 0
          let itemTotal5 = 0
          onlyItems.map(ite => {
            itemTotal1 += parseNum(ite.itemPcsPlus, 2)
            itemTotal2 += parseNum(ite.itemPcsMinus, 2)
            itemTotal3 += parseNum(ite.itemSuppBasePriceAmount, 2)
            itemTotal4 += parseNum(ite.itemBaseAmount, 2)
            itemTotal5 += parseNum(ite.itemAmountWithVAT, 2)
          })
          nr +=1
          const newTotalItem = {}
          newTotalItem.id = nr
          newTotalItem.itemID = item.itemID
          newTotalItem.itemGroupName = onlyItems[0].itemGroupName
          newTotalItem.itemName = onlyItems[0].itemName
          newTotalItem.itemUnit = onlyItems[0].itemUnit        
          newTotalItem.psc = this.type === 1 ? parseNum(itemTotal1, 2) : parseNum(itemTotal2, 2)
          newTotalItem.itemSuppBasePriceAmount = parseNum(itemTotal3, 2)
          newTotalItem.itemBaseAmount = parseNum(itemTotal4, 2)
          newTotalItem.itemFullTotal = parseNum(itemTotal5, 2)
          this.finalItems.push(newTotalItem)
          this.Total1 += this.type === 1 ? parseNum(itemTotal1, 2) : parseNum(itemTotal2, 2)
          this.Total2 += parseNum(itemTotal3, 2)
          this.Total4 += parseNum(itemTotal4, 2)
          this.Total5 += parseNum(itemTotal5, 2)
        })
      }

      this.PrintDate = dayjs().format('DD.MM.YYYY, H:mm')
      // if (this.finalItems) {
      //     this.finalItems.map(ite => {
      //         this.Total1 += parseNum(ite.psc, 2) 
      //         this.Total2 += parseNum(ite.itemSuppBasePriceAmount, 2)
      //         this.Total3 += parseNum(ite.itemBaseAmountNoVAT, 2)
      //     })
      // }
  },
  mounted () {
    console.log('mounted allItems', this.allItems, 'headitems', this.headitems)
    this.comp = this.$store.state.companyActiv
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }


  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    async printAna() {
      const newObj = {}
      this.ana = {...newObj}
      this.ana = {...this.documentAna}
      this.ana.items = this.finalItems ? this.finalItems : []
      this.ana.head1 = this.type === 1 ? 'Lista proizvedenog prema radnim nalozima' : 'Lista razduženog prema radnim nalozima'
      this.ana.head2 = this.head2
      this.ana.head3 = this.head3
      this.ana.head4 = this.head4
      this.ana.head5 = this.head5
      this.ana.type = this.type
      this.ana.Total1 = this.Total1
      this.ana.Total2 = this.Total2
      this.ana.Total3 = this.Total3
      await this.$store.dispatch('setPrintObj', this.ana)
      await this.$refs.prnt.print()
    },
    downloadFile () {

      this.topHeader = 'RadniNalozi' + this.$store.state.businessYear + this.lang.ReportCreated + dayjs().format('DD.MM.YYYY HH:MM')
      const final2 = [...this.finalItems]
      const newObj = {}
      newObj.id = ''
      newObj.itemID = ''
      newObj.itemGroupName = ''
      newObj.itemName = ''
      newObj.itemUnit = ''
      newObj.psc = this.Total1
      newObj.itemSuppBasePriceAmount = this.Total2
      newObj.itemBaseAmountNoVAT = this.Total3
      final2.push(newObj)

      let sheetNm = ''
      let fileNm = ''
      const currDate = dayjs().format('DD_MM_YYYY_HH_MM')
      if (this.$store.state.language === 'EN') {
       sheetNm = this.type === 1 ? 'WrkOrd_PRO_' + currDate : 'WrkOrd_UTR_' + currDate
       fileNm = this.type === 1 ? 'WrkOrd_PRO_' + currDate : 'WrkOrd_UTR_' + currDate 
      }
      if (this.$store.state.language === 'HR') {
       sheetNm = this.type === 1 ? 'RadNal_PRO_' + currDate : 'RadNal_UTR_' + currDate
       fileNm = this.type === 1 ? 'RadNal_PRO_' + currDate : 'RadNal_UTR_' + currDate 
      }
      let data = [
        {
          sheet: sheetNm,
          columns: [
            { label: this.lang.DocPrintOrder, value: 'id'},
            { label: this.lang.ItemID, value: 'itemID'},
            { label: this.lang.ItemGroup, value: 'itemGroupName'},
            { label: this.lang.ItemName, value: 'itemName' },
            { label: this.lang.ItemUnit, value: 'itemUnit' },
            { label: (this.type ===1 ? this.lang.ItemPcsPlus : this.lang.ItemPcsMinus), value: 'psc' },
            { label: this.lang.ItemSuppBasePriceAmount, value: 'itemSuppBasePriceAmount' },
            { label: this.lang.ItemBaseAmountNoVAT, value: 'itemBaseAmountNoVAT' },
          ],
          content: final2
        },
      ]
      
      let settings = {
        fileName: fileNm, // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
      }

      xlsx(data, settings)

    }

  },
  computed: {
  },
  watch: {

  },
  components: {
    DocumentPrintSalesListWO,
    DocumentSalesErrorListWO
  }

}

</script>