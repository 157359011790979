import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('docdesc', {
      params: query 
    })
  },
  choose (query) {
    return Api().get('docdesc/choose', {
      params: query 
    })
  },
  show (docdescId) {
    return Api().get(`docdesc/${docdescId}`)
  },
  post (newdocdesc) {
    return Api().post('docdesc', newdocdesc)
  },
  put (docdesc) {
    return Api().put(`docdesc/${docdesc.id}`, docdesc)
  },
  delete (docdescId) {
    return Api().delete(`docdesc/${docdescId}`)
  }
}