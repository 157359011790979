<template>
  <div class="mainledgerpayprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'

export default {
  name: 'mainledgerpayprint',
  props: ['pay'],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0,
      partner: '',
      tot1: 0,
      tot2: 0,
      tot3: 0
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    
  },
  methods: {
    async print () {
      await this.initDocument()
      await this.addHeaderNoLogo()
      if (this.$store.state.mainledgerStatus === 1) {
        await this.addItemsIn()
      }
      if (this.$store.state.mainledgerStatus === 2) {
        await this.addItemsOut()
      }
      await this.addEmptyRow()

      await this.addSignature2()

      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.content = []
      // this.docDefinition.header = {
      //   columns: [
      //       {
      //           fontSize: 9,
      //           text:[
      //           {
      //           text: + '\n',               
      //           },
      //           {
      //           margin: [10, 10],
      //           text: 'Obrazac PDV ',
      //           }
      //           ],
      //           alignment: 'right'
      //       }
      //   ]
      // }
      // this.docDefinition.footer = function(currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
      // this.docDefinition.header = function(currentPage, pageCount, pageSize) {
      //     // you can apply any logic and return any valid pdfmake element
      //     return [
      //       { text: 'simple text', alignment: (currentPage % 2) ? 'left' : 'right' },
      //       { canvas: [ { type: 'rect', x: 170, y: 32, w: pageSize.width - 170, h: 40 } ] }
      //     ]
      //   },
      // this.footer: function(currentPage, pageCount) {
      //   return {
      //       margin:10,
      //       columns: [
      //       {
      //           fontSize: 9,
      //           text:[
      //           {
      //           text: '--------------------------------------------------------------------------' +
      //           '\n',
      //           margin: [0, 20]
      //           },
      //           {
      //           text: '© xyz pvt., ltd. ' + currentPage.toString() + ' of ' + pageCount,
      //           }
      //           ],
      //           alignment: 'center'
      //       }
      //       ]
      //   }
      // },
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 500,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo () {
      let header = ''
      if (this.$store.state.mainledgerStatus === 1) {
        header = 'Kartica - URA'
      }
      if (this.$store.state.mainledgerStatus === 2) {
        header = 'Kartica - IRA'
      }
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '30%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 16, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '30%',
                text: [
                  { text: header + '\n', fontSize: 16, bold: true },
                  { text: ' \n', fontSize: 10, bold: true },
                  { text: ' \n', fontSize: 8 },
                ]
              },

              {
                // % width
                width: '30%',
                text: [
                  { text: 'Ispis: ' + dayjs().format('DD.MM.YYYY, HH:MM') + '\n', fontSize: 8, alignment: 'right' },
                  { text: ' \n', fontSize: 10},
                  { text: ' \n', fontSize: 10},
                ]   
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addPartner (item) {
        const docPart = {
        columns: [          
              {
                width: '70%',
                fontSize: 8,
                alignment: 'left',
                text: [
                  { text: item.partnerName + ' \n', fontSize: 16, bold: true },
                  { text: (item.partnerStreet ? item.partnerStreet : ' ') + ' ' + (item.partnerBuildingNameNumber ? item.partnerBuildingNameNumber : ' ') + (item.partnerPostcode ? item.partnerPostcode : ' ') + ' ' + (item.partnerCity ? item.partnerCity : ' ') + ', OIB:' + (item.partnerVATID ? item.partnerVATID : ' ') +  ' \n', fontSize: 8 },
                ]
              },
            ]
          }
        this.content.push(docPart)        
    },
    addItemsHeaderIn () {
        const docPart = {
        columns: [
                  {
                    width: '5%',
                    fontSize: 8,
                    alignment: 'left',
                    text: 'Rb.'
                  },
                  {
                    width: '6%',
                    fontSize: 8,
                    alignment: 'left',
                    text: 'URA br'
                  },
                  {
                    width: '3%',
                    fontSize: 8,
                    alignment: 'left',
                    text: 'Vr'
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: 'Datum knji.'
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'right',
                    text: 'Broj računa'
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: 'Datum računa'
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: 'Rok plaćanja' 
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: 'Iznos računa'
                  },
                  {
                    width: '7%',
                    fontSize: 8,
                    alignment: 'right',
                    text: 'Plačeno'
                  },
                  {
                    width: '3%',
                    fontSize: 8,
                    alignment: 'right',
                    text: 'Val'
                  },
                  {
                    width: '7%',
                    fontSize: 8,
                    alignment: 'right',
                    text: 'Plačeno dat.' 
                  },
                  {
                    width: '6%',
                    fontSize: 8,
                    alignment: 'right',
                    text: 'Zatvoren'
                  }
            ]
          }
        this.content.push(docPart)        
    },
    addItemsHeaderOut () {
        const docPart = {
        columns: [
                {
                  width: '5%',
                  fontSize: 8,
                  alignment: 'left',
                  text: 'Rb.'
                },
                {
                  width: '10%',
                  fontSize: 8,
                  alignment: 'left',
                  text: 'IRA br'
                },
                {
                  width: '8%',
                  fontSize: 8,
                  alignment: 'right',
                  text: 'Datum računa'
                },
                {
                  width: '8%',
                  fontSize: 8,
                  alignment: 'right',
                  text: 'Rok plaćanja' 
                },
                {
                  width: '8%',
                  fontSize: 8,
                  alignment: 'right',
                  text: 'Iznos računa'
                },
                {
                  width: '8%',
                  fontSize: 8,
                  alignment: 'right',
                  text: 'Plačeno'
                },
                {
                  width: '3%',
                  fontSize: 8,
                  alignment: 'right',
                  text: 'Val'
                },
                {
                  width: '8%',
                  fontSize: 8,
                  alignment: 'right',
                  text: 'Plačeno dat.'
                },
                {
                  width: '5%',
                  fontSize: 8,
                  alignment: 'right',
                  text: 'Zatvoren'
                },
                {
                  width: '6%',
                  fontSize: 8,
                  alignment: 'left',
                  text: 'Br.knji.'
                },
            ]
          }
        this.content.push(docPart)        
    },
    addTotalPartnerIn () {
        this.tot3 = this.tot1 - this.tot2
        const docPart = {
        columns: [
                  {
                    width: '5%',
                    fontSize: 8,
                    alignment: 'left',
                    text: ' '
                  },
                  {
                    width: '6%',
                    fontSize: 8,
                    alignment: 'left',
                    text: ' '
                  },
                  {
                    width: '3%',
                    fontSize: 8,
                    alignment: 'left',
                    text: ' '
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: ' '
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'right',
                    text: ' '
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: ' '
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: ' ' 
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.formatPrice(this.tot1 ? this.tot1 : 0)
                  },
                  {
                    width: '7%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.formatPrice(this.tot2 ? this.tot2 : 0)
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.formatPrice(this.tot3 ? this.tot3 : 0)
                  },
                  {
                    width: '6%',
                    fontSize: 8,
                    alignment: 'right',
                    text: ' '
                  }
            ]
          }
        this.content.push(docPart)        
    },
    addTotalPartnerOut () {
        this.tot3 = this.tot1 - this.tot2
        const docPart = {
        columns: [
                {
                  width: '5%',
                  fontSize: 8,
                  alignment: 'left',
                  text: ' '
                },
                {
                  width: '10%',
                  fontSize: 8,
                  alignment: 'left',
                  text: ' '
                },
                {
                  width: '8%',
                  fontSize: 8,
                  alignment: 'right',
                  text: ' '
                },
                {
                  width: '8%',
                  fontSize: 8,
                  alignment: 'right',
                  text: ' ' 
                },
                {
                  width: '8%',
                  fontSize: 8,
                  alignment: 'right',
                  text: this.formatPrice(this.tot1 ? this.tot1 : 0)
                },
                {
                  width: '8%',
                  fontSize: 8,
                  alignment: 'right',
                  text: this.formatPrice(this.tot2 ? this.tot2 : 0)
                },
                {
                  width: '10%',
                  fontSize: 8,
                  alignment: 'right',
                  text: this.formatPrice(this.tot3 ? this.tot3 : 0)
                },
            ]
          }
        this.content.push(docPart)        
    },
    addItemsIn () {
      // this.documentName = 'Book IN'
      // this.documentNameLoc = 'URE'
      // this.headers.push(
      //   {
      //     text: this.lang.BookID, align: 'left', sortable: true, value: 'BookID', width: '60px'
      //   },
      //   { text: this.lang.BookTypeID, align: 'left', sortable: true, value: 'BookName', width: '50px' },
      //   { text: this.lang.BookingDate, align: 'left', sortable: true, value: 'BookingDate', width: '80px' },
      //   { text: this.lang.InvoiceNumber, align: 'center', value: 'InvoiceNumber', width: '100px' },
      //   { text: this.lang.InvoiceDate, align: 'left', sortable: true, value: 'InvoiceDate', width: '80px' },
      //   { text: this.lang.InvoicePaymentDeadlineDate, align: 'left', sortable: true, value: 'InvoicePaymentDeadlineDate', width: '80px' },
      //   { text: this.lang.InvoiceAmount, align: 'right', sortable: true, value: 'InvoiceAmount', width: '100px' },
      //   { text: this.lang.PaidHome, align: 'right', sortable: true, value: 'paidHome', width: '100px' },
      //   { text: this.lang.HomeCurrency, align: 'left', sortable: true, value: 'HomeCurrency', width: '40px' },
      //   { text: this.lang.PaidDate, align: 'left', sortable: true, value: 'paidDate', width: '80px' },
      //   { text: this.lang.PaidClosed, align: 'left', sortable: true, value: 'paidClosed', width: '50px' },
      //   { text: this.lang.PartnerName, align: 'left', value: 'partnerName', width: '260px' },
      //   { text: this.lang.PartnerVATID, align: 'left', value: 'partnerVATID', width: '80px' },
      //   { text: this.lang.InvoiceForeginAmount, align: 'right', sortable: true, value: 'InvoiceForeginAmount', width: '100px' },
      //   { text: this.lang.ForeignCurrency, align: 'left', sortable: true, value: 'ForeignCurrency', width: '40px' },
      //   { text: 'DbId', value: 'id', width: '60px' }
      // )
      this.order = 0
      this.partner = ''
      if (this.pay.Items) {
        this.pay.Items.map(item => {

            if (this.partner !== item.partnerName) {
              if (this.partner.length !== 0) {
                this.addLine()
                this.addTotalPartnerIn()
                this.addLine()
                this.addEmptyRow()
              } 
              this.addEmptyRow()
              this.addLine()
              this.addPartner(item)
              this.addItemsHeaderIn()
              this.addLine()
              this.partner = item.partnerName
              this.tot1 = 0
              this.tot2 = 0
              this.tot3 = 0
            }
            this.order += 1
            this.tot1 += item.InvoiceAmount ? parseFloat(item.InvoiceAmount) : 0
            this.tot2 += item.PaidHome ? parseFloat(item.PaidHome) : 0
            // this.tot3 += item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0
            // this.pTtt8 = item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0
            // this.pTtt9 = item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0
            const docPart = {
            columns: [
                  {
                    width: '5%',
                    fontSize: 8,
                    alignment: 'left',
                    text: this.order
                  },
                  {
                    width: '6%',
                    fontSize: 8,
                    alignment: 'left',
                    text: parseInt(item.BookID)
                  },
                  {
                    width: '3%',
                    fontSize: 8,
                    alignment: 'left',
                    text: item.BookTypeID
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text:  item.BookingDate ? dayjs(item.BookingDate).format('DD.MM.YYYY') : ' '
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'right',
                    text: item.InvoiceNumber
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: item.InvoiceDate ? dayjs(item.InvoiceDate).format('DD.MM.YYYY') : ' ' 
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: item.InvoicePaymentDeadlineDate ? dayjs(item.InvoicePaymentDeadlineDate).format('DD.MM.YYYY') : ' ' 
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.formatPrice(item.InvoiceAmount ? item.InvoiceAmount : 0)
                  },
                  {
                    width: '7%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.formatPrice(item.PaidHome ? item.PaidHome : 0)
                  },
                  {
                    width: '3%',
                    fontSize: 8,
                    alignment: 'right',
                    text: item.HomeCurrency
                  },
                  {
                    width: '7%',
                    fontSize: 8,
                    alignment: 'right',
                    text: item.PaidDate ? dayjs(item.PaidDate).format('DD.MM.YYYY') : ' '  
                  },
                  {
                    width: '5%',
                    fontSize: 8,
                    alignment: 'right',
                    text: item.PaidClosed
                  }
                ]
              }
            this.content.push(docPart)
          }
        )

        if (this.partner.length !== 0) {
            this.addLine()
            this.addTotalPartnerIn()
            this.addLine()
            this.addEmptyRow()
          }
       }
    },
    addItemsOut () {
      // this.documentName = 'Book IN'
      // this.documentNameLoc = 'URE'
        // { text: this.lang.InvoiceNumber, align: 'center', value: 'InvoiceNumber', width: '100px' },
        // { text: this.lang.InvoiceDate, align: 'left', sortable: true, value: 'InvoiceDate', width: '80px' },
        // { text: this.lang.InvoicePaymentDeadlineDate, align: 'left', sortable: true, value: 'InvoicePaymentDeadlineDate', width: '80px' },
        // { text: this.lang.InvoiceAmount, align: 'right', sortable: true, value: 'InvoiceAmount', width: '100px' },
        // { text: this.lang.PaidHome, align: 'right', sortable: true, value: 'paidHome', width: '100px' },
        // { text: this.lang.HomeCurrency, align: 'left', sortable: true, value: 'HomeCurrency', width: '40px' },
        // { text: this.lang.PaidDate, align: 'left', sortable: true, value: 'paidDate', width: '80px' },
        // { text: this.lang.PaidClosed, align: 'left', sortable: true, value: 'paidClosed', width: '50px' },
        // {
        //   text: this.lang.BookID, align: 'left', sortable: true, value: 'BookID', width: '60px'
        // },
        // { text: this.lang.PartnerName, align: 'left', value: 'partnerName', width: '260px' },
        // { text: this.lang.PartnerVATID, align: 'left', value: 'partnerVATID', width: '80px' },
        // { text: 'DbId', value: 'id', width: '60px' }
      // )
      this.order = 0
      this.partner = ''
      if (this.pay.Items) {
        this.pay.Items.map(item => {

            if (this.partner !== item.partnerName) {
              if (this.partner.length !== 0) {
                this.addLine()
                this.addTotalPartnerOut()
                this.addLine()
                this.addEmptyRow()
              } 
              this.addEmptyRow()
              this.addLine()
              this.addPartner(item)
              this.addItemsHeaderOut()
              this.addLine()
              this.partner = item.partnerName
              this.tot1 = 0
              this.tot2 = 0
              this.tot3 = 0
            }
            this.order += 1
            this.tot1 += item.InvoiceAmount ? parseFloat(item.InvoiceAmount) : 0
            this.tot2 += item.PaidHome ? parseFloat(item.PaidHome) : 0
            // this.tot3 += item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0
            // this.pTtt8 = item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0
            // this.pTtt9 = item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0
            const docPart = {
            columns: [
                  {
                    width: '5%',
                    fontSize: 8,
                    alignment: 'left',
                    text: this.order
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'left',
                    text: parseInt(item.InvoiceNumber)
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: item.InvoiceDate ? dayjs(item.InvoiceDate).format('DD.MM.YYYY') : ' ' 
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: item.InvoicePaymentDeadlineDate ? dayjs(item.InvoicePaymentDeadlineDate).format('DD.MM.YYYY') : ' ' 
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.formatPrice(item.InvoiceAmount ? item.InvoiceAmount : 0)
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.formatPrice(item.PaidHome ? item.PaidHome : 0)
                  },
                  {
                    width: '3%',
                    fontSize: 8,
                    alignment: 'right',
                    text: item.HomeCurrency
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: item.PaidDate ? dayjs(item.PaidDate).format('DD.MM.YYYY') : ' '  
                  },
                  {
                    width: '5%',
                    fontSize: 8,
                    alignment: 'right',
                    text: item.PaidClosed
                  },
                  {
                    width: '6%',
                    fontSize: 8,
                    alignment: 'left',
                    text: parseInt(item.BookID)
                  },
                ]
              }
            this.content.push(docPart)
          }
        )

        if (this.partner.length !== 0) {
            this.addLine()
            this.addTotalPartnerOut()
            this.addLine()
            this.addEmptyRow()
          }
       }
    },
    addItemsFoter () {
        const docPart = {
        columns: [
              {
                width: '4%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
            
 
            ]
          }
        this.content.push(docPart)        
    },
    addTotal () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: 'Povrat: \n', fontSize: 10, alignment: 'center' },
                  { text: this.formatPrice(this.vat.Povrat2) + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: 'Predujam \n', fontSize: 10, alignment: 'center' },
                  { text: this.formatPrice(this.vat.Predujam2) + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: 'Ustup povrata \n', fontSize: 10, alignment: 'center' },
                  { text: this.formatPrice(this.vat.UstupPovrata2) + ': \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },

    addSignature1 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: 'OBRAČUN SASTAVIO \n', fontSize: 10, alignment: 'center' },
                  { text: ' \n', fontSize: 10 },
                  { text: this.lang.DocPrintMadeBy + ' ' + (this.vat.First ? this.vat.First : ' ') + ' ' + (this.vat.Last ? this.vat.Last : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '25%',
                text: ' '
              },

              {
                width: '25%',
                text: [
                  { text: 'POTPIS \n', fontSize: 10, alignment: 'center' },
                  { text: ' \n', fontSize: 10 },
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addSignature2 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________  \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>