<template>
  <v-container fluid >
  <v-row>
  <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" >
    <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
      
      <v-card>
        <v-card-title class="headline">
           {{topHeader}}
           <v-spacer></v-spacer>
            <v-btn
              text color="primary"
              @click="downloadFile()">
              download
            </v-btn>
            <v-btn text color="primary" @click="print()">
             Print
            </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            dense
            :headers="headers"
            :items="fullYear"
            sort-by="id"
            class="elevation-1"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="20"
          >
            <template v-slot:[`item.CashIn`] ="{ item }">
                <span  style="color:green; font-weight:bold">{{ localMoney(item.CashIn) }}</span>
            </template>
            <template v-slot:[`item.CashOut`] ="{ item }">
                <span style="color:blue; font-weight:bold">{{ localMoney(item.CashOut) }}</span>
            </template>
            <template v-slot:[`item.CashSaldo`] ="{ item }">
                <span style="color:#6A1B9A; font-weight:bold">{{ localMoney(item.CashSaldo) }}</span>
            </template>
            <template v-slot:[`item.CashInFore`] ="{ item }">
                <span  style="color:green; font-weight:bold">{{ localMoney(item.CashInFore) }}</span>
            </template>
            <template v-slot:[`item.CashOutFore`] ="{ item }">
                <span style="color:blue; font-weight:bold">{{ localMoney(item.CashOutFore) }}</span>
            </template>
            <template v-slot:[`item.CashSaldoFore`] ="{ item }">
                <span style="color:#6A1B9A; font-weight:bold">{{ localMoney(item.CashSaldoFore) }}</span>
            </template>

          </v-data-table>
        </v-card-text>
        <v-card-actions>

        </v-card-actions>
      </v-card>
      </v-col>
    </v-row>
    <CashDeskPrintTotals  ref="prnt" :card="card" />
  </v-container>
</template>

<script>
import langEn from './cashdeskDescEn'
import langHr from './cashdeskDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import CashDeskService from '@/services/CashDeskService'
import CashDeskPrintTotals from './CashDeskPrintTotals'
import moneyFormat from '@/snippets/moneyFormat'
import xlsx from 'json-as-xlsx'
import { round, parseNum } from '@/snippets/allSnippets'
import dayjs from 'dayjs'

export default {
  name: 'cashdesk',
  data () {
    return {
      msg: '',
      lang: {},
      langC: {},
      locMonths: [],
      locYears: [],
      emplys: [],
      mainQuery: {},
      items:[],
      refresh: 0,
      saving: false,
      postNew: false,
      totalR: [],
      totalE: [],
      totalRfore: [],
      totalEfore: [],
      fullYear: [],
      headers:[],
      topHeader: '',
      card: {}
    }
  },
   mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    if (this.$store.state.businessYear) {
      this.mainQuery.businessYear = this.$store.state.businessYear
    }
    this.initialize()
    this.topHeader = this.lang.CashDesk + ' ' + this.$store.state.businessYear + this.lang.ReportCreated + dayjs().format('DD.MM.YYYY HH:MM')
    this.headers.push(
      { text: this.lang.CurrentMonth, align: 'start', sortable: true, value: 'CurrentMonth'},
      { text: this.lang.CashIn, value: 'CashIn', align: 'end', sortable: true },
      { text: this.lang.CashOut, value: 'CashOut', align: 'end', sortable: true },
      { text: this.lang.CashSaldo, value: 'CashSaldo', align: 'end', sortable: true },
      { text: this.lang.CashInFore, value: 'CashInFore', align: 'end', sortable: true },
      { text: this.lang.CashOutFore, value: 'CashOutFore', align: 'end', sortable: true },
      { text: this.lang.CashSaldoFore, value: 'CashSaldoFore', align: 'end', sortable: true },
    )
  },
  methods: {
    print(){
      this.$refs.prnt.print()
    },
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    async initialize() {
      this.saving = true
      const {data} = await CashDeskService.index(this.mainQuery)
      if (data && data.cashdesk !== undefined){
        for(let i = 0; i < 12; i++) {
          this.totalR[i] = 0
          this.totalE[i] = 0
          this.totalRfore[i] = 0
          this.totalEfore[i] = 0
        }
        this.items = data.cashdesk
        this.items.map(item => {
          const itemMonth = dayjs(item.documentDate, 'YYYY-MM-DD').format('M')
          this.totalR[itemMonth - 1] += parseNum(item.CashIn)
          this.totalE[itemMonth - 1] += parseNum(item.CashOut)
          this.totalRfore[itemMonth - 1] += parseNum(item.CashInFore)
          this.totalEfore[itemMonth - 1] += parseNum(item.CashOutFore)
        })
        
      }
 
      let totE = 0
      let totR = 0
      let totRf = 0
      let totEf = 0
      for(let i = 0; i < 12; i++) {
        const j = i+1
        const item = {}
        item.id = i
        item.CurrentMonth = j
        item.CashIn = this.totalR[i]
        item.CashOut = this.totalE[i]
        item.CashSaldo = round((this.totalR[i] - this.totalE[i]), 2)
        item.CashInFore = this.totalRfore[i]
        item.CashOutFore = this.totalEfore[i]
        item.CashSaldoFore = this.totalRfore[i] - this.totalEfore[i]
        this.fullYear.push(item)
        totR += this.totalR[i]
        totE += this.totalE[i]
        totRf += this.totalRfore[i]
        totEf += this.totalEfore[i]
      }
      const itemTotal = {}
      itemTotal.id = 12
      itemTotal.CurrentMonth = 'Total'
      itemTotal.CashIn = totR
      itemTotal.CashOut = totE
      itemTotal.CashSaldo = round((totR - totE), 2)
      itemTotal.CashInFore = totRf
      itemTotal.CashOutFore = totEf
      itemTotal.CashSaldoFore = round((totRf - totEf), 2)
      this.fullYear.push(itemTotal)
      this.card = {}
      this.card.businessYear = this.$store.state.businessYear
      this.card.items = this.fullYear
      this.saving = false
    },
    downloadFile () {
      const columns = []
      this.topHeader = this.lang.CashDesk + this.$store.state.businessYear + this.lang.ReportCreated + dayjs().format('DD.MM.YYYY HH:MM')
      columns.push(
        { label: this.lang.CurrentMonth, value: 'CurrentMonth'},
        { label: this.lang.CashIn, value: 'CashIn' },
        { label: this.lang.CashOut, value: 'CashOut' },
        { label: this.lang.CashSaldo, value: 'CashSaldo' },
        { label: this.lang.CashInFore, value: 'CashInFore' },
        { label: this.lang.CashOutFore, value: 'CashOutFore' },
        { label: this.lang.CashSaldoFore, value: 'CashSaldoFore' },
      )
      let sheetNm = ''
      let fileNm = ''
      const currDate = dayjs().format('DD_MM_YYYY_HH_MM') 
      if (this.$store.state.language === 'EN') {
       sheetNm = 'CashDesk_' + this.$store.state.businessYear + '_' + currDate
       fileNm = 'CashDesk_' + this.$store.state.businessYear + '_' + currDate
      }
      if (this.$store.state.language === 'HR') {
       sheetNm = 'Blagajna_' + this.$store.state.businessYear + '_' + currDate
       fileNm = 'Blagajna_' + this.$store.state.businessYear + '_' + currDate
      }
           
      const content = this.fullYear
      const settings = {
        sheetName: sheetNm,
        fileName: fileNm
      }
      xlsx(columns, content, settings)
    }

  },
  computed: {

  },
  watch: {

  },
  components: {
    CashDeskPrintTotals
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

