const partnerEN = {
  PartnerDetails: 'Partner - details',
  BasicData: 'Basic data',
  PartnerName: 'Partner Name *',
  PartnerNameShort: 'Name short *',
  PartnerNameNoSpace: 'Name No spaces',
  PartnerBuildingNameNumber: 'Building Name/Number',
  PartnerStreet: 'Street',
  PartnerCity: 'City',
  PartnerPostcode: 'Post code',
  PartnerCountyRegion: 'County/Region',
  PartnerState: 'State',
  PartnerAdress1: 'Adress',
  PartnerAdress2: ' ',
  PartnerVATID: 'VAT ID*',
  Partneremail: 'email',
  PartnerPhones: 'Phones',
  PartnerBankName: 'Bank Name',
  PartnerBankAddress: 'Bank Address',
  PartnerBankIBAN: 'Bank IBAN',
  PartnerBankSWIFT: 'Bank SWIFT',
  PartnerCEO: 'CEO',
  PartnerDirectorTitle: 'CEO Title',
  PartnerDirectorFirst: 'CEO First',
  PartnerDirectorLast: 'CEO Last',
  PartnerDirectorFormer: 'CEO former name',
  PartnerDirectorStateResidence: 'CEO State Residence',
  PartnerDirectorNationality: 'CEo Nationality',
  PartnerDirectorDateOfBirth: 'CEO Date Of Birth',
  PartnerDirectorBusinessOccupation: 'CEO Business Occupation',
  PartnerDirectorAdressBuildingNameNumber: 'CEO Adress Building NameNumber',
  PartnerDirectorAdressStreet: 'CEO Adress Street',
  PartnerDirectorCity: 'CEO Adress City',
  PartnerDirectorPostcode: 'CEO City Postcode',
  PartnerDirectorCountyRegion: 'CEO County/Region',
  PartnerDirectorEmail: 'CEO email',
  PartnerDirectorPhone: 'CEO Phone',
  PartnerDirectorContactOption: 'CEO Contact Option',
  PartnerSecretary: 'Secretary',
  PartnerSecretaryTitle: 'Secretary Title',
  PartnerSecretaryFirst: 'Secretary First',
  PartnerSecretaryLast: 'Secretary Last',
  PartnerSecretaryFormer: 'Secretary Former',
  PartnerSecretaryStateResidence: 'Secretary StateResidence',
  PartnerSecretaryNationality: 'Secretary Nationality',
  PartnerSecretaryDateOfBirth: 'Secretary Date Of Birth',
  PartnerSecretaryBusinessOccupation: 'Secretary Business Occupation',
  PartnerSecretaryAdressBuildingNameNumber: 'Secretary Adress Building NameNumber',
  PartnerSecretaryAdressStreet: 'Secretary Adress Street',
  PartnerSecretaryCity: 'Secretary City',
  PartnerSecretaryPostcode: 'Secretary Postcode',
  PartnerSecretaryCountyRegion: 'Secretary County/Region',
  PartnerSecretaryEmail: 'Secretary email',
  PartnerSecretaryPhone: 'Secretary Phone',
  PartnerSecretaryContactOption: 'Secretary Contact Option',
  PartnerSettings: 'Settings',
  PartnerInvoiceMemo: 'partnerInvoiceMemo',
  PartnerInvoiceForeginMemo: 'partnerInvoiceForeginMemo',
  PartnerDeliveryNoteMemo: 'partnerDeliveryNoteMemo',
  PartnerDeliveryNoteForeginMemo: 'partnerDeliveryNoteForeginMemo',
  PartnerInvoiceDefDays: 'partnerInvoiceDefDays',
  PartnerInvoicePlace: 'partnerInvoicePlace',
  PartnerInvoiceType: 'partnerInvoiceType',
  PartnerInvoiceHint: 'partnerInvoiceHint',
  PartnerInvoiceSpeed: 'partnerInvoiceSpeed',
  PartnerUseRetailPrice: 'partnerUseRetailPrice',
  PartnerShowLastSaleBuyer: 'partnerShowLastSaleBuyer',
  PartnerShowWarehouseState: 'partnerShowWarehouseState',
  PartnerShowInputPrice: 'partnerShowInputPrice',
  PartnerVATonPay: 'partnerVATonPay',
  PartnerRetail: 'partnerRetail',
  PartnerDefaultWarehouse: 'partnerDefaultWarehouse',
  PartnerPrinterSize: 'partnerPrinterSize',
  PartnerInputFilesPath: 'partnerInputFilesPath',
  PartnerOutputFilesPath: 'partnerOutputFilesPath',
  PartnerTransferDataFilesPath: 'partnerTransferDataFilesPath',
  PartnerStartInvoiceNumber: 'partnerStartInvoiceNumber',
  PartnerStartBillNumber: 'partnerStartBillNumber',
  PartnerType: 'partnerType',
  PartnerStartFrom: 'StartFrom',
  PartnerActiv: 'Activ',
  PartnerLockedCredit: 'Locked Credit',
  PartnerlockedDebit: 'Locked Debit',
  PartnerErrorVAT: 'ERROR! Partner VAT ID already exist!!',
  PartnerErroremail: 'ERROR! Partner email already exist!!',
  privatePerson: 'Private Person',
  specialCustomer:'Special Customer',
  alertOK1: 'New partner is written to database',
  alertOK2: 'Updated data about partner is written to database',
  alertError1: 'Error ! Partner must have VAT ID, exepct if is not private buyer.',
  alertError2: 'Error! On save',
  otherSettings: 'Other Settings'
}

export default partnerEN