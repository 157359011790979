import { render, staticRenderFns } from "./CalendarCRUD.vue?vue&type=template&id=7851dc6c"
import script from "./CalendarCRUD.vue?vue&type=script&lang=js"
export * from "./CalendarCRUD.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports