<template>
<div>
  <v-container fluid grid-list-md>
  <v-row>
      <appAppSettings></appAppSettings>
    </v-row>
  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="users"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="50"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{lang.Calendar}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>


          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-row>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="mr-3 pa-0">
                      <v-dialog
                          ref="dialog1"
                          persistent
                          v-model="modal1"
                          width="290px"
                          :return-value.sync="editedItem.CalDate"
                        >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="editedItem.CalDate"
                            :label="langC.Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>

                          <v-date-picker v-model="editedItem.CalDate" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="modal1 = false">{{langC.Cancel}}</v-btn>
                            <v-btn color="primary" @click="$refs.dialog1.save(editedItem.CalDate)">OK</v-btn>
                          </v-date-picker>
                        </v-dialog>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.CalDay"
                        v-model="editedItem.CalDay"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.CalMonth"
                        v-model="editedItem.CalMonth"
                        type="number"
                      ></v-text-field>
                    </v-col>
                     <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.CalYear"
                        v-model="editedItem.CalYear"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.CalDayInYear"
                        v-model="editedItem.CalDayInYear"
                        type="number"
                      ></v-text-field>
                    </v-col>

                  </v-row>
 
                  <v-row>
                    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.CalWeek"
                        v-model="editedItem.CalWeek"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.CalDescription"
                        v-model="editedItem.CalDescription"
                      ></v-text-field>
                    </v-col>
                     <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1 pt-1">
                      <v-select
                        v-model="selected"
                        :items="types"
                        item-text="name"
                        item-value="id"
                        label="Select"
                        persistent-hint
                        return-object
                        single-line
                      >
                      </v-select>

                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.CalHolidayDescription"
                        v-model="editedItem.CalHolidayDescription"
                      ></v-text-field>
                    </v-col>
                     <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.FreeF1"
                        v-model="editedItem.FreeF1"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn color="success" text @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.CalDay`]="{ item }">
        <span>{{ parseInt(item.CalDay) }}</span>
    </template>
    <template v-slot:[`item.CalMonth`]="{ item }">
        <span>{{ parseInt(item.CalMonth) }}</span>
    </template>
    <template v-slot:[`item.CalYear`]="{ item }">
        <span>{{ parseInt(item.CalYear) }}</span>
    </template>
    <template v-slot:[`item.CalDayInYear`]="{ item }">
        <span>{{ parseInt(item.CalDayInYear) }}</span>
    </template>
    <template v-slot:[`item.CalWeek`]="{ item }">
        <span>{{ parseInt(item.CalWeek) }}</span>
    </template>
    <template v-slot:[`item.FreeF1`]="{ item }">
        <span>{{ parseInt(item.FreeF1) }}</span>
    </template>

    <template v-slot:[`item.CalDate`]="{ item }">
        <span>{{ localDate(item.CalDate) }}</span>
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      {{ lang.CalEmpty }}
    </template>
  </v-data-table>
  </v-container>
  </div>
</template>

<script>
import CalendarService from '@/services/CalendarService'
import langEn from './calendarDescEn'
import langHr from './calendarDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import AppSettings from '../AppSettings'

  export default {
    name: 'calendarCRUD',
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      modal1: false,
      dialog1: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: "",
        databaseDocID: 0
      },
      dialog: false,
      headers: [],
      users: [],
      editedIndex: -1,
      editedItem: { },
      defaultItem: {
        CompanyId: 0,
        CalDate: null,
        CalDay: null,
        CalMonth: null,
        CalYear: null,
        CalDayInYear: null,
        CalWeek: null,
        CalDescription: '',
        CalWorkDay: '',
        CalHolidayDescription: '',
        FreeF1: null,
      },
      privitems: [],
      valid: true,
      types: [
        {id:'W', name: 'Work day'},
        {id:'H', name: 'Non work day'},
        {id:'P', name: 'State holiday'},
      ], 
      selected: {}
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewUser: this.lang.EditUser
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      selected (val) {
        this.editedItem.CalWorkDay = val.id ? val.id : ''
      }
    },
    components: {
      appAppSettings: AppSettings
    },

    mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
        const locTypes = [
          {id:'W', name: 'Work day'},
          {id:'H', name: 'Non work day'},
          {id:'P', name: 'State holiday'},
        ]
        this.types = [...locTypes]
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr
        const locTypes = [
          {id:'W', name: 'Radni dan'},
          {id:'H', name: 'Vikend'},
          {id:'P', name: 'Praznk drzavni'},
        ]
        this.types = [...locTypes]
      }

      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }

      this.privitems.push(
        { text: this.lang.Priv1, value: 1 },
        { text: this.lang.Priv2, value: 2 },
        { text: this.lang.Priv3, value: 3 },
        { text: this.lang.Priv4, value: 4 },
        { text: this.lang.Priv5, value: 5 }
      )

      this.headers.push(
        {text: this.lang.CalDate, value: 'CalDate', width: '200px', type: 'date', dateInputFormat: 'YYYY-MM-DD', dateOutputFormat: 'DD.MM.YYYY'},
        {text: this.lang.CalDay, value: 'CalDay', width: '200px', type: 'number'},
        {text: this.lang.CalMonth , value: 'CalMonth', width: '150px', type: 'number'},
        {text: this.lang.CalYear, value: 'CalYear', width: '50px', type: 'number'},
        {text: this.lang.CalDayInYear, value: 'CalDayInYear', width: '50px', type: 'number'},
        {text: this.lang.CalWeek, value: 'CalWeek', width: '50px', type: 'number'},
        {text: this.lang.CalDescription, value: 'CalDescription', width: '50px'},
        {text: this.lang.CalWorkDay, value: 'CalWorkDay', width: '50px', type: 'number'},
        {text: this.lang.CalHolidayDescription, value: 'CalHolidayDescription', width: '50px', type: 'number'},
        {text: this.lang.FreeF1, value: 'FreeF1', width: '50px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
        {text: this.lang.Actions, value: 'action', sortable: false },
      ) 
    },

    created () {
      this.initialize()
    },

    methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      async initialize () {
        try {
          this.saving = true
          this.users = (await CalendarService.index()).data.Calendars
          this.saving = false
          this.errorMatch = false
          this.editedItem = Object.assign({}, this.defaultItem)
        } catch (err) {
          this.error = err
        }
      },
      editItem (item) {
        this.errorMatch = false
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.selected = this.editedItem.CalWorkDay ? this.types.find(type => (type.id === this.editedItem.CalWorkDay)) : 'W'
        this.dialog = true
        this.confpassword = ''
      },
      async deleteItem (item) {
        try {
          const index = this.users.indexOf(item)
          const warningMessage = item.CalDate
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.users.splice(index, 1)) {
            await CalendarService.delete(item.id)
            this.initialize()
          }
        } catch(err) {
          this.error = err
        }
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      checkMatch () {
        if (this.editedItem.password && this.confpassword &&  this.confpassword === this.editedItem.password) {
          this.errorMatch = null
          this.save()
        } else {
          this.errorMatch = 'Error. Passoword and Confirmation Password are NOT equal!'
          this.valid = false        
        }
      },

      async save () {
        try {
           let doneOK = false
           if (this.editedIndex > -1) {
              await CalendarService.put(this.editedItem)
              doneOK = true
            } else {
              //this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              //this.editedItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
              this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
              //this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              //this.editedItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : ''
              await CalendarService.post(this.editedItem)
              doneOK = true
            }
            if (doneOK) {
              this.initialize()
              this.close()
            }
        } catch(err) {
          this.error = err
          
        }
       
      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      }
    },
  }
</script>