const docEN = {
  CityCodeID: 'Card ID',
  CityCode: 'City Code',
  CityName: 'City Name',
  CityIBAN: 'City IBAN',
  CityBank: 'Bank',
  NewCity: 'New card type',
  EditCity: 'Edit card type',
  EmptyCity: 'Nema card types',
  City: 'Cities'
}

export default docEN