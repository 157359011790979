<template>
  <div>

  </div>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './salarieDescEn'
import langHr from './salarieDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'
import {dynamicSortMultiple} from '@/snippets/allSnippets'
// import { parseNum } from '@/snippets/allSnippets'

export default {
  name: 'salarieprintreportrad1',
  props: [],
  data () {
    return {
      msg: '',
      documentName: '',
      documentName2: '',
      docDefinition: null,
      content: [],
      document: {},
      havePath: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locDocument: {},
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      comp: {},
      mainQuery: {},
      ana: {},
      totalIz: 0,
      totalNa: 0,
      Bruto1: 0,
      Bruto3: 0,
      Neto3: 0,
      FinBruto1: 0,
      dodIz: 0,
      FinBruto2: 0,
      dopIz: 0,
      FinNeto1ls: 0,
      FinFreeTotalToUse: 0,
      FinTax1Am: 0,
      FinTax12Am: 0,
      FinNeto2: 0,
      neoT: 0,
      obuT: 0,
      FinNeto3: 0,
      FinBruto3: 0,
      notFinal: true,
      table1: null,
      table2: null,
      table3: null,
      table4: null,
      table5: null,
      table6: null,
      table7: null,
      table8: null,
      table9: null
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = {...langEn}
      this.langC = {...commEn}
    }
    if (this.$store.state.language === 'HR') {
      this.lang = {...langHr}
      this.langC = {...commHr}
    }

  },
  methods: {
    async print () {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.BusinessYear = this.$store.state.businessYear
      }
      this.printDoc()
    },

    formatPrice (value) {
      if (value !== undefined && value !== null) {
        if (this.$store.state.language === 'EN') {
          let val = (value / 1).toFixed(2).replace('.', ',')
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        }
        if (this.$store.state.language === 'HR') {
          let val = (value / 1).toFixed(2).replace('.', ',')
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        }
      } else
      { 
        let val = (0 / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }

    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    printDoc () {
      this.comp = this.$store.state.companyActiv
      this.ana = this.$store.state.printObj ? this.$store.state.printObj : {}
      console.log('this.ana printam', this.ana, this.ana.tables.table1)
      this.table1 = this.ana && this.ana.tables.table1 ? {...this.ana.tables.table1} : {}
      this.table2 = this.ana && this.ana.tables.table2 ? {...this.ana.tables.table2} : {}
      this.table3 = this.ana && this.ana.tables.table3 ? {...this.ana.tables.table3} : {}
      this.table4 = this.ana && this.ana.tables.table4 ? {...this.ana.tables.table4} : {}
      this.table5 = this.ana && this.ana.tables.table5 ? {...this.ana.tables.table5} : {}
      this.table6 = this.ana && this.ana.tables.table6 ? {...this.ana.tables.table6} : {}
      this.table7 = this.ana && this.ana.tables.table7 ? {...this.ana.tables.table7} : {}
  
      this.initDocument()
      this.addHead()
      this.addLine()
      this.addEmptyRow()
      this.addEmptyRow()
      this.addTable1Header()
      this.addTable1()
      this.addLine()
      this.addEmptyRow()
      this.addEmptyRow()
      this.addTable2Header()
      this.addTable2()
      this.addLine()
      this.addEmptyRow()
      this.addTable3Header()
      this.addTable3()
      this.addLine()
      this.addEmptyRow()
      this.addTable4Header()
      this.addTable4()
      this.addLine()
      this.addEmptyRow()
      this.addTable5Header()
      this.addTable5()
      this.addLine()
      this.addEmptyRow()
      this.addTable6Header()
      this.addTable6()
      this.addLine()
      this.addEmptyRow()
      this.addTable7Header()
      this.addTable7()
      this.addLine()
  
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 40 ]
      this.content = []
    },
  
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addLineDash () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 30,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1,
                dash: { length: 1 },
              }
            ]
          }
      this.content.push(docPart)
    },

    addHead() {
      const docPart = {
          columns: [
            {
              // % width
              width: '35%',
              text: [
                { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.Companyemail ? 'email:' +  this.comp.Companyemail + ' \n' : ' ') , fontSize: 8 },
                { text: 'OIB:' + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '45%',
              text: [
                { text: 'RAD1 - G' + '\n', fontSize: 18, bold: true },
                { text: 'za ' + (this.ana.startDate ? dayjs(this.ana.startDate).format('DD.MM.YYYY') + '/' : '') + (this.ana.endDate ? dayjs(this.ana.endDate).format('DD.MM.YYYY')  : '') , fontSize: 12, bold: true },
                { text: (this.ana.head3 ? this.ana.head3 + ' \n' : ''), fontSize: 8, bold: true },
                { text: (this.ana.head4 ? this.ana.head4 + ' \n' : ''), fontSize: 8, bold: true },
                { text: (this.ana.head5 ? this.ana.head5 + ' \n' : ''), fontSize: 8, bold: true },
              ]
            },
            {
              // % width
              width: '20%',
              text: [                 
                { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },

    addTable1Header () {
      this.addLine()
      const docPart1 = {
          columns: [
          {
              width: '3%',
              text: '1.' + '\n', fontSize: 8
            },
            {
              width: '85%',
              text: 'BRUTO I ISPLAĆENE NETO PLAĆE ZA PUNO RADNO VRIJEME PREMA SPOLU I ZAPOSLENI PREMA STUPNJU STRUČNE SPREME ' + '\n' + 'ZA OBAVLJANJE ODREĐENIH POSLOVA/RADNIH ZADATAKA ZA 2023.', fontSize: 8
            },
          ]
        }
      this.content.push(docPart1)

      this.addLineDash()

      const docPart = {
          columns: [
            {
              width: '3%',
              text: '' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: ('Godišnji iznos bruto plaće') + '\n', fontSize: 7
            },
            {
              width: '30%',
              text: 'Godišnji iznos bruto plaće' + '\n' + 'onih zaposlenih koji su radili svih'  + '\n' + '12 mjeseci u 2023. u eurima bez centi' , fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: 'Godišnji iznos isplaćene neto plaće' + '\n' + 'onih zaposlenih koji su radili svih'  + '\n' + '12 mjeseci u 2023. u eurima bez centi' , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: 'Broj zaposlenih' + '\n' + '(prikazuje se stanje'  + '\n' + 'jednog mjeseca onih' + '\n' + 'zaposlenih koji su' + '\n'  + 'radili svih 12 mjeseci' + '\n' + 'u 2023.)' , fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart)
      this.addLineDash()
    },
    
    addTable1 () {
      const docPart = {
          columns: [
            {
              width: '3%',
              text: '1.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Ukupno (2. - 3.)' + '\n', fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.total1.bruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.total1.neto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table1.total1.nr), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart)
      this.addLineDash()
      const docPart2 = {
          columns: [
            {
              width: '3%',
              text: '2.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Muškarci' + '\n', fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.men.bruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.men.neto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table1.men.nr), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart2)
      const docPart3 = {
          columns: [
            {
              width: '3%',
              text: '3.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Žene' + '\n', fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.women.bruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.women.neto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table1.women.nr), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart3)
      this.addLineDash()
      const docPart4 = {
          columns: [
            {
              width: '3%',
              text: '4.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Ukupno (5. - 12.)' + '\n', fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.total2.bruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.total2.neto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table1.total2.nr), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart4)
      this.addLineDash()
      const docPart5 = {
          columns: [
            {
              width: '3%',
              text: '5.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Diplomski studij te sveučilišni specijalistički i ' + '\n' + 'doktorski studij (VSS)1', fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.diplomskiBruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.diplomskiNeto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table1.diplomskiBroj), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart5)

      const docPart6 = {
          columns: [
            {
              width: '3%',
              text: '6.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Prijediplomski studij (VŠS)' + '\n' , fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.prijedipBruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.prijedipNeto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table1.prijedipBroj), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart6)

      const docPart7 = {
          columns: [
            {
              width: '3%',
              text: '7.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Srednja strukovna škola u trajanju od 4 ili 5 godina i ' + '\n' + 'gimnazija (SSS)', fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.sssBruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.sssNeto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table1.sssBroj), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart7)

      const docPart8 = {
          columns: [
            {
              width: '3%',
              text: '8.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Niže stručno obrazovanje' + '\n' , fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.nizestrucnoBruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.nizestrucnoNeto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table1.nizestrucnoBroj), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart8)

      const docPart9 = {
          columns: [
            {
              width: '3%',
              text: '9.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Visokokvalificirani' + '\n' , fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.visokokvBruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.visokokvNeto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table1.visokokvBroj), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart9)

      const docPart10 = {
          columns: [
            {
              width: '3%',
              text: '10.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Srednja strukovna škola u trajanju od 1 do 3 godine (KV)' , fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.srednjastrukBruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.srednjastrukNeto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table1.srednjastrukBroj), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart10)

      const docPart11 = {
          columns: [
            {
              width: '3%',
              text: '11.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Priučeni - polukvalificirani' + '\n'  , fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.priuceniBruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.priuceniNeto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table1.priuceniBroj), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart11)

      const docPart12 = {
          columns: [
            {
              width: '3%',
              text: '12.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Nekvalificirani' + '\n'  , fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.nekvalBruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table1.nekvalNeto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table1.nekvalBroj), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart12)


    },

    addTable2Header () {
      this.addLine()
      const docPart1 = {
          columns: [
          {
              width: '3%',
              text: '2.' + '\n', fontSize: 8
            },
            {
              width: '85%',
              text: 'BRUTO I ISPLAĆENE NETO PLAĆE ZA KRAĆE OD PUNOGA RADNOG VREMENA PREMA SPOLU I ZAPOSLENI PREMA STUPNJU ' + '\n' + 'STRUČNE SPREME ZA OBAVLJANJE ODREĐENIH POSLOVA/RADNIH ZADATAKA ZA 2023.', fontSize: 8
            },
          ]
        }
      this.content.push(docPart1)

      this.addLineDash()

      const docPart = {
          columns: [
            {
              width: '3%',
              text: '' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: ('Godišnji iznos bruto plaće') + '\n', fontSize: 7
            },
            {
              width: '30%',
              text: 'Godišnji iznos bruto plaće' + '\n' + 'onih zaposlenih koji su radili svih'  + '\n' + '12 mjeseci u 2023. u eurima bez centi' , fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: 'Godišnji iznos isplaćene neto plaće' + '\n' + 'onih zaposlenih koji su radili svih'  + '\n' + '12 mjeseci u 2023. u eurima bez centi' , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: 'Broj zaposlenih' + '\n' + '(prikazuje se stanje'  + '\n' + 'jednog mjeseca onih' + '\n' + 'zaposlenih koji su' + '\n'  + 'radili svih 12 mjeseci' + '\n' + 'u 2023.)' , fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart)
      this.addLineDash()
    },

    addTable2 () {
      const docPart = {
          columns: [
            {
              width: '3%',
              text: '1.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Ukupno (2. - 3.)' + '\n', fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.total1.bruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.total1.neto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table2.total1.nr), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart)
      this.addLineDash()
      const docPart2 = {
          columns: [
            {
              width: '3%',
              text: '2.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Muškarci' + '\n', fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.men.bruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.men.neto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table2.men.nr), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart2)
      const docPart3 = {
          columns: [
            {
              width: '3%',
              text: '3.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Žene' + '\n', fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.women.bruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.women.neto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table2.women.nr), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart3)
      this.addLineDash()
      const docPart4 = {
          columns: [
            {
              width: '3%',
              text: '4.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Ukupno (5. - 12.)' + '\n', fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.total2.bruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.total2.neto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table2.total2.nr), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart4)
      this.addLineDash()
      const docPart5 = {
          columns: [
            {
              width: '3%',
              text: '5.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Diplomski studij te ' + '\n' + 'sveučilišni specijalistički i ' + '\n' + 'doktorski studij (VSS)1', fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.diplomskiBruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.diplomskiNeto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table2.diplomskiBroj), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart5)

      const docPart6 = {
          columns: [
            {
              width: '3%',
              text: '6.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Prijediplomski studij (VŠS)' + '\n' , fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.prijedipBruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.prijedipNeto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table2.prijedipBroj), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart6)

      const docPart7 = {
          columns: [
            {
              width: '3%',
              text: '7.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Srednja strukovna škola u ' + '\n' + 'trajanju od 4 ili 5 godina i ' + '\n' + 'gimnazija (SSS)', fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.sssBruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.sssNeto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table2.sssBroj), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart7)

      const docPart8 = {
          columns: [
            {
              width: '3%',
              text: '8.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Niže stručno obrazovanje' + '\n' , fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.nizestrucnoBruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.nizestrucnoNeto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table2.nizestrucnoBroj), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart8)

      const docPart9 = {
          columns: [
            {
              width: '3%',
              text: '9.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Visokokvalificirani' + '\n' , fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.visokokvBruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.visokokvNeto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table2.visokokvBroj), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart9)

      const docPart10 = {
          columns: [
            {
              width: '3%',
              text: '10.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Srednja strukovna škola u ' + '\n' + 'trajanju od 1 do 3 godine (KV)' , fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.srednjastrukBruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.srednjastrukNeto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table2.srednjastrukBroj), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart10)

      const docPart11 = {
          columns: [
            {
              width: '3%',
              text: '11.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Priučeni - polukvalificirani' + '\n'  , fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.priuceniBruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.priuceniNeto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table2.priuceniBroj), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart11)

      const docPart12 = {
          columns: [
            {
              width: '3%',
              text: '12.' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Nekvalificirani' + '\n'  , fontSize: 7
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.nekvalBruto), fontSize: 7, alignment: 'right' 
            },
            {
              width: '30%',
              text: this.formatPrice(this.table2.nekvalNeto) , fontSize: 7, alignment: 'right' 
            },
            {
              width: '15%',
              text: this.formatPrice(this.table2.nekvalBroj), fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart12)


    },

    addTable3Header () {
      this.addLine()
      const docPart1 = {
          columns: [
          {
              width: '3%',
              text: '3.' + '\n', fontSize: 8
            },
            {
              width: '85%',
              text: 'ZAPOSLENI PREMA STAROSTI I SPOLU, STANJE 31. OŽUJKA 2024. ' + '\n', fontSize: 8
            },
          ]
        }
      this.content.push(docPart1)

      this.addLineDash()

      const docPart = {
          columns: [
            {
              width: '3%',
              text: '' + '\n', fontSize: 7
            },
            {
              width: '10%',
              text: 'Godine starosti'+ '\n', fontSize: 7
            },
            {
              width: '20%',
              text: 'Ukupno' + '\n', fontSize: 7, alignment: 'right' 
            },
            {
              width: '20%',
              text: 'Žene' + '\n', fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart)
      this.addLineDash()
    },
    addTable3() {
      console.log('this.table3.ukupno ', this.table3.ukupno )
      const docPart = {
          columns: [
            {
              width: '3%',
              text: '1.' + '\n', fontSize: 7
            },
            {
              width: '10%',
              text: 'Ukupno' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: this.formatPrice(this.table3.ukupno), fontSize: 7, alignment: 'right' 
            },
            {
              width: '20%',
              text: this.formatPrice(this.table3.ukupnoZ) , fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart)
      this.addLineDash()

      const docPart2 = {
          columns: [
            { width: '3%', text: '2.' + '\n', fontSize: 7 },
            { width: '10%', text: 'Do 18 godina' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table3.do18), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table3.do18z) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart2)
      const docPart3 = {
          columns: [
            { width: '3%', text: '3.' + '\n', fontSize: 7 },
            { width: '10%', text: '19-24' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table3.od19), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table3.od19z) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart3)
      const docPart4 = {
          columns: [
            { width: '3%', text: '4.' + '\n', fontSize: 7 },
            { width: '10%', text: '25-29' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table3.od25), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table3.od25z) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart4)
      const docPart5 = {
          columns: [
            { width: '3%', text: '5.' + '\n', fontSize: 7 },
            { width: '10%', text: '30-34' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table3.od30), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table3.od30z) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart5)
      const docPart6 = {
          columns: [
            { width: '3%', text: '6.' + '\n', fontSize: 7 },
            { width: '10%', text: '35-39' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table3.od35), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table3.od35z) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart6)
      const docPart7 = {
          columns: [
            { width: '3%', text: '7.' + '\n', fontSize: 7 },
            { width: '10%', text: '40 - 44' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table3.od40), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table3.od40z) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart7)
      const docPart8 = {
          columns: [
            { width: '3%', text: '8.' + '\n', fontSize: 7 },
            { width: '10%', text: '45-49' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table3.od45), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table3.od45z) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart8)
      const docPart9 = {
          columns: [
            { width: '3%', text: '9.' + '\n', fontSize: 7 },
            { width: '10%', text: '50 - 54' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table3.od50), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table3.od50z) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart9)
      const docPart10 = {
          columns: [
            { width: '3%', text: '10.' + '\n', fontSize: 7 },
            { width: '10%', text: '55-59' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table3.od55), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table3.od55z) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart10)
      const docPart11 = {
          columns: [
            { width: '3%', text: '11.' + '\n', fontSize: 7 },
            { width: '10%', text: '60-64' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table3.od60), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table3.od60z) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart11)
      const docPart12 = {
          columns: [
            { width: '3%', text: '12.' + '\n', fontSize: 7 },
            { width: '10%', text: '65 i više' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table3.od65), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table3.od65z) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart12)
    },

    addTable4Header () {
      this.addLine()
      const docPart1 = {
          columns: [
          { width: '3%', text: '4.' + '\n', fontSize: 8 },
          { width: '85%', text: 'ZAPOSLENI PREMA STUPNJU STRUČNOG OBRAZOVANJA (PREMA FORMALNO-PRAVNOJ ' + '\n' + 'KVALIFIKACIJI), STANJE 31. OŽUJKA 2024.', fontSize: 8 },
          ]
        }
      this.content.push(docPart1)

      this.addLineDash()

      const docPart = {
          columns: [
            { width: '3%', text: '' + '\n', fontSize: 7 },
            { width: '30%',text: ''+ '\n', fontSize: 7 },
            { width: '20%',text: 'Ukupno' + '\n', fontSize: 7, alignment: 'right' },
            { width: '20%',text: 'Žene' + '\n', fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart)
      this.addLineDash()


    },

    addTable4() {
      const docPart = {
          columns: [
            {
              width: '3%',
              text: '1.' + '\n', fontSize: 7
            },
            {
              width: '30%',
              text: 'Ukupno (2.-10.)' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: this.formatPrice(this.table4.red1), fontSize: 7, alignment: 'right' 
            },
            {
              width: '20%',
              text: this.formatPrice(this.table4.red1Z) , fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart)
      this.addLineDash()

      const docPart2 = {
          columns: [
            { width: '3%', text: '2.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Diplomski studij te sveučilišni specijalistički i doktorski studij' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table4.red2), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table4.red2Z) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart2)
      const docPart3 = {
          columns: [
            { width: '3%', text: '3.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Prijediplomski studij' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table4.oPre), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table4.oPreZ) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart3)
      const docPart4 = {
          columns: [
            { width: '3%', text: '4.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Stručni kratki studij' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table4.oStr), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table4.oStrZ) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart4)
      const docPart5 = {
          columns: [
            { width: '3%', text: '5.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Gimnazija' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table4.oGim), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table4.oGimZ) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart5)
      const docPart6 = {
          columns: [
            { width: '3%', text: '6.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Srednja strukovna škola u trajanju od 4 ili 5 godina' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table4.oSS4), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table4.oSS4Z) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart6)
      const docPart7 = {
          columns: [
            { width: '3%', text: '7.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Srednja strukovna škola u trajanju od 1 do 3 godine' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table4.oSS3), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table4.oSS3Z) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart7)
      const docPart8 = {
          columns: [
            { width: '3%', text: '8.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Priučeni - polukvalificirani' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table4.oPri), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table4.oPriZ) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart8)
      const docPart9 = {
          columns: [
            { width: '3%', text: '9.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Osnovna škola' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table4.oOsn), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table4.oOsnZ) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart9)
      const docPart10 = {
          columns: [
            { width: '3%', text: '10.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Bez škole' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table4.oBez), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table4.oBezZ) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart10)
      const docPart11 = {
          columns: [
            { width: '3%', text: '11.' + '\n', fontSize: 7 },
            { width: '30%', text: 'doktori znanosti' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table4.oDok), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table4.oDok) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart11)

      const docPart12 = {
          columns: [
            { width: '3%', text: '12.' + '\n', fontSize: 7 },
            { width: '30%', text: 'magistri znanosti' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table4.oMag), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table4.oMagZ) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart12)
    },

    addTable5Header () {
      this.addLine()
      const docPart1 = {
          columns: [
          { width: '3%', text: '5.' + '\n', fontSize: 8 },
          { width: '85%', text: 'ZAPOSLENI PREMA VRSTI RADNOG ODNOSA, STANJE' + '\n' + ' 31. OŽUJKA 2024.', fontSize: 8 },
          ]
        }
      this.content.push(docPart1)

      this.addLineDash()

      const docPart = {
          columns: [
            { width: '3%', text: '' + '\n', fontSize: 7 },
            { width: '30%',text: ''+ '\n', fontSize: 7 },
            { width: '20%',text: 'Ukupno' + '\n', fontSize: 7, alignment: 'right' },
            { width: '20%',text: 'Žene' + '\n', fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart)
      this.addLineDash()

    },
    addTable5() {
      const docPart = {
          columns: [
            {
              width: '3%',
              text: '1.' + '\n', fontSize: 7
            },
            {
              width: '30%',
              text: 'Ukupno (2.-10.)' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: this.formatPrice(this.table5.red1), fontSize: 7, alignment: 'right' 
            },
            {
              width: '20%',
              text: this.formatPrice(this.table5.red1Z) , fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart)
      this.addLineDash()

      const docPart2 = {
          columns: [
            { width: '3%', text: '2.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Neodređeno vrijeme' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table5.neoBr), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table5.neoBrZ) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart2)
      const docPart3 = {
          columns: [
            { width: '3%', text: '3.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Određeno vrijeme' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table5.odrBr), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table5.odrBrZ) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart3)
      const docPart4 = {
          columns: [
            { width: '3%', text: '4.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Pripravnici/vježbenici' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table5.priBr), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table5.priBrZ) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart4)
    },

    addTable6Header () {
      this.addLine()
      const docPart1 = {
          columns: [
            {
              width: '3%',
              text: '6.' + '\n', fontSize: 8
            },
            {
              width: '85%',
              text: 'ZAPOSLENI PREMA VRSTI RADNOG VREMENA, STANJE 31. OŽUJKA 2024.' + '\n', fontSize: 8
            },
          ]
        }
      this.content.push(docPart1)

      this.addLineDash()

      const docPart = {
          columns: [
            { width: '3%', text: '' + '\n', fontSize: 7 },
            { width: '30%',text: ''+ '\n', fontSize: 7 },
            { width: '20%',text: 'Ukupno' + '\n', fontSize: 7, alignment: 'right' },
            { width: '20%',text: 'Žene' + '\n', fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart)
      this.addLineDash()
    },

    addTable6() {
      const docPart = {
          columns: [
            {
              width: '3%',
              text: '1.' + '\n', fontSize: 7
            },
            {
              width: '30%',
              text: 'Ukupno (2.-10.)' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: this.formatPrice(this.table5.red1), fontSize: 7, alignment: 'right' 
            },
            {
              width: '20%',
              text: this.formatPrice(this.table5.red1Z) , fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart)
      this.addLineDash()

      const docPart2 = {
          columns: [
            { width: '3%', text: '2.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Puno radno vrijeme' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table6.punoBr), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table6.punoBrZ) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart2)
      const docPart3 = {
          columns: [
            { width: '3%', text: '3.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Nepuno radno vrijeme' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table6.nepunoBr), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table6.nepunoBrZ) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart3)
      const docPart4 = {
          columns: [
            { width: '3%', text: '4.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Skraćeno radno vrijeme' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table6.skracBr), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table6.skracBrZ) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart4)
    },


    addTable7Header () {
      this.addLine()
      const docPart1 = {
          columns: [
          { width: '3%', text: '7.' + '\n', fontSize: 8 },
          { width: '85%', text: 'SATI RADA ZAPOSLENIH U 2023.' + '\n \n', fontSize: 8 },
          ]
        }
      this.content.push(docPart1)

      this.addLineDash()
      const docPart2 = {
          columns: [
            { width: '3%', text: '' + '\n', fontSize: 7 },
            { width: '30%',text: ''+ '\n', fontSize: 7 },
            { width: '40%',text: 'Sati rada u 2023.' + '\n' , fontSize: 7, alignment: 'center' },
          ]
        }
      this.content.push(docPart2)

      const docPart = {
          columns: [
            { width: '3%', text: '' + '\n', fontSize: 7 },
            { width: '30%',text: ''+ '\n', fontSize: 7 },
            { width: '20%',text: 'zaposlenih u punome radnom' + '\n' + 'vremenu', fontSize: 7, alignment: 'center' },
            { width: '20%',text: 'zaposlenih u kraćem od punoga' + '\n' + 'radnog vremena', fontSize: 7, alignment: 'center' },
          ]
        }
      this.content.push(docPart)
      this.addLineDash()

    },
    addTable7() {
      const docPart = {
          columns: [
            {
              width: '3%',
              text: '1.' + '\n', fontSize: 7
            },
            {
              width: '30%',
              text: 'Ukupno (2.-7.)' + '\n', fontSize: 7
            },
            {
              width: '20%',
              text: this.formatPrice(this.table7.uk), fontSize: 7, alignment: 'right' 
            },
            {
              width: '20%',
              text: this.formatPrice(this.table7.uk7K) , fontSize: 7, alignment: 'right' 
            },
          ]
        }
      this.content.push(docPart)
      this.addLineDash()

      const docPart2 = {
          columns: [
            { width: '3%', text: '2.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Izvršeni sati rada' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table7.izvrseni), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table7.izvrseniK) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart2)
      const docPart3 = {
          columns: [
            { width: '3%', text: '3.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Neizvršeni sati rada plaćeni u pravnoj osobi zaposlenika' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table7.neizvrseni), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table7.neizvrseniK) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart3)
      const docPart5 = {
          columns: [
            { width: '3%', text: '4.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Neizvršeni sati rada plaćeni izvan pravne osobe' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table7.neizvrseniVan), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table7.neizvrseniVanK) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart5)
      const docPart6 = {
          columns: [
            { width: '3%', text: '5.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Neplaćeni sati rada' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table7.neplaceni), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table7.neplaceniK) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart6)
      const docPart7 = {
          columns: [
            { width: '3%', text: '6.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Prekovremeni sati rada' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table7.prekovremeni), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table7.prekovremeniK) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart7)
      const docPart8 = {
          columns: [
            { width: '3%', text: '7.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Broj sati provedenih u štrajku' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table7.strajk), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table7.strajkK) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart8)
      const docPart9 = {
          columns: [
            { width: '3%', text: '8.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Godišnji prosjek broja zaposlenih5)' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table7.prosjek), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table7.prosjekK) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart9)
      const docPart10 = {
          columns: [
            { width: '3%', text: '9.' + '\n', fontSize: 7 },
            { width: '30%', text: 'Prosječan broj sati po zaposlenome (podatak iz retka 1. podijelite s podatkom iz retka 8.' + '\n', fontSize: 7 },
            { width: '20%', text: this.formatPrice(this.table7.prosjekBroj), fontSize: 7, alignment: 'right' },
            { width: '20%', text: this.formatPrice(this.table7.prosjekBrojK) , fontSize: 7, alignment: 'right' },
          ]
        }
      this.content.push(docPart10)
    },

    addEmpty () {
      const docPart = {
        
          }
      this.content.push(docPart)
    },

  },
  computed: {
  },
  watch: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
