import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('opzstathead/showall', {
      params: query
    })
  },
  indexlist(search) {
    return Api().get('opzstathead', {
      params: search
    })
  },
  choose (query) {
    return Api().get('opzstathead/choose', {
      params: query
    })
  },
  show (id) {
    return Api().get(`opzstathead/${id}`)
  },
  post (newopzstathead) {
    return Api().post('opzstathead', newopzstathead)
  },
  put (opzstathead) {
    return Api().put(`opzstathead/${opzstathead.id}`, opzstathead)
  },
  delete (id) {
    return Api().delete(`opzstathead/delete/${id}`)
  },
  saveitems (newitems) {
    return Api().post('opzstathead/saveitems', newitems)
  },
  createopzstat(data) {
    return Api().post(`opzstathead/createopzstat`, data)
  },
  uploadopzstat(query) {
    return Api().get(`opzstatheadupload`, {
      params: query
    }, {
      responseType: 'blob'
    })
  },
}