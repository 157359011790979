<template>
  <v-form> 
  <v-container class="grey lighten-5 pa-0">
    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="9" lg="9" xs="12" sm="12" md="6" class="pa-0">
            <v-row v-if="error">
              <v-alert 
                :type="typeError"
                v-model="alert"
                dismissible>
               {{error}}
              </v-alert>
            </v-row>
            <v-row>
                <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5">
                  <h4>
                     {{lang.ItemFor}}  {{ documentNameLoc }}
                  </h4>
                </v-col>
            </v-row>

            <v-card
            class="pa-2 ma-1"
                outlined
              >
              <v-row no-gutters>
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" class="gray">
                  <div class="text-h6 pb-2">
                    <span>Opcije</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" sm="12"  md="3" lg="3" xl="3">
                    <v-select
                        :items="privGroups"
                        v-model="selectGroup"
                        :label="lang.selectGroup"
                        single-line
                        item-text="GroupName"
                        item-value="id"
                        return-object
                        persistent-hint
                        clearable
                        dense
                      ></v-select>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="9" lg="3" xl="3" class="right">
                  <v-btn text small color="primary" @click="showGroup" class="mr-1">
                      {{lang.Items}}
                    </v-btn>
                    <v-btn text small color="primary" @click="warehouseState" class="mr-1">
                      {{lang.Warehouse2}},{{$store.state.warehousemarkid}}
                    </v-btn>
                    <v-btn text small color="primary" @click="itemCard" class="mr-1">
                      {{lang.ItemCard}}
                    </v-btn>
                    <v-btn text small color="primary" @click="itemCardSell" class="mr-1">
                      {{lang.ItemCardInv}}
                    </v-btn>
                    <v-btn text small color="primary" @click="createNewItem" class="mr-1">
                      Novi artikal
                    </v-btn>
                    <v-btn text small color="red darken-1" @click="deleteDD2">
                      {{langC.Delete}}
                    </v-btn>
                  </v-col>
              </v-row>
            </v-card>

            <!-- <v-divider class="mt-1"></v-divider> -->
            <v-card
            class="pa-2 ma-1"
                outlined
              >
            <v-row no-gutters>
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" class="gray">
                  <div class="text-h6 pb-2">
                    <span>{{lang.ItemFor}} </span>
                  </div>
                </v-col>
              </v-row>

              <v-row class="pa-0">
                <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8" class="pa-0">
                  <v-autocomplete
                    :items="itemsLoc"
                    v-model="selectIL"
                    :label="lang.ItemSelect"
                    item-text="itemByID"
                    item-value="itemID"
                    clearable
                    return-object
                  ></v-autocomplete>
                </v-col>
            </v-row>

             <v-row class="pa-0">
                <v-col cols="12" xs="12" sm="12"  md="8"  lg="8" xl="8" class="pa-0">
                  <v-text-field
                      dense
                      :label="lang.ItemName"
                      v-model="documentDet.itemName"
                    ></v-text-field>    
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="pa-0">
                  <v-btn text small color="primary" @click="updateItemName" class="mr-1" v-if="haveItemSelected">
                    {{lang.UpdateItemName}}
                  </v-btn>
                </v-col>
            </v-row>

             <v-row>
                <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
                  <v-text-field
                      dense
                      :label="lang.ItemNameShort"
                      v-model="documentDet.itemNameShort"
                    ></v-text-field>    
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                  <v-text-field
                      dense
                      :label="lang.ItemGroup"
                      v-model="documentDet.itemGroup"

                      disabled
                    ></v-text-field>                   
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" >
                  <v-text-field
                      dense
                      :label="lang.ItemGroup"
                      v-model="documentDet.itemGroupName"
                      disabled
                    ></v-text-field>                   
                </v-col>
            </v-row>

            <v-row class="pa-0">
                <v-col cols="12" sm="8" md="8" xs="10" lg="8" xl="8" class="pa-0">
                  <v-textarea
                      dense
                      :label="lang.ItemDescription"
                      v-model="documentDet.itemDescription"
                    ></v-textarea>
                </v-col>
            </v-row>

            <v-row class="pa-0">
               <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-3 pa-0">
                  <v-text-field
                      :label="lang.ItemUnit"
                      v-model="documentDet.itemUnit"
                    ></v-text-field>    
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="mr-3 pa-0">
                  <v-text-field
                      :label="lang.ItemEANCode"
                      v-model="documentDet.ItemEANCode"
                    ></v-text-field>
                </v-col>
                
                <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pa-0">
                    <v-text-field
                      :label="lang.ItemSerialNr"
                      v-model="documentDet.itemSerialNr"
                    ></v-text-field>
                </v-col>
            </v-row>
           </v-card>

             <v-row v-if="this.$store.state.documentSide == 1">

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                  <span v-if="this.$store.state.documentSide == 1">
                      <v-text-field
                        dense
                        :label="lang.ItemPcsPlus"
                        v-model.number="peaces"
                        type="number"
                      ></v-text-field>
                   </span>
                </v-col>

                 <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
 
                </v-col>

                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0 mr-1">
                      <v-text-field
                      dense
                      :label="lang.ItemSuppBasePrice"
                      v-model.number="suppPrice"
                      type="number"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
                  <v-text-field
                      dense
                      :label="lang.ItemSuppDiscount"
                      v-model.number="suppDiscount"
                      type="number"
                    ></v-text-field>    
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                    <v-text-field
                      dense
                      :label="lang.ItemSuppPriceAmount"
                      v-model.number="this.documentDet.itemSuppPriceAmount"
                      type="number"
                      disabled
                    ></v-text-field>          
                </v-col>
            </v-row>

             <v-row v-if="this.$store.state.documentSide == 1">
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                  <v-text-field
                      dense
                      :label="lang.ItemSuppPrice"
                      v-model.number="documentDet.itemSuppPrice"
                      type="number"
                      disabled
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
  
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0 mr-1">
                  <v-text-field
                      dense
                      :label="lang.ItemMargin"
                      v-model.number="margin"
                      type="number"
                      :disabled="!fullpriceChange"
                    ></v-text-field>                   
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
                  <v-text-field
                      dense
                      :label="lang.FreeF2"
                      v-model.number="documentDet.FreeF2"
                      type="number"
                      disabled
                    ></v-text-field>                   
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                  <v-text-field
                      dense
                      :label="lang.ItemMarginAmount"
                      v-model.number="documentDet.itemMarginAmount"
                      type="number"
                      disabled
                    ></v-text-field>    
                </v-col>                
            </v-row>



             <v-row v-if="this.$store.state.documentSide === 2 || this.$store.state.documentSide === 3">
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                      <v-text-field
                        dense
                        :label="lang.itemPcsMinus"
                        v-model.number="peaces"
                        type="number"
                        color="indigo darken-4"
                      ></v-text-field>
                </v-col>
                 <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                  <v-text-field
                      dense
                      :label="lang.ItemPrice"
                      v-model.number="itemPrice"
                      type="number"
                    ></v-text-field>    
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
                  <div v-if="this.$store.state.documentSide === 1">
                    <v-text-field
                      dense
                      :label="lang.ItemMaxDiscount"
                      v-model.number="documentDet.itemMaxDiscount"
                      type="number"
                      disabled
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                  <v-text-field
                      dense
                      :label="lang.ItemBaseAmount"
                      v-model.number="documentDet.itemBaseAmount"
                      type="number"
                      disabled
                    ></v-text-field>                   
                </v-col>
            </v-row>

             <v-row v-if="this.$store.state.documentSide === 2 || this.$store.state.documentSide === 3">
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                  <v-text-field
                      dense
                      :label="lang.ItemDiscountPrice"
                      v-model.number="documentDet.itemDiscountPrice"
                      type="number"
                      disabled
                    ></v-text-field>    
                </v-col>
                 <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
                      <v-text-field
                      dense
                      :label="lang.ItemDiscountPercentage1"
                      v-model.number="homeDiscount"
                      type="number"
                      :disabled="!fullpriceChange"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
                      <v-text-field
                      dense
                      :label="lang.ItemMaxDiscount"
                      v-model.number="documentDet.itemMaxDiscount"
                      type="number"
                      disabled
                    ></v-text-field>
                </v-col>

                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                  <v-text-field
                      dense
                      :label="lang.ItemBaseAmountNoVAT"
                      v-model.number="documentDet.itemBaseAmountNoVAT"
                      type="number"
                      disabled
                    ></v-text-field>                   
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                      <v-text-field
                      dense
                      :label="lang.ItemPriceWithVAT"
                      v-model.number="homePriceWithVAT"
                      type="number"
                      :disabled="fullpriceChange"
                    ></v-text-field>
                </v-col>

                 <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
                      <v-icon
                          @click="fullpriceSwitch"
                        >
                          {{fullpriceChange ? 'create' : 'clear'}}
                        </v-icon>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0 mr-1">
                      <v-text-field
                      dense
                      :label="lang.ItemVAT1Percentage"
                      v-model.number="documentDet.itemVAT1Percentage"
                      type="number"
                      disabled
                    ></v-text-field>
                </v-col>                 
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
                  <v-text-field
                      dense
                      :label="lang.ItemVAT1Amount"
                      v-model.number="documentDet.itemVAT1Amount"
                      type="number"
                      disabled
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
 
                </v-col>
                
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                  <v-text-field
                      dense
                      :label="lang.ItemAmountWithVAT"
                      v-model.number="documentDet.itemAmountWithVAT"
                      type="number"
                      disabled
                    ></v-text-field>           
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                    <v-text-field
                    dense
                    :label="lang.ItemDirectDuty1"
                    v-model.number="documentDet.itemDirectDuty1"
                    type="number"
                  ></v-text-field>
                </v-col>
                 <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                  <v-text-field
                      dense
                      :label="lang.ItemDirectDuty1Amount"
                      v-model.number="documentDet.itemDirectDuty1Amount"
                      type="number"
                      disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                  <v-text-field
                      dense
                      :label="langItemFullTotal"
                      v-model.number="documentDet.itemFullTotal"
                      type="number"
                      disabled
                    ></v-text-field>                   
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-checkbox
                    v-if="this.$store.state.language == 'EN'"
                    v-model="documentDet.itemService"
                    label="Service"
                  ></v-checkbox>

                  <v-checkbox
                    v-if="this.$store.state.language == 'HR'"
                    v-model="documentDet.itemService"
                    label="Usluga"
                  ></v-checkbox>
                </v-col>
                 <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="pr-2">
                    <div v-if="showPT">
                      <v-select
                        :label="lang.MainLedgerProjectTypeName"
                        :items="projectTypes"
                        v-model="selectPT"
                        item-text="TypeName"
                        item-value="id"
                        return-object
                        @change="choosedProjectType(selectPT)"
                        clearable
                        class="pr-2"></v-select>
                    </div>
                  </v-col>
            </v-row>
            
             
          <v-btn
            class="primary mr-1"
             :disabled="!formIsValid"
            @click="register">
            {{lang.SaveItem}} {{ documentNameLoc }} 
          </v-btn>
          <v-btn
            color="green"
            @click="documentdetaillist">
            {{langC.Back}}
          </v-btn>

      </v-col>
    </v-row>

    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.statewhs">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <appDocumentWarehouseState></appDocumentWarehouseState>
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.suppcard">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <appDocumentItemCard />
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.sellcard">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <appDocumentItemCardSales></appDocumentItemCardSales>
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row>

     <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="10" lg="10" xs="12" sm="12" md="10" class="pa-0">
        <div v-if="this.newItemCreate">
            <v-row>
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <ItemCreate />
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.showConditionGroup">
            <v-row>
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <ItemListFast :conditionGroup="conditionGroup" />
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>

  </v-container>
  </v-form>
</template>

<script>
// import _ from 'lodash'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import ItemService from '@/services/ItemService'
import DocumentWarehouseState from './DocumentWarehouseState'
import DocumentItemCard from './DocumentItemCard'
import DocumentItemCardSales from './DocumentItemCardSales'
import ItemCreate from '../Item/ItemCreate'
import ItemListFast from '../Item/ItemListFast'
import {defDocumentDetail, defNewDocumentDetail} from './documentHelper'
import { mapGetters } from 'vuex'
import { newUpdates, testLastUpdate } from '@/services/TestLastUpdate'
import dayjs from 'dayjs'

export default {
  name: 'documentdetailcreate',
  $validates: true,
  data () {
    return {
      snackbar: false,
      alert: true,
      text: '',
      timeout: 2000,
      color: 'green',
      numberValue: 0,
      lang: {},
      langC: {},
      docdetailitems: [],
      menu: false,
      menu2: false,
      menu3: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      documentName: '',
      documentNameLoc: '',
      documentDet: {
      },
      itemTotalPrice: 0,
      error: null,
      typeError: null,
      localError: false,
      newDocument: {},
      size: 'sm',
      required: (value) => !!value || 'Required.',
      itemsLoc: [],
      itemLoc: {},
      CompanyId: 0,
      selectIL: {},
      sumDoc: {
        documentTotalVAT1Per: 0,
        documentTotalVAT1Base: 0,
        documentTotalVAT1Amount: 0,
        documentTotalVAT2Per: 0,
        documentTotalVAT2Base: 0,
        documentTotalVAT2Amount: 0,
        documentTotalVAT3Per: 0,
        documentTotalVAT3Base: 0,
        documentTotalVAT3Amount: 0,
        documentTotalVAT4Per: 0,
        documentTotalVAT4Base: 0,
        documentTotalVAT4Amount: 0,
        documentTotalVAT5Per: 0,
        documentTotalVAT5Base: 0,
        documentTotalVAT5Amount: 0,
        documentTotalAmountNetto: 0,
        documentTotalAmountVAT: 0,
        documentTotalAmountBrutto: 0,
        documentTotalAmountPayInAdvance: 0,
        documentTotalAmountToPay: 0,
        documentTotalAmountNettoForeign: 0,
        documentTotalAmountVATForeign: 0,
        documentTotalAmountBruttoForeign: 0,
        documentTotalAmountPayInAdvanceForeign: 0,
        documentTotalAmountToPayForeign: 0,
        documentTotalAmountDirectD1: 0,
        documentTotalAmountDirectD2: 0,
        documentTotalAmountDirectD1Fore: 0,
        documentTotalAmountDirectD2Fore: 0
      },
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
        documentName: '',
        databaseDocID: 0
      },
      mainQuery2: {
        CompanyId: 1,
        itemID: ''
      },
      currnecyType: [
        { text: 'Kn', value: 'Kn' },
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      money: {
        prefix: '= ',
        suffix: ' ',
        precision: 3
      },
      documentUpdated: {},
      price: 7.500,
      pcsLoc: 0,
      suppAmount: 0,
      suppNetoAmount: 0,
      maxDisc: 0,
      maxDiscPer: 0,
      haveEdit: false,
      noCalculate: false,
      haveNotice: '',
      firstUpdate: 0,
      workOn: false,
      fullpriceChange: true,
      fullpriceBtn: 'Change',
      itemIndex: 0,
      activItems: [],
      calcVersion: 1,
      statewhs: false, 
      suppcard: false,
      outcard: false,
      sellcard: false,
      groupcard: false,
      newItemCreate: false,
      privGroups: [],
      selectGroup: {},
      documentAna: {
        CompanyId: 0,
        BusinessYear: 0,
        UserId: 0,
        partnerId: 0,
        documentName: '',
        docFromDate: null,
        docToDate: null,
        itemID: null,
        warehouseID: null
      },
      payInAdvance: 0,
      exportDoc: false,
      langItemPrice: '',
      langItemFullTotal: '',
      ExRate: this.$store.getters.NeedDefExRate,
      homeDiscount : 0,
      exportDiscount: 0,
      homePriceWithVAT : 0,
      itemPrice: 0,
      peaces: 1,
      margin: 0,
      suppPrice: 0,
      suppDiscount: 0,
      emptyObj: {},
      ePrice: 0,
      ePcs: 0,
      showConditionGroup: false,
      conditionGroup: null,
      projectTypes: [],
      selectPT: {},
      showPT: false,
      haveItemSelected: false
    }
  },
  components: {
    appDocumentWarehouseState: DocumentWarehouseState,
    appDocumentItemCard: DocumentItemCard,
    appDocumentItemCardSales: DocumentItemCardSales,
    ItemCreate,
    ItemListFast
  },
  async created () {
 
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    this.exportDoc = this.$store.state.documentActivHead.exportDoc
    let haveCurrency = ''
    if (this.exportDoc === false) {
      haveCurrency = this.$store.state.documentActivHead.documentHomeCurrency ? (' ' + this.$store.state.documentActivHead.documentHomeCurrency) : ''
    } else {
      haveCurrency = this.$store.state.documentActivHead.documentForeignCurrency ? (' ' + this.$store.state.documentActivHead.documentForeignCurrency) : ''
    }
    this.langItemPrice = this.lang.ItemPrice + haveCurrency
    this.langItemFullTotal = this.lang.ItemFullTotal + haveCurrency

    this.ExRate = parseFloat(this.$store.state.documentActivHead.documentExRate)
    this.projectTypes = this.$store.state.projectTypes ? this.$store.state.projectTypes : []
    const docName = this.$store.state.documentActiv
    if (docName === 'InvoiceSales' || docName === 'DeliveryNoteSales' || docName === 'InternalDeliveryNotes') {
      this.showPT = true
    }

    // if add new item in items
    this.$store.dispatch('setnewItemAround', false)
    // if NEW item
    if (this.$store.state.editItem === 0 ) {
      this.newItem = true
      this.firstUpdate = 3
      await this.makeItNew()
    }
    // if edit item
    if (this.$store.state.editItem === 1 && this.$store.state.documentdetailid) {
      const itemOnly = this.$store.state.documentActivItems.find(item => {
        return item.id === this.$store.state.documentdetailid
      })
      await Object.assign(this.documentDet, itemOnly)
      this.itemIndex = this.$store.state.documentActivItems.indexOf(itemOnly)
      if (this.documentDet.itemID) {
        this.selectIL =
        {
          itemByID: (this.documentDet.itemID + ' ' + this.documentDet.itemName),
          itemID: this.documentDet.itemID
        }
        this.haveItemSelected = true
      }
      if (this.exportDoc) {
        this.exportDiscount = this.documentDet.itemDiscountPercentage1
      } else {
        this.homeDiscount = this.documentDet.itemDiscountPercentage1
      }
      await this.doParse()
      this.itemPrice = this.documentDet.itemPrice
      this.homePriceWithVAT = this.documentDet.itemPriceWithVAT
      if (this.$store.state.documentSide === 1) {
        this.pcsLoc = this.documentDet.itemPcsPlus
        this.peaces = this.documentDet.itemPcsPlus
      }
      if (this.$store.state.documentSide === 2) {
        this.pcsLoc = this.documentDet.itemPcsMinus
        this.peaces = this.documentDet.itemPcsMinus
      }
      if (this.$store.state.documentSide === 3) {
        this.pcsLoc = this.documentDet.itemPcsNeutral
        this.peaces = this.documentDet.itemPcsNeutral
      }
      this.pcsLoc = this.round(this.pcsLoc, 3)
      this.suppPrice= this.documentDet.itemSuppBasePrice
      this.margin = this.documentDet.itemMargin
      this.suppDiscount = this.documentDet.itemSuppDiscount
      this.selectPT = this.defaultItem && this.defaultItem.TypeID ? this.projectTypes.find(element => element.id === this.defaultItem.TypeID) : null
      // if (this.$store.state.documentActivHead.exportDoc === false && this.documentDet.itemPriceOriginal === 0) {
      //   this.documentDet.itemPriceOriginal = this.documentDet.itemPrice
      //   this.documentDet.itemFullPriceOriginal = this.documentDet.itemPriceWithVAT
      // } else {
      //   this.documentDet.itemPriceOriginal = this.documentDet.itemForePrice
      //   this.documentDet.itemFullPriceOriginal = this.documentDet.itemForePriceWithVAT
      // }

    }

    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    this.itemsLoc = this.$store.state.itemStore ? this.$store.state.itemStore : []
    this.privGroups = this.$store.state.groupStore ? this.$store.state.groupStore : []

    this.workOn = true
  },
  computed: {
    formIsValid () {
      return this.documentDet.itemID !== ''
    },
    ...mapGetters({
      mySelectedItemId: 'NeedSelectedItemId'
    }),
  },
  methods: {
    warehouseState() {
      this.statewhs = !this.statewhs
      this.$store.dispatch('setdontGo', false)
      this.$store.dispatch("setGroupid", null)
      if (this.selectGroup && this.selectGroup.id) {
        this.$store.dispatch("setGroupid", this.selectGroup.id)
      }
    },
    showGroup() {
      this.conditionGroup = null
      if (this.selectGroup && this.selectGroup.id) {
        this.conditionGroup = this.selectGroup.id
      }
      this.showConditionGroup = !this.showConditionGroup
    },
    choosedProjectType (choosedPT) {
      if (choosedPT) {
        const result = this.projectTypes.filter(element => element.id === choosedPT.id)
        if (result[0]) {
          const res = result[0]
          this.documentDet.TypeID = res.id
          this.documentDet.TypeName = res.TypeName
          this.selectPT = res
        } else {
          this.text = this.lang.MainLedgerProjectTypeEmpty
          this.color = 'red'
          this.snackbar = true
        }
      } else {
        this.documentDet.TypeID = null
        this.documentDet.TypeName = null
      }     
    },
    createNewItem() {
      this.$store.dispatch('setnewItemAround', true)
      this.newItemCreate = !this.newItemCreate
    },
    async initAna() {
      if (this.$store.state.companyid) {
        this.documentAna.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.documentAna.BusinessYear = this.$store.state.businessYear
      }
      if (this.selectIL.itemID) {
        this.documentAna.itemID = this.selectIL.itemID
      }
      this.documentAna.docFromDate = dayjs([this.documentAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
      this.documentAna.docToDate = dayjs([this.documentAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
      await this.$store.dispatch('setDocumentAna', this.documentAna)
    },
    itemCard() {
      if (this.selectIL.itemID) {
        this.initAna()  
        this.suppcard = !this.suppcard
      } else {
        this.text = this.lang.itemNotSelected
        this.color = 'red'
        this.snackbar = true
      }
    },
    itemCardMinus() {
      if (this.selectIL.itemID) {
        this.initAna()
        this.outcard = !this.outcard
      } else {
        this.text = this.lang.itemNotSelected
        this.color = 'red'
        this.snackbar = true
      }
    },
    itemCardSell() {
      if (this.selectIL.itemID) {
        this.initAna()
        this.sellcard = !this.sellcard
      } else {
        this.text = this.lang.itemNotSelected
        this.color = 'red'
        this.snackbar = true
      }
    },
    fullpriceSwitch() {
      this.fullpriceChange = !this.fullpriceChange
      this.initPcs()

      // if (this.fullpriceChange === true) {
      //   if (isNaN(this.documentDet.itemPriceWithVAT)) {
      //     this.documentDet.itemPriceWithVAT = 1
      //   }
      //   if (!this.documentDet.itemPriceWithVAT) {
      //     this.documentDet.itemPriceWithVAT = 0
      //   }
      //   // eslint-disable-next-line
      //   console.log('Full price is TRUE')
      //   this.documentDet.itemPrice = this.documentDet.itemPrice ? this.round(this.documentDet.itemPrice, 3) : 0
      //   this.documentDet.itemBaseAmount = this.round((this.documentDet.itemPrice * this.pcsLoc), 2)
      //   if (this.homeDiscount === null) {
      //     this.homeDiscount = 0
      //   }
      //   this.documentDet.itemBaseAmountNoVAT = (this.documentDet.itemBaseAmount - (this.documentDet.itemBaseAmount * (this.homeDiscount/ 100)))
      //   this.documentDet.itemBaseAmountNoVAT = this.round(this.documentDet.itemBaseAmountNoVAT, 2)

      //   const baseAmount = (this.documentDet.itemPriceWithVAT * this.pcsLoc) / (1 + (this.documentDet.itemVAT1Percentage / 100))

      //   // three options 
      //   // if baseAmount is bigger then no discount base amount - adjust all (discount = 0)
      //   // if baseAmount is between base amount and discount base amount, then adjust discount
      //   // if baseAmount is lower then discount base amount then - adjust all (discount = 0)
      //   if (this.homeDiscount > 0) {
      //     let doneIt = false
      //     if (baseAmount >= this.documentDet.itemBaseAmount) {
      //       this.homeDiscount = 0
      //       this.documentDet.itemBaseAmountNoVAT = baseAmount
      //       this.documentDet.itemBaseAmount = baseAmount
      //       this.documentDet.itemPrice = this.round((baseAmount / this.pcsLoc), 3)
      //       this.itemPrice = this.round((baseAmount / this.pcsLoc), 3)
      //       doneIt = true
      //     }
      //     if (doneIt === false && (baseAmount > this.documentDet.itemBaseAmountNoVAT && baseAmount < this.documentDet.itemBaseAmount)) {
      //       this.documentDet.itemBaseAmountNoVAT = baseAmount
      //       const discountAmount = this.documentDet.itemBaseAmount - this.documentDet.itemBaseAmountNoVAT
      //       this.homeDiscount = this.round((discountAmount / (this.documentDet.itemBaseAmount / 100)),3)
      //       doneIt = true
      //     }
      //     if (doneIt === false && (baseAmount <= this.documentDet.itemBaseAmountNoVAT)) {
      //       this.homeDiscount = 0
      //       this.documentDet.itemBaseAmountNoVAT = baseAmount
      //       this.documentDet.itemBaseAmount = baseAmount
      //       this.documentDet.itemPrice = this.round((baseAmount / this.pcsLoc), 3)
      //       this.itemPrice = this.round((baseAmount / this.pcsLoc), 3)
      //       doneIt = true
      //     }
      //   }
      //   if (this.homeDiscount === 0) {
      //     this.documentDet.itemBaseAmountNoVAT = baseAmount
      //     const discountAmount = this.documentDet.itemBaseAmount - this.documentDet.itemBaseAmountNoVAT
      //     this.homeDiscount = this.round((discountAmount / (this.documentDet.itemBaseAmount / 100)),2)
      //   }
      //   this.calculatePrice()
      // }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    doParse () {
      this.documentDet.ItemWeight = this.documentDet.ItemWeight ? parseFloat(this.documentDet.ItemWeight) : 0
      this.documentDet.ItemDimLenght = this.documentDet.ItemDimLenght ? parseFloat(this.documentDet.ItemDimLenght) : 0
      this.documentDet.ItemDimHeight = this.documentDet.ItemDimHeight ? parseFloat(this.documentDet.ItemDimHeight) : 0
      this.documentDet.ItemDimDepth = this.documentDet.ItemDimDepth ? parseFloat(this.documentDet.ItemDimDepth) : 0
      this.documentDet.ItemBelongsToId = this.documentDet.ItemBelongsToId ? parseFloat(this.documentDet.ItemBelongsToId) : 0
      this.documentDet.itemSuppBasePrice = this.documentDet.itemSuppBasePrice ? parseFloat(this.documentDet.itemSuppBasePrice) : 0
      this.documentDet.itemSuppBasePriceAmount = this.documentDet.itemSuppBasePriceAmount ? parseFloat(this.documentDet.itemSuppBasePriceAmount) : 0
      this.documentDet.itemSuppDiscunt = this.documentDet.itemSuppDiscunt ? parseFloat(this.documentDet.itemSuppDiscunt) : 0
      this.documentDet.itemSuppDiscuntAmount = this.documentDet.itemSuppDiscuntAmount ? parseFloat(this.documentDet.itemSuppDiscuntAmount) : 0
      this.documentDet.itemSuppPrice = this.documentDet.itemSuppPrice ? parseFloat(this.documentDet.itemSuppPrice) : 0
      this.documentDet.itemSuppPriceAmount = this.documentDet.itemSuppPriceAmount ? parseFloat(this.documentDet.itemSuppPriceAmount) : 0
      this.documentDet.ItemSuppCode = this.documentDet.ItemSuppCode ? parseFloat(this.documentDet.ItemSuppCode) : 0
      this.documentDet.itemMargin = this.documentDet.itemMargin ? parseFloat(this.documentDet.itemMargin) : 0
      this.documentDet.itemMarginAmount = this.documentDet.itemMarginAmount ? parseFloat(this.documentDet.itemMarginAmount) : 0
      this.documentDet.itemMaxDiscount = this.documentDet.itemMaxDiscount ? parseFloat(this.documentDet.itemMaxDiscount) : 0
      this.documentDet.itemPrice = this.documentDet.itemPrice ? parseFloat(this.documentDet.itemPrice) : 0
      this.documentDet.itemVAT1Percetage = this.documentDet.itemVAT1Percetage ? parseFloat(this.documentDet.itemVAT1Percetage) : 0
      this.documentDet.itemVAT1Amount = this.documentDet.itemVAT1Amount ? parseFloat(this.documentDet.itemVAT1Amount) : 0
      this.documentDet.itemVAT2Percentge =this.documentDet.itemVAT2Percentge ? parseFloat(this.documentDet.itemVAT2Percentge) : 0
      this.documentDet.itemVAT2Amount = this.documentDet.itemVAT2Amount ? parseFloat(this.documentDet.itemVAT2Amount) : 0
      this.documentDet.itemDirectDuty1 = this.documentDet.itemDirectDuty1 ? parseFloat(this.documentDet.itemDirectDuty1) : 0
      this.documentDet.itemDirectDuty2 = this.documentDet.itemDirectDuty2 ? parseFloat(this.documentDet.itemDirectDuty2) : 0
      this.documentDet.itemPriceWithVAT = this.documentDet.itemPriceWithVAT ? parseFloat(this.documentDet.itemPriceWithVAT) : 0
      this.documentDet.itemForePrice = this.documentDet.itemForePrice ? parseFloat(this.documentDet.itemForePrice) : 0
      this.documentDet.itemForeVAT1Percentage = this.documentDet.itemForeVAT1Percentage ? parseFloat(this.documentDet.itemForeVAT1Percentage) : 0
      this.documentDet.itemForeVAT1Amount = this.documentDet.itemForeVAT1Amount ? parseFloat(this.documentDet.itemForeVAT1Amount) : 0
      this.documentDet.itemForeVAT2Percentage = this.documentDet.itemForeVAT2Percentage ? parseFloat(this.documentDet.itemForeVAT2Percentage) : 0
      this.documentDet.itemForeVAT2Amount = this.documentDet.itemForeVAT2Amount ? parseFloat(this.documentDet.itemForeVAT2Amount) : 0
      this.documentDet.itemForeDirectDuty1 = this.documentDet.itemForeDirectDuty1 ? parseFloat(this.documentDet.itemForeDirectDuty1) : 0
      this.documentDet.itemForeDirectDuty2 = this.documentDet.itemForeDirectDuty2 ? parseFloat(this.documentDet.itemForeDirectDuty2) : 0
      this.documentDet.itemForePriceWithVAT = this.documentDet.itemForePriceWithVAT ? parseFloat(this.documentDet.itemForePriceWithVAT) : 0
      this.documentDet.itemPcsNeutral = this.documentDet.itemPcsNeutral ? parseFloat(this.documentDet.itemPcsNeutral) : 0
      this.documentDet.itemPcsMinus = this.documentDet.itemPcsMinus ? parseFloat(this.documentDet.itemPcsMinus) : 0
      this.documentDet.itemFullTotal = this.documentDet.itemFullTotal ? parseFloat(this.documentDet.itemFullTotal) : 0
      this.documentDet.itemFullTotalFore = this.documentDet.itemFullTotalFore ? parseFloat(this.documentDet.itemFullTotalFore) : 0
      this.documentDet.itemDirectDuty1Amount = this.documentDet.itemDirectDuty1Amount ? parseFloat(this.documentDet.itemDirectDuty1Amount) : 0
      this.documentDet.itemDirectDuty2Amount = this.documentDet.itemDirectDuty2Amount ? parseFloat(this.documentDet.itemDirectDuty2Amount) : 0
      this.documentDet.itemDirectDuty1AmountFore = this.documentDet.itemDirectDuty1AmountFore ? parseFloat(this.documentDet.itemDirectDuty1AmountFore) : 0
      this.documentDet.itemDirectDuty2AmountFore = this.documentDet.itemDirectDuty2AmountFore ? parseFloat(this.documentDet.itemDirectDuty2AmountFore) : 0
      this.documentDet.FreeF1 = this.documentDet.FreeF1 ? parseFloat(this.documentDet.FreeF1) : 0
      this.documentDet.FreeF2 = this.documentDet.FreeF2 ? parseFloat(this.documentDet.FreeF2) : 0
      this.documentDet.FreeF3 = this.documentDet.FreeF3 ? parseFloat(this.documentDet.FreeF3) : 0
      this.documentDet.FreeF4 = this.documentDet.FreeF4 ? parseFloat(this.documentDet.FreeF4) : 0
      this.documentDet.FreeF5 = this.documentDet.FreeF5 ? parseFloat(this.documentDet.FreeF5) : 0
      this.documentDet.itemPriceOriginal = this.documentDet.itemPriceOriginal ? parseFloat(this.documentDet.itemPriceOriginal) : 0
      this.documentDet.itemFullPriceOriginal = this.documentDet.itemFullPriceOriginal ? parseFloat(this.documentDet.itemFullPriceOriginal) : 0
    },
    async register () {
      try {
        this.localError = false

        if (this.exportDoc) {
          this.documentDet.itemDiscountPercentage1 = this.exportDiscount
        } else {
          this.documentDet.itemDiscountPercentage1 = this.homeDiscount
        }

        if (this.$store.state.editItem === 1) {
          const activItems = this.$store.state.documentActivItems ? this.$store.state.documentActivItems : []
          activItems.splice(this.itemIndex, 1, this.documentDet)
          if (activItems) {
            this.$store.dispatch('setDocumentActivItems', activItems)
          } else {
            this.$store.dispatch('setDocumentActivItems', [])
          }
          await this.$store.dispatch('seteditItem', 0)
          this.haveNotice = this.lang.itemUpdated
        } else {
          const newItem = this.documentDet
          this.activItems = this.$store.state.documentActivItems
          this.activItems.push(newItem)
          if (this.activItems) {
            this.$store.dispatch('setDocumentActivItems', this.activItems)
          } else {
            this.$store.dispatch('setDocumentActivItems', [])
          }
          this.haveNotice = this.lang.itemAdded
        }
        this.makeSum()
  
        this.$router.push({
          name: 'documentview'
        })
      } catch (error) {
        this.error = 'Greska u konekciji na bazu' + error
        this.typeError = 'error'
      }
    },
    close () {
      this.show = false
    },
    async updateItemName() {
      if (this.haveItemSelected) {
        const updateItem = {}
        updateItem.itemID = this.documentDet.itemID
        updateItem.itemName = this.documentDet.itemName
        updateItem.itemDescription = this.documentDet.itemDescription
        updateItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
        const updateItems = []
        updateItems.push(updateItem)
        await ItemService.updateprices(updateItems)
        .then(async(res) => {
          console.log(res.data)
          this.text = this.lang.UpdateItemNameUpdated
          this.color = 'green'
          
          this.snackbar = true
          await newUpdates(1)
          await testLastUpdate()
          // this.mainQuery.CompanyId = this.$store.state.companyid
          // this.itemsLoc = (await ItemService.index(this.mainQuery)).data.items
          // if (this.itemsLoc) {
          //   this.$store.dispatch('setActivItems', this.itemsLoc)
          // }
        })
        .catch(err => {
          console.log(' Error items ', err)
          this.text = this.lang.UpdateItemNameUpdatedError
          this.color = 'red'
          this.snackbar = true
        })
      }
    },
    async deleteDD2 () {
      try {
        if (this.$store.state.documentdetailid) {
          const activItems = this.$store.state.documentActivItems.filter(item => {
            return item.id !== this.$store.state.documentdetailid
          })
          if (activItems) {
            this.$store.dispatch('setDocumentActivItems', activItems)
          } else {
            this.$store.dispatch('setDocumentActivItems', [])
          }
          // make sum local head
          this.makeSum()
          if (this.$store.state.language === 'EN') {
            this.error = 'Item is deleted.'
            this.typeError = 'success'
          }
          if (this.$store.state.language === 'HR') {
            this.error = 'Obrisana je stavka sa dokumenta'
            this.typeError = 'success'
          }
          
          await this.$router.push({
            name: 'documentview'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async documentdetaillist () {
      try {
        await this.$router.push({
          name: 'documentview'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
    initPcs() {
      this.pcsLoc = 0

      if (this.$store.state.documentSide === 1) {
        this.pcsLoc = this.documentDet.itemPcsPlus ? this.documentDet.itemPcsPlus : 0
      }
      if (this.$store.state.documentSide === 2) {
        this.pcsLoc = this.documentDet.itemPcsMinus ? this.documentDet.itemPcsMinus : 0
      }
      if (this.$store.state.documentSide === 3) {
        this.pcsLoc = this.documentDet.itemPcsNeutral ? this.documentDet.itemPcsNeutral : 0
      }
      this.pcsLoc = this.round(this.pcsLoc, 2)
    },
    initSupp() {
      this.initPcs()
      this.documentDet.itemSuppBasePrice = this.documentDet.itemSuppBasePrice ? this.documentDet.itemSuppBasePrice : 0
      this.documentDet.itemSuppDiscount = this.suppDiscount ? this.suppDiscount : 0
      //this.documentDet.itemSuppDiscount = this.documentDet.itemSuppDiscount ? this.documentDet.itemSuppDiscount : 0
      this.documentDet.itemSuppBasePriceAmount  = (this.documentDet.itemSuppBasePrice * this.pcsLoc) ? this.round((this.documentDet.itemSuppBasePrice * this.pcsLoc),2) : 0
      this.documentDet.itemSuppPriceAmount = (this.documentDet.itemSuppBasePriceAmount - (this.documentDet.itemSuppBasePriceAmount * (this.documentDet.itemSuppDiscount / 100))) ? this.round((this.documentDet.itemSuppBasePriceAmount - (this.documentDet.itemSuppBasePriceAmount * (this.documentDet.itemSuppDiscount / 100))),2) : 0
      this.documentDet.itemSuppDiscountAmount = (this.documentDet.itemSuppBasePriceAmount - this.documentDet.itemSuppPriceAmount) ? (this.documentDet.itemSuppBasePriceAmount - this.documentDet.itemSuppPriceAmount) : 0
      if (this.pcsLoc !== 0) {
        this.documentDet.itemSuppPrice = (this.documentDet.itemSuppPriceAmount / this.pcsLoc) ? this.round((this.documentDet.itemSuppPriceAmount / this.pcsLoc),3) : 0
      }

    },
    calculateSupp() {
     
      this.initPcs()
      if (this.fullpriceChange) {
        if (this.$store.state.companyActiv.CompanyUseRetailPrice){
          this.initSupp()
          this.documentDet.itemBaseAmount = this.round((this.documentDet.itemPrice * this.pcsLoc), 2)
          this.documentDet.itemMarginAmount = (this.documentDet.itemBaseAmount - this.documentDet.itemSuppPriceAmount) ? this.round((this.documentDet.itemBaseAmount - this.documentDet.itemSuppPriceAmount),2) : 0
          this.documentDet.itemMargin = 0
          if (this.documentDet.itemSuppPriceAmount !== 0) {
            this.documentDet.itemMargin = (this.documentDet.itemMarginAmount / (this.documentDet.itemSuppPriceAmount / 100)) ? this.round((this.documentDet.itemMarginAmount / (this.documentDet.itemSuppPriceAmount / 100)),2) : 0
          }
          this.documentDet.FreeF2 = (this.documentDet.itemMarginAmount / this.pcsLoc) ? this.round((this.documentDet.itemMarginAmount / this.pcsLoc),2) : 0
          this.maxDiscPer = (this.documentDet.itemAmountWithVAT / 100) ? this.round((this.documentDet.itemAmountWithVAT / 100),3) : 0
        }
      }
    },
    marginCalculate() {
      this.initPcs()
      if (!this.fullpriceChange) {
        this.initSupp()
        if (this.$store.state.companyActiv.CompanyUseRetailPrice){
          this.documentDet.itemMarginAmount = this.round(((this.documentDet.itemSuppPriceAmount * (this.documentDet.itemMargin / 100))),3)
          if (this.documentDet.itemMarginAmount !== 0) {
            this.documentDet.itemBaseAmount = this.round((this.documentDet.itemSuppPriceAmount + this.documentDet.itemMarginAmount),2)
            this.documentDet.itemPrice = this.pcsLoc !== 0 ? this.round((this.documentDet.itemBaseAmount / this.pcsLoc ),2) : 0        
          }
          this.maxDiscPer = (this.documentDet.itemAmountWithVAT / 100) ? this.round((this.documentDet.itemAmountWithVAT / 100),3) : 0
          this.documentDet.itemMaxDiscount = ((this.documentDet.itemMarginAmount / this.maxDiscPer)) ? this.round(((this.documentDet.itemMarginAmount / this.maxDiscPer)),2) : 0
          this.calculateDiscount()
        }
      }
    },
    calculatePrice(){
       if (!this.fullpriceChange) {
          this.initPcs()

          // if (!this.documentDet.itemPriceWithVAT) {
          //   this.documentDet.itemPriceWithVAT = this.documentDet.itemFullPriceOriginal
          // }
          // if (this.documentDet.itemPriceWithVAT > this.documentDet.itemFullPriceOriginal) {
          //   this.documentDet.itemPriceWithVAT = this.documentDet.itemFullPriceOriginal
          // }

          this.documentDet.itemDirectDuty1 = this.documentDet.itemDirectDuty1 ? this.round(this.documentDet.itemDirectDuty1, 2) : 0
          this.documentDet.itemVAT1Percentage = this.documentDet.itemVAT1Percentage ? this.round(this.documentDet.itemVAT1Percentage, 2) : 0
          this.documentDet.itemPriceWithVAT = this.documentDet.itemPriceWithVAT ? this.round(this.documentDet.itemPriceWithVAT, 2) : 0

          this.documentDet.itemAmountWithVAT = this.documentDet.itemPriceWithVAT * this.pcsLoc ? this.documentDet.itemPriceWithVAT * this.pcsLoc : 0
          this.documentDet.itemAmountWithVAT = this.round(this.documentDet.itemAmountWithVAT, 2)
          this.documentDet.itemDirectDuty1Amount = this.round((this.documentDet.itemDirectDuty1 * this.pcsLoc), 2)
          this.documentDet.itemFullTotal = (this.documentDet.itemAmountWithVAT + this.documentDet.itemDirectDuty1Amount)
          this.documentDet.itemFullTotal = this.round(this.documentDet.itemFullTotal, 2)

          this.documentDet.itemBaseAmountNoVAT= this.documentDet.itemAmountWithVAT / (1 + (this.documentDet.itemVAT1Percentage / 100))
          this.documentDet.itemBaseAmountNoVAT = this.round((this.documentDet.itemBaseAmountNoVAT), 2)

          if (this.$store.state.companyActiv.CompanyUseRetailPrice === true && (this.$store.state.documentSide === 2 || this.$store.state.documentSide === 3)) {
            const percentage = this.documentDet.itemBaseAmount / 100
            this.documentDet.itemDiscountAmount1 = this.documentDet.itemBaseAmount - this.documentDet.itemBaseAmountNoVAT
            this.documentDet.itemDiscountPercentage1 = this.round((this.documentDet.itemDiscountAmount1 / percentage), 2)
            this.homeDiscount = this.documentDet.itemDiscountPercentage1
          } 
          if (this.$store.state.companyActiv.CompanyUseRetailPrice === false && (this.$store.state.documentSide === 2 || this.$store.state.documentSide === 3)) {
            this.documentDet.itemDiscountAmount1 = 0
            this.documentDet.itemDiscountPercentage1 = 0

            this.documentDet.itemBaseAmount = this.documentDet.itemBaseAmountNoVAT
            this.documentDet.itemPrice = this.round(this.pcsLoc, 2) !== 0 ? this.round(this.documentDet.itemBaseAmount / this.pcsLoc, 2) : 0
            this.documentDet.itemPrice = this.round((this.documentDet.itemPrice), 2)
            this.homeDiscount = 0
          }
          if (this.$store.state.documentSide === 2 || this.$store.state.documentSide === 3) {
            this.documentDet.itemPrice = this.round(this.pcsLoc, 2) !== 0 ? this.round(this.documentDet.itemBaseAmount / this.pcsLoc, 2) : 0
            this.documentDet.itemPrice = this.round((this.documentDet.itemPrice), 2)
            //this.calculateDiscount()
          }
          if (this.$store.state.documentSide === 1) {
            
            this.documentDet.itemVAT1Amount = this.documentDet.itemAmountWithVAT - this.documentDet.itemBaseAmountNoVAT
            this.documentDet.itemDiscountAmount1 = 0
            this.homeDiscount = 0
            this.documentDet.itemDiscountPercentage1 = this.homeDiscount
            this.documentDet.itemBaseAmount = this.documentDet.itemBaseAmountNoVAT
            // have new discount, then start again wih new discount
            if (this.$store.state.documentActiv === 'ReceiptPurchase') {
              this.initSupp()
              
              if (this.$store.state.companyActiv.CompanyUseRetailPrice) {

                 this.documentDet.itemMarginAmount = this.round((this.documentDet.itemBaseAmount - this.documentDet.itemSuppPriceAmount),2)
                if (this.documentDet.itemMarginAmount < 0) {
                  this.documentDet.itemMarginAmount = 0
                }
                const per2 = this.documentDet.itemSuppPriceAmount / 100
                this.documentDet.FreeF2 = this.round((this.documentDet.itemMarginAmount /this.pcsLoc) ,2)
                
                this.documentDet.itemMargin = per2 !== 0 ? this.round(this.documentDet.itemMarginAmount / per2, 2) : 0
                this.margin = this.documentDet.itemMargin
                
                this.maxDiscPer = 0
                this.documentDet.itemMaxDiscount = 0
              }
               if (!this.$store.state.companyActiv.CompanyUseRetailPrice) {
                this.documentDet.itemMargin = this.margin
                this.documentDet.itemMarginAmount = this.round((this.documentDet.itemBaseAmount * (this.margin / 100)),2)
               
                if (this.documentDet.itemMarginAmount < 0) {
                  this.documentDet.itemMarginAmount = 0
                }
                this.documentDet.FreeF2 = 0
                
                this.maxDiscPer = 0
                this.documentDet.itemMaxDiscount = 0
                this.documentDet.itemBaseAmount = this.round((this.documentDet.itemSuppPriceAmount + this.documentDet.itemMarginAmount),2)
                this.documentDet.itemPrice = this.pcsLoc !== 0 ? this.round((this.documentDet.itemBaseAmount / this.pcsLoc ),2) : 0 
                this.documentDet.itemBaseAmountNoVAT = this.documentDet.itemBaseAmount
                this.documentDet.itemVAT1Amount = ((this.documentDet.itemBaseAmountNoVAT * (this.documentDet.itemVAT1Percentage / 100)))
                this.documentDet.itemAmountWithVAT = ((this.documentDet.itemBaseAmountNoVAT) + (this.documentDet.itemVAT1Amount))
                this.documentDet.itemAmountWithVAT = this.round(this.documentDet.itemAmountWithVAT, 2)
              }

            }
          }
        }

    },
    calculateDiscount () {
        this.initPcs()
        
        this.documentDet.itemDirectDuty1 = this.documentDet.itemDirectDuty1 ? this.round(this.documentDet.itemDirectDuty1, 2) : 0
        this.documentDet.itemVAT1Percentage = this.documentDet.itemVAT1Percentage ? this.round(this.documentDet.itemVAT1Percentage, 2) : 0
        this.documentDet.itemPrice = this.documentDet.itemPrice ? this.round(this.documentDet.itemPrice, 2) : 0
        this.documentDet.itemBaseAmount = this.round((this.documentDet.itemPrice * this.pcsLoc), 2)

        if (!this.homeDiscount) {
          this.homeDiscount = 0
        }
        this.documentDet.itemBaseAmountNoVAT = (this.documentDet.itemBaseAmount - this.round((this.documentDet.itemBaseAmount * (this.homeDiscount/ 100)),2))
        this.documentDet.itemBaseAmountNoVAT = this.round(this.documentDet.itemBaseAmountNoVAT, 2)

        this.documentDet.itemDiscountAmount1 = this.documentDet.itemBaseAmount - this.documentDet.itemBaseAmountNoVAT
       
        if (this.pcsLoc !== 0) {
          this.documentDet.itemDiscountPrice = (this.documentDet.itemBaseAmountNoVAT / this.pcsLoc)
          this.documentDet.itemDiscountPrice = this.round(this.documentDet.itemDiscountPrice, 2)
        }
        this.documentDet.itemVAT1Amount = ((this.documentDet.itemBaseAmountNoVAT * (this.documentDet.itemVAT1Percentage / 100)))
        //this.documentDet.itemVAT1Amount = (this.documentDet.itemVAT1Amount)
        this.documentDet.itemAmountWithVAT = ((this.documentDet.itemBaseAmountNoVAT) + (this.documentDet.itemVAT1Amount))
        this.documentDet.itemAmountWithVAT = this.round(this.documentDet.itemAmountWithVAT, 2)

        this.maxDiscPer = (this.documentDet.itemAmountWithVAT / 100) ? this.round((this.documentDet.itemAmountWithVAT / 100),3) : 0
       // this.documentDet.itemMaxDiscount = ((this.documentDet.itemMarginAmount / this.maxDiscPer)) ? this.round(((this.documentDet.itemMarginAmount / this.maxDiscPer)),2) : 0
        if (this.pcsLoc !== 0) {
          this.documentDet.itemPriceWithVAT = ((this.documentDet.itemAmountWithVAT / this.pcsLoc))
          this.documentDet.itemPriceWithVAT = this.round(this.documentDet.itemPriceWithVAT, 2)
        }
        this.documentDet.itemDirectDuty1Amount = this.round((this.documentDet.itemDirectDuty1 * this.pcsLoc), 2)
        this.documentDet.itemFullTotal = (this.documentDet.itemBaseAmountNoVAT + this.documentDet.itemVAT1Amount + this.documentDet.itemDirectDuty1Amount)
        //this.documentDet.itemFullTotal = (this.documentDet.itemFullTotal)
        this.homePriceWithVAT = this.documentDet.itemPriceWithVAT

        this.documentDet.itemDiscountPercentage1 = this.homeDiscount

    },
    calculateDiscountFore () {
      this.initPcs()
      this.documentDet.itemForeDirectDuty1 = this.documentDet.itemForeDirectDuty1 ? this.round(this.documentDet.itemForeDirectDuty1, 2) : 0
      this.documentDet.itemForeVAT1Percentage = this.documentDet.itemForeVAT1Percentage ? this.round(this.documentDet.itemForeVAT1Percentage, 2) : 0

      this.documentDet.itemForePrice = this.documentDet.itemForePrice ? this.round(this.documentDet.itemForePrice, 3) : 0
      this.documentDet.itemBaseForeAmount = this.round((this.documentDet.itemForePrice * this.pcsLoc), 2)
      this.documentDet.itemBaseForeAmountNoVAT = (this.documentDet.itemBaseForeAmount - (this.documentDet.itemBaseForeAmount * (this.documentDet.itemDiscountPercentage1 / 100)))
      this.documentDet.itemBaseForeAmountNoVAT = this.round(this.documentDet.itemBaseForeAmountNoVAT, 2)
      if (this.pcsLoc !== 0) {
        this.documentDet.itemDiscountForePrice = (this.documentDet.itemBaseForeAmountNoVAT / this.pcsLoc)
        this.documentDet.itemDiscountForePrice = this.round(this.documentDet.itemDiscountForePrice, 2)
      }
      this.documentDet.itemForeVAT1Amount = ((this.documentDet.itemBaseForeAmountNoVAT * (this.documentDet.itemForeVAT1Percentage / 100)))
      this.documentDet.itemForeVAT1Amount = this.round(this.documentDet.itemForeVAT1Amount, 2)
      this.documentDet.itemForeAmountWithVAT = (parseFloat(this.documentDet.itemBaseForeAmountNoVAT) + parseFloat(this.documentDet.itemForeVAT1Amount))
      this.documentDet.itemForeAmountWithVAT = this.round(this.documentDet.itemForeAmountWithVAT, 2)
      this.maxDiscPer = (this.documentDet.itemForeAmountWithVAT / 100) ? this.round((this.documentDet.itemForeAmountWithVAT / 100),3) : 0
     // this.documentDet.itemMaxDiscount = ((this.documentDet.itemMarginAmount / this.maxDiscPer)) ? this.round(((this.documentDet.itemMarginAmount / this.maxDiscPer)),2) : 0
      if (this.pcsLoc !== 0) {
        this.documentDet.itemForePriceWithVAT = ((this.documentDet.itemForeAmountWithVAT / this.pcsLoc))
        this.documentDet.itemForePriceWithVAT = this.round(this.documentDet.itemForePriceWithVAT, 2)
      }
      this.documentDet.itemDirectDuty1AmountFore = this.round((this.documentDet.itemForeDirectDuty1 * this.pcsLoc), 2)
      this.documentDet.itemFullTotalFore = (this.documentDet.itemBaseForeAmountNoVAT + this.documentDet.itemForeVAT1Amount + this.documentDet.itemDirectDuty1AmountFore)
      this.documentDet.itemFullTotalFore = this.round(this.documentDet.itemFullTotalFore, 2)

      this.documentDet.itemPrice = this.documentDet.itemForePrice ? this.round(this.documentDet.itemForePrice * this.ExRate, 3) : 0
      this.documentDet.itemBaseAmount = this.round((this.documentDet.itemBaseForeAmount * this.ExRate), 2)
      this.documentDet.itemBaseAmountNoVAT = this.round(this.documentDet.itemBaseForeAmountNoVAT * this.ExRate, 2)
      this.documentDet.itemDiscountPrice = this.round(this.documentDet.itemDiscountForePrice * this.ExRate, 2)
      this.documentDet.itemVAT1Amount = this.round(this.documentDet.itemForeVAT1Amount * this.ExRate, 2)
      this.documentDet.itemAmountWithVAT = this.round(this.documentDet.itemForeAmountWithVAT * this.ExRate, 2)
      this.documentDet.itemPriceWithVAT = this.round(this.documentDet.itemForePriceWithVAT * this.ExRate, 2)
      this.documentDet.itemDirectDuty1Amount = this.round((this.documentDet.itemDirectDuty1Amount * this.ExRate), 2)
      this.documentDet.itemFullTotal = this.round(this.documentDet.itemFullTotalFore * this.ExRate, 2) 

    },
    makeItNew(){
      this.documentDet = defDocumentDetail()
      this.documentDet = {...this.documentDet, ...defNewDocumentDetail()}
      const newId = this.getRndInteger(1000000000, 9000000000)
      this.documentDet.id = newId
      this.$store.dispatch('setDocumentDetailid', newId)
      if (this.$store.state.documentSide === 1) {
        this.documentDet.itemPcsPlus = 1
      }
      if (this.$store.state.documentSide === 2) {
        this.documentDet.itemPcsMinus = 1
      }
      if (this.$store.state.documentSide === 3) {
        this.documentDet.itemPcsNeutral = 1
      }
      this.documentDet.itemDiscountPercentage1 = 0
      this.pcsLoc = 1
      this.peaces = 1
      this.homeDiscount = 0
      this.exportDiscount = 0
      this.homePriceWithVAT = 0
      this.itemPrice = 0
    },
    findItem () {
       if (this.newItem) {
        this.mainQuery2 = {...this.emptyObj}
        this.mainQuery2.CompanyId = this.$store.state.companyid
        this.mainQuery2.itemID = this.selectIL.itemID ? this.selectIL.itemID : null
        // const myObjStr = JSON.stringify(this.mainQuery2)
        this.findIt()
       }
    },
    async findIt () {
      this.itemLoc = {...this.emptyObj}
      try {
        const {data} = await ItemService.choose(this.mainQuery2)
        if (data && data.item !== undefined) {
          this.itemLoc = data.item 
        }
        // await ItemService.choose(this.mainQuery2).then(res => {
        //   this.itemLoc = res.data.item ? res.data.item : null
        //   
        // })
        // .catch(err => {
        //   this.error = ' Greska 1. Ne mogu naci artikla!!' + err
        //   this.typeError = 'error'
        //   this.makeItNew()
        // })
        //this.itemLoc = (await ItemService.choose(this.mainQuery2)).data.item
        //this.itemLoc = await this.itemsLoc.find(itemM => itemM.itemID === this.mainQuery2.itemID)
        if (this.itemLoc) {
            this.documentDet.itemID = this.itemLoc.itemID
            this.documentDet.itemName = this.itemLoc.itemName
            this.documentDet.ItemNameShort = this.itemLoc.ItemNameShort
            this.documentDet.ItemCode = this.itemLoc.ItemCode
            this.documentDet.ItemEANCode = this.itemLoc.ItemEANCode
            this.documentDet.itemGroup = this.itemLoc.GroupId
            this.documentDet.itemGroupName = this.itemLoc.GroupName ? this.itemLoc.GroupName : ''
            this.documentDet.itemDescription = this.itemLoc.itemDescription
            this.documentDet.itemUnit = this.itemLoc.itemUnit
            this.documentDet.ItemSize = this.itemLoc.ItemSize ? this.round(this.itemLoc.ItemSize, 2) : 0
            this.documentDet.ItemWeight = this.itemLoc.ItemWeight
            this.documentDet.ItemWeightMeasure = this.itemLoc.ItemWeightMeasure
            this.documentDet.ItemDimMeasure = this.itemLoc.ItemDimMeasure
            this.documentDet.ItemDimLenght = this.itemLoc.ItemDimLenght
            this.documentDet.ItemDimHeight = this.itemLoc.ItemDimHeight
            this.documentDet.ItemDimDepth = this.itemLoc.ItemDimDepth
            this.documentDet.ItemWarehouse = this.itemLoc.ItemWarehouse
            this.documentDet.ItemWarehouseRow = this.itemLoc.ItemWarehouseRow
            this.documentDet.ItemWarehouseShelf = this.itemLoc.ItemWarehouseShelf
            this.documentDet.ItemWahrehouseFloor = this.itemLoc.ItemWahrehouseFloor
            this.documentDet.ItemPicture1 = this.itemLoc.ItemPicture1
            this.documentDet.ItemPicture2 = this.itemLoc.ItemPicture2
            this.documentDet.ItemPicture3 = this.itemLoc.ItemPicture3
            this.documentDet.ItemPicture4 = this.itemLoc.ItemPicture4
            this.documentDet.ItemPicture5 = this.itemLoc.ItemPicture5
            this.documentDet.ItemPicture6 = this.itemLoc.ItemPicture6
            this.documentDet.ItemPicture7 = this.itemLoc.ItemPicture7
            this.documentDet.ItemBelongsToId = this.itemLoc.ItemBelongsToId
            this.documentDet.itemService = this.itemLoc.itemService
            this.documentDet.itemWeb = this.itemLoc.itemWeb
            this.documentDet.itemNotActiv = this.itemLoc.itemNotActiv
            this.documentDet.itemHideForever = this.itemLoc.itemHideForever
            this.documentDet.itemSuppBasePrice = this.round(this.itemLoc.itemSuppBasePrice, 2)
            this.documentDet.itemSuppDiscunt = this.round(this.itemLoc.itemSuppDiscunt, 2)
            this.documentDet.itemSuppPrice = this.itemLoc.itemSuppPrice ? this.round(this.itemLoc.itemSuppPrice, 2) : 0
            this.documentDet.ItemSuppCode = this.itemLoc.ItemSuppCode ? this.itemLoc.ItemSuppCode : 0
            this.documentDet.itemMargin = this.itemLoc.itemMargin ? this.round(this.itemLoc.itemMargin, 2) : 0
            this.documentDet.itemMaxDiscount = this.itemLoc.itemMaxDiscount ? this.round(this.itemLoc.itemMaxDiscount, 2) : 0
            this.documentDet.itemPrice = this.itemLoc.itemPrice ? this.round(this.itemLoc.itemPrice, 3) : 0
            this.documentDet.itemVAT1Percentage = this.itemLoc.itemVAT1Percentage ? this.round(this.itemLoc.itemVAT1Percentage, 2) : 0
            this.documentDet.itemVAT1Amount = this.itemLoc.itemVAT1Amount ? this.itemLoc.itemVAT1Amount : 0
            this.documentDet.itemVAT2Percentge = this.itemLoc.itemVAT2Percentge ? this.itemLoc.itemVAT2Percentge : 0
            this.documentDet.itemVAT2Amount = this.itemLoc.itemVAT2Amount ? this.itemLoc.itemVAT2Amount : 0
            this.documentDet.itemDirectDuty1 = this.itemLoc.itemDirectDuty1 ? this.round(this.itemLoc.itemDirectDuty1, 2) : 0
            this.documentDet.itemDirectDuty2 = this.itemLoc.itemDirectDuty2 ? this.itemLoc.itemDirectDuty2 : 0
            this.documentDet.itemPriceWithVAT = this.itemLoc.itemPriceWithVAT ? this.round(this.itemLoc.itemPriceWithVAT, 2) : 0
            
            if (this.itemLoc.itemForePrice && this.round(this.itemLoc.itemForePrice,2) !== 0) {
              
              this.documentDet.itemForePrice = this.round(this.itemLoc.itemForePrice, 2)
              this.documentDet.itemForeVAT1Percentage = this.itemLoc.itemForeVAT1Percentage
              this.documentDet.itemForeVAT1Amount = this.itemLoc.itemForeVAT1Amount
              this.documentDet.itemForeVAT2Percentage = this.itemLoc.itemForeVAT2Percentage
              this.documentDet.itemForeVAT2Amount = this.itemLoc.itemForeVAT2Amount
              this.documentDet.itemForeDirectDuty1 = this.itemLoc.itemForeDirectDuty1
              this.documentDet.itemForeDirectDuty2 = this.itemLoc.itemForeDirectDuty2
              this.documentDet.itemForePriceWithVAT = this.itemLoc.itemForePriceWithVAT
            } else {
              this.documentDet.itemForePrice = this.round(this.itemLoc.itemPrice / this.ExRate, 2)
              this.documentDet.itemForeVAT1Percentage = 0
              this.documentDet.itemForeVAT1Amount = 0
              this.documentDet.itemForeVAT2Percentage = 0
              this.documentDet.itemForeVAT2Amount = 0
              this.documentDet.itemForeDirectDuty1 = 0
              this.documentDet.itemForeDirectDuty2 = 0
              this.documentDet.itemForePriceWithVAT = this.documentDet.itemForePrice
            }

            if (this.$store.state.documentActivHead.exportDoc === false) {
              this.documentDet.itemPriceOriginal = this.documentDet.itemPrice
              this.documentDet.itemFullPriceOriginal = this.documentDet.itemPriceWithVAT
            } else {
              this.documentDet.itemPriceOriginal = this.documentDet.itemForePrice
              this.documentDet.itemFullPriceOriginal = this.documentDet.itemForePriceWithVAT
            }
            if (this.$store.state.companyActiv.CompanyUseRetailPrice === true) {
              this.documentDet.itemPrice = this.documentDet.itemPriceWithVAT / (1 + (this.documentDet.itemVAT1Percentage / 100))
              this.documentDet.itemPrice = this.documentDet.itemPrice ? this.round(this.documentDet.itemPrice, 2) : 0
            }
            this.itemPrice = this.documentDet.itemPrice
            const itemMargin = (this.documentDet.itemPrice - this.documentDet.itemSuppPrice)
            if (itemMargin > 0) {
              this.maxDiscPer = (this.documentDet.itemPrice / 100) ? this.round((this.documentDet.itemPrice / 100),3) : 0
              this.documentDet.itemMaxDiscount = ((itemMargin / this.maxDiscPer)) ? this.round(((itemMargin / this.maxDiscPer)),2) : 0
            }
            this.homeDiscount = 0
            this.documentDet.itemDiscountPercentage1 = 0
            this.homePriceWithVAT = this.documentDet.itemPriceWithVAT
          }
          if (!this.exportDoc) {
            this.calculateDiscount() 
          } else {
            this.calculateDiscountFore()
          }  
      } catch (error) {
        this.error = ' Greska Ne mogu naci artikla'
        this.typeError = 'error'
        // eslint-disable-next-line
        console.log(' greska Ne mogu naci artikla', error)
      }
    },
    makeSum() {
      const updateSumObject = {
        documentDescription9: 0,
        documentDescription10: 0,
        documentTotalVAT1Per: 0,
        documentTotalVAT1Base: 0,
        documentTotalVAT1Amount: 0,
        documentTotalVAT2Per: 0,
        documentTotalVAT2Base: 0,
        documentTotalVAT2Amount: 0,
        documentTotalVAT3Per: 0,
        documentTotalVAT3Base: 0,
        documentTotalVAT3Amount: 0,
        documentTotalVAT4Per: 0,
        documentTotalVAT4Base: 0,
        documentTotalVAT4Amount: 0,
        documentTotalVAT5Per: 0,
        documentTotalVAT5Base: 0,
        documentTotalVAT5Amount: 0,
        documentTotalAmountNetto: 0,
        documentTotalAmountVAT: 0,
        documentTotalAmountBrutto: 0,
        documentTotalAmountPayInAdvance: this.$store.state.documentActivHead.documentTotalAmountPayInAdvance ? parseFloat(this.$store.state.documentActivHead.documentTotalAmountPayInAdvance) : 0,
        documentTotalAmountToPay: 0,
        documentTotalAmountNettoForeign: 0,
        documentTotalAmountVATForeign: 0,
        documentTotalAmountBruttoForeign: 0,
        documentTotalAmountPayInAdvanceForeign: 0,
        documentTotalAmountToPayForeign: 0,
        documentTotalAmountDirectD1: 0,
        documentTotalAmountDirectD2: 0,
        documentTotalAmountDirectD1Fore: 0,
        documentTotalAmountDirectD2Fore: 0,
        supplierAmount: 0,
        supplierNetoAmount: 0,
        supplierDiscount: 0,
        supplierMargin: 0,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
      }
      let dSide = 3
      if (this.$store.state.documentSide) {
        dSide = this.$store.state.documentSide
      }

      const hrVat1 = 25
      const hrVat2 = 13
      const hrVat3 = 5
      const documentsDet = this.$store.state.documentActivItems ? this.$store.state.documentActivItems : []
      if (documentsDet) {
        documentsDet.map(documentDet => {

          this.pcsLoc = 0
          if (dSide === 1 ) {
            this.pcsLoc = parseFloat(documentDet.itemPcsPlus) ? parseFloat(documentDet.itemPcsPlus)  : 0
          }
          if (dSide === 2 ) {
            this.pcsLoc = parseFloat(documentDet.itemPcsMinus) ? parseFloat(documentDet.itemPcsMinus)  : 0
          }
          if (dSide === 3 ) {
            this.pcsLoc = parseFloat(documentDet.itemPcsNeutral) ? parseFloat(documentDet.itemPcsNeutral)  : 0
          }
          this.pcsLoc = this.round(this.pcsLoc, 3)     
          
          if ((documentDet.itemSuppBasePriceAmount)) {
            updateSumObject.supplierAmount += this.round((documentDet.itemSuppBasePriceAmount),2)
          }
          if (documentDet.itemSuppDiscountAmount) {
            updateSumObject.supplierDiscount += this.round(documentDet.itemSuppDiscountAmount,2)
          }
          if (documentDet.itemSuppPriceAmount) {
            updateSumObject.supplierNetoAmount += this.round(documentDet.itemSuppPriceAmount,2)
          }
          if (documentDet.itemBaseAmount) {
            updateSumObject.documentDescription9 += parseFloat(documentDet.itemBaseAmount)
          }
          if (documentDet.itemBaseAmountNoVAT) {
            updateSumObject.documentTotalAmountNetto += parseFloat(documentDet.itemBaseAmountNoVAT)
          }
          // if (documentDet.itemVAT1Amount) {
          //   updateSumObject.documentTotalAmountVAT += parseFloat(documentDet.itemVAT1Amount)
          // }
          //const dD1 = documentDet.itemDirectDuty1Amount ? parseFloat(documentDet.itemDirectDuty1Amount) : 0
          // if (documentDet.itemAmountWithVAT) {
          //   updateSumObject.documentTotalAmountBrutto += parseFloat(documentDet.itemAmountWithVAT) + dD1
          // }
          if (this.round(documentDet.itemVAT1Percentage, 2) === hrVat1) {
            updateSumObject.documentTotalVAT1Base += parseFloat(documentDet.itemBaseAmountNoVAT)
            // updateSumObject.documentTotalVAT1Amount += parseFloat(documentDet.itemVAT1Amount)
            // updateSumObject.documentTotalVAT1Base += parseFloat((documentDet.itemAmountWithVAT) - parseFloat(documentDet.itemVAT1Amount))
          }
          if (this.round(documentDet.itemVAT1Percentage, 2) === hrVat2) {
            updateSumObject.documentTotalVAT2Base += parseFloat(documentDet.itemBaseAmountNoVAT)
            // updateSumObject.documentTotalVAT2Amount += parseFloat(documentDet.itemVAT1Amount)
            // updateSumObject.documentTotalVAT2Base += parseFloat((documentDet.itemAmountWithVAT) - parseFloat(documentDet.itemVAT1Amount))
          }
          if (this.round(documentDet.itemVAT1Percentage, 2) === hrVat3) {
            updateSumObject.documentTotalVAT3Base += parseFloat(documentDet.itemBaseAmountNoVAT)
            // updateSumObject.documentTotalVAT3Amount += parseFloat(documentDet.itemVAT1Amount)
            // updateSumObject.documentTotalVAT3Base += (parseFloat(documentDet.itemAmountWithVAT) - parseFloat(documentDet.itemVAT1Amount))
          }
          if (documentDet.itemBaseAmount) {
            if (parseInt(documentDet.FreeF1) === 1) {
              updateSumObject.FreeF1 += parseFloat(documentDet.itemBaseAmount)
            }
            if (parseInt(documentDet.FreeF1) === 2) {
              updateSumObject.FreeF2 += parseFloat(documentDet.itemBaseAmount)
              updateSumObject.FreeF5 += parseFloat(documentDet.empWrkHrs)
            }
            if (parseInt(documentDet.FreeF1) === 3) {
              updateSumObject.FreeF3 += parseFloat(documentDet.itemBaseAmount)
            }
          }

          if (documentDet.itemBaseAmountNoVAT) {
            if (this.round(parseFloat(documentDet.itemVAT1Amount),2) === 0) {
              updateSumObject.FreeF4 += parseFloat(documentDet.itemBaseAmountNoVAT)
            }
          }
          if (documentDet.itemDirectDuty1Amount) {
            updateSumObject.documentTotalAmountDirectD1+= parseFloat(documentDet.itemDirectDuty1Amount)
          }
        })
      }

      updateSumObject.documentTotalAmountNetto = updateSumObject.documentTotalAmountNetto ? this.round(updateSumObject.documentTotalAmountNetto, 2) : 0
      updateSumObject.documentTotalVAT1Amount  = this.round((updateSumObject.documentTotalVAT1Base * ( (hrVat1/100))),2)
      updateSumObject.documentTotalVAT2Amount  = this.round((updateSumObject.documentTotalVAT2Base * ( (hrVat2/100))),2)
      updateSumObject.documentTotalVAT3Amount  = this.round((updateSumObject.documentTotalVAT3Base * ( (hrVat2/100))),2)
      updateSumObject.documentDescription9 = updateSumObject.documentDescription9 ? this.round(updateSumObject.documentDescription9, 2) : 0
      updateSumObject.supplierNetoAmount = updateSumObject.supplierNetoAmount ? this.round(updateSumObject.supplierNetoAmount, 2) : 0
      updateSumObject.supplierMargin = this.round(updateSumObject.documentTotalAmountNetto - updateSumObject.supplierNetoAmount, 2)
      updateSumObject.documentTotalAmountVAT = updateSumObject.documentTotalVAT1Amount + updateSumObject.documentTotalVAT2Amount + updateSumObject.documentTotalVAT3Amount
      updateSumObject.documentTotalAmountBrutto = updateSumObject.documentTotalAmountNetto + updateSumObject.documentTotalAmountVAT
      updateSumObject.documentTotalVAT1Per = hrVat1 ? this.round(hrVat1, 2) : 0
      updateSumObject.documentTotalVAT2Per = hrVat2 ? this.round(hrVat2, 2) : 0
      updateSumObject.documentTotalVAT3Per = hrVat3 ? this.round(hrVat3, 2) : 0

      // updateSumObject.documentTotalVAT1Base = hrVat1 && updateSumObject.documentTotalVAT1Amount ? this.round(updateSumObject.documentTotalVAT1Amount / (hrVat1/100), 2) : 0
      // updateSumObject.documentTotalVAT2Base = hrVat2 && updateSumObject.documentTotalVAT2Amount ? this.round(updateSumObject.documentTotalVAT2Amount / (hrVat2/100), 2) : 0
      // updateSumObject.documentTotalVAT3Base = hrVat3 && updateSumObject.documentTotalVAT3Amount ? this.round(updateSumObject.documentTotalVAT3Amount / (hrVat3/100), 2) : 0
      updateSumObject.FreeF1 = updateSumObject.FreeF1 ? this.round(updateSumObject.FreeF1, 2) : 0
      updateSumObject.FreeF2 = updateSumObject.FreeF2 ? this.round(updateSumObject.FreeF2, 2) : 0
      updateSumObject.FreeF3 = updateSumObject.FreeF3 ? this.round(updateSumObject.FreeF3, 2) : 0
      updateSumObject.FreeF4 = updateSumObject.FreeF4 ? this.round(updateSumObject.FreeF4, 2) : 0
      updateSumObject.FreeF5 = updateSumObject.FreeF5 ? this.round(updateSumObject.FreeF5, 2) : 0
      updateSumObject.documentDescription10 = updateSumObject.documentDescription9 - updateSumObject.documentTotalAmountNetto
      updateSumObject.documentTotalAmountPayInAdvance = updateSumObject.documentTotalAmountPayInAdvance ? this.round(updateSumObject.documentTotalAmountPayInAdvance, 2) : 0
      updateSumObject.documentTotalAmountToPay = updateSumObject.documentTotalAmountBrutto ? (this.round(updateSumObject.documentTotalAmountBrutto, 2) - (updateSumObject.documentTotalAmountPayInAdvance )): 0

      updateSumObject.documentTotalAmountNettoForeign = updateSumObject.documentTotalAmountNetto ? this.round((updateSumObject.documentTotalAmountNetto / this.ExRate), 2) : 0
      updateSumObject.documentTotalAmountVATForeign = updateSumObject.documentTotalAmountVAT ? this.round((updateSumObject.documentTotalAmountVAT / this.ExRate), 2) : 0
      updateSumObject.documentTotalAmountBruttoForeign = updateSumObject.documentTotalAmountBrutto ? this.round((updateSumObject.documentTotalAmountBrutto / this.ExRate), 2) : 0
      updateSumObject.documentTotalAmountPayInAdvanceForeign = this.round((updateSumObject.documentTotalAmountPayInAdvance  / this.ExRate), 2)
      updateSumObject.documentTotalAmountToPayForeign = updateSumObject.documentTotalAmountBrutto ? (this.round((updateSumObject.documentTotalAmountBrutto/ this.ExRate), 2) - (updateSumObject.documentTotalAmountPayInAdvance  / this.ExRate) ) : 0
      updateSumObject.documentTotalAmountDirectD1Fore = updateSumObject.documentTotalAmountDirectD1 ? this.round((updateSumObject.documentTotalAmountDirectD1 / this.ExRate), 2) : 0

      const newHead = {...this.$store.state.documentActivHead, ...updateSumObject}
      if (newHead) {
          this.$store.dispatch('setDocumentActivHead', newHead)
        } else {
          this.$store.dispatch('setDocumentActivHead', {})
        }
    }
  },
  watch: {
    'mySelectedItemId' () {      
      if (this.$store.state.selectedItemId) {
        const emObj = {}
        this.mainQuery2 = {...emObj}
        this.mainQuery2.CompanyId = this.$store.state.companyid
        this.mainQuery2.itemID = this.$store.state.selectedItemId
        if (this.$store.state.selectedItemId) {
          this.selectIL =
          {
            itemByID: (this.$store.state.selectedItemId),
            itemID: this.$store.state.selectedItemId
          }
        }
        if (this.$store.state.newItemAround) {
          this.itemsLoc = this.$store.state.itemStore ? this.$store.state.itemStore : []
        }
        this.findIt()
        //this.warehouseState()
        this.statewhs = false
        this.newItemCreate = false
      }
     // this.partner()
    },
    'itemPrice' () {
      if (this.workOn === true) {
      
        if (!this.itemPrice) {
          this.itemPrice = 0
        }
        if (this.fullpriceChange) {
          this.documentDet.itemPrice = this.round(this.itemPrice,2)
          this.calculateDiscount()
        }
      }
    },
    'homeDiscount' () {
      if (this.workOn === true) {
        if (!this.homeDiscount) {
          this.homeDiscount = 0
        }
          
          if (this.homeDiscount > 99.99){
            this.homeDiscount = 0
          }
          if (this.fullpriceChange) {
            this.calculateDiscount()
          }
      }
    },
    'exportDiscount' () {
      if (this.workOn === true) {
        if (!this.exportDiscount) {
          this.exportDiscount = 0
        }
         if (!this.exportDiscount )
          
          if (this.exportDiscount > 99.99){
            this.exportDiscount = 0
          }
          if (this.fullpriceChange) {
            this.calculateDiscountFore()
          }
      }
    },
    // 'documentDet.itemDiscountPercentage1' () {
    //   //if (this.workOn === true) {
    //       // eslint-disable-next-line
    //       console.log('gledam 2 unutra izracun rabata')
    //       if (this.documentDet.itemDiscountPercentage1 > 99.99){
    //         this.documentDet.itemDiscountPercentage1 = 0
    //       }
    //      // if (this.fullpriceChange === true) {
    //         if (this.exportDoc === false) {
    //           this.calculatePrice()
    //         } else {
    //           this.calculatePriceFore()
    //         } 
    //      // }
    //   //}
    // },
    'peaces' () {
      if (!this.peaces) {
        this.peaces = 1
      }
      this.peaces = this.round(this.peaces, 2)
      if (this.$store.state.documentSide === 1) {
        this.pcsLoc = this.peaces
        this.documentDet.itemPcsPlus = this.peaces
      }
      if (this.$store.state.documentSide === 2) {
        this.pcsLoc = this.peaces
        this.documentDet.itemPcsMinus = this.peaces
      }
      if (this.$store.state.documentSide === 3) {
        this.pcsLoc = this.peaces
        this.documentDet.itemPcsNeutral = this.peaces
      }

      if (this.fullpriceChange === true) {
        if (this.$store.state.documentSide === 1) {
          if (this.$store.state.documentActiv === 'ReceiptPurchase') {
            this.calculateSupp()
          }
        }
        if (this.exportDoc === false) {
          this.calculateDiscount()
        } else {
          this.calculateDiscountFore()
        } 
      }
      if (!this.fullpriceChange === true) {
        if (this.$store.state.documentSide === 1) {
          if (this.$store.state.documentActiv === 'ReceiptPurchase') {
            this.initSupp()
          }
        }
        if (this.exportDoc === false) {
          this.calculatePrice()
        } else {
          this.calculatePriceFore()
        } 
      }
    },
    'documentDet.itemPcsNeutral' () {

    },
    'documentDet.itemPcsMinus' () {
  
    },
    'documentDet.itemPcsPlus' () {
  
    },
    'documentDet.itemVAT1Percentage' () {
      if (this.workOn === true) {
        if (!this.documentDet.itemVAT1Percentage) {
          this.documentDet.itemVAT1Percentage = 25
        }
        if (this.fullpriceChange === true) {
          this.calculateDiscount()
        }
      }
    },
    'documentDet.itemDirectDuty1' () {
      if (this.workOn === true) {
        if (!this.documentDet.itemDirectDuty1) {
          this.documentDet.itemDirectDuty1 = 0
        }
        if (this.fullpriceChange === true) {
          this.calculateDiscount()
        }
      }
    },
    'suppPrice' () {
      console.log('suppPrice this.fullpriceChange', this.fullpriceChange)
      if (!this.suppPrice) {
        this.suppPrice = 0
      }
      this.documentDet.itemSuppBasePrice  = this.suppPrice
      if (this.workOn === true) {
        if (this.fullpriceChange === true) {
          this.calculateSupp()
          this.calculateDiscount()
        }
      }
    },
    'suppDiscount' () {
      console.log('suppDiscount this.fullpriceChange', this.fullpriceChange)
      if (this.workOn === true) {
        if (!this.suppDiscount) {
          this.suppDiscount = 0
        }
        this.documentDet.itemSuppDiscount = this.suppDiscount
        if (this.fullpriceChange === true) {
          this.calculateSupp()
          this.calculatePrice()
        }
      }
    },
    // 'documentDet.FreeF1' () {
    //   // eslint-disable-next-line
    //   console.log('gledam 10')
    //   if (this.workOn === true) {
    //     this.initPcs()
    //     this.initSupp()
    //     this.documentDet.FreeF2 = this.round((this.documentDet.FreeF2),2)
    //     this.documentDet.itemSuppPrice = this.round((this.documentDet.itemSuppPrice),2)
    //     this.documentDet.itemMargin = this.round((this.documentDet.FreeF2 * this.pcsLoc),3)
    //     this.documentDet.itemPrice = this.round((this.documentDet.itemSuppPrice + this.documentDet.FreeF2),2)
    //     this.documentDet.itemBaseAmount = this.round((this.documentDet.itemPrice * this.pcsLoc),2)
    //     if (this.pcsLoc !== 0) {
    //       this.maxDiscPer = this.round((this.documentDet.itemPrice / 100),3)
    //       this.documentDet.itemMaxDiscount = this.round((100 - (this.documentDet.itemSuppPrice / this.maxDiscPer)),2)
    //     }
    //     this.documentDet.FreeF1 = (this.documentDet.itemMargin / (this.suppNetoAmount / 100)) ? this.round((this.documentDet.itemMargin / (this.suppNetoAmount / 100)),2) : 0
    //     this.documentDet.FreeF2 = (this.documentDet.itemMargin / this.pcsLoc) ? this.round((this.documentDet.itemMargin / this.pcsLoc),2) : 0
    //     this.calculatePrice()
    //   }
    // },
    'margin' () {
      console.log('margin this.fullpriceChange', this.fullpriceChange)
      if (!this.margin){
        this.margin = 0
      }
      this.documentDet.itemMargin = this.margin
      if (this.fullpriceChange) {
       if (this.$store.state.documentSide === 1) {
          if (this.$store.state.documentActiv === 'ReceiptPurchase') {
            this.initSupp()
            this.documentDet.itemMarginAmount = 0
            //if (this.margin !== 0) {
              this.documentDet.itemMarginAmount = this.round((this.documentDet.itemSuppPriceAmount * (this.documentDet.itemMargin / 100)),2)
            //}
            this.documentDet.itemBaseAmount = this.round((this.documentDet.itemSuppPriceAmount  + this.documentDet.itemMarginAmount),2)
            this.documentDet.itemPrice = this.pcsLoc !== 0 ? this.round(this.documentDet.itemBaseAmount / this.pcsLoc, 2) : 0
            this.documentDet.FreeF2 = this.pcsLoc !== 0 ? this.round((this.documentDet.itemMarginAmount / this.pcsLoc),2) : 0

            this.calculateDiscount()
            this.maxDiscPer = (this.documentDet.itemAmountWithVAT / 100) ? this.round((this.documentDet.itemAmountWithVAT / 100),3) : 0
            this.documentDet.itemMaxDiscount = ((this.documentDet.itemMarginAmount / this.maxDiscPer)) ? this.round(((this.documentDet.itemMarginAmount / this.maxDiscPer)),2) : 0
          }
        }
      }
    },
    'homePriceWithVAT' () {
       if (!this.fullpriceChange) {
         this.documentDet.itemPriceWithVAT = this.homePriceWithVAT
         this.calculatePrice()
         
       }
      
    //  if (this.workOn === true) {
        // this.documentDet.itemMarginAmount = this.documentDet.itemBaseAmountNoVAT - this.itemSuppPriceAmount
        // if (this.pcsLoc !== 0) {
        //   this.maxDiscPer = (this.documentDet.itemPriceWithVAT / 100) ? this.round((this.documentDet.itemPriceWithVAT / 100),3) : 0
        //   this.documentDet.itemMaxDiscount = (100 - (this.documentDet.itemMarginAmount / this.maxDiscPer)) ? this.round((100 - (this.documentDet.itemMarginAmount / this.maxDiscPer)),2) : 0
        // }
        // this.documentDet.itemMargin = 0
        // this.documentDet.FreeF2 = 0
        // if (this.documentDet.itemSuppPriceAmount !== 0) {
        //   this.documentDet.itemMargin = (this.documentDet.itemMarginAmount / (this.documentDet.itemSuppPriceAmount / 100)) ? this.round((this.documentDet.itemMarginAmount / (this.documentDet.itemSuppPriceAmount / 100)),2) : 0
        // }
        // if (this.pcsLoc !== 0) {
        //   this.documentDet.FreeF2 = (this.documentDet.itemMarginAmount / this.pcsLoc) ? this.round((this.documentDet.itemMarginAmount / this.pcsLoc),2) : 0
        // }
        
     // }
    },
    'selectIL.itemID' () {
      if (this.workOn === true) {
        if (!this.selectIL) {
          this.haveItemSelected = false
          this.newItem = true
          this.firstUpdate = 3
          this.makeItNew()
        } else {
          if (this.selectIL && this.selectIL.itemID) {
            this.haveItemSelected = true
            this.findItem()
          } else {
            this.makeItNew()
          }
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
