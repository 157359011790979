<template>
    <v-card>
        <v-card-title>
          <v-toolbar> 
            Joppd B
            <v-spacer></v-spacer> 
            <v-btn
              color="primary"
              @click="exportXLSX">
              Excel
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="20"
            item-key="id"
            @click:row="navigateTo"
          >

          <template v-slot:[`item.P17`]="{ item }"> 
            {{ localMoney(item.P17) }}
          </template>
          <template v-slot:[`item.P152`]="{ item }"> 
            <span class="title green--text">{{ localMoney(item.P152) }}</span>
          </template>
          <template v-slot:[`item.P162`]="{ item }"> 
            <span class="title purple--text">{{ localMoney(item.P162) }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>

</template>

<script>
import ledgerEN from './salarieDescEn'
import ledgerHR from './salarieDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import moneyFormat from '@/snippets/moneyFormat'
import JoppdBService from '@/services/JoppdBService'
import xlsx from 'json-as-xlsx'
import dayjs from 'dayjs'

export default {
  name: 'joppdbdetaillist',
  data () {
    return {
      lang: {},
      langC: {},
      documentName: '',
      documentNameLoc: '',
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: '',
        databaseDocID: 0,
        FreeF1: 1
      },
      defaultItem: {},
      newItem: {},
      haveSide: 0,
      activItems: [],
      account4s: [],
      bookTypeSelected: {
      },
      prevNr: 0
    }
  },
  async mounted () {
    try {
      if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentid) {
        this.mainQuery.JoppdAID = this.$store.state.documentid
      }
      await JoppdBService.choose(this.mainQuery)
      .then(res => {     
        console.log('joppdb ', res)
        this.items = res.data.joppdb ? res.data.joppdb : []
      })
      .catch(err => {
        // eslint-disable-next-line
        console.log('Error ' + err)
      })
      
      this.headers.push(
        {text: 'Ime i prezime stjecatelja/osiguranika', align: 'left', sortable: true, value: 'P5', width: '120px' },
        {text: 'OIB stjecatelja/osiguranika', align: 'left', sortable: true, value: 'P4', width: '80px'},
        {text: 'Šifra općine/grada prebivališta /boravišta', align: 'left', sortable: true,  value: 'P2', width: '60px'},
        {text: 'Šifra općine/grada rada', align: 'left', sortable: true, value: 'P3',  width: '60px'},
        {text: '6.1. Oznaka stjecatelja/osiguranika', align: 'right', sortable: true, value: 'P61', width: '60px' },
        {text: '6.2. Oznaka primitka/obveze doprinosa', align: 'left', value: 'P62', width: '60px' },
        {text: '15.2. Iznos neoporezivog', align: 'right', value: 'P152', width: '90px' },
        {text: '16.1. Oznaka načina isplate', align: 'left', value: 'P161', width: '60px' },
        {text: '16.2. Iznos za isplatu', align: 'left', value: 'P162', width: '90px' },
        {text: '17. Obračunani primitak od nesam. rada (plaća)', align: 'left', value: 'P17', width: '90px' },
        {text: 'id', align: 'left', value: 'id', width: '120px' },
      )

    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
    async navigateTo (params) {
      await this.$store.dispatch('setActionType', 2)
      await this.$store.dispatch('seteditSubItem', 1)
      this.$store.dispatch('setretPath', 'joppdbindex')
      // ????
      this.$store.dispatch('setDocumentDetailid', params.id)
      this.$router.push({
        name: 'joppdbdetail'
      })
    },
    exportXLSX () {
      let sheetNm = ''
      let fileNm = ''
      const currDate = dayjs().format('DD_MM_YYYY_HH_MM')
      let tP10 = 0
      let tP11 = 0
      let tP12  = 0
      let tP121  = 0
      let tP122  = 0
      let tP123  = 0
      let tP124  = 0
      let tP125  = 0
      let tP126  = 0
      let tP127  = 0
      let tP128  = 0
      let tP129  = 0
      let tP131  = 0
      let tP132  = 0
      let tP133  = 0
      let tP134  = 0
      let tP135  = 0
      let tP141  = 0
      let tP142  = 0
      let tP152  = 0
      let tP162  = 0
      let tP17 = 0
      const newArr = []
   

      this.items.map(item => {
        const finT = {}
        finT.hashtag = item.P10
        finT.eemail = item.P10

        finT.P1 = item.P1
        finT.P2 = item.P2
        finT.P3 = item.P3
        finT.P4 = item.P4
        finT.P5 = item.P5
        finT.P61 = item.P61
        finT.P62 = item.P62

        finT.P71 = item.P71
        finT.P72 = item.P72
        finT.P8 = item.P8
        finT.P9 = item.P9
        finT.P10 = item.P10
        finT.P101 = item.P101
        finT.P102 = item.P102
        finT.P11 = item.P11
        finT.P12 = item.P12
        finT.P121 = item.P121
        finT.P122 = item.P122
        finT.P123 = item.P123
        finT.P124 = item.P1124
        finT.P125 = item.P125
        finT.P126 = item.P126
        finT.P127 = item.P127
        finT.P128 = item.P128
        finT.P129 = item.P129
        finT.P131 = item.P131
        finT.P132 = item.P132
        finT.P133 = item.P133
        finT.P134 = item.P134
        finT.P135 = item.P135
        finT.P141 = item.P141
        finT.P142 = item.P142
        finT.P151 = item.P151
        finT.P152 = item.P152
        finT.P161 = item.P161
        finT.P162 = item.P162
        finT.P17 = item.P17
        newArr.push(finT)

        tP10 += item.P10
        tP11 += item.P11
        tP12 += item.P12
        tP121 += item.P121
        tP122 += item.P122
        tP123 += item.P123
        tP124 += item.P124
        tP125 += item.P125
        tP126 += item.P126
        tP127 += item.P127
        tP128 += item.P128
        tP129 += item.P129
        tP131 += item.P131
        tP132 += item.P132
        tP133 += item.P133
        tP134 += item.P134
        tP135 += item.P135
        tP141 += item.P141
        tP142 += item.P142
        tP152 += item.P152
        tP162 += item.P162
        tP17 +=  item.P17
      })

      const finT = {}
      finT.hashtag = ''
      finT.eemail = ''

      finT.P1 = ''
      finT.P2 = ''
      finT.P3 = ''
      finT.P4 = ''
      finT.P5 = ''
      finT.P61 = ''
      finT.P62 = ''

      finT.P71 = ''
      finT.P72 = ''
      finT.P8 = ''
      finT.P9 = ''
      finT.P10 = tP10
      finT.P101 = ''
      finT.P102 = ''
      finT.P11 = tP11
      finT.P12 = tP12
      finT.P121 = tP121
      finT.P122 = tP122
      finT.P123 = tP123
      finT.P124 = tP124
      finT.P125 = tP125
      finT.P126 = tP126
      finT.P127 = tP127
      finT.P128 = tP128
      finT.P129 = tP129
      finT.P131 = tP131
      finT.P132 = tP132
      finT.P133 = tP133
      finT.P134 = tP134
      finT.P135 = tP135
      finT.P141 = tP141
      finT.P142 = tP142
      finT.P151 = ''
      finT.P152 = tP152
      finT.P161 = ''
      finT.P162 = tP162
      finT.P17 = tP17
      newArr.push(finT)

      sheetNm =  'JoppdB_' + currDate 
      fileNm = 'JoppdB_' + currDate 
      let data = [
      {
        sheet: sheetNm,
        columns: [
          {label: 'hashtag', value: 'hashtag' },
          {label: 'email', value: 'eemail' },
          {label: '1.Rb.', value: 'P1' },
          {label: '2. Šifra općine/grada prebivališta/boravišta', value: 'P2' },
          {label: '3. Šifra općine/grada rada', value: 'P3' },
          {label: '4. OIB stjecatelja/osiguranika', value: 'P4' },
          {label: '5. Ime i prezime stjecatelja/osiguranika', value: 'P5' },
          {label: '6.1. Oznaka stjecatelja/osiguranika', value: 'P61' },
          {label: '6.2. Oznaka primitka/obveze doprinosa', value: 'P62' },
          {label: '7.1. Ob.dod.dop.za MO za staž s pov.tr.', value: 'P71' },
          {label: '7.2. Obveza posebnog dop.za poticanje zap.osoba s inv.', value: 'P72' },
          {label: '8. Oznaka prvog/zadnjeg mjeseca u osiguranju po istoj osnovi', value: 'P8' },
          {label: '9. Oznaka punog/nepunog rad.vr. ili rada s polovicom rad.vr.', value: 'P9' },
          {label: '10. Ukupni sati rada prema kojima se radi obračun', value: 'P10' },
          {label: '10.1 Razdoblje obračuna od', value: 'P101' },
          {label: '10.2 Razdoblje obračuna do', value: 'P102' },
          {label: '11. Iznos primitka (oporezivi)', value: 'P11' },
          {label: '12. Osnovica za obračun doprinosa', value: 'P12' },
          {label: '12.1. Doprinos za mirovinsko osiguranje', value: 'P121' },
          {label: '12.2. Doprinos za mirovinsko osiguranje - II STUP', value: 'P122' },
          {label: '12.3. Doprinos za zdravstveno osiguranje', value: 'P123' },
          {label: '12.4. Doprinos za zaštitu zdravlja na radu', value: 'P124' },
          {label: '12.5. Doprinos za zapošljavanje', value: 'P125' },
          {label: '12.6. Dodatni dop.za mir.osig.za staž osig.koji se računa s pov.tr.', value: 'P126' },
          {label: '12.7. Dodatni dop.za mir.osig.za staž osig.koji se računa s pov.tr.-II STUP', value: 'P127' },
          {label: '12.8. Poseban doprinos za korištenje zdravstvene zaštite u inozemstvu"', value: 'P128' },
          {label: '12.9. Poseban doprinos za zapošljavanje osoba s invaliditetom', value: 'P129' },
          {label: '13.1. Izdatak', value: 'P131' },
          {label: '13.2. Izdatak-uplaćeni dop.za mir.osig.', value: 'P132' },
          {label: '13.3. Dohodak', value: 'P133' },
          {label: '13.4. Osobni odbitak', value: 'P134' },
          {label: '13.5. Porezna osnovica', value: 'P135' },
          {label: '14.1. Iznos obr.por.na doh. ', value: 'P141' },
          {label: '14.2. Iznos obr.prireza por.na doh.', value: 'P142' },
          {label: '15.1. Oznaka neoporezivog prim.', value: 'P151' },
          {label: '15.2 Iznos neoporezivog prim.', value: 'P152' },
          {label: '16.1 Oznaka načina isplate', value: 'P161' },
          {label: '16.2 Iznos za isplatu', value: 'P162' },
          {label: '17 Obračunani primitak od nesam. rada (plaća)', value: 'P17' }
        ],
        content: newArr
      },
    ]
      
    let settings = {
        fileName: fileNm, // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
      }

      xlsx(data, settings)
    }

  },
  computed: {
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
