<template>
    <v-container fluid grid-list-md>
      <v-row no-gutters class="pa-0">
        <v-col cols="12" xl="6" lg="6" xs="6" sm="6" md="6">
          <h1>{{this.$store.state.documentActivLoc}}</h1>
        </v-col>
        <v-col cols="12" xl="3" lg="3" xs="3" sm="3" md="3">
            <v-btn text @click="downloadFile">
              Export u Excel
            </v-btn>
        </v-col>
        <v-col cols="12" xl="3" lg="3" xs="3" sm="3" md="3">
          <div class="text-right">
              <v-btn fab dark class="indigo" @click="newitem">
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
          </div>
        </v-col>
      </v-row>
       <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          bottom
          :color="color"
          >
          {{ text }}
        </v-snackbar>
      <app-Item-list :key="componentKey"></app-Item-list>
    </v-container>
</template>

<script>

import Itemlist from './ItemList'
import ItemService from '@/services/ItemService'
import { round, parseNum } from '@/snippets/allSnippets'
import { newUpdates, testLastUpdate } from '../../services/TestLastUpdate'
import langEn from './itemDescEn'
import langHr from './itemDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import xlsx from 'json-as-xlsx'
import dayjs from 'dayjs'

export default {
  name: 'item',
  data () {
    return {
      msg: '',
      showOnlyEUR: true,
      updateItems: [],
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      componentKey: 0,
    }
  },
  mounted () {
     this.showOnlyEUR = this.$store.state.businessYear && this.$store.state.businessYear <= 2022 ? false : true
    if (this.$store.state.language === "EN") {
      this.lang = langEn
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = langHr
      this.langC = commHr;
    }
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;  
    },
    async newitem () {
      try {
        await this.$router.push({
          name: 'itemcreate'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async downloadFile () {
      const query = {}
        query.CompanyId = this.$store.state.companyid
        let expItems = []
        const {data} = await ItemService.index(query)

        if (data && data.items !== undefined && data.items.length > 0) {
          expItems = data.items
        }

        console.log('expItems', expItems)

        const currDate = dayjs().format('DD_MM_YYYY_HH_MM')  
        const sheetNm = 'Artikli' + '_' + currDate
        let data1 = [
        {
          sheet: sheetNm,
          columns: [
            { label: this.lang.ItemID, value: 'itemID' },
            { label: this.lang.ItemName, value: 'itemName' },
            { label: this.lang.GroupName, value: 'GroupName' },
            { label: this.lang.ItemUnit, value: 'itemUnit'},
            { label: this.lang.ItemService, value: 'itemService'},
            { label: this.lang.ItemSuppCode, value: 'ItemSuppCode'},
            { label: this.lang.ItemSuppBasePrice, value: 'itemSuppBasePrice'},
            { label: this.lang.ItemSuppPrice, value: 'itemSuppPrice'},
            { label: this.lang.ItemPrice, value: 'itemPrice'},
            { label: this.lang.ItemVAT1Percentage, value: 'itemVAT1Percentage'},
            { label: this.lang.ItemPriceWithVAT, value: 'itemPriceWithVAT'}
          ],
          content: expItems
        }
      ]
          
      let settings = {
          fileName: 'Artikli' + currDate,
          extraLength: 3, // A bigger number means that columns will be wider
          writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
        }
      // const settings = {
      //   sheetName: 'StanjeSkladista',
      //   fileName: 'StanjeSkladista_' + currDate
      // }
      xlsx(data1, settings)
    },
    async convertInEUR () {
      try {
        if (confirm('Da li ste sigurni da želite sve cijene na artiklima promjeniti u EUR ?')) {
          if (confirm('Da li ste STVARNO !!! sigurni da želite sve cijene na artiklima promjeniti u EUR ?')) {
            const query = {}
            query.CompanyId = this.$store.state.companyid
            await ItemService.index(query).then(async(res) => {
              if (res.data.items) {

                res.data.items.map(item => {
                  let updateItem = {}
                  updateItem.id = item.id
                  updateItem.itemSuppBasePrice = item.itemSuppBasePrice ? round(parseNum(item.itemSuppBasePrice)  / 7.5345 , 2) : 0
                  updateItem.itemSuppPrice = item.itemSuppPrice ? round(parseNum(item.itemSuppPrice)  / 7.5345 , 2) : 0
                  updateItem.itemPrice = item.itemPrice ? round(parseNum(item.itemPrice)  / 7.5345 , 2) : 0
                  const vatPer = item.itemVAT1Percentage ? round(parseNum(item.itemVAT1Percentage), 2) : 0
                  updateItem.itemPriceWithVAT =  round((updateItem.itemPrice * ( 1 +  (vatPer / 100))) ,2)
                  updateItem.itemVAT1Amount = updateItem.itemPriceWithVAT - updateItem.itemPrice
                  updateItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
                  this.updateItems.push(updateItem)
                })

                await ItemService.updateprices(this.updateItems)
                .then(async(res) => {
                  this.text = 'Cijene su uspješno konvertirane u EURO!!'
                  this.color = 'green'
                  this.snackbar = true
                  console.log(' update prices done ', res)
                  await newUpdates(1)
                  await testLastUpdate()
                  this.forceRerender()
                  
                })
                .catch(err => {
                  this.text = 'GREŠKA!!! Cijene NISU  konvertirane u EURO!!'
                  this.color = 'red'
                  this.snackbar = true
                  console.log('Error on update', err)
                })
                
              }
            })
            .catch(err => {
              this.havealert = !this.havealert
              this.havemssgalert = 'Error on init Items ' + err
            })
          }
        }
      }
      catch (err) {
        console.log('ERROR!!! On conversion in EUR!! Greska!! Kod konverzije u EUR!!')
      }
    }
  },
  computed: {

  },
  watch: {

  },
  components: {
    appItemList: Itemlist
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
