import { render, staticRenderFns } from "./Account4Index.vue?vue&type=template&id=57ab03b9&scoped=true"
import script from "./Account4Index.vue?vue&type=script&lang=js"
export * from "./Account4Index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57ab03b9",
  null
  
)

export default component.exports