<template>
  <div class="bankaccount">
      <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
      <v-card>
        <v-card-title>
          ToDo
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search" 
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"            
          >
          <template v-slot:[`item.taskShortDescription`]="{ item }">
              <span v-if="item.taskColor">
                <v-chip :color="item.taskColor" dark>{{item.taskShortDescription}}</v-chip>
              </span>
              <span v-else>{{item.taskShortDescription}}</span>
          </template>
          
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>
  </div>
</template>

<script>
import TaskService from '@/services/TaskService'
import langEn from './taskDescEn'
import langHr from './taskDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dayjs from 'dayjs'

export default {
  name: 'tasktodolist',
  data () {
    return {
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [40],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        UserId: 1
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
    }
  },
  async mounted () {
    try {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
      this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2022
      this.mainQuery.taskToDoList = true
      this.mainQuery.taskClosed = false
      // this.mainQuery.taskFromDate = new Date()
      // this.mainQuery.taskToDate = new Date()
      this.mainQuery.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''

      this.saving = true
      const res = await TaskService.taskana(this.mainQuery)
      this.items =  res && res.data &&  res.data.task ? res.data.task : []
      this.saving = false
      this.headers.push(
        { text: '', align: 'left', value: 'taskShortDescription' },
      )
 
    } catch (err) {
      // eslint-disable-next-line
      console.log(err)
    }
  },
  methods: {
    navigateTo (task) {
      console.log('TASK id ', task.id)
      this.$store.dispatch('seteditItem', 1)
      this.$store.dispatch('setTaskid', task.id)
      this.$router.push({
        name: 'taskcreate'
      })
    },
    localDate(dateToFormat) {
      return dayjs(dateToFormat).format('DD.MM.YYYY')
    }
  },
  computed: {

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
