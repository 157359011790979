<template>
<div>
  <v-container fluid grid-list-md>
  <v-row>
    <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
      <h1>{{this.$store.state.documentActivLoc}}</h1>
    </v-col>
  </v-row>
 
  <v-alert 
    :type="typeError"
    v-model="alert"
    dismissible>
    {{error}}
  </v-alert>
  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="users"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="50"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">

        <v-row >
          <v-col  cols="12" sm="3" class="text-sm-right"  v-if="lla">
            <v-btn
              text
              color="primary"
              @click="calulusAmo(2)">
              {{button2}}
            </v-btn>
          </v-col>
          <v-col  cols="12" sm="1" class=" align-left" v-if="lla">
            <v-select
              :items="months"
              label="Mjesec"
              dense
              v-model="selectedMonth"
              clearable
            ></v-select>
          </v-col>
          <v-col  cols="12" sm="2" v-if="lla">
            <v-btn
              text
              color="primary"
              @click="calulusAmo(1)">
              {{button1}}
            </v-btn>
          </v-col>
          <!-- <v-col  cols="12" sm="1" >
            <v-autocomplete
              :items="account4s"
              v-model="filterA4"
              :label="lang.llaAcount4"
              single-line
              item-text="acc4Acc"
              item-value="account4"
              return-object
              persistent-hint
              :filter="getList"
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col  cols="12" sm="1" >
             <v-btn
              text color="primary"
              @click="initialize()">
              Filter
            </v-btn>
          </v-col> -->
          <v-col cols="12" sm="1">
            <v-dialog
              ref="printdialog1"
              persistent
              v-model="printmodal1"                   
              width="290px"
              :return-value.sync="printdate"
            >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="printdate"
                label="Nabava od"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
                clearable
              ></v-text-field>
            </template>
              <v-date-picker v-model="printdate" scrollable>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="printmodal1 = false">{{langC.Cancel}}</v-btn>
                <v-btn color="primary" @click="$refs.printdialog1.save(printdate)">OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" sm="1">
            <v-dialog
              ref="printdialog2"
              persistent
              v-model="printmodal2"                   
              width="290px"
              :return-value.sync="printdate2"
            >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="printdate2"
                label="Nabava do"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
                clearable
              ></v-text-field>
            </template>
              <v-date-picker v-model="printdate2" scrollable>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="printmodal2 = false">{{langC.Cancel}}</v-btn>
                <v-btn color="primary" @click="$refs.printdialog2.save(printdate2)">OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col  cols="12" sm="1" >
             <v-btn
              text color="primary"
              @click="downloadFile()">
              Excel
            </v-btn>
          </v-col>
          <v-col  cols="12" sm="1" >
             <v-btn
              text
              color="primary"
              @click="print">
              Print
            </v-btn>
          </v-col>
        </v-row>

        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >

                  <v-row>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.llaID"
                        v-model="editedItem.llaID"
                        type="number"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.llaCode"
                        v-model="editedItem.llaCode"
                      ></v-text-field>
                    </v-col>
                     <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.BusinessYear"
                        v-model="editedItem.BusinessYear"
                        type="number"
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row v-if="lla"> 
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                      <v-autocomplete
                        :items="account4s"
                        v-model="editedItem.account4"
                        :label="lang.llaAcount4"
                        item-text="acc4Acc"
                        item-value="account4"
                        return-object
                        persistent-hint
                        :filter="getList"
                        @change="choosedAcc(editedItem.account4, 1)"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                      <v-autocomplete
                        :items="account4s"
                        v-model="editedItem.Debitaccount4"
                        :label="lang.llaAccountDebit"
                        item-text="acc4Acc"
                        item-value="account4"
                        return-object
                        persistent-hint
                        :filter="getList"
                        @change="choosedAcc(editedItem.Debitaccount4, 2)"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                      <v-autocomplete
                        :items="account4s"
                        v-model="editedItem.Creditaccount4"
                        :label="lang.llaAccountCredit"
                        item-text="acc4Acc"
                        item-value="account4"
                        return-object
                        persistent-hint
                        :filter="getList"
                        @change="choosedAcc(editedItem.Creditaccount4, 3)"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.llaName"
                        v-model="editedItem.llaName"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.llaDocumentSource"
                        v-model="editedItem.llaDocumentSource"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.llaPieces"
                        v-model="editedItem.llaPieces"
                        type="number"
                      ></v-text-field>
                    </v-col>


                  </v-row>

                  <v-row>

                    <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-1">
                       <v-dialog
                          ref="dialog2"
                          persistent
                          v-model="modal2"
                          width="290px"
                          :return-value.sync="editedItem.llaDocumentSourceDate"
                        >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="editedItem.llaDocumentSourceDate"
                            :label="lang.llaDocumentSourceDate"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>

                          <v-date-picker v-model="editedItem.llaDocumentSourceDate" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="modal2 = false">{{langC.Cancel}}</v-btn>
                            <v-btn color="primary" @click="$refs.dialog2.save(editedItem.llaDocumentSourceDate)">OK</v-btn>
                          </v-date-picker>
                        </v-dialog>
                    </v-col>


                    <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" v-if="lla">
                       <v-dialog
                          ref="dialog5"
                          persistent
                          v-model="modal5"
                          width="290px"
                          :return-value.sync="editedItem.llaStartDate"
                          >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="editedItem.llaStartDate"
                              :label="lang.llaStartDate"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>

                          <v-date-picker v-model="editedItem.llaStartDate" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="modal5 = false">{{langC.Cancel}}</v-btn>
                            <v-btn color="primary" @click="$refs.dialog5.save(editedItem.llaStartDate)">OK</v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" v-if="lla">
                        <v-dialog
                          ref="dialog6"
                          persistent
                          v-model="modal6"
                          width="290px"
                          :return-value.sync="editedItem.llaEndDate"
                          class="pb-6"
                          >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="editedItem.llaEndDate"
                              :label="lang.llaEndDate"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>

                          <v-date-picker v-model="editedItem.llaEndDate" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="modal6 = false">{{langC.Cancel}}</v-btn>
                            <v-btn color="primary" @click="$refs.dialog6.save(editedItem.llaEndDate)">OK</v-btn>
                          </v-date-picker>
                        
                        </v-dialog>
                    </v-col>                  
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.llaBuyingValue"
                        v-model="editedItem.llaBuyingValue"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.llaDepreciationPercent"
                        v-model="editedItem.llaDepreciationPercent"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.llaLasting"
                        v-model="editedItem.llaLasting"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    
                     
                  </v-row>

                  <v-row v-if="lla">
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.llaDepreciationAmountTotal"
                        v-model="editedItem.llaDepreciationAmountTotal"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <v-text-field
                        dense
                        :label="bookValue"
                        v-model="editedItem.llaBookValue"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <v-text-field
                        dense
                        :label="descYear"
                        v-model="editedItem.llaDepreciationAmountYear"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="descYearEnd"
                        v-model="editedItem.llaValueYearEnd"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <v-checkbox
                        :label="lang.llaMark"
                        v-model="editedItem.llaMark"
                      ></v-checkbox>

                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <v-dialog
                          ref="dialog3"
                          persistent
                          v-model="modal3"
                          width="290px"
                          :return-value.sync="editedItem.llaSellDate"
                        >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="editedItem.llaSellDate"
                            :label="lang.llaSellDate"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>

                          <v-date-picker v-model="editedItem.llaSellDate" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="modal3 = false">{{langC.Cancel}}</v-btn>
                            <v-btn color="primary" @click="$refs.dialog3.save(editedItem.llaSellDate)">OK</v-btn>
                          </v-date-picker>
                        </v-dialog>
                    </v-col>
                     <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                        <v-dialog
                          ref="dialog4"
                          persistent
                          v-model="modal4"
                          width="290px"
                          :return-value.sync="editedItem.llaRegistrartionDate"
                        >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="editedItem.llaRegistrartionDate"
                            :label="lang.llaRegistrartionDate"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>

                          <v-date-picker v-model="editedItem.llaRegistrartionDate" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="modal4 = false">{{langC.Cancel}}</v-btn>
                            <v-btn color="primary" @click="$refs.dialog4.save(editedItem.llaRegistrartionDate)">OK</v-btn>
                          </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.llaRegistrartionContract"
                        v-model="editedItem.llaRegistrartionContract"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
 

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="cardItem">{{lang.llaCard}}</v-btn>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn color="success" text @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.BusinessYear`]="{ item }">
        <span>{{ parseInt(item.BusinessYear) }}</span>
    </template>
     <template v-slot:[`item.llaStartDate`]="{ item }">
        <span>{{ localDate(item.llaStartDate) }}</span>
    </template>
    <template v-slot:[`item.llaDocumentSourceDate`]="{ item }">
        <span>{{ localDate(item.llaDocumentSourceDate) }}</span>
    </template>


    <template v-slot:[`item.llaBuyingValue`]="{ item }">
        <span>{{ localMoney(item.llaBuyingValue) }}</span>
    </template>
    <template v-slot:[`item.llaDepreciationAmountTotal`]="{ item }">
        <span>{{ localMoney(item.llaDepreciationAmountTotal) }}</span>
    </template>
    <template v-slot:[`item.llaBookValue`]="{ item }">
        <span>{{ localMoney(item.llaBookValue) }}</span>
    </template>
    <template v-slot:[`item.llaValueYearEnd`]="{ item }">
        <span>{{ localMoney(item.llaValueYearEnd) }}</span>
    </template>

    <template v-slot:[`item.llaMark`]="{ item }">
      <span v-if="item.llaMark === 'true'"><v-icon>mdi-check</v-icon></span>
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      {{ lang.CalEmpty }}
    </template>
  </v-data-table>
  </v-container>
   <MainLedgerPrintLla ref="prnt" :card="card" />
   <MainLedgerLLATotals :users="users" :key="key"/>

    <v-row>
      <v-col cols="12" sm="6" md="6" xs="6" lg="9" xl="9" class="pr-1">

      </v-col>

      <v-col cols="12" sm="6" md="6" xs="6" lg="3" xl="3" class="pr-1">
         <v-btn
          text color="primary"
          @click="copytoNewYear()">
          {{lang.CopytoNewYear}}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MainLedgerLLAService from '@/services/MainLedgerLLAService'
import MainLedgerDetailService from '@/services/MainLedgerDetailService'
import MainLedgerService from '@/services/MainLedgerService'
import MainLedgerPrintLla from './MainLedgerPrintLla'
import MainLedgerLLATotals from './MainLedgerLLATotals'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import { defMainLedger, defNew, defMainLedgerDetail, defMainLedgerDetailFill } from './mainledgerHelper'
import { round, months, dynamicSort, parseNum} from '@/snippets/allSnippets'
import moneyFormat from '@/snippets/moneyFormat'
import xlsx from 'json-as-xlsx'
import dayjs from 'dayjs'

  export default {
    name: 'MainLedgerLLA',
    props: ['myDocumentSide'],
    data: () => ({
      status: 0,
      typeError: null,
      alert: false,
      lla: true,
      documentName: '',
      documentNameLoc: '',
      date: new Date().toISOString().substr(0, 10),
      printdate: null,
      printdate2: null,
      menu: false,
      menu2: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      modal5: false,
      modal6: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      dialog5: false,
      dialog6: false,
      printdialog1: false,
      printmodal1: false,
      printdialog2: false,
      printmodal2: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {
      },
      selectA4: {},
      filterA4: null,
      account1s: [],
      account2s: [],
      account3s: [],
      account4s: [],
      dialog: false,
      headers: [],
      users: [],
      editedIndex: -1,
      editedItem: { },
      defaultItem: {
        llaType: null,
        llaID: null,
        llaCode: null,
        llaName: null,
        llaDocumentSource: null,
        llaDocumentSourceDate: null,
        llaBuyingValue: 0,
        llaBookValue: 0,
        llaLasting: 0,
        llaDepreciationPercent: 0,
        llaDepreciationAmountYear: 0,
        llaDepreciationAmountTotal: 0,
        llaValueYearEnd: 0,
        llaMark: '',
        llaSellDate: null,
        llaRegistrartionDate: null,
        llaRegistrartionContract: null,
        Debit: 0,
        Credit: 0,
        DebitForegin: 0,
        CreditForegin: 0,
        DebitTotal: 0,
        CreditTotal: 0,
        DebitForeginTotal: 0,
        CreditForeginTotal: 0,
        DiffTotal: 0,
        DiffForeginTotal: 0,
        ClosedAccount: false,
        FreeF1: null,
        account1:  '',
        account2:  '',
        account3:  '',
        account4:  '',
        account4Description: '',
        Debitaccount1:  '',
        Debitaccount2:  '',
        Debitaccount3:  '',
        Debitaccount4:  '',
        Debitaccount4Description: '',
        Creditaccount1:  '',
        Creditaccount2:  '',
        Creditaccount3:  '',
        Creditaccount4:  '',
        Creditaccount4Description: '',
        llaStartDate: null,
        llaEndDate: null,
        llaPieces: 1,
        llaStatus: '',
        a2Dcode: '',
        QRcodeData: '',
        QRcodeLink: '',
      },
      privitems: [],
      valid: true,
      card: {},
      newDebit: {},
      newCredit: {},
      newItems: [],
      descYear: '',
      descYearEnd: '',
      bookValue:'',
      exRate: 0,
      button1: '',
      button2: '',
      months: [],
      selectedMonth: 0,
      key: 1
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewLLA: this.lang.EditLLA
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    components: {
      MainLedgerPrintLla,
      MainLedgerLLATotals
    },

    mounted() {
      this.exRate = this.$store.getters.NeedDefExRate
      if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }

      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      
      this.lla = this.$store.state.documentSide === 7 ? true : false
      this.months = months()

      this.account4s = this.$store.state.account4s ? this.$store.state.account4s : []
      this.bookValue = this.lang.llaBookValue + ' ' + (this.$store.state.businessYear ? this.$store.state.businessYear.toString() : "2021")
      this.descYear = this.lang.llaDepreciationAmountYear + ' ' + (this.$store.state.businessYear ? this.$store.state.businessYear.toString() : "2021")
      this.descYearEnd = this.lang.llaValueYearEnd + ' ' + (this.$store.state.businessYear ? this.$store.state.businessYear.toString() : "2021")
      this.button1 = 'Obrač.amo.za ' +  (this.$store.state.businessYear ? this.$store.state.businessYear : 0)
      this.button2 = 'Obrač.amo.za ' +  (this.$store.state.businessYear ? this.$store.state.businessYear : 0) + ' do kraja mj.' 

      this.privitems.push(
        { text: this.lang.Priv1, value: 1 },
        { text: this.lang.Priv2, value: 2 },
        { text: this.lang.Priv3, value: 3 },
        { text: this.lang.Priv4, value: 4 },
        { text: this.lang.Priv5, value: 5 }
      )
      if (this.$store.state.documentSide === 7) {
        this.headers.push(
          {text: this.lang.BusinessYear, value: 'BusinessYear', align: 'left', width: '50px' },
          {text: this.lang.llaID, value: 'llaID', align: 'left', width: '70px' },
          {text: this.lang.llaCode, value: 'llaCode', align: 'left', width: '100px' },
          {text: this.lang.llaName, value: 'llaName', align: 'left', width: '300px' },
          {text: this.lang.llaAcount4, value: 'account4', width: '90px'},
          {text: this.lang.llaDocumentSource, value: 'llllaDocumentSourceaID', align: 'right', width: '100px' },
          {text: this.lang.llaDocumentSourceDate, value: 'llaDocumentSourceDate', width: '110px', type: 'date', dateInputFormat: 'YYYY-MM-DD', dateOutputFormat: 'DD.MM.YYYY'},
          {text: this.lang.llaBuyingValue, value: 'llaBuyingValue', width: '150px', type: 'number', align: 'right'},
          {text: this.lang.llaDepreciationPercent, value: 'llaDepreciationPercent', width: '50px', type: 'number', align: 'right'},
          {text: this.lang.llaLasting, value: 'llaLasting', width: '50px', type: 'number', align: 'right'},
          {text: this.lang.llaDepreciationAmountTotal, value: 'llaDepreciationAmountTotal', width: '100px', align: 'right'},
          {text: this.bookValue , value: 'llaBookValue', width: '150px', type: 'number', align: 'right'},
          {text: this.descYear, value: 'llaDepreciationAmountYear', width: '100px', type: 'number', align: 'right'},
          {text: this.descYearEnd, value: 'llaValueYearEnd', width: '100px', type: 'number', align: 'right'},
          {text: this.lang.llaMark, value: 'llaMark', width: '50px', type: 'number'},
          {text: 'DbId', value: 'id', type: 'number', width: '50px'},
          {text: this.lang.Actions, value: 'action', sortable: false },
        )
      }
      if (this.$store.state.documentSide === 8) {
        this.headers.push(
          {text: this.lang.BusinessYear, value: 'BusinessYear', align: 'left', width: '50px' },
          {text: this.lang.llaID, value: 'llaID', align: 'left', width: '100px' },
          {text: this.lang.llaCode, value: 'llaCode', align: 'left', width: '100px' },
          {text: this.lang.llaName, value: 'llaName', align: 'left', width: '150px' },
          {text: this.lang.llaDocumentSource, value: 'llllaDocumentSourceaID', align: 'left', width: '150px' },
          {text: this.lang.llaDocumentSourceDate, value: 'llaDocumentSourceDate', width: '150px', type: 'date', dateInputFormat: 'YYYY-MM-DD', dateOutputFormat: 'DD.MM.YYYY'},
          {text: this.lang.llaBuyingValue, value: 'llaBuyingValue', width: '150px', type: 'number', align: 'right'},
          {text: this.lang.llaBookValue , value: 'llaBookValue', width: '150px', type: 'number', align: 'right'},
          {text: this.lang.llaLasting, value: 'llaLasting', width: '50px', type: 'number', align: 'right'},
          {text: this.lang.llaMark, value: 'llaMark', width: '50px', type: 'number', align: 'right'},
          {text: 'DbId', value: 'id', type: 'number', width: '50px'},
          {text: this.lang.Actions, value: 'action', sortable: false },
        )
      }
    },


    created () {
      this.initialize()
    },

    methods: {
      localMoney(moneyToFormat) {
        return moneyFormat(moneyToFormat)
      },
      choosedAcc (choosedAcc, side) {
        if (choosedAcc) {
          const result = this.account4s.filter(element => element.account4 === choosedAcc.account4)
          if (result[0]) {
            const res = result[0]
            if (side === 1) {
              this.editedItem.account1 = res.account1
              this.editedItem.account2 = res.account2
              this.editedItem.account3 = res.account3
              this.editedItem.account4 = res.account4
              this.editedItem.account4id = res.id
              this.editedItem.account4Description = res.account4Description
              this.editedItem.account4Description2 = res.account4Description2
              this.selectA4 = res
            }
            if (side === 2) {
              this.editedItem.Debitaccount1 = res.account1
              this.editedItem.Debitaccount2 = res.account2
              this.editedItem.Debitaccount3 = res.account3
              this.editedItem.Debitaccount4 = res.account4
              this.editedItem.Debitaccount4id = res.id
              this.editedItem.Debitaccount4Description = res.account4Description
              this.editedItem.Debitaccount4Description2 = res.account4Description2
              this.selectA4 = res
            }
            if (side === 3) {
              this.editedItem.Creditaccount1 = res.account1
              this.editedItem.Creditaccount2 = res.account2
              this.editedItem.Creditaccount3 = res.account3
              this.editedItem.Creditaccount4 = res.account4
              this.editedItem.Creditaccount4id = res.id
              this.editedItem.Creditaccount4Description = res.account4Description
              this.editedItem.Creditaccount4Description2 = res.account4Description2
              this.selectA4 = res
            }

          } else {
            this.text = this.langC.AccountMissing
            this.color = 'red'
            this.snackbar = true
          }
        } else {
          if (side === 1) {
            this.editedItem.account1 = null
            this.editedItem.account2 = null
            this.editedItem.account3 = null
            this.editedItem.account4 = null
            this.editedItem.account4id = null
            this.editedItem.account4Description = null
            this.editedItem.account4Description2 = null
            this.selectA4 = {}
          }
          if (side === 1) {
            this.editedItem.Debitaccount1 = null
            this.editedItem.Debitaccount2 = null
            this.editedItem.Debitaccount3 = null
            this.editedItem.Debitaccount4 = null
            this.editedItem.Debitaccount4id = null
            this.editedItem.Debitaccount4Description = null
            this.editedItem.Debitaccount4Description2 = null
            this.selectA4 = {}
          }
          if (side === 1) {
            this.editedItem.Creditaccount1 = null
            this.editedItem.Creditaccount2 = null
            this.editedItem.Creditaccount3 = null
            this.editedItem.Creditaccount4 = null
            this.editedItem.Creditaccount4id = null
            this.editedItem.Creditaccount4Description = null
            this.editedItem.Creditaccount4Description2 = null
            this.selectA4 = {}
          }
        }     
      },
      print(){
        const newObj = {}
        let printitems = this.users.slice()
        if (!this.printdate && this.printdate2) {
          printitems = this.users.filter(user => user.llaDocumentSourceDate <= this.printdate2)
        }
        if (this.printdate && this.printdate2) {
          printitems = this.users.filter(user => user.llaDocumentSourceDate >= this.printdate && user.llaDocumentSourceDate <= this.printdate2)
        }
        printitems.sort(dynamicSort("llaDocumentSourceDate"))
        this.card = {...newObj}
        this.card.items = printitems
        this.card.head = this.$store.state.companyActiv
        this.$refs.prnt.print()
      },
      async cardItem () {
        const companyName = this.$store.state.companyActiv && this.$store.state.companyActiv.CompanyNameDatabase ? this.$store.state.companyActiv.CompanyNameDatabase : ''
        const currDate = dayjs().format('DD_MM_YYYY_HH_MM')
        this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        this.mainQuery.llaID = this.editedItem.llaID
        const sheetNm = ('OsKartica_' + (this.editedItem.llaName ? this.editedItem.llaName : '')).substring(0,30)
        const cont = (await MainLedgerLLAService.card(this.mainQuery)).data.mainLedger
        let data = [
          {
            sheet: sheetNm,
            columns: [
              {label: this.lang.BusinessYear, value: 'BusinessYear' },
              {label: this.lang.llaID, value: 'llaID'},
              {label: this.lang.llaCode, value: 'llaCode' },
              {label: this.lang.llaName, value: 'llaName'},
              {label: this.lang.llaAcount4, value: 'account4'},
              {label: this.lang.llaDocumentSource, value: 'llllaDocumentSourceaID'},
              {label: this.lang.llaDocumentSourceDate,  value: row => (this.localDate(row.llaDocumentSourceDate))},
              {label: this.lang.llaStartDate,  value: row => (this.localDate(row.llaStartDate))},
              {label: this.lang.llaEndDate,  value: row => (this.localDate(row.llaEndDate))},
              {label: this.lang.llaBuyingValue, value: 'llaBuyingValue'},
              {label: this.lang.llaDepreciationPercent, value: 'llaDepreciationPercent'},
              {label: this.lang.llaLasting, value: 'llaLasting'},
              {label: this.lang.llaDepreciationAmountTotal, value: 'llaDepreciationAmountTotal'},
              {label: this.bookValue , value: 'llaBookValue'},
              {label: this.descYear, value: 'llaDepreciationAmountYear'},
              {label: this.descYearEnd, value: 'llaValueYearEnd'},
              {label: this.lang.llaMark, value: 'llaMark'},
              {label: this.lang.llaSellDate,  value: row => (row.llaSellDate ? this.localDate(row.llaSellDate) : '')},            
            ],
            content: cont
          },
        ]
        let settings = {
              fileName: companyName  + currDate + '_OsKartica_' + (this.editedItem.llaName ? this.editedItem.llaName : ''),
              extraLength: 3, // A bigger number means that columns will be wider
              writeOptions: {},
              sheet: 'OS_kartica', // Style options from https://github.com/SheetJS/sheetjs#writing-options
        }
        xlsx( data, settings)
      },
      downloadFile () {
        //const allLines = this.users ? this.users : []
        let printitems = this.users.slice()
        if (!this.printdate && this.printdate2) {
          printitems = this.users.filter(user => user.llaDocumentSourceDate < this.printdate2)
        }
        if (this.printdate && this.printdate2) {
          printitems = this.users.filter(user => user.llaDocumentSourceDate > this.printdate && user.llaDocumentSourceDate < this.printdate2)
        }
        printitems.sort(dynamicSort("llaDocumentSourceDate"))
        const companyName = this.$store.state.companyActiv && this.$store.state.companyActiv.CompanyNameDatabase ? this.$store.state.companyActiv.CompanyNameDatabase : ''
        const currDate = dayjs().format('DD_MM_YYYY_HH_MM')
        const sheetNm = 'OS_' + this.$store.state.businessYear + '_' + currDate
        let data = [
        {
          sheet: sheetNm,
          columns: [
            {label: this.lang.BusinessYear, value: 'BusinessYear' },
            {label: this.lang.llaID, value: 'llaID'},
            {label: this.lang.llaCode, value: 'llaCode' },
            {label: this.lang.llaName, value: 'llaName'},
            {label: this.lang.llaAcount4, value: 'account4'},
            {label: this.lang.llaDocumentSource, value: 'llaDocumentSourceaID'},
            {label: this.lang.llaDocumentSourceDate,  value: row => (this.localDate(row.llaDocumentSourceDate))},
            {label: this.lang.llaStartDate,  value: row => (this.localDate(row.llaStartDate))},
            {label: this.lang.llaEndDate,  value: row => (this.localDate(row.llaEndDate))},
            {label: this.lang.llaBuyingValue, value: row => (this.localParse(row.llaBuyingValue))},
            {label: this.lang.llaDepreciationPercent, value: row => (this.localParse(row.llaDepreciationPercent))},
            {label: this.lang.llaLasting, value: row => (this.localParse(row.llaLasting))},
            {label: this.lang.llaDepreciationAmountTotal,value: row => (this.localParse(row.llaDepreciationAmountTotal))},
            {label: this.bookValue , value: row => (this.localParse(row.llaBookValue))},
            {label: this.descYear,value: row => (this.localParse(row.llaDepreciationAmountYear))},
            {label: this.descYearEnd, value: row => (this.localParse(row.llaValueYearEnd))},
            {label: this.lang.llaMark, value: 'llaMark'},
            {label: this.lang.llaSellDate,  value: row => (this.localDate(row.llaSellDate))},
          ],
          content: printitems
        },
      ]

        
        const settings = {
          fileName: companyName + '_OsnovnaSredstva_' + currDate, // Name of the spreadsheet
          extraLength: 3, // A bigger number means that columns will be wider
          writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
        }
        xlsx( data, settings)
      },
      getList  (item, queryText, itemText) {
        return itemText.toLocaleLowerCase().startsWith(queryText.toLocaleLowerCase())
      },
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min) ) + min;
      },
      async initialize () {
        try {
          this.saving = true
          this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1,
          this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021,
          this.mainQuery.MLType = this.$store.state.documentSide ? this.$store.state.documentSide : 1
          //this.mainQuery.account4 = this.filterA4 && this.filterA4.account4 ? this.filterA4.account4 : null

          const {data} = await MainLedgerLLAService.index(this.mainQuery)
          if (data && data.mainledgerLLAs) {
            this.users = data.mainledgerLLAs
          }
          this.errorMatch = false
          this.defaultItem.llaID = (await MainLedgerLLAService.choose(this.mainQuery)).data.lastID
          this.editedItem = {...defNew(), ...this.defaultItem}
          const locDate1 = new Date()
          const llamonth = locDate1.getMonth() + 1
          const llayear = locDate1.getFullYear()
          this.editedItem.llaStartDate = dayjs().year(llayear).month(llamonth).date(1).format('YYYY-MM-DD')
          this.editedItem.llaEndDate = dayjs([this.editedItem.BusinessYear]).endOf('year').format('YYYY-MM-DD')
          this.key += 1
          this.saving = false
        } catch (err) {
          this.error = 'Greska u konekciji na bazu' + err
          this.typeError = 'error'
        }
      },
      editItem (item) {
        this.errorMatch = false
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = {...item}
        if (!this.editedItem.llaMark || this.editedItem.llaMark !== "true") {
            this.editedItem.llaMark = false
        }
        if (this.editedItem.llaMark && this.editedItem.llaMark === "true") {
            this.editedItem.llaMark = true
        }
        this.dialog = true
        this.confpassword = ''
      },
      async calulusAmo(type) {
        try {
          // check if exist
          if (type === 2 && !this.selectedMonth) {
            this.error = 'Greska nije odabran mjesec. Error, month is not selected!! '
            this.alert = true
            this.typeError = 'error'
            return
          } 
          const msgAmo = type === 1 ? ('Sigurni ste da zelite obračunati amortizaciju za  ' + (this.$store.state.businessYear ? this.$store.state.businessYear : 0) + '!') : ('Sigurni ste da zelite obračunati amortizaciju za  ' + (this.$store.state.businessYear ? this.$store.state.businessYear : 0) + ' do mjeseca ' + type + '!')
          if (confirm(msgAmo)) {
            this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
            this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
            this.mainQuery.BookTypeID = 22
            this.mainQuery.MLType = 3
            this.mainQuery.ConnId = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
            let llaID = null
            const haveIt = await MainLedgerService.index(this.mainQuery)
            
            if (haveIt && haveIt.data.documents && haveIt.data.documents.length > 0) {
              // have mainledger LLA, use it
              const head = haveIt.data.documents[0]
              llaID = head.id
              this.mainledger = defMainLedger()
              this.mainledger = {...this.mainledger, ...defNew()}
              this.mainledger.BookTypeID = head.BookTypeID
              this.mainledger.MLType = head.MLType
              this.mainledger.BookName = head.BookName
              this.mainledger.BookID = head.BookID
              this.mainledger.documentName = head.documentName
              this.mainledger.documentIdGeneral = head.documentIdGeneral
              this.mainledger.BookIdGeneral = head.documentIdGeneral
              this.mainledger.ConnName = head.ConnName
              this.mainledger.ConnId = head.ConnId
              this.mainledger.MLFree1 = head.MLFree1
            }
            if (!llaID) {
              this.mainledger = defMainLedger()
              this.mainledger = {...this.mainledger, ...defNew()}
              this.mainledger.BookTypeID = 22
              this.mainledger.MLType = 3
              this.mainledger.BookName = 'OS'
              this.mainledger.BookID = (await MainLedgerService.choose(this.mainQuery)).data.docNumber
              this.mainledger.documentName = 'Ledger'
              this.mainledger.documentIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-Ledger-' + this.mainledger.BookID
              this.mainledger.BookIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-Ledger-' + this.mainledger.BookID
              this.mainledger.ConnName = 'OS'
              this.mainledger.ConnId = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
              this.mainledger.MLFree1 = 'AMORTIZACIJA '  + this.$store.state.businessYear
              const have2 = await MainLedgerService.post(this.mainledger)
              if (have2 && have2.data.mainledger && have2.data.mainledger.id > 0) {
                llaID = have2.data.mainledger.id
              }
            }
            if (!llaID) {
              console.log('ERROR dont have id')
              this.error = 'Greska u konekciji na bazu. Error database connection. NO ID '
              this.alert = true
              this.typeError = 'error'
            }
            if (this.users.length === 0) {
              this.error = 'Greska! Error!, Tabela osnovnih sredstava je prazna ! There is no items in table !'
              this.alert = true
              this.typeError = 'error'
            }
            this.newItems = []

            if (this.users.length > 0) {
              this.users.map(async(user) => {
                let index = this.users.indexOf(user)
            
                // if is disabled 
                if (user.llaMark === "true") {
                  const updateItem = user
                  updateItem.llaDepreciationAmountYear = 0
                  updateItem.llaValueYearEnd = round((user.llaBookValue), 2)

                  await MainLedgerLLAService.put(updateItem)
                }                  
                // it is not disabled
                if (!user.llaMark || user.llaMark !== "true") {
                  // do math
                  let depreciationAmount = 0
                  if (parseNum(user.llaBookValue,2) > 0) {
                    const yearsLast = user.llaDepreciationPercent > 0 ? parseInt(100 / user.llaDepreciationPercent) : 0

                    let startPeriod = user.llaStartDate
                    let endPeriod = (parseInt(user.llaStartDate.substr(0,4)) + yearsLast).toString() + user.llaEndDate.substr(3, user.llaEndDate.length)
                    //const finalDate = dayjs(user.llaStartDate).add(user.llaLasting, 'months').calendar()
                    //const isAfter = dayjs(finalDate).isSameOrAfter(user.llaEndDate, 'day')

                    const currYear = this.$store.state.businessYear 
                    if (parseInt(user.llaStartDate.substr(0,4)) < currYear) {
                      startPeriod = currYear + '-01-01'
                    }
                    if (parseInt(endPeriod.substr(0,4)) > currYear) {
                      endPeriod = currYear + '-12-31'
                    }

                    const startDate = startPeriod ? dayjs(startPeriod, 'YYYY-MM-DD').toDate(): null
                    const endDate = type === 1 && endPeriod ? dayjs(endPeriod, 'YYYY-MM-DD').toDate(): dayjs({ year: this.$store.state.businessYear, month: this.selectedMonth + 1, day: 1})

                    const fullMonths = round((Math.floor(( endDate - startDate ) / 86400000) / 30), 0)

                    let useMonths = 0
                    if (fullMonths > user.llaLasting) {
                      useMonths = user.llaLasting
                    }
                    if (fullMonths <= user.llaLasting) {
                      useMonths = fullMonths
                    }

                    const monthDepreciation = ((user.llaBuyingValue * (user.llaDepreciationPercent / 100))/ 12)
                    depreciationAmount = round(useMonths * monthDepreciation, 2 )

                    if (depreciationAmount > parseNum(user.llaBookValue,2)) {
                      depreciationAmount = user.llaBookValue
                    }
                  }
                  if (parseNum(user.llaBookValue,2) <= 0) {
                    depreciationAmount = 0
                  }
                  const updateItem = {...user}
                  updateItem.llaDepreciationAmountYear = depreciationAmount > 0 ? depreciationAmount : 0
                  updateItem.llaValueYearEnd = (parseNum(user.llaBookValue,2) - depreciationAmount) < 0 ? 0 : (parseNum(user.llaBookValue,2) - depreciationAmount)

                  if (index !== -1) {
                    this.users[index] = updateItem
                  }
                  if (depreciationAmount > 0) {
                    if (user.Debitaccount4 && user.Debitaccount4.length > 3) {
                      this.newDebit = defMainLedgerDetail()
                      this.newDebit = {...this.newDebit, ...defMainLedgerDetailFill()}
                      this.newDebit.databaseMLID = llaID
                      this.newDebit.BookTypeID  = this.mainledger.BookTypeID
                      this.newDebit.MLType  = this.mainledger.MLType
                      this.newDebit.BookName  = this.mainledger.BookName
                      this.newDebit.BookID = this.mainledger.BookID
                      this.newDebit.documentName = this.mainledger.documentName
                      this.newDebit.documentIdGeneral = this.mainledger.documentIdGeneral
                      this.newDebit.BookIdGeneral = this.mainledger.BookIdGeneral
                      this.newDebit.ConnName = this.mainledger.ConnName
                      this.newDebit.ConnId = this.mainledger.ConnId
                      this.newDebit.MLFree1 = this.mainledger.MLFree1
                      //this.newDebit.id = this.getRndInteger(1000000000, 9000000000)
                      this.newDebit.Description = 'Amortizacija ' + user.llaName ? user.llaName : ''
                      this.newDebit.Credit = 0
                      this.newDebit.CreditForegin = 0
                    
                      this.newDebit.account1 = user.Debitaccount1
                      this.newDebit.account2 = user.Debitaccount2
                      this.newDebit.account3 = user.Debitaccount3
                      this.newDebit.account4 = user.Debitaccount4
                      this.newDebit.Debit = depreciationAmount
                      this.newDebit.DebitForegin = round((depreciationAmount / this.exRate), 2)
                      this.newDebit.account4Description = user.Debitaccount4Description
                      this.newItems.push(this.newDebit)

                    } 
                    if (user.Creditaccount4 && user.Creditaccount4.length > 3) {
                      this.newCredit = defMainLedgerDetail()
                      this.newCredit = {...this.newCredit, ...defMainLedgerDetailFill()}
                      this.newCredit.databaseMLID = llaID
                      this.newCredit.BookTypeID  = this.mainledger.BookTypeID
                      this.newCredit.MLType  = this.mainledger.MLType
                      this.newCredit.BookName  = this.mainledger.BookName
                      this.newCredit.BookID = this.mainledger.BookID
                      this.newCredit.documentName = this.mainledger.documentName
                      this.newCredit.documentIdGeneral = this.mainledger.documentIdGeneral
                      this.newCredit.BookIdGeneral = this.mainledger.BookIdGeneral
                      this.newCredit.ConnName = this.mainledger.ConnName
                      this.newCredit.ConnId = this.mainledger.ConnId
                      this.newCredit.MLFree1 = this.mainledger.MLFree1
                      //this.newCredit.id = this.getRndInteger(1000000000, 9000000000)
                      this.newCredit.Description = 'Amortizacija ' + user.llaName ? user.llaName : ''
                      this.newCredit.Debit = 0
                      this.newCredit.DebitForegin = 0
                      this.newCredit.account1 = user.Creditaccount1
                      this.newCredit.account2 = user.Creditaccount2
                      this.newCredit.account3 = user.Creditaccount3
                      this.newCredit.account4 = user.Creditaccount4
                      this.newCredit.Credit = depreciationAmount
                      this.newCredit.CreditForegin = round((depreciationAmount / this.exRate), 2)
                      this.newCredit.account4Description = user.Creditaccount4Description
                      this.newItems.push(this.newCredit)
  
                    }
                  }
                }
              })             
            }
          }
          await this.saveItems()
          await this.updateAll()
          await this.initialize()
        } catch(err) {
          this.error = err
        }
      },
      async updateAll() {
        if (this.users.length > 0) {
          // save all to database
          await MainLedgerLLAService.saveitems(this.users)
          .then (response => {
            console.log("response", response)
            this.error = 'Amortizacija obracunata OK! All data saved OK!'
            this.alert = true
            this.typeError = 'success'
            }
          )
          .catch(err => {
            console.log("response", err)
            this.error = 'Greska! Error! Stavke nisu upisane u temeljnicu. Items are not saved.!'
            this.alert = true
            this.typeError = 'error'
          })
        }
      },
      async saveItems() {
            if (this.newItems.length > 0) {
              // save all to database
              await MainLedgerDetailService.saveitems(this.newItems)
              .then (response => {
                console.log("response", response)
                }
              )
              .catch(err => {
                console.log("response", err)
              })
            }

      },
      async deleteItem (item) {
        try {
          const index = this.users.indexOf(item)
          const warningMessage = item.llaName
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.users.splice(index, 1)) {
            await MainLedgerLLAService.delete(item.id)
            this.initialize()
          }
        } catch(err) {
          this.error = err
        }
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedIndex = -1
        }, 300)
      },

      async copytoNewYear() {
        const newYearUsers = []
        const msgAmo = ('Sigurni ste da želite prebaciti završno stanje kao početno stanje u ' + (this.$store.state.businessYear ? this.$store.state.businessYear +1  : 0) + ' godinu?')
        if (confirm(msgAmo)) { 
          if (this.users.length > 0) {
            this.users.map(user => {
              // it is not disabled
              if (user.llaMark !== "true") {
                const newUser = {...user}
                // do math 
                const divide = this.$store.state.businessYear <= 2022 ? 7.5345 : 1
                delete newUser.id
                delete newUser.documentIdGeneral
                newUser.documentIdGeneral = this.$store.state.companyName + '-' + (this.$store.state.businessYear + 1) + '-' + this.$store.state.documentActiv + '-' + newUser.llaID
                newUser.BusinessYear = this.$store.state.businessYear +1
                newUser.llaBuyingValue = round(((user.llaBuyingValue) / divide), 2)
                newUser.llaDepreciationAmountTotal = round(((user.llaBuyingValue - user.llaValueYearEnd) / divide), 2)
                newUser.llaBookValue = round((user.llaValueYearEnd /divide),2)
                newUser.llaDepreciationAmountYear = 0
                newUser.llaValueYearEnd = round((user.llaValueYearEnd /divide),2)
                newYearUsers.push(newUser)
              }
            })
          }
          if (newYearUsers.length > 0) {
            await MainLedgerLLAService.saveitems(newYearUsers)
            // pop up msg
          }
        }
      },
      async save () {
        try {
           if (!this.editedItem.llaCode) {
            alert('Greška! Nije upisana AOP oznaka. Podaci se ne mogu snimiti. Error! ID is not entered. Data is not saved!!')
            return
           }
           if (parseNum(this.editedItem.llaBookValue,2) > parseNum(this.editedItem.llaBuyingValue,2)) {
            alert('Greška! Knji.vrijednost ne može biti veća od nabavne. Podaci se ne mogu snimiti. Error! Booking value can not be bigger then buying value!!')
            return
           }
           if (parseNum(this.editedItem.llaDepreciationPercent,2) > 100) {
            alert('Greška! Stopa otpisa NE može biti veća od 100%. Podaci se ne mogu snimiti. Error! Depreciation percent can not exceed 100%. Data is not saved!!')
            return
           }
           if (parseNum(this.editedItem.llaDepreciationAmountTotal,2) > parseNum(this.editedItem.llaBuyingValue,2)) {
            alert('Greška! Akomulirana vrijednost ne može biti veća od nabavne. Podaci se ne mogu snimiti. Error! Depreciation value  can not bi bigger then buying value. Data is not saved!!')
            return
           }

           let doneOK = false
           if (this.editedIndex > -1) {
              await MainLedgerLLAService.put(this.editedItem)
              doneOK = true
            } else {
              this.editedItem.documentIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-' + this.$store.state.documentActiv + '-' + this.editedItem.llaID
              //this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              //this.editedItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
              this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
              //this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              //this.editedItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : ''
              await MainLedgerLLAService.post(this.editedItem)
              this.initialize()
              this.close()
              doneOK = true
            }
            if (doneOK) {
              this.initialize()
              this.close()
            }
        } catch(err) {
          this.error = err
          
        }
       
      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      },
      localParse(parseNumber) {
        return  parseNum(parseNumber)
      }
    },
  }
</script>