<template>
  <div>
    <v-alert type="error" v-if="error">
      {{error}}
    </v-alert>
    <v-card>
      <v-card-title>
        <span v-if="this.$store.state.language == 'EN'">
          Partners
        </span>
        <span v-if="this.$store.state.language == 'HR'">
          Partneri
        </span>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          v-model="search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        item-key="id"
        :search="search"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
        :items-per-page="50"
        @click:row="navigateTo"
      >
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
          From {{ pageStart }} to {{ pageStop }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import langEn from './PartnerDescEN'
import langHr from './PartnerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"

export default {
  name: 'partnerlistfast',
  data () {
    return {
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      error: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: "",
        databaseDocID: 0
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false
    }
  },
  async mounted () {
    try {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      this.items = this.$store.state.partnerStore ? this.$store.state.partnerStore : []
      this.headers.push(
        {text: this.lang.PartnerName, value: 'partnerName', width: '300px'},
        {text: this.lang.PartnerVATID, value: 'partnerVATID', width: '150px'},
        {text: this.lang.Partneremail, value: 'partneremail', width: '150px'},
        {text: this.lang.partnerStreet, value: 'partnerStreet', width: '250px'},
        {text: this.lang.partnerBuildingNameNumber, value: 'partnerBuildingNameNumber', width: '80px'},
        {text: this.lang.partnerCity, value: 'partnerCity', width: '250px'},
        {text: this.lang.partnerPostcode, value: 'partnerPostcode', width: '80px'},
        {text: this.lang.PartnerPhones, value: 'partnerPhones', width: '150px'},
        {text: this.lang.PartnerBankIBAN, value: 'partnerBankIBAN', width: '150px'},
        {text: this.lang.PartnerDirectorFirst, value: 'partnerDirectorFirst', width: '100px'},
        {text: this.lang.PartnerDirectorLast, value: 'partnerDirectorLast', width: '100px'},
        {text: this.lang.PartnerDirectorEmail, value: 'partnerDirectorEmail', width: '150px'},
        {text: this.lang.PartnerType, value: 'partnerType', width: '50px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'}
      )
      
    } catch (error) {
      this.error = error
    }
  },
  methods: {
    navigateTo (params) {
      this.$store.dispatch('setPartnerid', params.id)
      this.$store.dispatch('setPartnerVATID', params.partnerVATID)
    }
  },
  computed: {

  },
  watch: {

  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
