const partnerEN = {
  PartnerDetails: 'Partner - detalji',
  BasicData: 'Osnovni podaci',
  PartnerName: 'Naziv partnera *',
  PartnerNameShort: 'Naziv skraćeno *',
  PartnerNameNoSpace: 'Naziv BEZ razmaka *',
  PartnerBuildingNameNumber: 'Zgrada/kućni broj',
  PartnerStreet: 'Ulica',
  PartnerCity: 'Grad',
  PartnerPostcode: 'Poštanski broj',
  PartnerCountyRegion: 'Županija',
  PartnerState: 'Država',
  PartnerAdress1: 'Adresa',
  PartnerAdress2: ' ',
  PartnerVATID: 'OIB*',
  Partneremail: 'email',
  PartnerPhones: 'Telefon',
  PartnerBankName: 'Primarna banka',
  PartnerBankAddress: 'Banka adresa',
  PartnerBankIBAN: 'IBAN',
  PartnerBankSWIFT: 'SWIFT',
  PartnerCEO: 'Direktor',
  PartnerDirectorTitle: 'Titula',
  PartnerDirectorFirst: 'Ime',
  PartnerDirectorLast: 'Prezime',
  PartnerDirectorFormer: 'Bivše prezime',
  PartnerDirectorStateResidence: 'Država boravišta',
  PartnerDirectorNationality: 'Nacionalnost',
  PartnerDirectorDateOfBirth: 'Datum rođenja',
  PartnerDirectorBusinessOccupation: 'Zanimanje/radno mjesto',
  PartnerDirectorAdressBuildingNameNumber: 'Zgrada/kućni broj',
  PartnerDirectorAdressStreet: 'Ulica',
  PartnerDirectorCity: 'Grad',
  PartnerDirectorPostcode: 'Poštanski broj',
  PartnerDirectorCountyRegion: 'Županija',
  PartnerDirectorEmail: 'email',
  PartnerDirectorPhone: 'Telefon',
  PartnerDirectorContactOption: 'Najbolji način kontakta',
  PartnerSecretary: 'Osoba za kontakt',
  PartnerSecretaryTitle: 'Titula',
  PartnerSecretaryFirst: 'Ime',
  PartnerSecretaryLast: 'Prezime',
  PartnerSecretaryFormer: 'Bivše prezime',
  PartnerSecretaryStateResidence: 'Država boravka',
  PartnerSecretaryNationality: 'Nacionalnost',
  PartnerSecretaryDateOfBirth: 'Datum rođenja',
  PartnerSecretaryBusinessOccupation: 'Zanimanje/radno mjesto',
  PartnerSecretaryAdressBuildingNameNumber: 'Zgrada/kućni broj',
  PartnerSecretaryAdressStreet: 'Ulica',
  PartnerSecretaryCity: 'Grad',
  PartnerSecretaryPostcode: 'Poštanski broj',
  PartnerSecretaryCountyRegion: 'Županija',
  PartnerSecretaryEmail: 'email',
  PartnerSecretaryPhone: 'Telefon',
  PartnerSecretaryContactOption: 'Najbolji način kontakta',
  PartnerSettings: 'Postavke',
  PartnerInvoiceMemo: 'Tekst na računu',
  PartnerInvoiceForeginMemo: 'Tekst na ino računu',
  PartnerDeliveryNoteMemo: 'Tekst na otpremnici',
  PartnerDeliveryNoteForeginMemo: 'Tekst na ino otpremnici',
  PartnerInvoiceDefDays: 'Račun, broj dana za plaćanje:',
  PartnerInvoicePlace: 'Mjesto računa',
  PartnerInvoiceType: 'Vrsta računa',
  PartnerInvoiceHint: 'Račun slogan',
  PartnerInvoiceSpeed: 'Račun brzina(1-3)',
  PartnerUseRetailPrice: 'Koristi MPC cijene',
  PartnerShowLastSaleBuyer: 'Prikaži cijenu zadnje prodaje za kupca',
  PartnerShowWarehouseState: 'Prikaži stanje skladišta',
  PartnerShowInputPrice: 'Prikaži dobavnu cijenu',
  PartnerVATonPay: 'PDV po naplati',
  PartnerRetail: 'Kranji kupac',
  PartnerDefaultWarehouse: 'Default Skladište',
  PartnerPrinterSize: 'Printer veičina(1-3)',
  PartnerInputFilesPath: 'Staza za ulazne dokumente',
  PartnerOutputFilesPath: 'Staza za izlazne dokumente',
  PartnerTransferDataFilesPath: 'Staza za transfer dokumente',
  PartnerStartInvoiceNumber: 'Početni broj IRE',
  PartnerStartBillNumber: 'Početni broj URE',
  PartnerType: 'Vrsta partnera',
  PartnerStartFrom: 'Aktivan od',
  PartnerActiv: 'Aktivan',
  PartnerLockedCredit: 'Zaključan za fakturiranje',
  PartnerlockedDebit: 'Zaključan za nabavu',
  PartnerErrorVAT: 'Greška!! Partner sa tim OIBom već postoji!',
  PartnerErroremail: 'Greška!! Partner sa tim email-om već postoji!',
  privatePerson: 'Privatna osoba',
  specialCustomer:'Specijalni kupac',
  alertOK1: 'Novi partner je upisan u bazu',
  alertOK2: 'Promjena podataka o partneru je upisana u bazu.',
  alertError1: 'Greska! Partner mora imati OIB, osim ako nije privatni kupac.',
  alertError2: 'Greska! Kod snimanje partnera u bazu',
  otherSettings: 'Ostale postavke'
}

export default partnerEN