<template>
  <div class="task">
    <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
      <v-card>
        <v-card-title>
          {{ documentNameLoc }}
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"
          >

          <template v-slot:[`item.OrderDate`]="{ item }">
              <span>{{ localDate(item.OrderDate) }}</span>
          </template>
          <template v-slot:[`item.FinTotalKn`]="{ item }">
            <span class="title green--text">{{ formatPrice(item.FinTotalKn) }}</span>
          </template>
          <template v-slot:[`item.ExpensesForwardHomeC`]="{ item }">
            <span class="title indigo--text">{{ formatPrice(item.ExpensesForwardHomeC) }}</span>
          </template>
           <template v-slot:[`item.FinPayReturnKn`]="{ item }">
            <span class="title purple--text">{{ formatPrice(item.FinPayReturnKn) }}</span>
          </template>
          <template v-slot:[`item.Finished`]="{ item }">
            <span v-if="item.Finished"><v-icon>mdi-check</v-icon></span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>
  </div>
</template>

<script>
import TravelOrderService from '@/services/TravelOrderService'
import ledgerEN from './travelorderDescEn'
import ledgerHR from './travelorderDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'travelorderlist',
  data () {
    return {
      documentName: '',
      documentNameLoc: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {},
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
      required: (value) => !!value || 'Required.',
      rules: {
        required: value => !!value || '*'
      },
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }

    this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
    this.mainQuery.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2023

    this.saving = true
    const {data} = (await TravelOrderService.index(this.mainQuery))
    this.items = data.travelorders && data.travelorders.length > 0 ? data.travelorders : []
    
    this.saving = false
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    this.headers.push(
      { text: this.lang.TravelOrderId, align: 'left', sortable: true, value: 'TravelOrderId', width: '80px' },
      { text: this.lang.OrderDate, align: 'left', sortable: true, value: 'OrderDate', width: '80px' },
      { text: this.lang.eFirst, align: 'center', value: 'eFirst', width: '100px' },
      { text: this.lang.eLast, align: 'right', sortable: true, value: 'eLast', width: '100px' },
      { text: this.lang.TravelTo, align: 'right', sortable: true, value: 'TravelTo', width: '100px' },
      { text: this.lang.TravelTask, align: 'left', sortable: true, value: 'TravelTask', width: '40px' },
      { text: this.lang.ApprovedByemail, align: 'left', value: 'ApprovedByemail', width: '260px' },
      { text: this.lang.FinTotal, align: 'left', value: 'FinTotalKn', width: '120px' },
      { text: this.lang.ExpensesForwardHome, align: 'left', value: 'ExpensesForwardHomeC', width: '120px' },
      { text: this.lang.FinPayReturn, align: 'left', value: 'FinPayReturnKn', width: '120px' },
      { text: this.lang.Finished, align: 'left', value: 'Finished', width: '80px' },
      { text: 'DbId', value: 'id', width: '60px' }
    )

  },
  methods: {
    formatPrice (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async initAgain() {
      this.items = (await TravelOrderService.index(this.mainQuery)).data.travelorders
    },
    async navigateTo (params) {
      await this.$store.dispatch('seteditItem', 1)
      this.$store.dispatch('setretPath', 'travelorder')
      await this.$store.dispatch('setMainLedgerid', params.id)
      this.$router.push({
        name: 'travelordercreate'
      })
    },
    localDate(dateToFormat) {
       const dateOnly = dayjs(dateToFormat).format('YYYY-MM-DD')
       return dateFormat(dateOnly)
      },
  },
  computed: {
   ...mapGetters({
      myResetList: 'NeedResetList'
    })
  },
  watch: {
    'myResetList' () {
      this.initAgain()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
