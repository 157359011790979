<template>
  <v-row>
   <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
    <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
      <v-card>
        <v-card-title>
          <span v-if="!showPeriod" class="pb-3 mt-3">
            {{lang.WarehouseState2}},{{$store.state.warehousemarkid}}<br />
          </span>
          <v-dialog
            v-model="showPeriod"
            max-width="350px"
          >
            <v-card>
              <v-card-text>
                 <v-row dense>
                  <v-col cols="12" >
                    <span class="headline"> {{lang.WarehouseState2}} {{$store.state.warehousemarkid}} {{lang.WarehouseState3}}</span>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" >
                    <span class="headline"> {{onTime}}</span>
                  </v-col>
                </v-row>
                 <v-divider class="mt-1"></v-divider>
                <v-row >
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" >
                    {{langC.FromDate}}: <input type="date" ref="docdate"  v-model="startDate" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" >
                    {{langC.ToDate}}: <input type="date" v-model="endDate" />
                  </v-col>
                </v-row>
                 <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" >
                    <v-select
                    :items="privGroups"
                    v-model="selectGroup"
                    :label="lang.selectGroup"
                    single-line
                    item-text="GroupName"
                    item-value="id"
                    return-object
                    persistent-hint
                    clearable
                    dense
                  ></v-select>
                  </v-col>
                </v-row>
                 <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" >
                    <v-select
                      v-model="selectedMonth"
                      :items="months"
                      label="Mjesec"
                      clearable
                    ></v-select>
                  </v-col>
                </v-row>
                 <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" >
                    <v-btn
                      color="gray"
                      block
                      @click="selectItems()">
                      {{lang.OnlyZero}}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row >
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" >
                    <v-btn
                      color="gray" block
                      @click="groupTransactions()">
                      {{lang.GroupsTran}}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row >
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" >
                    <v-btn
                      color="gray" block
                      @click="groupInOut(1)">
                      {{lang.grIn}}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row >
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" >
                    <v-btn
                      color="gray" block
                      @click="groupInOut(2)">
                      {{lang.grOut}}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row >
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" >
                    <v-btn
                      color="gray" block
                      @click="downloadFile()">
                      download
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row >
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" > 
                    <v-btn
                      color="gray" block
                      @click="printAna()">
                      {{langC.Print}}
                    </v-btn>
                  </v-col>
                </v-row>

                 <v-divider class="mt-3 mb-3"></v-divider>
                <v-row>
                  <v-col cols="12" >
                    <v-btn color="green" block @click="refreshPeriod()">Refresh</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" >
                    <v-btn color="primary" block @click="switchPeriod()">Zatvori</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                
              </v-card-actions>
            </v-card>
          </v-dialog>
 
          
          <v-row no-gutters v-if="!showPeriod">

            <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1"  >

            </v-col>
              
              <v-col cols="12" sm="2" md="1" xs="1" lg="1" xl="1"  >

              </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1"  >

              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2"  >
                <v-text-field
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1"  >

              </v-col>
            <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1"  >
              <v-btn
                color="primary"
                @click="switchPeriod()">
                Opcije
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"
          >
          <template v-slot:[`item.ItemSuppDate`]="{ item }">
              <span>{{ localDate(item.ItemSuppDate) }}</span>
          </template>
          <template v-slot:[`item.itemPrice`]="{ item }">
              <span>{{ localMoney(item.itemPrice) }}</span>
          </template>
          <template v-slot:[`item.itemPriceWithVAT`]="{ item }">
              <span>{{ localMoney(item.itemPriceWithVAT) }}</span>
          </template>
          <template v-slot:[`item.TotalPlus`]="{ item }">
            <span>{{ localMoney(item.TotalPlus) }}</span>
          </template>
          <template v-slot:[`item.TotalMinus`]="{ item }">
              <span>{{ localMoney(item.TotalMinus) }}</span>
          </template>
          <template v-slot:[`item.TotalPcs`]="{ item }">
              <span>{{ localMoney(item.TotalPcs) }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
          <template v-slot:no-data>
            {{ lang.noDataList }}
          </template>
        </v-data-table>
      </v-card>
      <v-row>
      <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
        <DocumentWarehousestatePrint ref="prnt"/>
      </v-col>
      <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
        <DocumentWarehouseGroupsPrint ref="prnt2"/>
      </v-col>
      <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
        <DocumentWarehouseGroupsInOutPrint ref="prnt3"/>
      </v-col>
    </v-row>
     <v-row v-if="showCard">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <DocumentItemCard :key="refreshCard"/>
      </v-col>
    </v-row>
  </v-col>
 </v-row>
</template>

<script>
// import axios from 'axios'
import DocumentService from '@/services/DocumentService'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dateFormat from '@/snippets/dateFormat'
import moneyFormat from '@/snippets/moneyFormat'
import DocumentWarehousestatePrint from './DocumentWarehouseStatePrint'
import DocumentWarehouseGroupsPrint from './DocumentWarehouseGroupsPrint'
import DocumentWarehouseGroupsInOutPrint from './DocumentWarehouseGroupsInOutPrint'
import DocumentItemCard from './DocumentItemCard'
import DocumentDetailService from '@/services/DocumentDetailService'
import { round } from '@/snippets/allSnippets'
import xlsx from 'json-as-xlsx'
import dayjs from 'dayjs'

export default {
  name: 'documentwarehousestate',
  props: ['fromDate', 'toDate'],
  data () {    
    return {
      items: [],
      itemsLoc: [],
      itemsWH: [],
      documentName: '',
      documentNameLoc: '',
      msg: '',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: '',
        databaseDocID: 0
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
      documentAna: {},
      ana: {},
      privGroups: [],
      selectGroup: {},
      haveGroupId: null,
      showCard: false,
      refreshCard: 0,
      disableGroup: false,
      onTime: '',
      showPeriod: false,
      months: [1,2,3,4,5,6,7,8,9,10,11,12],
      selectedMonth: null,
      startDate: null,
      endDate: null,
      dialog1: false,
      dialog2: false,
      modal1: false,
      modal2: false,
      all: {},
      grTotalIn: 0,
      grTotalOut: 0,
      grTotalSum: 0,
      groups: [],
      groupin: [],
      groupout: []
    }
  },
  mounted () {
    this.itemsLoc = this.$store.state.itemStore ? this.$store.state.itemStore : []
    // do analysis from database
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    this.onTime = dayjs([this.$store.state.businessYear]).startOf('year').format('DD.MM.YYYY') + '-' + dayjs(new Date()).format('DD.MM.YYYY') + ', ' + dayjs(new Date()).format('HH:mm:ss')
    //this.items = this.$store.state.warehouseState ? this.$store.state.warehouseState : []
    this.headers.push(
      {text: this.lang.ItemID, value: 'itemID', width: '90px'},
      {text: this.lang.ItemName, value: 'itemName', width: '250px'},
      {text: this.lang.ItemPcsPlus, value: 'TotalPlus', align: 'right', type: 'decimal', width: '100px'},
      {text: this.lang.ItemPcsMinus, value: 'TotalMinus', align: 'right', type: 'decimal', width: '100px'},
      {text: this.lang.WarehouseState, value: 'TotalPcs', align: 'right', type: 'decimal', width: '100px'},
      {text: this.lang.ItemPrice, value: 'itemPrice', align: 'right', width: '90px'},
      {text: this.lang.ItemPriceWithVAT, value: 'itemPriceWithVAT', align: 'right', width: '90px'},
      {text: this.lang.GroupName, value: 'GroupName', width: '250px'},
      {text: this.lang.Supplier, value: 'ItemSuppCode', width: '250px'},
      {text: this.lang.id, value: 'id', width: '50px'}
    )
    this.privGroups = this.$store.state.groupStore ? this.$store.state.groupStore : []
    this.$store.dispatch("setGroupid", null)
    if (this.selectGroup && this.selectGroup.id) {
      this.$store.dispatch("setGroupid", this.selectGroup.id)
    }
    if (this.$store.state.companyid) {
      this.documentAna.CompanyId = this.$store.state.companyid
    }
    if (this.$store.state.businessYear) {
      this.documentAna.BusinessYear = this.$store.state.businessYear
    }
    const fDate = dayjs([this.documentAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
    const tDate = dayjs([this.documentAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
  
    this.documentAna.WareHouseId = this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1

    this.startDate = this.fromDate ? this.fromDate : fDate
    this.endDate = this.toDate ? this.toDate : tDate

    this.documentAna.docFromDate = this.fromDate ? this.fromDate : fDate
    this.documentAna.docToDate = this.toDate ? this.toDate : tDate

    this.initData(1)
  },
  methods: {
    switchPeriod() {
      this.showPeriod = !this.showPeriod
    },
    refreshPeriod(){
      this.showPeriod = false
      if (this.startDate) {
        this.documentAna.docFromDate = this.startDate
      }
      if (this.endDate) {
        this.documentAna.docToDate = this.endDate
      }
      this.selectGroup = null
      this.$store.dispatch("setGroupid", null)
      this.initData(1)
    },
    async initData(type) {
      try {
        this.localError = false
        this.error = null
        this.Total1 = 0
        this.Total2 = 0
        this.Total3 = 0
        this.Total4 = 0
        this.Total5 = 0
        this.Total6 = 0
        this.Total7 = 0
        this.Total8 = 0
        this.Total9 = 0
        this.Total10 = 0
        this.Total21 = 0
        this.Total31 = 0
        
        this.haveGroupId = null
        
        if (this.$store.state.groupid && this.itemsWH) {
         //this.documentAna.groupid = this.$store.state.groupid
          this.items = this.itemsWH.filter(item => item.GroupId === this.$store.state.groupid.toString())
          this.haveGroupId = this.$store.state.groupid
          return
        }
        if (this.disableGroup && this.itemsWH) {
          this.items = [...this.itemsWH]
          this.disableGroup = false
          return
        }
        this.items.splice(0, this.items.length)
        this.itemsWH.splice(0, this.itemsWH.length)
        this.groupin.length = 0
        this.groupout.length = 0

        this.onTime = this.localDate(this.documentAna.docFromDate) + '-' + this.localDate(this.documentAna.docToDate)

        //console.log('this.documentAna', this.documentAna)

        this.saving = true
        const res = (await DocumentService.documentwarehousestate(this.documentAna)).data
        const tempItems = res.document? res.document : []
        this.groupin = res.whgroupin ? res.whgroupin[0] : []
        this.groupout = res.whgroupout ? res.whgroupout[0] : []
        let iid = 0
        const lenItems = this.itemsLoc.length

        if (tempItems[0]) {
          tempItems[0].map(async(item) => {
            const newEntry = {}
            newEntry.itemID = item.itemID ? item.itemID.trim() : ''
            //const findIt = this.itemsLoc.find( itemL => itemL.itemID.trim() === item.itemID)
            let findIt = {}
            
            for(let i = 0; i < lenItems; i++) {
               
              if(this.itemsLoc[i].itemID === item.itemID){
                findIt = this.itemsLoc[i]
                break
              }
            }
            // umjesto find, treba probati sa foor loop jer je strasno brz, evo primjer

            // const numbers = ['one', 'two', 'three', 'five', 'eight', 'thirteen']

            // let item

            // for (let i = 0; i < numbers.length; i++) {
            //   if (numbers[i] === 'five') {
            //     item = numbers[i]
            //     break
            //   }
            // }

            // console.log(item) // 'five'

            newEntry.itemName = findIt.itemName ? findIt.itemName : ''
            newEntry.GroupName = findIt.GroupName ? findIt.GroupName : ''
            newEntry.GroupId = findIt.GroupId ? findIt.GroupId.toString() : ''
            const itemPrice = findIt.itemPrice ? parseFloat(findIt.itemPrice) : 0
            const itemPriceWithVAT = findIt.itemPriceWithVAT ? parseFloat(findIt.itemPriceWithVAT) : 0
            newEntry.itemVAT1Percentage = findIt.itemVAT1Percentage ? parseFloat(findIt.itemVAT1Percentage) : ''
            newEntry.itemPrice = itemPrice
            newEntry.itemPriceWithVAT = itemPriceWithVAT
            newEntry.ItemSuppCode = findIt.ItemSuppCode ? findIt.ItemSuppCode : ''
            newEntry.TotalPlus = parseFloat(item.TotalPlus ? item.TotalPlus : 0)
            newEntry.TotalMinus = parseFloat(item.TotalMinus ? item.TotalMinus : 0)
            const statePcs = parseFloat(item.TotalPlus ? item.TotalPlus : 0) - parseFloat(item.TotalMinus ? item.TotalMinus : 0)
            newEntry.TotalPcs = statePcs
            newEntry.TotalBase = round((itemPrice * statePcs), 2)
            newEntry.TotalWithVAT = round((itemPriceWithVAT * statePcs), 2)
            let saveItem = true
            // if have group id filter
            if (this.haveGroupId) {
              saveItem = false
              if (this.haveGroupId === findIt.GroupId) {
                saveItem = true
              }
            }
            if (findIt.itemService === true) {
              saveItem = false
            }
            if (saveItem) {
              if (type === 1) {
                if (newEntry.TotalPcs !== 0) {
                  this.Total1 += parseFloat(item.TotalPlus ? item.TotalPlus : 0)
                  this.Total2 += parseFloat(item.TotalMinus ? item.TotalMinus : 0)
                  iid += 1
                  newEntry.id = iid
                  await this.itemsWH.push(newEntry)
                }
              }
              if (type === 2) {
                if (newEntry.TotalPcs === 0) {
                  this.Total1 += parseFloat(item.TotalPlus ? item.TotalPlus : 0)
                  this.Total2 += parseFloat(item.TotalMinus ? item.TotalMinus : 0)
                  iid += 1
                  newEntry.id = iid
                  await this.itemsWH.push(newEntry)
                }
              }            
            }
          })


        }
        this.typeAna = 4
        this.items= [...this.itemsWH]
        this.saving = false
      } catch(err) {
        this.error = 'Greska u konekciji na bazu' + err
      }
    },
    selectItems() {
      this.initData(2)
    },
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
    async groupInOut(type) {
      if (!this.documentAna.groupid ) {
        alert('Grupa NIJE odabrana!!!')
        return
      }
      if (this.startDate) {
        this.documentAna.docFromDate = this.startDate
      }
      if (this.endDate) {
        this.documentAna.docToDate = this.endDate
      }
      console.log('>>>>this.documentAna', this.documentAna)
      this.documentAna.documentSide = type
      const res = await DocumentDetailService.salesana(this.documentAna)
      const src = res.data.document ? res.data.document : []
      if (src.length > 0) {
        const newObj = {}
        this.ana = {...newObj}
        this.ana = {...this.documentAna}
        this.ana.docFromDate =  this.localDate(this.documentAna.docFromDate)
        this.ana.docToDate =  this.localDate(this.documentAna.docToDate)
        this.ana.head1 =  type === 1 ? 'Ulaz za grupu' : 'Izlaz za grupu'
        this.ana.head2 =  this.documentAna.GroupName
        this.ana.GroupName = this.documentAna.GroupName
        this.ana.documentSide = type
        this.ana.items = src ? src : []
        this.$store.dispatch('setPrintObj', this.ana)
        this.$refs.prnt3.print()
      }

    },
    groupTransactions() {
      let nr =0
      const emAr = []
      this.groups = [...emAr]
      const res = Array.from(this.itemsWH.reduce(
          (m, {GroupId, TotalPcs}) => m.set(GroupId, (m.get(GroupId) || 0) + (TotalPcs,2)), new Map
        ), ([GroupId, TotalPcs]) => ({GroupId, TotalPcs}));
      if (res && res.length > 0) {
        res.map(gr => {
          this.grTotalIn = 0
          this.grTotalOut = 0
          this.grTotalSum = 0
          let grTotalInM = 0
          let grTotalOutM = 0
          let GroupName = ''
          const onlyGr = this.itemsWH.filter(group => group.GroupId === gr.GroupId)
          if (onlyGr && onlyGr.length > 0) {
            GroupName = onlyGr[0].GroupName ? onlyGr[0].GroupName : 0
            onlyGr.map(oneGr => {
              this.grTotalIn += oneGr.TotalPlus
              this.grTotalOut += oneGr.TotalMinus
            })
          }
  
          const onlyGrIn = this.groupin.filter(grin => grin.itemGroup === gr.GroupId)
          if (onlyGrIn && onlyGrIn.length > 0) {
            grTotalInM = round(( onlyGrIn[0].TotalMPlus), 2)
          }
          const onlyGrOut = this.groupout.filter(grout => grout.itemGroup === gr.GroupId)
          if (onlyGrOut && onlyGrOut.length > 0) {
              grTotalOutM = round(( onlyGrOut[0].TotalMMinus), 2)
          }
          nr += 1
          const newGr = {}
          newGr.id = nr
          newGr.GroupId = gr.GroupId
          newGr.GroupName = GroupName
          newGr.grTotalIn = round(this.grTotalIn,3)
          newGr.grTotalOut = round(this.grTotalOut,3)
          newGr.grTotalSum = round(this.grTotalIn - this.grTotalOut,3)
          newGr.grTotalInM = round(grTotalInM,2)
          newGr.grTotalOutM = round(grTotalOutM, 2)
          newGr.grTotalSumM = round(grTotalInM - grTotalOutM,2)
          this.groups.push(newGr)

        })
      }
      const newObj = {}
      this.ana = {...newObj}
      this.ana = {...this.documentAna}
      this.ana.docFromDate =  this.localDate(this.documentAna.docFromDate)
      this.ana.docToDate =  this.localDate(this.documentAna.docToDate)
      this.ana.documentName = 'Promet skladišta po grupama'
      this.ana.items = this.groups ? this.groups : []
      this.$store.dispatch('setPrintObj', this.ana)
      this.$refs.prnt2.print()
    },
    navigateTo (item) {
      this.refreshCard += 1
      if (confirm('Želite li karticu artikla: ' + item.itemName + ' ?')) {
        this.$store.dispatch('setSelectedItemId', null)
        this.$store.dispatch('setDocumentAna', null)
        this.showCard = false
        this.$store.dispatch('setSelectedItemId', item.itemID)
        const haveItem = {}
        if (this.$store.state.companyid) {
          haveItem.CompanyId = this.$store.state.companyid
        }
        if (this.$store.state.businessYear) {
          haveItem.BusinessYear = this.$store.state.businessYear
        }
        if (item.itemID) {
          haveItem.itemID = item.itemID
        }
        haveItem.docFromDate = dayjs([haveItem.BusinessYear]).startOf('year').format('YYYY-MM-DD')
        haveItem.docToDate = dayjs([haveItem.BusinessYear]).endOf('year').format('YYYY-MM-DD')
        this.$store.dispatch('setDocumentAna', haveItem)
        this.showCard = true
      } else {
        this.$store.dispatch('setSelectedItemId', null)
        this.$store.dispatch('setDocumentAna', null)
        this.showCard = false
      }
    },
    async printAna() {
      const newObj = {}
      this.ana = {...newObj}
      this.ana = {...this.documentAna}
      this.ana.docFromDate =  this.localDate(this.documentAna.docFromDate)
      this.ana.docToDate =  this.localDate(this.documentAna.docToDate)
      if (this.selectGroup && this.selectGroup.GroupName) {
        this.ana.GroupName = this.selectGroup.GroupName
      }

      this.ana.items = this.items ? this.items : []
      await this.$store.dispatch('setPrintObj', this.ana)
      await this.$refs.prnt.print()
    },
    downloadFile () {
        const currDate = dayjs().format('DD_MM_YYYY_HH_MM')  
        const sheetNm = 'StanjeSkl' + '_' + currDate
        let data = [
        {
          sheet: sheetNm,
          columns: [
            { label: 'Šifra', value: 'itemID' },
            { label: 'Naziv', value: 'itemName' },
            { label: 'Količina zaduži', value: 'TotalPlus' },
            { label: 'Količina razduži', value: 'TotalMinus' },
            { label: 'Stanje skladišta', value: 'TotalPcs' },
            { label: 'Cijena', value: 'itemPrice' },
            { label: 'Cijena sa PDVom', value: 'itemPriceWithVAT' },
            { label: 'Ulaz na skladište', value: 'ItemSuppCode' },
            { label: 'Stanje', value: 'TotalBase' },
            { label: 'Stanje sa PDVom', value: 'TotalWithVAT' },
            { label: 'Grupa', value: 'GroupName' },
          ],
          content: this.items
        }
      ]
          
      let settings = {
          fileName: 'StanjeSkladista_' + currDate,
          extraLength: 3, // A bigger number means that columns will be wider
          writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
        }
      // const settings = {
      //   sheetName: 'StanjeSkladista',
      //   fileName: 'StanjeSkladista_' + currDate
      // }
      xlsx(data, settings)
    }
  },
  computed: {
    formatDate1() {
      return this.startDate
        ? dateFormat(this.startDate)
        : "";
    },
    formatDate2() {
      return this.endDate
        ? dateFormat(this.endDate)
        : "";
    },
  },
  watch: {
    'selectGroup' () {
      console.log('Select group', this.selectGroup)
      if (!this.selectGroup) {
        this.$store.dispatch("setGroupid", null)
        delete this.documentAna.groupid
        delete this.documentAna.itemGroup
        delete this.documentAna.GroupName
        this.disableGroup = true
      }
      if (this.selectGroup && this.selectGroup.id) {
        this.$store.dispatch("setGroupid", this.selectGroup.id)
        this.documentAna.groupid = this.selectGroup.id
        this.documentAna.itemGroup = this.selectGroup.id
        this.documentAna.GroupName = this.selectGroup.GroupName
      }
      this.initData(1)
    },
    'selectedMonth' () {
     if  (this.selectedMonth) {
        let date = new Date(), y = date.getFullYear(), m = this.selectedMonth - 1;
        let firstDay = new Date(y, m, 1);
        let lastDay = new Date(y, m + 1, 0);

        this.startDate = dayjs(firstDay).format('YYYY-MM-DD')
        this.endDate = dayjs(lastDay).format('YYYY-MM-DD')
      }
      if (!this.selectedMonth) {
        this.startDate = dayjs([this.documentAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
        this.endDate = dayjs([this.documentAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
      }
    },
  },
  components: {
    DocumentWarehousestatePrint,
    DocumentItemCard,
    DocumentWarehouseGroupsPrint,
    DocumentWarehouseGroupsInOutPrint
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>


