const docEN = {
  DigitalAr: 'Digital Archive',
  ShortDesc: 'Short Desc',
  Desc: 'Description',
  Name: 'Name',
  Saved : 'Saved',
  Actions: 'Delete',
  Docs: 'docs',
  Empty: 'empty',
  SavedDoc: 'Scaned doc is saved',
  CannotFind: 'Sorry, document is not defined! Can not open.',
  DigitalArList: 'Digital Archive List',
  typeDoc: 'Type of Doc',
  DateOpen: 'Created',
  documentId: 'Document number',
}

export default docEN