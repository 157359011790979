<template>
<v-form 
ref="form"
v-model="valid"
lazy-validation> 
  <v-container class="grey lighten-5">
    <v-row no-gutters justify="center">
      <v-col xs="12" sm="12" lg="9" md="6" xl="6" class="pa-0">

            <v-progress-circular v-if="this.saving"
              indeterminate
              color="green"
            ></v-progress-circular>

            <v-row no-gutters class="pl-2">
                <h2>
                  <span v-if="this.$store.state.editItem  === 0">
                    {{langC.CreateNew}} {{ documentNameLoc }}
                  </span>
                  <span v-if="this.$store.state.editItem === 1">
                    {{langC.Edit}} {{ documentNameLoc }} {{document.documentId}}
                  </span>                  
                </h2>
            </v-row>
            <br>

            <!-- <v-divider class="mt-1"></v-divider> -->
            <v-card
            class="pa-2 ma-1"
                outlined
              >

              <!-- <v-toolbar class="pb-2" color="blue grey lighten-5" height="30px" elevation>
                <v-toolbar-title class="bg-purple-darken-2 text-center" >Datum </v-toolbar-title>
              </v-toolbar> -->
              <!-- <v-card-title class="ma-0 pa-0">
                <v-toolbar color="indigo darken-1" dark> 
                 Datum
                </v-toolbar>
              </v-card-title> -->

              <v-row no-gutters>
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" class="gray">
                  <div class="text-h6 pb-2">
                    <span>Datum</span>
                  </div>
                </v-col>
              </v-row>
            

            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6" >
                {{lang.DocumentDate}}: <input type="date" ref="docdate"  v-model="document.documentDate" />
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="2" lg="6" xl="6" >
                {{lang.documentTime}}: <input type="time" v-model="document.documentTime" />
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                {{lang.StartDate}}: <input type="date" v-model="document.documentInvoiceStartDate" />
              </v-col>
              <v-col cols="3" xs="3" sm="3" md="3" lg="2" xl="2" >
                 Br.dana:<input type="number" min="1" max="365" step="1" v-model="nrDays">
              </v-col>
              <v-col cols="9" xs="9" sm="9" md="9" lg="4" xl="4">
                {{lang.DeadLine}}: <input type="date" v-model="document.documentInvoiceEndDate" />
              </v-col>
            </v-row> 
            </v-card>
            <!-- <v-divider class="mt-1 mb-3"></v-divider> -->

              <v-card
                class="pa-2 ma-1"
                outlined
                tile
              >
              <v-row no-gutters>
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <div class="text-h6 pb-2">
                    <span>Partner</span>
                  </div>
                </v-col>
              </v-row>
            <v-row no-gutters>
                <v-col cols="12" sm="5" md="5" xs="12" lg="5" xl="5" class="pr-3">
                      <v-autocomplete
                        v-model="selectPa"
                        :items="partners"
                        density="comfortable"
                        :label="lang.SelectPartner"
                        return-object
                        clearable
                        item-text="partnerName"
                        item-value="id"
                      ></v-autocomplete>
                </v-col>
                <v-col cols="8" xs="8" sm="8" md="8" lg="3" xl="3">
                    <v-text-field class="pr-2"
                      :label="lang.VATID"
                      v-model="document.partnerVATID"
                      :rules="[rules.required]"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6"  lg="2" xl="2" >
                  
                   
                </v-col>
                <v-col cols="3" sm="3" md="3" xs="3" lg="2" xl="2">
                  <p class="text-left">
                      <v-btn text color="primary" class="pt-3" @click="findByVATID()">{{langC.checkVATID}}</v-btn>
                    </p>
                   
                  <!-- <v-checkbox
                      v-model="findpartnerbyid"
                      class="shrink mr-0 mt-0 ml-3"
                    >
                      <template v-slot:label>
                        <span style="font-size: 12px">Traži po ID</span>
                      </template>
                    </v-checkbox> -->
                   <!-- <v-checkbox>
                    <template v-slot:label>
                        <span style="font-size: 12px">Traži po ID</span>
                    </template>
                    v-model="findpartnerbyid"
                    class="shrink mr-0 mt-0"
                  </v-checkbox>  -->
                   <!-- <v-checkbox
                     <template v-slot:label>
                      <span style="font-size: 12px">Traži po ID</span>
                   </template>
                    v-model="findpartnerbyid"
                  ></v-checkbox> -->
                </v-col>
                <!-- <v-col cols="3" sm="3" md="3" xs="3" lg="2" xl="2">
                      <v-btn text color="primary" @click="findByVATID()">{{langC.checkVATID}}</v-btn>
                </v-col> -->
             </v-row>

            <v-row no-gutters>
                <v-col cols="12" xs="11" sm="5" md="5"  lg="5" xl="5" >
                    <v-text-field
                      class="pr-3"
                      :label="lang.PartnerName"
                      v-model="document.partnerName"
                      :rules="[rules.required]"
                    ></v-text-field> 
                </v-col>
                <v-col cols="8" sm="8" md="8" xs="8" lg="3" xl="3">
                    <v-text-field class="pr-3"
                      :label="lang.Street"
                      v-model="document.partnerStreet"
                    ></v-text-field> 
                </v-col>
                <v-col cols="3" xs="3" sm="3" md="3"  lg="1" xl="1">
                    <v-text-field
                      :label="lang.BuildingNameNumber"
                      v-model="document.partnerBuildingNameNumber"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" xs="0" sm="1" md="1"  lg="1" xl="1">

                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="5" md="5" xs="12" lg="5" xl="5">
                    <v-text-field class="pr-2"
                    :label="lang.CompanyEmail"
                    v-model="document.partneremail"
                    ></v-text-field>
                </v-col>
                
                <v-col cols="8" sm="8" md="8" xs="8" lg="3" xl="3">
                    <v-text-field class="pr-3"
                      :label="lang.City"
                      v-model="document.partnerCity"
                    ></v-text-field> 
                </v-col>
                <v-col cols="3" sm="3" md="3" xs="3" lg="1" xl="1">
                    <v-text-field
                      :label="lang.Postcode"
                      v-model="document.partnerPostcode"
                    ></v-text-field> 
                </v-col>
                <v-col cols="3" sm="3" md="3" xs="3" lg="1" xl="1">

                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="2" xl="2">
                  <p class="text-left">
                    <v-btn text color="primary" class="pt-3" @click="addeditPartner()">{{lang.PartnerSave}}</v-btn>
                  </p>
                  <!-- <span v-if="privateP">
                    <p class="text-right">
                      <v-btn text color="primary" class="pt-3" @click="updatePartner()">Update partner</v-btn>
                    </p>
                  </span> -->
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="2" xs="2" sm="2" md="2" lg="1" xl="1">
                  <v-text-field class="pr-2"
                    :label="lang.SecretaryTitle"
                    v-model="document.partnerSecretaryTitle"
                  ></v-text-field>
                </v-col>

                <v-col cols="5" sm="5" md="5" xs="5" lg="2" xl="2">
                  <v-text-field class="pr-3"
                    :label="lang.SecretaryFirst"
                    v-model="document.partnerSecretaryFirst"
                  ></v-text-field>
                </v-col>

                <v-col cols="5" sm="5" md="5" xs="5" lg="2" xl="2">
                  <v-text-field class="pr-3"
                    :label="lang.SecretaryLast"
                    v-model="document.partnerSecretaryLast"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="6" lg="3" xl="3">
                  <v-text-field class="pr-3"
                    :label="lang.SecretaryEmail"
                    v-model="document.partnerSecretaryEmail"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="6" lg="2" xl="2">
                  <v-text-field class="pr-3"
                    :label="lang.SecretaryPhone"
                    v-model="document.partnerPhones"
                  ></v-text-field>
                </v-col>
 
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                   <v-btn text color="primary" class="pt-3" @click="showAllPartners()">{{langC.Partners}}</v-btn>
                </v-col>
              </v-row>
              </v-card>
                <v-card
                class="pa-2 ma-1"
                outlined
                tile
              >
              <v-row no-gutters>
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <div class="text-h6 pb-2">
                    <span>Banka</span>
                  </div>
                </v-col>
              </v-row>
              <!-- <v-divider class="mt-1 mb-2"></v-divider> -->

              <v-row class="pt-3">
                <v-col cols="12" sm="3" md="3" xs="6" lg="3" xl="3" class="mr-2">
                  <v-autocomplete
                    :items="banks"
                    v-model="selectBank"
                    label="Ime banke"
                    item-text="BankName"
                    item-value="id"
                    return-object
                    clearable
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <v-select :label="lang.HomeCurrency" :items="currnecyType" v-model="document.documentHomeCurrency" value="document.documentHomeCurrency" class="pr-2"></v-select>
                </v-col>

                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="py-4" v-if="!importPurchase && !showOnlyEUR">
                  <v-text-field
                      class="mr-2"
                      dense
                      :label="lang.ExchangeRate"
                      v-model.number="document.documentExRate"
                      type="number"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-1" v-if="!showOnlyEUR">
                  <v-select :label="lang.SecodnaryCurrency" :items="currnecyType" v-model="document.documentForeignCurrency" value="document.documentForeignCurrency"></v-select>
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="py-4">
                  <span v-if="have8">
                    <v-text-field
                      class="pr-3"
                      dense
                      :label="lang.documentTotalAmountPayInAdvance"
                      v-model.number="document.documentTotalAmountPayInAdvance"
                      type="number"
                    ></v-text-field>
                  </span>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2" v-if="have5">
                  <v-select :label="lang.ServiceStatus" :items="statusType" v-model="document.status" value="document.status"></v-select>
               </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-2" v-if="$store.state.documentActiv === 'ServiceNotes'">
                  <v-select :label="lang.ServiceDoneBy" :items="users" 
                    item-text="email"
                    item-value="email" 
                    v-model="document.deliveryCurrier"></v-select>
                </v-col> 
               <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
                  <v-checkbox
                    :label="lang.exportDoc"
                    v-model="document.exportDoc"
                  ></v-checkbox>
                </v-col>
                 <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
                  <v-checkbox
                    :label="lang.PPO"
                    v-model="document.PPO"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0" v-if="documentName === 'ContractSales'">
                  <v-checkbox
                    :label="lang.validContract"
                    v-model="document.validContract"
                  ></v-checkbox>
                </v-col>
              </v-row>
              </v-card>

              <v-row no-gutters v-if="document.exportDoc && have6"> 
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-text-field class="pr-3"
                    :label="lang.UCDnumber"
                    v-model="document.UCDnumber"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-3">
                  <v-dialog
                    ref="dialogUCD"
                    persistent
                    v-model="modalUCD"
                    width="290px"
                    :return-value.sync="locUCDdate"
                    :retain-focus="false"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="locUCDdate"
                      :label="lang.UCDdate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                    <v-date-picker v-model="locUCDdate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modalUCD = false">{{langC.Cancel}}</v-btn>
                      <v-btn color="primary" @click="$refs.dialogUCD.save(locUCDdate)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field class="pr-3"
                    :label="lang.importAmount"
                    v-model.number="document.importAmount"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field class="pr-3"
                    :label="lang.exRate"
                    v-model.number="document.exRate"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-text-field class="pr-3"
                    :label="lang.domecilAmount"
                    v-model.number="document.domecilAmount"
                    type="number"
                    disabled
                  ></v-text-field>
                </v-col>
            </v-row>

            <v-row no-gutters v-if="document.exportDoc && have6"> 

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field class="pr-3"
                    :label="lang.customAmount"
                    v-model.number="document.customAmount"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field class="pr-3"
                    :label="lang.bankExp"
                    v-model.number="document.bankExp"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field class="pr-3"
                    :label="lang.forwarderExp"
                    v-model.number="document.forwarderExp"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field class="pr-3"
                    :label="lang.transportExp"
                    v-model.number="document.transportExp"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field class="pr-3"
                    :label="lang.otherExp"
                    v-model.number="document.otherExp"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  {{totalFullExp}}
                </v-col>
            </v-row>


            <v-row no-gutters v-if="have6"> 
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-text-field class="pr-3"
                    :label="lang.supplierDocument"
                    v-model="document.supplierDocument"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-text-field class="pr-3"
                    :label="lang.supplierDocumentNr"
                    v-model="document.supplierDocumentNr"
                  ></v-text-field>
                </v-col>

                <v-col xs="4" class="pr-3">
                  <v-dialog
                    ref="dialog5"
                    persistent
                    v-model="modal5"
                    width="290px"
                    :return-value.sync="document.supplierDocumentDate"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="document.supplierDocumentDate"
                      :label="lang.supplierDocumentDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                    <v-date-picker v-model="document.supplierDocumentDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal5 = false">{{langC.Cancel}}</v-btn>
                      <v-btn color="primary" @click="$refs.dialog5.save(document.supplierDocumentDate)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-text-field class="pr-3"
                    :label="lang.BookID"
                    v-model="document.BookID"
                  ></v-text-field>
                </v-col>
              </v-row>



              <v-card
                class="pa-2 ma-1"
                outlined
                tile
              >
              <v-row no-gutters>
                <v-col cols="12" sm="12" md="12" xs="12" lg="8" xl="8">
                  <div class="text-h6 pb-2">
                    <v-btn text color="primary" @click="showNoteOn()" class="pb-3 pl-0">Bilješke <v-icon v-if="!showNote">mdi-chevron-down</v-icon><v-icon v-if="showNote">mdi-chevron-up</v-icon></v-btn>
                  </div>
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-2" v-if="$store.state.documentActiv !== 'ServiceNotes' && !have7">
                    <v-select
                      label="Status"
                      :items="docStatus"
                      v-model="selectDS"
                      item-text="docStatus"
                      item-value="id"
                      single-line
                      return-object
                      persistent-hint
                      clearable
                      dense
                    ></v-select>
                </v-col>

              </v-row>

              <div v-if="showNote">

              <v-row no-gutters v-if="have1"> 
                <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="ma-0 pa-0" > 
                  <v-btn text color="primary" @click="showOn1()" class="pb-3 pl-0">{{lang.Description4show}} <v-icon v-if="!show1">mdi-chevron-down</v-icon><v-icon v-if="show1">mdi-chevron-up</v-icon></v-btn>
                  <v-text-field
                      dense
                      :label="lang1"
                      v-model="document.documentDescription4"
                      v-if="show1"
                    ></v-text-field> 
                </v-col>
              </v-row>

             <v-row no-gutters v-if="have2">
                <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="ma-0 pa-0">
                  <v-btn text color="primary" @click="showOn2()" class="pb-3 pl-0">{{lang.Description1show}} <v-icon v-if="!show2">mdi-chevron-down</v-icon><v-icon v-if="show2">mdi-chevron-up</v-icon></v-btn>
                  <v-textarea
                      :label="lang2"
                      v-model="document.documentDescription1"
                      v-if="show2"
                      dense
                    ></v-textarea>
                </v-col>
              </v-row>

              <v-row no-gutters v-if="have3">
                <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="ma-0 pa-0">
                  <v-btn text color="primary" @click="showOn3()" class="pb-3 pl-0">{{lang.Description5show}} <v-icon v-if="!show3">mdi-chevron-down</v-icon><v-icon v-if="show3">mdi-chevron-up</v-icon></v-btn>
                  <v-textarea
                      :label="lang3"
                      v-model="document.documentDescription5"
                      v-if="show3"
                      dense
                    ></v-textarea>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
                  <v-row no-gutters v-if="show3">
                    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" v-if="showDD" class="mt-9">
                      <v-select
                        v-model="selectDD"
                        :items="docDesc"
                        item-text="docsmall"
                        item-value="description"
                        label="Napomena"
                        persistent-hint
                        return-object
                        single-line
                        clearable
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="show3">
                    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                      <v-btn text color="primary" @click="addOpenStatement()" v-if="show3">{{lang.OpenStatement}}</v-btn>
                    </v-col>
                  </v-row>
                   
                </v-col>

              </v-row>

             <v-row no-gutters v-if="have4">
                <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
                  <v-textarea
                      class="ma-0 pa-0"
                      :label="lang4"
                      v-model="document.documentDescription3"
                      v-if="show4"
                    ></v-textarea>
                    <v-btn text color="primary" @click="showOn4()" class="pb-3 pl-0" v-if="!show4">{{lang.Description3show}} </v-btn>    
                </v-col>
              </v-row>  
              <v-row no-gutters v-if="have7">
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-2">
                    <v-select
                      label="Status naloga"
                      :items="productiontypes"
                      v-model="selectPT"
                      value="productiontypes.value"
                      single-line
                      return-object
                      persistent-hint
                      clearable
                      dense
                    ></v-select>
                </v-col>                    
              </v-row>

              </div>
              <!-- <v-row no-gutters v-if="!have7">
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-2" v-if="$store.state.documentActiv !== 'ServiceNotes'">
                    <v-select
                      label="Status"
                      :items="docStatus"
                      v-model="selectDS"
                      item-text="docStatus"
                      item-value="id"
                      single-line
                      return-object
                      persistent-hint
                      clearable
                      dense
                    ></v-select>
                </v-col>
                  
              </v-row> -->
              </v-card>
  
          {{error}}
          <div class="pink darken-2" v-html="error" />
          <v-snackbar
            v-model="snackbar"
            bottom
            :timeout="timeout"
            :color="color"
            >
            {{ text }}
          </v-snackbar>
          
          <v-btn
            class="primary mr-1"
            color="success"
             :disabled="!valid"
            @click="validate">
            <span v-if="this.$store.state.editItem  === 0">
              {{langC.CreateNew}} {{ documentNameLoc }}
            </span>
            <span v-if="this.$store.state.editItem === 1">
              {{langC.Save}} {{ documentNameLoc }} {{document.documentId}}
            </span>
          </v-btn>
          <v-btn
            color="green"
            @click="documentlist">
            {{langC.Back}}
          </v-btn>

      </v-col>
    </v-row>

    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.showPartners">
          <PartnerListFast />
        </div>
      </v-col>
    </v-row>
  </v-container>
</v-form>
</template>

<script>
import dayjs from 'dayjs'
import PartnerService from '@/services/PartnerService'
import BankAccountService from '@/services/BankAccountService'
import UserService from '@/services/UserService'
import DocumentService from '@/services/DocumentService'
import DocumentDetailService from '@/services/DocumentDetailService'
import DocumentStatusService from '@/services/DocumentStatusService'
import DocDescService from '@/services/DocDescService'
import MainLedgerDetailService from '@/services/MainLedgerDetailService'
import PartnerListFast from '../Partner/PartnerListFast'
import { testLastUpdate, newUpdates } from '@/services/TestLastUpdate'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dateFormat from '@/snippets/dateFormat'
import { addUpdatePartner} from '@/snippets/partnerCheck'
import { defDocument } from './documentHelper'
import { round, parseNum, moneyFormat } from '@/snippets/allSnippets'
import { mapGetters } from 'vuex'

export default {
  name: 'documentcreate',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      vrijeme: "14:00",
      saving: false,
      valid: true,
      lazy: false,
      rules: {
        required: value => !!value || '*'
      },
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      menu: false,
      menu2: false,
      menu3: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      date1: null,
      date2: null,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      modal5: false,
      modalT: false,
      modalUCD: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      dialog5: false,
      dialogT: false,
      dialogUCD: false,
      have1: true, 
      have2: true,
      have3: true,
      have4: true,
      have5: false,
      have6: false,
      have7: false,
      have8: false,
      lang1: '',
      lang2: '',
      lang3: '',
      lang4: '',
      lang5: '',
      msg: '',
      documentName: '',
      documentNameLoc: '',
      document: {},
      error: null,
      localError: false,
      newDocument: {},
      confpassword: '',
      size: 'sm',
      required: (value) => !!value || 'Required.',
      partners: [],
      employes: [],
      banks: [],
      currnecy: [],
      users: [],
      productiontypes: [],
      docStatus: [],
      docDesc: [],
      CompanyId: 0,
      selectPa: {},
      selectPT: {},
      selectDS: {},
      selectDD: {},
      firstTimePa: true,
      selectId: 0,
      search: null,
      selectBank: {},
      selectBankId: 0,
      firstTimeBank: true,
      selectCR: {},
      selectUs: {},
      documentDate: null,
      documentTime: null,
      invoiceStartDate: null,
      invoiceEndDate: null,
      supplierDocumentDate: null,
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
        documentName: ''
      },
      mainQuery2 : {},
      currnecyType: [
        { text: 'Kn', value: 'Kn' },
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      statusType: [
        { text: 'Prijavljeno', value: 'Reported' },
        { text: 'Zaprimljeno', value: 'Received' },
        { text: 'U tijeku', value: 'InProgress' },
        { text: 'Završeno', value: 'Done' },
        { text: 'Preuzeto', value: 'Delivered' }
      ],
      money: {
        prefix: '= ',
        suffix: ' ',
        precision: 3
      },
      price: 7.500,
      docName: '',
      lang: {},
      langC: {},
      mount: true,
      reqVATID: false,
      haveItem: null,
      privateP: true,
      mainledgerAna: {},
      showPartners: false,
      totalFullExp: '',
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      showNote: false,
      locUCDdate : null,
      importPurchase: false,
      showDD: false,
      showOnlyEUR: true,
      nrDays: 15,
      findpartnerbyid: false,
      showContractSales: false
    }
  },
  components: {
    PartnerListFast
  },
  async mounted () {

    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    this.findpartnerbyid = false
    this.showOnlyEUR = this.$store.state.businessYear && this.$store.state.businessYear <= 2022 ? false : true
    this.privateP = this.$store.state.user ? this.$store.state.user.privatePartner : true
    // if is private marked then find only by id
    if (this.privateP) {
      this.findpartnerbyid = true
    }
    this.lang1 = this.lang.Description4
    this.lang2 = this.lang.Description1
    this.lang3 = this.lang.Description5
    this.lang4 = this.lang.Description3
    if (this.$store.state.companyid) {
      this.CompanyId = await this.$store.state.companyid
      this.mainQuery.CompanyId = await this.$store.state.companyid
    }
    if (this.$store.state.businessYear) {
      this.mainQuery.BusinessYear = this.$store.state.businessYear
    }
    if (this.$store.state.documentActiv) {
      this.mainQuery.documentName = this.$store.state.documentActiv
    }
    if (this.$store.state.documentSide) {
      this.document.documentSide = this.$store.state.documentSide
    }
    this.saving = true
    this.productiontypes = this.$store.state.productiontype
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    //await checkPartners(0)
    await testLastUpdate()
    if (this.$store.state.partnerStore) {
      this.partners = this.$store.state.partnerStore
    }
    //this.partners = (await PartnerService.index()).data.partners
    this.banks = (await BankAccountService.choose(this.mainQuery)).data.bankAccounts
    this.users = (await UserService.choose(this.mainQuery)).data.users
    this.docStatus = (await DocumentStatusService.index(this.mainQuery)).data.documenttypes
    this.docDesc = (await DocDescService.choose(this.mainQuery)).data.docdescs
    this.showDD = this.docDesc && this.docDesc.length > 0 ? true : false
    

    this.saving = false
    // edit current record
     if (this.$store.state.editItem === 1) {
        this.documentName = this.$store.state.documentActiv
        this.documentNameLoc = this.$store.state.documentActivLoc        
        if (this.$store.state.documentActivHead) {
          this.document = this.$store.state.documentActivHead
          if (this.document.partnerId && parseInt(this.document.partnerId) > 0 ) {    
            this.selectId = parseInt(this.document.partnerId)
            this.selectPa = this.partners.find(partner => (partner.id === parseInt(this.document.partnerId)))
            if (Object.keys(this.selectPa).length === 0) {
              this.selectPa = this.partners.find(partner => (partner.partnerVATID === (this.document.partnerVATID)))
            }
          }
          this.selectBankId  =this.document.bankAccountId ? parseInt(this.document.bankAccountId) : null
          this.selectBank = this.selectBankId ? this.banks.find(bank => (bank.id === this.selectBankId)) : null
          this.document.documentTime = this.document.documentTime ? this.document.documentTime.substr(0,5) : null
          this.selectPT = this.document.productiontype ? this.document.productiontype : null
          this.show1 = this.document.documentDescription4 && this.document.documentDescription4.trim().length > 0 ? true : false
          this.show2 = this.document.documentDescription1 && this.document.documentDescription1.trim().length > 0 ? true : false
          this.show3 = this.document.documentDescription5 && this.document.documentDescription5.trim().length > 0 ? true : false
          this.show4 = this.document.documentDescription3 && this.document.documentDescription3.trim().length > 0 ? true : false

          this.locUCDdate = dayjs(this.document.UCDdate).format('YYYY-MM-DD')
          const firstDate = this.document.documentDate ? new Date(this.document.documentDate) : null
          const lastDate = this.document.documentInvoiceEndDate ? new Date(this.document.documentInvoiceEndDate) : null
          const difference = firstDate && lastDate ? lastDate.getTime() - firstDate.getTime() : 0
          this.nrDays = Math.ceil(difference / (1000 * 3600 * 24))

        } else {
          // if in any case dont have all data in head, return to list
          this.text = 'Error! Sorry... some error happend. Need to return.'
          this.color = 'red'
          this.snackbar = true
          this.timeout = 4000
            this.$router.push({
              name: 'document'
            })
        }
     }   
    // create NEW
    if (this.$store.state.editItem === 0) {
      this.document = defDocument()
      if (this.$store.state.companyid) {
        this.CompanyId = await this.$store.state.companyid
        this.mainQuery.CompanyId = await this.$store.state.companyid
      }

      this.document.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
      this.document.email = this.$store.state.user.email ? this.$store.state.user.email : 'test@cegrupa.com'
      this.document.First = this.$store.state.user.First ? this.$store.state.user.First : 'NoFirst'
      this.document.Last = this.$store.state.user.Last ? this.$store.state.user.Last : 'NoLast'
      this.document.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
      this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
      this.document.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
      this.document.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2024
      this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2024
      if (this.$store.state.documentActiv) {
        this.mainQuery.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : ''
        this.document.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : ''
        this.docName = this.$store.state.documentActiv
        if (this.docName === 'InquirySales' || this.docName === 'QuoteSales' || this.docName === 'OrderSales' || this.docName === 'DeliveryNoteSales' || this.docName === 'InvoiceSales' || this.docName === 'ContractSales') {
          this.document.documentAnalysis = 1
          this.$store.dispatch('setDocumentAnalysis', 1)
        }
        if (this.docName === 'RequestPurchase' || this.docName === 'InquiryPurchase' || this.docName === 'OrderPurchase' || this.docName === 'ReceiptPurchase' || this.docName === 'ReturnPurchase') {
          this.document.documentAnalysis = 2
          this.$store.dispatch('setDocumentAnalysis', 2)
        }
        if (!this.document.documentAnalysis || this.document.documentAnalysis === 0) {
          this.document.documentAnalysis = 1
          this.mainQuery.documentAnalysis = 1
          this.$store.dispatch('setDocumentAnalysis', 1)
        }
      }
      this.document.documentSide = this.$store.state.documentSide ? this.$store.state.documentSide : 1  
      if (this.docName === 'InvoiceSales') {
        this.document.documentDescription5 = this.$store.state.companyActiv.CompanyInvoiceMemo
      }
      if (this.banks) {
        this.selectBank = this.banks ? this.banks.find(bank => (bank.DefaultAccount === true)) : {}
        this.selectBankId  = this.selectBank ? parseInt(this.selectBank.id) : null
      }
      let locDate1 = new Date()
      locDate1.getDate()
      locDate1.getMonth() + 1
      locDate1.getFullYear()
      const nrOfDays = this.$store.state.companyActiv.CompanyInvoiceDefDays ? parseInt(this.$store.state.companyActiv.CompanyInvoiceDefDays) : 15
      this.document.documentDate = dayjs(new Date()).format('YYYY-MM-DD')
      this.document.documentTime = dayjs(new Date()).format('HH:mm')
      this.document.documentInvoiceStartDate = dayjs(new Date()).format('YYYY-MM-DD')
      this.document.documentInvoiceEndDate = dayjs(new Date()).add(nrOfDays, 'days').format('YYYY-MM-DD')
      this.document.documentHomeCurrency = this.$store.state.businessYear <= 2022 ? 'Kn' : 'EUR'
      this.document.documentForeignCurrency = 'EUR'
      this.document.documentExRate = this.$store.state.businessYear <= 2022 ? 7.5345 : 1
      this.locUCDdate = dayjs(new Date()).format('YYYY-MM-DD')
    }
     if (this.documentName === 'ServiceNotes') {
        this.have1 = true
        this.have2 = true
        this.have3 = true
        this.have4 = true
        this.have5 = true
        this.lang1 = this.lang.ServiceItem
        this.lang2 = this.lang.ServiceProblem
        this.lang3 = this.lang.ServiceDescription
        this.lang5 = this.lang.ServiceStatus
      }
      if (this.documentName === 'Inventory') {
        this.have1 = false
        this.have2 = false
        this.have3 = false
        this.have4 = true
        this.have5 = false
      }
      if (this.documentName === 'ReceiptPurchase' || this.documentName === 'ReturnPurchase') {
        this.have6 = true
      }
      if (this.documentName === 'WorkingOrder') {
        this.have7 = true
      }
      if (this.documentName === 'InvoiceSales') {
        this.have8 = true
      }
      if (this.have6) {
        if (this.document.exportDoc) {
          this.importPurchase = true
        }
      }
    this.mount = false
    this.$refs.docdate.focus()
  },
  computed: {
    formIsValid () {
      return this.document.email !== '' &&
        this.document.First !== '' &&
        this.document.Last !== '' &&
        this.document.documentId !== '' &&
        this.document.documentDate !== ''
    },
    ...mapGetters({
      myPartnerid: 'NeedPartnerid'
    }),
  },
  methods: {
    showNoteOn() {
      this.showNote = !this.showNote
    },
    showOn1() {
      this.show1 = !this.show1
    },
    showOn2() {
      this.show2 = !this.show2
    },
    showOn3() {
      this.show3 = !this.show3
    },
    showOn4() {
      this.show4 = !this.show4
    },
    showAllPartners() {
      this.showPartners = !this.showPartners
    },
    validate () {
      if (this.$refs.form.validate()) {
        //this.snackbar = true
        this.register()
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async findByVATID() {
      if (this.document.partnerVATID) {
        this.mainQuery = {}
        this.mainQuery.CompanyId = this.$store.state.companyid
        this.mainQuery.partnerVATID = this.document.partnerVATID
        const {data} = await PartnerService.check(this.mainQuery)
        const id = data && data.partner && data.partner.id ? data.partner.id : null
        if (id) {
          this.selectPa =  this.partners.find(partner => (partner.id === parseInt(id)))
          await this.findPartner(id)
          this.text = 'OK!'
          this.color = 'green'
          this.snackbar = true
        }
        if (!id) {
          this.text = 'Error! Partner with this VATid not exist! Partner sa tim OIBom ne postoji!'
          this.color = 'red'
          this.snackbar = true
        }
      }
    },
    async register () {
      try {
        // write new document to database
        // this.localError = false   
        const haveYear = dayjs(this.document.documentDate).year()
        if (haveYear !== this.$store.state.businessYear) {
          this.text = this.lang.wrongBussinesYear
          this.color = 'red'
          this.snackbar = true
          this.timeout = 3000
          return
        }

        if (!this.document.exportDoc) {
          const partVatLeght = this.document.partnerVATID.trim().length
          if (partVatLeght !== 11) {
            this.text = this.lang.PartnerErrorVAT3
            this.color = 'red'
            this.snackbar = true
            this.timeout = 3000
            this.reqVATID = true
            return
          }
        }

            this.document.documentTime = this.document.documentTime.substr(0,5)
            this.document.UCDdate = this.locUCDdate ?  dayjs(this.locUCDdate): null
            if (this.document.exportDoc && this.have6) {
              this.document.documentExRate = this.document.exRate && parseNum(this.document.exRate, 2) !== 0 ? this.document.exRate : 1
            }
            // create NEW
            if (this.$store.state.editItem === 0) {
              this.document.documentId = null

              if (!this.document.partnerNameShort || (this.document.partnerNameShort.trim()).length < 1) {
                this.document.partnerNameShort = this.document.partnerName.replace(/[\W_]/g, '')
              }
              if (!this.document.partnerNameDatabase || (this.document.partnerNameDatabase.trim()).length < 1) {
                this.document.partnerNameDatabase = this.document.partnerName.replace(/[\W_]/g, '')
              }
              if (this.documentName === 'ServiceNotes' && !this.document.status) {
                this.document.status = 'Received'
              }
              const side = this.$store.state.documentSide ? this.$store.state.documentSide : 0
              this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
              this.document.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              this.document.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2024
              this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2024
              if (side === 1 || side === 2) {
                this.mainQuery.WareHouseId = this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1
              }
              this.document.documentId = parseInt((await DocumentService.choose(this.mainQuery)).data.docNumber)
              if (this.document.documentId && this.document.documentId > 0)
              {
                this.document.documentIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-' + this.$store.state.documentActiv + '-' + this.document.documentId + '-' + (this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1)
                this.document.WareHouseId = (this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1)
                const whObj =  this.$store.state.warehouseState ? this.$store.state.warehouseState.find(whID => whID.WareHouseId === this.$store.state.warehousemarkid) : []
                this.document.WareHouseName = whObj && whObj.WareHouseName ? whObj.WareHouseName : ''
                await DocumentService.post(this.document)
                  .then(response => {
                    this.text = this.lang.DocumentSavedOK + response.statusText
                    this.color = 'green'
                    this.snackbar = true
                    this.timeout = 2000
                    this.$store.dispatch('seteditItem', 0)
                    this.$store.dispatch('setDocumentActivHead', {})
                    this.$store.dispatch('setDocumentActivItems', [])
                    const newDoc = response.data && response.data.newDoc ? response.data.newDoc : null
                    if (newDoc) {
                      this.navigateTo(newDoc)
                    }
                  })
                  .catch(err => {
                    // eslint-disable-next-line
                    console.log(err)
                    this.text = 'Error post!' + err
                    this.color = 'red'
                    this.snackbar = true
                    this.timeout = 4000
                  })
              }
              
            }
            // edit current
            if (this.$store.state.editItem === 1) {
              await DocumentService.put(this.document)
                .then(response => {
                  this.text = this.lang.DocumentSavedOK + response.statusText
                  this.color = 'green'
                  this.snackbar = true
                  this.timeout = 2000
                  this.$store.dispatch('seteditItem', 0)
                  this.$store.dispatch('setDocumentActivHead', {})
                  this.$store.dispatch('setDocumentActivItems', [])
                  const newDoc = response.data && response.data.newDoc ? response.data.newDoc : null
                  if (newDoc) {
                    this.navigateTo(newDoc)
                  } else {
                    this.documentlist()
                  }
                })
                .catch(err => {
                  // eslint-disable-next-line
                  console.log(err)
                  this.text = 'Error put!' + err
                  this.color = 'red'
                  this.snackbar = true
                  this.timeout = 4000
                })
            }
      
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
        this.text = 'Error on save!' + error
        this.color = 'red'
        this.snackbar = true
        this.timeout = 4000
        this.error = 'Error'
      }
    },
    close () {
      this.show = false
    },
    async navigateTo (params) {
      try {                
      //const dbDocument = await DocumentService.show(params.id)
        if (params) {
          console.log('params.partnerVATID', params.partnerVATID)
          this.$store.dispatch('setDocumentActivHead', params)
        } else {
          this.$store.dispatch('setDocumentActivHead', {})
        }
        this.$store.dispatch('setDocumentid', params.id)
        this.$store.dispatch('setDatabaseDocID', params.id)
        this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
        this.mainQuery.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
        this.mainQuery.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : null
        this.mainQuery.databaseDocID = this.$store.state.databaseDocID ? this.$store.state.databaseDocID : null
        this.docSide = this.$store.state.documentSide ? this.docSide = this.$store.state.documentSide : null

        this.items = (await DocumentDetailService.index(this.mainQuery)).data.documentDetails
        if (this.items) {
          this.$store.dispatch('setDocumentActivItems', this.items)
        } else {
          this.$store.dispatch('setDocumentActivItems', [])
        }
        this.$store.dispatch('setprintSmall', false)
        this.$store.dispatch('setprintNoPrice', false)
        this.$store.dispatch('settypePOSPrint', null)
        if (this.$store.state.documentActiv !== 'WorkingOrder') {
            this.$router.push({
            name: 'documentview'
          })
          } else {
            this.$router.push({
            name: 'documentview3'
          })
        }
        
      } catch (err) {
        // eslint-disable-next-line
        console.log('error read from database', err)
      }      
    },
    async documentlist () {
      try {
          if (this.$store.state.documentActiv !== 'WorkingOrder') {
            this.$router.push({
            name: 'document'
          })
          } else {
            this.$router.push({
            name: 'documentproduction'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(' Error ' + err)
        this.text = 'Error!' + err
        this.color = 'red'
        this.snackbar = true
        this.timeout = 4000
      }
    },
    clearPartner() {
      this.selectPa = null
      this.document.partnerId = null
      this.document.partnerName = null
      this.document.partnerNameShort = null
      this.document.partnerNameDatabase = null
      this.document.partnerBuildingNameNumber = null
      this.document.partnerStreet = null
      this.document.partnerCity = null
      this.document.partnerPostcode = null
      this.document.partnerCountyRegion = null
      this.document.partnerState = null
      this.document.partnerVATID = null
      this.document.partneremail = null
      this.document.partnerPhones = null
      this.document.partnerBankName = null
      this.document.partnerBankAddress = null
      this.document.partnerBankIBAN = null
      this.document.partnerBankSWIFT = null
      this.document.partnerContactTime = null
      this.document.partnerSecretaryTitle = null
      this.document.partnerSecretaryFirst = null
      this.document.partnerSecretaryLast = null
      this.document.partnerSecretaryEmail = null
      this.document.partnerSecretaryPhone = null
      this.document.partnerSecretaryContactOption = null
      this.document.privatePerson = false
    },

    async findPartner (findQuery) {
      //console.log('trazim  FIND PARTNER findQuery', findQuery, 'this.findpartnerbyid  ', this.findpartnerbyid )
      //if (partnerVATID.length > 0) {
        //const locPartner = !this.findpartnerbyid ? (await PartnerService.show(findQuery)).data.partner : (await PartnerService.findbyid(findQuery)).data.partner
        const locPartner =  (await PartnerService.findbyid(findQuery)).data.partner
        this.document.partnerId = locPartner.id
        this.document.partnerName = locPartner.partnerName
        this.document.partnerNameShort = locPartner.partnerNameShort
        this.document.partnerNameDatabase = locPartner.partnerNameDatabase
        this.document.partnerBuildingNameNumber = locPartner.partnerBuildingNameNumber
        this.document.partnerStreet = locPartner.partnerStreet
        this.document.partnerCity = locPartner.partnerCity
        this.document.partnerPostcode = locPartner.partnerPostcode
        this.document.partnerCountyRegion = locPartner.partnerCountyRegion
        this.document.partnerState = locPartner.partnerState
        this.document.partnerVATID = locPartner.partnerVATID
        this.document.partneremail = locPartner.partneremail
        this.document.partnerPhones = locPartner.partnerPhones
        this.document.partnerBankName = locPartner.partnerBankName
        this.document.partnerBankAddress = locPartner.partnerBankAddress
        this.document.partnerBankIBAN = locPartner.partnerBankIBAN
        this.document.partnerBankSWIFT = locPartner.partnerBankSWIFT
        this.document.partnerContactTime = locPartner.partnerContactTime
        this.document.partnerSecretaryTitle = locPartner.partnerSecretaryTitle
        this.document.partnerSecretaryFirst = locPartner.partnerSecretaryFirst
        this.document.partnerSecretaryLast = locPartner.partnerSecretaryLast
        this.document.partnerSecretaryEmail = locPartner.partnerSecretaryEmail
        this.document.partnerSecretaryPhone = locPartner.partnerSecretaryPhone
        this.document.partnerSecretaryContactOption = locPartner.partnerSecretaryContactOption
        this.document.privatePerson = locPartner.privatePerson

        this.selectPa = !this.findpartnerbyid ? this.selectPa = this.partners.find(partner => (partner.partnerVATID === (this.document.partnerVATID))) : this.selectPa = this.partners.find(partner => (partner.id === parseInt(this.document.partnerId)))
        //   }
        // }
     // }
    },

    async refreshPartners() {
      await newUpdates(2)
      await testLastUpdate()
      if (this.$store.state.partnerStore) {
        this.partners = this.$store.state.partnerStore
      }
    },
    async addeditPartner () {
        if (!this.document.partnerVATID){
          this.text = this.lang.PartnerErrorVAT2
          this.color = 'red'
          this.snackbar = true
          this.timeout = 3000
          return
        }
         if (!this.document.exportDoc) {
          const partVatLeght = this.document.partnerVATID.trim().length
          if (partVatLeght !== 11) {
            this.text = this.lang.PartnerErrorVAT3
            this.color = 'red'
            this.snackbar = true
            this.timeout = 3000
            this.reqVATID = true
            return
          }
        }
        const updatePartner = {...this.document}
        this.saving = true
        if (this.document.partnerName && this.document.partnerVATID) {
          this.localError = null
          // can save as private person ONLY in document ServiceNotes and QuoteSales
          if (!this.localError) {
            this.saving = true
            // const query= {}
            const partnerId = this.document.partnerId ? this.document.partnerId : null
            const newPartner = await addUpdatePartner(updatePartner, partnerId)
            this.saving = false
            this.text = this.lang.addedPartner
            this.color = 'green'
            this.snackbar = true
            this.timeout = 2000
            await this.refreshPartners()
            console.log('vratio mi se u Documnet newPartner', newPartner)
            this.document.partnerId = newPartner.id ? newPartner.id : null
            this.selectId = newPartner.id ? parseInt(newPartner.id) : null
            this.selectPa = this.partners.find(partner => (partner.id === newPartner.id))
            // const findWhat = !this.document.partnerId? this.document.partnerVATID : this.document.partnerId
            // await this.findPartner(findWhat)
            // console.log('update partner', findWhat, 'this.findpartnerbyid ', this.findpartnerbyid )
            // if (findWhat) {
            //   if (this.$store.state.editItem === 0) {
            //     await this.findPartner(findWhat)
            //   }
            //   if (this.$store.state.editItem === 1 && this.document.partnerVATID !== '00000000000') {
            //     await this.findPartner(findWhat)
            //   }
            // }
            //this.selectPa = this.partners.find(partner => (partner.partnerVATID === newPartner.partner.partnerVATID))
            //await this.findPartner(newPartner.partner.partnerVATID)
          } else {
            this.text = this.localError
            this.color = 'red'
            this.snackbar = true
          }
          
        } else {
          // not enough data to save partner 
          this.text = this.lang.PartnerErrorName
          this.color = 'red'
          this.snackbar = true
          this.timeout = 4000
        } 
        
        this.saving = false
    },
    findBank (bankId) {

      if (bankId) {
        const locBank = bankId ? this.banks.find(bank => (bank.id === bankId)) : null
        this.document.bankAccountId = locBank.id ? parseInt(locBank.id) : null
        this.document.bankAccountName = locBank.BankName ? locBank.BankName : null
        this.document.bankAccountNr = locBank.IBAN ? locBank.IBAN : null
      }
      this.firstTimeBank = false
    },
    formatdocumentDate () {
     // if (this.document.documentDate) {
     //   this.document.documentDate = dayjs(this.document.documentDate).format('DD.MM.YYYY')
     // }

    },
    formatInvoiceStartDate () {
     // if (this.document.documentInvoiceStartDate) {
     //   this.document.invoiceStartDate = this.formatDate(this.document.documentInvoiceStartDate)
     // }

    },
    formatInvoiceEndDate () {
      if (this.document.documentInvoiceEndDate) {
        this.invoiceEndDate = this.localDate(this.document.documentInvoiceEndDate)
      }
    },
    formatsupplierDocumentDate () {
      if (this.document.supplierDocumentDate) {
        this.supplierDocumentDate = this.localDate(this.document.supplierDocumentDate)
      }
    },
    calculateImportDoc() {
      this.document.domecilAmount = parseNum(((this.document.exRate ? parseFloat(this.document.exRate) : 1) * (this.document.importAmount ? parseFloat(this.document.importAmount) : 0)),2)
      this.document.totalExp = (this.document.customAmount ? parseNum(this.document.customAmount, 2) : 0) + (this.document.bankExp ? parseNum(this.document.bankExp, 2) : 0) + (this.document.forwarderExp ? parseNum(this.document.forwarderExp, 2) : 0) + (this.document.transportExp ? parseNum(this.document.transportExp, 2) : 0) + (this.document.otherExp ? parseNum(this.document.otherExp, 2) : 0)
      this.document.expenesPercent = (this.document.domecilAmount && parseNum(this.document.domecilAmount, 2) > 0 ) ? (parseNum((this.document.totalExp / (parseNum(this.document.domecilAmount, 2) / 100)), 2)) : 0
      this.totalFullExp = '= ' + moneyFormat(this.document.totalExp) + ' (' + moneyFormat(this.document.expenesPercent) + '%)'
    },
    async addOpenStatement(){
      // type 1 = balance sheet, 2 = partner card,, 3 = partner open, 4 = analysis, 5= all partners open
          this.mainledgerAna.type = 5
          if (this.document.partnerId) {
            this.mainledgerAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
            this.mainledgerAna.partnerId = this.document.partnerId
            this.mainledgerAna.partnerVATID = this.document.partnerVATID
            this.mainledgerAna.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
            this.mainledgerAna.fromAccount = '1200'
            this.mainledgerAna.toAccount = '1200'
            this.mainledgerAna.docFromDate = dayjs([this.mainledgerAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
            this.mainledgerAna.docToDate = dayjs([this.mainledgerAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
            // eslint-disable-next-line
            console.log(' imam upit ',this.mainledgerAna)
            await MainLedgerDetailService.card(this.mainledgerAna)
            .then(res => {
              this.items = res.data.documents ? res.data.documents : []
              if (this.items.length > 0) {
                const totalOpen = round(parseNum(this.items[0].totalDebit), 2) - round(parseNum(this.items[0].totalCredit), 2)
                if (totalOpen > 0) { 
                  this.document.documentDescription5 = this.document.documentDescription5 +  'Poštovani, obaviještavam Vas da na dan izdavanja ovog dokumenta imate otvoren iznos dugovanja: ' + moneyFormat(totalOpen) + ' Kn'
                }
                if (totalOpen === 0) { 
                  this.document.documentDescription5 = this.document.documentDescription5 +  'Poštovani, obaviještavam Vas da na dan izdavanja ovog dokumenta nemate otvoren iznos dugovanja. Hvala!'
                }
                if (totalOpen < 0) { 
                  this.document.documentDescription5 = this.document.documentDescription5 +  'Poštovani, obaviještavam Vas da na dan izdavanja ovog dokumenta imate pretplatu u iznosu: ' + moneyFormat(totalOpen) + ' Kn'
                }

              } else {
                this.text = 'Error! Nema knjizenja za odabranog partnera!'
                this.color = 'red'
                this.snackbar = true
                this.timeout = 4000
              }
            })
            .catch(err => {
              this.error = this.langC.ErrorE + err
            })
          } else {
            this.text = 'Error! Partner nije definiran!'
            this.color = 'red'
            this.snackbar = true
            this.timeout = 4000
          }
    }
  },
  watch: {
    'nrDays' () {
      if (!this.nrDays) {
        this.nrDays = 15
      }
      if (this.nrDays > 999) {
        this.nrDays = 15
      }
      this.document.documentInvoiceEndDate = dayjs(new Date(this.document.documentDate)).add(this.nrDays, 'days').format('YYYY-MM-DD')
      
    },
    'myPartnerid' () {
      if (this.$store.state.partnerid) {
        this.findpartnerbyid = true
        //this.selectPa = this.$store.state.partnerid ? this.partners.find(partner => (partner.id === this.$store.state.partnerid)) : null
        this.findPartner(this.$store.state.partnerid)
        this.showPartners = false
      }
     // this.partner()
    },
    'selectPa' (val) {
      if (val) {
        //const findWhat = !this.findpartnerbyid ? val.partnerVATID : val.id
        this.findPartner(val.id)

        // if (findWhat) {
        //   if (this.$store.state.editItem === 0) {
        //     this.findPartner(findWhat)
        //   }
        //   if (this.$store.state.editItem === 1 && val.partnerVATID !== '00000000000') {
        //     this.findPartner(findWhat)
        //   }
        // }
      }
      if (!val) {
        this.clearPartner()
      }
    },
    'selectBank' (val) {
      if (val) {
        val.id && this.findBank(val.id) 
      }           
    },
    'selectPT' (val) {
      if (val) {
        this.document.productiontype = val.value ? val.value : null
      }           
    },
    'selectDS' (val) {
      if (val) {
        this.document.docColor = val.docColor ? val.docColor : ''
        this.document.docStatus = val.docStatus ? val.docStatus : ''
      } else {
        this.document.docColor = null
        this.document.docStatus = null
      }
    },
    'selectDD' (val) {
      if (val) {
        this.document.documentDescription5 = val.description ? this.document.documentDescription5 + val.description : ''
      }
    },
    // 'document.documentDate' () {
    //   this.formatdocumentDate()
    // },
    // 'document.documentInvoiceStartDate' () {
    //   this.formatInvoiceStartDate()
    // },
    // 'document.documentInvoiceEndDate' () {
    //   this.formatInvoiceEndDate()
    // },
    'document.supplierDocumentDate' () {
      this.formatsupplierDocumentDate()
    },
    'document.documentTotalAmountPayInAdvance' () {
      if (isNaN(this.document.documentTotalAmountPayInAdvance)) {
        this.document.documentTotalAmountPayInAdvance = 0
      }
      if (!this.document.documentTotalAmountPayInAdvance) {
        this.document.documentTotalAmountPayInAdvance
      }
      const exRate = this.document.documentExRate ? this.document.documentExRate : 1
      this.document.documentTotalAmountPayInAdvance = round(this.document.documentTotalAmountPayInAdvance, 2)
      this.document.documentTotalAmountToPay = this.document.documentTotalAmountBrutto ? (round(this.document.documentTotalAmountBrutto, 2) - (this.document.documentTotalAmountPayInAdvance)): 0
      this.document.documentTotalAmountPayInAdvanceForeign = round((this.document.documentTotalAmountPayInAdvance / exRate), 2)
      this.document.documentTotalAmountToPayForeign = this.document.documentTotalAmountBrutto ? (round((this.document.documentTotalAmountBrutto/ exRate), 2) - (this.document.documentTotalAmountPayInAdvance / exRate) ) : 0  
    },
    'document.importAmount' () {
      if (!this.document.importAmount) {
        this.document.importAmount  = 0
      }
      if (this.document.importAmount && this.document.exportDoc) {
        this.calculateImportDoc()
      }
    },
    'document.exRate' () {
      if (!this.document.exRate) {
        this.document.exRate  = 0
      }
      if (this.document.exRate && this.document.exportDoc) {
        this.calculateImportDoc()
      }
    },
    'document.customAmount' () {
      if (!this.document.customAmount) {
        this.document.customAmount  = 0
      }
      if (this.document.customAmount && this.document.exportDoc) {
        this.calculateImportDoc()
      }
    },
    'document.bankExp' () {
      if (!this.document.bankExp) {
        this.document.bankExp  = 0
      }
      if (this.document.bankExp && this.document.exportDoc) {
        this.calculateImportDoc()
      }
    },
    'document.forwarderExp' () {
      if (!this.document.forwarderExp) {
        this.document.forwarderExp  = 0
      }
      if (this.document.forwarderExp && this.document.exportDoc) {
        this.calculateImportDoc()
      }
    },
    'document.transportExp' () {
      if (!this.document.transportExp) {
        this.document.transportExp  = 0
      }
      if (this.document.transportExp && this.document.exportDoc) {
        this.calculateImportDoc()
      }
    },
    'document.otherExp' () {
      if (!this.document.otherExp) {
        this.document.otherExp  = 0
      }
      if (this.document.otherExp && this.document.exportDoc) {
        this.calculateImportDoc()
      }
    },
    'document.exportDoc' () {
      this.importPurchase = false
      if (this.have6) {
        if (this.document.exportDoc) {
          this.importPurchase = true
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .v-input--checkbox .v-label {
    font-size: 10px;
  }
</style>
