import { render, staticRenderFns } from "./DocumentList.vue?vue&type=template&id=061c4512&scoped=true"
import script from "./DocumentList.vue?vue&type=script&lang=js"
export * from "./DocumentList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "061c4512",
  null
  
)

export default component.exports