<template>
<div>
  <v-container fluid grid-list-md>
  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-row>
  <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" class="pr-1">
  <v-data-table
    dense
    :headers="headers"
    :items="mbdocs"
    item-key="id"
    :search="search"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="50"

  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{lang.MainBookDocs}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          v-model="search"
        ></v-text-field>
        <v-dialog v-model="dialog" max-width="600px">

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>


          <v-card>
            <v-card-title>
              <span class="headline">{{ lang.MainBookDoc }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >

                  <v-row>
                    <v-col cols="12" sm="12" md="6" xs="6" lg="6" xl="6" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.MainBookDocID"
                        v-model="editedItem.DocID"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" xs="6" lg="6" xl="6" class="pr-1">
                      <span style="color:red" v-if="errorID">{{lang.MainBookDocIDErrorExist}} </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.MainBookDocName"
                        v-model="editedItem.DocName"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="6" xs="6" lg="6" xl="6" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.MainBookDocVATID"
                        v-model="editedItem.DocVATID"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.MainBookDocDesc"
                        v-model="editedItem.DocDesc"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" class="pr-1">
                     <v-text-field
                        dense
                        label="Šifra odvjetnika (broj):"
                        v-model="editedItem.FreeF1"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <span :style="colorStyle1">  {{lang.MainBookDocOpenDate}} </span>
                    </v-col>
                    <v-col cols="12" sm="12" md="3" xs="3" lg="3" xl="3" class="pr-3">
                      <input id="DocOpenDate" type="date"  :style="colorStyle1" v-model="editedItem.DocOpenDate"/>
                    </v-col>
                     <v-col cols="12" sm="12" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <span :style="colorStyle2">  {{lang.MainBookDocClosedDate}} </span>
                    </v-col>
                    <v-col cols="12" sm="12" md="3" xs="3" lg="3" xl="3" class="pr-3">
                      <input id="DocClosecDate" type="date"  :style="colorStyle2" v-model="editedItem.DocClosecDate"/>
                    </v-col>

                  </v-row>

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn color="success" text @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.DocOpenDate`]="{ item }">
        <span v-if="item.DocOpenDate">{{ localDate(item.DocOpenDate) }}</span>
    </template>
    <template v-slot:[`item.DocClosecDate`]="{ item }">
        <span v-if="item.DocClosecDate">{{ localDate(item.DocClosecDate) }}</span>
    </template>
    <template v-slot:[`item.FreeF1`]="{ item }">
        <span>{{ parseInt(item.FreeF1) }}</span>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      {{ lang.MainBookDocsEmpty }}
    </template>
  </v-data-table>
  </v-col>
  </v-row>
  </v-container>
  </div>
</template>


<script>
import MainBookDocService from '@/services/MainBookDocService'
import { defMainBookDoc, defNewMainBookDoc } from '../MainLedger/mainledgerHelper'
import langEn from './mainbookDescEn'
import langHr from './mainbookDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import dayjs from 'dayjs'

  export default {
    name: 'mainbookdocCRUD',
    data: () => ({
      search: '',
      redDocOpenDate: false,
      redDocClosecDate: false,
      redDocID: false,
      colorStyle1: '',
      colorStyle2: '',
      startDate: null,
      endDate: null,
      fromDate: null,
      toDate: null,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      menu2: false,
      modal1: false,
      dialog1: false,
      modal2: false,
      dialog2: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      dialog: false,
      headers: [],
      mbdocs: [],
      editedIndex: -1,
      editedItem: { },
      defaultItem: {

      },
      privitems: [],
      valid: true,
      RecTrue: true,
      ExpTrue: false,
      rules: {
        required: value => !!value || '*'
      },
      required: (value) => !!value || 'Required.',
      errorID: false
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewMainBookType: this.lang.EditMainBookType
      },
      formatDate1() {
      return this.editedItem.DocOpenDate
        ? dateFormat(this.editedItem.DocOpenDate)
        : "";
      },
      formatDate2() {
        return this.editedItem.DocClosecDate
          ? dateFormat(this.editedItem.DocClosecDate)
          : "";
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      'fromDate' () {
        const newDate = dayjs(this.fromDate)
        console.log('fD', this.fromDate, typeof this.fromDate, newDate, typeof newDate)
      }
    },
    components: {

    },

    mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      this.colorStyle1 = "color:black"
      this.colorStyle2 = "color:black"
      this.headers.push(
        {text: this.lang.MainBookDocID, value: 'DocID', width: '150px'},
        {text: this.lang.MainBookDocName, value: 'DocName', width: '300px'},
        {text: this.lang.MainBookDocVATID, value: 'DocVATID', width: '100px'},
        {text: this.lang.MainBookDocDesc, value: 'DocDesc', width: '300px'},
        {text: this.lang.MainBookDocOpenDate, value: 'DocOpenDate', width: '140px'},
        {text: this.lang.MainBookDocClosedDate, value: 'DocClosecDate', width: '140px'},
        {text: 'Šifra odv.', value: 'FreeF1', type: 'number', width: '50px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
        {text: this.lang.Actions, value: 'action', sortable: false },
      ) 
      this.defaultItem = {...defMainBookDoc(), ...defNewMainBookDoc()}
    },

    created () {
      this.initialize()
    },

    methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      localDate(dateTo) {
        return dateFormat(dateTo ? dateTo.substr(0,10) : null)
      },
      async initialize () {
        try {
          this.saving = true
          const mainAna = {}
          mainAna.CompanyId = this.$store.state.companyid ? parseInt(this.$store.state.companyid) : null
          this.mbdocs = (await MainBookDocService.index(mainAna)).data.mainbookdocs
          this.saving = false
          this.errorMatch = false
          this.editedItem ={...this.defaultItem}
        } catch (err) {
          this.error = err
        }
      },
      editItem (item) {
        this.errorMatch = false
        this.editedIndex = this.mbdocs.indexOf(item)
        this.editedItem = {...item}
        this.dialog = true
      },

      async deleteItem (item) {
        try {
          const index = this.mbdocs.indexOf(item)
          const warningMessage = item.CalDate
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.mbdocs.splice(index, 1)) {
            await MainBookDocService.delete(item.id)
            this.initialize()
          }
        } catch(err) {
          this.error = err
          
        }
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = {...this.defaultItem}
          this.editedIndex = -1
        }, 300)
      },

      async save () {
        try {
           let doneOK = false
           let noErr = true
           this.colorStyle1 = "color:black"
           this.colorStyle2 = "color:black"
           console.log('save')
           if (!this.editedItem.DocID || this.editedItem.DocID.length === 0) {
            this.colorStyle1 = "red--text"
            noErr = false
            alert(this.lang.MainBookDocIDError)
           }
           if (this.editedItem.DocOpenDate) {
            const haveOpenDateYear = parseInt(this.editedItem.DocOpenDate.substr(0,4))
            if ((haveOpenDateYear > this.$store.state.businessYear + 10) || (haveOpenDateYear < this.$store.state.businessYear - 10)) {
              alert(this.lang.MainBookDocOpenDateErr)
              this.colorStyle1 = "color:red"
              noErr = false
            }
           }
           if (this.editedItem.DocClosecDate) {
            const haveDocClosecDate = parseInt(this.editedItem.DocClosecDate.substr(0,4))
            if ((haveDocClosecDate > this.$store.state.businessYear + 20) || (haveDocClosecDate < this.$store.state.businessYear - 20)) {
              alert(this.lang.MainBookDocClosedDateErr)
              this.colorStyle2 = "color:red"
              noErr = false
            }
           }
           console.log('noErr', noErr)
           if (noErr) {
            if (this.editedIndex > -1) {
                await MainBookDocService.put(this.editedItem)
                doneOK = true
              } else {
                const idIsOn = (await MainBookDocService.choose(this.editedItem)).data.mainbookdocs
                console.log('isIsOn ', idIsOn)

                if (idIsOn && idIsOn.length > 0) {
                  this.errorID = !this.errorID
                } else {
                  await MainBookDocService.post(this.editedItem)
                  doneOK = true
                }
              }
              if (doneOK) {
                this.initialize()
                this.close()
              }
           }
        } catch(err) {
          this.error = err
          
        }
       
      },

    },
  }
</script>