<template>
  <v-container fluid >
    <v-row>
      <v-col cols="12" sm="4" xs="4" md="4" lg="4" xl="4" class="mb-4">
        <h2>Joppd A</h2>
      </v-col>
      <v-col cols="12" sm="4" xs="4" md="4"  lg="4" xl="4" class="mb-4">
        <JoppdNumber />
      </v-col>
      <v-col cols="12" sm="3" xs="3" md="3"  lg="3" xl="3">
        <v-btn fab dark fixed right class="indigo"  @click="newdocument">
              <v-icon dark>mdi-plus</v-icon>
          </v-btn>
      </v-col>
    </v-row>

    <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>

    <v-row no-gutters class="pa-0">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <JoppdAList :key="refresh"/>
      </v-col>
    </v-row>    
  </v-container>
</template>

<script>

import langEn from './salarieDescEn'
import langHr from './salarieDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import JoppdAList from './JoppdAList'
import JoppdNumber from './JoppdNumber.vue'
import {months, years} from '@/snippets/allSnippets.js'
import dayjs from 'dayjs'

export default {
  name: 'joppda',
  data () {
    return {
      msg: '',
      lang: {},
      langC: {},
      locMonths: [],
      locYears: [],
      monthSelected: null,
      yearSelected: null,
      empSelected: null,
      emplys: [],
      newWH: {},
      newWorkHours: [],
      mainQuery: {},
      days:[],
      refresh: 0,
      saving: false,
      postNew: false,
      workDays: 0,
      holiDays: 0,
      salarieID: null,
      updateObj: {},
      modal1: false,
      dialog1: false,
      joppddate: null,
      joppd: 0
    }
  },
  beforeMount() {
    this.joppddate = dayjs().format('YYYY-MM-DD')
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    if (this.$store.state.businessYear) {
      this.mainQuery.businessYear = this.$store.state.businessYear
    }
    this.locMonths = months()
    this.locYears = years()
    this.calculateJoppd()    
  },
  methods: {
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
    calculateJoppd() {

      const now = new Date(this.joppddate);
      const start = new Date(now.getFullYear(), 0, 0);
      const diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
      const oneDay = 1000 * 60 * 60 * 24;
      const day = Math.floor(diff / oneDay);

      const yy = new Date().getFullYear().toString()
      const y = yy.substring(2,2)

      let newDay = day.toString()
      if (day.toString().length === 1) {
        newDay = '00' + day.toString()
      }
      if (day.toString().length === 2) {
        newDay = '0' + day.toString()
      }
      this.joppd =  y + newDay
    },
    async newdocument () {
      try {
        await this.$store.dispatch('seteditItem', 0)
        await this.$router.push({
          name: 'joppdadetail'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },

  },
  computed: {

  },
  watch: {
    'joppddate' () {
      this.calculateJoppd()
    }
  },
  components: {
    JoppdAList,
    JoppdNumber
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
