<template>
  <div class="formprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import {moneyFormat} from '@/snippets/allSnippets'
import documentLocalName from '@/snippets/documentLocalName'
import dayjs from 'dayjs'

export default {
  name: 'salesprint',
  props: ['all'],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0,
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      Total6: 0,
      Total7: 0,
      Total8: 0,
      ana: {},
      documentName: ''
    }
  },
  async mounted () {
    
  },
  methods: {
    async print () {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr
      }
      this.comp = await this.$store.state.companyActiv
      this.ana = await this.$store.state.printObj
      console.log(' ana ', this.ana, ' all', this.all)
      this.documentName = await this.localName(this.ana.documentName)
      if (this.all === 1) {
        this.initDocument()
        this.addHeaderNoLogo()
        this.addHeaderTop()
        this.addLine()
        this.addHeader1()
        this.addLine()
        this.addItems1(1)
        this.addLine()
        this.addTotal1()
        this.addEmptyRow()
        this.addSignature()
        this.docDefinition.content = this.content
        await pdfMake.createPdf(this.docDefinition).open()
      }
      if (this.all === 2) {
        this.initDocument()
        this.addHeaderNoLogo()
        this.addHeaderTop()
        this.addLine()
        this.addHeader2()
        this.addLine()
        this.addItems2()
        this.addLine()
        this.addLine()
        this.addTotal2()
        this.addEmptyRow()
        this.addSignature()
        this.docDefinition.content = this.content
        await pdfMake.createPdf(this.docDefinition).open()
      }
     
    },
    localMoney (money) {
      return moneyFormat(money)
    },
    localName (name) {
      return documentLocalName(name)
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 35, 20, 20, 20 ]
      this.content = []
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 500,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {      
                // % width
                width: '30%',
                text: [         
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                ]   
              },
              {
                // % width
                width: '25%',
                text: [
                  { text:  '\n', fontSize: 14, bold: true },
                ]   
              },
              {      
                // % width
                width: '30%',
                text: [         
                  { text: 'OIB:' + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' ') + ' \n', fontSize: 8},        
                  { text: 'Datum ispisa: ' + dayjs().format('DD.MM.YYYY, HH:mm'), fontSize: 7}
                ]   
              }
             
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeaderTop () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '30%',
                text: [
                  { text:' \n', fontSize: 10, bold: true },
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text:  this.ana.head1 ? this.ana.head1 + '\n' : '\n', fontSize:12, bold: true},
                  { text:  'Vrsta dokumenta: ' + this.documentName + '\n', fontSize: 10, bold: true },
                  { text:  this.ana.head2 ? this.ana.head2 + '\n' : '\n', fontSize:10, bold: true},
                  { text:  this.ana.head4 ? this.ana.head4 + '\n' : '\n', fontSize:10, bold: true},
                  { text:  this.ana.head5 ? this.ana.head5 + '\n' : '\n', fontSize:10, bold: true}
                ]   
              },

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeader1 () {
        const docPart = {
        columns: [
               {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.documentId
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.documentDate1
              },
              {
                width: '25%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.partnerName
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.documentTotalAmountNetto
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.documentTotalAmountVAT
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.documentTotalAmountBrutto
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.documentTotalAmountNettoForeign
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.documentTotalAmountBruttoForeign
              },
            ]
          }
        this.content.push(docPart)
    },
    addHeader2 () {
        const docPart = {
        columns: [
               {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.documentId
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.documentDate1
              },
              {
                width: '20%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.partnerName
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.documentTotalAmountNetto
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.documentTotalAmountVAT
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.documentTotalAmountBrutto
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.TotalDiscount
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.TotalDiff
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.TotalServices
              },
            ]
          }
        this.content.push(docPart)
    },

    addItems1() {
      if (this.ana.items) {
          this.ana.items.map(item => {
            this.Total1 += parseFloat(item.documentTotalAmountNetto)
            this.Total2 += parseFloat(item.documentTotalAmountVAT)
            this.Total3 += parseFloat(item.documentTotalAmountBrutto)
            this.Total4 += parseFloat(item.documentTotalAmountNettoForeign)
            this.Total5 += parseFloat(item.documentTotalAmountVATForeign)
            this.Total6 += parseFloat(item.documentTotalAmountBruttoForeign)
            this.Total7 += parseFloat(item.supplierNetoAmount)
            this.Total8 += parseFloat(item.supplierMargin)
            const docPart = {
            columns: [
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'left',
                    text: item.documentId + '. \n'
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'left',
                    text: this.formatDate(item.documentDate) + '\n'
                  },
                  {
                    width: '25%',
                    fontSize: 8,
                    alignment: 'left',
                    text: item.partnerName + '\n'
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.documentTotalAmountNetto) + '\n'
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.documentTotalAmountVAT) + '\n'
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.documentTotalAmountBrutto) + '\n'
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.documentTotalAmountNettoForeign) + '\n'
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.documentTotalAmountBruttoForeign) + '\n'
                  },
                ]
              }
            this.content.push(docPart)
          })
      }
    },
    addItems2() {
      if (this.ana.items) {
          this.ana.items.map(item => {
            this.Total1 += item.documentTotalAmountNetto ? parseFloat(item.documentTotalAmountNetto) : 0
            this.Total2 += item.documentTotalAmountVAT ? parseFloat(item.documentTotalAmountVAT) : 0
            this.Total3 += item.documentTotalAmountBrutto ? parseFloat(item.documentTotalAmountBrutto) : 0
            this.Total4 += item.documentTotalAmountNettoForeign ? parseFloat(item.documentTotalAmountNettoForeign) : 0
            this.Total5 += item.documentTotalAmountVATForeign ? parseFloat(item.documentTotalAmountVATForeign) : 0
            this.Total6 += item.documentTotalAmountBruttoForeign ? parseFloat(item.documentTotalAmountBruttoForeign) : 0
            this.Total7 += item.supplierNetoAmount ? parseFloat(item.supplierNetoAmount) : 0
            this.Total8 += item.supplierMargin ? parseFloat(item.supplierMargin) : 0
            const docPart = {
            columns: [
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'left',
                    text: item.documentId + '. \n'
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'left',
                    text: this.formatDate(item.documentDate) + '\n'
                  },
                  {
                    width: '20%',
                    fontSize: 8,
                    alignment: 'left',
                    text: item.partnerName + '\n'
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.documentTotalAmountNetto) + '\n'
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.documentTotalAmountVAT) + '\n'
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.documentTotalAmountBrutto) + '\n'
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.Discount) + '\n'
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.Diff) + '\n'
                  },
                  {
                    width: '8%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.Services) + '\n'
                  },
                ]
              }
            this.content.push(docPart)
          })
      }
    },
    addTotal1() {

          const docPart = {
          columns: [
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: ' \n'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: '\n'
              },
              {
                width: '15%',
                fontSize: 8,
                alignment: 'left',
                text:  '\n'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'center',
                text:  '\n'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'right',
                text: this.localMoney(this.Total1) + '\n'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'right',
                text: this.localMoney(this.Total2) + '\n'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'right',
                text: this.localMoney(this.Total3) + '\n'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.localMoney(this.Total4) + '\n'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.localMoney(this.Total6) + '\n'
              },
            ]
          }
          this.content.push(docPart)
      
    },
    addTotal2() {

          const docPart = {
          columns: [
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: ' \n'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: '\n'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text:  '\n'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'center',
                text:  '\n'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'right',
                text: this.localMoney(this.Total1) + '\n'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'right',
                text: this.localMoney(this.Total2) + '\n'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'right',
                text: this.localMoney(this.Total3) + '\n'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.localMoney(this.ana.TotalFullDiscount) + '\n' 
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.localMoney(this.ana.TotalFullDiff) + '\n' + 'Roba:'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.localMoney(this.ana.TotalFullServices) + '\n' + this.localMoney(this.Total3 - this.ana.TotalFullServices)
              },
            ]
          }
          this.content.push(docPart)
      
    },
    addSignature () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: ' \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text:  '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    }

  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>