import Api from '@/services/Api'

export default {
  index(query) {
    return Api().get('form', {
      params: query    
    })
  },
  index2(query) {
    return Api().get('form/showall', {
      params: query    
    })
  },
  show(id) {
    return Api().get(`form/${id}`)
  },
  post(newform) {
    return Api().post('form', newform)
  },
  put(form) {
    return Api().put(`form/${form.id}`, form)
  },
  delete(id) {
    return Api().delete(`form/delete/${id}`)
  },
  choose(query) {
    return Api().get('form/choose', {
      params: query
    })
  },
  saveitems(items) {
    if (items) {
      return Api().post('form/saveitems', items)
    }
    return
  },
  createzpform(data) {
    return Api().post(`mainledger/createzpform`, data)
  },
  uploadzpform(query) {
    return Api().get(`mainledger/uploadzpform`, {
      params: query
    }, {
      responseType: 'blob'
    })
  },
  createppoform(data) {
    return Api().post(`mainledger/createppoform`, data)
  },
  uploadppoform(query) {
    return Api().get(`mainledger/uploadppoform`, {
      params: query
    }, {
      responseType: 'blob'
    })
  },
  createpdvsform(data) {
    return Api().post(`mainledger/createpdvsform`, data)
  },
  uploadpdvsform(query) {
    return Api().get(`mainledger/uploadpdvsform`, {
      params: query
    }, {
      responseType: 'blob'
    })
  },
}