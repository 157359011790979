<template>
  <div>

  </div>
</template>

<script>
import langEn from './salarieDescEn'
import langHr from './salarieDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'
import {round} from '@/snippets/allSnippets'
import xlsx from 'json-as-xlsx'
// import { parseNum } from '@/snippets/allSnippets'

export default {
  name: 'salarieoprintsynteticexcel',
  props: [],
  data () {
    return {
      msg: '',
      documentName: '',
      documentName2: '',
      document: {},
      havePath: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locDocument: {},
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      comp: {},
      mainQuery: {},
      ana: {},
      totalIz: 0,
      totalNa: 0,
      Bruto1: 0,
      Bruto3: 0,
      Neto3: 0,
      FinBruto1: 0,
      dodIz: 0,
      FinBruto2: 0,
      dopIz: 0,
      FinNeto1ls: 0,
      FinFreeTotalToUse: 0,
      FinTax1Am: 0,
      FinTax12Am: 0,
      FinNeto2: 0,
      neoT: 0,
      obuT: 0,
      FinNeto3: 0,
      FinBruto3: 0
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = {...langEn}
      this.langC = {...commEn}
    }
    if (this.$store.state.language === 'HR') {
      this.lang = {...langHr}
      this.langC = {...commHr}
    }

  },
  methods: {


    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async exportExcel() {
      this.doExport()
    },
    doExport() {
      this.comp = this.$store.state.companyActiv
      this.ana = this.$store.state.printObj ? this.$store.state.printObj : {}
      const lines = []
      const f1 ={}
      f1.Opis = this.comp.CompanyNameShort
      lines.push(f1)
      const f2 ={}
      f2.Opis = this.comp.CompanyStreet + ' ' + this.comp.CompanyBuildingNameNumber
      lines.push(f2)
      const f3 ={}
      f3.Opis = this.comp.CompanyPostcode + ' ' + this.comp.CompanyCity
      lines.push(f3)
      const f4 ={}
      f4.Opis = 'OIB:' + this.comp.CompanyVATID  + (this.comp.Companyemail ? ', email:' +  this.comp.Companyemail: ' ') + ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a')
      lines.push(f4)

      const f5 ={}
      f5.Opis = 'Plaća - lista' 
      lines.push(f5)

      const f6 ={}
      f6.Opis = 'za ' + (this.ana.Month ? this.ana.Month + '/' : '') + (this.ana.Year ? this.ana.Year + ', ('  : '') + (this.ana.Order ? this.ana.Order + ')' : '')
      lines.push(f6)

      this.order = 0
      this.Bruto1 = 0
      this.Bruto3 = 0
      this.Neto3 = 0
      this.FinBruto1 = 0
      this.dodIz = 0
      this.FinBruto2 = 0
      this.dopIz = 0
      this.FinNeto1ls = 0
      this.FinFreeTotalToUse = 0
      this.FinTax1Am = 0
      this.FinTax12Am = 0
      this.FinNeto2 = 0
      this.neoT = 0
      this.obuT = 0
      this.FinNeto3 = 0
      this.FinBruto3 = 0
      if (this.ana.head1) {
        this.ana.head1.map(item => {
            this.order += 1
            // const haveBrutoAddition1 = parseFloat(item.FinKoef1) 
            // const haveBrutoAddition2 = parseFloat(item.FinKoef2)
            // const haveBrutoAddition = haveBrutoAddition1 + haveBrutoAddition2
            const dodIz1 = item.FinKoef1 && item.FinKoef1 !== 0 ? (item.FinKoef1) : 0
            const dodIz2 = item.FinKoef2 && item.FinKoef2 !== 0 ? (item.FinKoef2) : 0
            const dodIz = dodIz1 + dodIz2
            const dopIz1 = item.FinFrom1_Amount && item.FinFrom1_Amount !== 0 ? (item.FinFrom1_Amount) : 0
            const dopIz2 = item.FinFrom2_Amount && item.FinFrom2_Amount !== 0 ? (item.FinFrom2_Amount) : 0
            const dopIz3 = item.FinFrom3_Amount && item.FinFrom3_Amount !== 0 ? (item.FinFrom3_Amount) : 0
            const dopIz4 = item.FinFrom4_Amount && item.FinFrom4_Amount !== 0 ? (item.FinFrom4_Amount) : 0
            const dopIz = dopIz1 + dopIz2 + dopIz3 +dopIz4
            const neo1 = item.FinAdd1_Amount && item.FinAdd1_Amount !== 0 ? (item.FinAdd1_Amount) : 0
            const neo2 = item.FinAdd2_Amount && item.FinAdd2_Amount !== 0 ? (item.FinAdd2_Amount) : 0
            const neo3 = item.FinAdd3_Amount && item.FinAdd3_Amount !== 0 ? (item.FinAdd3_Amount) : 0
            const neo4 = item.FinAdd4_Amount && item.FinAdd4_Amount !== 0 ? (item.FinAdd4_Amount) : 0
            const neoT = neo1 + neo2 + neo3 + neo4
            const obu1 = item.Fin1LoanBankAmount !== 0 ? (item.Fin1LoanBankAmount) : 0
            const obu2 = item.Fin2LoanBankAmount !== 0 ? (item.Fin2LoanBankAmount) : 0
            const obu3 = item.Fin3LoanBankAmount !== 0 ? (item.Fin3LoanBankAmount) : 0
            const obuT = obu1 + obu2 + obu3

            this.Bruto1 += ((item.FinBruto1))
            this.Bruto3 += ((item.FinBruto3))
            this.Neto3 += ((item.FinNeto3))
            this.FinBruto1 += ((item.FinBruto1))
            this.dodIz += dodIz
            this.FinBruto2 += item.FinBruto2
            this.dopIz += dopIz
            this.FinNeto1ls += item.FinNeto1ls
            this.FinFreeTotalToUse += item.FinFreeTotalToUse
            this.FinTax1Am += item.FinTax1Am
            this.FinTax12Am += item.FinTax2Am
            this.FinNeto2 += item.FinNeto2
            this.neoT += neoT
            this.obuT += obuT
            this.FinNeto3 += item.FinNeto3
            this.FinBruto3 += item.FinBruto3

            const l ={}
            l.Opis = ''
            l.Order = this.order
            l.Radnik = (item.First ? item.First + ' ' : '') + (item.Last ? item.Last  : '')
            l.Umjesecu = item.Order
            l.Mjesec = item.Month
            l.Godina = item.Year
            l.FinBruto1 = (item.FinBruto1)
            l.dodIz = (dodIz)
            l.FinBruto2 = (item.FinBruto2 )
            l.dopIz = (dopIz)
            l.FinNeto1ls = (item.FinNeto1ls )
            l.FinFreeTotalToUse = (item.FinFreeTotalToUse )
            l.FinTax1Per = (item.FinTax1Per !== 0 ? (item.FinTax1Per)  : '')
            l.FinTax1Am  = (item.FinTax1Am )
            l.FinTax2Per = (item.FinTax2Per !== 0 ? (item.FinTax2Per)  : '')
            l.FinTax12Am = (item.FinTax12Am )
            l.FinNeto2 = (item.FinNeto2 )
            l.neoT = (neoT )
            l.obuT = (obuT )
            l.FinNeto3 = (item.FinNeto3 )
            l.FinBruto3 = (item.FinBruto3 )
            lines.push(l)
        })
      }

      const l ={}
      l.Opis = ''
      l.Order = ''
      l.Radnik = 'UKUPNO:'
      l.Umjesecu = ''
      l.Mjesec = ''
      l.Godina = ''
      l.FinBruto1 = round(this.FinBruto1 ,2)
      l.dodIz = round(this.dodIz ,2)
      l.FinBruto2 = round(this.FinBruto2 ,2)
      l.dopIz = round(this.dopIz ,2)
      l.FinNeto1ls = round(this.FinNeto1ls ,2)
      l.FinFreeTotalToUse = round(this.FinFreeTotalToUse ,2)
      l.FinTax1Per = ''
      l.FinTax1Am = round(this.FinTax1Am ,2)
      l.FinTax2Per = ''
      l.FinTax12Am = round(this.FinTax12Am ,2)
      l.FinNeto2 = round(this.FinNeto2 ,2)
      l.neoT = round(this.neoT ,2)
      l.obuT = round(this.obuT ,2)
      l.FinNeto3 = round(this.FinNeto3 ,2)
      l.FinBruto3 = round(this.FinBruto3 ,2)
      lines.push(l)

      const companyName = this.$store.state.companyActiv && this.$store.state.companyActiv.CompanyNameDatabase ? this.$store.state.companyActiv.CompanyNameDatabase : ''
      const currDate = dayjs().format('DD_MM_YYYY_HH_MM')
      const sheetNm = 'Placa_' + this.$store.state.businessYear + '_' + currDate
      let data = [
      {
        sheet: sheetNm,
        columns: [
          {label: 'Opis', value: 'Opis'},
          {label: 'Rbr', value: 'Order'},
          {label: 'Radnik', value: 'Radnik'},
          {label: 'Baza broj', value: 'Umjesecu'},
          {label: 'Mjesec', value: 'Mjesec'},
          {label: 'Godina', value: 'Godina'},
          {label: 'Bruto 1', value: 'FinBruto1', format: "€#,##0.00" },
          {label: 'Bruto dod.', value: 'dodIz', format: "€#,##0.00" },
          {label: 'Bruto 2', value: 'FinBruto2', format: "€#,##0.00" },
          {label: 'Dop.IZ', value: 'dopIz', format: "€#,##0.00" },
          {label: 'Dohodak', value: 'FinNeto1ls', format: "€#,##0.00" },
          {label: 'Os.odb', value: 'FinFreeTotalToUse', format: "€#,##0.00" },
          {label: 'Por.% niž.st.',value: 'FinTax1Per' },
          {label: 'Por.Izn.' , value: 'FinTax1Am', format: "€#,##0.00" },
          {label: 'Por% viš.st.', value: 'FinTax2Per' },
          {label: 'Por2.Izn.', value: 'FinTax12Am', format: "€#,##0.00"},
          {label: 'Neto' , value: 'FinNeto2', format: "€#,##0.00" },
          {label: 'Neoporezivo' , value: 'neoT', format: "€#,##0.00" },
          {label: 'Obust.', value: 'obuT', format: "€#,##0.00" },
          {label: 'Neto isplata(neto+neopo.-obu.)', value: 'FinNeto3', format: "€#,##0.00" },
          {label: 'UK.trošak(bruto 2+dopr.NA)', value: 'FinBruto3', format: "€#,##0.00" },
        ],
        content: lines
      },
    ]

      const settings = {
        fileName: companyName + '_PlacaLista_' + currDate, // Name of the spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
      }
      xlsx( data, settings)
    }





  },
  computed: {
  },
  watch: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
