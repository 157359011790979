<template>
  <div>
            <!-- 1 -->
            <v-row dense class="ma-0 pa-0">
              <v-col cols="12" sm="6" md="6" lg="2" xl="2" class="headline ma-0 pa-0">
                Broj knjženja:
              </v-col>
              
             <v-col cols="12" sm="6" md="6" lg="2" xl="2" class="ma-0 pa-0">
                  <span class="headline">{{ locBookID }} </span>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="2" xl="2" class="ma-0 pa-0">

              </v-col>

              <v-col cols="12" sm="6" md="6" lg="4" xl="4" class="headline ma-0 pa-0">
                <span style="font-weight:bold">{{mainledger.partnerName}} </span>
              </v-col>
              
            </v-row>

            <!-- 2 -->
            <v-row dense class="ma-0 pa-0">
              <v-col cols="12" sm="6" md="6" lg="2" xl="2" class="ma-0 pa-0">
                Vrsta:
              </v-col>
              
              <v-col cols="12" sm="6" md="6" lg="2" xl="2" class="ma-0 pa-0">
                  {{ mainledger.BookTypeID }}
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="2" xl="2" class="ma-0 pa-0">

              </v-col>

              <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="ma-0 pa-0">
                   {{mainledger.partnerStreet}}, {{mainledger.partnerBuildingNameNumber}}
              </v-col>

            </v-row>
        
            <!-- 3 -->
            <v-row dense class="ma-0 pa-0">
              <v-col cols="12" sm="6" md="6" lg="2" xl="2" class="ma-0 pa-0">
                {{lang.CompanyName}}
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="2" xl="2" class="ma-0 pa-0">
                 <span style="font-weight:bold">{{mainledger.CompanyNameDatabase}}</span>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="2" xl="2" class="ma-0 pa-0">

              </v-col>


              <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="ma-0 pa-0">
                   {{mainledger.partnerCity}}, {{mainledger.partnerPostcode}}
              </v-col>

            </v-row>

            <!-- 4 -->

            <v-row dense class="ma-0 pa-0">
              <v-col cols="12" sm="6" md="6" lg="2" xl="2" class="ma-0 pa-0">
                {{lang.BusinessYear}}
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="2" xl="2" class="ma-0 pa-0">
                <span style="font-weight:bold">{{parseInt(mainledger.BusinessYear)}}</span>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="2" xl="2" class="ma-0 pa-0">

              </v-col>

              <v-col cols="12" sm="12" md="12" lg="1" xl="1" class="ma-0 pa-0">
                 {{lang.VATID}} 
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="2" xl="2" class="ma-0 pa-0">
                 <span style="font-weight:bold">{{mainledger.partnerVATID}} </span>
              </v-col>

            </v-row>

            <!-- 5 -->
            <v-row dense class="ma-0 pa-0">
               <v-col cols="12" sm="6" md="6" lg="2" xl="2" class="ma-0 pa-0">
                {{lang.User}}
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="2" xl="2" class="ma-0 pa-0">
                <span style="font-weight:bold"> {{mainledger.email}}, {{mainledger.UserId}}</span>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="2" xl="2" class="ma-0 pa-0">

              </v-col>
              <v-col cols="12" sm="12" md="12" lg="1" xl="1" class="ma-0 pa-0">
                 IBAN:
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="2" xl="2" class="ma-0 pa-0">
                 <span style="font-weight:bold">{{mainledger.partnerBankIBAN}} </span>
              </v-col>

             

            </v-row>

            <!-- 6 -->
            <v-row dense class="ma-0 pa-0">
              <v-col cols="12" sm="12" md="12" lg="4" xl="4" class="ma-0 pa-0">
                <small>{{haveDates}}</small>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="2" xl="2" class="ma-0 pa-0">

              </v-col>
              <v-col cols="12" sm="12" md="12" lg="2" xl="2" class="ma-0 pa-0">
                 {{lang.BookingDate}}
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="2" xl="2" class="ma-0 pa-0">
                 <span style="font-weight:bold">{{locBookingDate}} </span>
              </v-col>
            </v-row>

             <!-- 7 -->
            <v-row dense class="ma-0 pa-0">
              <v-col cols="12" sm="12" md="12" lg="4" xl="4" class="ma-0 pa-0">

              </v-col>
              <v-col cols="12" sm="12" md="12" lg="2" xl="2" class="ma-0 pa-0">

              </v-col>
              <v-col cols="12" sm="12" md="12" lg="2" xl="2" class="ma-0 pa-0">
                 {{lang.InvoiceDate}}
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="2" xl="2" class="ma-0 pa-0">
                 <span style="font-weight:bold">{{locInvoiceDate}} </span>
              </v-col>
            </v-row>

            <!-- 8 -->
            <v-row dense class="ma-0 pa-0">
              <v-col cols="12" sm="12" md="12" lg="4" xl="4" class="ma-0 pa-0">

              </v-col>
              <v-col cols="12" sm="12" md="12" lg="2" xl="2" class="ma-0 pa-0">

              </v-col>
              <v-col cols="12" sm="12" md="12" lg="2" xl="2" class="ma-0 pa-0">
                 {{lang.InvoicePaymentDeadlineDate}}
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="2" xl="2" class="ma-0 pa-0">
                 <span style="font-weight:bold">{{locInvoicePaymentDeadlineDate}} </span>
              </v-col>
            </v-row>

          <span v-if="this.$store.state.documentSide === 1 || this.$store.state.documentSide === 2">

              <v-divider class="mt-1"></v-divider>

              <v-row class="pt-3">
                <v-flex xs3 class="headline px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                      Invoice nr: 
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                      Broj računa:
                    </span>
                </v-flex>

                <v-flex xs6 class="headline">
                   {{mainledger.InvoiceNumber}}
                </v-flex>
                <v-flex xs3>
                    <span v-if="this.$store.state.language == 'EN'">
                      Delivery note: {{mainledger.DeliveryNoteNumber}}
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                      Otpr.br: {{mainledger.DeliveryNoteNumber}}
                    </span>
                </v-flex>
              </v-row> 

             <v-row>
                <v-flex xs3 class="headline px-2">
                    Iznos računa:
                </v-flex>
                <v-flex xs3 class="headline">
                  <span style="font-weight:bold">{{localMoney(mainledger.InvoiceAmount) }}</span>
                </v-flex>
                <v-flex xs1 class="py-2 px-0">
                   {{mainledger.HomeCurrency}}
                </v-flex>

                <v-flex xs2 class="py-2 px-0" v-if='hideCurrRate'>
                  Tečaj: <span style="font-weight:bold">{{mainledger.ExRate}}</span>
                </v-flex>

                <v-flex xs2 class="py-2 px-0" v-if ='hideCurrRate'>
                  <span style="font-weight:bold">{{localMoney(mainledger.InvoiceForeginAmount)}} {{localMoney(mainledger.ForeignCurrency) }}</span>
                </v-flex>
            </v-row>                           

            <v-divider class="mt-1"></v-divider>
            <v-row class="pt-3">
              <v-flex xs2 class="py-0 px-2" >
                PDV
              </v-flex>
               <v-flex xs2>
                  Iznos
              </v-flex>
              <v-flex xs2 v-if="this.$store.state.documentSide === 1">
                  Priznaje se %
              </v-flex>
              <v-flex xs2 v-if="this.$store.state.documentSide === 1">
                  Priznaje se 
              </v-flex>
              <v-flex xs2 v-if="this.$store.state.documentSide === 1">
                  NE priznaje se
              </v-flex>
              <v-flex xs2 v-if="this.$store.state.documentSide === 1">
                  Osnovica
              </v-flex>
            </v-row>
             
            <v-row>
              <v-flex xs2 class="py-0 px-2" >
                25%:
              </v-flex>

              <v-flex xs2>
                  <span style="font-weight:bold">{{ localMoney(mainledger.VAT1Amount) }}
                  </span>
              </v-flex>
              <v-flex xs2 v-if="this.$store.state.documentSide === 1">
                  <span >{{ localMoney(mainledger.VAT1PerCanUse) }}
                  </span>
              </v-flex>

              <v-flex xs2 v-if="this.$store.state.documentSide === 1">
                  <span >{{ localMoney(mainledger.VAT1CanUse) }}
                  </span>
              </v-flex>
              
              <v-flex xs2 v-if="this.$store.state.documentSide === 1">
                  <span >{{ localMoney(mainledger.VAT1CanTUse) }}
                  </span>
              </v-flex>
               <v-flex xs2 v-if="this.$store.state.documentSide === 1">
                  <span >{{ localMoney(mainledger.VAT1Base) }}
                  </span>
              </v-flex>
            </v-row>

            <v-row>
              <v-flex xs2 class="py-0 px-2" >
                13%:
              </v-flex>

              <v-flex xs2>
                  <span >{{ localMoney(mainledger.VAT2Amount) }}
                  </span>
              </v-flex>
              <v-flex xs2 v-if="this.$store.state.documentSide === 1">
                  <span >{{ localMoney(mainledger.VAT2PerCanUse) }}
                  </span>
              </v-flex>

              <v-flex xs2 v-if="this.$store.state.documentSide === 1">
                  <span >{{localMoney(mainledger.VAT2CanUse) }}
                  </span>
              </v-flex>
              
              <v-flex xs2 v-if="this.$store.state.documentSide === 1"> 
                  <span >{{ localMoney(mainledger.VAT2CanTUse) }}
                  </span>
              </v-flex>
              <v-flex xs2 v-if="this.$store.state.documentSide === 1">
                  <span >{{ localMoney(mainledger.VAT2Base) }}
                  </span>
              </v-flex>
            </v-row>

            <v-row>
              <v-flex xs2 class="py-0 px-2" >
                5%:
              </v-flex>

              <v-flex xs2>
                 <span >{{ localMoney(mainledger.VAT3Amount) }}</span>
              </v-flex>
                <v-flex xs2 v-if="this.$store.state.documentSide === 1">
                  <span >{{ localMoney(mainledger.VAT3PerCanUse) }}</span>
                </v-flex>

                <v-flex xs2 v-if="this.$store.state.documentSide === 1">
                    <span >{{ localMoney(mainledger.VAT3CanUse) }}
                    </span>
                </v-flex>
                
                <v-flex xs2 v-if="this.$store.state.documentSide === 1">
                    <span >{{ localMoney(mainledger.VAT3CanTUse) }}
                    </span>
                </v-flex>
                <v-flex xs2 v-if="this.$store.state.documentSide === 1">
                  <span >{{ localMoney(mainledger.VAT1Base) }}
                  </span>
              </v-flex>
            </v-row>

            <v-row>
              <v-flex xs2 class="py-0 px-2" >
                Ukupno:
              </v-flex>

              <v-flex xs2>
                   <span >{{ localMoney(mainledger.AmountVAT) }}</span>
              </v-flex>
              
              <v-flex xs2>
                   
              </v-flex>

                <v-flex xs2 v-if="this.$store.state.documentSide === 1">   
                    <span >{{ locCanUse }}
                    </span>
                </v-flex>

                <v-flex xs2 v-if="this.$store.state.documentSide === 1">   
                    <span >{{ locCantUse }}
                    </span>
                </v-flex>

            </v-row>            



            <v-row>
              <v-flex xs3 class="py-0 px-2" >
                Oporeziva osnovica:
              </v-flex>

              <v-flex xs2>
                <span >{{ localMoney(mainledger.AmountNetto) }}
                </span> 
              </v-flex>
              
              <v-flex xs4>
                  = Neoporezivo ukupno:
              </v-flex>

              <v-flex xs2>
                <span >={{ localMoney(mainledger.VAT4Base) }}</span>
              </v-flex>

            </v-row> 


             <v-row v-if="this.$store.state.documentSide === 1" class="pt-3">
                  <v-flex xs3 class="py-0 px-2">
                    Ulaz na skladište:
                  </v-flex>
                  <v-flex xs2>
                    Broj skladišta <span style="font-weight:bold">{{mainledger.WareHouseId}}</span>
                  </v-flex>
                  <v-flex xs2>
                    Broj primke <span style="font-weight:bold">{{mainledger.WareHouseEntryt}}</span>
                  </v-flex>
                  <v-flex xs2>
                    Iznos primke 
                  </v-flex>
                  <v-flex xs2>
                    <span style="font-weight:bold">{{ localMoney(mainledger.WareHouseEntryAmount) }}</span>
                  </v-flex>
              </v-row>

              <v-row v-if="this.$store.state.documentSide === 2" class="pt-3">
                  <v-flex xs3 class="py-0 px-2">
                    Izlaz iz skladišta:
                  </v-flex>
                  <v-flex xs2>
                    Broj sklad.<span style="font-weight:bold">{{mainledger.WareHouseId}}</span>
                  </v-flex>
                  <v-flex xs2>
                    Broj otpr.<span style="font-weight:bold">{{mainledger.WareHouseEntryt}}</span>
                  </v-flex>
                  <v-flex xs4>
                    Iznos otpr.<span style="font-weight:bold">{{ localMoney(mainledger.WareHouseEntryAmount) }}</span>
                  </v-flex>
              </v-row>

          </span>

            <v-row no-gutters class="pt-3">
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-text-field 
                      label="Skenirani dokument"
                      v-model="mainledger.MLFree2"
                      disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  <v-btn text small color="primary" @click="showPdf" class="mr-1" v-if="mainledger.MLFree2">
                      Prikazi
                  </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mt-1"></v-divider>
            <v-row no-gutters v-if="showTotals" class="pt-3">

              <v-flex xs3 class="headline px-2" >
                Uk.duguje:
              </v-flex>

              <v-flex xs2 class="headline">
                <span >{{ localMoney(debitTotal) }}
                </span> 
              </v-flex>
              
              <v-flex xs2 class="headline">
                  Uk.potraž.:
              </v-flex>

              <v-flex xs2 class="headline">
                <span >{{ localMoney(creditTotal) }}</span>
              </v-flex>

              <v-flex xs2 >
                <span class="headline red white--text" v-if="diffTotal != 0">{{ localMoney(diffTotal) }}</span>
                <span class="headline green white--text" v-if="closedAccount">{{ localMoney(diffTotal) }}</span>
              </v-flex>
            </v-row> 

        <div class="pink darken-2" v-html="error" />

  </div>
</template>

<script>
import MainLedgerService from '@/services/MainLedgerService'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dayjs from 'dayjs'
import moneyFormat from '@/snippets/moneyFormat'

export default {
  name: 'mainledgerview2',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      date1: null,
      date2: null,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      msg: '',
      valid: true,
      documentName: '',
      documentNameLoc: '',
      mainledger: { },
      mainledgerActiv: {},
      error: null,
      localError: false,
      newDocument: {},
      confpassword: '',
      size: 'sm',
      required: (value) => !!value || 'Required.',
      partners: [],
      employes: [],
      banks: [],
      currnecy: [],
      users: [],
      CompanyId: 0,
      selectPa: {},
      selectBank: {},
      selectCR: {},
      selectUs: {},
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
        documentName: '',
        MLType: 0
      },
      currnecyType: [
        { text: 'Kn', value: 'Kn' },
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      bookType: [],
      money: {
        prefix: '= ',
        suffix: ' ',
        precision: 3
      },
      price: 7.500,
      locCreatedAt: '',
      locUpdatedAt: '',
      locBookingDate: '',
      locInvoiceDate: '',
      locInvoicePaymentDeadlineDate: '',
      locBookID: 0,
      locCanUse: 0,
      locCantUse: 0,
      mldetails: [],
      showTotals: true,
      lang: {},
      langC: {},
      haveDates: null,
      hideCurrRate: false
    }
  },
  components: {
  },
  async mounted () {
    try {
      if (this.$store.state.language === "EN") {
        this.lang = {...ledgerEN}
        this.langC = {...commEn}
      }
      if (this.$store.state.language === "HR") {
        this.lang = {...ledgerHR}
        this.langC = {...commHr}
      }
      this.mainledger = this.$store.state.mainledgerActiv ? this.$store.state.mainledgerActiv : {}
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      if (this.$store.state.companyid) {
        this.CompanyId = await this.$store.state.companyid
        this.mainQuery.CompanyId = await this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.BusinessYear = this.$store.state.businessYear
        this.hideCurrRate = this.$store.state.businessYear > 2022 ? false : true
      }
      if (this.$store.state.documentActiv) {
        this.mainQuery.documentName = this.$store.state.documentActiv
      }
      this.locCreatedAt = this.mainledger.createdAt ? dayjs(this.mainledger.createdAt).format('DD.MM.YYYY HH:MM') : ''
      this.locUpdatedAt = this.mainledger.updatedAt ? dayjs(this.mainledger.updatedAt).format('DD.MM.YYYY HH:MM') : ''
      this.haveDates = this.langC.CreatedAt + ' ' + this.locCreatedAt + ', ' + this.langC.UpdatedAt + ' ' + this.locUpdatedAt
      this.locBookingDate = dayjs(this.mainledger.BookingDate).format('DD.MM.YYYY')
      this.locInvoiceDate = dayjs(this.mainledger.InvoiceDate).format('DD.MM.YYYY')
      this.locInvoicePaymentDeadlineDate = dayjs(this.mainledger.InvoicePaymentDeadlineDate).format('DD.MM.YYYY')
      this.locBookID = parseInt(this.mainledger.BookID)
      this.locCanUse = parseFloat(this.mainledger.VAT1CanUse) + parseFloat(this.mainledger.VAT2CanUse) + parseFloat(this.mainledger.VAT3CanUse)
      this.locCantUse = parseFloat(this.mainledger.VAT1CanTUse) + parseFloat(this.mainledger.VAT2CanTUse) + parseFloat(this.mainledger.VAT3CanTUse)
      this.showTotals = this.$store.state.mainledgerTotals === 1 ? false : true 
    } catch (error) {
      // eslint-disable-next-line
      console.log('Error on mount:', error)
    }
  },
  computed: {
    debitTotal () {
      return this.$store.state.mainledgerActiv.DebitTotal
    },
    creditTotal () {
      return this.$store.state.mainledgerActiv.CreditTotal
    },
    diffTotal () {
      return this.$store.state.mainledgerActiv.DiffTotal
    },
    closedAccount () {
      return this.$store.state.mainledgerActiv.ClosedAccount
    }
  },
  methods: {
    localMoney(moneyToFormat) {
        return moneyFormat(moneyToFormat)
      },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    close () {
      this.show = false
    },
    async mainledgeragain () {
      try {
        this.$forceUpdate()
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async showPdf() {
      if (this.mainledger.MLFree5) {
        const showFile ={
          showFile: this.mainledger.MLFree5
        }
        await MainLedgerService.showPdf(showFile)
        .then(res => {
          const data = res.data
          var byteCharacters = atob(data);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], { type: "application/pdf" });

          const fileurl = window.URL.createObjectURL(blob)
          window.open(fileurl)
          
        }).catch(error => {
          this.text = 'Error ' + error
          this.color = 'red'
          this.snackbar = true
        })
      } else {
        alert('Sorry, document is not defined! Can not open.')
      }
    },
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>

