<template>
  <div class="mainledgerprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import { parseNum } from '@/snippets/allSnippets'
import dayjs from 'dayjs'

export default {
  name: 'mainledgerprintlla',
  props: ['card'],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0,
      partner: {},
      businessYear: 0,
      llaBuyingValue: 0,
      llaDepreciationAmountTotal: 0,
      llaBookValue: 0,
      llaDepreciationAmountYear: 0,
      llaValueYearEnd: 0,
      lllaValueYearEnd: 0
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    
  },
  methods: {
    async print () {
      if (this.card) {
        // if (this.card.items && this.card.items.length > 0) {
        //   this.businessYear = parseInt(this.card.items[0].BusinessYear)
        // }
        this.businessYear = this.$store.state.businessYear
        await this.initDocument()
        this.addEmptyRow()
        this.addHeaderNoLogo()
        this.addLine()
        // this.addHeader12()
        this.addLine()
        this.addItemsHeader()
        this.addLine()
        this.addItems()
        this.addLine()
        this.addItemsFoter()
   
        this.docDefinition.content = this.content
        pdfMake.createPdf(this.docDefinition).open()
      }
      
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'landscape'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.content = []
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 800,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addLineDash () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1,
                dash: { length: 1 },
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '45%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // % width
                width: '30%',
                text: [
                  { text: 'Osnovna sredstva \n' , fontSize: 12, bold: true},
                  { text: 'Poslovna godina: ' + this.businessYear +  '\n', fontSize: 12, bold: true},
                ]   
              },
              // {
              //   // % width
              //   width: '45%',
              //   text: [
              //     { text: 'Konto: ' + card.account4 + '\n' , fontSize: 12, bold: true},
              //     { text: '' + card.account4Description+ '\n', fontSize: 8},
              //   ]   
              // },
              {
                // % width
                width: '20%',
                text: [                 
                  { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeader12() {
      const locCreatedAt = dayjs(this.card.head.createdAt).format('DD.MM.YYYY HH:MM')
      const locUpdatedAt = dayjs(this.card.head.updatedAt).format('DD.MM.YYYY HH:MM')
      const locBookingDate = dayjs(this.card.head.BookingDate).format('DD.MM.YYYY')
      const locInvoiceDate = dayjs(this.card.head.InvoiceDate).format('DD.MM.YYYY')
      const locInvoicePaymentDeadlineDate = dayjs(this.card.head.InvoicePaymentDeadlineDate).format('DD.MM.YYYY')
      const locBookID = parseInt(this.card.head.BookID)
     // const locCanUse = parseFloat(this.card.head.VAT1CanUse) + parseFloat(this.card.head.VAT2CanUse) + parseFloat(this.card.head.VAT3CanUse)
     // const locCantUse = parseFloat(this.card.head.VAT1CanTUse) + parseFloat(this.card.head.VAT2CanTUse) + parseFloat(this.card.head.VAT3CanTUse)
      const docPart = {
        columns: [
          {
            // auto-sized columns have their widths based on their content
            width: '45%',
            text: [
              { text: 'Broj knjiženja:' + locBookID +  ' \n', fontSize: 10, bold: true },
              { text: 'Vrsta: ' + this.card.head.BookTypeID + ', ' + this.card.head.BookName + ' \n', fontSize: 8 },
              { text: 'Kreiran: ' + locCreatedAt + ', Izmjena:' + locUpdatedAt + ' \n', fontSize: 8 },
              { text: 'Korisnik: ' + this.card.head.First + ' ' + this.card.head.Last + ', ' + this.card.head.email + ' \n', fontSize: 8 },
            ]
          },
          {
            // % width
            width: '30%',
            text: [
              { text: 'Osnovna sredstva \n' , fontSize: 12, bold: true},
              { text: '\n', fontSize: 8},
            ]   
          },
          {
            // % width
            width: '20%',
            text: [                 
              { text: ' Datum knjiženja: ' + locBookingDate +  ' \n', fontSize:8},
              { text: ' Datum računa: ' + locInvoiceDate +  ' \n', fontSize:8},
              { text: ' Rok plaćanja: ' + locInvoicePaymentDeadlineDate +  ' \n', fontSize:8},
              { text: ' Partner: ' + this.card.head.partnerName +  ' \n', fontSize:8, bold: true},
              { text: ' Partner OIB: ' + this.card.head.partnerVATID +  ' \n', fontSize:8},
            ]   
          }

        ],
        // optional space between columns
        columnGap: 10
      }
    this.content.push(docPart)
    },
    addItemsHeader () {
      const docPart = {
          columns: [
                {
                  width: '5%',
                  fontSize: 8,
                  alignment: 'left',
                  text: 'Rb.'
                },
                {
                  width: '5%',
                  fontSize: 8,
                  alignment: 'left',
                  text: this.lang.llaID
                },
                {
                  width: '6%',
                  fontSize: 8,
                  alignment: 'left',
                  text: this.lang.llaCode
                },
                {
                  width: '18%',
                  fontSize: 8,
                  alignment: 'left',
                  text: this.lang.llaName
                },
                {
                  width: '8%',
                  fontSize: 8,
                  alignment: 'left',
                  text: this.lang.llllaDocumentSourceaID
                },
                {
                  width: '8%',
                  fontSize: 8,
                  alignment: 'left',
                  text: this.lang.llaDocumentSourceDate
                },
                {
                  width: '8%',
                  fontSize: 8,
                  alignment: 'right',
                  text: this.lang.llaBuyingValue
                },
                {
                  width: '8%',
                  fontSize: 8,
                  alignment: 'right',
                  text: this.lang.llaBookValue
                },
                {
                  width: '8%',
                  fontSize: 8,
                  alignment: 'right',
                  text: this.lang.llaLasting
                },
                {
                  width: '5%',
                  fontSize: 8,
                  alignment: 'center',
                  text: this.lang.llaDepreciationPercent
                },
                {
                  width: '8%',
                  fontSize: 8,
                  alignment: 'left',
                  text: this.lang.llaDepreciationAmountYear
                },
                {
                  width: '8%',
                  fontSize: 8,
                  alignment: 'left',
                  text: this.lang.llaDepreciationAmountTotal
                },
                {
                  width: '8%',
                  fontSize: 8,
                  alignment: 'left',
                  text: this.lang.llaValueYearEnd
                },
              ]
           
      }
      this.content.push(docPart)   
    },

    addItems () {
           this.order = 0           
            if (this.card.items && this.card.items.length > 0) {
              this.card.items.map(item => {
                  this.order += 1
                  this.llaBuyingValue += parseNum(item.llaBuyingValue)
                  this.llaDepreciationAmountTotal += parseNum(item.llaDepreciationAmountTotal)
                  this.llaBookValue += parseNum(item.llaBookValue)
                  this.llaDepreciationAmountYear += parseNum(item.llaDepreciationAmountYear)
                  this.lllaValueYearEnd += parseNum(item.llaValueYearEnd)
                  // this.pTtt5 = item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0
                  // this.pTtt6 = item.itemDiscountPercentage1 ? this.formatPrice(item.itemDiscountPercentage1) : 0
                  // this.pTtt7 = item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0
                  // this.pTtt8 = item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0
                  // this.pTtt9 = item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0

                    // {text: this.lang.llaID, value: 'llaID', align: 'left', width: '100px' },
                    // {text: this.lang.llaCode, value: 'llaCode', align: 'left', width: '100px' },
                    // {text: this.lang.llaName, value: 'llaName', align: 'left', width: '150px' },
                    // {text: this.lang.llaDocumentSource, value: 'llllaDocumentSourceaID', align: 'left', width: '150px' },
                    // {text: this.lang.llaDocumentSourceDate, value: 'llaDocumentSourceDate', width: '150px', type: 'date', dateInputFormat: 'YYYY-MM-DD', dateOutputFormat: 'DD.MM.YYYY'},
                    // {text: this.lang.llaBuyingValue, value: 'llaBuyingValue', width: '150px', type: 'number'},
                    // {text: this.lang.llaBookValue , value: 'llaBookValue', width: '150px', type: 'number'},
                    // {text: this.lang.llaLasting, value: 'llaLasting', width: '50px', type: 'number'},
                    // {text: this.lang.llaDepreciationPercent, value: 'llaDepreciationPercent', width: '50px', type: 'number'},
                    // {text: this.lang.llaDepreciationAmountYear, value: 'llaDepreciationAmountYear', width: '100px', type: 'number'},
                    // {text: this.lang.llaDepreciationAmountTotal, value: 'llaDepreciationAmountTotal', width: '100px'},
                    // {text: this.lang.llaValueYearEnd, value: 'llaValueYearEnd', width: '100px', type: 'number'},
                    // {text: this.lang.llaMark, value: 'llaMark', width: '50px', type: 'number'},

                      const docPart01 = {
                      columns: [
                            {
                              width: '5%',
                              fontSize: 8,
                              alignment: 'left',
                              text: parseInt(this.order) + '.'
                            },
                            {
                              width: '5%',
                              fontSize: 8,
                              alignment: 'left',
                              text: item.llaID
                            },
                            {
                              width: '6%',
                              fontSize: 8,
                              alignment: 'left',
                              text: item.llaCode
                            },
                            {
                              width: '22%',
                              fontSize: 8,
                              alignment: 'left',
                              text: item.llaName
                            },
                            {
                              width: '5%',
                              fontSize: 8,
                              alignment: 'left',
                              text: item.llllaDocumentSourceaID
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              alignment: 'left',
                              text: this.formatDate(item.llaDocumentSourceDate)
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              alignment: 'right',
                              text: this.formatPrice(item.llaBuyingValue)
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              alignment: 'right',
                              text: this.formatPrice(item.llaBookValue)
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              alignment: 'right',
                              text: this.formatPrice(item.llaLasting)
                            },
                            {
                              width: '5%',
                              fontSize: 8,
                              alignment: 'center',
                              text: item.llaDepreciationPercent
                            },
                            {
                              width: '8%',
                              fontSize: 8,
                              alignment: 'right',
                              text: this.formatPrice(item.llaDepreciationAmountYear)
                            },
                            {
                              width: '8%',
                              fontSize: 8,
                              alignment: 'right',
                              text: this.formatPrice(item.llaDepreciationAmountTotal)
                            },
                            {
                              width: '8%',
                              fontSize: 8,
                              alignment: 'right',
                              text: this.formatPrice(item.llaValueYearEnd)
                            },
                          ]
                        }
                      this.content.push(docPart01)
                }
              )
          }
    },
    addItemsFoter () {
       
      this.addLine()

      const docPart01 = {
           columns: [
              {
                width: '5%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '18%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.llaBuyingValue ? this.formatPrice(this.llaBuyingValue) : 0
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'right',
                text: this.llaBookValue ? this.formatPrice(this.llaBookValue) : 0
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: ''
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'center',
                text: ''
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.llaDepreciationAmountYear ? this.formatPrice(this.llaDepreciationAmountYear) : 0
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.llaDepreciationAmountTotal ? this.formatPrice(this.llaDepreciationAmountTotal) : 0
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.lllaValueYearEnd ? this.formatPrice(this.lllaValueYearEnd) : 0
              },
          ]
        }
      this.content.push(docPart01)
    },
  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}
  

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>