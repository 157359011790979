const docHR = {
  Grupa: 'Grupa',
  Hitno: 'Hitno',
  ValutaPlacanja1: 'Valuta ',
  Tecaj: 'Tečaj',
  ValutaPlacanja2: '',
  Platitelj1: 'Platitelj',
  Platitelj2: 'Platitelj',
  Platitelj3: 'Platitelj',
  PlatiteljIBAN: 'IBAN - platitelj',
  PlatiteljModel: 'Model',
  PlatiteljPoziv: 'Poziv',
  Opis1: 'Opis',
  Opis2: 'Opis',
  Opis3: 'Opis',
  Iznos: 'Iznos',
  Iznos2: 'Iznos 2',
  Primatelj1: 'Primatelj',
  Primatelj2: 'Primatelj',
  Primatelj3: 'Primatelj',
  PrimateljIBAN: 'IBAN - primatelj',
  PrimateljModel: 'Model',
  PrimateljPoziv: 'Poziv',
  SifraNamjene: 'Šifra namjene',
  DatumIzvrsenja: 'Datum Izvršenja',
  BrojJoppdid: '',
  BrojJoppd: 'Broj JOPPDa',
  bankAccountId: 'Banka Id',
  bankAccountName: 'Banka Naziv',
  bankAccountNr: 'Banka broj',
  NewBankOrder: 'Novi virman',
  EditBankOrder: 'Uredi virman',
  EmptyBankOrder: 'Nema virmana',
  BankOrder: 'Virmani'
}

export default docHR