<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs12 sm12 m12 lg12 ex12>

        <div id="printArea">

                <v-layout row style="border-bottom:solid 1px;">
                    <v-flex xs5>
                        <h4><b><i>{{comp.CompanyNameShort}}</i></b></h4>
                        {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}   <br />                
                        {{comp.CompanyPostcode}} {{comp.CompanyCity}}<br />
                         OIB: {{comp.CompanyVATID}}
                    </v-flex>
                    <v-flex xs3>
                      <span v-if="this.$store.state.language == 'EN'">
                      <h2>Invoices - test book and invocies </h2>
                      </span>
                      <span v-if="this.$store.state.language == 'HR'">
                      <h2>Test računa - usporedba knjiga ira i ira</h2>
                      </span>
                  </v-flex>
                    <v-flex xs 4>
                      <p class="text-md-left">
                          Datum izvještaja: {{PrintDate}}<br />
                          Telefon: {{comp.CompanyPhones}}<br />
                          email: {{comp.Companyemail}}<br />
                      </p>
                    </v-flex>
              </v-layout>

            <v-layout row style="border-bottom:solid 1px; border-top:solid 1px;">
                    <v-flex xs1 class="text-left" >
                      Račun
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Datum Račun
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Datum Knjiga
                    </v-flex>
                    <v-flex xs2 class="text-left" >
                      Partner Račun
                    </v-flex>
                    <v-flex xs2 class="text-left" >
                      Partner Knjiga
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Iznos Račun
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Iznos Knjiga
                    </v-flex>
                     <v-flex xs1 class="text-left" >
                      Greška Datum
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Greška Partner
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Greška Iznos
                    </v-flex>
              </v-layout>

                <v-layout row v-for="item in items" :key="item.id">
                    <v-flex xs1 class="text-left" >
                      {{item.BookID}}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ formatDate(item.InvoiceDate)}}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ formatDate(item.InvoiceDateBook)}}
                    </v-flex>
                    <v-flex xs2 class="text-left" >
                      {{ item.partnerName}}
                    </v-flex>
                    <v-flex xs2 class="text-left" >
                      {{ item.partnerNameBook}}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                       {{formatPrice(parseFloat(item.InvoiceAmount))}}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      <span v-if="item.InvoiceAmountBook">
                        {{formatPrice(parseFloat(item.InvoiceAmountBook))}}
                      </span>
                    </v-flex>
                     <v-flex xs1 class="text-left" >
                     <span v-if="item.errDate == false">
                        <v-chip
                          color="green"
                          text-color="white"
                        >
                          OK
                        </v-chip>
                      </span>
                      <span v-if="item.errDate">
                        <v-chip
                          color="red"
                          text-color="white"
                        >
                          x
                        </v-chip>
                      </span>

                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      <span v-if="item.errPartner == false">
                        <v-chip
                          color="green"
                          text-color="white"
                        >
                          OK
                        </v-chip>
                      </span>
                      <span v-if="item.errPartner">
                        <v-chip
                          color="red"
                          text-color="white"
                        >
                          x
                        </v-chip>
                      </span>
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                    <span v-if="item.errAmount == false">
                        <v-chip
                          color="green"
                          text-color="white"
                        >
                          OK
                        </v-chip>
                      </span>
                      <span v-if="item.errAmount">
                        <v-chip
                          color="red"
                          text-color="white"
                        >
                          x
                        </v-chip>
                      </span>
                    </v-flex>
              </v-layout>

              <v-layout row style="border-top:solid 1px;">
  
              </v-layout>

        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import MainLedgerService from '@/services/MainLedgerService'
import { defMainQuery } from './mainledgerHelper'
import dayjs from 'dayjs'
//import PartnerService from '@/services/PartnerService'

export default {
  name: 'mainledgercard5',
  data () {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      comp: {},
      error: null,
      localError: false,
      newUser: {},
      size: 'sm',
      havePath: '',
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      items: [],
      mainQuery: {},
      partner: {},
    }
  },
  components: {
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    this.mainQuery = defMainQuery()
    this.PrintDate = dayjs(new Date()).format('llll')
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    this.items = (await MainLedgerService.testbooks(this.mainQuery)).data.documents


    this.Total1 = 0
    this.Total2 = 0
    this.Total3 = 0
    this.Total4 = 0
    // let partnerVATID = null

  },
  computed: {

  },
  methods: {
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    print () {
      // opens the "print dialog" of your browser to print the element
      // var bdhtml = window.document.body.innerHTML
      // window.document.body.innerHTML = document.getElementById('printArea').innerHTML
      // window.print()
      // window.document.body.innerHTML = bdhtml
      this.$htmlToPaper('printArea')
    },

    close () {
      this.show = false
    }
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
