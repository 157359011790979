import Api from '@/services/Api'
// import { log } from 'util';

export default {
  index(query) {
    return Api().get(`mainledgerlla`, {params: query})
  },
  choose(query) {
    return Api().get(`mainledgerlla/choose`, {
      params: query
    })
  },
  card(query) {
    return Api().get(`mainledgerlla/card`, {
      params: query
    })
  },
  show(mainledgerllaid) {
    return Api().get(`mainledgerlla/${mainledgerllaid}`)
  },
  post(newmainledgerlla) {
    return Api().post('mainledgerlla', newmainledgerlla)
  },
  put(mainledgerlla) {
    return Api().put(`mainledgerlla/${mainledgerlla.id}`, mainledgerlla)
  },
  delete(databaseMLID) {
    return Api().delete(`mainledgerlla/${databaseMLID}`)
  },
  saveitems(items) {
    if (items) {
      return Api().post('mainledgerlla/saveitems', items)
    }
    return
  },
}