<template>
  <v-container fluid >
    <v-row no-gutters class="pa-0">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <VehicleCRUD />
      </v-col>
    </v-row>    
  </v-container>
</template>

<script>
import VehicleCRUD from './VehicleCRUD.vue'
import langEn from './vehicleDescEn'
import langHr from './vehicleDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'

export default {
  name: 'bankorder',
  data () {
    return {
      msg: '',
      lang: {},
      langC: {},
      locMonths: [],
      locYears: [],
      saving: false,
      postNew: false,
      mainQuery2: ''
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
  },
  methods: {
 

  },
  computed: {

  },
  watch: {

  },
  components: {
    VehicleCRUD
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
