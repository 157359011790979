const docHR = {
  Calendar: 'Kalendar',
  CalDate: 'Datum',
  CalDay: 'Dan',
  CalMonth: 'Mjesec',
  CalYear: 'Godina',
  CalDayInYear: 'Dan u godini',
  CalWeek: 'Tjedan',
  CalDescription: 'Opis',
  CalWorkDay: 'Radni dan',
  CalHolidayDescription: 'Praznik opis',
  FreeF1: 'Dan u tjednu',
  CalEmpty: 'Kalendar je prazan'
}

export default docHR