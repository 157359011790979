<template>
  <div>
    <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
    <v-card>
      <v-card-title>
        <span v-if="this.$store.state.language == 'EN'">
          Groups
        </span>
        <span v-if="this.$store.state.language == 'HR'">
          Grupe
        </span>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          v-model="search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        item-key="id"
        :search="search"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
        :items-per-page="20"
        @click:row="navigateTo"
      >
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
          From {{ pageStart }} to {{ pageStop }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// import axios from 'axios'
import GroupService from "@/services/GroupService";
// import langEn from './documentDescEn'
// import langHr from './documentDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"

export default {
  name: "grouplist",
  data() {
    return {
      msg: "",
      items: [],
      max25chars: v => v.length <= 25 || "Input too long!",
      tmp: "",
      search: "",
      pagination: {},
      rowsPerPageItems: [50],
      headers: [],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: "",
        databaseDocID: 0
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false
    };
  },
  async mounted() {
    this.$store.dispatch("setGroupid", null)
    if (this.$store.state.language === "EN") {
      //this.lang = langEn
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      //this.lang = langHr
      this.langC = commHr;
    }
    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    if (this.$store.state.businessYear) {
      this.mainQuery.businessYear = this.$store.state.businessYear
    }
    this.$store.dispatch("seteditItem", 0)
    this.saving = true
    this.items = (await GroupService.index(this.mainQuery)).data.groups
    this.saving = false
    if (this.$store.state.language === "EN") {
      this.headers.push(
        { text: "Group name", value: "GroupName", width: "200px" },
        { text: "Group Belongs To", value: "GroupBelongsTo", width: "200px" },
        { text: "DbId", value: "id", type: "number", width: "50px" }
      );
    }
    if (this.$store.state.language === "HR") {
      // console.log(' radim push 2 hr naziva ', this.$store.state.language)
      this.headers.push(
        { text: "Naziv grupe", value: "GroupName", width: "200px" },
        { text: "Grupa pripada", value: "GroupBelongsTo", width: "200px" },
        { text: "DbId", value: "id", type: "number", width: "50px" }
      );
    }
  },
  methods: {
    navigateTo(params) {
      this.$store.dispatch("setGroupid", params.id)
        this.$router.push({
        name: "groupview"
      })
    }
  },
  computed: {},
  watch: {},
  components: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
