<template>
  <v-container fluid grid-list-md>

          <v-row no-gutters>
            <v-flex xs="12" class="py-0 px-2">                
                <h1>POS - naknadni pregled računa </h1>
            </v-flex>
          </v-row>

          <v-row no-gutters>
            <v-col xs="1" sm="1" md="1" lg="1" ex="1">
                {{lang.CompanyName}}
            </v-col>
            <v-col xs="3" sm="3" md="3" lg="3" ex="3">
                <span style="font-weight:bold">{{mainQuery.CompanyNameDatabase}}</span>
            </v-col>
            <v-col xs="2" sm="2" md="2" lg="2" ex="2">
              <v-btn @click="documentprint(false)">{{lang.printPOS}}</v-btn>
            </v-col>
            <v-col xs="2" sm="2" md="2" lg="2" ex="2">
              <v-btn @click="documentprint(true)">{{lang.printPOS2}}</v-btn>
            </v-col>
            <v-col xs="2" sm="2" md="2" lg="2" ex="2">
              <v-btn @click="documentprintA4(false)">{{lang.printPOS3}}</v-btn>
            </v-col>
            <v-col xs="2" sm="2" md="2" lg="2" ex="2">
              <v-btn @click="documentprintA4(true)">{{lang.printPOS4}}</v-btn>
            </v-col>

          </v-row>

          <v-row no-gutters>
            <v-col xs="1" sm="1" md="1" lg="1" ex="1">
                {{lang.BusinessYear}}
            </v-col>
            <v-col xs="3" sm="3" md="3" lg="3" ex="3">
                <span style="font-weight:bold">{{mainQuery.BusinessYear}}</span>
            </v-col>
            <v-col xs="2" sm="2" md="2" lg="2" ex="2">
       
            </v-col>
            <v-col xs="2" sm="2" md="2" lg="2" ex="2">

            </v-col>
            <v-col xs="2" sm="2" md="2" lg="2" ex="2">
              
            </v-col>
            <v-col xs="2" sm="2" md="2" lg="2" ex="2">
              
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col xs="1" sm="1" md="1" lg="1" ex="1">
              {{lang.User}}
            </v-col>
            <v-col xs="3" sm="3" md="3" lg="3" ex="3">
       
            </v-col>
            <v-col xs="2" sm="2" md="2" lg="2" ex="2">
       
            </v-col>
            <v-col xs="2" sm="2" md="2" lg="2" ex="2">

            </v-col>
            <v-col xs="2" sm="2" md="2" lg="2" ex="2">
              <v-btn text small color="primary" @click="posview1" class="mr-1">
                    {{lang.POStoday}}
              </v-btn>
            </v-col>
            <v-col xs="2" sm="2" md="2" lg="2" ex="2">
              <v-btn color="success" @click="returnPOS" class="mr-5">Back to POS</v-btn>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col xs="3" sm="3" md="3" lg="3" ex="3">
              {{this.vat1}}
            </v-col>
            <v-col xs="3" sm="3" md="3" lg="3" ex="3">
              
            </v-col>
            <v-col xs="2" sm="2" md="2" lg="2" ex="2">

            </v-col>
            <v-col xs="2" sm="2" md="2" lg="2" ex="2">

            </v-col>
            <v-col xs="2" sm="2" md="2" lg="2" ex="2">
            
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col xs="3" sm="3" md="3" lg="3" ex="3">
              {{this.vat2}}
            </v-col>
            <v-col xs="3" sm="3" md="3" lg="3" ex="3">
              {{this.paycash}}
            </v-col>
            <v-col xs="2" sm="2" md="2" lg="2" ex="2">
              {{this.lang.documentTotalAmountNetto}}
            </v-col>
            <v-col xs="1" sm="1" md="1" lg="1" ex="1" class="text-right">
              {{localMoney(this.document.documentTotalAmountNetto)}}
            </v-col>
            <v-col xs="2" sm="2" md="2" lg="2" ex="2">
            
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col xs="3" sm="3" md="3" lg="3" ex="3">
              {{this.vat3}}
            </v-col>
            <v-col xs="3" sm="3" md="3" lg="3" ex="3">
              {{this.paycard}} <v-btn text color="primary" class="pt-3" @click="changeShowCard()">Promjeni</v-btn>
            </v-col>
            <v-col xs="2" sm="2" md="2" lg="2" ex="2">
              {{this.lang.documentTotalAmountVAT}}
            </v-col>
            <v-col xs="1" sm="1" md="1" lg="1" ex="1" class="text-right">
              {{localMoney(this.document.documentTotalAmountVAT)}}
            </v-col>
            <v-col xs="2" sm="2" md="2" lg="2" ex="2">
            
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col xs="6" sm="6" md="6" lg="6" ex="6">
              {{this.vat4}}
            </v-col>
            <v-col xs="2" sm="2" md="2" lg="2" ex="2">
              {{this.lang.documentTotalAmountBrutto}}
            </v-col>
            <v-col xs="1" sm="1" md="1" lg="1" ex="1" class="text-right">
              <span class="headline"> {{localMoney(this.document.documentTotalAmountBrutto)}}</span>
            </v-col>
            <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="text-right">
              <v-btn @click="fiskalAgain()">Fiskaliziraj</v-btn>
            </v-col>
          </v-row>
          
          <v-row v-if="!showPartner">
            <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5">
               {{document.partnerName}} <br />
               {{document.partnerStreet}},{{document.partnerPostcode}} {{document.partnerCity}} <br/>
               {{document.partnerVATID}} <br />
            </v-col>
            <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5">
              <v-btn @click="changeShowPartner()">{{lang.editPOSpartner}}</v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-1"></v-divider>

          <v-row v-if="showPartner">
            <v-col cols="12" sm="7" md="7" xs="7" lg="7" xl="7">
              <v-card>
              <v-card-text>
                  <DocumentPOSPartner :newPOS="newPOS" />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5">

            </v-col>
           
          </v-row>
          <v-row v-if="showCard">
            <v-col cols="12" sm="7" md="7" xs="7" lg="7" xl="7">
              <v-card>
              <v-card-text>
                  <DocumentPOSCardChange  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5">

            </v-col>
          </v-row>
          <v-row>
               <v-progress-circular v-if="this.saving"
                indeterminate
                color="green"
              ></v-progress-circular>
              <v-col class="text-center" color="green" cols="12" sm="3">
                <div class="my-2" v-if="fiskalStatus">
                  {{fiskalStatus.status}}
                </div>
              </v-col>

              <v-col class="text-center" cols="12" sm="3">
                <div class="my-2" v-if="fiskalStatus">
                  JIR:{{fiskalStatus.JIR}}
                </div>
              </v-col>

              <v-col class="text-center" cols="12" sm="3">
                <div class="my-2" v-if="fiskalStatus">
                  ZKI:{{fiskalStatus.JIR}}
                </div>
              </v-col>

          </v-row>
          <br>
          <app-document-print-sales ref="prnt"></app-document-print-sales>
  </v-container>
</template>

<script>
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dateFormat from '@/snippets/dateFormat'
import moneyFormat from '@/snippets/moneyFormat'
import DocumentPrintSales from './DocumentPrintSales'
import DocumentPOSPartner from './DocumentPOSPartner'
import DocumentPOSCardChange from './DocumentPOSCardChange'
import {fiskalSave, fiskalRead} from './fiskal'
import {wait} from '@/snippets/allSnippets'

export default {
  data() {
    return {
      lang: {},
      langC: {},
      mainQuery: {},
      vat1: '',
      vat2: '',
      vat3: '',
      vat4: '',
      document: {},
      paycash: '',
      paycard: '',
      payother: '',
      newPOS: false,
      showPartner: false,
      showCard: false,
      saving: false,
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      fiskalStatus: null,
      dbID: 0,
    }
  },
  mounted() {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    this.$store.dispatch('settypePOSPrint', null)
    this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
    this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
    this.mainQuery.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''

    this.document = this.$store.state.documentActivHead ? this.$store.state.documentActivHead : {}

    this.vat1 = this.lang.DocPrintVAT1 + ' ' + (this.document.documentTotalVAT1Amount ? this.localMoney(this.document.documentTotalVAT1Amount) : 0)
    this.vat1 = this.vat1 + ', ' + this.lang.DocPrintBase + ' ' + (this.document.documentTotalVAT1Base ? this.localMoney(this.document.documentTotalVAT1Base) : 0)

    this.vat2 = this.lang.DocPrintVAT2 + ' ' + (this.document.documentTotalVAT2Amount ? this.localMoney(this.document.documentTotalVAT2Amount) : 0)
    this.vat2 = this.vat2 + ', ' + this.lang.DocPrintBase + ' ' + (this.document.documentTotalVAT2Base ? this.localMoney(this.document.documentTotalVAT2Base) : 0)

    this.vat3 = this.lang.DocPrintVAT3 + ' ' + (this.document.documentTotalVAT3Amount ? this.localMoney(this.document.documentTotalVAT3Amount) : 0)
    this.vat3 = this.vat3 + ', ' + this.lang.DocPrintBase + ' ' + (this.document.documentTotalVAT3Base ? this.localMoney(this.document.documentTotalVAT3Base) : 0)
    this.vat4 = this.lang.DocPrintVAT4 + ' ' + (this.document.FreeF4 ? this.localMoney(this.document.FreeF4) : 0)

    this.paycash = this.lang.documentPOSCash + ': ' + (this.document.documentPOSCash ? this.localMoney(this.document.documentPOSCash) : 0)
    this.paycard = this.lang.documentPOSCard + ': ' + (this.document.documentPOSCard ? this.localMoney(this.document.documentPOSCard) : 0) + (this.document.status ? ', ' + this.document.status : '')

  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
    localTime(timeToFormat) {
      return timeToFormat.substr(0,5)
    },
    changeShowPartner() {
      this.showPartner = !this.showPartner
    },
    changeShowCard() {
      this.showCard = !this.showCard
    },
    async posview1() {      
      await this.$store.dispatch('settypePOSPrint', 10)
      this.markMe('/crm/document/poslist', 'POS', 'POS', 2)
    },
    markMe (path, doc, docLoc, docSide) {
      this.$store.dispatch('setDocumentActiv', doc)
      this.$store.dispatch('setDocumentActivLoc', docLoc)
      this.$store.dispatch('setDocumentSide', docSide)
      if (this.currentPath != path ) {
        this.currentPath = path
        this.$router.push({
        path
        }).catch(err => {
          // eslint-disable-next-line
          console.log(err);
        })
      }
    },
    returnPOS() {
      this.$store.dispatch('seteditItem', 0)
      this.$store.dispatch('setDocumentActivHead', {})
      this.$store.dispatch('setDocumentActivItems', [])
      this.$store.dispatch('settypePOSPrint', null)
      this.$router.push({name: 'documentpos'})
    },
    async documentprint(withPartner) {
      await this.$store.dispatch('setprintSmall', true)
      if (withPartner === false) {
        await this.$store.dispatch('settypePOSPrint', 2)
      } else {
        await this.$store.dispatch('settypePOSPrint', 3)
      }
      if (this.$store.state.documentSide === 2 || this.$store.state.documentSide === 3) {
        this.$refs.prnt.print()
      }
    },
    async documentprintA4(withPartner) {
      await this.$store.dispatch('setprintSmall', false)
      if (withPartner === false) {
        await this.$store.dispatch('settypePOSPrint', 2)
      } else {
        await this.$store.dispatch('settypePOSPrint', 3)
      }
      if (this.$store.state.documentSide === 2 || this.$store.state.documentSide === 3) {
        this.$refs.prnt.print()
      }
    },
    saveIt() {
      
    },
    async fiskalAgain(){
      this.saving = true
      this.dbID = this.document.id
      await fiskalSave(this.dbID, this.document,'P')

      //await DocumentService.documentfiskalsave(fiskalSave)
      // wait 2 seconds and try read reponse
      wait(2000)
      this.fiskalStatus = await fiskalRead(1, this.dbID)
      this.saving = false
      this.document.documentJIR = this.fiskalStatus.JIR ? this.fiskalStatus.JIR : null
      this.document.documentZKI = this.fiskalStatus.ZKI ? this.fiskalStatus.ZKI : null
      this.document.documentUUID = this.fiskalStatus.UUID ? this.fiskalStatus.UUID : null
    }

  },
  computed: {

  },
  watch: {

  },
  components: {
    appDocumentPrintSales: DocumentPrintSales,
    DocumentPOSPartner,
    DocumentPOSCardChange
  }

}
</script>