<template>
  <v-layout row justify-center>
      <v-card v-if="this.$store.state.language == 'EN'">
        <v-card-title class="headline">Are you sure to delete Employee Diary entry?</v-card-title>
        <v-card-text>We not recomend to delete if you are not 100% shure.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" flat @click="deleteEmplday">Delete</v-btn>
          <v-btn color="green darken-1" flat @click="empldaylist">Cancel</v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-if="this.$store.state.language == 'HR'">
        <v-card-title class="headline">Da li ste sigurni da zelite obrisati dnevnik za radnika?</v-card-title>
        <v-card-text>NE preporucujemo brisanje ako niste 100% sigurni. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" flat @click="deleteEmplday">Obrisi</v-btn>
          <v-btn color="green darken-1" flat @click="empldaylist">Odustani</v-btn>
        </v-card-actions>
      </v-card>

  </v-layout>
</template>

<script>
import EmplDayService from '@/services/EmplDayService'

export default {
  name: 'empldaydelete',
  data () {
    return {
      msg: '',
      dialog: false
    }
  },
  methods: {
    async deleteEmplday () {
      try {
        if (this.$store.state.empldayid) {
          await EmplDayService.delete(this.$store.state.empldayid)
          await this.$router.push({
            name: 'emplday'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async empldaylist () {
      try {
        await this.$router.push({
          name: 'emplday'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
