<template>
    <v-card class="pt-3">
        <!-- <v-card-title>
          <v-toolbar> 
            Plaća: {{Month}}/{{Year}} - {{Order}}
            <v-spacer></v-spacer> 
          </v-toolbar>
        </v-card-title> -->
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="20"
            item-key="id"
            @click:row="navigateTo"
          >

          <template v-slot:[`item.First`]="{ item }">
            <span class="title indigo--text">{{ item.First }}</span>
          </template>
          <template v-slot:[`item.Last`]="{ item }">
            <span class="title indigo--text">{{ item.Last }}</span>
          </template>

          <template v-slot:[`item.FinBruto1`]="{ item }"> 
            {{ localMoney(item.FinBruto1) }}
          </template>
          <template v-slot:[`item.FinBruto2`]="{ item }"> 
            <span class="title purple--text">{{ localMoney(item.FinBruto2) }}</span>
          </template>
          <template v-slot:[`item.FinNeto1ls`]="{ item }"> 
            {{ localMoney(item.FinNeto1ls) }}
          </template>

          <template v-slot:[`item.FinNeto2`]="{ item }">
            <span class="title indigo--text">{{ localMoney(item.FinNeto2) }}</span>
          </template>
          <template v-slot:[`item.FinNeto3`]="{ item }">
            <span class="title deep-orange--text">{{ localMoney(item.FinNeto3) }}</span>
          </template>
          <template v-slot:[`item.FinBruto3`]="{ item }"> 
            {{ localMoney(item.FinBruto3) }}
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>

</template>

<script>
import ledgerEN from './salarieDescEn'
import ledgerHR from './salarieDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import moneyFormat from '@/snippets/moneyFormat'
import SalarieDetailService from '@/services/SalarieDetailService'

export default {
  name: 'salariedetaillist',
  props: ['locked'],
  data () {
    return {
      lang: {},
      langC: {},
      documentName: '',
      documentNameLoc: '',
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: '',
        databaseDocID: 0,
        FreeF1: 1
      },
      defaultItem: {},
      newItem: {},
      haveSide: 0,
      activItems: [],
      account4s: [],
      bookTypeSelected: {
      },
      prevNr: 0,
      Order: 0,
      Month: 0,
      Year: 0
    }
  },
  async mounted () {
    try {
      if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      this.Order = this.$store.state.documentActivHead.Order
      this.Month = this.$store.state.documentActivHead.Month
      this.Year = this.$store.state.documentActivHead.Year

      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.databaseDocID) {
        this.mainQuery.databaseDocID = this.$store.state.databaseDocID
      }
      await SalarieDetailService.choose(this.mainQuery)
      .then(res => {     
        this.items = res.data.salariedetails ? res.data.salariedetails : []
      })
      .catch(err => {
        // eslint-disable-next-line
        console.log('Error ' + err)
      })
      
      this.headers.push(
        {text: this.lang.hashtag, align: 'left', sortable: true,  value: 'hashtag', width: '100px'},
        {text: this.lang.eemail, align: 'left', sortable: true, value: 'eemail',  width: '120px'},
        {text: this.lang.First, align: 'left', sortable: true, value: 'First', width: '120px'},
        {text: this.lang.Last, align: 'left', sortable: true, value: 'Last', width: '120px' },
        {text: this.lang.VatID, align: 'right', sortable: true, value: 'VatID', width: '100px' },
        {text: this.lang.FinBruto1, align: 'left', value: 'FinBruto1', width: '120px' },
        {text: this.lang.FinBruto2, align: 'left', value: 'FinBruto2', width: '120px' },
        {text: this.lang.FinNeto1ls, align: 'left', value: 'FinNeto1ls', width: '120px' },
        {text: this.lang.FinNeto2, align: 'left', value: 'FinNeto2', width: '120px' },
        {text: this.lang.FinNeto3, align: 'left', value: 'FinNeto3', width: '120px' },
        {text: this.lang.FinBruto3, align: 'left', value: 'FinBruto3', width: '120px' },
      )

    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
    async openDetail (params) {
      await this.$store.dispatch('setActionType', 2)
      await this.$store.dispatch('seteditItem', 0)
      this.$store.dispatch('setretPath', 'salariedetail')
      this.$store.dispatch('setEmployeeid', params.id)
      this.$router.push({
        name: 'employeedit3'
      })
    },
    navigateTo (params) {
      if (this.locked) {
        if (confirm('Obračun je zaključen. Molim da NE RADITE nikakve izmjene!')) {
          this.openDetail(params)
        }
      }
      if (!this.locked) {
        this.openDetail(params)
      }
    },

  },
  computed: {
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
