<template>
    <v-container fluid grid-list-md>
    <v-row>
      <DocumentProductionHeader />
    </v-row>
    <v-row class="py-2">
     <v-col cols="12" sm="12" md="8" xs="12" lg="8" xl="8" class="py-0 px-2" v-if="this.$store.state.user.AccessLevel <= 3">
          <v-btn text color="primary" @click="showAll(100)" class="mr-1">
            Lista svih naloga
          </v-btn>
          <v-btn text color="primary" @click="showAll(1)" class="mr-1">
            Lista pripreme
          </v-btn>
          <v-btn text color="primary" @click="showAll(5)" class="mr-1">
            Lista završenog
          </v-btn>
          <v-btn text color="primary" @click="digitalDoc" class="mr-2" >
            Digitalna arhiva
          </v-btn>
      </v-col>
    </v-row>
    <v-row class="py-2">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" class="py-0 px-2" v-if="showDocumentList">
        <DocumentList :productiontype = "productiontype" />
      </v-col>
    </v-row>
    <v-row class="py-2">
        <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4" class="py-0 px-2">
          <DocumentProductionList hColor="blue" hHeader="Za proizvodnju" productiontype="2"/>
        </v-col>
        <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4" class="py-0 px-2">
          <DocumentProductionList hColor="pink" hHeader="U tijeku" productiontype="3" />
        </v-col>
        <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4" class="py-0 px-2">
          <DocumentProductionList hColor="green" hHeader="Proizvedeno" productiontype="4"/>
        </v-col>
    </v-row>
     <v-row no-gutters v-if="showDigitalDoc">    
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <DigitalDocFullList />
      </v-col>
    </v-row> 
            
    </v-container>
</template>

<script>
import DocumentProductionList from './DocumentProductionList'
import DocumentProductionHeader from './DocumentProductionHeader'
import DocumentList from './DocumentList'
import DigitalDocFullList from '@/components/DigitalDoc/DigitalDocFullList'


export default {
  name: 'documentproductionlist',
  data () {
    return {
      lang: {},
      langC: {},
      showDocumentList: false,
      productiontype: 100,
      showDigitalDoc: false
    }
  },
  mounted () {
    // if (this.$store.state.language === "EN") {
    //   this.lang = ledgerEN
    //   this.langC = commEn
    // }
    // if (this.$store.state.language === "HR") {
    //   this.lang = ledgerHR
    //   this.langC = commHr
    // }
  },
  methods: {
    digitalDoc() {
      this.showDigitalDoc = !this.showDigitalDoc
    },
    async newdocument () {
      try {
        this.$store.dispatch('seteditItem', 0)
        await this.$router.push({
          name: 'documentcreate'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    showAll(type) {
      this.productiontype = type
      this.showDocumentList = !this.showDocumentList
    },
    

  },
  computed: {
  },
  watch: {

  },
  components: {
   DocumentProductionList,
   DocumentProductionHeader,
   DocumentList,
   DigitalDocFullList
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
