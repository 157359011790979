<template>
 <v-container fluid grid-list-md>
        <v-alert :type="alerttype" v-model="havealert" dismissible close-text="Zatvori">
          {{havemssgalert}}
        </v-alert>
        <v-row class="mt-2">
          <v-col cols="12" sm="2" md="2" xs="2" class="right">
            <v-card>
            <v-card-text v-if="typePOS === 1" >
              <v-row>
                <v-select
                    :items="privGroups"
                    v-model="selectGroup"
                    :label="lang.selectGroup"
                    single-line
                    item-text="GroupName"
                    item-value="id"
                    return-object
                    persistent-hint
                    clearable
                    dense
                  ></v-select>
                </v-row>
                <v-row>
                  <v-btn text small color="primary" @click="warehouseState" class="mr-1">
                    {{langC.Warehouse}}
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn text small color="primary" @click="setItems" class="mr-1">
                    {{lang.Items}}
                  </v-btn>
                </v-row>               
                <v-row >
                  <v-btn text small color="primary" @click="posview1" class="mr-1">
                    {{lang.POStoday}}
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn text small color="primary" @click="poscheck" class="mr-1">
                    {{lang.POScheck}}
                  </v-btn>
                  
                </v-row>
                <v-row>
                  <v-btn text small color="primary" class="mr-1" @click="setR1">{{lang.addPOSpartner}}</v-btn>
                </v-row>
                
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5">
            <v-card>
             <v-card-text v-if="typePOS === 1 && showR1">
                <DocumentPOSPartner :newPOS="newPOS" />
               </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="5" md="5" xs="5">
            <v-row no-gutters v-if="typePOS === 1">
                <v-col cols="4">
                  <div class="display-2">KASA</div>
                </v-col>
                <v-col cols="8">
                  <div class="text-right display-2">
                    {{lang.documentTotalAmountToPay}}
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="typePOS === 1">
                <v-col cols="12">
                  <appDocumentPOSViewCard></appDocumentPOSViewCard>
                </v-col>
              </v-row>
          </v-col>           
        </v-row>

    <v-row v-if="typePOS === 1">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" class="pa-0">
        <appDocumentPOSCRUD></appDocumentPOSCRUD>
      </v-col>
    </v-row>
    <v-row v-if="typePOS === 2">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <appDocumentPOSViewCardFinal></appDocumentPOSViewCardFinal>
      </v-col>
    </v-row>
    <v-row v-if="saving">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
          <v-progress-circular v-if="saving"
            indeterminate
            color="green"
          ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.statewhs">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <appDocumentWarehouseState></appDocumentWarehouseState>
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.showItems">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <ItemListFast />
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>

  </v-container>
</template>

<script>
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import DocumentPOSCRUD from './DocumentPOSCRUD'
import DocumentPOSViewCard from './DocumentPOSViewCard'
import DocumentPOSViewCardFinal from './DocumentPOSViewCardFinal'
import DocumentWarehouseState from './DocumentWarehouseState'
import DocumentPOSPartner from './DocumentPOSPartner'
import ItemListFast from '../Item/ItemListFast'
import DocumentService from '@/services/DocumentService'
import {fiskalSave, fiskalRead} from './fiskal'
import {wait} from '@/snippets/allSnippets'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

// import generateBarcode from "pdf417"
// import {createCanvas} from 'canvas'
// import PDF417 from 'pdf417-generator'
// import ItemService from '@/services/ItemService'

export default {
  name: 'documentpos',
  data () {
    return {
      snackbar: false,
      alert: true,
      text: '',
      timeout: 2000,
      color: 'green',
      menu: false,
      modal: false,
      date1: null,
      date2: null,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      modal5: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      msg: '',
      valid: true,
      account: { },
      error: null,
      localError: false,
      privGroups: [],
      lang: {},
      langC: {},
      saving: false,
      itemsLoc: [],
      workType: 1,
      statewhs: false, 
      suppcard: false,
      outcard: false,
      sellcard: false,
      groupcard: false,
      selectGroup: {},
      copyhead: {},
      copyitems: [],
      showR1: false,
      showItems: false,
      newPOS: true,
      currentPath: null,
      code: null,
      slika: null,
      Canvas1: null,
      Canvas2: null,
      mainQuery: {},
      havealert: false,
      havemssgalert: '',
      alerttype: 'warning',
      noFiskal: []
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      await this.$store.dispatch('setDocumentActiv', 'POS')
      await this.$store.dispatch('setDocumentActivLoc', 'POS')
      await this.$store.dispatch('setDocumentSide', 2)
      await this.$store.dispatch('setDatabaseDocID', null)
      await this.$store.dispatch('setDocumentid', null)
      await this.$store.dispatch('setDocumentDetailid', null)
      this.date1 = dayjs(new Date()).format('DD.MM.YYYY')
      this.date2 = dayjs(new Date()).format('DD.MM.YYYY')
      this.$store.dispatch('seteditItem', 0)
      if (this.$store.state.pathAgain !== 'toPOS') {
        await this.$store.dispatch('setDocumentActivHead', {})
        await this.$store.dispatch('setDocumentActivItems', [])        
      }
      await this.$store.dispatch('setpathAgain', null)
      await this.$store.dispatch('settypePOS', 1)
      if (this.$store.state.groupStore) {
        //this.privGroups = (await GroupService.choose(this.mainQuery)).data.groups
        this.privGroups = this.$store.state.groupStore
      }
      this.code = `HRVHUB30
        HRK
        000000000012355
        PETAR KORETIĆ
        PREVOJ DD
        10000 Zagreb
        FIRMA J.D.O.O
        PREVOJ DD
        10000 ZAGREB
        HR5041240000000000
        HR01
        7336-68949637625-00001
        COST
        Uplata za 1. mjesec`

      // const canvas = createCanvas()
      // const canvas = document.getElementById("barcode")
      // PDF417.draw(this.code, canvas, 2, -1, 2)
      // this.Canvas1 = await canvas.toDataURL()
      // this.slika = generateBarcode('ovo je neki tekst', 2, -1, 'pdf')

      //this.generate()
  },
  methods: {
    async generate() {
        //const canvas = createCanvas()
        // this.slika = createCanvas()
        // PDF417.draw(this.code, this.slika)
        //this.slika = await canvas.toDataURL()
    },
    addPicture() {
      // return generateBarcode('ovo je neki tekst', 2, -1, 'pdf')
    },
    warehouseState() {
      this.statewhs = !this.statewhs
      this.$store.dispatch('setdontGo', false)
      this.$store.dispatch("setGroupid", null)
      if (this.selectGroup.id) {
        this.$store.dispatch("setGroupid", this.selectGroup.id)
      }
    },
    setR1() {
      if (this.$store.state.documentActivHead.documentTotalAmountToPay && parseFloat(this.$store.state.documentActivHead.documentTotalAmountToPay) !== 0) {
        this.newPOS = false
      }
      this.showR1 = !this.showR1
    },
    setItems() {
      this.showItems = !this.showItems
    },
    async clearPOS () {
      try {
        await this.account === {}
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async closePOS () {
      try {
        await this.account === {}
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async poscheck() {
      this.noFiskal = []
      this.mainQuery.CompanyId = this.$store.state.companyid
      this.mainQuery.BusinessYear = this.$store.state.businessYear
      const {data} = await DocumentService.documentfiskalcheck(this.mainQuery)
      
      if (data && data.documents !== undefined && data.documents.length > 0) {
        const haveIt = data.documents.length
        this.noFiskal = data.documents
        const warningMessage = 'Imate ' + haveIt + ' nefiskaliziranih računa. Fiskalizacija traje oko ' + (haveIt * 4)+ ' sekundi. Želite ih fiskalizirati sada?'
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!')) {
            this.fiskalDone()
          }
      } else {
        // show all is OK message
        this.text = 'Svi računi su fiskalizirani!' 
        this.color = 'green'
        this.snackbar = true
      }
    },
    async fiskalDone(){
      if (this.noFiskal.length > 0) {
        //const haveIt = this.noFiskal.length
        
        //let done = 0
        this.noFiskal.map(async(doc) => {
          const id = doc.id
          this.saving = true
          await fiskalSave(id, doc, 'P')
          // wait 2 seconds and try read reponse
          wait(3000)
          this.fiskalStatus = await fiskalRead(1, id)
          this.saving = false
          if (this.fiskalStatus.status === 'GRESKA FISKALIZACIJE!!!') {
            this.text = 'NIJE USPJELA FISKALIZACIJA! Račun ' + doc.documentId
            this.color = 'red'
            this.snackbar = true
          }
          if (this.fiskalStatus.status === 'Fiskalizacija OK.') {
            this.text = 'FISKALIZACIJA USPJELA! Račun ' + doc.documentId
            this.color = 'green'
            this.snackbar = true
          }
          //done += 1
        })
        

        // if (haveIt === done) {
        //   this.havealert = false
        //   this.havealert = true
        //   this.alerttype = 'success'
        //   this.havemssgalert = 'Svi racuni su fiskalizirani!'
        // } else {
        //   this.havealert = false
        //   this.havealert = true
        //   this.alerttype = 'warning'
        //   this.havemssgalert = 'NIJE USPJELA FISKALIZACIJA! Broj nefiskaliziranih računa = ' + haveIt
        // }
      } 
    },
    async posview1() {      
      await this.$store.dispatch('settypePOSPrint', 10)
      this.markMe('/crm/document/poslist', 'POS', 'POS', 2)
    },
    async posview2() {      
      await this.$store.dispatch('settypePOSPrint', 11)
      this.markMe('/crm/document', 'POS', 'POS', 2)
    },
    async posview3() {
     this.poscheck()
    },
    markMe (path, doc, docLoc, docSide) {
      this.$store.dispatch('setDocumentActiv', doc)
      this.$store.dispatch('setDocumentActivLoc', docLoc)
      this.$store.dispatch('setDocumentSide', docSide)
      if (this.currentPath !== path ) {
        this.currentPath = path
        this.$router.push({
        path
        }).catch(err => {
          // eslint-disable-next-line
          console.log(err);
        })
      }
    },
      
  },
  computed: {
    typePOS () {
      return (this.$store.state.typePOS ? this.$store.state.typePOS : 1)
    },
    ...mapGetters({
      myPOStype: 'NeedTypePOS'
    }),
  },
  watch: {
    'myPOStype'() {
      if (this.$store.state.typePOS === 2) {
        this.statewhs = false
      }
    }

  },
  components: {
    appDocumentPOSCRUD: DocumentPOSCRUD,
    appDocumentPOSViewCard: DocumentPOSViewCard,
    appDocumentPOSViewCardFinal: DocumentPOSViewCardFinal,
    appDocumentWarehouseState: DocumentWarehouseState,
    DocumentPOSPartner,
    ItemListFast
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
