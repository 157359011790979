<template>
<div>
  <v-container fluid grid-list-md>
  <v-row>
      <FormTotalPPO :forms="forms" :formType="formType" />
  </v-row>
  <v-divider></v-divider>
    <v-row>
     <v-col cols="12" sm="7" md="7" xs="7" lg="7" xl="7">
       <FormPrint ref="prnt" :frm="frm" :all="all" />
     </v-col>
      <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5">
      <v-btn
          text color="primary"
          @click="editHead()">
          Uredi zaglavlje
        </v-btn>
        <v-btn
          text color="primary"
          @click="fillForm()">
          {{langC.FillData}}
        </v-btn>
        <v-btn
          text color="primary"
          @click="printForm()">
          {{langC.Print}}
        </v-btn>
        <v-btn
          text color="primary"
          @click="createExport()">
          Kreiraj
        </v-btn>
        <v-btn
          text color="primary"
          @click="downloadExport()">
          Download
        </v-btn>
        <v-btn
          text color="primary"
          @click="formlist()">
          {{langC.Back}}
        </v-btn>
      </v-col>
  </v-row>
  <v-divider></v-divider>
  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="users"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="50"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{haveForm}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>


          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                <div v-if="formType == 1">
                  <v-row>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <v-select
                        :items="qmonths"
                        label="Mjesec u kvartalu (1,2,3)"
                        dense
                        v-model="editedItem.deliveredMonth"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="9" md="9" xs="9" lg="9" xl="9" class="pr-1">
                      <v-date-picker
                        v-model="editedItem.deliveredDate"
                        color="green lighten-1"
                        header-color="primary"
                      ></v-date-picker>

                    </v-col>
                   </v-row>
                   <v-row>
                    <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.PPO_10"
                        v-model="editedItem.partnerVATID"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.PPO_11"
                        v-model="editedItem.FreeF1"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="formType == 2">
                <v-row>
                    <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.partnerVATID"
                        v-model="editedItem.partnerVATID"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="7" md="7" xs="7" lg="7" xl="7" class="pr-1">

                    </v-col>
                   </v-row>
                  <v-row>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.PDVS_9"
                        v-model="editedItem.FreeS1"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="9" md="9" xs="9" lg="9" xl="9" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.PDVS_10"
                        v-model="editedItem.FreeS2"
                        disabled
                      ></v-text-field>
                    </v-col>
                   </v-row>
                   <v-row>
                    <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.PDVS_11"
                        v-model.number="editedItem.FreeF1"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.PDVS_12"
                        v-model.number="editedItem.FreeF2"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
                 <div v-if="formType == 3">
                 <v-row>
                    <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.partnerVATID"
                        v-model="editedItem.partnerVATID"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="7" md="7" xs="7" lg="7" xl="7" class="pr-1">

                    </v-col>
                   </v-row>
                  <v-row>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.ZP_9"
                        v-model="editedItem.FreeS1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="9" md="9" xs="9" lg="9" xl="9" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.ZP_10"
                        v-model="editedItem.FreeS2"
                      ></v-text-field>
                    </v-col>
                   </v-row>
                   <v-row>
                    <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.ZP_11"
                        v-model.number="editedItem.FreeF1"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.ZP_12"
                        v-model.number="editedItem.FreeF2"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.ZP_13"
                        v-model.number="editedItem.FreeF3"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.ZP_14"
                        v-model.number="editedItem.FreeF4"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>

                  <v-row v-if="errorMatch">
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn color="success" text @click="save">{{langC.Save}}</v-btn>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.FreeF1`]="{ item }">
      <span >{{ formatPrice(item.FreeF1) }}</span>
    </template>
    <template v-slot:[`item.FreeF2`]="{ item }">
      <span >{{ formatPrice(item.FreeF2) }}</span>
    </template>
    <template v-slot:[`item.FreeF3`]="{ item }">
      <span >{{ formatPrice(item.FreeF3) }}</span>
    </template>
    <template v-slot:[`item.FreeF4`]="{ item }">
      <span >{{ formatPrice(item.FreeF4) }}</span>
    </template>
    <template v-slot:[`item.deliveredDate`]="{ item }">
      <span >{{ localDate(item.deliveredDate) }}</span>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      {{ lang.EmptyForm }}
    </template>
  </v-data-table>
      <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>
  </v-container>
  </div>
</template>

<script>
import FormService from '@/services/FormService'
import FormDetailService from '@/services/FormDetailService'
import MainLedgerService from '@/services/MainLedgerService'
import langEn from './formDescEn'
import langHR from './formDescHR'
import commEn from '@/language/commonEN'
import commHR from '@/language/commonHR'
import {dateFormat, round, dynamicSort} from '@/snippets/allSnippets'
import { defFormDetail } from '../MainLedger/mainledgerHelper'
import FormTotalPPO from './FormTotalPPO'
import FormPrint from './FormPrint'
import FileSaver from 'file-saver'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'

  export default {
    name: 'formitemscrud',
    props: ['formType', 'formHead'],
    data: () => ({
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      modal1: false,
      dialog1: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {},
      mainQuery2: {},
      dialog: false,
      headers: [],
      users: [],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      privitems: [],
      qmonths: [1,2, 3],
      valid: true,
      haveForm: '',
      haveFormHead: '',
      forms: {},
      frm: {},
      all: 1,
      ml: {},
      mlObject: {
        databaseDocID: 0,
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        EmployeeId: 0,
        hashtag: '',
        eemail: '',
        First: '',
        Last: '',
        phone: '',
        formName: '',
        formType: 0,
        formSide: 0,
        formAnalysis: 0,
        formDate: null,
        formTime: null,
        month: 0,
        quarters: 0,
        year: 0,
        partnerId: null,
        partnerName: '',
        partnerNameShort: '',
        partnerNameDatabase: '',
        partnerBuildingNameNumber: '',
        partnerStreet: '',
        partnerCity: '',
        partnerPostcode: '',
        partnerCountyRegion: '',
        partnerState: '',
        partnerVATID: '',
        partneremail: '',
        deliveredDate: null,
        deliveredMonth: null,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        FreeF7: 0,
        FreeF8: 0,
        FreeF9: 0,
        FreeF10: 0,
        FreeB1: false,
        FreeB2: false,
        FreeB3: false,
        FreeB4: false,
        FreeB5: false,
        FreeS1: '',
        FreeS2: '',
        FreeS3: '',
        FreeS4: '',
        FreeS5: '',
        FreeS6: '',
        FreeS7: '',
        FreeS8: '',
        FreeS9: '',
        FreeS10: '',
      }
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewCardType: this.lang.EditCardType
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      'editedItem.partnerVATID' () {
        if (this.editedItem.partnerVATID && this.editedItem.partnerVATID.length > 3) {
          this.editedItem.FreeS1 = this.editedItem.partnerVATID.substr(0,2)
          this.editedItem.FreeS2 = this.editedItem.partnerVATID.substr(2,this.editedItem.partnerVATID.length)
        }
      }
    },
    components: {
      FormTotalPPO,
      FormPrint
    },

    mounted() {
      dayjs.extend(quarterOfYear)
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHR
        this.langC = commHR
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      this.mainQuery.databaseDocID = this.$store.state.mainledgerid
      this.defaultItem = {...defFormDetail()}

      if (this.formType === 1) {
        this.haveForm = this.lang.FormPPO
        this.haveFormHead = this.lang.FormPPODetail
      }
      if (this.formType === 2) {
        this.haveForm = this.lang.FormPDVS
        this.haveFormHead = this.lang.FormPDVSDetail
      }
      if (this.formType === 3) {
        this.haveForm = this.lang.FormZP
        this.haveFormHead = this.lang.FormZPDetail
      }
      // PPO
    if (this.formType === 1) {
      this.headers.push(
        {text: this.lang.PPO_8, value: 'deliveredMonth', width: '200px'},
        {text: this.lang.PPO_81, value: 'deliveredDate', width: '120px'},
        {text: this.lang.PPO_10, value: 'partnerVATID', width: '200px'},
        {text: this.lang.PPO_11, align: 'right', value: 'FreeF1', width: '150px'},
        {text: this.lang.partnerName, value: 'partnerName', width: '300px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
        {text: this.lang.Actions, value: 'action', sortable: false },
      )
    }
    // PDVS
    if (this.formType === 2) {
      this.headers.push(
        {text: this.lang.PDVS_9,  value: 'FreeS1', width: '80px'},
        {text: this.lang.PDVS_10, value: 'FreeS2', width: '200px'},
        {text: this.lang.PDVS_11, align: 'right', value: 'FreeF1', width: '150px'},
        {text: this.lang.PDVS_12, align: 'right', value: 'FreeF2', width: '150px'},
        {text: this.lang.partnerName, value: 'partnerName', width: '300px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
        {text: this.lang.Actions, value: 'action', sortable: false },
      )
    }
    // ZP
    if (this.formType === 3) {
      this.headers.push(
        {text: this.lang.ZP_9,  value: 'FreeS1', width: '80px'},
        {text: this.lang.ZP_10, value: 'FreeS2', width: '200px'},
        {text: this.lang.ZP_11,  align: 'right', value: 'FreeF1', width: '150px'},
        {text: this.lang.ZP_12, align: 'right', value: 'FreeF2', width: '150px'},
        {text: this.lang.ZP_13, align: 'right', value: 'FreeF3', width: '150px'},
        {text: this.lang.ZP_14, align: 'right', value: 'FreeF4', width: '150px'},
        {text: this.lang.partnerName, value: 'partnerName', width: '300px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
        {text: this.lang.Actions, value: 'action', sortable: false },
      )
    }

    },

    created () {
      this.initialize()
    },

    methods: {
      fillForm() {
        if (confirm('Da li ste sigurni da želite ispuniti obrazac (postojeći podaci će se obrisati) ?!')) {
          this.fillForm2()
        }
      },
      getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min) ) + min;
      },
      async fillForm2() {
        const newQr = {}
        this.mainQuery2 = {...newQr}
        this.mainQuery2.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        this.mainQuery2.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
        if (this.formType === 1 ) {
          const year = this.formHead.year ? this.formHead.year : 2021
          const quarter = this.formHead.quarters ? this.formHead.quarters : 0
          this.mainQuery2.docFromDate = dayjs().year(year).quarter(quarter).startOf('quarter').format('YYYY-MM-DD')
          this.mainQuery2.docToDate = dayjs().year(year).quarter(quarter).endOf('quarter').format('YYYY-MM-DD')
        }
        if (this.formType === 2 || this.formType === 3) {
          const month = this.formHead.month ? this.formHead.month - 1 : 0
          const year = this.formHead.year ? this.formHead.year : 2021
          this.mainQuery2.docFromDate = dayjs().year(year).month(month).startOf('month').format('YYYY-MM-DD')
          this.mainQuery2.docToDate = dayjs().year(year).month(month).endOf('month').format('YYYY-MM-DD')
        }
        this.mainQuery2.databaseDocID = this.formHead.id
        console.log('this.mainQuery2', this.mainQuery2, 'this.formType', this.formType)

        const {data} = await MainLedgerService.books(this.mainQuery2)

        if (data && data.documents) {
           console.log('data.documents', data.documents)
          this.privitems = []
          let allitems = []
          if (this.formType === 1 ) {
            allitems = data.documents.filter(docs => docs.BookTypeID === 10 && docs.BookSubTypeID === 1)
          }
          if (this.formType === 2 ) {
            allitems = data.documents.filter(docs => docs.BookTypeID === 3 || docs.BookTypeID === 4)
          }
          if (this.formType === 3 ) {
            allitems = data.documents.filter(docs => docs.BookTypeID === 11 && (docs.BookSubTypeID === 3 || docs.BookSubTypeID === 4))
          }
          console.log('allitems', allitems)
          allitems.map(doc => {

            const BookTypeID = parseInt(doc.BookTypeID)
            const InvoiceAmount = parseFloat(doc.InvoiceAmount)
            let Goods = doc.Goods ? parseFloat(doc.Goods) : 0
            let Services = doc.Services ? parseFloat(doc.Services) : 0
            if (BookTypeID === 11) {
              Goods = doc.Goods ? parseFloat(doc.VAT4Amount) : 0
              Services = doc.Services ? parseFloat(doc.VAT4PerCanUse) : 0
            }
            this.ml = {...this.mlObject}
            if (BookTypeID === 10) {
              this.ml.databaseDocID = this.$store.state.mainledgerid
              this.ml.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
              this.ml.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              this.ml.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
              this.ml.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              this.ml.email = this.$store.state.user.email ? this.$store.state.user.email : ''
              this.ml.First = this.$store.state.user.First ? this.$store.state.user.First : ''
              this.ml.Last = this.$store.state.user.Last ? this.$store.state.user.Last : ''
              this.ml.formName = this.formHead.formName
              this.ml.formType = this.formHead.formType
              this.ml.quarters = this.formHead.quarters ? this.formHead.quarters : 0
              const currMonth = doc.BookingDate ? dayjs(doc.BookingDate).month() + 1 : 1


              if (currMonth === 1) {
                this.ml.deliveredMonth = 1
              }
              if (currMonth === 2) {
                this.ml.deliveredMonth = 2
              }
              if (currMonth === 3) {
                this.ml.deliveredMonth = 3
              }
              if (currMonth === 4) {
                this.ml.deliveredMonth = 1
              }
              if (currMonth === 5) {
                this.ml.deliveredMonth = 2
              }
              if (currMonth === 6) {
                this.ml.deliveredMonth = 3
              }
              if (currMonth === 7) {
                this.ml.deliveredMonth = 1
              }
              if (currMonth === 8) {
                this.ml.deliveredMonth = 2
              }
              if (currMonth === 9) {
                this.ml.deliveredMonth = 3
              }
              if (currMonth === 10) {
                this.ml.deliveredMonth = 1
              }
              if (currMonth === 11) {
                this.ml.deliveredMonth = 2
              }
              if (currMonth === 12) {
                this.ml.deliveredMonth = 3
              }

              this.ml.deliveredDate = doc.BookingDate ? doc.BookingDate : null
              this.ml.partnerVATID = doc.partnerVATID ? doc.partnerVATID : ''
              this.ml.partnerId = doc.partnerId ? doc.partnerId : ''
              this.ml.partnerName = doc.partnerName ? doc.partnerName : ''
              this.ml.partnerNameDatabase = doc.partnerNameDatabase ? doc.partnerNameDatabase : ''
              this.ml.FreeF1 = InvoiceAmount ? InvoiceAmount : 0

            }
            if (BookTypeID === 3 || BookTypeID === 4) {
              // ure EU
              this.ml.databaseDocID = this.$store.state.mainledgerid
              this.ml.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
              this.ml.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              this.ml.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
              this.ml.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              this.ml.email = this.$store.state.user.email ? this.$store.state.user.email : ''
              this.ml.First = this.$store.state.user.First ? this.$store.state.user.First : ''
              this.ml.Last = this.$store.state.user.Last ? this.$store.state.user.Last : ''
              this.ml.formName = this.formHead.formName
              this.ml.formType = this.formHead.formType

              //this.ml.deliveredMonth = doc.BookingDate ? doc.BookingDate : 1
              const haveIt = this.users.filter(user => user.partnerVATID === doc.partnerVATID)
              let oldGoods = 0
              let oldServices = 0
              if (haveIt && haveIt.length > 0) {
                oldGoods = haveIt[0].FreeF1 ? parseFloat(haveIt[0].FreeF1) : 0
                oldServices = haveIt[0].FreeF2 ? parseFloat(haveIt[0].FreeF2) : 0
              }
              this.ml.partnerVATID = doc.partnerVATID ? doc.partnerVATID : ''
              this.ml.partnerId = doc.partnerId ? doc.partnerId : ''
              this.ml.partnerName = doc.partnerName ? doc.partnerName : ''
              this.ml.partnerNameDatabase = doc.partnerNameDatabase ? doc.partnerNameDatabase : ''

              this.ml.FreeS1 = doc.partnerVATID ? doc.partnerVATID.substr(0,2) : ''
              this.ml.FreeS2 = doc.partnerVATID ? doc.partnerVATID.substr(2, doc.partnerVATID.length) : ''
              this.ml.FreeF1 = Goods ? Goods + oldGoods : 0
              this.ml.FreeF2 = Services ? Services + oldServices : 0

            }
            if (BookTypeID === 11) {
              // ire EU
              this.ml.databaseDocID = this.$store.state.mainledgerid
              this.ml.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
              this.ml.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              this.ml.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
              this.ml.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              this.ml.email = this.$store.state.user.email ? this.$store.state.user.email : ''
              this.ml.First = this.$store.state.user.First ? this.$store.state.user.First : ''
              this.ml.Last = this.$store.state.user.Last ? this.$store.state.user.Last : ''
              this.ml.formName = this.formHead.formName
              this.ml.formType = this.formHead.formType

              //this.ml.deliveredMonth = doc.BookingDate ? doc.BookingDate : 1

              this.ml.partnerVATID = doc.partnerVATID ? doc.partnerVATID : ''
              this.ml.partnerId = doc.partnerId ? doc.partnerId : ''
              this.ml.partnerName = doc.partnerName ? doc.partnerName : ''
              this.ml.partnerNameDatabase = doc.partnerNameDatabase ? doc.partnerNameDatabase : ''
              const haveIt = this.users.filter(user => user.partnerVATID === doc.partnerVATID)
              let oldGoods = 0
              let oldServices = 0
              if (haveIt && haveIt.length > 0) {
                oldGoods = haveIt[0].FreeF1 ? parseFloat(haveIt[0].FreeF1) : 0
                oldServices = haveIt[0].FreeF2 ? parseFloat(haveIt[0].FreeF2) : 0
              }
              this.ml.FreeS1 = doc.partnerVATID ? doc.partnerVATID.substr(0,2) : ''
              this.ml.FreeS2 = doc.partnerVATID ? doc.partnerVATID.substr(2, doc.partnerVATID.length) : ''
              this.ml.FreeF1 = Goods ? Goods + oldGoods : 0
              this.ml.FreeF4 = Services ? Services + oldServices: 0
            }
            this.privitems.push(this.ml)
          })

          this.privitems.sort(dynamicSort("partnerVATID"))
          const totalPrivItems = []
          if (this.privitems) {
            let conditionVATID = null
            this.privitems.map(ii => {
              if (ii.partnerVATID !== conditionVATID) {
                conditionVATID = ii.partnerVATID

                const conditionName = ii.partnerName
                const moreItems = this.privitems.filter(i => i.partnerVATID === ii.partnerVATID)

                if (moreItems.length === 1) {
                  const newItem = {}
                  newItem.databaseDocID = this.$store.state.mainledgerid
                  newItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
                  newItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
                  newItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
                  newItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
                  newItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
                  newItem.First = this.$store.state.user.First ? this.$store.state.user.First : ''
                  newItem.Last = this.$store.state.user.Last ? this.$store.state.user.Last : ''
                  newItem.formName = this.formHead.formName
                  newItem.formType = this.formHead.formType
                  newItem.formAnalysis = this.formHead.formType
                  newItem.formSide = this.formHead.formType
                  newItem.quarters = this.formHead.quarters ? this.formHead.quarters : 0
                  newItem.deliveredMonth = ii.deliveredMonth
                  newItem.partnerVATID = ii.partnerVATID
                  newItem.partnerName = ii.partnerName
                  newItem.FreeS1 = ii.partnerVATID ? ii.partnerVATID.substr(0,2) : ''
                  newItem.FreeS2 = ii.partnerVATID ? ii.partnerVATID.substr(2, ii.partnerVATID.length) : ''
                  if (this.formType === 1) {
                    newItem.FreeF1 = round(ii.FreeF1, 2)
                  }
                   if (this.formType === 2) {
                    newItem.FreeF1 = round(ii.FreeF1, 2)
                    newItem.FreeF2 = round(ii.FreeF2, 2)
                  }
                  if (this.formType === 3) {
                    newItem.FreeF1 = round(ii.FreeF1, 2)
                    newItem.FreeF2 = round(ii.FreeF2, 2)
                    newItem.FreeF3 = round(ii.FreeF3, 2)
                    newItem.FreeF4 = round(ii.FreeF4, 2)
                  }
                  //newItem.id = this.getRndInteger(1000000000, 9000000000)
                  totalPrivItems.push(newItem)
                }
                if (moreItems.length > 1) {
                  // do sum by vatIT and delivered month
                  if (this.formType === 1) {
                    //let total1 = 0
                    let qtotal1 = 0
                    let qtotal2 = 0
                    let qtotal3 = 0
                    if (moreItems) {

                      moreItems.map(item => {
                       // total1 += round(parseFloat(item.FreeF1),2)
                        qtotal1 += (parseFloat(item.deliveredMonth) === 1 ? parseFloat(item.FreeF1): 0)
                        qtotal2 += (parseFloat(item.deliveredMonth) === 2 ? parseFloat(item.FreeF1): 0)
                        qtotal3 += (parseFloat(item.deliveredMonth) === 3 ? parseFloat(item.FreeF1): 0)
                      })
                    }

                    if (round(qtotal1,2) !== 0) {
                      const newItem = {}
                      newItem.databaseDocID = this.$store.state.mainledgerid
                      newItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
                      newItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
                      newItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
                      newItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
                      newItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
                      newItem.First = this.$store.state.user.First ? this.$store.state.user.First : ''
                      newItem.Last = this.$store.state.user.Last ? this.$store.state.user.Last : ''
                      newItem.formName = this.formHead.formName
                      newItem.formType = this.formHead.formType
                      newItem.formAnalysis = this.formHead.formType
                      newItem.formSide = this.formHead.formType
                      newItem.quarters = this.formHead.quarters ? this.formHead.quarters : 0
                      newItem.deliveredMonth = 1
                      newItem.partnerVATID = conditionVATID
                      newItem.partnerName = conditionName
                      newItem.FreeF1 = round(qtotal1, 2)
                      //newItem.id = this.getRndInteger(1000000000, 9000000000)
                      totalPrivItems.push(newItem)
                    }
                    if (round(qtotal2,2) !== 0) {
                      const newItem = {}
                      newItem.databaseDocID = this.$store.state.mainledgerid
                      newItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
                      newItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
                      newItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
                      newItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
                      newItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
                      newItem.First = this.$store.state.user.First ? this.$store.state.user.First : ''
                      newItem.Last = this.$store.state.user.Last ? this.$store.state.user.Last : ''
                      newItem.formName = this.formHead.formName
                      newItem.formType = this.formHead.formType
                      newItem.formAnalysis = this.formHead.formType
                      newItem.formSide = this.formHead.formType
                      newItem.quarters = this.formHead.quarters ? this.formHead.quarters : 0
                      newItem.deliveredMonth = 2
                      newItem.partnerVATID = conditionVATID
                      newItem.partnerName = conditionName
                      newItem.FreeF1 = round(qtotal2, 2)
                      //newItem.id = this.getRndInteger(1000000000, 9000000000)
                      totalPrivItems.push(newItem)
                    }
                    if (round(qtotal3, 2) !== 0) {
                      const newItem = {}
                      newItem.databaseDocID = this.$store.state.mainledgerid
                      newItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
                      newItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
                      newItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
                      newItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
                      newItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
                      newItem.First = this.$store.state.user.First ? this.$store.state.user.First : ''
                      newItem.Last = this.$store.state.user.Last ? this.$store.state.user.Last : ''
                      newItem.formName = this.formHead.formName
                      newItem.formType = this.formHead.formType
                      newItem.formAnalysis = this.formHead.formType
                      newItem.formSide = this.formHead.formType
                      newItem.quarters = this.formHead.quarters ? this.formHead.quarters : 0
                      newItem.deliveredMonth = 3
                      newItem.partnerVATID = conditionVATID
                      newItem.partnerName = conditionName
                      newItem.FreeF1 = round(qtotal3, 2)
                      //newItem.id = this.getRndInteger(1000000000, 9000000000)
                      totalPrivItems.push(newItem)
                    }
                  }
                  if (this.formType === 2) {
                    let total1 = 0
                    let total2 = 0
                    if (moreItems) {
                      moreItems.map(item => {
                        total1 += round(parseFloat(item.FreeF1),2)
                        total2 += round(parseFloat(item.FreeF2),2)
                      })
                    }
                    ii.FreeF1 = total1
                    ii.FreeF2 = total2
                    totalPrivItems.push(ii)

                  }
                  if (this.formType === 3) {
                    let total1 = 0
                    let total2 = 0
                    let total3 = 0
                    let total4 = 0
                    if (moreItems) {
                      moreItems.map(item => {
                        total1 += round(parseFloat(item.FreeF1),2)
                        total2 += round(parseFloat(item.FreeF2),2)
                        total3 += round(parseFloat(item.FreeF3),2)
                        total4 += round(parseFloat(item.FreeF4),2)
                      })
                    }

                    ii.FreeF1 = total1
                    ii.FreeF2 = total2
                    ii.FreeF3 = total3
                    ii.FreeF4 = total4
                    totalPrivItems.push(ii)

                  }

                }

              }
            })
          }

          this.users = [...totalPrivItems]
          console.log('Total priv items ', totalPrivItems)

          await FormDetailService.deleteitems(this.mainQuery2)
          await FormDetailService.saveitems(this.users)
          await this.doTotals()
          await this.initialize()
        }
      },
      async printForm(){
        this.frm = {...this.forms}
        this.frm.items = this.users
        this.all = this.formType
        await this.$refs.prnt.print()
      },
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      async initialize () {
        try {
          this.saving = true
          await FormService.show(this.$store.state.mainledgerid)
          .then(res => {
            this.forms = res.data.form && Object.keys(res.data.form).length !== 0 ? res.data.form : {}
          })
          .catch(err => {
            console.log(err)
          })
          const {data} = await FormDetailService.index(this.mainQuery)
          this.users = data.formdetails && Object.keys(data.formdetails).length !== 0 ? data.formdetails : []
          this.doTotals()

        } catch (err) {
          this.error = err
      }
    },
    async doTotals() {
      try {
          if (this.formType === 1) {
            let total1 = 0
            let qtotal1 = 0
            let qtotal2 = 0
            let qtotal3 = 0
            if (this.users) {

              this.users.map(item => {
                total1 += round(parseFloat(item.FreeF1),2)
                qtotal1 += (parseFloat(item.deliveredMonth) === 1 ? parseFloat(item.FreeF1): 0)
                qtotal2 += (parseFloat(item.deliveredMonth) === 2 ? parseFloat(item.FreeF1): 0)
                qtotal3 += (parseFloat(item.deliveredMonth) === 3 ? parseFloat(item.FreeF1): 0)
              })
            }

              this.forms.FreeTotal1 = total1
              this.forms.FreeTotal2 = qtotal1
              this.forms.FreeTotal3 = qtotal2
              this.forms.FreeTotal4 = qtotal3

          }
          if (this.formType === 2) {
            let total1 = 0
            let total2 = 0
            if (this.users) {
              this.users.map(item => {
                total1 += round(parseFloat(item.FreeF1),2)
                total2 += round(parseFloat(item.FreeF2),2)
              })
            }

              this.forms.FreeTotal1 = total1
              this.forms.FreeTotal2 = total2

          }
          if (this.formType === 3) {
            let total1 = 0
            let total2 = 0
            let total3 = 0
            let total4 = 0
            if (this.users) {
              this.users.map(item => {
                total1 += round(parseFloat(item.FreeF1),2)
                total2 += round(parseFloat(item.FreeF2),2)
                total3 += round(parseFloat(item.FreeF3),2)
                total4 += round(parseFloat(item.FreeF4),2)
              })
            }

              this.forms.FreeTotal1 = total1
              this.forms.FreeTotal2 = total2
              this.forms.FreeTotal3 = total3
              this.forms.FreeTotal4 = total4

          }
          await FormService.put(this.forms)

          this.saving = false
          this.errorMatch = false
          this.editedItem = Object.assign({}, this.defaultItem)
        } catch (err) {
          this.error = err
        }
      },
      editItem (item) {
        this.errorMatch = false
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      async deleteItem (item) {
        try {
          const index = this.users.indexOf(item)
          const warningMessage = item.CardName
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.users.splice(index, 1)) {
            if (item && item.id) {
              await FormDetailService.delete(item.id)
              this.initialize()
            }
          }
        } catch(err) {
          this.error = err
        }
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      async save () {
        try {
           let doneOK = false
           if (this.editedIndex > -1) {
              await FormDetailService.put(this.editedItem)
              doneOK = true
            } else {
              this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
              this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              this.editedItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
              this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              this.editedItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
              this.editedItem.First = this.$store.state.user.First ? this.$store.state.user.First : ''
              this.editedItem.Last = this.$store.state.user.Last ? this.$store.state.user.Last : ''
              this.editedItem.databaseDocID = this.$store.state.mainledgerid
              this.editedItem.formName = this.haveForm
              this.editedItem.formType = this.formType
              await FormDetailService.post(this.editedItem)
              doneOK = true
            }
            if (doneOK) {
              this.initialize()
              this.close()
            }
        } catch(err) {
          this.error = err
        }
      },
      createExport() {

        if (this.formType === 1) {
          this.createExportPPO()
        }
        if (this.formType === 2) {
          this.createExportPDVS()
        }
        if (this.formType === 3) {
          this.createExportZP()
        }
      },
      downloadExport () {
        if (this.formType === 1) {
          this.downloadExportPPO()
        }
        if (this.formType === 2) {
          this.downloadExportPDVS()
        }
        if (this.formType === 3) {
          this.downloadExportZP()
        }
      },
      createExportPPO() {
        const ppo = {}
        ppo.head = this.forms
        ppo.head.uui = uuidv4()
        ppo.head.CompanyPlace = (this.$store.state.companyActiv.CompanyPostcode ? this.$store.state.companyActiv.CompanyPostcode : '') + ' ' +this.$store.state.companyActiv.CompanyCity ? this.$store.state.companyActiv.CompanyCity : ''
        ppo.head.CompanyStreet = this.$store.state.companyActiv.CompanyStreet ? this.$store.state.companyActiv.CompanyStreet : ''
        ppo.head.CompanyStreetNumber = this.$store.state.companyActiv.CompanyBuildingNameNumber ? this.$store.state.companyActiv.CompanyBuildingNameNumber : ''
        ppo.items = this.users
        FormService.createppoform(ppo)
        .then(res => {
          console.log(res)
          this.text = 'Obrazac je kreiran.'
          this.color = 'green'
          this.snackbar = true
          this.timeout = 2000
        })
        .catch(err => {
          console.log(err)
          this.text = 'Greska!! Obrazac NIJE kreiran.' + err
          this.color = 'red'
          this.snackbar = true
          this.timeout = 2000
        })
      },
      createExportPDVS() {
        const pdvs = {}
        pdvs.head = this.forms
        pdvs.head.uui = uuidv4()
        pdvs.head.CompanyPlace = (this.$store.state.companyActiv.CompanyPostcode ? this.$store.state.companyActiv.CompanyPostcode : '') + ' ' +this.$store.state.companyActiv.CompanyCity ? this.$store.state.companyActiv.CompanyCity : ''
        pdvs.head.CompanyStreet = this.$store.state.companyActiv.CompanyStreet ? this.$store.state.companyActiv.CompanyStreet : ''
        pdvs.head.CompanyStreetNumber = this.$store.state.companyActiv.CompanyBuildingNameNumber ? this.$store.state.companyActiv.CompanyBuildingNameNumber : ''
        pdvs.items = this.users
        FormService.createpdvsform(pdvs)
        .then(res => {
          console.log(res)
          this.text = 'Obrazac je kreiran.'
          this.color = 'green'
          this.snackbar = true
          this.timeout = 2000
        })
        .catch(err => {
          console.log(err)
          this.text = 'Greska!! Obrazac NIJE kreiran.' + err
          this.color = 'red'
          this.snackbar = true
          this.timeout = 2000
        })
      },
      createExportZP() {
        const zp = {}
        zp.head = this.forms
        zp.head.uui = uuidv4()
        zp.head.CompanyPlace = (this.$store.state.companyActiv.CompanyPostcode ? this.$store.state.companyActiv.CompanyPostcode : '') + ' ' +this.$store.state.companyActiv.CompanyCity ? this.$store.state.companyActiv.CompanyCity : ''
        zp.head.CompanyStreet = this.$store.state.companyActiv.CompanyStreet ? this.$store.state.companyActiv.CompanyStreet : ''
        zp.head.CompanyStreetNumber = this.$store.state.companyActiv.CompanyBuildingNameNumber ? this.$store.state.companyActiv.CompanyBuildingNameNumber : ''
        zp.items = this.users
        FormService.createzpform(zp)
        .then(res => {
          console.log(res)
          this.text = 'Obrazac je kreiran.'
          this.color = 'green'
          this.snackbar = true
          this.timeout = 2000
        })
        .catch(err => {
          console.log(err)
          this.text = 'Greska!! Obrazac NIJE kreiran.' + err
          this.color = 'red'
          this.snackbar = true
          this.timeout = 2000
        })
      },
      downloadExportPPO () {
        this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
        this.mainQuery.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : ''
        this.mainQuery.VATID = this.$store.state.companyActiv.CompanyVATID? this.$store.state.companyActiv.CompanyVATID: ''
        this.mainQuery.month = this.forms.month ? this.forms.month : ''
        this.mainQuery.year = this.forms.year  ? this.forms.year  : ''
        this.mainQuery.quarters = this.forms.quarters  ? this.forms.quarters  : ''
        const zoveSe = 'PPOobrazac_' + this.mainQuery.VATID + '_' + this.mainQuery.month + '_' + this.mainQuery.year + '_.xml'
        FormService.uploadppoform(this.mainQuery)
        .then(res => {
          const data = res.data
          //const status = res.status
          const header = res.headers
          const fileType = header['content-type']
          const blob = new Blob([data], { type: fileType })
          FileSaver.saveAs(blob, zoveSe);
          //FileSaver.saveAs(res.data, zoveSe)
          // this.text = 'Spremite knjigu.'
          // this.color = 'green'
          // this.snackbar = true
          // this.timeout = 2000
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log(err)
          this.text = 'Greska!! Obrazac se NE moze ucitati.' + err
          this.color = 'red'
          this.snackbar = true
          this.timeout = 2000
        })
      },
      downloadExportPDVS () {
        this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
        this.mainQuery.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : ''
        this.mainQuery.VATID = this.$store.state.companyActiv.CompanyVATID? this.$store.state.companyActiv.CompanyVATID: ''
        this.mainQuery.month = this.forms.month ? this.forms.month : ''
        this.mainQuery.year = this.forms.year  ? this.forms.year  : ''
        const zoveSe = 'PDVSobrazac_' + this.mainQuery.VATID + '_' + this.mainQuery.month + '_' + this.mainQuery.year + '_.xml'
        FormService.uploadpdvsform(this.mainQuery)
        .then(res => {
          const data = res.data
          //const status = res.status
          const header = res.headers
          const fileType = header['content-type']
          const blob = new Blob([data], { type: fileType })
          FileSaver.saveAs(blob, zoveSe);
          //FileSaver.saveAs(res.data, zoveSe)
          // this.text = 'Spremite knjigu.'
          // this.color = 'green'
          // this.snackbar = true
          // this.timeout = 2000
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log(err)
          this.text = 'Greska!! Obrazac se NE moze ucitati.' + err
          this.color = 'red'
          this.snackbar = true
          this.timeout = 2000
        })
      },
      downloadExportZP () {
        this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
        this.mainQuery.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : ''
        this.mainQuery.VATID = this.$store.state.companyActiv.CompanyVATID? this.$store.state.companyActiv.CompanyVATID: ''
        this.mainQuery.month = this.forms.month ? this.forms.month : ''
        this.mainQuery.year = this.forms.year  ? this.forms.year  : ''
        const zoveSe = 'ZPobrazac_' + this.mainQuery.VATID + '_' + this.mainQuery.month + '_' + this.mainQuery.year + '_.xml'
        FormService.uploadzpform(this.mainQuery)
        .then(res => {
          const data = res.data
          //const status = res.status
          const header = res.headers
          const fileType = header['content-type']
          const blob = new Blob([data], { type: fileType })
          FileSaver.saveAs(blob, zoveSe);
          //FileSaver.saveAs(res.data, zoveSe)
          // this.text = 'Spremite knjigu.'
          // this.color = 'green'
          // this.snackbar = true
          // this.timeout = 2000
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log(err)
          this.text = 'Greska!! Obrazac se NE moze ucitati.' + err
          this.color = 'red'
          this.snackbar = true
          this.timeout = 2000
        })
      },
      async editHead() {
        await this.$store.dispatch('seteditItem', 1)
        this.$store.dispatch('setretPath', 'formlist')
        await this.$store.dispatch('setMainLedgerid', this.forms.id)
        this.$router.push({
          name: 'formheadcreate', params: {formType: this.formType }
        })
      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      },
      formatPrice (value) {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      async formlist () {
        await this.$store.dispatch('seteditItem', 0)
        this.$store.dispatch('setretPath', 'formlist')
        await this.$store.dispatch('setMainLedgerid', null)
        this.$router.push({
          name: 'formlist', params: {formType: this.formType }
        })
      },
    },
  }
</script>
