<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col>
        <DocumentPOSAgainHead />
      </v-col>
    </v-row>  
    <v-row>
      <v-col>
        <DocumentPOSAgainItems />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DocumentPOSAgainHead from './DocumentPOSAgainHead'
import DocumentPOSAgainItems from './DocumentPOSAgainItems'

export default {
  name: 'documentposagain',
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  },
  components: {
    DocumentPOSAgainItems,
    DocumentPOSAgainHead
  }

}
</script>