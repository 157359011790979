const docEN = {
  Grupa: 'Group',
  Hitno: 'Urgent',
  ValutaPlacanja1: 'Currency',
  Tecaj: 'ExRate',
  ValutaPlacanja2: 'Currency2',
  Platitelj1: 'Payer',
  Platitelj2: 'Payer',
  Platitelj3: 'Payer',
  PlatiteljIBAN: 'Payer IBAM',
  PlatiteljModel: 'Model',
  PlatiteljPoziv: 'Call',
  Opis1: 'Description',
  Opis2: 'Description',
  Opis3: 'Description',
  Iznos: 'Amount',
  Iznos2: 'Amount2',
  Primatelj1: 'Recipient',
  Primatelj2: 'Recipient',
  Primatelj3: 'Recipient',
  PrimateljIBAN: 'Recipient IBAN',
  PrimateljModel: 'Model',
  PrimateljPoziv: 'Call',
  SifraNamjene: '',
  DatumIzvrsenja: 'Date',
  BrojJoppdid: '',
  BrojJoppd: 'Joppd nr',
  bankAccountId: '',
  bankAccountName: 'Bank Account',
  bankAccountNr: 'Bank Account IBAN',
  NewBankOrder: 'New',
  EditBankOrder: 'Edit',
  EmptyBankOrder: 'Empty',
  BankOrder: 'Bank orders'
}

export default docEN