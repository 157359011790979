<template>
  <v-container fluid>
    <v-progress-circular v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
      <v-snackbar
          v-model="snackbar"
          bottom
          :timeout="timeout"
          :color="color"
          >
          {{ text }}
    </v-snackbar>

    <v-row style="border-bottom:solid 1px;">
      <v-flex xs5>
          <h4><b><i>{{comp.CompanyNameShort}}</i></b></h4>
          {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}   <br />                
          {{comp.CompanyPostcode}} {{comp.CompanyCity}}<br />
            OIB: {{comp.CompanyVATID}}
      </v-flex>
      <v-flex xs3>
          <h2>{{  headUp }} </h2>
      </v-flex>
      <v-flex xs 4>
        <p class="text-md-left">
            Datum izvještaja: {{PrintDate}}<br />
            Telefon: {{comp.CompanyPhones}}<br />
            email: {{comp.Companyemail}}<br />
            <v-btn @click="print" class="mr-2">Printaj</v-btn>
            <v-btn text @click="downloadFile" class="mr-2">Export</v-btn>
        </p>
      </v-flex>
    </v-row>
    <v-row style="border-top:solid 1px;">
      <v-flex xs6 class="text-xs-right">

      </v-flex>
      <v-flex xs1 class="text-right">
        {{ lang.Total30}}
      </v-flex>
      <v-flex xs1 class="text-right">
        {{ lang.Total60}}
      </v-flex>
      <v-flex xs1 class="text-right">
        {{ lang.Total90}}
      </v-flex>
      <v-flex xs1 class="text-right">
        {{ lang.Total120 }}
      </v-flex>
      <v-flex xs1 class="text-right">
        {{ lang.TotalOver }}
      </v-flex>
    </v-row>
    <v-row style="border-top:solid 1px;">
      <v-flex xs6 class="text-xs-right">

      </v-flex>
      <v-flex xs1 class="text-right">
        {{ formatPrice(totals.Total30) }}
      </v-flex>
      <v-flex xs1 class="text-right">
        {{ formatPrice(totals.Total60) }}
      </v-flex>
      <v-flex xs1 class="text-right">
        {{ formatPrice(totals.Total90) }}
      </v-flex>
      <v-flex xs1 class="text-right">
        {{ formatPrice(totals.Total120) }}
      </v-flex>
      <v-flex xs1 class="text-right">
        {{ formatPrice(totals.TotalOver) }}
      </v-flex>
    </v-row>

    <v-card class="mt-3">
        <v-card-title>
          {{ lang.OpenStatements }}
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"     
          >

          <template v-slot:[`item.Total30`] ="{ item }">
              <span>{{formatPrice(parseFloat(item.Total30)) }}</span>
          </template>
          <template v-slot:[`item.Total60`] ="{ item }">
              <span>{{formatPrice(parseFloat(item.Total60)) }}</span>
          </template>
          <template v-slot:[`item.Total90`] ="{ item }">
              <span>{{formatPrice(parseFloat(item.Total90)) }}</span>
          </template>
          <template v-slot:[`item.Total120`] ="{ item }">
              <span>{{formatPrice(parseFloat(item.Total120)) }}</span>
          </template>
          <template v-slot:[`item.TotalOver`] ="{ item }">
            <span>{{formatPrice(parseFloat(item.TotalOver)) }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>
      <MainLedgerPrintCard9 ref="prnt" :card="card" :head="mainledgerAna" />
  </v-container>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
//import PartnerService from '@/services/PartnerService'
import MainLedgerPrintCard9 from './MainLedgerPrintCard9'
import xlsx from 'json-as-xlsx'
import dayjs from 'dayjs'

export default {
  name: 'mainledgercard9',
  props: ['items', 'head', 'totals'],
  data () {
    return {
      headUp: '',
      headers: [ ],
      search: '',
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      comp: {},
      error: null,
      localError: false,
      newUser: {},
      size: 'sm',
      havePath: '',
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      pItems: [],
      mainQuery2: {},
      partner: {},
      card: {},
      showCard3: false,
      mainledgerAna: {},
      dItems: [],
      partners: []
    }
  },
  components: {
    MainLedgerPrintCard9
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    if (this.$store.state.partnerStore) {
      this.partners = this.$store.state.partnerStore
    }
    const typePartner = this.head.fromAccount ? this.head.fromAccount.substr(0,2) : ''
    this.headUp = typePartner === '12' ? this.lang.OpenStateBuyers : this.lang.OpenStateSupp

    this.headers.push(
      {text: this.lang.detailId, value: 'line', width: '80px'},
      {text: this.lang.Account4, value: 'account4', width: '100px',sortable: true,},
      {text: this.lang.PartnerName, value: 'partnerName', width: '300px', sortable: true,},
      {text: this.lang.PartnerVATID, value: 'partnerVATID', width: '100px', sortable: true,},
      {text: this.lang.Total30, value: 'Total30', align: 'right', sortable: true,},
      {text: this.lang.Total60, value: 'Total60', align: 'right', sortable: true, },
      {text: this.lang.Total90, value: 'Total90', align: 'right', sortable: true,},
      {text: this.lang.Total120, value: 'Total120', align: 'right', sortable: true,},
      {text: this.lang.TotalOver, value: 'TotalOver', align: 'right', sortable: true,}
    )

    this.PrintDate = dayjs(new Date()).format('llll')
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    
    this.card = {...this.head}
    this.card.items = this.items
    this.card.Total30 = this.totals.Total30
    this.card.Total60 = this.totals.Total60
    this.card.Total90 = this.totals.Total90
    this.card.Total120 = this.totals.Total120
    this.card.TotalOver = this.totals.TotalOver
  },
  computed: {

  },
  methods: {
     print(){
      this.$refs.prnt.print()
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    close () {
      this.show = false
    },
    downloadFile () {
      const columns = [
        { label: this.lang.detailId, value: 'line' },
        { label: this.lang.Account4, value: 'account4' },
        { label: this.lang.PartnerName, value: 'partnerName' },
        { label: this.lang.PartnerVATID, value: 'partnerVATID' },
        { label: this.lang.Total30, value: 'Total30' },
        { label: this.lang.Total60, value: 'Total60' },
        { label: this.lang.Total90, value: 'Total90' },
        { label: this.lang.Total120, value: 'Total120' },
        { label: this.lang.TotalOver, value: 'TotalOver' },
      ]

      const currDate = dayjs().format('DD_MM_YYYY_HH_MM')      
      const content = this.items
      const settings = {
        sheetName: 'Partneri_otvoreno',
        fileName: 'Partneri_otvoreno_banka_' + currDate
      }
      xlsx(columns, content, settings)
    }

  
 
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
