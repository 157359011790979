import Api from '@/services/Api'

export default {
  index (search) {
    return Api().get('account1', {
      params: {
        search: search
      }
    })
  },
  choose (query) {
    return Api().get('account1/choose', {
      params: query
    })
  },
  show (account1Id) {
    return Api().get(`account1/${account1Id}`)
  },
  post (newaccount1) {
    return Api().post('account1', newaccount1)
  },
  put (account1) {
    return Api().put(`account1/${account1.id}`, account1)
  },
  delete (account1Id) {
    return Api().delete(`account1/${account1Id}`)
  }
}
