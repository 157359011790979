<template>

  <v-container>
    <v-layout row wrap  justify-center>
      <v-flex xs12 sm12 md12 lg9 xl6>
          <form
            name="DocumentForm"
            autocomplete="off">

            <v-row no-gutters>
                <h2>
                  <span v-if="this.$store.state.editItem  === 0">
                    {{langC.CreateNew}} {{ documentNameLoc }}
                  </span>
                  <span v-if="this.$store.state.editItem === 1">
                    {{langC.Edit}} {{ documentNameLoc }} {{mainledger.BookID}}
                  </span>                  
                </h2>
            </v-row>
            <br>

            <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  {{lang.CompanyName}}
              </v-col>
              <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8" class="py-0 px-2">
                  <span style="font-weight:bold">{{mainledger.CompanyNameDatabase}}</span>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  {{lang.BusinessYear}}
              </v-col>
              <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8"  class="py-0 px-2">
                  <span style="font-weight:bold">{{mainledger.BusinessYear}}</span>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                {{lang.User}}
              </v-col>
              <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8"  class="py-0 px-2">
                  <span style="font-weight:bold">{{mainledger.First}} {{mainledger.Last}}, {{mainledger.email}}, {{mainledger.UserId}}</span>
              </v-col>
            </v-row>

            <v-divider class="mt-3 mb-5"></v-divider>

          <v-layout row>
              <v-flex xs3 class="py-0 px-2">
                
                  <span v-if="this.$store.state.language == 'EN'">
                    Booking date *:
                   </span>
                   <span v-if="this.$store.state.language == 'HR'">
                    Datum knjiženja *:
                   </span>
              </v-flex>
              <v-flex xs3 sm3>
                <v-dialog
                    ref="dialog1"
                    persistent
                    v-model="modal1"
                    width="290px"
                    :return-value.sync="mainledger.BookingDate"
                  >
                   <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatDate1"
                      :label="lang.BookingDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                    <v-date-picker v-model="mainledger.BookingDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal1 = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.dialog1.save(mainledger.BookingDate)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
             </v-flex>
          </v-layout>
  
            <v-layout row>
                <v-flex xs3 class="py-4 px-2">
                    Dokument:
                </v-flex>
                 <v-flex xs1 class="mr-2">
                   <v-text-field 
                      label="Id"
                      v-model="mainledger.BookTypeID"
                      readonly
                    ></v-text-field>  
                </v-flex>

                <v-flex xs2 class="mr-2">
                    <v-text-field 
                      label="Vrsta"
                      v-model="mainledger.BookName"
                      readonly
                    ></v-text-field> 
                </v-flex>
               
                <v-flex xs3>
         
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs3 class="py-4 px-2">
    
                </v-flex>
                 <v-flex xs6 class="mr-2">
                   <v-text-field 
                      label="Opis"
                      v-model="mainledger.MLFree1"
                    ></v-text-field> 
                </v-flex>

              </v-layout>

           


            <div v-if="$store.state.bookTypeSelected.value === 28">

              <v-row no-gutters>
                  <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-4 px-2 headline">
                      Iznos prometa:
                  </v-col>
                  <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    <v-text-field
                        class="headline pr-4"
                        label="Primarni iznos"
                        v-model.number="mainledger.InvoiceAmount"
                        type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-2">
                      <v-select label="Primarna valuta" :items="currnecyType" v-model="mainledger.HomeCurrency" value="mainledger.HomeCurrency"></v-select>
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pt-4 mr-1"  v-if="BusinessYear <=2022">
                    <v-text-field
                        dense
                        label="Tecaj:"
                        v-model.number="mainledger.ExRate"
                        type="number"
                    ></v-text-field> 
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1"  v-if="BusinessYear <=2022">
                      <v-select label="Sekundarna valuta" :items="currnecyType" v-model="mainledger.ForeignCurrency" value="mainledger.ForeignCurrency"></v-select>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pt-4 ml-3"  v-if="BusinessYear <=2022">
                    <v-text-field
                        dense
                        label="Sekun.iznos"
                        v-model.number="mainledger.InvoiceForeginAmount"
                        type="number"
                    ></v-text-field> 
                  </v-col>
              </v-row>                           

              <v-row no-gutters>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-4 px-2" >
                  PDV 25%:
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                    <v-text-field
                        dense
                        label="25% PDV iznos"
                        v-model.number="mainledger.VAT1Amount"
                        type="number"
                    ></v-text-field>
                </v-col>
                
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-3">
                  <v-text-field
                        dense
                        label="%PDV može se odbiti"
                        v-model.number="mainledger.VAT1PerCanUse"
                        type="number"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                    <v-text-field
                        dense
                        label="Izn. Može se odbiti"
                        v-model.number="mainledger.VAT1CanUse"
                        type="number"
                    ></v-text-field>
                </v-col>
                
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                    <v-text-field
                        dense
                        label="Izn.NE može se odbiti"
                        v-model.number="mainledger.VAT1CanTUse"
                        type="number"
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <v-text-field
                        dense
                        label="Osnovica"
                        v-model.number="mainledger.VAT1Base"
                        type="number"
                    ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-4 px-2" >
                  PDV 13%:
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                    <v-text-field
                        dense
                        label="13% PDV iznos"
                        v-model.number="mainledger.VAT2Amount"
                        type="number"
                    ></v-text-field>
                </v-col>
                
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-3">
                    <v-text-field
                        dense
                        label=" %PDV moze se odbiti"
                        v-model.number="mainledger.VAT2PerCanUse"
                        type="number"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                    <v-text-field
                        dense
                        label="Izn. Može se odbiti"
                        v-model.number="mainledger.VAT2CanUse"
                        type="number"
                    ></v-text-field>
                </v-col>
                
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                    <v-text-field
                        dense
                        label="Izn.NE Može se odbiti"
                        v-model.number="mainledger.VAT2CanTUse"
                        type="number"
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <v-text-field
                        dense
                        label="Osnovica"
                        v-model.number="mainledger.VAT2Base"
                        type="number"
                    ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-4 px-2" >
                  PDV 5%:
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                    <v-text-field
                        dense
                        label="5% PDV iznos"
                        v-model.number="mainledger.VAT3Amount"
                        type="number"
                    ></v-text-field>
                </v-col>
                
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-3">
                    <v-text-field
                        dense
                        label="%PDV moze se odbiti"
                        v-model.number="mainledger.VAT3PerCanUse"
                        type="number"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                    <v-text-field
                        dense
                        label="Izn. Može se odbiti"
                        v-model.number="mainledger.VAT3CanUse"
                        type="number"
                    ></v-text-field>
                </v-col>
                
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                    <v-text-field
                        dense
                        label="Izn.NE moze se odbiti"
                        v-model.number="mainledger.VAT3CanTUse"
                        type="number"
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <v-text-field
                        dense
                        label="Osnovica"
                        v-model.number="mainledger.VAT3Base"
                        type="number"
                    ></v-text-field>
                </v-col>
              </v-row>             

              <v-row no-gutters>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-4 px-2" >
                  Ostalo:
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                    <v-text-field
                        dense
                        label="Neoporezivi iznos"
                        v-model.number="mainledger.VAT4Base"
                        type="number"
                    ></v-text-field>
                </v-col>
                
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                    <v-text-field
                        dense
                        label="PDV ukupno (25+13+5)"
                        v-model.number="mainledger.AmountVAT"
                        type="number"
                        disabled
                    ></v-text-field>
  
                </v-col>

                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-3">

  
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                    <v-text-field
                        dense
                        label="= Porezna osnovica:"
                        v-model.number="mainledger.AmountNetto"
                        type="number"
                        disabled
                    ></v-text-field>
                </v-col>

              </v-row> 
            </div>

            <v-layout row>
              <v-flex xs3 class="pa-3 headline" >
                Broj knjiženja:
              </v-flex>
              
              <v-flex xs2 class="pa-3 headline">
                  <span >{{ mainledger.BookID }}
                  </span>
              </v-flex>

            </v-layout>

          </form>

          <div class="pink darken-2" v-html="error" />

          <v-btn
            class="primary mr-2"
             :disabled="!formIsValid"
            @click="register">
            PROKNJIŽI
          </v-btn>
          <v-btn
            class="primary mr-2"
            @click="mainledgeragain">
            OTVORI NOVI
          </v-btn>
          <v-btn
            color="green"
            @click="mainledgerlist">
            <span v-if="this.$store.state.language == 'EN'">
            Back
            </span>
            <span v-if="this.$store.state.language == 'HR'">
            Natrag
            </span>
          </v-btn>

      </v-flex>
    </v-layout>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import MainLedgerService from '@/services/MainLedgerService'
import MainLedgerDetailService from '@/services/MainLedgerDetailService'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import { defNew, defMainQuery, defMainLedger, defMainLedgerDetailFillUpdate } from './mainledgerHelper'
import dateFormat from '@/snippets/dateFormat'
import { testLastUpdate } from '../../services/TestLastUpdate'
import dayjs from 'dayjs'

export default {
  name: 'mainledgercreate3',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      lang: {},
      langC: {},
      menu: false,
      menu2: false,
      menu3: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      date1: null,
      date2: null,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      msg: '',
      valid: true,
      documentName: '',
      documentNameLoc: '',
      mainledger: {},
      error: null,
      localError: false,
      size: 'sm',
      required: (value) => !!value || 'Required.',
      currnecy: [],
      users: [],
      CompanyId: 0,
      selectBookName: [],
      selectBank: {},
      selectCR: {},
      selectUs: {},
      mainQuery: {
      },
      currnecyType: [
        { text: 'EUR', value: 'EUR' },
        { text: 'Kn', value: 'Kn' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      bookType: [],
      money: {
        prefix: '= ',
        suffix: ' ',
        precision: 3
      },
      price: 7.500,
      mainQuery3: {},
      databaseMLID: 0,
      BussinesYear: 2020
    }
  },
  components: {
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    const d = new Date()
    this.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : d.getFullYear()
    
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    if (this.$store.state.companyid) {
      this.CompanyId = this.$store.state.companyid
      this.mainQuery3.CompanyId = this.$store.state.companyid
    }
    if (this.CompanyId > 0) {
      //await checkPartners(0)
      await testLastUpdate()
      if (this.$store.state.partnerStore) {
        this.partners = this.$store.state.partnerStore
      }
      //this.partners = (await PartnerService.index()).data.partners
    }
    // create NEW entry, then set default data
    if (this.$store.state.editItem === 0) {
      this.mainledger = defMainLedger()
      this.mainledger = {...this.mainledger, ...defNew()}
      this.mainQuery = {...defMainQuery()}
      this.localUser = this.mainledger.First + ' ' + this.mainledger.Last + ', ' + this.mainledger.email
    }
     if (this.$store.state.editItem === 1) {
      const locDocument = await MainLedgerService.show(this.$store.state.mainledgerid)
      this.mainledger = locDocument.data.mainLedger
      this.databaseMLID = this.mainledger.id
      if (this.mainledger.partnerId) {
        this.selectPa.id = Number(this.mainledger.partnerId)
      }
      if (this.mainledger.partnerName) {
        this.selectPa.partnerName = this.mainledger.partnerName
      }
    }

  },
  computed: {
    formIsValid () {
      return this.mainledger.email !== '' &&
        this.mainledger.First !== '' &&
        this.mainledger.Last !== '' &&
        this.mainledger.InvoiceDate !== '' &&
        this.mainledger.BookingDate !== ''
    },
    formatDate1() {
      return this.mainledger.BookingDate
        ? dateFormat(this.mainledger.BookingDate)
        : "";
    },
  },
  methods: {
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async register () {
      try {
        // 
        const haveYear = dayjs(this.mainledger.BookingDate).year()
        if (haveYear !== this.$store.state.businessYear) {
          this.text = 'Greška!! Datum temeljnice je u krivoj poslovnoj godini !!'
          this.color = 'red'
          this.snackbar = true
          this.timeout = 3000
          return
        }

        this.mainledger.InvoiceDate = this.mainledger.BookingDate
        this.mainledger.InvoicePaymentDeadlineDate = this.mainledger.BookingDate
        this.mainledger.InvoiceDate = this.mainledger.BookingDate
        this.mainledger.DeliveryNoteDate  = this.mainledger.BookingDate
        await this.$store.dispatch('setMainLedgerActiv', this.mainledger ? this.mainledger : {})
        // write  to database
         if (this.$store.state.editItem === 0) {
          if (this.$store.state.bookTypeSelected && this.$store.state.bookTypeSelected.value) {
            this.mainQuery.BookTypeID = this.$store.state.bookTypeSelected.value
            this.mainledger.BookID = (await MainLedgerService.choose(this.mainQuery)).data.docNumber
            this.mainledger.documentIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-' + this.$store.state.documentActiv + '-' + this.mainledger.BookID 
            await MainLedgerService.post(this.mainledger)
            if (this.$store.state.language === 'EN') {
              this.text = 'New document has been saved..'
              this.color = 'green'
              this.snackbar = true
            }
            if (this.$store.state.language === 'HR') {
              this.text = 'Nova temeljnica je snimljena u bazu.'
              this.color = 'green'
              this.snackbar = true
            }
          } else {
            this.text = 'Greska! Nije odabrana vrsta knjige!! '
            this.color = 'red'
            this.snackbar = true
          }
        }
        if (this.$store.state.editItem === 1) {
          await MainLedgerService.put(this.mainledger)

           // update items
          this.mldetails = (await MainLedgerDetailService.index(this.mainledger.id)).data.mainledgerDetails
          const updateHead = defMainLedgerDetailFillUpdate()
          const updatedItems = []
          if (this.mldetails) {
          
            this.mldetails.map(detail => {
              const newItem = {...detail, ...updateHead}
              updatedItems.push(newItem)
            })
          }
          if (updatedItems && updatedItems.length >0) {
            await MainLedgerDetailService.saveitems(updatedItems)
          }

          // const {data} = MainLedgerDetailService.index(this.databaseMLID)
          // console.log("🚀 ~ file: MainLedgerCreate3.vue ~ line 345 ~ register ~ data", data)
          
          // if (data && data.mainledgerDetails !== undefined && data.mainledgerDetails.length > 0) {
          //   data.mainledgerDetails.map(async(item) => {
          //     const updateDetail = {}
          //     updateDetail.id = item.id
          //     updateDetail.BookingDate = item.BookingDate
          //     await MainLedgerDetailService.update(updateDetail)
          //   })
          // }
          if (this.$store.state.language === 'EN') {
            this.text = 'Updates are saved..'
            this.color = 'green'
            this.snackbar = true
          }
          if (this.$store.state.language === 'HR') {
            this.text = 'Ispravke temeljnice snimljene u bazu.'
            this.color = 'green'
            this.snackbar = true
          }
        }
        this.localError = false
        
      } catch (error) {
        this.text = 'Error! ' + error
        this.color = 'red'
        this.snackbar = true
        this.error = 'Greska u konekciji na bazu'
      }
    },
    close () {
      this.show = false
    },
    async mainledgerlist () {
      try {
        await this.$router.push({
          name: 'mainledger3'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async mainledgeragain () {
      try {
        this.$store.dispatch('setpathAgain', 'mainledgercreate3')
        await this.$router.push({
          name: 'mainledger3'
        })
        // Object.assign(this.$data, this.$options.data.call(this))
        // location.reload()
        // this.$forceUpdate()
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
