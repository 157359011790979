<template>
<div>
  <v-container fluid grid-list-md>
  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="account4s"
    :search="search"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="50"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{lang.Account4s}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="700px">

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                <v-row no-gutters>
                    <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="pr-2">
                      <v-text-field
                        v-model="editedItem.account1"
                        :label="lang.Account1"
                        required
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                      <v-text-field
                        v-model="localAcc1"
                        :label="lang.Account1Description"
                        required
                        disabled
                      ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters> 
                    <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="pr-2">
                      <v-text-field
                        v-model="editedItem.account2"
                        :label="lang.Account2"
                        required
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                      <v-text-field
                        v-model="localAcc2"
                        :label="lang.Account2Description"
                        required
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="pr-2">
                      <v-text-field
                        v-model="editedItem.account3"
                        :label="lang.Account3"
                        required
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                      <v-text-field
                        v-model="localAcc3"
                        :label="lang.Account3Description"
                        required
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="pr-2">
                      <v-text-field
                        v-model="editedItem.account4"
                        :label="lang.Account4"
                        required
                        maxlength="6"
                        :rules="account4Rules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                      <v-text-field
                        v-model="editedItem.account4Description"
                        :label="lang.Account4Description"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters> 
                    <v-col cols="12" sm="2" md="2" lg="2" xl="2">

                    </v-col>
                    <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                      <v-text-field
                        v-model="editedItem.account4Description2"
                        :label="lang.Account4Description2"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                      <v-checkbox
                        v-model="editedItem.account4Supplier"
                        :label="lang.Supplier"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                      <v-checkbox
                        v-model="editedItem.account4Buyer"
                        :label="lang.Buyer"
                      ></v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                      <v-checkbox
                        v-model="editedItem.account4Bool1"
                        :label="lang.PrivateAccount"
                      ></v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                   <v-row v-if="haveError">                    
                      <div class="red" v-html="haveError" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn :disabled="!valid" color="success" text @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
 
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      {{ lang.Account4sEmpty }}
    </template>
  </v-data-table>
  <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>
  </v-container>
  </div>
</template>

<script>
import Account4Service from '@/services/Account4Service'
import Account3Service from '@/services/Account3Service'
import Account2Service from '@/services/Account2Service'
import Account1Service from '@/services/Account1Service'
import MainLedgerDetailService from '@/services/MainLedgerDetailService'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import { newUpdates, testLastUpdate } from '@/services/TestLastUpdate'

  export default {
    name: 'account4CRUD',
    data: () => ({
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      search: '',
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      haveError: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {
      },
      dialog: false,
      headers: [ 
      ],
      account1s: [],
      account2s: [],
      account3s: [],
      account4s: [],
      localAcc1: '',
      localAcc2: '',
      localAcc3: '',
      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        account1: '',
        account1id: 0,
        account2: '',
        account2id: 0,
        account3: '',
        account3id: 0,
        account4: '',
        account4Description: '',
        account4Description2: '',
        EnteredInDatabase: null,
        account4Buyer: false,
        account4Supplier: false,
        bookTypeInvoiceSupplier: 0,
        bookColumnInvoiceSupplier: 0,
        bookVATCanUseSupplier: 0,
        bookVATCantUseSupplier: 0,
        bookTypeInvoiceBuyer: 0,
        bookColumnInvoiceBuyer: 0,
        bookVATCanUseBuyer: 0,
        bookVATCantUseBuyer: 0,
        EUTypeBook: 0,
        EUColumnInType: 0,
        EUVATcanuse: 0,
        EUVATcantuse: 0,
        EUVATtypeS: 0,
        EUVATtypeZP: 0,
        EUPPO: false,
        account4Free1: '',
        account4Free2: '',
        account4Free3: '',
        account4Free4: '',
        account4Free5: '',
        account4Bool1: false,
        account4Bool2: false,
        account4Bool3: false,
        account4Bool4: false,
        account4Bool5: false,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: ''
      },
      confpassword: '',
      privitems: [ ],
      valid: true,
      nameRules: [
        v => !!v || 'Name is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password must be 6 or more characters',
      ],
      account4Rules: [
        v => !!v || 'Account is required',
        v => (v && v.length >= 4) || 'Account must be 4 to 6 characters',
      ],
      locusers: [],
      select: { },
      postNewAccount: false
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewAccount4: this.lang.EditAccount4
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      'editedItem.account4' () {
        this.choosedAcc(this.editedItem.account4)
      }
    },

    async mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
        this.defaultItem.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.companyName) {
        this.defaultItem.CompanyNameDatabase = this.$store.state.companyName
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }

      this.headers.push(
        {
          text: this.lang.Account4,
          align: 'left',
          sortable: true,
          value: 'account4',
          width: '80px'
        },
        {
          text: this.lang.Account3,
          align: 'left',
          sortable: true,
          value: 'account3',
          width: '60px'
        },
        {
          text: this.lang.Account2,
          align: 'left',
          sortable: true,
          value: 'account2',
          width: '50px'
        },
        {
          text: this.lang.Account1,
          align: 'left',
          sortable: true,
          value: 'account1',
          width: '60px'
        },
        {text: this.lang.Account4Description, value: 'account4Description', align: 'left', width: '750px' },
        {text: this.lang.Actions, value: 'action', sortable: false },
      )
      this.defaultItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
      this.defaultItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
      this.defaultItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
      this.defaultItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
      this.defaultItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : ''
      // this.account1s = (await Account1Service.show()).data.account1s
      // this.account2s = (await Account2Service.show()).data.account2s
      // this.account3s = (await Account3Service.show()).data.account3s
      this.saving = true
      this.refreshAll()
      //this.account4s = this.$store.state.account4s ? this.$store.state.account4s : []
      //this.account4s = (await Account4Service.index(this.mainQuery)).data.account4s
      this.saving = false
    },

    created () {
      this.initialize()
    },

    methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      async refreshAll () {
        this.account1s = (await Account1Service.show()).data.account1s
        this.account2s = (await Account2Service.show()).data.account2s
        this.account3s = (await Account3Service.show()).data.account3s

        await Account4Service.index(this.mainQuery).then(res => {
          if (res.data.account4s) {
            this.$store.dispatch('setAccount4s', res.data.account4s)
            this.account4s = res.data.account4s ? res.data.account4s: []
            // eslint-disable-next-line
            console.log('OK refresh account4s ')
          }
        })
        .catch(err => {
          this.text = 'Error ' + err
          this.color = 'red'
          this.snackbar = true
        })

      },
      async initialize () {
        try {
          this.haveError = null
          
          this.errorMatch = false
          this.editedItem = {...this.defaultItem}
          this.select = {}
        } catch (err) {
          this.error = err
          this.text = 'Error ' + err
          this.color = 'red'
          this.snackbar = true
        }
      },
      editItem (item) {
        this.errorMatch = false
        this.haveError = null
        this.editedIndex = this.account4s.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      async deleteItem (item) {
        try {
          this.mainQuery = {}
          this.mainQuery.account4 = item.account4 ? item.account4 : ''
          const {data} = await MainLedgerDetailService.analyze(this.mainQuery)
          if (data && data.documents && data.documents.length > 0) {
            if (this.$store.state.language === "EN") {
              if (confirm('CAN NOT DELETE ACCOUNT. THERE ARE BOOKED ITEMS (' + parseInt(data.documents.length) + ') ON THAT ACCOUNT!')) {
                // eslint-disable-next-line
                console.log(' no delete ')
              }
            }
            if (this.$store.state.language === "HR") {
              if (confirm('NE MOŽETE OBRISATI KONTO, JER IMA STAVAKA(' + parseInt(data.documents.length) + ') PROKNJIŽENIH NA TOM KONTU!')) {
                // eslint-disable-next-line
                console.log(' no delete ')
              }
            }            
          } else {
            const index = this.account4s.indexOf(item)
            const warningMessage = item.account4 + ' ' + item.account4Description
            if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.account4s.splice(index, 1)) {
              Account4Service.delete(item.id)
              await newUpdates(3)
              await testLastUpdate()
              this.refreshAll()
              this.initialize()
            }
          }
        } catch(err) {
          this.error = err
          this.text = 'Error ' + err
          this.color = 'red'
          this.snackbar = true
        }
        
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      async save () {
        try {
           let doneOK = false
           if (this.editedItem.account4Bool1) {
              this.editedItem.FreeF1 = this.$store.state.companyid ? this.$store.state.companyid :0
            } else {
              this.editedItem.FreeF1 = 0
           }
           console.log('this.editedIndex', this.editedIndex)

           if (this.editedIndex > -1) {
              this.editedItem.account4Description = this.editedItem.account4Description ? (this.editedItem.account4Description).trim() : ''
              this.editedItem.account4Description2 = this.editedItem.account4Description2 ? (this.editedItem.account4Description2).trim() : ''
              if (this.editedItem.account4.trim().length >= 4) {
                  await Account4Service.put(this.editedItem)
                  await newUpdates(3)
                  await testLastUpdate()
                  // if (data.account) {
                  //   this.account4s.push(data.account)
                  //   this.$store.dispatch('setAccount4s', this.account4s)
                  // }
                doneOK = true
              }
            } else {
              this.haveError = null
              if (this.editedItem.account4Bool1 === true) {
                await this.checkAccount4Private()
              } else {
                await this.checkAccount4()
              }
              delete this.editedItem.id
              this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              this.editedItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
              this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
              this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              this.editedItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : ''
              this.editedItem.account4Description = this.editedItem.account4Description ? (this.editedItem.account4Description).trim() : ''
              this.editedItem.account4Description2 = this.editedItem.account4Description2 ? (this.editedItem.account4Description2).trim() : ''
              console.log('da postam this.postNewAccount ', this.postNewAccount)
              if (this.postNewAccount === true) {
                console.log('2 duzina', this.editedItem.account4.trim().length)
                if (this.editedItem.account4.trim().length >= 4 && this.editedItem.account1 && this.editedItem.account2 && this.editedItem.account3) {
                  console.log('3 ')
                  await Account4Service.post(this.editedItem)
                  await newUpdates(3)
                  await testLastUpdate()
                  // if (data.account) {
                  //   this.account4s.push(data.account)
                  //   this.$store.dispatch('setAccount4s', this.account4s)
                  // }
                  doneOK = true
                  this.text = 'OK saved.'
                  this.color = 'green'
                  this.snackbar = true
                }
              } else {
                this.haveError = this.lang.AccountError
                this.text = 'Error ' + this.lang.AccountError
                this.color = 'red'
                this.snackbar = true
              }
            }
            if (doneOK) {
              this.refreshAll()
              this.initialize()
              this.close()
            }
        } catch(err) {
          this.error = err
          this.text = 'Error ' + err
          this.color = 'red'
          this.snackbar = true
        }
      },
      async checkAccount4() {
        this.postNewAccount = false
         const checkAccount4 = {
            account4: this.editedItem.account4.trim(),
          }
        const res = await Account4Service.choose(checkAccount4)
        if (res && res.data && !res.data.result) {
          this.postNewAccount = true
        }
        console.log('res ', res)

      },
      // async checkAccount4() {
      //   this.postNewAccount = false
      //   try {
      //     const checkAccount4 = {
      //       account4: this.editedItem.account4.trim(),
      //     }
      //     await Account4Service.choose(checkAccount4)
      //   } catch(error) {
      //     this.postNewAccount = true
      //   }
      // },
      async checkAccount4Private() {
        this.postNewAccount = false
         const checkAccount4 = {
            account4: this.editedItem.account4.trim(),
            companyid : this.$store.state.companyid ? this.$store.state.companyid : ''
          }
        const res = await Account4Service.choose(checkAccount4)
        if (res && res.data && !res.data.result) {
          this.postNewAccount = true
        }
        console.log('res ', res)

        // this.postNewAccount = false
        // try {
        //   const checkAccount4 = {
        //     account4: this.editedItem.account4.trim(),
        //     companyid : this.$store.state.companyid ? this.$store.state.companyid : ''
        //   }
        //   await Account4Service.choose(checkAccount4)
        // } catch(error) {
        //   this.postNewAccount = true
        // }
      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      },
      choosedAcc (choosedAcc) {
        if (this.account1s && this.account2s && choosedAcc) {
          const haveIt = choosedAcc.substr(0,1)
          this.account1s.forEach(element => {
            if (element.account1 === haveIt) {
              this.editedItem.account1 = element.account1
              this.editedItem.account1id = element.id
              this.localAcc1 = element.account1Description
            }
          })
          const haveIt2 = choosedAcc.substr(0,2)
          this.account2s.forEach(element => {
            if (element.account2 === haveIt2) {
              this.editedItem.account2 = element.account2
              this.editedItem.account2id = element.id
              this.localAcc2 = element.account2Description
            }
          })
          const haveIt3 = choosedAcc.substr(0,3)
          this.account3s.forEach(element => {
            if (element.account3 === haveIt3) {
              this.editedItem.account3 = element.account3
              this.editedItem.account3id = element.id
              this.localAcc3 = element.account3Description
            }
          })
        }
      }
    },
  }
</script>