<template>
  <div>

       <v-row no-gutters>
        <v-col cols="11" class="headline text--primary">                   
            {{lang.FinancialData}}
        </v-col>
        <v-col cols="1">
            <span style="font-weight:bold" v-if="document.exportDoc">INO</span>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6" style="font-weight:bold">   
          {{document.bankAccountName}}                
        </v-col>
        <v-col cols="6">
            <span style="font-weight:bold">{{document.bankAccountNr}}</span>
        </v-col>
      </v-row>

    <v-divider></v-divider>

     <v-row no-gutters v-if="docSide === 1" >
        <v-col cols="4">
             {{lang.supplierAmount}} 
        </v-col>
        <v-col cols="4">
            
        </v-col>
        <v-col cols="4">
          <div class="text-right">
            {{supplierAmount}}
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="docSide === 1" >
        <v-col cols="4">
             {{lang.supplierDiscount}} 
        </v-col>
        <v-col cols="4">
            
        </v-col>
        <v-col cols="4">
          <div class="text-right">
            {{supplierDiscount}}
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="docSide === 1" >
        <v-col cols="4">
             {{lang.supplierNetoAmount}} 
        </v-col>
        <v-col cols="4">
            
        </v-col>
        <v-col cols="4">
          <div class="text-right font-weight-bold">
            {{supplierNetoAmount}}
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="docSide === 1" >
        <v-col cols="4">
             {{lang.supplierMargin}} 
        </v-col>
        <v-col cols="4">
            
        </v-col>
        <v-col cols="4">
          <div class="text-right">
            {{supplierMargin}}
          </div>
        </v-col>
      </v-row>

  <v-divider></v-divider>

      <v-row no-gutters v-if="document.documentTotalVAT1Amount != 0" >
        <v-col cols="4">
             {{lang.documentTotalVAT1Amount}} {{document.documentTotalVAT1Per}} %
        </v-col>
        <v-col cols="4">
            <span style="font-weight:normal">{{documentTotalVAT1Base}}</span>
        </v-col>
        <v-col cols="4">
          <div class="text-right">
            {{documentTotalVAT1Amount}}
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters v-if="document.documentTotalVAT2Amount != 0">
        <v-col cols="4">
              {{lang.documentTotalVAT2Amount}} {{document.documentTotalVAT2Per}} %
        </v-col>
        <v-col cols="4">
            <span style="font-weight:normal">{{documentTotalVAT2Base}}</span>
        </v-col>
        <v-col cols="4">
          <div class="text-right">
            {{documentTotalVAT2Amount}}
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters v-if="document.documentTotalVAT3Amount != 0">
        <v-col cols="4">
              {{lang.documentTotalVAT3Amount}} {{document.documentTotalVAT3Per}} %
        </v-col>
        <v-col cols="4">
            <span style="font-weight:normal">{{documentTotalVAT3Base}}</span>
        </v-col>
        <v-col cols="4">
          <div class="text-right">
            {{documentTotalVAT3Amount}}
          </div>
        </v-col>
      </v-row>


    <v-divider></v-divider>

    <v-row no-gutters>
        <v-col cols="5">
  
        </v-col>
        <v-col cols="4">
          <div class="text-right">
            {{document.documentHomeCurrency}}
          </div>
        </v-col>
         <v-col cols="3" v-if="!showOnlyEUR">
          <div class="text-right">
            {{document.documentForeignCurrency}}
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="5">
            {{lang.documentTotalAmountNetto}}
        </v-col>
        <v-col cols="4">
          <div class="text-right">
            {{documentTotalAmountNetto}}
          </div>
        </v-col>
         <v-col cols="3" v-if="!showOnlyEUR">
          <div class="text-right">
            {{documentTotalAmountNettoForeign}}
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="5">
            {{lang.documentTotalAmountVAT}}
        </v-col>
        <v-col cols="4">
          <div class="text-right">
            {{documentTotalAmountVAT}}
          </div>
        </v-col>
         <v-col cols="3" v-if="!showOnlyEUR">
          <div class="text-right">
            {{documentTotalAmountVATForeign}}
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="5">
            {{lang.documentTotalAmountDirectD1}} 
        </v-col>
        <v-col cols="4">
          <div class="text-right">
            {{documentTotalAmountDirectD1}}
          </div>
        </v-col>
         <v-col cols="3" v-if="!showOnlyEUR">
          <div class="text-right">
            {{documentTotalAmountDirectD1Foreign}}
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="5">
            {{lang.documentTotalAmountBrutto}} 
        </v-col>
        <v-col cols="4">
          <div class="text-right font-weight-bold">
            {{documentTotalAmountBrutto}}
          </div>
        </v-col>
         <v-col cols="3" v-if="!showOnlyEUR">
          <div class="text-right font-weight-bold">
             {{documentTotalAmountBruttoForeign}}
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters v-if="docSide != 1">
        <v-col cols="5">
            {{lang.documentTotalAmountPayInAdvance}}
        </v-col>
        <v-col cols="4">
            <div class="text-right">
              {{documentTotalAmountPayInAdvance}}
            </div>
        </v-col>
        <v-col cols="3" v-if="!showOnlyEUR">
            <div class="text-right">
              {{documentTotalAmountPayInAdvanceForeign}}
            </div>
        </v-col>
      </v-row>

    <v-row no-gutters v-if="docSide != 1">
        <v-col cols="5">
          <div class="font-weight-bold">
            {{lang.documentTotalAmountToPay}}
          </div>
        </v-col>
        <v-col cols="4">
            <div class="text-right font-weight-bold">
              {{documentTotalAmountToPay}}
            </div>
        </v-col>
        <v-col cols="3" v-if="!showOnlyEUR">
            <div class="text-right font-weight-bold">
              {{documentTotalAmountToPayForeign}}
            </div>
        </v-col>
      </v-row>              


  </div>
</template>

<script>
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'

export default {
  data() {
    return {
      document: {},
      lang: {},
      langC: {},
      documentNameLoc: '',
      docSide: 3,
      supplierAmount: 0,
      supplierDiscount: 0,
      supplierNetoAmount: 0,
      supplierMargin: 0,
      documentTotalVAT1Amount: 0,
      documentTotalVAT1Base: 0,
      documentTotalVAT2Amount: 0,
      documentTotalVAT2Base: 0,
      documentTotalVAT3Amount: 0,
      documentTotalVAT3Base: 0,
      documentTotalAmountNetto: 0,
      documentTotalAmountVAT: 0,
      documentTotalAmountDirectD1: 0,
      documentTotalAmountBrutto: 0,
      documentTotalAmountPayInAdvance: 0,
      documentTotalAmountToPay: 0,
      documentTotalAmountNettoForeign: 0,
      documentTotalAmountVATForeign: 0,
      documentTotalAmountDirectD1Foreign: 0,
      documentTotalAmountBruttoForeign: 0,
      documentTotalAmountPayInAdvanceForeign: 0,
      documentTotalAmountToPayForeign: 0,
      showOnlyEUR: true
    }
  },
  mounted() {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    this.showOnlyEUR = this.$store.state.businessYear && this.$store.state.businessYear <= 2022 ? false : true
    if (this.$store.state.documentActivHead) {
      this.document = this.$store.state.documentActivHead
      this.documentNameLoc = this.$store.state.documentActivLoc
    }
     if (this.$store.state.documentSide) {
        this.docSide = this.$store.state.documentSide
      }
    this.supplierAmount = this.document.supplierAmount ? this.formatPrice(this.document.supplierAmount) : 0
    this.supplierDiscount = this.document.supplierDiscount ? this.formatPrice(this.document.supplierDiscount) : 0
    this.supplierNetoAmount = this.document.supplierNetoAmount ? this.formatPrice(this.document.supplierNetoAmount) : 0
    this.supplierMargin = this.document.supplierMargin ? this.formatPrice(this.document.supplierMargin) : 0

    this.documentTotalVAT1Base = this.document.documentTotalVAT1Base ? this.formatPrice(this.document.documentTotalVAT1Base) : 0
    this.documentTotalVAT1Amount = this.document.documentTotalVAT1Amount ? this.formatPrice(this.document.documentTotalVAT1Amount) : 0
    this.documentTotalVAT2Base = this.document.documentTotalVAT2Base ? this.formatPrice(this.document.documentTotalVAT2Base) : 0
    this.documentTotalVAT2Amount = this.document.documentTotalVAT2Amount ? this.formatPrice(this.document.documentTotalVAT2Amount) : 0
    this.documentTotalVAT3Base = this.document.documentTotalVAT3Base ? this.formatPrice(this.document.documentTotalVAT3Base) : 0
    this.documentTotalVAT3Amount = this.document.documentTotalVAT3Amount ? this.formatPrice(this.document.documentTotalVAT3Amount) : 0

    this.documentTotalAmountNetto = this.document.documentTotalAmountNetto ? this.formatPrice(this.document.documentTotalAmountNetto) : 0
    this.documentTotalAmountVAT = this.document.documentTotalAmountVAT ? this.formatPrice(this.document.documentTotalAmountVAT) : 0
    this.documentTotalAmountDirectD1 = this.document.documentTotalAmountDirectD1 ? this.formatPrice(this.document.documentTotalAmountDirectD1) : 0
    this.documentTotalAmountBrutto = this.document.documentTotalAmountBrutto ? this.formatPrice(this.document.documentTotalAmountBrutto) : 0
    this.documentTotalAmountPayInAdvance = this.document.documentTotalAmountPayInAdvance ? this.formatPrice(this.document.documentTotalAmountPayInAdvance) : 0
    this.documentTotalAmountToPay = this.document.documentTotalAmountToPay ? this.formatPrice(this.document.documentTotalAmountToPay) : 0

    this.documentTotalAmountNettoForeign = this.document.documentTotalAmountNettoForeign ? this.formatPrice(this.document.documentTotalAmountNettoForeign) : 0
    this.documentTotalAmountVATForeign = this.document.documentTotalAmountVATForeign ? this.formatPrice(this.document.documentTotalAmountVATForeign) : 0
    this.documentTotalAmountDirectD1Foreign = this.document.documentTotalAmountDirectD1Foreign ? this.formatPrice(this.document.documentTotalAmountDirectD1Foreign) : 0
    this.documentTotalAmountBruttoForeign = this.document.documentTotalAmountBruttoForeign ? this.formatPrice(this.document.documentTotalAmountBruttoForeign) : 0
    this.documentTotalAmountPayInAdvanceForeign = this.document.documentTotalAmountPayInAdvanceForeign ? this.formatPrice(this.document.documentTotalAmountPayInAdvanceForeign) : 0
    this.documentTotalAmountToPayForeign = this.document.documentTotalAmountToPayForeign ? this.formatPrice(this.document.documentTotalAmountToPayForeign) : 0
  },
  methods: {
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
  } 
}
</script>

<style>

</style>