<template>
  <div>

  </div>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './empDescEn'
import langHr from './empDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import EmployeeService from '@/services/EmployeeService'
import dayjs from 'dayjs'
// import { parseNum } from '@/snippets/allSnippets'

export default {
  name: 'employeeprint',
  props: [],
  data () {
    return {
      lang: {},
      langC: {},
      msg: '',
      documentName: '',
      documentName2: '',
      document: {},
      havePath: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locDocument: {},
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      comp: {},
      mainQuery: {},
      ana: {},
      empl:[],
      emp: {},
    }
  },
  mounted () {


  },
  methods: {
    locDate(dateToFormat) {
      return dayjs([dateToFormat]).format('DD.MM.YYYY')
    },
    async print (emp) {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.BusinessYear = this.$store.state.businessYear
      }
      if (this.$store.state.language === 'EN') {
        this.lang = {...langEn}
        this.langC = {...commEn}
      }
      if (this.$store.state.language === 'HR') {
        this.lang = {...langHr}
        this.langC = {...commHr}
      }
      this.emp= {...emp}
      this.printDoc()
    },
    async fillEmp() {
        this.empl = []
        await EmployeeService.choose3(this.mainQuery)
          .then(res => {
            if (res.data && res.data.employees) {
              this.empl = res.data.employees
              console.log('all emp', this.empl)
            }
          })
          .catch(err => {
            console.log('Error ', err)
          })
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    addLineDash () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1,
                dash: { length: 1 },
              }
            ]
          }
      this.content.push(docPart)
    },
    printDoc () {
      this.comp = this.$store.state.companyActiv
      this.ana = this.$store.state.printObj ? this.$store.state.printObj : {}

      this.locCreatedAt =dayjs([this.emp.createdAt]).format('DD.MM.YYYY HH:MM')
      this.locUpdatedAt = dayjs([this.emp.updatedAt]).format('DD.MM.YYYY HH:MM') + (this.emp.LastUpdateBy ? ', ' + this.emp.LastUpdateBy : '') 
      this.initDocument()
      this.addHead(this.emp)
      this.addLine()
      this.addData(this.emp)
      this.addLineDash()
      this.addData2(this.emp)
      this.addLineDash()
      
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 40 ]
      this.content = []
    },
    skipNewPage() {
      const docPart01 = {
      columns: [
          {
            width: '40%',
            fontSize: 7,
            bold: true,
            alignment: 'left',
            text: '  ',
            pageBreak: 'after'
          },
        ]
      }
      this.content.push(docPart01)
    },
  
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },

    addHead(item) {

      console.log('item', item)
      const docPart = {
          columns: [
            {
              // % width
              width: '35%',
              text: [
                { text: 'Poslodavac: \n', fontSize: 12, bold: true },
                { text: this.comp.CompanyName + ' \n', fontSize: 12, bold: true },
                { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                { text: 'OIB:' + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' ') + ' \n', fontSize: 8 },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '45%',
              text: [
                { text: 'Radnik: \n', fontSize: 12, bold: true },
                { text: (item.First ? item.First + ' ' : '') + (item.Last ? item.Last  : '') + ' \n', fontSize: 12, bold: true },
                { text: (item && item.PlaceOfLive ? item.PlaceOfLive.trim() : ' ') +  ' \n', fontSize: 8 },
                { text: 'OIB:' + (item.VatID ? item.VatID : ' ') + ' \n', fontSize: 8 },
                { text: 'Račun:' + (item.Fin1bankAccountNr1 ? item.Fin1bankAccountNr1 : ' ') + (item.Fin1bankAccountName ? item.Fin1bankAccountName : ' ') + ' \n', fontSize: 8 },
                { text: '      ' + (item.Fin1bankAccountNr2 ? item.Fin1bankAccountNr2 + '  ' : ' ') + (item.Fin1bankAccountNr3 ? item.Fin1bankAccountNr3 : ' ') + ' \n', fontSize: 8 },
              ]
            },
            {
              // % width
              width: '20%',
              text: [     
                 { text: 'Evidencija o radniku - \n', fontSize: 12, bold: true },            
                { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },
    addData(item) {
      const docPart = {
          columns: [
            {
              // % width
              width: '30%',
              text: [
                { text: 'Osnovni podaci' + ' \n' , fontSize: 14,  },
                { text: 'Aktivno zaposlen:' + ' \n'  , fontSize: 8,  },
                { text: this.lang.First + ' \n'  , fontSize: 8,  },
                { text: this.lang.Last + ' \n'  , fontSize: 8,  },
                { text: 'email:' + ' \n'  , fontSize: 8,  },
                { text: this.lang.phone + ' \n'  , fontSize: 8,  },
                { text: this.lang.Gender +  ' \n'  , fontSize: 8,  },
                { text: this.lang.VatID + ' \n'  , fontSize: 8,  },
                { text: this.lang.PersonalID1 +  ' \n'  , fontSize: 8,  },
                { text: this.lang.PersonalID2 +  ' \n'  , fontSize: 8,  },
                { text: this.lang.DateOfBirht +  ' \n'  , fontSize: 8,  },
                { text: this.lang.PlaceOfBirht +  ' \n'  , fontSize: 8,  },
                { text: this.lang.PlaceOfBirthState  + ' \n'  , fontSize: 8,  },
                { text: this.lang.Foriner + ' \n'  , fontSize: 8,  },
                { text: this.lang.ForinerLicense + ' \n'  , fontSize: 8,  },
                { text: this.lang.PlaceOfLive  + ' \n'  , fontSize: 8,  },

              ]
            },
            {
              // % width
              width: '20%',
              text: [
                { text:  ' \n' , fontSize: 14,  },
                { text: (item.Activ ? 'DA' : 'NE') + ' \n'  , fontSize: 8,  },
                { text: (item.First ? item.First : '') + ' \n'  , fontSize: 8,  },
                { text: (item.Last ? item.Last : '') + ' \n'  , fontSize: 8,  },
                { text: (item.email ? item.email : '') + ' \n'  , fontSize: 8,  },
                { text: (item.phone ? item.phone : '') + ' \n'  , fontSize: 8,  },
                { text: (item.Gender ? item.Gender : '') + ' \n'  , fontSize: 8,  },
                { text: (item.VatID ? item.VatID : '') + ' \n'  , fontSize: 8,  },
                { text: (item.PersonalID1 ? item.PersonalID1 : '') + ' \n'  , fontSize: 8,  },
                { text: (item.PersonalID2 ? item.PersonalID2 : '') + ' \n'  , fontSize: 8,  },
                { text: (item.DateOfBirht ? this.locDate(item.DateOfBirht) : '') + ' \n'  , fontSize: 8,  },
                { text: (item.PlaceOfBirht ? item.PlaceOfBirht : '') + ' \n'  , fontSize: 8,  },
                { text: (item.PlaceOfBirthState ? item.PlaceOfBirthState : '') + ' \n'  , fontSize: 8,  },
                { text: (item.Foriner ? item.Foriner : '') + ' \n'  , fontSize: 8,  },
                { text: (item.ForinerLicense ? item.ForinerLicense : '') + ' \n'  , fontSize: 8,  },
                { text: (item.PlaceOfLive ? item.PlaceOfLive : '') + ' \n'  , fontSize: 8,  },

              ]
            },

          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },

    addData2(item) {
      const docPart = {
          columns: [
            {
              // % width
              width: '30%',
              text: [
                { text: this.lang.WorkEnvironment + ' \n' , fontSize: 14,  },
                { text: this.lang.Occupation + ' \n'  , fontSize: 8,  },
                { text: this.lang.Degree + ' \n'  , fontSize: 8,  },
                { text: this.lang.SpecialKnowledge + ' \n'  , fontSize: 8,  },
                { text: this.lang.StartInCompany + ' \n'  , fontSize: 8,  },
                { text: this.lang.WorkForOtherCompany + ' \n'  , fontSize: 8,  },
                { text: this.lang.WorkForOtherCompanyAbroad +  ' \n'  , fontSize: 8,  },
                { text: this.lang.WorkForOtherConnectedCompany + ' \n'  , fontSize: 8,  },
                { text: this.lang.WorkHardConditions +  ' \n'  , fontSize: 8,  },
                { text: this.lang.WorkPlace +  ' \n'  , fontSize: 8,  },
                { text: this.lang.WeeklyWorkHours +  ' \n'  , fontSize: 8,  },
                { text: this.lang.NotWorkingTime +  ' \n'  , fontSize: 8,  },
                { text: this.lang.EndDateInCompany +  ' \n'  , fontSize: 8,  },
                { text: this.lang.EndReason  + ' \n'  , fontSize: 8,  },
                { text: this.lang.OtherData + ' \n'  , fontSize: 8,  },
                { text: this.langC.CreatedAt + ' \n'  , fontSize: 8,  },
                { text: this.langC.UpdatedAt + ' \n'  , fontSize: 8,  },
              ]
            },
            {
              // % width
              width: '17%',
              text: [
                { text:  ' \n' , fontSize: 14,  },
                { text: (item.Occupation) + ' \n'  , fontSize: 8,  },
                { text: (item.Degree ? item.Degree : '') + (item.WorkInCityName ? ' '+ item.WorkInCityName : '') + ' \n'  , fontSize: 8,  },
                { text: (item.SpecialKnowledge ? item.SpecialKnowledge : '') + ' \n'  , fontSize: 8,  },
                { text: (item.StartInCompany ? this.locDate(item.StartInCompany) : '') + ' \n'  , fontSize: 8,  },
                { text: (item.WorkForOtherCompany ? item.WorkForOtherCompany : '') + ' \n'  , fontSize: 8,  },
                { text: (item.WorkForOtherCompanyAbroad ? item.WorkForOtherCompanyAbroad : '') + ' \n'  , fontSize: 8,  },
                { text: (item.WorkForOtherConnectedCompany ? item.WorkForOtherConnectedCompany : '') + ' \n'  , fontSize: 8,  },
                { text: (item.WorkHardConditions ? item.WorkHardConditions : '') + ' \n'  , fontSize: 8,  },
                { text: (item.WorkPlace ? item.WorkPlace : '') + ' \n'  , fontSize: 8,  },
                { text: (item.WeeklyWorkHours ? item.WeeklyWorkHours : '') + ' \n'  , fontSize: 8,  },
                { text: (item.NotWorkingTime ? item.NotWorkingTime : '') + ' \n'  , fontSize: 8,  },
                { text: (item.EndDateInCompany ? item.EndDateInCompany : '') + ' \n'  , fontSize: 8,  },
                { text: (item.EndReason ? item.EndReason : '') + ' \n'  , fontSize: 8,  },
                { text: (item.OtherData ? item.OtherData : '') + ' \n'  , fontSize: 8,  },
                { text: (this.locUpdatedAt ? this.locCreatedAt: '') + ' \n'  , fontSize: 8,  },
                { text: (this.locUpdatedAt ? this.locUpdatedAt: '') + ' \n'  , fontSize: 8,  },
              ]
            },

            {
              // % width
              width: '28%',
              text: [
                { text:  ' \n' , fontSize: 14,  },
                { text: this.lang.WorkBefore + ' \n'  , fontSize: 8,  },
                { text: this.lang.WorkInCityCodeID + ' \n'  , fontSize: 8,  },
                { text: this.lang.Pregnancy + ' \n'  , fontSize: 8,  },
                { text: this.lang.Maternity + ' \n'  , fontSize: 8,  },
                { text: this.lang.Breastfeeding + ' \n'  , fontSize: 8,  },
                { text: this.lang.OnlyParent +  ' \n'  , fontSize: 8,  },
                { text: this.lang.AdoptionStatus + ' \n'  , fontSize: 8,  },
                { text: this.lang.ProfessionalIllnes +  ' \n'  , fontSize: 8,  },
                { text: this.lang.HurtOnWork +  ' \n'  , fontSize: 8,  },
                { text: this.lang.ProfessionalCantWork +  ' \n'  , fontSize: 8,  },
                { text: this.lang.ProfessionalLowerAbbilities +  ' \n'  , fontSize: 8,  },
                { text: this.lang.ProfessionalLoseWrokAbbility  + ' \n'  , fontSize: 8,  },
                { text: this.lang.DangerOfLosingProfAbbilityInjury + ' \n'  , fontSize: 8,  },
                { text: this.lang.DangerOfHaveDisability + ' \n'  , fontSize: 8,  },
                { text: this.lang.Disability  + ' \n'  , fontSize: 8,  },
                { text: this.lang.DisabilityPension  + ' \n'  , fontSize: 8,  },
                { text: this.lang.DisabilityWorkTIme  + ' \n'  , fontSize: 8,  },
                { text: this.lang.WorkForOtherEmployee  + ' \n'  , fontSize: 8,  },
                { text: this.lang.PersonalData2  + ' \n'  , fontSize: 8,  },

              ]
            },
            {
              // % width
              width: '20%',
              text: [
                { text:  ' \n' , fontSize: 14,  },
                { text: this.lang.WorkYearsBefore + parseInt(item.WorkYearsBefore) + '-' + this.lang.WorkMonthsBefore + parseInt(item.WorkMonthsBefore) + '-' + this.lang.WorkDaysBefore + '-' + parseInt(item.WorkDaysBefore) + ' \n'  , fontSize: 8,  },
                { text: (item.WorkInCityCode ? item.WorkInCityCode : '') + (item.WorkInCityName ? ' '+ item.WorkInCityName : '') + ' \n'  , fontSize: 8,  },
                { text: (item.Pregnancy ? item.Pregnancy : '') + ' \n'  , fontSize: 8,  },
                { text: (item.Maternity ? item.Maternity : '') + ' \n'  , fontSize: 8,  },
                { text: (item.Breastfeeding ? item.Breastfeeding : '') + ' \n'  , fontSize: 8,  },
                { text: (item.OnlyParent ? item.OnlyParent : '') + ' \n'  , fontSize: 8,  },
                { text: (item.AdoptionStatus ? item.AdoptionStatus : '') + ' \n'  , fontSize: 8,  },
                { text: (item.ProfessionalIllnes ? item.ProfessionalIllnes : '') + ' \n'  , fontSize: 8,  },
                { text: (item.HurtOnWork ? item.HurtOnWork : '') + ' \n'  , fontSize: 8,  },
                { text: (item.ProfessionalCantWork ? item.ProfessionalCantWork : '') + ' \n'  , fontSize: 8,  },
                { text: (item.ProfessionalLowerAbbilities ? item.ProfessionalLowerAbbilities : '') + ' \n'  , fontSize: 8,  },
                { text: (item.ProfessionalLoseWrokAbbility ? item.ProfessionalLoseWrokAbbility : '') + ' \n'  , fontSize: 8,  },
                { text: (item.DangerOfLosingProfAbbilityInjury ? item.DangerOfLosingProfAbbilityInjury : '') + ' \n'  , fontSize: 8,  },
                { text: (item.DangerOfHaveDisability ? item.DangerOfHaveDisability : '') + ' \n'  , fontSize: 8,  },
                { text: (item.Disability ? item.Disability : '') + ' \n'  , fontSize: 8,  },
                { text: (item.DisabilityPension ? item.DisabilityPension : '') + ' \n'  , fontSize: 8,  },
                { text: (item.DisabilityWorkTIme ? item.DisabilityWorkTIme : '') + ' \n'  , fontSize: 8,  },
                { text: (item.WorkForOtherEmployee ? item.WorkForOtherEmployee : '') + ' \n'  , fontSize: 8,  },
                { text: (item.PersonalHeight ? item.PersonalHeight : '') + (item.PersonalSizeUp ? ', ' +item.PersonalSizeUp : '') + (item.PersonalSizeDown ? ', '+ item.PersonalSizeDown : '')  + (item.PersonalShoes ? ', '+ item.PersonalShoes : '')  +' \n'  , fontSize: 8,  },

              ]
            },

          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },

    addSignature () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                 // { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________  \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },

    addEmpty () {
      const docPart = {
        
          }
      this.content.push(docPart)
    },

  },
  computed: {
  },
  watch: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
