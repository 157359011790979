<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs12 sm12 m12 lg12 ex12>

        <div id="printArea">

                <v-layout row style="border-bottom:solid 1px;">
                    <v-flex xs5>
                        <h4><b><i>{{comp.CompanyNameShort}}</i></b></h4>
                        {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}   <br />                
                        {{comp.CompanyPostcode}} {{comp.CompanyCity}}<br />
                         OIB: {{comp.CompanyVATID}}
                    </v-flex>
                    <v-flex xs3>
                      <span v-if="this.$store.state.language == 'EN'">
                      <h2>Balance sheet {{currency}}</h2>
                      </span>
                      <span v-if="this.$store.state.language == 'HR'">
                      <h2>Bruto bilanca {{currency}}</h2>
                      </span>

                      {{(this.head.docFromDate ? localDate(this.head.docFromDate) : '')}} do {{this.head.docToDate ? localDate(this.head.docToDate) : ''}}
                      <br/>
                      Od konta:{{this.head.fromAccount}}, do konta:{{this.head.toAccount}}

                  </v-flex>
                    <v-flex xs 4>
                      <p class="text-md-left">
                          Datum izvještaja: {{PrintDate}}<br />
                          Telefon: {{comp.CompanyPhones}}<br />
                          email: {{comp.Companyemail}}<br />
                          <v-btn @click="print" class="mr-2">Printaj</v-btn>
                          <v-btn @click="downloadFile" class="mr-2">Excel</v-btn>
                          <v-btn @click="rdg" class="mr-2">RDG</v-btn>
                      </p>
                    </v-flex>
              </v-layout>
<!-- 
            <v-layout row style="border-bottom:solid 1px; border-top:solid 1px;" class="ma-1">

                    <v-flex xs1 class="text-left" >
                      Rb.
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Konto
                    </v-flex>
                    <v-flex xs3 class="text-left" >
                      Naziv
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      P.S.Duguje
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      P.S.Potražuje
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Promet Dug.
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Promet Pot.
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Uk.Duguje
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Uk.Potražuje
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Saldo
                    </v-flex>
              </v-layout> -->

                <div v-for="item in pItems" :key="item.id">

                      <v-row v-if="item.addLine && item.sirina && item.spaces === 0" style="border-top:solid 1px;" class="ma-1">
                        <v-flex xs1 class="text-left" >
                          
                        </v-flex>
                        <v-flex xs1 class="text-left" >
                         
                        </v-flex>
                        <v-flex xs3 class="text-left" >
                          {{currency}}
                        </v-flex>
                        <v-flex xs1 class="text-right" >
                          P.S.Duguje
                        </v-flex>
                        <v-flex xs1 class="text-right" >
                          P.S.Potražuje
                        </v-flex>
                        <v-flex xs1 class="text-right" >
                          Promet Dug.
                        </v-flex>
                        <v-flex xs1 class="text-right" >
                          Promet Pot.
                        </v-flex>
                        <v-flex xs1 class="text-right" >
                          Uk.Duguje
                        </v-flex>
                        <v-flex xs1 class="text-right" >
                          Uk.Potražuje
                        </v-flex>
                        <v-flex xs1 class="text-right" >
                          Saldo
                        </v-flex>

                      </v-row>
                      <v-row v-if="item.addLine && item.sirina" style="border-top:solid 1px;" class="ma-1">
                          <v-col cols="12" sm="1" :class="item.class" :style="item.style">
                            <span v-if="item.spaces === 2">&nbsp;&nbsp;</span>
                            <span v-if="item.spaces === 4">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                              {{ item.oneCond }}
                          </v-col>

                          <v-col cols="12" sm="1" :class="item.class" :style="item.style">
                              {{item.itLine}}
                          </v-col>

                          <v-col cols="12" sm="10" :class="item.class" :style="item.style">
                              {{ item.account1Name }} 
                          </v-col>
                      </v-row>

                      <v-row v-if="item.addLine && !item.sirina" style="border-top:solid 1px;" class="ma-1">
                          <v-col cols="12" sm="1" :class="item.class" :style="item.style">
                            <span v-if="item.spaces === 2">&nbsp;&nbsp;</span>
                            <span v-if="item.spaces === 4">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                              {{ item.oneCond }}
                          </v-col>

                          <v-col cols="12" sm="4" :class="item.class" :style="item.style">
                              {{item.itLine}}
                          </v-col>

                            <v-col cols="12" sm="1" :class="item.class" >
                              <span  v-if="item.onePsTotal1">
                                <div class="text-right">
                                  {{ formatPrice(parseFloat(item.onePsTotal1)) }}
                                </div>
                              </span>
                            </v-col>
                            <v-col cols="12" sm="1" :class="item.class" >
                              <span v-if="item.onePsTotal2">
                                <div class="text-right">
                                  {{ formatPrice(parseFloat(item.onePsTotal2)) }}
                                </div>
                              </span>
                            </v-col>
                            <v-col cols="12" sm="1" :class="item.class" >
                              <span  v-if="item.oneYearTotal1">
                                <div class="text-right">
                                  {{ formatPrice(parseFloat(item.oneYearTotal1)) }}
                                </div>
                              </span>
                            </v-col>
                            <v-col cols="12" sm="1" :class="item.class" >
                              <span v-if="item.oneYearTotal2">
                                <div class="text-right">
                                  {{ formatPrice(parseFloat(item.oneYearTotal2)) }}
                                </div>
                              </span>
                            </v-col>
                            <v-col cols="12" sm="1" :class="item.class" >
                              <span  v-if="item.oneTotal1">
                                <div class="text-right">
                                  {{ formatPrice(parseFloat(item.oneTotal1)) }}
                                </div>
                              </span>
                            </v-col>
                            <v-col cols="12" sm="1" :class="item.class" >
                              <span v-if="item.oneTotal2">
                                <div class="text-right">
                                  {{ formatPrice(parseFloat(item.oneTotal2)) }}
                                </div>
                              </span>
                            </v-col>
                            <v-col cols="12" sm="1" :class="item.class" >
                              <span  v-if="item.CreditDebit">
                                <div class="text-right">
                                  {{ formatPrice(parseFloat(item.CreditDebit)) }}
                                </div>
                              </span>
                           </v-col>
                      </v-row>
                      
                      <v-row v-if="item.itLine === 'KLASA UKUPNO:'" class="ma-1">
                        <v-flex xs12>
                          <br />
                        </v-flex>
                      </v-row>

                      <v-row v-if="item.itLine === 'Sin.ukupno:'" class="ma-1">
                        <v-flex xs12>
                          <br />
                        </v-flex>
                      </v-row>
                      

                      <v-row v-if="item.addLine === false" class="ma-1">
                        <v-flex xs1 class="text-right">
                            
                        </v-flex>
                        <v-flex xs1 class="text-left" >
                            {{ item.account4 }} 
                        </v-flex>
                        <v-flex xs3 class="text-left" style="font-size:12px">
                          {{ item.account4Name }}
                        </v-flex>
                        <v-flex xs1 class="text-right" >
                          {{ formatPrice(parseFloat(item.psDebit)) }}
                        </v-flex>
                        <v-flex xs1 class="text-right" >
                          {{ formatPrice(parseFloat(item.psCredit)) }}
                        </v-flex>
                        <v-flex xs1 class="text-right" >
                          {{ formatPrice(parseFloat(item.yearDebit)) }}
                        </v-flex>
                        <v-flex xs1 class="text-right" >
                          {{ formatPrice(parseFloat(item.yearCredit)) }}
                        </v-flex>
                        <v-flex xs1 class="text-right" >
                          {{ formatPrice(parseFloat(item.totDebit)) }}
                        </v-flex>
                        <v-flex xs1 class="text-right" >
                            {{ formatPrice(parseFloat(item.totCredit)) }}
                        </v-flex>
                        <v-flex xs1 class="text-right" >
                            {{ formatPrice(parseFloat(item.acc4Total)) }}
                        </v-flex>
                      </v-row>
 
            </div>
            <v-row v-if="lastOne.account3Name" style="border-top:solid 1px;" class="ma-1">
              <v-flex xs1 class="text-left" style="font-size:12px">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ lastOne.threeCond }}
              </v-flex>
              <v-flex xs1 class="text-left" style="font-size:13px, italic">
                  Sin3.Ukupno:
              </v-flex>
              <v-flex xs3 class="text-left" style="font-size:13px, italic">
                  {{ lastOne.account3Name }}
              </v-flex>
              <v-flex xs1 class="text-right" >
                  <span v-if="lastOne.threePsTotal1">
                    {{ formatPrice(parseFloat(lastOne.threePsTotal1)) }}
                  </span>
              </v-flex>
              <v-flex xs1 class="text-right" >
                  <span v-if="lastOne.threePsTotal2">
                  {{ formatPrice(parseFloat(lastOne.threePsTotal2)) }}
                  </span>
              </v-flex>
              <v-flex xs1 class="text-right" >
                  <span v-if="lastOne.threeYearTotal1">
                    {{ formatPrice(parseFloat(lastOne.threeYearTotal1)) }}
                  </span>
              </v-flex>
              <v-flex xs1 class="text-right" >
                  <span v-if="lastOne.threeYearTotal2">
                  {{ formatPrice(parseFloat(lastOne.threeYearTotal2)) }}
                  </span>
              </v-flex>
              <v-flex xs1 class="text-right" >
                  <span v-if="lastOne.threeTotal1">
                    {{ formatPrice(parseFloat(lastOne.threeTotal1)) }}
                  </span>
              </v-flex>
              <v-flex xs1 class="text-right" >
                  <span v-if="lastOne.threeTotal2">
                  {{ formatPrice(parseFloat(lastOne.threeTotal2)) }}
                  </span>
              </v-flex>
              <v-flex xs1 class="text-right" >
                  <span v-if="lastOne.threeSum">
                  {{ formatPrice(parseFloat(lastOne.threeSum)) }}
                  </span>
              </v-flex>
            </v-row>

            <v-row v-if="lastOne.account2Name" style="border-top:solid 1px;" class="ma-1">
              <v-flex xs1 class="green lighten-5 text-left" style="font-weight:bold; font-size:16px">
                &nbsp;&nbsp;{{ lastOne.twoCond }}
              </v-flex>
              <v-flex xs1 class="green lighten-5 text-left" style="font-weight:bold; font-size:16px">
                Sin.ukupno:
              </v-flex>
              <v-flex xs3 class="green lighten-5 text-left" style="font-weight:bold; font-size:16px">
                  {{ lastOne.account2Name }}
              </v-flex>
              <v-flex xs1 class="green lighten-5 text-right" >
                  <span v-if="lastOne.twoPsTotal1">
                    {{ formatPrice(parseFloat(lastOne.twoPsTotal1)) }}
                  </span>
              </v-flex>
              <v-flex xs1 class="green lighten-5 text-right" >
                  <span v-if="lastOne.twoPsTotal2">
                  {{ formatPrice(parseFloat(lastOne.twoPsTotal2)) }}
                  </span>
              </v-flex>
              <v-flex xs1 class="green lighten-5 text-right" >
                  <span v-if="lastOne.twoYearTotal1">
                    {{ formatPrice(parseFloat(lastOne.twoYearTotal1)) }}
                  </span>
              </v-flex>
              <v-flex xs1 class="green lighten-5 text-right" >
                  <span v-if="lastOne.twoYearTotal2">
                  {{ formatPrice(parseFloat(lastOne.twoYearTotal2)) }}
                  </span>
              </v-flex>
              <v-flex xs1 class="green lighten-5 text-right" >
                  <span v-if="lastOne.twoTotal1">
                    {{ formatPrice(parseFloat(lastOne.twoTotal1)) }}
                  </span>
              </v-flex>
              <v-flex xs1 class="green lighten-5 text-right" >
                  <span v-if="lastOne.twoTotal2">
                  {{ formatPrice(parseFloat(lastOne.twoTotal2)) }}
                  </span>
              </v-flex>
              <v-flex xs1 class="green lighten-5 text-right" >
                  <span v-if="lastOne.twoSum">
                  {{ formatPrice(parseFloat(lastOne.twoSum)) }}
                  </span>
              </v-flex>
            </v-row>


            <v-row v-if="lastOne.account1Name" style="border-top:solid 1px;" class="ma-1">
              <v-flex xs1 class="purple lighten-5 text-left" style="font-weight:bold; font-size:20px">
                 {{ lastOne.oneCond }}
              </v-flex>
              <v-flex xs1>
                <div class="purple lighten-5 text-left" style="font-weight:bold; font-size:20px">
                  KLASA UKUPNO:
                </div>
              </v-flex>
              <v-flex xs3 class="purple lighten-5 text-left" style="font-weight:bold; font-size:20px">
                  {{ lastOne.account1Name }}
              </v-flex>
              <v-flex xs1 class="purple lighten-5 text-right" >
                  <span v-if="lastOne.onePsTotal1">
                    {{ formatPrice(parseFloat(lastOne.onePsTotal1)) }}
                  </span>
              </v-flex>
              <v-flex xs1 class="purple lighten-5 text-right" >
                  <span v-if="lastOne.onePsTotal2">
                  {{ formatPrice(parseFloat(lastOne.onePsTotal2)) }}
                  </span>
              </v-flex>
              <v-flex xs1 class="purple lighten-5 text-right" >
                  <span v-if="lastOne.oneYearTotal1">
                    {{ formatPrice(parseFloat(lastOne.oneYearTotal1)) }}
                  </span>
              </v-flex>
              <v-flex xs1 class="purple lighten-5 text-right" >
                  <span v-if="lastOne.oneYearTotal2">
                  {{ formatPrice(parseFloat(lastOne.oneYearTotal2)) }}
                  </span>
              </v-flex>
              <v-flex xs1 class="purple lighten-5 text-right" >
                  <span v-if="lastOne.oneTotal1">
                    {{ formatPrice(parseFloat(lastOne.oneTotal1)) }}
                  </span>
              </v-flex>
              <v-flex xs1 class="purple lighten-5 text-right" >
                  <span v-if="lastOne.oneTotal2">
                  {{ formatPrice(parseFloat(lastOne.oneTotal2)) }}
                  </span>
              </v-flex>
              <v-flex xs1 class="purple lighten-5 text-right" >
                  <span v-if="lastOne.oneSum">
                  {{ formatPrice(parseFloat(lastOne.oneSum)) }}
                  </span>
              </v-flex>
            </v-row>
            <v-layout row style="border-top:solid 1px;">
              <v-flex xs3 class="text-xs-right">
              </v-flex>
              <v-flex xs2 class="text-xs-right">
                {{currency}}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(psTotal1) }}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(psTotal2) }}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(yearTotal1) }}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(yearTotal2) }}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(Total1) }}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(Total2) }}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(Total5) }}
              </v-flex>

            </v-layout>
        </div>
      </v-flex>
      <MainLedgerPrintBalance ref="prnt" :balance="balance" />
      <MainLedgerPrintProfitLoss ref="prnt2" />
    </v-layout>
  </v-container>
</template>

<script>
import Account1Service from '@/services/Account1Service'
import Account2Service from '@/services/Account2Service'
import Account3Service from '@/services/Account3Service'
import MainLedgerPrintBalance from './MainLedgerPrintBalance'
import MainLedgerPrintProfitLoss from './MainLedgerPrintProfitLoss'
import MainLedgerDetailService from '@/services/MainLedgerDetailService'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import { round, parseNum} from '@/snippets/allSnippets'
import xlsx from 'json-as-xlsx'
import dayjs from 'dayjs'

export default {
  name: 'mainledgercard1',
  props: ['items', 'psItems', 'account4Loc', 'head', 'showEUR'],
  data () {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      comp: {},
      error: null,
      localError: false,
      newUser: {},
      size: 'sm',
      havePath: '',
      Total1: 0,
      Total2: 0,
      psTotal1: 0,
      psTotal2: 0,
      yearTotal1: 0,
      yearTotal2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      oneTotal1: 0,
      oneTotal2: 0,
      onePsTotal1: 0,
      onePsTotal2: 0,
      oneYearTotal1: 0,
      oneYearTotal2: 0,
      oneTotal3: 0,
      oneTotal4: 0,
      oneTotal5: 0,
      twoTotal1: 0,
      twoTotal2: 0,
      twoPsTotal1: 0,
      twoPsTotal2: 0,
      twoYearTotal1: 0,
      twoYearTotal2: 0,
      twoTotal3: 0,
      twoTotal4: 0,
      twoTotal5: 0,
      threeTotal1: 0,
      threeTotal2: 0,
      threePsTotal1: 0,
      threePsTotal2: 0,
      threeYearTotal1: 0,
      threeYearTotal2: 0,
      threeTotal3: 0,
      threeTotal4: 0,
      threeTotal5: 0,
      oneCond: null,
      twoCond: null,
      threeCond: null,
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      pItems: [],
      account1s: [],
      account2s: [],
      account3s: [],
      lastOne: {},
      asLast: {},
      freeLine: ' ',
      balance: {},
      exRate: 1,
      currency: 'EUR',
      allId: 0,
      nI: {},
      nA: {},
      nB: {},
      nC: {},
      fRDG:{},
      lItems: [],
      lPsItems: [],
      pOldItems: [],
      pYitems: [],
      useYear: 1
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    this.account1s = (await Account1Service.show()).data.account1s
    this.account2s = (await Account2Service.show()).data.account2s
    this.account3s = (await Account3Service.show()).data.account3s

    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    this.exRate = this.$store.state.defExRate ? this.$store.state.defExRate : 1

    if (!this.showEUR) {
      this.exRate = 1
    }
    this.currency = this.$store.state.businessYear >= 2023 ? 'EUR' : 'Kn'
    this.lItems = [...this.items]
    this.balanceSh()
  },
  computed: {

  },
  methods: {
    balanceSh() {
      this.psTotal1 = 0
      this.psTotal2 = 0
      this.yearTotal1 = 0
      this.yearTotal2 = 0
      this.Total1 = 0
      this.Total2 = 0
      this.Total3 = 0
      this.Total4 = 0
      let itLine = 0
      let id = 0
      let locId = 0

      if (this.lItems && this.account4Loc) {
        this.lItems.map(item => {
          itLine += 1
          id += 1

          const haveStart = this.psItems.find(psacc => psacc.account4 === item.account4)

          if (this.oneCond === null) {
            //header of class
            this.oneCond = item.account1
            const objectHeader = {
                id,
                itLine: 'KLASA',
                spaces: 0,
                oneCond: this.oneCond,
                addLine: true,
                onePsTotal1: null,
                onePsTotal2: null,
                oneYearTotal1: null,
                oneYearTotal2: null,
                oneTotal1: null,
                oneTotal2: null,
                account1Name: this.findA1(item.account1),
                CreditDebit: null,
                class: 'purple lighten-5',
                style: "font-weight:bold; font-size:20px",
                sirina: true,
                header: false
              }
              if (this.useYear === 1) {
                this.pItems.push(objectHeader)
              }
              if (this.useYear !== 1) {
                this.pOldItems.push(objectHeader)
              }
            id += 1
          }
          if (this.twoCond === null) {
            //header of syntetic
            this.twoCond = item.account2
            const objectHeader = {
                id,
                itLine: 'Sintetika',
                spaces: 2,
                oneCond: this.twoCond,
                addLine: true,
                onePsTotal1: null,
                onePsTotal2: null,
                oneYearTotal1: null,
                oneYearTotal2: null,
                oneTotal1: null,
                oneTotal2: null,
                account1Name: this.findA2(item.account2),
                CreditDebit: null,
                class: 'green lighten-5',
                style: "font-weight:bold; font-size:16px",
                sirina: true,
                header: false
              }
              if (this.useYear === 1) {
                this.pItems.push(objectHeader)
              }
              if (this.useYear !== 1) {
                this.pOldItems.push(objectHeader)
              }
            id += 1
          }
          if (this.threeCond === null) {
            //header of syntetic 3
            this.threeCond = item.account3
            const objectHeader = {
                id,
                itLine: 'Sin.3',
                spaces: 4,
                oneCond: this.threeCond,
                addLine: true,
                onePsTotal1: null,
                onePsTotal2: null,
                oneYearTotal1: null,
                oneYearTotal2: null,
                oneTotal1: null,
                oneTotal2: null,
                account1Name: this.findA3(item.account3),
                CreditDebit: null,
                class: '',
                style: "font-style: italic;",
                sirina: true,
                header: false
              }
              if (this.useYear === 1) {
                this.pItems.push(objectHeader)
              }
              if (this.useYear !== 1) {
                this.pOldItems.push(objectHeader)
              }
            id += 1
          }
          // NEW A3 ------------------------------------
          if (item.account3 !== this.threeCond) {
              // total of class
              const object2 = {
                id,
                itLine: 'Sin3.ukupno:',
                spaces: 4,
                oneCond: this.threeCond,
                addLine: true,
                onePsTotal1: this.threePsTotal1,
                onePsTotal2: this.threePsTotal2,
                oneYearTotal1: this.threeYearTotal1,
                oneYearTotal2: this.threeYearTotal2,
                oneTotal1: this.threeTotal1,
                oneTotal2: this.threeTotal2,
                account1Name: this.findA3(this.threeCond),
                CreditDebit: this.threeTotal1 - this.threeTotal2,
                class: '',
                style: "font-style: italic;",
                sirina: false,
                header: false
                
              }
              const object3 = {...item, ...object2}
              if (this.useYear === 1) {
                this.pItems.push(object3)
              }
              if (this.useYear !== 1) {
                this.pOldItems.push(object3)
              }
              itLine += 1
              id += 1
              this.threePsTotal1 = 0
              this.threePsTotal2 = 0
              this.threeYearTotal1 = 0
              this.threeYearTotal2 = 0
              this.threeTotal1 = 0
              this.threeTotal2 = 0
              locId = 0
  
          }
          // NEW A2 ------------------------------------
          if (item.account2 !== this.twoCond) {
              // total of class
              const object2 = {
                id,
                itLine: 'Sin.ukupno:',
                spaces: 2,
                oneCond: this.twoCond,
                addLine: true,
                onePsTotal1: this.twoPsTotal1,
                onePsTotal2: this.twoPsTotal2,
                oneYearTotal1: this.twoYearTotal1,
                oneYearTotal2: this.twoYearTotal2,
                oneTotal1: this.twoTotal1,
                oneTotal2: this.twoTotal2,
                account1Name: this.findA2(this.twoCond),
                CreditDebit: this.twoTotal1 - this.twoTotal2,
                class: 'green lighten-5',
                style: "font-weight:bold; font-size:16px",
                sirina: false,
                header: false
              }
              const object3 = {...item, ...object2}
              if (this.useYear === 1) {
                this.pItems.push(object3)
              }
              if (this.useYear !== 1) {
                this.pOldItems.push(object3)
              }
              itLine += 1
              id += 1
              this.twoPsTotal1 = 0
              this.twoPsTotal2 = 0
              this.twoYearTotal1 = 0
              this.twoYearTotal2 = 0
              this.twoTotal1 = 0
              this.twoTotal2 = 0
              locId = 0
          }

          // NEW A1  ------------------------------------
          if (item.account1 !== this.oneCond) {
              // total of class
              const object2 = {
                id,
                itLine: 'KLASA UKUPNO:',
                spaces: 0,
                oneCond: this.oneCond,
                addLine: true,
                onePsTotal1: this.onePsTotal1,
                onePsTotal2: this.onePsTotal2,
                oneYearTotal1: this.oneYearTotal1,
                oneYearTotal2: this.oneYearTotal2,
                oneTotal1: this.oneTotal1,
                oneTotal2: this.oneTotal2,
                account1Name: this.findA1(this.oneCond),
                CreditDebit: this.oneTotal1 - this.oneTotal2,
                class: 'purple lighten-5',
                style: "font-weight:bold; font-size:20px",
                sirina: false,
                header: true
              }
              const object3 = {...item, ...object2}
              if (this.useYear === 1) {
                this.pItems.push(object3)
              }
              if (this.useYear !== 1) {
                this.pOldItems.push(object3)
              }
              itLine += 1
              id += 1
              this.onePsTotal1 = 0
              this.onePsTotal2 = 0
              this.oneYearTotal1 = 0
              this.oneYearTotal2 = 0
              this.oneTotal1 = 0
              this.oneTotal2 = 0
              locId = 0

          }
          
          if (item.account1 !== this.oneCond) {
            //header of class
            this.oneCond = item.account1
            const objectHeader = {
                id,
                itLine: 'Klasa',
                spaces: 0,
                oneCond: this.oneCond,
                addLine: true,
                onePsTotal1: null,
                onePsTotal2: null,
                oneYearTotal1: null,
                oneYearTotal2: null,
                oneTotal1: null,
                oneTotal2: null,
                account1Name: this.findA1(item.account1),
                CreditDebit: null,
                class: 'purple lighten-5',
                style: "font-weight:bold; font-size:20px",
                sirina: true,
                header: false
              }
              if (this.useYear === 1) {
                this.pItems.push(objectHeader)
              }
              if (this.useYear !== 1) {
                this.pOldItems.push(objectHeader)
              }
            id += 1
          }
          if (item.account2 !== this.twoCond) {
            //header of syntetic
            this.twoCond = item.account2
            const objectHeader = {
                id,
                itLine: 'Sintetika',
                spaces: 2,
                oneCond: this.twoCond,
                addLine: true,
                onePsTotal1: null,
                onePsTotal2: null,
                oneYearTotal1: null,
                oneYearTotal2: null,
                oneTotal1: null,
                oneTotal2: null,
                account1Name: this.findA2(item.account2),
                CreditDebit: null,
                class: 'green lighten-5',
                style: "font-weight:bold; font-size:16px",
                sirina: true,
                header: false
              }
              if (this.useYear === 1) {
                this.pItems.push(objectHeader)
              }
              if (this.useYear !== 1) {
                this.pOldItems.push(objectHeader)
              }
            id += 1
          }
          if (item.account3 !== this.threeCond) {
            //header of syntetic 3
            this.threeCond = item.account3
            const objectHeader = {
                id,
                itLine: 'Sin.3',
                spaces: 4,
                oneCond: this.threeCond,
                addLine: true,
                onePsTotal1: null,
                onePsTotal2: null,
                oneYearTotal1: null,
                oneYearTotal2: null,
                oneTotal1: null,
                oneTotal2: null,
                account1Name: this.findA3(item.account3),
                CreditDebit: null,
                class: '',
                style: "font-style: italic;",
                sirina: true,
                header: false
              }
              if (this.useYear === 1) {
                this.pItems.push(objectHeader)
              }
              if (this.useYear !== 1) {
                this.pOldItems.push(objectHeader)
              }
            id += 1
          }

          const ps1 = round(((haveStart && haveStart.totalDebit ? parseFloat(haveStart.totalDebit) : 0) / this.exRate), 2)
          const ps2 = round(((haveStart && haveStart.totalCredit ? parseFloat(haveStart.totalCredit) : 0) / this.exRate), 2)
          const year1 = round((round((item.totalDebit) , 2) / this.exRate) - ps1, 2)
          const year2 = round((round((item.totalCredit) , 2) / this.exRate) - ps2, 2)
          const total1 = round((item.totalDebit), 2)/ this.exRate
          const total2 = round((item.totalCredit), 2)/ this.exRate


          this.psTotal1 += ps1
          this.psTotal2 += ps2
          this.yearTotal1 += year1
          this.yearTotal2 += year2
          this.Total1 += total1
          this.Total2 += total2
          // this.Total3 += parseFloat(item.DebitForegin)
          // this.Total4 += parseFloat(item.CreditForegin)
          
          // account1 totals
          this.onePsTotal1 += ps1
          this.onePsTotal2 += ps2
          this.oneYearTotal1 += year1
          this.oneYearTotal2 += year2
          this.oneTotal1 += total1
          this.oneTotal2 += total2
          // this.oneTotal3 += parseFloat(item.DebitForegin)
          // this.oneTotal4 += parseFloat(item.CreditForegin)

          // account2 totals
          this.twoPsTotal1 += ps1
          this.twoPsTotal2 += ps2
          this.twoYearTotal1 += year1
          this.twoYearTotal2 += year2
          this.twoTotal1 += total1
          this.twoTotal2 += total2
          // this.twoTotal3 += parseFloat(item.DebitForegin)
          // this.twoTotal4 += parseFloat(item.CreditForegin)

          // account3 totals
          this.threePsTotal1 += ps1
          this.threePsTotal2 += ps2
          this.threeYearTotal1 += year1
          this.threeYearTotal2 += year2
          this.threeTotal1 += total1
          this.threeTotal2 += total2
          // this.threeTotal3 += parseFloat(item.DebitForegin)
          // this.threeTotal4 += parseFloat(item.CreditForegin)
          locId += 1
          const object2 = {
            id,
            locId,
            itLine ,
            addLine: false,
            account4Name: this.findA4(item.account4),
            psDebit: ps1,
            psCredit: ps2,
            yearDebit: year1,
            yearCredit: year2,
            totDebit: total1,
            totCredit: total2,
            CreditDebit: total1 - total2,
            acc4Total: total1 - total2,
            sirina: false,
            header: false
          }
          const object3 = {...item, ...object2}
          if (this.useYear === 1) {
            this.pItems.push(object3)
          }
          if (this.useYear !== 1) {
            this.pOldItems.push(object3)
          }
          this.asLast = {
            oneCond: this.oneCond,
            twoCond: this.twoCond,
            threeCond: this.threeCond,
            onePsTotal1: this.onePsTotal1,
            onePsTotal2: this.onePsTotal2,
            oneYearTotal1: this.oneYearTotal1,
            oneYearTotal2: this.oneYearTotal2,
            oneTotal1: parseNum(this.onePsTotal1) + parseNum(this.oneYearTotal1),
            oneTotal2: parseNum(this.onePsTotal2) + parseNum(this.oneYearTotal2),

            twoPsTotal1: this.twoPsTotal1,
            twoPsTotal2: this.twoPsTotal2,
            twoYearTotal1: this.twoYearTotal1,
            twoYearTotal2: this.twoYearTotal2,
            twoTotal1: parseNum(this.twoPsTotal1) + parseNum(this.twoYearTotal1),
            twoTotal2: parseNum(this.twoPsTotal2) + parseNum(this.twoYearTotal2),

            threePsTotal1: this.threePsTotal1,
            threePsTotal2: this.threePsTotal2,
            threeYearTotal1: this.threeYearTotal1,
            threeYearTotal2: this.threeYearTotal2,
            threeTotal1: parseNum(this.threePsTotal1) + parseNum(this.threeYearTotal1),
            threeTotal2: parseNum(this.threePsTotal2) + parseNum(this.threeYearTotal2),
            sirina: false,
            header: false
          } 
        })
      }
      this.Total5 = this.Total1 - this.Total2

      const addLastOne = {
        account1Name: this.asLast.oneCond ? this.findA1(this.asLast.oneCond) : '',
        account2Name: this.asLast.twoCond ? this.findA2(this.asLast.twoCond) : '',
        account3Name: this.asLast.threeCond ? this.findA3(this.asLast.threeCond) : '',

        onePsSum: this.asLast.onePsTotal1 - this.asLast.onePsTotal2 ,
        oneYearSum: this.asLast.oneYearTotal1 - this.asLast.oneYearTotal2,
        oneSum:  this.asLast.oneTotal1 - this.asLast.oneTotal2,

        twoPsSum: this.asLast.twoPsTotal1 - this.asLast.twoPsTotal2,
        twoYearSum: this.asLast.twoYearTotal1 - this.asLast.twoYearTotal2,
        twoSum:  this.asLast.twoTotal1 - this.asLast.twoTotal2 ,

        threePsSum: this.asLast.threePsTotal1 - this.asLast.threePsTotal2,
        threeYearSum: this.asLast.threeYearTotal1 - this.asLast.threeYearTotal2,
        threeSum:  this.asLast.threeTotal1 - this.asLast.threeTotal2
      }
      this.lastOne ={ ...this.asLast, ...addLastOne}
      this.balance = {...this.head}
      if (this.useYear === 1) {
        this.balance.items = this.pItems
      }
      if (this.useYear !== 1) {
        this.balance.items = this.pOldItems
      }
      this.balance = {...this.balance, ...this.lastOne}
      this.balance.psTotal1 = this.psTotal1
      this.balance.psTotal2 = this.psTotal2
      this.balance.yearTotal1 = this.yearTotal1
      this.balance.yearTotal2 = this.yearTotal2
      this.balance.Total1 = this.Total1
      this.balance.Total2 = this.Total2
      this.balance.Total5 = this.Total5
      this.balance.showEUR = this.showEUR
    },
    print(){
      this.$refs.prnt.print()
    },
    print2(){
      this.$refs.prnt2.print(this.fRDG)
    },
    findA1(condition) {
      const account1Name = this.account1s.filter(account => {
        return account.account1 === condition
      })
      return (account1Name && account1Name.length > 0 && account1Name[0].account1Description ? account1Name[0].account1Description : '')
    },
    findA2(condition) {
      const account2Name = this.account2s.filter(account => {
        return account.account2 === condition
      })
      return (account2Name && account2Name.length > 0  && account2Name[0].account2Description ? account2Name[0].account2Description : '')
    },
    findA3(condition) {
      const account3Name = this.account3s.filter(account => {
        return account.account3 === condition
      })
      return (account3Name && account3Name.length > 0 && account3Name[0].account3Description ? account3Name[0].account3Description : '')
    },
    findA4(condition) {
      const account4Name = this.account4Loc.filter(account => {
        return account.account4 === condition
      })
      return (account4Name && account4Name.length > 0 && account4Name[0].account4Description ? account4Name[0].account4Description : '')
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    localParse(parseNumber) {
      return  parseNum(parseNumber)
    },


    async rdg() {

      const emp = []
      this.pOldItems = [...emp]
      const mlAnaPY = this.$store.state.searchState ? {...this.$store.state.searchState} : {}
      const newDocFromDate = mlAnaPY.docFromDate ? (parseInt(mlAnaPY.docFromDate.substr(0,4)) - 1).toString() + mlAnaPY.docFromDate.substr(4,6) : ''
      const newDocToDate = mlAnaPY.docToDate ? (parseInt(mlAnaPY.docToDate.substr(0,4)) - 1).toString() + mlAnaPY.docToDate.substr(4,6) : ''
      
      mlAnaPY.docFromDate = newDocFromDate
      mlAnaPY.docToDate = newDocToDate
      mlAnaPY.BusinessYear = parseInt(mlAnaPY.docFromDate.substr(0,4))
      mlAnaPY.type = 1
      this.exRate = round(mlAnaPY.BusinessYear,0) === 2022 ? 7.5345 : 1
      if (mlAnaPY.bookTypeSelected) {
        delete mlAnaPY.bookTypeSelected
      }
      if (mlAnaPY.subtype) {
        delete mlAnaPY.subtype
      }
      if (mlAnaPY.BookTypeID) {
        delete mlAnaPY.BookTypeID
      }

      await MainLedgerDetailService.card(mlAnaPY)
      .then (res => {
          this.pOldItems = res && res.data && res.data.documents ? res.data.documents : []
          if (this.pOldItems && this.pOldItems.length > 0) {
            this.pOldItems.map(pItem => {
              const lInd = this.pOldItems.indexOf(pItem)
              const CreditDebit = round(pItem.totalDebit,2) - round(pItem.totalCredit,2)
              this.pOldItems[lInd].totalDebit = round(pItem.totalDebit / this.exRate,2)
              this.pOldItems[lInd].totalCredit = round(pItem.totalCredit / this.exRate,2)
              this.pOldItems[lInd].CreditDebit = round(CreditDebit/ this.exRate, 2)
            })
          }
          this.useYear = 2
          //this.balanceSh()

          this.rdg2()
          this.useYear = 1
        }
      )
      .catch(err => {
           this.error = 'Error  ' + err
        })
    },
    rdg2() {
      const fullRDG =[]
      const emptyObj = {}
      this.nI= {...emptyObj}
      let poslovniPrihodiPg = 0
      let poslovniPrihodiTg = 0

      // ----------------------------------------------------------------POSLOVNI PRIHODI
      this.nI.id = 1
      this.nI.header = true
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = 'I'
      this.nI.col2 = '75,76 i 78 dio'
      this.nI.col3 = 'POSLOVNI PRIHODI (1+2+3)'
      this.nI.col4 = ''
      this.nI.col5 = null
      this.nI.col6 = null

      this.nA = { ...emptyObj}

      let col5 = 0
      let col6 = 0
      const res1 = this.doRdgCalc(1, '750', '754', 'oneTotal2', 'totalCredit')
      if (res1.prevYear) {col5 += res1.prevYear }
      if (res1.currYear) {col6 += res1.currYear }
      
      const res2 = this.doRdgCalc(1, '756', '759', 'oneTotal2', 'totalCredit')
      if (res2.prevYear) {col5 += res2.prevYear }
      if (res2.currYear) {col6 += res2.currYear }
      // col5 = res2.prevYear ? col5 + res2.prevYear : col5
      // col6 = res2.currYear ? col6 + res2.currYear : col6

      const res3 = this.doRdgCalc(1, '760', '764', 'oneTotal2', 'totalCredit')
      if (res3.prevYear) {col5 += res3.prevYear }
      if (res3.currYear) {col6 += res3.currYear }
      // col5 = res3.prevYear ? col5 + res3.prevYear : col5
      // col6 = res3.currYear ? col6 + res3.currYear : col6

      const res4 = this.doRdgCalc(1, '766', '769', 'oneTotal2', 'totalCredit')
      if (res4.prevYear) {col5 += res4.prevYear }
      if (res4.currYear) {col6 += res4.currYear }
      // col5 = res4.prevYear ? col5 + res4.prevYear : col5
      // col6 = res4.currYear ? col6 + res4.currYear : col6
  

      this.nA.id = 2
      this.nA.header = false
      this.nA.bold = true
      this.nA.small = false
      this.nA.col1 = '1.'
      this.nA.col2 = '750 do 754, 756 do 759, 760 do 764, 766 do 769'
      this.nA.col3 = 'Prihodi od prodaje'
      this.nA.col4 = ''
      this.nA.col5 = col5
      this.nA.col6 = col6
      poslovniPrihodiPg += col5
      poslovniPrihodiTg += col6

      this.nB= {...emptyObj}

      col5 = 0
      col6 = 0

      const res5 = this.doRdgCalc(2, '755', '', 'oneTotal2', 'totalCredit')
      col5 = res5.prevYear ? col5 + res5.prevYear : col5
      col6 = res5.currYear ? col6 + res5.currYear : col6

      const res6 = this.doRdgCalc(2, '765', '', 'oneTotal2', 'totalCredit')
      col5 = res6.prevYear ? col5 + res6.prevYear : col5
      col6 = res6.currYear ? col6 + res6.currYear : col6

      this.nB.id = 3
      this.nB.header = false
      this.nB.bold = true
      this.nB.small = false
      this.nB.col1 = '2.'
      this.nB.col2 = '755 i 765'
      this.nB.col3 = 'Prihod na temelju uporabe vlastitih proizvoda, roba i usluga'
      this.nB.col4 = ''
      this.nB.col5 = col5
      this.nB.col6 = col6
      poslovniPrihodiPg += col5
      poslovniPrihodiTg += col6

      this.nC= {...emptyObj}
      col5 = 0
      col6 = 0

      const res7 = this.doRdgCalc(1, '780', '786', 'oneTotal2', 'totalCredit')
      col5 = res7.prevYear ? col5 + res7.prevYear : col5
      col6 = res7.currYear ? col6 + res7.currYear : col6

      this.nC.id = 4
      this.nC.header = false
      this.nC.bold = true
      this.nC.small = false
      this.nC.col1 = '3.'
      this.nC.col2 = '780 do 786'
      this.nC.col3 = 'Ostali poslovni prihodi'

      this.nC.col4 = ''
      this.nC.col5 = col5
      this.nC.col6 = col6
      poslovniPrihodiPg += col5
      poslovniPrihodiTg += col6

      this.nI.col5 = poslovniPrihodiPg
      this.nI.col6 = poslovniPrihodiTg
      fullRDG.push(this.nI)
      fullRDG.push(this.nA)
      fullRDG.push(this.nB)
      fullRDG.push(this.nC)


      // ----------------------------------------------------------------POSLOVNI RASHODI
      this.nI= {...emptyObj}

      this.nI.id = 5
      this.nI.header = true
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = 'II'
      this.nI.col2 = ''
      this.nI.col3 = 'POSLOVNI RASHODI (1 + 3 do 9 ili 3 do 9 - 2)'
      this.nI.col4 = ''
      this.nI.col5 = null
      this.nI.col6 = null
      const poslovniRashodiIndex = fullRDG.push(this.nI)
      
      col5 = 0
      col6 = 0

      const res8 = this.doRdgCalc(3, '60', '64', 'oneTotal2', 'totalCredit')
      col5 = res8.prevYear ? col5 + res8.prevYear : col5
      col6 = res8.currYear ? col6 + res8.currYear : col6

      this.nI= {...emptyObj}
      this.nI.id = 6
      this.nI.header = false
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = '1.'
      this.nI.col2 = '60 do 64'
      this.nI.col3 = 'Smanjenje vrijednosti zaliha nedovršene proizvodnje i gotovih proizvoda'
      this.nI.col4 = ''
      this.nI.col5 = col5 && col5 < 0 ? res8.prevYear : 0
      this.nI.col6 = col6 && col6 < 0 ? res8.currYear : 0
      const rashod1pg = this.nI.col5
      const rashod1tg = this.nI.col6
      fullRDG.push(this.nI)


      this.nI= {...emptyObj}
      this.nI.id = 7
      this.nI.header = false
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = '2.'
      this.nI.col2 = '60 do 64'
      this.nI.col3 = 'Povećanje vrijednosti zaliha nedovršene proizvodnje i gotovih proizvoda'
      this.nI.col4 = ''
      this.nI.col5 = col5 && col5 > 0 ? res8.prevYear : 0
      this.nI.col6 = col6 && col6 > 0 ? res8.currYear : 0
      const rashod2pg = this.nI.col5
      const rashod2tg = this.nI.col6
      fullRDG.push(this.nI)


      // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>

      col5 = 0
      col6 = 0
      

      this.nI= {...emptyObj}
      this.nI.id = 8
      this.nI.header = false
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = ''
      this.nI.col2 = '40, 41, 703 i 71'
      this.nI.col3 = 'Materijalni troškovi (a + b + c)'
      this.nI.col4 = ''
           
      const res9 = this.doRdgCalc(4, "40",'', 'oneTotal1', 'totalDebit')
      col5 = res9.prevYear ? col5 + res9.prevYear : col5
      col6 = res9.currYear ? col6 + res9.currYear : col6

      const res11 = this.doRdgCalc(2, "703",'', 'oneTotal1', 'totalDebit')
      col5 = res11.prevYear ? col5 + res11.prevYear : col5
      col6 = res11.currYear ? col6 + res11.currYear : col6

      this.nA= {...emptyObj}
      this.nA.id = 9
      this.nA.header = false
      this.nA.bold = false
      this.nA.small = false
      this.nA.col1 = ''
      this.nA.col2 = '40 i 703'
      this.nA.col3 = '\t a) Troškovi sirovna i materijala'
      this.nA.col4 = ''
      this.nA.col5 = col5 ? col5 : 0
      this.nA.col6 = col6 ? col6 : 0

      col5 = 0
      col6 = 0
      
      const res12 = this.doRdgCalc(4, "71",'', 'oneTotal1', 'totalDebit')
      col5 = res12.prevYear ? res12.prevYear : 0
      col6 = res12.currYear ? res12.currYear : 0
      const res13 = this.doRdgCalc(2, "718",'', 'oneTotal1', 'totalDebit') //??
      col5 = res13.prevYear ? col5 - res13.prevYear : col5
      col6 = res13.currYear ? col6 - res13.currYear : col6

      this.nB= {...emptyObj}
      this.nB.id = 10
      this.nB.header = false
      this.nB.bold = false
      this.nB.small = false
      this.nB.col1 = '3.'
      this.nB.col2 = '71 (osim 718)'
      this.nB.col3 = '\t b) Troškovi prodane robe'
      this.nB.col4 = ''
      this.nB.col5 = col5 ? col5 : 0
      this.nB.col6 = col6 ? col6 : 0
      
      const res14 = this.doRdgCalc(4, "41",'', 'oneTotal2', 'totalCredit')
      
      this.nC= {...emptyObj}
      this.nC.id = 10
      this.nC.header = false
      this.nC.bold = false
      this.nC.small = false
      this.nC.col1 = ''
      this.nC.col2 = '41'
      this.nC.col3 = '\t c) Ostali vanjski troškovi'
      this.nC.col4 = ''
      this.nC.col5 = res14.prevYear ? res14.prevYear : 0
      this.nC.col6 = res14.currYear ? res14.currYear : 0   

      this.nI.col5 = this.nA.col5 + this.nB.col5 + this.nC.col5
      this.nI.col6 = this.nA.col6 + this.nB.col6 + this.nC.col6
      const rashod3pg = this.nA.col5 + this.nB.col5 + this.nC.col5
      const rashod3tg = this.nA.col6 + this.nB.col6 + this.nC.col6
      fullRDG.push(this.nI)
      fullRDG.push(this.nA)
      fullRDG.push(this.nB)
      fullRDG.push(this.nC)



      this.nI= {...emptyObj}
      this.nI.id = 11
      this.nI.header = false
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = ''
      this.nI.col2 = '42'
      this.nI.col3 = 'Troškovi osoblja (a + b + c)'
      this.nI.col4 = ''
      this.nI.col5 = null
      this.nI.col6 = null

      col5 = 0
      col6 = 0
      
      const res15 = this.doRdgCalc(2, "420",'', 'oneTotal1', 'totalDebit')
      this.nA= {...emptyObj}
      this.nA.id = 12
      this.nA.header = false
      this.nA.bold = false
      this.nA.small = false
      this.nA.col1 = ''
      this.nA.col2 = '420'
      this.nA.col3 = '\t  a) Neto plaće i nadnice'
      this.nA.col4 = ''
      this.nA.col5 = res15.prevYear ? res15.prevYear : 0
      this.nA.col6 = res15.currYear ? res15.currYear : 0
      
      const res16 = this.doRdgCalc(2, "421",'', 'oneTotal1', 'totalDebit')
      this.nB= {...emptyObj}
      this.nB.id = 13
      this.nB.header = false
      this.nB.bold = false
      this.nB.small = false
      this.nB.col1 = '4.'
      this.nB.col2 = '421'
      this.nB.col3 = '\t  b) Troškovi poreza i doprinosi iz plaća'
      this.nB.col4 = ''
      this.nB.col5 = res16.prevYear ? res16.prevYear : 0
      this.nB.col6 = res16.currYear ? res16.currYear : 0
      
      const res17 = this.doRdgCalc(2, "422")
      this.nC= {...emptyObj}
      this.nC.id = 14
      this.nC.header = false
      this.nC.bold = false
      this.nC.small = false
      this.nC.col1 = ''
      this.nC.col2 = '422'
      this.nC.col3 = '\t  c) Doprinosi na plaće'
      this.nC.col4 = ''
      this.nC.col5 = res17.prevYear ? res17.prevYear : 0
      this.nC.col6 = res17.currYear ? res17.currYear : 0
      

      this.nI.col5 = this.nA.col5 + this.nB.col5 + this.nC.col5
      this.nI.col6 = this.nA.col6 + this.nB.col6 + this.nC.col6
      const rashod4pg = this.nA.col5 + this.nB.col5 + this.nC.col5
      const rashod4tg = this.nA.col6 + this.nB.col6 + this.nC.col6
      fullRDG.push(this.nI)
      fullRDG.push(this.nA)
      fullRDG.push(this.nB)
      fullRDG.push(this.nC)

      
      const res18 = this.doRdgCalc(4, "43",'', 'oneTotal1', 'totalDebit')
      this.nI= {...emptyObj}
      this.nI.id = 15
      this.nI.header = false
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = '5.'
      this.nI.col2 = '43'
      this.nI.col3 = 'Amortizacija'
      this.nI.col4 = ''
      this.nI.col5 = res18.prevYear ? res18.prevYear : 0
      this.nI.col6 = res18.currYear ? res18.currYear : 0
      const rashod5pg = this.nI.col5
      const rashod5tg = this.nI.col6
      fullRDG.push(this.nI)

      const res19 = this.doRdgCalc(4, "46",'', 'oneTotal1', 'totalDebit')
      this.nI= {...emptyObj}
      this.nI.id = 16
      this.nI.header = false
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = '6.'
      this.nI.col2 = '46'
      this.nI.col3 = 'Ostali troškovi'
      this.nI.col4 = ''
      this.nI.col5 = res19.prevYear ? res19.prevYear : 0
      this.nI.col6 = res19.currYear ? res19.currYear : 0
      const rashod6pg = this.nI.col5
      const rashod6tg = this.nI.col6
      fullRDG.push(this.nI)


      this.nI= {...emptyObj}
      this.nI.id = 17
      this.nI.header = false
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = ''
      this.nI.col2 = '44, 708 i 718'
      this.nI.col3 = 'Vrijednosno usklađivanje (a + b)'
      this.nI.col4 = ''

      const res20 = this.doRdgCalc(1, '440', '442', 'oneTotal1', 'totalDebit')
      this.nA= {...emptyObj}
      this.nA.id = 18
      this.nA.header = false
      this.nA.bold = false
      this.nA.small = false
      this.nA.col1 = '7.'
      this.nA.col2 = '440 do 442'
      this.nA.col3 = '\t  a) Vrijednosno usklađivanje dugotrajne imovine (osim financijske imovine)'
      this.nA.col4 = ''
      this.nA.col5 = res20.prevYear ? res20.prevYear : 0
      this.nA.col6 = res20.currYear ? res20.currYear : 0
      
      col5 = 0
      col6 = 0
      const res21 = this.doRdgCalc(1, '444', '447', 'oneTotal1', 'totalDebit')
      col5 = res21.prevYear ? col5 + res21.prevYear : col5
      col6 = res21.currYear ? col6 + res21.currYear : col6

      const res22 = this.doRdgCalc(2, "718",'', 'oneTotal1', 'totalDebit') //?
      col5 = res22.prevYear ? col5 + res22.prevYear : col5
      col6 = res22.currYear ? col6 + res22.currYear : col6
      this.nB= {...emptyObj}
      this.nB.id = 19
      this.nB.header = false
      this.nB.bold = false
      this.nB.small = false
      this.nB.col1 = ''
      this.nB.col2 = '444 do 447 i 718'
      this.nB.col3 = '\t  b) Vrijednosno usklađivanje kratkotrajne imovine (osim financijske imovine)'
      this.nB.col4 = ''
      this.nB.col5 = col5
      this.nB.col6 = col6
      
      this.nI.col5 = this.nA.col5 + this.nB.col5 
      this.nI.col6 = this.nA.col6 + this.nB.col6
      const rashod7pg = this.nI.col5
      const rashod7tg = this.nI.col6
      fullRDG.push(this.nI)
      fullRDG.push(this.nA)
      fullRDG.push(this.nB)


      const res23 = this.doRdgCalc(4, "45",'', 'oneTotal1', 'totalDebit')
      this.nI= {...emptyObj}
      this.nI.id = 20
      this.nI.header = false
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = '8.'
      this.nI.col2 = '45'
      this.nI.col3 = 'Rezerviranja'
      this.nI.col4 = ''
      this.nI.col5 = res23.prevYear ? res23.prevYear : 0
      this.nI.col6 = res23.currYear ? res23.currYear : 0
      const rashod8pg = this.nI.col5
      const rashod8tg = this.nI.col6
      fullRDG.push(this.nI)

      col5 = 0
      col6 = 0
      const res24 = this.doRdgCalc(4, "46",'', 'oneTotal1', 'totalDebit')
      col5 = res24.prevYear ? col5 + res24.prevYear : col5
      col6 = res24.currYear ? col6 + res24.currYear : col6

      const res25 = this.doRdgCalc(4, "48",'', 'oneTotal1', 'totalDebit')
      col5 = res25.prevYear ? col5 + res25.prevYear : col5
      col6 = res25.currYear ? col6 + res25.currYear : col6

      const res26 = this.doRdgCalc(1, '704', '707', 'oneTotal1', 'totalDebit') //?
      col5 = res26.prevYear ? col5 + res26.prevYear : col5
      col6 = res26.currYear ? col6 + res26.currYear : col6

      const res27 = this.doRdgCalc(1, '713', '718', 'oneTotal1', 'totalDebit')
      col5 = res27.prevYear ? col5 + res27.prevYear : col5
      col6 = res27.currYear ? col6 + res27.currYear : col6

      const res28 = this.doRdgCalc(2, "721",'', 'oneTotal1', 'totalDebit') //?
      col5 = res28.prevYear ? col5 + res28.prevYear : col5
      col6 = res28.currYear ? col6 + res28.currYear : col6


      this.nI= {...emptyObj}
      this.nI.id = 21
      this.nI.header = false
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = '9.'
      this.nI.col2 = '46 dio 48, 704 do 707, 713 do 718 i 721'
      this.nI.col3 = 'Ostali poslovni rashodi'
      this.nI.col4 = ''
      this.nI.col5 = col5
      this.nI.col6 = col6
      const rashod9pg = this.nI.col5
      const rashod9tg = this.nI.col6
      fullRDG.push(this.nI)

      let poslovniRashodiPg = rashod3pg + rashod4pg + rashod5pg + rashod6pg + rashod7pg + rashod8pg + rashod9pg
      let poslovniRashodiTg = rashod3tg + rashod4tg + rashod5tg + rashod6tg + rashod7tg + rashod8tg + rashod9tg
      if (rashod1pg > 0) {
        poslovniRashodiPg += rashod1pg  
      }
      if (rashod2pg > 0) {
        poslovniRashodiPg -= rashod2pg
      }
      if (rashod1tg > 0) {
        poslovniRashodiTg += rashod1tg
      }
      if (rashod2tg > 0) {
        poslovniRashodiTg -= rashod2tg
      }
      fullRDG[poslovniRashodiIndex-1].col5 = poslovniRashodiPg
      fullRDG[poslovniRashodiIndex-1].col6 = poslovniRashodiTg

      // -------------------------------------------------------------FINANCIJSKI PRIHODI
      let financijskiPrihodiPg = 0
      let financijskiPrihodiTg = 0
      this.nI= {...emptyObj}
      this.nI.id = 22
      this.nI.header = true
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = 'III'
      this.nI.col2 = '77'
      this.nI.col3 = 'FINANCIJSKI PRIHODI (1 do 5)'
      this.nI.col4 = ''
      this.nI.col5 = 0
      this.nI.col6 = 0
      
      const financijskiPrihodiIndex = fullRDG.push(this.nI)

      const res30 = this.doRdgCalc(2, "770",'', 'oneTotal2', 'totalCredit')
      this.nI= {...emptyObj}
      this.nI.id = 23
      this.nI.header = false
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = '1.'
      this.nI.col2 = '770'
      this.nI.col3 = 'Kamate, tečajne razlike, dividende i slični prihodi iz odnosa s povezanim poduzetnicima'
      this.nI.col4 = ''
      this.nI.col5 = res30.prevYear ? res30.prevYear : 0
      this.nI.col6 = res30.currYear ? res30.currYear : 0
      financijskiPrihodiPg = this.nI.col5
      financijskiPrihodiTg = this.nI.col6
      fullRDG.push(this.nI)

      col5 = 0
      col6 = 0
      const res31 = this.doRdgCalc(2, "771",'', 'oneTotal2', 'totalCredit')
      col5 = res31.prevYear ? col5 + res31.prevYear : col5
      col6 = res31.currYear ? col6 + res31.currYear : col6

      const res32 = this.doRdgCalc(2, "772",'', 'oneTotal2', 'totalCredit')
      col5 = res32.prevYear ? col5 + res32.prevYear : col5
      col6 = res32.currYear ? col6 + res32.currYear : col6

      const res33 = this.doRdgCalc(2, "773",'', 'oneTotal2', 'totalCredit')
      col5 = res33.prevYear ? col5 + res33.prevYear : col5
      col6 = res33.currYear ? col6 + res33.currYear : col6

      const res34 = this.doRdgCalc(2, "774",'', 'oneTotal2', 'totalCredit')
      col5 = res34.prevYear ? col5 + res34.prevYear : col5
      col6 = res34.currYear ? col6 + res34.currYear : col6

      this.nI= {...emptyObj}
      this.nI.id = 24
      this.nI.header = false
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = '2.'
      this.nI.col2 = '771, 772, 773 i 774'
      this.nI.col3 = 'Kamate, tečajne razlike, dividende i slični prihodi iz odnosa s nepovezanim poduzetnicima i dr.osob.'
      this.nI.col4 = ''
      this.nI.col5 = col5
      this.nI.col6 = col6
      fullRDG.push(this.nI)
      financijskiPrihodiPg += this.nI.col5
      financijskiPrihodiTg += this.nI.col6

      
      const res35 = this.doRdgCalc(2, "775",'', 'oneTotal2', 'totalCredit')
      this.nI= {...emptyObj}
      this.nI.id = 25
      this.nI.header = false
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = '3.'
      this.nI.col2 = '775'
      this.nI.col3 = 'Dio prihoda od pridruženih poduzetnika i sudjelujućih interes'
      this.nI.col4 = ''
      this.nI.col5 = res35.prevYear ? res35.prevYear : 0
      this.nI.col6 = res35.currYear ? res35.currYear : 0
      financijskiPrihodiPg += this.nI.col5
      financijskiPrihodiTg += this.nI.col6
      fullRDG.push(this.nI)


      col5 = 0
      col6 = 0
      const res36 = this.doRdgCalc(2, "776",'', 'oneTotal2', 'totalCredit')
      col5 = res36.prevYear ? col5 + res36.prevYear : col5
      col6 = res36.currYear ? col6 + res36.currYear : col6

      const res37 = this.doRdgCalc(2, "777",'', 'oneTotal2', 'totalCredit')
      col5 = res37.prevYear ? col5 + res37.prevYear : col5
      col6 = res37.currYear ? col6 + res37.currYear : col6
      this.nI= {...emptyObj}
      this.nI.id = 26
      this.nI.header = false
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = '4.'
      this.nI.col2 = '776 i 777'
      this.nI.col3 = 'Nerealizirani dobitci (prihodi)'
      this.nI.col4 = ''
      this.nI.col5 = col5
      this.nI.col6 = col6
      financijskiPrihodiPg += this.nI.col5
      financijskiPrihodiTg += this.nI.col6
      fullRDG.push(this.nI)

      col5 = 0
      col6 = 0
      const res38 = this.doRdgCalc(2, "774",'', 'oneTotal2', 'totalCredit')
      col5 = res38.prevYear ? col5 + res38.prevYear : col5
      col6 = res38.currYear ? col6 + res38.currYear : col6

      const res39 = this.doRdgCalc(2, "778",'', 'oneTotal2', 'totalCredit')
      col5 = res39.prevYear ? col5 + res39.prevYear : col5
      col6 = res39.currYear ? col6 + res39.currYear : col6
      this.nI= {...emptyObj}
      this.nI.id = 27
      this.nI.header = false
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = '5.'
      this.nI.col2 = '774 dio i 778'
      this.nI.col3 = 'Ostali financijski prihodi'
      this.nI.col4 = ''
      this.nI.col5 = col5
      this.nI.col6 = col6
      financijskiPrihodiPg += this.nI.col5
      financijskiPrihodiTg += this.nI.col6
      fullRDG.push(this.nI)


      fullRDG[financijskiPrihodiIndex-1].col5 = financijskiPrihodiPg
      fullRDG[financijskiPrihodiIndex-1].col6 = financijskiPrihodiTg

      // -------------------------------------------------------------FINANCIJSKI RASHODI
      let financijskiRashodiPg = 0
      let financijskiRashodiTg = 0
      this.nI= {...emptyObj}
      this.nI.id = 28
      this.nI.header = true
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = 'IV'
      this.nI.col2 = '47'
      this.nI.col3 = 'FINANCIJSKI RASHODI (1 do 4)'
      this.nI.col4 = ''
      this.nI.col5 = 0
      this.nI.col6 = 0
      
      const financijskiRashodiIndex = fullRDG.push(this.nI)

      col5 = 0
      col6 = 0
      const res41 = this.doRdgCalc(2, "470",'', 'oneTotal1', 'totalDebit')
      col5 = res41.prevYear ? col5 + res41.prevYear : col5
      col6 = res41.currYear ? col6 + res41.currYear : col6

      const res42 = this.doRdgCalc(2, "471",'', 'oneTotal1', 'totalDebit')
      col5 = res42.prevYear ? col5 + res42.prevYear : col5
      col6 = res42.currYear ? col6 + res42.currYear : col6

      const res43 = this.doRdgCalc(2, "472",'', 'oneTotal1', 'totalDebit')
      col5 = res43.prevYear ? col5 + res43.prevYear : col5
      col6 = res43.currYear ? col6 + res43.currYear : col6

      this.nI= {...emptyObj}
      this.nI.id = 29
      this.nI.header = false
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = '1.'
      this.nI.col2 = '470, 471 i 472'
      this.nI.col3 = 'Kamate, tečajne razlike i drugi rashodi s povezanim poduzetnicima'
      this.nI.col4 = ''
      this.nI.col5 = col5
      this.nI.col6 = col6
      fullRDG.push(this.nI)
      financijskiRashodiPg += this.nI.col5
      financijskiRashodiTg += this.nI.col6

      col5 = 0
      col6 = 0
      const res44 = this.doRdgCalc(1, '473', '476', 'oneTotal1', 'totalDebit')
      col5 = res44.prevYear ? col5 + res44.prevYear : col5
      col6 = res44.currYear ? col6 + res44.currYear : col6

      const res45 = this.doRdgCalc(2, "479",'', 'oneTotal1', 'totalDebit')
      col5 = res45.prevYear ? col5 + res45.prevYear : col5
      col6 = res45.currYear ? col6 + res45.currYear : col6

      this.nI= {...emptyObj}
      this.nI.id = 30
      this.nI.header = false
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = '2.'
      this.nI.col2 = '473 do 476 i dio 479'
      this.nI.col3 = 'Kamate, tečajne razlike i drugi rashodi s nepovezanim poduzetnicima i dr.osob.'
      this.nI.col4 = ''
      this.nI.col5 = col5
      this.nI.col6 = col6
      fullRDG.push(this.nI)
      financijskiRashodiPg += this.nI.col5
      financijskiRashodiTg += this.nI.col6

      const res46 = this.doRdgCalc(2, "478",'', 'oneTotal1', 'totalDebit')
      this.nI= {...emptyObj}
      this.nI.id = 31
      this.nI.header = false
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = '3.'
      this.nI.col2 = '478'
      this.nI.col3 = 'Nerealizirani gubitci (rashodi) financijske imovine'
      this.nI.col4 = ''
      this.nI.col5 = res46.prevYear ? res46.prevYear : 0
      this.nI.col6 = res46.currYear ? res46.currYear : 0
      fullRDG.push(this.nI)
      financijskiRashodiPg += this.nI.col5
      financijskiRashodiTg += this.nI.col6

      const res47 = this.doRdgCalc(2, "478",'', 'oneTotal1', 'totalDebit')
      this.nI= {...emptyObj}
      this.nI.id = 32
      this.nI.header = false
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = '4.'
      this.nI.col2 = '477 i 479 dio'
      this.nI.col3 = 'Ostali financijski rashodi'
      this.nI.col4 = ''
      this.nI.col5 = res47.prevYear ? res47.prevYear : 0
      this.nI.col6 = res47.currYear ? res47.currYear : 0
      fullRDG.push(this.nI)
      financijskiRashodiPg += this.nI.col5
      financijskiRashodiTg += this.nI.col6

      fullRDG[financijskiRashodiIndex-1].col5 = financijskiRashodiPg
      fullRDG[financijskiRashodiIndex-1].col6 = financijskiRashodiTg

      // -------------------------------------------------------------IZVANREDNI - OSTALI PRIHODI
      col5 = 0
      col6 = 0
      const res48 = this.doRdgCalc(2, "788",'', 'oneTotal1', 'totalCredit')
      col5 = res48.prevYear ? col5 + res48.prevYear : col5
      col6 = res48.currYear ? col6 + res48.currYear : col6

      const res49 = this.doRdgCalc(2, "789",'', 'oneTotal1', 'totalCredit')
      col5 = res49.prevYear ? col5 + res49.prevYear : col5
      col6 = res49.currYear ? col6 + res49.currYear : col6

      let izvanredniPrihodiPg = 0
      let izvanredniPrihodiTg = 0
      this.nI= {...emptyObj}
      this.nI.id = 33
      this.nI.header = true
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = 'V'
      this.nI.col2 = '788 i 789'
      this.nI.col3 = 'IZVANREDNI - OSTALI PRIHODI'
      this.nI.col4 = ''
      this.nI.col5 = col5
      this.nI.col6 = col6
      fullRDG.push(this.nI)
      
      // -------------------------------------------------------------IZVANREDNI - OSTALI RASHODI
      let izvanredniRashodiPg = 0
      let izvanredniRashodiTg = 0
      const res50 = this.doRdgCalc(4, "73",'', 'oneTotal1', 'totalDebit') //?
      this.nI= {...emptyObj}
      this.nI.id = 34
      this.nI.header = true
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = 'VI'
      this.nI.col2 = '73'
      this.nI.col3 = 'IZVANREDNI - OSTALI RASHODI'
      this.nI.col4 = ''
      this.nI.col5 = res50.prevYear ? res50.prevYear : 0
      this.nI.col6 = res50.currYear ? res50.currYear : 0
      izvanredniRashodiPg = this.nI.col5
      izvanredniRashodiTg = this.nI.col6
      fullRDG.push(this.nI)
      
      // -------------------------------------------------------------UKUPNI PRIHODI (I. + III. + V.)
      this.nI= {...emptyObj}
      this.nI.id = 35
      this.nI.header = true
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = 'VII'
      this.nI.col2 = ''
      this.nI.col3 = 'UKUPNI PRIHODI (I. + III. + V.)'
      this.nI.col4 = ''
      this.nI.col5 = poslovniPrihodiPg + financijskiPrihodiPg + izvanredniPrihodiPg
      this.nI.col6 = poslovniPrihodiTg + financijskiPrihodiTg + izvanredniPrihodiTg
      fullRDG.push(this.nI)


      const poslovniRashodiPgE = rashod3pg + rashod4pg + rashod6pg 
      const poslovniRashodiTgE = rashod3tg + rashod4tg + rashod6tg 
      // -------------------------------------------------------------UKUPNI PRIHODI (I. + III. + V.)
      this.nI= {...emptyObj}
      this.nI.id = 35
      this.nI.header = true
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = ''
      this.nI.col2 = ''
      this.nI.col3 = 'EBITDA (posl.prihodi - posl.rashodi(bez amo.))'
      this.nI.col4 = ''
      this.nI.col5 = poslovniPrihodiPg - poslovniRashodiPgE
      this.nI.col6 = poslovniPrihodiTg - poslovniRashodiTgE
      fullRDG.push(this.nI)

      // -------------------------------------------------------------UKUPNI RASHODI (II. + IV. + VI.)
      this.nI= {...emptyObj}
      this.nI.id = 36
      this.nI.header = true
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = 'VIII'
      this.nI.col2 = ''
      this.nI.col3 = 'UKUPNI RASHODI (II. + IV. + VI.)'
      this.nI.col4 = ''
      this.nI.col5 = poslovniRashodiPg + financijskiRashodiPg + izvanredniRashodiPg
      this.nI.col6 = poslovniRashodiTg + financijskiRashodiTg + izvanredniRashodiTg
      fullRDG.push(this.nI)

      let dobitPg = 0
      let dobitTk = 0
      let gubitakPg = 0
      let gubitakTk = 0
      // -------------------------------------------------------------DOBITAK PRIJE OPOREZIVANJA (VII. - VIII.)
      if ((poslovniPrihodiPg + financijskiPrihodiPg + izvanredniPrihodiPg) > (poslovniRashodiPg + financijskiRashodiPg + izvanredniRashodiPg)) {
        dobitPg = (poslovniPrihodiPg + financijskiPrihodiPg + izvanredniPrihodiPg) - (poslovniRashodiPg + financijskiRashodiPg + izvanredniRashodiPg)
      }
      if ((poslovniPrihodiTg + financijskiPrihodiTg + izvanredniPrihodiTg) > (poslovniRashodiTg + financijskiRashodiTg + izvanredniRashodiTg)) {
        dobitTk = (poslovniPrihodiTg + financijskiPrihodiTg + izvanredniPrihodiTg) - (poslovniRashodiTg + financijskiRashodiTg + izvanredniRashodiTg)
      }
      this.nI= {...emptyObj}
      this.nI.id = 37
      this.nI.header = true
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = 'IX'
      this.nI.col2 = '800'
      this.nI.col3 = 'DOBITAK PRIJE OPOREZIVANJA (VII. - VIII.)'
      this.nI.col4 = ''
      this.nI.col5 = dobitPg
      this.nI.col6 = dobitTk
      fullRDG.push(this.nI)

      // -------------------------------------------------------------GUBITAK PRIJE OPOREZIVANJA (VIII. - VII.)
       if ((poslovniPrihodiPg + financijskiPrihodiPg + izvanredniPrihodiPg) < (poslovniRashodiPg + financijskiRashodiPg + izvanredniRashodiPg)) {
        gubitakPg = (poslovniRashodiPg + financijskiRashodiPg + izvanredniRashodiPg) - (poslovniPrihodiPg + financijskiPrihodiPg + izvanredniPrihodiPg)
      }
      if ((poslovniPrihodiTg + financijskiPrihodiTg + izvanredniPrihodiTg) < (poslovniRashodiTg + financijskiRashodiTg + izvanredniRashodiTg)) {
        gubitakTk = (poslovniPrihodiTg + financijskiPrihodiTg + izvanredniPrihodiTg) - (poslovniRashodiTg + financijskiRashodiTg + izvanredniRashodiTg)
      }
      this.nI= {...emptyObj}
      this.nI.id = 38
      this.nI.header = true
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = 'X'
      this.nI.col2 = '801'
      this.nI.col3 = 'GUBITAK PRIJE OPOREZIVANJA (VIII. - VII.)'
      this.nI.col4 = ''
      this.nI.col5 = gubitakPg
      this.nI.col6 = gubitakTk
      fullRDG.push(this.nI)

      const porezPg = dobitPg < 995421.06 ? dobitPg * 0.1 : dobitPg * 0.18
      const porezTg = dobitTk < 1000000 ? dobitTk * 0.1 : dobitTk * 0.18
      // -------------------------------------------------------------POREZ NA DOBITAK
      this.nI= {...emptyObj}
      this.nI.id = 39
      this.nI.header = true
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = 'XI'
      this.nI.col2 = '803'
      this.nI.col3 = 'POREZ NA DOBITAK'
      this.nI.col4 = ''
      this.nI.col5 = porezPg
      this.nI.col6 = porezTg
      fullRDG.push(this.nI)
      

      const dobit = dobitTk - porezTg

      // -------------------------------------------------------------DOBITAK RAZDOBLJA (IX. - XI.)
      this.nI= {...emptyObj}
      this.nI.id = 40
      this.nI.header = true
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = 'XII'
      this.nI.col2 = '8040'
      this.nI.col3 = 'DOBITAK RAZDOBLJA (IX. - XI.)'
      this.nI.col4 = ''
      this.nI.col5 = dobitPg - porezPg
      this.nI.col6 = dobitTk - porezTg
      fullRDG.push(this.nI)

      // -------------------------------------------------------------GUBITAK RAZDOBLJA (X. + XI.) ili (XI. - IX.)
      this.nI= {...emptyObj}
      this.nI.id = 40
      this.nI.header = true
      this.nI.bold = true
      this.nI.small = false
      this.nI.col1 = 'XII'
      this.nI.col2 = '8041'
      this.nI.col3 = 'GUBITAK RAZDOBLJA (X. + XI.) ili (XI. - IX.)'
      this.nI.col4 = ''
      this.nI.col5 = gubitakPg
      this.nI.col6 = gubitakTk
      fullRDG.push(this.nI)

      this.fRDG.head = {...this.head}
      this.fRDG.items = fullRDG
      this.print2()
    },
    doRdgCalc(type, from, to, currYearField, prevYearField) {
      const retObj ={}
      retObj.prevYear = 0
      retObj.currYear = 0

      if (type === 1) {
        // go from to to
        const fCond = this.pItems.filter(item => (item.oneCond >= from && item.oneCond <= to) && item.itLine === 'Sin3.ukupno:')
        if (fCond) {
          retObj.currYear = this.calculateSum(fCond, currYearField)
        }
        const fCond2 = this.pOldItems.filter(item => (item.account3 >= from && item.account3 <= to))
        if (fCond2) {
          retObj.prevYear = round(this.calculateSum(fCond2, prevYearField) / this.exRate , 2)
        }
      }
      if (type === 2) {
        // just use from
        const fCond = this.pItems.filter(item => item.oneCond === from && item.itLine === 'Sin3.ukupno:')
        if (fCond) {
          retObj.currYear = this.calculateSum(fCond, currYearField)
        }
        const fCond2 = this.pOldItems.filter(item => item.account3 === from)
        if (fCond2) {
          retObj.prevYear = round(this.calculateSum(fCond2, prevYearField)  / this.exRate,2) 
        }
      }

      if (type === 3) {
        // go from to to
        const fCond = this.pItems.filter(item => (item.oneCond >= from && item.oneCond <= to) && item.itLine === 'Sin.ukupno:')
        if (fCond) {
          retObj.currYear = this.calculateSum(fCond, currYearField)
        }
        const fCond2 = this.pOldItems.filter(item => (item.account2 >= from && item.account2 <= to))
        if (fCond2) {
          retObj.prevYear = round(this.calculateSum(fCond2, prevYearField) / this.exRate ,2)
        }
      }

      if (type === 4) {
        // go from to to
        const fCond = this.pItems.filter(item => item.oneCond === from  && item.itLine === 'Sin.ukupno:')
        if (fCond) {
          retObj.currYear = this.calculateSum(fCond, currYearField)
        }
        const fCond2 = this.pOldItems.filter(item => item.account2 === from )
        if (fCond2) {
          retObj.prevYear = round(this.calculateSum(fCond2, prevYearField) / this.exRate, 2)
        }
      }
    return retObj
    
    },
    calculateSum(array, property) {
      const total = array.reduce((accumulator, object) => {
        return accumulator + object[property];
      }, 0);
      const total2 = Math.abs(total)
      return total2;
    },

    downloadFile () {
      //const allLines = this.users ? this.users : []
      // let printitems = this.users.slice()
      // if (!this.printdate && this.printdate2) {
      //   printitems = this.users.filter(user => user.llaDocumentSourceDate < this.printdate2)
      // }
      // if (this.printdate && this.printdate2) {
      //   printitems = this.users.filter(user => user.llaDocumentSourceDate > this.printdate && user.llaDocumentSourceDate < this.printdate2)
      // }
   
      const fullB = []
      const newI1 = {}
      newI1.id = ''
      newI1.acc0 = ' '
      newI1.acc1 = ''
      newI1.acc2 = ''
      newI1.acc3 = ''
      newI1.account4Name = ' BRUTO BILANACA  ' + this.comp.CompanyNameShort 
      newI1.account4 = ''
      newI1.psDebit =  ''
      newI1.psCredit=  ''
      newI1.yearDebit= ''
      newI1.yearCredit = ''
      newI1.totDebit= ''
      newI1.totCredit =  ''
      newI1.acc4Total =  ''
      fullB.push(newI1)

      const newI2 = {}
      newI2.id = ''
      newI2.acc0 = ' '
      newI2.acc1 = ''
      newI2.acc2 = ''
      newI2.acc3 = ''
      newI2.account4Name =  this.comp.CompanyStreet + ', ' + this.comp.CompanyBuildingNameNumber + ', ' + this.comp.CompanyPostcode + ', ' + this.comp.CompanyPostcode
      newI2.account4 = ''
      newI2.psDebit =  ''
      newI2.psCredit=  ''
      newI2.yearDebit= ''
      newI2.yearCredit = ''
      newI2.totDebit= ''
      newI2.totCredit =  ''
      newI2.acc4Total =  ''
      fullB.push(newI2)

      const newI3 = {}
      newI3.id = ''
      newI3.acc0 = ' '
      newI3.acc1 = ''
      newI3.acc2 = ''
      newI3.acc3 = ''
      newI3.account4Name =  'OD '+ (this.head.docFromDate ? this.localDate(this.head.docFromDate) : '') + ' do ' + (this.head.docToDate ? this.localDate(this.head.docToDate) : '')
      newI3.account4 = ''
      newI3.psDebit =  ''
      newI3.psCredit=  ''
      newI3.yearDebit= ''
      newI3.yearCredit = ''
      newI3.totDebit= ''
      newI3.totCredit =  ''
      newI3.acc4Total =  ''
      fullB.push(newI3)

      const newI4 = {}
      newI4.id = ''
      newI4.acc0 = ' '
      newI4.acc1 = ''
      newI4.acc2 = ''
      newI4.acc3 = ''
      newI4.account4Name =  ' Od konta:' + this.head.fromAccount + ' do konta: ' + this.head.toAccount
      newI4.account4 = ''
      newI4.psDebit =  ''
      newI4.psCredit=  ''
      newI4.yearDebit= ''
      newI4.yearCredit = ''
      newI4.totDebit= ''
      newI4.totCredit =  ''
      newI4.acc4Total =  ''
      fullB.push(newI4)


      if (this.pItems) {
        this.pItems.map(item => {
          const cond1 = typeof item.itLine === 'string' ? item.itLine.trim() : ''
          if (item.addLine && (cond1 === "Sin.3" || cond1 === "Sintetika" || cond1 === "KLASA")) {
            const newI = {}
            newI.id = item.id
            this.allId = item.id && item.id > this.allId ? this.allId = item.id : 0
            newI.acc0 = ' '
            newI.acc1 = ''
            newI.acc2 = ''
            newI.acc3 = ''
            newI.account4Name = ''
            if (item.itLine === "KLASA") {
              newI.acc1 =  item.oneCond
              newI.account4Name = item.account1Name ? '> ' + item.account1Name : ''
            }
            if (item.itLine === "Sintetika") {
              newI.acc2 =  item.oneCond
              newI.account4Name = item.account1Name ? '>> ' + item.account1Name : ''
            }
            if (item.itLine === "Sin.3") {
              newI.acc3 = item.oneCond
              newI.account4Name = item.account1Name ? '>>> ' + item.account1Name : ''
            }
            newI.account4 = ''
            newI.psDebit =  ''
            newI.psCredit=  ''
            newI.yearDebit= ''
            newI.yearCredit = ''
            newI.totDebit= ''
            newI.totCredit =  ''
            newI.acc4Total =  ''
            fullB.push(newI)
          }
          if (!item.addLine) {
            const newI = {}
            this.allId = item.id && item.id > this.allId ? this.allId = item.id : 0
            newI.id = item.id
            newI.acc0 = ' '
            newI.account4Name = item.account4Name ? item.account4Name : ''
            newI.acc1 = ''
            newI.acc2 = ''
            newI.acc3 = ''
            newI.account4 = item.account4 ? item.account4 : ''
            newI.psDebit = item.psDebit ? item.psDebit  : ''
            newI.psCredit= item.psCredit ? item.psCredit  : ''
            newI.yearDebit= item.yearDebit ? item.yearDebit : ''
            newI.yearCredit = item.yearCredit ? item.yearCredit : ''
            newI.totDebit= item.totDebit ? item.totDebit : ''
            newI.totCredit = item.totCredit ? item.totCredit: ''
            newI.acc4Total = item.CreditDebit ? item.CreditDebit : ''
            fullB.push(newI)
          }
          if (item.addLine && (cond1 === "Sin3.ukupno:" || cond1 === "Sin.ukupno:" || cond1 === "KLASA UKUPNO:")) {
            const newI = {}
            this.allId = item.id && item.id > this.allId ? this.allId = item.id : 0
            newI.id = item.id
            newI.acc0 = ' '
            newI.acc1 = ''
            newI.acc2 = ''
            newI.acc3 = ''
            newI.account4Name = ''
            if (item.itLine === 'KLASA UKUPNO:') {
              newI.account4Name = '> KLASA UKUPNO:'
              newI.acc1 = item.oneCond
            }
            if (item.itLine === 'Sin.ukupno:') {
              newI.account4Name = '>> Sin.ukupno:'
              newI.acc2 = item.oneCond
            }
            if (item.itLine === 'Sin3.ukupno:') {
              newI.account4Name = '>>> Sin3.ukupno:'
              newI.acc3 = item.oneCond
            }
            newI.account4 = ''
            newI.psDebit = item.onePsTotal1 ? item.onePsTotal1 : ''
            newI.psCredit= item.onePsTotal2 ? item.onePsTotal2 : ''
            newI.yearDebit= item.oneYearTotal1 ? item.oneYearTotal1 : ''
            newI.yearCredit = item.oneYearTotal2 ? item.oneYearTotal2 : ''
            newI.totDebit= item.oneTotal1 ? item.oneTotal1 : ''
            newI.totCredit = item.oneTotal2 ? item.oneTotal2 : ''
            newI.acc4Total = item.CreditDebit ? item.CreditDebit : ''
            fullB.push(newI)
          }
        })
      }

      if (this.lastOne.account3Name) {
        const newI = {}
        this.allId += 1
        newI.id = this.allId
        newI.acc0 = ' '
        newI.acc1 = ''
        newI.acc2 = ''
        newI.acc3 = ''
        newI.account4Name = '>>> Sin3.ukupno:'
        newI.acc3 = this.lastOne.threeCond
        newI.account4 = ''
        newI.psDebit = this.lastOne.threePsTotal1
        newI.psCredit= this.lastOne.threePsTotal2
        newI.yearDebit= this.lastOne.threeYearTotal1
        newI.yearCredit = this.lastOne.threeYearTotal2
        newI.totDebit= this.lastOne.threeTotal1
        newI.totCredit = this.lastOne.threeTotal2
        newI.acc4Total = this.lastOne.threeSum
        fullB.push(newI)
      }
      if (this.lastOne.account2Name) {
        const newI = {}
        this.allId += 1
        newI.id = this.allId
        newI.acc0 = ' '
        newI.acc1 = ''
        newI.acc2 = ''
        newI.acc3 = ''
        newI.account4Name = '>> Sin.ukupno:'
        newI.acc3 = this.lastOne.twoCond
        newI.account4 = ''
        newI.psDebit = this.lastOne.twoPsTotal1
        newI.psCredit= this.lastOne.twoPsTotal2
        newI.yearDebit= this.lastOne.twoYearTotal1
        newI.yearCredit = this.lastOne.twoYearTotal2
        newI.totDebit= this.lastOne.twoTotal1
        newI.totCredit = this.lastOne.twoTotal2
        newI.acc4Total = this.lastOne.twoSum
        fullB.push(newI)
      }
      if (this.lastOne.account1Name) {
        const newI = {}
        this.allId += 1
        newI.id = this.allId
        newI.acc0 = ' '
        newI.acc1 = ''
        newI.acc2 = ''
        newI.acc3 = ''
        newI.account4Name = '> KLASA UKUPNO:'
        newI.acc3 = this.lastOne.oneCond
        newI.account4 = ''
        newI.psDebit = this.lastOne.onePsTotal1
        newI.psCredit= this.lastOne.onePsTotal2
        newI.yearDebit= this.lastOne.oneYearTotal1
        newI.yearCredit = this.lastOne.oneYearTotal2
        newI.totDebit= this.lastOne.oneTotal1
        newI.totCredit = this.lastOne.oneTotal2
        newI.acc4Total = this.lastOne.oneSum
        fullB.push(newI)
      }

      const newI = {}
      this.allId += 1
      newI.id = this.allId
      newI.acc0 = ' '
      newI.acc1 = ''
      newI.acc2 = ''
      newI.acc3 = ''
      newI.account4Name = this.currency
      newI.account4 = ''
      newI.psDebit = this.psTotal1
      newI.psCredit= this.psTotal2
      newI.yearDebit= this.yearTotal1
      newI.yearCredit = this.yearTotal2
      newI.totDebit= this.Total1
      newI.totCredit = this.Total2
      newI.acc4Total = this.Total5
      fullB.push(newI)

      const companyName = this.$store.state.companyActiv && this.$store.state.companyActiv.CompanyNameDatabase ? this.$store.state.companyActiv.CompanyNameDatabase : ''
      const currDate = dayjs().format('DD_MM_YYYY_HH_MM')
      const sheetNm = 'BB_' + this.$store.state.businessYear + '_' + currDate
      let data = [
      {
        sheet: sheetNm,
        columns: [
          {label: 'Rb', value: 'id'},
          {label: ' ', value: ' '},
          {label: 'Naziv', value: 'account4Name' },
          {label: 'Klasa', value: 'acc1'},
          {label: 'Sintetika', value: 'acc2'},
          {label: 'Sint.3', value: 'acc3'},
          {label: 'Konto', value: 'account4'},
          {label: 'PS.duguje', value: 'psDebit'},
          {label: 'PS.potražuje', value: 'psCredit'},
          {label: 'Promet Dug.', value: 'yearDebit'},
          {label: 'Promet Pot.',value: 'yearCredit'},
          {label: 'Uk.Duguje' , value: 'totDebit'},
          {label: 'Uk.Potražuje', value: 'totCredit'},
          {label: 'Saldo', value: 'acc4Total'},
        ],
        content: fullB
      },
    ]

      const settings = {
        fileName: companyName + '_BrutoBilanca_' + currDate, // Name of the spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
      }
      xlsx( data, settings)
    },
    close () {
      this.show = false
    }
  },
  watch: {

  },
  components: {
    MainLedgerPrintBalance,
    MainLedgerPrintProfitLoss
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
