const docEN = {
  Vehicle: 'New Vehicle',
  VehicleList: 'Vehicle List',
  EditVehicle: 'Edit Vehicle',
  VehicleListEmpty: 'Vehicle List Empty',
  MarkType: 'Mark Type *',
  Chasis: 'Chasis ID',
  TypeVeichle: 'Type of Veichle',
  Serie: 'Serie',
  Engine: 'Engine',
  ChasisModel: 'Chasis Model',
  Registration: 'Registration *',
  Colour: 'Colour',
  Km: 'Km',
  YearManufacture: 'Year Manufacture',
  MonthManufacture: 'Month Manufacture',
  StateManufacture: 'State Manufacture',
  partnerName: 'Owner'
}

export default docEN