const docEN = {
  Forms: 'Forms',
  FormPPO: 'PPO',
  FormPPODetail: 'Pregled tuzemnih isporuka s prijenosom porezne obveze',
  FormPDVS: 'PDV-S',
  FormPDVSDetail: 'Prijava za stjecanje dobara i usluga iz drugih država članice Europske unije',
  FormZP: 'ZP',
  FormZPDetail: 'Zbrina prijava za isporuke dobara i usluga u druge države članice Europske unije',
  PPO_1: 'POREZNA UPRAVA PODRUČNI URED',
  PPO_2: 'ISPOSTAVA',
  PPO_3: 'OIB',
  PPO_4: 'Porezni obveznik (naziv/ime i prezime)',
  PPO_5: 'Adresa (mjesto, ulica i broj)',
  PPO_6: 'OIB poreznog zastupnika',
  PPO_7: 'Za tromjesečje',
  PPO_71: 'godina',
  PPO_8: 'Mjesec u kojem je obavljena isporuka',
  PPO_81: 'Datum',
  PPO_9: 'Redni broj',
  PPO_10: 'OIB primatelja isporuke',
  PPO_11: 'Vrijednost isporuka (Obrazac PDV - red.br. I.1.)(u kunama i lipama)',
  PPO_12: 'Prvi mjesec tromjesečja',
  PPO_13: 'Drugi mjesec tromjesečja',
  PPO_14: 'Treći mjesec tromjesečja',
  PPO_15: 'UKUPNO ',
  PPO_16: 'Obračun sastavio (ime i prezime)',
  PPO_17: 'Potpis',
  PPO_8_1: '',
  PPO_8_2: '',
  PPO_8_3: '',
  PPO_9_1: '1.',
  PPO_9_2: '2.',
  PPO_9_3: '3.',
  PPO_9_4: 'n',
  PDVS_1: 'POREZNA UPRAVA PODRUČNI URED',
  PDVS_2: 'ISPOSTAVA',
  PDVS_3: 'PDV identifikacijski broj',
  PDVS_31: 'HR',
  PDVS_4: 'Porezni obveznik (naziv/ime i prezime)',
  PDVS_5: 'Adresa (mjesto, ulica i broj)',
  PDVS_6: 'PDV identifikacijski broj poreznog zastupnika',
  PDVS_7: 'Za mjesec',
  PDVS_71: 'godina',
  PDVS_8: 'Redni broj',
  PDVS_9: 'Kod države isporučitelja',
  PDVS_10: 'PDV identifikacijski broj isporučitelja (bez kôda države)',
  PDVS_11: 'Vrijednost stečenih dobara(u kunama i lipama)',
  PDVS_12: 'Vrijednost primljenih usluga(u kunama i lipama)',
  PDVS_13: 'Ukupno dobra',
  PDVS_14: 'Ukupno usluge',
  PDVS_15: 'Obračun sastavio (ime i prezime)',
  PDVS_16: 'Potpis',
  PDVS_17: 'Broj telefona/fax/e-mail',
  ZP_1: 'POREZNA UPRAVA PODRUČNI URED',
  ZP_2: 'ISPOSTAVA',
  ZP_3: 'PDV identifikacijski broj',
  ZP_31: 'HR',
  ZP_4: 'Porezni obveznik (naziv/ime i prezime)',
  ZP_5: 'Adresa (mjesto, ulica i broj)',
  ZP_6: 'PDV identifikacijski broj poreznog zastupnika',
  ZP_7: 'Za mjesec',
  ZP_71: 'godina',
  ZP_8: 'Redni broj',
  ZP_9: 'Kod države',
  ZP_10: 'PDV identifikacijski broj (bez kôda države)',
  ZP_11: 'Vrijednost isporuke dobara(u kunama i lipama)',
  ZP_12: 'Vrijednost isporuke dobara u postupcima 42 i 63(u kunama i lipama)',
  ZP_13: 'Vrijednost isporuke dobara u okviru trostranog(u kunama i lipama)',
  ZP_14: 'Vrijednost obavljenih usluga(u kunama i lipama)',
  ZP_15: 'Ukupna vrijednost:',
  ZP_16: 'Obračun sastavio (ime i prezime)',
  ZP_17: 'Potpis',
  ZP_18: 'Broj telefona/fax/e-mail',
  EmptyForm: 'Nema stavaka',
  partnerVATID: 'Cijeli OIB',
  partnerName: 'Partner'
}

export default docEN