<template>
  <div class="bankaccount">
      <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
       <v-snackbar
            v-model="snackbar"
            bottom
            :timeout="timeout"
            :color="color"
            >
            {{ text }}
      </v-snackbar>
      <v-card>
        <v-card-title>
          {{ lang.BankAccounts }}
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="20"
            @click:row="navigateTo"
            :mobile-breakpoint="0"            
          >
          <template v-slot:[`item.DefaultAccount`]="{ item }">
            <span v-if="item.DefaultAccount"><v-icon>mdi-check</v-icon></span>
          </template>
           <template v-slot:[`item.createdAt`]="{ item }">
              <span>{{ localDate(item.createdAt) }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>
  </div>
</template>

<script>
// import axios from 'axios'
import BankAccountService from '@/services/BankAccountService'
import langEn from './BankAccountDescEN'
import langHr from './BankAccountDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dateFormat from '@/snippets/dateFormat'

export default {
  name: 'bankaccountlist',
  data () {
    return {
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [25],
      headers: [ ],
      mainQuery: {},
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    // if (this.$store.state.businessYear) {
    //   this.mainQuery.businessYear = this.$store.state.businessYear
    // }
    this.headers.push(
      {text: this.lang.BankName, value: 'BankName', width: '150px'},
      {text: this.lang.IBAN, value: 'IBAN', width: '150px'},
      {text: this.lang.BankAccountDescription, value: 'BankAccountDescription', width: '250px'},
      {text: this.lang.DefaultAccount, value: 'DefaultAccount'},
      {text: this.lang.createdAt, value: 'createdAt', width: '150px'},
      {text: this.lang.id, value: 'id', width: '50px'}
    )
    this.saving = true
    const {data} = await BankAccountService.index(this.mainQuery)
    // eslint-disable-next-line
    console.log(data, this.mainQuery)
    this.saving = false
    if (data.bankAccounts && data.bankAccounts.length > 0) {
      this.items = data.bankAccounts ? data.bankAccounts : []
    } else {
      this.text = 'Nema niti jedan ziro racun upisan!! Bank account data are empty!!'
      this.color = 'red'
      this.snackbar = true
      this.timeout = 4000
    }
    
  },
  methods: {
    navigateTo (params) {
      this.$store.dispatch('seteditItem', 0)
      this.$store.dispatch('setBankAccountid', params.id)
      this.$router.push({
        name: 'bankaccountview'
      }).catch(err => {
          // eslint-disable-next-line
          console.log(err);
        })
    },
    formatFn (value) {
      if (value === true) {
        return 'Yes'
      } else {
        return ''
      }
    },
    formatFnHR (value) {
      if (value === true) {
        return 'Da'
      } else {
        return ''
      }
    },
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    }
    // localDate(dateToFormat) {
    //   return dateToFormat.toLocaleDateString('de-DE', {year: 'numeric', month: '2-digit', day:'2-digit'})
    //   //return this.$moment(dateToFormat).format('DD.MM.YYYY HH:MM')
    // }
  },
  computed: {

  },
  watch: {

  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
