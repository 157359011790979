import Api from '@/services/Api'

export default {
  index(query) {
    return Api().get('item', {
      params: query
    })
  },
  indexlist(query) {
    return Api().get('item/list', {
      params: query
    })
  },
  choose(query) {
    return Api().get('item/choose', {
      params: query
    })
  },
  updateprices(items) {
    return Api().post('item/updateprices', items)
  },
  
  show(itemId) {
    return Api().get(`item/${itemId}`)
  },
  post(newitem) {
    return Api().post('item', newitem)
  },
  put(item) {
    return Api().put(`item/${item.id}`, item)
  },
  delete(itemId) {
    return Api().delete(`item/${itemId}`)
  }
}