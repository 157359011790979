<template>
  <v-container fluid>
    <v-progress-circular v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
      <v-snackbar
          v-model="snackbar"
          bottom
          :timeout="timeout"
          :color="color"
          >
          {{ text }}
    </v-snackbar>
    <v-row v-if="showCard3">
      <MainLedgerCard3 :items="dItems" :head="mainledgerAna"/>
    </v-row>

    <v-row style="border-bottom:solid 1px;">
      <v-flex xs5>
          <h4><b><i>{{comp.CompanyNameShort}}</i></b></h4>
          {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}   <br />                
          {{comp.CompanyPostcode}} {{comp.CompanyCity}}, OIB: {{comp.CompanyVATID}}
      </v-flex>
      <v-flex xs3>
          <h2>{{  headUp }} {{currency}}</h2>
      </v-flex>
      <v-flex xs 4>
        <p class="text-md-left">
            Datum izvještaja: {{PrintDate}}<br />
            Telefon: {{comp.CompanyPhones}}, email: {{comp.Companyemail}}<br />
            <v-row class="mt-1 ml-1">
              <v-flex xs3>
                <v-autocomplete
                label="Sort"
                :items="sortTypes"
                v-model="selectST"
                item-text="text"
                item-value="value"
                return-object
                @change="doSort(selectST)"
                clearable
                class="pr-2"></v-autocomplete>
              </v-flex>
              <v-flex xs3>
                 <v-btn @click="print" class="mr-2">Printaj</v-btn>
              </v-flex>
               <v-flex xs3>
                  <v-progress-circular v-if="this.preparePrint"
                    indeterminate
                    color="green"
                  ></v-progress-circular>
                 <v-btn @click="print2" class="mr-2">Print SVI DET.</v-btn>
              </v-flex>
              <v-flex xs3>
                <v-progress-circular v-if="this.preparePrint"
                    indeterminate
                    color="green"
                  ></v-progress-circular>
                <v-btn @click="doOpzstat()" class="mr-2" v-if="showOpzstat">Kreiraj OPZstat</v-btn>
              </v-flex>
            </v-row>
            
        </p>
      </v-flex>
    </v-row>
    <v-row style="border-top:solid 1px;">
      <v-flex xs6 class="text-xs-right">

      </v-flex>
      <v-flex xs1 class="text-right">
        {{ lang.Debit}} <span v-if="!showEUR">EUR</span><span v-if="showEUR">Kn</span>
      </v-flex>
      <v-flex xs1 class="text-right">
        {{ lang.Credit }} <span v-if="!showEUR">EUR</span><span v-if="showEUR">Kn</span>
      </v-flex>
      <v-flex xs1 class="text-right">
        {{ lang.CreditDebit }} <span v-if="!showEUR">EUR</span><span v-if="showEUR">Kn</span>
      </v-flex>
    </v-row>
    <v-row style="border-top:solid 1px;">
      <v-flex xs6 class="text-xs-right">

      </v-flex>
      <v-flex xs1 class="text-right">
        {{ formatPrice(Total1) }}
      </v-flex>
      <v-flex xs1 class="text-right">
        {{ formatPrice(Total2) }}
      </v-flex>
      <v-flex xs1 class="text-right">
        {{ formatPrice(Total5) }}
      </v-flex>
    </v-row>

    <v-card class="mt-3">
        <v-card-title>
          {{ lang.OpenStatements }}
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="pItems"
            item-key="id"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"            
          >

          <template v-slot:[`item.tDebit`]="{ item }">
              <span>{{formatPrice(item.tDebit) }}</span>
          </template>
          <template v-slot:[`item.tCredit`]="{ item }">
              <span>{{formatPrice(item.tCredit) }}</span>
          </template>
          <template v-slot:[`item.CreditDebit`]="{ item }">
              <span>{{formatPrice(parseFloat(item.CreditDebit)) }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>

      <MainLedgerPrintCard5 ref="prnt" :card="card" :head="mainledgerAna" />
      <MainLedgerPrintCard10 ref="prnt2"/>
  </v-container>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import {round, parseNum, dynamicSort, doZeros} from '@/snippets/allSnippets'
//import PartnerService from '@/services/PartnerService'
import MainLedgerPrintCard5 from './MainLedgerPrintCard5'
import MainLedgerPrintCard10 from './MainLedgerPrintCard10'
import MainLedgerCard3 from './MainLedgerCard3'
import MainLedgerService from '@/services/MainLedgerService'
import MainLedgerDetailService from '@/services/MainLedgerDetailService'
import PartnerService from '@/services/PartnerService'

import OpzstatHeadService from '@/services/OpzstatHeadService'
import {defOpzstathead, defNewOpzstathead} from '../Form/formhelper'
import OpzstatItemsService from '@/services/OpzstatItemsService'
import {defOpzstatitems, defNewOpzstatitems} from '../Form/formhelper'
import dayjs from 'dayjs'

export default {
  name: 'mainledgercard5',
  props: ['items', 'head', 'showEUR'],
  data () {
    return {
      headUp: '',
      headers: [ ],
      search: '',
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      comp: {},
      error: null,
      localError: false,
      newUser: {},
      size: 'sm',
      havePath: '',
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      fTotal1: 0,
      fTotal2: 0,
      fTotal3: 0,
      fTotal4: 0,
      fTotal5: 0,
      ffTotal1: 0,
      ffTotal2: 0,
      ffTotal3: 0,
      ffTotal4: 0,
      ffTotal5: 0,
      newItem: {},
      newItems: [],
      pItems: [],
      allItems: [],
      mainQuery2: {},
      partner: {},
      card: {},
      pCard: {},
      showCard3: false,
      mainledgerAna: {},
      dItems: [],
      partners: [],
      sortTypes: [
        {text: 'Partner',
        value: 1},
        {text: 'Duguje',
        value: 2},
        {text: 'Potražuje',
        value: 3},
        {text: 'Saldo',
        value: 4},
      ],
      selectST: {},
      printType: 1,
      localHead: {},
      exRate: 1,
      currency: 'EUR',
      preparePrint: false,
      showOpzstat: false
    }
  },
  components: {
    MainLedgerCard3,
    MainLedgerPrintCard5,
    MainLedgerPrintCard10
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    this.exRate = this.$store.state.defExRate ? this.$store.state.defExRate : 1
    if (!this.showEUR) {
      this.exRate = 1
    }
    this.currency = this.$store.state.businessYear >= 2023 ? 'EUR' : 'Kn'
    const lPartner = this.head.fromAccount ? this.head.fromAccount.substr(0,2) : ''
    this.showOpzstat = lPartner === '12' || lPartner === '16' ? true : false
   // if (this.$store.state.partnerStore) {
    this.mainledgerAna = JSON.parse(JSON.stringify(this.head))
    this.localHead = JSON.parse(JSON.stringify(this.head))
    // console.log('1 Card5 mount head', this.head)
    // console.log('2 Card5 mount ANA', this.mainledgerAna)

    const mainQuery = {}
    mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
    mainQuery.noPageing = 0
    const {data} = await PartnerService.index(mainQuery)
    if (data && data.partners && data.partners.rows.length > 0) {
      this.partners = data.partners.rows
    }
    const typePartner = this.head.fromAccount ? this.head.fromAccount.substr(0,2) : ''
    if (this.head.subtype && this.head.subtype === 5) {
      this.headUp = typePartner === '12' ? this.lang.OpenStateBuyers : this.lang.OpenStateSupp
    }
    if (this.head.subtype && this.head.subtype === 6) {
      this.headUp = typePartner === '12' ? this.lang.CardsBuyers : this.lang.CardsSupp
    }

    this.headers.push(
      {text: this.lang.detailId, value: 'itLine', width: '80px'},
      {text: this.lang.Account4, value: 'account4', width: '100px',sortable: true,},
      {text: this.lang.PartnerName, value: 'partnerName', width: '300px', sortable: true,},
      {text: this.lang.PartnerVATID, value: 'partnerVATID', width: '100px', sortable: true,},
      // {text: this.lang.Debit, value: 'totalDebit', align: 'right', sortable: true,},
      // {text: this.lang.Credit, value: 'totalCredit', align: 'right', sortable: true, },
      {text: this.lang.Debit + (!this.showEUR ? ' EUR' : ' Kn'), value: 'tDebit', align: 'right', sortable: true,},
      {text: this.lang.Credit + (!this.showEUR ? ' EUR' : ' Kn'), value: 'tCredit', align: 'right', sortable: true, },
      {text: this.lang.CreditDebit + (!this.showEUR ? ' EUR' : ' Kn'), value: 'CreditDebit', align: 'right', sortable: true,},
      {text: this.lang.id, value: 'id', width: '50px'},
      {text: this.lang.partnerId, value: 'partnerId', width: '50px'}
    )

    this.PrintDate = dayjs(new Date()).format('DD.MM.YYYY') 
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    
    this.Total1 = 0
    this.Total2 = 0
    this.Total3 = 0
    this.Total4 = 0
    this.Total5 = 0
    let itLine = 0
    // let partnerVATID = null

    if (this.items) {
      if (this.head.subtype && this.head.subtype === 5) {
        this.items.map(async(item) => {
          const newDebit = round((parseFloat(item.totalDebit) / this.exRate), 2)
          const newCredit = round((parseFloat(item.totalCredit) / this.exRate), 2)
          // const diff = this.round(parseFloat(item.totalDebit),2) - this.round(parseFloat(item.totalCredit), 2)
          const diff = newDebit - newCredit
          this.partner = {}
          if (diff !== 0) {
            // reduce det items to be shure what invoices are open and what are closed
            // only open push to pItems

            itLine += 1
            this.Total1 += newDebit
            this.Total2 += newCredit
            const par = this.partners.find(part => (part.partnerVATID === item.partnerVATID))

            const object2 = {
              itLine,
              // tDebit: parseFloat(item.totalDebit),
              // tCredit: parseFloat(item.totalCredit),
              // CreditDebit: parseFloat(item.totalDebit) - parseFloat(item.totalCredit),
              tDebit: newDebit,
              tCredit: newCredit,
              CreditDebit: diff,
              partner: (par ? par : {}),
              partnerName: (par && par.partnerName ? par.partnerName  : '')
            }

            const object3 = {...item, ...object2}
            this.pItems.push(object3)
          }
        })
      }
      if (this.head.subtype && this.head.subtype === 6) {
        this.items.map(async(item) => {
          this.partner = {}
          const newDebit = round((parseFloat(item.totalDebit) / this.exRate), 2)
          const newCredit = round((parseFloat(item.totalCredit) / this.exRate), 2)

          itLine += 1
          this.Total1 += newDebit
          this.Total2 += newCredit
          const par = this.partners.find(part => (part.partnerVATID === item.partnerVATID))

          const object2 = {
            itLine,
            tDebit: newDebit,
            tCredit: newCredit,
            CreditDebit: newDebit - newCredit,
            partner: (par ? par : {}),
            partnerName: (par && par.partnerName ? par.partnerName  : '')
          }

          const object3 = {...item, ...object2}
          this.pItems.push(object3)
          
        })
      }
      // const arr = [ { 'name': 'P1', 'value': 150 }, { 'name': 'P1', 'value': 150 }, { 'name': 'P2', 'value': 200 }, { 'name': 'P3', 'value': 450 } ];

      // const res = Array.from(arr.reduce(
      //     (m, {name, value}) => m.set(name, (m.get(name) || 0) + value), new Map
      //   ), ([name, value]) => ({name, value}));
        
      // console.log(res);
      

    }
    this.Total5 = this.Total1 - this.Total2
    this.card = {...this.head}
    this.card.items = this.pItems
    //this.card = {...this.card, ...this.lastOne}
    this.card.Total1 = this.Total1
    this.card.Total2 = this.Total2
    this.card.Total5 = this.Total5

  },
  computed: {

  },
  methods: {
    print(){
      this.$refs.prnt.print()
    },
    async print2(){
      this.pCard ={}
      this.preparePrint = true
      await this.itemTenX2()
      .then(res => {
        console.log('2', res)
        this.print3()
        this.preparePrint = false
      })
      .catch (err => {
        console.log(err)
        this.preparePrint = false
      })
      
    },
    print3 () {
      this.printType = 1
      this.$refs.prnt2.print(this.pCard)
    },
    async itemTenX2() {
      if (this.localHead) {
        this.mainledgerAna = JSON.parse(JSON.stringify(this.localHead))
      }
      if (this.mainledgerAna && this.mainledgerAna.partnerId) {
        delete this.mainledgerAna.partnerId
      }
      this.mainledgerAna.type = 4
      this.allItems.length = 0
      await MainLedgerDetailService.card(this.mainledgerAna)
      .then((res) => {
        if (res && res.data && res.data.documents && res.data.documents.length > 0) {
          this.allItems = [...res.data.documents]
          const result = this.itemsTen()
          console.log( result )
        }
      })
      .catch(err => {
        console.log('err ', err)
      })

    },
    // doZeros(invoiceNumber, BusinessYearInvoice ) {
    //   const zeroes = "0"
    //   const howMany = 10 - String(invoiceNumber).length
    //   const inNumber = String(BusinessYearInvoice) + zeroes.repeat(howMany) + String(invoiceNumber)
    //   return inNumber
    // },
    calculateSum(array, property) {
      const total = array.reduce((accumulator, object) => {
        return accumulator + object[property];
      }, 0);
      const total2 = Math.abs(total)
      return total2;
    },
    checkOccurrence (array, element) {
        let counter = 0;
        for (item of array.flat()) {
            if (item == element) {
                counter++;
            }
        };
        return counter
    },
    createCards () {

      // if (this.pItems && this.pItems.length > 0) {
      //   this.pItems.map(item => {

      //   })
      // }
    },
    itemsTen() {

     // console.log('zovem itemsTEN ')
      let side = 2
      // buyers
      if (String(this.mainledgerAna.fromAccount).substring(0, 2) === '12') {
        side = 1
      }
      const newCards = []
      let itLine = 0
      this.newItems.length = 0
      this.fTotal1 = 0
      this.fTotal2 = 0
      this.fTotal3 = 0
      this.fTotal4 = 0
      this.fTotal5 = 0

      // type 1 = balance sheet, 2 = partner card,, 3 = partner open, 4 = analysis, 5= all partners open
      this.mainledgerAna.type = 3
      console.log('1 this.pItems', this.pItems)
      console.log('2 this.head.subtype', this.head.subtype)

      if (this.pItems && this.head.subtype === 5) {
        let obj = {}
        const openOnly= []
       
        this.pItems.map((itemA) => {
          

          const diff = round(parseFloat(itemA.totalDebit),2) - round(parseFloat(itemA.totalCredit), 2)
          if (diff !== 0 && itemA.partnerVATID && itemA.partnerVATID.length > 0) {
            // this.fTotal1 += round(parseNum(itemA.totalDebit), 2)
            // this.fTotal2 += round(parseNum(itemA.totalCredit), 2)
            let haveCard = false
            const tempItems = []

            const subItems = this.allItems.filter(f => f.partnerVATID === itemA.partnerVATID)

            if (subItems && subItems.length > 0) {
                subItems.map(adY => {
                  const tIndx = subItems.indexOf(adY)
                  if (side === 1) {
                    subItems[tIndx].inOutNr = doZeros(adY.invoiceOutNumber, adY.BusinessYearInvoice)
                    subItems[tIndx].Connection = (adY.invoiceOutNumber)+ '/' + adY.BusinessYearInvoice
                  }
                  if (side === 2) {
                    subItems[tIndx].inInNr = doZeros(adY.invoiceInNumber, adY.BusinessYearInvoice)
                    subItems[tIndx].Connection = (adY.invoiceInNumber)+ '/' + adY.BusinessYearInvoice
                  }
                })
                
                
                let subLine = 0
                let subTotal1 = 0
                let subTotal2 = 0
                let resDebit = []
                let resCredit = []

                // if (side === 1) {
                //   resDebit = Array.from(subItems.reduce(
                //          (m, {invoiceOutNumber, Debit}) => m.set(invoiceOutNumber, (m.get(invoiceOutNumber) || 0) + Debit), new Map
                //        ), ([invoiceOutNumber, Debit]) => ({invoiceOutNumber, Debit}));

                //   resCredit = Array.from(subItems.reduce(
                //          (m, {invoiceOutNumber, Credit}) => m.set(invoiceOutNumber, (m.get(invoiceOutNumber) || 0) + Credit), new Map
                //        ), ([invoiceOutNumber, Credit]) => ({invoiceOutNumber, Credit}));
                // }
                // if (side === 2) {
                //   resDebit = Array.from(subItems.reduce(
                //          (m, {invoiceInNumber, Debit}) => m.set(invoiceInNumber, (m.get(invoiceInNumber) || 0) + Debit), new Map
                //        ), ([invoiceInNumber, Debit]) => ({invoiceInNumber, Debit}));

                //   resCredit = Array.from(subItems.reduce(
                //          (m, {invoiceInNumber, Credit}) => m.set(invoiceInNumber, (m.get(invoiceInNumber) || 0) + Credit), new Map
                //        ), ([invoiceInNumber, Credit]) => ({invoiceInNumber, Credit}));
                // }


                if (side === 1) {
                  resDebit = Array.from(subItems.reduce(
                         (m, {inOutNr, Debit}) => m.set(inOutNr, (m.get(inOutNr) || 0) + Debit), new Map
                       ), ([inOutNr, Debit]) => ({inOutNr, Debit}));

                  resCredit = Array.from(subItems.reduce(
                         (m, {inOutNr, Credit}) => m.set(inOutNr, (m.get(inOutNr) || 0) + Credit), new Map
                       ), ([inOutNr, Credit]) => ({inOutNr, Credit}));
                }
                if (side === 2) {
                  resDebit = Array.from(subItems.reduce(
                         (m, {inInNr, Debit}) => m.set(inInNr, (m.get(inInNr) || 0) + Debit), new Map
                       ), ([inInNr, Debit]) => ({inInNr, Debit}));

                  resCredit = Array.from(subItems.reduce(
                         (m, {inInNr, Credit}) => m.set(inInNr, (m.get(inInNr) || 0) + Credit), new Map
                       ), ([inInNr, Credit]) => ({inInNr, Credit}));
                }

                subItems.map(sitem => {
                  let dInTotal = {}
                  let cInTotal = {}
                  if (side === 1) {
                    dInTotal = resDebit && resDebit.length > 0 ? resDebit.find(itm => itm.inOutNr === sitem.inOutNr) : 0
                    cInTotal = resCredit && resCredit.length > 0 ? resCredit.find(itm => itm.inOutNr === sitem.inOutNr) : 0
                  }
                  if (side === 2) {
                    dInTotal = resDebit && resDebit.length > 0 ? resDebit.find(itm => itm.inInNr === sitem.inInNr) : 0
                    cInTotal = resCredit && resCredit.length > 0 ? resCredit.find(itm => itm.inInNr === sitem.inInNr) : 0
                  }

                  if (round((dInTotal && dInTotal.Debit ? round(dInTotal.Debit,2) : 0) - (cInTotal && cInTotal.Credit ? round(cInTotal.Credit,2): 0),2) !== 0) {
                    subLine += 1
                    subTotal1 += round(dInTotal.Debit, 2)
                    subTotal2 += round(cInTotal.Credit, 2)
                    this.fTotal1 += round(dInTotal.Debit, 2)
                    this.fTotal2 += round(cInTotal.Credit, 2)
                    

                    let itInvoice
                    if (sitem.BookName.substring(0,3) === 'IRA' || sitem.BookName.substring(0,3) === 'URA') {
                      itInvoice = parseInt(sitem.invoiceInNumber) > 0 ? parseInt(sitem.invoiceInNumber) : parseInt(sitem.invoiceOutNumber)
                    }
                    if (sitem.BookName.substring(0,2) === 'PS') {
                      itInvoice = parseInt(sitem.invoiceInNumber) > 0 ? parseInt(sitem.invoiceInNumber) : parseInt(sitem.invoiceOutNumber)
                    }
                    if (sitem.BookName.substring(0,3) === 'TEM') {
                      itInvoice = parseInt(sitem.BookID)
                    }
                    if (sitem.BookName.substring(0,2) === 'BS') {
                      itInvoice = parseInt(sitem.BookID)
                    }

                    const bookName = sitem.BookName === 'BS' ? 'Izv.' : sitem.BookName
                    const object2 = {
                      itBookName: subLine + '. ' + bookName,
                      CreditDebit: subTotal1 - subTotal2,
                      itInvoice: itInvoice,
                      subLine: subLine,
                    }

                    haveCard = true
                    const object3 = {...sitem, ...object2}
                    tempItems.push(object3)

                  }
                })

                if (haveCard) {

                  let subTotal5 = subTotal1 - subTotal2
                  itLine += 1
                  const card = {}
                  card.id = itLine
                  card.partnerVATID = itemA.partnerVATID
                  const havePartner = itemA.partnerVATID ? this.partners.find(part => (part.partnerVATID === itemA.partnerVATID)) : {}
                  card.partnerName = havePartner && havePartner.partnerName ? havePartner.partnerName : ''
                  card.partner = havePartner
                  card.detItems = tempItems
                  

                  card.Total1 = subTotal1
                  card.Total2 = subTotal2
                  card.Total5 = subTotal5
                  newCards.push(card)
                }
          
            }

            // const arr = [ { 'name': 'P1', 'value': 150 }, { 'name': 'P1', 'value': 150 }, { 'name': 'P2', 'value': 200 }, { 'name': 'P3', 'value': 450 } ];

            // const res = Array.from(arr.reduce(
            //     (m, {name, value}) => m.set(name, (m.get(name) || 0) + value), new Map
            //   ), ([name, value]) => ({name, value}));
              
            // console.log(res);

            // create sum debit && credit by invoiceOutNumber

            
            // if buyers
            // if (side === 1) {
              


            //     subItems.forEach((item)=> {
            //      // if not null is for invoiceNR - do counting
                   
            //        if (item.invoiceOutNumber && item.invoiceOutNumber !== 0) {
            //         const res = Array.from(arr.reduce(
            //              (m, {name, value}) => m.set(name, (m.get(name) || 0) + value), new Map
            //            ), ([name, value]) => ({name, value}));
            //         this.calculateSum(item.invoiceOutNumber, item.Debit)
            //         const inNumber = this.doZeros(item.invoiceOutNumber, item.BusinessYearInvoice)

            //         if(obj[inNumber]){
            //           obj[inNumber].Credit = obj[inNumber].Credit + item.Credit
            //           obj[inNumber].Debit = obj[inNumber].Debit + item.Debit
            //         } else {
            //           obj[inNumber] = item
            //         }
     
            //        }
            //     })

            //     let valuesArr = Object.values(obj)
            //     if (valuesArr && valuesArr.length >0) {
            //       valuesArr.map(vitem => {
            //         const diffOpen = round((round(parseFloat(vitem.Debit),2) - round(parseFloat(vitem.Credit), 2)), 2)
            //         if (diffOpen !== 0 ){
            //           const newObj = {}
            //           newObj.partnerVATID = vitem.partnerVATID
            //           newObj.invoiceNR = vitem.invoiceOutNumber
            //           newObj.diffOpen = diffOpen
            //           newObj.condition = this.doZeros(vitem.invoiceOutNumber, vitem.BusinessYearInvoice)
            //           openOnly.push(newObj)
            //         }
            //       })
            //     }
            // }
            // if suppliers
            // if (side === 2) {
            //     subItems.forEach((item)=> {
            //      // if not null is for invoiceNR - do counting
                   
            //        if (item.invoiceInNumber && item.invoiceInNumber !== 0) {
            //         const inNumber = this.doZeros(item.invoiceInNumber, item.BusinessYearInvoice)

            //         if(obj[inNumber]){
            //           obj[inNumber].Credit = obj[inNumber].Credit + item.Credit
            //           obj[inNumber].Debit = obj[inNumber].Debit + item.Debit
            //         } else {
            //           obj[inNumber] = item
            //         }
     
            //        }
            //     })

            //     let valuesArr = Object.values(obj)
            //     if (valuesArr && valuesArr.length >0) {
            //       valuesArr.map(vitem => {
            //         const diffOpen = round((round(parseFloat(vitem.Debit),2) - round(parseFloat(vitem.Credit), 2)), 2)
            //         if (diffOpen !== 0 ){
            //           const newObj = {}
            //           newObj.partnerVATID = vitem.partnerVATID
            //           newObj.invoiceNR = vitem.invoiceInNumber
            //           newObj.diffOpen = diffOpen
            //           newObj.condition = this.doZeros(vitem.invoiceInNumber, vitem.BusinessYearInvoice)
            //           openOnly.push(newObj)
            //         }
            //       })
            //     }
            // }

            // --------------------------------------------------------------------------------------------

            
            // let subTotal1 = 0
            // let subTotal2 = 0
            // let subTotal5 = 0
            let subLine = 0
            // if (subItems) {
            //   subItems.sort((a,b) => (a.BookingDate > b.BookingDate) ? 1 : ((b.BookingDate > a.BookingDate) ? -1 : 0))
            //   subItems.map(subitem => {
            //       let putOnCard = false

            //       if (side === 1) {
            //         if (!subitem.invoiceOutNumber) {
            //           putOnCard = true
            //         }

            //         if (subitem.invoiceOutNumber && subitem.invoiceOutNumber !== 0) {
            //           const condition = this.doZeros(subitem.invoiceOutNumber, subitem.BusinessYearInvoice)
            //           const isItClean = openOnly.find(i => i.condition === condition)
                      
            //           if (isItClean && Object.keys(isItClean).length !== 0) {
            //             putOnCard = true
            //             const index = openOnly.indexOf(isItClean)
            //             if (index > -1) { 
            //               openOnly.splice(index, 1)
            //             }
            //           }
            //         }
            //       }

            //       if (side === 2) {
            //         if (!subitem.invoiceInNumber) {
            //           putOnCard = true
            //         }

            //         if (subitem.invoiceInNumber && subitem.invoiceInNumber !== 0) {
            //           const condition = this.doZeros(subitem.invoiceInNumber, subitem.BusinessYearInvoice)
            //           const isItClean = openOnly.find(i => i.condition === condition)
                      
            //           if (isItClean && Object.keys(isItClean).length !== 0) {
            //             putOnCard = true
            //             const index = openOnly.indexOf(isItClean)
            //             if (index > -1) { 
            //               openOnly.splice(index, 1)
            //             }
            //           }
            //         }
            //       }
                  
            //       if (putOnCard) {

            //         subLine += 1
            //         const pDebit = (subitem.totalDebit ? parseFloat(subitem.totalDebit) : subitem.Debit)
            //         const pCredit = (subitem.totalCredit ? parseFloat(subitem.totalCredit) : subitem.Credit)

            //         subTotal1 += pDebit
            //         subTotal2 += pCredit

            //         let itInvoice
            //         if (subitem.BookName.substring(0,3) === 'IRA' || subitem.BookName.substring(0,3) === 'URA') {
            //           itInvoice = parseInt(subitem.invoiceInNumber) > 0 ? parseInt(subitem.invoiceInNumber) : parseInt(subitem.invoiceOutNumber)
            //         }
            //         if (subitem.BookName.substring(0,2) === 'PS') {
            //           itInvoice = parseInt(subitem.invoiceInNumber) > 0 ? parseInt(subitem.invoiceInNumber) : parseInt(subitem.invoiceOutNumber)
            //         }
            //         if (subitem.BookName.substring(0,3) === 'TEM') {
            //           itInvoice = parseInt(subitem.BookID)
            //         }
            //         if (subitem.BookName.substring(0,2) === 'BS') {
            //           itInvoice = parseInt(subitem.BookID)
            //         }

            //         const bookName = subitem.BookName !== 'BS' ? subitem.BookName : 'Izv.'
            //         const object2 = {
            //           itBookName: subLine + '. ' + bookName,
            //           CreditDebit: subTotal1 - subTotal2,
            //           itInvoice: itInvoice,
            //           subLine: subLine,
            //         }
            //         const object3 = {...subitem, ...object2}
            //         tempItems.push(object3)
            //     }
            //   }) 
            // }
            // --------------------------------------------------------------------
            


          }
          
        })

        this.fTotal5 =  round(this.fTotal1 - this.fTotal2 , 2)

        this.ffTotal1 = this.fTotal1 ? this.fTotal1 : 0
        this.ffTotal2 = this.fTotal2 ? this.fTotal2 : 0
        this.ffTotal5 = this.fTotal5 ? this.fTotal5 : 0
        this.pCard = {...this.head}
        this.pCard.Total1 = this.Total1 ? this.Total1 : 0
        this.pCard.Total2 = this.Total2 ? this.Total2 : 0
        this.pCard.Total5 = this.Total5 ? this.Total5 : 0
        this.pCard.items = newCards
        this.pCard.currency = this.currency
        return newCards

      }
      if (this.pItems && this.head.subtype === 6) {
        this.pItems.map((itemA) => {
          if (itemA.partnerVATID && itemA.partnerVATID.length > 0) {
            this.fTotal1 += round(parseNum(itemA.totalDebit), 2)
            this.fTotal2 += round(parseNum(itemA.totalCredit), 2)

            const subItems = this.allItems.filter(f => f.partnerVATID === itemA.partnerVATID)
            let tempItems = []
            let subTotal1 = 0
            let subTotal2 = 0
            let subTotal5 = 0
            let subLine = 0
            if (subItems) {
            subItems.sort((a,b) => (a.BookingDate > b.BookingDate) ? 1 : ((b.BookingDate > a.BookingDate) ? -1 : 0))
            subItems.map(subitem => {
              subLine += 1
              const pDebit = (subitem.totalDebit ? parseFloat(subitem.totalDebit) : subitem.Debit)
              const pCredit = (subitem.totalCredit ? parseFloat(subitem.totalCredit) : subitem.Credit)
              subTotal1 += pDebit
              subTotal2 += pCredit

              let itInvoice
              if (subitem.BookName.substring(0,3) === 'IRA' || subitem.BookName.substring(0,3) === 'URA') {
                itInvoice = parseInt(subitem.invoiceInNumber) > 0 ? parseInt(subitem.invoiceInNumber) : parseInt(subitem.invoiceOutNumber)
              }
              if (subitem.BookName.substring(0,2) === 'PS') {
                itInvoice = parseInt(subitem.invoiceInNumber) > 0 ? parseInt(subitem.invoiceInNumber) : parseInt(subitem.invoiceOutNumber)
              }
              if (subitem.BookName.substring(0,3) === 'TEM') {
                itInvoice = parseInt(subitem.BookID)
              }
              if (subitem.BookName.substring(0,2) === 'BS') {
                itInvoice = parseInt(subitem.BookID)
              }

              const bookName = subitem.BookName !== 'BS' ? subitem.BookName : 'Izv.'

              const object2 = {
                itBookName: subLine + '. ' + bookName,
                CreditDebit: subTotal1 - subTotal2,
                itInvoice: itInvoice,
                subLine: subLine,
              }
              const object3 = {...subitem, ...object2}
              tempItems.push(object3)

            })
          }
          
          subTotal5 = subTotal1 - subTotal2
          itLine += 1
          const card = {}
          card.id = itLine
          card.partnerVATID = itemA.partnerVATID
          const havePartner = itemA.partnerVATID ? this.partners.find(part => (part.partnerVATID === itemA.partnerVATID)) : {}
          card.partnerName = havePartner && havePartner.partnerName ? havePartner.partnerName : ''
          card.partner = havePartner
          card.detItems = tempItems
          card.Total1 = subTotal1
          card.Total2 = subTotal2
          card.Total5 = subTotal5
          newCards.push(card)
            
          }
          
        })
        this.fTotal5 =  round(this.fTotal1 - this.fTotal2 , 2)

        this.ffTotal1 = this.fTotal1 ? this.fTotal1 : 0
        this.ffTotal2 = this.fTotal2 ? this.fTotal2 : 0
        this.ffTotal5 = this.fTotal5 ? this.fTotal5 : 0
        this.pCard = {...this.head}
        this.pCard.items = newCards
        // console.log('GOTOV itemsTEN newCards',  newCards)
        return newCards
      }
    },
    async doOpzstat() {
      const mainQuery = {}
      mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
      mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2023
      mainQuery.MLType = 2
      mainQuery.BookTypeID = 10

      const books = (await MainLedgerService.books(mainQuery)).data.documents
      this.preparePrint = true
      await this.itemTenX2()
      
      const newItems = []
      
      if (this.pCard && this.pCard.items) {
        let newHead = false
        let head = defOpzstathead()
        head = {...head, ...defNewOpzstathead()}
        const {data} = await OpzstatHeadService.post(head)
        if (data && data.newOpzstatHead) {
          newHead = true
          await this.$store.dispatch('setDocumentid', data.newOpzstatHead.id)
        }
        let newHeadOrder = 0
        this.pCard.items.map(item => {
          newHeadOrder += 1

          if (item.detItems && newHead) {

            let R8 = 0
            let R9 = 0

            item.detItems.map(tot => {
              R8 += parseNum(tot.totalDebit)
              R9 += parseNum(tot.totalCredit)

            })

            let statOrder = 0
            item.detItems.map(subitem => {

              //const useIt = parseNum(subitem.totalDebit) - parseNum(subitem.totalCredit)
              //if (useIt > 0) { 
              let statItem   = defOpzstatitems()
              statItem  = {...statItem , ...defNewOpzstatitems()}
              const bookOne = books.find(book => book.id === subitem.invoiceOutID)
              const totalVAT = (bookOne && bookOne.VAT1Amount ? parseNum(bookOne.VAT1Amount) : 0)+ (bookOne && bookOne.VAT2Amount ? parseNum(bookOne.VAT2Amount) : 0) + (bookOne && bookOne.VAT3Amount ? parseNum(bookOne.VAT3Amount) : 0) + (bookOne && bookOne.VAT4Amount ? parseNum(bookOne.VAT4Amount) : 0)

              statOrder += 1
              statItem.K1 = newHeadOrder
              statItem.K2 = 1
              statItem.K3 = item.partnerVATID ? item.partnerVATID : ''
              statItem.K4 = item.partner && item.partner.partnerName ? item.partner.partnerName : ''
              statItem.K5 = 0
              statItem.K6 = 0
              statItem.K7 = R8
              statItem.K8 = R9
              statItem.K9 = R8 - R9

              statItem.R1 = statOrder
              statItem.R2 = subitem.InvoiceNumber ? subitem.InvoiceNumber : '1/1/1'
              statItem.R3 = subitem.BookingDate
              statItem.R4 = subitem.InvoicePaymentDeadlineDate
              statItem.R5 = 0
              statItem.R6 = parseNum(subitem.Debit) - totalVAT
              statItem.R7 = totalVAT
              statItem.R8 = parseNum(subitem.Debit)
              statItem.R9 = parseNum(subitem.Credit)
              statItem.R10 = parseNum(subitem.Debit) - parseNum(subitem.Credit)

              newItems.push(statItem)
              //}
            })

          }
        })

        if (newItems && newItems.length > 0) {
         await OpzstatItemsService.saveitems(newItems)
        }
        alert('OK! Opz stat obrazac je kreiran.')
      }
      this.preparePrint = false
    },
    doSort(typeSort) {

      if (typeSort && typeSort.value) {
        if (typeSort.value === 1) {
          this.pItems.sort(dynamicSort("partnerName"))
        }
        if (typeSort.value === 2) {
          this.pItems.sort(dynamicSort("-tDebit"))
        }
        if (typeSort.value === 3) {
          this.pItems.sort(dynamicSort("-tCredit"))
        }
        if (typeSort.value === 4) {
          this.pItems.sort(dynamicSort("-CreditDebit"))
        }
      }
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    close () {
      this.show = false
    },
    async navigateTo (params) {
      try {
        this.showCard3 = false

        // if (this.$store.state.searchState) {
        //   this.mainledgerAna = this.$store.state.searchState
        // }
        if (this.localHead) {
          this.mainledgerAna = JSON.parse(JSON.stringify(this.localHead))
        }

        if (this.mainledgerAna.partnerId) {
          delete this.mainledgerAna.partnerId
        }
        if (this.mainledgerAna.type) {
          delete this.mainledgerAna.type
        }
        if (this.mainledgerAna.subtype) {
          delete this.mainledgerAna.subtype
        }

        // type 1 = balance sheet, 2 = partner card,, 3 = partner open, 4 = analysis, 5= all partners open
        if (this.head.subtype && this.head.subtype === 5) {
          this.mainledgerAna.type = 3
        }
        if (this.head.subtype && this.head.subtype === 6) {
          this.mainledgerAna.type = 2
        }
        if (params.partnerId) {
          this.mainledgerAna.partnerId = params.partnerId
        }
        if (params.partnerVATID) {
          this.mainledgerAna.partnerVATID = params.partnerVATID
        }

        //this.$store.dispatch('setSearchState', this.mainledgerAna)
        this.dItems = []
        this.saving = true
        await MainLedgerDetailService.card(this.mainledgerAna)
        .then(res => {
          this.dItems = res.data.documents ? res.data.documents : []
          
          if (this.dItems) {
            this.showCard3 = true
          }
        })
        .catch(err => {
           this.error = 'Error  ' + err
        })
        this.saving = false
    } catch (error) {
        // eslint-disable-next-line
        console.log(' Error 2?? ', error)
        this.error = 'Greska u konekciji na bazu'
    }
  }
 
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
