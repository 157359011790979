<template>
    <v-container fluid grid-list-md>
    <MainBookCompHeader />
    </v-container>
</template>

<script>


import MainBookCompHeader from './MainBookCompHeader'
import { testLastUpdate } from '@/services/TestLastUpdate'

export default {
  name: 'mainbookcomp',
  data () {
    return {
      lang: {},
      langC: {}
    }
  },
  async mounted () {
    await this.$store.dispatch('seteditItem', 0)
    await testLastUpdate()
  },
  methods: {

  },
  computed: {
  },
  watch: {

  },
  components: {
    MainBookCompHeader
  }
}
</script>

<style scoped>

</style>
