<template>
  <v-container fluid >
    <v-row>
      <v-col cols="12" sm="12" xs="12" md="6"  lg="6" xl="6">
        <h2>{{lang.WorkHours}}</h2>
      </v-col>
       <v-progress-circular
        v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
      <v-col cols="12" sm="6" xs="6" md="1" lg="1" xl="1">
          <v-select
              label="Mjesec"
              :items="locMonths"
              v-model="monthSelected"
              single-line
              persistent-hint
              clearable
              dense
            ></v-select>
        </v-col>
        <v-col cols="12" sm="6" xs="6" md="1" lg="1" xl="1">
            <v-select
              label="Godina"
              :items="locYears"
              v-model="yearSelected"
              value="bookType.value"
              single-line
              return-object
              persistent-hint
              clearable
              dense
            ></v-select>
      </v-col>
      <v-col cols="12" sm="3" xs="6" md="3" lg="3" xl="3">
        <v-btn
            class="primary"
            @click="fillMonth">
            {{lang.Create}}
          </v-btn>
          <v-btn
            color="red"
            text
            @click="deleteMonth">
            {{lang.DeleteMonth}}
          </v-btn>
      </v-col>
      <v-col cols="12" sm="3" xs="6" md="1" lg="1" xl="1" class="text-right">  

      </v-col>
    </v-row>
    <v-row no-gutters class="pa-0">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <WhList :monthSelected="monthSelected" :yearSelected="yearSelected" :key="refresh"/>
      </v-col>
    </v-row>    
  </v-container>
</template>

<script>
import WhList from './WHlist'
import langEn from './workhourDescEn'
import langHr from './workhourDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import {months, years} from '@/snippets/allSnippets.js'
import {defWorkHour, defNeWWorkHour} from './workhourDef'
import EmployeeService from '@/services/EmployeeService'
import WorkHourService from '@/services/WorkHourService'
import CalendarService from '@/services/CalendarService'

export default {
  name: 'workhour',
  data () {
    return {
      msg: '',
      lang: {},
      langC: {},
      locMonths: [],
      locYears: [],
      monthSelected: null,
      yearSelected: null,
      empSelected: null,
      emplys: [],
      newWH: {},
      newWorkHours: [],
      mainQuery: {},
      days:[],
      refresh: 0,
      saving: false,
      postNew: false
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    this.locMonths = months()
    this.locYears = years()
    const nD = new Date()
    this.monthSelected = nD.getMonth() +1
    this.yearSelected = nD.getFullYear()
    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    if (this.$store.state.businessYear) {
      this.mainQuery.businessYear = this.$store.state.businessYear
    }
  },
  methods: {
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
    async fillMonth() {
      const warningMessage = this.monthSelected + '/' + this.yearSelected
      if (confirm(this.langC.AreYouShureToCreate + ' ' + warningMessage + '?')) {
        this.days = []
        this.mainQuery.ForMonth = this.monthSelected
        this.mainQuery.ForYear = this.yearSelected
        const {data} = await CalendarService.index2(this.mainQuery)
        if (data && data.calendars !== undefined) {
          this.days = data.calendars
        }
        await EmployeeService.index(this.mainQuery)
        .then(res => {
          if (res.data && res.data.employees) {
            console.log('1', res.data.employees)
            this.saving = true
            this.emplys = res.data.employees
            if (this.emplys && this.emplys.length > 0) {
              this.emplys.map(async(emp) => {
                const empA = {...defWorkHour(), ...defNeWWorkHour()}
                empA.EmployeeId = emp.id ? emp.id : ''
                this.empSelected = emp.id ? emp.id : ''
                empA.email = emp.eemail ? emp.eemail : ''
                empA.First = emp.First ? emp.First : ''
                empA.Last = emp.Last ? emp.Last : ''
                const dayWork = emp.WeeklyWorkHours ? parseInt(emp.WeeklyWorkHours) / 5 : 0
                // if is activ then enter it
                if (emp.Activ) {
                // check if already have worksheet skip, otherwise add
                this.postNew = false
                await this.checkEmployee()
                 console.log('Post new ? ', this.postNew, this.days)
                if (this.postNew === true) {
                    this.newWorkHours = []
                    this.days.map(day => {
                      const newOb = {}
                      newOb.ForWeek = day.CalWeek ? parseInt(day.CalWeek) : 0
                      newOb.ForMonth = this.monthSelected
                      newOb.ForYear = this.yearSelected
                      newOb.WDate = day.CalDate ? day.CalDate : null
                      newOb.WDay = day.CalDescription ? day.CalDescription : ''
                      
                      if (day.CalWorkDay === 'W') {
                        newOb.PocetakRada = 7.30
                        newOb.ZavrsetakRada = 15.30
                        newOb.RedovanRad = dayWork
                        newOb.Ukupno = dayWork
                      }
                      if (day.CalWorkDay === 'H') {
                        newOb.PocetakRada = null
                        newOb.ZavrsetakRada = null
                        newOb.RedovanRad = 0
                        newOb.Ukupno = 0
                      }
                      if (day.CalWorkDay === 'P') {
                        newOb.PocetakRada = null
                        newOb.ZavrsetakRada = null
                        newOb.RedovanRad = 0
                        newOb.Ukupno = 0
                      }
                      this.newWH = {...empA, ...newOb}
                      this.newWorkHours.push(this.newWH)
                    })
                     console.log('this.newWorkHours ', this.newWorkHours)
                    const {data} = await WorkHourService.saveitems(this.newWorkHours)
                    // eslint-disable-next-line
                    console.log('data', data)
                  }
                }
              })
            }
            this.saving = false
          }
        })
        .catch (err => {
          // eslint-disable-next-line
          console.log(err)
        })
        this.refresh = this.getRndInteger(1000000000, 9000000000)
      }
    },
    async checkEmployee() {
        this.postNew = false
        const checkEmp = {
          EmployeeId: this.empSelected,
          ForMonth: this.monthSelected,
          ForYear: this.yearSelected,
          CompanyId : this.$store.state.companyid ? this.$store.state.companyid : ''
        }
        await WorkHourService.empmonth(checkEmp)
        .then(res => {
          if (res.data && res.data.workhours !== undefined && res.data.workhours.length > 0){
            this.postNew = false
          } else {
            this.postNew = true
          }
        }).catch(err => {
          this.postNew = true
          // eslint-disable-next-line
          console.log('Check  emp post NEW', err)
        })
    },
    async deleteMonth() {
      const warningMessage = this.monthSelected + '/' + this.yearSelected
      if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!')) {
        this.mainQuery.ForMonth = this.monthSelected
        this.mainQuery.ForYear = this.yearSelected
        const {data} = await WorkHourService.deleteitems(this.mainQuery)
        if (data) {
          // eslint-disable-next-line
          console.log('Obrisano  ', data)
        }
        this.refresh = this.getRndInteger(1000000000, 9000000000)
      }
    }
  },
  computed: {

  },
  watch: {
    'monthSelected' () {
      this.refresh = this.getRndInteger(1000000000, 9000000000)
    },
    'yearSelected' () {
      this.refresh = this.getRndInteger(1000000000, 9000000000)
    }
  },
  components: {
    WhList
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
