<template>
  <div>

  </div>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'
// import { parseNum } from '@/snippets/allSnippets'

export default {
  name: 'documentprintsaleslistwo',
  props: [],
  data () {
    return {
      msg: '',
      documentName: '',
      documentName2: '',
      document: {},
      havePath: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locDocument: {},
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      comp: {},
      mainQuery: {},
      ana: {}
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = {...langEn}
      this.langC = {...commEn}
    }
    if (this.$store.state.language === 'HR') {
      this.lang = {...langHr}
      this.langC = {...commHr}
    }

  },
  methods: {
    async print () {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.BusinessYear = this.$store.state.businessYear
      }
      this.printDoc()
    },

    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    printDoc () {
      this.comp = this.$store.state.companyActiv
      this.ana = this.$store.state.printObj ? this.$store.state.printObj : {}

      this.initDocument()
      this.addHead()
      this.addLine()
        if (this.ana.type === 1 || this.ana.type === 2){
        this.addItemsHeader()
        this.addLine()
        this.addItems()
        this.addLine()
        this.addTotal()
      }
      if (this.ana.type === 10){ 
        this.addItemsHeader10()
        this.addLine()
        this.addItems10()
        this.addLine()
        this.addTotal10()
      }
     // this.addComment()      
      const noSignature = this.comp.showFooter1 ? true : false
      if (!noSignature) {
         this.addSignature()
      }
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'landscape'
      this.docDefinition.pageMargins = [ 20, 20, 20, 40 ]
      this.content = []
    },
  
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addEmptyRow1 () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: '.' + ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ' , fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 780,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addGroupLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 50,
                y1: 0,
                x2: 780,
                y2: 0,
                dash: {length: 3, space: 2},
                lineWidth: 0.5
              }
            ]
          }
      this.content.push(docPart)
    },

    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '55%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 18, bold: true },
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.Companyemail ? 'email:' +  this.comp.Companyemail + ' \n' : ' ') , fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHead() {
      const docPart = {
          columns: [
            {
              // % width
              width: '45%',
              text: [
                { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.Companyemail ? 'email:' +  this.comp.Companyemail + ' \n' : ' ') , fontSize: 8 },
                { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '55%',
              text: [
                { text: this.ana.head1 + ' \n', fontSize: 18, bold: true },
                { text: this.ana.head2 + ' \n', fontSize: 8, bold: true },
                { text: this.ana.head3 + ' \n', fontSize: 8, bold: true },
                { text: this.ana.head4 + ' \n', fontSize: 8, bold: true },
                { text: this.ana.head5 + ' \n', fontSize: 8, bold: true },
              ]
            },
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },
    addItemsHeader () {
      const docPart = {
          columns: [
            {
              width: '5%',
              text: { text: this.lang.DocPrintOrder + '\n', fontSize: 8 }
            },
            {
              width: '9%',
              text: { text: this.lang.ItemID + '\n', fontSize: 8, alignment: 'left' }
            },
            {
              width: '18%',
              text: { text: this.lang.ItemName + '\n', fontSize: 8, alignment: 'left' }
            },            
            {
              width: '7%',
              text: { text: this.lang.ItemUnit + '\n', fontSize: 8, alignment: 'right' }
            },
            {
              width: '7%',
              text: {text: this.lang.PcsHome+ '\n', fontSize: 8, alignment: 'right' }
            },
            {
              width: '7%',
              text: {text: this.lang.PcsExport+ '\n', fontSize: 8, alignment: 'right' }
            },
            {
              width: '7%',
              text: {text: this.lang.SuppAmountHome+ '\n', fontSize: 8, alignment: 'right' }
            },
            {
              width: '7%',
              text: {text: this.lang.NetoSalesAmountHome+ '\n', fontSize: 8, alignment: 'right' }
            },
            {
              width: '7%',
              text: { text: this.lang.SuppAmountExport + '\n', fontSize: 8, alignment: 'right' }
            },
            {
              width: '7%',
              text: { text: this.lang.NetoSalesAmountExport + '\n', fontSize: 8, alignment: 'right' }
            },
            {
              width: '7%',
              text: { text: this.lang.suppPrice + '\n', fontSize: 8, alignment: 'right' }
            },
            {
              width: '7%',
              text: { text: this.lang.ruc + '\n', fontSize: 8, alignment: 'right' }
            },
          ]
        }
      this.content.push(docPart)
    },
    addItemsHeader10 () {
      const docPart = {
          columns: [
            {
              width: '5%',
              text: this.lang.documentId + '\n', fontSize: 8 
            },
            {
              width: '10%',
              text: this.lang.documentDate1 + '\n', fontSize: 8 
            },
            {
              width: '25%',
              text: this.lang.partnerName+ '\n', fontSize: 8, alignment: 'left' 
            },
            {
              width: '10%',
              text: this.langC.UserId + '\n', fontSize: 8 
            },
            {
              width: '9%',
              text: 'Trošak materijala:\n', fontSize: 8, alignment: 'left' 
            },
            {
              width: '9%',
              text: 'Trošak radnika:\n', fontSize: 8, alignment: 'right' 
            },
            {
              width: '9%',
              text: 'Ostali troškovi:\n', fontSize: 8, alignment: 'right' 
            },
            {
              width: '9%',
              text: 'Dobit/Gubitak:\n', fontSize: 8, alignment: 'right' 
            },
            {
              width: '9%',
              text: 'Proizvedeno:\n', fontSize: 8, alignment: 'center' 
            },
          ]
        }
      this.content.push(docPart)
    },
    addItems() {
          this.order = 0
            if (this.ana.items) {
              this.ana.items.map(item => {
                this.order += 1
                if (item.grFirst) {
                  const docPart1 = {
                  columns: [
                      {
                        width: '5%',
                        fontSize: 8,
                        alignment: 'left',
                        text: ''
                      },
                      {
                        width: '29%',
                        fontSize: 12,
                        alignment: 'left',
                        text: '\n' + (item.itemGroupName ? item.itemGroupName : ''),
                        bold:true
                      },
                      
                    ]
                  }
                  this.content.push(docPart1)
                  this.addGroupLine()
                }
                if (item.groupEnd) {
                  this.addGroupLine()
                  const docPartG = {
                  columns: [
                      {
                        width: '5%',
                        fontSize: 8,
                        alignment: 'left',
                        text: item.prNr,
                        bold:true
                      },
                      {
                        width: '9%',
                        fontSize: 8,
                        alignment: 'left',
                        text: (item.itemID ? item.itemID : ''),
                        bold:true
                      },
                      {
                        width: '18%',
                        fontSize: 8,
                        alignment: 'left',
                        text: item.itemName,
                        bold:true
                      },
                      
                      {
                        width: '7%',
                        fontSize: 8,
                        alignment: 'right',
                        text: item.itemUnit,
                        bold:true
                      },
                      {
                        width: '7%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.pscHome),
                        bold:true
                      },
                      {
                        width: '7%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.pscExport),
                        bold:true
                      },
                      {
                        width: '7%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.itemSuppBasePriceAmountHome),
                        bold:true
                      },
                      {
                        width: '7%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.itemBaseAmountNoVATHome),
                        bold:true
                      },
                      {
                        width: '7%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.itemSuppBasePriceAmountExport),
                        bold:true
                      },
                      {
                        width: '7%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.itemBaseAmountNoVATExport),
                        bold:true
                      },
                      {
                        width: '7%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.suppPrice),
                        bold:true
                      },
                      {
                        width: '7%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.ruc),
                        bold:true
                      }
                    ]
                  }
                  this.content.push(docPartG)
                  this.addEmptyRow()
                }
                if (!item.groupEnd) {
                  const docPart = {
                  columns: [
                      {
                        width: '5%',
                        fontSize: 8,
                        alignment: 'left',
                        text: item.prNr
                      },
                      {
                        width: '9%',
                        fontSize: 8,
                        alignment: 'left',
                        text: (item.itemID ? item.itemID : '')
                      },
                      {
                        width: '18%',
                        fontSize: 8,
                        alignment: 'left',
                        text: item.itemName 
                      },
                      
                      {
                        width: '7%',
                        fontSize: 8,
                        alignment: 'right',
                        text: item.itemUnit
                      },
                      {
                        width: '7%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.pscHome)
                      },
                      {
                        width: '7%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.pscExport)
                      },
                      {
                        width: '7%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.itemSuppBasePriceAmountHome)
                      },
                      {
                        width: '7%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.itemBaseAmountNoVATHome)
                      },
                      {
                        width: '7%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.itemSuppBasePriceAmountExport)
                      },
                      {
                        width: '7%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.itemBaseAmountNoVATExport)
                      },
                      {
                        width: '7%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.suppPrice)
                      },
                      {
                        width: '7%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.ruc)
                      }
                    ]
                  }
                  this.content.push(docPart)
                }

            })
          }
    },
    addItems10() {
          this.order = 0
            if (this.ana.items) {
              this.ana.items.map(item => {
                this.order += 1
                const docPart = {
                columns: [
                      {
                        width: '5%',
                        fontSize: 8,
                        alignment: 'left',
                        text: this.order
                      },
                      {
                        width: '10%',
                        fontSize: 8,
                        alignment: 'left',
                        text: dayjs(item.documentDate).format('DD.MM.YYYY')
                      },
                      {
                        width: '25%',
                        fontSize: 8,
                        alignment: 'left',
                        text: item.partnerName
                      },
                      {
                        width: '10%',
                        fontSize: 8,
                        alignment: 'right',
                        text: item.email
                      },
                       {
                        width: '9%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.FreeF1)
                      },
                       {
                        width: '9%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.FreeF5)
                      },
                      {
                        width: '9%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.FreeF3)
                      },
                      {
                        width: '9%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.profitLoss)
                      },
                      {
                        width: '9%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.FreeF4)
                      }
                    ]
                  }
                this.content.push(docPart)
 
              })
          }
    },
    addTotal () {
      const docPart = {
          columns: [
            {
              width: '5%',
              fontSize: 8,
              text: ''
            },
            {
              width: '9%',
              fontSize: 8,
              text: ' '
            },
            {
              width: '18%',
              fontSize: 8,
              text: ''
            },
            {
              width: '7%',
              fontSize: 8,
              text: ' '
            },
            {
              width: '7%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total1) + '\n', fontSize: 8
            },
            {
              width: '7%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total4) + '\n', fontSize: 8
            },
            {
              width: '7%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total2) + '\n', fontSize: 8
            },
            {
              width: '7%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total3) + '\n', fontSize: 8
            },
            {
              width: '7%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total5) + '\n', fontSize: 8
            },
            {
              width: '7%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total6) + '\n', fontSize: 8
            }
          ]
        }
      this.content.push(docPart)

      const docPart2 = {
          columns: [
            {
              width: '5%',
              fontSize: 8,
              text: ''
            },
            {
              width: '9%',
              fontSize: 8,
              text: ' '
            },
            {
              width: '18%',
              fontSize: 8,
              text: ''
            },
            {
              width: '7%',
              fontSize: 8,
              text: ' '
            },
            {
              width: '7%',
              alignment: 'right',
              text: 'Uk.Kol:' + this.formatPrice(this.ana.Total4+ this.ana.Total1)+ '\n', fontSize: 8
            },
            {
              width: '7%',
              alignment: 'right',
              text: '' + '\n', fontSize: 8
            },
            {
              width: '7%',
              alignment: 'right',
              text: 'Dobit dom:' + '\n', fontSize: 8
            },
            {
              width: '7%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total3 - this.ana.Total2) + '\n', fontSize: 8
            },
            {
              width: '7%',
              alignment: 'right',
              text: 'Dobit exp:' + '\n', fontSize: 8
            },
            {
              width: '7%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total6- this.ana.Total5) + '\n', fontSize: 8
            },
            {
              width: '7%',
              fontSize: 8,
              text: ' '
            },
            {
              width: '7%',
              alignment: 'right',
              text: this.formatPrice((this.ana.Total6- this.ana.Total5) + (this.ana.Total3 - this.ana.Total2)) + '\n', fontSize: 8
            }
          ]
        }
      this.content.push(docPart2)
      
    },
    addTotal10 () {
      const docPart = {
          columns: [
            {
              width: '5%',
              fontSize: 8,
              text: ''
            },
            {
              width: '10%',
              fontSize: 8,
              text: ''
            },
            {
              width: '25%',
              fontSize: 8,
              text: ''
            },
            {
              width: '10%',
              fontSize: 8,
              text: ''
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total1) + '\n', fontSize: 8
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total2) + '\n', fontSize: 8
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total3) + '\n', fontSize: 8
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total10) + '\n', fontSize: 8
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total4) + '\n', fontSize: 8
            }
          ]
        }
      this.content.push(docPart)
      
    },

    addSignature () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                 // { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________  \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },

    addEmpty () {
      const docPart = {
        
          }
      this.content.push(docPart)
    },

  },
  computed: {
  },
  watch: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
