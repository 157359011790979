<template>
  <v-layout row justify-center>
      <v-card>
        <v-card-title class="headline">
            {{lang.DeleteItem}}
          </v-card-title>
        <v-card-text>
            {{lang.DeleteItemMsg}}
        </v-card-text>
        <v-snackbar
          v-model="snackbar"
          bottom
          :timeout="timeout"
          :color="color"
          >
            {{ text }}
        </v-snackbar>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="pink" v-html="error" />
          <v-btn color="red darken-1" @click="deleteOn">
            {{langC.Delete}}
          </v-btn>
          <v-btn color="green darken-1" @click="mainledgerlist">
            {{langC.Cancel}}
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-layout>
</template>

<script>
import MainLedgerService from '@/services/MainLedgerService'
import MainLedgerDetailService from '@/services/MainLedgerDetailService'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"

export default {
  name: 'mainledgerdelete',
  data () {
    return {
      msg: '',
      dialog: false,
      error: '',
      er2: '',
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      lang: {},
      langC: {},
      saving: false,
      retPath: 'mainledger',
    }
  },
  mounted() {
    if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      if (this.$store.state.documentSide === 1 || this.$store.state.documentSide === 2) {
        this.show = 1
        this.retPath = 'mainledger'
      }
      if (this.$store.state.documentSide === 3) {
        this.show = 3
        this.retPath = 'mainledger3'
      }
      if (this.$store.state.documentSide === 4) {
        this.show = 4
        this.retPath = 'mainledger4'
      }
  },
  methods: {
    async deleteOn() {
      try{
        // eslint-disable-next-line
        console.log('delete 1')
        await this.deleteMainLedgerDetail()
        // eslint-disable-next-line
        console.log(' delete 2 ')
        await this.deleteMainLedgerSide()
        // eslint-disable-next-line
        console.log(' delete 3')
        await this.deleteMainLedger()
        this.$store.dispatch('setMainLedgerActiv', {})
        this.$store.dispatch('setMainLedgerItems', [])
        this.$store.dispatch('setMainLedgerStatus', 1)
        this.$router.push({
          name: this.retPath
        })
      } catch(error) {
        this.text = error.response.data.message ? error.response.data.message : ''
        this.text = error.response.data.error ? this.text + ', ' + error.response.data.error : this.error
        this.color = 'red'
        this.snackbar = true
        this.timeout = 4000
      }
    },
    async deleteMainLedger () {
      try {
        if (this.$store.state.mainledgerid) {
          await MainLedgerService.delete(this.$store.state.mainledgerid).then(res => {
            this.er2 = res.data            
          }).catch((error) => {
            this.text = error.response.data.message ? error.response.data.message : ''
            this.text = error.response.data.error ? this.text + ', ' + error.response.data.error : this.error
            this.color = 'red'
            this.snackbar = true
            this.timeout = 4000
          })
        }
      } catch (err) {
        this.text = 'Error on DELETE!'
        this.color = 'red'
        this.snackbar = true
        this.timeout = 4000
      }
    },
    async deleteMainLedgerDetail () {
      try {
        if (this.$store.state.mainledgerid) {
          await MainLedgerDetailService.delete(this.$store.state.mainledgerid).then(res => {
            this.er2 = res.data
            // eslint-disable-next-line
            console.log(' delete Detail ', res.data)
          }).catch((error) => {
            this.text = error.response.data.message ? error.response.data.message : ''
            this.text = error.response.data.error ? this.text + ', ' + error.response.data.error : this.error
            this.color = 'red'
            this.snackbar = true
            this.timeout = 4000
          })
        }
      } catch (err) {
        this.text = 'Error on DELETE!'
        this.color = 'red'
        this.snackbar = true
        this.timeout = 4000
      }
    },
    async deleteMainLedgerSide () {
      try {
        if (this.$store.state.mainledgerid) {
          await MainLedgerDetailService.deleteSide(this.$store.state.mainledgerid).then(res => {
            this.er2 = res.data
            // eslint-disable-next-line
            console.log(' delete Side ', res.data)
          }).catch((error) => {
            this.text = error.response.data.message ? error.response.data.message : ''
            this.text = error.response.data.error ? this.text + ', ' + error.response.data.error : this.error
            this.color = 'red'
            this.snackbar = true
            this.timeout = 4000
          })
        }
      } catch (err) {
        this.text = 'Error on DELETE!'
        this.color = 'red'
        this.snackbar = true
        this.timeout = 4000
      }
    },

    async mainledgerlist () {
      try {
        this.$store.dispatch('setMainLedgerActiv', {})
        this.$store.dispatch('setMainLedgerItems', [])
        this.$store.dispatch('setMainLedgerStatus', 1)
        await this.$router.push({
          name: this.retPath
        })
      } catch (err) {
        this.text = 'Error on route!'
        this.color = 'red'
        this.snackbar = true
        this.timeout = 4000
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
