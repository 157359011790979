const docHR = {
  CardTypes: 'Vrsta Kartica',
  CardType: 'Vrsta Kartica',
  CardName: 'Naziv kartice',
  DescHR: '',
  DescEN: '',
  Side: 'Strana',
  NewCardType: 'Nova kartica',
  EditCardType: 'Uredi karticu',
  EmptyCardType: 'Nema definiranih kartica',
  Discount: 'Popust'
}

export default docHR