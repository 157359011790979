const docHR = {
  WareHouse: 'Skladište',
  WareHouses: 'Skladišta',
  WareHouseEmpty: 'Skladišta tabela je prazna',
  AddWareHouse: 'Dodaj novo skladište',
  WareHouseName: 'Ime skladišta',
  WareHouseStatus: 'Zaključano',
  WareHouseLocked: 'Skladište zaključano',
  WareHouseLockedDate: 'Datum zaključavanja',
  WareHouseLockedTime: 'Vrijeme zaključavanja',
  WareHouseLockedBy: 'Zaključao',
  WareHouseUpdateDate: 'Vrijedi od',
  WareHouseUpdateTime: 'Vrijeme izmjene',
  WareHouseUpdateBy: 'Izmjenio',
  NewWareHouse: 'Novo Skladište',
  EditWareHouse: 'Skladište izmjena',
  Actions: 'Akcije',
  User: 'Korisnik',
  Users: 'Korisnici/Skladište',
  UserId: 'Korisnik sifra',
  email: 'email',
  UserIdRight: 'Korisnik prava',
  EnteredInDatabase: 'Datum upisa',
  AccessLevel: 'Razina pristupa (1 najveca, 5 najmanja)',
  Priv1: '1 najveća prava',
  Priv2: '2 srednja do najveća prva',
  Priv3: '3 srednja prava',
  Priv4: '4 niska do srednja prava',
  Priv5: '5 najniža prava',
  CompanyId: 'Tvrtka sifra',
  CompanyNameDatabase: 'Tvrtka naziv u bazi',
  NewWareHouseUser: 'Novi Korisnik za Skladište',
  EditWareHouseUser: 'Uredi Korisnika za Skladište',
  WareHouseId: 'Skladište šifra(broj)',
  SelectWarehouse: 'Odaberi Skladište',
  SelectUser: 'Odaberi korisnik'
}

export default docHR