<template>
  <div>
     <MainLedgerLLACRUD :key="myDocumentSide" />
  </div>
</template>

<script>
import MainLedgerLLACRUD from './MainLedgerLLACRUD'
import { mapGetters } from 'vuex'

export default {
  name: 'MainLedgerLLA',
  data() {
    return {
    };
  },
  monunted() {
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      myDocumentSide: 'NeedDocumentSide'
    })
  },
  components: {
    MainLedgerLLACRUD
  }
}
</script>
