<template>
  <div>

  </div>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './salarieDescEn'
import langHr from './salarieDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'
// import { parseNum } from '@/snippets/allSnippets'

export default {
  name: 'salarieprintsigns',
  props: [],
  data () {
    return {
      msg: '',
      documentName: '',
      documentName2: '',
      document: {},
      havePath: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locDocument: {},
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      comp: {},
      mainQuery: {},
      ana: {},
      totalIz: 0,
      totalNa: 0
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = {...langEn}
      this.langC = {...commEn}
    }
    if (this.$store.state.language === 'HR') {
      this.lang = {...langHr}
      this.langC = {...commHr}
    }

  },
  methods: {
    async print () {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.BusinessYear = this.$store.state.businessYear
      }
      this.printDoc()
    },

    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    printDoc () {
      this.comp = this.$store.state.companyActiv
      this.ana = this.$store.state.printObj ? this.$store.state.printObj : {}

      this.initDocument()
      this.addHead()
      this.addLine()
      this.addEmptyRow()
      this.addItems()
      this.addLine()

  
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 40 ]
      this.content = []
    },
  
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },

    addHead() {
      const docPart = {
          columns: [
            {
              // % width
              width: '35%',
              text: [
                { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.Companyemail ? 'email:' +  this.comp.Companyemail + ' \n' : ' ') , fontSize: 8 },
                { text: 'OIB:' + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '45%',
              text: [
                { text: 'Plaća - potpisna lista \n', fontSize: 18, bold: true },
                { text: 'za ' + (this.ana.Month ? this.ana.Month + '/' : '') + (this.ana.Year ? this.ana.Year + ', ('  : '') + (this.ana.Order ? this.ana.Order + ')' : '') , fontSize: 12, bold: true },
                { text: (this.ana.head3 ? this.ana.head3 + ' \n' : ''), fontSize: 8, bold: true },
                { text: (this.ana.head4 ? this.ana.head4 + ' \n' : ''), fontSize: 8, bold: true },
                { text: (this.ana.head5 ? this.ana.head5 + ' \n' : ''), fontSize: 8, bold: true },
              ]
            },
            {
              // % width
              width: '20%',
              text: [                 
                { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },

    addItemsHeader () {
      const docPart = {
          columns: [
            {
              width: '5%',
              text: 'Rbr' + '\n', fontSize: 8 
            },
            {
              width: '40%',
              text: 'Radnik' + '\n', fontSize: 8
            },
            {
              width: '25%',
              text: 'Potpis:\n', fontSize: 8, alignment: 'center' 
            },
          ]
        }
      this.content.push(docPart)
    },
    addItems() {
          this.order = 0
            if (this.ana.head1) {
              this.ana.head1.map(item => {
                this.order += 1
                const docPart = {
                columns: [
                    {
                      width: '5%',
                      fontSize: 12,
                      alignment: 'left',
                      text: this.order + '.'
                    },
                    {
                      width: '40%',
                      fontSize: 12,
                      alignment: 'left',
                      text:(item.First ? item.First + ' ' : '') + (item.Last ? item.Last  : '') + ' \n',
                    },
                    {
                      width: '25%',
                      text: [
                        { text: ' \n', fontSize: 8 },
                        { text: '____________________________ \n', fontSize: 8, alignment: 'center' }
                      ]
                    }
                  ]
                }
              this.content.push(docPart)
              const docPart2 = {
                columns: [
                    {
                      width: '25%',
                      text: [
                        { text: ' \n', fontSize: 12 },
                      ]
                    }
                  ]
                }
              this.content.push(docPart2)
            })
          }
    },


    addEmpty () {
      const docPart = {
        
          }
      this.content.push(docPart)
    },

  },
  computed: {
  },
  watch: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
