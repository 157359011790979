import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('digitaldoc/showall', {
      params: query
    })
  },
  indexlist(search) {
    return Api().get('digitaldoc', {
      params: search
    })
  },
  choose (query) {
    return Api().get('digitaldoc/choose', {
      params: query
    })
  },
  show (id) {
    return Api().get(`digitaldoc/${id}`)
  },
  post (newdigitaldoc) {
    return Api().post('digitaldoc', newdigitaldoc)
  },
  put (digitaldoc) {
    return Api().put(`digitaldoc/${digitaldoc.id}`, digitaldoc)
  },
  delete (id) {
    return Api().delete(`digitaldoc/${id}`)
  },
}