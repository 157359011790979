<template>
  <div class="mainledgerprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'

export default {
  name: 'mainledgerprintcard2',
  props: ['card', 'type', 'exRate'],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0,
      partner: {}
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}

  },
  methods: {
    async print () {
      console.log('this.exRate', this.exRate)
      if (this.card) {
         await this.initDocument()
        this.card.map(crd => {
          if (crd.Head) {
            this.addEmptyRow()
            this.addHeaderNoLogo(crd)
            this.addLine()
            this.addItemsHeader()
            this.addLine()
          }
          if (crd.Footer) {
            this.addItemsFoter(crd.Total1, crd.Total2, crd.Total5)
          }
          if (!crd.Head && !crd.Footer) {
            this.addItem(crd)
          }
        })
        this.docDefinition.content = this.content
        pdfMake.createPdf(this.docDefinition).open()
      }
      
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.content = []
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addLineDash () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1,
                dash: { length: 1 },
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo (card) {
      if (this.type === 4) {
       const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '35%',
                text: [
                  { text: this.comp.CompanyNameShort + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text: 'Konto: ' + card.account4 + '\n' , fontSize: 12, bold: true},
                  { text: '' + card.account4Description+ '\n', fontSize: 8},
                ]   
              },
              {
                // % width
                width: '20%',
                text: [                 
                  { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a') + '\n' , fontSize:8},
                  {text: '' + (card.cardNr ? 'Kartica:' + card.cardNr : ''),  fontSize:8}
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
      }
      if (this.type === 6) {
       const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '35%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text: card.partnerName + '\n' , fontSize: 12, bold: true},
                  { text: 'Konto: ' + card.account4 + '\n' , fontSize: 8},
                  { text: '' + card.account4Description+ '\n', fontSize: 8},
                ]   
              },
              {
                // % width
                width: '20%',
                text: [                 
                  { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a') + '\n' , fontSize:8},
                  { text: '' + (card.cardNr ? 'Kartica:' + card.cardNr : ''),  fontSize:8}
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
      }
    },
    addItemsHeader () {
      if (this.type === 4) {
        const docPart = {
        columns: [
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: 'Rb.  Vrsta'
              },
               {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: 'Broj'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: 'Datum knjiž.'
              },
               {
                width: '12%',
                fontSize: 8,
                alignment: 'left',
                text: 'Broj dok.'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: 'Duguje'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: 'Potražuje'
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'right',
                text: 'Saldo'
              },
              {
                width: '14%',
                fontSize: 7,
                alignment: 'center',
                text: 'Opis'
              },
              {
                width: '12%',
                fontSize: 7,
                alignment: 'center',
                text: 'Partner'
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'center',
                text: 'Veza'
              },
            ]
          }
          this.content.push(docPart)  
      }
      if (this.type === 6) {
        const docPart = {
        columns: [
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: 'Rb.  Vrsta'
              },
               {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: 'Broj'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: 'Datum knjiž.'
              },
               {
                width: '12%',
                fontSize: 8,
                alignment: 'left',
                text: 'Broj dok.'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: 'Duguje'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: 'Potražuje'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: 'Saldo'
              },
              {
                width: '14%',
                fontSize: 7,
                alignment: 'center',
                text: 'Opis'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'center',
                text: 'Datum rač.'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'center',
                text: 'Dat.dosp.'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: 'Veza.'
              },
              
            ]
          }
          this.content.push(docPart)  
      }

            
    },
    addItem (crd) {
      const veza = crd.invoiceInNumber > 0 ? 'URA-' + crd.invoiceInNumber : 'IRA-' + crd.invoiceOutNumber 
      if (this.type === 4) {        
        const docPart01 = {
        columns: [
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: crd.Line + '. ' + crd.BookName
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'left',
                text: (crd.BookID ? parseInt(crd.BookID) : '')
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: dayjs(crd.BookingDate).format('DD.MM.YYYY')
              },
              {
                width: '10%',
                fontSize: 6,
                alignment: 'left',
                text: (crd.InvoiceNumber ? crd.InvoiceNumber : '')
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(crd.Debit/ this.exRate)
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(crd.Credit/ this.exRate)
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(crd.Total5/ this.exRate)
              },
              {
                width: '1%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '14%',
                fontSize: 7,
                alignment: 'left',
                text: (crd.Description ? crd.Description.substr(0,22) : '')
              },
              {
                width: '12%',
                fontSize: 7,
                alignment: 'left',
                text: (crd.partnerName ? crd.partnerName.substr(0,20) : '')
              },
              {
                width: '10%',
                fontSize: 7,
                alignment: 'left',
                text: veza
              },
            ]
          }
        this.content.push(docPart01)
      }
      if (this.type === 6) {
        const docPart01 = {
        columns: [
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: crd.Line + '. ' + crd.BookName
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'left',
                text: (crd.BookID ? parseInt(crd.BookID) : '')
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: dayjs(crd.BookingDate).format('DD.MM.YYYY')
              },
              {
                width: '10%',
                fontSize: 6,
                alignment: 'left',
                text: (crd.InvoiceNumber ? crd.InvoiceNumber : '')
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(crd.Debit / this.exRate)
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(crd.Credit / this.exRate)
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(crd.Total5 / this.exRate)
              },
              {
                width: '1%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '14%',
                fontSize: 7,
                alignment: 'left',
                text: (crd.Description ? crd.Description.substr(0,25) : '')
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: dayjs(crd.InvoiceDate).format('DD.MM.YYYY')
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: dayjs(crd.InvoicePaymentDeadlineDate).format('DD.MM.YYYY')
              },
              {
                width: '7%',
                fontSize: 7,
                alignment: 'left',
                text: veza
              },
            ]
          }
        this.content.push(docPart01)
      }

    },
    addItems () {
           this.order = 0           
            if (this.card.items && this.card.items.length > 0) {
              this.card.items.map(item => {
                  this.order += 1
                  // this.pTtt5 = item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0
                  // this.pTtt6 = item.itemDiscountPercentage1 ? this.formatPrice(item.itemDiscountPercentage1) : 0
                  // this.pTtt7 = item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0
                  // this.pTtt8 = item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0
                  // this.pTtt9 = item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0

                      const docPart01 = {
                      columns: [
                            {
                              width: '8%',
                              fontSize: 8,
                              alignment: 'left',
                              text: item.itBookName
                            },
                            {
                              width: '8%',
                              fontSize: 8,
                              alignment: 'left',
                              text: dayjs(item.BookingDate).format('DD.MM.YYYY')
                            },
                            {
                              width: '8%',
                              fontSize: 8,
                              alignment: 'left',
                              text: item.account4
                            },
                            {
                              width: '8%',
                              fontSize: 8,
                              alignment: 'right',
                              text: this.formatPrice(item.Debit / this.exRate)
                            },
                            {
                              width: '8%',
                              fontSize: 8,
                              alignment: 'right',
                              text: this.formatPrice(item.Credit / this.exRate)
                            },
                            {
                              width: '8%',
                              fontSize: 8,
                              alignment: 'right',
                              text: this.formatPrice(item.CreditDebit / this.exRate)
                            },
                            {
                              width: '12%',
                              fontSize: 8,
                              alignment: 'left',
                              text: item.Description
                            },
                            {
                              width: '11%',
                              fontSize: 8,
                              alignment: 'left',
                              text: item.partnerName
                            },
                            {
                              width: '8%',
                              fontSize: 8,
                              alignment: 'right',
                              text: item.invoiceInNumber
                            },
                            {
                              width: '8%',
                              fontSize: 8,
                              alignment: 'right',
                              text: item.invoiceOutNumber
                            },
                            {
                              width: '8%',
                              fontSize: 8,
                              alignment: 'right',
                              text: item.BusinessYearInvoice
                            },
                          ]
                        }
                      this.content.push(docPart01)
                }
              )
          }
    },
    addItemsFoter (total1, total2, total5) {
       
      this.addLine()

      const docPart01 = {
           columns: [
              { 
                width: '9%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(total1/ this.exRate)
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(total2/ this.exRate)
              },
              {
                width: '9%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(total5/ this.exRate)
              },
          ]
        }
      this.content.push(docPart01)
    },
  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>