import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('employee', {
      params: query
    })
  },
  index2 (query) {
    return Api().get('employee/index2', {
      params: query
    })
  },
  show (employeeId) {
    return Api().get(`employee/${employeeId}`)
  },
  post (newemployee) {
    return Api().post('employee', newemployee)
  },
  put (employee) {
    return Api().put(`employee/${employee.id}`, employee)
  },
  delete (employeeId) {
    return Api().delete(`employee/${employeeId}`)
  },
  login (loginemployee) {
    return Api().post('employee/login', loginemployee)
  },
  choose (query) {
    return Api().get('employee/choose', { params: query })
  },
  choose2 (query) {
    return Api().get('employee/choose2', { params: query })
  },
  choose3 (query) {
    return Api().get('employee/choose3', { params: query })
  }
}
