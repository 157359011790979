import Api from '@/services/Api'

export default {
  index(search) {
    return Api().get('partnercompany', {
      params: {
        search: search
      }
    })
  },
  show(partnerId) {
    return Api().get(`partnercompany/${partnerId}`)
  },
  check(checkPartner) {
    return Api().get(`/partnercompany/checkvatid/`, checkPartner)
  },
  post(newpartner) {
    return Api().post('partnercompany', newpartner)
  },
  put(partner) {
    return Api().put(`partnercompany/${partner.id}`, partner)
  },
  delete(query) {
    return Api().delete('partnercompany/delete', {params: query})
  },
  choose(query) {
    return Api().get('partnercompany/choose', {
      params: query
    })
  },
}