import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('salarie/showall', {
      params: query
    })
  },
  indexlist(search) {
    return Api().get('salarie', {
      params: search
    })
  },
  choose (query) {
    return Api().get('salarie/choose', {
      params: query
    })
  },
  choosedetail (query) {
    return Api().get('salarie/choosedetail', {
      params: query
    })
  },
  show (id) {
    return Api().get(`salarie/${id}`)
  },
  post (newsalarie) {
    return Api().post('salarie', newsalarie)
  },
  put (salarie) {
    return Api().put(`salarie/${salarie.id}`, salarie)
  },
  delete (id) {
    return Api().delete(`salarie/${id}`)
  },
  saveitems (newitems) {
    return Api().post('salarie/saveitems', newitems)
  },
}