const docHR = {
  CompanyName: 'Naziv tvrtke',
  Company: 'Tvrtka',
  Companies: 'Tvrtke',
  CompanyNameShort: 'Naziv skraćen',
  CompanyNameDatabase: 'Naziv database',
  CompanyBuildingNameNumber: 'Broj/zgrada',
  CompanyStreet: 'Ulica',
  CompanyCity: 'Grad',
  CompanyPostcode: 'Poštanski broj',
  CompanyCountyRegion: 'Županija',
  CompanyState: 'Država',
  CompanyVATID: 'OIB',
  Companyemail: 'email',
  CompanyPhones: 'Telefoni',
  CompanyBankName: 'Naziv banke',
  CompanyBankAddress: 'Adresa banke',
  CompanyBankIBAN: 'IBAN',
  CompanyBankSWIFT: 'SWIFT',
  Director: 'CEO',
  CompanyDirectorTitle: 'Titula',
  CompanyDirectorFirst: 'Ime',
  CompanyDirectorLast: 'Prezime',
  CompanyDirectorFormer: 'Djevojačko ime',
  CompanyDirectorStateResidence: 'Država',
  CompanyDirectorNationality: 'Nacionalnost',
  CompanyDirectorDateOfBirth: 'Datum rođenja',
  CompanyDirectorBusinessOccupation: 'Poslovna pozicija',
  CompanyDirectorAdressBuildingNameNumber: 'Broj/zgrada',
  CompanyDirectorAdressStreet: 'Ulica',
  CompanyDirectorCity: 'Grad',
  CompanyDirectorPostcode: 'Poštanski broj',
  CompanyDirectorCountyRegion: 'Županija',
  CompanyDirectorEmail: 'email',
  CompanyDirectorPhone: 'Telefoni',
  CompanyDirectorContactOption: 'Opcije za kontakt',
  Secretary: 'Kontakt osoba',
  CompanySecretaryTitle: 'Titula',
  CompanySecretaryFirst: 'Ime',
  CompanySecretaryLast: 'Prezime',
  CompanySecretaryFormer: 'Bivše prezime',
  CompanySecretaryStateResidence: 'Država stanovanja',
  CompanySecretaryNationality: 'Nacionalnost',
  CompanySecretaryDateOfBirth: 'Datum rođenja',
  CompanySecretaryBusinessOccupation: 'Poslovna pozicija',
  CompanySecretaryAdressBuildingNameNumber: 'Zgrada/broj',
  CompanySecretaryAdressStreet: 'Ulica',
  CompanySecretaryCity: 'Grad',
  CompanySecretaryPostcode: 'Poštanski broj',
  CompanySecretaryCountyRegion: 'Županija',
  CompanySecretaryEmail: 'email',
  CompanySecretaryPhone: 'Telefon',
  CompanySecretaryContactOption: 'Opcije kontakta',
  CompanyInvoiceMemo: 'Memo za račun',
  CompanyInvoiceForeginMemo: 'Memo za strani račun',
  CompanyDeliveryNoteMemo: 'Otpremnica memo',
  CompanyDeliveryNoteForeginMemo: 'Otpremnica strana memo',
  CompanyInvoiceDefDays: 'Broj dana plaćanje',
  CompanyInvoicePlace: 'Račun mjesto izdavanja',
  CompanyInvoiceType: 'Račun vrsta',
  CompanyInvoiceHint: 'Račun uzrečica',
  CompanyInvoiceSpeed: 'Račun brzina',
  CompanyUseRetailPrice: 'Koristi MPC cijene',
  CompanyShowLastSaleBuyer: 'Prikaži zadnju prodaju za kupca',
  CompanyShowWarehouseState: 'Prikaži stanje skladišta',
  CompanyShowInputPrice: 'Prikaži dobavnu cijenu',
  CompanyVATonPay: 'PDV po naplati',
  CompanyRetail: 'Maloprodaja',
  CompanyDefaultWarehouse: 'Default Skladište',
  CompanyPrinterSize: 'Printer default',
  CompanyInputFilesPath: 'Ulazna staza',
  CompanyOutputFilesPath: 'Izlazna staza',
  CompanyTransferDataFilesPath: 'Transfer staza',
  CompanyStartInvoiceNumber: 'Početni broj računa',
  CompanyStartBillNumber: 'Početni broj na kasi',
  CompanyType: 'Vrsta',
  CompanyDescriptionUp: 'Opis na vrhu',
  CompanyDescriptionDown: 'Opis na dnu',
  CompanyBusinessSpace: 'Poslovni prostor',
  CompanyBusinessDevice: 'Uređaj u posl.prostoru',
  CompanyBusinessSpaceRetail: 'Poslovni prostor maloprodaja',
  CompanyBusinessDeviceRetail: 'Uređaj maloprodaja',
  CompanySendEmailTask: 'Šalji email za zadatke',
  StartFrom: 'Početni datum',
  Activ: 'Aktivan',
  BusinessYears: 'Poslovne godine',
  BusinessYear: 'Poslovna godina',
  NewBusinessYears: 'Nova poslovna godina',
  EditBusinessYears: 'Uredi poslovnu godinu',
  CompanyBusinessYearsEmpty: 'Tabela sa poslovnim godinama je prazna',
  BusinessYearStartDate: 'Početak poslovne godine',
  BusinessYearEndDate: 'Kraj poslovne godine',
  BusinessYearLocked: 'Poslovna godina zaključana',
  BusinessYearKey1: 'Ključ 1',
  BusinessYearKey2: 'Ključ 2',
  defaultBusinessYear: 'default Poslovna godina',
  CompanyId: 'Id',
  DefaultCompany: 'Default Tvrtka',
  UserId: 'Id',
  CompanyUsers: 'Korisnici - Tvrtka',
  CompanyUser: 'Korisnik - Tvrtka',
  CompanyUsersEmpty: 'Nema korisnika za tvrtku',
  NewCompanyUser: 'Novi korisnik za tvrtku',
  EditCompanyUser: 'Uredi korisnika za tvrtku',
  UserIdRight: 'Razina prava korisnika',
  Priv1: '1 najveća prava',
  Priv2: '2 srednja do najveća prva',
  Priv3: '3 srednja prava',
  Priv4: '4 niska do srednja prava',
  Priv5: '5 najniža prava'
}

export default docHR