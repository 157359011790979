import { render, staticRenderFns } from "./TaskDelete.vue?vue&type=template&id=2f9cab0f&scoped=true"
import script from "./TaskDelete.vue?vue&type=script&lang=js"
export * from "./TaskDelete.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f9cab0f",
  null
  
)

export default component.exports