<template>
  <v-layout row>
      <v-flex xs4>
        {{label}}
      </v-flex>
      <v-flex xs8>
          <span style="font-weight:bold">{{info}}</span>
      </v-flex>
    </v-layout>
</template>

<script>
export default {
  props: ['label', 'info']
}
</script>

<style>

</style>
