<template>
  <v-container class="pa-2">
    <v-row v-if="showBsItem">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
       <MainLedgerBankStatmentDetailItem />
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">

          <v-card>
            <v-card-title>
              <span class="headline">{{cardTitle}}</span>
              <v-spacer></v-spacer>
              <v-btn text small color="red darken-1" @click="deleteDD2">
                {{langC.Delete}}
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                <v-row no-gutters>
                    <v-col cols="12" sm="9" md="9" lg="9" xl="9">
                      <v-autocomplete
                        :items="account4s"
                        v-model="selectA4"
                        :label="lang.Account4"
                        item-text="acc4Acc"
                        item-value="account4"
                        return-object
                        persistent-hint
                        :filter="getList"
                        @change="choosedAcc(selectA4)"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="pt-3">
                        <v-btn color="blue darken-1" text @click="account">{{lang.Account4s}}</v-btn>
                        <v-btn color="blue darken-1" text @click="account4add">
                        <span v-if="!showAccount4Add">Dodaj</span>
                        <span v-if="showAccount4Add">Zatvori</span>
                        </v-btn>
                        
                    </v-col>
                </v-row>

                <v-row no-gutters>
                    <v-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
                      <v-text-field
                        v-model="defaultItem.Description"
                        :label="lang.Description"
                        required
                      ></v-text-field>
                    </v-col>
                </v-row>

                <v-row no-gutters> 
                  
                  <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2" class="pr-3">
                    <v-text-field
                      v-model.number="defaultItem.Debit"
                      :label="lang.Debit"
                      type="number"
                      class="title"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2" class="pr-3">
                    <v-text-field
                      v-model.number="defaultItem.Credit"
                      :label="lang.Credit"
                      type="number"
                      class="title"
                    ></v-text-field>
                  </v-col>

                    <v-col cols="12" xs="6" sm="6" md="1" lg="1" xl="1" v-if="BusinessYear > 2022">
                      <span class="headline px-2">{{defaultItem.HomeCurrency}}</span>
                    </v-col>

                    <v-col cols="12" xs="6" sm="6" md="1" lg="1" xl="1" class="pr-2" v-if="BusinessYear <= 2022">
                      <v-select :label="lang.HomeCurrency" :items="currnecyType" v-model="defaultItem.HomeCurrency" value="defaultItem.HomeCurrency" class="pr-2"></v-select>
                    </v-col>
                
                    <v-col cols="12" xs="6" sm="6" md="1" lg="1" xl="1" class="pr-3" v-if="BusinessYear <= 2022">
                      <v-text-field
                        v-model.number="defaultItem.ExRate"
                        :label="lang.ExRate"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  <v-col cols="12" xs="6" sm="6" md="1" lg="1" xl="1" class="pr-2" v-if="BusinessYear <= 2022">
                   <v-select :label="lang.ForeignCurrency" :items="currnecyType" v-model="defaultItem.ForeignCurrency" value="defaultItem.ForeignCurrency"></v-select>
                  </v-col>
                  <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2" class="pr-3" v-if="BusinessYear <= 2022">
                    <v-text-field
                      v-model.number="defaultItem.DebitForegin"
                      :label="lang.DebitFr"
                      disabled
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2" v-if="BusinessYear <= 2022">
                    <v-text-field
                      v-model.number="defaultItem.CreditForegin"
                      :label="lang.CreditFr"
                      disabled
                      type="number"
                    ></v-text-field>
                  </v-col>

                </v-row>
                <v-row no-gutters> 
                  
                  
                </v-row>

                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="pr-2">
                      <v-autocomplete
                        v-bind:items="partners"
                        v-model="selectPa"
                        :label="lang.PartnerName"
                        item-text="partnerName"
                        item-value="id"
                        return-object
                        persistent-hint
                        @change="findPartner(selectPa)"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                      <v-text-field class="pr-2"
                        label="OIB *"
                        v-model="defaultItem.partnerVATID"
                      ></v-text-field> 
                  </v-col>
                   
                    <v-col cols="12" sm="12" md="7" lg="7" xl="7" class="pt-3">
                        <v-btn color="blue darken-1" text @click="openCard(1)">{{langC.PartnerOpen}}</v-btn>
                        <v-btn color="blue darken-1" text @click="openCard(2)">{{langC.PartnerCard}}</v-btn>
                        <v-btn color="blue darken-1" text @click="bookin">{{lang.BookInInvoices}}</v-btn>
                        <v-btn color="blue darken-1" text @click="bookout">{{lang.BookOutInvoices}}</v-btn>
                        <v-btn color="blue darken-1" text @click="partner">{{lang.PartnerList}}</v-btn>
                        <v-btn color="blue darken-1" text @click="addeditPartner()">{{lang.PartnerSave}}</v-btn>
                        <v-btn color="blue darken-1" text @click="copyPartnerIBAN">{{lang.copyPartnerIban}}</v-btn>
                    </v-col>

                </v-row>

                <v-row no-gutters>
                  <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4">
                      <v-text-field class="pr-2"
                        label="Naziv partnera"
                        v-model="defaultItem.partnerName"
                      ></v-text-field> 
                  </v-col>

                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                      <v-text-field class="pr-2"
                      :label="lang.Partneremail"
                      v-model="defaultItem.partneremail"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <v-text-field class="pr-2"
                      label="IBAN"
                      v-model="defaultItem.partnerIBAN"
                    ></v-text-field> 
                  </v-col>
                  
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                    <v-text-field
                      label="Ulica"
                      v-model="defaultItem.partnerStreet"
                    ></v-text-field> 
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                    <v-text-field
                      label="Br"
                      v-model="defaultItem.partnerBuildingNameNumber"
                    ></v-text-field> 
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                    <v-text-field
                      label="Mjesto"
                      v-model="defaultItem.partnerCity"
                    ></v-text-field> 
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                      <v-text-field
                        label="Pošta"
                        v-model="defaultItem.partnerPostcode"
                      ></v-text-field> 
                  </v-col>
                </v-row>

                <v-row no-gutters> 
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="pr-2">
                    <v-text-field
                      v-model.number="defaultItem.invoiceInNumber"
                      :label="lang.invoiceInNumber"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="pr-3">
                    <v-text-field
                      v-model.number="defaultItem.invoiceOutNumber"
                      :label="lang.invoiceOutNumber"
                      type="number"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="1" md="1" lg="1" xl="1" class="pl-2">
                    <v-text-field
                      v-model.number="defaultItem.BusinessYearInvoice"
                      :label="lang.BusinessYearInvoice"
                      type="number"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="1" md="1" lg="1" xl="1" class="pr-3 pl-2" v-if="!showStart">
                    <v-btn color="blue darken-1" text @click="findInvoice">Nadji</v-btn>
                  </v-col>

                  <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="pr-3 pl-2" v-if="showStart">
                    <v-text-field
                      v-model="defaultItem.InvoiceNumber"
                      :label="lang.InvoiceNumber2"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="pr-3" v-if="showStart">
                    <v-dialog
                      ref="dialog1"
                      persistent
                      v-model="modal1"                   
                      width="290px"
                      :return-value.sync="defaultItem.InvoiceDate"
                    >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="defaultItem.InvoiceDate"
                        :label="lang.InvoiceDate"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </template>
                      <v-date-picker v-model="defaultItem.InvoiceDate" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="modal1 = false">{{langC.Cancel}}</v-btn>
                        <v-btn color="primary" @click="$refs.dialog1.save(defaultItem.InvoiceDate)">OK</v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>

                  <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="pr-3" v-if="showStart">
                    <v-dialog
                      ref="dialog2"
                      persistent
                      v-model="modal2"                   
                      width="290px"
                      :return-value.sync="defaultItem.InvoicePaymentDeadlineDate"
                    >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="defaultItem.InvoicePaymentDeadlineDate"
                        :label="lang.InvoicePaymentDeadlineDate"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </template>
                      <v-date-picker v-model="defaultItem.InvoicePaymentDeadlineDate" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="modal2 = false">{{langC.Cancel}}</v-btn>
                        <v-btn color="primary" @click="$refs.dialog2.save(defaultItem.InvoicePaymentDeadlineDate)">OK</v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>


                </v-row>

                <v-row no-gutters>
                    <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="pr-2">
                      <v-select
                        :label="lang.VATPer"
                        :items="vatHRType"
                        v-model="defaultItem.VATPer"
                        value="defaultItem.VATPer"
                        clearable
                        class="pr-2"></v-select>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                      <v-select
                      :label="lang.BookTypeID"
                      :items="bookType"
                      v-model="bookTypeSelected"
                      value="defaultItem.BookTypeID"
                      return-object
                      persistent-hint
                      clearable
                      disabled
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="pr-2">
                      <div v-if="showPT">
                        <v-select
                          :label="lang.MainLedgerProjectTypeName"
                          :items="projectTypes"
                          v-model="selectPT"
                          item-text="TypeName"
                          item-value="id"
                          return-object
                          @change="choosedProjectType(selectPT)"
                          clearable
                          class="pr-2"></v-select>
                      </div>
                    </v-col>
                  </v-row>


                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn :disabled="!valid" color="success"  @click="haveAlert">{{langC.SaveItem}}</v-btn>  
                  </v-row>
                  <v-row>
                    <MainLedgerViewTotal v-if="!showBsItem" /> 
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.havePartners">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <PartnerListFast></PartnerListFast>
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.haveAccounts">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <Account4ListFast></Account4ListFast>
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.haveBookIn || this.haveBookOut">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <MainLedgerListFast :partnerId="defaultItem.partnerId" :docSide="docSide" />
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showAlert"
      max-width="350"
    >
      <v-card>
        <v-card-title class="headline">
          {{alertText1}}
        </v-card-title>

        <v-card-text>
          {{alertText2}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="showAlert = false"
          >
            {{langC.Cancel}}
          </v-btn>

          <v-btn
            color="blue"
            text
            @click="haveAlert2()"
          >
            {{langC.Continue}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row v-if="showCard1">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <MainLedgerCard1 :items="items" :account4Loc="account4Loc" :head="mainledgerAna"/>
      </v-col>
    </v-row>
    <v-row v-if="showCard2">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <MainLedgerCard2 :items="items" :head="mainledgerAna"/>
      </v-col>
    </v-row>
    <v-row v-if="showCard3">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <MainLedgerCard3Selection :items="items" :head="mainledgerAna" :debit="defaultItem.Debit" :credit="defaultItem.Credit"/>   
      </v-col>
    </v-row>
    <v-row v-if="showCard4">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <MainLedgerCard4 :items="items" :head="mainledgerAna"/>
      </v-col>
    </v-row>
    <v-row v-if="showCard5">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <MainLedgerCard5 :items="items" :head="mainledgerAna"/>
      </v-col>
    </v-row>
    <v-row v-if="showAccount4Add">
      <Account4Add/>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import PartnerListFast from "@/components/Partner/PartnerListFast"
import Account4ListFast from './Account4ListFast'
import Account4Add from './Account4Add'
import MainLedgerViewTotal from './MainLedgerViewTotal'
import MainLedgerListFast from './MainLedgerListFast'
import MainLedgerDetailService from '@/services/MainLedgerDetailService'
import MainLedgerSideService from '@/services/MainLedgerSideService'
import MainLedgerCard1 from './MainLedgerCard1'
import MainLedgerCard2 from './MainLedgerCard2'
import MainLedgerCard3Selection from './MainLedgerCard3Selection'
import MainLedgerCard4 from './MainLedgerCard4'
import MainLedgerCard5 from './MainLedgerCard5'
import MainLedgerBankStatmentDetailItem from './MainLedgerBankStatmentDetailItem'
import PartnerService from '@/services/PartnerService'
import { defMainLedgerDetail, defMainLedgerDetailFill, defNewpartner } from './mainledgerHelper'
import { mapGetters } from 'vuex'
import {parseNum, round} from '@/snippets/allSnippets'
import { testLastUpdate, newUpdates } from '@/services/TestLastUpdate'
import dayjs from 'dayjs'

export default {
  name: 'mainledgerdetailcreate',
  data () {
    return {
      rules: {
        required: value => !!value || '*'
      },
      modal1: false,
      modal2: false,
      dialog1: false,
      dialog2: false,
      havePartners: false,
      haveAccounts: false, 
      haveOpenStatements: false, 
      haveBookIn: false,
      haveBookOut: false,
      snackbar: false,
      alert: true,
      text: '',
      timeout: 2000,
      color: 'green',
      lang: {},
      langC: {},
      valid: true,
      documentName: '',
      documentNameLoc: '',
      partners: [],
      select: { },
      selectPa: {},
      selectA4: {},
      itemIndex: 0,
      activItems: [],
      account1s: [],
      account2s: [],
      account3s: [],
      account4s: [],
      currnecyType: [
        { text: 'EUR', value: 'EUR' },
        { text: 'Kn', value: 'Kn' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      vatHRType: [
        { text: '25%', value: '25' },
        { text: '13%', value: '13' },
        { text: '5%', value: '5' },
      ],
      sumML: {
        Debit: 0,
        Credit: 0,
        DebitForegin: 0,
        CreditForegin: 0
      },
      mainQuery: {},
      mainQuery2: {
        CompanyId: 1,
        account4: '',
        databaseMLID: 0,
        businessYear: 0
      },
      mainledgerAna: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        First: '',
        Last: '',
        fromDate: null,
        toDate: null,
        fromAccount: '',
        toAccount: ''
      },
      showCard1: false,
      showCard2: false,
      showCard3: false,
      showCard4: false,
      showCard5: false,
      showBsItem: false,
      showAccount4Add: false,
      exRate: this.$store.getters.NeedDefExRate,
      bookType: [],
      bookTypeSelected: {
      },
      defaultItem: {},
      showStart: false,
      docSide: 1,
      mainQuery3: {},
      showAlert: false,
      type: null,
      alertText1: null,
      alertText2: null,
      projectTypes: [],
      selectPT: {},
      showPT: true,
      cardTitle: '',
      ml: {},
      BusinessYear: 2022,
    }
  },
  mounted () {
    if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery3.CompanyId = this.$store.state.companyid
      }
      this.alertText1 = this.lang.MainLedgerQues3 ? this.lang.MainLedgerQues3 : ''
      this.alertText2 = this.lang.MainLedgerQues4 ? this.lang.MainLedgerQues4 : ''
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc

      this.account4s = this.$store.state.account4s ? this.$store.state.account4s : []
      this.partners = this.$store.state.partnerStore ? this.$store.state.partnerStore : []
      this.bookType = this.$store.state.booktype ? this.$store.state.booktype : []
      this.projectTypes = this.$store.state.projectTypes ? this.$store.state.projectTypes : []
      this.bookTypeSelected = this.$store.state.bookTypeSelected ? this.$store.state.bookTypeSelected : {}
      
      this.showStart = this.bookTypeSelected.text === 'PS' ? true : false
      this.showBsItem = this.$store.state.mainledgerSideItem ? true : false
      this.cardTitle = this.documentNameLoc + ' ' + this.bookTypeSelected.text

      if (this.$store.state.documentSide === 1 || this.$store.state.documentSide === 2) {
        this.ml = this.$store.state.mainledgerActiv ? this.$store.state.mainledgerActiv : {}
        this.cardTitle = this.cardTitle + ', Broj:' + parseInt(this.ml.BookID) + ', ' + this.ml.partnerName + ', Račun:' + this.ml.InvoiceNumber + ', Iznos: '+ this.formatPriceHR(this.ml.InvoiceAmount)
      }
      // reset card with more close items to zero
      this.$store.dispatch('setMainLedgerDetailItems', [])
      this.BusinessYear =  this.$store.state.businessYear ? this.$store.state.businessYear : 2022

      const d = new Date();
      // if NEW item
      if (this.$store.state.editItem === 0 ) {
        console.log(this.$store.state.mainledgerSideItem)
        
        this.defaultItem = defMainLedgerDetail()
        this.defaultItem = {...this.defaultItem, ...defMainLedgerDetailFill()}
        //this.mainQuery = {...defMainQuery()}
        const newId = this.getRndInteger(1000000000, 9000000000)
        this.defaultItem.id = newId
        this.$store.dispatch('setMainLedgerDetailid', newId)

        this.defaultItem.partnerId = this.$store.state.mainledgerActiv.partnerId ? parseInt(this.$store.state.mainledgerActiv.partnerId) : null
        this.defaultItem.partnerName = this.$store.state.mainledgerActiv.partnerName ? this.$store.state.mainledgerActiv.partnerName : null
        this.defaultItem.partnerVATID = this.$store.state.mainledgerActiv.partnerVATID ? this.$store.state.mainledgerActiv.partnerVATID : null
        this.defaultItem.partnerIBAN = this.$store.state.mainledgerActiv.partnerBankIBAN ? this.$store.state.mainledgerActiv.partnerBankIBAN : null
        this.defaultItem.partneremail = this.$store.state.mainledgerActiv.partneremail ? this.$store.state.mainledgerActiv.partneremail : null
        this.defaultItem.partnerStreet = this.$store.state.mainledgerActiv.partnerStreet ? this.$store.state.mainledgerActiv.partnerStreet : null
        this.defaultItem.partnerBuildingNameNumber = this.$store.state.mainledgerActiv.partnerBuildingNameNumber ? this.$store.state.mainledgerActiv.partnerBuildingNameNumber : null
        this.defaultItem.partnerCity = this.$store.state.mainledgerActiv.partnerCity ? this.$store.state.mainledgerActiv.partnerCity : null
        this.defaultItem.partnerPostcode = this.$store.state.mainledgerActiv.partnerPostcode ? this.$store.state.mainledgerActiv.partnerPostcode : null

        this.selectPa = this.$store.state.mainledgerActiv.partnerId ? this.partners.find(partner => (partner.id === parseInt(this.$store.state.mainledgerActiv.partnerId))) : null

        // if have data from bank statement
        if (this.showBsItem) {
           let didit = false
           this.defaultItem.Description = this.$store.state.mainledgerSideItem.bankDescription ? this.$store.state.mainledgerSideItem.bankDescription : ''
           if (this.$store.state.mainledgerSideItem.bankStatmentReference === 'EUR' || this.$store.state.mainledgerSideItem.bankStatmentReference === 'USD') {
            this.defaultItem.Debit = this.$store.state.mainledgerSideItem.bankStatmentDebit ? round(parseNum(this.$store.state.mainledgerSideItem.bankStatmentDebit), 2) * this.exRate : 0
            this.defaultItem.Credit = this.$store.state.mainledgerSideItem.bankStatmentCredit ? round(parseNum(this.$store.state.mainledgerSideItem.bankStatmentCredit), 2) * this.exRate : 0
            this.defaultItem.DebitForegin = this.$store.state.mainledgerSideItem.bankStatmentDebit ? round(parseNum(this.$store.state.mainledgerSideItem.bankStatmentDebit),2) : 0
            this.defaultItem.CreditForegin = this.$store.state.mainledgerSideItem.bankStatmentCredit ? round(parseNum(this.$store.state.mainledgerSideItem.bankStatmentCredit), 2) : 0
            didit = true
          }
          if (didit === false) {
            this.defaultItem.Debit = this.$store.state.mainledgerSideItem.bankStatmentDebit ? round(parseNum(this.$store.state.mainledgerSideItem.bankStatmentDebit),2) : 0
            this.defaultItem.Credit = this.$store.state.mainledgerSideItem.bankStatmentCredit ? round(parseNum(this.$store.state.mainledgerSideItem.bankStatmentCredit), 2) : 0
            this.defaultItem.DebitForegin = this.$store.state.mainledgerSideItem.bankStatmentDebit ? round(parseNum(this.$store.state.mainledgerSideItem.bankStatmentDebit) / this.exRate, 3) : 0
            this.defaultItem.CreditForegin = this.$store.state.mainledgerSideItem.bankStatmentCredit ? round(parseNum(this.$store.state.mainledgerSideItem.bankStatmentCredit) / this.exRate, 3) : 0
          }
         
          this.defaultItem.bankStatmentDBNumber = this.$store.state.mainledgerSideItem.databaseMLID ? this.$store.state.mainledgerSideItem.databaseMLID  : null
          this.defaultItem.bankStatmentDetailDBNumber = this.$store.state.mainledgerSideItem.id ? this.$store.state.mainledgerSideItem.id  : null

          if (this.defaultItem.Debit && this.defaultItem.Debit !==0) {
            const choosedAcc = {}
            choosedAcc.account4 = '2200'
            this.choosedAcc(choosedAcc)
            this.selectA4 = this.defaultItem.account4 ? this.account4s.find(element => element.account4 === choosedAcc.account4) : null
          }
          if (this.defaultItem.Credit && this.defaultItem.Credit !==0) {
            const choosedAcc = {}
            choosedAcc.account4 = '1200'
            this.choosedAcc(choosedAcc)
            this.selectA4 = this.defaultItem.account4 ? this.account4s.find(element => element.account4 === choosedAcc.account4) : null
          }
          this.checkByIban2()
        }

      }
      // if edit item
      if (this.$store.state.editItem === 1 && this.$store.state.mainledgerdetailid) {
        const itemOnly = this.$store.state.mainledgerItems.find(item => {
          return item.id === this.$store.state.mainledgerdetailid
        })
        this.defaultItem = {...itemOnly}
        this.itemIndex = this.$store.state.mainledgerItems.indexOf(itemOnly)
        this.selectPa = this.defaultItem.partnerId ? this.partners.find(partner => (partner.id === parseInt(this.defaultItem.partnerId))) : null
        this.selectA4 = this.defaultItem.account4 ? this.account4s.find(element => element.account4 === this.defaultItem.account4) : null
        this.selectPT = this.defaultItem.TypeID ? this.projectTypes.find(element => element.id === this.defaultItem.TypeID) : null
       // if (this.defaultItem.account4.substr(0,1) === '4' || this.defaultItem.account4.substr(0,1) === '7') {
          this.showPT = true
       // }
        // podesiti konto na ispravku i partnera u selectu
        // if (this.defaultItem.itemID) {
        //   this.selectIL =
        //   {
        //     itemByID: (this.defaultItem.itemID + ' ' + this.defaultItem.itemName),
        //     itemID: this.defaultItem.itemID
        //   }
        // }
      }

      if (Math.abs(this.defaultItem.Debit) > 0 && Math.abs(this.defaultItem.Credit) > 0) {
        this.text = 'Upozorenje! Dugovna i Potražna strana su vece od 0!!'
        this.color = 'red'
        this.snackbar = true
      }
      this.defaultItem.BusinessYearInvoice = this.showStart ? this.$store.state.businessYear - 1  : this.$store.state.businessYear
      this.$store.dispatch('setPartnerid', null)
      this.$store.dispatch('setPartnerVATID', null)
      this.$store.dispatch('setAccount4id', null)
  },
  components: {
    PartnerListFast,
    Account4ListFast,
    Account4Add,
    MainLedgerViewTotal,
    MainLedgerListFast,
    MainLedgerCard1,
    MainLedgerCard2,
    MainLedgerCard3Selection,
    MainLedgerCard4,
    MainLedgerCard5,
    MainLedgerBankStatmentDetailItem
  },
  computed: {
    ...mapGetters({
      myPartnerid: 'NeedPartnerid'
    }),
    ...mapGetters({
      myPartnerIBAN: 'NeedPartnerIBAN'
    }),
    ...mapGetters({
      myAccount4id: 'NeedAccount4id'
    }),
    ...mapGetters({
      mydialogState: 'NeedDialogState'
    }),
  },
  watch: {
    'myPartnerid' () {      
      if (this.$store.state.partnerid) {
        const searchPartner = {}
        searchPartner.id = this.$store.state.partnerid
        this.findPartner(searchPartner)
        //this.$store.dispatch('setPartnerid', null)
        this.havePartners = false
      }
     // this.partner()
    },
    'myPartnerIBAN' () {      
      if (this.$store.state.partnerIBAN) {
        this.defaultItem.partnerIBAN = this.$store.state.partnerIBAN
      }
     // this.partner()
    },
    'myAccount4id' () {
      this.defaultItem.Account4id = this.$store.state.account4id ? this.$store.state.account4id : null
      if (this.defaultItem.Account4id) {
        const searchAccount4 = {}
        searchAccount4.account4 = this.defaultItem.Account4id
        this.choosedAcc(searchAccount4)
        this.haveAccounts = false
      }
     // this.$store.dispatch('setAccount4id', null)
     // this.account()
    },
    'mydialogState' () {
      this.showAccount4Add = this.mydialogState
    },
    'defaultItem.Debit' () {
      this.defaultItem.Debit= round(parseNum(this.defaultItem.Debit), 2)
    },
    'defaultItem.Credit' () {
      this.defaultItem.Credit= round(parseNum(this.defaultItem.Credit), 2)
    }
  },
  methods: {
      formatPriceHR (value) {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      partner() {
        this.havePartners = !this.havePartners
      },
      account() {
        this.haveAccounts = !this.haveAccounts
      },
      account4add() {
        this.$store.dispatch('setDialogState', true)
      },
      bookin() {
        this.haveBookIn = !this.haveBookIn
        this.docSide = 1
      },
      async checkByIban2() {
          if (this.showBsItem && this.$store.state.mainledgerSideItem.partnerBankIBAN){
            const cIban = {
              partnerBankIBAN: this.$store.state.mainledgerSideItem.partnerBankIBAN
            }

            this.error = null
            this.showPr = {}
            await PartnerService.checkIBAN(cIban)
            .then(res => {
              if (res.data.partner) {
                const pr = res.data.partner
                this.showPr.partnerId = pr.id ? pr.id : null
                this.showPr.partnerName = pr.partnerName ? pr.partnerName : null
                this.showPr.partnerNameShort = pr.partnerNameShort ? pr.partnerNameShort : null
                this.showPr.partnerNameDatabase = pr.partnerNameDatabase ? pr.partnerNameDatabase : null
                this.showPr.partnerVATID = pr.partnerVATID ? pr.partnerVATID : null
                this.showPr.partnerStreet = pr.partnerStreet ? pr.partnerStreet : null
                this.showPr.partnerCity = pr.partnerCity ? pr.partnerCity : null
                this.showPr.partnerPostcode = pr.partnerPostcode ? pr.partnerPostcode : null
                this.$store.dispatch('setPartnerid', this.showPr.partnerId)
                this.$store.dispatch('setPartnerVATID', pr.partnerVATID)
                this.alertText1 = 'Account 1200 error!'
                //this.partnerError(this.lang.partnerExist, 'green')
                
              }
            })
            .catch(err => {
              // eslint-disable-next-line
              console.log('Partner not exist', err)
            })
          }
      },
      haveAlert() {
        // if is book in or book out
        this.showAlert = false
        const cond1 = this.defaultItem.account4.substr(0,2) === '12' ? true : false
        const cond2 = this.defaultItem.account4.substr(0,2) === '22' ? true : false
        if (!cond1 || !cond2) {
          if (this.showBsItem) {
            if (cond1 && round(parseNum(this.defaultItem.Debit), 2) !== 0) {
              if (this.$store.state.language === "EN") {
                this.alertText1 = 'Account 1200 error!'
                this.alertText2 = 'For account 1200 you need amount on credit side!'
              }
              if (this.$store.state.language === "HR") {
                this.alertText1 = 'Konto 1200 greška!'
                this.alertText2 = 'Za konto 1200 morate imati promet na potražnoj strani!'
              }
              this.showAlert = true
            }
            if (cond2 && round(parseNum(this.defaultItem.Credit), 2) !== 0) {
              if (this.$store.state.language === "EN") {
                this.alertText1 = 'Konto 2200 greška!'
                this.alertText2 = 'Za konto 2200 morate imati promet na dugovnoj strani!'
              }
              if (this.$store.state.language === "HR") {
                this.alertText1 = 'Account 2200 error!'
                this.alertText2 = 'For account 2200 you need amount on debit side!'
              }
              this.showAlert = true
            }
          }
          if ((cond1 || cond2) && !this.defaultItem.partnerId) {
            this.showAlert = true
          }

        }
        if (!this.showAlert) {
          this.checkValues ()
        }
      },
      haveAlert2() {
        this.showAlert = false
        this.checkValues ()
      },
      openCard(type) {
        console.log('Otvorene stavke type', type)
        if (!this.defaultItem.account4) {
          this.text = this.langC.AccountMissing
          this.color = 'red'
          this.snackbar = true
        } else {
          const cond1 = this.defaultItem.account4.substr(0,2) !== '12' ? false : true
          const cond2 = this.defaultItem.account4.substr(0,2) !== '22' ? false : true

          if ((cond1 === false && cond2 === false ) ) {
            this.text = this.langC.AccountIsNotSupplierBuyer
            this.color = 'red'
            this.snackbar = true
          } else {
            if (type === 1) {
              console.log('zovem 3')
              this.showCards(3)
              // if (!this.defaultItem.partnerId) {
              //   this.showCards(5)
              // } else {
              //   this.showCards(3)
              // }
            }
            if (type === 2) {
              this.showCards(2)
            }
          }
          
        }
      },
      bookout() {
        this.haveBookOut = !this.haveBookOut
        this.docSide = 2
      },
      getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min) ) + min;
      },
      close() {
        this.$store.dispatch('seteditItem', 0)
        this.$store.dispatch('setPartnerIBAN', null)
        this.$store.dispatch('setPartnerid', null)
        this.$store.dispatch('setPartnerVATID', null)
        this.$router.push({
          name: 'mainledgerdetail'
        })
      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      },
      getList  (item, queryText, itemText) {
        return itemText.toLocaleLowerCase().startsWith(queryText.toLocaleLowerCase())
      },
      async copyPartnerIBAN() {
        //this.showBsItem = this.$store.state.mainledgerSideItem ? true : false

        if (this.selectPa) {
           this.saving = true
            const locPartner = await this.partners.find(partner => (partner.id === this.selectPa.id))
            this.saving = false
            if (!this.defaultItem.partnerIBAN && this.showBsItem) {
              this.defaultItem.partnerIBAN = this.$store.state.mainledgerSideItem && this.$store.state.mainledgerSideItem.partnerBankIBAN ? this.$store.state.mainledgerSideItem.partnerBankIBAN : null
            }
            if (!this.defaultItem.partnerIBAN) {
              this.text = 'NO IBAN!!'
              this.color = 'red'
              this.snackbar = true
            }
            if (locPartner && this.defaultItem.partnerIBAN) {              
              locPartner.partnerBankIBAN = this.defaultItem.partnerIBAN ? this.defaultItem.partnerIBAN: ''
              await PartnerService.put(locPartner)
              this.text = 'Partner IBAN updated OK'
              this.color = 'green'
              this.snackbar = true
              // await checkPartners(1)
              await newUpdates(2)
              await testLastUpdate()
              if (this.$store.state.partnerStore) {
                this.partners = this.$store.state.partnerStore
              }
            } 
        } 
        if (!this.selectPa) {
          this.text = 'Partner is empty!!'
          this.color = 'red'
          this.snackbar = true
        }
      },
      async findPartner (selectedPa) {
        if (selectedPa) {
          // in partners array I dont have all required data, for faster serach 
          // const locPartner = this.partners.find(partner => (partner.id === id)) and then need to contact API
          if (parseInt(selectedPa.id) !== parseInt(this.defaultItem.partnerId)) {
            this.saving = true
            const locPartner = await this.partners.find(partner => (partner.id === selectedPa.id))
            this.saving = false
            if (locPartner) {              
              this.selectPa = locPartner
              //this.selectPa.partnerName = locPartner.partnerName
              this.defaultItem.partnerId = locPartner.id ? locPartner.id : null
              this.defaultItem.partnerName = locPartner.partnerName ? locPartner.partnerName : null
              this.defaultItem.partnerVATID = locPartner.partnerVATID ? locPartner.partnerVATID : null
              this.defaultItem.partnerIBAN = locPartner.partnerBankIBAN ? locPartner.partnerBankIBAN : null
              this.defaultItem.partneremail = locPartner.partneremail ? locPartner.partneremail : null
              this.defaultItem.partnerStreet = locPartner.partnerStreet ? locPartner.partnerStreet : null
              this.defaultItem.partnerBuildingNameNumber = locPartner.partnerBuildingNameNumber ? locPartner.partnerBuildingNameNumber : null
              this.defaultItem.partnerCity = locPartner.partnerCity ? locPartner.partnerCity : null
              this.defaultItem.partnerPostcode = locPartner.partnerPostcode ? locPartner.partnerPostcode : null
              this.$store.dispatch('setPartnerid', this.defaultItem.partnerId)
              this.$store.dispatch('setPartnerVATID', locPartner.partnerVATID)
            }            
          }
        } else {
          this.selectPa = {}
          this.defaultItem.partnerId = null
          this.defaultItem.partnerName = null
          this.defaultItem.partnerVATID = null
          this.defaultItem.partnerIBAN = null
          this.defaultItem.partneremail = null
          this.defaultItem.partnerStreet = null
          this.defaultItem.partnerBuildingNameNumber = null
          this.defaultItem.partnerCity = null
          this.defaultItem.partnerPostcode = null
          this.$store.dispatch('setPartnerid', null)
          this.$store.dispatch('setPartnerVATID', null)
        }
      },
      async findInvoice() {
        if (this.defaultItem.account4 && (this.defaultItem.invoiceInNumber || this.defaultItem.invoiceOutNumber)){
          this.mainQuery = {}
          this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
          this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
          this.mainQuery.account4 = this.defaultItem.account4 ? this.defaultItem.account4 : ''
          this.mainQuery.invoiceInNumber = this.defaultItem.invoiceInNumber ? this.defaultItem.invoiceInNumber : ''
          this.mainQuery.invoiceOutNumber = this.defaultItem.invoiceOutNumber ? this.defaultItem.invoiceOutNumber : ''
          
          await MainLedgerDetailService.findinvoice(this.mainQuery)
          .then(res => {  
            this.text = this.langC.NumberNotExist
            this.color = 'green'
            this.snackbar = true
            if (res.data.documents[0]){
              const inv = res.data.documents[0]
              const parCheck = {
                id: parseInt(inv.partnerId)
              }
              this.findPartner(parCheck)
            }
          })
          .catch(err => {
            this.text = this.langC.NumberNotExist + err
            this.color = 'red'
            this.snackbar = true
          })
        } else {
          const err1 = !this.defaultItem.account4 ? this.langC.AccountMissing : ''
          const err2 = !this.defaultItem.invoiceInNumber ? this.langC.InvoiceNumberMissing : null
          const err3 = !this.defaultItem.invoiceOutNumber ? this.langC.InvoiceNumberMissing : ''
          this.text = err1 + err2 + err3
          this.color = 'orange'
          this.snackbar = true
        }
      },
      checkValues() {
        this.localError = false
        let errMsg = ''
        if (!this.defaultItem.account4) {
          errMsg = this.lang.errorNoAccount
          this.localError = true
        }
        if (!this.defaultItem.Debit && !this.defaultItem.Credit) {
          errMsg = errMsg + this.lang.errorNoDebitCredit
          this.localError = true
        }
        if (round(parseNum(this.defaultItem.Debit),2) === 0 && round(parseNum(this.defaultItem.Credit),2) === 0) {
          errMsg = errMsg + this.lang.errorZero
          this.localError = true
        }
        if (this.localError === false) {
          this.defaultItem.Debit = round(this.defaultItem.Debit, 2)
          this.defaultItem.Credit = round(this.defaultItem.Credit, 2)
          this.register()
        } else {
          this.text = this.langC.ErrorE + errMsg
          this.color = 'red'
          this.snackbar = true
        }
      },
      async showCards (type) {
        try {
          
          // do analysis from database
          this.error = null
          this.localError = false
          this.showCard1 = false
          this.showCard2 = false
          this.showCard3 = false
          this.showCard4 = false
          this.showCard5 = false
          this.mainledgerAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
          this.mainledgerAna.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : null
          this.mainledgerAna.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
          // type 1 = balance sheet, 2 = partner card,, 3 = partner open, 4 = analysis, 5= all partners open partnerVATID
          this.mainledgerAna.type = type
          if (this.defaultItem.partnerId){
            this.mainledgerAna.partnerId = this.defaultItem.partnerId 
            this.mainledgerAna.partnerVATID = this.defaultItem.partnerVATID ? this.defaultItem.partnerVATID : null
          }
          // if (this.bookTypeSelected.value) {
          //   this.mainledgerAna.bookTypeSelected = parseInt(this.bookTypeSelected.value)
          // }
          this.mainledgerAna.fromAccount = this.defaultItem.account4  ? this.defaultItem.account4  : null
          this.mainledgerAna.toAccount = this.defaultItem.account4  ? this.defaultItem.account4  : null
          this.mainledgerAna.docFromDate = dayjs([this.mainledgerAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
          this.mainledgerAna.docToDate = dayjs([this.mainledgerAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
          console.log('this.mainledgerAna trazim karticu', this.mainledgerAna)
  
          await MainLedgerDetailService.card(this.mainledgerAna)
          .then(res => {
            this.items = res.data.documents ? res.data.documents : []
            if (this.items) {
              this.showCard1 = type === 1 ? true : false
              this.showCard2 = type === 2 ? true : false
              this.showCard3 = type === 3 ? true : false
              this.showCard4 = type === 4 ? true : false
              this.showCard5 = type === 5 ? true : false
            }
          })
          .catch(err => {
            this.error = this.langC.ErrorE + err
          })
          //this.items = (await MainLedgerService.card(this.mainledgerAna)).data.documents
        
        } catch (error) {
          // eslint-disable-next-line
          console.log(' Error 2?? ', error)
          this.error = this.langC.ErrorE + error
        }
      },
      async registerCardSelection () {
        try {
          this.defaultItem.Debit = round(this.defaultItem.Debit, 2)
          this.defaultItem.Credit = round(this.defaultItem.Credit, 2)
          let usedDebit = 0
          let usedCredit = 0
          this.bsNr = this.$store.state.mainledgerActiv.bankStatmentNumber ? parseInt(this.$store.state.mainledgerActiv.bankStatmentNumber) : ''
          let ds1 = this.$store.state.language === "HR" ? 'Izvod ': 'Bank Statement '
          ds1 = ds1 + ' '  + (this.$store.state.mainledgerActiv.bankStatmentNumber ? parseInt(this.$store.state.mainledgerActiv.bankStatmentNumber) : '')
          const ds2 = ', ' + (this.$store.state.mainledgerActiv.bankStatmentDate ? dayjs(this.$store.state.mainledgerActiv.bankStatmentDate).format('DD.MM.YYYY') : '')
          const ds3 = ', ' + (this.$store.state.mainledgerActiv.bankStatmentMoneyName ? this.$store.state.mainledgerActiv.bankStatmentMoneyName : '')

          if (this.$store.state.mainledgerDetailItems && this.$store.state.mainledgerDetailItems.length > 0) {
            this.$store.state.mainledgerDetailItems.map(item => {
              
              const newItem = {...this.defaultItem}
              newItem.id = this.getRndInteger(1000000000, 9000000000)
              newItem.Credit = item.Credit ? round(parseNum(item.Credit), 2) : 0
              newItem.CreditForegin = item.CreditForegin ? round(parseNum(item.CreditForegin), 2) : 0
              newItem.Debit = item.Debit ? round(parseNum(item.Debit), 2) : 0
              newItem.DebitForegin = item.DebitForegin ? round(parseNum(item.DebitForegin), 2) : 0
              newItem.BusinessYearInvoice = item.BusinessYearInvoice ? parseInt(item.BusinessYearInvoice) : null
              newItem.invoiceInNumber = item.invoiceInNumber ? parseInt(item.invoiceInNumber) : null
              newItem.invoiceOutNumber = item.invoiceOutNumber ? parseInt(item.invoiceOutNumber) : null
              newItem.invoiceInID = item.invoiceInID ? parseInt(item.invoiceInID) : null
              newItem.invoiceOutID = item.invoiceOutID ? parseInt(item.invoiceOutID) : null
              newItem.InvoiceNumber = item.InvoiceNumber ? parseInt(item.InvoiceNumber) : null
              newItem.Description = ds1 + ds3 + ds2
              usedDebit += round(newItem.Debit, 2)
              usedCredit += round(newItem.Credit, 2)

              this.activItems.push(newItem)
            })
          }
          let runDebit = this.defaultItem.Debit > 0 && this.defaultItem.Credit <= 0 ? true : false
          usedDebit = round(usedDebit,2)
          usedCredit = round(usedCredit, 2)
          const usedAll = round(Math.abs(Math.abs(usedDebit) - Math.abs(usedCredit)) , 2)
          const haveAll = round(Math.abs(Math.abs(this.defaultItem.Debit) - Math.abs(this.defaultItem.Credit)) ,2)
          // if on selected is not used all amount, save rest in new item
          if (round(haveAll - usedAll, 2) > 0) {
            const newItem = {...this.defaultItem}
              newItem.id = this.getRndInteger(1000000000, 9000000000)
              if (runDebit) {
                newItem.Debit = round(haveAll - usedAll, 2)
                newItem.DebitForegin = round((haveAll - usedAll) / this.exRate, 2)
                newItem.Credit =  0
                newItem.CreditForegin = 0
              }
              if (!runDebit) {
                newItem.Debit =  0
                newItem.DebitForegin =  0
                newItem.Credit = round(haveAll - usedAll, 2)
                newItem.CreditForegin = round((haveAll - usedAll) / this.exRate, 2)
              }
              
              newItem.BusinessYearInvoice =  null
              newItem.invoiceInNumber =  null
              newItem.invoiceOutNumber = null
              newItem.invoiceInID = null
              newItem.invoiceOutID = null
              newItem.InvoiceNumber = null
              newItem.Description = this.$store.state.language === "HR" ? 'Razlika po Izvodu ' + ds1 + ds3 + ds2: 'Diff by Bank Stat. ' + ds1 + ds3 + ds2
              this.activItems.push(newItem)
          }

         // console.log('this.defaultItem.Debit ', this.defaultItem.Debit, ' this.defaultItem.Credit', this.defaultItem.Credit, ' usedDebit', usedDebit, round(usedDebit), typeof round(usedDebit),  'usedCredit', usedCredit )

        } catch (err) {
          console.log("🚀 ~ err", err)
        }
      },
      async register () {
        try {
          this.localError = false   
          this.defaultItem.updatedAt = new Date()
          this.defaultItem.LastUpdateBy = this.$store.state.user.email ? this.$store.state.user.email : ''

          if (this.$store.state.editItem === 1) {

        
            this.activItems = this.$store.state.mainledgerItems ? this.$store.state.mainledgerItems : []
            this.activItems.splice(this.itemIndex, 1, this.defaultItem)
            if (this.$store.state.mainledgerDetailItems && this.$store.state.mainledgerDetailItems.length > 0) {
              await this.registerCardSelection()
            }
            if (this.activItems) {
              this.$store.dispatch('setMainLedgerItems', this.activItems)
            } else {
              this.$store.dispatch('setMainLedgerItems', [])
            }
            await this.$store.dispatch('seteditItem', 0)
            
          } else {
            const newItem = this.defaultItem
            this.activItems = this.$store.state.mainledgerItems ? this.$store.state.mainledgerItems : []
            if (this.$store.state.mainledgerDetailItems && this.$store.state.mainledgerDetailItems.length > 0) {
              await this.registerCardSelection()
            } else {
              this.activItems.push(newItem)
            }
            if (this.activItems) {
              this.$store.dispatch('setMainLedgerItems', this.activItems)
            } else {
              this.$store.dispatch('setMainLedgerItems', [])
            }
            
          }

          await this.makeSum()
          // if commit bank statement, then check if it is closed
          if (this.showBsItem) {
            await this.markClosedItem()
          }
          this.close()
        } catch (error) {
          // eslint-disable-next-line
          console.log(' Register Error', error)
          this.text = this.langC.ErrorE + error
          this.color = 'red'
          this.snackbar = true
        }
      },
      async markUNClosedItem(haveID) {
        // if have data from bank statement check if item is closed in activ items
        if (haveID) {
          this.mainQuery.id = haveID
          this.mainQuery.ClosedAccount = false
          await MainLedgerSideService.markclosed(this.mainQuery)
        }
      },
      async markClosedItem() {
        // if have data from bank statement check if item is closed in activ items
        if (this.showBsItem) {
          const selActivItems = this.activItems.filter(item => item.bankStatmentDetailDBNumber === this.$store.state.mainledgerSideItem.id)
          let selDebit = 0
          let selCredit = 0
          if (selActivItems && selActivItems.length > 0) {
            selActivItems.map(item => {
              selDebit += round(item.Debit, 2)
              selCredit += round(item.Credit, 2)
            })
            const newObj = {}
            this.mainQuery = {...newObj}
            let ClosedAccount = false            
            if (selDebit === round(parseNum(this.$store.state.mainledgerSideItem.bankStatmentDebit),2) && selCredit === round(parseNum(this.$store.state.mainledgerSideItem.bankStatmentCredit),2) ) {
              // mark side item in database as closed 
              ClosedAccount = true
            }
            this.mainQuery.id = this.$store.state.mainledgerSideItem.id
            this.mainQuery.ClosedAccount = ClosedAccount
            // MainLedgerSideService ==> mark status 
            await MainLedgerSideService.markclosed(this.mainQuery)
            try { 
              // eslint-disable-next-line
              console.log(' mark closed ok ')
            } catch(err) {
              // eslint-disable-next-line
             console.log(' Error on mark closed ', err)
            }
            
          }

        }
      },
      async makeSum() {
        const updateSumObject = {
          DebitTotal: 0,
          CreditTotal: 0,
          DebitForeginTotal: 0,
          CreditForeginTotal: 0,
          DiffTotal: 0,
          ClosedAccount: false
        }
        let status = false
        let nrItems = 0
        if (this.$store.state.mainledgerItems) {
          this.$store.state.mainledgerItems.map(mldetail => {
            updateSumObject.DebitTotal += round(parseFloat(mldetail.Debit), 2)
            updateSumObject.CreditTotal += round(parseFloat(mldetail.Credit), 2)
            updateSumObject.DebitForeginTotal += round(parseFloat(mldetail.DebitForegin), 2)
            updateSumObject.CreditForeginTotal += round(parseFloat(mldetail.DebitForegin), 2)
            nrItems += 1
          })
        }
        // if debit and credit are diff than 0, then check if they are same 
        // if they are same then account is OK, closed, if they are diff, then
        // account is still not same ...
        const debitTotal = updateSumObject.DebitTotal ? round(Math.abs(updateSumObject.DebitTotal),2) : 0
        const creditTotal = updateSumObject.CreditTotal ? round(Math.abs(updateSumObject.CreditTotal),2) : 0
        const newItems = []
        if (debitTotal !== 0 || creditTotal !== 0) {
          updateSumObject.DiffTotal = round(debitTotal,2) - round(creditTotal,2)
          if (updateSumObject.DiffTotal === 0) {
            updateSumObject.ClosedAccount = true
            status = true
          }
        }
        // if have items but totals are 0
        if (debitTotal === 0 && creditTotal === 0 && nrItems > 0) {
          updateSumObject.DiffTotal = round(debitTotal,2) - round(creditTotal,2)
          if (updateSumObject.DiffTotal === 0) {
            updateSumObject.ClosedAccount = true
            
            status = true
          }
        }
        const newMainLedgerActiv = {...this.$store.state.mainledgerActiv, ...updateSumObject}
        if (this.$store.state.mainledgerItems) {
          this.$store.state.mainledgerItems.map(mldetail => {
            const updateStatus = {
              ClosedAccount: status
            }
            const newItem = {...mldetail, ...updateStatus}
            newItems.push(newItem)
          })
        }
        //console.log('detalj update Glave ', newMainLedgerActiv.DebitTotal, newMainLedgerActiv.CreditTotal)
        await this.$store.dispatch('setMainLedgerActiv', newMainLedgerActiv)
      },
      choosedAcc (choosedAcc) {
        if (choosedAcc) {
        
          const result = this.account4s.filter(element => element.account4 === choosedAcc.account4)
          if (result[0]) {
            const res = result[0]
            this.defaultItem.account1 = res.account1
            this.defaultItem.account2 = res.account2
            this.defaultItem.account3 = res.account3
            this.defaultItem.account4 = res.account4
            this.defaultItem.account4id = res.id
            this.defaultItem.account4Description = res.account4Description
            this.defaultItem.account4Description2 = res.account4Description2
            this.selectA4 = res
            // if (res.account1 === '4' || res.account1 === '7') {
            //   this.showPT = true
            // } else {
            //   this.showPT = false
            // }

          } else {
            this.text = this.langC.AccountMissing
            this.color = 'red'
            this.snackbar = true
          }
        } else {
          this.defaultItem.account1 = null
          this.defaultItem.account2 = null
          this.defaultItem.account3 = null
          this.defaultItem.account4 = null
          this.defaultItem.account4id = null
          this.defaultItem.account4Description = null
          this.defaultItem.account4Description2 = null
        }     
      },
      choosedProjectType (choosedPT) {
        if (choosedPT) {
          const result = this.projectTypes.filter(element => element.id === choosedPT.id)
          if (result[0]) {
            const res = result[0]
            this.defaultItem.TypeID = res.id
            this.defaultItem.TypeName = res.TypeName
            this.selectPT = res
          } else {
            this.text = this.lang.MainLedgerProjectTypeEmpty
            this.color = 'red'
            this.snackbar = true
          }
        } else {
          this.defaultItem.TypeID = null
          this.defaultItem.TypeName = null
        }     
      },
      async deleteDD2 () {
      try {
        if (this.$store.state.mainledgerdetailid) {
          const newActivItems = this.$store.state.mainledgerItems.filter(item => {
            return item.id !== this.$store.state.mainledgerdetailid
          })
          if (newActivItems) {
              this.$store.dispatch('setMainLedgerItems', newActivItems)
            } else {
              this.$store.dispatch('setMainLedgerItems', [])
            }
            await this.$store.dispatch('seteditItem', 0)
          // make sum local head
          await this.markUNClosedItem(this.defaultItem.bankStatmentDetailDBNumber)
          await this.makeSum()
          this.close()
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
        this.text = this.langC.ErrorE + err
        this.color = 'red'
        this.snackbar = true
      }
    },
    async addeditPartner () {
        const addeditPartner = {...defNewpartner()}
        addeditPartner.CompanyId = this.defaultItem.CompanyId ? this.defaultItem.CompanyId : ''
        addeditPartner.CompanyNameDatabase = this.defaultItem.CompanyNameDatabase
        addeditPartner.BusinessYear = this.defaultItem.BusinessYear
        addeditPartner.UserId = this.defaultItem.UserId
        addeditPartner.email = this.defaultItem.email
        addeditPartner.First = this.defaultItem.First
        addeditPartner.Last = this.defaultItem.Last
        addeditPartner.partnerName = this.defaultItem.partnerName
        addeditPartner.partnerNameShort = this.defaultItem.partnerName.replace(/[\W_]/g, '')
        addeditPartner.partnerNameDatabase = this.defaultItem.partnerName.replace(/[\W_]/g, '')
        addeditPartner.partnerBuildingNameNumber = this.defaultItem.partnerBuildingNameNumber
        addeditPartner.partnerStreet = this.defaultItem.partnerStreet
        addeditPartner.partnerCity = this.defaultItem.partnerCity
        addeditPartner.partnerPostcode = this.defaultItem.partnerPostcode
        addeditPartner.partnerCountyRegion = this.defaultItem.partnerCountyRegion
        addeditPartner.partnerState = this.defaultItem.partnerState
        addeditPartner.partnerVATID = this.defaultItem.partnerVATID
        addeditPartner.partneremail = this.defaultItem.partneremail
        addeditPartner.partnerPhones = this.defaultItem.partnerPhones
        addeditPartner.partnerBankIBAN = this.defaultItem.partnerIBAN
        this.saving = true
        if (this.defaultItem.partnerId) {
            addeditPartner.id = this.defaultItem.partnerId
            this.saving = true
            await PartnerService.put(addeditPartner)
            this.saving = false
            this.text = 'Partner updated!'
            this.color = 'green'
            this.snackbar = true
            this.timeout = 2000
            this.reloadPartners(this.defaultItem.partnerId)
        } else {
          if (addeditPartner.partnerName) {
            this.localError = null
            this.mainQuery2 = {}
            // if is private person then dont check
            if (addeditPartner.privatePerson === false) {
              // check if vatid exist and if exist then do not add partner
              if (!addeditPartner.partneremail) {
                this.localError = 'Error partner dot have email ! '
              }
              if (!addeditPartner.partnerVATID) {
                this.localError =  this.localError + ', Error partner dot have VAT ID or mark for private person '
              }
              if (addeditPartner.partnerVATID) {
                this.mainQuery2.partnerVATID = addeditPartner.partnerVATID
              }
              if (addeditPartner.partneremail) {
                this.mainQuery2.partneremail = addeditPartner.partneremail
              }
              if (!this.localError) {
                await PartnerService.check(this.mainQuery2)
                  .then(res => {
                    if (res.data.partner) {
                      this.haveItem = res.data.partner
                      this.localError = this.lang.PartnerErrorVAT + (this.haveItem.partnerName ? this.haveItem.partnerName : '')
                    }
                    if (res.data.partner2) {
                      this.haveItem = res.data.partner2
                      this.localError = this.lang.PartnerErroremail + (this.haveItem.partnerName ? this.haveItem.partnerName : '')
                    }                
                  })
                  .catch(err => {
                    this.error = err.data
                    this.localError = false
                  })
              }
            }
            // can save as private person ONLY in document ServiceNotes and QuoteSales
            if (!this.localError) {
              this.saving = true
              const newPartner = await PartnerService.post(addeditPartner)
              this.saving = false
              this.text = this.lang.Partneradded
              this.color = 'green'
              this.snackbar = true
              this.timeout = 2000
              this.reloadPartners(newPartner.data.partner.id)
            } else {
              this.text = this.localError
              this.color = 'red'
              this.snackbar = true
            }
            
          } else {
            // not enough data to save partner 
            this.text = 'Error! Partner name is empty. Can not save partner.'
            this.color = 'red'
            this.snackbar = true
            this.timeout = 4000
          } 
        }
        this.saving = false
    },
    async reloadPartners(haveId) {
      // await checkPartners(1)
      await newUpdates(2)
      await testLastUpdate()
      if (this.$store.state.partnerStore) {
        this.partners = this.$store.state.partnerStore
      }
      //this.partners = (await PartnerService.index()).data.partners
      this.$store.dispatch("setPartners",  this.partners ? this.partners : [])
      this.defaultItem.partnerId = haveId
      this.selectId = this.defaultItem.partnerId ? parseInt(this.defaultItem.partnerId) : null
      this.selectPa = this.selectId ? this.partners.find(partner => (partner.id === this.selectId)) : null
    }
  }
}
</script>