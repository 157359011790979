<template>
  <div class="documentprintpostotal">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'

export default {
  name: 'documentprintpostotal',
  props: ['printObj'],
  data () {
    return {
      msg: '',
      documentName: '',
      documentName2: '',
      document: {},
      havePath: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locDocument: {},
      fields1: [ 'itemName', 'ItemCode', 'itemUnit', 'itemService', 'itemPrice', 'itemPcsNeutral', 'itemBaseAmount', 'itemDiscountPercentage1', 'itemBaseAmountNoVAT', 'itemAmountWithVAT' ],
      items1: [
      ],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      fields2: [ 'vat', 'base', 'amount' ],
      items2: [
        { vat: '5%', base: 100.00, amount: 5.00 },
        { vat: '13%', base: 200.00, amount: 26.00 },
        { vat: '25%', base: 400.00, amount: 100.00 }
      ],
      comp: {},
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        databaseDocID: 0
      },
      order: 0,
      loCdocumentDate: '',
      loCorderDate: '',
      loCdocumentInvoiceStartDate: '',
      loCdocumentInvoiceTime: '',
      loCdocumentInvoiceEndDate: '',
      loCdocumentConnDate: '',
      loCdeliveryDate: '',
      loCorderTime: '',
      loCdocumentConnTime: '',
      loCdeliveryTime: '',
      locDocumentId: '',
      output: null,
      documentNameLoc: '',
      docDefinition: null,
      profitLoss: 0,
      profitLossP: '',
      pP1: [],
      pP2: [],
      pP3: [],
      pP4: [],
      pP5: [],
      pP6: [],
      pP7: [],
      pP8: [],
      pP9: [],
      pP10: [],
      pD1: [],
      pD2: [],
      pD3: [],
      pD4: [],
      pD5: [],
      pD6: [],
      pD7: [],
      pD8: [],
      pD9: [],
      pD10: [],
      pT1: [],
      pT2: [],
      pT3: [],
      pT4: [],
      pT5: [],
      pT6: [],
      pT7: [],
      pT8: [],
      pT9: [],
      pT10: [],
      pC1: [],
      pC2: [],
      pC3: [],
      pC4: [],
      pC5: [],
      pC6: [],
      pC7: [],
      pC8: [],
      pC9: [],
      pC10: [],
      pC11: [],
      pC1tot: 0,
      pC2tot: 0,
      pC3tot: 0,
      pC4tot: 0,
      pC5tot: 0,
      pC6tot: 0,
      pC7tot: 0,
      pC8tot: 0,
      pC9tot: 0,
      pC10tot: 0,
      pD1tot: 0,
      pD2tot: 0,
      pD3tot: 0,
      pD4tot: 0,
      pD5tot: 0,
      pD6tot: 0,
      pD7tot: 0,
      pD8tot: 0,
      pD9tot: 0,
      pD10tot: 0,
      pTtt1: 0,
      pTtt2: 0,
      pTtt3: 0,
      pTtt4: 0,
      pTtt5: 0,
      pTtt6: 0,
      pTtt7: 0,
      pTtt8: 0,
      pTtt9: 0,
      pTtt10: 0,
      pTtt11: 0,
      pSum00: 0,
      pSum01: 0,
      pSum1: 0,
      pSum2: 0,
      pSum3: 0,
      pSum4: 0,
      pSum5: 0,
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
      docDefinition2: {},
      content2: [],
      logoType: 0,
      printSmall: false, 
      printNoPrice: false,
      itemdesc: '',
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.documentActivHead) {
      this.document = this.$store.state.documentActivHead
      this.documentNameLoc = this.$store.state.documentActivLoc
    }
    this.printSmall = false
    if (this.$store.state.printSmall === true) {
      this.printSmall = true
    }
  },
  methods: {
    print () {
      this.printSmall = true
      this.printNoPrice = false
      const currDate = Date.now()
      this.locCreatedAt = dayjs(currDate).format('DD.MM.YYYY HH:mm:SS')
      this.prepareData()
      this.printSmallDoc()
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    prepareData() {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentid) {
        this.mainQuery.databaseDocID = this.$store.state.documentid
      }
      if (this.$store.state.documentActivHead) {
        this.document = this.$store.state.documentActivHead
      }
      if (this.$store.state.companyActiv.CompanyLogo1) {
        this.logoType = 1
      }
      if (this.$store.state.companyActiv.CompanyLogo2) {
        this.logoType = 2
      }
      if (this.$store.state.companyActiv.CompanyLogo3) {
        this.logoType = 3
      }
      this.documentName2 = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      this.pSum00 = this.formatPrice(this.document.documentDescription9)
      this.pSum01 = this.formatPrice(this.document.documentDescription10)
      this.pSum1 = this.formatPrice(this.document.documentTotalAmountNetto)
      this.pSum2 = this.formatPrice(this.document.documentTotalAmountVAT)
      this.pSum3 = this.formatPrice(this.document.documentTotalAmountBrutto)
      this.pSum4 = this.formatPrice(this.document.documentTotalAmountPayInAdvance)
      this.pSum5 = this.formatPrice(this.document.documentTotalAmountToPay)
      this.comp = this.$store.state.companyActiv
      this.order = 0

      // this.locCreatedAt = dayjs(this.document.CreatedAt).format('DD.MM.YYYY HH:MM')
      this.locUpdatedAt = dayjs(this.document.UpdatedAt).format('DD.MM.YYYY HH:MM')
      this.loCdocumentInvoiceTime = dayjs(this.document.UpdatedAt).format('HH:MM')
      this.loCdocumentDate = this.document.documentDate ? dayjs(this.document.documentDate).format('DD.MM.YYYY') : ' '
      this.loCorderDate = this.document.orderDate ? dayjs(this.document.orderDate).format('DD.MM.YYYY') : ' '
      this.loCdocumentInvoiceStartDate = this.document.documentInvoiceStartDate ? dayjs(this.document.documentInvoiceStartDate).format('DD.MM.YYYY') : ' '
      this.loCdocumentInvoiceEndDate = this.document.documentInvoiceEndDate ? dayjs(this.document.documentInvoiceEndDate).format('DD.MM.YYYY') : ' '      
      this.loCdocumentConnDate =  this.document.documentConnDate ? dayjs(this.document.documentConnDate).format('DD.MM.YYYY') : ' '
      this.loCdeliveryDate = this.document.deliveryDate ? dayjs(this.document.deliveryDate).format('DD.MM.YYYY') : ' '
      this.loCorderTime = this.document.orderTime ? dayjs(this.document.orderTime).format('HH:MM') : ' '
      this.loCdocumentConnTime = this.document.documentConnTime ?  dayjs(this.document.documentConnTime).format('HH:MM') : ' '
      this.loCdeliveryTime = this.document.deliveryTime ? dayjs(this.document.deliveryTime).format('HH:MM') : ' '
      if (this.$store.state.documentActiv === 'InvoiceSales') {
        this.locDocumentId = this.document.documentId + '/' + this.$store.state.companyActiv.CompanyBusinessSpace + '/' + this.$store.state.companyActiv.CompanyBusinessDevice
      } else {
        this.locDocumentId = this.document.documentId
      }
      this.profitLoss = this.document.FreeF4 - this.document.FreeF1 - this.document.FreeF2 - this.document.FreeF3
      this.profitLossP = this.profitLoss.toFixed(2)
    },

    printSmallDoc () {
      this.initDocumentSmall()
      if (this.logoType === 1) {
        this.addHeaderWithLogoLeftSmall()
      } else {
        this.addHeaderNoLogo()
      }

      this.addLineSmall2()
      // this.addHeadSmall()
      this.addLineSmall2()
      this.addTotals()
      this.addCardsSmall()
      this.addTotalsPrint()
      this.addLineSmall2()
      
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },

    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.content = []
    },
    initDocumentSmall () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A6'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 10, 10, 10, 10 ]
      this.content = []
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addLineSmall () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 100,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addLineSmall2 () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 280,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addTotals() {
            const docPart = {
            columns: [
              {
                // % width
                width: '80%',
                text: [
                  { text: ' Obračun prometa kase \n', fontSize: 14, bold: true },
                  { text: ' od: ' + dayjs(this.printObj.startDate).format('DD.MM.YYYY') + ' do: ' + dayjs(this.printObj.endDate).format('DD.MM.YYYY') + ' \n', fontSize: 10 },
                  { text: ' ====================================== \n', fontSize: 10 },
                  { text: this.printObj.line1 + ' \n', fontSize: 12, bold: true },
                  { text: ' ====================================== \n', fontSize: 10 },
                  { text: this.printObj.line2 + ' \n', fontSize: 10 },
                  { text: this.printObj.line3 + ' \n', fontSize: 10 },
                  { text: this.printObj.line4 + ' \n', fontSize: 10 },
                  { text: this.printObj.line5 + ' \n', fontSize: 10 },
                  { text: this.printObj.line6 + ' \n', fontSize: 10 },
                  { text: this.printObj.line7 + ' \n', fontSize: 10 },
                  { text: this.printObj.line8 + ' \n', fontSize: 10 },
                  { text: this.printObj.line9 + ' \n', fontSize: 10, bold: true  },
                  { text: this.printObj.line10 + ' \n', fontSize: 10 , bold: true },
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addTotalsPrint() {
            const docPart = {
            columns: [
              {
                // % width
                width: '80%',
                text: [
                  { text: ' -------------------------------------- \n', fontSize: 10 },
                  { text: 'Ispisano: ' + this.locCreatedAt + ' \n', fontSize: 10 },
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addCardsSmall () {
          this.order = 0
            if (this.printObj.cardAmounts) {
              this.printObj.cardAmounts.map(item => {
                  this.order += 1
                  const docPart = {
                  columns: [
                        {
                          width: '4%',
                          fontSize: 8,
                          alignment: 'left',
                          text: this.order
                        },
                        {
                          width: '32%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.card
                        },
                        {
                          width: '25%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.total)
                        },
                      ]
                  }
                  this.content.push(docPart)
                
              })
          }
    },
    addHeaderWithLogoLeft () {
      const docPart = {
            columns: [
              {
                width: '4%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '51%',
                image: this.comp.CompanyLogo1,
                fit: [70, 70]
              },
              {
                // % width
                width: '40%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: 'email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + ': ' +  (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeaderWithLogoLeftSmall () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '55%',
                image: this.comp.CompanyLogo1,
                fit: [70, 70]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 },
                  { text: this.lang.DocPrintRetail + + (this.comp.CompanyLogo5 ? this.comp.CompanyLogo5 : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 1
          }
        this.content.push(docPart)
    },
    addHeaderWithLogoRight () {
      const docPart = {
            columns: [
              {
                width: '7%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                // % width
                width: '50%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: 'email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '31%',
                image: this.comp.CompanyLogo2,
                fit: [70, 70]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeaderWithLogoFull () {
      const docPart = {
            columns: [
              {
                width: '7%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '81%',
                image: this.comp.CompanyLogo3,
                fit: [800, 80]
              },
            ],
          }
        this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '55%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 18, bold: true },
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: 'email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeadSmall () {
      const docPart = {
        columns: [
              {
                width: '56%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                  { text: this.documentNameLoc + ':' + this.document.documentId + '/' + this.comp.CompanyBusinessSpaceRetail + '/' + this.comp.CompanyBusinessDeviceRetail + ' \n', fontSize: 15 },
                  { text: this.lang.DocPrintDocumentDate + ':' + this.loCdocumentDate + ',' + this.loCdocumentInvoiceTime + '\n', fontSize: 8, bold: true },
                ]
              },
              {
                width: '42%',
                text: [
                  { text: this.lang.DocPrintRetail + ' ' + (this.comp.CompanyLogo5 ? this.comp.CompanyLogo5 : ' ') +  ' \n', fontSize: 7 },
                  { text: this.lang.DocPrintMadeBySmall + ' ' + (this.document.First ? this.document.First : ' ') + ' ' + (this.document.Last ? this.document.Last : ' ') + ': \n', fontSize: 7, alignment: 'left' },
                  { text: this.lang.DocPrintPayTypeSmall1 + ' ' +  ' \n', fontSize: 7, alignment: 'left' },
                  
                ]
              },
            ]
          }
      this.content.push(docPart)
    },
    addHeadComment () {
      const docPart = {
        columns: [
              {
                width: '10%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: ' \n ', fontSize: 8 },
                  { text: 'Predmet: ' + this.document.documentDescription4 + ' \n ', fontSize: 10, bold: true },
                  { text: ' \n ', fontSize: 8 },
                  { text: this.document.documentDescription1 + ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '10%',
                text: ' '
              },
              {
                width: '10%',
                text: [
                  { text: ' \n', fontSize: 15 },
                  { text: ' \n', fontSize: 8, bold: true },
                  { text: ' \n', fontSize: 8 }
                ]
              }
            ]
      }
      this.content.push(docPart)
    },
    addHeadCommentSmall () {
      const docPart = {
        columns: [
              {
                width: '80%',
                text: [
                  { text: 'Predmet: ' + this.document.documentDescription4 + ' \n ', fontSize: 10, bold: true },
                  { text: ' \n ', fontSize: 8 },
                  { text: this.document.documentDescription1 + ' \n ', fontSize: 8 },
                  { text: this.document.documentDescription5 + ' \n ', fontSize: 8 }
                ]
              },
            ]
      }
      this.content.push(docPart)
    },

    addComment () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '80%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text:  this.lang.DocPrintComment + ' ' + this.document.documentDescription5 + ' \n', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addFooterSmall () {
      const docPart = {
        columns: [
              {
                width: '80%',
                text: [
                  { text: this.lang.DocPrintZKI + ' ' + ' \n', fontSize: 8 },
                  { text: this.lang.DocPrintJIR + ' ' + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyInvoiceHint ? this.comp.CompanyInvoiceHint : ' ') + ' \n', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addSignature1 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: this.lang.DocPrintMadeBy + ' ' + (this.document.First ? this.document.First : ' ') + ' ' + (this.document.Last ? this.document.Last : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: this.lang.DocPrintControlBy + ' ' + (this.document.FirstControl ? this.document.FirstControl : ' ') + ' ' + (this.document.LastControl ? this.document.LastControl : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: this.lang.DocPrintCFO + ' ' + (this.document.FirstCFO ? this.document.FirstCFO : ' ') + ' ' + (this.document.LastCFO ? this.document.LastCFO : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addSignature2 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________  \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addSignature3 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: 'Primio na servis: ' + (this.document.First ? this.document.First : ' ') + ' ' + (this.document.Last ? this.document.Last : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: ' Predao : \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addSignature4 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addEmpty () {
      const docPart = {
        
          }
      this.content.push(docPart)
    },
    print3 () {
      this.docDefinition2 = {}
      this.docDefinition2.pageSize = 'A4'
      this.docDefinition2.pageOrientation = 'portrait'
      this.docDefinition2.pageMargins = [ 20, 20, 20, 20 ]
      this.docDefinition2.footer = {
          columns: [ {
            text: [
              { text: '   ' + this.comp.CompanyName, fontSize: 8, bold: true, alignment: 'center' },
              { text: ',' + (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ', ' + (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') , fontSize: 7 },
              { text: ', ' + this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + ', email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ', ' + this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' ') + ' \n', fontSize: 7 },
              { text: '   ' + (this.comp.CompanyDescriptionUp ? this.comp.CompanyDescriptionUp : ' '), fontSize: 7, alignment: 'center' }
            ]
          }
          ]
        }

      const docPart = {
            columns: [
              {
                width: '7%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '51%',
                image: this.comp.CompanyLogo1,
                fit: [70, 70]
              },
              {
                // % width
                width: '30%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: 'email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content2.push(docPart)
        // eslint-disable-next-line
        console.log('Content 2', this.content2)
        const docPart2 = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
        this.content2.push(docPart2)
        
    },

  },
  computed: {
  },
  watch: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
