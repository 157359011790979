<template>
  <div class="groupcreate">
    <v-container grid-list-md text-xs-center>
      <v-layout >
        <v-flex xs4 >
         <form
            name="GroupForm"
            autocomplete="off">
            <h2 class="text-xs-left">Add new group</h2>
            <v-text-field
              label="Group name"
              required
              :rules="[required]"
              v-model="group.GroupName"
            ></v-text-field>
            <v-text-field
              label="Group belongs to id:"
              v-model="group.GroupBelongsTo"
            ></v-text-field>
          </form>
          <br>
          <div class="pink darken-2" v-html="error" />
          <br>
          <v-btn
            class="primary mr-2"
             :disabled="!formIsValid"
            @click="register">
            {{langC.Save}}
          </v-btn>
          <v-btn
            color="green"
            @click="grouplist">
            {{langC.Back}}
          </v-btn>
      </v-flex>
    </v-layout>
    </v-container>
  </div>
</template>

<script>
import GroupService from '@/services/GroupService'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'

export default {
  name: 'groupcreate',
  $validates: true,
  data () {
    return {
      menu: false,
      modal: false,
      msg: '',
      valid: true,
      lang: {},
      langC: {},
      group: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 2022,
        UserId: 0,
        email: '',
        GroupName: '',
        GroupBelongsTo: 0,
        EnteredInDatabase: null,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: '',
        LastUpdate: null
      },
      locUpdatedAt: '',
      error: null,
      errors: [],
      required: (value) => !!value || 'Required.',
      groupsLoc: [],
      mainQuery: {}
    }
  },
  components: {
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.langC = commHr
    }
  },
  computed: {
    formIsValid () {
      return this.group.groupName !== ''
    }
  },
  methods: {
    async register () {
      try {
        if (this.$store.state.user.id) {
          this.group.UserId = this.$store.state.user.id
        }
        if (this.$store.state.user.email) {
          this.group.email = this.$store.state.user.email
        }
        if (this.$store.state.companyid) {
          this.group.CompanyId = this.$store.state.companyid
          this.mainQuery.CompanyId = this.$store.state.companyid
        }
        if (this.$store.state.companyName) {
          this.group.CompanyNameDatabase = this.$store.state.companyName
        }
        if (this.$store.state.businessYear) {
          this.group.BusinessYear = this.$store.state.businessYear
        }
        await GroupService.post(this.group)

        this.groupsLoc = (await GroupService.index(this.mainQuery)).data.groups
        if (this.groupsLoc) {
          this.$store.dispatch('setActivGroups', this.groupsLoc)
        }
        this.$router.push({
          name: 'group'
        })
      } catch (error) {
        this.error = error.response.data.error
      }
    },
    async grouplist () {
      try {
        await this.$router.push({
          name: 'group'
        })
      } catch (err) {
        this.error = err
      }
    }
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
