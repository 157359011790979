<template>
  <v-layout row justify-center>
      <v-card>
        <v-card-title class="headline">
          
              <span v-if="this.$store.state.language == 'EN'">
              Are you sure to delete Document? {{ documentNameLoc }}
              </span>
              <span v-if="this.$store.state.language == 'HR'">
              {{ documentNameLoc }} - da li ste sigurni da ga želite obrisati?
              </span>
          </v-card-title>
        <v-card-text>
              <span v-if="this.$store.state.language == 'EN'">
                We not recomend to delete document. Better reuse it.
              </span>
              <span v-if="this.$store.state.language == 'HR'">
               Ne preporučamo brisanje, upotrijebite ga sa drugim podacima. Ako niste sigurni, kontaktirajte asministratora. Hvala.
              </span>
          
         </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1"  @click="deleteDocument">Delete</v-btn>
          <v-btn color="green darken-1"  @click="documentlist">Cancel</v-btn>
        </v-card-actions>
      </v-card>
  </v-layout>
</template>

<script>
import DocumentService from '@/services/DocumentService'

export default {
  name: 'documentdelete',
  data () {
    return {
      msg: '',
      dialog: false,
      documentName: '',
      documentNameLoc: '',
    }
  },
  mounted() {
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
  },
  methods: {
    async deleteDocument () {
      try {
        if (this.$store.state.documentid) {
          await DocumentService.delete(this.$store.state.documentid)
          await this.$router.push({
            name: 'document'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async documentlist () {
      try {
        await this.$router.push({
          name: 'document'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
