<template>
 <v-container fluid class="ma-2">
   <v-row no-gutters justify="center" class="pa-0">
    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">

    <h2>
      <span v-if="this.$store.state.language == 'EN'">
      Partner create
      </span>
      <span v-if="this.$store.state.language == 'HR'">
      Upis partnera
      </span>        
    </h2>

    <v-row v-if="!checkedVAT">
      <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4">
        <v-text-field
            :label="lang.PartnerVATID"
            v-model="partner.partnerVATID"
        ></v-text-field> 
        <v-btn
          dark
          color="blue"
          @click="checkVATID" class="mr-2">
          {{langC.checkVATID}}
        </v-btn>
        <v-btn
              color="green"
              @click="partnerList">
              {{langC.Back}}
        </v-btn>

        <div v-if="alreadyHaveIt">
          <h2>{{lang.alertOK1}}</h2>
          <app-pres-row :label="lang.PartnerName" :info="partner.partnerName" />
          <app-pres-row :label="lang.PartnerNameShort" :info="partner.partnerNameShort" />
          <app-pres-row :label="lang.PartnerNameNoSpace" :info="partner.partnerNameDatabase" />
          <app-pres-row :label="lang.PartnerBuildingNameNumber" :info="partner.partnerBuildingNameNumber" />
          <app-pres-row :label="lang.PartnerStreet" :info="partner.partnerStreet" />
          <app-pres-row :label="lang.PartnerCity" :info="partner.partnerCity" />
          <app-pres-row :label="lang.PartnerPostcode" :info="partner.partnerPostcode" />
          <app-pres-row :label="lang.PartnerCountyRegion" :info="partner.partnerCountyRegion" />
          <app-pres-row :label="lang.PartnerState" :info="partner.partnerState" />
          <app-pres-row :label="lang.PartnerVATID" :info="partner.partnerVATID" />
          <span v-if="!privateP"> <app-pres-row :label="lang.Partneremail" :info="partner.partneremail" /> </span>
          <app-pres-row :label="lang.PartnerPhones" :info="partner.partnerPhones" />
          <app-pres-row :label="lang.PartnerBankName" :info="partner.partnerBankName" />
          <app-pres-row :label="lang.PartnerBankAddress" :info="partner.partnerBankAddress" />
          <app-pres-row :label="lang.PartnerBankIBAN" :info="partner.partnerBankIBAN" />
          <app-pres-row :label="lang.PartnerBankSWIFT" :info="partner.partnerBankSWIFT" />

        </div>
        
      </v-col>
    </v-row>

    <v-row v-if="checkedVAT">
      <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4">
        <v-card outlined>
          <v-card-title primary class="title py-2">{{lang.BasicData}}</v-card-title>
          <v-card-text>

            <v-text-field
              :label="lang.PartnerName"
              required
              :rules="[required]"
              v-model="partner.partnerName"
            ></v-text-field>      
            <v-text-field
              :label="lang.PartnerNameShort"
              required
              :rules="[required]"
              v-model="partner.partnerNameShort"
            ></v-text-field>        
            <v-text-field
              :label="lang.PartnerNameNoSpace"
              required
              :rules="[required]"
              v-model="partner.partnerNameDatabase"
            ></v-text-field>
            <v-row>
                <v-flex xs8 class="pr-2">
                  <v-text-field
                    :label="lang.PartnerStreet"
                    v-model="partner.partnerStreet"
                  ></v-text-field>
                </v-flex>
                <v-flex xs4 class="pr-2">
                  <v-text-field
                    :label="lang.PartnerBuildingNameNumber"
                    v-model="partner.partnerBuildingNameNumber"
                  ></v-text-field>
                </v-flex>
            </v-row>
            <v-row>
                <v-flex xs8 class="pr-2">
                  <v-text-field
                    :label="lang.PartnerCity"
                    v-model="partner.partnerCity"
                  ></v-text-field>
                </v-flex>
                <v-flex xs4 class="pr-2">
                  <v-text-field
                    :label="lang.PartnerPostcode"
                    v-model="partner.partnerPostcode"
                  ></v-text-field>
                </v-flex>
            </v-row>             

            <v-text-field
              :label="lang.PartnerCountyRegion"
              v-model="partner.partnerCountyRegion"
            ></v-text-field>       

            <v-text-field
              :label="lang.PartnerState"
              v-model="partner.partnerState"
            ></v-text-field>

            <v-text-field
              :label="lang.PartnerVATID"
              v-model="partner.partnerVATID"
            ></v-text-field>     

            <v-text-field
              :label="lang.PartnerPhones"
              v-model="partner.partnerPhones"
            ></v-text-field>       

            <v-text-field
              :label="lang.PartnerBankName"
              v-model="partner.partnerBankName"
            ></v-text-field>    

            <v-text-field
              :label="lang.PartnerBankAddress"
              v-model="partner.partnerBankAddress"
            ></v-text-field>      

            <v-text-field
              :label="lang.PartnerBankIBAN"
              v-model="partner.partnerBankIBAN"
            ></v-text-field>

            <v-text-field
              :label="lang.PartnerBankSWIFT"
              v-model="partner.partnerBankSWIFT"
            ></v-text-field>      

            <br>
            <div class="danger-alert" v-html="error" />
            <div v-if="this.locError">
                <v-layout row>
                  <v-flex xs4>
                    <v-subheader color="red">{{locError}}</v-subheader>
                  </v-flex>
                  <v-flex xs8>

                  </v-flex>
                </v-layout>
            </div>
            <br>
            <v-btn
              dark
              color="blue"
              :disabled="!formIsValid"
              @click="register" class="mr-2">
              {{langC.Save}}
            </v-btn>
            <v-btn
              color="green"
              @click="partnerList">
              {{langC.Back}}
            </v-btn>

          </v-card-text>
        </v-card>
      </v-col>
   </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>

   </v-col>
  </v-row>
 </v-container>
</template>

<script>
import PartnerService from '@/services/PartnerService'
import PartnerCompanyService from '@/services/PartnerCompanyService'
import PresRow2 from '@/snippets/PressRow2'
import langEn from './PartnerDescEN'
import langHr from './PartnerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import { testLastUpdate, newUpdates } from '@/services/TestLastUpdate'

export default {
  name: 'partnercreate2',
  data () {
    return {
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      menu: false,
      modal: false,
      modal1: false,
      dialog1: false,
      locError: null,
      msg: '',
      newPartner: {},
      partner: {
        CompanyId: 1,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 1,
        email: '',
        First: '',
        Last: '',
        partnerName: '',
        partnerNameShort: '',
        partnerNameDatabase: '',
        partnerBuildingNameNumber: '',
        partnerStreet: '',
        partnerCity: '',
        partnerPostcode: '',
        partnerCountyRegion: '',
        partnerState: '',
        partnerVATID: '',
        partneremail: '',
        partnerPhones: '',
        partnerBankName: '',
        partnerBankAddress: '',
        partnerBankIBAN: '',
        partnerBankSWIFT: '',
        partnerDirectorTitle: '',
        partnerDirectorFirst: '',
        partnerDirectorLast: '',
        partnerDirectorFormer: '',
        partnerDirectorStateResidence: '',
        partnerDirectorNationality: '',
        partnerDirectorDateOfBirth: '',
        partnerDirectorBusinessOccupation: '',
        partnerDirectorAdressBuildingNameNumber: '',
        partnerDirectorAdressStreet: '',
        partnerDirectorCity: '',
        partnerDirectorPostcode: '',
        partnerDirectorCountyRegion: '',
        partnerDirectorEmail: '',
        partnerDirectorPhone: '',
        partnerDirectorContactOption: '',
        partnerSecretaryTitle: '',
        partnerSecretaryFirst: '',
        partnerSecretaryLast: '',
        partnerSecretaryFormer: '',
        partnerSecretaryStateResidence: '',
        partnerSecretaryNationality: '',
        partnerSecretaryDateOfBirth: '',
        partnerSecretaryBusinessOccupation: '',
        partnerSecretaryAdressBuildingNameNumber: '',
        partnerSecretaryAdressStreet: '',
        partnerSecretaryCity: '',
        partnerSecretaryPostcode: '',
        partnerSecretaryCountyRegion: '',
        partnerSecretaryEmail: '',
        partnerSecretaryPhone: '',
        partnerSecretaryContactOption: '',
        partnerInvoiceMemo: '',
        partnerInvoiceForeginMemo: '',
        partnerDeliveryNoteMemo: '',
        partnerDeliveryNoteForeginMemo: '',
        partnerInvoiceDefDays: 15,
        partnerInvoicePlace: '',
        partnerInvoiceType: '',
        partnerInvoiceHint: '',
        partnerInvoiceSpeed: 1,
        partnerUseRetailPrice: false,
        partnerShowLastSaleBuyer: false,
        partnerShowWarehouseState: false,
        partnerShowInputPrice: false,
        partnerVATonPay: false,
        partnerRetail: false,
        partnerDefaultWarehouse: 1,
        partnerPrinterSize: 1,
        partnerInputFilesPath: '',
        partnerOutputFilesPath: '',
        partnerTransferDataFilesPath: '',
        partnerStartInvoiceNumber: 1,
        partnerStartBillNumber: 1,
        partnerType: 1,
        StartFrom: null,
        Activ: false,
        lockedCredit: false,
        lockedDebit: false,
        memberOfGroup: '',
        EnteredInDatabase: null,
        LastUpdateInDatabase: null,
        privatePerson: false,
        specialCustomer: false,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: ''
      },
      required: (value) => !!value || 'Required.',
      error: '',
      lang: {},
      langC: {},
      mainQuery: {},
      haveItem: {},
      privateP: true,
      checkedVAT: false,
      alreadyHaveIt: false
    }
  },
  async mounted () {
    try {
      this.locError = null
      if (this.$store.state.language === 'EN') {
        this.lang = langEn
        this.langC = commEn
      }
      if (this.$store.state.language === 'HR') {
        this.lang = langHr
        this.langC = commHr
      }
      this.privateP = this.$store.state.user ? this.$store.state.user.privatePartner : true
      if (this.$store.state.editItem === 1) {
        this.checkedVAT = true
        const {data} = await PartnerService.show(this.$store.state.partnerVATID)
        if (data && data.partner) {
          this.partner = data.partner
        }
      }
    } catch (error) {
      this.error = error
    }
  },
  methods: {
    async checkVATID() {
      this.mainQuery = {}
      if (this.partner.partnerVATID) {
        this.mainQuery.partnerVATID = this.partner.partnerVATID
        const {data} = await PartnerService.check(this.mainQuery)
        if (data && data.partner &&  Object.keys(data.partner).length !== 0 ) {
           this.partner = data.partner
            this.locError = this.lang.PartnerErrorVAT + (this.partner.partnerName ? this.partner.partnerName : '')
            const newPrivatePartner = {}
            newPrivatePartner.CompanyId = this.$store.state.companyid
            newPrivatePartner.CompanyNameDatabase = this.$store.state.companyName
            newPrivatePartner.BusinessYear = this.$store.state.businessYear ? parseInt(this.$store.state.businessYear) : 2020
            newPrivatePartner.UserId = this.$store.state.user.id ? this.$store.state.user.id : 0
            newPrivatePartner.email = this.$store.state.user.email ? this.$store.state.user.email : ''
            newPrivatePartner.partnerId = this.partner.id ? this.partner.id : ''
            newPrivatePartner.partnerNameDatabase = this.partner.partnerNameDatabase ? this.partner.partnerNameDatabase : ''
            await PartnerCompanyService.post(newPrivatePartner)
            this.checkedVAT = false
            this.alreadyHaveIt = true
        } else {
          this.locError = false
          this.checkedVAT = true
        }
      } 
    },
    async register () {
      try {
        this.locError = null
        if (this.$store.state.editItem === 0) {
          this.locError = null
          if (this.$store.state.user.id) {
            this.partner.UserId = this.$store.state.user.id
          }
          if (this.$store.state.user.email) {
            this.partner.email = this.$store.state.user.email
            this.partner.LastUpdateBy = this.$store.state.user.email
          }
          if (this.$store.state.companyid) {
            this.partner.CompanyId = this.$store.state.companyid
            this.mainQuery.CompanyId = this.$store.state.companyid
          }
          if (this.$store.state.companyName) {
            this.partner.CompanyNameDatabase = this.$store.state.companyName
          }
          if (this.$store.state.businessYear) {
            this.partner.BusinessYear = this.$store.state.businessYear
          }
          // if (!this.partner.partneremail) {
          //   this.locError = 'Error partner dot have email ! '
          // }
          if (this.partner.privatePerson === false) {
            if (!this.partner.partnerVATID) {
              this.locError = this.locError + this.lang.alertError1
            }
            if (this.partner.partnerVATID) {
              this.mainQuery.partnerVATID = this.partner.partnerVATID
            }
            // if (this.partner.partneremail) {
            //   this.mainQuery.partneremail = this.partner.partneremail
            // }
            if (!this.locError) {
              await PartnerService.check(this.mainQuery)
                .then(res => {
                  if (res.data.partner) {
                    this.haveItem = res.data.partner
                    this.locError = this.lang.PartnerErrorVAT + (this.haveItem.partnerName ? this.haveItem.partnerName : '')
                  }
                  if (res.data.partner2) {
                    this.haveItem = res.data.partner2
                    this.locError = this.lang.PartnerErroremail + (this.haveItem.partnerName ? this.haveItem.partnerName : '')
                  }                
                })
                .catch(err => {
                  this.error = err.data
                  this.locError = false
                })
            }
          }
          if (!this.locError) {
            const {data} = await PartnerService.post(this.partner)
            if (data.partner) {
              const newPrivatePartner = {}
              newPrivatePartner.CompanyId = this.$store.state.companyid
              newPrivatePartner.CompanyNameDatabase = this.$store.state.companyName
              newPrivatePartner.BusinessYear = this.$store.state.businessYear
              newPrivatePartner.UserId = this.$store.state.user.id
              newPrivatePartner.email = data.partner.email
              newPrivatePartner.partnerId = data.partner.id
              newPrivatePartner.partnerNameDatabase = data.partner.partnerNameDatabase
              await PartnerCompanyService.post(newPrivatePartner)
            }
            this.text = this.lang.alertOK1
            this.color = 'green'
            this.snackbar = true
            await newUpdates(2)
            await testLastUpdate()
          } else {
            this.text = this.locError
            this.color = 'red'
            this.snackbar = true
          }
        }
        if (this.$store.state.editItem === 1) {
          if (this.partner.privatePerson === false) {
              if (!this.partner.partnerVATID) {
                this.locError = this.locError + this.lang.alertError1
              }
            }
            if (!this.locError) {
              await PartnerService.put(this.partner)
              this.text = this.lang.alertOK2
              this.color = 'green'
              this.snackbar = true
              // await checkPartners(1)
              await newUpdates(2)
              await testLastUpdate()
              if (this.$store.state.partnerStore) {
                this.partners = this.$store.state.partnerStore
              }
            } else {
               this.text = this.locError
               this.color = 'red'
               this.snackbar = true
            }
          
        }
      } catch (error) {
        this.error = error
      }
    },
    async partnerList () {
      try {
        if (this.$store.state.editItem === 1) {
          this.$store.dispatch('seteditItem', 0)
        }
        await this.$router.push({
          name: 'partner'
        })
      } catch (err) {
        this.error = err
      }
    }
  },
  computed: {
    formIsValid () {
      return this.partner.partnerName !== '' &&
        this.partner.partnerNameShort !== '' &&
        this.partner.partnerNameDatabase !== '' &&
        this.partner.partnerVATID !== '' 
    }
  },
  watch: {
    'partner.partnerName' () {
      if (this.partner.partnerName) {
        this.partner.partnerNameDatabase =  this.partner.partnerName.replace(/[\W_]/g, '')
      }
    }
  },
  components: {
    appPresRow: PresRow2,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
