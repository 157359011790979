import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('cardtype', {
      params: query 
    })
  },
  show (cardtypeId) {
    return Api().get(`cardtype/${cardtypeId}`)
  },
  post (newcardtype) {
    return Api().post('cardtype', newcardtype)
  },
  put (cardtype) {
    return Api().put(`cardtype/${cardtype.id}`, cardtype)
  },
  delete (cardtypeId) {
    return Api().delete(`cardtype/${cardtypeId}`)
  }
}
