<template>
  <div class="task">
    <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
          <v-alert type="warning" v-model="havealert" dismissible close-text="Close Alert">
        {{havemssgalert}}
    </v-alert>

  <v-row>
    <v-col cols="12" sm="12" > 
      <v-card>
        <v-row align="center" class="list px-3 mx-auto">
          <v-col cols="4" sm="2">
            <v-select
              v-model="searchOption"
              :items="searchOptions"
              :label="langC.SearchBy"
              clearable
              @change="handleSearchOption"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field v-model="searchName" :label="langC.SearchBy"></v-text-field>
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn small @click="page = 1; defaultInit();">
              {{langC.Search}}
            </v-btn>
          </v-col>
          <v-col cols="5" sm="5">
            <v-pagination
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            ></v-pagination>
            
          </v-col>
          <v-col cols="4" sm="1">
            Total:{{totalItems}}
          </v-col>
          <v-col cols="4" sm="1">
            <v-select
              v-model="pageSize"
              :items="pageSizes"
              :label="langC.ItemsPerPage"
              @change="handlePageSizeChange"
            ></v-select>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row> 

  <v-row>
    <v-col cols="12" sm="12"> 
      <v-card>
        <v-card-title>
          {{ documentNameLoc }}
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="30"
            @click:row="navigateTo"
            :mobile-breakpoint="0" 
          >

          <template v-slot:[`item.BookDate`]="{ item }">
              <span>{{ localDate(item.BookDate) }}</span>
          </template>
          <template v-slot:[`item.Skip`]="{ item }">
            <span v-if="item.Skip"><v-icon>mdi-check</v-icon></span>
          </template>
          <template v-slot:[`item.FreeB1`]="{ item }">
            <span v-if="item.FreeB1"><v-icon>mdi-check</v-icon></span>
          </template>
          <template v-slot:[`item.RecTotal`]="{ item }">
            <span v-if="item.RecTotal !== 0" class="title green--text">{{ formatPrice(item.RecTotal) }}</span>
          </template>
          <template v-slot:[`item.ExpTotal`]="{ item }">
            <span v-if="item.ExpTotal !== 0" class="title indigo--text">{{ formatPrice(item.ExpTotal) }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
          <template v-slot:no-data>
            {{ lang.BookEmpty }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row> 
  </div>
</template>

<script>
import MainBookCompService from '@/services/MainBookCompService'
import ledgerEN from '../MainBook/mainbookDescEn'
import ledgerHR from '../MainBook/mainbookDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'mainbookcomplist',
  data () {
    return {
      documentName: '',
      documentNameLoc: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [30],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      havealert: false,
      havemssgalert: '',
      saving: false,
      searchName: "",
      page: 1,
      totalPages: 0,
      pageSize: 30,
      pageSizes: [10, 30, 50],
      searchOption: '',
      searchOptions: [],
      totalItems: 0,
    }
  },
  mounted () {  
    if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    this.defaultInit()
  },
  methods: {
    async handlePageChange(value) {      
      const query = this.$store.state.onPage
      query.page = value - 1;
      this.page = value
      await this.$store.dispatch('setOnPage', query)
      this.defaultInit();
    },

    async handlePageSizeChange(size) {
      const query = this.$store.state.onPage
      query.pageSize = size;
      query.page = 1;
      this.pageSize = size
      await this.$store.dispatch('setOnPage', query)
      this.defaultInit();
    },

    async handleSearchOption(option) {
      this.searchOption = option
      const query = this.$store.state.onPage
      query.searchItem = option
      query.page = 0
      await this.$store.dispatch('setOnPage', query)
    },

    setQuery() {
      const haveOnPage = this.$store.state.onPage
      const query = {}
      if (this.$store.state.companyid) {
        query.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        query.businessYear = this.$store.state.businessYear
      }
      // if (this.$store.state.documentActiv) {
      //   query.documentName = this.$store.state.documentActiv
      // }
      query.searchName = haveOnPage.searchName ? haveOnPage.searchName.trim() : null
      query.page = haveOnPage.page ? haveOnPage.page : 0
      this.page  = query.page + 1
      query.pageSize = haveOnPage.pageSize ? haveOnPage.pageSize : 30
      this.pageSize = query.pageSize
      query.searchItem = haveOnPage.searchItem ? haveOnPage.searchItem : null
      return query
    },
    async defaultInit() {
    try {
      this.items.length = 0
      this.headers.length = 0
      const query = this.setQuery()
      if (query.searchItem && this.searchName) {
        query.searchName =  this.searchName.trim()
        await this.$store.dispatch('setOnPage', query)
      }
      if (query.searchItem && query.searchName) {
        this.searchOption = query.searchItem
        this.searchName = query.searchName 
      }
      this.$store.dispatch('seteditItem', 0)
      this.saving = true
      await MainBookCompService.indexlist(query).then(res => {
        this.items = res.data.mainbookcomps ? res.data.mainbookcomps : []
        this.totalItems = res.data.totalPages
        this.totalPages = parseInt(res.data.totalPages / this.pageSize) + 1
      })
      .catch(err => {
        this.havealert = !this.havealert
        this.havemssgalert = 'Error' + err
      })
      this.saving = false
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      this.searchOptions.push(
        {text: this.lang.partnerName, value: 'partnerName'},
        {text: this.lang.BookDesc1, value: 'BookDesc1', width: '90px'},
        {text: this.lang.BookTypeName, value: 'BookTypeName', width: '90px'},
      )
      this.headers.push(
        { text: this.lang.BookDate, align: 'left', sortable: true, value: 'BookDate', width: '80px' },
        { text: this.lang.BookDesc1, align: 'center', value: 'BookDesc1', width: '150px' },
        { text: this.lang.RecTotal2, align: 'right', sortable: true, value: 'RecTotal', width: '100px' },
        { text: this.lang.ExpTotal2, align: 'right', sortable: true, value: 'ExpTotal', width: '100px' },
        { text: this.lang.HomeCurrency, align: 'left', sortable: true, value: 'HomeCurrency', width: '40px' },
        { text: 'Povezan', align: 'left', sortable: true, value: 'FreeB1', width: '40px' },
        { text: 'Izvod broj', align: 'left', sortable: true, value: 'bankStatmentNumber', width: '40px' },
        { text: this.lang.PartnerName, align: 'left', value: 'partnerName', width: '260px' },
        { text: this.lang.PartnerVATID, align: 'left', value: 'partnerVATID', width: '80px' },
        { text: 'DbId', value: 'id', width: '60px' }
      )
    } catch(err) {
      // eslint-disable-next-line
      console.log(err)
    }
    },
    formatPrice (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async defaultQuery() {
      const query = {}
      if (this.$store.state.companyid) {
        query.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        query.businessYear = this.$store.state.businessYear
      }
      // if (this.$store.state.documentActiv) {
      //   query.documentName = this.$store.state.documentActiv
      // }
      query.searchName = null
      query.page =  0
      query.pageSize =  30
      query.searchItem =  null
      await this.$store.dispatch('setOnPage', query)
      this.searchName = ""
      this.page = 1
      this.totalPages = 0
      this.pageSize = 30
      this.mainQuery = query
    },
    async initAgain() {
      this.defaultQuery()
      const query = this.setQuery()
      this.items = (await MainBookCompService.indexlist(query)).data.mainbookcomps
    },
    async navigateTo (params) {

      await this.$store.dispatch('seteditItem', 1)
      await this.$store.dispatch('setretPath', 'mainbook')
      await this.$store.dispatch('setMainLedgerid', params.id)
      this.$router.push({
        name: 'mainbookcompcreate'
      })
    },
    localDate(dateToFormat) {
      let newDate = null
      if (dateToFormat) {
        const dateOnly = dayjs(dateToFormat).format('YYYY-MM-DD')
        newDate = dateFormat(dateOnly)
      }
      return newDate
      },
  },
  computed: {
   ...mapGetters({
      myResetList: 'NeedResetList'
    })
  },
  watch: {
    'myResetList' () {
      this.initAgain()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
