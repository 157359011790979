const docHR = {
  Vehicle: 'Novo Vozilo',
  VehicleList: 'Lista vozila',
  EditVehicle: 'Uredi vozilo',
  VehicleListEmpty: 'Lista vozila je prazna',
  MarkType: 'Marka *',
  Chasis: 'Broj Šasije',
  TypeVeichle: 'Tip vozila',
  Serie: 'Serija',
  Engine: 'Model Motora',
  ChasisModel: 'Model šasije',
  Registration: 'Registracija *',
  Colour: 'Boja',
  Km: 'Km',
  YearManufacture: 'Godina proizvodnje',
  MonthManufacture: 'Mjesec prozivodnje',
  StateManufacture: 'Država proizvodnje',
  partnerName: 'Vlasnik'
}

export default docHR