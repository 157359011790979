const commHR = {
  Company: 'Tvrtka',
  BusinessYear: 'Posl.godina',
  Number: 'Broj',
  User: 'Korisnik',
  UserId: 'Kreirao korisnik:',
  UserEmail: 'Korisnik email:',
  CreatedAt: 'Kreirano:',
  UpdatedAt: 'Zadnja izmjena:',
  Save: 'SPREMI',
  SaveItem: 'SPREMI STAVKU',
  Edit: 'UREDI',
  Delete: 'OBRIŠI',
  Back: 'NATRAG',
  Error: ' Greška',
  Copy: 'KOPIRAJ',
  Saved: 'Podaci uspješno spremljeni',
  Cancel: 'Odustani',
  Find: 'Traži',
  CreateNew: 'Kreiraj',
  SaveAndClose: 'SPREMI I ZATVORI',
  SaveAndNext: 'SPREMI I SLIJEDECI',
  CreateDetails: 'RAZRADI',
  DocumentSaved: 'Dokument je spremljenu u bazu',
  Print: 'Print',
  Analysis: 'Analiziraj',
  FromDate: 'Od datuma',
  ToDate: 'Do datuma',
  StartDate: 'Početni datum',
  EndDate: 'Završni datum',
  PrintDate: 'Datum ispisa:',
  AreYouShureToDelete: 'Da li ste sigurni da želite obrisati stavku?',
  AreYouShureToCreate: 'Da li ste sigurni da želite dodati ',
  ErrorCannotSave: 'Greška! Ne može se snimiti u bazu.',
  ErrorCannotUpdate: 'Greška! Ne mogu se snimiti promjene u bazu.',
  ErrorCannotDelete: 'Greška! Ne može se obrisati iz baze.',
  ErrorCannotLoad: 'Greška! Ne mogu se učitati podaci iz baze.',
  InProgress: 'Upppss. Funkcija je u izradi!!',
  BackToList: 'Natrag na listu',
  HidePrice: 'Sakrij cijene',
  SmallPrint: 'Ispis na A6',
  Previous: 'Prethodni',
  Warehouse: 'Skladište',
  Warehouse2: 'Sklad.',
  DocumentName: 'Naziv Dokumenta',
  Locked: 'Zaključan',
  LockedDate: 'Zaključan datuma',
  Date: 'Datum',
  LastOne: 'Upppss. To je posljednji.',
  Partner: 'Partner',
  Partners: 'Partneri',
  SelectPartner: 'Odaberi Partnera',
  BalanceSheet: 'Bilanca',
  PartnerCard: 'Par.Kartica',
  PartnerOpen: 'Par.Otvoreno',
  AllPartnerOpen: 'Svi part.ot.',
  AllPartnerOpenDet: 'Svi part.ot.det',
  VAT: 'OIB',
  NumberNotExist: 'Nažalost broj ne postoji ',
  AccountMissing: 'Nedostaje konto ',
  AccountIsNotSupplierBuyer: 'Konto nije saldakontni ',
  InvoiceNumberMissing: 'Nedostaje broj računa ',
  DatabaseError: 'Greška u konekciji na bazu ',
  ErrorE: 'Greška ',
  Today: 'Danas',
  Day: 'Dan',
  Week: 'Tjedan',
  Month: 'Mjesec',
  FourDays: '4 dana',
  useMemo: 'Na memo',
  checkVATID: 'Provjeri OIB',
  Continue: 'Nastavi',
  ShowAll: 'Prikaži sve',
  Search: 'Traži',
  SearchBy: 'Traži po',
  showSerials: 'Prikazi serijske',
  Disabled: 'Isključen',
  ItemsPerPage: 'Redova po stranici',
  PriceChange: 'Zap.Kreiraj',
  FillData: 'Napuni',
  NewTask: 'Novi zadatak',
  Done: 'Obavljeno',
  Close: 'Zatvori',
  DocumentSaveError: 'Greška!! Dokument NIJE snimljen!',
  DeleteSelected: 'Obriši označene',
  JustWait: 'Molim pričekajte ...'
}

export default commHR