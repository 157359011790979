<template>
  <div class="mainledgerprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'

export default {
  name: 'mainledgerprint',
  props: ['balance'],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0,
      page: 1,
      pageLine: 10,
      currency: ''
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    
  },
  methods: {
    async print () {
      this.order = 0
      this.page = 1
      this.pageLine = 10
      this.currency = this.balance.showEUR ? 'u EUR' : 'u Kn'
      const tempCurry = this.currency
      this.currency = this.$store.state.businessYear >= 2023 ? 'EUR' : tempCurry
      await this.initDocument()
      await this.addHeaderNoLogo()
      await this.addLine()
      await this.addItemsHeader()
      await this.addLine()
      await this.addItems()
      await this.addItemsFoter()
      await this.addLine()
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()    
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'landscape'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.content = []
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    skipNewPage(lines) {
      this.pageLine += lines
      if (this.pageLine > 45) {
        this.pageLine = 10
        this.page += 1
        this.addLine()
        const docPart01 = {
        columns: [
            {
              width: '40%',
              fontSize: 7,
              bold: true,
              alignment: 'left',
              text: 'www.roboot.net',
              pageBreak: 'after'
            },
          ]
        }
        this.content.push(docPart01)
        this.addHeaderNoLogo()
        this.addLine()
        this.addItemsHeader()
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 800,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addLineDash () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 800,
                y2: 0,
                lineWidth: 1,
                dash: { length: 1 },
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '35%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text:  'Bruto bilanca ,' + this.currency + '\n', fontSize: 18, bold: true },
                  { text: ' Od konta: ' + this.balance.fromAccount +', do konta: ' + this.balance.toAccount+'\n', fontSize: 10, bold: true},
                  { text: ' Od datuma: ' + dayjs(this.balance.docFromDate).format('DD.MM.YYYY') + ', do ' + dayjs(this.balance.docToDate).format('DD.MM.YYYY'), fontSize:10, bold: true}
                ]   
              },
              {
                // % width
                width: '20%',
                text: [                 
                  { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a') + '\n', fontSize:8},
                  { text: ' Stranica: ' + parseInt(this.page) + '\n', fontSize:8}
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addItemsHeader () {
        const docPart = {
        columns: [
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: 'Konto'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: 'Tip'
              },
              {
                width: '25%',
                fontSize: 8,
                alignment: 'left',
                text: 'Naziv'
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'left',
                text: this.currency
              },
               {
                width: '7%',
                fontSize: 8,
                alignment: 'right',
                text: 'P.S.Duguje'
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'right',
                text: 'P.S.Potražuje'
              },
               {
                width: '7%',
                fontSize: 8,
                alignment: 'right',
                text: 'Promet Dug'
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'right',
                text: 'Promet Pot'
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'right',
                text: 'Duguje'
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'right',
                text: 'Potražuje'
              },
              {
                width: '7%',
                fontSize: 7,
                alignment: 'right',
                text: 'Saldo'
              },
            ]
          }
        this.content.push(docPart)        
    },
    addItems () {
           this.order = 0           
            if (this.balance.items && this.balance.items.length > 0) {
              this.balance.items.map(item => {
                  this.order += 1
                  let haveItem = false
                  // this.pTtt5 = item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0
                  // this.pTtt6 = item.itemDiscountPercentage1 ? this.formatPrice(item.itemDiscountPercentage1) : 0
                  // this.pTtt7 = item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0
                  // this.pTtt8 = item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0
                  // this.pTtt9 = item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0
                  
                  if (item.addLine) {
                    if ( item.itLine === 'KLASA' || item.itLine === 'Klasa') {
                      haveItem = true
                      this.addLine()
                      const docPart01 = {
                      columns: [
                            {
                              width: '10%',
                              fontSize: 10,
                              bold: true,
                              alignment: 'left',
                              text: item.oneCond
                            },
                            {
                              width: '10%',
                              fontSize: 10,
                              bold: true,
                              alignment: 'left',
                              text: item.itLine
                            },
                            {
                              width: '70%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'left',
                              text: item.account1Name
                            }    
                          ]
                        }
                      this.content.push(docPart01)
                      this.skipNewPage(1)
                    }
                    
                    if (item.itLine === 'KLASA UKUPNO:') {
                      haveItem = true
                      this.addLine()
                      const docPart01 = {
                      columns: [
                            {
                              width: '10%',
                              fontSize: 10,
                              bold: true,
                              alignment: 'left',
                              text: item.oneCond
                            },
                            {
                              width: '10%',
                              fontSize: 10,
                              bold: true,
                              alignment: 'left',
                              text: item.itLine
                            },
                            {
                              width: '30%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'left',
                              text: item.account1Name
                            },
                             {
                              width: '7%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'right',
                              text: this.formatPrice(item.onePsTotal1)
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'right',
                              text: this.formatPrice(item.onePsTotal2)
                            },
                             {
                              width: '7%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'right',
                              text: this.formatPrice(item.oneYearTotal1)
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'right',
                              text: this.formatPrice(item.oneYearTotal2)
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'right',
                              text: this.formatPrice(item.oneTotal1)
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'right',
                              text: this.formatPrice(item.oneTotal2)
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'right',
                              text: this.formatPrice(item.CreditDebit)
                            },
                          ]
                        }
                      this.content.push(docPart01)
                      this.skipNewPage(1)
                    }
                    if (item.itLine === 'Sintetika') {
                      haveItem = true
                      this.addLineDash()
                      const docPart01 = {
                      columns: [
                            {
                              width: '2%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'left',
                              text: ' '
                            },
                            {
                              width: '8%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'left',
                              text: item.oneCond
                            },
                            {
                              width: '10%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'left',
                              text: item.itLine
                            },
                            {
                              width: '70%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'left',
                              text: item.account1Name
                            }
                          ]
                        }
                      this.content.push(docPart01)
                      this.skipNewPage(1)
                    }

                    if (item.itLine === 'Sin.ukupno:') {
                      haveItem = true
                      this.addLineDash()
                      const docPart01 = {
                      columns: [
                            {
                              width: '2%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'left',
                              text: ' '
                            },
                            {
                              width: '8%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'left',
                              text: '  ' + item.oneCond
                            },
                            {
                              width: '10%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'left',
                              text: item.itLine
                            },
                            {
                              width: '30%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'left',
                              text: item.account1Name
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'right',
                              text: this.formatPrice(item.onePsTotal1)
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'right',
                              text: this.formatPrice(item.onePsTotal2)
                            },
                             {
                              width: '7%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'right',
                              text: this.formatPrice(item.oneYearTotal1)
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'right',
                              text: this.formatPrice(item.oneYearTotal2)
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'right',
                              text: this.formatPrice(item.oneTotal1)
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'right',
                              text: this.formatPrice(item.oneTotal2)
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              bold: true,
                              alignment: 'right',
                              text: this.formatPrice(item.CreditDebit)
                            },
                          ]
                        }
                      this.content.push(docPart01)
                      this.skipNewPage(1)
                    }

                    if (item.itLine === 'Sin.3') {
                      haveItem = true
                      const docPart01 = {
                      columns: [
                            {
                              width: '5%',
                              fontSize: 8,
                              italics: true,
                              alignment: 'left',
                              text: ' '
                            },
                            {
                              width: '5%',
                              fontSize: 8,
                              italics: true,
                              alignment: 'left',
                              text: '    ' + item.oneCond
                            },
                            {
                              width: '10%',
                              fontSize: 8,
                              italics: true,
                              alignment: 'left',
                              text: item.itLine
                            },
                            {
                              width: '70%',
                              fontSize: 8,
                              italics: true,
                              alignment: 'left',
                              text: item.account1Name
                            },

                          ]
                        }
                      this.content.push(docPart01)
                      this.skipNewPage(1)
                    }

                    if (item.itLine === 'Sin3.ukupno:') {
                      haveItem = true
                      const docPart01 = {
                      columns: [
                            {
                              width: '5%',
                              fontSize: 8,
                              italics: true,
                              alignment: 'left',
                              text: ' '
                            },
                            {
                              width: '5%',
                              fontSize: 8,
                              italics: true,
                              alignment: 'left',
                              text: '    ' + item.oneCond
                            },
                            {
                              width: '10%',
                              fontSize: 8,
                              italics: true,
                              alignment: 'left',
                              text: item.itLine
                            },
                            {
                              width: '30%',
                              fontSize: 8,
                              italics: true,
                              alignment: 'left',
                              text: item.account1Name
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              italics: true,
                              alignment: 'right',
                              text: this.formatPrice(item.onePsTotal1)
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              italics: true,
                              alignment: 'right',
                              text: this.formatPrice(item.onePsTotal2)
                            },
                             {
                              width: '7%',
                              fontSize: 8,
                              italics: true,
                              alignment: 'right',
                              text: this.formatPrice(item.oneYearTotal1)
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              italics: true,
                              alignment: 'right',
                              text: this.formatPrice(item.oneYearTotal2)
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              italics: true,
                              alignment: 'right',
                              text:  this.formatPrice(item.oneTotal1)
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              italics: true,
                              alignment: 'right',
                              text: this.formatPrice(item.oneTotal2) 
                            },
                            {
                              width: '7%',
                              fontSize: 8,
                              italics: true,
                              alignment: 'right',
                              text: this.formatPrice(item.CreditDebit)
                            },
                          ]
                        }
                      this.content.push(docPart01)
                      this.skipNewPage(1)
                    }


                    if (haveItem === false) {
                    const docPart0 = {
                    columns: [
                          {
                            width: '10%',
                            fontSize: 8,
                            alignment: 'left',
                            text: item.oneCond
                          },
                          {
                            width: '10%',
                            fontSize: 8,
                            alignment: 'left',
                            text: item.itLine
                          },
                          {
                            width: '30%',
                            fontSize: 8,
                            alignment: 'left',
                            text: item.account1Name
                          },
                          {
                            width: '7%',
                            fontSize: 8,
                            alignment: 'right',
                            text: this.formatPrice(item.onePsTotal1)
                          },
                          {
                            width: '7%',
                            fontSize: 8,
                            alignment: 'right',
                            text: this.formatPrice(item.onePsTotal2)
                          },
                            {
                            width: '7%',
                            fontSize: 8,
                            alignment: 'right',
                            text: this.formatPrice(item.oneYearTotal1)
                          },
                          {
                            width: '7%',
                            fontSize: 8,
                            alignment: 'right',
                            text: this.formatPrice(item.oneYearTotal2)
                          },
                          {
                            width: '7%',
                            fontSize: 8,
                            alignment: 'right',
                            text: this.formatPrice(item.oneTotal1)
                          },
                          {
                            width: '7%',
                            fontSize: 8,
                            alignment: 'right',
                            text: this.formatPrice(item.oneTotal2)
                          },
                          {
                            width: '7%',
                            fontSize: 8,
                            alignment: 'right',
                            text: this.formatPrice(item.CreditDebit)
                          },
                        ]
                      }
                      this.content.push(docPart0)
                      this.skipNewPage(1)
                    }

                  }

                  if (item.itLine === 'KLASA UKUPNO:') {
                    this.addEmptyRow()
                    this.skipNewPage(1)
                  }
                  if (item.itLine === 'Sin.ukupno:') {
                    this.addEmptyRow()
                    this.skipNewPage(1)
                  }
                  if (!item.addLine) {
                  const docPart = {
                  columns: [
                        {
                          width: '10%',
                          fontSize: 8,
                          alignment: 'left',
                          text: ' '
                        },
                        {
                          width: '4%',
                          fontSize: 8,
                          italics: true,
                          alignment: 'left',
                          text: ' '
                        },
                        {
                          width: '6%',
                          fontSize: 8,
                          bold: true,
                          alignment: 'left',
                          text: item.account4
                        },
                        {
                          width: '30%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.account4Name
                        },
                        {
                          width: '7%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.psDebit)
                        },
                        {
                          width: '7%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.psCredit)
                        },
                          {
                          width: '7%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.yearDebit)
                        },
                        {
                          width: '7%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.yearCredit)
                        },
                        {
                          width: '7%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.totDebit)
                        },
                        {
                          width: '7%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.totCredit)
                        },
                        {
                          width: '7%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.CreditDebit)
                        },
                      ]
                    }
                  this.content.push(docPart)
                  this.skipNewPage(1)
                  }
                }
              )
          }
    },
    addItemsFoter () {

        if (this.balance.threeCond) {
          const docPart = {
              columns: [
                    {
                      width: '5%',
                      fontSize: 8,
                      italics: true,
                      alignment: 'left',
                      text: ' '
                    },
                    {
                      width: '5%',
                      fontSize: 8,
                      italics: true,
                      alignment: 'left',
                      text: this.balance.threeCond
                    },
                    {
                      width: '10%',
                      fontSize: 8,
                      italics: true,
                      alignment: 'left',
                      text: 'Sin3.Ukupno:'
                    },
                    {
                      width: '30%',
                      fontSize: 8,
                      italics: true,
                      alignment: 'left',
                      text: this.balance.account3Name
                    },
                    {
                      width: '7%',
                      fontSize: 8,
                      italics: true,
                      alignment: 'right',
                      text: this.formatPrice(this.balance.threePsTotal1)
                    },
                    {
                      width: '7%',
                      fontSize: 8,
                      italics: true,
                      alignment: 'right',
                      text: this.formatPrice(this.balance.threePsTotal2)
                    },
                      {
                      width: '7%',
                      fontSize: 8,
                      italics: true,
                      alignment: 'right',
                      text: this.formatPrice(this.balance.threeYearTotal1)
                    },
                    {
                      width: '7%',
                      fontSize: 8,
                      italics: true,
                      alignment: 'right',
                      text: this.formatPrice(this.balance.threeYearTotal2)
                    },
                    {
                      width: '7%',
                      fontSize: 8,
                      italics: true,
                      alignment: 'right',
                      text: this.formatPrice(this.balance.threeTotal1)
                    },
                    {
                      width: '7%',
                      fontSize: 8,
                      italics: true,
                      alignment: 'right',
                      text: this.formatPrice(this.balance.threeTotal2)
                    },
                    {
                      width: '7%',
                      fontSize: 8,
                      italics: true,
                      alignment: 'right',
                      text: this.formatPrice(this.balance.threeSum)
                    },
                  ]
                }
              this.content.push(docPart)
        }


        if (this.balance.twoCond) {
          this.addLineDash()
          const docPart01 = {
          columns: [
                {
                  width: '2%',
                  fontSize: 8,
                  bold: true,
                  alignment: 'left',
                  text: ' '
                },
                {
                  width: '8%',
                  fontSize: 8,
                  bold: true,
                  alignment: 'left',
                  text: '  ' + this.balance.twoCond
                },
                {
                  width: '10%',
                  fontSize: 8,
                  bold: true,
                  alignment: 'left',
                  text: ' Sin.ukupno:'
                },
                {
                  width: '30%',
                  fontSize: 8,
                  bold: true,
                  alignment: 'left',
                  text: this.balance.account2Name
                },
                {
                  width: '7%',
                  fontSize: 8,
                  bold: true,
                  alignment: 'right',
                  text: this.formatPrice(this.balance.twoPsTotal1)
                },
                {
                  width: '7%',
                  fontSize: 8,
                  bold: true,
                  alignment: 'right',
                  text: this.formatPrice(this.balance.twoPsTotal2)
                },
                  {
                  width: '7%',
                  fontSize: 8,
                  bold: true,
                  alignment: 'right',
                  text: this.formatPrice(this.balance.twoYearTotal1)
                },
                {
                  width: '7%',
                  fontSize: 8,
                  bold: true,
                  alignment: 'right',
                  text: this.formatPrice(this.balance.twoYearTotal2)
                },                
                {
                  width: '7%',
                  fontSize: 8,
                  bold: true,
                  alignment: 'right',
                  text: this.formatPrice(this.balance.twoTotal1)
                },
                {
                  width: '7%',
                  fontSize: 8,
                  bold: true,
                  alignment: 'right',
                  text: this.formatPrice(this.balance.twoTotal2)
                },
                {
                  width: '7%',
                  fontSize: 8,
                  bold: true,
                  alignment: 'right',
                  text: this.formatPrice(this.balance.twoSum)
                },
              ]
            }
          this.content.push(docPart01)
        }

        if (this.balance.oneCond) {
          this.addLine()
          const docPart01 = {
          columns: [
                {
                  width: '10%',
                  fontSize: 10,
                  bold: true,
                  alignment: 'left',
                  text: this.balance.oneCond
                },
                {
                  width: '10%',
                  fontSize: 10,
                  bold: true,
                  alignment: 'left',
                  text: 'KLASA UKUPNO:'
                },
                {
                  width: '30%',
                  fontSize: 8,
                  bold: true,
                  alignment: 'left',
                  text: this.balance.account1Name
                },
                {
                  width: '7%',
                  fontSize: 8,
                  bold: true,
                  alignment: 'right',
                  text: this.formatPrice(this.balance.onePsTotal1)
                },
                {
                  width: '7%',
                  fontSize: 8,
                  bold: true,
                  alignment: 'right',
                  text: this.formatPrice(this.balance.onePsTotal2)
                },
                  {
                  width: '7%',
                  fontSize: 8,
                  bold: true,
                  alignment: 'right',
                  text: this.formatPrice(this.balance.oneYearTotal1)
                },
                {
                  width: '7%',
                  fontSize: 8,
                  bold: true,
                  alignment: 'right',
                  text: this.formatPrice(this.balance.oneYearTotal2)
                },                 
                {
                  width: '7%',
                  fontSize: 8,
                  bold: true,
                  alignment: 'right',
                  text: this.formatPrice(this.balance.oneTotal1)
                },
                {
                  width: '7%',
                  fontSize: 8,
                  bold: true,
                  alignment: 'right',
                  text: this.formatPrice(this.balance.oneTotal2)
                },
                {
                  width: '7%',
                  fontSize: 8,
                  bold: true,
                  alignment: 'right',
                  text: this.formatPrice(this.balance.oneSum)
                },
              ]
            }
          this.content.push(docPart01)
       }
       
       this.addEmptyRow()
       this.addLine()
       const docPart01 = {
        columns: [
            {
              width: '10%',
              fontSize: 10,
              bold: true,
              alignment: 'left',
              text: ''
            },
            {
              width: '10%',
              fontSize: 10,
              bold: true,
              alignment: 'left',
              text: 'SVEUKUPNO:'
            },
            {
              width: '25%',
              fontSize: 8,
              bold: true,
              alignment: 'left',
              text: ''
            },
            {
                width: '5%',
                fontSize: 8,
                alignment: 'left',
                text: this.currency
              },
            {
              width: '7%',
              fontSize: 8,
              bold: true,
              alignment: 'right',
              text: this.formatPrice(this.balance.psTotal1)
            },
            {
              width: '7%',
              fontSize: 8,
              bold: true,
              alignment: 'right',
              text: this.formatPrice(this.balance.psTotal2)
            },
              {
              width: '7%',
              fontSize: 8,
              bold: true,
              alignment: 'right',
              text: this.formatPrice(this.balance.yearTotal1)
            },
            {
              width: '7%',
              fontSize: 8,
              bold: true,
              alignment: 'right',
              text: this.formatPrice(this.balance.yearTotal2)
            },               
            {
              width: '7%',
              fontSize: 8,
              bold: true,
              alignment: 'right',
              text: this.formatPrice(this.balance.Total1)
            },
            {
              width: '7%',
              fontSize: 8,
              bold: true,
              alignment: 'right',
              text: this.formatPrice(this.balance.Total2)
            },
            {
              width: '7%',
              fontSize: 8,
              bold: true,
              alignment: 'right',
              text: this.formatPrice(this.balance.Total5)
            },
          ]
        }
      this.content.push(docPart01)

    },
  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>