<template>
  <div class="usercreate">
   <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="11" xs="10" sm="10" md="6" lg="5" xl="5" >
          <form
            name="UserForm"
            autocomplete="off">
          <div v-if="!registered">
            <h2>{{lang.regUsr}}</h2>

              <v-row class="mt-3">
                <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                  <v-select
                    :items="select"
                    v-model="e1"
                    :label="lang.chLang"
                    item-value="text"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                  <v-text-field
                    v-model="user.email"
                    :label="lang.em"
                    :rules="emailRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters>
                
                <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                  <v-text-field
                    :label="lang.pass"
                    :type="show1 ? 'text' : 'password'"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    v-model="user.password"
                    :rules="passwordRules"
                    required
                    counter
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                  <v-text-field
                    :label="lang.passconf"
                    :type="show2 ? 'text' : 'password'"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    v-model="confpassword"
                    :rules="passwordRules"
                    required
                    counter
                    @click:append="show2 = !show2"
                  ></v-text-field>
                </v-col>
              </v-row>

            <v-row no-gutters>                 
              <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                <v-text-field
                  :label="lang.First"
                  required
                  :rules="[required]"
                  v-model="user.First"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>    
            <v-col cols="12" sm="10" md="10" lg="10" xl="10">
              <v-text-field
                :label="lang.Last"
                required
                :rules="[required]"
                v-model="user.Last"
              ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>    
              <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                <v-btn
                  text color="primary"
                  @click="showTerms1()">
                  Uvjeti korištenja/Terms of use
                </v-btn>
              </v-col>
            </v-row> 

            <v-row no-gutters v-if="showTerms">    
              <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                <CompTermsOfUse />
              </v-col>
            </v-row> 


            <v-row no-gutters>    
              <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                <v-checkbox
                  label="Suglasan sam sa uvjetima korištenja Roboot.net / I am agree with Terms of use for Roboot.net"
                  v-model="agreeWithTerms"
                ></v-checkbox>
              </v-col>
            </v-row> 
          </div>

          <div v-if="registered">
              <h2>{{lang.user}}</h2>
              <br>
              <v-row no-gutters>    
                <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                  {{user.First}} {{user.Last}}
                </v-col>
              </v-row> 
              <br>
              <v-row no-gutters>    
                <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                  {{lang.isregistered}} 
                </v-col>
              </v-row> 

          </div>

          </form>
          <br>
          <div class="pink darken-2" v-html="error" />
          <br>
           <v-row no-gutters v-if="!registered" >    
              <v-col cols="12" sm="10" md="10" lg="10" xl="10" align="center" justify="center">
                <v-btn
                  class="primary mr-2"
                  :disabled="!formIsValid"
                  @click="register">
                  {{lang.reg}}
                </v-btn>
                <v-btn
                  color="green"
                  @click="userlist">
                  {{lang.back}}
                </v-btn>
            </v-col>
           </v-row>
            <v-row no-gutters v-if="registered">    
              <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                <v-btn
                  color="green"
                  @click="userlist">
                  {{lang.backlogin}}
                </v-btn>
            </v-col>
           </v-row> 
           <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            bottom
            :color="color"
            >
            {{ text }}
          </v-snackbar>
      </v-col>
    </v-row>
   </v-container>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import UserService from '@/services/UserService'
import CompTermsOfUse from '@/components/Companies/CompTermsOfUse'

export default {
  name: 'userregister',
  $validates: true,
  data () {
    return {
      agreeWithTerms: false,
      showTerms: false,
      e1: { text: 'English' },
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      menu: false,
      modal1: false,
      dialog1: false,
      msg: '',
      valid: true,
      modal: false,
      show1: false,
      show2: false,
      registered: false,
      select: [
        { text: 'English' },
        { text: 'Croatian-Hrvatski' }
      ],
      lang: {},
      langEn: {
        em: 'e-mail',
        pass: 'Password',
        passconf: 'Confirm Password',
        First: 'First name',
        Last: 'Last name',
        VATID: 'VAT id',
        regUsr: 'Register user',
        chLang: 'Choose language',
        reg: 'Register',
        back: 'Cancel',
        login: 'Login',
        backlogin: 'Back to Login',
        user: 'User',
        isregistered: 'is successfully registered. Please wait for administrator to activate account.'
      },
      langHR: {
        em: 'e-mail',
        pass: 'Šifra',
        passconf: 'Potvrda šifre',
        First: 'Ime',
        Last: 'Prezime',
        OIB: 'OIB tvrtke',
        regUsr: 'Registriranje korisnika',
        chLang: 'Odaberi jezik',
        reg: 'Registriraj',
        back: 'Odustani',
        login: 'Login',
        backlogin: 'Natrag na Login',
        user: 'Korisnik',
        isregistered: 'je uspješno registriran. Molim da pričekate administratora kako bi aktivirao vaš račun.'
      },
      user: {
        email: '',
        password: '',
        First: '',
        Last: '',
        StartFrom: null,
        Activ: false,
        Admin: false,
        AccessLevel: 5
      },
      error: null,
      localError: false,
      newUser: {},
      confpassword: '',
      size: 'sm',
      privitems: [
        { text: '1 max privileges', value: 1 },
        { text: '2 medium to max privileges', value: 2 },
        { text: '3 medium privileges', value: 3 },
        { text: '4 low to midium privileges', value: 4 },
        { text: '5 low privileges', value: 5 }
      ],
      required: (value) => !!value || 'Required.',
      nameRules: [
        v => !!v || 'Name is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password must be 6 or more characters',
      ],
    }
  },
  components: {
    CompTermsOfUse
  },
  mounted () {
    this.lang = this.langEn
  },
  computed: {
    formIsValid () {
      return this.user.email !== '' &&
        this.user.First !== '' &&
        this.user.Last !== '' &&
        this.user.activ !== '' &&
        this.user.password !== '' &&
        this.confpassword !== '' &&
        this.agreeWithTerms === true
    }
  },
  methods: {
    showTerms1() {
      this.showTerms = !this.showTerms
    },
    async register () {
      try {
        // treba sad tu sloziti da provjeri da li su password i
        // this password isti
        this.localError = false
        const controlpass1 = this.user.password
        const controlpass2 = this.confpassword
        if (controlpass1 !== controlpass2) {
          this.localError = true
        }

        let newStartFrom = dayjs(String('2022-01-01T00:00:00.196Z')).format('YYYY-MM-DD')

        if (this.user.StartFrom) {
          newStartFrom = dayjs(String(this.user.StartFrom + 'T00:00:00.196Z')).format('YYYY-MM-DD')
        }
        let newActiv = false
        let newAdmin = false

        if (!this.localError) {
          this.newUser = {
            email: this.user.email,
            password: this.user.password,
            First: this.user.First,
            Last: this.user.Last,
            StartFrom: newStartFrom,
            Activ: newActiv,
            Admin: newAdmin,
            AccessLevel: this.user.AccessLevel
          }

          await UserService.post(this.newUser)
          this.text = 'User is registered. Please wait for administrator to activate it!'
          this.color = 'green'
          this.snackbar = true
          this.registered = true
        } else {
          this.error = 'Password not match. Please enter password again!'
        }
      } catch (error) {
        this.error = error.response.data.error
        this.text = 'User is NOT registered. Something is worng. Please contact administrator!'
        this.color = 'red'
        this.snackbar = true
        // this.error = 'Greska u konekciji na bazu'
      }
    },
    close () {
      this.show = false
    },
    async userlist () {
      try {
        await this.$router.push({
          path: '/'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  watch: {
    'e1' () {
       if (this.e1 === 'English') {
         this.lang = this.langEn
       }
       if (this.e1 === 'Croatian-Hrvatski') {
         this.lang = this.langHR
       }
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
