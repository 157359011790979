<template>
 <div>
    <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
    <v-card>
      <v-card-title>
        {{lang.Items}}
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          v-model="search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        item-key="id"
        :search="search"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
        :items-per-page="50"
        @click:row="navigateTo"
      >
          <template v-slot:[`item.itemService`]="{ item }">
            <span v-if="item.itemService"><v-icon>mdi-check</v-icon></span>
          </template>
          <template v-slot:[`item.itemPrice`]="{ item }">
              <span>{{ localMoney(item.itemPrice) }}</span>
          </template>
          <template v-slot:[`item.itemPriceWithVAT`]="{ item }">
              <span>{{ localMoney(item.itemPriceWithVAT) }}</span>
          </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
          From {{ pageStart }} to {{ pageStop }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import langEn from './itemDescEn'
import langHr from './itemDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import moneyFormat from '@/snippets/moneyFormat'

export default {
  name: 'itemlist',
  props: ['conditionGroup'],
  data () {
    return {
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: "",
        databaseDocID: 0
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false
    }
  },
  async mounted () {
    try {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid;
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear;
      }
      this.$store.dispatch("seteditItem", 0)
      this.saving = true

      if (!this.conditionGroup) {
        this.items = this.$store.state.itemStore ? this.$store.state.itemStore : []
      }
      if (this.conditionGroup) {
        this.items = this.$store.state.itemStore.filter(item => item.GroupId === this.conditionGroup)
      }
      this.saving = false
      this.headers.push(
        {text: this.lang.ItemID, value: 'itemID', width: '150px'},
        {text: this.lang.ItemName, value: 'itemName'},
        {text: this.lang.GroupName, value: 'GroupName'},
        {text: this.lang.ItemGroup, value: 'GroupId', width: '50px'},
        {text: this.lang.ItemService, value: 'itemService', width: '50px'},
        {text: this.lang.ItemPrice, align: 'right', value: 'itemPrice', type: 'decimal', width: '150px'},
        {text: this.lang.ItemVAT1Percentage, value: 'itemVAT1Percentage', type: 'decimal', width: '50px'},
        {text: this.lang.ItemPriceWithVAT, align: 'right', value: 'itemPriceWithVAT', type: 'decimal', width: '150px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'}
      )
    } catch (err) {
      // eslint-disable-next-line
      console.log(err)
    }
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    navigateTo (item) {
      this.$store.dispatch('setSelectedItemId', item.itemID)
    }
  },
  computed: {

  },
  watch: {

  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table {
  width: 100%;
}
td {
  padding: 10px;
}
td.shrink {
  white-space: nowrap;
  width: 1px;
}
</style>
