<template>
  <div class="formprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './serialsDescEn'
import langHr from './serialsDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import {moneyFormat} from '@/snippets/allSnippets'
import documentLocalName from '@/snippets/documentLocalName'

export default {
  name: 'stickersprint',
  props: ['ana'],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0,
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      Total6: 0,
      Total7: 0,
      Total8: 0,
     // ana: {},
      documentName: ''
    }
  },
  async mounted () {
    //this.ana = this.$store.state.printObj ? this.$store.state.printObj : {}
  },
  methods: {
    async print () {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr
      }
      this.comp = await this.$store.state.companyActiv
     //this.ana = await this.$store.state.printObj
 
      this.initDocument()
      this.addItems()
      this.docDefinition.content = this.content
      await pdfMake.createPdf(this.docDefinition).open()    
    },
    localMoney (money) {
      return moneyFormat(money)
    },
    localName (name) {
      return documentLocalName(name)
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 40, 27, 20, 10 ]
      this.content = []
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 500,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },

    addTable ( ) {
      const docPart = {
        table: {
          widths: [129, 129, 129, 129],
          heights: [64, 64, 64, 64, 64],
          body: [
            ['Column 1', 'Column 2', 'Column 3', 'Kolona 4'],
            ['One value goes here', 'Another one here', 'OK?', 'drugi red 4'],
            ['One value goes here', 'Another one here', 'OK?', 'treci red 4'],
            ['One value goes here', 'Another one here', 'OK?', 'treci red 4']
          ]
        }
      }
      this.content.push(docPart)
      
    },
    addItems() {
      if (this.ana.items) {
        let nrstick = this.ana.items.length

        let cols = []
        let rows = []
        let i = 0
        // if (nrstick <= 4) {
        //   for (i = 1; i <= nrstick; i++) {
        //     cols.push(129)
        //   }
        // }
        // if (nrstick > 4) {
          cols = [129, 129, 129, 129]
        // }

        for (i = 0; i <= Math.floor(nrstick / 4); i++) {
            rows.push(68)
          }

        const docPart = {
            table: {   
              widths: cols,
              heights: rows,
              body: []
            },
            layout: 'noBorders'
          }

        nrstick = 0
        // let sticker1 = null
        // let sticker2 = null
        // let sticker3 = null
        // let sticker4 = null
        let newItem01 = {}
        let newItem02 = {}
        let newItem03 = {}
        let newItem04 = {}
        if (this.ana.emptyLines && this.ana.emptyLines > 0) {
             newItem01 = {
                table: {
                  body: [
                    [{text: ' ', fontSize: 7 ,  alignment: 'left'}],
                    [{text: ' ', fontSize: 7 ,  alignment: 'center'}],
                    [{text: ' ', fontSize: 10 ,  alignment: 'right', bold: true}],
                    [{text: ' ', fontSize: 10 ,  alignment: 'right',}]
                  ]
                },
                layout: 'noBorders',
                alignment: 'center'
              }
            
            for (i = 1; i <= this.ana.emptyLines; i++) {
              const newRow = []
              newRow.push(newItem01)
              newRow.push(newItem01)
              newRow.push(newItem01)
              newRow.push(newItem01)
              docPart.table.body.push(newRow)
              this.content.push(docPart)
            } 
          }
          this.ana.items.map(item => {
            nrstick +=1
            const CashPrice = item.itemPriceWithVAT - (item.itemPriceWithVAT * 0.1)
            const docP = item.documentId + '/' + this.formatDate(item.documentDate)
            let stickerOne = {}

            if (this.ana.printCashDiscount) {
              stickerOne = {
                table: {
                  body: [
                    [{text: item.itemID + ', ' + docP, fontSize: 7 ,  alignment: 'left'}],
                    [{text: item.itemName.substr(0,30), fontSize: 7 ,  alignment: 'center'}],
                    [{text: 'Gotovina - 10%: ' + this.localMoney(CashPrice) + ' kn', fontSize: 9 ,  alignment: 'right', bold: true}],
                    [{text: 'Redovna cijena: ' + this.localMoney(item.itemPriceWithVAT) + ' kn', fontSize: 9 ,  alignment: 'right',}]
                  ]
                },
                layout: 'noBorders',
                alignment: 'center'
              }
            }
            if (!this.ana.printCashDiscount) {
              stickerOne = {
                table: {
                  body: [
                    [{text: ' \n', fontSize: 7 ,  alignment: 'left'}],
                    [{text: item.itemID + ', ' + docP, fontSize: 7 ,  alignment: 'left'}],
                    [{text: item.itemName.substr(0,30), fontSize: 7 ,  alignment: 'center'}],
                    [{text: '' + this.localMoney(item.itemPriceWithVAT) + ' kn', fontSize: 10 ,  alignment: 'right',}]
                  ]
                },
                layout: 'noBorders',
                alignment: 'center'
              }
            }  
            
            if (nrstick === 1) {
              newItem01 = {...stickerOne}
            }
            if (nrstick === 2) {
              newItem02 = {...stickerOne}
            }
            if (nrstick === 3) {
              newItem03 = {...stickerOne}
            }
            if (nrstick === 4) {
              newItem04 = {...stickerOne}
            }
            if (nrstick === 4) {
                  const newRow = []

                  newRow.push(newItem01)
                  newRow.push(newItem02)
                  newRow.push(newItem03)
                  newRow.push(newItem04)
                docPart.table.body.push(newRow)
                nrstick = 0
                const newObj = {}
                newItem01 = {...newObj}
                newItem02 = {...newObj}
                newItem03 = {...newObj}
                newItem04 = {...newObj}
            }
          })

          if (nrstick > 0 &&  nrstick <= 4) {
            const newRow = []
            newRow.push(newItem01)
            newRow.push(newItem02)
            newRow.push(newItem03)
            newRow.push(newItem04)
            docPart.table.body.push(newRow)
          }
          //docPart.table.body.push(rows)
          
          this.content.push(docPart)
      }
    },


  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>