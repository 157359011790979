<template>
  <div class="warehousestatelist">
    <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="3">
             Knjiga URA
            </v-col>
            <v-col cols="4">
              <v-btn @click="downloadFile()">excel</v-btn>
              <v-btn @click="print" class="mr-2">Printaj</v-btn>
              <v-btn @click="createbook" class="mr-2">Kreiraj export</v-btn>
              <v-btn @click="uploadbook">Download</v-btn>
            </v-col>
            <v-col cols="5">
              <v-text-field
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                v-model="search"
              ></v-text-field>
            </v-col>
          </v-row>

        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"
          >

          <template v-slot:[`item.mark`]="{ item }">
            <span v-if="!item.mark"><v-icon>mdi-close</v-icon></span>
            <span v-if="item.mark"><v-icon>mdi-check</v-icon></span>
          </template>
          <template v-slot:[`item.diff`]="{ item }">
              <span>{{ formatPrice(item.diff) }}</span>
          </template>
          <template v-slot:[`item.mark25`]="{ item }">
            <span v-if="item.diff25 !== 0"><v-icon>mdi-close</v-icon></span>
          </template>
          <template v-slot:[`item.diff25`]="{ item }">
              <span v-if="item.diff25 !== 0">{{ formatPrice(item.diff25) }}</span>
          </template>
          <template v-slot:[`item.BookID`]="{ item }">
              <span>{{ parseInt(item.BookID) }}</span>
          </template>
          <template v-slot:[`item.InvoiceDate`]="{ item }">
              <span>{{ localDate(item.InvoiceDate) }}</span>
          </template>
          <template v-slot:[`item.VATZero`]="{ item }">
              <span>{{ (item.VATZero) }}</span>
          </template>
          <template v-slot:[`item.VAT3Base`]="{ item }">
              <span>{{ (item.VAT3Base) }}</span>
          </template>
          <template v-slot:[`item.VAT2Base`]="{ item }">
              <span>{{ (item.VAT2Base) }}</span>
          </template>
          <template v-slot:[`item.VAT1Base`]="{ item }">
              <span>{{ (item.VAT1Base) }}</span>
          </template>
          <template v-slot:[`item.InvoiceAmount`]="{ item }">
             <h3> <span>{{ (item.InvoiceAmount) }}</span></h3>
          </template>
          <template v-slot:[`item.AmountVAT`]="{ item }">
              <span>{{ (item.AmountVAT) }}</span>
          </template>
          <template v-slot:[`item.VAT3CanUse`]="{ item }">
              <span>{{ (item.VAT3CanUse) }}</span>
          </template>
          <template v-slot:[`item.VAT3CanTUse`]="{ item }">
              <span>{{ (item.VAT3CanTUse) }}</span>
          </template>
          <template v-slot:[`item.VAT2CanUse`]="{ item }">
              <span>{{ (item.VAT2CanUse) }}</span>
          </template>
          <template v-slot:[`item.VAT2CanTUse`]="{ item }">
              <span>{{ (item.VAT2CanTUse) }}</span>
          </template>
          <template v-slot:[`item.VAT1CanUse`]="{ item }">
              <span>{{ (item.VAT1CanUse) }}</span>
          </template>
          <template v-slot:[`item.VAT1CanTUse`] ="{ item }">
              <span>{{ (item.VAT1CanTUse) }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
          <template slot="body.append">
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Ukupno:</th>
                    <th class="text-right">{{ formatPrice(TotalDiff)}}</th>
                    <th class="text-right">{{ formatPrice(TotalVATZero)}}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT3Base)}}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT2Base)}}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT1Base) }}</th>
                    <th class="text-right">{{ formatPrice(TotalInvoiceAmount) }}</th>
                    <th class="text-right">{{ formatPrice(TotalAmountVAT) }}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT3CanUse) }}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT3CanTUse) }}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT2CanUse) }}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT2CanTUse) }}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT1CanUse) }}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT1CanTUse) }}</th>
                </tr>
          </template>
        </v-data-table>
      </v-card>

      <appMainLedgerPrint ref="prnt" :ura="ura"></appMainLedgerPrint>
      <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
// import axios from 'axios'
import langEn from './MainLedgerDescEN'
import langHr from './MainLedgerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import MainLedgerService from '@/services/MainLedgerService'
import MainBookService from '@/services/MainBookService'
import dateFormat from '@/snippets/dateFormat'
import FileSaver from 'file-saver'
import { v4 as uuidv4 } from 'uuid'
import MainLedgerPrint from './MainLedgerPrint'
import {parseNum, round, dynamicSort} from '@/snippets/allSnippets'
import xlsx from 'json-as-xlsx'
import dayjs from 'dayjs'

export default {
  name: 'mainledgerbookin',
  props: ['mainledgerAna', 'source'],
  data () {
    return {
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      items: [],
      printitems: [],
      expitems: [],
      documentName: '',
      documentNameLoc: '',
      msg: '',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: '',
        databaseDocID: 0
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
      documentAna: {},
      ura: {},
      TotalDiff : 0,
      TotalVATZero : 0,
      TotalVAT3Base : 0,
      TotalVAT2Base : 0,
      TotalVAT1Base : 0,
      TotalInvoiceAmount : 0,
      TotalAmountVAT : 0,
      TotalVAT1CanUse : 0,
      TotalVAT1CanTUse : 0,
      TotalVAT2CanUse : 0,
      TotalVAT2CanTUse : 0,
      TotalVAT3CanUse : 0,
      TotalVAT3CanTUse : 0,
      exTotalVATZero : 0,
      exTotalVAT3Base : 0,
      exTotalVAT2Base : 0,
      exTotalVAT1Base : 0,
      exTotalInvoiceAmount : 0,
      exTotalAmountVAT : 0,
      exTotalVAT1CanUse : 0,
      exTotalVAT1CanTUse : 0,
      exTotalVAT2CanUse : 0,
      exTotalVAT2CanTUse : 0,
      exTotalVAT3CanUse : 0,
      exTotalVAT3CanTUse : 0,
      TotalVAT4Per: 0,
      TotalVAT4Base: 0,
      TotalVAT4Amount: 0,
      TotalVAT4PerCanUse: 0,
      TotalVAT4CanUse: 0,
      TotalVAT4CanTUse: 0,
      TotalVAT5Per: 0,
      TotalVAT5Base: 0,
      TotalVAT5Amount: 0,
      TotalVAT5PerCanUse: 0,
      TotalVAT5CanUse: 0,
    }
  },

  async mounted () {
    try {
        // do analysis from database
        if (this.$store.state.language === 'EN') {
          this.lang = langEn
          this.langC = commEn
        }
        if (this.$store.state.language === 'HR') {
          this.lang = langHr
          this.langC = commHr
        }

        //this.items = this.$store.state.warehouseState ? this.$store.state.warehouseState : []
        this.headers.push(
          {text: this.lang.BookID, value: 'BookID', width: '70px', align: 'start'},
          {text: this.lang.InvoiceNumber, value: 'InvoiceNumber', width: '120px', align: 'start'},
          {text: this.lang.InvoiceDate, value: 'InvoiceDate', width: '120px', align: 'start'},
          {text: this.lang.partnerName, value: 'partnerName', width: '280px', align: 'start'},
          {text: this.lang.partnerVATID, value: 'partnerVATID', width: '150px', align: 'start'},
          {text: this.lang.mark, value: 'mark', width: '30px', align: 'center'},
          {text: this.lang.diff, value: 'diff', width: '80px', align: 'end'},
          {text: this.lang.HomeCurrency, value: 'HomeCurrency', width: '50px', align: 'center'},
          {text: 'Neoporezivo', value: 'VAT4Base', width: '100px', align: 'end'},
          {text: this.lang.VATZero, value: 'VATZero', width: '100px', align: 'end'},
          {text: this.lang.VAT3Base, value: 'VAT3Base', width: '100px', align: 'end'},
          {text: this.lang.VAT2Base, value: 'VAT2Base', width: '100px', align: 'end'},
          {text: this.lang.VAT1Base, value: 'VAT1Base', width: '100px', align: 'end'},
          {text: this.lang.InvoiceAmount, value: 'InvoiceAmount', width: '100px', align: 'end'},
          {text: this.lang.AmountVAT, value: 'AmountVAT', width: '100px', align: 'end'},
          {text: this.lang.VAT3CanUse, value: 'VAT3CanUse', width: '100px', align: 'end'},
          {text: this.lang.VAT3CanTUse, value: 'VAT3CanTUse', width: '100px', align: 'end'},
          {text: this.lang.VAT2CanUse, value: 'VAT2CanUse', width: '100px', align: 'end'},
          {text: this.lang.VAT2CanTUse, value: 'VAT2CanTUse', width: '100px', align: 'end'},
          {text: this.lang.VAT1CanUse, value: 'VAT1CanUse', width: '100px', align: 'end'},
          {text: this.lang.VAT1CanTUse, value: 'VAT1CanTUse', width: '100px', align: 'end'},
          {text: this.lang.id, value: 'id', width: '50px'}
        )

        // do analysis from database
        this.localError = false
        this.error = null

        this.items = []
        this.expitems = []
        this.printitems = []
        this.saving = true
        let tempItems = null
        if (this.source === 1) {
          this.mainledgerAna.PayedVAT = false
          tempItems = (await MainLedgerService.books(this.mainledgerAna)).data.documents
          this.mainledgerAna.PayedVAT = true
          const tempItems2 = (await MainLedgerService.books(this.mainledgerAna)).data.documents
          tempItems.push(...tempItems2)
          tempItems.sort(dynamicSort("BookID"))
        }
        if (this.source === 2) {
          const locItems = (await MainBookService.choose(this.mainledgerAna)).data.mainbooks
          tempItems = locItems.filter(item => item.BookRecExp === 2) // exp = knjiga ura
        }
        this.saving = false
        let iid = 0
        this.TotalVATZero= 0
        this.TotalVAT3Base = 0
        this.TotalVAT2Base = 0
        this.TotalVAT1Base = 0
        this.TotalInvoiceAmount = 0
        this.TotalAmountVAT = 0
        this.TotalVAT1CanUse = 0
        this.TotalVAT1CanTUse = 0
        this.TotalVAT2CanUse = 0
        this.TotalVAT2CanTUse = 0
        this.TotalVAT3CanUse = 0
        this.TotalVAT3CanTUse = 0
        this.exTotalVATZero = 0
        this.exTotalVAT3Base = 0
        this.exTotalVAT2Base = 0
        this.exTotalVAT1Base = 0
        this.exTotalInvoiceAmount = 0
        this.exTotalAmountVAT = 0
        this.exTotalVAT1CanUse = 0
        this.exTotalVAT1CanTUse = 0
        this.exTotalVAT2CanUse = 0
        this.exTotalVAT2CanTUse = 0
        this.exTotalVAT3CanUse = 0
        this.exTotalVAT3CanTUse = 0
        this.TotalDiff = 0
        // eslint-disable-next-line
        console.log('tempItems', tempItems )
        if (tempItems) {
          tempItems.map(item => {
            const nb = {}
            const newEntry = {}
            const expnb = {}
           if (item.InvoiceAmount && round((parseNum(item.InvoiceAmount)),2) !== 0 && !item.partnerNotInVAT )  {
            iid += 1
            newEntry.id = iid
            newEntry.BookID = item.BookID ? item.BookID : ''
            newEntry.partnerName = item.partnerName ? item.partnerName : ''
            newEntry.InvoiceNumber = (item.InvoiceNumber ? item.InvoiceNumber : '')
            newEntry.InvoiceDate = (item.InvoiceDate ? item.InvoiceDate : null)
            newEntry.HomeCurrency = (item.HomeCurrency ? item.HomeCurrency : '')
            newEntry.VATZero = (item.VATZero ? item.VATZero: 0)
            newEntry.VAT3Base = (item.VAT3Base ? item.VAT3Base: 0)
            newEntry.VAT2Base = (item.VAT2Base ? item.VAT2Base: 0)
            newEntry.VAT1Base = (item.VAT1Base ? item.VAT1Base: 0)
            newEntry.InvoiceAmount = (item.InvoiceAmount ? round(parseNum(item.InvoiceAmount), 2): 0)
            newEntry.AmountVAT = (item.AmountVAT ? item.AmountVAT: 0)
            newEntry.VAT1CanUse = (item.VAT1CanUse ? item.VAT1CanUse: 0)
            newEntry.VAT1CanTUse = (item.VAT1CanTUse ? item.VAT1CanTUse: 0)
            newEntry.VAT2CanUse = (item.VAT2CanUse ? item.VAT2CanUse: 0)
            newEntry.VAT2CanTUse = (item.VAT2CanTUse ? item.VAT2CanTUse: 0)
            newEntry.VAT3CanUse = (item.VAT3CanUse ? item.VAT3CanUse: 0)
            newEntry.VAT3CanTUse = (item.VAT3CanTUse ? item.VAT3CanTUse: 0)
            const diff = round((round(parseNum(item.InvoiceAmount), 2) - round(parseNum(item.AmountVAT), 2) - round(parseNum(item.VATZero), 2) - round(parseNum(item.VAT3Base), 2) - round(parseNum(item.VAT2Base), 2) -round(parseNum(item.VAT1Base), 2)- round(parseNum(item.VAT4Base), 2)),2)
            newEntry.diff = (diff !==0 ? diff: 0)
            newEntry.mark = (diff !==0 ? false : true)
            this.TotalDiff += diff

            // book out invoices
            newEntry.VAT4Per = (item.VAT4Per ? item.VAT4Per: 0)
            newEntry.VAT4Base = (item.VAT4Base ? item.VAT4Base: 0)
            newEntry.VAT4Amount = (item.VAT4Amount ? item.VAT4Amount: 0)
            newEntry.VAT4PerCanUse = (item.VAT4PerCanUse ? item.VAT4PerCanUse: 0)
            newEntry.VAT4CanUse = (item.VAT4CanUse ? item.VAT4CanUse: 0)
            newEntry.VAT4CanTUse = (item.VAT4CanTUse ? item.VAT4CanTUse: 0)
            newEntry.VAT5Per = (item.VAT5Per ? item.VAT5Per: 0)
            newEntry.VAT5Base = (item.VAT5Base ? item.VAT5Base: 0)
            newEntry.VAT5Amount = (item.VAT5Amount ? item.VAT5Amount: 0)
            newEntry.VAT5PerCanUse = (item.VAT5PerCanUse ? item.VAT5PerCanUse: 0)
            newEntry.VAT5CanUse = (item.VAT5CanUse ? item.VAT5CanUse: 0)

            // for export export
            if ((parseFloat(item.VAT1Base) + parseFloat(item.VAT2Base) + parseFloat(item.VAT3Base)) !== 0 ) {
              const R19 = (item.VATZero ? item.VATZero : 0)
              const R8 = (item.VAT3Base ? item.VAT3Base : 0)
              const R9 = (item.VAT2Base ? item.VAT2Base : 0)
              const R10 = (item.VAT1Base ? item.VAT1Base : 0)
              const R12 = (item.AmountVAT ? item.AmountVAT : 0)
              const R13 = (item.VAT3CanUse ? item.VAT3CanUse : 0)
              const R14 = (item.VAT3CanTUse ? item.VAT3CanTUse : 0)
              const R15 = (item.VAT2CanUse ? item.VAT2CanUse : 0)
              const R16 = (item.VAT2CanTUse ? item.VAT2CanTUse : 0)
              const R17 = (item.VAT1CanUse ? item.VAT1CanUse : 0)
              const R18 = (item.VAT1CanTUse ? item.VAT1CanTUse : 0)

              expnb.R1 = iid
              expnb.R2 = (item.InvoiceNumber ? item.InvoiceNumber : '')
              expnb.R3 = (item.InvoiceDate ? item.InvoiceDate : null)
              expnb.R4 = item.partnerName ? item.partnerName : ''
              expnb.R5 = (item.partnerStreet ? item.partnerStreet : '') + ' ' + (item.partnerBuildingNameNumber ? item.partnerBuildingNameNumber : '') + '  ' + (item.partnerPostcode ? item.partnerPostcode : '') + ' ' + (item.partnerCity ? item.partnerCity : '')
              expnb.R6 = '1'
              expnb.R7 = item.partnerVATID ? item.partnerVATID : ''
              expnb.R19 = R19
              expnb.R8 = R8
              expnb.R9 = R9
              expnb.R10 = R10
              //expnb.R11 = (item.InvoiceAmount ? item.InvoiceAmount : 0)
              expnb.R11 = round((round(parseNum(R8),2) + round(parseNum(R9),2) + round(parseNum(R10),2) + round(parseNum(R13),2) + round(parseNum(R14),2) + round(parseNum(R15),2) + round(parseNum(R16),2) + round(parseNum(R17),2) + round(parseNum(R18),2)),2)
              expnb.R12 = R12
              expnb.R13 = R13
              expnb.R14 = R14
              expnb.R15 = R15
              expnb.R16 = R16
              expnb.R17 = R17
              expnb.R18 = R18
              expnb.R19 = R19


              this.exTotalVATZero += parseNum(item.VATZero)
              this.exTotalVAT3Base += parseNum(item.VAT3Base)
              this.exTotalVAT2Base += parseNum(item.VAT2Base)
              this.exTotalVAT1Base += parseNum(item.VAT1Base)
              this.exTotalInvoiceAmount += parseNum(expnb.R11)
              this.exTotalAmountVAT += parseNum(item.AmountVAT)
              this.exTotalVAT3CanUse += parseNum(item.VAT3CanUse)
              this.exTotalVAT3CanTUse += parseNum(item.VAT3CanTUse)
              this.exTotalVAT2CanUse += parseNum(item.VAT2CanUse)
              this.exTotalVAT2CanTUse += parseNum(item.VAT2CanTUse)
              this.exTotalVAT1CanUse += parseNum(item.VAT1CanUse)
              this.exTotalVAT1CanTUse += parseNum(item.VAT1CanTUse)
              this.expitems.push(expnb)
            }
            // for export print
            nb.R1 = iid
            nb.R2 = (item.InvoiceNumber ? item.InvoiceNumber : '')
            nb.R3 = (item.InvoiceDate ? item.InvoiceDate : null)
            nb.R4 = item.partnerName ? item.partnerName : ''
            nb.R5 = (item.partnerStreet ? item.partnerStreet : '') + ' ' + (item.partnerBuildingNameNumber ? item.partnerBuildingNameNumber : '') + '  ' + (item.partnerPostcode ? item.partnerPostcode : '') + ' ' + (item.partnerCity ? item.partnerCity : '')
            nb.R6 = '1'
            nb.R7 = item.partnerVATID ? item.partnerVATID : ''
            nb.R8 = (item.VAT3Base ? item.VAT3Base : 0)
            nb.R9 = (item.VAT2Base ? item.VAT2Base : 0)
            nb.R10 = (item.VAT1Base ? item.VAT1Base : 0)
            nb.R11 = (item.InvoiceAmount ? item.InvoiceAmount : 0)
            nb.R12 = (item.AmountVAT ? item.AmountVAT : 0)
            nb.R13 = (item.VAT3CanUse ? item.VAT3CanUse : 0)
            nb.R14 = (item.VAT3CanTUse ? item.VAT3CanTUse : 0)
            nb.R15 = (item.VAT2CanUse ? item.VAT2CanUse : 0)
            nb.R16 = (item.VAT2CanTUse ? item.VAT2CanTUse : 0)
            nb.R17 = (item.VAT1CanUse ? item.VAT1CanUse : 0)
            nb.R18 = (item.VAT1CanTUse ? item.VAT1CanTUse : 0)
            nb.R19 = (item.VATZero ? item.VATZero : 0)
            nb.R20 = (item.BookID ? item.BookID : 0)

            // this.Total1 += item.TotalPlus ? item.TotalPlus : 0)
            // this.Total2 += item.TotalMinus ? item.TotalMinus : 0)
            this.TotalVATZero +=  parseNum(item.VATZero)
            this.TotalVAT3Base +=  parseNum(item.VAT3Base)
            this.TotalVAT2Base += parseNum(item.VAT2Base)
            this.TotalVAT1Base += parseNum(item.VAT1Base)
            this.TotalInvoiceAmount += parseNum(item.InvoiceAmount)
            this.TotalAmountVAT +=  parseNum(item.AmountVAT)
            this.TotalVAT3CanUse +=  parseNum(item.VAT3CanUse)
            this.TotalVAT3CanTUse += parseNum(item.VAT3CanTUse)
            this.TotalVAT2CanUse += parseNum(item.VAT2CanUse)
            this.TotalVAT2CanTUse += parseNum(item.VAT2CanTUse)
            this.TotalVAT1CanUse += round(parseNum(item.VAT1CanUse),2)
            this.TotalVAT1CanTUse += round(parseNum(item.VAT1CanTUse),2)

            // boot in invoices
            this.TotalVAT4Per += parseNum(item.VAT4Per)
            this.TotalVAT4Base += parseNum(item.VAT4Base)
            this.TotalVAT4Amount += parseNum(item.VAT4Amount)
            this.TotalVAT4PerCanUse += parseNum(item.VAT4PerCanUse)
            this.TotalVAT4CanUse += parseNum(item.VAT4CanUse)
            this.TotalVAT4CanTUse += parseNum(item.VAT4CanTUse)
            this.TotalVAT5Per += parseNum(item.VAT5Per)
            this.TotalVAT5Base += parseNum(item.VAT5Base)
            this.TotalVAT5Amount += parseNum(item.VAT5Amount)
            this.TotalVAT5PerCanUse += parseNum(item.VAT5PerCanUse)
            this.TotalVAT5CanUse += round(parseNum(item.VAT5CanUse),2)

            this.items.push(newEntry)
            this.printitems.push(nb)
           }
          })
        }
        this.typeAna = 4
      } catch (error) {
        this.error = 'Greska u konekciji na bazu' + error.detail
      }
  },
  methods: {
    navigateTo () {
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
    localParse(parseNumber) {
      return  parseNum(parseNumber)
    },
    round(value, exp) {
      if (typeof exp === 'undefined' || +exp === 0)
        return Math.round(value)

      value = +value
      exp = +exp

      if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split('e')
      value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)))

      // Shift back
      value = value.toString().split('e')
      return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp))
    },
    print(){
      this.initUra()
      this.$refs.prnt.print()
    },
    initUra() {
      this.ura = {}
      this.ura.Identifikator = uuidv4()
      this.ura.OIB = this.$store.state.companyActiv.CompanyVATID ? this.$store.state.companyActiv.CompanyVATID : ''
      this.ura.Naziv = this.$store.state.companyActiv.CompanyName ? this.$store.state.companyActiv.CompanyName : ''
      this.ura.Mjesto = (this.$store.state.companyActiv.CompanyPostcode ? this.$store.state.companyActiv.CompanyPostcode : '') + ' ' +this.$store.state.companyActiv.CompanyCity ? this.$store.state.companyActiv.CompanyCity : ''
      this.ura.Ulica = this.$store.state.companyActiv.CompanyStreet ? this.$store.state.companyActiv.CompanyStreet : ''
      this.ura.Broj = this.$store.state.companyActiv.CompanyBuildingNameNumber ? this.$store.state.companyActiv.CompanyBuildingNameNumber : ''
      this.ura.DodatakKucnomBroju = ''
      this.ura.DatumOd = this.mainledgerAna.docFromDate
      this.ura.DatumDo = this.mainledgerAna.docToDate
      this.ura.PodrucjeDjelatnosti = this.$store.state.companyActiv.CompanyInvoiceType ? this.$store.state.companyActiv.CompanyInvoiceType : ''
      this.ura.SifraDjelatnosti = this.$store.state.companyActiv.CompanyInvoiceHint ? this.$store.state.companyActiv.CompanyInvoiceHint : ''
      this.ura.Ime = this.$store.state.user.First ? this.$store.state.user.First : ''
      this.ura.Prezime = this.$store.state.user.Last ? this.$store.state.user.Last : ''
      this.ura.Ukupno = {}
      this.ura.Ukupno.U8 = this.TotalVAT3Base ? round(this.TotalVAT3Base, 2)  : 0
      this.ura.Ukupno.U9 = this.TotalVAT2Base ? round(this.TotalVAT2Base, 2)  : 0
      this.ura.Ukupno.U10 = this.TotalVAT1Base ? round(this.TotalVAT1Base, 2)  : 0
      this.ura.Ukupno.U11 = this.TotalInvoiceAmount ? round(this.TotalInvoiceAmount, 2)  : 0
      this.ura.Ukupno.U12 = this.TotalAmountVAT ? round(this.TotalAmountVAT, 2)  : 0
      this.ura.Ukupno.U13 = this.TotalVAT3CanUse ? round(this.TotalVAT3CanUse, 2)  : 0
      this.ura.Ukupno.U14 = this.TotalVAT3CanTUse ? round(this.TotalVAT3CanTUse, 2)  : 0
      this.ura.Ukupno.U15 = this.TotalVAT2CanUse ? round(this.TotalVAT2CanUse, 2)  : 0
      this.ura.Ukupno.U16 = this.TotalVAT2CanTUse ? round(this.TotalVAT2CanTUse, 2)  : 0
      this.ura.Ukupno.U17 = this.TotalVAT1CanUse ? round(this.TotalVAT1CanUse, 2)  : 0
      this.ura.Ukupno.U18 = this.TotalVAT1CanTUse ? round(this.TotalVAT1CanTUse, 2) : 0
      this.ura.Ukupno.U19 = this.TotalVATZero ? round(this.TotalVATZero, 2) : 0
      this.ura.Ukupno.exU8 = this.exTotalVAT3Base ? round(this.exTotalVAT3Base, 2)  : 0
      this.ura.Ukupno.exU9 = this.exTotalVAT2Base ? round(this.exTotalVAT2Base, 2)  : 0
      this.ura.Ukupno.exU10 = this.exTotalVAT1Base ? round(this.exTotalVAT1Base, 2)  : 0
      this.ura.Ukupno.exU11 = this.exTotalInvoiceAmount ? round(this.exTotalInvoiceAmount, 2)  : 0
      this.ura.Ukupno.exU12 = this.exTotalAmountVAT ? round(this.exTotalAmountVAT, 2)  : 0
      this.ura.Ukupno.exU13 = this.exTotalVAT3CanUse ? round(this.exTotalVAT3CanUse, 2)  : 0
      this.ura.Ukupno.exU14 = this.exTotalVAT3CanTUse ? round(this.exTotalVAT3CanTUse, 2)  : 0
      this.ura.Ukupno.exU15 = this.exTotalVAT2CanUse ? round(this.exTotalVAT2CanUse, 2)  : 0
      this.ura.Ukupno.exU16 = this.exTotalVAT2CanTUse ? round(this.exTotalVAT2CanTUse, 2)  : 0
      this.ura.Ukupno.exU17 = this.exTotalVAT1CanUse ? round(this.exTotalVAT1CanUse, 2)  : 0
      this.ura.Ukupno.exU18 = this.exTotalVAT1CanTUse ? round(this.exTotalVAT1CanTUse, 2) : 0
      this.ura.Ukupno.exU19 = this.exTotalVATZero ? round(this.exTotalVATZero, 2) : 0
      this.ura.RacuniIspis = this.printitems ? this.printitems.slice() : []
      this.ura.Racuni = this.expitems ? this.expitems.slice() : []
    },
    createbook(){
      this.initUra()
      MainLedgerService.createbook(this.ura)
      .then(res => {
        // eslint-disable-next-line
        console.log(res)
        this.text = 'Knjiga je kreirana.'
        this.color = 'green'
        this.snackbar = true
        this.timeout = 2000
      })
      .catch(err => {
        // eslint-disable-next-line
        console.log(err)
        this.text = 'Greska!! Knjiga NIJE kreirana.' + err
        this.color = 'red'
        this.snackbar = true
        this.timeout = 2000
      })
    },
    uploadbook(){
      this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
      this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
      this.mainQuery.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : ''
      this.mainQuery.VATID = this.$store.state.companyActiv.CompanyVATID? this.$store.state.companyActiv.CompanyVATID: ''
      this.mainQuery.fromDate = this.mainledgerAna.docFromDate ? this.mainledgerAna.docFromDate : ''
      this.mainQuery.toDate = this.mainledgerAna.docToDate  ? this.mainledgerAna.docToDate  : ''
      const zoveSe = 'KnjigaUra_' + this.mainQuery.VATID + '_' + this.mainQuery.fromDate + '_' + this.mainQuery.toDate + '_.xml'
      // eslint-disable-next-line
        console.log(this.mainQuery)
      MainLedgerService.uploadbook(this.mainQuery)
      .then(res => {
        // eslint-disable-next-line
        console.log(' have file ', res)
        const data = res.data
        //const status = res.status
        const header = res.headers
        const fileType = header['content-type']
        const blob = new Blob([data], { type: fileType })
        FileSaver.saveAs(blob, zoveSe);
        //FileSaver.saveAs(res.data, zoveSe)
        // this.text = 'Spremite knjigu.'
        // this.color = 'green'
        // this.snackbar = true
        // this.timeout = 2000
      })
      .catch(err => {
        // eslint-disable-next-line
        console.log(err)
        this.text = 'Greska!! Knjiga se NE moze ucitati.' + err
        this.color = 'red'
        this.snackbar = true
        this.timeout = 2000
      })
    },
    downloadFile () {
      //const allLines = this.users ? this.users : []
      // let printitems = this.users.slice()
      // if (!this.printdate && this.printdate2) {
      //   printitems = this.users.filter(user => user.llaDocumentSourceDate < this.printdate2)
      // }
      // if (this.printdate && this.printdate2) {
      //   printitems = this.users.filter(user => user.llaDocumentSourceDate > this.printdate && user.llaDocumentSourceDate < this.printdate2)
      // }
      // printitems.sort(dynamicSort("llaDocumentSourceDate"))
      const allData = [...this.items]

      const newObj2 = {}
      newObj2.BookID = ''
      newObj2.InvoiceNumber = ''
      newObj2.InvoiceDate = ''
      newObj2.partnerName = 'UKUPNO: >>>>>'
      newObj2.partnerVATID = ''
      newObj2.diff = ''
      newObj2.HomeCurrency = 'EUR'
      newObj2.VAT4Base = this.TotalVAT4Base
      newObj2.VATZero = this.TotalVATZero
      newObj2.VAT3Base = this.TotalVAT3Base
      newObj2.VAT2Base = this.TotalVAT2Base
      newObj2.VAT1Base = this.TotalVAT1Base
      newObj2.InvoiceAmount = this.TotalInvoiceAmount
      newObj2.AmountVAT = this.TotalAmountVAT
      newObj2.VAT3CanUse = this.TotalVAT3CanUse
      newObj2.VAT3CanTUse = this.TotalVAT3CanTUse
      newObj2.VAT2CanUse = this.TotalVAT2CanUse
      newObj2.VAT2CanTUse = this.TotalVAT2CanTUse
      newObj2.VAT1CanUse = this.TotalVAT1CanUse
      newObj2.VAT1CanTUse = this.TotalVAT1CanTUse
      allData.push(newObj2)

      const companyName = this.$store.state.companyActiv && this.$store.state.companyActiv.CompanyNameDatabase ? this.$store.state.companyActiv.CompanyNameDatabase : ''
      const currDate = dayjs().format('DD_MM_YYYY_HH_MM')
      const sheetNm = 'URA_' + this.$store.state.businessYear + '_' + currDate
      let data = [
      {
        sheet: sheetNm,
        columns: [
          {label: this.lang.BookID, value: 'BookID'},
          {label: this.lang.InvoiceNumber, value: 'InvoiceNumber'},
          {label: this.lang.InvoiceDate, value: 'InvoiceDate'},
          {label: this.lang.partnerName, value: 'partnerName'},
          {label: this.lang.partnerVATID, value: 'partnerVATID'},
          {label: this.lang.diff, value: 'diff'},
          {label: this.lang.HomeCurrency, value: 'HomeCurrency'},
          {label: 'Neoporezivo', value: row => (this.localParse(row.VAT4Base))},
          {label: this.lang.VATZero, value: row => (this.localParse(row.VATZero))},
          {label: this.lang.VAT3Base, value: row => (this.localParse(row.VAT3Base))},
          {label: this.lang.VAT2Base, value: row => (this.localParse(row.VAT2Base))},
          {label: this.lang.VAT1Base, value: row => (this.localParse(row.VAT1Base))},
          {label: this.lang.InvoiceAmount, value: row => (this.localParse(row.InvoiceAmount))},
          {label: this.lang.AmountVAT, value: row => (this.localParse(row.AmountVAT))},
          {label: this.lang.VAT3CanUse, value: row => (this.localParse(row.VAT3CanUse))},
          {label: this.lang.VAT3CanTUse, value: row => (this.localParse(row.VAT3CanTUse))},
          {label: this.lang.VAT2CanUse, value: row => (this.localParse(row.VAT2CanUse))},
          {label: this.lang.VAT2CanTUse, value: row => (this.localParse(row.VAT2CanTUse))},
          {label: this.lang.VAT1CanUse, value:row => (this.localParse(row.VAT1CanUse))},
          {label: this.lang.VAT1CanTUse, value: row => (this.localParse(row.VAT1CanTUse))},
        ],
        content: allData
      },
    ]

      const settings = {
        fileName: companyName + '_KnjigaURA_' + currDate, // Name of the spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
      }
      xlsx( data, settings)
    },
  },
  computed: {

  },
  watch: {

  },
  components: {
    appMainLedgerPrint: MainLedgerPrint
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>


