<template>
  <v-container fluid grid-list-md>
    <v-row no-gutters>

      <v-col xs="12" sm="10" md="6" lg="6" ex="6">
        <v-card ref="form">
          <v-card-text>

              <v-row no-gutters>
                <v-flex xs="12" class="py-0 px-2">                
                    <h2>{{lang.PurchaseAnalysis}} </h2>
                </v-flex>
              </v-row>

              <v-row no-gutters>
                <v-col xs="4" class="py-0 px-2">
                    {{lang.CompanyName}}
                </v-col>
                <v-col xs="8" class="py-0 px-2">
                    <span style="font-weight:bold">{{task.CompanyNameDatabase}}</span>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col xs="4" class="py-0 px-2">
                    {{lang.BusinessYear}}
                </v-col>
                <v-col xs="8" class="py-0 px-2">
                    <span style="font-weight:bold">{{task.BusinessYear}}</span>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col xs="4" class="py-0 px-2">
                  {{lang.User}}
                </v-col>
                <v-col xs="8" class="py-0 px-2">
                    <span style="font-weight:bold">{{task.First}} {{task.Last}}, {{task.email}}, {{task.UserId}}</span>
                </v-col>
              </v-row>
              <v-divider class="mt-1"></v-divider>
              <br>

          <v-row no-gutters>
              <v-col xs="6" >
                 <v-dialog
                  ref="dialog1"
                  persistent
                  v-model="modal1"
                  width="290px"
                  :return-value.sync="startDate"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="startDate"
                      :label="langC.FromDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>

                  <v-date-picker v-model="startDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="modal1 = false">Cancel</v-btn>
                    <v-btn color="primary" @click="$refs.dialog1.save(startDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col xs="4" >
               <v-dialog
                  ref="dialog2"
                  persistent
                  v-model="modal2"
                  width="290px"
                  :return-value.sync="endDate"
                >
                 <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="endDate"
                      :label="langC.ToDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
 
                  <v-date-picker v-model="endDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="modal2 = false">Cancel</v-btn>
                    <v-btn color="primary" @click="$refs.dialog2.save(endDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1"  >
                  <v-select
                  v-model="selectedMonth"
                  :items="months"
                  label="Mjesec"
                  clearable
                ></v-select>
              </v-col>

          </v-row>           

            <v-row no-gutters>
              <v-col xs="3" class="py-0 px-2">
                  {{lang.DocumentType}}
              </v-col>
              <v-col xs="9">
                <v-select :label="lang.DocumentType" :items="privitems" v-model="documentAna.documentName" value="documentAna.documentName"></v-select>
                  <v-spacer></v-spacer>
              </v-col>
            </v-row>
             <v-row no-gutters>
                  <v-col xs="3" class="py-4 px-2">
                    {{lang.Partner}}
                  </v-col>
                  <v-col xs="9" class="py-0 px-2">
                    <v-autocomplete
                      :items="partners"
                      v-model="selectPa"
                      :label="lang.SelectPartner"
                      item-text="partnerName"
                      item-value="id"
                      return-object
                      persistent-hint
                    ></v-autocomplete>
                  </v-col>
              </v-row>

              <v-row no-gutters>
                  <v-col xs="3" class="py-4 px-2">
                    
                  </v-col>
                  <v-col xs="9" class="py-0 px-2">
                    <v-autocomplete
                    :items="itemsLoc"
                    v-model="selectIL"
                    :label="lang.ItemSelect"
                    item-text="itemByID"
                    item-value="itemID"
                    return-object
                    clearable
                  ></v-autocomplete>
                  </v-col>
              </v-row>
              <v-row no-gutters>
                  <v-col xs="3" class="py-4 px-2">
                    
                  </v-col>
                  <v-col xs="9" class="py-0 px-2">
                    <v-autocomplete
                    :items="privGroups"
                    v-model="selectGroup"
                    label="Odaberi grupu"
                    single-line
                    item-text="GroupName"
                    item-value="id"
                    return-object
                    persistent-hint
                    clearable
                  ></v-autocomplete>
                  </v-col>
              </v-row>
                

              <div class="pink darken-2" v-html="error" />

          </v-card-text>
          <v-divider class="mt-1"></v-divider>
          <v-card-actions>

            <v-btn
              color="green"
              @click="documentlist">
              {{langC.Back}}
            </v-btn>
             <v-btn color="green" @click="warehouseState" class="mr-1">
                {{langC.Warehouse}}
            </v-btn>
             <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="itemCard">
              {{lang.ItemCard}}
            </v-btn>

            <v-btn
              class="primary"
              :disabled="!formIsValid"
              @click="register">
              {{langC.Analysis}}
            </v-btn>

            <v-progress-circular v-if="this.saving"
                  indeterminate
                  color="green"
            ></v-progress-circular>

          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col xs="12" sm="10" md="8" lg="8" ex="8">
        <div id="printArea">
          <v-container >
           <div v-if="this.typeAna === 1">
                <v-row style="border-bottom:solid 1px;">
                    <v-col xs="5">
                        <h4><b><i>{{comp.CompanyName}}</i></b></h4>
                        {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}   <br />                
                        {{comp.CompanyPostcode}} {{comp.CompanyCity}}<br />
                        {{lang.VATID}} {{comp.CompanyVATID}}
                    </v-col>
                    <v-col xs="3">
                      <h2>{{lang.PurchaseAnalysis}}</h2>
                  </v-col>
                    <v-col xs="4">
                      <p class="text-md-left">
                          {{langC.PrintDate}} {{PrintDate}}<br />
                          email: {{comp.Companyemail}}, {{comp.CompanyPhones}}<br />
                      </p>
                    </v-col>
              </v-row>
                <v-row>
                  <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
  
                  </v-col>
                    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <v-btn
                        text color="primary"
                        @click="printAna()">
                        {{langC.Print}}
                      </v-btn>
                    </v-col>
                </v-row>

            <v-row  class="mb-3" style="border-bottom:solid 1px; border-top:solid 1px;">
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-md-left">
                   {{lang.documentId}} 
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    {{lang.documentDate1}}
                  </v-col>
                  <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="text-left">
                    {{lang.partnerName}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left">
                    <span v-if="documentAna.documentName === 'ReceiptPurchase'">
                      Dok.
                    </span>
                    <span v-if="documentAna.documentName !== 'ReceiptPurchase'">
                      {{langC.UserId}}
                    </span>
                   
                  </v-col>

                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" v-if="showSupp">
                      {{lang.suppNetoAmount}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" v-if="showSupp">
                      {{lang.ItemMarginAmount}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" v-if="showSupp">
                      {{lang.documentTotalAmountNetto}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" v-if="showSupp">
                      {{lang.documentTotalAmountVAT}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" v-if="showSupp">
                      {{lang.documentTotalAmountBrutto}}
                    </v-col>

                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" v-if="!showSupp"> 
                      {{lang.documentTotalAmountNetto}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" v-if="!showSupp">
                      {{lang.documentTotalAmountVAT}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" v-if="!showSupp">
                      {{lang.documentTotalAmountBrutto}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" v-if="!showSupp">
                      {{lang.documentTotalAmountNettoForeign}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" v-if="!showSupp">
                      {{lang.documentTotalAmountVATForeign}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" v-if="!showSupp">
                      {{lang.documentTotalAmountBruttoForeign}}
                    </v-col>
              </v-row>

                <v-row v-for="item in items" :key="item.id" dense>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-xs-left" style="font-size:10px;">
                      {{ item.documentId }}
                      <span v-if="item.documentName === 'RequestPurchase'">
                        {{lang.RequestPurchase}}
                      </span>
                      <span v-if="item.documentName === 'InquiryPurchase'">
                        {{lang.InquiryPurchase}}
                      </span>
                      <span v-if="item.documentName === 'OrderPurchase'">
                        {{lang.OrderPurchase}}
                      </span>
                      <span v-if="item.documentName === 'ReceiptPurchase'">
                        {{lang.ReceiptPurchase}}
                      </span>
                      <span v-if="item.documentName === 'ReturnPurchase'">
                        {{lang.ReturnPurchase}}
                      </span>
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" style="font-size:10px;">
                      {{ formatDate(item.documentDate) }}
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="text-left" style="font-size:10px;">
                      {{ item.partnerName }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" style="font-size:10px;">
                      <span v-if="item.documentName === 'ReceiptPurchase'">
                        {{ item.supplierDocument}},{{ item.supplierDocumentNr}}
                      </span>
                      <span v-if="item.documentName !== 'ReceiptPurchase'">
                        {{ item.email }}
                      </span>
                    </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="showSupp">
                        {{ localMoney(item.supplierNetoAmount )}}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="showSupp">
                        {{ localMoney(item.supplierMargin )}}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="showSupp">
                        {{ localMoney(item.documentTotalAmountNetto )}}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="showSupp">
                        {{ localMoney(item.documentTotalAmountVAT )}}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="showSupp">
                        {{ localMoney(item.documentTotalAmountBrutto )}}
                      </v-col>

                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="!showSupp">
                        {{ localMoney(item.documentTotalAmountNetto )}}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="!showSupp">
                        {{ localMoney(item.documentTotalAmountVAT )}}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="!showSupp">
                        {{ localMoney(item.documentTotalAmountBrutto )}}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="!showSupp">
                        {{ localMoney(item.documentTotalAmountNettoForeign )}}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="!showSupp">
                        {{ localMoney(item.documentTotalAmountVATForeign )}}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="!showSupp">
                        {{ localMoney(item.documentTotalAmountBruttoForeign )}}
                      </v-col>
            </v-row>
            <v-row style="border-top:solid 1px;">
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
                .
              </v-col>
              <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5" class="text-right" style="font-size:10px;"> 
                .
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="showSupp">
                {{ localMoney(Total7 )}}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="showSupp">
                {{ localMoney(Total8 )}}
              </v-col>
               <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="showSupp">
                {{ localMoney(Total1 )}}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="showSupp">
                {{ localMoney(Total2 )}}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="showSupp">
                {{ localMoney(Total3 )}}
              </v-col>

              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="!showSupp">
                {{ localMoney(Total1 )}}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="!showSupp">
                {{ localMoney(Total2 )}}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="!showSupp">
                {{ localMoney(Total3 )}}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="!showSupp">
                {{ localMoney(Total4 )}}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="!showSupp">
                {{ localMoney(Total5 )}}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="!showSupp">
                {{ localMoney(Total6 )}}
              </v-col>
            </v-row>
            </div>
          </v-container>
        </div>
      </v-col>
    </v-row>
    
    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.suppcard">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <DocumentItemCard />
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.statewhs">
          <v-row class="pt-2">
              <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                <DocumentWarehouseState />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row>
     <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
        <DocumentPurchasePrint ref="prnt" :all="all" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserService from '@/services/UserService'
import DocumentService from '@/services/DocumentService'
import DocumentPurchasePrint from './DocumentPurchasePrint'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
//import {checkPartners} from '../MainLedger/mainledgerHelper'
import { testLastUpdate } from '@/services/TestLastUpdate'
import DocumentWarehouseState from './DocumentWarehouseState'
import DocumentItemCard from './DocumentItemCard'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import moneyFormat from '@/snippets/moneyFormat'

export default {
  name: 'documentpurchaseanalysis',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      itemsLoc: [],
      selectIL: {},
      statewhs: false, 
      rules: {
        required: value => !!value || '*'
      },
      typeAna : 0,
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      task: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        First: '',
        Last: '',
        EmployeeId: 0,
        Employeeemail: '',
        Employeehashtag: '',
        partnerId: 0,
        partnerName: '',
        partnerNameShort: '',
        taskTypeID: 0,
        taskTypeDescription: '',
        taskDepartmentID: 0,
        taskDepartmentDescription: '',
        taskShortDescription: '',
        taskDetailDescription: '',
        taskOpenDate: null,
        taskOpenTime: null,
        taskForDate: null,
        taskForTime: null,
        taskAlarmDate: null,
        taskAlarmTime: null,
        taskFor1UserId: 0,
        taskFor1Useremail: '',
        taskFor1UserSendEmail: false,
        taskFor2UserId: 0,
        taskFor2Useremail: '',
        taskFor2UserSendEmail: false,
        taskFor3UserId: 0,
        taskFor3Useremail: '',
        taskFor3UserSendEmail: false,
        taskFor4UserId: 0,
        taskFor4Useremail: '',
        taskFor4UserSendEmail: false,
        taskFor5UserId: 0,
        taskFor5Useremail: '',
        taskFor5UserSendEmail: false,
        taskClosedDate: null,
        taskClosedTime: null,
        taskClosedUserId: 0,
        taskClosed: false,
        taskClosedSendEmail: false,
        taskSpentHours: 0,
        taskChargeHours: 0,
        taskCharged: false,
        taskChargedByUserId: 0,
        taskChargedByUseremail: '',
        taskChargedDate: null,
        taskChargedTime: null,
        taskNotFinished: false,
        taskNotFinishedReason: '',
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: '',
        LastUpdate: null
      },
      temptask: {},
      task2: {},
      taskAna: {
        CompanyId: 0,
        BusinessYear: 0,
        UserId: 0,
        EmployeeId: 0,
        partnerId: 0,
        taskTypeID: 0,
        taskDepartmentID: 0,
        taskFor2UserId: 0,
        taskFromDate: null,
        taskToDate: null,
        taskClosed: false,
        taskCharged: false,
        taskNotFinished: false
      },
      documentAna: {
        CompanyId: 0,
        BusinessYear: 0,
        UserId: 0,
        partnerId: 0,
        documentName: '',
        docFromDate: null,
        docToDate: null
      },
      comp: {},
      error: null,
      localError: false,
      newUser: {},
      confpassword: '',
      size: 'sm',
      privitems: [ ],
      required: (value) => !!value || 'Required.',
      partners: [],
      employes: [],
      tasktypes: [],
      taskdepartments: [],
      users: [],
      items: [],
      CompanyId: 0,
      selectPa: {},
      selectEm: {},
      selectTT: {},
      selectTD: {},
      selectUs: {},
      selectUs2: {},
      selectUs3: {},
      privGroups: [],
      selectGroup: {},
      mainQuery: {
        CompanyId: 1
      },
      havePath: '',
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      Total6: 0,
      Total7: 0,
      Total8: 0,
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      startDate: null,
      endDate: null,
      showSupp: false,
      ana: {},
      all: 0,
      suppcard: false,
      months: [1,2,3,4,5,6,7,8,9,10,11,12],
      selectedMonth: null,
    }
  },
  components: {
    DocumentPurchasePrint,
    DocumentWarehouseState,
    DocumentItemCard
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    
    this.privitems.push(
      { text: this.lang.RequestPurchase, value: 'RequestPurchase' },
      { text: this.lang.InquiryPurchase, value: 'InquiryPurchase' },
      { text: this.lang.OrderPurchase, value: 'OrderPurchase' },
      { text: this.lang.ReceiptPurchase, value: 'ReceiptPurchase' },
      { text: this.lang.ReturnPurchase, value: 'ReturnPurchase' }
    )
    // need five arrays with partners, employes, tasktype, taskdepartment, users
    if (this.$store.state.companyid) {
      this.CompanyId = await this.$store.state.companyid
      this.documentAna.CompanyId = await this.$store.state.companyid
      this.mainQuery.CompanyId = await this.$store.state.companyid
    }
    this.comp = this.$store.state.companyActiv
    if (this.CompanyId > 0) {
      //await checkPartners(0)
      await testLastUpdate()
      if (this.$store.state.partnerStore) {
        this.partners = this.$store.state.partnerStore
      }
      //this.partners = (await PartnerService.index()).data.partners
      this.users = (await UserService.choose(this.mainQuery)).data.users
    }
    if (this.$store.state.user.id) {
      this.task.UserId = this.$store.state.user.id
      this.documentAna.UserId = this.$store.state.user.id
    }
    if (this.$store.state.user.email) {
      this.task.email = this.$store.state.user.email
    }
    if (this.$store.state.user.First) {
      this.task.First = this.$store.state.user.First
    }
    if (this.$store.state.user.Last) {
      this.task.Last = this.$store.state.user.Last
    }
    if (this.$store.state.companyid) {
      this.task.CompanyId = this.$store.state.companyid
      this.documentAna.CompanyId = this.$store.state.companyid
    }
    if (this.$store.state.companyName) {
      this.task.CompanyNameDatabase = this.$store.state.companyName
    }
    if (this.$store.state.businessYear) {
      this.task.BusinessYear = this.$store.state.businessYear
      this.documentAna.BusinessYear = this.$store.state.businessYear
    }
    let locDate1 = new Date()
    this.PrintDate = dayjs().format('DD.MM.YYYY, H:mm')
    locDate1.getDate()
    locDate1.getMonth() + 1
    locDate1.getFullYear()
    this.task.taskOpenDate = dayjs().format('YYYY-MM-DD')
    this.startDate = dayjs([this.documentAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
    this.endDate = dayjs([this.documentAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
    this.itemsLoc = this.$store.state.itemStore ? this.$store.state.itemStore : []
    this.privGroups = this.$store.state.groupStore ? this.$store.state.groupStore : []
  },
  computed: {
    formIsValid () {
      return this.documentAna.CompanyId !== ''
    },
    ...mapGetters({
      mySelectedItemId: 'NeedSelectedItemId'
    }),
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    validate () {
      if (this.$refs.form.validate()) {
        //this.snackbar = true
        this.register()
      }
    },
    warehouseState() {
      this.$store.dispatch('setdontGo', false)
      this.$store.dispatch("setGroupid", null)
      if (this.selectGroup && this.selectGroup.id) {
        this.$store.dispatch("setGroupid", this.selectGroup.id)
      }
      this.statewhs = !this.statewhs
    },
    async printAna() {
      const newObj = {}
      this.ana = {...newObj}
      this.ana = {...this.documentAna}
      this.ana.items = this.items ? this.items : []
      this.all = this.showSupp ? 1 : 2
      await this.$store.dispatch('setPrintObj', this.ana)
      await this.$refs.prnt.print()
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async initAna() {
      if (this.documentAna.partnerId) {
        delete this.documentAna.partnerId
      }
      if (this.documentAna.UserId) {
        delete this.documentAna.UserId
      }
      if (this.documentAna.docFromDate) {
        delete this.documentAna.docFromDate
      }
      if (this.documentAna.docToDate) {
        delete this.documentAna.docToDate
      }
      if (this.documentAna.partnerPostcode) {
        delete this.documentAna.partnerPostcode
      }
      if (this.documentAna.itemID) {
        delete this.documentAna.itemID
      }
      if (this.documentAna.itemGroup) {
        delete this.documentAna.itemGroup
      }

      // do analysis from database
      if (this.$store.state.companyid) {
        this.documentAna.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.documentAna.BusinessYear = this.$store.state.businessYear
      }
      if (this.selectIL.itemID) {
        this.documentAna.itemID = this.selectIL.itemID
      }
      if (this.selectGroup.id) {
        this.documentAna.itemGroup = this.selectGroup.id
      }
      this.documentAna.docFromDate = dayjs([this.documentAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
      this.documentAna.docToDate = dayjs([this.documentAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
      await this.$store.dispatch('setDocumentAna', this.documentAna)
    },
    async itemCard() {
      this.suppcard = false
      if (this.selectIL && this.selectIL.itemID) {
        await this.initAna()  
        this.suppcard = true
      } else {
        this.text = this.lang.itemNotSelected
        this.color = 'red'
        this.snackbar = true
      }
    },
    async register () {
      try {
        // do analysis from database
        this.localError = false
        if (this.selectPa.id) {
          this.documentAna.partnerId = this.selectPa.id
        }
        if (this.selectUs.id) {
          this.documentAna.UserId = this.selectUs.id
        }
        if (this.startDate) {
          this.documentAna.docFromDate = dayjs(this.startDate).format('YYYY-MM-DD')
        }
        if (this.endDate) {
          this.documentAna.docToDate = dayjs(this.endDate).format('YYYY-MM-DD')
        }
        this.Total1 = 0
        this.Total2 = 0
        this.saving = true
        this.items = (await DocumentService.documentpurchaseana(this.documentAna)).data.document
        this.saving = false
        if (this.items) {
          this.items.map(item => {
            this.Total1 += parseFloat(item.documentTotalAmountNetto)
            this.Total2 += parseFloat(item.documentTotalAmountVAT)
            this.Total3 += parseFloat(item.documentTotalAmountBrutto)
            this.Total4 += parseFloat(item.documentTotalAmountNettoForeign)
            this.Total5 += parseFloat(item.documentTotalAmountVATForeign)
            this.Total6 += parseFloat(item.documentTotalAmountBruttoForeign)
            this.Total7 += parseFloat(item.supplierNetoAmount)
            this.Total8 += parseFloat(item.supplierMargin)
          })
        }
        this.typeAna = 1
      } catch (error) {
        this.error = 'Greska u konekciji na bazu'
      }
    },
    close () {
      this.show = false
    },
    async documentlist () {
      try {
        this.havePath = this.$store.state.retPath
        this.$store.dispatch('setretPath', '')
        // if (this.havePath !== 'document' && this.havePath.length > 0) {
        //   await this.$router.push({
        //     name: this.havePath
        //   })
        // } else {
        await this.$router.push({
          name: 'home'
        })
        // }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  watch: {
    'selectedMonth' () {
     if  (this.selectedMonth) {
        let y = this.$store.state.businessYear
        let m = this.selectedMonth - 1;
        let firstDay = new Date(y, m, 1);
        let lastDay = new Date(y, m + 1, 0);

        this.startDate = dayjs(firstDay).format('YYYY-MM-DD')
        this.endDate = dayjs(lastDay).format('YYYY-MM-DD')

      }
      if (!this.selectedMonth) {
        this.startDate = dayjs([this.documentAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
        this.endDate = dayjs([this.documentAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
      }
    },
    'documentAna.documentName' () {

      if (!this.selectIL) {
        this.typeAna = 0
      } 
      this.showSupp = this.documentAna.documentName === 'ReceiptPurchase' ? true : false
    },
    'mySelectedItemId' () {      
      if (this.$store.state.selectedItemId) {
        this.suppcard = false
        this.$store.dispatch('setDocumentAna', null)
        const emObj = {}
        this.mainQuery2 = {...emObj}
        this.mainQuery2.CompanyId = this.$store.state.companyid
        this.mainQuery2.itemID = this.$store.state.selectedItemId
        if (this.$store.state.selectedItemId) {
          this.selectIL =
          {
            itemByID: (this.$store.state.selectedItemId),
            itemID: this.$store.state.selectedItemId
          }
        }
        
        this.itemCard()
      } else {
        this.suppcard = false
      }
     // this.partner()
    },
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
