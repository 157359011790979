import Api from '@/services/Api'

export default {
  index(query) {
    return Api().get('cashdesk', {
      params: query    
    })
  },
  index2(query) {
    return Api().get('cashdesk/showall', {
      params: query    
    })
  },
  show(id) {
    return Api().get(`cashdesk/${id}`)
  },
  post(newcashdesk) {
    return Api().post('cashdesk', newcashdesk)
  },
  put(cashdesk) {
    return Api().put(`cashdesk/${cashdesk.id}`, cashdesk)
  },
  delete(query) {
    return Api().delete('cashdesk/delete', {params: query})
  },
  choose(query) {
    return Api().get('cashdesk/choose', {
      params: query
    })
  },
  saveitems(items) {
    if (items) {
      return Api().post('cashdesk/saveitems', items)
    }
    return
  },
}