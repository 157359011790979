<template>
  <v-container>
    <v-layout row wrap  justify-center>
      <v-flex xs12 sm12 md12 lg9 xl6>
        <v-card ref="form">
          <v-card-text>

          <v-layout row >
            <v-flex xs12 class="py-0 px-2">                
                <h2>{{lang.NewTask}}</h2>                    
            </v-flex>
          </v-layout>

          <v-row class="pt-6">
            <v-col cols="3" xs="0" sm="0" md="0" lg="2" xl="2">
            </v-col>
            <v-col cols="2" xs="12" sm="12" md="12" lg="3" xl="3" class="pt-3 pr-2">
              {{lang.ForDate}}: <input type="date" v-model="task.taskForDate" />
              </v-col>

              <v-col cols="2" xs="12" sm="12" md="6" lg="2" xl="2" class="pt-3 pr-2">
                {{lang.FromTime}}: <input type="time" v-model="task.taskForTime" />
              </v-col>
              <v-col cols="2" xs="12" sm="12" md="6" lg="2" xl="2" class="pt-3 pr-2">
                {{lang.ToTime}}: <input type="time" v-model="task.taskClosedTime" />
              </v-col>

              <v-col cols="3" xs="12" sm="12" md="6" lg="3" xl="3" class="pa-0">
                <v-checkbox
                  v-model="task.taskToDoList"
                  :label="lang.TaskToDoList"
                ></v-checkbox>
              </v-col>

          </v-row>           

          <v-row>
            <v-col cols="3" xs="0" sm="0" md="0" lg="2" xl="2">
            </v-col>
            <v-col cols="12" sm="7" class="pt-0 pr-3">
              <v-text-field
                  :label="lang.ShortDesc"
                  required
                  :rules="[required]"
                  v-model="task.taskShortDescription"
                  :color="task.taskColor"
                ></v-text-field>    
             </v-col>
            <v-col cols="12" sm="2" class="py-1">
              <v-select
                dense
                v-model="task.taskColor"
                :items="colors"
                item-text="text"
                item-value="value"
              >
              </v-select>
             </v-col>
          </v-row>

          <v-row dense>
              <v-col cols="3" xs="0" sm="0" md="0" lg="2" xl="2">
            </v-col>
              <v-col>
                <v-text-field
                  :label="lang.DetDesc"
                  v-model="task.taskDetailDescription"
                ></v-text-field>    
              </v-col>
          </v-row>

      <div v-if="!showDetails">
        <v-btn
            color="blue"
            text
            @click="showDets">
            {{lang.ShowDetails}}
          </v-btn>
      </div>

      <div v-if="showDetails">
      <v-btn
            color="blue"
            text
            @click="showDets">
            {{lang.HideDetails}}
          </v-btn>
          
          <v-row >
            <v-flex xs3 class="py-4 px-2">
              {{lang.TaskPartner}}                  
            </v-flex>
            <v-flex xs9 class="py-0 px-2">
              <v-autocomplete
                :items="partners"
                v-model="selectPa"
                :label="lang.TaskPartner"
                item-text="partnerName"
                item-value="id"
                return-object
                persistent-hint
              ></v-autocomplete>
            </v-flex>
          </v-row>

          <v-row >
            <v-flex xs3 class="py-4 px-2">
              {{lang.TaskForUser}}                    
            </v-flex>
            <v-flex xs9 class="py-0 px-2">
              <v-autocomplete
                :items="users"
                v-model="selectUs"
                :label="lang.TaskForUser"
                item-text="email"
                item-value="id"
                return-object
                persistent-hint
              ></v-autocomplete>
            </v-flex>
          </v-row>

          <v-row >
            <v-flex xs3 class="py-4 px-2">
              {{lang.TaskInfoSend}}                  
            </v-flex>
            <v-flex xs3 class="py-0 px-2">
              <v-autocomplete
                :items="users"
                v-model="selectUs2"
                :label="lang.TaskInfoSend"
                item-text="email"
                item-value="id"
                return-object
                persistent-hint
              ></v-autocomplete>
            </v-flex>

            <v-flex xs3 class="py-4 px-2">
              {{lang.TaskInfoSend2}}                     
            </v-flex>
            <v-flex xs3 class="py-0 px-2">
                <v-autocomplete
                  :items="users"
                  v-model="selectUs3"
                  :label="lang.TaskInfoSend2"
                  item-text="email"
                  item-value="id"
                  return-object
                  persistent-hint
                ></v-autocomplete>
              </v-flex>
          </v-row>

          <v-row >
                  <v-flex xs3 class="py-4 px-2">
                    {{lang.TaskEmp}}                     
                  </v-flex>
                  <v-flex xs9 class="py-0 px-2">
                    <v-autocomplete
                      :items="employes"
                      v-model="selectEm"
                      :label="lang.TaskEmp"
                      item-text="hashtag"
                      item-value="id"
                      return-object
                      persistent-hint
                    ></v-autocomplete>
                  </v-flex>
                </v-row>

                <v-row >
                  <v-flex xs3 class="py-4 px-2">
                    {{lang.TaskType}}
                  </v-flex>
                  <v-flex xs3 class="py-0 px-2">
                    <v-select
                      :items="tasktypes"
                      v-model="selectTT"
                      :label="lang.TaskType"
                      item-text="taskTypeDescription"
                      item-value="id"
                      return-object
                      persistent-hint
                    ></v-select>
                  </v-flex>
                  <v-flex xs3 class="py-4 px-2">
                    {{lang.TaskDepartment}}                   
                  </v-flex>
                  <v-flex xs3 class="py-0 px-2">
                    <v-select
                      :items="taskdepartments"
                      v-model="selectTD"
                      :label="lang.TaskDepartment"
                      item-text="taskDepartmentDescription"
                      item-value="id"
                      return-object
                      persistent-hint
                    ></v-select>
                  </v-flex>
                </v-row>         

              <v-row wrap>
                <v-flex xs3>
                  <v-checkbox
                    v-model="task.taskPublic"
                    :label="lang.TaskPublic"
                  ></v-checkbox>
                </v-flex>
              </v-row>
   
              <v-layout row wrap>
                <v-flex xs3>
                  <v-checkbox
                    v-model="task.taskNotFinished"
                    :label="lang.TaskHaveProblem"
                    color="warning"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs9 >
                  <v-text-field
                    :label="lang.TaskProblem"
                    v-model="task.taskNotFinishedReason"
                  ></v-text-field>
                </v-flex>
              </v-layout>
          </div>

          <v-row>
            <v-col cols="12" sm="3">

            </v-col>
            <v-col cols="12" sm="3">
              <v-checkbox
                v-model="task.taskClosed"
                :label="lang.TaskClosed"
              ></v-checkbox>
             </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                :label="lang.ChargeHours"
                v-model.number="task.taskChargeHours"
                type="number"
              ></v-text-field>
             </v-col>
           <v-col cols="12" sm="3">
              <v-text-field
                :label="lang.SpentHours"
                v-model.number="task.taskSpentHours"
                type="number"
              ></v-text-field>
             </v-col>
          </v-row>

          <div class="pink darken-2" v-html="error" />

          </v-card-text>
          <v-divider class="mt-1"></v-divider>
          <v-card-actions>

            <v-btn
              color="green"
              @click="tasklist">
              {{langC.Back}}
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              class="primary"
              :disabled="!formIsValid"
              @click="register">
              {{langC.Save}}
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import EmployeeService from '@/services/EmployeeService'
import TaskTypeService from '@/services/TaskTypeService'
import TaskDepartmentService from '@/services/TaskDepartmentService'
import UserService from '@/services/UserService'
import TaskService from '@/services/TaskService'
// import EmailService from '@/services/EmailService'
import langEn from './taskDescEn'
import langHr from './taskDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import { testLastUpdate } from '../../services/TestLastUpdate'

export default {
  name: 'taskcreate',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      lang: {},
      langC: {},
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      task: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        First: '',
        Last: '',
        EmployeeId: 0,
        Employeeemail: '',
        Employeehashtag: '',
        partnerId: 0,
        partnerName: '',
        partnerNameShort: '',
        taskTypeID: 0,
        taskTypeDescription: '',
        taskDepartmentID: 0,
        taskDepartmentDescription: '',
        taskShortDescription: '',
        taskDetailDescription: '',
        taskOpenDate: null,
        taskOpenTime: null,
        taskForDate: null,
        taskForTime: null,
        taskAlarmDate: null,
        taskAlarmTime: null,
        taskFor1UserId: 0,
        taskFor1Useremail: '',
        taskFor1UserSendEmail: false,
        taskFor2UserId: 0,
        taskFor2Useremail: '',
        taskFor2UserSendEmail: false,
        taskFor3UserId: 0,
        taskFor3Useremail: '',
        taskFor3UserSendEmail: false,
        taskFor4UserId: 0,
        taskFor4Useremail: '',
        taskFor4UserSendEmail: false,
        taskFor5UserId: 0,
        taskFor5Useremail: '',
        taskFor5UserSendEmail: false,
        taskClosedDate: null,
        taskClosedTime: null,
        taskClosedUserId: 0,
        taskClosed: false,
        taskClosedSendEmail: false,
        taskSpentHours: 0,
        taskChargeHours: 0,
        taskCharged: false,
        taskChargedByUserId: 0,
        taskChargedByUseremail: '',
        taskChargedDate: null,
        taskChargedTime: null,
        taskNotFinished: false,
        taskNotFinishedReason: '',
        taskPublic: false,
        taskColor: 'blue',
        taskEndDate : null,
        taskToDoList: false,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: '',
        LastUpdate: null
      },
      temptask: {},
      task2: {},
      error: null,
      localError: false,
      newUser: {},
      confpassword: '',
      size: 'sm',
      privitems: [
        { text: '1 max privileges', value: 1 },
        { text: '2 medium to max privileges', value: 2 },
        { text: '3 medium privileges', value: 3 },
        { text: '4 low to midium privileges', value: 4 },
        { text: '5 low privileges', value: 5 }
      ],
      required: (value) => !!value || 'Required.',
      partners: [],
      employes: [],
      tasktypes: [],
      colors: [],
      taskdepartments: [],
      users: [],
      CompanyId: 0,
      selectPa: {},
      selectEm: {},
      selectTT: {},
      selectTD: {},
      selectUs: {},
      selectUs2: {},
      selectUs3: {},
      mainQuery: {
        CompanyId: 1
      },
      havePath: '',
      showDetails: false,
    }
  },
  components: {
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = langEn
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = langHr
      this.langC = commHr;
    }
    // need five arrays with partners, employes, tasktype, taskdepartment, users
    if (this.$store.state.companyid) {
      this.CompanyId = await this.$store.state.companyid
      this.mainQuery.CompanyId = await this.$store.state.companyid
    }
    if (this.$store.state.editItem === 0) {
      if (this.CompanyId > 0) {
        await testLastUpdate()
        if (this.$store.state.partnerStore) {
          this.partners = this.$store.state.partnerStore
        }
        //this.partners = (await PartnerService.index()).data.partners
        this.employes = (await EmployeeService.choose(this.mainQuery)).data.employes
        this.tasktypes = (await TaskTypeService.choose(this.mainQuery)).data.taskTypes
        this.taskdepartments = (await TaskDepartmentService.choose(this.mainQuery)).data.taskDepartments
        this.users = (await UserService.choose(this.mainQuery)).data.users
        // console.log(this.partners)
        // console.log(this.tasktypes)
        // console.log(this.taskdepartments)
        // console.log(this.users)
      }
      if (this.$store.state.user.id) {
        this.task.UserId = this.$store.state.user.id
      }
      if (this.$store.state.user.email) {
        this.task.email = this.$store.state.user.email
      }
      if (this.$store.state.user.First) {
        this.task.First = this.$store.state.user.First
      }
      if (this.$store.state.user.Last) {
        this.task.Last = this.$store.state.user.Last
      }
      if (this.$store.state.companyid) {
        this.task.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.companyName) {
        this.task.CompanyNameDatabase = this.$store.state.companyName
      }
      if (this.$store.state.businessYear) {
        this.task.BusinessYear = this.$store.state.businessYear
      }
    }
    if (this.$store.state.editItem === 1) {
      const dbTask = await TaskService.show(this.$store.state.taskid)
      this.task = dbTask.data.task
      if (this.$store.state.companyid) {
        this.CompanyId = await this.$store.state.companyid
        this.mainQuery.CompanyId = await this.$store.state.companyid
      }
      if (this.task.taskOpenDate) {
        this.loctaskOpenDate = dayjs(this.task.taskOpenDate).format('DD.MM.YYYY')
      }
      // if (this.task.taskForDate) {
      //   this.loctaskForDate = dayjs(this.task.taskForDate).format('DD.MM.YYYY')
      // }
      if (this.task.taskAlarmDate) {
        this.loctaskAlarmDate = dayjs(this.task.taskAlarmDate).format('DD.MM.YYYY')
      }
      if (this.task.taskClosedDate) {
        this.loctaskClosedDate = dayjs(this.task.taskClosedDate).format('DD.MM.YYYY')
      }
      this.locCreatedAt = dayjs(this.task.createdAt).format('DD.MM.YYYY HH:MM')
      this.locUpdatedAt = dayjs(this.task.updatedAt).format('DD.MM.YYYY HH:MM')

      if (this.$store.state.companyid) {
        this.CompanyId = await this.$store.state.companyid
        this.mainQuery.CompanyId = await this.$store.state.companyid
      }
      if (this.CompanyId > 0) {
        await testLastUpdate()
        if (this.$store.state.partnerStore) {
          this.partners = this.$store.state.partnerStore
        }
        //this.partners = (await PartnerService.index()).data.partners
        this.employes = (await EmployeeService.choose(this.mainQuery)).data.employes
        this.tasktypes = (await TaskTypeService.choose(this.mainQuery)).data.taskTypes
        this.taskdepartments = (await TaskDepartmentService.choose(this.mainQuery)).data.taskDepartments
        this.users = (await UserService.choose(this.mainQuery)).data.users
        // console.log(this.partners)
        // console.log(this.tasktypes)
        // console.log(this.taskdepartments)
        // console.log(this.users)
      }
      if (this.task.partnerId) {
        this.selectPa = { partnerName: this.task.partnerName,
          partnerNameShort: '',
          partnerNameDatabase: '',
          partneremail: '',
          id: this.task.partnerId}
      }
      if (this.task.taskTypeID) {
        this.selectTT = {
          taskTypeDescription: this.task.taskTypeDescription,
          CompanyId: this.CompanyId,
          id: this.task.taskTypeID
        }
      }
      if (this.task.taskDepartmentID) {
        this.selectTD = {
          taskDepartmentDescription: this.task.taskDepartmentDescription,
          CompanyId: this.CompanyId,
          id: this.task.taskDepartmentID
        }
      }
      if (this.task.taskFor1UserId) {
        this.selectUs = {
          id: this.task.taskFor1UserId,
          email: this.task.taskFor1Useremail,
          First: '',
          Last: '',
          AccessLevel: '',
          CompanyId: this.CompanyId
        }
      }
      if (this.task.taskFor2UserId) {
        this.selectUs2 = {
          id: this.task.taskFor2UserId,
          email: this.task.taskFor2Useremail,
          First: '',
          Last: '',
          AccessLevel: '',
          CompanyId: this.CompanyId
        }
      }
      if (this.task.taskFor3UserId) {
        this.selectUs3 = {
          id: this.task.taskFor3UserId,
          email: this.task.taskFor3Useremail,
          First: '',
          Last: '',
          AccessLevel: '',
          CompanyId: this.CompanyId
        }
      }
      if (this.task.EmployeeId) {
        this.selectEm = {
          hashtag: this.task.Employeehashtag,
          eemail: this.task.Employeeemail,
          First: '',
          Last: '',
          CompanyId: this.CompanyId,
          VatID: '',
          id: this.task.EmployeeId
        }
      }
    }
    let locDate1 = new Date()
    locDate1.getDate()
    locDate1.getMonth() + 1
    locDate1.getFullYear()
    this.task.taskOpenDate = dayjs().format('YYYY-MM-DD')
    this.colors = this.$store.state.colors ? this.$store.state.colors : []
  },
  computed: {
    formIsValid () {
      return this.task.email !== '' &&
        this.task.First !== '' &&
        this.task.Last !== '' &&
        this.task.activ !== ''
    }
  },
  methods: {
    showDets() {
      this.showDetails = !this.showDetails
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async register () {
      try {
        // write new task to database
        this.localError = false
        if (this.selectPa.id) {
          this.task.partnerId = this.selectPa.id
        }
        if (this.selectPa.partnerName) {
          this.task.partnerName = this.selectPa.partnerName
        }
        if (this.selectEm.id) {
          this.task.EmployeeId = this.selectEm.id
        }
        if (this.selectEm.eemail) {
          this.task.Employeeemail = this.selectEm.eemail
        }
        if (this.selectEm.hashtag) {
          this.task.Employeehashtag = this.selectEm.hashtag
        }
        if (this.selectTT.id) {
          this.task.taskTypeID = this.selectTT.id
        }
        if (this.selectTT.taskTypeDescription) {
          this.task.taskTypeDescription = this.selectTT.taskTypeDescription
        }
        if (this.selectTD.id) {
          this.task.taskDepartmentID = this.selectTD.id
        }
        if (this.selectTD.taskDepartmentDescription) {
          this.task.taskDepartmentDescription = this.selectTD.taskDepartmentDescription
        }
        if (this.selectUs.id) {
          this.task.taskFor1UserId = this.selectUs.id
          // this.task2 = Object.assign({}, this.task)
          // this.task2.UserId = this.selectUs.id
          // this.task2.First = this.selectUs.First
          // this.task2.Last = this.selectUs.Last
          // this.task2.For5UserUserId = this.$store.state.user.id
        }
        if (this.selectUs2.id) {
          this.task.taskFor2UserId = this.selectUs2.id
          this.task.taskFor2Useremail = this.selectUs2.email
        }
        if (this.selectUs3.id) {
          this.task.taskFor3UserId = this.selectUs3.id
          this.task.taskFor3Useremail = this.selectUs3.email
        }
        if (this.selectUs.email) {
          this.task.taskFor1Useremail = this.selectUs.email
          // this.task2.email = this.selectUs.email
          // this.task2.taskFor5Useremail = this.$store.state.user.email
        }
        if (!this.task.taskForDate) {
          this.task.taskForDate = new Date()
        }
        if (this.$store.state.editItem === 0) {
          delete this.task.id 
          const resTask = await TaskService.post(this.task)
          if (resTask && this.task2.UserId  && resTask.data) {
            this.task2.FreeF1 = resTask && resTask.data && resTask.data.id ? resTask.data.id : null
            await TaskService.post(this.task2)
          }
        }
        // await EmailService.sendemail(this.task)
         if (this.$store.state.editItem === 1) {
          const resTask = await TaskService.put(this.task)
          console.log('resTask', resTask)
        }
        this.havePath = this.$store.state.retPath
        this.$store.dispatch('setretPath', '')
        this.$router.push({
            name: 'home'
        })

        // if (this.havePath !== 'task' && this.havePath.length > 0) {
        //   this.$router.push({
        //     name: 'home'
        //   })
        // } else {
        //   this.$router.push({
        //     name: 'task'
        //   })
        // }
      } catch (error) {
        this.error = 'Greska u konekciji na bazu'
      }
    },
    close () {
      this.show = false
    },
    async tasklist () {
      try {
        this.havePath = this.$store.state.retPath
        this.$store.dispatch('setretPath', '')
         this.$router.push({
            name: 'home'
        })
        // if (this.havePath !== 'task' && this.havePath.length > 0) {
        //   await this.$router.push({
        //     name: this.havePath
        //   })
        // } else {
        //   await this.$router.push({
        //     name: 'task'
        //   })
        // }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
