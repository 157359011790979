<template>
    <v-container fluid grid-list-md>
    
    <v-row no-gutters v-if="!hideAll">
        <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
          <h2>{{lang.Forms}}</h2>
        </v-col>
        <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
          <v-btn
            text color="primary"
            @click="formtype(1)">
            {{lang.FormPPO}}
          </v-btn>
          <v-btn
            text color="primary"
            @click="formtype(2)">
            {{lang.FormPDVS}}
          </v-btn>
          <v-btn
            text color="primary"
            @click="formtype(3)">
            {{lang.FormZP}}
          </v-btn>
           <v-btn
            text color="primary"
            @click="dispOPZ">
            OPZ STAT
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="showOpzStat">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-row>
            <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
              <v-btn
                text color="primary"
                @click="dispOPZ">
                Natrag na obrasce
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <OpzstatHeadIndex key="refresh" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    </v-container>
</template>

<script>
import langEn from './formDescEn'
import langHR from './formDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import OpzstatHeadIndex from './OpzstatHeadIndex.vue'

export default {
  name: 'formindex',
  data () {
    return {
      lang: {},
      langC: {},
      formType: 0,
      formList: false,
      haveForm : '',
      hideAll : false,
      showOpzStat: false
    }
  },
  mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === "HR") {
      this.lang = langHR
      this.langC = commHr
    }
  },
  methods: {
    dispOPZ() {
      this.showOpzStat = !this.showOpzStat
      this.hideAll = !this.hideAll
    },
    formtype(type){
      this.formType = type
      this.formList = true
      if (type === 1) {
        this.haveForm = this.lang.FormPPODetail
      }
      if (type === 2) {
        this.haveForm = this.lang.FormPDVSDetail
      }
      if (type === 3) {
        this.haveForm = this.lang.FormZPDetail
      }
      this.formlist()
    },
    async formlist () {
      await this.$store.dispatch('seteditItem', 0)
      this.$store.dispatch('setretPath', 'formlist')
      await this.$store.dispatch('setMainLedgerid', null)
      this.$router.push({
        name: 'formlist', params: {formType: this.formType }
      })
    },
  },
  computed: {

  },
  watch: {

  },
  components: {
    OpzstatHeadIndex
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
