<template>
  <v-layout row justify-center>
      <v-card v-if="this.$store.state.language == 'EN'">
        <v-card-title class="headline">Are you sure to delete Employee?</v-card-title>
        <v-card-text>We not recomend to delete employee. Better make it NOT activ. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" flat @click="deleteEmployee">Delete</v-btn>
          <v-btn color="green darken-1" flat @click="employeelist">Cancel</v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-if="this.$store.state.language == 'HR'">
        <v-card-title class="headline">Da li ste sigurni da zelite obrisati Radnika?</v-card-title>
        <v-card-text>NE preporucujemo brisanje. Bolje ga oznacite kao neaktivnog. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" flat @click="deleteEmployee">Obrisi</v-btn>
          <v-btn color="green darken-1" flat @click="employeelist">Odustani</v-btn>
        </v-card-actions>
      </v-card>

  </v-layout>
</template>

<script>
import EmployeeService from '@/services/EmployeeService'

export default {
  name: 'employeedelete',
  data () {
    return {
      msg: '',
      dialog: false
    }
  },
  methods: {
    async deleteEmployee () {
      try {
        if (this.$store.state.employeeid) {
          await EmployeeService.delete(this.$store.state.employeeid)
          await this.$router.push({
            name: 'employee'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async employeelist () {
      try {
        await this.$router.push({
          name: 'employee'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
