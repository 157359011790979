<template>
  <div class="mainbookprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ledgerEN from './mainbookDescEn'
import ledgerHR from './mainbookDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import {sortByDate, dynamicSort, parseNum, round} from '@/snippets/allSnippets'
import dayjs from 'dayjs'

export default {
  name: 'mainbookprint',
  props: ['kpi'],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0,
      RecCash: 0,
      RecBank: 0,
      RecFree: 0,
      RecVAT: 0,
      RecSpecial: 0,
      RecTotal: 0,
      ExpCash: 0,
      ExpBank: 0,
      ExpFree: 0,
      ExpVAT: 0,
      ExpSpecial: 0,
      ExpTotal: 0,
      tRecCash: 0,
      tRecBank: 0,
      tRecFree: 0,
      tRecVAT: 0,
      tRecSpecial: 0,
      tRecTotal: 0,
      tExpCash: 0,
      tExpBank: 0,
      tExpFree: 0,
      tExpVAT: 0,
      tExpSpecial: 0,
      tExpTotal: 0,
      totalDoc: 0
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    
  },
  methods: {
    print (type, zeros) {
      this.totalDoc = 0

      this.initDocument()
      this.addHeaderNoLogo(type)
      this.addEmptyRow()
      this.addLine()
      if (type === 2) {
        this.addItemsHeader()
        this.addLine()
        this.addItems(this.kpi.RacuniIspis ? this.kpi.RacuniIspis.sort(dynamicSort("BookDate")): [])
        this.addLine()
        this.addItemsFoter()
        this.addLine()
        this.addTotalsFoter()
      }
      if (type === 5) {
        this.allDocCards(type, zeros)
      }
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {
        footer: function(currentPage, pageCount) {
           return ({text: ' Stranica: ' + currentPage.toString() + ' od ' + pageCount, fontSize: 8, alignment: 'center' });
         },
      }
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'landscape'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.content = []
    },
    allDocCards (type, zeros) {
      this.tRecCash = 0
      this.tRecBank = 0
      this.tRecFree = 0
      this.tRecVAT = 0
      this.tRecSpecial = 0
      this.tRecTotal = 0
      this.tExpCash = 0
      this.tExpBank = 0
      this.tExpFree = 0
      this.tExpVAT = 0
      this.tExpSpecial = 0
      this.tExpTotal = 0

      let haveCards = this.kpi.RacuniIspis.filter(item => item.BookDocId > 0 )

      const res = Array.from(haveCards.reduce(
        (m, {BookDocId, RecTotal}) => m.set(BookDocId, (m.get(BookDocId) || 0) + parseNum(RecTotal,2)), new Map
      ), ([BookDocId, RecTotal]) => ({BookDocId, RecTotal}));

      if (res) {
        res.map (card => {
            const cardItems = haveCards.filter(oneCard => oneCard.BookDocId === card.BookDocId)
            cardItems.sort(dynamicSort("BookDate"))
            const cardHead = this.kpi.Docs.find(oneHead => oneHead.id === card.BookDocId)

            // only if is marked as to print deposits 
            if (type === 5 && zeros) {
              this.RecSpecial = 0
              cardItems.map(item => {
                this.RecSpecial += item.RecSpecial ? parseNum(item.RecSpecial)  : 0
              })
              // border as 0.5 EUR (print only bigger amounts)
              const specB = Math.abs(this.RecSpecial)
              if (this.RecSpecial !==0 && specB > 4) {
                // then print all
                //this.addEmptyRow()
                this.addHeaderCard(cardHead)
                this.addItemsHeader(type)
                this.addLine()
                this.addItems(cardItems, type)
                this.addLine()
                this.addItemsFoterDoc()
              }
            }
            if (type === 5 && !zeros) {
              //this.addEmptyRow()
              this.addHeaderCard(cardHead)
              this.addItemsHeader(type)
              this.addLine()
              this.addItems(cardItems, type)
              this.addLine()
              this.addItemsFoterDoc()
            }

        })

        this.addEmptyRow()
        this.addLine()
        this.addEmptyRow()
        this.addLine()
        this.addTotalsFoterDoc()
      }

    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 800,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo (type) {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '35%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text:  (type === 5 ? 'Knjiga depozita\n' : 'Knjiga primitaka - izdataka\n'), fontSize: 18, bold: true },
                  { text: ' Od datuma: ' + dayjs(this.kpi.DatumOd).format('DD.MM.YYYY') + ' Do datuma: ' + dayjs(this.kpi.DatumDo).format('DD.MM.YYYY')  + ' \n', fontSize:10, bold: true},
                  { text: (this.kpi.DocId ? this.kpi.DocId : '') + (this.kpi.DocDesc ? ', ' + this.kpi.DocDesc : '') + ' \n', fontSize:10, bold: true},
                  { text: (this.kpi.DocOpenDate ? ' Otvoreno: ' + dayjs(this.kpi.DocOpenDate).format('DD.MM.YYYY'): '') + (this.kpi.DocClosecDate ? ' Zatvoreno: ' + dayjs(this.kpi.DocClosecDate).format('DD.MM.YYYY') : '')  + ' \n', fontSize:10, bold: true}
                ]   
              },
              {
                // % width
                width: '20%',
                text: [                 
                  { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeaderCard (cardHead) {
      const row2 = (cardHead.DocOpenDate ? ' Otvoreno: ' + dayjs(cardHead.DocOpenDate).format('DD.MM.YYYY'): '') + (cardHead.DocClosecDate ? ' Zatvoreno: ' + dayjs(cardHead.DocClosecDate).format('DD.MM.YYYY') : '') 
      if (row2.length > 0) {
      const docPart = {
            columns: [
              {
                // % width
                width: '55%',
                text: [
                  { text: (cardHead.DocID ? 'Spis: ' + cardHead.DocID : '') + (cardHead.DocName ? ', ' + cardHead.DocName : '') + ' \n', fontSize:14, bold: true},
                  { text: (cardHead.DocOpenDate ? ' Otvoreno: ' + dayjs(cardHead.DocOpenDate).format('DD.MM.YYYY'): '') + (cardHead.DocClosecDate ? ' Zatvoreno: ' + dayjs(cardHead.DocClosecDate).format('DD.MM.YYYY') : '')  + ' \n', fontSize:8}
                ]   
              },
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
      }
      if (row2.length === 0) {
      const docPart = {
            columns: [
              {
                // % width
                width: '55%',
                text: [
                  { text: (cardHead.DocID ? 'Spis: ' + cardHead.DocID : '') + (cardHead.DocName ? ', ' + cardHead.DocName : '') + ' \n', fontSize:14, bold: true},
                ]   
              },
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
      }
    },
    addItemsHeader (type) {
      if (type === 5) {
        const docPart = {
        columns: [
              {
                width: '3%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.id
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.BookDate 
              },
              {
                width: '20%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.BookDesc1
              },
              {
                width: '30%',
                fontSize: 7,
                alignment: 'left',
                text: this.lang.BookDesc2
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: 'Depozit'
              },
            ]
          }
        this.content.push(docPart) 
      }
      if (type !== 5) {
        const docPart = {
        columns: [
              {
                width: '3%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.id
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.BookDate 
              },
              {
                width: '11%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.BookDesc1
              },
              {
                width: '9%',
                fontSize: 7,
                alignment: 'left',
                text: this.lang.BookDesc2
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.RecCash
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.RecBank
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.RecFree
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.RecVAT
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.RecSpecial
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.RecTotal
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.ExpCash
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.ExpBank
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.ExpFree
              },
              {
                width: '4%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.ExpVAT
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.ExpSpecial
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.ExpTotal
              }
            ]
          }
        this.content.push(docPart) 
      }       
    },
    addItems (printIT, type) {
            this.order = 0           
            this.RecCash = 0
            this.RecBank = 0
            this.RecFree = 0
            this.RecVAT = 0
            this.RecSpecial = 0
            this.RecTotal = 0
            this.ExpCash = 0
            this.ExpBank = 0
            this.ExpFree = 0
            this.ExpVAT = 0
            this.ExpSpecial = 0
            this.ExpTotal = 0
            this.printitems = sortByDate(printIT, 'date')
            if (this.printitems) {
              this.printitems.map(item => {
                  
                  this.order += 1
                  this.RecCash += item.RecCash ? parseNum(item.RecCash)  : 0
                  this.RecBank += item.RecBank ? parseNum(item.RecBank)  : 0
                  this.RecFree += item.RecFree ? parseNum(item.RecFree)  : 0
                  this.RecVAT += item.RecVAT ? parseNum(item.RecVAT)  : 0
                  this.RecSpecial += item.RecSpecial ? round(parseNum(item.RecSpecial),2)  : 0
                  this.RecTotal += item.RecTotal ? parseNum(item.RecTotal)  : 0
                  this.ExpCash += item.ExpCash ? parseNum(item.ExpCash)  : 0
                  this.ExpBank += item.ExpBank ? parseNum( item.ExpBank) : 0
                  this.ExpFree += item.ExpFree ?  parseNum(item.ExpFree) : 0
                  this.ExpVAT += item.ExpVAT ? parseNum(item.ExpVAT)  : 0
                  this.ExpSpecial += item.ExpSpecial ? parseNum(item.ExpSpecial)  : 0
                  this.ExpTotal += item.ExpTotal ? parseNum(item.ExpTotal) : 0

                  this.tRecCash += item.RecCash ? parseNum(item.RecCash)  : 0
                  this.tRecBank += item.RecBank ? parseNum(item.RecBank)  : 0
                  this.tRecFree += item.RecFree ? parseNum(item.RecFree)  : 0
                  this.tRecVAT += item.RecVAT ? parseNum(item.RecVAT)  : 0
                  this.tRecSpecial += item.RecSpecial ? parseNum(item.RecSpecial)  : 0
                  this.tRecTotal += item.RecTotal ? parseNum(item.RecTotal)  : 0
                  this.tExpCash += item.ExpCash ? parseNum(item.ExpCash)  : 0
                  this.tExpBank += item.ExpBank ? parseNum( item.ExpBank) : 0
                  this.tExpFree += item.ExpFree ?  parseNum(item.ExpFree) : 0
                  this.tExpVAT += item.ExpVAT ? parseNum(item.ExpVAT)  : 0
                  this.tExpSpecial += item.ExpSpecial ? parseNum(item.ExpSpecial)  : 0
                  this.tExpTotal += item.ExpTotal ? parseNum(item.ExpTotal) : 0

                  
                  if (type === 5) {
                  const docPart = {
                  columns: [
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'left',
                          text: parseInt(this.order)
                        },
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'left',
                          text: dayjs(item.BookDate).format('DD.MM.YYYY')
                        },
                        {
                          width: '20%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.BookDesc1
                        },
                        {
                          width: '30%',
                          fontSize: 7,
                          alignment: 'left',
                          text: (item.BookDocDesc && item.BookDocDesc.length > 0 ? (item.BookDesc2 ? item.BookDesc2 + ', ' : '')  + item.BookDocDesc : item.BookDesc2)
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          color: (item.RecSpecial >= 0 ? '#229954' : '#C0392B'),
                          text: this.formatPrice(item.RecSpecial)
                        },
                      ]
                    }
                    this.content.push(docPart)

                  }
                  if (type !== 5) {
                  const docPart = {
                  columns: [
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'left',
                          text: parseInt(this.order)
                        },
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'left',
                          text: dayjs(item.BookDate).format('DD.MM.YYYY')
                        },
                        {
                          width: '11%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.BookDesc1
                        },
                        {
                          width: '9%',
                          fontSize: 7,
                          alignment: 'left',
                          text: (item.BookDocDesc && item.BookDocDesc.length > 0 ? (item.BookDesc2 ? item.BookDesc2 + ', ' : '')  + item.BookDocDesc : item.BookDesc2)
                        },
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.RecCash)
                        },
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'right',
                          text:this.formatPrice(item.RecBank)
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.RecFree)
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.RecVAT)
                        },
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'right',
                          color: (item.RecSpecial >= 0 ? '#229954' : '#C0392B'),
                          text: this.formatPrice(item.RecSpecial)
                        },
                        {
                          width: '7%',
                          fontSize: 8,
                          alignment: 'right',
                          bold: true,
                          text: this.formatPrice(item.RecTotal)
                        },
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.ExpCash)
                        },
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.ExpBank)
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.ExpFree)
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.ExpVAT)
                        },
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'right',
                          color: (item.ExpSpecial >= 0 ? '#229954' : '#C0392B'),
                          text: this.formatPrice(item.ExpSpecial)
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text:  this.formatPrice(item.ExpTotal)
                        }
                      ]
                    }
                  this.content.push(docPart)
                  }
                }
              )
          }
    },
    addItemsFoterDoc () {
        this.totalDoc += round(this.RecSpecial, 2)
        console.log('this.totalDoc', this.totalDoc)
        const docPart = {
        columns: [
              {
                width: '3%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '20%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '28%',
                fontSize: 8,
                alignment: 'right',
                text: ''
              },
              {
                width: '10%',
                fontSize: 10,
                alignment: 'right',
                color: (this.RecSpecial >= 0 ? '#229954' : '#C0392B'),
                text: this.formatPrice(this.RecSpecial)
              },
            ]
          }
        this.content.push(docPart)        
    },
    addItemsFoter () {
        const docPart = {
        columns: [
              {
                width: '3%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '19%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.kpi.Ukupno.RecCash)
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.kpi.Ukupno.RecBank)
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.kpi.Ukupno.RecFree)
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                bold: true,
                text: this.formatPrice(this.kpi.Ukupno.RecVAT)
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                color: (this.kpi.Ukupno.RecSpecial >= 0 ? '#229954' : '#C0392B'),
                text: this.formatPrice(this.kpi.Ukupno.RecSpecial)
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.kpi.Ukupno.RecTotal)
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.kpi.Ukupno.ExpCash)
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.kpi.Ukupno.ExpBank)
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.kpi.Ukupno.ExpFree)
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.kpi.Ukupno.ExpVAT)
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                color: (this.kpi.Ukupno.ExpSpecial >= 0 ? '#229954' : '#C0392B'),
                text: this.formatPrice(this.kpi.Ukupno.ExpSpecial)
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.kpi.Ukupno.ExpTotal)
              }
            ]
          }
        this.content.push(docPart)        
    },
    addTotalsFoterDoc () {
        // const Total1 = this.tRecCash - this.tExpCash
        // const Total2 = this.tRecBank - this.tExpBank
        // const Total3 = this.tRecFree - this.tExpFree
        // const Total4 = this.tRecVAT - this.tExpVAT
        // const Total5 = this.tExpSpecial
        //const Total6 = this.tRecTotal - this.tExpTotal
        const Total7 = this.tRecSpecial

        const docPart = {
        columns: [
              {
                width: '9%',
                fontSize: 8,
                alignment: 'right',
                text: 'SVEUKUPNO:' 
              },
              {
                width: '20%',
                fontSize: 8,
                alignment: 'right',
                text: '' 
              },
              {
                width: '23%',
                fontSize: 8,
                alignment: 'right',
                text: '' 
              },
 
              {
                width: '15%',
                fontSize: 8,
                alignment: 'right',
                color: (Total7 !== 0 ? '#229954' : '#C0392B'),
                text: 'Ukupno:' + this.formatPrice(Total7)
              },
            ]
          }
        this.content.push(docPart)        
    },
    addTotalsFoter () {
        const docPart = {
        columns: [
              {
                width: '7%',
                fontSize: 8,
                alignment: 'right',
                text: 'SVEUKUPNO:' 
              },
              {
                width: '15%',
                fontSize: 8,
                alignment: 'right',
                text: 'Gotovina:' + this.formatPrice(this.kpi.Ukupno.Total1)
              },
              {
                width: '15%',
                fontSize: 8,
                alignment: 'right',
                text: 'Žiro račun:' + this.formatPrice(this.kpi.Ukupno.Total2)
              },
              {
                width: '15%',
                fontSize: 8,
                alignment: 'right',
                text: 'U naravi:' + this.formatPrice(this.kpi.Ukupno.Total3)
              },
              {
                width: '15%',
                fontSize: 8,
                alignment: 'right',
                bold: true,
                text: 'PDV:' + this.formatPrice(this.kpi.Ukupno.Total4)
              },
              {
                width: '15%',
                fontSize: 8,
                alignment: 'right',
                text: 'Ostalo:' + this.formatPrice(this.kpi.Ukupno.Total5)
              },
              {
                width: '15%',
                fontSize: 8,
                alignment: 'right',
                color: (this.kpi.Ukupno.Total6 >= 0 ? '#229954' : '#C0392B'),
                text: 'Ukupno:' + this.formatPrice(this.kpi.Ukupno.Total6)
              },
            ]
          }
        this.content.push(docPart)        
    },
  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>