<template>
  <v-container>
    <v-layout row wrap  justify-center>
      <v-flex xs12 sm12 md12 lg9 xl6>

            <v-layout row>
                <h4>
                    <span v-if="this.$store.state.language == 'EN'">
                    Dodaj sliku u stavku na dokumentu {{ documentDet.documentName }}
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Dodaj sliku u stavku na dokumentu {{ documentDet.documentName }}
                    </span>
                </h4>
            </v-layout>

            <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                   <span v-if="this.$store.state.language == 'EN'">
                    Item ID:
                   </span>
                   <span v-if="this.$store.state.language == 'HR'">
                    Sifra:
                   </span>
                </v-flex>
                <v-flex xs8 class="py-0 px-0">
                    <span style="font-weight:bold">{{documentDet.itemID}}</span>
                </v-flex>
            </v-layout>

            <v-layout row >
              <v-flex xs4 class="py-0 px-2">
                   <span v-if="this.$store.state.language == 'EN'">
                    Item group:
                   </span>
                   <span v-if="this.$store.state.language == 'HR'">
                    Grupa:
                   </span>
              </v-flex>
              <v-flex xs8 class="py-0 px-0">
                  <span style="font-weight:bold">{{documentDet.itemGroup}}</span>
              </v-flex>
            </v-layout> 

             <v-layout row>
              <v-flex xs4 class="py-0 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Item name:
                   </span>
                   <span v-if="this.$store.state.language == 'HR'">
                    Naziv:
                   </span>
              </v-flex>
              <v-flex xs8 class="py-0 px-0">
                  <span style="font-weight:bold">{{documentDet.itemName}}</span>
              </v-flex>
            </v-layout>

             <v-layout row>
              <v-flex xs4 class="py-0 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Item name short:
                   </span>
                   <span v-if="this.$store.state.language == 'HR'">
                    Naziv skraceni:
                   </span>
              </v-flex>
              <v-flex xs8 class="py-0 px-0">
                  <span style="font-weight:bold">{{documentDet.itemNameShort}}</span>
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex xs4 class="py-0 px-2">
                  <span v-if="this.$store.state.language == 'EN'">
                    Description:
                   </span>
                   <span v-if="this.$store.state.language == 'HR'">
                    Opis:
                   </span>
              </v-flex>
              <v-flex xs8 class="py-0 px-0">
                  <span style="font-weight:bold">{{documentDet.itemDescription}}</span>
              </v-flex>
            </v-layout> 
            <div id="app2">
                <img :src="selectedFile" width="200" height="200" />
                <input type="file" @change="onFileChanged">
                <v-btn
                    class="primary"
                    :disabled="!selectedFile"
                    @click="register">
                    <span v-if="this.$store.state.language == 'EN'">
                    Save picture  
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Snimi sliku 
                    </span>  
                </v-btn>
            </div>

          <v-btn
            color="green"
            @click="documentdetaillist">
            <span v-if="this.$store.state.language == 'EN'">
            Back 
            </span>
            <span v-if="this.$store.state.language == 'HR'">
            Natrag 
            </span>  
          </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import DocumentDetailService from '@/services/DocumentDetailService'

export default {
  name: 'documentdetailupload',
  $validates: true,
  data () {
    return {
      selectedFile: null,
      menu: false,
      menu2: false,
      menu3: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      documentName: '',
      documentDet: { },
      error: null,
      localError: false,
      newDocument: {},
      size: 'sm',
      required: (value) => !!value || 'Required.',
      itemsLoc: [],
      itemLoc: {},
      CompanyId: 0,
      selectIL: {},
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
        documentName: ''
      },
      mainQuery2: {
        CompanyId: 1,
        itemID: ''
      },
      currnecyType: [
        { text: 'Kn', value: 'Kn' },
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      money: {
        prefix: '= ',
        suffix: ' ',
        precision: 3
      },
      price: 7.500
    }
  },
  components: {
  },
  async mounted () {
    if (this.$store.state.editItem === 1) {
      const locDocumentDet = await DocumentDetailService.show(this.$store.state.documentdetailid)
      this.documentDet = locDocumentDet.data.documentDetail
      this.$store.dispatch('seteditItem', 0)
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.BusinessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentActiv) {
        this.mainQuery.documentName = this.$store.state.documentActiv
      }
      if (this.$store.state.databaseDocID) {
        this.mainQuery.databaseDocID = this.$store.state.databaseDocID
      }
    }
  },
  computed: {
    formIsValid () {
      return this.documentDet.itemID !== ''
    }
  },
  methods: {
    onFileChanged (event) {
      this.selectedFile = event.target.files[0]
    },
    onUpload () {
        // upload file, get it from this.selectedFile
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async register (formData) {
      try {
        this.localError = false
        await DocumentDetailService.put(formData)
        this.$router.push({
          name: 'documentdetail'
        })
      } catch (error) {
        alert('Cannot save document to database.')
        this.error = 'Greska u konekciji na bazu'
      }
    },
    close () {
      this.show = false
    },
    async documentdetaillist () {
      try {
        await this.$router.push({
          name: 'documentdetail'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    onChange (image) {
      if (image) {
        this.image = image
      } else {
        // eslint-disable-next-line
        console.log('FileReader API not supported: use the <form>, Luke!')
      }
    }
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
#app2 {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}
</style>
