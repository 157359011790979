<template>
  <router-view></router-view>
</template>

<script>

  export default {
    data () {
      return {
        clipped: false,
        drawer: true,
        fixed: false,
        items: [
          { icon: 'bubble_chart', title: 'Inspire' }
        ],
        miniVariant: false,
        right: true,
        rightDrawer: false,
        title: 'Roboot.net',
        sideNav: false,
        menuItems: [
        ],
        account1s: [],
        account2s: [],
        account3s: [],
        account4s: [],
      }
    },
    mounted () {

    }
  }
</script>
