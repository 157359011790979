<template>
  <div class="employeecreate">
    <h2>
      {{lang.AdditionalData}}
      </h2>
    <v-container grid-list-md text-xs-center>
      <v-layout >

        <v-flex xs4 >
          <h3 class="text-xs-left mb-2">
            {{lang.WorkBefore}}
          </h3>
    
        <v-row>
          <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
            <v-text-field
              :label="lang.WorkYearsBefore"
              v-model.number="employee.WorkYearsBefore"
            ></v-text-field>
          </v-col>
           <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
            <v-text-field
              :label="lang.WorkMonthsBefore"
              v-model.number="employee.WorkMonthsBefore"
            ></v-text-field>
          </v-col>
           <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
            <v-text-field
              :label="lang.WorkDaysBefore"
              v-model.number="employee.WorkDaysBefore"
            ></v-text-field>
          </v-col>
        </v-row>

         <v-row>
            <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3" class="pr-2">
            <v-autocomplete
                :items="cities"
                v-model="selectCity"
                :label="lang.CityTaxId"
                item-text="CityName"
                item-value="CityCode"
                return-object
                persistent-hint
                clearable
                dense
              ></v-autocomplete> 
          </v-col>
           <v-col cols="12" xs="6" sm="6" md="6" lg="2" xl="2" >
            <v-text-field
              :label="lang.WorkInCityCode"
              v-model="employee.WorkInCityCode"
            ></v-text-field>
          </v-col>
           <v-col cols="12" xs="6" sm="6" md="6" lg="4" xl="4" >
            <v-text-field
              :label="lang.WorkInCityName"
              v-model="employee.WorkInCityName"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" >
            <v-text-field
              :label="lang.Pregnancy"
              v-model="employee.Pregnancy"
            ></v-text-field>
          </v-col>
           <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" >
            <v-text-field
              :label="lang.Maternity"
              v-model="employee.Maternity"
            ></v-text-field>
          </v-col>
           <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" >
            <v-text-field
              :label="lang.Breastfeeding"
              v-model="employee.Breastfeeding"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" >
            <v-text-field
              :label="lang.OnlyParent"
              v-model="employee.OnlyParent"
            ></v-text-field>
          </v-col>
           <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" >
            <v-text-field
              :label="lang.AdoptionStatus"
              v-model="employee.AdoptionStatus"
            ></v-text-field>
          </v-col>
        </v-row>                  

          <br>
          <div class="danger-alert" v-html="error" />
          <br>

          <v-btn
            dark
            class="blue mr-2"
            @click="register">
            {{langC.Save}}
          </v-btn>
          <v-btn
            color="green"
            @click="employeeList">
            {{langC.Back}}
          </v-btn>
        </v-flex>


        <v-flex xs4 >
          <h3 class="text-xs-left">
            {{lang.WorkEnvironment}}
          </h3>

          <v-text-field
            :label="lang.ProfessionalIllnes"
            v-model="employee.ProfessionalIllnes"
          ></v-text-field>

          <v-text-field
            :label="lang.HurtOnWork"
            v-model="employee.HurtOnWork"
          ></v-text-field>      

          <v-text-field
            :label="lang.ProfessionalCantWork"
            v-model="employee.ProfessionalCantWork"
          ></v-text-field>      

          <v-text-field
            :label="lang.ProfessionalLowerAbbilities"
            v-model="employee.ProfessionalLowerAbbilities"
          ></v-text-field>      

          <v-text-field
            label="Professional Lose Wrok Abbility:"
            v-model="employee.ProfessionalLoseWrokAbbility"
          ></v-text-field>
      
          <v-text-field
            :label="lang.DangerOfLosingProfAbbilityInjury"
            v-model="employee.DangerOfLosingProfAbbilityInjury"
          ></v-text-field>
      
          <v-text-field
            :label="lang.DangerOfHaveDisability"
            v-model="employee.DangerOfHaveDisability"
          ></v-text-field>     

          <v-text-field
            :label="lang.Disability"
            v-model="employee.Disability"
          ></v-text-field>       

          <v-text-field
            :label="lang.DisabilityPension"
            v-model="employee.DisabilityPension"
          ></v-text-field>

          <v-text-field
            :label="lang.DisabilityWorkTIme"
            v-model="employee.DisabilityWorkTIme"
          ></v-text-field>         

          <v-text-field
            :label="lang.WorkForOtherEmployee"
            v-model="employee.WorkForOtherEmployee"
          ></v-text-field>           

        </v-flex>

        <v-flex xs2 >
          <h3 class="text-xs-left">
            {{lang.PersonalData}}         
          </h3>

         <v-text-field
            :label="lang.PersonalHeight"
            v-model="employee.PersonalHeight"
          ></v-text-field>

         <v-text-field
            :label="lang.PersonalSizeUp"
            v-model="employee.PersonalSizeUp"
          ></v-text-field>

         <v-text-field
            :label="lang.PersonalSizeDown"
            v-model="employee.PersonalSizeDown"
          ></v-text-field>

         <v-text-field
            :label="lang.PersonalShoes"
            v-model="employee.PersonalShoes"
          ></v-text-field>                             

        </v-flex>

      </v-layout>
    </v-container>

  </div>
</template>

<script>
import EmployeeService from '@/services/EmployeeService'
import CityService from '@/services/CityService'
import langEn from './empDescEn'
import langHr from './empDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'

export default {
  name: 'employeedit2',
  data () {
    return {
      lang: {},
      langC: {},
      menu: false,
      modal: false,
      msg: '',
      newEmployee: {},
      employee: { },
      required: (value) => !!value || 'Required.',
      error: '',
      cities: [],
      selectCity: {},
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.employeeid) {
      try {
        const {data} = await CityService.index()
        if (data && data.cities !== undefined){
          this.cities = data.cities
        }
        const dbEmployee = await EmployeeService.show(this.$store.state.employeeid)
        // console.log(dbEmployee.data.employee)
        this.employee = dbEmployee.data.employee
        if (this.employee.WorkInCityCodeID) {
          this.selectCity = this.cities.find( city => city.id === this.employee.WorkInCityCodeID)
        }
      } catch (error) {
        this.error = error
      }
    }
  },
  methods: {
    async register () {
      try {
        if (this.$store.state.user.email) {
          this.employee.email = this.$store.state.user.email
          this.employee.LastUpdateBy = this.$store.state.user.email
        }
        await EmployeeService.put(this.employee)
        this.$router.push({
          name: 'employeeview'
        })
      } catch (error) {
        this.error = error.response.data.error
      }
    },
    async employeeList () {
      try {
        await this.$router.push({
          name: 'employeeview'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  computed: {
  },
  watch: {
     'selectCity' () {
      if (this.selectCity) {
        this.employee.WorkInCityCodeID = this.selectCity.id
        this.employee.WorkInCityCode = this.selectCity.CityCode
        this.employee.WorkInCityName = this.selectCity.CityName
      }
      if (!this.selectCity) {
        this.employee.WorkInCityCode = null
        this.employee.WorkInCityName = null
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
