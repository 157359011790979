import Api from '@/services/Api'

export default {
  index (search) {
    return Api().get('company', {
      params: {
        search: search
      }
    })
  },
  show (companyId) {
    return Api().get(`company/${companyId}`)
  },
  choose (query) {
    return Api().get('company/choose', {
      params: query
    })
  },
  post (newcompany) {
    return Api().post('company', newcompany)
  },
  put (company) {
    return Api().put(`company/${company.id}`, company)
  },
  delete (companyId) {
    return Api().delete(`company/${companyId}`)
  }
}
