const docHR = {
  EmployeeList: 'Zaposlenici lista',
  EmployeeCreate: 'Dodaj novog zaposlenika',
  EmployeeDetails: 'Zaposlenik detalji',
  BasicData: 'Osnovni podaci',
  AdditionalData: 'Dodatni podaci',
  CompanyName: 'Tvrtka ',
  WorkStatus: 'Radni odnos',
  WorkEnvironment: 'Radni uvjeti',
  Edit2: 'Uredi plaću',
  Edit3: 'Uredi dodatne podatke',
  Tasks: 'Zadaci',
  hashtag: '#hashtag *',
  eemail: 'email',
  phone: 'Telefon',
  First: 'Ime *',
  Last: 'Prezime *',
  Gender: 'Spol(M ili Z)',
  VatID: 'OIB *',
  PersonalID1: 'Broj osobne iskaznice',
  PersonalID2: 'HZZO MBO',
  PersonalID3: '',
  PersonalID4: '',
  PersonalID5: '',
  DateOfBirht: 'Datum rođenja *',
  PlaceOfBirht: 'Mjesto rođenja',
  PlaceOfBirthState: 'Država rođenja',
  Foriner: 'Stranac',
  ForinerLicense: 'Stranac Licenca za rad',
  PlaceOfLive: 'Prebivalište',
  Occupation: 'Zanimanje',
  Degree: 'Obrazovanje - stupanj',
  SpecialKnowledge: 'Specijalna znanja',
  StartInCompany: 'Početak rada u firmi',
  WorkForOtherCompany: 'Rad za drugu tvrtku',
  WorkForOtherCompanyAbroad: 'Rad za tvrtku u inozemstvu',
  WorkForOtherConnectedCompany: 'Rad za drugu povezanu tvrtku',
  WorkHardConditions: 'Radno mjesto-otežani uvjeti',
  WorkPlace: 'Radno mjesto',
  WeeklyWorkHours: 'Tjedno radnih sati',
  NotWorkingTime: 'Vrijeme koje ne radi',
  EndDateInCompany: 'Kraj rada u firmi',
  EndReason: 'Razlog završetka rada',
  OtherData: 'Ostali podaci',
  InternalNotes: 'Interna bilješka',
  Activ: 'Aktivno zaposlen',
  Black: 'Honorar',
  WorkBefore: 'Rad do dolaska u firmu',
  WorkYearsBefore: 'Godine',
  WorkMonthsBefore: 'Mjeseci',
  WorkDaysBefore: 'Dana',
  WorkInCityCodeID: 'Rad se obavlja u mjestu',
  WorkInCityCode: 'Šifra grada',
  WorkInCityName: 'Naziv grada',
  Pregnancy: 'Trudnoća',
  Maternity: 'Majčinstvo',
  Breastfeeding: 'Dojenje',
  OnlyParent: 'Status samohranog roditelj',
  AdoptionStatus: 'Status posvojitelja',
  ProfessionalIllnes: 'Profesionalna bolest',
  HurtOnWork: 'Ozljeda na radu',
  ProfessionalCantWork: 'Profesionalna nesposobnost za rad',
  ProfessionalLowerAbbilities: 'Smanjenje radne sposobnosti uz preostalu radnu sposobnost',
  ProfessionalLoseWrokAbbility: 'Smanjenje radne sposobnosti uz djelomični gubitak radne sposobnosti',
  DangerOfLosingProfAbbilityInjury: 'Neposredna opasnost od nastanka smanjenja radne sposobnosti',
  DangerOfHaveDisability: 'Neposredna opasnost od nastanka invalidnosti',
  Disability: 'Invalidnost',
  DisabilityPension: 'Invalidska mirovina zbog djelomičnog gubitka radne sposobnosti',
  DisabilityWorkTIme: 'Invalid - smanjeno radno vrijeme',
  WorkForOtherEmployee: 'Za radnike koji rade u nepunom radnom vremenu podatak o svakom drugom poslodavcu kod kojeg radnik radi',
  PersonalData: 'Osobne mjere',
  PersonalData2: 'Visina, vl.majce, vl.hlaća, vl.cipela',
  PersonalHeight: 'Osobni podatak - visina',
  PersonalSizeUp: 'Osobni podatak - veličina majce',
  PersonalSizeDown: 'Osobni podatak - veličina hlaca',
  PersonalShoes: 'Osobni podatak - veličina cipela',
  EmployeeDiaryCreate: 'Upis radnika u dnevnik',
  Dateindatabase: 'Datum upisa u bazu:',
  Employee: 'Radnik',
  SelectEmployee: 'Odaberi radnika',
  ActionType: 'Vrsta upisa',
  ActionTypeSelect: 'Odaberi vrsta upisa',
  WorkType: 'Radno vrijeme',
  WorkTypeSelect: 'Odaberi radno vrijeme',
  WorkHours: 'Broj sati',
  WorkMinutes: 'Minuta',
  ForDate: 'Datum prijave/odjave/promjene',
  AlarmDate: 'Datum kraja statusa',
  HealthServiceID: 'HZZO broj upisa ID',
  PensionFundID: 'Mirovinsko ID',
  EmplDayFree1: 'Kratki opis upisa',
  SalarieDefinition: 'Definicija plaće',
  Bruto1:' Bruto plaća 1 €',
  Add1:'Prvi dodatak na bruto 1',
  Add2:'Drugi dodatak na bruto 1',
  Bruto2: 'Bruto plaća 2 €',
  SalFrom: 'Doprinosi iz plaće',
  SalFrom1: 'Doprinos iz',
  SalFrom1Desc: 'Doprinos opis',
  SalFrom1Per: '%',
  SalFrom2: 'Doprinos iz',
  SalFrom2Desc: 'Doprinos opis',
  SalFrom2Per: '%',
  SalFrom2Amount: 'Iznos',
  FinNeto1ls: 'Dohodak €',
  Deduction: 'Osobni odbitak €',
  DeductionKids: 'Odbitak za djecu',
  DeductionFamily: 'Odbitak za uzdržavane članove obitelji',
  DeductionDisablity: 'Invaliditet',
  DeductionFactor: 'Faktor',
  DeductionAmount: 'Iznos odbitka',
  DeductionUse: '% koji se koristi',
  DeductionFinalAmounut: 'Ukupni iznos odbitka €',
  DeductionDontUse: 'Iznos odbitka koji se NE koristi',
  DeductionDontUseAtAll: 'Ne koristi se',
  CityTaxPer: 'Postotak prireza',
  CityTaxId: 'Šifra grada/općine prebivališta',
  CityTaxName: 'Naziv grada/općine prebivališta',
  Neto: 'Neto Plaća €',
  NetoAdd: 'Neoporeziva naknada €',
  NetoAddDesc: 'Vrste i iznosi neoporezivih naknada',
  NetoAddType: 'Vrsta',
  NetoAddTypeName: 'Naziv vrste',
  NetoAddJoppd:'Joppd kolona',
  NetoAddAmounut: 'Iznos neoporezive naknade €',
  FinNeto3: 'Za isplatu €',
  SalToHead: 'Doprinosi na plaću',
  SalTo: 'Doprinos na',
  SalToDesc: 'Doprinos opis',
  SalToPer: '%',
  SalToAmount: 'Iznos €',
  TaxTax: 'Porez i Prirez',
  Tax20h: 'Porez 20%',
  Tax20: 'Porez 20% osn',
  Tax20Desc: '(do 3981.69 € mjesečno)',
  Tax20Am: 'Porez 20% iznos',
  Tax30h: 'Porez 30%',
  Tax30: 'Porez 30% osn',
  Tax30Desc: '(iznad 3981.69 € mjesečno)',
  Tax30Am: 'Porez 30% iznos',
  TaxOnTax: 'Prirez',
  TaxOnTaxAm: 'Prirez Iznos',
  TaxTotal: 'Porez + Prirez ukupno €=',
  Bruto3: 'UKUPAN TROŠAK RADA €'
}

export default docHR