import Api from '@/services/Api'
// import { log } from 'util';

export default {
  markclosed(query) {
    return Api().get('mainledgerside/markclosed', {
      params: query
    })
  },
  markcompclosed(query) {
    return Api().get('mainledgerside/markcompclosed', {
      params: query
    })
  },
}