import Api from '@/services/Api'

export default {
  index(query) {
    return Api().get('formdetail', {
      params: query    
    })
  },
  index2(query) {
    return Api().get('formdetail/showall', {
      params: query    
    })
  },
  show(id) {
    return Api().get(`formdetail/${id}`)
  },
  post(newformdetail) {
    return Api().post('formdetail', newformdetail)
  },
  put(formdetail) {
    return Api().put(`formdetail/${formdetail.id}`, formdetail)
  },
  delete(id) {
    return Api().delete(`formdetail/delete/${id}`)
  },
  deleteitems(query) {    
    return Api().get('formdetail/deleteitems', {params: query})
  },
  saveitems(items) {
    if (items) {
      return Api().post('formdetail/saveitems', items)
    }
    return
  },
}