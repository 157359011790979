<template>
    <v-container fluid grid-list-md>
      <v-row no-gutters class="pa-0">
        <v-col cols="12" xl="6" lg="6" xs="6" sm="6" md="6">
        
        </v-col>
        <v-col cols="12" xl="6" lg="6" xs="6" sm="6" md="6">
          <div class="text-right">
              <v-btn fab dark class="indigo" @click="newgroup">
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
          </div>
        </v-col>
      </v-row>
      <app-Group-list></app-Group-list>
    </v-container>
</template>

<script>
import Grouplist from "./GroupList";

export default {
  name: "group",
  data() {
    return {
      msg: ""
    };
  },
  mounted() {},
  methods: {
    async newgroup () {
      try {
        await this.$router.push({
          name: 'groupcreate'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  computed: {},
  watch: {},
  components: {
    appGroupList: Grouplist
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
