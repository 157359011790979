<template>
  <div class="groupcreate">
    <v-container grid-list-md text-xs-center>
      <v-layout >
        <v-flex xs4 >
         <form
            name="GroupForm"
            autocomplete="off">
            <h2 class="text-xs-left">Edit group</h2>
            <v-text-field
              label="Group name"
              required
              :rules="[required]"
              v-model="group.GroupName"
            ></v-text-field>
            <v-text-field
              label="Group belongs to id:"
              v-model="group.GroupBelongsTo"
            ></v-text-field>
          </form>
          <br>
          <div class="pink darken-2" v-html="error" />
          <br>
          <v-btn
            class="primary mr-2"
             :disabled="!formIsValid"
            @click="updateGroup"
            >
            {{langC.Save}}
          </v-btn>
          <v-btn
            color="green"
            @click="grouplist">
            {{langC.Back}}
          </v-btn>
      </v-flex>
    </v-layout>
    </v-container>

  </div>
</template>

<script>

import GroupService from '@/services/GroupService'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'

export default {
  name: 'groupedit',
  $validates: true,
  data () {
    return {
      lang: {},
      langC: {},
      menu: false,
      modal: false,
      msg: '',
      valid: true,
      group: {
      },
      locUpdatedAt: '',
      error: null,
      required: (value) => !!value || 'Required.',
      mainQuery: {}
    }
  },
  components: {
  },
  async mounted () {
    try {
      if (this.$store.state.language === 'EN') {
        this.langC = commEn
      }
      if (this.$store.state.language === 'HR') {
        this.langC = commHr
      }
       if (this.$store.state.companyid) {
          this.mainQuery.CompanyId = this.$store.state.companyid
        }
        console.log('state group ', this.$store.state.groupid)
        const dbGroup = await GroupService.show(this.$store.state.groupid)
        this.group = dbGroup.data.group
        console.log('Grupa ', this.group)
        this.$store.dispatch('seteditItem', 0)
      
    } catch (error) {
      // eslint-disable-next-line
      console.log('greska kod monut dokumenta', error)
    }
  },
  computed: {
    formIsValid () {
      return this.group.groupName !== ''
    }
  },
  methods: {
    async updateGroup () {
      try {
        // if (this.$store.state.user) {
        //   this.group.LastUpdateBy = this.$store.state.user.email
        // }
        await GroupService.put(this.group)
        this.groupsLoc = (await GroupService.index(this.mainQuery)).data.groups
        if (this.groupsLoc) {
          this.$store.dispatch('setActivGroups', this.groupsLoc)
        }
        this.$router.push({
          name: 'group'
        })
      } catch (error) {
        this.error = error.response.data.error
      }
    },
    close () {
      this.show = false
    },
    async grouplist () {
      try {
        await this.$router.push({
          name: 'group'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
