
<template>
  <v-container class="ma-2">
   <v-row no-gutters justify="center" class="pa-0">
    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">

    <v-row>
      <v-flex xs5>
          <h2>{{lang.PartnerDetails}}</h2>
        <br>
      </v-flex>
    </v-row>

     <v-row>
         <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <h3 class="mb-3"> {{lang.BasicData}}</h3>
            <app-pres-row :label="lang.PartnerName" :info="partner.partnerName" />
            <app-pres-row :label="lang.PartnerNameShort" :info="partner.partnerNameShort" />
            <app-pres-row :label="lang.PartnerAdress1" :info="adress1" />
            <app-pres-row :label="lang.PartnerAdress2" :info="adress2" />
            <app-pres-row :label="lang.PartnerVATID" :info="partner.partnerVATID" />
            <app-pres-row :label="lang.Partneremail" :info="partner.partneremail" /> 
            <app-pres-row :label="lang.PartnerPhones" :info="partner.partnerPhones" />
            <app-pres-row :label="lang.PartnerBankName" :info="bank" />
            <app-pres-row :label="lang.PartnerBankIBAN" :info="partner.partnerBankIBAN" />
            <app-pres-row :label="lang.PartnerBankSWIFT" :info="partner.partnerBankSWIFT" />
         </v-col>
       </v-row>

       <v-row>
           <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" v-if="!privateP">
              <h3 class="mb-3">{{lang.PartnerCEO}}</h3>

              <app-pres-row :label="lang.PartnerAdress2" :info="director" />
              <app-pres-row :label="lang.PartnerAdress2" :info="directorBorn" />
              <app-pres-row :label="lang.PartnerDirectorBusinessOccupation" :info="partner.partnerDirectorBusinessOccupation" />
              <app-pres-row :label="lang.PartnerAdress1" :info="adress3" />
              <app-pres-row :label="lang.PartnerAdress2" :info="adress4" />
              <app-pres-row :label="lang.PartnerDirectorEmail" :info="partner.partnerDirectorEmail" />
              <app-pres-row :label="lang.PartnerDirectorPhone" :info="partner.partnerDirectorPhone" />
              <app-pres-row :label="lang.PartnerDirectorContactOption" :info="partner.partnerDirectorContactOption" />
              
              <h3 class="mb-3 mt-3">{{lang.PartnerSecretary}}</h3>

              <app-pres-row :label="lang.PartnerAdress2" :info="contact" />
              <app-pres-row :label="lang.PartnerAdress2" :info="contactBorn" />
              <app-pres-row :label="lang.PartnerDirectorBusinessOccupation" :info="partner.partnerSecretaryBusinessOccupation" />
              <app-pres-row :label="lang.PartnerAdress1" :info="adress5" />
              <app-pres-row :label="lang.PartnerAdress2" :info="adress6" />
              <app-pres-row :label="lang.PartnerSecretaryEmail" :info="partner.partnerSecretaryEmail" />
              <app-pres-row :label="lang.PartnerSecretaryPhone" :info="partner.partnerSecretaryPhone" />
              <app-pres-row :label="lang.PartnerSecretaryContactOption" :info="partner.partnerSecretaryContactOption" />
          </v-col>
        </v-row>
      </v-col>
   </v-row>
  </v-container>
</template>

<script>
import PartnerService from '@/services/PartnerService'
import PresRow2 from '@/snippets/PressRow2'
import langEn from './PartnerDescEN'
import langHr from './PartnerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'

export default {
  name: 'partnerview',
  data () {
    return {
      msg: '',
      partner: {},
      locStartFrom: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locLastLogin: '',
      locForgotDate: '',
      haveRight: 4,
      error: '',
      lang: {},
      langC: {},
      privateP: true,
      admin: false,
      adress1: '',
      adress2: '',
      adress3: '',
      adress4: '',
      adress5: '',
      adress6: '',
      bank: '',
      director: '',
      directorBorn: '',
      contact: '',
      contactBorn: ''
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.user.Admin) {
        this.admin = this.$store.state.user.Admin
      }
    this.privateP = this.$store.state.user ? this.$store.state.user.privatePartner : true
    if (this.$store.state.partnerVATID) {
      try {
        const dbPartner = await PartnerService.show(
          this.$store.state.partnerVATID
        )
        this.partner = dbPartner.data.partner
        this.locStartFrom = dayjs(this.partner.StartFrom).format(
          'DD.MM.YYYY'
        )
        this.locCreatedAt = dayjs(this.partner.createdAt).format(
          'DD.MM.YYYY HH:MM'
        )
        this.locUpdatedAt = dayjs(this.partner.updatedAt).format(
          'DD.MM.YYYY HH:MM'
        )
      } catch (error) {
        this.error = error
      }
    }

    this.bank = (this.partner.partnerBankName ? this.partner.partnerBankName : '') + (this.partner.partnerBankAddress ? ', ' + this.partner.partnerBankAddress : '')
    this.adress1 = (this.partner.partnerStreet ? this.partner.partnerStreet : '') + ' ' + (this.partner.partnerBuildingNameNumber ? this.partner.partnerBuildingNameNumber : '')
    this.adress2 = (this.partner.partnerPostcode ? this.partner.partnerPostcode : '') + ' ' + (this.partner.partnerCity ? this.partner.partnerCity : '') + (this.partner.partnerCountyRegion ? ', ' + this.partner.partnerCountyRegion : '') + (this.partner.partnerState ? ', ' + this.partner.partnerState : '') 

    this.director = (this.partner.partnerDirectorTitle ? this.partner.partnerDirectorTitle : '') + ' ' + (this.partner.partnerDirectorFirst ? this.partner.partnerDirectorFirst : '') + ' ' + (this.partner.partnerDirectorLast ? this.partner.partnerDirectorLast : '') + ' ' + (this.partner.partnerDirectorFormer ? this.partner.partnerDirectorFormer : '')
    this.directorBorn = (this.partner.partnerDirectorDateOfBirth ? this.partner.partnerDirectorDateOfBirth : '') + (this.partner.PartnerDirectorNationality ? ', ' + this.partner.PartnerDirectorNationality : '')
    this.adress3 = (this.partner.partnerDirectorAdressStreet ? this.partner.partnerDirectorAdressStreet : '') + ' ' + (this.partner.partnerDirectorAdressBuildingNameNumber ? this.partner.partnerDirectorAdressBuildingNameNumber : '')
    this.adress4 = (this.partner.partnerDirectorPostcode ? this.partner.partnerDirectorPostcode : '') + ' ' + (this.partner.partnerDirectorCity ? this.partner.partnerDirectorCity : '') + (this.partner.partnerDirectorCountyRegion ? ', ' + this.partner.partnerDirectorCountyRegion : '')+ (this.partner.partnerDirectorStateResidence ? ', ' + this.partner.partnerDirectorStateResidence : '') 

    this.contact = (this.partner.partneSecretaryTitle ? this.partner.partneSecretaryTitle : '') + ' ' + (this.partner.partneSecretaryFirst ? this.partner.partneSecretaryFirst : '') + ' ' + (this.partner.partneSecretaryLast ? this.partner.partneSecretaryLast : '') + ' ' + (this.partner.partneSecretaryFormer ? this.partner.partneSecretaryFormer : '')
    this.contactBorn = (this.partner.partnerSecretaryDateOfBirth ? this.partner.partnerSecretaryDateOfBirth : '') + (this.partner.PartnerSecretaryNationality ? ', ' + this.partner.PartnerSecretaryNationality : '')
    this.adress5 = (this.partner.partnerSecretaryAdressStreet ? this.partner.partnerSecretaryAdressStreet : '') + ' ' + (this.partner.partnerSecretaryAdressBuildingNameNumber ? this.partner.partnerSecretaryAdressBuildingNameNumber : '')
    this.adress6 = (this.partner.partnerSecretaryPostcode ? this.partner.partnerSecretaryPostcode : '') + ' ' + (this.partner.partnerSecretaryCity ? this.partner.partnerSecretaryCity : '') + (this.partner.partnerSecretaryCountyRegion ? ', ' + this.partner.partnerSecretaryCountyRegion : '')+ (this.partner.partnerSecretaryStateResidence ? ', ' + this.partner.partnerSecretaryStateResidence : '')

  },
  methods: {
    async partnerlist () {
      try {
        await this.$router.push({
          name: 'partner'
        })
      } catch (err) {
        this.error = err
      }
    },
  },
  computed: {},
  watch: {},
  components: {
    appPresRow: PresRow2,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
