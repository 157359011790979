<template>
 <v-container fluid grid-list-md>
      <v-row no-gutters class="pa-0">
        <v-col cols="12" xl="6" lg="6" xs="6" sm="6" md="6">
          <h1>{{this.$store.state.documentActivLoc}}</h1>
        </v-col>
        <v-col cols="12" xl="3" lg="3" xs="3" sm="3" md="3">
            <v-btn text @click="downloadFile">
              Export u Excel
            </v-btn>
        </v-col>
        <v-col cols="12" xl="3" lg="3" xs="3" sm="3" md="3">
          <div class="text-right">
              <v-btn fab dark class="indigo" @click="newpartner">
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
          </div>
        </v-col>
      </v-row>
      <app-Partner-list></app-Partner-list>
    </v-container>
</template>

<script>
import Partnerlist from './PartnerList'
import PartnerService from '@/services/PartnerService'
import xlsx from 'json-as-xlsx'
import langEn from './PartnerDescEN'
import langHr from './PartnerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dayjs from 'dayjs'

export default {
  name: 'partner',
  data () {
    return {
      msg: '',
      privateP: true,
      lang: {},
      langC: {},
    }
  },
  mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = langEn
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = langHr
      this.langC = commHr;
    }
    this.privateP = this.$store.state.user ? this.$store.state.user.privatePartner : true
  },
  methods: {
    async newpartner () {
      try {
        if (this.privateP) {
          await this.$router.push({
            name: 'partnercreate2'
          })
        } else {
          await this.$router.push({
            name: 'partnercreate'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async downloadFile () {
      const query = {}
        query.CompanyId = this.$store.state.companyid
        query.noPageing = 0
        let expItems = []
        const privateP = this.$store.state.user ? this.$store.state.user.privatePartner : true
        const {data} = privateP ? (await PartnerService.index2(query)) : (await PartnerService.index(query))
        //const {data} = await PartnerService.index(query)
        //console.log('data', data)
        if (data && data.partners.rows !== undefined && data.partners.rows.length > 0) {
          expItems = data.partners.rows
        }

        //console.log('expItems', expItems)

        const currDate = dayjs().format('DD_MM_YYYY_HH_MM')  
        const sheetNm = 'Partneri' + '_' + currDate
        let data1 = [
        {
          sheet: sheetNm,
          columns: [
            {label: this.lang.PartnerName, value: 'partnerName'},
            {label: this.lang.PartnerVATID, value: 'partnerVATID'},
            {label: this.lang.Partneremail, value: 'partneremail'},
            {label: this.lang.PartnerPhones, value: 'partnerPhones'},
            {label: this.lang.PartnerStreet, value: 'partnerStreet'},
            {label: this.lang.PartnerBuildingNameNumber, value: 'partnerBuildingNameNumber'},
            {label: this.lang.PartnerCity, value: 'partnerCity'},
            {label: this.lang.PartnerPostcode, value: 'partnerPostcode'},
            {label: this.lang.PartnerBankIBAN, value: 'partnerBankIBAN'},
            {label: this.lang.PartnerDirectorFirst, value: 'partnerDirectorFirst'},
            {label: this.lang.PartnerDirectorLast, value: 'partnerDirectorLast'},
            {label: this.lang.PartnerDirectorEmail, value: 'partnerDirectorEmail'},
            {label: this.lang.PartnerType, value: 'partnerType'},
          ],
          content: expItems
        }
      ]
          
      let settings = {
          fileName: 'Partneri' + currDate,
          extraLength: 3, // A bigger number means that columns will be wider
          writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
        }
      // const settings = {
      //   sheetName: 'StanjeSkladista',
      //   fileName: 'StanjeSkladista_' + currDate
      // }
      xlsx(data1, settings)
    },
  },
  computed: {

  },
  watch: {

  },
  components: {
    appPartnerList: Partnerlist
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
