<template>
<div>
  <v-container fluid grid-list-md>
  <v-row>
      <appAppSettings></appAppSettings>
    </v-row>
  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="companyBusinessYears"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="20"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{lang.BusinessYears}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>


          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-row>
                     <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                      <v-autocomplete
                        :items="companies"
                        v-model="selectComp"
                        label="Select company"
                        item-text="CompanyName"
                        item-value="id"
                        return-object
                        persistent-hint
                        clearable
                      ></v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-select
                      menu-props="auto"
                      single-line
                      :label="lang.BusinessYear"
                      :items="privitems"
                      v-model="editedItem.BusinessYear"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                     <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="editedItem.BusinessYearStartDate"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="editedItem.BusinessYearStartDate"
                              :label="lang.BusinessYearStartDate"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
    
                            <v-date-picker v-model="editedItem.BusinessYearStartDate" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="menu = false">Cancel</v-btn>
                            <v-btn color="primary" @click="$refs.menu.save(editedItem.BusinessYearStartDate)">OK</v-btn>
                            </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-menu
                          ref="menu2"
                          v-model="menu2"
                          :close-on-content-click="false"
                          :return-value.sync="editedItem.BusinessYearEndDate"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="editedItem.BusinessYearEndDate"
                              :label="lang.BusinessYearEndDate"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
    
                            <v-date-picker v-model="editedItem.BusinessYearEndDate" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="menu2 = false">Cancel</v-btn>
                            <v-btn color="primary" @click="$refs.menu2.save(editedItem.BusinessYearEndDate)">OK</v-btn>
                            </v-date-picker>
                      </v-menu>
                    </v-col>
                    
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-checkbox
                        v-model="editedItem.defaultBusinessYear"
                        :label="lang.defaultBusinessYear"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-checkbox
                        v-model="editedItem.BusinessYearLocked"
                        :label="lang.BusinessYearLocked"
                      ></v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn :disabled="!valid" color="success" text @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.BusinessYearStartDate`]="{ item }">
        <span>{{ localDate(item.BusinessYearStartDate) }}</span>
    </template>
    <template v-slot:[`item.BusinessYearEndDate`]="{ item }">
        <span>{{ localDate(item.BusinessYearEndDate) }}</span>
    </template>
    <template v-slot:[`item.defaultBusinessYear`]="{ item }">
      <span v-if="item.defaultBusinessYear"><v-icon>mdi-check</v-icon></span>
    </template>
    <template v-slot:[`item.BusinessYearLocked`]="{ item }">
      <span v-if="item.BusinessYearLocked"><v-icon>mdi-check</v-icon></span>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      {{ lang.CompanyBusinessYearsEmpty }}
    </template>
  </v-data-table>
  </v-container>
  </div>
</template>

<script>
import CompBusinessYearService from '@/services/CompBusinessYearService'
import CompService from '@/services/CompService'
import langEn from './companyDescEn'
import langHr from './companyDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import AppSettings from '../AppSettings'
import dayjs from 'dayjs'

  export default {
    name: 'userCRUD',
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: "",
        databaseDocID: 0
      },
      dialog: false,
      headers: [
        
      ],
      companyBusinessYears: [],
      editedIndex: -1,
      editedItem: { },
      defaultItem: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        BusinessYearStartDate: null,
        BusinessYearEndDate: null,
        BusinessYearLocked: false,
        BusinessYearKey1: '',
        BusinessYearKey2: '',
        defaultBusinessYear: 0,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: '',
        LastUpdate: null
      },
      confpassword: '',
      privitems: [ ],
      valid: true,
      nameRules: [
        v => !!v || 'Name is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password must be 6 or more characters',
      ],
      locusers: [],
      select: { },
      locWareHouses: [],
      select2: { },
      companies:[],
      selectComp: {}
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewBusinessYears: this.lang.EditBusinessYears
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      'editedItem.BusinessYear' () {
        this.editedItem.BusinessYearStartDate = dayjs([this.editedItem.BusinessYear]).startOf('year').format('YYYY-MM-DD')
        this.editedItem.BusinessYearEndDate = dayjs([this.editedItem.BusinessYear]).endOf('year').format('YYYY-MM-DD')
        // let locDate1 = new Date()
        // let locDate2 = new Date()
        // locDate1 = dayjs().set({ 'year': this.editedItem.BusinessYear, 'month': 0, 'date': 1 })
        // locDate2 = dayjs().set({ 'year': this.editedItem.BusinessYear, 'month': 11, 'date': 31 })
        // this.editedItem.BusinessYearStartDate = dayjs(locDate1).format('YYYY-MM-DD')
        // this.editedItem.BusinessYearEndDate = dayjs(locDate2).format('YYYY-MM-DD')
      },
      'selectComp' (comp) {
        this.editedItem.CompanyId = comp.id
        this.editedItem.CompanyNameDatabase = comp.CompanyNameDatabase
        //this.choosedComp(comp.id)
      },
    },

    async mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
        this.defaultItem.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.companyName) {
        this.defaultItem.CompanyNameDatabase = this.$store.state.companyName
      }

      this.privitems.push(
        { text: '2019', value: 2019 },
        { text: '2020', value: 2020 },
        { text: '2021', value: 2021 },
        { text: '2022', value: 2022 },
        { text: '2023', value: 2023 },
        { text: '2024', value: 2024 },
        { text: '2025', value: 2025 },
        { text: '2026', value: 2026 },
        { text: '2027', value: 2027 },
        { text: '2028', value: 2028 },
        { text: '2029', value: 2029 },
        { text: '2030', value: 2030 },
        { text: '2031', value: 2031 },
        { text: '2032', value: 2032 },
        { text: '2033', value: 2033 },
        { text: '2034', value: 2034 },
        { text: '2035', value: 2035 },
        { text: '2036', value: 2036 },
        { text: '2037', value: 2037 },
        { text: '2038', value: 2038 },
        { text: '2039', value: 2039 },
        { text: '2040', value: 2040 },
        { text: '2041', value: 2041 },
        { text: '2042', value: 2042 },
        { text: '2043', value: 2043 },
        { text: '2044', value: 2044 },
        { text: '2045', value: 2044 }
      )

      this.headers.push(
        {text: this.lang.CompanyId, value: 'CompanyId', width: '50px'},
        {text: this.lang.CompanyNameDatabase, value: 'CompanyNameDatabase', width: '200px'},
        {text: this.lang.BusinessYear, value: 'BusinessYear', width: '100px'},
        {text: this.lang.BusinessYearStartDate, value: 'BusinessYearStartDate', type: 'date', width: '150px'},
        {text: this.lang.BusinessYearEndDate, value: 'BusinessYearEndDate', type: 'date', width: '150px'},
        {text: this.lang.defaultBusinessYear, value: 'defaultBusinessYear', type: 'boolean', width: '80px'},
        {text: this.lang.BusinessYearLocked, value: 'BusinessYearLocked', type: 'boolean', width: '80px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
        {text: this.lang.Actions, value: 'action', sortable: false },
      )

     this.defaultItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
     this.defaultItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
     this.companies = (await CompService.index()).data.companys
     console.log("🚀 ~ file: CompBusinessYearCRUD.vue ~ line 369 ~ mounted ~ this.companies", this.companies)

    },

    created () {
      this.initialize()
    },
    components: {
      appAppSettings: AppSettings
    },
    methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      async initialize () {
        try {
          this.saving = true
          this.companyBusinessYears = (await CompBusinessYearService.index()).data.companyBusinessYears 
          this.saving = false
          this.errorMatch = false
          this.editedItem = this.defaultItem
          if (this.$store.state.businessYear) {
            this.editedItem.BusinessYear = this.$store.state.businessYear
            let locDate1 = new Date()
            let locDate2 = new Date()
            locDate1 = dayjs().set({ 'year': this.editedItem.BusinessYear, 'month': 0, 'date': 1 })
            locDate2 = dayjs().set({ 'year': this.editedItem.BusinessYear, 'month': 11, 'date': 31 })
            this.editedItem.BusinessYearStartDate = dayjs(locDate1).format('YYYY-MM-DD')
            this.editedItem.BusinessYearEndDate = dayjs(locDate2).format('YYYY-MM-DD')
          }
          this.selectComp = {}
        } catch (err) {
          this.error = err
        }
      },
      editItem (item) {
        this.errorMatch = false
        this.editedIndex = this.companyBusinessYears.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.selectComp = item.CompanyId ? this.companies.find(company => (parseInt(company.id) === item.CompanyId )) : null
        this.dialog = true
      },

      async deleteItem (item) {
        try {
          const index = this.companyBusinessYears.indexOf(item)
          const warningMessage = item.BusinessYear
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.companyBusinessYears.splice(index, 1)) {
            await CompBusinessYearService.delete(item.id)
            this.initialize()
          }
        } catch(err) {
          this.error = err
        }
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      async save () {
        try {
          console.log('Saving ', this.editedItem, this.editedItem.CompanyId, this.editedItem.CompanyNameDatabase)
          if (this.editedItem.CompanyId && this.editedItem.CompanyNameDatabase) {
           let doneOK = false
           if (this.editedIndex > -1) {
              await CompBusinessYearService.put(this.editedItem)
              doneOK = true
            } else {
              this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              this.editedItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
              await CompBusinessYearService.post(this.editedItem)
              doneOK = true
            }
            if (doneOK) {
              this.initialize()
              this.close()
            }
          }
        } catch(err) {
          this.error = err
        }
       
      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      }
    },
  }
</script>