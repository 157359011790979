<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs12 sm12 m12 lg12 ex12>

             <MainBookCompCardHeader :type="type" :par="par"  addHeader="" :showOpen="showOpen" :short="short"/>
            <div>
            <v-row>
              <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" class="text-right">
                <v-btn @click="print()" class="mr-5">Printaj</v-btn>
              </v-col>
            </v-row>
            
            <v-layout row style="border-bottom:solid 1px; border-top:solid 1px;">

                    <v-flex xs1 class="text-left" >
                      Rb.  Vrsta
                    </v-flex>
                    <v-flex xs2 class="text-left" >
                      Dokument
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Veza
                    </v-flex>
                     <v-flex xs1 class="text-left" >
                      Datum knjiž
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Datum dok
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Rok plać.
                    </v-flex>
                    <v-flex xs2 class="text-left">
                      Opis
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                       <span v-if="type === 1">Isplate {{defCurrency}}</span>
                       <span v-if="type === 2">Računi {{defCurrency}}</span>
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      <span v-if="type === 1">Računi {{defCurrency}}</span>
                      <span v-if="type === 2">Uplate {{defCurrency}}</span>
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Saldo {{defCurrency}}
                    </v-flex>
                    
              </v-layout>

                <v-layout row v-for="item in items" :key="item.id">
                    <v-flex xs1 class="text-left" >
                      {{ item.itBookName }} {{ item.BookTypeName }}
                    </v-flex>
                  
                    <v-flex xs2 class="text-left" >
                      {{ (type === 2 && item.InvoiceNumber ?parseInt(item.InvoiceNumber) : item.InvoiceNumber)}}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ (item.docID ? parseInt(item.docID): null) }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ localDate(item.datum) }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ localDate(item.InvoiceDate) }}
                    </v-flex>
                     <v-flex xs1 class="text-left" >
                      {{ item.InvoicePaymentDeadlineDate ? localDate(item.InvoicePaymentDeadlineDate) : '' }}
                    </v-flex>
                    <v-flex xs2 class="text-left" style="font-size:12px;">
                      {{ item.desc1 }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(parseFloat(item.debit)) }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(parseFloat(item.credit)) }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(item.CreditDebit) }}
                    </v-flex>
                    
            </v-layout>
            <v-layout row style="border-top:solid 1px;">
              <v-flex xs9 class="text-xs-right">

              </v-flex>

              <v-flex xs1 class="text-right">
                {{ formatPrice(Tot1) }}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(Tot2) }}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(Tot3) }}
              </v-flex>

            </v-layout>
            </div>
      </v-flex>
    </v-layout>
    <Mainbookcompcard2print ref="prnt" />
  </v-container>
</template>

<script>

import ledgerEN from '../MainBook/mainbookDescEn'
import ledgerHR from '../MainBook/mainbookDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import MainBookCompCardHeader from "../MainBookComp/MainBookCompCardHeader"
import Mainbookcompcard2print from '../MainBookComp/MainBookCompCard2Print'
import {  dateFormat} from '@/snippets/allSnippets'
export default {
  name: 'mainbookcardinv',
  props: ['items', 'type', 'par', 'Tot1', 'Tot2', 'Tot3', 'mainbookAna'],
  data () {
    return {
      rowsPerPageItems: [50],
      modal: false, 
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      comp: {},
      error: null,
      localError: false,
      newUser: {},
      size: 'sm',
      havePath: '',
      lang: {},
      langC: {},
      saving: false,
      defCurrency: '',
      short: false,
      showOpen: false,
      card:{}
    }
  },
  components: {
    MainBookCompCardHeader,
    Mainbookcompcard2print
  },
  created() {

  },
  async mounted () {
    try {
      if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      this.defCurrency = this.$store.state.businessYear && this.$store.state.businessYear <= 2022 ? 'Kn' : 'EUR'
      console.log('Tot1', this.Tot1)
      console.log('Tot2', this.Tot2)
      console.log('Tot3', this.Tot3)
  
    } catch (err) {
      // eslint-disable-next-line
      console.log('Error ', err)
    }
    
  },
  computed: {

  },
  methods: {
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
     localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
     print () {
      const emOb = {}
      this.card = {...emOb}
      this.card.items = this.items
      this.card.par = this.par
      this.card.docFromDate = this.mainbookAna.docFromDate
      this.card.docToDate = this.mainbookAna.docToDate
      this.card.defCurrency = this.defCurrency
      this.card.type = this.type
      this.card.open = false
      this.$refs.prnt.print(this.card)
    },

  }

}
</script>