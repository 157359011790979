import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('mainbookcomp', {
      params: query
    })
  },
  indexlist(search) {
    return Api().get('mainbookcomp/list', {
      params: search
    })
  },
  choose (query) {
    return Api().get('mainbookcomp/choose', {
      params: query
    })
  },
  checkbs (query) {
    return Api().get('mainbookcomp/checkbs', {
      params: query
    })
  },
  sumbyvat (query) {
    return Api().get('mainbookcomp/sumbyvat', {
      params: query
    })
  },
  show (id) {
    return Api().get(`mainbookcomp/${id}`)
  },
  post (newmainbook) {
    return Api().post('mainbookcomp', newmainbook)
  },
  put (mainbookcomp) {
    return Api().put(`mainbookcomp/${mainbookcomp.id}`, mainbookcomp)
  },
  delete (id) {
    return Api().delete(`mainbookcomp/${id}`)
  },
  saveitems (newitems) {
    return Api().post('mainbookcomp/saveitems', newitems)
  },
}