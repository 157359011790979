const docEN = {
  Item: 'Item',
  Items: 'Items',
  ItemDetails: 'Item Details',
  ItemCreate: 'Item Create',
  SupplierData: 'Supplier Data',
  CalcPrice: 'Calculate price',
  Saved: 'Item Saved to database',
  Price: 'Price',
  PriceExp: 'Price export',
  BasicData: 'Basic data',
  AddItem: 'Add Nex Item',
  EditItem: 'Edit Item',
  GroupId: 'Group Id',
  GroupName: 'Group Name',
  ItemID: 'ID',
  ItemName: 'Name',
  ItemNameShort: 'Name Short',
  ItemCode: 'Code',
  ItemEANCode: 'EAN code',
  ItemGroup: 'Group',
  ItemDescription: 'Description',
  ItemUnit: 'Unit',
  ItemSize: 'Size',
  ItemWeight: 'Weight',
  ItemWeightMeasure: 'Weight Measure',
  ItemDimMeasure: 'Dim Measure',
  ItemDimLenght: 'Dim Length',
  ItemDimHeight: 'Dim Height',
  ItemDimDepth: 'Dim Depth',
  ItemWarehouse: 'Warehouse',
  ItemWarehouseRow: 'Warehouse Row',
  ItemWarehouseShelf: 'Warehouse Shelf',
  ItemWahrehouseFloor: 'Warehouse Floor',
  ItemPicture1: 'Picture 1',
  ItemPicture2: 'Picture 2',
  ItemPicture3: 'Picture 3',
  ItemPicture4: 'Picture 4',
  ItemPicture5: 'Picture 5',
  ItemPicture6: 'Picture 6',
  ItemPicture7: 'Picture 7',
  ItemBelongsToId: 'Belongs to ID',
  ItemService: 'Service',
  ItemWeb: 'Web',
  ItemNotActiv: 'No Activ',
  ItemHideForever: 'Hide Forever',
  ItemHomeCurrency: 'Home Currency',
  ItemForeignCurrency: 'Fore Currency',
  ItemExRate: 'Ex Rate',
  ItemSuppPrice: 'Supp Price',
  ItemSuppBasePrice: 'Supp Base Price',
  ItemSuppDiscount: 'Supp Discount',
  ItemSuppCode: 'Supp Code',
  ItemMargin: 'Margin',
  ItemMaxDiscount: 'Max Discount',
  ItemPrice: 'Price',
  ItemVAT1Percentage: 'VAT %',
  ItemVAT1Amount: 'VAT amount',
  ItemVAT2Percentage: 'VAT % second',
  ItemVAT2Amount: 'VAT amount second',
  ItemDirectDuty1: 'Direct Duty1',
  ItemDirectDuty2: 'Direct Duty2',
  ItemPriceWithVAT: 'Price with VAT',
  ItemForePrice: 'Export Price',
  ItemForeVAT1Percentage: 'VAT % export',
  ItemForeVAT1Amount: 'VAT Amount export',
  ItemForeVAT2Percentage: 'VAT % second exp',
  ItemForeVAT2Amount: 'VAT amount sec.exp',
  ItemForeDirectDuty1: 'Direct duty1 exp',
  ItemForeDirectDuty2: 'Direct duty2 exp',
  ItemForePriceWithVAT: 'Price with VAT fexp',
  ItemDiscountAmount1: 'Discount on amount 1',
  ItemDiscountPercentage1: 'Discount %',
  ItemDiscountAmount2: 'Discount on amount 2',
  ItemDiscountPercentage2: 'Discount % ',
  ItemDiscountAmount3: 'Discount on amount 3',
  ItemDiscountPercentage3: 'Discount %',
  ItemWarranty: 'Warranty months'
}

export default docEN