<template>
  <div class="mainledgerprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'

export default {
  name: 'mainledgerprint',
  props: [],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0,
      page: 1,
      pageLine: 10,
      currency: '',
      head:{}
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    
  },
  methods: {
    print (fRDG) {
      console.log('printam fRDG', fRDG) 
      this.head = {...fRDG.head}
      this.order = 0
      this.page = 1
      this.pageLine = 10
      this.currency = 'u EUR' 
      const tempCurry = this.currency
      this.currency = this.$store.state.businessYear >= 2023 ? 'EUR' : tempCurry
      this.initDocument()
      this.addHeaderNoLogo()
      this.addLine()
      this.addItemsHeader()
      this.addLine()
      this.addItems(fRDG)
      this.addLine()
      this.addLastLine()
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()    
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'landscape'
      this.docDefinition.pageMargins = [ 30, 30, 30, 30 ]
      this.content = []
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    skipNewPage(lines) {
      this.pageLine += lines
      if (this.pageLine > 44) {
        this.pageLine = 10
        this.page += 1
        this.addLine()
        const docPart01 = {
        columns: [
            {
              width: '40%',
              fontSize: 7,
              bold: false,
              alignment: 'left',
              text: 'www.roboot.net',
              pageBreak: 'after'
            },
          ]
        }
        this.content.push(docPart01)
        this.addHeaderNoLogo()
        this.addLine()
        this.addItemsHeader()
        this.addLine()
      }
    },
    addLastLine() {
      const docPart01 = {
        columns: [
            {
              width: '40%',
              fontSize: 7,
              bold: false,
              alignment: 'left',
              text: 'www.roboot.net',
            },
          ]
        }
        this.content.push(docPart01)
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 800,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addLineDash () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 800,
                y2: 0,
                lineWidth: 1,
                dash: { length: 1 },
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '35%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text:  'RAČUN DOBITKA I GUBITKA'  + '\n', fontSize: 18, bold: true },
                  { text: ' za razdoblje od: ' + dayjs(this.head.docFromDate).format('DD.MM.YYYY') + ', do ' + dayjs(this.head.docToDate).format('DD.MM.YYYY') + ', ' + this.currency, fontSize:10, bold: true}
                ]   
              },
              {
                // % width
                width: '20%',
                text: [                 
                  { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a') + '\n', fontSize:8},
                  { text: ' Stranica: ' + parseInt(this.page) + '\n', fontSize:8},
                  { text: (this.head.email ? 'Ispisao: ' + this.head.email : '') + '\n', fontSize:8},
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addItemsHeader () {
        const docPart = {
        columns: [
              {
                width: '5%',
                fontSize: 8,
                alignment: 'left',
                text: 'Ozn.poz.'
              },
              {
                width: '20%',
                fontSize: 8,
                alignment: 'left',
                text: 'Račun (skupina) iz RRiF-ovog rač. plana'
              },
              {
                width: '48%',
                fontSize: 8,
                alignment: 'left',
                text: 'NAZIV POZICIJE'
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'left',
                text: 'Tek.br.bilj'
              },
               {
                width: '12%',
                fontSize: 8,
                alignment: 'right',
                text: 'Prethodna godina'
              },
              {
                width: '12%',
                fontSize: 8,
                alignment: 'right',
                text: 'Tekuća godina'
              },

            ]
          }
        this.content.push(docPart)        
    },
    addItems (fRDG) {
           this.order = 0 
                  
            if (fRDG.items && fRDG.items.length > 0) {
              fRDG.items.map(item => {
                  this.order += 1
                      const docPart01 = {
                      columns: [
                                {
                                width: '5%',
                                fontSize: (item.header ? 12: 8),
                                bold: (item.bold ? true : false),
                                alignment: (item.header ? 'left' : 'center'),
                                text: item.col1
                              },
                              {
                                width: '20%',
                                fontSize: 7,
                                alignment: 'left',
                                text: item.col2
                              },
                              {
                                width: '48%',
                                fontSize: (item.header ? 12: 8),
                                bold: (item.bold ? true : false),
                                alignment: 'left',
                                text: item.col3,
                                preserveLeadingSpaces: true
                              },
                              {
                                width: '5%',
                                fontSize: 8,
                                alignment: 'left',
                                text: item.col4
                              },
                              {
                                width: '12%',
                                fontSize: (item.header ? 12: 8),
                                bold: (item.bold ? true : false),
                                alignment: 'right',
                                text: this.formatPrice(item.col5)
                              },
                              {
                                width: '12%',
                                fontSize: (item.header ? 12: 8),
                                bold: (item.bold ? true : false),
                                alignment: 'right',
                                text: this.formatPrice(item.col6)
                              }
                          ]
                        }
                      this.content.push(docPart01)
                      this.skipNewPage(1)
                    
                }
              )
          }
    },

  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>