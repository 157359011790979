const docEN = {
  TaskType: 'Task Type',
  TaskTypes: 'Task Types',
  AddTaskType: 'Add New Task Type',
  TaskTypeDesc: 'Task Type Description',
  TaskTypeEmpty: 'Task Types are Empty',
  Actions: 'Actions',
  NewTaskType: 'New Task Type',
  EditTaskType: 'Edit Task Type',
  TaskDepartment: 'Task Department',
  TaskDepartments: 'Task Departments',
  AddTaskDepartment: 'Add New Task Department',
  TaskDepartmentDesc: 'Task Department Description',
  TaskDepartmentEmpty: 'Task Departments are Empty',
  NewTaskDepartment: 'New Task Department',
  EditTaskDepartment: 'Edit Task Department',
  partnerName: 'Partner Name',
  taskTypeDescription: 'Task Type Description',
  taskDepartmentDescription: 'Task Department Description',
  taskShortDescription: 'Task Short Description',
  taskOpenDate: 'Task Open Date',
  taskForDate: 'Task For Date',
  taskAlarmDate: 'Task Alarm Date',
  Tasks: 'Tasks',
  NewTask: 'New Task',
  TaskDate: 'Task Date',
  OpenDate: 'Open Date',
  ForDate: 'Task For Date',
  ForTime: 'Task For Time',
  FromTime: 'From:',
  ToTime: 'To:',
  ShortDesc: 'Short Description',
  DetDesc: 'Detail Description',
  TaskPartner: 'Task for Partner',
  TaskForUser: 'Task For User',
  TaskInfoSend: 'Send info to',
  TaskInfoSend2: 'Send info to',
  TaskEmp: 'Task for Employee',
  TaskPublic: 'Task is Public',
  TaskClosed: 'Task is Closed',
  TaskDone: 'Task is Done',
  ChargeHours: 'Charge Hours',
  SpentHours: 'Spent Hours',
  TaskHaveProblem: 'Task Have Problem',
  TaskProblem: 'Problem',
  TaskToDoList: 'Put on todo',
  ShowDetails: 'Show Details',
  HideDetails: 'Hide Details'

}

export default docEN