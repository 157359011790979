const docHR = {
  Serials: 'Serijski brojevi',
  Serial: 'Serijski broj',
  documentName: 'Dokument',
  documentSide: 'Dokument Strana',
  documentId: 'Dokument broj',
  documentIdGeneral: 'Dokument General',
  documentDate: 'Dokument Datum',
  documentTime: 'Vrijeme',
  partnerId: 'Partner',
  partnerNameDatabase: 'Partner Naziv',
  HomeCurrency: 'Dom.Valuta',
  ForeignCurrency: 'Strana.Valuta',
  ExRate: '',
  itemID: 'Šifra',
  itemName: 'Naziv',
  ItemNameShort: 'Naziv skraćen',
  ItemCode: 'EAN kod',
  ItemEANCode: '',
  itemGroup: 'Grupa',
  itemGroupName: 'Grupa naziv',
  itemDescription: 'Serijski broj',
  itemUnit: 'Mjera',
  ItemWarehouse: 'Skladište',
  StartDate : 'Datum početka',
  StartTime : '',
  EndDate : 'Datum završetka',
  EndTime : '',
  WareHouseUpdateBy : 'Upisao',
  FreeF1: 'Gar.Mjeseci',
  SelectPartner: 'Odaberi partnera',
  showSerials: 'Prikazi serijske',
  Stickers: 'Naljepnice',
  Sticker: 'Naljepnica',
  ItemSelect: 'Odaberi',
  ItemName: 'Naziv',
  ItemUnit: 'Jed.mj.',
  ItemPrice: 'Cijena',
  ItemPriceWithVAT: 'Cijena sa PDVom',
  emptyLines:'Praznih redova',
  printCashDiscount: 'Popust za gotovinu'
}

export default docHR