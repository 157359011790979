<template>
  <v-container class="py-10">
    <v-row>
      <v-flex xs12 sm12 m12 lg12 ex12>

            <v-row>
              <span class="headline">Za knjiženje</span>
            </v-row>
            <v-row style="border-bottom:solid 1px; border-top:solid 1px; py-3">
                    <v-flex xs1 class="text-left" >
                     Rb.  Vrsta
                    </v-flex>
                    <v-flex xs1 class="text-left">
                      VEZA
                    </v-flex>
                    <v-flex xs1 class="text-left">
                      Orig.račun
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Datum knjiženja
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Datum dokumenta
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Datum dospijeća
                    </v-flex>
                    <v-flex xs2 class="text-left">
                      Opis
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Konto
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Duguje
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Potražuje
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Saldo
                    </v-flex>
              </v-row>
                <v-row v-for="item in ppItems" :key="item.newKey">
                    <v-flex xs1 class="text-left" >
                      {{ item.itBookName }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ item.itInvoice ? item.itInvoice : ''}}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ item.InvoiceNumber }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ item.BookingDate ? localDate(item.BookingDate) : '' }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ item.InvoiceDate ? localDate(item.InvoiceDate) : '' }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ item.InvoicePaymentDeadlineDate ? localDate(item.InvoicePaymentDeadlineDate) : ''}}
                    </v-flex>
                    <v-flex xs2 class="text-left">
                      {{ item.Description }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ item.account4 }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(parseFloat(item.Debit)) }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(parseFloat(item.Credit)) }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(parseFloat(item.CreditDebit)) }}
                    </v-flex>
                    
            </v-row>

            <v-row style="border-bottom:solid 1px; border-top:solid 1px; py-3">
              <v-flex xs6 class="text-xs-right">

              </v-flex>
              <v-flex xs3 class="text-left" >
                {{ opis }}
              </v-flex>

              <v-flex xs1 class="text-right">
                {{ formatPrice(fullDebit) }}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(fullCredit) }}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(fullDebit - fullCredit) }}
              </v-flex>

            </v-row>
       
      </v-flex>
    </v-row>

  </v-container>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import {parseNum, round} from '@/snippets/allSnippets'
import dayjs from 'dayjs'

export default {
  name: 'mainledgercard3selected',
  props: ['ssitems', 'opis', 'fullDebit', 'fullCredit'],
  data () {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      comp: {},
      error: null,
      localError: false,
      newUser: {},
      size: 'sm',
      havePath: '',
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      ppItems: [],
      mainQuery2: {},
      partner: {},
      partners: [],
      card: {},
      mainQuery: {}
    }
  },
  components: {

  },
  async mounted () {

    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    this.PrintDate = dayjs(new Date()).format('llll')
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    //this.partners = (await PartnerService.index()).data.partners
    
    this.Total1 = 0
    this.Total2 = 0
    this.Total3 = 0
    this.Total4 = 0
    let itLine = 0


    if (this.ssitems) {
      
      this.ssitems.map(item => {
        itLine += 1
        this.Total1 += round(parseNum(item.Debit), 2)
        this.Total2 += round(parseNum(item.Credit), 2)
        //this.Total3 += parseFloat(item.DebitForegin)
        //this.Total4 += parseFloat(item.CreditForegin)

        const itInvoice = item.invoiceInNumber && parseInt(item.invoiceInNumber) > 0 ? parseInt(item.invoiceInNumber) : (item.invoiceOutNumber ? parseInt(item.invoiceOutNumber) : '')
        const bookName = item.BookName !== 'BS' ? item.BookName : 'Izv.' + parseInt(item.BookID)
        const CreditDebit = round(this.Total1 - this.Total2, 2)
        const object2 = {
          itBookName: itLine + '. ' + bookName,
          CreditDebit: CreditDebit,
          itInvoice: itInvoice
        }
        const object3 = {...item, ...object2}
        this.ppItems.push(object3)
      })
    }
    
    this.Total5 = round(this.Total1 - this.Total2, 2)
    // this.partner = partnerVATID ? this.partners.find(partner => (partner.partnerVATID === partnerVATID)) : {}
    // this.card = {...this.head}
    // this.card.partner = this.partner
    // this.card.items = this.pItems
    // //this.card = {...this.card, ...this.lastOne}
    // this.card.Total1 = this.Total1
    // this.card.Total2 = this.Total2
    // this.card.Total5 = this.Total5
  },
  computed: {

  },
  methods: {
    print(){
      this.$refs.prnt.print()
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    close () {
      this.show = false
    }
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
