<template>
  <div class="task">
    <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
      <v-card>
        <v-card-title>
          {{ documentNameLoc }}
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"
          >

          <template v-slot:[`item.ClosedAccount`]="{ item }">
            <span v-if="item.ClosedAccount"><v-icon>mdi-check</v-icon></span>
          </template>

          <template v-slot:[`item.BookingDate`]="{ item }">
              <span>{{ localDate(item.BookingDate) }}</span>
          </template>
          <template v-slot:[`item.InvoiceDate`]="{ item }">
              <span>{{ localDate(item.InvoiceDate) }}</span>
          </template>
          <template v-slot:[`item.paidClosed`]="{ item }">
              <span><v-icon >{{item.paidClosed ? 'mdi-check' : ''}} </v-icon></span>
          </template>
          <template v-slot:[`item.InvoicePaymentDeadlineDate`]="{ item }">
            <v-chip :color="getColor(item.InvoicePaymentDeadlineDate)">{{ localDate(item.InvoicePaymentDeadlineDate) }}</v-chip>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
          <template slot="body.append">
                <tr v-if="this.$store.state.mainledgerStatus === 1">
                    <th></th> 
                    <th></th>
                    <th></th> 
                    <th></th>
                    <th></th>
                    <th>Ukupno:</th>
                    <th class="text-right">{{ formatPrice(tot1) }}</th>
                    <th class="text-right">{{ formatPrice(tot2) }}</th> 
                    <th class="text-right">{{ formatPrice(tot3) }}</th>
                </tr>
                <tr v-if="this.$store.state.mainledgerStatus === 2">
                    <th></th>
                    <th></th>
                    <th>Ukupno:</th>
                    <th class="text-right">{{ formatPrice(tot1) }}</th>
                    <th class="text-right">{{ formatPrice(tot2) }}</th> 
                    <th class="text-right">{{ formatPrice(tot3) }}</th>
                </tr>
          </template>
        </v-data-table>
      </v-card>
  </div>
</template>

<script>
// import axios from 'axios'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import dayjs from 'dayjs'

export default {
  name: 'MainLedgerlist',
  props: ['items'],
  data () {
    return {
      documentName: '',
      documentNameLoc: '',
      docside: 0,
      msg: '',
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        MLType: 1
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
      tot1: 0,
      tot2: 0,
      tot3: 0
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    
    if (this.$store.state.mainledgerStatus === 1) {
      this.documentName = 'Book IN'
      this.documentNameLoc = 'URE'
      this.headers.push(
        {
          text: this.lang.BookID, align: 'left', sortable: true, value: 'BookID', width: '60px'
        },
        { text: this.lang.BookTypeID, align: 'left', sortable: true, value: 'BookName', width: '50px' },
        { text: this.lang.BookingDate, align: 'left', sortable: true, value: 'BookingDate', width: '80px' },
        { text: this.lang.InvoiceNumber, align: 'center', value: 'InvoiceNumber', width: '100px' },
        { text: this.lang.InvoiceDate, align: 'left', sortable: true, value: 'InvoiceDate', width: '80px' },
        { text: this.lang.InvoicePaymentDeadlineDate, align: 'left', sortable: true, value: 'InvoicePaymentDeadlineDate', width: '80px' },
        { text: this.lang.InvoiceAmount, align: 'right', sortable: true, value: 'InvoiceAmount', width: '100px' },
        { text: this.lang.PaidHome, align: 'right', sortable: true, value: 'paidHome', width: '100px' },
        { text: this.lang.HomeCurrency, align: 'left', sortable: true, value: 'HomeCurrency', width: '40px' },
        { text: this.lang.PaidDate, align: 'left', sortable: true, value: 'paidDate', width: '80px' },
        { text: this.lang.PaidClosed, align: 'left', sortable: true, value: 'paidClosed', width: '50px' },
        { text: this.lang.PartnerName, align: 'left', value: 'partnerName', width: '260px' },
        { text: this.lang.PartnerVATID, align: 'left', value: 'partnerVATID', width: '80px' },
        { text: this.lang.InvoiceForeginAmount, align: 'right', sortable: true, value: 'InvoiceForeginAmount', width: '100px' },
        { text: this.lang.ForeignCurrency, align: 'left', sortable: true, value: 'ForeignCurrency', width: '40px' },
        { text: 'DbId', value: 'id', width: '60px' }
      )
    }
    if (this.$store.state.mainledgerStatus === 2) {
      this.documentName = 'Book OUT'
      this.documentNameLoc = 'IRE'
      this.headers.push(
        { text: this.lang.InvoiceNumber, align: 'center', value: 'InvoiceNumber', width: '100px' },
        { text: this.lang.InvoiceDate, align: 'left', sortable: true, value: 'InvoiceDate', width: '80px' },
        { text: this.lang.InvoicePaymentDeadlineDate, align: 'left', sortable: true, value: 'InvoicePaymentDeadlineDate', width: '80px' },
        { text: this.lang.InvoiceAmount, align: 'right', sortable: true, value: 'InvoiceAmount', width: '100px' },
        { text: this.lang.PaidHome, align: 'right', sortable: true, value: 'paidHome', width: '100px' },
        { text: this.lang.HomeCurrency, align: 'left', sortable: true, value: 'HomeCurrency', width: '40px' },
        { text: this.lang.PaidDate, align: 'left', sortable: true, value: 'paidDate', width: '80px' },
        { text: this.lang.PaidClosed, align: 'left', sortable: true, value: 'paidClosed', width: '50px' },
        {
          text: this.lang.BookID, align: 'left', sortable: true, value: 'BookID', width: '60px'
        },
        { text: this.lang.PartnerName, align: 'left', value: 'partnerName', width: '260px' },
        { text: this.lang.PartnerVATID, align: 'left', value: 'partnerVATID', width: '80px' },
        { text: 'DbId', value: 'id', width: '60px' }
      )
    }
    if (this.items) {
      this.items.map(item => {
        this.tot1 += parseFloat(item.InvoiceAmount ? item.InvoiceAmount : 0)
        this.tot2 += parseFloat(item.paidHome ? item.paidHome : 0)
      })
    }
    this.tot3 = this.tot1 - this.tot2
  },
  methods: {
    navigateTo (params) {
      this.$store.dispatch('setMainLedgerActiv', {})
      this.$store.dispatch('setMainLedgerItems', [])
      this.$store.dispatch('setMainLedgerItem', params)
      this.$store.dispatch('setMainLedgerid', params.id)
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      },
    getColor (deadDate) {     
      const limitDate = dayjs(deadDate)
      const currDate = dayjs()
      let colorr = 'green accent-4'
      if (limitDate <= currDate) { 
        colorr = 'red lighten-3'
      }
      return colorr
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
  },
  computed: {
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
