import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('vehicle', {
      params: query 
    })
  },
  choose (query) {
    return Api().get('vehicle/choose', {
      params: query 
    })
  },
  show (vehicleId) {
    return Api().get(`vehicle/${vehicleId}`)
  },
  post (newvehicle) {
    return Api().post('vehicle', newvehicle)
  },
  saveitems(items) {
    if (items) {
      return Api().post('vehicle/saveitems', items)
    }
    return
  },
  put (vehicle) {
    return Api().put(`vehicle/${vehicle.id}`, vehicle)
  },
  delete (vehicleId) {
    return Api().delete(`vehicle/${vehicleId}`)
  }
}