import { render, staticRenderFns } from "./CompList.vue?vue&type=template&id=b29335f8&scoped=true"
import script from "./CompList.vue?vue&type=script&lang=js"
export * from "./CompList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b29335f8",
  null
  
)

export default component.exports