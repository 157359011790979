<template>
  <v-container fluid>
    <v-row>
     <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      <h1>{{haveFormHead}}</h1>
     </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row v-if="formType == 1">
     <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
      {{lang.PPO_12}}= {{localMoney(forms.FreeTotal2)}}
     </v-col>
     <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
      {{lang.PPO_13}}= {{localMoney(forms.FreeTotal3)}}
     </v-col>
     <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
      {{lang.PPO_14}}= {{localMoney(forms.FreeTotal4)}}
     </v-col>
     <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
      {{lang.PPO_15}}= {{localMoney(forms.FreeTotal1)}}
     </v-col>
    </v-row>
    <v-row v-if="formType == 2">
     <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
      {{lang.PDVS_11}}= {{localMoney(forms.FreeTotal1)}}
     </v-col>
     <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
      {{lang.PDVS_12}}= {{localMoney(forms.FreeTotal2)}}
     </v-col>
    </v-row>
    <v-row v-if="formType == 3">
     <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
      {{lang.ZP_11}}= {{localMoney(forms.FreeTotal1)}}
     </v-col>
     <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
      {{lang.ZP_12}}= {{localMoney(forms.FreeTotal2)}}
     </v-col>
     <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
      {{lang.ZP_13}}= {{localMoney(forms.FreeTotal3)}}
     </v-col>
     <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
      {{lang.ZP_14}}= {{localMoney(forms.FreeTotal4)}}
     </v-col>
    </v-row>
  </v-container>
</template>


<script>

import langEn from './formDescEn'
import langHR from './formDescHR'
import commEn from '@/language/commonEN'
import commHR from '@/language/commonHR'
import {moneyFormat} from '@/snippets/allSnippets'

export default {
  name: 'formtotalPPO',
  props: ['forms', 'formType'],
  data: ()=> ({
    lang: {},
    langC: {},
    haveForm: '',
    haveFormHead: '',
  }),
  mounted () {
    if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHR
        this.langC = commHR
      }

      if (this.formType === 1) {
        this.haveForm = this.lang.FormPPO
        this.haveFormHead = this.lang.FormPPODetail
      }
      if (this.formType === 2) {
        this.haveForm = this.lang.FormPDVS
        this.haveFormHead = this.lang.FormPDVSDetail
      }
      if (this.formType === 3) {
        this.haveForm = this.lang.FormZP
        this.haveFormHead = this.lang.FormZPDetail
      }

  },
  methods: {
    localMoney (money) {
      return moneyFormat(money)
    }
  },
  computed: {
  },
  watch: {

  },
  components: {

  }

}

</script>