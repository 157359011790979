import Api from '@/services/Api'
// import { log } from 'util';

export default {
  index (search) {
    return Api().get('companybusinessyear', {
      params: {
        search: search
      }
    })
  },
  show (companybusinessyearid) {
    return Api().get(`companybusinessyear/${companybusinessyearid}`)
  },
  post (newcompany) {
    return Api().post('companybusinessyear', newcompany)
  },
  put (company) {
    return Api().put(`companybusinessyear/${company.id}`, company)
  },
  delete (companybusinessyearid) {
    return Api().delete(`companybusinessyear/${companybusinessyearid}`)
  },
  choose (query) {
    return Api().get('companybusinessyear/choose', {
      params: query
    })
  }
}
