<template>
  <v-row no-gutters>
    <v-col xs="12" sm="12" md="12" lg="12" ex="12">
      <v-card>
        <v-card-title>
          <v-toolbar color="red" dark> 
            {{lang.errorSuppAvgPrice}}
          </v-toolbar>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="errorItems"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="10"
            item-key="id"
            @click:row="navigateTo"
          >
          <template v-slot:[`item.pscHome`]="{ item }">
              <span>{{ localMoney(item.pscHome) }}</span>
          </template>
          <template v-slot:[`item.pscExport`]="{ item }">
              <span>{{ localMoney(item.pscExport) }}</span>
          </template>
          <template v-slot:[`item.itemSuppBasePriceAmountHome`]="{ item }">
              <span>{{ localMoney(item.itemSuppBasePriceAmountHome) }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>

    <v-row v-if="showCard">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <DocumentItemCard :key="refreshCard"/>
      </v-col>
    </v-row>
   </v-col>
  </v-row>  
</template>

<script>
import DocumentItemCard from './DocumentItemCard'
import moneyFormat from '@/snippets/moneyFormat'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'

export default {
  name: 'documentsaleserrorlist',
  props: ['errorItems'],
  data () {
    return {
      lang: {},
      langC: {},
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: '',
        databaseDocID: 0,
        FreeF1: 1
      },
      showCard: false,
      refreshCard: 0,
    }
  },
  mounted () {
    this.comp = this.$store.state.companyActiv
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    if (this.$store.state.businessYear) {
      this.mainQuery.businessYear = this.$store.state.businessYear
    }
    if (this.$store.state.documentActiv) {
      this.mainQuery.documentName = this.$store.state.documentActiv
    }
    if (this.$store.state.databaseDocID) {
      this.mainQuery.databaseDocID = this.$store.state.databaseDocID
    }
    this.mainQuery.FreeF1 = 1

    this.headers.push(
      {text: this.lang.itemID, value: 'itemID', width: '90px'},
      {text: this.lang.itemName, value: 'itemName', width: '250px'},
      {text: this.lang.itemUnit, value: 'itemUnit', width: '250px'},
      {text: this.lang.PcsHome, value: 'pscHome', type: 'decimal', width: '100px'},
      {text: this.lang.PcsExport, value: 'pscExport', type: 'decimal', width: '100px'},
      {text: this.lang.SuppAmountHome, value: 'itemSuppBasePriceAmountHome', type: 'decimal', width: '100px'},
      {text: this.lang.NetoSalesAmountHome, value: 'itemBaseAmountNoVATHome', width: '90px'},
      {text: this.lang.SuppAmountExport, value: 'itemSuppBasePriceAmountExport', width: '90px'},
      {text: this.lang.NetoSalesAmountExport, value: 'itemBaseAmountNoVATExport', width: '250px'},
      {text: 'Dobavna baza artikala ', value: 'suppPrice', width: '100px'},
      {text: this.lang.suppPrice, value: 'suppPriceAvg', width: '100px'},
      {text: this.lang.id, value: 'id', width: '50px'}
    )
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    navigateTo (item) {

      this.refreshCard += 1
      if (confirm('Zelite li karticu artikla: ' + item.itemName + ' ?')) {
        this.$store.dispatch('setSelectedItemId', null)
        this.$store.dispatch('setDocumentAna', null)
        this.showCard = false
        this.$store.dispatch('setSelectedItemId', item.itemID)
        const haveItem = {}
        if (this.$store.state.companyid) {
          haveItem.CompanyId = this.$store.state.companyid
        }
        if (this.$store.state.businessYear) {
          haveItem.BusinessYear = this.$store.state.businessYear
        }
        if (item.itemID) {
          haveItem.itemID = item.itemID
        }
        haveItem.docFromDate = dayjs([haveItem.BusinessYear]).startOf('year').format('YYYY-MM-DD')
        haveItem.docToDate = dayjs([haveItem.BusinessYear]).endOf('year').format('YYYY-MM-DD')
        this.$store.dispatch('setDocumentAna', haveItem)
        this.showCard = true
      } else {
        this.$store.dispatch('setSelectedItemId', null)
        this.$store.dispatch('setDocumentAna', null)
        this.showCard = false
      }
    },

  },
  computed: {

  },
  watch: {

  },
  components: {
    DocumentItemCard
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
