import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('documentstatus', {
      params: query
    })
  },
  choose (query) {
    return Api().get('documentstatus/choose', {
      params: query
    })
  },
  show (id) {
    return Api().get(`documentstatus/${id}`)
  },
  post (newstatus) {
    return Api().post('documentstatus', newstatus)
  },
  put (status) {
    return Api().put(`documentstatus/${status.id}`, status)
  },
  delete (id) {
    return Api().delete(`documentstatus/${id}`)
  },
}