const docEN = {
  CardTypes: 'Card Types',
  CardType: 'Card Type',
  CardName: 'Card Name',
  DescHR: '',
  DescEN: '',
  Side: 'Side',
  NewCardType: 'New card type',
  EditCardType: 'Edit card type',
  EmptyCardType: 'Nema card types',
  Discount: 'Discount'
}

export default docEN