<template>
  <div>
    <v-card>
        <v-card-title>
          <v-toolbar> 
          {{documentNameLoc}} {{bookTypeSelected.text}} - {{lang.Items}}
            <v-spacer></v-spacer> 

            <v-btn color="green" text @click="haveAlert(1)" class="mr-1" v-if="haveSide === 1 || haveSide === 2">
              {{lang.CreateDetails1}}
            </v-btn>
            <v-btn color="green" text @click="doAsPrevious()" class="mr-1" v-if="haveSide === 1 || haveSide === 2">
              {{lang.CreateDetails2}}
            </v-btn>

            <v-btn class="mx-2" fab dark color="indigo" @click='newMaterial'>
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
            
            <v-alert type="warning" v-model="alert" dismissible dense>
              Upppsss! Still testing...
            </v-alert>
          </v-toolbar>
        </v-card-title>
        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="20"
            item-key="id"
            @click:row="navigateTo"
            show-select
          >

          <template v-slot:[`item.account4`]="{ item }"> 
            <span class="title purple--text">{{ item.account4 }}</span>
          </template>
          <template v-slot:[`item.Debit`]="{ item }">
            <span class="title indigo--text">{{ formatPrice(item.Debit) }}</span>
          </template>
          <template v-slot:[`item.Credit`]="{ item }">
            <span class="title deep-orange--text">{{ formatPrice(item.Credit) }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>

      <v-dialog
          v-model="showAlert"
          max-width="290"
        >
          <v-card>
            <v-card-title class="headline">
             {{lang.MainLedgerQues1}}
            </v-card-title>

            <v-card-text>
              {{lang.MainLedgerQues2}}
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="showAlert = false"
              >
                {{langC.Cancel}}
              </v-btn>

              <v-btn
                color="blue"
                text
                @click="haveAlert2()"
              >
                {{langC.Continue}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-row>
          <v-col cols="12" xs="6" sm="10" md="10" lg="10" xl="10" class="pr-3">
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2" class="pr-3">
            <v-btn
                color="red darken-1"
                text small
                @click="DeleteSelected"
              >
                {{langC.DeleteSelected}}
              </v-btn>
          </v-col>
        </v-row>
    </div>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import { defMainLedgerDetail, defMainLedgerDetailFill, findPartnerVATID } from './mainledgerHelper'
import {parseNum, round} from '@/snippets/allSnippets'
import MainLedgerDetailService from '@/services/MainLedgerDetailService'

export default {
  name: 'mainledgerdetaillist',
  data () {
    return {
      lang: {},
      langC: {},
      documentName: '',
      documentNameLoc: '',
      documentSide: 0,
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: '',
        databaseDocID: 0,
        FreeF1: 1
      },
      defaultItem: {},
      newItem: {},
      haveSide: 0,
      activItems: [],
      account4s: [],
      bookTypeSelected: {
      },
      alert: false,
      firstNormal: true,
      lastNormal: true,
      InAllaccount4: null,
      InVATaccount4: null,
      InBaseaccount4: null,
      OutAllaccount4: null,
      OutVATaccount4: null,
      OutBaseaccount4: null,
      BookTypeID: null,
      haveDiff: false,
      showAlert: false,
      type: null,
      DebitTotal: 0,
      CreditTotal: 0,
      DiffTotal: 0,
      selected: [],
      firma: true
    }
  },
  async mounted () {
    try {

      this.BookTypeID = this.$store.state.mainledgerActiv.BookTypeID
      if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentActiv) {
        this.mainQuery.documentName = this.$store.state.documentActiv
      }
      if (this.$store.state.databaseDocID) {
        this.mainQuery.databaseDocID = this.$store.state.databaseDocID
      }
      this.mainQuery.FreeF1 = 1
      this.items = this.$store.state.mainledgerItems ? await this.$store.state.mainledgerItems : []
      
      this.headers.push(
        {
          text: this.lang.Account4,
          align: 'left',
          sortable: true,
          value: 'account4',
          width: '80px'
        },
        {
          text: this.lang.Debit,
          align: 'right',
          sortable: true,
          value: 'Debit',
          width: '120px'
        },
        {
          text: this.lang.Credit,
          align: 'right',
          sortable: true,
          value: 'Credit',
          width: '120px'
        },
        {
          text: this.lang.Description,
          align: 'left',
          sortable: true,
          value: 'Description',
          width: '250px'
        },
        {text: this.lang.VATPer, align: 'right', sortable: true, value: 'VATPer', width: '60px' },
        {text: this.lang.invoiceInNumber, align: 'right', sortable: true, value: 'invoiceInNumber', width: '120px' },
        {text: this.lang.invoiceOutNumber, align: 'right', sortable: true, value: 'invoiceOutNumber', width: '120px' },
        {text: 'Partner', align: 'left', value: 'partnerName', width: '260px' },
        {text: this.lang.Account4Description, value: 'account4Description', align: 'left', width: '550px' },
        {text: this.lang.Actions, value: 'action', sortable: false },
        { text: this.lang.id, value: 'id', width: '50px' }
      )
      if (this.$store.state.mainledgerActiv.InvoiceAmount) {
          if (this.$store.state.documentSide === 1) {
            this.haveSide = 1
            this.bookTypeSelected = this.$store.state.bookTypeSelected ? this.$store.state.bookTypeSelected : {}
          }
          if (this.$store.state.documentSide === 2) {
            this.haveSide = 2
            this.bookTypeSelected = this.$store.state.bookTypeSelected ? this.$store.state.bookTypeSelected : {}
          }
       }

       console.log('Company Activ ', this.$store.state.companyActiv.showMainBook )
       this.firma = !this.$store.state.companyActiv.showMainBook

       this.account4s = this.$store.state.account4s ? this.$store.state.account4s : []       
       this.documentSide = this.$store.state.documentSide ? this.$store.state.documentSide === 1 : 0

       this.InAllaccount4 = this.$store.state.companyActiv.InAllaccount4 ? this.$store.state.companyActiv.InAllaccount4 : (this.firma ? '2200' : '2425')
       this.InVATaccount4 = this.$store.state.companyActiv.InVATaccount4 ? this.$store.state.companyActiv.InVATaccount4 : (this.firma ? '140012' : '12425')
       this.InBaseaccount4 = this.$store.state.companyActiv.InBaseaccount4 ? this.$store.state.companyActiv.InBaseaccount4 : (this.firma ? '4000' : '4259')

       this.OutAllaccount4 = this.$store.state.companyActiv.OutAllaccount4 ? this.$store.state.companyActiv.OutAllaccount4 : (this.firma ? '1200' : '1611')
       this.OutVATaccount4 = this.$store.state.companyActiv.OutVATaccount4 ? this.$store.state.companyActiv.OutVATaccount4 : (this.firma ? '240012' : '24925')
       this.OutBaseaccount4 = this.$store.state.companyActiv.OutBaseaccount4 ? this.$store.state.companyActiv.OutBaseaccount4 : (this.firma ? '7500' : '3421')

    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  },
  methods: {
    DeleteSelected() {
      if (confirm('Da li ste sigurni da želite obrisati označene stavke!')) {
        if (confirm('Da li ste STVARNO sigurni da želite obrisati označene stavke!')) {
          this.items = this.items.filter(item => !this.selected.includes(item))
          this.$store.dispatch('setMainLedgerItems', this.items)
        }
      }
    },
    formatPrice (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
    navigateTo (item) {
      
      this.$store.dispatch('seteditItem', 1)
      this.$store.dispatch('setMainLedgerDetailid', item.id)
      this.$router.push({
        name: 'mainledgerdetailcreate'
      })
    },
    newMaterial () {
      this.$store.dispatch('seteditItem', 0)
      this.$store.dispatch('setMainLedgerDetailid', null)
      this.$router.push({
        name: 'mainledgerdetailcreate'
      })
    },
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
    haveAlert(type) {
      // if is book in or book out
      this.showAlert = false
      this.type = type
      const mustBe = round(parseNum(this.$store.state.mainledgerActiv.InvoiceAmount),2)
      if (this.$store.state.mainledgerActiv.BookTypeID <= 10) {
        const debitTotal = this.$store.state.mainledgerActiv.DebitTotal ? round(parseNum(this.$store.state.mainledgerActiv.DebitTotal),2) : 0
        const creditTotal = this.$store.state.mainledgerActiv.CreditTotal ? round(parseNum(this.$store.state.mainledgerActiv.CreditTotal),2) : 0
        if (debitTotal !==0 && creditTotal !== 0) {
          if (mustBe <= debitTotal || mustBe <= creditTotal) {
            this.showAlert = true
          }
        }
      }
      if (!this.showAlert) {
        if (this.type === 1) {
          this.type = 0
          this.mainledgerCheck()
        }
        if (this.type === 2) {
          this.type = 0
          this.mainledgerCheck2()
        }
      }
    },
    haveAlert2() {
      this.showAlert = false
      if (this.type === 1) {
        this.mainledgerCheck()
      }
      if (this.type === 2) {
        this.mainledgerCheck2()
      }
    },
    async doAsPrevious() {
      this.firstNormal = true
      this.lastNormal = true
      const ml = this.$store.state.mainledgerActiv ? this.$store.state.mainledgerActiv : {}
      this.mainQuery.FreeF1 = 1
      this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
      this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
      this.mainQuery.MLType = this.$store.state.documentSide ? this.$store.state.documentSide : 1
      this.mainQuery.id = this.$store.state.mainledgerid ? this.$store.state.mainledgerid : 1
      this.mainQuery.BookTypeID = ml.BookTypeID ? parseInt(ml.BookTypeID) : null
      this.mainQuery.BookID = ml.BookID ? ml.BookID : null
      this.mainQuery.partnerVATID = ml.partnerVATID ? ml.partnerVATID : null
      this.mainQuery.invoiceInNumber = this.$store.state.documentSide === 1 ? parseInt(ml.BookID) : null
      this.mainQuery.invoiceOutNumber = this.$store.state.documentSide === 2 ? parseInt(ml.BookID) : null
      //this.alert = true
      const { data } = await MainLedgerDetailService.previtems(this.mainQuery)
      if (data && data.documents && data.documents.length > 0) {
        this.items = data.documents
        
        const invNR = ml.InvoiceNumber ? ', rn: ' + ml.InvoiceNumber : ''
        this.items.map(item => {
          if (item.account4 && item.account4.length > 0) {
          let didIT = false
          
          if (item.account4.substr(0,2) === '22') {
            const desc1 = 'URA ukupno'
            const description = desc1 + invNR
            this.mainledgerDoIt(parseFloat(ml.InvoiceAmount), item.account4, 2, description, 0)
            didIT = true
          }
          if (item.account4.substr(0,2) === '12') {
            const desc1 = 'IRA ukupno'
            const description = desc1 + invNR
            this.mainledgerDoIt(parseFloat(ml.InvoiceAmount), item.account4, 1, description, 0)
            didIT = true
          }
          if (item.account4.substr(0,2) === '14') {
            if (ml.VAT1CanUse && parseFloat(ml.VAT1CanUse) !== 0 && item.Description.substr(0,19) === 'URA pdv 25% odbitni' ) {
              const desc1 = 'URA pdv 25% odbitni'
              const description = desc1 + invNR
              this.mainledgerDoIt(parseFloat(ml.VAT1CanUse), item.account4, 1, description, 25)
              didIT = true
            }
            if (ml.VAT1CanTUse && parseFloat(ml.VAT1CanTUse) !== 0 && item.Description.substr(0,21) === 'URA pdv 25% neodbitni') {
              const desc1 = 'URA pdv 25% neodbitni'
              const description = desc1 + invNR
              this.mainledgerDoIt(parseFloat(ml.VAT1CanTUse), item.account4, 1, description, 25)
              didIT = true
            }
            if (ml.VAT2CanUse && parseFloat(ml.VAT2CanUse) !== 0 && item.Description.substr(0,19) === 'URA pdv 13% odbitni') {
              const desc1 = 'URA pdv 13% odbitni'
              const description = desc1 + invNR
              this.mainledgerDoIt(parseFloat(ml.VAT2CanUse), item.account4, 1, description, 13)
              didIT = true
            }
            if (ml.VAT2CanTUse && parseFloat(ml.VAT2CanTUse) !== 0 && item.Description.substr(0,21) === 'URA pdv 13% neodbitni') {
              const desc1 = 'URA pdv 13% neodbitni'
              const description = desc1 + invNR
              this.mainledgerDoIt(parseFloat(ml.VAT2CanTUse), item.account4, 1, description, 13)
              didIT = true
            }
            if (ml.VAT3CanUse && parseFloat(ml.VAT3CanUse) !== 0 && item.Description.substr(0,18) === 'URA pdv 5% odbitni') {
              const desc1 = 'URA pdv 5% odbitni'
              const description = desc1 + invNR
              this.mainledgerDoIt(parseFloat(ml.VAT3CanUse), item.account4, 1, description, 5)
              didIT = true
            }
            if (ml.VAT3CanTUse && parseFloat(ml.VAT3CanTUse) !== 0 && item.Description.substr(0,20) === 'URA pdv 5% neodbitni') {
              const desc1 = 'URA pdv 5% neodbitni'
              const description = desc1 + invNR
              this.mainledgerDoIt(parseFloat(ml.VAT3CanTUse), item.account4, 1, description, 5)
              didIT = true
            }
          }
          if (item.account4.substr(0,2) === '24') {
              if (ml.VAT1CanUse && parseFloat(ml.VAT1CanUse) !== 0 && item.Description.substr(0,19) === 'IRA pdv 25% odbitni') {
                const desc1 = 'IRA pdv 25% odbitni'
                const description = desc1 + invNR
                this.mainledgerDoIt(parseFloat(ml.VAT1CanUse), item.account4, 2, description, 25)
                didIT = true
              }
              if (ml.VAT1CanTUse && parseFloat(ml.VAT1CanTUse) !== 0 && item.Description.substr(0,21) === 'IRA pdv 25% neodbitni') {
                const desc1 = 'IRA pdv 25% neodbitni'
                const description = desc1 + invNR
                this.mainledgerDoIt(parseFloat(ml.VAT1CanTUse), item.account4, 2, description, 25)
                didIT = true
              }
              if (ml.VAT2CanUse && parseFloat(ml.VAT2CanUse) !== 0 && item.Description.substr(0,19) === 'IRA pdv 13% odbitni') {
                const desc1 = 'IRA pdv 13% odbitni'
                const description = desc1 + invNR
                this.mainledgerDoIt(parseFloat(ml.VAT2CanUse), item.account4, 2, description, 13)
                didIT = true
              }
              if (ml.VAT2CanTUse && parseFloat(ml.VAT2CanTUse) !== 0 && item.Description.substr(0,21) === 'IRA pdv 13% neodbitni') {
                const desc1 = 'IRA pdv 13% neodbitni'
                const description = desc1 + invNR
                this.mainledgerDoIt(parseFloat(ml.VAT2CanTUse), item.account4, 2, description, 13)
                didIT = true
              }
              if (ml.VAT3CanUse && parseFloat(ml.VAT3CanUse) !== 0 && item.Description.substr(0,18) === 'IRA pdv 5% odbitni') {
                const desc1 = 'IRA pdv 5% odbitni'
                const description = desc1 + invNR
                this.mainledgerDoIt(parseFloat(ml.VAT3CanUse), item.account4, 2, description, 5)
                didIT = true
              }
              if (ml.VAT3CanTUse && parseFloat(ml.VAT3CanTUse) !== 0 && item.Description.substr(0,20) === 'IRA pdv 5% neodbitni') {
                const desc1 = 'IRA pdv 5% neodbitni'
                const description = desc1 + invNR
                this.mainledgerDoIt(parseFloat(ml.VAT3CanTUse), item.account4, 2, description, 5)
                didIT = true
              }
          }
          if (item.Description.substr(0,12) === 'URA osnovica'){
            const desc1 = 'URA osnovica'
            const description = desc1 + invNR
            let haveBase = false
            if (ml.VAT1Base && parseFloat(ml.VAT1Base) !== 0) {
              this.mainledgerDoIt(parseFloat(ml.VAT1Base), item.account4, 1, description)
              haveBase = true
              didIT = true
            }
            if (ml.VAT2Base && parseFloat(ml.VAT2Base) !== 0) {
              this.mainledgerDoIt(parseFloat(ml.VAT2Base), item.account4, 1, description)
              haveBase = true
              didIT = true
            }
            if (ml.VAT3Base && parseFloat(ml.VAT3Base) !== 0) {
              this.mainledgerDoIt(parseFloat(ml.VAT3Base), item.account4, 1, description)
              haveBase = true
              didIT = true
            }
            if (ml.VAT4Base && parseFloat(ml.VAT4Base) !== 0) {
              this.mainledgerDoIt(parseFloat(ml.VAT4Base), item.account4, 1, description)
              haveBase = true
              didIT = true
            }
            if (haveBase === false) {
              this.mainledgerDoIt(parseFloat(ml.InvoiceAmount), item.account4, 2, description)
              didIT = true
            }
          }
          if (item.Description.substr(0,12) === 'IRA osnovica'){
            const desc1 = 'IRA osnovica'
            const description = desc1 + invNR
            let haveBase = false
            if (ml.VAT1Base && parseFloat(ml.VAT1Base) !== 0) {
              this.mainledgerDoIt(parseFloat(ml.VAT1Base), item.account4, 2, description)
              haveBase = true
              didIT = true
            }
            if (ml.VAT2Base && parseFloat(ml.VAT2Base) !== 0) {
              this.mainledgerDoIt(parseFloat(ml.VAT2Base), item.account4, 2, description)
              haveBase = true
              didIT = true
            }
            if (ml.VAT3Base && parseFloat(ml.VAT3Base) !== 0) {
              this.mainledgerDoIt(parseFloat(ml.VAT3Base), item.account4, 2, description)
              haveBase = true
              didIT = true
            }
            if (haveBase === false) {
              this.mainledgerDoIt(parseFloat(ml.InvoiceAmount), item.account4, 2, description)
              didIT = true
            }
          }
          
          if (didIT === false) {
            let lastDid = false
            if (this.round(parseFloat(item.Debit)) !== 0) {
              this.mainledgerDoIt(0, item.account4, 1, item.Description)
              lastDid = true
            }
            if (this.round(parseFloat(item.Credit)) !== 0 && !lastDid) {
              this.mainledgerDoIt(0, item.account4, 2, item.Description)
            }
          }

          }

        })
        this.items = this.$store.state.mainledgerItems ? await this.$store.state.mainledgerItems : []
        this.makeSum()
      }
    },
    async mainledgerCheck2() {
      this.firstNormal = true
      this.lastNormal = true
      this.mainQuery.FreeF1 = 1
      this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
      this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
      this.mainQuery.MLType = this.$store.state.documentSide ? this.$store.state.documentSide : 1
      this.mainQuery.id = this.$store.state.mainledgerid ? this.$store.state.mainledgerid : 1
      this.mainQuery.BookTypeID = this.$store.state.mainledgerActiv.BookTypeID ? parseInt(this.$store.state.mainledgerActiv.BookTypeID) : null
      this.mainQuery.BookID = this.$store.state.mainledgerActiv.BookID ? this.$store.state.mainledgerActiv.BookID : null
      this.mainQuery.partnerVATID = this.$store.state.mainledgerActiv.partnerVATID ? this.$store.state.mainledgerActiv.partnerVATID : null
      this.mainQuery.invoiceInNumber = this.$store.state.documentSide === 1 ? parseInt(this.$store.state.mainledgerActiv.BookID) : null
      this.mainQuery.invoiceOutNumber = this.$store.state.documentSide === 2 ? parseInt(this.$store.state.mainledgerActiv.BookID) : null
      //this.alert = true
      const { data } = await MainLedgerDetailService.previtems(this.mainQuery)
      if (data && data.documents && data.documents.length > 0) {
        let firstAcc = null
        let lastAcc = null
        this.items = data.documents
        
        //this.prevNr = parseInt(this.items[0].BookID)
        if (this.$store.state.documentSide === 1) {
          firstAcc = this.items.filter(o => o.account4.substr(0,2) === '22')
          lastAcc = this.items.filter(o => o.account4.substr(0,2) !== '22' && o.account4.substr(0,2) !== '14')
          if (firstAcc && firstAcc.length > 0  && firstAcc[0].account4) {
             this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.InvoiceAmount), firstAcc[0].account4, 2, 0)
             this.firstNormal = false
          }
        }
        if (this.$store.state.documentSide === 2) {
          firstAcc = this.items.filter(o => o.account4.substr(0,2) === '12')
          lastAcc = this.items.filter(o => o.account4.substr(0,2) !== '12' && o.account4.substr(0,2) !== '24')
          if (firstAcc && firstAcc.length > 0  && firstAcc[0].account4) {
            this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.InvoiceAmount), firstAcc[0].account4, 1, 0)
            this.firstNormal = false
          }
        }
        if (lastAcc && lastAcc.length > 0  && lastAcc[0].account4) {
          let haveBase = false
          this.lastNormal = false
          if (this.$store.state.mainledgerActiv.VAT1Base && parseFloat(this.$store.state.mainledgerActiv.VAT1Base) !== 0) {
            this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT1Base), lastAcc[0].account4, this.$store.state.documentSide, 0)
            haveBase = true
          }
          if (this.$store.state.mainledgerActiv.VAT2Base && parseFloat(this.$store.state.mainledgerActiv.VAT2Base) !== 0) {
            this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT2Base), lastAcc[0].account4, this.$store.state.documentSide, 0)
            haveBase = true
          }
          if (this.$store.state.mainledgerActiv.VAT3Base && parseFloat(this.$store.state.mainledgerActiv.VAT3Base) !== 0) {
            this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT3Base), lastAcc[0].account4, this.$store.state.documentSide, 0)
            haveBase = true
          }
          if (this.$store.state.mainledgerActiv.VAT4Base && parseFloat(this.$store.state.mainledgerActiv.VAT4Base) !== 0) {
            this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT4Base), lastAcc[0].account4, this.$store.state.documentSide, 0)
            haveBase = true
          }
          if (haveBase === false) {
            this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.InvoiceAmount), lastAcc[0].account4, this.$store.state.documentSide, 0)
          }
        }

      }
      this.mainledgerCheck()
    },
    mainledgerCheck() {
      if (this.$store.state.mainledgerActiv.InvoiceAmount) {
        if (this.$store.state.documentSide === 1) {
          const invNR = this.$store.state.mainledgerActiv.InvoiceNumber ? ', rn: ' + this.$store.state.mainledgerActiv.InvoiceNumber : ''
          if (this.firstNormal) {
            const desc1 = this.$store.state.language === "HR" ? 'URA ukupno' : 'Inv.total'
            const description = desc1 + invNR
            this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.InvoiceAmount), this.InAllaccount4 , 2, description)
          }
          if (this.$store.state.mainledgerActiv.VAT1CanUse && parseFloat(this.$store.state.mainledgerActiv.VAT1CanUse) !== 0) {
            const desc1 = this.$store.state.language === "HR" ? 'URA pdv 25% odbitni' : 'VAT 25% can use'
            const description = desc1 + invNR
            this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT1CanUse), this.InVATaccount4, 1, description, 25)
          }
          if (this.$store.state.mainledgerActiv.VAT1CanTUse && parseFloat(this.$store.state.mainledgerActiv.VAT1CanTUse) !== 0) {
            const desc1 = this.$store.state.language === "HR" ? 'URA pdv 25% neodbitni' : 'VAT 25% cant use'
            const description = desc1 + invNR
            this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT1CanTUse), (this.firma ? "140017" : ''), 1, description, 25)
          }
          if (this.$store.state.mainledgerActiv.VAT2CanUse && parseFloat(this.$store.state.mainledgerActiv.VAT2CanUse) !== 0) {
            const desc1 = this.$store.state.language === "HR" ? 'URA pdv 13% odbitni' : 'VAT 13% can use'
            const description = desc1 + invNR
            this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT2CanUse), (this.firma ? "140011" : '12416'), 1, description, 13)
          }
          if (this.$store.state.mainledgerActiv.VAT2CanTUse && parseFloat(this.$store.state.mainledgerActiv.VAT2CanTUse) !== 0) {
            const desc1 = this.$store.state.language === "HR" ? 'URA pdv 13% neodbitni' : 'VAT 13% cant use'
            const description = desc1 + invNR
            this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT2CanTUse), (this.firma ? "140016" : ''), 1, description, 13)
          }
          if (this.$store.state.mainledgerActiv.VAT3CanUse && parseFloat(this.$store.state.mainledgerActiv.VAT3CanUse) !== 0) {
            const desc1 = this.$store.state.language === "HR" ? 'URA pdv 5% odbitni' : 'VAT 5% can use'
            const description = desc1 + invNR
            this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT3CanUse), (this.firma ? "140010" : '12425'), 1, description, 5)
          }
          if (this.$store.state.mainledgerActiv.VAT3CanTUse && parseFloat(this.$store.state.mainledgerActiv.VAT3CanTUse) !== 0) {
            const desc1 = this.$store.state.language === "HR" ? 'URA pdv 5% neodbitni' : 'VAT 5% cant use'
            const description = desc1 + invNR
            this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT3CanTUse), (this.firma ? "140015" : '' ), 1, description, 5)
          }
          if (this.lastNormal) {
            let haveBase = false
            const desc1 = this.$store.state.language === "HR" ? 'URA osnovica' : 'Inv.base'
            const description = desc1 + invNR
            if (this.$store.state.mainledgerActiv.VAT1Base && parseFloat(this.$store.state.mainledgerActiv.VAT1Base) !== 0) {
              this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT1Base), this.InBaseaccount4, 1, description, 0)
              haveBase = true
            }
            if (this.$store.state.mainledgerActiv.VAT2Base && parseFloat(this.$store.state.mainledgerActiv.VAT2Base) !== 0) {
              this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT2Base), this.InBaseaccount4, 1, description, 0)
              haveBase = true
            }
            if (this.$store.state.mainledgerActiv.VAT3Base && parseFloat(this.$store.state.mainledgerActiv.VAT3Base) !== 0) {
              this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT3Base), this.InBaseaccount4, 1, description, 0)
              haveBase = true
            }
            if (this.$store.state.mainledgerActiv.VAT4Base && parseFloat(this.$store.state.mainledgerActiv.VAT4Base) !== 0) {
              this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT4Base), this.InBaseaccount4, 1, description, 0)
              haveBase = true
            }
            if (haveBase === false) {
              this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.InvoiceAmount), this.InBaseaccount4, 1, description, 0)
            }
          }
        }
        if (this.$store.state.documentSide === 2) {
          const invNR = this.$store.state.mainledgerActiv.InvoiceNumber ? ', rn: ' + this.$store.state.mainledgerActiv.InvoiceNumber : ''
          if (this.firstNormal) {
            const desc1 = this.$store.state.language === "HR" ? 'IRA ukupno' : 'Inv.total'
            const description = desc1 + invNR
            this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.InvoiceAmount), this.OutAllaccount4, 1, description, 0)
          }
          if (this.$store.state.mainledgerActiv.VAT1CanUse && parseFloat(this.$store.state.mainledgerActiv.VAT1CanUse) !== 0) {
            const desc1 = this.$store.state.language === "HR" ? 'IRA pdv 25% odbitni' : 'Inv. VAT 25% can use'
            const description = desc1 + invNR
            this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT1CanUse), this.OutVATaccount4, 2, description, 25)
          }
          if (this.$store.state.mainledgerActiv.VAT1CanTUse && parseFloat(this.$store.state.mainledgerActiv.VAT1CanTUse) !== 0) {
            const desc1 = this.$store.state.language === "HR" ? 'IRA pdv 25% neodbitni' : 'Inv. VAT 25% cant use'
            const description = desc1 + invNR
            this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT1CanTUse), "240017", 2, description, 25)
          }
          if (this.$store.state.mainledgerActiv.VAT2CanUse && parseFloat(this.$store.state.mainledgerActiv.VAT2CanUse) !== 0) {
            const desc1 = this.$store.state.language === "HR" ? 'IRA pdv 13% odbitni' : 'Inv. VAT 13% can use'
            const description = desc1 + invNR
            this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT2CanUse), "240011", 2, description, 25)
          }
          if (this.$store.state.mainledgerActiv.VAT2CanTUse && parseFloat(this.$store.state.mainledgerActiv.VAT2CanTUse) !== 0) {
            const desc1 = this.$store.state.language === "HR" ? 'IRA pdv 13% neodbitni' : 'Inv. VAT 13% cant use'
            const description = desc1 + invNR
            this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT2CanTUse), "240016", 2, description, 25)
          }
          if (this.$store.state.mainledgerActiv.VAT3CanUse && parseFloat(this.$store.state.mainledgerActiv.VAT3CanUse) !== 0) {
            const desc1 = this.$store.state.language === "HR" ? 'IRA pdv 5% odbitni' : 'Inv. VAT 5% can use'
            const description = desc1 + invNR
            this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT3CanUse), "240010", 2, description, 25)
          }
          if (this.$store.state.mainledgerActiv.VAT3CanTUse && parseFloat(this.$store.state.mainledgerActiv.VAT3CanTUse) !== 0) {
            const desc1 = this.$store.state.language === "HR" ? 'IRA pdv 5% neodbitni' : 'Inv. VAT 5% cant use'
            const description = desc1 + invNR
            this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT3CanTUse), "240015", 2, description, 25)
          }
          if (this.lastNormal) {
            let haveBase = false
            const desc1 = this.$store.state.language === "HR" ? 'IRA osnovica' : 'Inv.base'
            const description = desc1 + invNR
            if (this.$store.state.mainledgerActiv.VAT1Base && parseFloat(this.$store.state.mainledgerActiv.VAT1Base) !== 0) {
              this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT1Base), this.OutBaseaccount4, 2, description, 0)
              haveBase = true
            }
            if (this.$store.state.mainledgerActiv.VAT2Base && parseFloat(this.$store.state.mainledgerActiv.VAT2Base) !== 0) {
              this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT2Base), this.OutBaseaccount4, 2, description, 0)
              haveBase = true
            }
            if (this.$store.state.mainledgerActiv.VAT3Base && parseFloat(this.$store.state.mainledgerActiv.VAT3Base) !== 0) {
              this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.VAT3Base), this.OutBaseaccount4, 2, description, 0)
              haveBase = true
            }
            if (haveBase === false) {
              this.mainledgerDoIt(parseFloat(this.$store.state.mainledgerActiv.InvoiceAmount), this.OutBaseaccount4, 2, description, 0)
            }
          }
        }
      }
      this.makeSum()
    },
    async mainledgerDoIt(amount, account, side, description, VATPer) {
      this.defaultItem = defMainLedgerDetail()
      this.defaultItem = {...this.defaultItem, ...defMainLedgerDetailFill()}
      console.log('this.defaultItem', this.defaultItem)
      if (this.defaultItem.partnerVATID && !this.defaultItem.partnerName) {
        const partner = await findPartnerVATID(this.defaultItem.partnerVATID)
        this.defaultItem.partnerName = partner.partnerNameDatabase ? partner.partnerNameDatabase : (partner.partnerNameShort ? partner.partnerNameShort: '')
      }
      this.choosedAcc(account)
      const newId = this.getRndInteger(1000000000, 9000000000)
      this.defaultItem.id = newId
      this.newItem = {...this.defaultItem}
      this.exRate = this.$store.state.mainledgerActiv.ExRate ? this.$store.state.mainledgerActiv.ExRate : this.$store.getters.NeedDefExRate
      
      if (side === 1) {
        this.newItem.Debit = this.round(amount,2)
        this.newItem.DebitForegin = (amount / this.exRate).toFixed(2)
      }
      if (side === 2) {
        this.newItem.Credit = amount
        this.newItem.CreditForegin = (amount / this.exRate).toFixed(2)
      }
      this.newItem.Description = description ? description : ''
      this.newItem.VATPer = VATPer ? VATPer : 0
      this.activItems = this.$store.state.mainledgerItems ? this.$store.state.mainledgerItems : []
      this.activItems.push(this.newItem)
      if (this.activItems) {
        this.$store.dispatch('setMainLedgerItems', this.activItems)
      } else {
        this.$store.dispatch('setMainLedgerItems', [])
      }

    },
    choosedAcc (choosedAcc) {

        if (choosedAcc) {
          const result = this.account4s.filter(element => element.account4 === choosedAcc)
          if (result[0]) {
            const res = result[0]
            this.defaultItem.account1 = res.account1
            this.defaultItem.account2 = res.account2
            this.defaultItem.account3 = res.account3
            this.defaultItem.account4 = res.account4
            this.defaultItem.account4id = res.id
            this.defaultItem.account4Description = res.account4Description
            this.defaultItem.account4Description2 = res.account4Description2
          } else {
            this.text = 'Error! Entered account not exist' 
            this.color = 'red'
            this.snackbar = true
          }
        }        
      },
      makeSum() {
       
        const updateSumObject = {
          DebitTotal: 0,
          CreditTotal: 0,
          DebitForeginTotal: 0,
          CreditForeginTotal: 0,
          DiffTotal: 0,
          ClosedAccount: false
        }
        if (this.$store.state.mainledgerItems) {
          this.$store.state.mainledgerItems.map(mldetail => {

            updateSumObject.DebitTotal += parseFloat(mldetail.Debit)
            updateSumObject.CreditTotal += parseFloat(mldetail.Credit)
            updateSumObject.DebitForeginTotal += parseFloat(mldetail.DebitForegin)
            updateSumObject.CreditForeginTotal += parseFloat(mldetail.DebitForegin)
          })
        }
        // if debit and credit are diff than 0, then check if they are same 
        // if they are same then account is OK, closed, if they are diff, then
        // account is still not same ...
        const debitTotal = updateSumObject.DebitTotal ? Math.abs(updateSumObject.DebitTotal) : 0
        const creditTotal = updateSumObject.CreditTotal ? Math.abs(updateSumObject.CreditTotal) : 0
        
        if (debitTotal !== 0 || creditTotal !== 0) {
          updateSumObject.DiffTotal = this.round(debitTotal, 2) - this.round(creditTotal, 2)
          if (updateSumObject.DiffTotal === 0) {
            updateSumObject.ClosedAccount = true
          }
        }
        const newMainLedgerActiv = {...this.$store.state.mainledgerActiv, ...updateSumObject}
        const newTikTok = this.$store.state.tikTok + 1
        this.$store.dispatch('setTikTok', newTikTok)
        this.$store.dispatch('setMainLedgerActiv', newMainLedgerActiv)
      },

  },
  computed: {

  },
  watch: {
    'selected' () {
      console.log('this.selected', this.selected)
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
