<template>
  <v-layout row justify-center>
      <v-card v-if="this.$store.state.language == 'EN'">
        <v-card-title class="headline">Are you sure to delete Task?</v-card-title>
        <v-card-text>Consider mark it done. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" flat @click="deleteTask">Delete</v-btn>
          <v-btn color="green darken-1" flat @click="tasklist">Cancel</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="this.$store.state.language == 'HR'">
        <v-card-title class="headline">Da li ste sigurni da zelite obrisati Zadatak?</v-card-title>
        <v-card-text>Preporucujemo da ga ne brisete vec oznacite napravljen. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" flat @click="deleteTask">Obrisi</v-btn>
          <v-btn color="green darken-1" flat @click="tasklist">Odustani</v-btn>
        </v-card-actions>
      </v-card>   
  </v-layout>
</template>

<script>
import TaskService from '@/services/TaskService'

export default {
  name: 'taskdelete',
  data () {
    return {
      msg: '',
      dialog: false
    }
  },
  methods: {
    async deleteTask () {
      try {
        if (this.$store.state.taskid) {
          await TaskService.delete(this.$store.state.taskid)

          this.havePath = this.$store.state.retPath
          this.$store.dispatch('setretPath', '')
          if (this.havePath !== 'task' && this.havePath.length > 0) {
            await this.$router.push({
              name: this.havePath
            })
          } else {
            await this.$router.push({
              name: 'task'
            })
          }
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async tasklist () {
      try {
        this.havePath = this.$store.state.retPath
        this.$store.dispatch('setretPath', '')
        if (this.havePath !== 'task' && this.havePath.length > 0) {
          await this.$router.push({
            name: this.havePath
          })
        } else {
          await this.$router.push({
            name: 'task'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
