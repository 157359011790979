<template>
    <v-card>
        <v-card-title>
          <v-toolbar> 
            {{documentNameLoc}}: {{bookTypeSelected.text}} - {{lang.PrevItems}} {{prevNr}}
            <v-spacer></v-spacer> 
          </v-toolbar>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="20"
            item-key="id"
          >

          <template v-slot:item.account4="{ item }"> 
            <span class="title purple--text">{{ item.account4 }}</span>
          </template>
          <template v-slot:item.Debit="{ item }">
            <span class="title indigo--text">{{ item.Debit }}</span>
          </template>
          <template v-slot:item.Credit="{ item }">
            <span class="title deep-orange--text">{{ item.Credit }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>

</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import MainLedgerDetailService from '@/services/MainLedgerDetailService'

export default {
  name: 'mainledgerdetaillistfast',
  data () {
    return {
      lang: {},
      langC: {},
      documentName: '',
      documentNameLoc: '',
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: '',
        databaseDocID: 0,
        FreeF1: 1
      },
      defaultItem: {},
      newItem: {},
      haveSide: 0,
      activItems: [],
      account4s: [],
      bookTypeSelected: {
      },
      prevNr: 0
    }
  },
  async mounted () {
    try {
      if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentActiv) {
        this.mainQuery.documentName = this.$store.state.documentActiv
      }
      if (this.$store.state.databaseDocID) {
        this.mainQuery.databaseDocID = this.$store.state.databaseDocID
      }
      this.mainQuery.FreeF1 = 1
      this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
      this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
      this.mainQuery.MLType = this.$store.state.documentSide ? this.$store.state.documentSide : 1
      this.mainQuery.id = this.$store.state.mainledgerid ? this.$store.state.mainledgerid : 1
      this.mainQuery.BookTypeID = this.$store.state.mainledgerActiv.BookTypeID ? parseInt(this.$store.state.mainledgerActiv.BookTypeID) : null
      this.mainQuery.BookID = this.$store.state.mainledgerActiv.BookID ? this.$store.state.mainledgerActiv.BookID : null
      this.mainQuery.partnerId = this.$store.state.mainledgerActiv.partnerId ? this.$store.state.mainledgerActiv.partnerId : null
      this.mainQuery.partnerVATID = this.$store.state.mainledgerActiv.partnerVATID ? this.$store.state.mainledgerActiv.partnerVATID : null
      this.mainQuery.invoiceInNumber = this.$store.state.documentSide === 1 ? parseInt(this.$store.state.mainledgerActiv.BookID) : null
      this.mainQuery.invoiceOutNumber = this.$store.state.documentSide === 2 ? parseInt(this.$store.state.mainledgerActiv.BookID) : null
      
      await MainLedgerDetailService.previtems(this.mainQuery)
      .then(res => {
        this.items = res.data.documents ? res.data.documents : []
        this.prevNr = parseInt(this.items[0].BookID)
      })
      .catch(err => {
        // eslint-disable-next-line
        console.log('Error ' + err)
      })
      
      this.headers.push(
        {
          text: this.lang.Account4,
          align: 'left',
          sortable: true,
          value: 'account4',
          width: '80px'
        },
        {
          text: this.lang.Debit,
          align: 'right',
          sortable: true,
          value: 'Debit',
          width: '120px'
        },
        {
          text: this.lang.Credit,
          align: 'right',
          sortable: true,
          value: 'Credit',
          width: '120px'
        },
        {
          text: this.lang.Description,
          align: 'left',
          sortable: true,
          value: 'Description',
          width: '250px'
        },
        {text: this.lang.VATPer, align: 'right', sortable: true, value: 'VATPer', width: '60px' },
        {text: 'Partner', align: 'left', value: 'partnerName', width: '260px' },
        {text: this.lang.Account4Description, value: 'account4Description', align: 'left', width: '550px' },
        {text: this.lang.Actions, value: 'action', sortable: false },
      )
      if (this.$store.state.mainledgerActiv.InvoiceAmount) {
          if (this.$store.state.documentSide === 1) {
            this.haveSide = 1
          }
          if (this.$store.state.documentSide === 2) {
            this.haveSide = 2
          }
       }
       this.account4s = this.$store.state.account4s ? this.$store.state.account4s : []
       this.bookTypeSelected = this.$store.state.bookTypeSelected ? this.$store.state.bookTypeSelected : {}

    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  },
  methods: {
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },

    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
  },
  computed: {
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
