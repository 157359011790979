import store from '../../store/store'

const defDigitalDoc = () => {
  const defObj = {}
  defObj.CompanyId = 0
  defObj.CompanyNameDatabase = ''
  defObj.BusinessYear = 0
  defObj.UserId = 0
  defObj.email = ''
  defObj.First = ''
  defObj.Last = ''
  defObj.doc = ''
  defObj.hashtag = ''
  defObj.eemail = ''
  defObj.phone = ''
  defObj.First = ''
  defObj.Last = ''
  defObj.EvidenceType = 0
  defObj.documentIdGeneral = ''
  defObj.Description = ''
  defObj.originalname = ''
  defObj.filename = ''
  defObj.destination = ''
  defObj.path = ''
  defObj.typeDoc = ''
  defObj.newId = 0
  defObj.Notes = ''
  defObj.MLType = 0
  defObj.BookID = 0
  defObj.BookTypeID = 0
  defObj.BookName = ''
  defObj.BookSubTypeID = 0
  defObj.BookSubName = ''
  defObj.BookIdGeneral = ''
  defObj.documentName = ''
  defObj.documentSide = 0
  defObj.documentId = 0
  defObj.DateOpen = null
  defObj.Locked = false
  defObj.LockedDate = null
  defObj.LockedTime = null
  defObj.LockedBy = ''
  defObj.LastUpdateBy = ''
  return defObj
}

const defNewDigitalDoc = () => {
  const defaultDigitalDoc = {}
  defaultDigitalDoc.CompanyId = store.state.companyid ? store.state.companyid : 1
  defaultDigitalDoc.CompanyNameDatabase = store.state.companyName ? store.state.companyName : ''
  defaultDigitalDoc.BusinessYear = store.state.businessYear ? store.state.businessYear : 2020
  defaultDigitalDoc.UserId = store.state.user.id ? store.state.user.id : ''
  defaultDigitalDoc.email = store.state.user.email ? store.state.user.email : ''
  defaultDigitalDoc.First = store.state.user.First ? store.state.user.First : ''
  defaultDigitalDoc.Last = store.state.user.Last ? store.state.user.Last : ''
  return defaultDigitalDoc
}

export {
  defDigitalDoc,
  defNewDigitalDoc
}