<template>
  <v-form
    ref="form"
    v-model="valid"
  >
   <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" xs="12" md="4"  lg="4" xl="4" class="mb-4">
        <h2>Obrazac Joppd A</h2>
      </v-col>
      <v-col cols="12" sm="12" xs="12" md="4"  lg="4" xl="4" class="mb-4">
         <v-btn
            text small color="primary"
            @click="createjopp">
            Kreiraj datoteku
        </v-btn>
        <v-btn
            text small color="primary"
            @click="downloadjopp">
            Download
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" xs="12" md="4"  lg="4" xl="4">
        <v-spacer></v-spacer>

       <v-btn
        :disabled="!valid"
        class="primary mr-2"
        color="success"
        @click="save">
        <span v-if="this.$store.state.editItem  === 0">
          {{langC.CreateNew}} Joppd A
        </span>
        <span v-if="this.$store.state.editItem === 1">
          {{langC.Save}} Joppd A {{joppda.OznakaIzvjesca}}
        </span>
       </v-btn>
        <v-btn
          class="mr-3"
          color="green"
          @click="goBack">
          {{langC.Back}}
        </v-btn>

        <v-btn
          color="blue"
          @click="goJoppdB">
          Joppd B
        </v-btn>
      </v-col>
    </v-row>

    <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>

    <v-row no-gutters class="pa-0">

      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-4">
        <v-text-field
          dense
          :rules="reqRules"
          label="I. OZNAKA IZVJEŠĆA *"
          v-model="joppda.OznakaIzvjesca"
          required
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
        <v-text-field
          dense
          :rules="reqRules"
          label="II. VRSTA IZVJEŠĆA *"
          v-model.number="joppda.VrstaIzvjesca"
          type="number"
          required
          outlined
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters class="pa-0">
      <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-4">
        <h4 class="mb-3">Podaci o podnositelju izvješća</h4>
      </v-col>
    </v-row>

    <v-row no-gutters class="pa-0">
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-4">
        <v-text-field
          dense
          :rules="reqRules"
          label="Naziv/ime i prezime *"
          v-model="joppda.PodNaziv"
          required
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
        <v-text-field
          dense
          label="Mjesto"
          v-model="joppda.PodAdrMjesto"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
        <v-text-field
          dense
          label="Ulica"
          v-model="joppda.PodAdrUlica"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-3">
        <v-text-field
          dense
          label="Kbr"
          v-model="joppda.PodAdrBroj"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-4">
        <v-text-field
          dense
          label="email"
          v-model="joppda.PodEmail"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-3">
        <v-text-field
          dense
          :rules="reqRules"
          label="OIB *"
          v-model="joppda.PodOIB"
          required
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
        <v-text-field
          dense
          label="Ozn.Podnositelja"
          v-model="joppda.PodOznaka"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters class="pa-0">
      <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-4">
        <h4 class="mb-3">Podaci o obvezniku plaćanja</h4>
      </v-col>
    </v-row>

    <v-row no-gutters class="pa-0">
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-4">
        <v-text-field
          dense
          label="Naziv/ime i prezime"
          v-model="joppda.ObPlNaziv"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
        <v-text-field
          dense
          label="Mjesto"
          v-model="joppda.ObPlAdrMjesto"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
        <v-text-field
          dense
          label="Ulica"
          v-model="joppda.ObPlAdrUlica"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-3">
        <v-text-field
          dense
          label="Kbr"
          v-model="joppda.ObPlAdrBroj"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-4">
        <v-text-field
          dense
          label="email"
          v-model="joppda.ObPlEmail"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-3">
        <v-text-field
          dense
          label="OIB"
          v-model="joppda.ObPlOIB"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>

   <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-4">
      <v-text-field
        dense
        :rules="reqRules"
        label="BROJ OSOBA ZA KOJE SE PODNOSI IZVJEŠĆE *"
        v-model.number="joppda.BrojOsoba"
        type="number"
        required
        outlined
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-3">
      <v-text-field
        dense
        :rules="reqRules"
        label="BROJ REDAKA NA POPISU POJEDINAČNIH OBRAČUNA SA STRANICE B *"
        v-model.number="joppda.BrojRedaka"
        type="number"
        required
        outlined
      ></v-text-field>
    </v-col>
  </v-row>

  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="pr-4">
      <h3 class="mb-3">PODACI O UKUPNOM IZNOSU OBRAČUNANOG PREDUJMA POREZA NA DOHODAK I PRIREZA POREZU NA DOHODAK</h3>
    </v-col>
     <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-4">
      <h4 class="mb-3">IZNOS</h4>
    </v-col>
  </v-row>

  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      Ukupan iznos predujma poreza na dohodak i prireza porezu na dohodak po osnovi nesamostalnog rada (1.1.+1.2.)
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="1. "
        v-model.number="joppda.PP_P1"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
     <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
       1.1 Ukupan zbroj stupaca 14.1. i 14.2. sa stranice B pod oznakom stjecatelja primitka/osiguranika (plaća)
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="1.1"
        v-model.number="joppda.PP_P11"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
       1.2 Ukupan zbroj stupaca 14.1. i 14.2. sa stranice B pod oznakom stjecatelja primitka/osiguranika (mirovina)
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="1.2"
        v-model.number="joppda.PP_P12"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>

  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
       2. Ukupan iznos predujma poreza na dohodak i prireza porezu na dohodak po osnovi dohotka od kapitala
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="2. "
        v-model.number="joppda.PP_P2"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
     <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      3.Ukupan iznos predujma poreza na dohodak i prireza porezu na dohodak po osnovi dohotka od imovinskih prava i posebnih vrsti imovine
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="3. "
        v-model.number="joppda.PP_P3"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      4. Ukupan iznos predujma poreza na dohodak i prireza porezu na dohodak po osnovi dohotka od osiguranja
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="4."
        v-model.number="joppda.PP_P4"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      5. Ukupan iznos predujma poreza na dohodak i prireza porezu na dohodak po osnovi primitka od kojeg se utvrđuje drugi dohodak
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="5. "
        v-model.number="joppda.PP_P5"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      6. Ukupan iznos predujma poreza na dohodak i prireza porezu na dohodak po osnovi dohotka od kamata
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="6."
        v-model.number="joppda.PP_P6"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>

  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="pr-4">
      <h3 class="mb-3">PODACI O UKUPNOM IZNOSU OBRAČUNANOG DOPRINOSA </h3>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="pr-4">
      <h4 class="mb-3">VI.1 DOPRINOS ZA MIROVINSKO OSIGURANJE NA TEMELJU GENERACIJSKE SOLIDARNOSTI</h4>
    </v-col>
     <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-4">
      <h4 class="mb-3">IZNOS</h4>
    </v-col>
  </v-row>

  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      1. Ukupan iznos doprinosa za mirovinsko osiguranje na temelju generacijske solidarnosti po osnovi radnog odnosa
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="1."
        v-model.number="joppda.GS_P1"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>

  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      2. Ukupan iznos doprinosa za mirovinsko osiguranje na temelju generacijske solidarnosti po osnovi drugog dohotka
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="2."
        v-model.number="joppda.GS_P2"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>

  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      3. Ukupan iznos doprinosa za mirovinsko osiguranje na temelju generacijske solidarnosti po osnovi poduzetničke plaće
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="3."
        v-model.number="joppda.GS_P3"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>

  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      4. Ukupan iznos doprinosa za mirovinsko osiguranje na temelju generacijske solidarnosti za osiguranike za koje se doprinos uplaćuje prema posebnim propisima
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="4."
        v-model.number="joppda.GS_P4"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>

  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      5. Ukupan iznos posebnog doprinosa za mirovinsko osiguranje na temelju generacijske solidarnosti za osobe osigurane u određenim okolnostima
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="5."
        v-model.number="joppda.GS_P5"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>

   <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      6. Ukupan iznos dodatnog doprinosa za mirovinsko osiguranje na temelju generacijske solidarnosti za staž osiguranja koji se računa s povećanim trajanjem
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="6."
        v-model.number="joppda.GS_P6"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>

   <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      7. Ukupan iznos doprinosa za mirovinsko osiguranje na temelju generacijske solidarnosti po osnovi obavljanja samostalne djelatnosti za osobe koje su same za sebe obvezne obračunati doprinose
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="7."
        v-model.number="joppda.GS_P7"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>

  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="pr-4 mt-3">
      <h4 class="mb-3">VI.2 DOPRINOS ZA MIROVINSKO OSIGURANJE NA TEMELJU INDIVIDUALNE KAPITALIZIRANE ŠTEDNJE </h4>
    </v-col>
     <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-4">
      <h4 class="mb-3">IZNOS</h4>
    </v-col>
  </v-row>

  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      1. Ukupan iznos doprinosa za mirovinsko osiguranje na temelju individualne kapitalizirane štednje po osnovi radnog odnosa
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="1. "
        v-model.number="joppda.KS_P1"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>

  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      2. Ukupan iznos doprinosa za mirovinsko osiguranje na temelju individualne kapitalizirane štednje po osnovi drugog dohotka
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="2."
        v-model.number="joppda.KS_P2"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      3. Ukupan iznos doprinosa za mirovinsko osiguranje na temelju individualne kapitalizirane štednje po osnovi poduzetničke plaće
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="3. "
        v-model.number="joppda.KS_P3"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      4. Ukupan iznos doprinosa za mirovinsko osiguranje na temelju individualne kapitalizirane štednje za osiguranike za koje se doprinos uplaćuje prema posebnim propisima
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="4."
        v-model.number="joppda.KS_P4"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      5. Ukupan iznos dodatnog doprinosa za mirovinsko osiguranje na temelju individualne kapitalizirane štednje za staž osiguranja koji se računa s povećanim trajanjem
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="5. "
        v-model.number="joppda.KS_P5"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      6. Ukupan iznos doprinosa za mirovinsko osiguranje na temelju individualne kapitalizirane štednje po osnovi obavljanja samostalne djelatnosti za osobe koje su same za sebe obvezne obračunati doprinose
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="6."
        v-model.number="joppda.KS_P6"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>

  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="pr-4 mt-3">
      <h4 class="mb-3">VI.3 DOPRINOS ZA ZDRAVSTVENO OSIGURANJE </h4>
    </v-col>
     <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-4">
      <h4 class="mb-3">IZNOS</h4>
    </v-col>
  </v-row>

  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      1. Ukupan iznos doprinosa za zdravstveno osiguranje po osnovi radnog odnosa
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="1. "
        v-model.number="joppda.ZO_P1"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>

  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      2. Ukupan iznos doprinosa za zaštitu zdravlja na radu po osnovi radnog odnosa
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="2. "
        v-model.number="joppda.ZO_P2"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>

  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      3. Ukupan iznos doprinosa za zdravstveno osiguranje po osnovi poduzetničke plaće
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="3. "
        v-model.number="joppda.ZO_P3"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>

  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      4. Ukupan iznos doprinosa za zaštitu zdravlja na radu po osnovi poduzetničke plaće
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="4. "
        v-model.number="joppda.ZO_P4"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      5. Ukupan iznos doprinosa za zdravstveno osiguranje po osnovi drugog dohotka
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="5. "
        v-model.number="joppda.ZO_P5"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      6. Ukupan iznos posebnog doprinosa za korištenje zdravstvene zaštite u inozemstvu
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="6. "
        v-model.number="joppda.ZO_P6"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      7. Ukupan iznos dodatnog doprinosa za zdravstveno osiguranje – za obveznike po osnovi korisnika mirovina
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="7. "
        v-model.number="joppda.ZO_P7"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      8. Ukupan iznos doprinosa za zdravstveno osiguranje - za osiguranike za koje se doprinos uplaćuje prema posebnim propisima
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="8. "
        v-model.number="joppda.ZO_P8"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      9. Ukupan iznos doprinosa za zaštitu zdravlja na radu - za osiguranike za koje se doprinos uplaćuje prema posebnim propisima
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="9. "
        v-model.number="joppda.ZO_P9"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      10. Ukupan iznos posebnog doprinosa za zaštitu zdravlja na radu - za osobe osigurane u određenim okolnostima
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="10. "
        v-model.number="joppda.ZO_P10"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      11. Ukupan iznos doprinosa za zdravstveno osiguranje po osnovi obavljanja samostalne djelatnosti za osobe koje su same za sebe obvezne obračunati doprinose
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="11. "
        v-model.number="joppda.ZO_P11"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      12. Ukupan iznos doprinosa za zaštitu zdravlja na radu po osnovi obavljanja samostalne djelatnosti za osobe koje su same za sebe obvezne obračunati doprinose
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="12. "
        v-model.number="joppda.ZO_P12"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>

  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="pr-4 mt-3">
      <h4 class="mb-3">VI.4 DOPRINOS ZA ZAPOŠLJAVANJE </h4>
    </v-col>
     <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-4">
      <h4 class="mb-3">IZNOS</h4>
    </v-col>
  </v-row>

  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      Ukupan iznos doprinosa za zapošljavanje
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="1. "
        v-model.number="joppda.ZP_P1"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      Ukupan iznos posebnog doprinosa za zapošljavanje osoba s invaliditetom
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="2. "
        v-model.number="joppda.ZP_P2"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      Ukupan iznos doprinosa za zapošljavanje po osnovi poduzetničke plaće
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="3. "
        v-model.number="joppda.ZP_P3"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      Ukupan iznos doprinosa za zapošljavanje po osnovi obavljanja samostalne djelatnosti za osobe koje su same za sebe obvezne obračunati doprinose
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="4. "
        v-model.number="joppda.ZP_P4"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>


  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="pr-4 mt-3">
      <h4 class="mb-3">VII ISPLAĆENI NEOPOREZIVI PRIMICI  </h4>
    </v-col>
     <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-4">
      <v-text-field
        dense
        label="VII. "
        v-model.number="joppda.IsplaceniNeoporeziviPrimici"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>

  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      NAPLAĆENA KAMATA ZA DOPRINOSE ZA MIROVINSKO OSIGURANJE NA TEMELJU INDIVIDUALNE KAPITALIZIRANE ŠTEDNJE
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="VIII. "
        v-model.number="joppda.KamataMO2"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      UKUPAN IZNOS NEOPOREZIVIH PRIMITAKA NEREZIDENATA KOJE ISPLAĆUJU NEPROFITNE ORGANIZACIJE DO PROPISANOG IZNOSA
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="IX. "
        v-model.number="joppda.UkupniNeoporeziviPrimici"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      PODACI O BROJU OSOBA I NAKNADI UTVRĐENOJ SUKLADNO ODREDBAMA ZAKONA O PROFESIONALNOJ REHABILITACIJI I ZAPOŠLJAVANJU OSOBA S INVALIDITETOM
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">

    </v-col>
  </v-row>



  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      Broj osoba s invaliditetom koje je obveznik bio dužan zaposliti
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="1. "
        v-model.number="joppda.NZI_P1"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="pa-0">
    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
      Iznos obračunane naknade
    </v-col>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
      <v-text-field
        dense
        label="2. "
        v-model.number="joppda.NZI_P2"
        type="number"
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
      <v-row>
      <v-col cols="12" sm="12" xs="12" md="4"  lg="4" xl="4" class="mb-4">
         <v-btn
            text small color="red"
            @click="deletejoppd">
            Obriši
        </v-btn>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>

  </v-container>
 </v-form>
</template>


<script>

import JoppdAService from '@/services/JoppdAService'
import JoppdBService from '@/services/JoppdBService'
import {defJoppdA, defNewJoppdA} from './salarieHelper'
import { v4 as uuidv4 } from 'uuid'
import ledgerEN from './salarieDescEn'
import ledgerHR from './salarieDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import FileSaver from 'file-saver'

export default {
  name: 'joppdadetail',
  props: [],
  data: ()=> ({
    lang: {},
    langC: {},
    saving: false,
    valid: false,
    reqRules: [
      v => !!v || 'Obavezno polje',
    ],
    joppda: {
    },
    items: [],
    mainQuery: {},
    snackbar: false,
    text: '',
    timeout: 2000,
    color: 'green',

  }),
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    if (this.$store.state.editItem === 0) {
      this.joppda = defJoppdA()
      this.joppda = {...this.joppda, ...defNewJoppdA()}
    }
    if (this.$store.state.editItem === 1) {
      const res = await JoppdAService.show(this.$store.state.documentid)
      console.log(res)
      this.joppda = res && res.data ? res.data : {}
      this.joppda.LastUpdateBy = this.$store.state.user.email
    }
  },
  methods: {
    async createjopp() {
      if (this.joppda) {
        if (this.$store.state.companyid) {
          this.mainQuery.CompanyId = this.$store.state.companyid
        }
        if (this.$store.state.businessYear) {
          this.mainQuery.businessYear = this.$store.state.businessYear
        }
        if (this.$store.state.documentid) {
          this.mainQuery.JoppdAID = this.$store.state.documentid
        }
        const empArr = []
        this.items =[...empArr]

        await JoppdBService.choose(this.mainQuery)
        .then(res => {
          console.log('joppdb ', res)
          this.items = res.data.joppdb ? res.data.joppdb : []
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log('Error ' + err)
        })

        const newJoppd = {}
        newJoppd.head = this.joppda
        newJoppd.head.uui = uuidv4()
        newJoppd.items = this.items

        JoppdAService.createjoppd(newJoppd)
        .then(res => {
          console.log(res)
          this.text = 'Obrazac je kreiran.'
          this.color = 'green'
          this.snackbar = true
          this.timeout = 2000
        })
        .catch(err => {
          console.log(err)
          this.text = 'Greska!! Obrazac NIJE kreiran.' + err
          this.color = 'red'
          this.snackbar = true
          this.timeout = 2000
        })
      }
    },
    async downloadjopp(){
        this.mainQuery.OIB = this.joppda.PodOIB
        this.mainQuery.OznakaIzvjesca = this.joppda.OznakaIzvjesca
        const zoveSe = 'Joppd_' + this.joppda.PodOIB + '_' + this.joppda.OznakaIzvjesca  + '_.xml'
        JoppdAService.uploadjoppd(this.mainQuery)
        .then(res => {
          const data = res.data
          //const status = res.status
          const header = res.headers
          const fileType = header['content-type']
          const blob = new Blob([data], { type: fileType })
          FileSaver.saveAs(blob, zoveSe);
          //FileSaver.saveAs(res.data, zoveSe)
          // this.text = 'Spremite knjigu.'
          // this.color = 'green'
          // this.snackbar = true
          // this.timeout = 2000
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log(err)
          this.text = 'Greska!! Obrazac se NE moze ucitati.' + err
          this.color = 'red'
          this.snackbar = true
          this.timeout = 2000
        })
    },
    validate () {
      this.$refs.form.validate()
    },
    async deletejoppd() {
      if (confirm('Da li ste sigurni da želite obrisati JOPPD A i B ?')) {
        await JoppdAService.delete2(this.joppda.id)
        .then(
            this.$router.push({
            name: 'salarie'
          })
        )
        .catch(err => {
          alert('GRESKA kod brisanja joppd ' + err)
        })

      }
    },
    async save() {
      try {

        this.saving = true
        if (this.$store.state.editItem === 0) {
          await JoppdAService.post(this.joppda)
        }
        if (this.$store.state.editItem === 1) {
          await JoppdAService.put(this.joppda)
        }
        this.saving = false
        this.goBack()
      }
      catch (err) {
        console.log(err)
      }
    },
    goBack (){
      this.$router.push({
          name: 'joppda'
        })
    },
    goJoppdB() {
       this.$router.push({
          name: 'joppdb'
        })
    }

  },
  computed: {
  },
  watch: {

  },
  components: {

  }

}

</script>
