<template>
  <v-layout row justify-center>
      <v-card>
        <v-card-title class="headline">Are you sure to delete Group?</v-card-title>
        <v-card-text>We not recomend to delete group. Better disable it. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" flat @click="deleteGroup">Delete</v-btn>
          <v-btn color="green darken-1" flat @click="grouplist">Cancel</v-btn>
        </v-card-actions>
      </v-card>
  </v-layout>
</template>

<script>
import GroupService from '@/services/GroupService'

export default {
  name: 'groupdelete',
  data () {
    return {
      msg: '',
      dialog: false
    }
  },
  methods: {
    async deleteGroup () {
      try {
        if (this.$store.state.groupid) {
          await GroupService.delete(this.$store.state.groupid)
          await this.$router.push({
            name: 'group'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async grouplist () {
      try {
        await this.$router.push({
          name: 'group'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
