<template>
  <div>

            <v-row no-gutters>
              <v-col cols="4" class="headline text--primary">
                {{documentNameLoc}} {{langC.Number}}:
              </v-col>
              <v-col cols="8">
                <div class="headline text--primary">
                  {{document.documentId}}, {{locdocumentDate}}, {{exportDoc}}
                </div>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="4">
                {{lang.User}}
              </v-col>
              <v-col cols="8">
                  <span >{{document.First}} {{document.Last}}, {{document.email}}</span>
              </v-col>
            </v-row>

            <v-row no-gutters v-if="connTxt">
              <v-col cols="4">
                  <span v-if="this.$store.state.language == 'EN'">
                    Connection:
                   </span>
                   <span v-if="this.$store.state.language == 'HR'">
                    Veza:
                   </span>
              </v-col>
              <v-col cols="8">
                  <span style="font-weight:bold">{{connTxt}}</span>
              </v-col>
            </v-row>

            <v-divider></v-divider>


            <v-row no-gutters v-if="document.documentDescription4">
              <v-col cols="4">
                {{lang.Description4}}
              </v-col>
              <v-col cols="8">
                  <span >{{document.documentDescription4}}</span>
              </v-col>
            </v-row>

            <v-row no-gutters v-if="document.documentDescription1">
              <v-col cols="4">
                {{lang.Description1}}
              </v-col>
              <v-col cols="8">
                  <span >{{document.documentDescription1}}</span>
              </v-col>
            </v-row>  

            <v-row no-gutters v-if="document.documentDescription5">
              <v-col cols="4">
                {{lang.Description5}}
              </v-col>
              <v-col cols="8">
                  <span >{{document.documentDescription5}}</span>
              </v-col>
            </v-row>  

            <v-row no-gutters v-if="document.documentDescription3">
              <v-col cols="4">
                {{lang.Description3}}
              </v-col>
              <v-col cols="8">
                  <span >{{document.documentDescription3}}</span>
              </v-col>
            </v-row>             

            <v-divider></v-divider>

            <v-row no-gutters>
              <v-col cols="4">
                {{langC.CreatedAt}}
              </v-col>
              <v-col cols="8">
                  <span >{{locCreatedAt}}</span>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="4">
                {{langC.UpdatedAt}}
              </v-col>
              <v-col cols="8">
                  <span >{{locUpdatedAt}}</span>
              </v-col>
            </v-row>
  </div>
</template>

<script>
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'

export default {
  data() {
    return {
      document: {},
      locCreatedAt: '',
      locUpdatedAt: '',
      lang: {},
      langC: {},
      documentNameLoc: '',
      locdocumentDate: '',
      exportDoc: '',
      connTxt: ''
    }
  },
  mounted() {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
      this.exportDoc = this.$store.state.documentActivHead.exportDoc ? ' EXPORT' : ''
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
      this.exportDoc = this.$store.state.documentActivHead.exportDoc ? ' IZVOZ' : '' 
    }
    if (this.$store.state.documentActivHead) {
      this.document = this.$store.state.documentActivHead
      this.documentNameLoc = this.$store.state.documentActivLoc
     
      if (this.document.documentDate) {
          this.locdocumentDate = dayjs(this.document.documentDate).format('DD.MM.YYYY')
      }
      this.locCreatedAt = dayjs(this.document.createdAt).format('DD.MM.YYYY HH:MM')
      this.locUpdatedAt = dayjs(this.document.updatedAt).format('DD.MM.YYYY HH:MM') + (this.document.LastUpdateBy ? ', ' + this.document.LastUpdateBy : '')
      this.connTxt =  (this.document.documentConnName ? this.document.documentConnName + ':' : '') + (this.document.documentConnId ? this.document.documentConnId + '/' : '') + (this.document.documentConnDate ? dayjs(this.document.documentConnDate).format('DD.MM.YYYY HH:MM') : '')
    }
  },
  methods: {
  } 
}
</script>

<style>

</style>