<template>
  <v-container fluid>
        <v-row>
            <v-col cols="12" sm="4" md="4" lg="4" xl="4">
              <h1>{{documentNameLoc}}: {{bookTypeSelected.text}}</h1>
            </v-col>
            <v-col cols="12" sm="1" md="1" lg="1" xl="1">
                 <v-btn text color="red" @click="mainledgerDelete" class="mr-1" v-if="show === 4">
                      {{langC.Delete}}
                  </v-btn>
            </v-col>
            <v-col cols="12" sm="7" md="7" lg="7" xl="7" class="text-right">
                  <v-btn v-if="BookTypeID === 100" color="blue darken-1" text @click="finishBS" class="mr-1">{{lang.closeBS}}</v-btn>
                  <v-btn color="green" @click="mainledgerlist" class="mr-1">
                      {{langC.BackToList}}
                  </v-btn>
                  <v-btn color="green" @click="mainledgerprint" class="mr-1" v-if="show !== 4">
                      {{langC.Print}}
                  </v-btn>
                  <v-btn color="green" @click="previtems" class="mr-1" v-if="show !== 4">
                      {{lang.Previous}}
                  </v-btn>
                  <v-btn color="green" @click="haveAlert(2)" class="mr-1" v-if="show !== 4">
                      {{langC.SaveAndNext}}
                  </v-btn>
                  
                  <v-btn color="green" @click="haveAlert(1)" class="mr-1">
                      {{langC.Save}}
                  </v-btn> 
           </v-col>
           <v-progress-circular v-if="this.saving"
              indeterminate
              color="green"
            ></v-progress-circular>
        </v-row>

        <v-row v-if="showErrorItems">
            <v-col cols="12" sm="4" md="4" lg="4" xl="4">
  
            </v-col>
            <v-col cols="12" sm="1" md="1" lg="1" xl="1">

            </v-col>
            <v-col cols="12" sm="7" md="7" lg="7" xl="7" class="text-right">
                <v-btn color="red"  @click="retrieveErrorItems()" class="mr-1">
                    {{lang.ReturnUnsaved}}
                </v-btn> 
           </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <span v-if="show == 1 || show == 2">
                <MainLedgerView2 />
              </span>
              <span v-if="show == 3">
                <MainLedgerView3  />
              </span>
              <span v-if="show == 4">
                <MainLedgerView4 />
              </span>
              
          </v-col>
        </v-row>

        <v-row v-if="show == 4">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="pr-2">
            <MainLedgerDetailBankStatementCRUD />
          </v-col>
        </v-row>

        <v-row v-if="show == 1 || show == 2 || show == 3 || show == 4" >
          <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="pr-2">
            <MainLedgerDetailList :key="componentKey" />
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="pr-2">
            <span v-if="showTotals"> <MainLedgerViewTotal :key="componentKey" />
            </span>
          </v-col>
        </v-row>
        
        <v-row v-if="havePrevious">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="pr-2">
            <MainLedgerDetailListFast />
          </v-col>
        </v-row>

        <v-dialog
          v-model="showAlert"
          max-width="290"
        >
          <v-card>
            <v-card-title class="headline">
             {{lang.MainLedgerQues1}}
            </v-card-title>

            <v-card-text>
              {{lang.MainLedgerQues2}}
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="showAlert = false"
              >
                {{langC.Cancel}}
              </v-btn>

              <v-btn
                color="blue"
                text
                @click="haveAlert2()"
              >
                {{langC.Continue}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          bottom
          :color="color"
          >
          {{ text }}
        </v-snackbar>
        <MainLedgerPrintCurrent ref="prnt" :card="card" :type="$store.state.documentSide"/>
        <div v-if="showDialog">
          <JustWait :message="message" :showDialog="showDialog" />
        </div>
  </v-container>
</template>

<script>
import MainLedgerService from '@/services/MainLedgerService'
import MainLedgerDetailService from '@/services/MainLedgerDetailService'
import MainLedgerDetailList from './MainLedgerDetailList'
import MainLedgerDetailListFast from './MainLedgerDetailListFast'
import MainLedgerDetailBankStatementCRUD from './MainLedgerDetailBankStatementCRUD'
import MainLedgerView2 from './MainLedgerView2'
import MainLedgerView3 from './MainLedgerView3'
import MainLedgerView4 from './MainLedgerView4'
import MainLedgerViewTotal from './MainLedgerViewTotal'
import MainLedgerPrintCurrent from './MainLedgerPrintCurrent'
import JustWait from '../../snippets/JustWait'
//import BankAccountService from '@/services/BankAccountService'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import {parseNum, round} from '@/snippets/allSnippets'
import { defMainLedgerDetail, defMainLedgerDetailFill } from './mainledgerHelper'
import { mapGetters } from 'vuex'

export default {
  name: 'mainledgerdetail',
  data () {
    return {
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      msg: '',
      documentName2: '',
      document: {},
      mainledger: {},
      locDocument: {},
      documentName: '',
      documentNameLoc: '',
      retPath: 'mainledger',
      show: 1,
      error: '',
      mainQuery: {
        CompanyId: 1,
        MLType: 1,
      },
      locCreatedAt: '',
      locUpdatedAt: '',
      locBookingDate: '',
      locInvoiceDate: '',
      locInvoicePaymentDeadlineDate: '',
      locBookID: 0,
      locCanUse: 0,
      locCantUse: 0,
      mldetails: [],
      account4s: [],
      lang: {},
      langC: {},
      saving: false,
      showTotals: false,
      bookTypeSelected: {
      },
      havePrevious: false,
      haveDiff: false,
      showAlert: false,
      type: null,
      DebitTotal: 0,
      CreditTotal: 0,
      DiffTotal: 0,
      ClosedAccount: false,
      componentKey: 0,
      databaseMLID: 0,
      card: {},
      BookTypeID: null,
      defaultItem: {},
      showErrorItems: false,
      message: '',
      showDialog: false
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    this.message = this.langC.JustWait
    this.$store.dispatch('setTikTok', 0)
    this.BookTypeID = this.$store.state.mainledgerActiv.BookTypeID
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    if (this.$store.state.documentSide === 1 || this.$store.state.documentSide === 2) {
      this.show = 1
      this.retPath = 'mainledger'
      this.bookTypeSelected = this.$store.state.bookTypeSelected ? this.$store.state.bookTypeSelected : {}
    }
    if (this.$store.state.documentSide === 3) {
      this.show = 3
      this.retPath = 'mainledger3'
    }
    if (this.$store.state.documentSide === 4) {
      this.show = 4
      this.retPath = 'mainledger4'
    }
    this.showTotals =  this.$store.state.mainledgerTotals === 1 ? true : false
    this.account4s = this.$store.state.account4s ? this.$store.state.account4s : [] 
    if (this.$store.state.mainledgerErrorItems && this.$store.state.mainledgerErrorItems.length > 0) {
      this.showErrorItems = true
    }
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;  
    },
    previtems() {
      this.havePrevious = !this.havePrevious
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
    
    retrieveErrorItems() {
      if (this.$store.state.mainledgerErrorItems && this.$store.state.mainledgerErrorItems.length > 0) {
        this.$store.dispatch('setMainLedgerItems', this.$store.state.mainledgerErrorItems)
        this.showErrorItems = false
        this.forceRerender()
      }
    },
    async mainledgerlist () {
      try {
        await this.SaveIt()
        this.$store.dispatch('setMainLedgerStatus', 1)
        await this.$router.push({
          name: this.retPath
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async mainLedgerAdd () {
      try {
        this.$store.dispatch('seteditItem', 0)
        this.$store.dispatch('setMainLedgerStatus', 1)
      } catch (error) {
        this.text = error.data
        this.color = 'red'
        this.snackbar = true
        this.error = error.data
      }
    },
    haveAlert(type) {
      // if is book in or book out
      this.showAlert = false
      this.type = type
      const mustBe = round(parseNum(this.$store.state.mainledgerActiv.InvoiceAmount),2)
      if (this.$store.state.mainledgerActiv.BookTypeID <= 10 && mustBe !== 0) {
        const debitTotal = this.$store.state.mainledgerActiv.DebitTotal ? round(parseNum(this.$store.state.mainledgerActiv.DebitTotal),2) : 0
        const creditTotal = this.$store.state.mainledgerActiv.CreditTotal ? round(parseNum(this.$store.state.mainledgerActiv.CreditTotal),2) : 0
        if (mustBe !== debitTotal || mustBe !== creditTotal) {
          this.showAlert = true
        }
      }
      if (!this.showAlert) {
        if (this.type === 1) {
          this.type = 0
          this.mainledgerSaveClose()
        }
        if (this.type === 2) {
          this.type = 0
          this.mainledgerSaveNext()
        }
      }
    },
    haveAlert2() {
      this.showAlert = false
      if (this.type === 1) {
        this.mainledgerSaveClose()
      }
      if (this.type === 2) {
        this.mainledgerSaveNext()
      }
    },
    async SaveIt() {
      try {
        this.showDialog = true
        await this.makeSum()
        this.$store.dispatch('setMainLedgerErrorItems', [])
        // const updateSumObject = {
        //   DebitTotal: 0,
        //   CreditTotal: 0,
        //   DebitForeginTotal: 0,
        //   CreditForeginTotal: 0,
        //   DiffTotal: 0,
        //   ClosedAccount: false
        // }

        // let nrItems = 0
        // this.DebitTotal = 0
        // this.CreditTotal = 0
        // this.DebitForeginTotal = 0
        // this.CreditForeginTotal = 0

        // if (this.$store.state.mainledgerItems && this.$store.state.mainledgerItems.length > 0 ) {
        //   this.$store.state.mainledgerItems.map(item => {
        //     this.DebitTotal += parseFloat(item.Debit)
        //     this.CreditTotal += parseFloat(item.Credit)
        //     this.DebitForeginTotal += parseFloat(item.DebitForegin)
        //     this.CreditForeginTotal += parseFloat(item.DebitForegin)
        //     nrItems += 1
        //   })
        // }

        // updateSumObject.DebitTotal = this.DebitTotal
        // updateSumObject.CreditTotal = this.CreditTotal
        // updateSumObject.DiffTotal = round(this.DebitTotal,2) - round(this.CreditTotal,2)

        // if (this.DebitTotal !== 0 || this.CreditTotal !== 0) {          
        //   if (updateSumObject.DiffTotal === 0) {
        //     updateSumObject.ClosedAccount = true
        //   }
        // }
        // // if have items but totals are 0
        // if (this.DebitTotal === 0 && this.CreditTotal === 0 && nrItems > 0) {
        //   if (updateSumObject.DiffTotal === 0) {
        //     updateSumObject.ClosedAccount = true
        //   }
        // }
        // const newMainLedgerActiv = {...this.$store.state.mainledgerActiv, ...updateSumObject}
        // await this.$store.dispatch('setMainLedgerActiv', newMainLedgerActiv)

        const ClosedAccount = this.$store.state.mainledgerActiv.ClosedAccount ? this.$store.state.mainledgerActiv.ClosedAccount : false
        if (this.$store.state.mainledgerActiv) {
          await MainLedgerService.put(this.$store.state.mainledgerActiv)
          .then(async(res) => {
            let savedMsg = 'Spremljen dokument.'
            this.databaseMLID = res.data.newDoc.id
            
            let newItems = []

            this.$store.state.mainledgerItems.map(item => {
              let newObject = JSON.parse(JSON.stringify(item))
              newObject.ClosedAccount = ClosedAccount
              delete newObject.id
              newItems.push(newObject)
            })

            if (newItems.length > 0) {
              await MainLedgerDetailService.saveitems(newItems).then(async(res) => {
              this.msg = res.data
              savedMsg = savedMsg + ' Spremljene stavke dokumenta!'
              this.mldetails = (await MainLedgerDetailService.index(this.databaseMLID)).data.mainledgerDetails
              await this.$store.dispatch('setMainLedgerItems', this.mldetails ? this.mldetails : [])

              }).catch(err => {
                this.$store.dispatch('setMainLedgerErrorItems', this.$store.state.mainledgerItems ? this.$store.state.mainledgerItems : [])
                this.text = this.langC.DocumentSaveError + (err.data.message)
                this.color = 'red'
                this.snackbar = true
              })
            } else {
              this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
              this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
              this.mainQuery.MLType = this.$store.state.documentSide ? this.$store.state.documentSide : 1
              this.mainQuery.id = this.$store.state.mainledgerid ? this.$store.state.mainledgerid : 1
              await MainLedgerDetailService.olditems(this.mainQuery)
            }
            this.showDialog = false
            this.text = savedMsg
            this.color = 'green'
            this.snackbar = true
          })
          .catch(err => {
            this.$store.dispatch('setMainLedgerErrorItems', this.$store.state.mainledgerItems ? this.$store.state.mainledgerItems : [])
            this.showDialog = false
            this.text = this.langC.DocumentSaveError + (err.data)
            this.color = 'red'
            this.snackbar = true
          })
        }
        this.forceRerender()
        
      } catch (error) {
        // eslint-disable-next-line
        this.error = error.data
        this.text = this.langC.DocumentSaveError + (error.data)
        this.color = 'red'
        this.snackbar = true
      }
    },

    async mainledgerSaveClose () {
      // eslint-disable-next-line
      await this.SaveIt()
      // this.$store.dispatch('setMainLedgerStatus', 1)
      // this.$store.dispatch('setMainLedgerTotals', 0)
      // if (this.retPath) {
      //   this.$router.push({
      //     name: this.retPath
      //   })
      // } else {
      //   this.$router.push({
      //     name: 'mainledger'
      //   })
      // }
    },
    async mainledgerSaveNext () {
      try {
        await this.SaveIt()

        this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
        this.mainQuery.MLType = this.$store.state.documentSide ? this.$store.state.documentSide : 1
        this.mainQuery.id = this.$store.state.mainledgerid ? this.$store.state.mainledgerid : 1
        this.mainQuery.BookName = this.$store.state.mainledgerActiv.BookName ? this.$store.state.mainledgerActiv.BookName : 1
        this.mainQuery.BookID = this.$store.state.mainledgerActiv.BookID ? this.$store.state.mainledgerActiv.BookID : 1
        this.mainQuery.partnerId = this.$store.state.mainledgerActiv.partnerId ? this.$store.state.mainledgerActiv.partnerId : 1
        let newID = null
        await MainLedgerService.choosenext(this.mainQuery)
        .then(res => {
          newID = res.data.nextDocNumber ? res.data.nextDocNumber : null
        })
        .catch(err => {
          this.text = 'Error ' + err.data
          this.color = 'red'
          this.snackbar = true
        })
        //const newID = (await MainLedgerService.choosenext(this.mainQuery)).data.nextDocNumber
        if (newID) {

          await this.$store.dispatch('setMainLedgerTotals', 0)
          await this.$store.dispatch('seteditItem', 0)
          this.$store.dispatch('setretPath', 'mainledger')
          await this.$store.dispatch('setMainLedgerid', newID)
          const locDocument = await MainLedgerService.show(newID)
          this.mainledger = locDocument.data.mainLedger

          this.mldetails = (await MainLedgerDetailService.index(newID)).data.mainledgerDetails
          await this.$store.dispatch('setMainLedgerActiv', this.mainledger ? this.mainledger : {})
          await this.$store.dispatch('setMainLedgerItems', this.mldetails ? this.mldetails : [])
          await this.makeSum()
          this.$router.push({
            name: 'mainledgerview'
          })
        } else {
          this.text = this.langC.LastOne
          this.color = 'red'
          this.snackbar = true
        }

      } catch (error) {
          this.text = error.data
          this.color = 'red'
          this.snackbar = true
      }
    },
    async makeSum() {
      const updateSumObject = {
        DebitTotal: 0,
        CreditTotal: 0,
        DebitForeginTotal: 0,
        CreditForeginTotal: 0,
        DiffTotal: 0,
        ClosedAccount: false
      }
      let status = false
      let nrItems = 0
      if (this.$store.state.mainledgerItems) {
        this.$store.state.mainledgerItems.map(mldetail => {
          updateSumObject.DebitTotal += parseNum(mldetail.Debit, 2)
          updateSumObject.CreditTotal += parseNum(mldetail.Credit, 2)
          updateSumObject.DebitForeginTotal += parseNum(mldetail.DebitForegin, 2)
          updateSumObject.CreditForeginTotal += parseNum(mldetail.DebitForegin, 2)
          nrItems += 1
        })
      }
      // if debit and credit are diff than 0, then check if they are same 
      // if they are same then account is OK, closed, if they are diff, then
      // account is still not same ...
      const debitTotal = updateSumObject.DebitTotal ? round(Math.abs(updateSumObject.DebitTotal),2) : 0
      const creditTotal = updateSumObject.CreditTotal ? round(Math.abs(updateSumObject.CreditTotal),2) : 0
      const newItems = []
      if (debitTotal !== 0 || creditTotal !== 0) {
        updateSumObject.DiffTotal = round(debitTotal,2) - round(creditTotal,2)
        if (updateSumObject.DiffTotal === 0) {
          updateSumObject.ClosedAccount = true
          status = true
        }
      }
      // if have items but totals are 0
      if (debitTotal === 0 && creditTotal === 0 && nrItems > 0) {
        updateSumObject.DiffTotal = round(debitTotal,2) - round(creditTotal,2)
        if (updateSumObject.DiffTotal === 0) {
          updateSumObject.ClosedAccount = true
          status = true
        }
      }
      const newMainLedgerActiv = {...this.$store.state.mainledgerActiv, ...updateSumObject}
      if (this.$store.state.mainledgerItems) {
        this.$store.state.mainledgerItems.map(mldetail => {
          const updateStatus = {
            ClosedAccount: status
          }
          const newItem = {...mldetail, ...updateStatus}
          newItems.push(newItem)
        })
      }
      await this.$store.dispatch('setMainLedgerActiv', newMainLedgerActiv)
    },
    async mainledgerprint () {
       this.card.documentName = this.$store.state.documentActiv
       this.card.documentNameLoc = this.$store.state.documentActivLoc
       this.card.bookTypeSelected = this.$store.state.bookTypeSelected ? this.$store.state.bookTypeSelected : {}
       this.card.head = this.$store.state.mainledgerActiv ? this.$store.state.mainledgerActiv : {}
       this.card.items = this.$store.state.mainledgerItems ?this.$store.state.mainledgerItems : []
       this.$refs.prnt.print()
    },
    async mainledgerDelete () {
      try {
        await this.$router.push({
          name: 'mainledgerdelete'
        })
      } catch (err) {
        this.text = err.data
        this.color = 'red'
        this.snackbar = true
      }
    },
    choosedAcc (choosedAcc) {
      if (choosedAcc) {
        const result = this.account4s.filter(element => element.account4 === choosedAcc)
        if (result[0]) {
          const res = result[0]
          this.defaultItem.account1 = res.account1
          this.defaultItem.account2 = res.account2
          this.defaultItem.account3 = res.account3
          this.defaultItem.account4 = res.account4
          this.defaultItem.account4id = res.id
          this.defaultItem.account4Description = res.account4Description
          this.defaultItem.account4Description2 = res.account4Description2
        } else {
          this.text = 'Error! Entered account not exist' 
          this.color = 'red'
          this.snackbar = true
        }
      }        
    },
    async finishBS() {
        this.defaultItem = defMainLedgerDetail()
        this.defaultItem = {...this.defaultItem, ...defMainLedgerDetailFill()}
        //this.mainQuery = {...defMainQuery()}
        const newId = this.getRndInteger(1000000000, 9000000000)
        this.defaultItem.id = newId

        //const bankAccount = (await BankAccountService.isdefault(this.mainQuery)).data.bankAccount[0]
        const bankAccount = this.$store.state.bankActiv ? this.$store.state.bankActiv : null
        if (bankAccount.LedgerAccount){
          this.choosedAcc(bankAccount.LedgerAccount)
        }

        // if have data from bank statement
        this.defaultItem.Credit = this.$store.state.mainledgerActiv.bankStatmentDebit ? parseFloat(this.$store.state.mainledgerActiv.bankStatmentDebit) : null
        this.defaultItem.Debit = this.$store.state.mainledgerActiv.bankStatmentCredit ? parseFloat(this.$store.state.mainledgerActiv.bankStatmentCredit) : null

        const newItem = this.defaultItem
        this.activItems = this.$store.state.mainledgerItems ? this.$store.state.mainledgerItems : []
        this.activItems.push(newItem)
        if (this.activItems) {
          this.$store.dispatch('setMainLedgerItems', this.activItems)
        } else {
          this.$store.dispatch('setMainLedgerItems', [])
        }
        const updateSumObject = {
          DebitTotal: 0,
          CreditTotal: 0,
          DebitForeginTotal: 0,
          CreditForeginTotal: 0,
          DiffTotal: 0,
          ClosedAccount: false
        }
        if (this.$store.state.mainledgerItems) {
          this.$store.state.mainledgerItems.map(mldetail => {
            updateSumObject.DebitTotal += parseFloat(mldetail.Debit)
            updateSumObject.CreditTotal += parseFloat(mldetail.Credit)
            updateSumObject.DebitForeginTotal += parseFloat(mldetail.DebitForegin)
            updateSumObject.CreditForeginTotal += parseFloat(mldetail.DebitForegin)
          })
        }
        // if debit and credit are diff than 0, then check if they are same 
        // if they are same then account is OK, closed, if they are diff, then
        // account is still not same ...
        const debitTotal = updateSumObject.DebitTotal ? Math.abs(updateSumObject.DebitTotal) : 0
        const creditTotal = updateSumObject.CreditTotal ? Math.abs(updateSumObject.CreditTotal) : 0
        if (debitTotal !== 0 || creditTotal !== 0) {
          updateSumObject.DiffTotal = this.round(debitTotal, 2) - this.round(creditTotal, 2)
          if (updateSumObject.DiffTotal === 0) {
            updateSumObject.ClosedAccount = true
          }
        }
        const newMainLedgerActiv = {...this.$store.state.mainledgerActiv, ...updateSumObject}
        this.$store.dispatch('setMainLedgerActiv', newMainLedgerActiv)
        this.forceRerender()
      }
  },
  computed: {
    ...mapGetters({
      newItems: 'NeedMainLedgerDetailItems',
      newTikTok: 'NeedTikTok'
    }),
  },
  watch: {
    'newItems' () {
      this.forceRerender()
    },
    'newTikTok' () {
      this.forceRerender()
    }
  },
  components: {
    MainLedgerDetailList,
    MainLedgerDetailListFast,
    MainLedgerView2,
    MainLedgerView3,
    MainLedgerView4,
    MainLedgerDetailBankStatementCRUD,
    MainLedgerViewTotal,
    MainLedgerPrintCurrent,
    JustWait
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
