<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs12 sm12 m12 lg12 ex12>
        <v-row no-gutters>
          <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8" class="text-left">
          </v-col>

          <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
          </v-col>
          <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
            <v-btn
              class="primary"
              @click="exportExcel">
              Excel
            </v-btn>   
          </v-col>
          <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
            <v-btn
              class="primary"
              @click="print">
              Print
            </v-btn>
          </v-col>
        </v-row>


        <div id="printArea">

              <v-row v-for="item in items" :key="item.id" no-gutters>
               <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
               <v-row  v-if="item.Head" no-gutters>
                    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                      <br />
                    </v-col>
                  </v-row>
                 <v-row  v-if="item.Head" no-gutters>
                    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                      <MainLedgerCardHead :head="item" :type="type"/>
                    </v-col>
                  </v-row>
                  <v-row  v-if="item.Head" no-gutters>
                    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                      <MainLedgerCard4Head :type="type"/>
                    </v-col>
                  </v-row>
                  <v-row  v-if="item.Footer" no-gutters>
                    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                      <MainLedgerCard4Footer :total1="item.Total1" :total2="item.Total2" :total5="item.Total5" :showEUR="showEUR"/>
                    </v-col>
                  </v-row>
                   <v-row  v-if="!item.Head && !item.Footer" no-gutters>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left">
                        {{ item.Line }}. {{ item.BookName !== 'BS' ? item.BookName : 'Izv.' }}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left">
                       {{ parseInt(item.BookID) }}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left">
                        {{ localDate(item.BookingDate) }}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" style="font-size: 12px">
                        {{ item.InvoiceNumber }}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
                        {{ formatPrice(parseNum2(item.Debit) / exRate, 2) }}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
                        {{ formatPrice(parseNum2(item.Credit) / exRate, 2) }}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
                        {{ formatPrice(parseNum2(item.Total5) / exRate, 2) }}
                      </v-col>
                      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="text-center" style="font-size: 12px">
                        {{ item.Description ? item.Description.substr(0,25) : '' }}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" style="font-size: 12px" v-if="type == 4">
                        {{ item.partnerName ? item.partnerName.substr(0,25) : '' }}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" v-if="type == 4">
                        {{ item.TypeName}}
                      </v-col>
                       <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" v-if="type == 6">
                        {{ localDate(item.InvoiceDate) ? localDate(item.InvoiceDate) : '' }}
                      </v-col>
                       <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" v-if="type == 6">
                        {{ localDate(item.InvoicePaymentDeadlineDate) ? localDate(item.InvoicePaymentDeadlineDate) : '' }}
                      </v-col>
                      <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" style="font-size: 12px">
                         {{ item.invoiceInNumber > 0 ? 'URA-' + item.invoiceInNumber : '' }}
                         {{ item.invoiceOutNumber > 0 ? 'IRA-' + item.invoiceOutNumber : '' }}
                      </v-col>
                  </v-row>

                </v-col>
            </v-row>
            
        </div>
      </v-flex>
       <MainLedgerPrintCard4 ref="prnt" :card="items" :type="type" :exRate="exRate"/>
    </v-layout>
  </v-container>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import MainLedgerPrintCard4 from './MainLedgerPrintCard4'
import MainLedgerCardHead from './MainLedgerCardHead'
import MainLedgerCard4Head from './MainLedgerCard4Head'
import MainLedgerCard4Footer from './MainLedgerCard4Footer'
import { round, parseNum } from '@/snippets/allSnippets'
import xlsx from 'json-as-xlsx'
import dayjs from 'dayjs'


export default {
  name: 'mainledgercard4',
  props: ['items', 'head', 'showEUR'],
  data () {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      comp: {},
      error: null,
      localError: false,
      newUser: {},
      size: 'sm',
      havePath: '',
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      pItems: [],
      card: {},
      type: 0,
      cardNr: 0,
      exRate: 1
    }
  },
  components: {
    MainLedgerPrintCard4,
    MainLedgerCardHead,
    MainLedgerCard4Head,
    MainLedgerCard4Footer
  },
  async mounted () {
    console.log('kartica 4')
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    this.exRate = this.$store.state.defExRate ? this.$store.state.defExRate : 1

    if (!this.showEUR) {
      this.exRate = 1
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    this.type = this.head.type ? this.head.type : 0
    // this.Total1 = 0
    // this.Total2 = 0
    // this.Total3 = 0
    // this.Total4 = 0
    // let itLine = 0
    // if (this.items) {
    //   this.items.map(item => {
    //     itLine += 1
    //     this.Total1 += parseFloat(item.Debit)
    //     this.Total2 += parseFloat(item.Credit)
    //     this.Total3 += parseFloat(item.DebitForegin)
    //     this.Total4 += parseFloat(item.CreditForegin)
    //     const bookName = item.BookName !== 'BS' ? item.BookName : 'Izv.' + parseInt(item.BookID)
    //     const object2 = {
    //       itBookName: itLine + '. ' + bookName,
    //       CreditDebit: this.Total1 - this.Total2
    //     }
    //     const object3 = {...item, ...object2}
    //     this.pItems.push(object3)
    //   })
    // }
    // this.Total5 = this.Total1 - this.Total2
    // this.card = {...this.head}
    // this.card.items = this.pItems
    // //this.card = {...this.card, ...this.lastOne}
    // this.card.Total1 = this.Total1
    // this.card.Total2 = this.Total2
    // this.card.Total5 = this.Total5

  },
  computed: {

  },
  methods: {
    print(){
      this.$refs.prnt.print()
    },
    round2(param) {
      return round(param)
    },
    parseNum2(param) {
      return parseNum(param)
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    close () {
      this.show = false
    },
    exportExcel() {
      const lines = []
      const f1 ={}
      f1.Konto = this.comp.CompanyNameShort
      lines.push(f1)
      const f2 ={}
      f2.Konto = this.comp.CompanyStreet + ' ' + this.comp.CompanyBuildingNameNumber
      lines.push(f2)
      const f3 ={}
      f3.Konto = this.comp.CompanyPostcode + ' ' + this.comp.CompanyCity
      lines.push(f3)
      const f4 ={}
      f4.Konto = 'OIB:' + this.comp.CompanyVATID 
      lines.push(f4)

      const f5 ={}
      f5.Konto = 'Kartice od:' + (this.head.docFromDate ? this.localDate(this.head.docFromDate) : '') + ' do:' + (this.head.docToDate ? this.localDate(this.head.docToDate) : '')
      lines.push(f5)

      const f6 ={}
      f6.Konto = 'Konto od:' + this.head.fromAccount + ' do:' +this.head.toAccount
      lines.push(f6)

      this.items.forEach(it => {
        const line = {}
        line.Konto = ''
        line.KontoName = ''
        if (it.Head) {
          const h = {}
          h.Konto = it.account4
          h.KontoName = it.account4Description
          h.Line = 'Rb'
          h.Doc = 'Dokument'
          h.BookID = 'Broj'
          h.BookingDate = 'Datum knjiženja'
          h.InvoiceNumber ='Dokument broj'
          h.Debit = 'Duguje'
          h.Credit = 'Potražuje'
          h.Saldo = 'Saldo'
          h.Description = 'Opis'
          h.PartnerName = 'Partner'
          h.TypeName = 'Vrsta'
          h.InvoiceDate = 'Datum'
          h.InvoicePaymentDeadlineDate = 'Rok plaćanja'
          h.InvoiceInNumber = 'Broj URE'
          h.invoiceOutNumber = 'Broj IRE'
          lines.push(h)
        }
        if (it.Footer) {
          const f ={}
          f.Konto = it.account4
          f.KontoName = it.account4Description
          f.Line = ''
          f.Doc = ''
          f.BookID = ''
          f.BookingDate = '==========='
          f.InvoiceNumber ='UKUPNO:'
          f.Debit = this.formatPrice(parseNum(it.Total1), 2)
          f.Credit = this.formatPrice(parseNum(it.Total2) , 2)
          f.Saldo = this.formatPrice(parseNum(it.Total5), 2)
          f.Description = ''
          f.PartnerName = ''
          f.TypeName = ''
          f.InvoiceDate = ''
          f.InvoicePaymentDeadlineDate = ''
          f.InvoiceInNumber = ''
          f.invoiceOutNumber = ''
          lines.push(f)
        }
        if (!it.Footer && !it.Head) {
          line.Line = it.Line
          line.Doc = it.BookName !== 'BS' ? it.BookName : 'Izv.'
          line.BookID = it.BookID
          line.BookingDate = this.localDate(it.BookingDate)
          line.InvoiceNumber = it.InvoiceNumber
          line.Debit = this.formatPrice(parseNum(it.Debit), 2)
          line.Credit = this.formatPrice(parseNum(it.Credit) , 2)
          line.Saldo = this.formatPrice(parseNum(it.Total5), 2)
          line.Description = it.Description ? it.Description.substr(0,35) : ''
          line.PartnerName = it.partnerName ? it.partnerName.substr(0,35) : ''
          line.TypeName = it.TypeName
          line.InvoiceDate = this.localDate(it.InvoiceDate) ? this.localDate(it.InvoiceDate) : ''
          line.InvoicePaymentDeadlineDate = this.localDate(it.InvoicePaymentDeadlineDate) ? this.localDate(it.InvoicePaymentDeadlineDate) : ''
          line.InvoiceInNumber = it.invoiceInNumber > 0 ? 'URA-' + it.invoiceInNumber : '' 
          line.invoiceOutNumber = it.invoiceOutNumber > 0 ? 'IRA-' + it.invoiceOutNumber : ''
        }
        lines.push(line)
      });

      const companyName = this.$store.state.companyActiv && this.$store.state.companyActiv.CompanyNameDatabase ? this.$store.state.companyActiv.CompanyNameDatabase : ''
      const currDate = dayjs().format('DD_MM_YYYY_HH_MM')
      const sheetNm = 'Cards_' + this.$store.state.businessYear + '_' + currDate
      let data = [
      {
        sheet: sheetNm,
        columns: [
          {label: 'Konto', value: 'Konto'},
          {label: 'KontoNaziv', value: 'KontoName'},
          {label: 'Broj', value: 'BookID'},
          {label: 'Datum knjiž.', value: 'BookingDate'},
          {label: 'Broj dokumenta', value: 'InvoiceNumber'},
          {label: 'Duguje', value: 'Debit', format: "€#,##0.00" },
          {label: 'Potražuje', value: 'Credit', format: "€#,##0.00" },
          {label: 'Saldo', value: 'Saldo', format: "€#,##0.00" },
          {label: 'Opis',value: 'Description'},
          {label: 'Partner' , value: 'PartnerName'},
          {label: 'Vrsta', value: 'TypeName'},
          {label: 'Datum', value: 'InvoiceDate', format: 'dd.MM.yyyy'},
          {label: 'Rok plaćanja' , value: 'InvoicePaymentDeadlineDate'},
          {label: 'Broj URE', value: 'InvoiceInNumber'},
          {label: 'Broj IRE', value: 'invoiceOutNumber'},
        ],
        content: lines
      },
    ]

      const settings = {
        fileName: companyName + '_Kartice_' + currDate, // Name of the spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
      }
      xlsx( data, settings)
    }
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
