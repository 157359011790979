import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('salarietaxfree', {
      params: query
    })
  },
  show (salarietaxfreeid) {
    return Api().get(`salarietaxfree/${salarietaxfreeid}`)
  },
  post (newsalarietaxfree) {
    return Api().post('salarietaxfree', newsalarietaxfree)
  },
  put (salarietaxfree) {
    return Api().put(`salarietaxfree/${salarietaxfree.id}`, salarietaxfree)
  },
  delete (salarietaxfreeid) {
    return Api().delete(`salarietaxfree/${salarietaxfreeid}`)
  },
  choose (query) {
    return Api().get('salarietaxfree/choose', { params: query })
  }
}