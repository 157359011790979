<template>
  <div class="formprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import {moneyFormat} from '@/snippets/allSnippets'
import documentLocalName from '@/snippets/documentLocalName'
import dayjs from 'dayjs'

export default {
  name: 'salesprint',
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0,
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      Total6: 0,
      Total7: 0,
      Total8: 0,
      ana: {},
      documentName: ''
    }
  },
  async mounted () {
    
  },
  methods: {
    async print () {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr
      }
      this.comp = await this.$store.state.companyActiv
      this.ana = await this.$store.state.printObj
 
      this.initDocument()
      this.addHeaderNoLogo()
      this.addLine()
      this.addHeader()
      this.addLine()
      this.addItems(1)
      this.addLine()
      this.addTotal()
      this.addEmptyRow()
      this.addSignature()
      this.docDefinition.content = this.content
      await pdfMake.createPdf(this.docDefinition).open()

     
    },
    localMoney (money) {
      return moneyFormat(money)
    },
    localName (name) {
      return documentLocalName(name)
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 35, 20, 20, 20 ]
      this.content = []
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 520,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {      
                // % width
                width: '30%',
                text: [         
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                ]   
              },
              {
                // % width
                width: '45%',
                text: [
                  { text: this.ana.documentName + '\n', fontSize: 14, bold: true },
                  { text: (this.ana.docFromDate ? 'Od:' + this.ana.docFromDate : '') + (this.ana.docToDate ? ', do:' + this.ana.docToDate : '') + '\n', fontSize: 7},
                  { text: (this.ana.GroupName ? 'Grupa:' + this.ana.GroupName : '') + '\n', fontSize: 7}
                ]   
              },
              {      
                // % width
                width: '20%',
                text: [         
                  { text: 'OIB:' + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' ') + ' \n', fontSize: 8},        
                  { text: 'Datum ispisa: ' + dayjs().format('DD.MM.YYYY, HH:mm'), fontSize: 7}
                ]   
              }
             
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeaderTop () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '30%',
                text: [
                  { text:' \n', fontSize: 10, bold: true },
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text:  this.ana.head1 ? this.ana.head1 + '\n' : '\n', fontSize:12, bold: true},
                  { text:  'Vrsta dokumenta: ' + this.ana.documentName + '\n', fontSize: 10, bold: true },
                  { text:  this.ana.head2 ? this.ana.head2 + '\n' : '\n', fontSize:10, bold: true},
                  { text:  this.ana.head4 ? this.ana.head4 + '\n' : '\n', fontSize:10, bold: true},
                  { text:  this.ana.head5 ? this.ana.head5 + '\n' : '\n', fontSize:10, bold: true}
                ]   
              },

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeader () {
        const docPart = {
        columns: [
               {
                width: '5%',
                fontSize: 8,
                alignment: 'left',
                text: 'Rb'
              },
              {
                width: '29%',
                fontSize: 8,
                alignment: 'left',
                text: 'Grupa'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'center',
                text: 'Uk.zad.kom'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'center',
                text: 'Uk.raz.kom'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'center',
                text: 'Stanje kom'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'center',
                text: 'Uk.zad.€'+ '\n' + 'bez PDVa'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'center',
                text: 'Uk.raz.€'+ '\n'+ 'bez PDVa'
              },
                            {
                width: '10%',
                fontSize: 8,
                alignment: 'center',
                text: 'Stanje €' + '\n' + 'bez PDVa'
              },
            ]
          }
        this.content.push(docPart)
    },

    addItems() {
      if (this.ana.items) {
        this.Total1 = 0
        this.Total2 = 0
        this.Total3 = 0
        this.Total4 = 0
        this.Total5 = 0
        this.Total6 = 0
         let id = 0
          this.ana.items.map(item => {
            id += 1
            this.Total1 += parseFloat(item.grTotalIn)
            this.Total2 += parseFloat(item.grTotalOut)
            this.Total3 += parseFloat(item.grTotalSum)
            this.Total4 += parseFloat(item.grTotalInM)
            this.Total5 += parseFloat(item.grTotalOutM)
            this.Total6 += parseFloat(item.grTotalSumM)
            const docPart = {
            columns: [
                  {
                    width: '5%',
                    fontSize: 7,
                    alignment: 'left',
                    text: id + '. \n'
                  },
                  {
                    width: '29%',
                    fontSize: 7,
                    alignment: 'left',
                    text: item.GroupName + '\n'
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.grTotalIn) + '\n'
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.grTotalOut) + '\n'
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.grTotalSum) + '\n'
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.grTotalInM) + '\n'
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.grTotalOutM) + '\n'
                  },
                  {
                    width: '10%',
                    fontSize: 8,
                    alignment: 'right',
                    text: this.localMoney(item.grTotalSumM) + '\n'
                  },
                ]
              }
            this.content.push(docPart)
          })
      }
    },

    addTotal() {

          const docPart = {
          columns: [
              {
                width: '5%',
                fontSize: 8,
                alignment: 'left',
                text: ' \n'
              },

              {
                width: '29%',
                fontSize: 8,
                alignment: 'left',
                text:  '\n'
              },

              {
                width: '10%',
                fontSize: 8,
                alignment: 'right',
                text: this.localMoney(this.Total1) + '\n'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'right',
                text: this.localMoney(this.Total2) + '\n'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'right',
                text: this.localMoney(this.Total3) + '\n'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'right',
                text: this.localMoney(this.Total4) + '\n'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'right',
                text: this.localMoney(this.Total5) + '\n'
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'right',
                text: this.localMoney(this.Total6) + '\n'
              },
            ]
          }
          this.content.push(docPart)
      
    },

    addSignature () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: ' \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text:  '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    }

  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>