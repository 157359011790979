<template>
  <div>

  </div>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'
// import { parseNum } from '@/snippets/allSnippets'

export default {
  name: 'documentprintitemcard',
  props: [],
  data () {
    return {
      msg: '',
      documentName: '',
      documentName2: '',
      document: {},
      havePath: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locDocument: {},
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      comp: {},
      mainQuery: {},
      ana: {},
      PrintDate: null
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = {...langEn}
      this.langC = {...commEn}
    }
    if (this.$store.state.language === 'HR') {
      this.lang = {...langHr}
      this.langC = {...commHr}
    }

  },
  methods: {
    async print () {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.BusinessYear = this.$store.state.businessYear
      }
      this.printDoc()
    },

    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    printDoc () {
      this.comp = this.$store.state.companyActiv
      this.ana = this.$store.state.printObj ? this.$store.state.printObj : {}
      this.PrintDate = dayjs().format('DD.MM.YYYY, H:mm')

      this.initDocument()
      this.addHead()
      this.addLine()
      this.addItemsHeader()
      this.addLine()
      this.addItems()
      this.addLine()
      this.addTotalInLine()
 
      
     // this.addComment()      
      const noSignature = this.comp.showFooter1 ? true : false
      if (!noSignature) {
         this.addSignature()
      }
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'landscape'
      this.docDefinition.pageMargins = [ 20, 20, 20, 40 ]
      this.content = []
    },
  
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addEmptyRow1 () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: '.' + ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ' , fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 780,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addGroupLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 50,
                y1: 0,
                x2: 780,
                y2: 0,
                dash: {length: 3, space: 2},
                lineWidth: 0.5
              }
            ]
          }
      this.content.push(docPart)
    },

    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '55%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 18, bold: true },
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.Companyemail ? 'email:' +  this.comp.Companyemail + ' \n' : ' ') , fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHead() {
      const docPart = {
          columns: [
            {
              // % width
              width: '35%',
              text: [
                { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.Companyemail ? 'email:' +  this.comp.Companyemail + ' \n' : ' ') , fontSize: 8 },
                { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 },
                { text: ', ' + this.langC.PrintDate + (this.PrintDate ? this.PrintDate : ' '), fontSize: 8 }
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '55%',
              text: [
                { text: this.ana.head1 + ' \n', fontSize: 18, bold: true },
                { text: this.ana.head2 + ' \n', fontSize: 8, bold: true },
                { text: this.ana.head3 + ' \n', fontSize: 8, bold: true },
                { text: this.ana.head4 + ' \n', fontSize: 8, bold: true },
                { text: this.ana.head5 + ' \n', fontSize: 8, bold: true },
              ]
            },
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },
    addTotalInLine() {
        const docPart = {
          columns: [
            {
              // auto-sized columns have their widths based on their content
              width: '55%',
              text: [
                { text: this.ana.Total1 + ' \n', fontSize: 8, bold: true },
                { text: (this.ana.Total2 ? this.ana.Total2 + ' \n' : '')  , fontSize: 8, bold: true },
                { text: (this.ana.Total3 ? this.ana.Total3 + ' \n' : '') , fontSize: 8, bold: true },
                { text: (this.ana.Total4 ? this.ana.Total4 + ' \n' : '') , fontSize: 8, bold: true },
                { text: (this.ana.Total5 ? this.ana.Total5 + ' \n' : '') , fontSize: 8, bold: true },
                { text: (this.ana.Total6 ? this.ana.Total6 + ' \n' : '') , fontSize: 8, bold: true },
              ]
            },
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },
    addItemsHeader () {
      const docPart = {
          columns: [
            {
              width: '3%',
              text: { text: this.lang.DocPrintOrder + '\n', fontSize: 8 }
            },
            {
              width: '7%',
              text: { text: this.lang.documentDate1 + '\n', fontSize: 8, alignment: 'left' }
            },
            {
              width: '5%',
              text: { text: this.lang.documentId + '\n', fontSize: 8, alignment: 'left' }
            },            
            {
              width: '7%',
              text: { text: this.langC.DocumentName + '\n', fontSize: 8, alignment: 'left' }
            },
            {
              width: '18%',
              text: {text: this.lang.partnerName+ '\n', fontSize: 8, alignment: 'left' }
            },
            {
              width: '6%',
              text: {text: this.lang.ItemPcsPlus+ '\n', fontSize: 8, alignment: 'right' }
            },
            {
              width: '6%',
              text: {text: this.lang.ItemPcsMinus+ '\n', fontSize: 8, alignment: 'right' }
            },
            {
              width: '6%',
              text: {text: this.lang.itemState+ '\n', fontSize: 8, alignment: 'right' }
            },
            {
              width: '6%',
              text: { text: this.lang.ItemSuppBasePrice + '\n', fontSize: 8, alignment: 'right' }
            },
            {
              width: '4%',
              text: { text: this.lang.ItemSuppDiscount + '\n', fontSize: 8, alignment: 'right' }
            },
            {
              width: '6%',
              text: { text: this.lang.ItemSuppPrice + '\n', fontSize: 8, alignment: 'right' }
            },
            {
              width: '6%',
              text: { text: this.lang.ItemMargin + '\n', fontSize: 8, alignment: 'right' }
            },
            {
              width: '6%',
              text: { text: this.lang.ItemPrice + '\n', fontSize: 8, alignment: 'right' }
            },
            {
              width: '6%',
              text: { text: this.lang.ItemPriceWithVAT + '\n', fontSize: 8, alignment: 'right' }
            },
          ]
        }
      this.content.push(docPart)
    },

    addItems() {
          this.order = 0
            if (this.ana.items) {
              this.ana.items.map(item => {
                this.order += 1

                  const docPart = {
                  columns: [
                      {
                        width: '3%',
                        fontSize: 8,
                        alignment: 'left',
                        text: this.order
                      },
                      {
                        width: '7%',
                        fontSize: 8,
                        alignment: 'left',
                        text: dayjs(item.documentDate).format('DD.MM.YYYY')
                      },
                      {
                        width: '5%',
                        fontSize: 8,
                        alignment: 'left',
                        text: item.documentId 
                      },
                      
                      {
                        width: '7%',
                        fontSize: 8,
                        alignment: 'left',
                        text: item.documentName
                      },
                      {
                        width: '18%',
                        fontSize: 8,
                        alignment: 'left',
                        text: item.partnerNameDatabase
                      },
                      {
                        width: '6%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.itemPcsPlus)
                      },
                      {
                        width: '6%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.itemPcsMinus)
                      },
                      {
                        width: '6%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.itemState)
                      },
                      {
                        width: '6%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.itemSuppBasePrice)
                      },
                      {
                        width: '4%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.itemSuppDiscount)
                      },
                      {
                        width: '6%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.itemSuppPrice)
                      },
                      {
                        width: '6%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.itemMargin)
                      },
                      {
                        width: '6%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.itemPrice)
                      },
                      {
                        width: '6%',
                        fontSize: 8,
                        alignment: 'right',
                        text: this.formatPrice(item.itemPriceWithVAT)
                      }
                    ]
                  }
                  this.content.push(docPart)
                

            })
          }
    },

    addTotal () {
      const docPart = {
          columns: [
            {
              width: '50%',
              fontSize: 8,
              text: ''
            },
            {
              width: '9%',
              fontSize: 8,
              text: ' '
            },
            {
              width: '18%',
              fontSize: 8,
              text: ''
            },
            {
              width: '7%',
              fontSize: 8,
              text: ' '
            },
            {
              width: '7%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total1) + '\n', fontSize: 8
            },
            {
              width: '7%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total4) + '\n', fontSize: 8
            },
            {
              width: '7%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total2) + '\n', fontSize: 8
            },
            {
              width: '7%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total3) + '\n', fontSize: 8
            },
            {
              width: '7%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total5) + '\n', fontSize: 8
            },
            {
              width: '7%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total6) + '\n', fontSize: 8
            }
          ]
        }
      this.content.push(docPart)

      const docPart2 = {
          columns: [
            {
              width: '5%',
              fontSize: 8,
              text: ''
            },
            {
              width: '9%',
              fontSize: 8,
              text: ' '
            },
            {
              width: '18%',
              fontSize: 8,
              text: ''
            },
            {
              width: '7%',
              fontSize: 8,
              text: ' '
            },
            {
              width: '7%',
              alignment: 'right',
              text: 'Uk.Kol:' + this.formatPrice(this.ana.Total4+ this.ana.Total1)+ '\n', fontSize: 8
            },
            {
              width: '7%',
              alignment: 'right',
              text: '' + '\n', fontSize: 8
            },
            {
              width: '7%',
              alignment: 'right',
              text: 'Dobit dom:' + '\n', fontSize: 8
            },
            {
              width: '7%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total3 - this.ana.Total2) + '\n', fontSize: 8
            },
            {
              width: '7%',
              alignment: 'right',
              text: 'Dobit exp:' + '\n', fontSize: 8
            },
            {
              width: '7%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total6- this.ana.Total5) + '\n', fontSize: 8
            },
            {
              width: '7%',
              fontSize: 8,
              text: ' '
            },
            {
              width: '7%',
              alignment: 'right',
              text: this.formatPrice((this.ana.Total6- this.ana.Total5) + (this.ana.Total3 - this.ana.Total2)) + '\n', fontSize: 8
            }
          ]
        }
      this.content.push(docPart2)
      
    },
    addTotal10 () {

      const docPart = {
          columns: [
            {
              width: '5%',
              fontSize: 8,
              text: this.ana.Total1 + ' \n'
            },
            {
              width: '10%',
              fontSize: 8,
              text: ''
            },
            {
              width: '25%',
              fontSize: 8,
              text: ''
            },
            {
              width: '10%',
              fontSize: 8,
              text: ''
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total1) + '\n', fontSize: 8
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total2) + '\n', fontSize: 8
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total3) + '\n', fontSize: 8
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total10) + '\n', fontSize: 8
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total4) + '\n', fontSize: 8
            }
          ]
        }
      this.content.push(docPart)
      
    },

    addSignature () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                 // { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________  \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },

    addEmpty () {
      const docPart = {
        
          }
      this.content.push(docPart)
    },

  },
  computed: {
  },
  watch: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
