<template>
      <v-container fluid  class="grey lighten-5">
        <v-row no-gutters>
          <v-col sm="5">
              <h1>{{documentNameLoc}} </h1>
          </v-col>
          <v-col sm="5">
            <v-btn color="green" @click="documentlist" class="mr-1">
              {{langC.Back}}
            </v-btn>
          </v-col>
        </v-row>        

        <v-row no-gutters>
            <v-col
              sm="4"
            >
              <v-card
                class="pa-2"
                outlined
                tile
              >
              <appDocumentViewCard1></appDocumentViewCard1>
              </v-card>
            </v-col>

           <v-col
              sm="4"
            >
              <v-card
                class="pa-2"
                outlined
                tile
              >
                <appDocumentViewCard2></appDocumentViewCard2>
              </v-card>
            </v-col>

            <v-col
              sm="4"
            >
              <v-card
                class="pa-2"
                outlined
                tile
              >
                <appDocumentViewCard3></appDocumentViewCard3>
              </v-card>
            </v-col>
          </v-row>

        <v-row>
            <v-flex xs3 class="py-3 px-4">
               {{lang.CopyThisDocument}}
            </v-flex>
            <v-flex xs4 class="py-0 px-2">                  
                  <v-autocomplete
                  :items="items"
                  v-model="selectDest1"
                  :label="lang.CopyDestination"
                  item-text="title"
                  item-value="doc"
                  return-object
                  persistent-hint
                ></v-autocomplete>
            </v-flex>
            <v-flex xs1 class="py-0 px-2">
              <v-select
                :items="haveYears"
                v-model="selectedYear"
                :label="langC.BusinessYear"
                item-text="BusinessYear"
                item-value="BusinessYear"
                return-object
                persistent-hint
              ></v-select>
            </v-flex>
            <v-flex xs4 class="py-0 px-2">
                <v-btn color="primary" @click="documentCopyTo">
                  {{documentNameLoc}} {{lang.CopyDestination2}}
                </v-btn>
            </v-flex>

          </v-row> 
           <v-row>
            <v-flex xs4 class="py-3 px-4">
     
            </v-flex>
            <v-flex xs4 class="py-0 px-2">                  

            </v-flex>
            <v-flex xs2 class="py-0 px-2">
                <v-btn color="primary" @click="createSerials">
                  {{lang.createSerials}}
                </v-btn>
            </v-flex>
            <v-flex xs2 class="py-0 px-2">
                <v-btn color="primary" @click="saveSerials" v-if="haveSerials">
                    Save
                  </v-btn>
            </v-flex>

          </v-row>
          <v-row>
            <v-flex xs4 class="py-3 px-4">
     
            </v-flex>
            <v-flex xs4 class="py-0 px-2">                  

            </v-flex>
            <v-flex xs2 class="py-0 px-2">

            </v-flex>
            <v-flex xs2 class="py-0 px-2">
              <v-btn text color="primary" @click="deleteStickers()">
                {{lang.clearStickers}}
              </v-btn>
            </v-flex>

          </v-row>
          <v-row>
            <v-flex xs4 class="py-3 px-4">
     
            </v-flex>
            <v-flex xs4 class="py-0 px-2">                  

            </v-flex>
            <v-flex xs2 class="py-0 px-2">
              <v-btn color="primary" @click="createStickers(1)">
                {{lang.createStickersAll}}
              </v-btn>
            </v-flex>
            <v-flex xs2 class="py-0 px-2">
              <v-btn color="primary" @click="createStickers(2)">
                {{lang.createStickersOne}}
              </v-btn>
            </v-flex>

          </v-row>
          <div v-if="haveSerials">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <SerialsListShort :key="key" :serials="serials" />
              </v-col>
            </v-row>
          </div>
          <div v-if="haveStickers">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <StickersCRUD :key="key" />
              </v-col>
            </v-row>
          </div>
      <div v-if="showDialog">
      <JustWait :message="message" :showDialog="showDialog" />
    </div>

      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        bottom
        :color="color"
        >
        {{ text }}
      </v-snackbar>
   </v-container>
</template>

<script>
import DocumentService from '@/services/DocumentService'
import DocumentViewCard1 from './DocumentViewCard1'
import DocumentViewCard2 from './DocumentViewCard2'
import DocumentViewCard3 from './DocumentViewCard3'
import SerialsListShort from '../Serials/SerialsListShort'
import StickersCRUD from '../Serials/StickersCRUD'
// import langEn from './documentDescEn'
// import langHr from './documentDescHR'
import langEn from '../mainDescEN'
import langHr from '../mainDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import {defSerials, defNewSerials} from '../Serials/serialsHelper'
import {dynamicSort} from '@/snippets/allSnippets'
import SerialsService from '@/services/SerialsService'
import CompBusinessYearService from '@/services/CompBusinessYearService'
import dayjs from 'dayjs'
import JustWait from '@/snippets/JustWait'


export default {
  name: 'documentview',
  data () {
    return {
      snackbar: false,
      alert: true,
      text: '',
      timeout: 2000,
      color: 'green',
      msg: '',
      document: {
      },
      lang: {},
      langC: {},
      saving: false,
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
        documentName: '',
        documentId: 0,
        documentIdGeneral: '',
        databaseDocID: 0,
        databaseDocIdOLD: 0,
        documentDate: null,
        documentAnalysis: 1,
        documentSide: 0
      },
      docSide: 0,
      loctaskOpenDate: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      loctaskForDate: '',
      loctaskClosedDate: '',
      loctaskAlarmDate: '',
      haveRight: 4,
      documentActivData: {},
      documentNameLoc: '',
      selectDest1: {},
      selectDest2: {},
      sourceCompanyid: 0,
      sourceCompanyNameDatabase: '',
      sourceConnId: 0,
      sourceConnDate: null,
      sourceConnName: '',
      sourceDocument: {},
      itemsLoc: [],
      items: [],
      itemsEN2: [],
      pcsLoc: 0,
      newSerials: {},
      serials: [],
      allNewSerials: [],
      haveSerials: false,
      key: 1,
      mainQuery2: {},
      allNewStickers: [],
      newSticker: {},
      haveStickers: false,
      haveYears: [],
      selectedYear: {},
      showDialog: false, 
      haveDouble: false,
      message: ''
    }
  },
  async mounted () {
    if (this.$store.state.documentid) {
      try {
        if (this.$store.state.language === "EN") {
          this.lang = langEn
          this.langC = commEn;
        }
        if (this.$store.state.language === "HR") {
          this.lang = langHr
          this.langC = commHr;
        }
        this.sourceDocument = this.$store.state.documentActivHead ? this.$store.state.documentActivHead : {}

        await this.items.push(
          { title: this.lang.Inquirys, path: '/crm/document', doc: 'InquirySales', docLoc: this.lang.Inquirys, docSide: 3 },
          { title: this.lang.Quotes, path: '/crm/document', doc: 'QuoteSales', docLoc: this.lang.Quotes, docSide: 3 },
          { title: this.lang.Order, path: '/crm/document', doc: 'OrderSales', docLoc: this.lang.Order, docSide: 3 },
          { title: this.lang.DeliveryNote, path: '/crm/document', doc: 'DeliveryNoteSales', docLoc: this.lang.DeliveryNote, docSide: 2 },
          { title: this.lang.InternalDeliveryNotes, path: '/crm/document', doc: 'InternalDeliveryNotes', docLoc: this.lang.InternalDeliveryNotes, docSide: 2 },
          { title: this.lang.ServiceNotes, path: '/crm/document', doc: 'ServiceNotes', docLoc: this.lang.ServiceNotes, docSide: 2 },
          { title: this.lang.InvoicesAdvance, path: '/crm/document', doc: 'InvoiceAdvance', docLoc: this.lang.InvoicesAdvance, docSide: 3 },
          { title: this.lang.Invoices, path: '/crm/document', doc: 'InvoiceSales', docLoc: this.lang.Invoices, docSide: 3 },
          { title: this.lang.Contracts, path: '/crm/document', doc: 'ContractSales', docLoc: this.lang.Contracts, docSide: 3 },
          { title: this.lang.WorkingOrder, path: '/crm/document3', doc: 'WorkingOrder', docLoc: this.lang.Production, docSide: 3 },
          { title: this.lang.POS, path: '/crm/document/pos', doc: 'POS', docLoc: this.lang.POS, docSide: 2 },
          { title: this.lang.Requests, path: '/crm/document', doc: 'RequestPurchase', docLoc: this.lang.Inquirys, docSide: 3 },
          { title: this.lang.Inquirys, path: '/crm/document', doc: 'InquiryPurchase', docLoc: this.lang.Requests, docSide: 3 },
          { title: this.lang.Order, path: '/crm/document', doc: 'OrderPurchase', docLoc: this.lang.Order, docSide: 3 },
          { title: this.lang.CalculationsPurchase, path: '/crm/document', doc: 'ReceiptPurchase', docLoc: this.lang.CalculationsPurchase, docSide: 1 },
          { title: this.lang.Returns, path: '/crm/document', doc: 'ReturnPurchase', docLoc: this.lang.Returns, docSide: 2 },
          { title: this.lang.Inventory, path: '/crm/document', doc: 'Inventory', docLoc: this.lang.Inventory, docSide: 1 },
        )
        if (this.sourceDocument.documentName === 'InvoiceSales') {
          this.items.push(
            { title: 'Otpremnica - Radni nalog proizvedeno', path: '/crm/document', doc: 'DeliveryNoteSalesWorkingOrder', docLoc: this.lang.DeliveryNote, docSide: 2 },
          )
        }
        // await this.itemsEN2.push(
        //   { title: this.lang.Requests, path: '/crm/document', doc: 'RequestPurchase', docLoc: this.lang.Inquirys, docSide: 3 },
        //   { title: this.lang.Inquirys, path: '/crm/document', doc: 'InquiryPurchase', docLoc: this.lang.Requests, docSide: 3 },
        //   { title: this.lang.Order, path: '/crm/document', doc: 'OrderPurchase', docLoc: this.lang.Order, docSide: 3 },
        //   { title: this.lang.CalculationsPurchase, path: '/crm/document', doc: 'ReceiptPurchase', docLoc: this.lang.CalculationsPurchase, docSide: 1 },
        //   { title: this.lang.Returns, path: '/crm/document', doc: 'ReturnPurchase', docLoc: this.lang.Returns, docSide: 2 },
        //   { title: this.lang.Inventory, path: '/crm/document', doc: 'Inventory', docLoc: this.lang.Inventory, docSide: 1 },
        // )
        // again load last copy of document and write it to new one
        const dbDocument = await DocumentService.show(this.$store.state.documentid)
        this.document = dbDocument.data.document
        this.documentNameLoc = this.$store.state.documentActivLoc
        

        if (this.$store.state.stickers && this.$store.state.stickers.length > 0) {
          this.haveStickers = true
        }
        const bussYearQ = {}

        bussYearQ.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
        this.haveYears = (await CompBusinessYearService.choose(bussYearQ)).data.companyBY
        this.haveYears.sort(dynamicSort("-BusinessYear"))
        const haveYearS = this.haveYears ? this.haveYears.find(year => year.BusinessYear === this.$store.state.businessYear) : {}
        this.selectedYear = haveYearS && haveYearS.BusinessYear ? haveYearS.BusinessYear : 2022
      } catch (error) {
        // eslint-disable-next-line
        console.log('error read from datebase')
      }
    }
  },
  methods: {
    async documentlist () {
      try {
        await this.$router.push({
          name: 'document'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    deleteStickers() {
      this.$store.dispatch('setStickers', null)
      this.key += 1
    },
    createStickers(type) {

            if (this.$store.state.documentActivItems && this.$store.state.documentActivItems.length > 0) {
              this.allNewStickers = []
              if (this.$store.state.stickers && this.$store.state.stickers.length > 0) {
                this.$store.state.stickers.map(stick => {
                   this.allNewStickers.push(stick)
                })
              }

              this.$store.state.documentActivItems.map(item => {
                this.pcsLoc = 0
                if (this.$store.state.documentSide === 1) {
                  this.pcsLoc = item.itemPcsPlus ? item.itemPcsPlus : 0
                }
                if (this.$store.state.documentSide === 2) {
                  this.pcsLoc = item.itemPcsMinus ? item.itemPcsMinus : 0
                }
                if (this.$store.state.documentSide === 3) {
                  this.pcsLoc = item.itemPcsNeutral ? item.itemPcsNeutral : 0
                }

                if (this.pcsLoc !== 0) {
                  if (type === 1) {
                    let i
                    for (i = 1; i <= this.pcsLoc; i++) {
                      const newObj = {}
                      this.newSticker = {...newObj}
                      this.newSticker.documentName = this.$store.state.documentActivHead.documentIdGeneral
                      this.newSticker.documentSide = this.$store.state.documentActivHead.documentSide
                      this.newSticker.documentAnalysis = this.$store.state.documentActivHead.documentAnalysis
                      this.newSticker.documentId = parseInt(this.$store.state.documentActivHead.documentId)
                      this.newSticker.documentIdGeneral = this.$store.state.documentActivHead.documentIdGeneral
                      this.newSticker.documentDate = this.$store.state.documentActivHead.documentDate
                      this.newSticker.documentTime = this.$store.state.documentActivHead.documentTime
                      this.newSticker.partnerId = parseInt(this.$store.state.documentActivHead.partnerId)
                      this.newSticker.partnerNameDatabase = this.$store.state.documentActivHead.partnerNameDatabase
                      this.newSticker.HomeCurrency = this.$store.state.documentActivHead.HomeCurrency
                      this.newSticker.ForeignCurrency = this.$store.state.documentActivHead.ForeignCurrency
                      this.newSticker.ExRate = this.$store.state.documentActivHead.ExRate
                      this.newSticker.itemID = item.itemID
                      this.newSticker.itemName = item.itemName
                      this.newSticker.ItemNameShort = item.ItemNameShort
                      this.newSticker.ItemCode = item.ItemCode
                      this.newSticker.ItemEANCode = item.ItemEANCode
                      this.newSticker.itemGroup = item.itemGroup
                      this.newSticker.itemGroupName = item.itemGroupName
                      this.newSticker.itemDescription = item.itemDescription
                      this.newSticker.itemUnit = item.itemUnit
                      this.newSticker.itemPrice = item.itemPrice
                      this.newSticker.itemPriceWithVAT = item.itemPriceWithVAT
                      this.newSticker.itemDirectDuty1 = item.itemDirectDuty1
                      this.newSticker.ItemWarehouse = item.ItemWarehouse
                      this.newSticker.WareHouseUpdateBy = null
                      this.newSticker.FreeF5 = this.document.id
                      this.allNewStickers.push(this.newSticker)
                    } 
                  }
                  if (type === 2) {
                      const newObj = {}
                      this.newSticker = {...newObj}
                      this.newSticker.documentName = this.$store.state.documentActivHead.documentIdGeneral
                      this.newSticker.documentSide = this.$store.state.documentActivHead.documentSide
                      this.newSticker.documentAnalysis = this.$store.state.documentActivHead.documentAnalysis
                      this.newSticker.documentId = parseInt(this.$store.state.documentActivHead.documentId)
                      this.newSticker.documentIdGeneral = this.$store.state.documentActivHead.documentIdGeneral
                      this.newSticker.documentDate = this.$store.state.documentActivHead.documentDate
                      this.newSticker.documentTime = this.$store.state.documentActivHead.documentTime
                      this.newSticker.partnerId = parseInt(this.$store.state.documentActivHead.partnerId)
                      this.newSticker.partnerNameDatabase = this.$store.state.documentActivHead.partnerNameDatabase
                      this.newSticker.HomeCurrency = this.$store.state.documentActivHead.HomeCurrency
                      this.newSticker.ForeignCurrency = this.$store.state.documentActivHead.ForeignCurrency
                      this.newSticker.ExRate = this.$store.state.documentActivHead.ExRate
                      this.newSticker.itemID = item.itemID
                      this.newSticker.itemName = item.itemName
                      this.newSticker.ItemNameShort = item.ItemNameShort
                      this.newSticker.ItemCode = item.ItemCode
                      this.newSticker.ItemEANCode = item.ItemEANCode
                      this.newSticker.itemGroup = item.itemGroup
                      this.newSticker.itemGroupName = item.itemGroupName
                      this.newSticker.itemDescription = item.itemDescription
                      this.newSticker.itemUnit = item.itemUnit
                      this.newSticker.itemPrice = item.itemPrice
                      this.newSticker.itemPriceWithVAT = item.itemPriceWithVAT
                      this.newSticker.itemDirectDuty1 = item.itemDirectDuty1
                      this.newSticker.ItemWarehouse = item.ItemWarehouse
                      this.newSticker.WareHouseUpdateBy = null
                      this.newSticker.FreeF5 = this.document.id
                      this.allNewStickers.push(this.newSticker)
                  }
                  if (this.allNewStickers) {
                    this.$store.dispatch('setStickers', this.allNewStickers)
                    this.haveStickers = true
                    this.key += 1
                  }
                }
          })

          if (this.allNewStickers) {
            this.$store.dispatch('setStickers', this.allNewStickers)
            this.key += 1
          }
        }

    },
    async showSerials() {
      if (this.$store.state.companyid) {
        this.mainQuery2.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.documentActivHead.id) {
        this.mainQuery2.dbID = this.$store.state.documentActivHead.id
      }
      const {data} = await SerialsService.index(this.mainQuery2)
      if (data && data.serials !== undefined) {
        this.serials = data.serials
        this.haveSerials = true
        this.key += 1
      } else {
        this.text = 'Error! No serials for these document'
        this.color = 'red'
        this.snackbar = true
      }

    },
    async saveSerials() {
      try {
        await SerialsService.saveitems(this.serials)
        this.text = this.lang.serialsCreatedOK
        this.color = 'green'
        this.snackbar = true
        this.haveSerials = false
      } catch(err){
        // eslint-disable-next-line
        console.log(err)
        this.text = 'Error! On create serials'
        this.color = 'red'
        this.snackbar = true
      }
    },
    async createSerials() {
      await this.showSerials()
      if (this.serials.length === 0) {
        this.allNewSerials = []
        if (this.$store.state.documentActivItems && this.$store.state.documentActivItems.length > 0) {
          this.$store.state.documentActivItems.map(item => {
            this.pcsLoc = 0
            if (this.$store.state.documentSide === 1) {
              this.pcsLoc = item.itemPcsPlus ? item.itemPcsPlus : 0
            }
            if (this.$store.state.documentSide === 2) {
              this.pcsLoc = item.itemPcsMinus ? item.itemPcsMinus : 0
            }
            if (this.$store.state.documentSide === 3) {
              this.pcsLoc = item.itemPcsNeutral ? item.itemPcsNeutral : 0
            }

            if (this.pcsLoc !== 0) {
              let i
              for (i = 1; i <= this.pcsLoc; i++) {
                this.newSerials = {...defSerials(), ... defNewSerials()}
                this.newSerials.documentName = this.$store.state.documentActivHead.documentIdGeneral
                this.newSerials.documentSide = this.$store.state.documentActivHead.documentSide
                this.newSerials.documentAnalysis = this.$store.state.documentActivHead.documentAnalysis
                this.newSerials.documentId = parseInt(this.$store.state.documentActivHead.documentId)
                this.newSerials.documentIdGeneral = this.$store.state.documentActivHead.documentIdGeneral
                this.newSerials.documentDate = this.$store.state.documentActivHead.documentDate
                this.newSerials.documentTime = this.$store.state.documentActivHead.documentTime
                this.newSerials.partnerId = parseInt(this.$store.state.documentActivHead.partnerId)
                this.newSerials.partnerNameDatabase = this.$store.state.documentActivHead.partnerNameDatabase
                this.newSerials.HomeCurrency = this.$store.state.documentActivHead.HomeCurrency
                this.newSerials.ForeignCurrency = this.$store.state.documentActivHead.ForeignCurrency
                this.newSerials.ExRate = this.$store.state.documentActivHead.ExRate
                this.newSerials.itemID = item.itemID
                this.newSerials.itemName = item.itemName
                this.newSerials.ItemNameShort = item.ItemNameShort
                this.newSerials.ItemCode = item.ItemCode
                this.newSerials.ItemEANCode = item.ItemEANCode
                this.newSerials.itemGroup = item.itemGroup
                this.newSerials.itemGroupName = item.itemGroupName
                this.newSerials.itemDescription = item.itemDescription
                this.newSerials.itemUnit = item.itemUnit
                this.newSerials.ItemWarehouse = item.ItemWarehouse
                this.newSerials.StartDate = this.$store.state.documentActivHead.documentDate
                this.newSerials.StartTime = this.$store.state.documentActivHead.documentTime
                this.newSerials.EndDate = null
                this.newSerials.EndTime = null
                this.newSerials.WareHouseUpdateBy = null
                this.newSerials.FreeF5 = this.document.id
                this.allNewSerials.push(this.newSerials)
              } 
            }

          })
        }
        // if this.allNewSerials then post bulk to server
        if (this.allNewSerials.length) {
          try {
             await SerialsService.saveitems(this.allNewSerials)
             this.text = this.lang.serialsCreatedOK
             this.color = 'green'
             this.snackbar = true
             await this.showSerials()
          } catch(err){
            // eslint-disable-next-line
            console.log(err)
            this.text = 'Error! On create serials'
            this.color = 'red'
            this.snackbar = true
          }
         
        }
      }
    },
    async documentCopyTo () {
      try {
        // load items of document and  write it to destination
        console.log('1 this.selectDest1.doc', this.selectDest1.doc)
        if (this.document.CompanyId) {
          this.mainQuery.CompanyId = this.document.CompanyId
        }
        if (this.$store.state.businessYear) {
          this.mainQuery.BusinessYear = this.$store.state.businessYear
        }
        if (this.selectDest1.doc) {
          this.mainQuery.databaseDocIdOLD = this.$store.state.documentid
        }
        if (this.$store.state.documentSide) {
          this.mainQuery.documentSideOLD = this.$store.state.documentSide
        }
        this.sourceCompanyid = this.document.CompanyId
        this.sourceCompanyNameDatabase = this.document.CompanyNameDatabase
        this.sourceConnId = this.document.id
        this.sourceConnDate = this.document.documentDate
        this.sourceConnName = (this.document.documentName ? this.document.documentName : '') +  (this.document.documentId ? ', ' + this.document.documentId : '')
        if (this.document.documentName === 'WorkingOrder') {
          if (this.selectDest1.doc === 'WorkingOrder') {
            alert('Trenutno nije omoguceno kopiranje radnog naloga u drugi radni nalog!')
            return
          }
        }
        if (this.selectDest1.doc === 'InvoiceSales' && this.document.partnerVATID === '00000000000') {
          alert('Sorry! OIB nije ispravan. Nije moguće kopiranje. VAT id is not right. Copying not possible!')
          return
        }

        if (this.selectDest1.doc) {          
          
          if (this.selectDest1.doc === 'POS') {
            this.$store.dispatch('setpathAgain', 'toPOS')
            this.$store.dispatch('setDocumentActiv', this.selectDest1.doc)
            this.$store.dispatch('setDocumentActivLoc', this.selectDest1.docLoc)
            this.$store.dispatch('setDocumentSide', this.selectDest1.docSide)        
            
            await this.$router.push({name: 'documentpos'})

          } else {
            this.showDialog = true
            console.log('2 this.selectDest1', this.selectDest1.do, this.selectDest1.doc === 'DeliveryNoteSalesWorkingOrder')
            if (this.selectDest1.doc === 'DeliveryNoteSalesWorkingOrder') {
              this.haveDouble = true
              this.selectDest1.doc = 'DeliveryNoteSales'
              this.$store.dispatch('setDocumentActiv', this.selectDest1.doc)
              this.$store.dispatch('setDocumentActivLoc', this.selectDest1.docLoc)

              this.$store.dispatch('setDocumentSide', this.selectDest1.docSide)
              this.selectDest2.doc = 'WorkingOrder'
              this.selectDest2.docLoc = this.lang.Production
              this.selectDest2.docSide = 3

            }
            if (!this.haveDouble) {
              // have new destination document
              this.$store.dispatch('setDocumentActiv', this.selectDest1.doc)
              this.$store.dispatch('setDocumentActivLoc', this.selectDest1.docLoc)
              this.$store.dispatch('setDocumentSide', this.selectDest1.docSide)
            }
            // have new query for last number
            if (this.document.CompanyId) {
              this.mainQuery.CompanyId = this.document.CompanyId
            }

            this.mainQuery.BusinessYear = this.selectedYear && this.selectedYear.BusinessYear ? this.selectedYear.BusinessYear :  (this.$store.state.businessYear ? this.$store.state.businessYear : 2022)
            
            if (this.selectDest1.doc) {
              this.mainQuery.documentName = this.selectDest1.doc
            }
            if (this.selectDest1.docSide) {
              this.documentSide = this.selectDest1.docSide
              this.mainQuery.documentSide = this.selectDest1.docSide
            }
            // have a new number document
            delete this.document.id
            delete this.document.status
            const side = this.$store.state.documentSide ? this.$store.state.documentSide : 0
            if (side === 1 || side === 2) {
              this.mainQuery.WareHouseId = this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1
            }
            this.document.documentId = parseInt((await DocumentService.choose(this.mainQuery)).data.docNumber)
            this.document.documentIdGeneral = this.sourceCompanyNameDatabase + '-' + this.mainQuery.BusinessYear + '-' + this.$store.state.documentActiv + '-' + this.document.documentId + '-' + (this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1)
            this.document.WareHouseId = (this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1)
            const whObj =  this.$store.state.warehouseState ? this.$store.state.warehouseState.find(whID => whID.WareHouseId === this.$store.state.warehousemarkid) : []
            this.document.WareHouseName = whObj && whObj.WareHouseName ? whObj.WareHouseName : ''
            this.document.documentName = this.$store.state.documentActiv
            this.document.BusinessYear = this.mainQuery.BusinessYear
            if (this.selectDest1.doc) {
              this.mainQuery.databaseId = this.document.documentId
            }
            if (this.docName === 'InquirySales' || this.docName === 'QuoteSales' || this.docName === 'OrderSales' || this.docName === 'DeliveryNoteSales' || this.docName === 'InternalDeliveryNoteSales' || this.docName === 'InvoiceSales' || this.docName === 'ContractSales' || this.docName === 'WorkingOrder' || this.docName === 'POS') {
              this.document.documentAnalysis = 1
              this.mainQuery.documentAnalysis = 1
              this.$store.dispatch('setDocumentAnalysis', 1)
            }
            if (this.docName === 'RequestPurchase' || this.docName === 'InquiryPurchase' || this.docName === 'OrderPurchase' || this.docName === 'ReceiptPurchase' || this.docName === 'ReturnPurchase') {
              this.document.documentAnalysis = 2
              this.mainQuery.documentAnalysis = 2
              this.$store.dispatch('setDocumentAnalysis', 1)
            }
            if (this.$store.state.documentSide) {
              this.document.documentSide = this.$store.state.documentSide
              this.mainQuery.documentSide = this.$store.state.documentSide
            }
            if (!this.document.documentAnalysis || this.document.documentAnalysis === 0) {
              this.document.documentAnalysis = 1
              this.mainQuery.documentAnalysis = 1
              this.$store.dispatch('setDocumentAnalysis', 1)
            }
            // if (this.docName === 'InvoiceSales') {
            //   this.document.documentDescription1 = this.$store.state.companyActiv.CompanyInvoiceMemo
            // }
            this.mainQuery.documentName = this.$store.state.documentActiv
            this.mainQuery.documentId = this.document.documentId
            this.mainQuery.documentIdGeneral = this.document.documentIdGeneral
            this.mainQuery.documentDate = this.document.documentDate
           // this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
            this.mainQuery.partnerId = this.document.partnerId ? this.document.partnerId : ''
            this.mainQuery.partnerNameDatabase = this.document.partnerNameDatabase ? this.document.partnerNameDatabase : ''
            if (this.haveDouble) {
                this.mainQuery2.docFromDate = this.sourceConnDate
            }
            let d = new Date()
            const dayN = d.getDate()
            const monthN = d.getMonth() 
            const yearN = this.mainQuery.BusinessYear
            if (!this.haveDouble) {
              this.mainQuery2.docFromDate = dayjs().year(yearN).month(monthN).date(dayN).format('YYYY-MM-DD')
            }
            this.document.documentDate = this.mainQuery2.docFromDate
            this.document.documentTime = dayjs(new Date()).format('hh:mm:ss')
            this.document.documentInvoiceStartDate = this.mainQuery2.docFromDate
            this.document.documentInvoiceEndDate = dayjs(this.mainQuery2.docFromDate).add(15, 'days').format('YYYY-MM-DD')
            this.document.documentConnIdFrom = this.sourceConnId
            this.document.documentConnDateFrom = this.sourceConnDate
            this.document.documentConnNameFrom = this.sourceConnName

            const resp = await DocumentService.post(this.document)
            if (resp && resp.data && resp.data.newDoc && resp.data.newDoc.id) {
              this.mainQuery.databaseDocID = resp.data.newDoc.id
              this.sourceDocument.documentConnId = resp.data.newDoc.id
              this.sourceDocument.documentConnDate = resp.data.newDoc.documentInvoiceStartDate ? resp.data.newDoc.documentInvoiceStartDate : null
              this.sourceDocument.documentConnName = resp.data.newDoc.documentName ? resp.data.newDoc.documentName + ', ' + resp.data.newDoc.documentId: null
              await DocumentService.put(this.sourceDocument)
              const resp2 = await DocumentService.copydocdet(this.mainQuery)
              if (resp2 && resp2.data && resp2.data.itemsCopied) {
                console.log('copied items  ', resp2.data.itemsCopied)
              }
            }

            // have DOUBLE ---------------------------------------------- >>>  
            console.log('have double ', this.haveDouble)

            if (this.haveDouble === true) {
                this.$store.dispatch('setDocumentActiv', this.selectDest2.doc)
                this.$store.dispatch('setDocumentActivLoc', this.selectDest2.docLoc)
                this.$store.dispatch('setDocumentSide', this.selectDest2.docSide)
                if (this.selectDest2.doc) {
                  this.mainQuery.documentName = this.selectDest2.doc
                }
                if (this.selectDest2.docSide) {
                  this.documentSide = this.selectDest2.docSide
                  this.mainQuery.documentSide = this.selectDest2.docSide
                }
                // have a new number document
                if (this.document.id) {
                  delete this.document.id
                }
                if (this.document.status) {
                  delete this.document.status
                }
                const side = this.selectDest2.docSide ? this.selectDest2.docSide : 0
                if (side === 1 || side === 2) {
                  this.mainQuery.WareHouseId = this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1
                }
                this.document.documentId = parseInt((await DocumentService.choose(this.mainQuery)).data.docNumber)
                this.document.documentIdGeneral = this.sourceCompanyNameDatabase + '-' + this.mainQuery.BusinessYear + '-' + 'WorkingOrder'+ '-' + this.document.documentId + '-' + (this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1)
                this.document.WareHouseId = (this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1)
                const whObj =  this.$store.state.warehouseState ? this.$store.state.warehouseState.find(whID => whID.WareHouseId === this.$store.state.warehousemarkid) : []
                this.document.WareHouseName = whObj && whObj.WareHouseName ? whObj.WareHouseName : ''
                this.document.documentName = this.$store.state.documentActiv
                this.document.BusinessYear = this.mainQuery.BusinessYear
                if (this.selectDest2.doc) {
                  this.mainQuery.databaseId = this.document.documentId
                }
                this.docName === 'WorkingOrder'
                this.document.documentAnalysis = 1
                this.mainQuery.documentAnalysis = 1
                this.$store.dispatch('setDocumentAnalysis', 1)
                this.document.documentSide = side
                this.mainQuery.documentSide = side
                
                this.mainQuery.documentName = this.$store.state.documentActiv
                this.mainQuery.documentId = this.document.documentId
                this.mainQuery.documentIdGeneral = this.document.documentIdGeneral
                this.mainQuery.documentDate = this.sourceConnDate
              // this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
                this.mainQuery.partnerId = this.document.partnerId ? this.document.partnerId : ''
                this.mainQuery.partnerNameDatabase = this.document.partnerNameDatabase ? this.document.partnerNameDatabase : ''
                let d = new Date()
                const dayN = d.getDate()
                const monthN = d.getMonth() 
                const yearN = this.mainQuery.BusinessYear
                //this.mainQuery2.docFromDate = dayjs().year(yearN).month(monthN).date(dayN).format('YYYY-MM-DD')
                this.mainQuery2.docFromDate = this.sourceConnDate
                this.document.documentDate = this.mainQuery2.docFromDate
                this.document.documentTime = dayjs(new Date()).format('hh:mm:ss')
                this.document.documentInvoiceStartDate = this.mainQuery2.docFromDate
                this.document.documentInvoiceEndDate = dayjs(this.mainQuery2.docFromDate).add(15, 'days').format('YYYY-MM-DD')
                this.document.documentConnIdFrom = this.sourceConnId
                this.document.documentConnDateFrom = this.sourceConnDate
                this.document.documentConnNameFrom = this.sourceConnName
                this.document.FreeF4 = this.document.documentTotalAmountBrutto
                this.document.productiontype = 5

                const resp = await DocumentService.post(this.document)
                if (resp && resp.data && resp.data.newDoc && resp.data.newDoc.id) {
                  this.mainQuery.databaseDocID = resp.data.newDoc.id
                  // this.sourceDocument.documentConnId = resp.data.newDoc.id
                  // this.sourceDocument.documentConnDate = resp.data.newDoc.documentInvoiceStartDate ? resp.data.newDoc.documentInvoiceStartDate : null
                  // this.sourceDocument.documentConnName = resp.data.newDoc.documentName ? resp.data.newDoc.documentName + ', ' + resp.data.newDoc.documentId: null
                  // await DocumentService.put(this.sourceDocument)
                  const resp2 = await DocumentService.copydocdet(this.mainQuery)
                  if (resp2 && resp2.data && resp2.data.itemsCopied) {
                    console.log('copied items  2', resp2.data.itemsCopied)
                  }
                }
            }
            
            this.showDialog= false
          
            const querydoc  = this.selectDest1.doc
            // and then return
            await this.$router.push({
              name: 'document',
              query: {
                  querydoc
                }
            })
          }
        }        
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
        this.text = 'Error! On copy document!'
        this.color = 'red'
        this.snackbar = true
      }
    }
  },
  computed: {
  },
  watch: {

  },
  components: {
    appDocumentViewCard1: DocumentViewCard1,
    appDocumentViewCard2: DocumentViewCard2,
    appDocumentViewCard3: DocumentViewCard3,
    SerialsListShort,
    StickersCRUD,
    JustWait
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
