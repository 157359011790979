<template>
<div>
  <v-container fluid grid-list-md>
  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-row>
  <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
  <v-data-table
    dense
    :headers="headers"
    :items="mbtypes"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="50"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{lang.documentTypes}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
        <v-spacer></v-spacer>
        <v-btn text color="success" @click="documentlist">{{langC.Back}}</v-btn>
        <v-dialog v-model="dialog" max-width="500px">

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">{{ lang.documentType }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >

                  <v-row>
                    <v-col cols="12" sm="12" md="6" xs="6" lg="6" xl="6" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.docStatus"
                        v-model="editedItem.docStatus"
                        :class="editedItem.docColor"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" xs="4" lg="4" xl="4" class="pr-1">
                      <v-select
                        dense
                        v-model="editedItem.docColor"
                        :items="colors"
                        item-text="text"
                        item-value="value"
                      >
                        <template v-slot:selection="{ item}">
                          <span :class="item.value">Color</span>
                        </template>
                      </v-select>

                    </v-col>

                  </v-row>

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn color="success" text @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.docStatus="{item}">
      <span v-if="item.docColor">
        <v-chip :color="item.docColor" dark>{{item.docStatus}}</v-chip>
      </span>
      <span v-else>{{item.docStatus}}</span>
    </template>

    <template v-slot:item.action="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      {{ lang.CalEmpty }}
    </template>
  </v-data-table>
  </v-col>
  </v-row>
  </v-container>
  </div>
</template>

<script>
import DocumentStatusService from '@/services/DocumentStatusService'
import { defDocumentStatus, defNewDocumentStatus } from './documentHelper'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'

  export default {
    name: 'documentstatusCRUD',
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      modal1: false,
      dialog1: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {
      },
      dialog: false,
      headers: [],
      mbtypes: [],
      editedIndex: -1,
      editedItem: { },
      defaultItem: {

      },
      privitems: [],
      valid: true,
      RecTrue: true,
      ExpTrue: false,
      colors: [],
      selectedColor: {},
      inIndex: null
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewMainBookType: this.lang.EditMainBookType
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },
    components: {

    },

    mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      
      this.headers.push(
        {text: this.lang.docStatus, value: 'docStatus', width: '350px'},
        {text: this.lang.documentColor, value: 'docColor', width: '150px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
        {text: this.lang.Actions, value: 'action', sortable: false },
      ) 
      this.defaultItem = {...defDocumentStatus(), ...defNewDocumentStatus()}
      this.colors = this.$store.state.colors ? this.$store.state.colors : []
    },

    created () {
      this.initialize()
    },

    methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      async initialize () {
        try {
          this.mbtypes = []
          if (this.$store.state.companyid) {
            this.mainQuery.CompanyId = this.$store.state.companyid
          }
          if (this.$store.state.businessYear) {
            this.mainQuery.businessYear = this.$store.state.businessYear
          }
          this.mainQuery.documentName = this.$store.state.documentActiv
          this.saving = true

          const { data } = await DocumentStatusService.index(this.mainQuery)
          
          if (data && data.documenttypes && data.documenttypes.length > 0) {
            this.mbtypes = data.documenttypes
          }
          this.saving = false
          this.errorMatch = false
          this.editedItem ={...this.defaultItem}
          this.RecTrue = true
          this.ExpTrue = false
        } catch (err) {
          this.error = err
        }
      },
      editItem (item) {
        this.errorMatch = false
        this.editedIndex = this.mbtypes.indexOf(item)
        this.editedItem = {...item}
        this.dialog = true
        this.confpassword = ''
      },

      async deleteItem (item) {
        try {
          this.inIndex = this.mbtypes.indexOf(item)
          const warningMessage = item.docStatus
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.mbtypes.splice(this.inIndex, 1)) {
            await DocumentStatusService.delete(item.id)
            this.initialize()
          }
        } catch(err) {
          this.error = err
          
        }
      },
      async documentlist () {
        try {
          this.$store.dispatch('setDocumentActivHead', {})
          this.$store.dispatch('setDocumentActivItems', [])
          await this.$router.push({
            name: 'document'
          })
        } catch (err) {
          // eslint-disable-next-line
          console.log(err)
        }
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = {...this.defaultItem}
          this.editedIndex = -1
        }, 300)
      },

      checkMatch () {
        if (this.editedItem.password && this.confpassword &&  this.confpassword === this.editedItem.password) {
          this.errorMatch = null
          this.save()
        } else {
          this.errorMatch = 'Error. Passoword and Confirmation Password are NOT equal!'
          this.valid = false        
        }
      },

      async save () {
        try {
           let doneOK = false

           if (this.editedIndex > -1) {
              await DocumentStatusService.put(this.editedItem)
              doneOK = true
            } else {
              await DocumentStatusService.post(this.editedItem)
              doneOK = true
            }
            if (doneOK) {
              this.initialize()
              this.close()
            }
        } catch(err) {
          this.error = err
          
        }
       
      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      }
    },
  }
</script>