<template>
  <div class="mainbookcompprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ledgerEN from '../MainBook/mainbookDescEn'
import ledgerHR from '../MainBook/mainbookDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import {parseNum, round} from '@/snippets/allSnippets'
import dayjs from 'dayjs'

export default {
  name: 'mainbookcompprint',
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0,
      kpi: null
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    
  },
  methods: {
    print (kpi) {
      this.kpi = kpi ? kpi : null
      this.initDocument()
      this.addHeaderNoLogo()
      this.addEmptyRow()
      this.addLine()
      this.addItemsHeader()
      this.addLine()
      this.addItems()
      this.addLine()
      this.addItemsFoter()
      this.addLine()
      this.addTotalsFoter()
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'landscape'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.content = []
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 800,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '35%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text:  'Knjiga uplata-isplata\n', fontSize: 18, bold: true },
                  { text: ' Od datuma: ' + dayjs(this.kpi.DatumOd).format('DD.MM.YYYY') + ' Do datuma: ' + dayjs(this.kpi.DatumDo).format('DD.MM.YYYY'), fontSize:10, bold: true}
                ]   
              },
              {
                // % width
                width: '20%',
                text: [                 
                  { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addItemsHeader () {
        const docPart = {
        columns: [
              {
                width: '3%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.id
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.BookDate 
              },
              {
                width: '21%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.partnerName
              },
              {
                width: '21%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.BookDesc1
              },
               {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.RecBank
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.RecCash
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.RecTotal
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.ExpBank
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.ExpCash
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.ExpTotal
              }
            ]
          }
        this.content.push(docPart)        
    },
    addItems () {
           this.order = 0           
            this.printitems = this.kpi.RacuniIspis ? this.kpi.RacuniIspis: []
            if (this.printitems) {
              this.printitems.map(item => {
                  this.order += 1
                  // this.pTtt5 = item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0
                  // this.pTtt6 = item.itemDiscountPercentage1 ? this.formatPrice(item.itemDiscountPercentage1) : 0
                  // this.pTtt7 = item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0
                  // this.pTtt8 = item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0
                  // this.pTtt9 = item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0
                  // eslint-disable-next-line
                  const docPart = {
                  columns: [
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'left',
                          text: parseInt(this.order)
                        },
                        {
                          width: '7%',
                          fontSize: 8,
                          alignment: 'left',
                          text: dayjs(item.BookDate).format('DD.MM.YYYY')
                        },
                        {
                          width: '21%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.partnerName
                        },
                        {
                          width: '21%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.BookDesc1
                        },
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'right',
                          text: (item.RecBank !== 0 ? this.formatPrice(round((parseNum(item.RecBank) / this.kpi.exRate) ,2)) : '')
                        },
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'right',
                          text: (item.RecCash ? this.formatPrice(round((parseNum(item.RecCash) / this.kpi.exRate) ,2)) : '')
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          bold: true,
                          text: (item.RecTotal ? this.formatPrice(round((parseNum(item.RecTotal) / this.kpi.exRate) ,2)) : '')
                        },
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'right',
                          text: (item.ExpBank ? this.formatPrice(round((parseNum(item.ExpBank) / this.kpi.exRate) ,2)) : '' )
                        },
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'right',
                          text: (item.ExpCash ? this.formatPrice(round((parseNum(item.ExpCash) / this.kpi.exRate) ,2)) : '')
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: (item.ExpTotal ? this.formatPrice(round((parseNum(item.ExpTotal) / this.kpi.exRate) ,2)) : '')
                        }
                      ]
                    }
                  this.content.push(docPart)
                }
              )
          }
    },
    addItemsFoter () {
        const docPart = {
        columns: [
              {
                width: '3%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '42%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.kpi.Ukupno.RecBank)
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.kpi.Ukupno.RecCash)
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.kpi.Ukupno.RecTotal)
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.kpi.Ukupno.ExpBank)
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.kpi.Ukupno.ExpCash)
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.kpi.Ukupno.ExpTotal)
              }
            ]
          }
        this.content.push(docPart)        
    },
    addTotalsFoter () {
        const docPart = {
        columns: [
              {
                width: '7%',
                fontSize: 8,
                alignment: 'right',
                text: 'Saldo:' 
              },
              {
                width: '15%',
                fontSize: 8,
                alignment: 'right',
                text: 'Žiro račun:' + this.formatPrice(this.kpi.Ukupno.Total2)
              },
              {
                width: '15%',
                fontSize: 8,
                alignment: 'right',
                text: 'Gotovina:' + this.formatPrice(this.kpi.Ukupno.Total1)
              },
              {
                width: '15%',
                fontSize: 8,
                alignment: 'right',
                text: 'Ukupno:' + this.formatPrice(this.kpi.Ukupno.Total6)
              },
            ]
          }
        this.content.push(docPart)        
    },
  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>