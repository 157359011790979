<template>
<div>
  <v-container fluid grid-list-md>
    <app-account-choose></app-account-choose>
    <app-Account4-CRUD></app-Account4-CRUD>
  </v-container>
  </div>
</template>

<script>
import AccountChoose from './AccountChoose'
import Account4CRUD from './Account4CRUD'

export default {
  name: 'account4',
  data () {
    return {
      msg: ''
    }
  },
  mounted () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  },
  components: {
    appAccount4CRUD: Account4CRUD,
    appAccountChoose: AccountChoose
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
