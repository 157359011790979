import { render, staticRenderFns } from "./EmployeeEdit2.vue?vue&type=template&id=00bb4422&scoped=true"
import script from "./EmployeeEdit2.vue?vue&type=script&lang=js"
export * from "./EmployeeEdit2.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00bb4422",
  null
  
)

export default component.exports