<template>
  <div>
    <v-alert type="error" v-if="error">
      {{error}}
    </v-alert>
    <v-card>
      <v-card-title>
        {{lang.Account4s}}
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          v-model="search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        item-key="id"
        :search="search"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
        :items-per-page="50"
        @click:row="navigateTo"
      >
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
          From {{ pageStart }} to {{ pageStop }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"

export default {
  name: 'partnerlistfast',
  data () {
    return {
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      error: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: "",
        databaseDocID: 0
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false
    }
  },
  async mounted () {
    try {
      if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      this.items = this.$store.state.account4s ? this.$store.state.account4s : []
      this.headers.push(
        {
          text: this.lang.Account4,
          align: 'left',
          sortable: true,
          value: 'account4',
          width: '80px'
        },
        {
          text: this.lang.Account3,
          align: 'left',
          sortable: true,
          value: 'account3',
          width: '60px'
        },
        {
          text: this.lang.Account2,
          align: 'left',
          sortable: true,
          value: 'account2',
          width: '50px'
        },
        {
          text: this.lang.Account1,
          align: 'left',
          sortable: true,
          value: 'account1',
          width: '60px'
        },
        {text: this.lang.Account4Description, value: 'account4Description', align: 'left', width: '750px' },
        {text: this.lang.Actions, value: 'action', sortable: false },
      )
      
    } catch (error) {
      this.error = error
    }
  },
  methods: {
    navigateTo (params) {
      // eslint-disable-next-line
      console.log(' account4 params ', params)
      this.$store.dispatch('setAccount4id', params.account4)
    }
  },
  computed: {

  },
  watch: {

  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
