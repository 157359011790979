<template>
  <v-container>
  <v-row>
      <appAppSettings></appAppSettings>
    </v-row>
    <v-row>
      <v-col cols="12" xl="6" lg="9" xs="12" sm="12" md="6" class="pa-0">

      </v-col>
      <v-col cols="12" xl="6" lg="9" xs="12" sm="12" md="6" class="pa-0 text-right">
          <v-btn fab dark fixed right class="indigo"  @click="newaccount">
              <v-icon dark>mdi-plus</v-icon>
          </v-btn>
      </v-col>
    </v-row>
    <app-BankAccount-list></app-BankAccount-list>
  </v-container>
</template>

<script>
import BankAccountlist from './BankAccountList'
import AppSettings from '../AppSettings'

export default {
  name: 'bankaccount',
  data () {
    return {
      msg: ''
    }
  },
  mounted () {

  },
  methods: {
    async newaccount () {
      try {
        await this.$router.push({
          name: 'bankaccountcreateedit'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }

  },
  computed: {

  },
  watch: {

  },
  components: {
    appBankAccountList: BankAccountlist,
    appAppSettings: AppSettings
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
