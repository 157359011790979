<template>
   <v-form ref="form" v-model="valid">
   <v-container fluid>

    <v-row>
      <v-col cols="12" sm="12" xs="12" md="4"  lg="4" xl="4" class="mb-4">
        <h2>Obrazac Joppd B</h2>
      </v-col>
      <v-col cols="12" sm="1" md="1" lg="1" xl="1">
          <v-btn text color="red" @click="joppdbDelete" class="mr-1">
              {{langC.Delete}}
          </v-btn>
     </v-col>
    </v-row>
    <v-col cols="12" sm="12" xs="12" md="4"  lg="4" xl="4" class="mb-4">
       <v-btn
        :disabled="!valid"
        class="primary mr-1"
        color="success"
        @click="save">
        <span v-if="this.$store.state.editItem  === 0">
          {{langC.CreateNew}} Joppd B
        </span>
        <span v-if="this.$store.state.editItem === 1">
          {{langC.Save}} Joppd B
        </span>
      </v-btn>
      <v-btn
        color="green"
        @click="goBack">
        {{langC.Back}}
      </v-btn>
    </v-col>


    <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>

    <v-row >
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          :rules="reqRules"
          label="2. Šifra općine/grada prebivališta/boravišta *"
          v-model="joppdb.P2"
          required
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          :rules="reqRules"
          label="3. Šifra općine/grada rada"
          v-model="joppdb.P3"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row >
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">    
        <v-text-field
          dense
          :rules="reqRules"
          label="4. OIB stjecatelja/osiguranika *"
          v-model="joppdb.P4"
          required
          outlined
        ></v-text-field>
      </v-col>
      
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          :rules="reqRules"
          label="5. Ime i prezime stjecatelja/osiguranika *"
          v-model.number="joppdb.P5"
          required
          outlined
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row >
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          :rules="reqRules"
          label="6.1. Oznaka stjec./os. *"
          v-model="joppdb.P61"
          required
          outlined
        ></v-text-field>
      </v-col>
       <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          :rules="reqRules"
          label="6.2. Oznaka primitka/obveze doprinosa"
          v-model="joppdb.P62"
          required
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row > 
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="7.1. Ob.dod.dop.za MO za staž s pov.tr."
          v-model="joppdb.P71"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="7.2. Oz.načina umanjenja mj.osn. za obračun dop.za MO na temelju gen.sol."
          v-model="joppdb.P72"
          type="number"
          outlined
        ></v-text-field>
      </v-col>      
    </v-row>

    <v-row >
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="8. Oznaka prvog/zadnjeg mjeseca u osiguranju po istoj osnovi"
          v-model.number="joppdb.P8"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="9. Oznaka punog/nepunog radnog vremena ili rada s polovicom radnog vremena"
          v-model.number="joppdb.P9"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row >
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="10. Ukupni sati rada prema kojima se radi obračun"
          v-model.number="joppdb.P10"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        Razdoblje obračuna od: <input type="date" v-model="joppdb.P101" />
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="10.0.Ukupni neodrađeni sati rada (10.-odrađeni sati rada)"
          v-model.number="joppdb.P100"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        Razdoblje obračuna do: <input type="date" v-model="joppdb.P102" />
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="11. Iznos primitka (oporezivi)"
          v-model.number="joppdb.P11"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="12. Osnovica za obračun doprinosa"
          v-model.number="joppdb.P12"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="12.1. Doprinos za mirovinsko osiguranje"
          v-model.number="joppdb.P121"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="12.2. Doprinos za mirovinsko osiguranje - II STUP"
          v-model.number="joppdb.P122"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="12.3. Doprinos za zdravstveno osiguranje"
          v-model.number="joppdb.P123"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="12.4. Doprinos za zaštitu zdravlja na radu"
          v-model.number="joppdb.P124"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="12.5. Doprinos za zapošljavanje "
          v-model.number="joppdb.P125"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="12.6. Dodatni doprinos za mirovinsko osig. za staž osiguranja koji se računa s povećanim trajanjem"
          v-model.number="joppdb.P126"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
     <v-row >
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="12.7. Dodatni doprinos za mirovinsko osiguranje za staž osiguranja koji se računa s povećanim trajanjem - II STUP "
          v-model.number="joppdb.P127"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="12.8. Poseban doprinos za korištenje zdravstvene zaštite u inozemstvu"
          v-model.number="joppdb.P128"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="12.9. Iznos umanjenja mj.osn. za obračun doprinosa za MO na temelju gen.sol."
          v-model.number="joppdb.P129"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="13.1. Izdatak"
          v-model.number="joppdb.P131"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="13.2. Izdatak-uplaćeni doprinos za mirovinsko osiguranje "
          v-model.number="joppdb.P132"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="13.3. Dohodak"
          v-model.number="joppdb.P133"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="13.4. Osobni odbitak "
          v-model.number="joppdb.P134"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="13.5. Porezna osnovica"
          v-model.number="joppdb.P135"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="14.1. Iznos obračunanog poreza na dohodak "
          v-model.number="joppdb.P141"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="14.2. Iznos obračunanog prireza porezu na dohodak"
          v-model.number="joppdb.P142"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="15.1. Oznaka neoporezivog primitka "
          v-model.number="joppdb.P151"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="15.2 Iznos neoporezivog primitka"
          v-model.number="joppdb.P152"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
     <v-row >
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="16.1. Oznaka načina isplate "
          v-model.number="joppdb.P161"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="16.2 Iznos za isplatu"
          v-model.number="joppdb.P162"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">
        <v-text-field
          dense
          label="17 Obračunani primitak od nesam. rada (plaća)"
          v-model.number="joppdb.P17"
          type="number"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pr-2">

      </v-col>
    </v-row>    

  </v-container>
 </v-form>
</template>


<script>
import JoppdBService from '@/services/JoppdBService'
import {defJoppdB, defNewJoppdB} from './salarieHelper'
import ledgerEN from './salarieDescEn'
import ledgerHR from './salarieDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dayjs from 'dayjs'


export default {
  name: 'joppdbdetail',
  props: [],
  data: ()=> ({
    lang: {},
    langC: {},
    saving: false,
    valid: false,
    reqRules: [
      v => !!v || 'Obavezno polje',
    ],
    joppdb: {
    },
    dialog1: false,
    dialog2: false,
    modal1: false,
    modal2: false,
  }),
  async mounted () {
      if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    if (this.$store.state.editSubItem === 0) {
      this.joppdb = defJoppdB()
      this.joppdb = {...this.joppdb, ...defNewJoppdB()}
    }
    if (this.$store.state.editSubItem === 1) {
      const res = await JoppdBService.show(this.$store.state.documentdetailid)
      this.joppdb = res && res.data && res.data.joppdb ? res.data.joppdb : {}
      this.joppdb.P101 = dayjs(this.joppdb.P101).format('YYYY-MM-DD')
      this.joppdb.P102 = dayjs(this.joppdb.P102).format('YYYY-MM-DD')
      this.joppdb.LastUpdateBy = this.$store.state.user.email
    }
  },
  methods: {
    async save() {
      try {
        this.saving = true
        if (this.$store.state.editSubItem === 0) {
          await JoppdBService.post(this.joppdb)
        }
        if (this.$store.state.editSubItem === 1) {
          await JoppdBService.put(this.joppdb)
        }
        this.saving = false
        this.goBack()
      }
      catch (err) {
        console.log(err)
      }
    },
    async joppdbDelete() {
      if (confirm(this.langC.AreYouShureToDelete + '!')) {
        await JoppdBService.delete(this.$store.state.documentdetailid)
        this.goBack()
      }
    },
    goBack (){
      this.$router.push({
        name: 'joppdb'
      })
    },
  },
  computed: {
  },
  watch: {

  },
  components: {

  }

}

</script>