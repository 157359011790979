<template>
  <div class="mainledgerprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'

export default {
  name: 'mainledgerprint',
  props: ['ura'],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    
  },
  methods: {
    async print () {

      await this.initDocument()
      await this.addHeaderNoLogo()
      await this.addEmptyRow()
      await this.addLine()
      await this.addItemsHeader()
      await this.addLine()
      await this.addItems()
      await this.addLine()
      await this.addItemsFoter()
      await this.addLine()
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'landscape'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.content = []
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 800,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '35%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text:  'Knjiga primljenih (ulaznih) računa\n', fontSize: 18, bold: true },
                  { text: ' Od datuma: ' + dayjs(this.ura.DatumOd).format('DD.MM.YYYY') + ' Do datuma: ' + dayjs(this.ura.DatumDo).format('DD.MM.YYYY'), fontSize:10, bold: true}
                ]   
              },
              {
                // % width
                width: '20%',
                text: [                 
                  { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addItemsHeader () {
        const docPart = {
        columns: [
              {
                width: '3%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.id
              },
              {
                width: '11%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.InvoiceNumber 
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.InvoiceDate
              },
              {
                width: '14%',
                fontSize: 8,
                alignment: 'left',
                bold: true,
                text: this.lang.partnerName
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.VATZero
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.VAT3Base
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.VAT2Base
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.VAT1Base
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                bold: true,
                text: this.lang.InvoiceAmount
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.AmountVAT
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.VAT3CanUse
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.VAT3CanTUse
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.VAT2CanUse
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.VAT2CanTUse
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.VAT1CanUse
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.lang.VAT1CanTUse
              }
            ]
          }
        this.content.push(docPart) 
        const docPart2 = {
        columns: [
              {
                width: '3%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '11%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '14%',
                fontSize: 7,
                alignment: 'left',
                text: this.lang.partnerVATID
              },

            ]
          }
        this.content.push(docPart2)      
    },
    addItems () {
           this.order = 0           
            this.printitems = this.ura.RacuniIspis ? this.ura.RacuniIspis: []
            if (this.printitems) {
              this.printitems.map(item => {
                  this.order += 1
                  // this.pTtt5 = item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0
                  // this.pTtt6 = item.itemDiscountPercentage1 ? this.formatPrice(item.itemDiscountPercentage1) : 0
                  // this.pTtt7 = item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0
                  // this.pTtt8 = item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0
                  // this.pTtt9 = item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0
   
                  const docPart = {
                  columns: [
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'left',
                          text: parseInt(item.R1)
                        },
                        {
                          width: '11%',
                          fontSize: 8,
                          alignment: 'left',
                          text: parseInt(item.R20) 
                        },
                        {
                          width: '7%',
                          fontSize: 8,
                          alignment: 'left',
                          text: dayjs(item.R3).format('DD.MM.YYYY')
                        },
                        {
                          width: '14%',
                          fontSize: 8,
                          alignment: 'left',
                          bold: true,
                          text: item.R4 
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.R19)
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.R8)
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.R9)
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.R10)
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          bold: true,
                          text: this.formatPrice(item.R11)
                        },
                        {
                          width: '7%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.R12)
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.R13)
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.R14)
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.R15)
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.R16)
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.R17)
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.R18)
                        }
                      ]
                    }
                  this.content.push(docPart)
                  const docPart2 = {
                  columns: [
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'left',
                          text: ''
                        },
                        {
                          width: '11%',
                          fontSize: 7,
                          alignment: 'left',
                          text: (item.R2 ? item.R2 : ' ')
                        },
                        {
                          width: '7%',
                          fontSize: 8,
                          alignment: 'left',
                          text: ''
                        },
                        {
                          width: '14%',
                          fontSize: 7,
                          alignment: 'left',
                          text: (item.R7 ? 'OIB:' + item.R7 : 'OIB:')
                        },
                  ]
                  }
                  this.content.push(docPart2)
                }
              )
          }
    },
    addItemsFoter () {
        const docPart = {
        columns: [
              {
                width: '3%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '11%',
                fontSize: 8,
                alignment: 'left',
                text: ' ' 
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '14%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ura.Ukupno.U19)
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ura.Ukupno.U8)
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ura.Ukupno.U9)
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ura.Ukupno.U10)
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                bold: true,
                text: this.formatPrice(this.ura.Ukupno.U11)
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ura.Ukupno.U12)
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ura.Ukupno.U13)
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ura.Ukupno.U14)
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ura.Ukupno.U15)
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ura.Ukupno.U16)
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ura.Ukupno.U17)
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ura.Ukupno.U18)
              }
            ]
          }
        this.content.push(docPart)        
    },
  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>