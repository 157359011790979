import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('joppdb/showall', {
      params: query
    })
  },
  indexlist(search) {
    return Api().get('joppdb', {
      params: search
    })
  },
  choose (query) {
    return Api().get('joppdb/choose', {
      params: query
    })
  },
  show (id) {
    return Api().get(`joppdb/${id}`)
  },
  post (newjoppdb) {
    return Api().post('joppdb', newjoppdb)
  },
  put (joppdb) {
    return Api().put(`joppdb/${joppdb.id}`, joppdb)
  },
  delete (id) {
    return Api().delete(`joppdb/delete/${id}`)
  },
  saveitems (newitems) {
    return Api().post('joppdb/saveitems', newitems)
  },
}