<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs12 sm12 m12 lg12 ex12>
            <v-row style="border-bottom:solid 1px;">
                <v-flex xs5>
                    <h4><b><i>{{comp.CompanyNameShort}}</i></b></h4>
                    {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}   <br />                
                    {{comp.CompanyPostcode}} {{comp.CompanyCity}}<br />
                      OIB: {{comp.CompanyVATID}}
                </v-flex>
                <v-flex xs3>
                  <span v-if="this.$store.state.language == 'EN'">
                  <h2>Partners - open statement </h2>
                  </span>
                  <span v-if="this.$store.state.language == 'HR'">
                  <h2>Partneri - otvorene stavke</h2>
                  </span>
              </v-flex>
                <v-flex xs 4>
                  <p class="text-md-left">
                      Datum izvještaja: {{PrintDate}}<br />
                      Telefon: {{comp.CompanyPhones}}<br />
                      email: {{comp.Companyemail}}<br />
                      <v-btn @click="print()" class="mr-5">Printaj</v-btn>
                      <v-btn @click="doOpzstat()" class="mr-2" v-if="showOpzstat">Kreiraj OPZstat</v-btn>
                  </p>
                </v-flex>
          </v-row>
          <v-layout row style="border-bottom:solid 1px;" class="mt-5">
            <v-flex xs8 class="text-xs-right">
              Total:
            </v-flex>

            <v-flex xs1 class="text-right">
              {{ formatPrice(fTotal1) }}
            </v-flex>
            <v-flex xs1 class="text-right">
              {{ formatPrice(fTotal2) }}
            </v-flex>
            <v-flex xs1 class="text-right">
              {{ formatPrice(fTotal5) }}
            </v-flex>

          </v-layout>


          <v-row v-for="item in items" :key="item.id">
            <v-flex xs12 class="text-left" >
              <h4><b><i>{{item.partner && item.partner.partnerName ? item.partner.partnerName : ''}}</i></b></h4>
              {{item.partner && item.partner.partnerStreet ? item.partner.partnerStreet : ''}} {{item.partner && item.partner.partnerBuildingNameNumber ? item.partner.partnerBuildingNameNumber: ''}}              
              {{item.partner && item.partner.partnerPostcode ? item.partner.partnerPostcode : ''}} {{item.partner && item.partner.partnerCity ? item.partner.partnerCity : ''}} {{item.partnerVATID ? ',OIB: ' + item.partnerVATID : ''}}, {{item.partner && item.partner.partneremail ? item.partner.partneremail : ''}}
              <div v-if="item.detItems">
                <MainLedgerCard10detail :detItems="item.detItems" :total1="item.Total1" :total2="item.Total2" :total5="item.Total5" />
              </div>
            
            </v-flex>

        </v-row>

      </v-flex>
      <MainLedgerPrintCard10 ref="prnt" :card="card" :printType="printType" :fTotal1="fTotal1" :fTotal2="fTotal2" :fTotal5="fTotal5"/>
    </v-layout>
  </v-container>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import MainLedgerCard10detail from "./MainLedgerCard10detail"
import MainLedgerPrintCard10 from './MainLedgerPrintCard10'
import MainLedgerService from '@/services/MainLedgerService'

import OpzstatHeadService from '@/services/OpzstatHeadService'
import {defOpzstathead, defNewOpzstathead} from '../Form/formhelper'
import OpzstatItemsService from '@/services/OpzstatItemsService'
import {defOpzstatitems, defNewOpzstatitems} from '../Form/formhelper'
import { dateFormat, parseNum } from '@/snippets/allSnippets'
import dayjs from 'dayjs'


export default {
  name: 'mainledgercard10',
  props: ['items', 'head', 'fTotal1', 'fTotal2', 'fTotal5'],
  data () {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      comp: {},
      error: null,
      localError: false,
      newUser: {},
      size: 'sm',
      havePath: '',
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      pItems: [],
      mainQuery2: {},
      partner: {},
      partners: [],
      card: {},
      mainQuery: {},
      printType: 1,
      ffTotal1: 0,
      ffTotal2: 0,
      ffTotal3: 0,
      ffTotal4: 0,
      ffTotal5: 0,
      showOpzstat: false
    }
  },
  components: {
    MainLedgerCard10detail,
    MainLedgerPrintCard10
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }


    this.PrintDate = dayjs(new Date()).format('llll')
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    this.ffTotal1 = this.fTotal1 ? this.fTotal1 : 0
    this.ffTotal2 = this.fTotal2 ? this.fTotal2 : 0
    this.ffTotal5 = this.fTotal5 ? this.fTotal5 : 0
    this.card = {...this.head}
    this.card.items = this.items

    const typePartner = this.head.fromAccount ? this.head.fromAccount.substr(0,2) : ''
    this.showOpzstat = typePartner === '12' || typePartner === '16' ? true : false
  },
  computed: {

  },
  methods: {
    print(){
      this.printType = 1
      this.$refs.prnt.print()
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    parseNum2(param) {
      return parseNum(param)
    },

    close () {
      this.show = false
    },
    async doOpzstat() {
      this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
      this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
      this.mainQuery.MLType = 2
      this.mainQuery.BookTypeID = 10

      const books = (await MainLedgerService.books(this.mainQuery)).data.documents
      const newItems = []
      if (this.items) {
        let newHead = false
        let head = defOpzstathead()
        head = {...head, ...defNewOpzstathead()}
        const {data} = await OpzstatHeadService.post(head)
        if (data && data.newOpzstatHead) {
          newHead = true
          await this.$store.dispatch('setDocumentid', data.newOpzstatHead.id)
        }
        let newHeadOrder = 0
        this.items.map(item => {
          newHeadOrder += 1

          if (item.detItems && newHead) {

            let R8 = 0
            let R9 = 0

            item.detItems.map(tot => {
              R8 += this.parseNum2(tot.totalDebit)
              R9 += this.parseNum2(tot.totalCredit)

            })

            let statOrder = 0
            item.detItems.map(subitem => {
              const useIt = this.parseNum2(subitem.totalDebit) - this.parseNum2(subitem.totalCredit)
              if (useIt > 0) { 
              let statItem   = defOpzstatitems()
              statItem  = {...statItem , ...defNewOpzstatitems()}
              const bookOne = books.find(book => book.id === subitem.invoiceOutID)
              const totalVAT = (bookOne && bookOne.VAT1Amount ? this.parseNum2(bookOne.VAT1Amount) : 0)+ (bookOne && bookOne.VAT2Amount ? this.parseNum2(bookOne.VAT2Amount) : 0) + (bookOne && bookOne.VAT3Amount ? this.parseNum2(bookOne.VAT3Amount) : 0) + (bookOne && bookOne.VAT4Amount ? this.parseNum2(bookOne.VAT4Amount) : 0)

              statOrder += 1
              statItem.K1 = newHeadOrder
              statItem.K2 = 1
              statItem.K3 = item.partnerVATID ? item.partnerVATID : ''
              statItem.K4 = item.partner && item.partner.partnerName ? item.partner.partnerName : ''
              statItem.K5 = 0
              statItem.K6 = 0
              statItem.K7 = R8
              statItem.K8 = R9
              statItem.K9 = R8 - R9

              statItem.R1 = statOrder
              statItem.R2 = subitem.InvoiceNumber ? subitem.InvoiceNumber : '1/1/1'
              statItem.R3 = subitem.BookingDate
              statItem.R4 = subitem.InvoicePaymentDeadlineDate
              statItem.R5 = 0
              statItem.R6 = this.parseNum2(subitem.totalDebit) - totalVAT
              statItem.R7 = totalVAT
              statItem.R8 = this.parseNum2(subitem.totalDebit)
              statItem.R9 = this.parseNum2(subitem.totalCredit)
              statItem.R10 = this.parseNum2(subitem.totalDebit) - this.parseNum2(subitem.totalCredit)

              newItems.push(statItem)
              }
            })

          }
        })

        if (newItems && newItems.length > 0) {
         await OpzstatItemsService.saveitems(newItems)
        }
        alert('OK! Opz stat obrazac je kreiran.')
      }
    }
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
