<template>
  <v-container fluid class="ma-2">
   <v-row no-gutters justify="center" class="pa-0">
    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">

    <v-row>
      <v-flex xs5>
          <h2>Company details</h2>
        <br>
        <div class="danger-alert" v-html="error" />
        
      </v-flex>
       <v-flex xs5>
            <span v-if="this.$store.state.user.AccessLevel <= 3">
              <v-btn color="primary" @click="compEdit" class="mr-1">{{langC.Edit}}</v-btn>
            </span>
            <span v-if="this.$store.state.user.AccessLevel <= 3">
              <v-btn color="primary" @click="compEditLogo" class="mr-1">Edit LOGO</v-btn>
            </span>
            <span v-if="this.$store.state.user.AccessLevel == 1">
              <v-btn color="red" @click="compDelete" class="mr-1">{{langC.Delete}}</v-btn>
            </span>
            <v-btn color="green" @click="complist">{{langC.Back}}</v-btn>
        </v-flex>
    </v-row>

    <v-row>
         <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4">
            <v-card >
              <v-card-title primary class="title py-2">
                Basic Data
              </v-card-title>
              <v-card-text>
                <app-pres-row label="Name" :info="company.CompanyName" />
                <app-pres-row label="Name Short" :info="company.CompanyNameShort" />
                <app-pres-row label="Name Database" :info="company.CompanyNameDatabase" />
                <app-pres-row label="Building Name/Number" :info="company.CompanyBuildingNameNumber" />
                <app-pres-row label="Street" :info="company.CompanyStreet" />
                <app-pres-row label="City" :info="company.CompanyCity" />
                <app-pres-row label="Postcode" :info="company.CompanyPostcode" />
                <app-pres-row label="CountyRegion" :info="company.CompanyCountyRegion" />
                <app-pres-row label="State" :info="company.CompanyState" />
                <app-pres-row label="VATID" :info="company.CompanyVATID" />
                <app-pres-row label="email" :info="company.Companyemail" />
                <app-pres-row label="Phones" :info="company.CompanyPhones" />
                <app-pres-row label="BankName" :info="company.CompanyBankName" />
                <app-pres-row label="BankAddress" :info="company.CompanyBankAddress" />
                <app-pres-row label="BankIBAN" :info="company.CompanyBankIBAN" />
                <app-pres-row label="BankSWIFT" :info="company.CompanyBankSWIFT" />
              </v-card-text>
            </v-card>
         </v-col>

           <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4">
            <v-card>
              <v-card-title primary class="title">
                Director
              </v-card-title>
              <v-card-text>
                <app-pres-row label="Title" :info="company.CompanyDirectorTitle" />
                <app-pres-row label="First" :info="company.CompanyDirectorFirst" />
                <app-pres-row label="Last" :info="company.CompanyDirectorLast" />
                <app-pres-row label="Former" :info="company.CompanyDirectorFormer" />
                <app-pres-row label="State Residence" :info="company.CompanyDirectorStateResidence" />
                <app-pres-row label="Nationality" :info="company.CompanyDirectorNationality" />
                <app-pres-row label="Date Of Birth" :info="company.CompanyDirectorDateOfBirth" />
                <app-pres-row label="Business Occupation" :info="company.CompanyDirectorBusinessOccupation" />
                <app-pres-row label="Adress BuildingName/Number" :info="company.CompanyDirectorAdressBuildingNameNumber" />
                <app-pres-row label="Adress Street" :info="company.CompanyDirectorAdressStreet" />
                <app-pres-row label="City" :info="company.CompanyDirectorCity" />
                <app-pres-row label="Postcode" :info="company.CompanyDirectorPostcode" />
                <app-pres-row label="County/Region" :info="company.CompanyDirectorCountyRegion" />
                <app-pres-row label="Email" :info="company.CompanyDirectorEmail" />
                <app-pres-row label="Phone" :info="company.CompanyDirectorPhone" />
                <app-pres-row label="Contact Option" :info="company.CompanyDirectorContactOption" />
                
                <v-card-title primary class="title">
                  Contact person
                </v-card-title>
                <app-pres-row label="Title" :info="company.CompanySecretaryTitle" />
                <app-pres-row label="First" :info="company.CompanySecretaryFirst" />
                <app-pres-row label="Last" :info="company.CompanySecretaryLast" />
                <app-pres-row label="Former" :info="company.CompanySecretaryFormer" />
                <app-pres-row label="State Residence" :info="company.CompanySecretaryStateResidence" />
                <app-pres-row label="Nationality" :info="company.CompanySecretaryNationality" />
                <app-pres-row label="Date Of Birth" :info="company.CompanySecretaryDateOfBirth" />
                <app-pres-row label="Business Occupation" :info="company.CompanySecretaryBusinessOccupation" />
                <app-pres-row label="Adress Building Name/Number" :info="company.CompanySecretaryAdressBuildingNameNumber" />
                <app-pres-row label="Adress Street" :info="company.CompanySecretaryAdressStreet" />
                <app-pres-row label="City" :info="company.CompanySecretaryCity" />
                <app-pres-row label="Postcode" :info="company.CompanySecretaryPostcode" />
                <app-pres-row label="County/Region" :info="company.CompanySecretaryCountyRegion" />
                <app-pres-row label="Email" :info="company.CompanySecretaryEmail" />
                <app-pres-row label="Phone" :info="company.CompanySecretaryPhone" />
                <app-pres-row label="Contact Option" :info="company.CompanySecretaryContactOption" />
              </v-card-text>
            </v-card>
          </v-col>

           <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4">
            <v-card >
              <v-card-title primary class="title">
                Company Settings
              </v-card-title>
              <v-card-text>
                <app-pres-row label="Invoice Memo" :info="company.CompanyInvoiceMemo" />
                <app-pres-row label="Invoice Foregin Memo" :info="company.CompanyInvoiceForeginMemo" />
                <app-pres-row label="Delivery NoteMemo" :info="company.CompanyDeliveryNoteMemo" />
                <app-pres-row label="Delivery NoteForeginMemo" :info="company.CompanyDeliveryNoteForeginMemo" />
                <app-pres-row label="Invoice Def Days" :info="company.CompanyInvoiceDefDays" />
                <app-pres-row label="Invoice Place" :info="company.CompanyInvoicePlace" />
                <app-pres-row label="Invoice Type" :info="company.CompanyInvoiceType" />
                <app-pres-row label="Invoice Hint" :info="company.CompanyInvoiceHint" />
                <app-pres-row label="Invoice Speed" :info="company.CompanyInvoiceSpeed" />
                <app-pres-row3 label="Use Retail Price" :info="company.CompanyUseRetailPrice" />
                <app-pres-row3 label="Show Last Sale Buyer" :info="company.CompanyShowLastSaleBuyer" />
                <app-pres-row3 label="ShowWarehouseState" :info="company.CompanyShowWarehouseState" />
                <app-pres-row3 label="ShowInputPrice" :info="company.CompanyShowInputPrice" />
                <app-pres-row3 label="VATonPay" :info="company.CompanyVATonPay" />
                <app-pres-row3 label="Retail" :info="company.CompanyRetail" />
                <app-pres-row label="Default Warehouse" :info="company.CompanyDefaultWarehouse" />
                <app-pres-row label="PrinterSize" :info="company.CompanyPrinterSize" />
                <app-pres-row label="Input FilesPath" :info="company.CompanyInputFilesPath" />
                <app-pres-row label="Output FilesPath" :info="company.CompanyOutputFilesPath" />
                <app-pres-row label="Transfer Data FilesPath" :info="company.CompanyTransferDataFilesPath" />
                <app-pres-row label="Start Invoice Number" :info="company.CompanyStartInvoiceNumber" />
                <app-pres-row label="Start Bill Number" :info="company.CompanyStartBillNumber" />
                <app-pres-row label="Type" :info="company.CompanyType" />
                <app-pres-row label="DescriptionUp" :info="company.CompanyDescriptionUp" />
                <app-pres-row label="DescriptionDown" :info="company.CompanyDescriptionDown" />
                <app-pres-row label="Business Space" :info="company.CompanyBusinessSpace" />
                <app-pres-row label="Business Device" :info="company.CompanyBusinessDevice" />
                <app-pres-row label="Business Space Retail" :info="company.CompanyBusinessSpaceRetail" />
                <app-pres-row label="Business Device Retail" :info="company.CompanyBusinessDeviceRetail" />
                <app-pres-row3 label="Send Email Task" :info="company.CompanySendEmailTask" />
                <app-pres-row3 label="Show Warehouse State in every document" :info="company.CompanyShowWarehouse" />
                <app-pres-row label="Start From" :info="locStartFrom" />
                <app-pres-row label="Default Currency" :info="company.currencyType" />
                <app-pres-row3 label="Activ" :info="company.Activ" />
                <app-pres-row label="CreatedAt" :info="locCreatedAt" />
                <app-pres-row label="UpdatedAt" :info="locUpdatedAt" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

      </v-col>
   </v-row>
  </v-container>


</template>

<script>
import CompService from '@/services/CompService'
import PresRow2 from '@/snippets/PressRow2'
import PresRow3 from '@/snippets/PressRow3'
//import langEn from './PartnerDescEN'
//import langHr from './PartnerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'

export default {
  name: 'companyview',
  data () {
    return {
      msg: '',
      company: { },
      locStartFrom: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locLastLogin: '',
      locForgotDate: '',
      haveRight: 4,
      error: '',
      lang: {},
      langC: {},
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      //this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      //this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.companyid) {
      try {
        const dbCompany = await CompService.show(this.$store.state.companyid)
        this.company = dbCompany.data.company
        this.locStartFrom = dayjs(this.company.StartFrom).format('DD.MM.YYYY')
        this.locCreatedAt = dayjs(this.company.createdAt).format('DD.MM.YYYY HH:MM')
        this.locUpdatedAt = dayjs(this.company.updatedAt).format('DD.MM.YYYY HH:MM')
      } catch (error) {
        this.error = error
      }
    }
  },
  methods: {
    async complist () {
      try {
        await this.$router.push({
          name: 'company'
        })
      } catch (err) {
        this.error = err
      }
    },
    async compDelete () {
      try {
        await this.$router.push({
          name: 'companydelete'
        })
      } catch (error) {
        this.error = error
      }
    },
    async compEdit () {
      try {
        this.$store.dispatch('seteditItem', 1)
        await this.$router.push({
          name: 'companycreate'
        })
      } catch (error) {
        this.error = error
      }
    },
    async compEditLogo () {
      try {
        this.$store.dispatch('seteditItem', 1)
        await this.$router.push({
          name: 'companyedit'
        })
      } catch (error) {
        this.error = error
      }
    }
  },
  computed: {

  },
  watch: {

  },
  components: {
    appPresRow: PresRow2,
    appPresRow3: PresRow3
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
