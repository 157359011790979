<template>
<div>
  <v-container fluid grid-list-md>
          <v-snackbar
            v-model="snackbar"
            bottom
            :timeout="timeout"
            :color="color"
            >
            {{ text }}
          </v-snackbar>
          <v-card>
            <v-card-title>
              <span class="headline"> {{ lang.bankStatment }}:{{bsNr}}, {{ lang.bankStatmentDetailNr }} {{showBS.bankStatmentNumberDetail}} </span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"

                >
                <v-row no-gutters>
                    <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                      <v-text-field
                        v-model="showBS.Description"
                        :label="lang.Description"
                        disabled
                      ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="10" lg="10" xl="10" class="title pr-4">
                      {{showBS.partnerName}}
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                      {{showBS.partnerBankIBAN}}
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pr-3">
                      <v-btn color="blue" text @click="checkByIban">{{lang.checkPartnerByIban}}</v-btn>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="pr-3">
                    {{showPr.partnerName}}, {{showPr.partnerStreet}}, {{showPr.partnerPostcode}} {{showPr.partnerCity}}
                  </v-col>
                </v-row>
                
                <v-row no-gutters>
                  <v-col cols="12" sm="4" md="2" lg="2" xl="2" class="pr-4">
                      <v-text-field
                        v-model="debit"
                        :label="lang.Debit"
                        outline
                        disabled
                      ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="2" lg="2" xl="2">
                      <v-text-field
                        v-model="credit"
                        :label="lang.Credit"
                        outline
                        disabled
                      ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="2" lg="2" xl="2" class="pr-3">
                    {{langC.VAT}} {{showPr.partnerVATID}}
                  </v-col>
                   
                </v-row>

                <v-row no-gutters>
                  <v-col cols="12" sm="4" md="2" lg="2" xl="2" class="pr-3">
                    {{showBS.bankReference1}}
                  </v-col>
                  <v-col cols="12" sm="4" md="2" lg="2" xl="2" class="pr-3">
                    {{showBS.bankCall1}}
                  </v-col>
                  
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="4" md="2" lg="2" xl="2" class="pr-3">
                    {{showBS.bankReference2}}
                  </v-col>
                  <v-col cols="12" sm="4" md="2" lg="2" xl="2" class="pr-3">
                    {{showBS.bankCall2}}
                  </v-col>
                  
                </v-row>
                  
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>

  </v-container>
  </div>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import PartnerService from '@/services/PartnerService'

  export default {
    name: 'mlsideitem',
    data: () => ({
      lang: {},
      langC: {},
      showBS: '',
      debit: 0,
      credit: 0,
      bsNr: 0,
      showPr: {},
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
    }),

    computed: {

    },

    watch: {

    },
    async mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      this.showBS = this.$store.state.mainledgerSideItem ? this.$store.state.mainledgerSideItem : ''
      this.debit = this.showBS.bankStatmentDebit ? this.formatPrice(this.showBS.bankStatmentDebit) : 0
      this.credit = this.showBS.bankStatmentCredit ? this.formatPrice(this.showBS.bankStatmentCredit) : 0
      this.bsNr = this.$store.state.mainledgerActiv.bankStatmentNumber ? parseInt(this.$store.state.mainledgerActiv.bankStatmentNumber) : ''
    },

    created () {

    },

    methods: {
      formatPrice (value) {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      formatPriceHR (value) {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      partnerError (msg, color){
        this.text = msg
        this.color = color
        this.snackbar = true
        this.timeout = 4000
      },
      async copyPartnerIBAN() {
        if (this.showBS.partnerBankIBAN) {
          await this.$store.dispatch('setPartnerIBAN', this.showBS.partnerBankIBAN)
        }
      },
      async checkByIban() {
          if (this.showBS.partnerBankIBAN){
            const cIban = {
              partnerBankIBAN: this.showBS.partnerBankIBAN
            }
            this.error = null
            this.showPr = {}
            await PartnerService.checkIBAN(cIban)
            .then(res => {
              if (res.data.partner) {
                const pr = res.data.partner
                this.showPr.partnerId = pr.id ? pr.id : null
                this.showPr.partnerName = pr.partnerName ? pr.partnerName : null
                this.showPr.partnerNameShort = pr.partnerNameShort ? pr.partnerNameShort : null
                this.showPr.partnerNameDatabase = pr.partnerNameDatabase ? pr.partnerNameDatabase : null
                this.showPr.partnerVATID = pr.partnerVATID ? pr.partnerVATID : null
                this.showPr.partnerStreet = pr.partnerStreet ? pr.partnerStreet : null
                this.showPr.partnerCity = pr.partnerCity ? pr.partnerCity : null
                this.showPr.partnerPostcode = pr.partnerPostcode ? pr.partnerPostcode : null
                this.$store.dispatch('setPartnerid', this.showPr.partnerId)
                this.$store.dispatch('setPartnerVATID', pr.partnerVATID)
                this.partnerError(this.lang.partnerExist, 'green')
              } else {
                this.partnerError(this.lang.partnerNOTexist, 'red')
              }
            })
            .catch(err => {
              // eslint-disable-next-line
              console.log('Partner not exist', err)
              this.partnerError(this.lang.partnerNOTexist, 'red')
            })
          }
      }


    },
  }
</script>