<template>
<div>
  <v-row align="center" class="list px-3 mx-auto">
  <v-col cols="4" sm="2">
      <v-select
        v-model="searchOption"
        :items="searchOptions"
        :label="langC.SearchBy"
        clearable
        @change="handleSearchOption"
      ></v-select>
    </v-col>
    <v-col cols="12" sm="2">
      <v-text-field v-model="searchName" :label="langC.SearchBy"></v-text-field>
    </v-col>
    <v-col cols="12" sm="1">
      <v-btn small @click="page = 1; retrieveSerials();">
        {{langC.Search}}
      </v-btn>
    </v-col>
    <v-col cols="5" sm="5">
      <v-pagination
        v-model="page"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      ></v-pagination>
      
    </v-col>
    <v-col cols="4" sm="1">
      Total:{{totalItems}}
    </v-col>
    <v-col cols="4" sm="1">
      <v-select
        v-model="pageSize"
        :items="pageSizes"
        label="Items per Page"
        @change="handlePageSizeChange"
      ></v-select>
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="12" sm="12">
      <v-card class="mx-auto" tile>
        <v-card-title>Serials</v-card-title>

        <v-data-table
          dense
          :headers="headers"
          :items="serials"
          disable-pagination
          :hide-default-footer="true"
          class="elevation-1"
        >

        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title class="mr-3">{{lang.Serials}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">

              <template v-slot:activator="{ on }">
                <v-btn fab dark class="indigo" v-on="on">
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </template>


              <v-card>
                <v-card-title>
                  <span class="headline">{{ lang.Serial }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form
                      ref="form"
                      v-model="valid"
                      lazy-validation
                    >

                      <v-row>
                        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-1">
                        <v-text-field
                            dense
                            :label="lang.documentName"
                            v-model="editedItem.documentName"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-1">
                          <v-text-field
                            dense
                            :label="lang.documentId"
                            v-model="editedItem.documentId"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-1">
                        <v-text-field
                            dense
                            :label="lang.documentIdGeneral"
                            v-model="editedItem.documentIdGeneral"
                            type="number"
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="mr-3 pa-0">
                          <v-dialog
                              ref="dialog1"
                              persistent
                              v-model="modal1"
                              width="290px"
                              :return-value.sync="editedItem.documentDate"
                            >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="editedItem.documentDate"
                                :label="lang.documentDate"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>

                              <v-date-picker v-model="editedItem.documentDate" scrollable>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="modal1 = false">{{langC.Cancel}}</v-btn>
                                <v-btn color="primary" @click="$refs.dialog1.save(editedItem.documentDate)">OK</v-btn>
                              </v-date-picker>
                            </v-dialog>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="pr-1">
                        <v-autocomplete
                            :items="partners"
                            v-model="selectPa"
                            :label="lang.SelectPartner"
                            :search-input.sync="search"
                            item-text="partnerName"
                            item-value="id"
                            return-object
                            persistent-hint
                            clearable
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="pt-6">
                          <v-text-field
                            dense
                            :label="lang.partnerNameDatabase"
                            v-model="editedItem.partnerNameDatabase"
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="pr-1">
                          <v-autocomplete
                            :items="itemsLoc"
                            v-model="selectIL"
                            :label="lang.ItemSelect"
                            item-text="itemByID"
                            item-value="itemID"
                            clearable
                            return-object
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="pr-1">
                          <v-text-field
                            dense
                            :label="lang.itemName"
                            v-model="editedItem.itemName"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
                        <v-text-field
                            dense
                            :label="lang.itemUnit"
                            v-model="editedItem.itemUnit"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="pr-1">
                        <v-text-field
                            dense
                            :label="lang.ItemCode"
                            v-model="editedItem.ItemCode"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="mr-3 pa-0">
                          <v-dialog
                              ref="dialog2"
                              persistent
                              v-model="modal2"
                              width="290px"
                              :return-value.sync="editedItem.StartDate"
                            >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="editedItem.StartDate"
                                :label="lang.StartDate"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>

                              <v-date-picker v-model="editedItem.StartDate" scrollable>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="modal2 = false">{{langC.Cancel}}</v-btn>
                                <v-btn color="primary" @click="$refs.dialog2.save(editedItem.StartDate)">OK</v-btn>
                              </v-date-picker>
                            </v-dialog>
                        </v-col>
                        <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="mr-1 pt-4">
                        <v-text-field
                            dense
                            :label="lang.FreeF1"
                            v-model.number="editedItem.FreeF1"
                            type = "number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pa-0">
                          <v-dialog
                              ref="dialog3"
                              persistent
                              v-model="modal3"
                              width="290px"
                              :return-value.sync="editedItem.EndDate"
                            >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="editedItem.EndDate"
                                :label="lang.EndDate"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>

                              <v-date-picker v-model="editedItem.EndDate" scrollable>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="modal3 = false">{{langC.Cancel}}</v-btn>
                                <v-btn color="primary" @click="$refs.dialog3.save(editedItem.EndDate)">OK</v-btn>
                              </v-date-picker>
                            </v-dialog>
                        </v-col>

                      </v-row>

                      <v-row v-if="errorMatch">                    
                          <div class="red" v-html="errorMatch" />
                      </v-row>
                      <v-row>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                        <v-btn color="success" text @click="save">{{langC.Save}}</v-btn>  
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>
    
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

         <template v-slot:[`item.documentDate`]="{ item }">
              <span>{{ localDate(item.documentDate) }}</span>
          </template>
          <template v-slot:[`item.StartDate`]="{ item }">
              <span>{{ localDate(item.StartDate) }}</span>
          </template>
          <template v-slot:[`item.EndDate`]="{ item }">
              <span>{{ localDate(item.EndDate) }}</span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
  </div>
</template>

<script>
import SerialsService from '@/services/SerialsService'
import {defSerials, defNewSerials} from '../Serials/serialsHelper'
import PartnerService from '@/services/PartnerService'
import ItemService from '@/services/ItemService'
import langEn from './serialsDescEn'
import langHr from './serialsDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import { testLastUpdate } from '../../services/TestLastUpdate'
import dayjs from 'dayjs'

export default {
  name: 'serialslist',
  data () {
    return {
      lang: {},
      langC: {},
      serials: [],
      searchName: "",
      headers: [],
      page: 1,
      totalPages: 0,
      pageSize: 30,
      pageSizes: [10, 30, 50],
      searchOption: '',
      searchOptions: [],
      totalItems: 0,
      modal: false,
      dialog: false,
      modal1: false,
      dialog1: false,
      modal2: false,
      dialog2: false,
      modal3: false,
      dialog3: false,
      selectPa: {},
      partners: [],
      itemsLoc: [],
      itemLoc: {},
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      mainQuery: {},
      errorMatch: null,
      valid: true,
      search: null,
      selectIL: {},

    }
  },
  async mounted () {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      this.searchOptions.push(
        {text: this.lang.partnerNameDatabase , value: 'partnerNameDatabase', width: '150px'},
        {text: this.lang.itemDescription, value: 'itemDescription', width: '150px',},
        {text: this.lang.itemID, value: 'itemID', width: '50px',},
        {text: this.lang.itemName, value: 'itemName', width: '400px',},
      )

      this.headers.push(
        {text: this.lang.documentName, value: 'documentName', width: '200px', },
        {text: this.lang.documentDate, value: 'documentDate', width: '120px'},
        {text: this.lang.partnerNameDatabase , value: 'partnerNameDatabase', width: '150px'},
        {text: this.lang.itemID, value: 'itemID', width: '50px',},
        {text: this.lang.itemName, value: 'itemName', width: '400px',},
        {text: this.lang.itemUnit, value: 'itemUnit', width: '50px', },
        {text: this.lang.itemDescription, value: 'itemDescription', width: '150px',},
        {text: this.lang.StartDate, value: 'StartDate', width: '100px'},
        {text: this.lang.EndDate, value: 'EndDate', width: '100px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
        {text: this.lang.Actions, value: 'action', sortable: false },
      )

      this.defaultItem = {...defSerials(), ...defNewSerials()}
      await testLastUpdate()
      if (this.$store.state.partnerStore) {
        this.partners = this.$store.state.partnerStore
      }
      this.itemsLoc = this.$store.state.itemStore ? this.$store.state.itemStore : []
      this.$store.dispatch('seteditItem', 0)
      this.retrieveSerials()
  },
  methods: {

      retrieveSerials() {
        this.editedItem = Object.assign({}, this.defaultItem)
        
        const query = {}
        query.CompanyId = this.$store.state.companyid
        query.searchName = this.searchName ? this.searchName.trim() : null
        query.page = this.page ? this.page - 1 : 0
        query.pageSize = this.pageSize ? this.pageSize : 30
        query.searchItem = this.searchOption ? this.searchOption : null

        SerialsService.index2(query)
          .then((response) => {
            const { data } = response
            if (data && data.serials !== undefined) {
              this.serials = data.serials
              this.totalItems = data.totalPages
              this.totalPages = parseInt(data.totalPages / this.pageSize) + 1
            }
            
          })
          .catch((e) => {
            // eslint-disable-next-line
            console.log(e);
          });
      },

      handlePageChange(value) {
        this.page = value;
        this.retrieveSerials();
      },

      handlePageSizeChange(size) {
        this.pageSize = size;
        this.page = 1;
        this.retrieveSerials();
      },

      handleSearchOption(option) {
        this.searchOption = option

      },
      editItem (item) {
        this.errorMatch = false
        this.editedIndex = this.serials.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      async deleteItem (item) {
        try {
          const index = this.serials.indexOf(item)
          const warningMessage = item.CalDate
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.serials.splice(index, 1)) {
            const deleteItem = {}
            deleteItem.id = item.id
            deleteItem.CompanyId = this.$store.state.companyid
            await SerialsService.delete(deleteItem)
            this.retrieveSerials()
          }
        } catch(err) {
          this.error = err
        }
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
      async save () {
        try {
           let doneOK = false
           if (this.editedIndex > -1) {
              await SerialsService.put(this.editedItem)
              doneOK = true
            } else {
              //this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              //this.editedItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
              this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
              //this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              //this.editedItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : ''
              await SerialsService.post(this.editedItem)
              doneOK = true
            }
            if (doneOK) {
              this.retrieveSerials()
              this.close()
            }
        } catch(err) {
          this.error = err
        } 
      },
      localDate(dateToFormat) {
        return dateFormat(dateToFormat)
      },
      async findPartner (partnerVATID) {
        if (partnerVATID) {
          // in partners array I dont have all required data, for faster serach 
          // const locPartner = this.partners.find(partner => (partner.id === partnerId)) and then need to contact API
          if ((partnerVATID) !== (this.editedItem.partnerVATID)) {
            this.saving = true
            const locPartner = (await PartnerService.show(partnerVATID)).data.partner
            this.saving = false
            this.editedItem.partnerId = locPartner.id
            this.editedItem.partnerNameDatabase = locPartner.partnerNameDatabase ? locPartner.partnerNameDatabase : locPartner.partnerName 
          }
        }
      },
      findItem () {
        const emObj = {}
        this.mainQuery2 = {...emObj}
        this.mainQuery2.CompanyId = this.$store.state.companyid
        this.mainQuery2.itemID = this.selectIL.itemID
        // const myObjStr = JSON.stringify(this.mainQuery2)
        this.findIt()
      },
      async findIt () {
        try {
          await ItemService.choose(this.mainQuery2).then(res => {
            this.itemLoc = res.data.item ? res.data.item : null
          })
          .catch(err => {
            this.error = ' Greska 1. Ne mogu naci artikla!!' + err
            this.typeError = 'error'
          })
          //this.itemLoc = (await ItemService.choose(this.mainQuery2)).data.item
          //this.itemLoc = await this.itemsLoc.find(itemM => itemM.itemID === this.mainQuery2.itemID)
          if (this.itemLoc) {
              this.editedItem.itemID = this.itemLoc.itemID
              this.editedItem.itemName = this.itemLoc.itemName
              this.editedItem.ItemNameShort = this.itemLoc.ItemNameShort
              this.editedItem.ItemEANCode = this.itemLoc.ItemEANCode
              this.editedItem.itemGroup = this.itemLoc.itemGroup
              this.editedItem.itemGroupName = this.itemLoc.GroupName ? this.itemLoc.GroupName : ''
              this.editedItem.itemDescription = this.itemLoc.itemDescription
              this.editedItem.itemUnit = this.itemLoc.itemUnit
              this.selectIL =
              {
                itemByID: (this.editedItem.itemID + ' ' + this.editedItem.itemName),
                itemID: this.editedItem.itemID
              } 
            }
        } catch (error) {
          this.error = ' Greska Ne mogu naci artikla'
          this.typeError = 'error'
          // eslint-disable-next-line
          console.log(' greska Ne mogu naci artikla', error)
        }
      },
  },
  computed: {
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    'selectPa' (val) {
    if (val) {
        val.partnerVATID && this.findPartner(val.partnerVATID)
      }
    },
    'selectIL.itemID' () {
      this.findItem() 
    },
    'editedItem.FreeF1' () {
      if (this.editedItem.FreeF1 > 0 && this.editedItem.StartDate) {
        this.editedItem.EndDate = dayjs(this.editedItem.StartDate).add(this.editedItem.FreeF1, 'M').format('YYYY-MM-DD')
      }
    }
  },
  components: {
 
  }
}
</script>
