<template>
<div>
  <v-container fluid grid-list-md>
   <v-row>

    </v-row>
  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="stickers"
    :search="search"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="50"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title class="mr-3">{{lang.Stickers}}</v-toolbar-title>
         <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="printAna" class="mr-1">{{langC.Print}}</v-btn>
          <v-select
            class="mt-5"
            :items="oneten"
            :label="lang.emptyLines"
            v-model="emptyLines"
          ></v-select>
          <v-checkbox
            v-model="printCashDisc"
            :label="lang.printCashDiscount"
          ></v-checkbox>


        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>


          <v-card>
            <v-card-title>
              <span class="headline">{{ lang.Sticker }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >

                  <v-row>
                    <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.documentId"
                        v-model="editedItem.documentId"
                        type="number"
                      ></v-text-field>
                    </v-col>
                     <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-1">

                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="mr-3 pa-0">
                      <v-dialog
                          ref="dialog1"
                          persistent
                          v-model="modal1"
                          width="290px"
                          :return-value.sync="editedItem.documentDate"
                        >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="editedItem.documentDate"
                            :label="lang.documentDate"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>

                          <v-date-picker v-model="editedItem.documentDate" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="modal1 = false">{{langC.Cancel}}</v-btn>
                            <v-btn color="primary" @click="$refs.dialog1.save(editedItem.documentDate)">OK</v-btn>
                          </v-date-picker>
                        </v-dialog>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="pr-1">
                      <v-autocomplete
                        :items="itemsLoc"
                        v-model="selectIL"
                        :label="lang.ItemSelect"
                        item-text="itemByID"
                        item-value="itemID"
                        clearable
                        return-object
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.ItemName"
                        v-model="editedItem.itemName"
                      ></v-text-field>
                    </v-col>
                     <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.ItemUnit"
                        v-model="editedItem.itemUnit"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                   <v-row>
                    <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.ItemPrice"
                        v-model="editedItem.itemPrice"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-1">
                      <v-text-field
                        dense
                        :label="lang.ItemPriceWithVAT"
                        v-model="editedItem.itemPriceWithVAT"
                        type="number"
                      ></v-text-field>
                    </v-col>
                     <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-1">

                    </v-col>
                  </v-row>


                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn color="success" text @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.documentDate`]="{ item }">
        <span>{{ localDate(item.documentDate) }}</span>
    </template>


    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      {{ lang.CalEmpty }}
    </template>
  </v-data-table>
  <v-row>
      <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
        <StickersPrint ref="prnt" :ana="ana"/>
      </v-col>
  </v-row>
  </v-container>

  </div>
</template>

<script>

import langEn from './serialsDescEn'
import langHr from './serialsDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import ItemService from '@/services/ItemService'
import StickersPrint from './StickersPrint'

  export default {
    name: 'stickersCRUD',
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal1: false,
      dialog1: false,
      modal2: false,
      dialog2: false,
      modal3: false,
      dialog3: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {
      },
      dialog: false,
      headers: [],
      stickers: [],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {
      },
      privitems: [],
      valid: true,
      privateP: true,
      selectPa: {},
      partners: [],
      search: null,
      itemsLoc: [],
      itemLoc: {},
      CompanyId: 0,
      selectIL: {},
      mainQuery2: {}, 
      ana: {},
      newObj: {},
      keyLoc: 0,
      emptyLines: 0,
      oneten: [0,1,2,3,4,5,6,7,8,9,10],
      printCashDisc: false
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New': 'Edit'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      'selectIL.itemID' () {
        if (this.selectIL && this.selectIL.itemID) {
          this.findItem() 
        }
      },
    },
    components: {
      StickersPrint
    },
    async mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      this.itemsLoc = this.$store.state.itemStore ? this.$store.state.itemStore : []
      this.stickers = this.$store.state.stickers ? this.$store.state.stickers : []

      this.headers.push(
        {text: this.lang.documentName, value: 'documentId', width: '200px', },
        {text: this.lang.documentDate, value: 'documentDate', width: '100px',type: 'date', dateInputFormat: 'YYYY-MM-DD', dateOutputFormat: 'DD.MM.YYYY'},
        {text: this.lang.partnerNameDatabase , value: 'partnerNameDatabase', width: '150px'},
        {text: this.lang.ItemCode, value: 'ItemCode', width: '150px',},
        {text: this.lang.itemID, value: 'itemID', width: '50px',},
        {text: this.lang.itemName, value: 'itemName', width: '400px',},
        {text: this.lang.itemUnit, value: 'itemUnit', width: '50px', },
        {text: this.lang.itemPrice, value: 'itemPrice', width: '50px', },
        {text: this.lang.itemPriceWithVAT, value: 'itemPriceWithVAT', width: '50px', },
        {text: this.lang.itemDirectDuty1, value: 'itemDirectDuty1', width: '50px', },
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
        {text: this.lang.Actions, value: 'action', sortable: false },
      )

    },

    created () {
      this.stickers = []
      this.initialize()
    },

    methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      async printAna() {
        const newObj = {}
        this.ana = {...newObj}
        this.ana.items = this.stickers ? this.stickers : []
        this.ana.emptyLines = this.emptyLines
        this.ana.printCashDiscount = this.printCashDisc
        await this.$store.dispatch('setPrintObj', this.ana)
        await this.$refs.prnt.print()
      },
      async showAll () {
        try {
          this.saving = true
          this.stickers = this.$store.state.stickers ? this.$store.state.stickers : []
          this.saving = false
          this.errorMatch = false

          this.editedItem = Object.assign({}, this.defaultItem)
        } catch (err) {
          this.error = err
        }
      },
      async initialize () {
        try {
          this.saving = true
          //this.serials = sortByDateDesc(pItems, 'documentDate')
          this.saving = false
          this.errorMatch = false
          this.defaultItem = this.defNewSticker()
          this.editedItem = {...this.defaultItem}
          this.selectIL = {...this.newObj}
        } catch (err) {
          this.error = err
        }
      },
      editItem (item) {
        this.selectIL = {...this.newObj}
        this.errorMatch = false
        this.editedIndex = this.stickers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        if (this.editedItem.itemID) {
          this.selectIL =
              {
                itemByID: (this.editedItem.itemID + ' ' + this.editedItem.itemName),
                itemID: this.editedItem.itemID
              }
        }
        this.dialog = true
        this.confpassword = ''
      },
      async deleteItem (item) {
        try {
          const index = this.stickers.indexOf(item)
          const warningMessage = item.itemName
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.stickers.splice(index, 1)) {
            const deleteItem = {}
            deleteItem.id = item.id
            deleteItem.CompanyId = this.$store.state.companyid
            this.initialize()
          }
        } catch(err) {
          this.error = err
        }
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
        this.selectIL = {...this.newObj}
      },

      checkMatch () {
        if (this.editedItem.password && this.confpassword &&  this.confpassword === this.editedItem.password) {
          this.errorMatch = null
          this.save()
        } else {
          this.errorMatch = 'Error. Passoword and Confirmation Password are NOT equal!'
          this.valid = false        
        }
      },

      async save () {
        try {
           let doneOK = false
           if (this.editedIndex > -1) {
              // put
              Object.assign(this.stickers[this.editedIndex], this.editedItem)
              this.$store.dispatch('setStickers', this.stickers)
              doneOK = true
            } else {
              //this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              //this.editedItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
              this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
              //this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              //this.editedItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : ''
              //post
              this.stickers.push(this.editedItem)
              this.$store.dispatch('setStickers', this.stickers)
              doneOK = true
            }
            if (doneOK) {
              
              this.close()
            }
        } catch(err) {
          this.error = err
        }
       
      },
      localDate(dateToFormat) {
        return dateFormat(dateToFormat)
      },
      findItem () {
        const emObj = {}
        this.mainQuery2 = {...emObj}
        this.mainQuery2.CompanyId = this.$store.state.companyid
        this.mainQuery2.itemID = this.selectIL.itemID
        console.log("🚀 ~ file: StickersCRUD.vue ~ line 438 ~ findItem ~ this.selectIL", this.selectIL)
        // const myObjStr = JSON.stringify(this.mainQuery2)
        this.findIt()
      },
      async findIt () {
          try {
            await ItemService.choose(this.mainQuery2).then(res => {
              this.itemLoc = res.data.item ? res.data.item : null
            })
            .catch(err => {
              this.error = ' Greska 1. Ne mogu naci artikla!!' + err
              this.typeError = 'error'
            })
            //this.itemLoc = (await ItemService.choose(this.mainQuery2)).data.item
            //this.itemLoc = await this.itemsLoc.find(itemM => itemM.itemID === this.mainQuery2.itemID)
            if (this.itemLoc) {
                this.editedItem.itemID = this.itemLoc.itemID
                this.editedItem.itemName = this.itemLoc.itemName
                this.editedItem.ItemNameShort = this.itemLoc.ItemNameShort
                this.editedItem.ItemEANCode = this.itemLoc.ItemEANCode
                this.editedItem.itemGroup = this.itemLoc.itemGroup
                this.editedItem.itemGroupName = this.itemLoc.GroupName ? this.itemLoc.GroupName : ''
                this.editedItem.itemDescription = this.itemLoc.itemDescription
                this.editedItem.itemUnit = this.itemLoc.itemUnit
                this.editedItem.itemPrice = this.itemLoc.itemPrice
                this.editedItem.itemPriceWithVAT = this.itemLoc.itemPriceWithVAT
                this.selectIL =
                {
                  itemByID: (this.editedItem.itemID + ' ' + this.editedItem.itemName),
                  itemID: this.editedItem.itemID
                } 
              }
          } catch (error) {
            this.error = ' Greska Ne mogu naci artikla'
            this.typeError = 'error'
            // eslint-disable-next-line
            console.log(' greska Ne mogu naci artikla', error)
          }
        },

        defNewSticker () {
          const defObj = {}
          defObj.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
          defObj.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
          defObj.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
          defObj.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
          defObj.email = this.$store.state.user.email ? this.$store.state.user.email : ''
          defObj.documentName = null
          defObj.documentSide = null
          defObj.documentAnalysis = null
          defObj.documentId = null
          defObj.documentIdGeneral = null
          defObj.documentDate = null
          defObj.documentTime = null
          defObj.partnerId = null
          defObj.partnerNameDatabase = null
          defObj.HomeCurrency = null
          defObj.ForeignCurrency = null
          defObj.ExRate = null
          defObj.itemID = null
          defObj.itemName = null
          defObj.ItemNameShort = null
          defObj.ItemCode = null
          defObj.ItemEANCode = null
          defObj.itemGroup = null
          defObj.itemGroupName = null
          defObj.itemDescription = null
          defObj.itemUnit = null
          defObj.itemPrice = null
          defObj.itemPriceWithVAT = null
          defObj.itemDirectDuty1 = null
          defObj.ItemWarehouse = null
          defObj.WareHouseUpdateBy = null
          defObj.FreeF1 = 0
          defObj.FreeF2 = 0
          defObj.FreeF3 = 0
          defObj.FreeF4 = 0
          defObj.FreeF5 = 0
          defObj.FreeF6 = 0
          return defObj
    },
      
    }
  }
</script>