<template>
  <div class="documentprintsalesservice">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import BankAccountService from '@/services/BankAccountService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
// import { parseNum } from '@/snippets/allSnippets'
import documentExportName from '@/snippets/documentExportName'
import dayjs from 'dayjs'

export default {
  name: 'documentprintsalesservice',
  props: ['useMemo', 'printID'],
  data () {
    return {
      msg: '',
      documentName: '',
      documentName2: '',
      document: {},
      havePath: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locDocument: {},
      fields1: [ 'itemName', 'ItemCode', 'itemUnit', 'itemService', 'itemPrice', 'itemPcsNeutral', 'itemBaseAmount', 'itemDiscountPercentage1', 'itemBaseAmountNoVAT', 'itemAmountWithVAT' ],
      items1: [
      ],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      fields2: [ 'vat', 'base', 'amount' ],
      items2: [
        { vat: '5%', base: 100.00, amount: 5.00 },
        { vat: '13%', base: 200.00, amount: 26.00 },
        { vat: '25%', base: 400.00, amount: 100.00 }
      ],
      comp: {},
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        databaseDocID: 0
      },
      order: 0,
      locDocumentName: '',
      loCdocumentDate: '',
      loCorderDate: '',
      loCdocumentInvoiceStartDate: '',
      loCdocumentInvoiceTime: '',
      loCdocumentInvoiceEndDate: '',
      locDocumentConnFrom: null,
      loCdocumentConnDate: '',
      loCdeliveryDate: '',
      loCorderTime: '',
      loCdocumentConnTime: '',
      loCdeliveryTime: '',
      locDocumentId: '',
      output: null,
      documentNameLoc: '',
      documentNameExport: '',
      docDefinition: null,
      profitLoss: 0,
      profitLossP: '',
      haveSmallLogo: false,
      pP1: [],
      pP2: [],
      pP3: [],
      pP4: [],
      pP5: [],
      pP6: [],
      pP7: [],
      pP8: [],
      pP9: [],
      pP10: [],
      pD1: [],
      pD2: [],
      pD3: [],
      pD4: [],
      pD5: [],
      pD6: [],
      pD7: [],
      pD8: [],
      pD9: [],
      pD10: [],
      pT1: [],
      pT2: [],
      pT3: [],
      pT4: [],
      pT5: [],
      pT6: [],
      pT7: [],
      pT8: [],
      pT9: [],
      pT10: [],
      pC1: [],
      pC2: [],
      pC3: [],
      pC4: [],
      pC5: [],
      pC6: [],
      pC7: [],
      pC8: [],
      pC9: [],
      pC10: [],
      pC11: [],
      pC1tot: 0,
      pC2tot: 0,
      pC3tot: 0,
      pC4tot: 0,
      pC5tot: 0,
      pC6tot: 0,
      pC7tot: 0,
      pC8tot: 0,
      pC9tot: 0,
      pC10tot: 0,
      pD1tot: 0,
      pD2tot: 0,
      pD3tot: 0,
      pD4tot: 0,
      pD5tot: 0,
      pD6tot: 0,
      pD7tot: 0,
      pD8tot: 0,
      pD9tot: 0,
      pD10tot: 0,
      pTtt1: 0,
      pTtt2: 0,
      pTtt3: 0,
      pTtt4: 0,
      pTtt5: 0,
      pTtt6: 0,
      pTtt7: 0,
      pTtt8: 0,
      pTtt9: 0,
      pTtt10: 0,
      pTtt11: 0,
      pSum00: 0,
      pSum01: 0,
      pSum1: 0,
      pSum2: 0,
      pSum3: 0,
      pSum4: 0,
      pSum5: 0,
      peSum00: 0,
      peSum01: 0,
      peSum1: 0,
      peSum2: 0,
      peSum3: 0,
      peSum4: 0,
      peSum5: 0,
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      langE: {},
      saving: false,
      docDefinition2: {},
      content2: [],
      logoType: 0,
      printSmall: false, 
      printNoPrice: false,
      itemdesc: '',
      paycash: '',
      paycard: '',
      payother: '',
      Canvas1: null,
      Canvas2: null,
      slika: null,
      tekstHUB: null,
      exportDoc: false, 
      printItemID: true,
      desDocumentDate: '',
      desStartDate: '',
      desDeadLine: '',
      hmCR: '',
      exCR: '',
      banks: [],
      bankDesc: null,
      SWIFT: '',
      printType : '',
      printSize: 0,
      paperWidth: 0, 
      lineWidth: 0,

    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = {...langEn}
      this.langC = {...commEn}
    }
    if (this.$store.state.language === 'HR') {
      this.lang = {...langHr}
      this.langC = {...commHr}
    }
 
    if (this.$store.state.documentActivHead) {
      this.document = await this.$store.state.documentActivHead
      this.documentNameLoc = await this.$store.state.documentActivLoc
    }
    this.exportDoc = this.$store.state.documentActivHead.exportDoc
    this.hmCR = this.document.documentHomeCurrency ? this.document.documentHomeCurrency : 'Kn'
    this.exCR = this.document.documentForeignCurrency ? this.document.documentForeignCurrency : 'EUR'

    this.langE = {...langEn}

    this.printSmall = false
    if (this.$store.state.printSmall === true) {  
      this.printSmall = true
    }

  },
  methods: {
    async print () {

      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.BusinessYear = this.$store.state.businessYear
      }
      this.banks = (await BankAccountService.choose(this.mainQuery)).data.bankAccounts
      console.log('Print Vehicle Type ',this.$store.state.typePOSName)

      this.printType = this.$store.state.typePOSName ? this.$store.state.typePOSName : null
      this.printSize = 0

      // this.printSmall = false
      // this.printNoPrice = false
      // this.exportDoc = this.$store.state.documentActivHead.exportDoc
      // if( this.$store.state.documentActivHead.documentName === 'InvoiceSales') {
      //   this.printItemID = this.printID && this.printID === true ? true : false
      // }
      // if (this.exportDoc) {
      //   this.desDocumentDate = this.exportDoc ? this.lang.DocumentDate + '\n' + 'Issue date:' : this.lang.DocumentDate
      //   this.desStartDate = this.exportDoc ? this.lang.StartDate + '\n' + 'From date:' : this.lang.StartDate
      //   this.desDeadLine = this.exportDoc ? this.lang.DeadLine + '\n' + 'Currency date:' : this.lang.DeadLine
      // }

      // if (this.$store.state.printSmall === true) {
      //   this.printSmall = true 
      // }
      // if (this.$store.state.printNoPrice === true) {
      //   this.printNoPrice = true
      // }
      this.prepareData()
      // if (this.$store.state.typePOSPrint === 100) {
      //   this.printCustomDoc()
      // }
      // if (this.$store.state.typePOSPrint !== 100) {
      //   if (this.printSmall === true) {
      //     this.printSmallDoc()
      //   } else {
      //     this.printTestDoc()
      //   }
      // }
      this.init()
      this.printDoc()
    },

    init() {
      if (this.printType === 'confirmPOS' || this.printType === 'confirmA6' || this.printType === 'confirmA5') {
          this.documentName = 'Potvrda primitka na servis '
      }
      if (this.printType === 'proformaPOS' || this.printType === 'proformaA6' || this.printType === 'proformaA5') {
          this.documentName = 'Predračun servisa '
      }
      if (this.printType === 'mechanicPOS' || this.printType === 'mechanicA6' || this.printType === 'mechanicA5') {
          this.documentName = 'Radni nalog za servis'
      }
      if (this.useMemo) {
        // Memo can print only on A5 landscape or A4 portrait
        this.initDocumentMemo()
      }
      if (!this.useMemo) {
        if (this.printType === 'confirmPOS' || this.printType === 'proformaPOS' || this.printType === 'mechanicPOS') {
          this.paperWidth = 180
          this.lineWidth = 100
          this.printSize = 1
          this.initDocumentPOS()
        }
        if (this.printType === 'confirmA6' || this.printType === 'proformaA6' || this.printType === 'mechanicA6') {
          this.paperWidth = 380
          this.lineWidth = 260
          this.printSize = 2
          this.initDocumentSmall()
        }
        if (this.printType === 'confirmA5' || this.printType === 'proformaA5' || this.printType === 'mechanicA5') {
          this.paperWidth = 720
          this.lineWidth = 530
          this.printSize = 3
          this.initDocument()
        }
       
        if (this.logoType === 0) {
          this.addHeaderNoLogo()
        }
        if (this.logoType === 1) {
          // this.addHeaderWithLogoLeftSmall() - should be tested
          this.addHeaderWithLogoLeft()
        }
        if (this.logoType === 2) {
          this.addHeaderWithLogoRight()
        }
        if (this.logoType === 3) {
          this.addHeaderWithLogoFull()
        }
        this.addEmptyRow()
        this.addLine()
        this.addHead()
        this.addPartner()
        this.addHeadComment()
        if (this.documentName === 'Predračun servisa ') {
          this.addTotal()
          this.addEmptyRow()
        }
        if (this.documentName === 'Potvrda primitka na servis ') {
          this.addSignature3()
        }
        if (this.documentName != 'Potvrda primitka na servis ') {
          this.addSignature4()
        }

      }
    },
     initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 40 ]
      this.content = []
    },
    initDocumentMemo () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 110, 20, 100 ]
      this.content = []
    },
    initDocumentSmall () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A6'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 15, 15, 15, 15 ]
      this.content = []
    },
    initDocumentPOS () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {
        pageSize : {
          width: 185,
          height: 'auto'
        },
        pageOrientation: 'portrait',
        pageMargins: [ 9, 9, 9, 9 ],
      }
      this.content = []
    },
    addHead () {
      const docPart = {
        columns: [
              {
                width: '90%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                  { text: this.documentName + ':' + this.document.documentId + (this.comp.CompanyBusinessSpaceRetail ? '/' + this.comp.CompanyBusinessSpaceRetail : '') + (this.comp.CompanyBusinessDeviceRetail ? '/' + this.comp.CompanyBusinessDeviceRetail : '') + ' \n', fontSize: 14 },
                  { text: this.lang.DocPrintDocumentDate + ':' + this.loCdocumentDate + ',' + this.loCdocumentInvoiceTime + '\n', fontSize: 8, bold: true },
                  { text: (this.comp.CompanyLogo5 ? this.lang.DocPrintRetail + ' ' + this.comp.CompanyLogo5 +  ' \n' : ' ') , fontSize: 7 },
                  { text: this.lang.DocPrintMadeBySmall + ' ' + (this.document.First ? this.document.First : ' ') + ' ' + (this.document.Last ? this.document.Last : ' ') + ': \n', fontSize: 7, alignment: 'left' }, 
                ]
              },
            ]
          }
      this.content.push(docPart)
    },
    addHeadComment() {
      const docPart = {
        columns: [
              {
                width: '80%',
                text: [
                  { text: 'Predmet: ' + this.document.documentDescription4 + ' \n ', fontSize: 10, bold: true },
                  { text: (this.document.documentDescription1 ? this.document.documentDescription1 + '\n ' : '' ) , fontSize: 8 },
                  { text: (this.document.deliveryCurrier && this.document.documentName === 'ServiceNotes' ? 'Serviser:' + this.document.deliveryCurrier + ' \n' : ' ') , fontSize: 8 },
                  { text: (this.document.documentDescription5 ? this.document.documentDescription5 + '\n ' : '' ) , fontSize: 8 },
                ]
              },
            ]
      }
      this.content.push(docPart)
    },
    addPartner () {
      const docPart = {
        columns: [
              {
                width: '85%',
                text: [
                  { text: ' \n ', fontSize: 8 },
                  { text: this.document.partnerName + ' \n', fontSize: 12, bold: true },
                  { text: (this.document.partnerStreet ? this.document.partnerStreet : ' ') + ' ' + (this.document.partnerBuildingNameNumber ? this.document.partnerBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.document.partnerPostcode ? this.document.partnerPostcode : ' ') + ' ' + (this.document.partnerCity ? this.document.partnerCity: ' ') + ' \n', fontSize: 8 },
                  { text: (this.document.partneremail ? 'email: ' + this.document.partneremail + ' ' : ' ') + (this.document.partnerVATID ? this.lang.VATIDBuyer + ':' + this.document.partnerVATID : ' ') + ' \n', fontSize: 8 },
                  { text: (this.document.partnerPhones && this.document.documentName === 'ServiceNotes' ? 'Tel: ' + this.document.partnerPhones + ' \n' : ' ') , fontSize: 8 }
                ]
              },
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 0,
            x2: this.lineWidth,
            y2: 0,
            lineWidth: 1
          }
        ]
      }
      this.content.push(docPart)
    },
    printDoc() {
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    prepareData() {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentid) {
        this.mainQuery.databaseDocID = this.$store.state.documentid
      }
      if (this.$store.state.documentActivHead) {
        this.document = this.$store.state.documentActivHead
        this.paycash = this.lang.documentPOSCash + ': ' + (this.document.documentPOSCash ? this.formatPriceHR(this.document.documentPOSCash) : 0) + ' \n '
        this.paycard = this.lang.documentPOSCard + ': ' + (this.document.documentPOSCard ? this.formatPriceHR(this.document.documentPOSCard) : 0) + (this.document.status ? ', ' + this.document.status : '')
        const selectedBank = this.banks ? this.banks.find(bank => bank.IBAN === this.document.bankAccountNr) : null
        this.bankDesc = selectedBank && selectedBank.BankAccountDescription ? selectedBank.BankAccountDescription : null
        this.SWIFT = selectedBank && selectedBank.SWIFT ? selectedBank.SWIFT : null
      }
      if (this.$store.state.companyActiv.CompanyLogo1) {
        this.logoType = 1
        this.haveSmallLogo = true
      }
      if (this.$store.state.companyActiv.CompanyLogo2) {
        this.logoType = 2
      }
      if (this.$store.state.companyActiv.CompanyLogo3) {
        this.logoType = 3
      }
      this.documentName2 = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      this.documentNameExport = documentExportName(this.$store.state.documentActiv)
      this.pSum00 = this.formatPrice(this.document.documentDescription9)
      this.pSum01 = this.formatPrice(this.document.documentDescription10)
      this.pSum1 = this.formatPrice(this.document.documentTotalAmountNetto)
      this.pSum2 = this.formatPrice(this.document.documentTotalAmountVAT)
      this.pSum3 = this.formatPrice(this.document.documentTotalAmountBrutto)
      this.pSum4 = this.formatPrice(this.document.documentTotalAmountPayInAdvance)
      this.pSum5 = this.formatPrice(this.document.documentTotalAmountToPay)
      if (this.document.documentExRate && parseFloat(this.document.documentExRate) !== 0) {
        this.peSum00 = this.formatPrice(this.document.documentDescription9 / parseFloat(this.document.documentExRate))
        this.peSum01 = this.formatPrice(this.document.documentDescription10 / parseFloat(this.document.documentExRate))
      }
      this.peSum1 = this.formatPrice(this.document.documentTotalAmountNettoForeign)
      this.peSum2 = this.formatPrice(this.document.documentTotalAmountVATForeign)
      this.peSum3 = this.formatPrice(this.document.documentTotalAmountBruttoForeign)
      this.peSum4 = this.formatPrice(this.document.documentTotalAmountPayInAdvanceForeign)
      this.peSum5 = this.formatPrice(this.document.documentTotalAmountToPayForeign)

      this.comp = this.$store.state.companyActiv
      this.order = 0
      this.items = []
      if (this.$store.state.documentActivItems) {
        // this.items = (await DocumentDetailService.index(this.mainQuery)).data.documentDetails
        this.items = this.$store.state.documentActivItems
      }
      // console.log(' items imam ili ne', this.items)
      this.pC1 = []
      this.pC2 = []
      this.pC3 = []
      this.pC4 = []
      this.pC5 = []
      this.pC6 = []
      this.pC7 = []
      this.pC8 = []
      this.pC9 = []
      this.pC10 = []
      this.pC11 = []

      if (this.items) {
        this.items.map(item => {
          if (parseFloat(item.itemAmountWithVAT) !== 0) {

            this.order += 1
            this.pTtt3 = this.formatPrice(item.itemPrice)
            this.pTtt11 = this.formatPrice(item.itemPrice * (1 + (item.itemVAT1Percentage/100)))
            if (this.$store.state.documentSide === 2) {
              this.pTtt4 = item.itemPcsMinus ? this.formatPrice(item.itemPcsMinus) : 0
            }
            if (this.$store.state.documentSide === 3) {
              this.pTtt4 = item.itemPcsNeutral ? this.formatPrice(item.itemPcsNeutral) : 0
            }
            this.pTtt5 = item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0
            this.pTtt6 = item.itemDiscountPercentage1 ? this.formatPrice(item.itemDiscountPercentage1) : 0
            this.pTtt7 = item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0
            this.pTtt8 = item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0
            this.pTtt9 = item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0
            this.pC1.push({ text: this.order + '', fontSize: 8 })
            this.pC2.push({ text: item.itemName + '', fontSize: 8 })
            this.pC3.push({ text: this.pTtt3 + '', fontSize: 8, alignment: 'right' })
            this.pC4.push({ text: this.pTtt4 + '', fontSize: 8, alignment: 'right' })
            this.pC5.push({ text: this.pTtt5 + '', fontSize: 8, alignment: 'right' })
            this.pC6.push({ text: this.pTtt6 + '', fontSize: 8, alignment: 'right' })
            this.pC7.push({ text: this.pTtt7 + '', fontSize: 8, alignment: 'right' })
            this.pC8.push({ text: this.pTtt8 + '', fontSize: 8, alignment: 'right' })
            this.pC9.push({ text: this.pTtt9 + 'n', fontSize: 8, alignment: 'right' })
            this.pC10.push({ text: item.itemUnit + '', fontSize: 8, alignment: 'left' })
            this.pC11.push({ text: this.pTtt11 + '', fontSize: 8, alignment: 'right' })
          }

        })
      }
      this.locDocumentName = (this.$store.state.documentActiv != 'POS' ? this.documentNameLoc: 'Račun') + ': ' + this.document.documentId + '/' + (this.$store.state.documentActiv !== 'POS' ? (this.comp.CompanyBusinessSpace ? this.comp.CompanyBusinessSpace : '_')  : this.comp.CompanyBusinessSpaceRetail) + '/' + (this.$store.state.documentActiv !== 'POS' ? (this.comp.CompanyBusinessDevice ? this.comp.CompanyBusinessDevice : '_') : this.comp.CompanyBusinessDeviceRetail)
      this.locCreatedAt = dayjs(this.document.CreatedAt).format('DD.MM.YYYY HH:MM')
      this.locUpdatedAt = dayjs(this.document.UpdatedAt).format('DD.MM.YYYY HH:MM')
      this.loCdocumentInvoiceTime = this.document.documentTime ? this.document.documentTime.substring(0, 5) : '10:00'
      this.loCdocumentDate = this.document.documentDate ? dayjs(this.document.documentDate).format('DD.MM.YYYY') : ' '
      this.loCorderDate = this.document.orderDate ? dayjs(this.document.orderDate).format('DD.MM.YYYY') : ' '
      this.loCdocumentInvoiceStartDate = this.document.documentInvoiceStartDate ? dayjs(this.document.documentInvoiceStartDate).format('DD.MM.YYYY') : ' '
      this.loCdocumentInvoiceEndDate = this.document.documentInvoiceEndDate ? dayjs(this.document.documentInvoiceEndDate).format('DD.MM.YYYY') : ' '      
      this.loCdocumentConnDate =  this.document.documentConnDate ? dayjs(this.document.documentConnDate).format('DD.MM.YYYY') : ' '
      this.loCdeliveryDate = this.document.deliveryDate ? dayjs(this.document.deliveryDate).format('DD.MM.YYYY') : ' '
      this.loCorderTime = this.document.orderTime ? dayjs(this.document.orderTime).format('HH:MM') : ' '
      this.loCdocumentConnTime = this.document.documentConnTime ?  dayjs(this.document.documentConnTime).format('HH:MM') : ' '
      this.loCdeliveryTime = this.document.deliveryTime ? dayjs(this.document.deliveryTime).format('HH:MM') : ' '
      
      if (this.document.documentConnNameFrom && this.document.documentConnNameFrom.trim().length > 2) {
        if (this.document.documentConnNameFrom.substring(0, 17) === 'DeliveryNoteSales') {
          const connNr = this.document.documentConnNameFrom.substring(18, (this.document.documentConnNameFrom.trim().length))
          this.locDocumentConnFrom = 'Veza: Otpremnica - ' + connNr  + (this.document.documentConnDate ? ', od: ' + dayjs(this.document.documentConnDateFrom).format('DD.MM.YYYY') : ' ')
        }
      }

      if (this.$store.state.documentActiv === 'InvoiceSales') {
        this.locDocumentId = this.document.documentId + '/' + this.$store.state.companyActiv.CompanyBusinessSpace + '/' + this.$store.state.companyActiv.CompanyBusinessDevice
      } else {
        this.locDocumentId = this.document.documentId
      }
      this.profitLoss = this.document.FreeF4 - this.document.FreeF1 - this.document.FreeF2 - this.document.FreeF3
      this.profitLossP = this.profitLoss.toFixed(2)
    },

    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    
    addHeaderWithLogoLeft () {
      const docPart = {
            columns: [
              {
                width: '4%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '51%',
                image: this.comp.CompanyLogo1,
                fit: [70, 70]
              },
              {
                // % width
                width: '40%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: 'email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + ': ' +  (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeaderWithLogoLeftSmall () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '55%',
                image: this.comp.CompanyLogo1,
                fit: [70, 70]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: (this.exportDoc ? this.langE.VATID : this.lang.VATID) + + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 },
                  { text: this.lang.DocPrintRetail + + (this.comp.CompanyLogo5 ? this.comp.CompanyLogo5 : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 1
          }
        this.content.push(docPart)
    },
    addHeaderWithLogoRight () {
      const docPart = {
            columns: [
              {
                width: '7%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                // % width
                width: '50%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones : ' ') + '\n', fontSize: 8 },
                  { text: 'email:' + (this.comp.Companyemail ? this.comp.Companyemail : ' ') + ' \n', fontSize: 8 },
                  { text: (this.exportDoc ? this.langE.VATID : this.lang.VATID) + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // auto-sized columns have their widths based on their content
                width: '31%',
                image: this.comp.CompanyLogo2,
                fit: [70, 70]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeaderWithLogoFull () {
      const docPart = {
          columns: [
            {
              width: '2%',
              text: [
                { text: ' \n ', fontSize: 8 }
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '78%',
              image: this.comp.CompanyLogo3,
              fit: [this.paperWidth, 78]
            },
          ],
        }
      this.content.push(docPart)
    },
    addHeaderNoLogo () {
      if (this.printSize === 2 || this.printSize === 3) {
        const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '55%',
                text: [
                  { text: (this.document.documentName === 'ServiceNotes' ? this.comp.CompanyNameShort : this.comp.CompanyName) + ' \n', fontSize: 18, bold: true },
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text: (this.document.documentName === 'ServiceNotes' ? this.comp.CompanyNameShort : this.comp.CompanyName) + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones  + '\n' : ' '), fontSize: 8 },
                  { text: (this.comp.Companyemail ? 'email:' +  this.comp.Companyemail + ' \n' : ' ') , fontSize: 8 },
                  { text: (this.exportDoc ? this.langE.VATID + 'HR' : this.lang.VATID) + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
      }
      if (this.printSize === 1) {
        const docPart = {
            columns: [
              {
                // % width
                width: '100%',
                text: [
                  { text: (this.document.documentName === 'ServiceNotes' ? this.comp.CompanyNameShort : this.comp.CompanyName) + ' \n', fontSize: 8, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.SecretaryPhone + (this.comp.CompanyPhones ? this.comp.CompanyPhones  + '\n' : ' '), fontSize: 8 },
                  { text: (this.comp.Companyemail ? 'email:' +  this.comp.Companyemail + ' \n' : ' ') , fontSize: 8 },
                  { text: (this.exportDoc ? this.langE.VATID + 'HR' : this.lang.VATID) + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              }
            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
      }
    },

    addComment () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '80%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: ' \n', fontSize: 10 },
                  { text: (this.lang.DocPrintComment ? this.lang.DocPrintComment: '') + ' ' + (this.document.documentDescription5 ? this.document.documentDescription5 : '') + ' \n', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },

    addTotal () {
      const docPart = {
        columns: [
              {
                width: '30%',
                text: [
                  { text: 'Usluga '  + ' \n' + (this.document.vehicleHands ? this.formatPrice(this.document.vehicleHands) : '0.00') + ' \n', fontSize: 8, alignment: 'right' }
                ]
              },
              {
                width: '30%',
                text: [
                  { text: 'Dijelovi ' + ' \n' + (this.document.vehicleParts ? this.formatPrice(this.document.vehicleParts) : '0.00') + ' \n', fontSize: 8, alignment: 'right' }
                ]
              },
              {
                width: '30%',
                text: [
                  { text: 'Ukupno kn: ' + ' \n' + (this.document.vehicleTotalService ? this.formatPrice(this.document.vehicleTotalService) : '0.00') + ' \n', fontSize: 8, alignment: 'right' },
                  { text: 'Ukupno EUR: ' + ' \n' + (this.document.vehicleTotalService ? this.formatPrice(this.document.vehicleTotalService / 7.3453) : '0.00') + ' \n', fontSize: 8, alignment: 'right' }
                ]
              },

            ]
          }
      this.content.push(docPart)
    },

    addSignature3 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: 'Primio na servis: ' + (this.document.First ? this.document.First : ' ') + ' ' + (this.document.Last ? this.document.Last : ' ') + ': \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: ' Predao : \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addSignature4 () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '35%',
                text: [
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addEmpty () {
      const docPart = {
        
          }
      this.content.push(docPart)
    },

  },
  computed: {
  },
  watch: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
