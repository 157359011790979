<template>
    <v-container fluid grid-list-md>
      <v-layout row>
        <v-flex xs6 sm6 md6 lg6 xl6>
          <h1>{{this.$store.state.documentActivLoc}}</h1>
        </v-flex>
        <v-flex xs6 sm6 md6 lg6 xl6 class="text-xs-right">

        </v-flex>
      </v-layout>
      <MainLedgerList4 v-if="haveAllData" :banks="banks" :selectBank="selectBank" :selectBankId="selectBankId"/>
    </v-container>
</template>

<script>
import MainLedgerList4 from './MainLedgerList4'
import BankAccountService from '@/services/BankAccountService'
import { checkBooks} from './mainledgerHelper'
import { testLastUpdate } from '../../services/TestLastUpdate'

export default {
  name: 'mainledger',
  data () {
    return {
      msg: '',
      MainLedgerName: '',
      MainLedgerNameLoc: '',
      mainledger: {},
      documentName: '',
      documentNameLoc: '',
      error: null,
      localError: false,
      newPath: '',
      show: 1,
      banks: [],
      selectBank: {},
      selectBankId: 0,
      haveAllData: false
    }
  },
  async mounted () {
    this.MainLedgerName = this.$store.state.MainLedgerActiv
    this.MainLedgerNameLoc = this.$store.state.MainLedgerActivLoc
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    this.show = 1
    if (this.$store.state.documentSide === 1 || this.$store.state.documentSide === 2) {
      this.show = 1
    }
    if (this.$store.state.documentSide === 3) {
      this.show = 3
    }
    if (this.$store.state.documentSide === 4) {
      this.show = 4
    }
    await this.$store.dispatch('setbookTypeSelected', null)
    await checkBooks(this.show)
    this.bookTypeSelected = this.$store.state.bookTypeSelected
    const query = {}
    query.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
    query.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
    await BankAccountService.choose(query)
    .then(res => {
      if (res.data && res.data.bankAccounts) {
        this.banks = res.data.bankAccounts
        const updateOnPage = this.$store.state.onPage
        // have already selected bank
        if (typeof(updateOnPage.selectBankId) !== 'undefined') {
          this.selectBank = this.banks ? this.banks.find(bank => (bank.id === updateOnPage.selectBankId)) : {}
          this.selectBankId  = updateOnPage.selectBankId
        }
        // don't have selected bank initilaze it
        if (typeof(updateOnPage.selectBankId) === 'undefined') {
          this.selectBank = this.banks ? this.banks.find(bank => (bank.DefaultAccount === true)) : {}
          this.selectBankId  = this.selectBank ? parseInt(this.selectBank.id) : null
          this.$store.dispatch('setBankActiv', this.selectBank)
          const updateOnPage = this.$store.state.onPage
          updateOnPage.selectBankId = this.selectBankId
          this.$store.dispatch('setOnPage', updateOnPage)
        }
        // console.log('IMAM BANKU INDEX!!', this.banks, this.selectBank, this.selectBankId, updateOnPage)
        this.haveAllData = true        
      }
    })
    .catch(err => {
      console.log(err)
      alert('Morate upisati žiro račune u postavke! Need to define bank accounts in settings!')
    })
    
    await this.$store.dispatch('setMainLedgerActiv', {})
    await this.$store.dispatch('seteditItem', 0)
    await this.$store.dispatch('setMainLedgerDetailid', null)
    await this.$store.dispatch('setPartnerid', null)
    await this.$store.dispatch('setPartnerVATID', null)
    await this.$store.dispatch('setMainLedgerItems', [])
    await this.$store.dispatch('setMainLedgerSideItem', null)
    
    if (this.$store.state.pathAgain) {
      this.newPath = this.$store.state.pathAgain
      this.$store.dispatch('setpathAgain', '')
      this.$router.push({
        name: this.newPath
      })
    }
    await testLastUpdate()

  },
  methods: {
    
  },
  computed: {
  },
  watch: {
  },
  components: {
    MainLedgerList4
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
