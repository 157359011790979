import Api from '@/services/Api'

export default {
  index(query) {
    return Api().get('group', {
      params: query
    })
  },
  show(groupId) {
    return Api().get(`group/${groupId}`)
  },
  post(newGroup) {
    return Api().post('group', newGroup)
  },
  put(group) {
    return Api().put(`group/${group.id}`, group)
  },
  delete(groupId) {
    return Api().delete(`group/${groupId}`)
  },
  choose(query) {
    return Api().get('group/choose', {
      params: query
    })
  }
}