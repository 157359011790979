<template>
    <v-data-table
      :headers="headers"
      :items="selectedItems"
      class="elevation-1"
      >
      <template v-slot:[`item.ExpTotal`]="{ item }">
        <span class="title indigo--text">{{ formatPrice(item.ExpTotal) }}</span>
      </template>
      <template v-slot:[`item.RecTotal`]="{ item }">
        <span class="title indigo--text">{{ formatPrice(item.RecTotal) }}</span>
      </template>
    </v-data-table>
</template>

<script>

import ledgerEN from '../MainBook/mainbookDescEn'
import ledgerHR from '../MainBook/mainbookDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"

export default {
  name: 'mainbookcompcardselected',
  props: ['selectedItems', 'type'],
  data () {
    return {
      lang: {},
      langC: {},
      headers: [],
    }
  },
  components: {

  },
  created() {

  },
  mounted () {
   
      if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      if (this.type === 1) {
        this.headers.push(
          {text: 'Račun', align: 'right', value: 'BookID', width: '120px' },
          {text: 'Iznos', align: 'right', value: 'ExpTotal', width: '150px' },
        )
      }
       if (this.type === 2) {
        this.headers.push(
          {text: 'Račun', align: 'right', value: 'BookID', width: '120px' },
          {text: 'Iznos', align: 'right', value: 'RecTotal', width: '150px' },
        )
      }
    
  },
  computed: {

  },
  methods: {
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>