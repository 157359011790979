<template>
<div>
  <v-container fluid grid-list-md>
  <v-row>
      <appAppSettings></appAppSettings>
    </v-row>
  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="compUsers"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="20"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{lang.CompanyUsers}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-row>
                    <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                      <v-autocomplete
                        :items="companies"
                        v-model="selectComp"
                        label="Select company"
                        item-text="CompanyName"
                        item-value="id"
                        return-object
                        persistent-hint
                        clearable
                      ></v-autocomplete>
                       </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-select
                        :items="locusers"
                        v-model="select"
                        :label="lang.SelectUser"
                        item-text="email"
                        item-value="id"
                        return-object
                        persistent-hint
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-select :label="lang.AccessLevel" :items="privitems" v-model="editedItem.UserIdRight"></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-checkbox
                        v-model="editedItem.defaultCompany"
                        :label="lang.DefaultCompany"
                      ></v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn :disabled="!valid" color="success" text @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.defaultCompany`]="{ item }">
      <span v-if="item.defaultCompany"><v-icon>mdi-check</v-icon></span>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      {{ lang.CompUsersEmpty }}
    </template>
  </v-data-table>
  </v-container>
  </div>
</template>

<script>
import CompService from '@/services/CompService'
import CompUserService from '@/services/CompUserService'
import UserService from '@/services/UserService'
import langEn from './companyDescEn'
import langHr from './companyDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import AppSettings from '../AppSettings'

  export default {
    name: 'compuserCRUD',
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: "",
        databaseDocID: 0
      },
      dialog: false,
      headers: [ 
      ],
      compUsers: [],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        UserIdRight: 5,
        EnteredInDatabase: null,
        defaultCompany: false,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: '',
        LastUpdate: null
      },
      confpassword: '',
      privitems: [ ],
      valid: true,
      nameRules: [
        v => !!v || 'Name is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password must be 6 or more characters',
      ],
      locusers: [],
      select: { },
      companies:[],
      selectComp: {}
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewCompanyUser: this.lang.EditCompanyUser
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      'select' () {
        this.choosedUser(this.select.id)
      },
      'selectComp' (comp) {
        if (comp) {
          this.editedItem.CompanyId = comp.id
          this.editedItem.CompanyNameDatabase = comp.CompanyNameDatabase
        }
        if (!comp) {
          this.editedItem.CompanyId = null
          this.editedItem.CompanyNameDatabase = null
        } 
        //this.choosedComp(comp.id)
      },
    },
    components: {
      appAppSettings: AppSettings
    },

    async mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
        this.defaultItem.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.companyName) {
        this.defaultItem.CompanyNameDatabase = this.$store.state.companyName
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }

      this.privitems.push(
        { text: this.lang.Priv1, value: 1 },
        { text: this.lang.Priv2, value: 2 },
        { text: this.lang.Priv3, value: 3 },
        { text: this.lang.Priv4, value: 4 },
        { text: this.lang.Priv5, value: 5 }
      )
      this.headers.push(
        {text: this.lang.CompanyId, value: 'CompanyId', width: '50px'},
        {text: this.lang.CompanyNameDatabase, value: 'CompanyNameDatabase', width: '200px'},
        {text: this.lang.UserId, value: 'UserId', width: '150px'},
        {text: 'email', value: 'email', width: '200px'},
        {text: this.lang.UserIdRight, value: 'UserIdRight', type: 'number', width: '50px'},
        {text: this.lang.DefaultCompany, value: 'defaultCompany', width: '50px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
        {text: this.lang.Actions, value: 'action', sortable: false },
      )

     this.defaultItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
     this.defaultItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
     this.defaultItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : ''
     this.companies = (await CompService.index()).data.companys

     const {data} = (await UserService.index())
     if (data.users && data.users.length > 0) {
       this.locusers = data.users
     }

    },

    created () {
      this.initialize()
    },

    methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      async initialize () {
        try {
          this.saving = true
          this.compUsers = (await CompUserService.index()).data.companyUsers   
          this.saving = false
          this.errorMatch = false
          this.editedItem = this.defaultItem
          this.select = {}
          this.selectComp = {}
        } catch (err) {
          this.error = err
        }
      },
      editItem (item) {
        this.errorMatch = false
        this.editedIndex = this.compUsers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
        this.select = this.editedItem.UserId
        this.selectComp = this.editedItem.CompanyId
      },

      async deleteItem (item) {
        try {
          const index = this.compUsers.indexOf(item)
          const warningMessage = item.email
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.compUsers.splice(index, 1)) {
            await CompUserService.delete(item.id)
            this.initialize()
          }
        } catch(err) {
          this.error = err
         
        }
        
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      async save () {
        try {
           if (this.editedItem.CompanyId && this.editedItem.CompanyNameDatabase) {
            let doneOK = false
            if (this.editedIndex > -1) {
                await CompUserService.put(this.editedItem)
                doneOK = true
              } else {
                //this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
                //this.editedItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
                //this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
                //this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
                this.editedItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : ''
                await CompUserService.post(this.editedItem)
                doneOK = true
              }
              if (doneOK) {
                this.initialize()
                this.close()
              }
           } else {
             console.log('Error!! Not saved!')
           }
        } catch(err) {
          this.error = err
   
        }
      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      },
      choosedUser (choosedId) {
        if (this.locusers) {
          this.locusers.forEach(element => {
            if (element.id === choosedId) {
              this.editedItem.UserId = element.id
              this.editedItem.email = element.email
            }
          })
        }
      },
      choosedComp (id) {
        if (this.companies) {
          this.companies.forEach(element => {
            if (element.id === id) {
              this.editedItem.CompanyId = element.id
              this.editedItem.CompanyNameDatabase = element.CompanyNameDatabase
            }
          })
        }
      }
    },
  }
</script>