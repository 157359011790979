<template>
  <v-container fluid >

    <v-row no-gutters class="pa-0">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <CashDeskCRUD />
      </v-col>
    </v-row>    
  </v-container>
</template>

<script>
import CashDeskCRUD from './CashDeskCRUD'
import langEn from './cashdeskDescEn'
import langHr from './cashdeskDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'

export default {
  name: 'cashdesk',
  data () {
    return {
      msg: '',
      lang: {},
      langC: {},
      locMonths: [],
      locYears: [],
      monthSelected: null,
      yearSelected: null,
      empSelected: null,
      emplys: [],
      newWH: {},
      newWorkHours: [],
      mainQuery: {},
      days:[],
      refresh: 0,
      saving: false,
      postNew: false
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    if (this.$store.state.businessYear) {
      this.mainQuery.businessYear = this.$store.state.businessYear
    }
  },
  methods: {


  },
  computed: {

  },
  watch: {

  },
  components: {
    CashDeskCRUD
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
