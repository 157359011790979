<template>
<div>
  <v-container fluid grid-list-md>
    <v-row>
      <appAppSettings></appAppSettings>
    </v-row>
    <v-layout row>
      <v-flex xs6 sm6 md6 lg6 xl6>
  
      </v-flex>
      <v-flex xs6 sm6 md6 lg6 xl6 class="text-xs-right">
          <v-btn fab dark fixed right class="indigo"  @click="newcompany">
              <v-icon dark>mdi-plus</v-icon>
          </v-btn>
      </v-flex>
    </v-layout>
    <app-Comp-list></app-Comp-list>
  </v-container>
  </div>
</template>

<script>
import Complist from './CompList'
import AppSettings from '../AppSettings'

export default {
  name: 'company',
  data () {
    return {
      msg: ''
    }
  },
  mounted () {

  },
  methods: {
    async newcompany () {
      try {
        await this.$router.push({
          name: 'companycreate'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  computed: {

  },
  watch: {

  },
  components: {
    appCompList: Complist,
    appAppSettings: AppSettings
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
