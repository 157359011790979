import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('emplday', {
      params: query
    })
  },
  show (empldayId) {
    return Api().get(`emplday/${empldayId}`)
  },
  post (newemplday) {
    return Api().post('emplday', newemplday)
  },
  put (emplday) {
    return Api().put(`emplday/${emplday.id}`, emplday)
  },
  delete (empldayId) {
    return Api().delete(`emplday/${empldayId}`)
  },
  choose (query) {
    return Api().get('emplday/choose', { params: query })
  },
  choose2 (query) {
    return Api().get('emplday/choose2', { params: query })
  },
  employeeana (query) {
    return Api().get('emplday/employeeana', {
      params: query
    })
  },
  rewind (query) {
    return Api().get('emplday/rewind', {
      params: query
    })
  }
}
