<template>
  <v-layout row justify-center>
      <v-card v-if="this.$store.state.language == 'EN'">
        <v-card-title class="headline">Are you sure to delete Partner?</v-card-title>
        <v-card-text>We not recomend to delete partner. Better disable it. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" @click="deletePartner">Delete</v-btn>
          <v-btn color="green darken-1" @click="partnerlist">Cancel</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="this.$store.state.language == 'HR'">
        <v-card-title class="headline">Da li ste sigurni da zelite obrisati Partnera?</v-card-title>
        <v-card-text>NE preporucujemo da brisite. Iskljucite ga ili kontaktirate administratora. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" @click="deletePartner">Obrisi</v-btn>
          <v-btn color="green darken-1" @click="partnerlist">Odustani</v-btn>
        </v-card-actions>
      </v-card>      
  </v-layout>
</template>

<script>
import PartnerService from '@/services/PartnerService'
import PartnerCompanyService from '@/services/PartnerCompanyService'

export default {
  name: 'partnerdelete',
  data () {
    return {
      msg: '',
      dialog: false,
      mainQuery: {},
      privateP: true
    }
  },
  mounted() {
    this.privateP = this.$store.state.user ? this.$store.state.user.privatePartner : true
  },
  methods: {
    async deletePartner () {
      try {
        if (this.$store.state.partnerid) {

          this.mainQuery.partnerId = this.$store.state.partnerid
          this.mainQuery.CompanyId = this.$store.state.companyid
          await PartnerCompanyService.delete(this.mainQuery)
          if (!this.privateP) {
            await PartnerService.delete(this.$store.state.partnerid)
          }
          alert('Partner je obrisan')
          await this.$router.push({
            name: 'partner'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async partnerlist () {
      try {
        await this.$router.push({
          name: 'partner'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
