import DocumentService from './DocumentService'
import dayjs from 'dayjs'
export default {
  async whstate(companyId, businessYear, warehouseId) {
    const state = []
    // let localError = false
    // let error = null
    // let Total1 = 0
    // let Total2 = 0
    // let Total3 = 0
    // let Total4 = 0
    // let Total5 = 0
    // let Total6 = 0
    // let Total7 = 0
    // let Total8 = 0
    // let Total9 = 0
    // let Total10 = 0
    // let Total21 = 0
    // let Total31 = 0
    // let items = []
    // let saving = false
    const documentAna = {}
    if (companyId) {
      documentAna.CompanyId = companyId
    }
    if (businessYear) {
      documentAna.BusinessYear = businessYear
    }
    if (warehouseId) {
      documentAna.warehouseId = warehouseId
    }
    documentAna.docFromDate = dayjs([businessYear]).startOf('year').format('YYYY-MM-DD')
    documentAna.docToDate = dayjs([businessYear]).endOf('year').format('YYYY-MM-DD')
    // saving = true
    const tempItems = (await DocumentService.documentwarehousestate(documentAna)).data.document
    // saving = false
    let iid = 0
    if (tempItems) {
      tempItems.map(item => {
        const newEntry = {}
        newEntry.itemID = item.itemID ? item.itemID : ''
        newEntry.itemName = item.itemName ? item.itemName : ''
        newEntry.TotalPlus = parseFloat(item.TotalPlus ? item.TotalPlus : 0)
        newEntry.TotalMinus = parseFloat(item.TotalMinus ? item.TotalMinus : 0)
        newEntry.TotalPcs = parseFloat(item.TotalPlus ? item.TotalPlus : 0) - parseFloat(item.TotalMinus ? item.TotalMinus : 0)
        // Total1 += parseFloat(item.TotalPlus ? item.TotalPlus : 0)
        // Total2 += parseFloat(item.TotalMinus ? item.TotalMinus : 0)
        iid += 1
        newEntry.id = iid
        state.push(newEntry)
      })
    }
    return state
  }
}
