<template>
<div>
  <v-container fluid grid-list-md>
   <v-row>
      <v-col cols="12" sm="6" xs="6" md="6" lg="6" xl="6">
        <h2>{{employee}}</h2>
      </v-col>
      <v-col cols="12" sm="3" xs="6" md="6" lg="6" xl="6" class="text-right"> 
        <v-btn @click="print" class="mr-2">Printaj</v-btn>
        <v-btn
            class="primary"
            @click="back">
            {{langC.Back}}
          </v-btn>
      </v-col>
    </v-row>
     <v-row>
      <v-col cols="12" sm="6" xs="6" md="6" lg="6" xl="6">
        <h2>{{header}}</h2>
      </v-col>
    
    </v-row>
  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="workhours"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="50"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{lang.WorkHours}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>


          <v-card>
            <v-card-title>
              <span class="headline">{{ lang.WorkHour }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >

                  <v-row>
                    <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-1">
                        <span class="headline">{{localDate(editedItem.WDate)}}</span>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-1">
                        <span v-if="editedItem.WDay == 'Monday'" class="headline">Ponedjeljak</span>
                        <span v-if="editedItem.WDay == 'Tuesday'" class="headline">Utorak</span>
                        <span v-if="editedItem.WDay == 'Wednesday'" class="headline">Srijeda</span>
                        <span v-if="editedItem.WDay == 'Thursday'" class="headline">Cetvrak</span>
                        <span v-if="editedItem.WDay == 'Friday'" class="headline">Petak</span>
                        <span v-if="editedItem.WDay == 'Saturday'" class="headline red--text">Subota</span>
                        <span v-if="editedItem.WDay == 'Sunday'" class="headline red--text">Nedjelja</span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.PocetakRada"
                        v-model.number ="editedItem.PocetakRada"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.ZavrsetakRada"
                        v-model.number ="editedItem.ZavrsetakRada"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.RedovanRad"
                        v-model.number ="editedItem.RedovanRad"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.NocniRad"
                        v-model.number ="editedItem.NocniRad"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.PrekovremeniRad"
                        v-model.number ="editedItem.PrekovremeniRad"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.NedjeljaRad"
                        v-model.number ="editedItem.NedjeljaRad"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.BlagdanRad"
                        v-model.number ="editedItem.BlagdanRad"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.TerenRad"
                        v-model.number ="editedItem.TerenRad"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.PreraspodjelaRada"
                        v-model.number ="editedItem.PreraspodjelaRada"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.Pripravnost"
                        v-model.number ="editedItem.Pripravnost"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.DnevniOdmor"
                        v-model.number ="editedItem.DnevniOdmor"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.TjedniOdmor"
                        v-model.number ="editedItem.TjedniOdmor"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.NeradniBlagdan"
                        v-model.number ="editedItem.NeradniBlagdan"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.GodisnjiOdmor"
                        v-model.number ="editedItem.GodisnjiOdmor"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.BolovanjeDo42Dana"
                        v-model.number ="editedItem.BolovanjeDo42Dana"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.BolovanjeOd42Dana"
                        v-model.number ="editedItem.BolovanjeOd42Dana"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>               

                  <v-row>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.Rodiljni"
                        v-model.number ="editedItem.Rodiljni"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.PlaceniDopust"
                        v-model.number ="editedItem.PlaceniDopust"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.NeplaceniDopust"
                        v-model.number ="editedItem.NeplaceniDopust"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.NenazocnostNaZahtjevRadnika"
                        v-model.number ="editedItem.NenazocnostNaZahtjevRadnika"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>  

                  <v-row>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.ZastojKrivnjomPoslodavca"
                        v-model.number ="editedItem.ZastojKrivnjomPoslodavca"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.Strajk"
                        v-model.number ="editedItem.Strajk"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.Lockout"
                        v-model.number ="editedItem.Lockout"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        :label="lang.VrijemeMirovanjaRadnogOdnosa"
                        v-model.number ="editedItem.VrijemeMirovanjaRadnogOdnosa"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row> 

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn color="success" text @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.WDate`]="{ item }">
        <span>{{ localDate(item.WDate) }}</span>
    </template>
    <template v-slot:[`item.Ukupno`]="{ item }">
        <span class="font-weight-bold" >{{ localFormat(item.Ukupno) }}</span>
    </template>
    <template v-slot:[`item.RedovanRad`]="{ item }">
        <span v-if="item.RedovanRad > 0">{{ localFormat(item.RedovanRad) }}</span>
    </template>
    <template v-slot:[`item.NocniRad`]="{ item }">
        <span v-if="item.NocniRad > 0">{{ localFormat(item.NocniRad) }}</span>
    </template>
    <template v-slot:[`item.PrekovremeniRad`]="{ item }">
        <span v-if="item.PrekovremeniRad > 0">{{ localFormat(item.PrekovremeniRad) }}</span>
    </template>
    <template v-slot:[`item.NedjeljaRad`]="{ item }">
        <span v-if="item.NedjeljaRad > 0">{{ localFormat(item.NedjeljaRad) }}</span>
    </template>
    <template v-slot:[`item.BlagdanRad`]="{ item }">
        <span v-if="item.BlagdanRad > 0">{{ localFormat(item.BlagdanRad) }}</span>
    </template>
    <template v-slot:[`item.TerenRad`]="{ item }">
        <span v-if="item.TerenRad > 0">{{ localFormat(item.TerenRad) }}</span>
    </template>
    <template v-slot:[`item.PreraspodjelaRada`]="{ item }">
        <span v-if="item.PreraspodjelaRada > 0">{{ localFormat(item.PreraspodjelaRada) }}</span>
    </template>
    <template v-slot:[`item.Pripravnost`]="{ item }">
        <span v-if="item.Pripravnost > 0">{{ localFormat(item.Pripravnost) }}</span>
    </template>
    <template v-slot:[`item.DnevniOdmor`]="{ item }">
        <span v-if="item.DnevniOdmor > 0">{{ localFormat(item.DnevniOdmor) }}</span>
    </template>
    <template v-slot:[`item.TjedniOdmor`]="{ item }">
        <span v-if="item.TjedniOdmor > 0">{{ localFormat(item.TjedniOdmor) }}</span>
    </template>
    <template v-slot:[`item.NeradniBlagdan`]="{ item }">
        <span v-if="item.NeradniBlagdan > 0">{{ localFormat(item.NeradniBlagdan) }}</span>
    </template>
    <template v-slot:[`item.GodisnjiOdmor`]="{ item }">
        <span v-if="item.GodisnjiOdmor > 0">{{ localFormat(item.GodisnjiOdmor) }}</span>
    </template>
    <template v-slot:[`item.BolovanjeDo42Dana`] = "{ item }">
        <span v-if="item.BolovanjeDo42Dana > 0">{{ localFormat(item.BolovanjeDo42Dana) }}</span>
    </template>
    <template v-slot:[`item.BolovanjeOd42Dana`] = "{ item }">
        <span v-if="item.BolovanjeOd42Dana > 0">{{ localFormat(item.BolovanjeOd42Dana) }}</span>
    </template>
    <template v-slot:[`item.Rodiljni`]="{ item }">
        <span v-if="item.Rodiljni > 0">{{ localFormat(item.Rodiljni) }}</span>
    </template>
    <template v-slot:[`item.PlaceniDopust`]="{ item }">
        <span v-if="item.PlaceniDopust > 0">{{ localFormat(item.PlaceniDopust) }}</span>
    </template>
    <template v-slot:[`item.NeplaceniDopust`]="{ item }">
        <span v-if="item.NeplaceniDopust > 0">{{ localFormat(item.NeplaceniDopust) }}</span>
    </template>
    <template v-slot:[`item.NenazocnostNaZahtjevRadnika`]="{ item }">
        <span v-if="item.NenazocnostNaZahtjevRadnika > 0">{{ localFormat(item.NenazocnostNaZahtjevRadnika) }}</span>
    </template>
    <template v-slot:[`item.ZastojKrivnjomPoslodavca`]="{ item }">
        <span v-if="item.ZastojKrivnjomPoslodavca > 0">{{ localFormat(item.ZastojKrivnjomPoslodavca) }}</span>
    </template>
    <template v-slot:[`item.Strajk`]="{ item }">
        <span v-if="item.Strajk > 0">{{ localFormat(item.Strajk) }}</span>
    </template>
    <template v-slot:[`item.Lockout`]="{ item }">
        <span v-if="item.Lockout > 0">{{ localFormat(item.Lockout) }}</span>
    </template>
    <template v-slot:[`item.VrijemeMirovanjaRadnogOdnosa`]="{ item }">
        <span v-if="item.VrijemeMirovanjaRadnogOdnosa > 0">{{ localFormat(item.VrijemeMirovanjaRadnogOdnosa) }}</span>
    </template>
     <template v-slot:[`item.WDay`]="{ item }">
        <span v-if="item.WDay == 'Monday'">Ponedjeljak</span>
        <span v-if="item.WDay == 'Tuesday'">Utorak</span>
        <span v-if="item.WDay == 'Wednesday'">Srijeda</span>
        <span v-if="item.WDay == 'Thursday'">Cetvrak</span>
        <span v-if="item.WDay == 'Friday'">Petak</span>
        <span v-if="item.WDay == 'Saturday'" class="red--text">Subota</span>
        <span v-if="item.WDay == 'Sunday'" class="red--text">Nedjelja</span>
        <span v-if="item.WDay == 'UKUPNO:'" class="blue--text font-weight-bold">UKUPNO:</span>
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      {{ lang.WhEmpty }}
    </template>
  </v-data-table>
    <WhPrint ref="prnt" :wh="wh" />
  </v-container>
  </div>
</template>

<script>
import WorkHourService from '@/services/WorkHourService'
import WhPrint from './WhPrint'
import langEn from './workhourDescEn'
import langHr from './workhourDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import {parseNum, moneyFormat} from '@/snippets/allSnippets'
import dayjs from 'dayjs'

  export default {
    name: 'whCRUD',
    props: ['ForMonth', 'ForYear', 'EmployeeId'], 
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal1: false,
      dialog1: false,
      modal2: false,
      dialog2: false,
      modal3: false,
      dialog3: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
      },
      dialog: false,
      headers: [],
      workhours: [],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      valid: true,
      privateP: true,
      search: null,
      itemsLoc: [],
      itemLoc: {},
      CompanyId: 0,
      selectIL: {},
      mainQuery2: {},
      employee: '',
      header: '',
      def: {
      },
      wh:{}
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewUser: this.lang.EditUser
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      'editedItem.FreeF1' () {
        if (this.editedItem.FreeF1 > 0 && this.editedItem.StartDate) {
          this.editedItem.EndDate = dayjs(this.editedItem.StartDate).add(this.editedItem.FreeF1, 'M').format('YYYY-MM-DD')
        }
      }
    },
    components: {
      WhPrint
    },
    async mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      this.mainQuery.ForMonth = this.ForMonth
      this.mainQuery.ForYear = this.ForYear
      this.mainQuery.EmployeeId = this.EmployeeId

      this.headers.push(
        {text: this.lang.WDate, value: 'WDate', width: '120px', },
        {text: this.lang.WDay, value: 'WDay', width: '100px'},
        {text: this.lang.PocetakRada , value: 'PocetakRada', width: '50px'},
        {text: this.lang.ZavrsetakRada, value: 'ZavrsetakRada', width: '50px',},
        {text: this.lang.Ukupno, value: 'Ukupno', width: '50px',},
        {text: this.lang.Actions, value: 'action', sortable: false, width: '100px' },
        {text: this.lang.RedovanRad, value: 'RedovanRad', width: '50px',},
        {text: this.lang.NocniRad, value: 'NocniRad', width: '50px',},
        {text: this.lang.PrekovremeniRad, value: 'PrekovremeniRad', width: '50px', },
        {text: this.lang.NedjeljaRad, value: 'NedjeljaRad', width: '50px'},
        {text: this.lang.BlagdanRad, value: 'BlagdanRad', width: '50px'},
        {text: this.lang.TerenRad, value: 'TerenRad', width: '50px'},
        {text: this.lang.PreraspodjelaRada, value: 'PreraspodjelaRada', width: '50px'},
        {text: this.lang.Pripravnost, value: 'Pripravnost', width: '50px'},
        {text: this.lang.DnevniOdmor, value: 'DnevniOdmor', width: '50px'},
        {text: this.lang.TjedniOdmor, value: 'TjedniOdmor', width: '50px'},
        {text: this.lang.NeradniBlagdan, value: 'NeradniBlagdan', width: '50px'},
        {text: this.lang.GodisnjiOdmor, value: 'GodisnjiOdmor', width: '50px'},
        {text: this.lang.BolovanjeDo42Dana, value: 'BolovanjeDo42Dana', width: '50px'},
        {text: this.lang.BolovanjeOd42Dana, value: 'BolovanjeOd42Dana', width: '50px'},
        {text: this.lang.Rodiljni, value: 'Rodiljni', width: '50px'},
        {text: this.lang.PlaceniDopust, value: 'PlaceniDopust', width: '50px'},
        {text: this.lang.NeplaceniDopust, value: 'NeplaceniDopust', width: '50px'},
        {text: this.lang.NenazocnostNaZahtjevRadnika, value: 'NenazocnostNaZahtjevRadnika', width: '50px'},
        {text: this.lang.ZastojKrivnjomPoslodavca, value: 'ZastojKrivnjomPoslodavca', width: '50px'},
        {text: this.lang.Strajk, value: 'Strajk', width: '50px'},
        {text: this.lang.Lockout, value: 'Lockout', width: '50px'},
        {text: this.lang.VrijemeMirovanjaRadnogOdnosa, value: 'VrijemeMirovanjaRadnogOdnosa', width: '50px'},        
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
      ) 
    },

    created () {
      this.initialize()
    },

    methods: {
      print(){
        this.$refs.prnt.print()
      },
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      async initialize () {
        try {
          this.saving = true          
          const upit = {}
          upit.CompanyId = this.$store.state.companyid
          upit.businessYear = this.$store.state.businessYear          
          upit.ForMonth = this.ForMonth
          upit.ForYear = this.ForYear
          upit.EmployeeId = this.EmployeeId
          console.log('upit', upit)
          const res = (await WorkHourService.empmonth(upit))
          console.log('res', res)
          if (res.data && res.data.workhours !== undefined && res.data.workhours.length > 0) {
            this.workhours = res.data.workhours
            this.employee = this.workhours[0].First + ' ' + this.workhours[0].Last + ', ' + this.workhours[0].email + ', id:' + this.workhours[0].EmployeeId
            this.header = 'Obračun radnih sati za ' + parseInt(this.workhours[0].ForMonth) + '/' + parseInt(this.workhours[0].ForYear)
            this.totals()
            this.wh.First = this.workhours[0].First
            this.wh.Last = this.workhours[0].Last
            this.wh.email = this.workhours[0].email
            this.wh.id = this.workhours[0].EmployeeId
            this.wh.ForMonth = parseInt(this.workhours[0].ForMonth)
            this.wh.ForYear = parseInt(this.workhours[0].ForYear)
            this.wh.items = this.workhours
            this.wh.footer = this.def
          }

          this.saving = false
          this.errorMatch = false
          this.editedItem = Object.assign({}, this.defaultItem)
        } catch (err) {
          this.error = err
        }
      },
      totals () {

        this.def.RedovanRad = 0
        this.def.NocniRad = 0
        this.def.PrekovremeniRad = 0
        this.def.NedjeljaRad = 0
        this.def.BlagdanRad = 0
        this.def.TerenRad = 0
        this.def.PreraspodjelaRada = 0
        this.def.Pripravnost = 0
        this.def.DnevniOdmor = 0
        this.def.TjedniOdmor = 0
        this.def.NeradniBlagdan = 0
        this.def.GodisnjiOdmor = 0
        this.def.BolovanjeDo42Dana = 0
        this.def.BolovanjeOd42Dana = 0
        this.def.Rodiljni = 0
        this.def.PlaceniDopust = 0
        this.def.NeplaceniDopust = 0
        this.def.NenazocnostNaZahtjevRadnika = 0
        this.def.ZastojKrivnjomPoslodavca = 0
        this.def.Strajk = 0
        this.def.Lockout = 0
        this.def.VrijemeMirovanjaRadnogOdnosa = 0
        this.def.Ukupno = 0
      
        if (this.workhours.length > 0) {
          this.workhours.map(wh => {
            this.def.RedovanRad += parseNum(wh.RedovanRad)
            this.def.NocniRad += parseNum(wh.NocniRad)
            this.def.PrekovremeniRad += parseNum(wh.PrekovremeniRad)
            this.def.NedjeljaRad += parseNum(wh.NedjeljaRad)
            this.def.BlagdanRad += parseNum(wh.BlagdanRad)
            this.def.TerenRad += parseNum(wh.TerenRad)
            this.def.PreraspodjelaRada += parseNum(wh.PreraspodjelaRada)
            this.def.Pripravnost += parseNum(wh.Pripravnost)
            this.def.DnevniOdmor += parseNum(wh.DnevniOdmor)
            this.def.TjedniOdmor += parseNum(wh.TjedniOdmor)
            this.def.NeradniBlagdan += parseNum(wh.NeradniBlagdan)
            this.def.GodisnjiOdmor += parseNum(wh.GodisnjiOdmor)
            this.def.BolovanjeDo42Dana += parseNum(wh.BolovanjeDo42Dana)
            this.def.BolovanjeOd42Dana += parseNum(wh.BolovanjeOd42Dana)
            this.def.Rodiljni += parseNum(wh.Rodiljni)
            this.def.PlaceniDopust += parseNum(wh.PlaceniDopust)
            this.def.NeplaceniDopust += parseNum(wh.NeplaceniDopust)
            this.def.NenazocnostNaZahtjevRadnika += parseNum(wh.NenazocnostNaZahtjevRadnika)
            this.def.ZastojKrivnjomPoslodavca += parseNum(wh.ZastojKrivnjomPoslodavca)
            this.def.Strajk += parseNum(wh.Strajk)
            this.def.Lockout += parseNum(wh.Lockout)
            this.def.VrijemeMirovanjaRadnogOdnosa += parseNum(wh.VrijemeMirovanjaRadnogOdnosa)
            this.def.Ukupno += parseNum(wh.Ukupno)
            this.def.WDay = 'UKUPNO:'
          })
          // eslint-disable-next-line
          console.log('Totali ', this.def)
          this.workhours.push(this.def)
        }

      },
      editItem (item) {
        if (item.ForMonth && item.ForYear) {
          this.errorMatch = false
          this.editedIndex = this.workhours.indexOf(item)
          this.editedItem = Object.assign({}, item)
          this.dialog = true
        }
      },
      async deleteItem (item) {
        try {
          if (item.ForMonth && item.ForYear) {
            const index = this.workhours.indexOf(item)
            const warningMessage = item.CalDate
            if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.workhours.splice(index, 1)) {
              await WorkHourService.delete(item.id)
              this.initialize()
            }
          }
        } catch(err) {
          this.error = err
        }
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
      checkMatch () {
        if (this.editedItem.password && this.confpassword &&  this.confpassword === this.editedItem.password) {
          this.errorMatch = null
          this.save()
        } else {
          this.errorMatch = 'Error. Passoword and Confirmation Password are NOT equal!'
          this.valid = false        
        }
      },
      doTotal() {
        this.editedItem.RedovanRad = parseNum(this.editedItem.RedovanRad)
        this.editedItem.NocniRad = parseNum(this.editedItem.NocniRad)
        this.editedItem.PrekovremeniRad = parseNum(this.editedItem.PrekovremeniRad)
        this.editedItem.NedjeljaRad = parseNum(this.editedItem.NedjeljaRad)
        this.editedItem.BlagdanRad = parseNum(this.editedItem.BlagdanRad)
        this.editedItem.TerenRad = parseNum(this.editedItem.TerenRad)
        this.editedItem.PreraspodjelaRada = parseNum(this.editedItem.PreraspodjelaRada)
        this.editedItem.Pripravnost = parseNum(this.editedItem.Pripravnost)
        this.editedItem.DnevniOdmor = parseNum(this.editedItem.DnevniOdmor)
        this.editedItem.TjedniOdmor = parseNum(this.editedItem.TjedniOdmor)
        this.editedItem.NeradniBlagdan = parseNum(this.editedItem.NeradniBlagdan)
        this.editedItem.GodisnjiOdmor = parseNum(this.editedItem.GodisnjiOdmor)
        this.editedItem.BolovanjeDo42Dana = parseNum(this.editedItem.BolovanjeDo42Dana)
        this.editedItem.BolovanjeOd42Dana = parseNum(this.editedItem.BolovanjeOd42Dana)
        this.editedItem.Rodiljni = parseNum(this.editedItem.Rodiljni)
        this.editedItem.PlaceniDopust = parseNum(this.editedItem.PlaceniDopust)
        this.editedItem.NeplaceniDopust = parseNum(this.editedItem.NeplaceniDopust)
        this.editedItem.NenazocnostNaZahtjevRadnika = parseNum(this.editedItem.NenazocnostNaZahtjevRadnika)
        this.editedItem.ZastojKrivnjomPoslodavca = parseNum(this.editedItem.ZastojKrivnjomPoslodavca)
        this.editedItem.Strajk = parseNum(this.editedItem.Strajk)
        this.editedItem.Lockout = parseNum(this.editedItem.Lockout)
        this.editedItem.VrijemeMirovanjaRadnogOdnosa = parseNum(this.editedItem.VrijemeMirovanjaRadnogOdnosa)
        this.editedItem.Ukupno = this.editedItem.RedovanRad + this.editedItem.NocniRad + this.editedItem.PrekovremeniRad + this.editedItem.NedjeljaRad + this.editedItem.BlagdanRad + this.editedItem.TerenRad + this.editedItem.PreraspodjelaRada
        this.editedItem.Ukupno = this.editedItem.Ukupno + this.editedItem.Pripravnost + this.editedItem.DnevniOdmor + this.editedItem.TjedniOdmor + this.editedItem.NeradniBlagdan + this.editedItem.GodisnjiOdmor + this.editedItem.BolovanjeDo42Dana + this.editedItem.BolovanjeOd42Dana + this.editedItem.Rodiljni
        this.editedItem.Ukupno = this.editedItem.Ukupno + this.editedItem.PlaceniDopust + this.editedItem.NeplaceniDopust + this.editedItem.NenazocnostNaZahtjevRadnika + this.editedItem.ZastojKrivnjomPoslodavca + this.editedItem.Strajk + this.editedItem.Lockout + this.editedItem.VrijemeMirovanjaRadnogOdnosa      
      },
      async save () {
        try {
           let doneOK = false
           this.doTotal()
           if (this.editedIndex > -1) {
              await WorkHourService.put(this.editedItem)
              doneOK = true
            } else {
              //this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              //this.editedItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
              this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
              //this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              //this.editedItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : ''
              await WorkHourService.post(this.editedItem)
              doneOK = true
            }
            if (doneOK) {
              this.initialize()
              this.close()
            }
        } catch(err) {
          this.error = err
        }
       
      },
      localDate(dateToFormat) {
        return dateFormat(dateToFormat)
      },
      localFormat(money) {
        return moneyFormat(money)
      },
      findItem () {
        const emObj = {}
        this.mainQuery2 = {...emObj}
        this.mainQuery2.CompanyId = this.$store.state.companyid
        // const myObjStr = JSON.stringify(this.mainQuery2)
      },
      back() {
        this.$router.push({
            name: 'workhour'
          })
      }

    },
  }
</script>