<template>
  <v-container>

      <v-layout row>
        <v-flex xs5>
          <h3>{{lang.BankAccountDetails}}</h3>
          <br>
          <div class="red" v-if="error">Server error: {{error}}</div>

        </v-flex>
        <v-flex xs5>
            <span v-if="this.$store.state.user.AccessLevel <= 3">
              <v-btn color="primary" @click="bankaccountEdit" class="mr-1">{{langC.Edit}}</v-btn>
            </span>
            <span v-if="this.$store.state.user.AccessLevel == 1">
              <v-btn color="red" @click="bankaccountDelete" class="mr-1">{{langC.Delete}}</v-btn>
            </span>
            <v-btn color="green" @click="bankaccountlist">{{langC.Back}}</v-btn>
        </v-flex>
      </v-layout>

        <app-pres-row :label="lang.BankAccountID" :info="bankaccount.id" />
        <app-pres-row :label="lang.BankName" :info="bankaccount.BankName" />
        <app-pres-row :label="lang.IBAN" :info="bankaccount.IBAN" />
        <app-pres-row :label="lang.SWIFT" :info="bankaccount.SWIFT" />
        <app-pres-row :label="lang.BankAccountDescription" :info="bankaccount.BankAccountDescription" />
        <app-pres-row :label="lang.BankAdress" :info="bankaccount.BankAdress" />
        <app-pres-row :label="lang.AccountID" :info="bankaccount.LedgerAccount" />

        <v-layout row>
          <v-flex xs2>
            {{lang.DefaultAccount}}
          </v-flex>
          <v-flex xs10>
              <span v-if="bankaccount.DefaultAccount">
                <i class="material-icons">check_box</i>
              </span>
              <span v-if="!bankaccount.DefaultAccount">
                <i class="material-icons">check_box_outline_blank</i>
              </span>
          </v-flex>
        </v-layout>

        <app-pres-row :label="langC.BusinessYear" :info="BusinessYear" />
        <app-pres-row :label="langC.UserId" :info="bankaccount.UserId" />
        <app-pres-row :label="langC.UserEmail" :info="bankaccount.email" />
        <app-pres-row :label="langC.CreatedAt" :info="locCreatedAt" />
        <app-pres-row :label="langC.UpdatedAt" :info="locUpdatedAt" />

  </v-container>
</template>

<script>
import BankAccountService from '@/services/BankAccountService'
import PresRow from '@/snippets/PresRow'
import langEn from './BankAccountDescEN'
import langHr from './BankAccountDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'

export default {
  name: 'bankaccountview',
  data () {
    return {
      msg: '',
      bankaccount: { },
      locWareHouseUpdateDate: '',
      locWareHouseLockedDate: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locLastLogin: '',
      locForgotDate: '',
      haveRight: 4,
      BusinessYear: 0,
      error: '',
      lang: {},
      langC: {}
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.bankaccountid) {
      try {
        const dbBankAccount = await BankAccountService.show(this.$store.state.bankaccountid)
        this.bankaccount = dbBankAccount.data.BankAccount
        this.BusinessYear = parseInt(this.bankaccount.BusinessYear)
        this.locCreatedAt = new Date()
        this.locUpdatedAt = new Date()
      } catch (error) {
        this.error = error
      }
    }
  },
  methods: {
    async bankaccountlist () {
      try {
        await this.$router.push({
          name: 'bankaccount'
        })
      } catch (err) {
        this.error = err
      }
    },
    async bankaccountDelete () {
      try {
        await this.$router.push({
          name: 'bankaccountdelete'
        })
      } catch (error) {
        this.error = error
      }
    },
    async bankaccountEdit () {
      try {
        this.$store.dispatch('seteditItem', 1)
        await this.$router.push({
          name: 'bankaccountcreateedit'
        })
      } catch (error) {
        this.error = error
      }
    }
  },
  computed: {

  },
  watch: {

  },
  components: {
    appPresRow: PresRow
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
