import { render, staticRenderFns } from "./VehiclePrint.vue?vue&type=template&id=f64bd9f0&scoped=true"
import script from "./VehiclePrint.vue?vue&type=script&lang=js"
export * from "./VehiclePrint.vue?vue&type=script&lang=js"
import style0 from "./VehiclePrint.vue?vue&type=style&index=0&id=f64bd9f0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f64bd9f0",
  null
  
)

export default component.exports