import { render, staticRenderFns } from "./MainLedgerList4.vue?vue&type=template&id=51e2708d&scoped=true"
import script from "./MainLedgerList4.vue?vue&type=script&lang=js"
export * from "./MainLedgerList4.vue?vue&type=script&lang=js"
import style0 from "./MainLedgerList4.vue?vue&type=style&index=0&id=51e2708d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51e2708d",
  null
  
)

export default component.exports