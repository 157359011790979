const docEN = {
  TravelOrder: 'Putni nalog',
  TravelOrders: 'Putni nalozi',
  partnerId : 'Id ',
  partnerName: 'Partner',
  partnerNameShort: 'Partner',
  TravelOrderId : 'Order Id',
  OrderDate: 'Order Date',
  eId : 'Emp.Id',
  eemail: 'email',
  phone: 'phone',
  eFirst: 'First name',
  eLast: 'Last name',
  eVatID: 'VATid',
  eJOPP: '',
  WorkPlace: 'WorkPlace',
  TravelTo: 'Travel To',
  TravelTask: 'Travel Task',
  TravelLastDays: 'Travel Last Days',
  TravelLastDaysLett: ' ',
  TravelVeichle: 'Veichle',
  TravelVeichleBrand: 'Brand',
  TravelVeichleReg: 'Registration',
  ExpensesForwardHomeC: 'Advance Home',
  ExpensesForwardForeC: 'Advance EUR',
  ReportInDays: 'Report in Days',
  ApprovedBy: 'Approved',
  ApprovedByFirst: 'First',
  ApprovedByLast: 'Last',
  ApprovedByemail: 'email',
  FinTravel1: ' ',
  FinTravel2: ' ',
  FinTravel3: ' ',
  FinStartDate: ' ',
  FinStartTime: ' ',
  FinEndDate: ' ',
  FinEndTime: ' ',
  FinHours: '',
  FinDays: '',
  FinDaysAmount: '',
  FinDaysTotal: '',
  FinDaysEUR: 'Broj EUR dnev.',
  FinDaysAmountEUR: 'Iznos EUR dnev.',
  FinDaysTotalEUR: 'Ukupna EUR svota',
  FinTrStart: '',
  FinTrEnd: '',
  FinRel1From: ' ',
  FinRel1To: ' ',
  FinRel1Km: '',
  FinRel1KmKn: '',
  FinRel1KmEur: '',
  FinRel1Total: '',
  FinRel1TotalEur: '',
  FinRel2From: ' ',
  FinRel2To: ' ',
  FinRel2Km: '',
  FinRel2KmKn: '',
  FinRel2KmEur: '',
  FinRel2Total: '',
  FinRel2TotalEur: '',
  FinRel3From: ' ',
  FinRel3To: ' ',
  FinRel3Km: '',
  FinRel3KmKn: '',
  FinRel3KmEur: '',
  FinRel3Total: '',
  FinRel3TotalEur: '',
  FinRel4From: ' ',
  FinRel4To: ' ',
  FinRel4Km: '',
  FinRel4KmKn: '',
  FinRel4KmEur: '',
  FinRel4Total: '',
  FinRel4TotalEur: '',
  FinRel5From: ' ',
  FinRel5To: ' ',
  FinRel5Km: '',
  FinRel5KmKn: '',
  FinRel5KmEur: '',
  FinRel5Total: '',
  FinRel5TotalEur: '',
  FinOther1: ' ',
  FinOther1Kn: '',
  FinOther1Eur: '',
  FinOther2: ' ',
  FinOther2Kn: '',
  FinOther2Eur: '',
  FinOther3: ' ',
  FinOther3Kn: '',
  FinOther3Eur: '',
  FinOther4: ' ',
  FinOther4Kn: '',
  FinOther4Eur: '',
  FinOther5: ' ',
  FinOther5Kn: '',
  FinOther5Eur: '',
  OtherExp: 'Ostali troškovi ukupno:',
  OtherExpTotal: 'Ostali troškovi ukupno:',
  FinTotal: ' ',
  FinTotalKn: '',
  FinTotalEur: '',
  FinPayReturn: ' ',
  FinPayReturnKn: '',
  FinPayReturnEur: '',
  FinReport: ' ',
  FinEndPlace: 'Place',
  FinEndDate2: 'Date',
  ErrorUser: 'Error! First enter emloyees and then enter travel order!',
  SelectEmployee: 'Select Employee',
  SelectApp: 'Approved by',
  Finished: 'Finished'
}

export default docEN