<template>
  <div>

            <v-layout row>
              <v-flex xs3 class="px-2 headline" >
                Broj knjiženja:
              </v-flex>
              
              <v-flex xs2 >
                  <span class="headline">{{ locBookID }}
                  </span>
              </v-flex>
               <v-flex xs7>
                    {{langC.CreatedAt}} {{locCreatedAt}}, {{langC.UpdatedAt}} {{locUpdatedAt}}
              </v-flex>
            </v-layout>


            <v-layout row class="mt-3">
              <v-flex xs10>
                {{langC.Company}} <span style="font-weight:bold">{{mainledger.CompanyNameDatabase}}</span>
              </v-flex>
              <v-flex xs2 >
                  
              </v-flex>
            </v-layout>

            <v-layout row >
              <v-flex xs5>
                  {{langC.BusinessYear}} {{parseInt(mainledger.BusinessYear)}}
              </v-flex>
               <v-flex xs6 >
                  {{langC.User}} <span style="font-weight:bold">{{mainledger.First}} {{mainledger.Last}}, {{mainledger.email}}, {{mainledger.UserId}}</span>
              </v-flex>
            </v-layout>

            <v-divider class="mt-2"></v-divider>

          <v-row row class="mt-3">
              <v-flex xs5 >
                {{lang.BookingDate}} <span style="font-weight:bold">{{locBookingDate}} </span>
              </v-flex>

             <v-flex xs4>
  
             </v-flex>

              <v-flex xs3 >

             </v-flex>

           </v-row>
                   
            <v-divider class="mt-2"></v-divider>
             <v-row v-if="showTotals" class="mt-3">

              <v-flex xs3 class="headline px-2" >
                Uk.duguje:
              </v-flex>

              <v-flex xs2 class="headline">
                <span >{{ localMoney(mainledger.DebitTotal ) }}
                </span> 
              </v-flex>
              
              <v-flex xs3 class="headline">
                  Uk.potražuje:
              </v-flex>

              <v-flex xs2 class="headline">
                <span >{{ localMoney(mainledger.CreditTotal ) }}</span>
              </v-flex>

              <v-flex xs2 >
                <span class="headline red white--text" v-if="mainledger.DiffTotal != 0">{{ localMoney(mainledger.DiffTotal) }}</span>
                <span class="headline green white--text" v-if="mainledger.ClosedAccount">{{ localMoney(mainledger.DiffTotal) }}</span>
              </v-flex>
            </v-row> 


          <div class="pink darken-2" v-html="error" />

  </div>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dayjs from 'dayjs'
import moneyFormat from '@/snippets/moneyFormat'

export default {
  name: 'mainledgerview3',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      lang: {},
      langC: {},
      saving: false,
      menu: false,
      menu2: false,
      menu3: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      date1: null,
      date2: null,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      msg: '',
      valid: true,
      documentName: '',
      documentNameLoc: '',
      mainledger: { },
      mainledgerActiv: {},
      error: null,
      localError: false,
      newDocument: {},
      confpassword: '',
      size: 'sm',
      required: (value) => !!value || 'Required.',
      partners: [],
      employes: [],
      banks: [],
      currnecy: [],
      users: [],
      CompanyId: 0,
      selectPa: {},
      selectBank: {},
      selectCR: {},
      selectUs: {},
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
        documentName: '',
        MLType: 0
      },
      currnecyType: [
        { text: 'Kn', value: 'Kn' },
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      bookType: [],
      money: {
        prefix: '= ',
        suffix: ' ',
        precision: 3
      },
      price: 7.500,
      locCreatedAt: '',
      locUpdatedAt: '',
      locBookingDate: '',
      locInvoiceDate: '',
      locInvoicePaymentDeadlineDate: '',
      locBookID: 0,
      locCanUse: 0,
      locCantUse: 0,
      showTotals: true
    }
  },
  components: {
  },
  async mounted () {
    try {
      if (this.$store.state.language === "EN") {
        this.lang = {...ledgerEN}
        this.langC = {...commEn}
      }
      if (this.$store.state.language === "HR") {
        this.lang = {...ledgerHR}
        this.langC = {...commHr}
      }
      this.mainledger = this.$store.state.mainledgerActiv ? this.$store.state.mainledgerActiv : {}
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc      
      if (this.$store.state.companyid) {
        this.CompanyId = await this.$store.state.companyid
        this.mainQuery.CompanyId = await this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.BusinessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentActiv) {
        this.mainQuery.documentName = this.$store.state.documentActiv
      }
      this.locCreatedAt = dayjs(this.mainledger.createdAt).format('DD.MM.YYYY HH:MM')
      this.locUpdatedAt = dayjs(this.mainledger.updatedAt).format('DD.MM.YYYY HH:MM')
      this.locBookingDate = dayjs(this.mainledger.BookingDate).format('DD.MM.YYYY')
      this.locInvoiceDate = dayjs(this.mainledger.InvoiceDate).format('DD.MM.YYYY')
      this.locInvoicePaymentDeadlineDate = dayjs(this.mainledger.InvoicePaymentDeadlineDate).format('DD.MM.YYYY')
      this.locBookID = parseInt(this.mainledger.BookID)
      this.locCanUse = parseFloat(this.mainledger.VAT1CanUse) + parseFloat(this.mainledger.VAT2CanUse) + parseFloat(this.mainledger.VAT3CanUse)
      this.locCantUse = parseFloat(this.mainledger.VAT1CanTUse) + parseFloat(this.mainledger.VAT2CanTUse) + parseFloat(this.mainledger.VAT3CanTUse)
      this.showTotals = this.$store.state.mainledgerTotals === 1 ? false : true 
    } catch (error) {
      // eslint-disable-next-line
      console.log('greska kod monut dokumenta', error)
    }
  },
  computed: {
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    close () {
      this.show = false
    },
    async mainledgeragain () {
      try {
        this.$forceUpdate()
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>

