const docHR = {
  CityCodeID:'Grad ID',
  CityCode: 'Šifra',
  CityName: 'Naziv',
  CityIBAN: 'IBAN',
  CityBank: 'Banka',
  NewCity: 'Novi grad-općina',
  EditCity: 'Ured grad-općinu',
  EmptyCity: 'Nema gradova - općina',
  City: 'Gradovi-općine'
}

export default docHR