<template>

    <v-card>
        <v-card-title>
          <v-toolbar color="deep-purple darken-2" dark> 
            Trošak radnika
            <v-spacer></v-spacer>              
            <v-btn class="mx-2" fab dark color="deep-purple darken-1" @click='newMaterial'>
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="10"
            @click:row="navigateTo"
            :mobile-breakpoint="0"
          >
          <template v-slot:[`item.empWrkExpHrs`]="{ item }">
              <span>{{ localMoney(item.empWrkExpHrs) }}</span>
          </template>
          <template v-slot:[`item.empExp1`]="{ item }">
              <span>{{ localMoney(item.empExp1) }}</span>
          </template>
          <template v-slot:[`item.empExp2`]="{ item }">
              <span>{{ localMoney(item.empExp2) }}</span>
          </template>
          <template v-slot:[`item.itemBaseAmount`]="{ item }">
              <span>{{ localMoney(item.itemBaseAmount) }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>

</template>

<script>
import moneyFormat from '@/snippets/moneyFormat'

export default {
  name: 'documentdetaillist31',
  data () {
    return {
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: '',
        databaseDocID: 0,
        FreeF1: 2
      }
    }
  },
  async mounted () {
    try {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentActiv) {
        this.mainQuery.documentName = this.$store.state.documentActiv
      }
      if (this.$store.state.databaseDocID) {
        this.mainQuery.databaseDocID = this.$store.state.databaseDocID
      }
      this.mainQuery.FreeF1 = 2
      
      this.items = this.$store.state.documentActivItems.filter(item => {
            return parseInt(item.FreeF6)  === 31
          })
  
      if (this.$store.state.language === 'EN') {
        this.headers.push(
          {
            text: 'Worker',
            align: 'left',
            sortable: true,
            value: 'hashtag'
          },
          { text: 'Date', align: 'left', value: 'empWorkDay' },
          { text: 'Hours ', align: 'left', value: 'empWrkHrs' },
          { text: 'Hour/price', align: 'left', value: 'empWrkExpHrs' },
          { text: 'Travel ', align: 'left', value: 'empExp1' },
          { text: 'Travel2', align: 'left', value: 'empExp2' },
          { text: 'Wrk.Amount', align: 'right', sortable: true, value: 'itemBaseAmount' },
          { text: 'DbId', value: 'id' }
       )
      }
      if (this.$store.state.language === 'HR') {
        this.headers.push(
          {
            text: 'Radnik',
            align: 'left',
            sortable: true,
            value: 'hashtag'
          },
          { text: 'Datum', align: 'left', value: 'empWorkDay' },
          { text: 'Radni sati', align: 'left', value: 'empWrkHrs' },
          { text: 'Bruto satnica', align: 'left', value: 'empWrkExpHrs' },
          { text: 'Dnevnice ', align: 'left', value: 'empExp1' },
          { text: 'Kilometri', align: 'left', value: 'empExp2' },
          { text: 'Trosak radnika', align: 'right', sortable: true, value: 'itemBaseAmount' },
          { text: 'Id baze', value: 'id' }
        )
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    navigateTo (item) {
      this.$store.dispatch('seteditItem', 1)
      this.$store.dispatch('setDocumentDetailid', item.id)
      this.$store.dispatch('settypePOS', 31)
      // warehouse not use
      this.$store.dispatch('setDocumentSide', 3) 
      this.$router.push({
        name: 'documentdetailcreateedit3'
      })
    },
    newMaterial () {
      this.$store.dispatch('seteditItem', 0)
      this.$store.dispatch('settypePOS', 31)
      // warehouse not use
      this.$store.dispatch('setDocumentSide', 3) 
      this.$router.push({
        name: 'documentdetailcreateedit3'
      })
    }
  },
  computed: {

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
