const docEN = {
  CompanyName: 'Company Name',
  Company: 'Company',
  Companies: 'Companies',
  CompanyNameShort: 'Name Short',
  CompanyNameDatabase: 'Name Database',
  CompanyBuildingNameNumber: 'Building Name/Number',
  CompanyStreet: 'Street',
  CompanyCity: 'City',
  CompanyPostcode: 'Post code',
  CompanyCountyRegion: 'County/Region',
  CompanyState: 'State',
  CompanyVATID: 'VAT ID',
  Companyemail: 'email',
  CompanyPhones: 'Phones',
  CompanyBankName: 'Bank Name',
  CompanyBankAddress: 'Bank Address',
  CompanyBankIBAN: 'IBAN',
  CompanyBankSWIFT: 'SWIFT',
  Director: 'CEO',
  CompanyDirectorTitle: 'Title',
  CompanyDirectorFirst: 'First Name',
  CompanyDirectorLast: 'Last Name',
  CompanyDirectorFormer: 'Former Name',
  CompanyDirectorStateResidence: 'State / Residence',
  CompanyDirectorNationality: 'Nationality',
  CompanyDirectorDateOfBirth: 'Date of Birth',
  CompanyDirectorBusinessOccupation: 'Bussiness Occupation',
  CompanyDirectorAdressBuildingNameNumber: 'Building Name/Number',
  CompanyDirectorAdressStreet: 'Street',
  CompanyDirectorCity: 'City',
  CompanyDirectorPostcode: 'Postcode',
  CompanyDirectorCountyRegion: 'County/region',
  CompanyDirectorEmail: 'email',
  CompanyDirectorPhone: 'Phone',
  CompanyDirectorContactOption: 'Contact Option',
  Secretary: 'Contact Person',
  CompanySecretaryTitle: 'Title',
  CompanySecretaryFirst: 'First Name',
  CompanySecretaryLast: 'Last Name',
  CompanySecretaryFormer: 'Former Name',
  CompanySecretaryStateResidence: 'State Residence',
  CompanySecretaryNationality: 'Nationality',
  CompanySecretaryDateOfBirth: 'Date of Birth',
  CompanySecretaryBusinessOccupation: 'Business Occupation',
  CompanySecretaryAdressBuildingNameNumber: 'Building Name/Number',
  CompanySecretaryAdressStreet: 'Street',
  CompanySecretaryCity: 'City',
  CompanySecretaryPostcode: 'Postcode',
  CompanySecretaryCountyRegion: 'County/region',
  CompanySecretaryEmail: 'email',
  CompanySecretaryPhone: 'Phone',
  CompanySecretaryContactOption: 'Occupation',
  CompanyInvoiceMemo: 'Inovice Memo',
  CompanyInvoiceForeginMemo: 'Invoice Foregin Memo',
  CompanyDeliveryNoteMemo: 'Note Memo',
  CompanyDeliveryNoteForeginMemo: 'Note Foregin Memo',
  CompanyInvoiceDefDays: 'Nr of days',
  CompanyInvoicePlace: 'Invoice place',
  CompanyInvoiceType: 'Invoice Type',
  CompanyInvoiceHint: 'Invoice hint',
  CompanyInvoiceSpeed: 'Invoice Speed',
  CompanyUseRetailPrice: 'Use retail prices',
  CompanyShowLastSaleBuyer: 'Show last Sale Buyer',
  CompanyShowWarehouseState: 'Show warehouse state',
  CompanyShowInputPrice: 'Show input price',
  CompanyVATonPay: 'VAT per payment',
  CompanyRetail: 'Retail',
  CompanyDefaultWarehouse: 'Default Warehouse',
  CompanyPrinterSize: 'Printer default',
  CompanyInputFilesPath: 'Input files path',
  CompanyOutputFilesPath: 'Output files path',
  CompanyTransferDataFilesPath: 'Transfer data files path',
  CompanyStartInvoiceNumber: 'Start Invoice Number',
  CompanyStartBillNumber: 'Start Bill Number',
  CompanyType: 'Type',
  CompanyDescriptionUp: 'Description Top',
  CompanyDescriptionDown: 'Description Bottom',
  CompanyBusinessSpace: 'Bussiness Space',
  CompanyBusinessDevice: 'Bussiness Device',
  CompanyBusinessSpaceRetail: 'Business Space Retail',
  CompanyBusinessDeviceRetail: 'Business Device Retail',
  CompanySendEmailTask: 'Send email on task',
  StartFrom: 'Start from date',
  Activ: 'Activ',
  BusinessYears: 'Business Years',
  BusinessYear: 'Business Year',
  CompanyBusinessYearsEmpty: 'Company Business Years  are Empty',
  NewBusinessYears: 'New Business Years',
  EditBusinessYears: 'Edit Business Years',
  BusinessYearStartDate: 'Business Year Start Date',
  BusinessYearEndDate: 'Business Year End Date',
  BusinessYearLocked: 'Business Year Locked',
  BusinessYearKey1: 'Business Year Key1',
  BusinessYearKey2: 'Business Year Key2',
  defaultBusinessYear: 'default Business Year',
  CompanyId: 'Id',
  DefaultCompany: 'Default Company',
  UserId: 'Id',
  CompanyUsers: 'Company Users',
  CompanyUser: 'Company User',
  CompanyUsersEmpty: 'Company users are Empty',
  NewCompanyUser: 'New Company User',
  EditCompanyUser: 'Edit Company User',
  UserIdRight: 'User Right',
  Priv1: '1 max privileges',
  Priv2: '2 medium to max privileges',
  Priv3: '3 medium privileges',
  Priv4: '4 low to midium privileges',
  Priv5: '5 low privileges'
}

export default docEN