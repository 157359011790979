import Api from '@/services/Api'

export default {
  index (search) {
    return Api().get('calendar', {
      params: {
        search: search
      }
    })
  },
  index2 (calendar) {
    return Api().get('calendar/index2', {params: calendar})
  },
  show (calendarId) {
    return Api().get(`calendar/${calendarId}`)
  },
  post (newcalendar) {
    return Api().post('calendar', newcalendar)
  },
  put (calendar) {
    return Api().put(`calendar/${calendar.id}`, calendar)
  },
  delete (calendarId) {
    return Api().delete(`calendar/${calendarId}`)
  }
}
