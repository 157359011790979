<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :scrim="false"
      persistent
      width="auto"
    >
      <v-card
        color="primary"
      >
        <v-card-text>
          {{message}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>


export default {
  name: '',
  props: [ 'message', 'dcolor', 'showDialog'],
  data: ()=> ({
    dialog: true
  }),
  created () {
    this.dialog = this.showDialog
  },


}

</script>