<template>
      <v-dialog v-model="dialog2" max-width="500px">
        <v-card>
          <v-card-title>
            Language selection - odabir jezika
            <br>
            Current language / trenutno odabrani jezik : {{ this.$store.getters.NeedLanguage }}
          </v-card-title>
          <v-card-text>
             <v-select
              :items="select"
              v-model="e1"
              label="Choose language"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click.stop="navigateTo">Set Language</v-btn>
            <v-btn color="red" text @click.stop="goHome">Cancel/Odustani</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>


export default {
  name: 'languageselect',
  data () {
    return {
      e1: null,
      msg: '',
      newlang: '',
      dialog2: true,
      select: [ 'English', 'Croatian']
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.e1 = 'English'
    }
    if (this.$store.state.language === 'HR') {
      this.e1 = 'Croatian'
    }
  },
  methods: {
    async navigateTo () {
      try {
        this.dialog2 = false
        this.newlang = this.e1
        if (this.e1 === 'English') {
          this.$store.dispatch('setLanguage', 'EN')
        }
        if (this.e1 === 'Croatian') {
          this.$store.dispatch('setLanguage', 'HR')
        }
        this.$router.push({
          name: 'home'
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
    },
    async goHome () {
      try {
        this.dialog2 = false
        // this.$store.dispatch('setCompanyActiv', compSelect)
        this.$router.push({
          name: 'home'
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
    }
  },
  computed: {

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
