<template>
  <div>
    <v-card>
        <v-card-title>
          <v-toolbar color="red" dark> 
            {{lang.errorWorkingOrder}}
          </v-toolbar>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="errorItems"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="10"
            item-key="id"
          >
          <template v-slot:[`item.supp3`]="{ item }">
              <span>{{ localMoney(item.supp3) }}</span>
          </template>
          <template v-slot:[`item.supp33`]="{ item }">
              <span>{{ localMoney(item.supp33) }}</span>
          </template>
          <template v-slot:[`item.diff`]="{ item }">
              <span>{{ localMoney(item.diff) }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>

  </div>    
</template>

<script>
import moneyFormat from '@/snippets/moneyFormat'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'

export default {
  name: 'documentsaleserrorlistWO',
  props: ['errorItems'],
  data () {
    return {
      lang: {},
      langC: {},
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: '',
        databaseDocID: 0,
        FreeF1: 1
      },
      showCard: false,
      refreshCard: 0,
    }
  },
  mounted () {
    this.comp = this.$store.state.companyActiv
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }

    this.headers.push(
      {text: 'Doc', value: 'documentIdGeneral', width: '150px'},
      {text: 'Dob.utroseno', value: 'supp3', width: '100px'},
      {text: 'Dob.proizvedeno', value: 'supp33', width: '100px'},
      {text: 'Razlika', value: 'diff', width: '100px'},
      {text: this.lang.id, value: 'id', width: '50px'}
    )
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    navigateTo () {
      this.refreshCard += 1
    },

  },
  computed: {

  },
  watch: {

  },
  components: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
