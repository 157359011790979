import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('city', {
      params: query 
    })
  },
  show (cityId) {
    return Api().get(`city/${cityId}`)
  },
  post (newcardtype) {
    return Api().post('city', newcardtype)
  },
  put (city) {
    return Api().put(`city/${city.id}`, city)
  },
  delete (cityId) {
    return Api().delete(`city/${cityId}`)
  }
}