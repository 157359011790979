<template>
  <v-layout row justify-center>
      <v-card v-if="this.$store.state.language == 'EN'">
        <v-card-title class="headline">Are you sure to delete Item?</v-card-title>
        <v-card-text>We not recomend to delete Item. Better disable it. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" flat @click="deleteItem">Delete</v-btn>
          <v-btn color="green darken-1" flat @click="itemlist">Cancel</v-btn>
        </v-card-actions>        
      </v-card>
      <v-card v-if="this.$store.state.language == 'HR'">
        <v-card-title class="headline">Da li ste sigurni da zelite obrisati Artikl?</v-card-title>
        <v-card-text>NE preporucujemo da briste. Iskljucite ga ili kontaktirate administratora. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" flat @click="deleteItem">Obrisi</v-btn>
          <v-btn color="green darken-1" flat @click="itemlist">Odustani</v-btn>
        </v-card-actions>
      </v-card>      
  </v-layout>
</template>

<script>
import ItemService from '@/services/ItemService'
import { newUpdates, testLastUpdate } from '../../services/TestLastUpdate'

export default {
  name: 'itemdelete',
  data () {
    return {
      msg: '',
      dialog: false,
      mainQuery: {}
    }
  },
  methods: {
    async deleteItem () {
      try {
        if (this.$store.state.itemid) {
          await ItemService.delete(this.$store.state.itemid)
          await newUpdates(1)
          await testLastUpdate()
          
          // if (this.$store.state.companyid) {
          //   this.mainQuery.CompanyId = await this.$store.state.companyid
          // }
          // this.itemsLoc = (await ItemService.index(this.mainQuery)).data.items
          // if (this.itemsLoc) {
          //   this.$store.dispatch('setActivItems', this.itemsLoc)
          // }
          // if (this.newItemAround) {
          //   this.$store.dispatch('setSelectedItemId', this.item.itemID)
          // }
          await this.$router.push({
            name: 'item'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async itemlist () {
      try {
        await this.$router.push({
          name: 'item'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
