<template>
  <v-container>
    <v-row no-gutters justify="center" class="pa-0">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">

          <v-form
            name="DocumentForm"
            autocomplete="off">
            <v-row no-gutters v-if="!hideHeader">
                <h2 class="pl-2">
                  <span v-if="this.$store.state.editItem  === 0">
                    {{langC.CreateNew}} 
                  </span>
                  <span v-if="this.$store.state.editItem === 1">
                    {{langC.Edit}} 
                  </span>                  
                </h2>
            </v-row>

            <v-row no-gutters v-if="!hideHeader">
              <v-col sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 px-2">
                   <span style="font-weight:bold">{{Company}}</span>
              </v-col>
              <v-col sm="4" md="4" xs="4" lg="4" xl="4" class="py-0 px-2">
                  {{langC.BusinessYear}} <span style="font-weight:bold">{{BusinessYear}}</span>
              </v-col>
              <v-col sm="2" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                  <v-btn text color="red darken-1" @click="deleteBook(show === 1 ? 1 : 2)">{{ show === 1 ? lang.DeleteExpense2 : lang.DeleteReceive2}}</v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="hideHeader">
              <v-col sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 px-2">

              </v-col>
              <v-col sm="4" md="4" xs="4" lg="4" xl="4" class="py-0 px-2">

              </v-col>
              <v-col sm="2" md="2" xs="2" lg="2" xl="2" class="py-0 px-2">
                  <v-btn text color="red darken-1" @click="deleteBook(show === 1 ? 1 : 2)">{{ show === 1 ? lang.DeleteExpense2 : lang.DeleteReceive2}}</v-btn>
              </v-col>
            </v-row>

            <v-divider></v-divider>
              <v-row no-gutters>
                <v-col sm="12" md="3" xs="3" lg="3" xl="3" class="py-0 px-2" v-if="show === 1">
                  <div class="green darken-2 text-center">
                    <span class="white--text display-1">{{lang.Rec2}}</span>
                  </div>
                </v-col>
                <v-col sm="12" md="3" xs="3" lg="3" xl="3" class="py-0 px-2" v-if="show === 2">
                  <div class="blue darken-2 text-center">
                    <span class="white--text display-1">{{lang.Exp2}}</span>
                  </div>
                </v-col>
                <v-col sm="6" md="6" xs="6" lg="6" xl="6" class="py-0 px-2">

                </v-col>
                <v-col sm="6" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  <v-checkbox v-model="mainbook.Skip" label="Nije kupac/dobavljač"></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="3" xs="3" lg="3" xl="3" >
                  <v-dialog
                    ref="dialog1"
                    persistent
                    v-model="modal1"
                    width="290px"
                    :return-value.sync="mainbook.BookDate"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatDate1"
                      :label="lang.BookDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
                    <v-date-picker v-model="mainbook.BookDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal1 = false">{{langC.Cancel}}</v-btn>
                      <v-btn color="primary" @click="$refs.dialog1.save(mainbook.BookDate)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2"  v-if="show === 1">
                  <v-text-field class="pr-2"
                      :label="lang.RecBank"
                      v-model.number="mainbook.RecBank"
                      type="number"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2"  v-if="show === 1">
                  <v-text-field class="pr-2"
                      :label="lang.RecCash"
                      v-model.number="mainbook.RecCash"
                      type="number"
                    ></v-text-field> 
                </v-col>
              
                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3" v-if="show === 1">
                    <div class="text-left">
                      <span class="title green--text">Ukupno:{{formatPrice(mainbook.RecTotal)}} {{defCurrency}}</span>
                    </div>
                </v-col>

                 <v-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2" v-if="show === 2">
                  <v-text-field class="pr-2"
                      :label="lang.ExpBank"
                      v-model.number="mainbook.ExpBank"
                      type="number"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2"  v-if="show === 2">
                  <v-text-field class="pr-2"
                      :label="lang.ExpCash"
                      v-model.number="mainbook.ExpCash"
                      type="number"
                    ></v-text-field> 
                </v-col>
               
                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3"  v-if="show === 2">
                    <div class="text-left">
                      <span class="title indigo--text">Ukupno:{{formatPrice(mainbook.ExpTotal)}}  {{defCurrency}}</span>
                    </div>
                </v-col>
              </v-row>
              
              <v-row dense no-gutters>
                 <v-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2"  >
                  <v-text-field class="pr-2"
                      :label="lang.BookDesc1"
                      v-model="mainbook.BookDesc1"
                    ></v-text-field>
                </v-col>
                 <v-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2"  >
                  <v-text-field class="pr-2"
                      :label="lang.BookDesc2"
                      v-model="mainbook.BookDesc2"
                    ></v-text-field>
                </v-col>                                
            </v-row>

            <v-divider></v-divider>
            <br/>
              <v-row  no-gutters>
                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3"  class="py-4 px-2">
                    {{ langC.Partner }}
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4"  class="py-0 px-0 pr-2">
                  <v-autocomplete
                    :items="partners"
                    v-model="selectPa"
                    :label="langC.SelectPartner"
                    item-text="partnerName"
                    item-value="id"
                    return-object
                    persistent-hint
                    clearable
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2" >
                    <v-text-field
                      label="OIB *"
                      v-model="mainbook.partnerVATID"
                      required
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" xs="0" sm="0" md="1" lg="1" xl="1">
    
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2" >
                     <v-btn text color="primary" class="pt-3" @click="addeditPartner()">{{lang.PartnerSave}}</v-btn>
                </v-col>

              </v-row>
              <v-row no-gutters>
                <v-col xs="0"  sm="0" md="3"  lg="3" xl="3">
                    
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4"  lg="4" xl="4">
                    <v-text-field class="pr-2"
                      label="Naziv partnera"
                      v-model="mainbook.partnerName"
                    ></v-text-field> 
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field class="pr-2"
                      label="IBAN"
                      v-model="mainbook.partnerBankIBAN"
                    ></v-text-field> 
                </v-col>

                <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2">
                  <v-btn color="primary" text @click="checkByIban">{{lang.checkPartnerByIban}}</v-btn>
                </v-col>

              </v-row>

              <v-row no-gutters>
                <v-col  sm="0" md="3" xs="3" lg="3" xl="3">
                    
                </v-col>
                <v-col cols="12" sm="6" md="3" xs="3" lg="3" xl="3" class="pr-2">
                    <v-text-field
                      label="Ulica"
                      v-model="mainbook.partnerStreet"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="pr-2">
                    <v-text-field
                      label="Br"
                      v-model="mainbook.partnerBuildingNameNumber"
                    ></v-text-field> 
                </v-col>

                <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2" class="pr-2">
                    <v-text-field
                      label="Mjesto"
                      v-model="mainbook.partnerCity"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="1" lg="1" xl="1" class="pr-2">
                    <v-text-field
                      label="Pošta"
                      v-model="mainbook.partnerPostcode"
                    ></v-text-field> 
                </v-col>
                 <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                      label="email"
                      v-model="mainbook.partneremail"
                    ></v-text-field> 
                </v-col>
                
              </v-row>

              <v-divider></v-divider>
              <br/>
              
              <v-row no-gutters >
                 <v-col sm="6" md="3" xs="3" lg="3" xl="3" >
                  <v-text-field class="pr-2"
                      :label="show === 1 ? lang.CloseRec : lang.CloseExp"
                      v-model="mainbook.BookID"
                    ></v-text-field> 
                </v-col>
                <v-col sm="6" md="1" xs="1" lg="1" xl="1" >
                  <v-text-field class="pr-2"
                      :label="langC.BusinessYear"
                      v-model="mainbook.BookBusinessYear"
                    ></v-text-field> 
                </v-col>
                 <v-col xs="4" sm="4" md="1"  lg="1" xl="1" >
                  <v-btn text color="primary" class="pt-3" @click="card(2)">{{ show === 1 ? lang.CardOpenInvoice : lang.CardOpenRecivable}}</v-btn>
                </v-col>
                 <v-col xs="4" sm="4" md="1"  lg="1" xl="1" >
                  <v-btn text color="primary" class="pt-3" @click="card(1)">{{ show === 1 ? lang.CardInvoice : lang.CardRecivable}}</v-btn>
                </v-col>

                <v-col xs="4" sm="4" md="2"  lg="1" xl="1">

                </v-col>
                <v-col sm="4" md="2" xs="2" lg="2" xl="2" v-if="showCard">
                  <span class="title blue--text">Zatvoreno:{{formatPrice(selectedAmount)}}{{defCurrency}}</span>
                </v-col>
                 <v-col sm="4" md="2" xs="2" lg="2" xl="2" v-if="showCard">
                   <span class="title pink--text">Razlika:{{formatPrice(restAmonut)}} {{defCurrency}}</span>
                </v-col>

                <!-- <v-col sm="4" md="1" xs="1" lg="1" xl="1" >
                  <v-btn text color="primary" class="pt-3" @click="addBook(show === 1 ? 1 : 2)">{{ show === 1 ? lang.AddInvoice : lang.AddRecivable}}</v-btn>
                </v-col>
                <v-col sm="4" md="1" xs="1" lg="1" xl="1" >
                  <v-btn text color="primary" class="pt-3" @click="showBooks(show === 1 ? 1 : 2)">{{ show === 1 ? lang.BookOutInvoices : lang.BookInInvoices}}</v-btn>
                </v-col> -->
              </v-row>
          </v-form>
          <div class="pink" v-html="error" />

          <v-btn v-if="show === 1"
            class="mr-2"
            color="green"
            :disabled="!formIsValid"
            @click="register" >
             {{lang.Rec2}} -> PROKNJIŽI
          </v-btn>
          <v-btn v-if="show === 2"
            class="mr-2"
            color="blue"
            :disabled="!formIsValid"
            @click="register" >
            {{lang.Exp2 }} -> PROKNJIŽI
          </v-btn>

          <v-btn
            color="purple"
            @click="mainledgerlist">
            {{langC.Back}}
          </v-btn>
      </v-col>
    </v-row>

    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.haveBookIn || this.haveBookOut">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <MainLedgerListFast :partnerId="mainbook.partnerId" :docSide="docSide" />
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="showCard">
      <MainBookCompCard2 :key="componentKey" :head="head" :partnerVATID= "partnerVATID" :type="docSide" :defCurrency="defCurrency" :hHeader= "hideHeader" :mainbook="mainbook" :showOpen="showOpen"/>
    </v-row>


    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>
    <div v-if="showDialog">
      <Dialog :header="header" :message="message" :dcolor="dcolor" :showDialog="showDialog"/>
    </div>
  </v-container>
</template>

<script>
import PartnerService from '@/services/PartnerService'
import MainBookCompService from '@/services/MainBookCompService'
// import MainBookTypeService from '@/services/MainBookTypeService'
// import MainBookDocService from '@/services/MainBookDocService'
import MainLedgerService from '@/services/MainLedgerService'
import MainLedgerListFast from '../MainLedger/MainLedgerListFast'
import ledgerEN from '../MainBook/mainbookDescEn'
import ledgerHR from '../MainBook/mainbookDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import { defMainBook, defNewMainBook } from '../MainLedger/mainledgerHelper'
import {parseNum, round, dateFormat} from '@/snippets/allSnippets'
import MainBookCompCard2 from './MainBookCompCard2'
//import {checkPartners} from '../MainLedger/mainledgerHelper'
import { testLastUpdate, newUpdates } from '@/services/TestLastUpdate'
import Dialog from  '../../snippets/Dialog'
import { mapGetters } from 'vuex'
import { addUpdatePartner} from '@/snippets/partnerCheck'
import dayjs from 'dayjs'

export default {
  name: 'mainbookcreate',
  props: ['head', 'hHeader'],
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      showDialog: false,
      header: '',
      message: '',
      dcolor: '',
      defCurrency: '',
      exRate: 1,
      search: null,
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      lang: {},
      langC: {},
      valid: true,
      lazy: false,
      rules: {
        required: value => !!value || '*'
      },
      menu: false,
      menu2: false,
      menu3: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      date1: null,
      date2: null,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      cal25: false,
      cal13: false,
      cal5: false,
      msg: '',
      documentName: '',
      documentNameLoc: '',
      mainbook: { },
      error: null,
      localError: false,
      newDocument: {},
      confpassword: '',
      size: 'sm',
      required: (value) => !!value || 'Required.',
      partners: [],
      types: [],
      docs: [],
      CompanyId: 0,
      selectPa: {},
      select: {},
      selectDoc: {},
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
      },
      currnecyType: [
        { text: 'Kn', value: 'Kn' },
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      money: {
        prefix: '= ',
        suffix: ' ',
        precision: 3
      },
      price: 7.500,
      localUser: '',
      bookType: [],
      bookTypeSelected: {
      },
      showUpload: false,
      haveItem: null,
      selectId: null,
      haveNew: false,
      show: 1,
      haveBookIn: false,
      haveBookOut: false,
      docSide: 1,
      showCard: false,
      showOpen: false,
      startDate: null,
      endDate: null,
      partnerId: null,
      MLType: null,
      Company: null,
      BusinessYear: null,
      haveInvoice: false,
      hideHeader: false,
      componentKey: 0,
      partnerVATID: null,
      selectedAmount: 0,
      restAmonut : 0,
      type: 0
    }
  },
  components: {
    MainLedgerListFast,
    MainBookCompCard2,
    Dialog
  },
  async mounted () {
    try {
      if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
     
      const displayEURselector =  this.$store.state.businessYear && this.$store.state.businessYear <= 2022 ? false : true
      this.defCurrency = displayEURselector ? this.$store.state.newCurrency : this.$store.state.oldCurrency
      this.exRate = displayEURselector ?  this.$store.state.defExRate : 1

      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
      this.Company = this.$store.state.companyActiv.CompanyName
      this.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
      if (this.$store.state.companyid) {
        
        this.CompanyId = this.$store.state.companyid
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      this.show = 1
      if (this.$store.state.tasktypeid === 1) {
        this.show = 1
        this.type = 1
      }
      if (this.$store.state.tasktypeid === 2) {
        this.show = 2
        this.type = 2
      }
      this.startDate = dayjs([this.$store.state.businessYear ? this.$store.state.businessYear : 2020]).startOf('year').format('YYYY-MM-DD')
      this.endDate = dayjs([this.$store.state.businessYear ? this.$store.state.businessYear : 2020]).endOf('year').format('YYYY-MM-DD')
      if (this.CompanyId > 0) {
        await testLastUpdate()
        this.partners = this.$store.state.partnerStore ? this.$store.state.partnerStore : []
      }

      // if (this.hHeader) {
      //   this.hideHeader = this.hHeader
      // }
      this.hideHeader = true
      
      if (this.$store.state.editItem === 0) {
        this.mainbook = defMainBook()
        this.mainbook = {...this.mainbook, ...defNewMainBook()}
        this.mainbook.BookRecExp  = this.show
        this.localUser = this.mainbook.First + ' ' + this.mainbook.Last + ', ' + this.mainbook.email
        this.haveNew = true
        if (this.head) {
          this.mainbook.BookDate = this.$store.state.mainledgerActiv.bankStatmentDate ? this.$store.state.mainledgerActiv.bankStatmentDate : null
          this.mainbook.bankAccountID = this.$store.state.mainledgerActiv.bankAccountID ? this.$store.state.mainledgerActiv.bankAccountID : null
          this.mainbook.bankStatmentDate = this.$store.state.mainledgerActiv.bankStatmentDate ? this.$store.state.mainledgerActiv.bankStatmentDate : null
          this.mainbook.bankStatmentNumber = this.$store.state.mainledgerActiv.bankStatmentNumber ? this.$store.state.mainledgerActiv.bankStatmentNumber : null
          this.mainbook.BookDesc1 = this.head.bankDescription ? this.head.bankDescription : ''
          this.mainbook.BookDesc2 = this.head.bankStatmentAccount ? this.head.bankStatmentAccount : ''
          this.mainbook.partnerName = this.head.partnerName ? this.head.partnerName : ''
          this.mainbook.partnerBankIBAN = this.head.partnerBankIBAN ? this.head.partnerBankIBAN : null
          this.mainbook.RecCash =  0
          this.mainbook.RecBank = this.head.bankStatmentCredit ? parseNum(this.head.bankStatmentCredit) : 0
          this.mainbook.RecFree =  0
          this.mainbook.RecVAT =  0
          this.mainbook.RecSpecial = 0
          this.mainbook.RecTotal = this.head.bankStatmentCredit ? parseNum(this.head.bankStatmentCredit) : 0
          this.mainbook.ExpCash =  0
          this.mainbook.ExpBank = this.head.bankStatmentDebit ? parseNum(this.head.bankStatmentDebit) : 0
          this.mainbook.ExpFree = 0
          this.mainbook.ExpVAT = 0
          this.mainbook.ExpSpecial = 0
          this.mainbook.ExpTotal = this.head.bankStatmentDebit ? parseNum(this.head.bankStatmentDebit) : 0
        }
      }
      if (this.$store.state.editItem === 1) {
        const locDocument = await MainBookCompService.show(this.$store.state.mainledgerid)
        this.mainbook = locDocument.data.mainbookcomps ? locDocument.data.mainbookcomps : {}
        this.show = this.mainbook.BookRecExp === 1 ? 1 : 2

        this.mainbook.BookDate = this.mainbook.BookDate ?  dayjs(this.mainbook.BookDate).format('YYYY-MM-DD') : null
        if (this.mainbook.partnerId && this.mainbook.partnerId !== 0 ) {
          this.selectPa = this.partners.find(partner => (partner.id === parseInt(this.mainbook.partnerId)))
        }
        this.haveNew = false        
      }
    } catch (err) {
      console.log(err)
    }
    
  },
  computed: {
    formIsValid () {
      return (this.mainbook.BookDate && this.mainbook.partnerVATID) || this.mainbook.Skip 
    },
     formatDate1() {
      return this.mainbook.BookDate
        ? dateFormat(this.mainbook.BookDate)
        : "";
    },
    ...mapGetters({
      mySelectedItems: 'NeedMainLedgerDetailItems'
    }),
  },
  methods: {
     formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    bookin() {
      this.docSide = 1
      this.$store.dispatch('setDocumentSide', 1)
      this.haveBookIn = !this.haveBookIn        
    },
    bookout() {
      this.docSide = 2
      this.$store.dispatch('setDocumentSide', 2)
      this.haveBookOut = !this.haveBookOut        
    },
    showBooks() {
      if (this.selectPa.id) {
        if (this.show === 1) {
          this.bookout()
        }
        if (this.show === 2) {
          this.bookin()
        }
      } else {
        this.text = 'Greška!! Nije odabran niti jedan partner !'
        this.color = 'red'
        this.snackbar = true
      }

    },
    card(type) {
      if (this.selectPa && this.selectPa.partnerVATID) {
        this.componentKey += 1
        const showOpen = type === 1 ? false : true
        if (this.show === 1) {
          this.bookoutcard(showOpen)
        }
        if (this.show === 2) {
          this.bookincard(showOpen)
        }
      } else {
        this.text = 'Greška!! Nije odabran niti jedan partner !'
        this.color = 'red'
        this.snackbar = true
      }
    },
    async bookincard(showOpen) {
      this.$store.dispatch('setMainLedgerDetailItems', [])
      this.partnerVATID = this.selectPa.partnerVATID
      this.docSide = 1
      await this.$store.dispatch('setDocumentSide', 1)
      this.MLType = 2
      this.showCard = !this.showCard
      this.showOpen = showOpen     
    },
    async bookoutcard(showOpen) {
      this.$store.dispatch('setMainLedgerDetailItems', [])
      this.partnerVATID = this.selectPa.partnerVATID
      this.docSide = 2
      await this.$store.dispatch('setDocumentSide', 2)
      this.MLType = 1
      this.showCard = !this.showCard
      this.showOpen = showOpen
    },
    async addBook() {
      if (this.mainbook.BookID) {
        this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        this.mainQuery.BusinessYear = this.mainbook.BookBusinessYear ? this.mainbook.BookBusinessYear: 2022
        this.mainQuery.MLType = this.show === 1 ? 2 : 1
        this.mainQuery.BookID = this.mainbook.BookID

        const arrDocs = (await MainLedgerService.books(this.mainQuery)).data.documents
        const lP = arrDocs[0] ? arrDocs[0] : null
        if (lP) {
          this.selectPa = this.partners.find(partner => (partner.id === parseInt(lP.partnerId)))
          this.mainbook.partnerId = lP.partnerId ? lP.partnerId : null
          this.mainbook.partnerName = lP.partnerName ? lP.partnerName : ''
          this.mainbook.partnerNameShort = lP.partnerNameShort ? lP.partnerNameShort : ''
          this.mainbook.partnerNameDatabase = lP.partnerNameDatabase ? lP.partnerNameDatabase : ''
          this.mainbook.partnerBuildingNameNumber = lP.partnerBuildingNameNumber ? lP.partnerBuildingNameNumber : ''
          this.mainbook.partnerStreet = lP.partnerStreet ? lP.partnerStreet : ''
          this.mainbook.partnerCity = lP.partnerCity ? lP.partnerCity : ''
          this.mainbook.partnerPostcode = lP.partnerPostcode ? lP.partnerPostcode : null
          this.mainbook.partnerCountyRegion = lP.partnerCountyRegion ? lP.partnerCountyRegion : null
          this.mainbook.partnerState = lP.partnerState ? lP.partnerState : null
          this.mainbook.partnerVATID = lP.partnerVATID ? lP.partnerVATID : null
          this.mainbook.partneremail = lP.partneremail ? lP.partneremail : null
          this.mainbook.partnerPhones = lP.partnerPhones ? lP.partnerPhones : null
          this.mainbook.partnerBankName = lP.partnerBankName ? lP.partnerBankName : null
          this.mainbook.partnerBankAddress = lP.partnerBankAddress ? lP.partnerBankAddress : null
          this.mainbook.partnerBankIBAN = lP.partnerBankIBAN ? lP.partnerBankIBAN : null
          this.mainbook.partnerBankSWIFT = lP.partnerBankSWIFT ? lP.partnerBankSWIFT : null
          if (this.show === 1) {
            if (round(this.mainbook.RecBank, 2) === 0) {
              this.mainbook.RecBank = lP.InvoiceAmount ? parseNum(lP.InvoiceAmount) : 0
            }
            this.mainbook.BookDesc1 = 'IRA ' + parseInt(lP.BookID) + ' od ' + this.formatDate(lP.BookingDate) 
            this.mainbook.InvoiceNumber = lP.BookID ? this.round(lP.BookID, 0) : 0
          }
          if (this.show === 2) {
            if (round(this.mainbook.ExpBank, 2) === 0) {
              this.mainbook.ExpBank = lP.InvoiceAmount ? parseNum(lP.InvoiceAmount) : 0
            }
            this.mainbook.BookDesc1 = 'URA ' + parseInt(lP.BookID) + ' od ' + this.formatDate(lP.BookingDate) 
            this.mainbook.InvoiceNumber = lP.InvoiceNumber ? lP.InvoiceNumber : ""
          }       
          this.mainbook.InvoiceDate = lP.InvoiceDate ? lP.InvoiceDate : 0
          this.mainbook.InvoicePaymentDeadlineDate = lP.InvoicePaymentDeadlineDate ? lP.InvoicePaymentDeadlineDate : 0
          this.mainbook.InvoiceAmount = lP.InvoiceAmount ? (lP.InvoiceAmount) : 0
          this.mainbook.InvoiceForeginAmount = lP.InvoiceForeginAmount ? (lP.InvoiceForeginAmount) : 0
          this.mainbook.VAT1Per = lP.VAT1Per ? (lP.VAT1Per) : 0
          this.mainbook.VAT1Base = lP.VAT1Base ? (lP.VAT1Base) : 0
          this.mainbook.VAT1Amount = lP.VAT1Amount ? (lP.VAT1Amount) : 0
          this.mainbook.VAT1PerCanUse = lP.VAT1PerCanUse ? (lP.VAT1PerCanUse) : 0
          this.mainbook.VAT1CanUse = lP.VAT1CanUse ? (lP.VAT1CanUse) : 0
          this.mainbook.VAT1CanTUse = lP.VAT1CanTUse ? (lP.VAT1CanTUse) : 0
          this.mainbook.VAT2Per = lP.VAT2Per ? (lP.VAT2Per) : 0
          this.mainbook.VAT2Base = lP.VAT2Base ? (lP.VAT2Base) : 0
          this.mainbook.VAT2Amount = lP.VAT2Amount ? (lP.VAT2Amount) : 0
          this.mainbook.VAT2PerCanUse = lP.VAT2PerCanUse ? (lP.VAT2PerCanUse) : 0
          this.mainbook.VAT2CanUse = lP.VAT2CanUse ? (lP.VAT2CanUse) : 0
          this.mainbook.VAT2CanTUse = lP.VAT2CanTUse ? (lP.VAT2CanTUse) : 0
          this.mainbook.VAT3Per = lP.VAT3Per ? (lP.VAT3Per) : 0
          this.mainbook.VAT3Base = lP.VAT3Base ? (lP.VAT3Base) : 0
          this.mainbook.VAT3Amount = lP.VAT3Amount ? (lP.VAT3Amount) : 0
          this.mainbook.VAT3PerCanUse = lP.partnerBankSWIFT ? (lP.partnerBankSWIFT) : 0
          this.mainbook.VAT3CanUse = lP.VAT3CanUse ? (lP.VAT3CanUse) : 0
          this.mainbook.VAT3CanTUse = lP.VAT3CanTUse ? (lP.VAT3CanTUse) : 0
          this.mainbook.VAT4Per = lP.VAT4Per ? (lP.VAT4Per) : 0
          this.mainbook.VAT4Base = lP.VAT4Base ? (lP.VAT4Base) : 0
          this.mainbook.VAT4Amount = lP.VAT4Amount ? (lP.VAT4Amount) : 0
          this.mainbook.VAT4PerCanUse = lP.VAT4PerCanUse ? (lP.VAT4PerCanUse) : 0
          this.mainbook.VAT4CanUse = lP.VAT4CanUse ? (lP.VAT4CanUse) : 0
          this.mainbook.VAT4CanTUse = lP.VAT4CanTUse ? (lP.VAT4CanTUse) : 0
          this.mainbook.VAT5Per = lP.VAT5Per ? (lP.VAT5Per) : 0
          this.mainbook.VAT5Base = lP.VAT5Base ? (lP.VAT5Base) : 0
          this.mainbook.VAT5Amount = lP.VAT5Amount ? (lP.VAT5Amount) : 0
          this.mainbook.VAT5PerCanUse = lP.VAT5PerCanUse ? (lP.VAT5PerCanUse) : 0
          this.mainbook.VAT5CanUse = lP.VAT5CanUse ? (lP.VAT5CanUse) : 0
          this.mainbook.VAT5CanTUse = lP.VAT5CanTUse ? (lP.VAT5CanTUse) : 0
          this.mainbook.AmountNetto = lP.AmountNetto ? (lP.AmountNetto) : 0
          this.mainbook.AmountVAT = lP.AmountVAT ? (lP.AmountVAT) : 0
          this.mainbook.AmountBrutto = lP.AmountBrutto ? (lP.AmountBrutto) : 0
          this.doRound()
          this.haveInvoice = true
        } else {
          this.text = 'Greška!! Dokument s tim broj NE postoji !'
          this.color = 'red'
          this.snackbar = true
        }
      }
    },
    async deleteBook() {
      try {
        const warningMessage = (this.show1 ? this.lang.Exp2 : this.lang.Rec2)
        if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!')) {
          if (this.mainbook.id) {
            await MainBookCompService.delete(this.mainbook.id)
            this.$router.push({
                name: 'mainbookcomp'
              })
          }
        }
        
      } catch(err) {
          this.text = 'Greška!! Dokument s tim broj NE postoji !' + err
          this.color = 'red'
          this.snackbar = true
      }
    },
    async register () {
      try {
         this.localError = false
         if (this.selectPa.partnerVATID) {
          this.mainbook.partnerId = this.selectPa.partnerVATID
          const lP = (await PartnerService.show(this.selectPa.partnerVATID)).data.partner
          this.mainbook.partnerId = this.selectPa.id
          this.mainbook.partnerName = lP.partnerName ? lP.partnerName : ''
          this.mainbook.partnerNameShort = lP.partnerNameShort ? lP.partnerNameShort : ''
          this.mainbook.partnerNameDatabase = lP.partnerNameDatabase ? lP.partnerNameDatabase : ''
          this.mainbook.partnerBuildingNameNumber = lP.partnerBuildingNameNumber ? lP.partnerBuildingNameNumber : ''
          this.mainbook.partnerStreet = lP.partnerStreet ? lP.partnerStreet : ''
          this.mainbook.partnerCity = lP.partnerCity ? lP.partnerCity : ''
          this.mainbook.partnerPostcode = lP.partnerPostcode ? lP.partnerPostcode : null
          this.mainbook.partnerCountyRegion = lP.partnerCountyRegion ? lP.partnerCountyRegion : null
          this.mainbook.partnerState = lP.partnerState ? lP.partnerState : null
          this.mainbook.partnerVATID = lP.partnerVATID ? lP.partnerVATID : null
          this.mainbook.partneremail = lP.partneremail ? lP.partneremail : null
          this.mainbook.partnerPhones = lP.partnerPhones ? lP.partnerPhones : null
          this.mainbook.partnerBankName = lP.partnerBankName ? lP.partnerBankName : null
          this.mainbook.partnerBankAddress = lP.partnerBankAddress ? lP.partnerBankAddress : null
          this.mainbook.partnerBankIBAN = lP.partnerBankIBAN ? lP.partnerBankIBAN : null
          this.mainbook.partnerBankSWIFT = lP.partnerBankSWIFT ? lP.partnerBankSWIFT : null
          
        }
        this.doRound()
        this.mainbook.BookDate = dayjs(this.mainbook.BookDate).format('YYYY-MM-DD')
        if (this.$store.state.editItem === 0) {
          const msg = this.$store.state.language === 'EN' ? 'New mainbook doc has been saved.' : 'Nova ura/ira je snimljena u bazu.'
          let haveArray = false
          if (this.$store.state.mainledgerDetailItems && this.$store.state.mainledgerDetailItems.length) {
            let selectedAmount = 0
            let restAmount = this.type === 2 ? this.mainbook.RecTotal : this.mainbook.ExpTotal
            console.log('Imam niz - trebam restAmount', restAmount, 'type', this.type)

            this.$store.state.mainledgerDetailItems.map(tItem => {
              let amount = 0
              if (this.type === 1) {
                amount = tItem.ExpTotal ? parseNum(tItem.ExpTotal) : 0
              }
              if (this.type === 2) {
                amount = tItem.RecTotal ? parseNum(tItem.RecTotal) : 0
              }
              selectedAmount += round(amount,2)
            })
            console.log('selectedAmount', selectedAmount)

            haveArray = true
            await MainBookCompService.saveitems(this.$store.state.mainledgerDetailItems)
            .then(res => {
              if (res.status === 200) {
                this.text = msg
                this.color = 'green'
                this.snackbar = true
              }
              this.timeout = 1000
              let itIsEq = false
              if(restAmount === selectedAmount) {
                itIsEq = true
              }
              this.$store.dispatch('setMainLedgerDetailItems', [])
              const newTikTok = itIsEq ? 100001 :  this.$store.state.tikTok + 1
              this.$store.dispatch('setTikTok', newTikTok)
            })
            .catch(err => {
              this.$store.dispatch('setMainLedgerDetailItems', [])
              this.timeout = 2000
              this.error = err.data
              this.text = 'Error1! Cannot save document to database. ' + err
              this.color = 'red'
              this.snackbar = true
            })
          }
          if (!haveArray) {
            let skipIt = false
            if (this.mainbook.Skip) {
              skipIt = true
              const newTikTok = skipIt ? 100001 : this.$store.state.tikTok + 1
              this.$store.dispatch('setTikTok', newTikTok)
            }
            if (!skipIt && this.mainbook.BookDate && this.mainbook.partnerVATID) {

              if (this.mainbook.BookID) {
                this.mainbook.FreeB1 = true
              }
              // if (this.show === 1) {
              //   if (round(parseNum(this.head.bankStatmentCredit),2) === this.mainbook.RecTotal) {
              //     // it is not skipit just mark closed
              //     skipIt = true
              //   }
              // }
              // if (this.show === 2) {
              //   if (round(parseNum(this.head.bankStatmentDebit),2) === this.mainbook.ExpTotal) {
              //     // it is not skipit just mark closed
              //     skipIt = true
              //   }
              // }
              await MainBookCompService.post(this.mainbook)
              .then(res => {
                this.mainbook = res.data.mainbook ? res.data.mainbook : {}
                this.text = msg
                this.color = 'green'
                this.snackbar = true
                this.timeout = 1000
                if (this.$store.state.tikTok !== 99999) {
                  if (this.$store.state.retPath) {
                      this.$router.push({
                        name: this.$store.state.retPath
                      })
                    }
                    this.$router.push({
                      name: 'mainbookcomp'
                    })
                }
                const newTikTok = skipIt ? 100001 : this.$store.state.tikTok + 1
                this.$store.dispatch('setTikTok', newTikTok)
              
              })
              .catch(err => {
                this.timeout = 2000
                this.error = err.data
                this.text = 'Error1! Cannot save document to database. ' + err
                this.color = 'red'
                this.snackbar = true
              })
            }
          }
        }        
        if (this.$store.state.editItem === 1) {
          await MainBookCompService.put(this.mainbook)
          this.timeout = 1000
          if (this.$store.state.retPath) {
            this.$router.push({
              name: this.$store.state.retPath
            })
          }
          this.$router.push({
            name: 'mainbookcomp'
          })
        }

      } catch (error) {
        this.error = error.data
        this.text = 'Error2! Cannot save document to database. ' + error.data
        this.color = 'red'
        this.snackbar = true
      }
    },
    async checkByIban() {
        this.showDialog = false
        if (this.mainbook.partnerBankIBAN){
          const cIban = {
            partnerBankIBAN: this.mainbook.partnerBankIBAN
          }
          this.error = null
          this.showPr = {}
          await PartnerService.checkIBAN(cIban)
          .then(res => {
            if (res.data.partner) {
              const lP = res.data.partner
              this.selectPa = this.partners.find(partner => (partner.id === parseInt(lP.id)))
              this.mainbook.partnerId = this.selectPa.id
              this.mainbook.partnerName = lP.partnerName ? lP.partnerName : ''
              this.mainbook.partnerNameShort = lP.partnerNameShort ? lP.partnerNameShort : ''
              this.mainbook.partnerNameDatabase = lP.partnerNameDatabase ? lP.partnerNameDatabase : ''
              this.mainbook.partnerBuildingNameNumber = lP.partnerBuildingNameNumber ? lP.partnerBuildingNameNumber : ''
              this.mainbook.partnerStreet = lP.partnerStreet ? lP.partnerStreet : ''
              this.mainbook.partnerCity = lP.partnerCity ? lP.partnerCity : ''
              this.mainbook.partnerPostcode = lP.partnerPostcode ? lP.partnerPostcode : null
              this.mainbook.partnerCountyRegion = lP.partnerCountyRegion ? lP.partnerCountyRegion : null
              this.mainbook.partnerState = lP.partnerState ? lP.partnerState : null
              this.mainbook.partnerVATID = lP.partnerVATID ? lP.partnerVATID : null
              this.mainbook.partneremail = lP.partneremail ? lP.partneremail : null
              this.mainbook.partnerPhones = lP.partnerPhones ? lP.partnerPhones : null
              this.mainbook.partnerBankName = lP.partnerBankName ? lP.partnerBankName : null
              this.mainbook.partnerBankAddress = lP.partnerBankAddress ? lP.partnerBankAddress : null
              if (!this.mainbook.partnerBankIBAN) {
                this.mainbook.partnerBankIBAN = lP.partnerBankIBAN ? lP.partnerBankIBAN : null
              }
              this.mainbook.partnerBankSWIFT = lP.partnerBankSWIFT ? lP.partnerBankSWIFT : null
              this.header = 'OK'
              this.message = this.lang.partnerExist
              this.dcolor = 'green'
              this.showDialog = true
              //this.partnerError(this.lang.partnerExist, 'green')
            } else {
              this.header = 'Error!!'
              this.message = this.lang.partnerNOTexist
              this.dcolor = 'red'
              this.showDialog = true
            }
          })
          .catch(err => {
            this.header = 'Error!!'
            this.message = this.lang.partnerNOTexist + ', ' + err
            this.dcolor = 'red'
            this.showDialog = true
          })
        }
    },
    close () {
      this.show = false
    },
    calculateSecAmount () {
      this.mainbook.InvoiceForeginAmount = this.mainbook.ExRate !== 0 ? (this.mainbook.InvoiceAmount) / (this.mainbook.ExRate) : 0
      // this.mainbook.VAT1Base = this.round(((parseFloat(this.mainbook.InvoiceAmount) / (1 + parseFloat(this.mainbook.VAT1Per) / 100))),2)
      // this.mainbook.VAT1Amount = this.round((parseFloat(this.mainbook.InvoiceAmount) - this.mainbook.VAT1Base),2)
      // this.mainbook.VAT1CanUse = this.round((parseFloat(this.mainbook.InvoiceAmount) - this.mainbook.VAT1Base),2)
    },
    calculateFirstAmount () {
      if ((this.mainbook.InvoiceAmount) === 0) {
        this.mainbook.InvoiceAmount = this.mainbook.ExRate !== 0 ? (this.mainbook.InvoiceForeginAmount * this.mainbook.ExRate) : 0
        // this.mainbook.VAT1Base = this.round(((parseFloat(this.mainbook.InvoiceAmount) / (1 + parseFloat(this.mainbook.VAT1Per) / 100))),2)
        // this.mainbook.VAT1Amount = this.round((parseFloat(this.mainbook.InvoiceAmount) - this.mainbook.VAT1Base),2)
        // this.mainbook.VAT1CanUse = this.round((parseFloat(this.mainbook.InvoiceAmount) - this.mainbook.VAT1Base),2)
      }
    },
    calculateFirstVAT () {
      this.mainbook.VAT1CanUse = (((this.mainbook.VAT1Amount) * ((this.mainbook.VAT1PerCanUse) / 100)))
      this.mainbook.VAT1CanTUse = (this.mainbook.VAT1Amount) - this.mainbook.VAT1CanUse
      this.mainbook.VAT1Base = this.mainbook.VAT1Per !== 0 ? (this.mainbook.VAT1CanUse + this.mainbook.VAT1CanTUse) / ( this.mainbook.VAT1Per / 100) : 0
      
      this.calculateVATAmount()
    },
    calculateSecondVAT () {
      this.mainbook.VAT2CanUse = ((this.mainbook.VAT2Amount) * ((this.mainbook.VAT2PerCanUse) / 100))
      this.mainbook.VAT2CanTUse = (this.mainbook.VAT2Amount) - this.mainbook.VAT2CanUse
      this.mainbook.VAT2Base = this.mainbook.VAT2Per !== 0 ? (this.mainbook.VAT2CanUse + this.mainbook.VAT2CanTUse) / ( this.mainbook.VAT2Per / 100) : 0
      this.calculateVATAmount()
    },
    calculateThirdVAT () {
      this.mainbook.VAT3CanUse = ((this.mainbook.VAT3Amount) * ((this.mainbook.VAT3PerCanUse) / 100))
      this.mainbook.VAT3CanTUse = (this.mainbook.VAT3Amount) - this.mainbook.VAT3CanUse
      this.mainbook.VAT3Base = this.mainbook.VAT3Per !==0 ? (this.mainbook.VAT3CanUse + this.mainbook.VAT3CanTUse) / ( this.mainbook.VAT3Per / 100) : 0
      this.calculateVATAmount()
    },
    calculateVATAmount () {
      this.mainbook.AmountVAT = ((this.mainbook.VAT1Amount) + (this.mainbook.VAT2Amount) + (this.mainbook.VAT3Amount))
      this.mainbook.AmountNetto = ((this.mainbook.InvoiceAmount) - this.mainbook.AmountVAT - (this.mainbook.VAT4Base))
    },
    calculateRecExp() {

      this.mainbook.RecTotal = (this.mainbook.RecCash ? this.mainbook.RecCash : 0) + (this.mainbook.RecBank ? this.mainbook.RecBank : 0) + (this.mainbook.RecFree ? this.mainbook.RecFree : 0) 
      this.mainbook.ExpTotal = (this.mainbook.ExpCash ? this.mainbook.ExpCash : 0) + (this.mainbook.ExpBank ? this.mainbook.ExpBank : 0) + (this.mainbook.ExpFree ? this.mainbook.ExpFree : 0) 
    },
    async findPartner () {
      if (this.selectPa.partnerVATID) {
        this.mainbook.partnerId = this.selectPa.partnerVATID
        const locPartner = (await PartnerService.show(this.selectPa.partnerVATID)).data.partner
        this.mainbook.partnerId = locPartner.id ? locPartner.id : null
        this.mainbook.partnerName = locPartner.partnerName
        this.mainbook.partnerNameShort = locPartner.partnerNameShort
        this.mainbook.partnerNameDatabase = locPartner.partnerNameDatabase
        this.mainbook.partnerBuildingNameNumber = locPartner.partnerBuildingNameNumber
        this.mainbook.partnerStreet = locPartner.partnerStreet
        this.mainbook.partnerCity = locPartner.partnerCity
        this.mainbook.partnerPostcode = locPartner.partnerPostcode
        this.mainbook.partnerCountyRegion = locPartner.partnerCountyRegion
        this.mainbook.partnerState = locPartner.partnerState
        this.mainbook.partnerVATID = locPartner.partnerVATID
        this.mainbook.partneremail = locPartner.partneremail
        this.mainbook.partnerPhones = locPartner.partnerPhones
        this.mainbook.partnerBankName = locPartner.partnerBankName
        this.mainbook.partnerBankAddress = locPartner.partnerBankAddress
        if (!this.mainbook.partnerBankIBAN) {
          this.mainbook.partnerBankIBAN = locPartner.partnerBankIBAN
        }
        this.mainbook.partnerBankSWIFT = locPartner.partnerBankSWIFT
      }
    },
    doParse() {
      this.mainbook.BookID = this.mainbook.BookID ? parseInt(this.mainbook.BookID) : 0
      this.mainbook.InvoiceAmount = this.mainbook.InvoiceAmount ? parseNum(this.mainbook.InvoiceAmount) : 0
      this.mainbook.InvoiceForeginAmount = this.mainbook.InvoiceForeginAmount ? parseNum(this.mainbook.InvoiceForeginAmount) : 0
      this.mainbook.VAT1Per = this.mainbook.VAT1Per ? parseNum(this.mainbook.VAT1Per) : 25
      this.mainbook.VAT1Base = this.mainbook.VAT1Base  ? parseNum(this.mainbook.VAT1Base) : 0
      this.mainbook.VAT1Amount = this.mainbook.VAT1Amount  ? parseNum(this.mainbook.VAT1Amount) : 0
      this.mainbook.VAT1PerCanUse = this.mainbook.VAT1PerCanUse  ? parseNum(this.mainbook.VAT1PerCanUse) : 100
      this.mainbook.VAT1CanUse = this.mainbook.VAT1CanUse  ? parseNum(this.mainbook.VAT1CanUse) : 0
      this.mainbook.VAT1CanTUse = this.mainbook.VAT1CanTUse  ? parseNum(this.mainbook.VAT1CanTUse) : 0
      this.mainbook.VAT2Per = this.mainbook.VAT2Per  ? parseNum(this.mainbook.VAT2Per) : 13
      this.mainbook.VAT2Base = this.mainbook.VAT2Base  ? parseNum(this.mainbook.VAT2Base) : 0
      this.mainbook.VAT2Amount = this.mainbook.VAT2Amount  ? parseNum(this.mainbook.VAT2Amount) : 0
      this.mainbook.VAT2PerCanUse = this.mainbook.VAT2PerCanUse  ? parseNum(this.mainbook.VAT2PerCanUse) : 100
      this.mainbook.VAT2CanUse = this.mainbook.VAT2CanUse  ? parseNum(this.mainbook.VAT2CanUse) : 0
      this.mainbook.VAT2CanTUse = this.mainbook.VAT2CanTUse ? parseNum(this.mainbook.VAT2CanTUse) : 0
      this.mainbook.VAT3Per = this.mainbook.VAT3Per  ? parseNum(this.mainbook.VAT3Per) : 5
      this.mainbook.VAT3Base = this.mainbook.VAT3Base  ? parseNum(this.mainbook.VAT3Base) : 0
      this.mainbook.VAT3Amount = this.mainbook.VAT3Amount  ? parseNum(this.mainbook.VAT3Amount) : 0
      this.mainbook.VAT3PerCanUse = this.mainbook.VAT3PerCanUse ? parseNum(this.mainbook.VAT3PerCanUse) : 100
      this.mainbook.VAT3CanUse = this.mainbook.VAT3CanUse ? parseNum(this.mainbook.VAT3CanUse) : 0
      this.mainbook.VAT3CanTUse = this.mainbook.VAT3CanTUse ? parseNum(this.mainbook.VAT3CanTUse) : 0
      this.mainbook.VAT4Per = this.mainbook.VAT4Per ? parseNum(this.mainbook.VAT4Per) : 0
      this.mainbook.VAT4Base = this.mainbook.VAT4Base ? parseNum(this.mainbook.VAT4Base) : 0
      this.mainbook.VAT4Amount = this.mainbook.VAT4Amount ? parseNum(this.mainbook.VAT4Amount) : 0
      this.mainbook.VAT4PerCanUse = this.mainbook.VAT4PerCanUse ? parseNum(this.mainbook.VAT4PerCanUse) : 100
      this.mainbook.VAT4CanUse = this.mainbook.VAT4CanUse ? parseNum(this.mainbook.VAT4CanUse) : 0
      this.mainbook.VAT4CanTUse = this.mainbook.VAT4CanTUse ? parseNum(this.mainbook.VAT4CanTUse) : 0
      this.mainbook.VAT5Per = this.mainbook.VAT5Per ? parseNum(this.mainbook.VAT5Per) : 0
      this.mainbook.VAT5Base = this.mainbook.VAT5Base ? parseNum(this.mainbook.VAT5Base) : 0
      this.mainbook.VAT5Amount = this.mainbook.VAT5Amount ? parseNum(this.mainbook.VAT5Amount) : 0
      this.mainbook.VAT5PerCanUse = this.mainbook.VAT5PerCanUse ? parseNum(this.mainbook.VAT5PerCanUse) : 100
      this.mainbook.VAT5CanUse = this.mainbook.VAT5CanUse ? parseNum(this.mainbook.VAT5CanUse) : 0
      this.mainbook.VAT5CanTUse = this.mainbook.VAT5CanTUse ? parseNum(this.mainbook.VAT5CanTUse) : 0
      this.mainbook.RecCash = this.mainbook.RecCash ? parseNum(this.mainbook.RecCash) : 0
      this.mainbook.RecBank = this.mainbook.RecBank ? parseNum(this.mainbook.RecBank) : 0
      this.mainbook.RecFree = this.mainbook.RecFree ? parseNum(this.mainbook.RecFree) : 0
      this.mainbook.RecVAT = this.mainbook.RecVAT ? parseNum(this.mainbook.RecVAT) : 0
      this.mainbook.RecSpecial = this.mainbook.RecSpecial ? parseNum(this.mainbook.RecSpecial) : 0
      this.mainbook.RecTotal = this.mainbook.RecTotal ? parseNum(this.mainbook.RecTotal) : 0
      this.mainbook.ExpCash = this.mainbook.ExpCash ? parseNum(this.mainbook.ExpCash) : 0
      this.mainbook.ExpBank = this.mainbook.ExpBank ? parseNum(this.mainbook.ExpBank) : 0
      this.mainbook.ExpFree = this.mainbook.ExpFree ? parseNum(this.mainbook.ExpFree) : 0
      this.mainbook.ExpVAT = this.mainbook.ExpVAT ? parseNum(this.mainbook.ExpVAT): 0
      this.mainbook.ExpSpecial = this.mainbook.ExpSpecial ? parseNum(this.mainbook.ExpSpecial) : 0
      this.mainbook.ExpTotal = this.mainbook.ExpTotal ? parseNum(this.mainbook.ExpTotal) : 0
    },
    doRound() {
      this.mainbook.InvoiceAmount = this.mainbook.InvoiceAmount ? this.round(this.mainbook.InvoiceAmount, 2) : 0
      this.mainbook.InvoiceForeginAmount = this.mainbook.InvoiceForeginAmount ? this.round(this.mainbook.InvoiceForeginAmount, 2) : 0
      //this.mainbook.VAT1Per = this.mainbook.VAT1Per ? this.round(this.mainbook.VAT1Per) : 25
      this.mainbook.VAT1Base = this.mainbook.VAT1Base ? this.round(this.mainbook.VAT1Base, 2) : 0
      this.mainbook.VAT1Amount = this.mainbook.VAT1Amount ? this.round(this.mainbook.VAT1Amount, 2) : 0
      this.mainbook.VAT1PerCanUse = this.mainbook.VAT1PerCanUse ? this.round(this.mainbook.VAT1PerCanUse, 2) : 100
      this.mainbook.VAT1CanUse = this.mainbook.VAT1CanUse ? this.round(this.mainbook.VAT1CanUse, 2) : 0
      this.mainbook.VAT1CanTUse = this.mainbook.VAT1CanTUse ? this.round(this.mainbook.VAT1CanTUse, 2) : 0
      //this.mainbook.VAT2Per = this.mainbook.VAT2Per ? this.round(this.mainbook.VAT2Per) : 13
      this.mainbook.VAT2Base = this.mainbook.VAT2Base ? this.round(this.mainbook.VAT2Base, 2) : 0
      this.mainbook.VAT2Amount = this.mainbook.VAT2Amount ? this.round(this.mainbook.VAT2Amount, 2) : 0
      this.mainbook.VAT2PerCanUse = this.mainbook.VAT2PerCanUse ? this.round(this.mainbook.VAT2PerCanUse, 2) : 100
      this.mainbook.VAT2CanUse = this.mainbook.VAT2CanUse ? this.round(this.mainbook.VAT2CanUse, 2) : 0
      this.mainbook.VAT2CanTUse = this.mainbook.VAT2CanTUse ? this.round(this.mainbook.VAT2CanTUse, 2) : 0
      //this.mainbook.VAT3Per = this.mainbook.VAT3Per ? this.round(this.mainbook.VAT3Per) : 5
      this.mainbook.VAT3Base = this.mainbook.VAT3Base ? this.round(this.mainbook.VAT3Base, 2) : 0
      this.mainbook.VAT3Amount = this.mainbook.VAT3Amount ? this.round(this.mainbook.VAT3Amount, 2) : 0
      this.mainbook.VAT3PerCanUse = this.mainbook.VAT3PerCanUse ? this.round(this.mainbook.VAT3PerCanUse, 2) : 100
      this.mainbook.VAT3CanUse = this.mainbook.VAT3CanUse ? this.round(this.mainbook.VAT3CanUse, 2) : 0
      this.mainbook.VAT3CanTUse = this.mainbook.VAT3CanTUse ? this.round(this.mainbook.VAT3CanTUse, 2) : 0
      this.mainbook.VAT4Per = this.mainbook.VAT4Per ? this.round(this.mainbook.VAT4Per, 2) : 0
      this.mainbook.VAT4Base = this.mainbook.VAT4Base ? this.round(this.mainbook.VAT4Base, 2) : 0
      this.mainbook.VAT4Amount = this.mainbook.VAT4Amount ? this.round(this.mainbook.VAT4Amount, 2) : 0
      this.mainbook.VAT4PerCanUse = this.mainbook.VAT4PerCanUse ? this.round(this.mainbook.VAT4PerCanUse, 2) : 100
      this.mainbook.VAT4CanUse = this.mainbook.VAT4CanUse ? this.round(this.mainbook.VAT4CanUse, 2) : 0
      this.mainbook.VAT4CanTUse = this.mainbook.VAT4CanTUse ? this.round(this.mainbook.VAT4CanTUse, 2) : 0
      this.mainbook.VAT5Per = this.mainbook.VAT5Per ? this.round(this.mainbook.VAT5Per, 2) : 0
      this.mainbook.VAT5Base = this.mainbook.VAT5Base ? this.round(this.mainbook.VAT5Base, 2) : 0
      this.mainbook.VAT5Amount = this.mainbook.VAT5Amount ? this.round(this.mainbook.VAT5Amount, 2) : 0
      this.mainbook.VAT5PerCanUse = this.mainbook.VAT5PerCanUse ? this.round(this.mainbook.VAT5PerCanUse, 2) : 100
      this.mainbook.VAT5CanUse = this.mainbook.VAT5CanUse ? this.round(this.mainbook.VAT5CanUse, 2) : 0
      this.mainbook.VAT5CanTUse = this.mainbook.VAT5CanTUse ? this.round(this.mainbook.VAT5CanTUse, 2) : 0
      this.mainbook.RecCash = this.mainbook.RecCash ? this.round(this.mainbook.RecCash, 2) : 0
      this.mainbook.RecBank = this.mainbook.RecBank ? this.round(this.mainbook.RecBank, 2) : 0
      this.mainbook.RecFree = this.mainbook.RecFree ? this.round(this.mainbook.RecFree, 2) : 0
      this.mainbook.RecVAT = this.mainbook.RecVAT ? this.round(this.mainbook.RecVAT, 2) : 0
      this.mainbook.RecSpecial= this.mainbook.RecSpecial ? this.round(this.mainbook.RecSpecial, 2) : 0
      this.mainbook.RecTotal = this.mainbook.RecTotal ? this.round(this.mainbook.RecTotal, 2) : 0
      this.mainbook.ExpCash = this.mainbook.ExpCash ? this.round(this.mainbook.ExpCash, 2) : 0
      this.mainbook.ExpBank = this.mainbook.ExpBank ? this.round(this.mainbook.ExpBank, 2) : 0
      this.mainbook.ExpFree = this.mainbook.ExpFree ? this.round(this.mainbook.ExpFree, 2) : 0
      this.mainbook.ExpVAT = this.mainbook.ExpVAT ? this.round(this.mainbook.ExpVAT,2 ): 0
      this.mainbook.ExpSpecial = this.mainbook.ExpSpecial ? this.round(this.mainbook.ExpSpecial, 2) : 0
      this.mainbook.ExpTotal = this.mainbook.ExpTotal ? this.round(this.mainbook.ExpTotal, 2) : 0
    },
    async mainledgerlist () {
      try {
        if (this.$store.state.tikTok !== 99999) {
          await this.$router.push({
            name: 'mainbookcomp'
          })
        }
        const newTikTok = this.$store.state.tikTok + 1
        await this.$store.dispatch('setTikTok', newTikTok)
        
      } catch (err) {
        this.error = err.data
        // eslint-disable-next-line
        console.log(err)
      }
    },
    mainledgerupload() {
      this.showUpload = !this.showUpload
    },
    async mainledgeragain () {
      try {
        this.$store.dispatch('setpathAgain', 'mainledgercreate')
        await this.$router.push({
          name: 'mainbook'
        })
        // Object.assign(this.$data, this.$options.data.call(this))
        // location.reload()
        // this.$forceUpdate()
      } catch (err) {
        this.error = err.data
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async refreshPartners() {
      await newUpdates(2)
      await testLastUpdate()
      if (this.$store.state.partnerStore) {
        this.partners = this.$store.state.partnerStore
      }
    },
    round(value, exp) {
      if (typeof exp === 'undefined' || +exp === 0)
        return Math.round(value)

        value = +value
        exp = +exp

      if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split('e')
      value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)))

      // Shift back
      value = value.toString().split('e')
      return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp))
    },
    async addeditPartner () {
      if (!this.mainbook.partnerVATID){
          this.text = this.lang.PartnerErrorVAT2
          this.color = 'red'
          this.snackbar = true
          this.timeout = 3000
          return
        }
         if (!this.mainbook.exportDoc) {
          const partVatLeght = this.mainbook.partnerVATID.trim().length
          if (partVatLeght !== 11) {
            this.text = this.lang.PartnerErrorVAT3
            this.color = 'red'
            this.snackbar = true
            this.timeout = 3000
            this.reqVATID = true
            return
          }
        }
        const updatePartner = {...this.mainbook}
        this.saving = true
        if (this.mainbook.partnerName && this.mainbook.partnerVATID) {
          this.localError = null
          // can save as private person ONLY in mainbook ServiceNotes and QuoteSales
          if (!this.localError) {
            this.saving = true
            const query= {}
            query.partnerVATID = this.mainbook.partnerVATID ? this.mainbook.partnerVATID : null
            const newPartner = await addUpdatePartner(updatePartner)
            this.saving = false
            this.text = this.lang.addedPartner
            this.color = 'green'
            this.snackbar = true
            this.timeout = 2000
            await this.refreshPartners()
            this.mainbook.partnerId = newPartner.partner.id ? newPartner.partner.id : null
            this.selectId = newPartner.partnerId ? parseInt(newPartner.partnerId) : null
            this.selectPa = this.partners.find(partner => (partner.partnerVATID === newPartner.partner.partnerVATID))
            await this.findPartner(newPartner.partner.partnerVATID)
          } else {
            this.text = this.localError
            this.color = 'red'
            this.snackbar = true
          }
          
        } else {
          // not enough data to save partner 
          this.text = this.lang.PartnerErrorName
          this.color = 'red'
          this.snackbar = true
          this.timeout = 4000
        } 
        
        this.saving = false
    },
    calc25 () {
      if (this.mainbook.InvoiceAmount) {
        this.mainbook.VAT1Amount = round((this.mainbook.InvoiceAmount - (this.mainbook.InvoiceAmount / 1.25)), 2)
        this.mainbook.VAT1PerCanUse = 100
        this.mainbook.VAT1CanUse = (((this.mainbook.VAT1Amount) * ((this.mainbook.VAT1PerCanUse) / 100)))
        this.mainbook.VAT1CanTUse = (this.mainbook.VAT1Amount) - this.mainbook.VAT1CanUse
        this.mainbook.VAT1Base = this.mainbook.VAT1Per !== 0 ? (this.mainbook.VAT1CanUse + this.mainbook.VAT1CanTUse) / ( this.mainbook.VAT1Per / 100) : 0
      }
    },
    calc13 () {
      if (this.mainbook.InvoiceAmount) {
        this.mainbook.VAT2Amount = round((this.mainbook.InvoiceAmount - (this.mainbook.InvoiceAmount / 1.13)), 2)
        this.mainbook.VAT2PerCanUse = 100
        this.mainbook.VAT2CanUse = (((this.mainbook.VAT2Amount) * ((this.mainbook.VAT2PerCanUse) / 100)))
        this.mainbook.VAT2CanTUse = (this.mainbook.VAT2Amount) - this.mainbook.VAT2CanUse
        this.mainbook.VAT2Base = this.mainbook.VAT2Per !== 0 ? (this.mainbook.VAT2CanUse + this.mainbook.VAT2CanTUse) / ( this.mainbook.VAT2Per / 100) : 0
      }
    },
    calc5 () {
      if (this.mainbook.InvoiceAmount) {
        this.mainbook.VAT3Amount = round((this.mainbook.InvoiceAmount - (this.mainbook.InvoiceAmount / 1.05)), 2)
        this.mainbook.VAT3PerCanUse = 100
        this.mainbook.VAT3CanUse = (((this.mainbook.VAT3Amount) * ((this.mainbook.VAT3PerCanUse) / 100)))
        this.mainbook.VAT3CanTUse = (this.mainbook.VAT3Amount) - this.mainbook.VAT3CanUse
        this.mainbook.VAT3Base = this.mainbook.VAT3Per !== 0 ? (this.mainbook.VAT3CanUse + this.mainbook.VAT3CanTUse) / ( this.mainbook.VAT3Per / 100) : 0
      }
    },
    calcVAT () {
      const claimVAT = (this.mainbook.VAT1CanUse !== 0 ? this.mainbook.VAT1CanUse : 0) + (this.mainbook.VAT2CanUse !== 0 ? this.mainbook.VAT2CanUse : 0) + (this.mainbook.VAT3CanUse !== 0 ? this.mainbook.VAT3CanUse : 0)
      if (this.show === 1) {
        this.mainbook.RecVAT = claimVAT
      }
      if (this.show === 2) {
        this.mainbook.ExpVAT = claimVAT
      }
    },
    doCalc() {
      if (this.$store.getters.NeedMainLedgerDetailItems) {

        const items = this.$store.getters.NeedMainLedgerDetailItems

        if (items) {
          this.selectedAmount = 0
          this.restAmonut = this.show === 1 ? this.mainbook.RecTotal : this.mainbook.ExpTotal
          items.map(item => {
            if (this.show === 1) {
              this.selectedAmount += item.RecTotal ? item.RecTotal : 0
              this.restAmonut = this.mainbook.RecTotal - this.selectedAmount
            }
            if (this.show === 2) {
              this.selectedAmount += item.ExpTotal ? item.ExpTotal : 0
              this.restAmonut = this.mainbook.ExpTotal - this.selectedAmount
            }
          })
        }
      }
    }
  },
  watch: {
    'mySelectedItems' () {
      this.doCalc()
    },
    'mainbook.VAT1Amount' () {
      this.calculateFirstVAT()
    },
    'mainbook.VAT1PerCanUse' () {
      this.calculateFirstVAT()
    },
    'mainbook.VAT2Amount' () {
      this.calculateSecondVAT()
    },
    'mainbook.VAT2PerCanUse' () {
      this.calculateSecondVAT()
    },
    'mainbook.VAT3Amount' () {
      this.calculateThirdVAT()
    },
    'mainbook.VAT3PerCanUse' () {
      this.calculateThirdVAT()
    },
    'mainbook.InvoiceAmount' () {
      this.calculateSecAmount()
    },
    'mainbook.InvoiceForeginAmount' () {
      this.calculateFirstAmount()
    },
    'mainbook.VAT4Base' () {
      this.calculateVATAmount()
    },
    'selectPa' () {
      if (!this.selectPa) {
        this.mainbook.partnerName = null
        this.mainbook.partnerBankIBAN =  null
        this.mainbook.partnerVATID = null
        this.mainbook.partnerStreet = null
        this.mainbook.partnerCity = null
        this.mainbook.partnerPostcode = null
        this.mainbook.partnerBuildingNameNumber = null
        this.mainbook.partneremail = null
      }
      if (this.selectPa) {
        let locOK = true
        if (this.selectPa.partnerVATID && this.selectPa.partnerVATID === '00000000000') {
          locOK = false
          const newQuery = {}
          this.selectPa = {...newQuery}
          this.text = 'GREŠKA!! Uplata ili isplata sa žiro računa mora imati ispravan OIB!! ERROR!! VAT ID is not valid!'
          this.color = 'red'
          this.snackbar = true
          this.timeout = 4000
        }
        if (locOK) {
          this.findPartner()
        }
      }
    },
    'mainbook.RecCash' () {
      this.doParse()
      this.calculateRecExp()
    },
    'mainbook.RecBank' () {
      this.doParse()
      this.calculateRecExp()
    },
    'mainbook.RecFree' () {
      this.doParse()
      this.calculateRecExp()
    },
    'mainbook.RecVAT' () {
      this.doParse()
      this.calculateRecExp()
    },
    'mainbook.RecSpecial' () {
      this.doParse()
      this.calculateRecExp()
    },
    'mainbook.ExpCash' () {
      this.doParse()
      this.calculateRecExp()
    },
    'mainbook.ExpBank' () {
      this.doParse()
      this.calculateRecExp()
    },
    'mainbook.ExpFree' () {
      this.doParse()
      this.calculateRecExp()
    },
    'mainbook.ExpVAT' () {
      this.doParse()
      this.calculateRecExp()
    },
    'mainbook.ExpSpecial' () {
      this.doParse()
      this.calculateRecExp()
    },
    'select' () {
      if (this.select) {
      this.mainbook.BookTypeId = this.select && this.select.id && typeof(this.select.id) === 'string' ? parseInt(this.select.id) : this.select.id
      this.mainbook.BookTypeName = this.select.BookTypeName}
    },
    'selectDoc' () {
      if (this.selectDoc) {
      this.mainbook.BookDocId = this.selectDoc && this.selectDoc.id ? parseInt(this.selectDoc.id) : this.selectDoc.id
      this.mainbook.BookDocDesc = this.selectDoc.DocName}
    },
    'mainbook.VAT1CanUse' () {
      this.calcVAT()      
    },
    'mainbook.VAT2CanUse' () {
      this.calcVAT()      
    },
    'mainbook.VAT3CanUse' () {
      this.calcVAT()      
    }
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
