<template>
  <v-container fluid grid-list-md>
   <v-row>
    <v-col>
        Od računa broj:  {{this.minNr}}, do računa broj: {{this.maxNr}}<br />
        Ukupno: ({{curr1}}) : {{ localMoney(round(total1, 2))}} <br />
        PDV 25% Osnovica: {{ localMoney(round(vat1Base, 2))}} Iznos: {{ localMoney(round(vat1Amo, 2))}}<br/>
        PDV 13% Osnovica: {{ localMoney(round(vat2Base, 2))}} Iznos: {{ localMoney(round(vat2Amo, 2))}}<br/>
        PDV 5%  Osnovica: {{ localMoney(round(vat3Base, 2))}} Iznos: {{ localMoney(round(vat3Amo, 2))}}<br/>
        Neoporezivo: {{ localMoney(round(noVat, 2))}} <br/>
        Popust ({{curr1}}) : {{ localMoney(round(totalDiscount, 2))}}<br/>
        Roba: {{ localMoney(round(total1 - totalService, 2))}}, Usluge: {{ localMoney(round(totalService, 2))}}<br/>
        Gotovina ({{curr1}}) : {{ localMoney(round(cash, 2))}}<br/>
        Kartice ({{curr1}}) : {{ localMoney(round(card, 2))}}<br/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <tr
                v-for="(item, index) in cardAmounts"
                :key="item.card"
              >
                <td>{{index + 1}}.</td>
                <td>{{ item.card }}</td>
                <td>{{localMoney(round(item.total,2))}}</td>
              </tr>
            </tbody>
          </template>
      </v-simple-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn @click="documentPrintTotal()"> Isprintaj Obračun </v-btn>
      </v-col>
    </v-row> 
    <DocumentPOSPrintTotal ref="prnt" :printObj="printObj" />
 
  </v-container>
</template>

<script>
import dateFormat from '@/snippets/dateFormat'
import moneyFormat from '@/snippets/moneyFormat'
import DocumentPOSPrintTotal from './DocumentPOSPrintTotal'

export default {
  name: 'dcumentpostotal',
  props: ['items', 'period', 'totalDiscount', 'totalService'],
  data() {
    return {
      total1: 0,
      total2: 0,
      totalFull: 0,
      totalGoods: 0,
      curr1: '',
      curr2: '',
      date: null,
      cash : 0,
      card: 0,
      other: 0,
      vat1Base: 0,
      vat1Amo: 0,
      vat2Base: 0,
      vat2Amo: 0,
      vat3Base: 0,
      vat3Amo: 0,
      noVat: 0,
      printObj: {},
      maxNr: 0,
      minNr: 1000000000,
      cardAmounts: [],
      cardIndex: 1
    }
  },
  mounted() {

    if (this.items) {
      let greske = 0
      const newObj = {}
      this.items.map(item => {
        const nrPos = parseInt(item.documentId)
        if (nrPos < this.minNr) {
          this.minNr = nrPos
        }
        if (nrPos > this.maxNr) {
          this.maxNr = nrPos
        }

        this.cash += parseFloat(item.documentPOSCash)
        this.card += parseFloat(item.documentPOSCard)
        this.vat1Base += parseFloat(item.documentTotalVAT1Base)
        this.vat1Amo += parseFloat(item.documentTotalVAT1Amount)
        this.vat2Base += parseFloat(item.documentTotalVAT2Base)
        this.vat2Amo += parseFloat(item.documentTotalVAT2Amount)
        this.vat3Base += parseFloat(item.documentTotalVAT3Base)
        this.vat3Amo += parseFloat(item.documentTotalVAT3Amount)

        this.total1 += item.documentTotalAmountBrutto ?  parseFloat(item.documentTotalAmountBrutto) : 0
        this.total2 += parseFloat(item.documentTotalAmountBruttoForeign)
        this.totalFull += parseFloat(item.documentDescription9)
        //this.totalDiscount += parseFloat(item.documentDescription10)
        this.curr1 = item.documentHomeCurrency ? item.documentHomeCurrency : 'Kn'
        this.curr2 = item.documentForeignCurrency ? item.documentForeignCurrency : 'EUR'
        if (item.status) {
          const cardPay = {...newObj}
          cardPay.card = item.status
          cardPay.total = parseFloat(item.documentPOSCard)
          this.totalCards(cardPay)
        }
        const a1 = parseFloat(item.documentTotalAmountBrutto)
        const a2 = parseFloat(item.documentPOSCash)
        const a3 = parseFloat(item.documentPOSCard)
        if ((a1 - (a2 + a3))) {
          console.log(' Razlika racun ', item.documentId, item.email, ' =', (a1 - (a2 + a3)))
          greske += 1
        }
      })
      console.log(' Greske !', greske)
      this.printObj.line1 = 'Ukupno: ' + this.localMoney(this.round(this.total1, 2)) + ' ' + this.curr1 
      this.printObj.line2 = 'Od računa broj: ' + this.minNr + ', do računa broj:' + this.maxNr
      this.printObj.line3 = 'PDV 25% Osnovica: ' + this.localMoney(this.round(this.vat1Base, 2)) + ' Iznos: ' + this.localMoney(this.round(this.vat1Amo, 2))
      this.printObj.line4 = 'PDV 13% Osnovica: ' + this.localMoney(this.round(this.vat2Base, 2)) + ' Iznos: ' + this.localMoney(this.round(this.vat2Amo, 2))
      this.printObj.line5 = 'PDV 5%  Osnovica: ' + this.localMoney(this.round(this.vat3Base, 2)) + ' Iznos: ' + this.localMoney(this.round(this.vat3Amo, 2))
      this.printObj.line6 = 'Neoporezivo: ' + this.localMoney(this.round(this.noVat, 2))
      this.printObj.line7 = 'Popust: ' + this.localMoney(this.round(this.totalDiscount, 2))  + ' ' + this.curr1
      this.printObj.line8 = 'Roba: ' + this.localMoney(this.round(this.total1 - this.totalService, 2)) +  ', Usluge: ' + this.localMoney(this.round(this.totalService, 2))
      this.printObj.line9 = 'Gotovina: ' + this.localMoney(this.round(this.cash, 2)) + ' ' + this.curr1 
      this.printObj.line10 = 'Kartice: ' + this.localMoney(this.round(this.card, 2)) + ' ' + this.curr1
      this.printObj.startDate = this.period.startDate ? this.period.startDate : Date.now()
      this.printObj.endDate = this.period.endDate ? this.period.endDate: Date.now()
      this.printObj.cardAmounts = this.cardAmounts
      
    }
   
  },
  methods: {
    totalCards(cardPay) {
      if (this.cardAmounts.length > 0) {
        const objIndex = this.cardAmounts.findIndex((obj => obj.card === cardPay.card));
        if (objIndex !== -1) {
          const newTotal = this.cardAmounts[objIndex].total + cardPay.total
          this.cardAmounts[objIndex].total = newTotal
        } else {
          this.cardAmounts.push(cardPay)
        }
      } else {
        this.cardAmounts.push(cardPay)
      }
    },
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    round (value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    documentPrintTotal() {
      this.$refs.prnt.print()
    },
  },
  components: {
    DocumentPOSPrintTotal
  }

}
</script>