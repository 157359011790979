<template>
<div>
  <v-progress-circular
    v-if="this.saving"
    indeterminate
    color="green"
  ></v-progress-circular>

  <v-row align="center" class="list px-3 mx-auto">
  <v-col cols="4" sm="2">
      <v-select
        v-model="searchOption"
        :items="searchOptions"
        :label="langC.SearchBy"
        clearable
        @change="handleSearchOption"
      ></v-select>
    </v-col>
    <v-col cols="12" sm="2">
      <v-text-field v-model="searchName" :label="langC.SearchBy"></v-text-field>
    </v-col>
    <v-col cols="12" sm="1">
      <v-btn small @click="page = 1; retrievePartners();">
        {{langC.Search}}
      </v-btn>
    </v-col>
    <v-col cols="5" sm="5">
      <v-pagination
        v-model="page"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      ></v-pagination>
      
    </v-col>
    <v-col cols="4" sm="1">
      Total:{{totalItems}}
    </v-col>
    <v-col cols="4" sm="1">
      <v-select
        v-model="pageSize"
        :items="pageSizes"
        label="Items per Page"
        @change="handlePageSizeChange"
      ></v-select>
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="12" sm="12">
      <v-card class="mx-auto" tile>


        <v-data-table
          dense
          :headers="headers"
          :items="partners"
          disable-pagination
          :hide-default-footer="true"
          class="elevation-1"
          @click:row="navigateTo"
        >

        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
    <v-row>
    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
      <v-btn
        color="green"
        text
        @click="partnertestvatid">
        Test
      </v-btn>
    </v-col>
  </v-row>
  </div>
</template>

<script>
import PartnerService from '@/services/PartnerService'
import langEn from './PartnerDescEN'
import langHr from './PartnerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"

export default {
  name: 'partnerlist',
  data () {
    return {
      lang: {},
      langC: {},
      partners: [],
      searchName: "",
      headers: [],
      page: 1,
      totalPages: 0,
      pageSize: 30,
      pageSizes: [10, 30, 50],
      searchOption: '',
      searchOptions: [],
      totalItems: 0,
      saving: false,
      errorpart: []
    }
  },
  mounted () {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      this.searchOptions.push(
        {text: this.lang.PartnerName, value: 'partnerName', width: '300px'},
        {text: this.lang.PartnerVATID, value: 'partnerVATID', width: '150px'},
        {text: this.lang.Partneremail, value: 'partneremail', width: '150px'},
        {text: this.lang.PartnerPhones, value: 'partnerPhones', width: '150px'},
        {text: this.lang.PartnerBankIBAN, value: 'partnerBankIBAN', width: '150px'},
        {text: this.lang.PartnerDirectorFirst, value: 'partnerDirectorFirst', width: '100px'},
        {text: this.lang.PartnerDirectorLast, value: 'partnerDirectorLast', width: '100px'},
        {text: this.lang.PartnerDirectorEmail, value: 'partnerDirectorEmail', width: '150px'},
        {text: this.lang.PartnerType, value: 'partnerType', width: '50px'},
      )

      this.headers.push(
        {text: this.lang.PartnerName, value: 'partnerName', width: '300px'},
        {text: this.lang.PartnerVATID, value: 'partnerVATID', width: '150px'},
        {text: this.lang.Partneremail, value: 'partneremail', width: '150px'},
        {text: this.lang.PartnerPhones, value: 'partnerPhones', width: '150px'},
        {text: this.lang.partnerStreet, value: 'partnerStreet', width: '250px'},
        {text: this.lang.partnerBuildingNameNumber, value: 'partnerBuildingNameNumber', width: '80px'},
        {text: this.lang.partnerCity, value: 'partnerCity', width: '250px'},
        {text: this.lang.partnerPostcode, value: 'partnerPostcode', width: '80px'},
        {text: this.lang.PartnerBankIBAN, value: 'partnerBankIBAN', width: '150px'},
        {text: this.lang.PartnerDirectorFirst, value: 'partnerDirectorFirst', width: '100px'},
        {text: this.lang.PartnerDirectorLast, value: 'partnerDirectorLast', width: '100px'},
        {text: this.lang.PartnerDirectorEmail, value: 'partnerDirectorEmail', width: '150px'},
        {text: this.lang.PartnerType, value: 'partnerType', width: '50px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'}
      )
      this.$store.dispatch('seteditItem', 0)
      if (this.$store.state.searchState) {
        this.searchName = this.$store.state.searchState.searchName ? this.$store.state.searchState.searchName : null
        this.page = this.$store.state.searchState.page ? this.$store.state.searchState.page + 1 : 0
        this.pageSize = this.$store.state.searchState.pageSize ? this.$store.state.searchState.pageSize : 30
        this.searchItem = this.$store.state.searchState.searchOption ? this.$store.state.searchState.searchOption : null
        this.$store.dispatch('setSearchState', null)
      }
      this.retrievePartners()
  },
  methods: {
      async partnertestvatid(){
        const query = {}
        const {data} =  await PartnerService.testvatid(query)

        if (data && data.partners !== undefined && data.partners.length > 0) {
          data.partners.map(part => {
            if (parseInt(part.havesame) > 1 && part.partnerVATID.length > 0) {
              this.errorpart.push(part)
            }
          })
          console.log("🚀 ~ errorpart", this.errorpart)
        }
      },
      setQuery() {
        const query = {}
        query.CompanyId = this.$store.state.companyid
        query.searchName = this.searchName ? this.searchName.trim() : null
        query.page = this.page ? this.page - 1 : 0
        query.pageSize = this.pageSize ? this.pageSize : 30
        query.searchItem = this.searchOption ? this.searchOption : null
        return query
      },
      async retrievePartners() {
        
        const query = this.setQuery()
        this.saving = true
        const privateP = this.$store.state.user ? this.$store.state.user.privatePartner : true
        const {data} = privateP ? (await PartnerService.index2(query)) : (await PartnerService.index(query))
        this.saving = false

        if (data && data.partners !== undefined && data.partners.length > 0) {

          this.partners = data.partners
          this.totalItems = data.totalPages
          this.totalPages = parseInt(data.totalPages / this.pageSize) + 1
        }
      },

      handlePageChange(value) {
        this.page = value;
        this.retrievePartners();
      },

      handlePageSizeChange(size) {
        this.pageSize = size;
        this.page = 1;
        this.retrievePartners();
      },

      handleSearchOption(option) {
        this.searchOption = option
      },
      navigateTo (params) {
        this.$store.dispatch('setPartnerid', params.id)
        this.$store.dispatch('setPartnerVATID', params.partnerVATID)
        this.$store.dispatch('setSearchState', this.setQuery())
        this.$router.push({
          name: 'partnerview'
        })
      }

  },
  computed: {
  },
  watch: {

  },
  components: {
 
  }
}
</script>
