<template>
  <div>

  </div>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './salarieDescEn'
import langHr from './salarieDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'
import {dynamicSortMultiple} from '@/snippets/allSnippets'
// import { parseNum } from '@/snippets/allSnippets'

export default {
  name: 'salarieprintcompensation',
  props: [],
  data () {
    return {
      msg: '',
      documentName: '',
      documentName2: '',
      docDefinition: null,
      content: [],
      document: {},
      havePath: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locDocument: {},
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      comp: {},
      mainQuery: {},
      ana: {},
      totalAll: 0,
      notaxaddition:[],
      First: '',
      Last: '',
      VatID: '',
      hideDetails: false
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = {...langEn}
      this.langC = {...commEn}
    }
    if (this.$store.state.language === 'HR') {
      this.lang = {...langHr}
      this.langC = {...commHr}
    }
  },
  methods: {
    async print () {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.BusinessYear = this.$store.state.businessYear
      }

      this.printDoc()
    },

    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    printDoc () {
      this.comp = this.$store.state.companyActiv
      this.ana = this.$store.state.printObj ? this.$store.state.printObj : {}
      this.hideDetails = this.ana.head2
      this.notaxaddition = this.$store.state.joppdNotax
      
     //const persons = this.ana.items.filter((VatID, index, array) => array.indexOf(VatID) === index);
      const persons = [...new Set(this.ana.items.map(item => item.VatID))];
      // console.log('persons', persons)

      this.initDocument()
      this.addHead()
      this.addLine()
      
      if (persons && persons.length > 0){
        persons.map(person => {
          const oneperson = this.ana.items.filter(per => per.VatID === person)
          oneperson.sort(dynamicSortMultiple("value", 'JoppdDate'))
          this.addEmptyRow()
          this.addLine()
          this.addItemsHeader(oneperson[0])
          this.addLine()
          this.addItems(oneperson)
  
        })
      }
      //this.addItems()
      this.addLine()
      this.addLine()
      this.addFullTotal()
      this.addLine()
      this.addLastLine()

  
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 40 ]
      this.content = []
    },
  
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addLineDash () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 150,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1,
                dash: { length: 1 },
              }
            ]
          }
      this.content.push(docPart)
    },
    addLastLine() {
      const docPart01 = {
        columns: [
            {
              width: '40%',
              fontSize: 7,
              bold: false,
              alignment: 'left',
              text: 'www.roboot.net',
            },
          ]
        }
        this.content.push(docPart01)
    },

    addHead() {
      const docPart = {
          columns: [
            {
              // % width
              width: '35%',
              text: [
                { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.Companyemail ? 'email:' +  this.comp.Companyemail + ', OIB:' + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' ') +' \n' : ' ') , fontSize: 8 },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '45%',
              text: [
                { text: 'Plaća - neoporezive isplate \n', fontSize: 18, bold: true },
                { text: 'za period joppd od ' + (this.ana.startDate ? dayjs(this.ana.startDate).format('DD.MM.YYYY') + '/' : '') + (this.ana.endDate ? dayjs(this.ana.endDate).format('DD.MM.YYYY') : ''), fontSize: 12, bold: true },
                { text: (this.ana.head3 ? this.ana.head3 + ' \n' : ''), fontSize: 8, bold: true },
                { text: (this.ana.head4 ? this.ana.head4 + ' \n' : ''), fontSize: 8, bold: true },
                { text: (this.ana.head5 ? this.ana.head5 + ' \n' : ''), fontSize: 8, bold: true },
              ]
            },
            {
              // % width
              width: '20%',
              text: [                 
                { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },

    addItemsHeader (person) {
      const docPart = {
          columns: [
            {
              width: '5%',
              text: 'Rbr' + '\n', fontSize: 8 
            },
            {
              width: '35%',
              fontSize: 8,
              bold: true,
              text:  (person.First ? person.First + ' ' : '') + (person.Last ? person.Last  : 'Radnik')  + '\n', fontSize: 14
            },
            {
              width: '10%',
              fontSize: 8,
              alignment: 'right',
              text: this.hideDetails ? '' : ('Vrsta') + ' \n',
            },
            {
              width: '10%',
              fontSize: 8,
              alignment: 'right',
              text: this.hideDetails ? '' : ('Broj Joppd') + ' \n',
            },
            {
              width: '10%',
              fontSize: 8,
              alignment: 'right',
              text: this.hideDetails ? '' : ('Joppd Poslan') + ' \n',
            },
            {
              width: '10%',
              fontSize: 8,
              alignment: 'right',
              text: [
                { text: 'Iznos' + ' \n' }
              ]
            },
            {
              width: '8%',
              fontSize: 8,
              alignment: 'right',
              text: [
                { text: 'Godišnje' + ' \n' + 'dozvoljeno' }
              ]
            },
            {
              width: '8%',
              fontSize: 8,
              alignment: 'center',
              text: [
                { text: 'Za' + ' \n' + 'iskoristiti' }
              ]
            }
          ]
        }
      this.content.push(docPart)
    },
    addItems(person) {
          this.First = person.First
          this.Last = person.Last
          this.VatID = person.VatID
          this.order = 0
          let total = 0
          let totalBy = 0
          let cond = null
            if (person) {
              person.map(item => {
                this.order += 1
                if (!cond) {
                  cond = item.value
                }
                if (cond !== item.value){
                  // add row fow total in value
                  const haveIt = this.$store.state.joppdNotaxAll.find(tadd => (tadd.Value === cond) )
                  const avaY = haveIt && haveIt.OnYear && haveIt.OnYear > 0 ? haveIt.OnYear : null
                  const restY = avaY && haveIt.OnYear ? haveIt.OnYear - totalBy : null
                  const docPartInt = {
                    columns: [
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: ''
                        },
                        {
                          width: '65%',
                          fontSize: 8,
                          alignment: 'left',
                          text: 'Uk.'+(cond && haveIt && haveIt.Description ? haveIt.Description.substring(0, 90) : '') +  ' \n',
                        },
                        {
                          width: '10%',
                          fontSize: 8,
                          alignment: 'right',
                          text: [
                            { text: this.formatPriceHR(totalBy) + ' \n' }
                          ]
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: (avaY ? this.formatPriceHR(avaY) : '') + ' \n' 
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text: (restY ? this.formatPriceHR(restY) : '') + ' \n' 
                        }
                      ]
                  }
                  this.content.push(docPartInt)
                  this.addLineDash()
                  totalBy = 0
                  cond = item.value
                }

                total += item.amount
                totalBy += item.amount
                this.totalAll += item.amount
                const docPart = {
                columns: [
                    {
                      width: '5%',
                      fontSize: 8,
                      alignment: 'left',
                      text: this.order + '.'
                    },
                    {
                      width: '35%',
                      fontSize: 8,
                      alignment: 'left',
                      text: '' + ' \n',
                    },
                    {
                      width: '10%',
                      fontSize: 8,
                      alignment: 'right',
                      text:(item.value ? item.value  : '') + ' \n',
                    },
                    {
                      width: '10%',
                      fontSize: 8,
                      alignment: 'right',
                      text:(item.BrojJoppd ? item.BrojJoppd  : '') + ' \n',
                    },
                    {
                      width: '10%',
                      fontSize: 8,
                      alignment: 'right',
                      text:(item.JoppdPoslanDate ? dayjs(item.JoppdPoslanDate ).format('DD.MM.YYYY')  : '') + ' \n',
                    },
                    {
                      width: '10%',
                      fontSize: 8,
                      alignment: 'right',
                      text: [
                        { text: this.formatPriceHR(item.amount) + ' \n' }
                      ]
                    }
                  ]
                }

              if (this.hideDetails === false) {
                this.content.push(docPart)
              }
              // const docPart2 = {
              //   columns: [
              //       {
              //         width: '25%',
              //         text: [
              //           { text: ' \n', fontSize: 8 },
              //         ]
              //       }
              //     ]
              //   }
              // this.content.push(docPart2)
            })
            // do final for person
            if (cond){
              const haveIt = this.$store.state.joppdNotaxAll.find(tadd => (tadd.Value === cond) )
              const avaY = haveIt && haveIt.OnYear && haveIt.OnYear > 0 ? haveIt.OnYear : null
              const restY = avaY && haveIt.OnYear ? haveIt.OnYear - totalBy : null
              // add row fow total in value
              const docPartInt = {
                columns: [
                    {
                      width: '5%',
                      fontSize: 8,
                      alignment: 'left',
                      text: ''
                    },
                    {
                      width: '65%',
                      fontSize: 8,
                      alignment: 'left',
                      text: 'Uk.'+(cond && haveIt && haveIt.Description ? haveIt.Description.substring(0, 90) : '') +  ' \n',
                    },
                    {
                      width: '10%',
                      fontSize: 8,
                      alignment: 'right',
                      text: [
                        { text: this.formatPriceHR(totalBy) + ' \n' }
                      ]
                    },
                    {
                      width: '8%',
                      fontSize: 8,
                      alignment: 'right',
                      text: [
                        { text: avaY ? this.formatPriceHR(avaY) : '' + ' \n' }
                      ]
                    },
                    {
                      width: '8%',
                      fontSize: 8,
                      alignment: 'right',
                      text: [
                        { text: restY ? this.formatPriceHR(restY) : '' + ' \n' }
                      ]
                    }
                  ]
              }
              this.content.push(docPartInt)
              this.addLineDash()
              
            }

            const docPartTot = {
                    columns: [
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: ''
                        },
                        {
                          width: '25%',
                          fontSize: 8,
                          alignment: 'left',
                          text: 'Ukupno: '+ (this.First ? this.First + ' ' : '') + (this.Last ? this.Last  : '') + (this.VatID ? ' OIB: ' + this.VatID  : '')+' \n',
                        },
                        {
                          width: '15%',
                          fontSize: 8,
                          alignment: 'right',
                          text: '' + ' \n',
                        },
                        {
                          width: '25%',
                          fontSize: 8,
                          alignment: 'right',
                          text: '' + ' \n',
                        },
                        {
                          width: '10%',
                          fontSize: 8,
                          alignment: 'right',
                          text: [
                            { text: this.formatPriceHR(total) + ' \n' }
                          ]
                        }
                      ],
                  }
                  this.content.push(docPartTot)
                  this.addEmptyRow()
          }
    },

    addFullTotal() {
      const docPartTot = {
        columns: [
              {
                width: '5%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '25%',
                fontSize: 8,
                alignment: 'left',
                text: 'SVEUKUPNO:'+ ' \n',
              },
              {
                width: '15%',
                fontSize: 8,
                alignment: 'right',
                text: '' + ' \n',
              },
              {
                width: '25%',
                fontSize: 8,
                alignment: 'right',
                text: '' + ' \n',
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'right',
                text: [
                  { text: this.formatPriceHR(this.totalAll) + ' \n' }
                ]
              }
            ]
        }
        this.content.push(docPartTot)
      },  

    addEmpty () {
      const docPart = {
        
          }
      this.content.push(docPart)
    }

  },
  computed: {
  },
  watch: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
