import store from '../../store/store'

const defDocumentStatus = () => {
  const defObj = {}
  defObj.CompanyId = 0
  defObj.CompanyNameDatabase = ''
  defObj.BusinessYear = 0
  defObj.UserId = 0
  defObj.email = ''
  defObj.First = ''
  defObj.Last = ''
  defObj.Activ = 0
  defObj.doc = ''
  defObj.documentSide = 0
  defObj.docStatus = ''
  defObj.docColor = ''
  defObj.EnteredInDatabase = null
  defObj.LastUpdateInDatabase = null
  defObj.FreeF1 = 0
  defObj.FreeF2 = 0
  defObj.FreeF3 = 0
  defObj.FreeF4 = 0
  defObj.FreeF5 = 0
  defObj.FreeB1 = false
  defObj.FreeB2 = false
  defObj.FreeB3 = false
  defObj.FreeB4 = false
  defObj.FreeB5 = false
  defObj.FreeS1 = ''
  defObj.FreeS2 = ''
  defObj.FreeS3 = ''
  defObj.FreeS4 = ''
  defObj.FreeS5 = ''
  return defObj
}

const defNewDocumentStatus = () => {
  const defNew = {}
  defNew.CompanyId = store.state.companyid ? store.state.companyid : 1
  defNew.CompanyNameDatabase = store.state.companyName ? store.state.companyName : ''
  defNew.BusinessYear = store.state.businessYear ? store.state.businessYear : 2020
  defNew.UserId = store.state.user.id ? store.state.user.id : ''
  defNew.email = store.state.user.email ? store.state.user.email : ''
  defNew.First = store.state.user.First ? store.state.user.First : ''
  defNew.Last = store.state.user.Last ? store.state.user.Last : ''
  defNew.doc = store.state.documentActiv ? store.state.documentActiv : ''
  defNew.documentSide = store.state.documentSide ? store.state.documentSide : 1
  defNew.WareHouseId = store.state.warehousemarkid ? store.state.warehousemarkid : 1
  return defNew
}

const defDocument = () => {
  const defObj = {}
  defObj.CompanyId = 0
  defObj.CompanyNameDatabase = ''
  defObj.BusinessYear = 0
  defObj.UserId = 0
  defObj.email = ''
  defObj.First = ''
  defObj.Last = ''
  defObj.UserIdControl = 0
  defObj.emailControl = ''
  defObj.FirstControl = ''
  defObj.LastControl = ''
  defObj.UserIdCFO = 0
  defObj.emailCFO = ''
  defObj.FirstCFO = ''
  defObj.LastCFO = ''
  defObj.documentName = ''
  defObj.documentSide = 0
  defObj.documentAnalysis = 0
  defObj.documentId = 0
  defObj.documentIdGeneral = ''
  defObj.documentDate = null
  defObj.documentTime = null
  defObj.documentStatus = ''
  defObj.documentLocked = false
  defObj.documentLockedDate = null
  defObj.documentLockedTime = null
  defObj.documentLockedBy = ''
  defObj.documentUpdateDate = null
  defObj.documentUpdateTime = null
  defObj.documentUpdateBy = ''
  defObj.documentInvoiceStartDate = null
  defObj.documentInvoiceEndDate = null
  defObj.documentHomeCurrency = 'EUR'
  defObj.documentForeignCurrency = 'USD'
  defObj.documentExRate = store.getters.NeedDefExRate
  defObj.documentConnName = ''
  defObj.documentConnId = 0
  defObj.documentConnDate = null
  defObj.documentConnTime = null
  defObj.documentConnNameFrom = ''
  defObj.documentConnIdFrom = 0
  defObj.documentConnDateFrom = null
  defObj.documentConnTimeFrom = null
  defObj.bankAccountId = ''
  defObj.bankAccountName = ''
  defObj.bankAccountNr = ''
  defObj.partnerId = ''
  defObj.partnerName = ''
  defObj.partnerNameShort = ''
  defObj.partnerNameDatabase = ''
  defObj.partnerBuildingNameNumber = ''
  defObj.partnerStreet = ''
  defObj.partnerCity = ''
  defObj.partnerPostcode = ''
  defObj.partnerCountyRegion = ''
  defObj.partnerState = ''
  defObj.partnerVATID = ''
  defObj.partneremail = ''
  defObj.partnerPhones = ''
  defObj.partnerBankName = ''
  defObj.partnerBankAddress = ''
  defObj.partnerBankIBAN = ''
  defObj.partnerBankSWIFT = ''
  defObj.partnerContactTime = ''
  defObj.partnerSecretaryTitle = ''
  defObj.partnerSecretaryFirst = ''
  defObj.partnerSecretaryLast = ''
  defObj.partnerSecretaryFormer = ''
  defObj.partnerSecretaryStateResidence = ''
  defObj.partnerSecretaryNationality = ''
  defObj.partnerSecretaryDateOfBirth = ''
  defObj.partnerSecretaryBusinessOccupation = ''
  defObj.partnerSecretaryAdressBuildingNameNumber = ''
  defObj.partnerSecretaryAdressStreet = ''
  defObj.partnerSecretaryCity = ''
  defObj.partnerSecretaryPostcode = ''
  defObj.partnerSecretaryCountyRegion = ''
  defObj.partnerSecretaryEmail = ''
  defObj.partnerSecretaryPhone = ''
  defObj.partnerSecretaryContactOption = ''
  defObj.orderDate = null
  defObj.orderTime = null
  defObj.orderDocument = ''
  defObj.deliveryDocumentId = ''
  defObj.deliveryDocumentName = ''
  defObj.deliveryDate = null
  defObj.deliveryTime = null
  defObj.deliveryCurrier = ''
  defObj.deliveryCurrierPhone = ''
  defObj.deliveryCurrierTrackingId = ''
  defObj.documentDescription1 = ''
  defObj.documentDescription2 = ''
  defObj.documentDescription3 = ''
  defObj.documentDescription4 = ''
  defObj.documentDescription5 = ''
  defObj.documentDescription6 = 0
  defObj.documentDescription7 = 0
  defObj.documentDescription8 = 0
  defObj.documentDescription9 = 0
  defObj.documentDescription10 = 0
  defObj.documentTotalVAT1Per = 0
  defObj.documentTotalVAT1Base = 0
  defObj.documentTotalVAT1Amount = 0
  defObj.documentTotalVAT2Per = 0
  defObj.documentTotalVAT2Base = 0
  defObj.documentTotalVAT2Amount = 0
  defObj.documentTotalVAT3Per = 0
  defObj.documentTotalVAT3Base = 0
  defObj.documentTotalVAT3Amount = 0
  defObj.documentTotalVAT4Per = 0
  defObj.documentTotalVAT4Base = 0
  defObj.documentTotalVAT4Amount = 0
  defObj.documentTotalVAT5Per = 0
  defObj.documentTotalVAT5Base = 0
  defObj.documentTotalVAT5Amount = 0
  defObj.documentTotalAmountDirectD1 = 0
  defObj.documentTotalAmountDirectD2 = 0
  defObj.documentTotalAmountDirectD1Fore = 0
  defObj.documentTotalAmountDirectD2Fore = 0
  defObj.documentTotalAmountNetto = 0
  defObj.documentTotalAmountVAT = 0
  defObj.documentTotalAmountBrutto = 0
  defObj.documentTotalAmountPayInAdvance = 0
  defObj.documentTotalAmountToPay = 0
  defObj.documentTotalAmountNettoForeign = 0
  defObj.documentTotalAmountVATForeign = 0
  defObj.documentTotalAmountBruttoForeign = 0
  defObj.documentTotalAmountPayInAdvanceForeign = 0
  defObj.documentTotalAmountToPayForeign = 0
  defObj.FreeF1 = 0
  defObj.FreeF2 = 0
  defObj.FreeF3 = 0
  defObj.FreeF4 = 0
  defObj.FreeF5 = 0
  defObj.FreeF6 = 0
  defObj.paid1Home = 0
  defObj.paid1Fore = 0
  defObj.paid1Date = null
  defObj.paid2Home = 0
  defObj.paid2Fore = 0
  defObj.paid2Date = null
  defObj.unpaidHome = 0
  defObj.unpaidFore = 0
  defObj.status = ''
  defObj.WareHouseId = 1
  defObj.WareHouseName = ''
  defObj.uservatID = ''
  defObj.BookID = 0
  defObj.supplierDocument = ''
  defObj.supplierDocumentNr = ''
  defObj.supplierDocumentDate = null
  defObj.supplierAmount = 0
  defObj.supplierDiscount = 0
  defObj.supplierNetoAmount = 0
  defObj.supplierMargin = 0
  defObj.directDuty1 = 0
  defObj.directDuty2 = 0
  defObj.LastUpdateBy = ''
  defObj.LastUpdate = null
  defObj.privatePerson = false
  defObj.newBaseAmount = 0
  defObj.newVAT1Amount = 0
  defObj.newAmountWithVAT = 0
  defObj.newDirectDuty1 = 0
  defObj.newFullTotal = 0
  defObj.productiontype = 1
  defObj.docColor = null
  defObj.docStatus = null
  defObj.exportDoc = false
  defObj.UCDnumber = ''
  defObj.UCDdate = null
  defObj.importCurrency = ''
  defObj.importAmount = 0
  defObj.exRate = 7.535
  defObj.domecilAmount = 0
  defObj.customAmount = 0
  defObj.bankExp = 0
  defObj.forwarderExp = 0
  defObj.transportExp = 0
  defObj.otherExp = 0
  defObj.totalExp = 0
  defObj.expenesPercent = 0
  defObj.vehicleId = 0
  defObj.noVehicle = false
  defObj.vehicleDesc = ''
  defObj.vehicleHands = 0
  defObj.vehicleParts = 0
  defObj.vehicleTotalService = 0
  defObj.PPO = false
  defObj.serviceStartTime = null
  defObj.serviceEndTime = null
  defObj.a2Dcode = ''
  defObj.QRcodeData = ''
  defObj.QRcodeLink = ''
  defObj.UUID = ''
  defObj.inAdvance = false
  defObj.inAdvancePaid = false
  defObj.validContract = false
  defObj.validContractDate = null
  return defObj
}

const defDocumentDetail = () => {
  const defObj = {}
  defObj.CompanyId = 0
  defObj.CompanyNameDatabase = ''
  defObj.BusinessYear = 0
  defObj.UserId = 0
  defObj.email = ''
  defObj.EmployeeId = null
  defObj.hashtag = ''
  defObj.eemail = ''
  defObj.First = ''
  defObj.Last = ''
  defObj.empWorkDay = null
  defObj.empStartTime = null
  defObj.empEndTime = null
  defObj.empWrkHrs = 0
  defObj.empWrkExpHrs = 0
  defObj.empExp1 = 0
  defObj.empExp2 = 0
  defObj.documentName = ''
  defObj.documentSide = 0
  defObj.documentAnalysis = 0
  defObj.documentId = 0
  defObj.documentIdGeneral = ''
  defObj.documentDate = ''
  defObj.partnerId = ''
  defObj.partnerNameDatabase = ''
  defObj.HomeCurrency = ''
  defObj.ForeignCurrency = ''
  defObj.ExRate = 0
  defObj.itemID = ''
  defObj.itemName = ''
  defObj.ItemNameShort = ''
  defObj.ItemCode = ''
  defObj.ItemEANCode = ''
  defObj.itemGroup = ''
  defObj.itemGroupName = ''
  defObj.itemDescription = ''
  defObj.itemUnit = ''
  defObj.ItemSize = ''
  defObj.ItemWeight = 0
  defObj.ItemWeightMeasure = ''
  defObj.ItemDimMeasure = ''
  defObj.ItemDimLenght = 0
  defObj.ItemDimHeight = 0
  defObj.ItemDimDepth = 0
  defObj.ItemWarehouse = ''
  defObj.ItemWarehouseRow = ''
  defObj.ItemWarehouseShelf = ''
  defObj.ItemWahrehouseFloor = ''
  defObj.ItemPicture1 = ''
  defObj.ItemPicture2 = ''
  defObj.ItemPicture3 = ''
  defObj.ItemPicture4 = ''
  defObj.ItemPicture5 = ''
  defObj.ItemPicture6 = ''
  defObj.ItemPicture7 = ''
  defObj.ItemBelongsToId = 0
  defObj.itemService = false
  defObj.itemWeb = false
  defObj.itemNotActiv = false
  defObj.itemHideForever = false
  defObj.ItemHomeCurrency = ''
  defObj.ItemForeignCurrency = ''
  defObj.ItemExRate = 0
  defObj.itemTransport = 0
  defObj.itemTransportAmount = 0
  defObj.itemCustom = 0
  defObj.itemCustomAmount = 0
  defObj.itemDependables = 0
  defObj.itemDependablesAmount = 0
  defObj.itemSuppBaseForePrice = 0
  defObj.itemSuppBasePercentageExp = 0
  defObj.itemSuppBaseForePrice2 = 0
  defObj.itemSuppBaseForeAmount = 0
  defObj.itemSuppBasePrice = 0
  defObj.itemSuppBasePriceAmount = 0
  defObj.itemSuppDiscount = 0
  defObj.itemSuppDiscountAmount = 0
  defObj.itemSuppPrice = 0
  defObj.itemSuppPriceAmount = 0
  defObj.ItemSuppCode = ''
  defObj.itemMargin = 0
  defObj.itemMarginAmount = 0
  defObj.itemMaxDiscount = 0
  defObj.itemPrice = 0
  defObj.itemVAT1Percetage = 0
  defObj.itemVAT1Amount = 0
  defObj.itemVAT2Percentge = 0
  defObj.itemVAT2Amount = 0
  defObj.itemDirectDuty1 = 0
  defObj.itemDirectDuty2 = 0
  defObj.itemPriceWithVAT = 0
  defObj.itemForePrice = 0
  defObj.itemForeVAT1Percentage = 0
  defObj.itemForeVAT1Amount = 0
  defObj.itemForeVAT2Percentage = 0
  defObj.itemForeVAT2Amount = 0
  defObj.itemForeDirectDuty1 = 0
  defObj.itemForeDirectDuty2 = 0
  defObj.itemForePriceWithVAT = 0
  defObj.itemBaseAmount = 0
  defObj.itemBaseForeAmount = 0
  defObj.itemDiscountAmount1 = 0
  defObj.itemDiscountForeAmount1 = 0
  defObj.itemDiscountPercentage1 = 0
  defObj.itemDiscountAmount2 = 0
  defObj.itemDiscountPercentage2 = 0
  defObj.itemDiscountAmount3 = 0
  defObj.itemDiscountPercentage4 = 0
  defObj.itemDiscountPrice = 0
  defObj.itemDiscountForePrice = 0
  defObj.itemSerialNr = ''
  defObj.itemPcsPlus = 0
  defObj.itemPcsMinus = 0
  defObj.itemPcsNeutral = 0
  defObj.itemBaseAmountNoVAT = 0
  defObj.itemBaseForeAmountNoVAT = 0
  defObj.itemBaseAmountWithVAT = 0
  defObj.itemBaseForeAmountWithVAT = 0
  defObj.itemAmountWithVAT = 0
  defObj.itemFullTotal = 0
  defObj.itemFullTotalFore = 0
  defObj.itemForeAmountWithVAT = 0
  defObj.itemDirectDuty1Amount = 0
  defObj.itemDirectDuty2Amount = 0
  defObj.itemDirectDuty1AmountFore = 0
  defObj.itemDirectDuty2AmountFore = 0
  defObj.EnteredInDatabase = null
  defObj.newitemPrice = 0
  defObj.newitemBaseAmount = 0
  defObj.newitemVAT1Percentage = 0
  defObj.newitemVAT1Amount = 0
  defObj.newitemAmountWithVAT = 0
  defObj.newitemDirectDuty1 = 0
  defObj.newitemFullTotal = 0
  defObj.exportDoc = false
  defObj.itemPriceOriginal = 0
  defObj.itemFullPriceOriginal = 0
  defObj.FreeF1 = 0
  defObj.FreeF2 = 0
  defObj.FreeF3 = 0
  defObj.FreeF4 = 0
  defObj.FreeF5 = 0
  defObj.FreeF6 = 0
  defObj.LastUpdateBy = ''
  defObj.TypeID = 0
  defObj.TypeName = ''
  return defObj
}

const defNewDocumentDetail = () => {
  const defNew = {}
  defNew.CompanyId = store.state.documentActivHead.CompanyId
  defNew.CompanyNameDatabase = store.state.documentActivHead.CompanyNameDatabase
  defNew.BusinessYear = store.state.documentActivHead.BusinessYear
  defNew.UserId = store.state.documentActivHead.UserId
  defNew.email = store.state.documentActivHead.email ? store.state.documentActivHead.email : 'email@email.com'
  defNew.documentName = store.state.documentActivHead.documentName
  defNew.documentId = parseInt(store.state.documentActivHead.documentId)
  defNew.documentIdGeneral = store.state.documentActivHead.documentIdGeneral
  defNew.documentDate = store.state.documentActivHead.documentDate
  defNew.partnerId = store.state.documentActivHead.partnerId
  defNew.partnerNameDatabase = store.state.documentActivHead.partnerNameDatabase
  defNew.HomeCurrency = store.state.documentActivHead.documentHomeCurrency
  defNew.ForeignCurrency = store.state.documentActivHead.documentForeignCurrency
  defNew.ExRate = parseFloat(store.state.documentActivHead.documentExRate)
  defNew.ItemHomeCurrency = store.state.documentActivHead.documentHomeCurrency
  defNew.ItemForeignCurrency = store.state.documentActivHead.documentForeignCurrency
  //defNew.exportDoc = store.state.documentActivHead.exportDoc
  defNew.ItemExRate = parseFloat(store.state.documentActivHead.documentExRate)
  
  if (store.state.documentSide) {
    defNew.documentSide = store.state.documentSide
  }
  if (store.state.documentAnalysis) {
    defNew.documentAnalysis = store.state.documentAnalysis
  }          
  defNew.databaseDocID = store.state.databaseDocID
  if (store.state.documentSide === 1) {
    defNew.itemPcsPlus = 1
  }
  if (store.state.documentSide === 2) {
    defNew.itemPcsMinus = 1
  }
  if (store.state.documentSide === 3) {
    defNew.itemPcsNeutral = 1
  }
  return defNew
}

export {
  defDocument,
  defDocumentStatus,
  defNewDocumentStatus,
  defDocumentDetail,
  defNewDocumentDetail,
}


// document: {
//   CompanyId: 0,
//   CompanyNameDatabase: '',
//   BusinessYear: 0,
//   UserId: 0,
//   email: '',
//   First: '',
//   Last: '',
//   UserIdControl: 0,
//   emailControl: '',
//   FirstControl: '',
//   LastControl: '',
//   UserIdCFO: 0,
//   emailCFO: '',
//   FirstCFO: '',
//   LastCFO: '',
//   documentName: '',
//   documentSide: 0,
//   documentAnalysis: 0,
//   documentId: 0,
//   documentIdGeneral: '',
//   documentDate: null,
//   documentTime: null,
//   documentStatus: '',
//   documentLocked: false,
//   documentLockedDate: null,
//   documentLockedTime: null,
//   documentLockedBy: '',
//   documentUpdateDate: null,
//   documentUpdateTime: null,
//   documentUpdateBy: '',
//   documentInvoiceStartDate: null,
//   documentInvoiceEndDate: null,
//   documentHomeCurrency: 'Kn',
//   documentForeignCurrency: 'EUR',
//   documentExRate: 7.535,
//   documentConnName: '',
//   documentConnId: 0,
//   documentConnDate: null,
//   documentConnTime: null,
//   documentConnNameFrom: '',
//   documentConnIdFrom: 0,
//   documentConnDateFrom: null,
//   documentConnTimeFrom: null,
//   bankAccountId: '',
//   bankAccountName: '',
//   bankAccountNr: '',
//   partnerId: '',
//   partnerName: '',
//   partnerNameShort: '',
//   partnerNameDatabase: '',
//   partnerBuildingNameNumber: '',
//   partnerStreet: '',
//   partnerCity: '',
//   partnerPostcode: '',
//   partnerCountyRegion: '',
//   partnerState: '',
//   partnerVATID: '',
//   partneremail: '',
//   partnerPhones: '',
//   partnerBankName: '',
//   partnerBankAddress: '',
//   partnerBankIBAN: '',
//   partnerBankSWIFT: '',
//   partnerContactTime: '',
//   partnerSecretaryTitle: '',
//   partnerSecretaryFirst: '',
//   partnerSecretaryLast: '',
//   partnerSecretaryFormer: '',
//   partnerSecretaryStateResidence: '',
//   partnerSecretaryNationality: '',
//   partnerSecretaryDateOfBirth: '',
//   partnerSecretaryBusinessOccupation: '',
//   partnerSecretaryAdressBuildingNameNumber: '',
//   partnerSecretaryAdressStreet: '',
//   partnerSecretaryCity: '',
//   partnerSecretaryPostcode: '',
//   partnerSecretaryCountyRegion: '',
//   partnerSecretaryEmail: '',
//   partnerSecretaryPhone: '',
//   partnerSecretaryContactOption: '',
//   orderDate: null,
//   orderTime: null,
//   orderDocument: '',
//   deliveryDocumentId: '',
//   deliveryDocumentName: '',
//   deliveryDate: null,
//   deliveryTime: null,
//   deliveryCurrier: '',
//   deliveryCurrierPhone: '',
//   deliveryCurrierTrackingId: '',
//   documentDescription1: '',
//   documentDescription2: '',
//   documentDescription3: '',
//   documentDescription4: '',
//   documentDescription5: '',
//   documentDescription6: 0,
//   documentDescription7: 0,
//   documentDescription8: 0,
//   documentDescription9: 0,
//   documentDescription10: 0,
//   documentTotalVAT1Per: 0,
//   documentTotalVAT1Base: 0,
//   documentTotalVAT1Amount: 0,
//   documentTotalVAT2Per: 0,
//   documentTotalVAT2Base: 0,
//   documentTotalVAT2Amount: 0,
//   documentTotalVAT3Per: 0,
//   documentTotalVAT3Base: 0,
//   documentTotalVAT3Amount: 0,
//   documentTotalVAT4Per: 0,
//   documentTotalVAT4Base: 0,
//   documentTotalVAT4Amount: 0,
//   documentTotalVAT5Per: 0,
//   documentTotalVAT5Base: 0,
//   documentTotalVAT5Amount: 0,
//   documentTotalAmountDirectD1: 0,
//   documentTotalAmountDirectD2: 0,
//   documentTotalAmountDirectD1Fore: 0,
//   documentTotalAmountDirectD2Fore: 0,
//   documentTotalAmountNetto: 0,
//   documentTotalAmountVAT: 0,
//   documentTotalAmountBrutto: 0,
//   documentTotalAmountPayInAdvance: 0,
//   documentTotalAmountToPay: 0,
//   documentTotalAmountNettoForeign: 0,
//   documentTotalAmountVATForeign: 0,
//   documentTotalAmountBruttoForeign: 0,
//   documentTotalAmountPayInAdvanceForeign: 0,
//   documentTotalAmountToPayForeign: 0,
//   FreeF1: 0,
//   FreeF2: 0,
//   FreeF3: 0,
//   FreeF4: 0,
//   FreeF5: 0,
//   FreeF6: 0,
//   paid1Home: 0,
//   paid1Fore: 0,
//   paid1Date: null,
//   paid2Home: 0,
//   paid2Fore: 0,
//   paid2Date: null,
//   unpaidHome: 0,
//   unpaidFore: 0,
//   status: '',
//   WareHouseId: 1,
//   WareHouseName: '',
//   uservatID: '',
//   BookID: 0,
//   supplierDocument: '',
//   supplierDocumentNr: '',
//   supplierDocumentDate: null,
//   supplierAmount: 0,
//   supplierDiscount: 0,
//   supplierNetoAmount: 0,
//   supplierMargin: 0,
//   directDuty1: 0,
//   directDuty2: 0,
//   LastUpdateBy: '',
//   LastUpdate: null,
//   privatePerson: false,
//   newBaseAmount: 0,
//   newVAT1Amount: 0,
//   newAmountWithVAT: 0,
//   newDirectDuty1: 0,
//   newFullTotal: 0,
//   productiontype: 1,
//   docColor: null,
//   docStatus: null,
//   exportDoc: false,
//   UCDnumber: '',
//   UCDdate: null,
//   importCurrency: '',
//   importAmount: 0,
//   exRate: 7.535,
//   domecilAmount: 0,
//   customAmount: 0,
//   bankExp: 0,
//   forwarderExp: 0,
//   transportExp: 0,
//   otherExp: 0,
//   totalExp: 0,
//   expenesPercent: 0,
// },