<template>
  <div>

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ledgerEN from '../MainLedger/MainLedgerDescEN'
import ledgerHR from '../MainLedger/MainLedgerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
//import PartnerService from '@/services/PartnerService'
import {parseNum, round} from '@/snippets/allSnippets'
import dayjs from 'dayjs'


export default {
  name: 'mainbookcompcard2print',
  props: [ ],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0,
      partner: {},
      debitTotal: 0,
      creditTotal: 0,
      debitCreditTotal: 0,
      headline: '',
      card: {},
      defCurrency: '',
      type: null
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    

  },
  methods: {
    print (card) {
      this.card = card
      this.defCurrency = card.defCurrency ? card.defCurrency : ''
      this.type = card.type ? card.type : ''
      this.debitTotal = this.Total1 ? this.Total1 : 0
      this.creditTotal = this.Total2 ? this.Total2 : 0
      this.debitCreditTotal = this.Total3 ? this.Total3 : 0
      console.log('this.card.par', this.card.par)

      if (this.type === 1) {
        this.headline = 'Kartica - Dobavljač ' + this.defCurrency
      }
      if (this.type === 2) {
        this.headline = 'Kartica - Kupac ' +  this.defCurrency
      }
      if (this.card.open) {
        this.headline = this.headline + '\n' + 'otvorene stavke '
      }
 
      this.initDocument()
      this.addHeaderNoLogo()
      this.addLine()
      this.addItemsHeader()
      this.addLine()
      this.addItems()
      this.addLine()
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.content = []
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addLineDash () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1,
                dash: { length: 1 },
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '35%',
                text: [
                  { text: this.comp.CompanyNameShort + ' \n', fontSize: 14, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text:  this.headline + '\n', fontSize: 14, bold: true },
                  { text:  (this.card.par && this.card.par.partnerName ? this.card.par.partnerName + '\n' : '') , fontSize: 16, bold: true },
                  { text:  (this.card.par && this.card.par.partnerStreet ? this.card.par.partnerStreet : '') + (this.card.par && this.card.par.partnerBuildingNameNumber ? this.card.par.partnerBuildingNameNumber: '') + '\n', fontSize: 10 },
                  { text:  (this.card.par && this.card.par.partnerPostcode ? this.card.par.partnerPostcode : '') + (this.card.par && this.card.par.partnerCity ? this.card.par.partnerCity : '') + (this.card.par.partnerVATID ? ',OIB: ' + this.card.par.partnerVATID : '') + (this.card.par && this.card.par.partneremail ? this.card.par.partneremail : '') + '\n', fontSize: 10 },
                  { text:  (this.card.docFromDate ? ' Od datuma: ' + dayjs(this.card.docFromDate).format('DD.MM.YYYY') : '')  + (this.card.docToDate ? ' Do datuma: ' + dayjs(this.card.docToDate).format('DD.MM.YYYY') : ''), fontSize:8}
                ]   
              },
              {
                // % width
                width: '20%',
                text: [                 
                  { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },

    addItemsHeader () {
        const docPart04 = {
        columns: [
              {
                width: '6%',
                fontSize: 8,
                alignment: 'left',
                text: 'Rb.'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: 'Vrsta dok.'
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'left',
                text: 'Broj dok.'
              },
              {
                width: '11%',
                fontSize: 8,
                alignment: 'left',
                text: 'Veza'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: 'Datum knjiž.'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: 'Datum rač.'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: 'Rok plać.'
              },
              {
                width: '16%',
                fontSize: 8,
                alignment: 'left',
                text: 'Opis'
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: (this.type === 1 ? 'Isplate ' : 'Računi ') + this.defCurrency
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                text: (this.type === 1 ? 'Računi ' : 'Uplate ') + this.defCurrency
              },
              {
                width: '8%',
                fontSize: 7,
                alignment: 'right',
                text: 'Saldo ' + this.defCurrency
              },
            ]
          }
        this.content.push(docPart04)        
    },

    addItems () {
           this.order = 0
 
            if (this.card.items) {

              const locItems = JSON.parse(JSON.stringify(this.card.items))
              locItems.map(item => {
                  this.order += 1
   
                    this.debitTotal +=  item.debit ? round(parseNum(item.debit),2) : 0
                    this.creditTotal +=  item.credit ? round(parseNum(item.credit),2) : 0
                    this.debitCreditTotal +=  item.debit || item.credit ? round(parseNum(item.debit),2) - round(parseNum(item.credit),2) : 0
                    const docPart010 = {
                      columns: [
                            {
                              width: '6%',
                              fontSize: 8,
                              alignment: 'left',
                              text: this.order + '.'
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'left',
                              text: item.BookTypeName
                            },
                            {
                              width: '7%',
                              fontSize: 7,
                              alignment: 'left',
                              text: item.InvoiceNumber
                            },
                            {
                              width: '11%',
                              fontSize: 7,
                              alignment: 'left',
                              text: item.docID ? parseInt(item.docID) : ''
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'left',
                              text: (item.datum ? dayjs(item.datum).format('DD.MM.YYYY') : '')
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'left',
                              text: (item.InvoiceDate ? dayjs(item.InvoiceDate).format('DD.MM.YYYY') : '')
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'left',
                              text: (item.InvoicePaymentDeadlineDate ? dayjs(item.InvoicePaymentDeadlineDate).format('DD.MM.YYYY') : '')
                            },
                            {
                              width: '16%',
                              fontSize: 7,
                              alignment: 'left',
                              text: item.desc1
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'right',
                              text: this.formatPrice(item.debit)
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'right',
                              text: this.formatPrice(item.credit)
                            },
                            {
                              width: '8%',
                              fontSize: 7,
                              alignment: 'right',
                              text: this.formatPrice(item.CreditDebit)
                            },
                          ]
                        }
                      this.content.push(docPart010)
                  })

                  this.addLine()

                  const docPart020 = {
                      columns: [
                          {
                            width: '8%',
                            fontSize: 8,
                            alignment: 'left',
                            text: ' '
                          },
                          {
                            width: '8%',
                            fontSize: 8,
                            alignment: 'left',
                            text: ' '
                          },
                          {
                            width: '8%',
                            fontSize: 8,
                            alignment: 'left',
                            text: ' '
                          },
                          {
                            width: '8%',
                            fontSize: 8,
                            alignment: 'left',
                            text: ' '
                          },
                          {
                            width: '8%',
                            fontSize: 8,
                            alignment: 'left',
                            text: ' '
                          },
                          {
                            width: '8%',
                            fontSize: 8,
                            alignment: 'left',
                            text: ' '
                          },
                          {
                            width: '18%',
                            fontSize: 8,
                            alignment: 'left',
                            text: 'UKUPNO:'
                          },
                          {
                            width: '6%',
                            fontSize: 8,
                            alignment: 'right',
                            text: ' '
                          },
                          {
                            width: '8%',
                            fontSize: 7,
                            alignment: 'right',
                            text: this.formatPrice(this.debitTotal)
                          },
                          {
                            width: '8%',
                            fontSize: 7,
                            alignment: 'right',
                            text: this.formatPrice(this.creditTotal)
                          },
                          {
                            width: '8%',
                            fontSize: 7,
                            alignment: 'right',
                            text: this.formatPrice(this.debitCreditTotal)
                          },
                      ]
                    }
                  this.content.push(docPart020)

        }  
    },

  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>