<template>
  <v-container fluid class="py-10">
    <v-layout row>
      <v-flex xs12 sm12 m12 lg12 ex12>
      <v-row v-if="error">
              <v-alert 
                :type="typeError"
                v-model="alert"
                dismissible>
               {{error}}
              </v-alert>
            </v-row>
        <v-card>
          <v-card-title>
            {{ documentNameLoc }}
            <span class="blue--text">, Za rasporediti: {{localMoney(useAmount)}}</span>
            <span class="green--text">,  Rasporedjeno: {{localMoney(newSelected)}}</span>
            <span class="pink--text">,  Ostalo: {{localMoney(newRest)}}</span>
          </v-card-title>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="pItems"
            item-key="Line"
            show-select
            class="elevation-1"
          >
            <template v-slot:[`item.itInvoice`]="{ item }">
              <span v-if="item.itInvoice !== undefined && item.itInvoice !== null && item.itInvoice !== 0">{{ parseInt(item.itInvoice) }}</span>
            </template>
            <template v-slot:[`item.invoiceInNumber`]="{ item }">
                <span v-if="item.invoiceInNumber !== undefined && item.invoiceInNumber !== null && item.invoiceInNumber !== 0">{{ parseInt(item.invoiceInNumber) }}</span>
            </template>
            <template v-slot:[`item.invoiceOutNumber`]="{ item }">
                <span v-if="item.invoiceOutNumber !== undefined && item.invoiceOutNumber !== null && item.invoiceOutNumber !== 0">{{ parseInt(item.invoiceOutNumber) }}</span>
            </template>
            <template v-slot:[`item.Debit`]="{ item }">
                <span>{{ localMoney(item.Debit) }}</span>
            </template>
             <template v-slot:[`item.Credit`]="{ item }">
                <span>{{ localMoney(item.Credit) }}</span>
            </template>
             <template v-slot:[`item.CreditDebit`]="{ item }">
                <span>{{ localMoney(item.CreditDebit) }}</span>
            </template>
            <template v-slot:[`item.CreditDebitDok`]="{ item }">
                <span>{{ localMoney(item.CreditDebitDok) }}</span>
            </template>

            <template v-slot:[`item.BookingDate`]="{ item }">
                <span>{{ localDate(item.BookingDate) }}</span>
            </template>
            <template v-slot:[`item.InvoiceDate`]="{ item }">
                <span>{{ localDate(item.InvoiceDate) }}</span>
            </template>
             <template v-slot:[`item.InvoicePaymentDeadlineDate`]="{ item }">
                <span>{{ localDate(item.InvoicePaymentDeadlineDate) }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-row v-if="showSelected">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <mainledgercard3selected :ssitems="ssitems" :key="locid" :opis="opis" :fullDebit="fullDebit" :fullCredit="fullCredit" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import mainledgercard3selected from './MainLedgerCard3Selected'
import dateFormat from '@/snippets/dateFormat'
import moneyFormat from '@/snippets/moneyFormat'
//import {checkPartners} from '../MainLedger/mainledgerHelper'
import { testLastUpdate } from '@/services/TestLastUpdate'
import {parseNum, round, doZeros} from '@/snippets/allSnippets'
import dayjs from 'dayjs'
import { del, h } from 'vue'

export default {
  name: 'mainledgercard3selection',
  props: ['items', 'head', 'debit', 'credit'],
  data () {
    return {
      alert: true,
      documentNameLoc: '',
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      comp: {},
      error: null,
      typeError: null,
      localError: false,
      newUser: {},
      size: 'sm',
      havePath: '',
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      pItems: [],
      mainQuery2: {},
      partner: {},
      partners: [],
      card: {},
      mainQuery: {},
      singleSelect: false,
      selected: [],
      useAmount: 0,
      selectedAmount: 0,
      allSelectedAmount: 0,
      restAmount: 0,
      ssitems: [],
      midItems: [],
      order: 0,
      locid: 1,
      showSelected: false,
      headers: [],
      saldoItems: [],
      exRate: 1,
      opis: '',
      fullDebit: 0,
      fullCredit: 0,
      newSelected: 0,
      newRest: 0
    }
  },
  components: {
    mainledgercard3selected
  },
  async mounted () {

    this.exRate = this.$store.getters.NeedDefExRate
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
     const ureTable = [
        { text: 'Vrsta', align: 'start', value: 'BookName'},
        { text: 'Broj dok.', value: 'itInvoice' },
        { text: 'VEZA', value: 'invoiceInNumber' },
        { text: 'Orig.račun', value: 'InvoiceNumber' },
        { text: 'Datum knjiženja', value: 'BookingDate' },
        { text: 'Datum dokumenta', value: 'InvoiceDate' },
        { text: 'Datum dospijeća', value: 'InvoicePaymentDeadlineDate' },
        { text: 'Opis', value: 'Description', width: '180px' },
        { text: 'Konto', value: 'account4' },
        { text: 'Duguje', value: 'Debit',  align: 'right' },
        { text: 'Potražuje', value: 'Credit',  align: 'right' },
        { text: 'Saldo Dok', value: 'CreditDebitDok',  align: 'right' },
        { text: 'Saldo Ukup.', value: 'CreditDebit',  align: 'right' },
    ]
     const ireTable = [
        { text: 'Vrsta', align: 'start', value: 'BookName'},
        { text: 'Broj dok.', value: 'itInvoice' },
        { text: 'VEZA', value: 'invoiceOutNumber' },
        { text: 'Datum knjiženja', value: 'BookingDate' },
        { text: 'Datum dokumenta', value: 'InvoiceDate' },
        { text: 'Datum dospijeća', value: 'InvoicePaymentDeadlineDate' },
        { text: 'Opis', value: 'Description', width: '180px' },
        { text: 'Konto', value: 'account4' },
        { text: 'Duguje', value: 'Debit',  align: 'right' },
        { text: 'Potražuje', value: 'Credit', align: 'right' },
        { text: 'Saldo Dok', value: 'CreditDebitDok',  align: 'right' },
        { text: 'Saldo Ukup.', value: 'CreditDebit',  align: 'right' },
    ]
    this.headers = this.head.fromAccount && this.head.fromAccount.substr(0,2) === '22' ? [...ureTable] : [...ireTable]
    this.PrintDate = dayjs(new Date()).format('llll')
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    //await checkPartners(0)
    await testLastUpdate()
    // if (this.$store.state.partnerStore) {
    //   this.partners = this.$store.state.partnerStore
    // }
    //this.partners = (await PartnerService.index()).data.partners
    const typePartner = this.head.fromAccount ? this.head.fromAccount.substr(0,2) : ''
    if (typePartner === '12') {
      this.useAmount = this.credit
      this.opis = 'Kupac ukupno:'
    }
    if (typePartner === '22') {
      this.useAmount = this.debit
      this.opis = 'Dobavljač ukupno:'
    }
    const locItems =[]
    this.items.map(itm=> {
      const objUp = {
        newID: typePartner === '12' ? doZeros(round(itm.invoiceOutNumber,0).toString(), round(itm.BusinessYear,0).toString()) : doZeros(round(itm.invoiceInNumber,0).toString(), round(itm.BusinessYear,0).toString())
      }
      const objUp2 = {...itm, ...objUp}
      locItems.push(objUp2)
    })
    //console.log('locItems', locItems)

     const result = [...locItems.reduce((r, o) => {
      const key = o.newID;
      
      const item = r.get(key) || Object.assign({}, o, {
        Debit: 0,
        Credit: 0
      });
      
      item.Debit += o.Debit;
      item.Credit += o.Credit;

      return r.set(key, item);
    }, new Map).values()];
  

    result.map(r => {
      if (round(Math.abs(r.Debit)-Math.abs(r.Credit) , 2) !== 0) {
        this.saldoItems.push(r)
      }
    })
    
    this.Total1 = 0
    this.Total2 = 0
    this.Total3 = 0
    this.Total4 = 0
    let itLine = 0
    let partnerVATID = null

    if (this.saldoItems) {
      this.saldoItems.sort((a,b) => (a.BookingDate > b.BookingDate) ? 1 : ((b.BookingDate > a.BookingDate) ? -1 : 0))
      this.saldoItems.map(item => {
        itLine += 1
        const pDebit = item.totalDebit !== undefined && item.totalDebit !== null ? round((parseFloat(item.totalDebit) / this.exRate),2) : round((parseFloat(item.Debit) / this.exRate),2)
        const pCredit = item.totalCredit !== undefined && item.totalCredit !== null ? round((parseFloat(item.totalCredit) / this.exRate),2) : round((parseFloat(item.Credit) / this.exRate),2)
        this.Total1 += pDebit
        this.Total2 += pCredit
        //this.Total3 += parseFloat(item.DebitForegin)
        //this.Total4 += parseFloat(item.CreditForegin)
        partnerVATID = item.partnerVATID ? item.partnerVATID : null
        let inNr = ''
        if (typeof item.invoiceInNumber === 'number') {
          inNr = parseInt(item.invoiceInNumber)
        }
        if (typeof item.invoiceInNumber !== 'number') {
          inNr = item.invoiceInNumber && item.invoiceInNumber.trim().length > 0 ? item.invoiceInNumber.trim() : ''
        }
        let outNr = ''
        if (typeof item.invoiceOutNumber === 'number') {
          outNr = parseInt(item.invoiceOutNumber)
        }
        if (typeof item.invoiceOutNumber !== 'number') {
          outNr = item.invoiceOutNumber && item.invoiceOutNumber.trim().length > 0 ? item.invoiceOutNumber.trim() : ''
        }

        let itInvoice
        if (item.BookName.substring(0,3) === 'IRA' || item.BookName.substring(0,3) === 'URA') {
          itInvoice = item.BookName.substring(0,3) === 'URA'? inNr : outNr
        }
        if (item.BookName.substring(0,2) === 'PS') {
          itInvoice = parseInt(item.invoiceInNumber) > 0 ? inNr : outNr
        }
        if (item.BookName.substring(0,3) === 'TEM') {
          itInvoice = item.BookID ? parseInt(item.BookID) : ''
        }
         if (item.BookName.substring(0,2) === 'BS') {
          itInvoice = item.BookID ? parseInt(item.BookID) : ''
        }

        const bookName = item.BookName !== 'BS' ? item.BookName : 'Izv.'
        const object2 = {
          pDebit: pDebit,
          pCredit: pCredit, 
          itBookName: itLine + '. ' + bookName,
          CreditDebit: this.Total1 - this.Total2,
          CreditDebitDok: pDebit - pCredit,
          itInvoice: itInvoice,
          locBookName: bookName,
          Line: itLine
        }
        const object3 = {...item, ...object2}
        this.pItems.push(object3)
      })
    }

    this.restAmount = round(this.useAmount - this.allSelectedAmount, 2)
    this.newRest = round(this.useAmount - this.newSelected, 2)
    this.Total5 = this.Total1 - this.Total2
    this.partner = partnerVATID ? this.$store.state.partnerStore.find(partner => (partner.partnerVATID === partnerVATID)) : {}
    this.documentNameLoc = 'Otvorene stavke: ' + this.partner.partnerName +  ', OIB:'+ this.partner.partnerVATID
  },
  computed: {

  },
  methods: {
    print(){
      this.$refs.prnt.print()
    },
    localMoney(moneyTo) {
      return moneyFormat(moneyTo)
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    stariNacin () {
        // zbrojim si slado kartice
        if (this.pItems && this.pItems.length > 0) {
          this.pItems.map(pIt => {
            this.fullDebit += round(pIt.Debit, 2 )
            this.fullCredit += round(pIt.Credit, 2)
          })
        }

        // zbrojim si odabrano i iskoristeno
        if (this.allSelectedAmount <= this.useAmount) {
          if (this.midItems && this.midItems.length > 0) {
            this.allSelectedAmount = 0
            let allDebit = 0
            let allCredit = 0
            // do math
            this.midItems.map(item => {
              // const itemAmount = round(Math.abs(parseNum(item.Debit) - parseNum(item.Credit)), 2)
              
              const dDebit = round(parseNum(item.Debit),2)
              const cCredit = round(parseNum(item.Credit),2)
              const itemAmount = runDebit ? round((cCredit - dDebit),2) : round((dDebit - cCredit), 2) 
              allDebit += round(dDebit,2)
              allCredit += round(cCredit,2)
              allDebit = round(allDebit, 2)
              allCredit = round(allCredit,2)

              //let itemAmount = 0
              // if (runDebit) {
              //   if (dDebit >= 0 && cCredit >=0) {
              //     itemAmount = round((cCredit - dDebit), 2)
              //   }
              //   if (dDebit >= 0 && cCredit < 0) {
              //     itemAmount = round((dDebit + cCredit), 2)
              //   }
              // }
              // if (!runDebit) {
              //   if (dDebit >= 0 && cCredit >=0) {
              //     itemAmount = round((dDebit - cCredit), 2)
              //   }
              //   if (dDebit < 0 && cCredit >= 0) {
              //     itemAmount = round((dDebit + cCredit), 2)
              //   }
              // }
    
              // allSelected += round(itemAmount, 2)
              // allSelected = round(allSelected, 2)

              if (allDebit === 0 && allCredit !== 0) {
                allSelected = allCredit
              }
              if (allDebit !== 0 && allCredit === 0) {
                allSelected = allDebit
              }
              // if buyer or supplier
              if (allDebit !== 0 && allCredit !== 0) {
                allSelected = runDebit ? round((allCredit - allDebit),2) : round((allDebit - allCredit), 2) 
              }
              allSelected = round(allSelected, 2)
              let goToLast = true
              
              if (allSelected <= this.useAmount){
                goToLast = false
                let newItem = {...item}
                // newItem.Debit = cCredit
                // newItem.Credit = dDebit
                newItem.Debit = runDebit ? round((this.useAmount - this.allSelectedAmount),2) : 0 
                newItem.Credit = runDebit ? 0 : round((this.useAmount - this.allSelectedAmount),2)
                this.fullDebit += newItem.Debit
                this.fullCredit += newItem.Credit
                // if (runDebit) {
                //   newItem.Debit = round(itemAmount, 2)
                //   newItem.Credit = 0
                // }
                
                // if (!runDebit) {
                //   newItem.Debit = 0
                //   newItem.Credit = round(itemAmount, 2)
                // }
                //this.allSelectedAmount += round(itemAmount, 2)
                this.allSelectedAmount = round(allSelected, 2)
                saveItems.push(newItem)
              }

              if (goToLast){
                if (round(allSelected - itemAmount, 2) <= this.useAmount) {
                  const haveRest = round(this.useAmount, 2) - round((allSelected - itemAmount), 2)
                  let newItem = {...item}
                  
                  if (runDebit) {
                    newItem.Debit = round(haveRest, 2)
                    newItem.Credit = 0
                    this.fullDebit += round(haveRest, 2)
                  }
                  if (!runDebit) {
                    newItem.Debit = 0
                    newItem.Credit = round(haveRest, 2)
                    this.fullCredit += round(haveRest, 2)
                  }
                  this.allSelectedAmount += round(haveRest, 2)
                  saveItems.push(newItem)
                }
              }

            })
          }
        
          if (saveIt) {
            // this.allSelectedAmount = 0
            // if (saveItems && saveItems.length > 0) {
            //   saveItems.map(detail => {
            //     this.allSelectedAmount += round(Math.abs(detail.Debit - detail.Credit), 2)
            //   })
            // }
            this.allSelectedAmount = round(this.allSelectedAmount, 2)
            this.ssitems = [...saveItems]
            
            this.locid += 1
            this.showSelected = true
            this.restAmount = round(this.useAmount - this.allSelectedAmount, 2)
            this.$store.dispatch('setMainLedgerDetailItems', saveItems)
          }
        } 
    },

    close () {
      this.show = false
    },
    doCalculation(){
        this.fullDebit = 0
        this.fullCredit = 0
        this.midItems.length = 0
        this.allSelectedAmount = 0
        this.newSelected = 0
        let runDebit =  this.head.fromAccount && this.head.fromAccount.substr(0,2) === '22' ? true : false
        let forLastItem = null
        // dodam zadnju odabranu 
        if (this.selected && this.selected.length > 0){

          this.selected.map(item => {
            let addMore = false
            const dDebit = round(parseNum(item.Debit),2)
            const cCredit = round(parseNum(item.Credit),2)
            const itemAmount = runDebit ? round((cCredit - dDebit),2) : round((dDebit - cCredit), 2)
            let haveAdd = 0
            if (itemAmount + this.allSelectedAmount <= this.useAmount ) {
              haveAdd = itemAmount
              addMore = true
            }
            if (this.restAmount > 0 && (itemAmount + this.allSelectedAmount > this.useAmount)) {
              // dodaj samo razliku stavke u odabrane
              haveAdd = round(this.useAmount, 2) - round((this.allSelectedAmount), 2)
              addMore = true
            }
            if (addMore) {
              const newItem = {...item}
              forLastItem = {...item}
              this.order +=1
              this.locid += 1
              newItem.order = this.order
              if (runDebit) {
                newItem.Debit = round(haveAdd, 2)
                newItem.Credit = 0
                this.fullDebit += round(haveAdd, 2)
              }
              if (!runDebit) {
                newItem.Debit = 0
                newItem.Credit = round(haveAdd, 2)
                this.fullCredit += round(haveAdd, 2)
              }
              this.allSelectedAmount += round(haveAdd,2)
              this.newSelected += round(haveAdd,2)
              this.restAmount = round(this.useAmount - this.allSelectedAmount, 2)
              this.newRest = round(this.useAmount - this.newSelected, 2)
              delete newItem.id
              newItem.newKey = this.locid
              this.midItems.push(newItem)
            }
          })
          // ako jos imam preostali iznos (vise je duguje ili potrazuje nego je odabrano onda dodaj jos jedan red)
          if (this.restAmount > 0) {
            this.order +=1
            this.locid += 1
            const haveAdd = this.restAmount
            forLastItem.order = this.order
            if (runDebit) {
              forLastItem.Debit = round(haveAdd, 2)
              forLastItem.Credit = 0
              this.fullDebit += round(haveAdd, 2)
            }
            if (!runDebit) {
              forLastItem.Debit = 0
              forLastItem.Credit = round(haveAdd, 2)
              this.fullCredit += round(haveAdd, 2)
            }
            this.allSelectedAmount += round(haveAdd,2)
            this.restAmount = round(this.useAmount - this.allSelectedAmount, 2)
            
            delete forLastItem.id
            delete forLastItem.itInvoice
            delete forLastItem.InvoiceNumber
            delete forLastItem.invoiceInNumber
            delete forLastItem.invoiceOutNumber
            delete forLastItem.InvoiceDate
            delete forLastItem.InvoicePaymentDeadlineDate
            console.log('forLastItem.InvoiceDate', forLastItem.InvoiceDate)
            
            forLastItem.BookName = this.$store.state.mainledgerActiv.BookName
            forLastItem.Description = this.$store.state.mainledgerActiv.BookName + ' ' + (this.$store.state.mainledgerActiv.BookID ? parseInt(this.$store.state.mainledgerActiv.BookID) : '')
            forLastItem.newKey = this.locid
            this.midItems.push(forLastItem)
          }
          this.showSelected = true
          this.ssitems = [...this.midItems]
          this.$store.dispatch('setMainLedgerDetailItems', this.midItems)
        }
        
    },
  },
  watch: {
    'selected' ()
      {
        if (this.selected && this.selected.length > 0) {
          this.doCalculation()
        }
        let clearIt = false
        if (!this.selected) {
          clearIt = true
        }
        if (this.selected && this.selected.length === 0) {
          clearIt = true
        }
        if (clearIt) {
          this.midItems.length = 0
          this.allSelectedAmount = 0
          this.showSelected = false
          this.ssitems.length = 0
         //this.$store.dispatch('setMainLedgerDetailItems', this.midItems)
        }
    }
 }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
