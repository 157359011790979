<template>
  <v-layout row style="border-bottom:solid 1px;" no-gutters>
      <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="text-left">
          <h4><b><i>{{comp.CompanyNameShort}}</i></b></h4>
          {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}   <br />                
          {{comp.CompanyPostcode}} {{comp.CompanyCity}}<br />
          OIB: {{comp.CompanyVATID}}
      </v-col>
      <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5" class="text-left">
          <span v-if="head.partnerName">
            <h2>{{head.partnerName}}</h2> 
            Konto: {{head.account4}}<br />
            {{head.account4Description}}<br />
          </span>
          <span v-if="!head.partnerName">
            <h2>Konto: {{head.account4}}</h2>
            {{head.account4Description}}<br />
          </span>
       </v-col>
       <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="text-left">
          Datum izvještaja: {{PrintDate}}<br />
          Telefon: {{comp.CompanyPhones}}<br />
          email: {{comp.Companyemail}}<br />
          {{head.cardNr ? 'Kartica:' + head.cardNr : ''}}
      </v-col>
  </v-layout>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'cardhead',
  props: ['head', 'type'],
  data: ()=> ({
    comp: {},
    PrintDate: null,
  }),
  mounted () {
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    this.PrintDate = dayjs().format('DD.MM.YYYY, H:mm')
  },
  methods: {

  },
  computed: {
  },
  watch: {

  },
  components: {

  }

}

</script>