<template>
  <v-container fluid grid-list-md>
    <v-alert type="warning" v-model="havealert" dismissible close-text="Close Alert">
          {{havemssgalert}}
    </v-alert>
    <v-row no-gutters>

      <v-col xs="12" sm="10" md="6" lg="6" ex="6">
        <v-card ref="form">
          <v-card-text>

              <v-row no-gutters>
                <v-flex xs="12" class="py-0 px-2">                
                    <h2>{{lang.SalesPOSAnalysis}} </h2>
                </v-flex>
              </v-row>

              <v-row no-gutters>
                <v-col xs="4" class="py-0 px-2">
                    {{lang.CompanyName}}
                </v-col>
                <v-col xs="8" class="py-0 px-2">
                    <span style="font-weight:bold">{{mainQuery.CompanyNameDatabase}}</span>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col xs="4" class="py-0 px-2">
                    {{lang.BusinessYear}}
                </v-col>
                <v-col xs="8" class="py-0 px-2">
                    <span style="font-weight:bold">{{mainQuery.BusinessYear}}</span>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col xs="4" class="py-0 px-2">
                  {{lang.User}}
                </v-col>
              </v-row>
              <v-divider class="mt-1"></v-divider>
              <br>

          <v-row no-gutters>
              <v-col xs="6" >
                <v-dialog
                  ref="dialog1"
                  persistent
                  v-model="modal1"
                  width="290px"
                  :return-value.sync="startDate"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatDate1"
                      :label="langC.FromDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>

                  <v-date-picker v-model="startDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="modal1 = false">Cancel</v-btn>
                    <v-btn color="primary" @click="$refs.dialog1.save(startDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col xs="4" >
                <v-dialog
                  ref="dialog2"
                  persistent
                  v-model="modal2"
                  width="290px"
                  :return-value.sync="endDate"
                >
                 <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatDate2"
                      :label="langC.ToDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
 
                  <v-date-picker v-model="endDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="modal2 = false">Cancel</v-btn>
                    <v-btn color="primary" @click="$refs.dialog2.save(endDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

          </v-row>           

          <div class="pink darken-2" v-html="error" />

          </v-card-text>
          <v-divider class="mt-1"></v-divider>
          <v-card-actions>
             <v-btn color="success" @click="returnPOS" class="mr-5">Back to POS</v-btn>

            <v-spacer></v-spacer>

            <v-btn
              class="primary"
              @click="fillData">
              {{langC.Analysis}}
            </v-btn>

            <v-progress-circular v-if="this.saving"
                  indeterminate
                  color="green"
            ></v-progress-circular>

          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"
          >
          
          <template v-slot:[`item.documentDate`]="{ item }">
              <span>{{ localDate(item.documentDate) }}</span>
          </template>
          <template v-slot:[`item.documentTime`]="{ item }">
              <span>{{ localTime(item.documentTime) }}</span>
          </template>
          <template v-slot:[`item.documentTotalAmountBrutto`]="{ item }">
              <span>{{ localMoney(item.documentTotalAmountBrutto) }}</span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="getColor(item.status)" dark>{{ localStatus(item.status) }}</v-chip>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <DocumentPOSTotal v-if="items.length > 0 && Object.keys(period).length !== 0 && period.constructor === Object" :key="toSee" :items="items" :period="period" :totalDiscount="totalDiscount" :totalService="totalService" ></DocumentPOSTotal>

  </v-container>
</template>

<script>
import DocumentService from '@/services/DocumentService'
import DocumentDetailService from '@/services/DocumentDetailService'
import DocumentPOSTotal from './DocumentPOSTotal'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dateFormat from '@/snippets/dateFormat'
import moneyFormat from '@/snippets/moneyFormat'
import dayjs from 'dayjs'

export default {
  name: 'documentposlist',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [],
      itemsLoc: [],
      selectIL: {},
      statewhs: false, 
      rules: {
        required: value => !!value || '*'
      },
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      comp: {},
      error: null,
      localError: false,
      size: 'sm',
      privitems: [],
      required: (value) => !!value || 'Required.',
      items: [],
      itemDetails: [],
      mainQuery: {
        CompanyId: 1
      },
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      Total6: 0,
      Total7: 0,
      Total8: 0,
      Total9: 0,
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      startDate: null,
      endDate: null,
      itemID: '',
      itemName: '',
      havemssgalert: '',
      havealert: false,
      period: {},
      totalDiscount : 0,
      totalService: 0,
      toSee: 1,
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
    this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
    this.mainQuery.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''

    this.headers.push(
      {text: this.lang.documentId, value: 'documentId', width: '90px'},
      {text: this.lang.documentDate1, value: 'documentDate', width: '50px'},
      {text: this.lang.documentTime, value: 'documentTime', width: '50px'},
      {text: this.lang.documentTotalAmountBrutto, value: 'documentTotalAmountBrutto', type: 'decimal', width: '100px', align: 'right'},
      {text: this.lang.documentHomeCurrency, value: 'documentHomeCurrency', width: '50px'},
      {text: this.lang.partnerName, value: 'partnerName', width: '250px'},
      {text: this.lang.documentJIR, value: 'documentJIR', width: '250px'},
      {text: this.lang.documentZKI, value: 'documentZKI', width: '250px'},
    )
    this.$store.dispatch('settypePOSPrint', null)

    this.startDate = dayjs().format('YYYY-MM-DD')
    this.endDate = dayjs().format('YYYY-MM-DD')
    await this.fillData()

  },
  computed: {
    formIsValid () {
      return this.mainQuery.CompanyId !== null
    },
    formatDate1() {
      return this.startDate
        ? dateFormat(this.startDate)
        : "";
    },
    formatDate2() {
      return this.endDate
        ? dateFormat(this.endDate)
        : "";
    },
  },
  methods: {
    async fillData(){
        this.havealert = false
        this.mainQuery.documentName = 'POS'
        if (this.startDate) {
          this.mainQuery.docFromDate = dayjs(this.startDate).format('YYYY-MM-DD')
        }
        if (this.endDate) {
          this.mainQuery.docToDate = dayjs(this.endDate).format('YYYY-MM-DD')
        }
        this.Total1 = 0
        this.Total2 = 0
        this.Total3 = 0
        this.Total4 = 0
        this.Total5 = 0
        this.Total6 = 0
        this.totalDiscount = 0
        this.totalService = 0
        this.saving = true
        //this.items = (await DocumentService.documentsalesana(this.mainQuery)).data.document
        await DocumentService.documentsalesana(this.mainQuery)
        .then(data => {
          this.items = data.data.document ? data.data.document : []
          if (this.items.length === 0) {
            this.havealert = true
            this.havemssgalert = 'Za period ' + this.startDate + ' do ' + this.endDate + ' nema racuna kase.'
          }
        })
        .catch((err) => {
          this.items = []
          this.havealert = true
          this.havemssgalert = 'Za period ' + this.startDate + ' do ' + this.endDate + ' nema racuna kase.'
          // eslint-disable-next-line
          console.log(err)
        })

        await DocumentDetailService.salesana(this.mainQuery)
        .then(res => {
          this.itemDetails = res.data.document ? res.data.document : []
          if (this.itemDetails) {
            this.itemDetails.map(item=> {
              this.totalDiscount += parseFloat(item.itemDiscountAmount1)
              if (item.itemService === true) {
                this.totalService += parseFloat(item.itemFullTotal)
              }
            })
          }
        })
        .catch((err) => {
          this.itemDetailss = []
          // eslint-disable-next-line
          console.log(err)
        })
        this.period.startDate = this.startDate
        this.period.endDate = this.endDate
        this.saving = false
        this.toSee += 1
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
    localTime(timeToFormat) {
      return timeToFormat.substr(0,5)
    },
    returnPOS() {
      this.$store.dispatch('setDocumentActivHead', {})
      this.$store.dispatch('setDocumentActivItems', [])
      this.$router.push({name: 'documentpos'})
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async navigateTo (params) {
      try {      
  
        const dbDocument = await DocumentService.show(params.id)
        if (dbDocument) {
          this.$store.dispatch('setDocumentActivHead', dbDocument.data.document)
        } else {
          this.$store.dispatch('setDocumentActivHead', {})
        }
        this.$store.dispatch('setDocumentid', params.id)
        this.$store.dispatch('setDatabaseDocID', params.id)
        if (this.$store.state.companyid) {
          this.mainQuery.CompanyId = this.$store.state.companyid
        }
        if (this.$store.state.businessYear) {
          this.mainQuery.businessYear = this.$store.state.businessYear
        }
        if (this.$store.state.documentActiv) {
          this.mainQuery.documentName = this.$store.state.documentActiv
        }
        if (this.$store.state.databaseDocID) {
          this.mainQuery.databaseDocID = this.$store.state.databaseDocID
        }
        if (this.$store.state.documentSide) {
          this.docSide = this.$store.state.documentSide
        }
        this.items = (await DocumentDetailService.index(this.mainQuery)).data.documentDetails
        if (this.items) {
          this.$store.dispatch('setDocumentActivItems', this.items)
        } else {
          this.$store.dispatch('setDocumentActivItems', [])
        }
        this.$store.dispatch('setprintSmall', false)
        this.$store.dispatch('setprintNoPrice', false)
        this.$router.push({
          name: 'documentposagain'
        })
        
      } catch (err) {
        // eslint-disable-next-line
        console.log('error read from database', err)
      }      
    },
    close () {
      this.show = false
    },
    async documentlist () {
      try {
        this.havePath = this.$store.state.retPath
        this.$store.dispatch('setretPath', '')
        await this.$router.push({
          name: 'home'
        })
        // }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  watch: {

  },
  components: {
    DocumentPOSTotal
  }
}
</script>

<style >

</style>
