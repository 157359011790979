import Api from '@/services/Api'
// import { log } from 'util';

export default {
  index (search) {
    return Api().get('warehouseuser', {
      params: {
        search: search
      }
    })
  },
  show (warehouseuserid) {
    return Api().get(`warehouseuser/${warehouseuserid}`)
  },
  post (newwarehouseuser) {
    return Api().post('warehouseuser', newwarehouseuser)
  },
  put (warehouseuser) {
    return Api().put(`warehouseuser/${warehouseuser.id}`, warehouseuser)
  },
  delete (warehouseuserid) {
    return Api().delete(`warehouseuser/${warehouseuserid}`)
  }
}
