import store from '../store/store'
import Api from '@/services/Api'
import ItemService from '@/services/ItemService'
import PartnerService from '@/services/PartnerService'
import Account4Service from '@/services/Account4Service'

const testLastUpdate = async () => {
  try {
    if (!store.state.dateItems) {
      await store.dispatch('setDateItems', new Date())
    }
    if (!store.state.datePartners) {
      await store.dispatch('setDatePartners', new Date())
    }
    if (!store.state.dateAccounts) {
      await store.dispatch('setDateAccounts', new Date())
    }
    const dateItems = store.state.dateItems
    const datePartners = store.state.datePartners 
    const dateAccounts = store.state.dateAccounts

    const query = {}
    query.CompanyId = store.state.companyid ? store.state.companyid : 0
    const res = await Api().get('lastupdate', {
      params: query
    })
    if (res && res.data && res.data.lastupdates && res.data.lastupdates.length > 0) {
      const haveItem = res.data.lastupdates[0]
      const LastUpdateItems = haveItem.LastUpdateItems ? new Date(haveItem.LastUpdateItems) : null
      const LastUpdatePartners = haveItem.LastUpdatePartners ? new Date(haveItem.LastUpdatePartners) : null
      const LastUpdateAccounts = haveItem.LastUpdateAccounts ? new Date(haveItem.LastUpdateAccounts) : null
      if (LastUpdateItems >= dateItems) {
        // do refresh Items
        console.log('Refresh items')
        await ItemService.index(query).then(res => {
          if (res.data.items) {
            store.dispatch('setActivItems', res.data.items)
            store.dispatch('setDateItems', new Date())
          }
        })
        .catch(err => {
          console.log('Refresh items ERROR !!', err)
        })
      }

      if (LastUpdatePartners >= datePartners) {
        // do refresh Items
        console.log('Refresh partners')
        query.noPageing = 0
        const privateP = "privatePartner" in store.state.user ? store.state.user.privatePartner : true
          const {data} = privateP ? (await PartnerService.index2(query)) : (await PartnerService.index(query))
          if (data && data.partners && data.partners.rows) {
            store.dispatch("setPartners", data.partners.rows)
            store.dispatch('setDatePartners', new Date())
          } else {
            store.dispatch("setPartners", [])
            store.dispatch('setDatePartners', null)
          }
      }
      if (LastUpdateAccounts >= dateAccounts) {
        // do refresh Items
        console.log('Refresh accounts')
        await Account4Service.index(query).then(res => {
          if (res.data.account4s) {
            store.dispatch('setAccount4s', res.data.account4s)
            store.dispatch('setDateAccounts', new Date())
          }
        })
        .catch(err => {
          console.log('Refresh items ERROR !!', err)
        })
      }
    }
    // ako je res.data prazan onda ga zapisi
    if (res && res.data && res.data.lastupdates && res.data.lastupdates.length === 0) {
      console.log('Nemam nist zapisujem ', dateItems, datePartners, dateAccounts)
      // const currDate = new Date()
      const newData  = {}
      newData.CompanyId = store.state.companyid ? store.state.companyid : 0
      newData.CompanyNameDatabase = store.state.companyName ? store.state.companyName : 0
      newData.BusinessYear = store.state.businessYear ? store.state.businessYear : 0
      newData.LastUpdateBy = store.state.user.email ? store.state.user.email : ''
      newData.LastUpdateItems = dateItems
      newData.LastUpdatePartners = datePartners
      newData.LastUpdateAccounts = dateAccounts
      await Api().post('lastupdate', newData)
    }
  } catch (error) {
    return "No result, error " + error
  }
}

const newUpdates = async (type) => {
  try {
    console.log('newUpdates pamtim type ', type)
    const query = {}
    query.CompanyId = store.state.companyid ? store.state.companyid : 0
    const res = await Api().get('lastupdate', {
      params: query
    })
    if (res && res.data && res.data.lastupdates && res.data.lastupdates.length > 0) {
      const haveItem = res.data.lastupdates[0]
      if (type === 1) {
        haveItem.LastUpdateItems = new Date()
      }
      if (type === 2) {
        haveItem.LastUpdatePartners = new Date()
      }
      if (type === 3) {
        haveItem.LastUpdateAccounts = new Date()
      }
      const res2 = await Api().put('lastupdate/' + haveItem.id, haveItem)
      console.log('Updated item ', res2)
    }
  }
  catch (err) {
    return "No result, error " + err
  }
}

export
{ testLastUpdate,
   newUpdates }