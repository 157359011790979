<template>
  <div class="task">
    <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
      <v-card>
        <v-card-title>

          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="documentId"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"
          >

          <template v-slot:[`item.Locked`]="{ item }">
            <span v-if="item.Locked"><v-icon>mdi-check</v-icon></span>
          </template>
          <template v-slot:[`item.BusinessYear`]="{ item }">
              <span>{{ parseInt(item.BusinessYear) }}</span>
          </template>
          <template v-slot:[`item.fromDate`]="{ item }">
              <span>{{ localDate(item.fromDate) }}</span>
          </template>
          <template v-slot:[`item.toDate`]="{ item }">
              <span>{{ localDate(item.toDate) }}</span>
          </template>
          <template v-slot:[`item.LockedDate`]="{ item }">
              <span>{{ localDate(item.LockedDate) }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>
  </div>
</template>

<script>
// import axios from 'axios'
import MainLedgerService from '@/services/MainLedgerService'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'

export default {
  name: 'MainLedgerlist',
  data () {
    return {
      documentName: '',
      documentNameLoc: '',
      docside: 0,
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        MLType: 1
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    if (this.$store.state.businessYear) {
      this.mainQuery.businessYear = this.$store.state.businessYear
    }
    if (this.$store.state.documentSide) {
      this.docSide = this.$store.state.documentSide
      this.mainQuery.MLType = this.$store.state.documentSide
    }
    this.$store.dispatch('seteditItem', 0)
    this.saving = true
    this.items = (await MainLedgerService.indexvat(this.mainQuery)).data.documents
    this.saving = false
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    this.headers.push(
      { text: 'DbId', value: 'id', width: '60px' },
      {
        text: this.langC.Company, align: 'left', sortable: true, value: 'CompanyNameDatabase', width: '60px'
      },
      { text: this.langC.BusinessYear, align: 'left', sortable: true, value: 'BusinessYear', width: '50px' },
      { text: this.langC.FromDate, align: 'left', sortable: true, value: 'fromDate', width: '50px' },
      { text: this.langC.ToDate, align: 'left', sortable: true, value: 'toDate', width: '80px' },
      { text: this.lang.Locked, align: 'center', value: 'Locked', width: '100px' },
      { text: this.lang.LockedDate, align: 'left', sortable: true, value: 'LockedDate', width: '80px' },      
    )
  },
  methods: {
    navigateTo (params) {
      this.$store.dispatch('setMainLedgerActiv', {})
      this.$store.dispatch('setMainLedgerItems', [])
      this.$store.dispatch('setMainLedgerid', params.id)
      this.$store.dispatch('seteditItem', 1)
      this.$router.push({
        name: 'mainledgercreatevat'
      })
    },
    localDate(dateToFormat) {
       return (dateToFormat ? dateFormat(dateToFormat) : null)
      },
    async initAgain () {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentSide) {
        this.docSide = this.$store.state.documentSide
        this.mainQuery.MLType = this.$store.state.documentSide
      }
      this.items = (await MainLedgerService.indexvat(this.mainQuery)).data.documents
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc
    }
  },
  computed: {

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
