<template>
  <div class="task">
  
    <v-card>
       <v-toolbar flat color="white" >
          <v-toolbar-title>{{documentNameLoc}} - stavke</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
          <v-btn text small color="primary" @click="profitcard" class="mr-1">
            {{lang.ProfitCard}}
          </v-btn>
          <v-btn fab dark class="indigo" @click='documentnew'>
              <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="20"
            item-key="id"
            @click:row="navigateTo"
            :mobile-breakpoint="0"
          >
          <template v-slot:[`item.itemService`]="{ item }">
              <span><v-icon >{{item.itemService ? 'mdi-check' : ''}} </v-icon></span>
          </template>
          <template v-slot:[`item.itemPcsPlus`]="{ item }">
              <span v-if="side === 1">{{ localMoney(item.itemPcsPlus) }}</span>
          </template>
          <template v-slot:[`item.itemPcsMinus`]="{ item }">
              <span v-if="side === 2">{{ localMoney(item.itemPcsMinus) }}</span>
          </template>
          <template v-slot:[`item.itemPcsNeutral`]="{ item }">
              <span v-if="side === 3">{{ localMoney(item.itemPcsNeutral) }}</span>
          </template>
          <template v-slot:[`item.itemSuppPrice`]="{ item }">
              <span v-if="side === 1">{{ localMoney(item.itemSuppPrice) }}</span>
          </template>
          <template v-slot:[`item.itemSuppPriceAmount`]="{ item }">
              <span v-if="side === 1">{{ localMoney(item.itemSuppPriceAmount) }}</span>
          </template>
          <template v-slot:[`item.itemMarginAmount`]="{ item }">
              <span v-if="side === 1">{{ localMoney(item.itemMarginAmount) }}</span>
          </template>
          <template v-slot:[`item.itemSuppBaseForePrice`]="{ item }">
              <span v-if="side === 1 && exportDoc">{{ localMoney(item.itemSuppBaseForePrice) }}</span>
          </template>
          <template v-slot:[`item.itemDependablesAmount`]="{ item }">
              <span v-if="side === 1 && exportDoc">{{ localMoney(item.itemDependablesAmount) }}</span>
          </template>
          <template v-slot:[`item.itemPrice`]="{ item }">
              <span>{{ localMoney(item.itemPrice) }}</span>
          </template>
          <template v-slot:[`item.itemBaseAmount`]="{ item }">
              <span>{{ localMoney(item.itemBaseAmount) }}</span>
          </template>
          <template v-slot:[`item.itemBaseAmountNoVAT`]="{ item }">
              <span>{{ localMoney(item.itemBaseAmountNoVAT) }}</span>
          </template>
          <template v-slot:[`item.itemPriceWithVAT`]="{ item }">
              <span>{{ localMoney(item.itemPriceWithVAT) }}</span>
          </template>
          <template v-slot:[`item.itemAmountWithVAT`]="{ item }">
              <span>{{ localMoney(item.itemAmountWithVAT) }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
          <template v-slot:no-data>
            {{ lang.noData }}
          </template>

        </v-data-table>
      </v-card>
    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.profcard">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <DocumentItemsProfitCard />
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import DocumentItemsProfitCard from './DocumentItemsProfitCard'
import moneyFormat from '@/snippets/moneyFormat'

export default {
  name: 'documentdetaillist',
  data () {
    return {
      documentNameLoc: '',
      lang: {},
      langC: {},
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: '',
        databaseDocID: 0
      },
      haveCurrency: '',
      profcard: false,
      exportDoc: false,
      side: 1
    }
  },
  components: {
    DocumentItemsProfitCard
  },
  mounted () {
      this.side = this.$store.state.documentSide
      this.documentNameLoc = this.$store.state.documentActivLoc
      if (this.$store.state.language === 'EN') {
        this.lang = langEn
        this.langC = commEn
      }
      if (this.$store.state.language === 'HR') {
        this.lang = langHr
        this.langC = commHr
      }
      this.exportDoc = this.$store.state.documentActivHead.exportDoc
      this.items = this.$store.state.documentActivItems ? this.$store.state.documentActivItems : []
      if (this.exportDoc === false) {
        this.haveCurrency = this.$store.state.documentActivHead.documentHomeCurrency ? (' ' + this.$store.state.documentActivHead.documentHomeCurrency) : ''
      } else {
        this.haveCurrency = this.$store.state.documentActivHead.documentForeignCurrency ? (' ' + this.$store.state.documentActivHead.documentForeignCurrency) : ''
      }
      if (this.$store.state.documentSide === 1) {
        if (!this.$store.state.documentActivHead.exportDoc) {
          this.headers.push(
            {
              text: this.lang.ItemName,
              align: 'left',
              sortable: true,
              value: 'itemName',
              width: '350px'
            },
            { text: this.lang.ItemID, align: 'left', sortable: true, value: 'itemID', width: '50px' },
            { text: this.lang.ItemUnit, align: 'center', value: 'itemUnit', width: '35px' },
            { text: this.lang.ItemService, align: 'center', value: 'itemService', width: '50px' },
            { text: this.lang.ItemPcsPlus, align: 'right', value: 'itemPcsPlus', width: '100px' },
            { text: this.lang.ItemSuppPrice + this.haveCurrency, align: 'right', value: 'itemSuppPrice', width: '100px' },
            { text: this.lang.ItemSuppPriceAmount + this.haveCurrency, align: 'right', value: 'itemSuppPriceAmount', width: '100px' },
            { text: this.lang.ItemMarginAmount + this.haveCurrency, align: 'right', value: 'itemMarginAmount', width: '100px' },
            { text: this.lang.ItemPrice + this.haveCurrency, align: 'right', value: 'itemPrice', width: '100px' },        
            { text: this.lang.ItemBaseAmount + this.haveCurrency, align: 'right', sortable: true, value: 'itemBaseAmount', width: '100px' },
            { text: this.lang.ItemDiscountPercentage1 + this.haveCurrency, align: 'right', value: 'itemDiscountPercentage1', width: '30px' },
            { text: this.lang.ItemBaseAmountNoVAT + this.haveCurrency, align: 'right', value: 'itemBaseAmountNoVAT', width: '100px' },
            { text: this.lang.ItemVAT1Percentage + this.haveCurrency, align: 'right', sortable: true, value: 'itemVAT1Percentage', width: '30px' },
            { text: this.lang.ItemPriceWithVAT + this.haveCurrency, align: 'right', value: 'itemPriceWithVAT', width: '100px' },
            { text: this.lang.ItemAmountWithVAT + this.haveCurrency, align: 'right', value: 'itemAmountWithVAT', width: '100px' },
            { text: this.lang.id, value: 'id', width: '50px' }
          )
        }
        if (this.$store.state.documentActivHead.exportDoc) {
          this.headers.push(
            {
              text: this.lang.ItemName,
              align: 'left',
              sortable: true,
              value: 'itemName',
              width: '350px'
            },
            { text: this.lang.ItemID, align: 'left', sortable: true, value: 'itemID', width: '50px' },
            { text: this.lang.ItemUnit, align: 'center', value: 'itemUnit', width: '35px' },
            { text: this.lang.ItemService, align: 'center', value: 'itemService', width: '50px' },
            { text: this.lang.ItemPcsPlus, align: 'right', value: 'itemPcsPlus', width: '100px' },
            { text: this.lang.itemSuppBaseForePrice + this.haveCurrency, align: 'center', value: 'itemSuppBaseForePrice', width: '100px' },
            { text: this.lang.ItemExRate, align: 'center', value: 'ItemExRate', width: '100px' },
            { text: this.lang.itemDependablesAmount, align: 'center', value: 'itemDependablesAmount', width: '100px' },
            { text: this.lang.ItemSuppPrice + 'Kn', align: 'center', value: 'itemSuppPrice', width: '100px' },
            { text: this.lang.ItemSuppPriceAmount + 'Kn', align: 'right', value: 'itemSuppPriceAmount', width: '100px' },
            { text: this.lang.ItemMarginAmount + 'Kn', align: 'right', value: 'itemMarginAmount', width: '100px' },
            { text: this.lang.ItemPrice + 'Kn', align: 'right', value: 'itemPrice', width: '100px' },        
            { text: this.lang.ItemBaseAmount + 'Kn', align: 'right', sortable: true, value: 'itemBaseAmount', width: '100px' },
            { text: this.lang.ItemDiscountPercentage1 + 'Kn', align: 'right', value: 'itemDiscountPercentage1', width: '30px' },
            { text: this.lang.ItemBaseAmountNoVAT + 'Kn', align: 'right', value: 'itemBaseAmountNoVAT', width: '100px' },
            { text: this.lang.ItemVAT1Percentage + 'Kn', align: 'right', sortable: true, value: 'itemVAT1Percentage', width: '30px' },
            { text: this.lang.ItemPriceWithVAT + 'Kn', align: 'right', value: 'itemPriceWithVAT', width: '100px' },
            { text: this.lang.ItemAmountWithVAT + 'Kn', align: 'right', value: 'itemAmountWithVAT', width: '100px' },
            { text: this.lang.id, value: 'id', width: '50px' }
          )
        }
      }
      if (this.$store.state.documentSide === 2) {
       if (!this.$store.state.documentActivHead.exportDoc) {
          this.headers.push(
            {
              text: this.lang.ItemName,
              align: 'left',
              sortable: true,
              value: 'itemName',
              width: '350px'
            },
            { text: this.lang.ItemID, align: 'left', sortable: true, value: 'itemID', width: '50px' },
            { text: this.lang.ItemUnit, align: 'center', value: 'itemUnit', width: '35px' },
            { text: this.lang.ItemService, align: 'center', value: 'itemService', width: '50px' },
            { text: this.lang.ItemPcsMinus, align: 'right', value: 'itemPcsMinus', width: '100px' },
            { text: this.lang.ItemPrice + this.haveCurrency, align: 'right', value: 'itemPrice', width: '100px' },        
            { text: this.lang.ItemBaseAmount + this.haveCurrency, align: 'right', sortable: true, value: 'itemBaseAmount', width: '100px' },
            { text: this.lang.ItemDiscountPercentage1, align: 'right', value: 'itemDiscountPercentage1', width: '30px' },
            { text: this.lang.ItemBaseAmountNoVAT + this.haveCurrency, align: 'right', value: 'itemBaseAmountNoVAT', width: '100px' },
            { text: this.lang.ItemVAT1Percentage, align: 'right', sortable: true, value: 'itemVAT1Percentage', width: '30px' },
            { text: this.lang.ItemPriceWithVAT + this.haveCurrency, align: 'right', value: 'itemPriceWithVAT', width: '100px' },
            { text: this.lang.ItemAmountWithVAT + this.haveCurrency, align: 'right', value: 'itemAmountWithVAT', width: '100px' },
            { text: this.lang.id, value: 'id', width: '50px' }
          )
        } else {
          this.headers.push(
            {
              text: this.lang.ItemName,
              align: 'left',
              sortable: true,
              value: 'itemName',
              width: '350px'
            },
            { text: this.lang.ItemID, align: 'left', sortable: true, value: 'itemID', width: '50px' },
            { text: this.lang.ItemUnit, align: 'center', value: 'itemUnit', width: '35px' },
            { text: this.lang.ItemService, align: 'center', value: 'itemService', width: '50px' },
            { text: this.lang.ItemPcsMinus, align: 'right', value: 'itemPcsMinus', width: '100px' },
            { text: this.lang.ItemPrice + this.haveCurrency, align: 'right', value: 'itemForePrice', width: '100px' },        
            { text: this.lang.ItemBaseAmount + this.haveCurrency, align: 'right', sortable: true, value: 'itemBaseForeAmount', width: '100px' },
            { text: this.lang.ItemDiscountPercentage1, align: 'right', value: 'itemDiscountPercentage1', width: '30px' },
            { text: this.lang.ItemBaseAmountNoVAT + this.haveCurrency, align: 'right', value: 'itemBaseForeAmountNoVAT', width: '100px' },
            { text: this.lang.ItemVAT1Percentage, align: 'right', sortable: true, value: 'itemVAT1Percentage', width: '30px' },
            { text: this.lang.ItemPriceWithVAT + this.haveCurrency, align: 'right', value: 'itemPriceWithVAT', width: '100px' },
            { text: this.lang.ItemAmountWithVAT + this.haveCurrency, align: 'right', value: 'itemForeAmountWithVAT', width: '100px' },
            { text: this.lang.id, value: 'id', width: '50px' }
          )
        }  
      }
      if (this.$store.state.documentSide === 3) {
        if (!this.$store.state.documentActivHead.exportDoc) {
          this.headers.push(
            {
              text: this.lang.ItemName,
              align: 'left',
              sortable: true,
              value: 'itemName',
              width: '350px'
            },
            { text: this.lang.ItemID, align: 'left', sortable: true, value: 'itemID', width: '50px' },
            { text: this.lang.ItemUnit, align: 'center', value: 'itemUnit', width: '35px' },
            { text: this.lang.ItemService, align: 'center', value: 'itemService', width: '50px' },
            { text: this.lang.ItemPcsNeutral, align: 'right', value: 'itemPcsNeutral', width: '100px' },
            { text: this.lang.ItemPrice + this.haveCurrency, align: 'right', value: 'itemPrice', width: '100px' },        
            { text: this.lang.ItemBaseAmount + this.haveCurrency, align: 'right', sortable: true, value: 'itemBaseAmount', width: '100px' },
            { text: this.lang.ItemDiscountPercentage1, align: 'right', value: 'itemDiscountPercentage1', width: '30px' },
            { text: this.lang.ItemBaseAmountNoVAT + this.haveCurrency, align: 'right', value: 'itemBaseAmountNoVAT', width: '100px' },
            { text: this.lang.ItemVAT1Percentage, align: 'right', sortable: true, value: 'itemVAT1Percentage', width: '30px' },
            { text: this.lang.ItemPriceWithVAT + this.haveCurrency, align: 'right', value: 'itemPriceWithVAT', width: '100px' },
            { text: this.lang.ItemAmountWithVAT + this.haveCurrency, align: 'right', value: 'itemAmountWithVAT', width: '100px' },
            { text: this.lang.id, value: 'id', width: '50px' }
          )
        } else {
          this.headers.push(
            {
              text: this.lang.ItemName,
              align: 'left',
              sortable: true,
              value: 'itemName',
              width: '350px'
            },
            { text: this.lang.ItemID, align: 'left', sortable: true, value: 'itemID', width: '50px' },
            { text: this.lang.ItemUnit, align: 'center', value: 'itemUnit', width: '35px' },
            { text: this.lang.ItemService, align: 'center', value: 'itemService', width: '50px' },
            { text: this.lang.ItemPcsNeutral, align: 'right', value: 'itemPcsNeutral', width: '100px' },
            { text: this.lang.ItemPrice + this.haveCurrency, align: 'right', value: 'itemForePrice', width: '100px' },        
            { text: this.lang.ItemBaseAmount + this.haveCurrency, align: 'right', sortable: true, value: 'itemBaseForeAmount', width: '100px' },
            { text: this.lang.ItemDiscountPercentage1, align: 'right', value: 'itemDiscountPercentage1', width: '30px' },
            { text: this.lang.ItemBaseAmountNoVAT + this.haveCurrency, align: 'right', value: 'itemBaseForeAmountNoVAT', width: '100px' },
            { text: this.lang.ItemVAT1Percentage, align: 'right', sortable: true, value: 'itemVAT1Percentage', width: '30px' },
            { text: this.lang.ItemAmountWithVAT + this.haveCurrency, align: 'right', value: 'itemForeAmountWithVAT', width: '100px' },
            { text: this.lang.id, value: 'id', width: '50px' }
          )
        }  

      }
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    profitcard() {
      this.profcard = !this.profcard
    },
    async navigateTo (item) {
      this.$store.dispatch('seteditItem', 1)
      this.$store.dispatch('setnewItem', 0)
      if (item.id) {
        this.$store.dispatch('setDocumentDetailid', item.id)
        await this.$router.push({
          name: 'documentdetailcreate2'
        })
      } else {
        confirm(this.lang.Error1)
      }
    },
    async documentnew () {
      try {
        this.$store.dispatch('seteditItem', 0)
        this.$store.dispatch('setnewItem', 1)
        this.$store.dispatch('setDocumentDetailid', 0)
        await this.$router.push({
          name: 'documentdetailcreate2'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  computed: {

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
