<template>
  <v-container>
    <v-row no-gutters justify="center" class="pa-0">
      <v-col cols="12" sm="8" md="8" xs="8" lg="6" xl="6">
        <v-file-input v-model="somefile" single label="File input"></v-file-input>
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
        <v-btn
          class="primary mr-1"
          @click="onSubmit">
          Upload
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import MainLedgerService from '@/services/MainLedgerService'

//import axios from 'axios'
export default {
  name: 'mainledgerupload',
  props: ['mainledger'],
  data() {
    return {
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      somefile: null,
      errors: null,
      data: null,
      attachment : { name : null,file: null }
    }
  },
  methods: {
    fileSkUpload(event) {
        let files = event.target.files;
        if (files.length) this.fileSk = files[0];
    },
    openFileDialog() {
        document.getElementById('file-upload').click();
    },
    onFileChange(e) {
        var self = this;
        var files = e.target.files || e.dataTransfer.files;       
        if(files.length > 0){
            for(var i = 0; i< files.length; i++){
                self.formData.append("file", files[i], files[i].name);
            }
        }   
    },
    uploadFile() {
        // var self = this; 
        // Api().post('/bookin/invoice', self.formData).then(function (response) {
        //   // eslint-disable-next-line
        //     console.log(response);
        // }).catch(function (error) {
        //   // eslint-disable-next-line
        //     console.log(error);
        // });
    },
    onSubmit() {
        const newId = this.getRndInteger(100000, 900000)
        let fd = new FormData()
        fd.append('companyId', this.$store.state.companyid)
        fd.append('newId', newId)
        fd.append('originalName', this.somefile.name)
        fd.append('typeDoc', 'BI')
        fd.append('myFile', this.somefile, this.somefile.name)
        Api().post('bookin/invoice', fd).then(response => {
            this.data = response.data
             if (this.data) {
                this.mainledger.MLFree2 = this.data.originalname ? this.data.originalname : ''
                this.mainledger.MLFree3 = this.data.filename ? this.data.filename : ''
                this.mainledger.MLFree4 = this.data.destination ? this.data.destination : ''
                this.mainledger.MLFree5 = this.data.path ? this.data.path : ''
                this.updateMl()
            }
        }).catch(error => {
            this.text = 'Error ' + error
            this.color = 'red'
            this.snackbar = true
            if (error.response.status === 422) {
                this.errors = error.response.data.errors || {};
            }
        });
    },
    async updateMl() {
      try {
        await MainLedgerService.put(this.mainledger).then(res => {
          this.text = 'Skenirani dokument je snimljen' + res.data.message
          this.color = 'green'
          this.snackbar = true
        }).catch(error => {
          this.text = 'Error ' + error
          this.color = 'red'
          this.snackbar = true
        })         
      } catch (error) {
        this.text = 'Error ' + error
        this.color = 'red'
        this.snackbar = true
      }
    },
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
  },
}
</script>