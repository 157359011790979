<template>
  <div>
    <p class="headline text--primary">
      {{lang.FinancialData}}
    </p>

    <v-divider></v-divider>

      <v-row no-gutters>
        <v-col cols="8">
            {{lang.documentTotalAmountNetto}} {{document.documentHomeCurrency}}
        </v-col>
        <v-col cols="4">
          <div class="text-right">
            {{document.documentTotalAmountNetto}}
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="8">
            {{lang.documentTotalAmountVAT}} {{document.documentHomeCurrency}}
        </v-col>
        <v-col cols="4">
          <div class="text-right">
            {{document.documentTotalAmountVAT}}
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="8">
            {{lang.documentTotalAmountDirectD1}} {{document.documentHomeCurrency}}
        </v-col>
        <v-col cols="4">
          <div class="text-right">
            {{document.documentTotalAmountDirectD1}}
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="8">
            {{lang.documentTotalAmountBrutto}} {{document.documentHomeCurrency}}
        </v-col>
        <v-col cols="4">
          <div class="text-right font-weight-bold">
            {{document.documentTotalAmountBrutto}}
          </div>
        </v-col>
      </v-row>            

    <v-divider></v-divider>

       <v-row no-gutters>
        <v-col cols="8">
            {{lang.newdocumentTotalAmountNetto}} {{document.documentHomeCurrency}}
        </v-col>
        <v-col cols="4">
          <div class="text-right">
            {{document.newBaseAmount}}
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="8">
            {{lang.newdocumentTotalAmountVAT}} {{document.documentHomeCurrency}}
        </v-col>
        <v-col cols="4">
          <div class="text-right">
            {{document.newVAT1Amount}}
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="8">
            {{lang.newdocumentTotalAmountDirectD1}} {{document.documentHomeCurrency}}
        </v-col>
        <v-col cols="4">
          <div class="text-right">
            {{document.newDirectDuty1}}
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="8">
            {{lang.newdocumentTotalAmountBrutto}} {{document.documentHomeCurrency}}
        </v-col>
        <v-col cols="4">
          <div class="text-right font-weight-bold">
            {{document.newFullTotal}}
          </div>
        </v-col>
      </v-row> 

    <v-divider></v-divider>

       <v-row no-gutters>
        <v-col cols="8">
          {{lang.diffNeto}}
        </v-col>
        <v-col cols="4">
          <div class="text-right">
            {{localMoney(document.newBaseAmount - document.documentTotalAmountNetto)}}
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="8">
          {{lang.diffVAT}}
        </v-col>
        <v-col cols="4">
          <div class="text-right">
            {{localMoney(document.newVAT1Amount - document.documentTotalAmountVAT)}}
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="8">
          {{lang.diffDD}}
        </v-col>
        <v-col cols="4">
          <div class="text-right">
            {{localMoney(document.newDirectDuty1 - document.documentTotalAmountDirectD1)}}
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="8">
          {{lang.diffBrutto}}
        </v-col>
        <v-col cols="4">
          <div class="text-right font-weight-bold">
            {{localMoney(document.newFullTotal - document.documentTotalAmountBrutto)}}
          </div>
        </v-col>
      </v-row> 

  </div>
</template>

<script>
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import { mapGetters } from 'vuex'
import moneyFormat from '@/snippets/moneyFormat'

export default {
  data() {
    return {
      document: {},
      lang: {},
      langC: {},
      documentNameLoc: '',
      docSide: 3
    }
  },
  mounted() {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.documentActivHead) {
      this.document = this.$store.state.documentActivHead
      this.documentNameLoc = this.$store.state.documentActivLoc
    }
     if (this.$store.state.documentSide) {
        this.docSide = this.$store.state.documentSide
      }
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
  },
  computed: {
    ...mapGetters({
      mySum: 'NeedDocumentActivHead'
    }),
  },
   watch: {
    'mySum' () {
      this.document = this.$store.state.documentActivHead
    },
  },
}
</script>

<style>

</style>