<template>
  <v-layout justify-center>
    <v-flex xs12 sm10 md8 lg6>
      <v-card ref="form">
        <v-card-text>

            <v-layout row >
              <v-flex xs12 class="py-0 px-2">                
                 <span v-if="this.$store.state.language == 'EN'">
                  <h2>New Task Create</h2>
                  </span>
                  <span v-if="this.$store.state.language == 'HR'">
                  <h2>Kreiraj novi zadatak</h2>
                  </span>
              </v-flex>
            </v-layout>

            <v-layout row >
              <v-flex xs4 class="py-0 px-2">                
                 <span v-if="this.$store.state.language == 'EN'">
                  Company:
                  </span>
                  <span v-if="this.$store.state.language == 'HR'">
                  Firma:
                  </span>
              </v-flex>
              <v-flex xs8 class="py-0 px-2">
                  <span style="font-weight:bold">{{task.CompanyNameDatabase}}, Id:{{task.CompanyId}}</span>
              </v-flex>
            </v-layout>

              <v-layout row >
              <v-flex xs4 class="py-0 px-2">
                 <span v-if="this.$store.state.language == 'EN'">
                  Business Year:
                  </span>
                  <span v-if="this.$store.state.language == 'HR'">
                  Poslovna godina:
                  </span>                  
              </v-flex>
              <v-flex xs8 class="py-0 px-2">
                  <span style="font-weight:bold">{{task.BusinessYear}}</span>
              </v-flex>
            </v-layout>

            <v-layout row >
              <v-flex xs4 class="py-0 px-2">
                 <span v-if="this.$store.state.language == 'EN'">
                  User:
                  </span>
                  <span v-if="this.$store.state.language == 'HR'">
                  Korisnik:
                  </span>                  
              </v-flex>
              <v-flex xs8 class="py-0 px-2">
                  <span style="font-weight:bold">{{task.First}} {{task.Last}}, {{task.email}}</span>
              </v-flex>
            </v-layout>

            <v-layout row >
              <v-flex xs4 class="py-0 px-2">
                 <span v-if="this.$store.state.language == 'EN'">
                  Date:
                  </span>
                  <span v-if="this.$store.state.language == 'HR'">
                  Datum:
                  </span>                  
              </v-flex>
              <v-flex xs8 class="py-0 px-2">
                  <span style="font-weight:bold">{{task.taskOpenDate}}</span>
              </v-flex>
            </v-layout>  
            <v-divider></v-divider>


            <v-layout row >
              <v-flex xs4 class="py-0 px-2">
                 <span v-if="this.$store.state.language == 'EN'">
                  Employee:
                  </span>
                  <span v-if="this.$store.state.language == 'HR'">
                  Zaposlenik:
                  </span>                  
              </v-flex>
              <v-flex xs8 class="py-0 px-2">
                  <span style="font-weight:bold">{{task.Employeehashtag}}, {{task.Employeeemail}}, {{task.EmployeeId}}</span>
              </v-flex>
            </v-layout>

            <br>

        <v-layout row v-if="this.$store.state.language == 'EN'">
            <v-flex xs11 sm5>
              <v-dialog
                ref="dialog1"
                persistent
                v-model="modal1"
                lazy
                full-width
                width="290px"
                :return-value.sync="task.taskForDate"
              >
                <v-text-field
                  slot="activator"
                  label="Task for date"
                  v-model="task.taskForDate"
                  prepend-icon="mdi-calendar"
                ></v-text-field>
                <v-date-picker v-model="task.taskForDate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                  <v-btn flat color="primary" @click="$refs.dialog1.save(task.taskForDate)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-flex>
           </v-layout>           

          <v-layout row v-if="this.$store.state.language == 'HR'">
            <v-flex xs11 sm5>
              <v-dialog
                ref="dialog1"
                persistent
                v-model="modal1"
                lazy
                full-width
                width="290px"
                :return-value.sync="task.taskForDate"
              >
                <v-text-field
                  slot="activator"
                  label="Zadatak za datum"
                  v-model="task.taskForDate"
                  prepend-icon="mdi-calendar"
                ></v-text-field>
                <v-date-picker v-model="task.taskForDate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                  <v-btn flat color="primary" @click="$refs.dialog1.save(task.taskForDate)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-flex>
           </v-layout>  


        <v-layout row v-if="this.$store.state.language == 'EN'">
            <v-flex xs11 sm5>
              <v-dialog
                ref="dialog2"
                persistent
                v-model="modal2"
                lazy
                full-width
                width="290px"
                :return-value.sync="task.taskAlarmDate"
              >
                <v-text-field
                  slot="activator"
                  label="Task Alarm Date"
                  v-model="task.taskAlarmDate"
                  prepend-icon="mdi-calendar"
                ></v-text-field>
                <v-date-picker v-model="task.taskAlarmDate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                  <v-btn flat color="primary" @click="$refs.dialog2.save(task.taskAlarmDate)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-flex>
           </v-layout>           

          <v-layout row v-if="this.$store.state.language == 'HR'">
            <v-flex xs11 sm5>
              <v-dialog
                ref="dialog2"
                persistent
                v-model="modal2"
                lazy
                full-width
                width="290px"
                :return-value.sync="task.taskAlarmDate"
              >
                <v-text-field
                  slot="activator"
                  label="Datum alarma"
                  v-model="task.taskAlarmDate"
                  prepend-icon="mdi-calendar"
                ></v-text-field>
                <v-date-picker v-model="task.taskAlarmDate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                  <v-btn flat color="primary" @click="$refs.dialog2.save(task.taskAlarmDate)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-flex>
           </v-layout>          
 
            <br>
              <v-layout row >
                <v-flex xs4 class="py-4 px-2">
                 <span v-if="this.$store.state.language == 'EN'">
                  Partner Id:
                  </span>
                  <span v-if="this.$store.state.language == 'HR'">
                  Partner:
                  </span>                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                  <v-select
                    v-bind:items="partners"
                    v-model="selectPa"
                    label="Select partner"
                    single-line
                    item-text="partnerName"
                    item-value="id"
                    return-object
                    persistent-hint
                    autocomplete
                  ></v-select>
                </v-flex>
              </v-layout>

              <v-layout row >
                <v-flex xs4 class="py-4 px-2">
                 <span v-if="this.$store.state.language == 'EN'">
                  User Id Attention:
                  </span>
                  <span v-if="this.$store.state.language == 'HR'">
                  Zadatak za Korisnika:
                  </span>                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                  <v-select
                    v-bind:items="users"
                    v-model="selectUs"
                    label="Select user"
                    single-line
                    item-text="email"
                    item-value="id"
                    return-object
                    persistent-hint
                    autocomplete
                  ></v-select>
                </v-flex>
              </v-layout>

              <v-layout row >
                <v-flex xs4 class="py-4 px-2">
                 <span v-if="this.$store.state.language == 'EN'">
                  Task Type:
                  </span>
                  <span v-if="this.$store.state.language == 'HR'">
                  Zadatak vrsta:
                  </span>
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                  <v-select
                    v-bind:items="tasktypes"
                    v-model="selectTT"
                    label="Select task type"
                    single-line
                    item-text="taskTypeDescription"
                    item-value="id"
                    return-object
                    persistent-hint
                  ></v-select>
                </v-flex>
              </v-layout>


              <v-layout row >
                <v-flex xs4 class="py-4 px-2">
                 <span v-if="this.$store.state.language == 'EN'">
                  Task Department:
                  </span>
                  <span v-if="this.$store.state.language == 'HR'">
                  Zadatak odjel:
                  </span>                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                  <v-select
                    v-bind:items="taskdepartments"
                    v-model="selectTD"
                    label="Select task department"
                    single-line
                    item-text="taskDepartmentDescription"
                    item-value="id"
                    return-object
                    persistent-hint
                  ></v-select>
                </v-flex>
              </v-layout>              


              <v-layout row>
                <v-flex xs4 class="py-4 px-2">
                  
                 <span v-if="this.$store.state.language == 'EN'">
                  Task Short Description *:
                  </span>
                  <span v-if="this.$store.state.language == 'HR'">
                  Zadatak kratki opis:
                  </span>                  
                </v-flex>
                <v-flex xs8>
                  <v-text-field
                      label="Short description"
                      required
                      :rules="[required]"
                      v-model="task.taskShortDescription"
                    ></v-text-field>    
                </v-flex>
              </v-layout>

             <v-layout row>
                <v-flex xs4 class="py-4 px-2">
                  
                 <span v-if="this.$store.state.language == 'EN'">
                  Task Detail Description:
                  </span>
                  <span v-if="this.$store.state.language == 'HR'">
                  Zadatak detaljan opis:
                  </span>                  
                </v-flex>
                <v-flex xs8>
                  <v-text-field
                      label="Detail description"
                      v-model="task.taskDetailDescription"
                      multi-line
                    ></v-text-field>    
                </v-flex>
              </v-layout>

             <v-checkbox
               v-model="task.taskClosed"
               label="Task Closed"
               v-if="this.$store.state.language == 'EN'"
             ></v-checkbox>

             <v-checkbox
               v-model="task.taskClosed"
               label="Zadatak je obavljen:"
               v-if="this.$store.state.language == 'HR'"
             ></v-checkbox>


              <div class="pink darken-2" v-html="error" />

        </v-card-text>
        <v-divider class="mt-1"></v-divider>
        <v-card-actions>

          <v-btn
            color="green"
            @click="tasklist">
            <span v-if="this.$store.state.language == 'EN'">
            Back
            </span>
            <span v-if="this.$store.state.language == 'HR'">
            Natrag
            </span>  
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            class="primary"
             :disabled="!formIsValid"
            @click="register">
            <span v-if="this.$store.state.language == 'EN'">
            Register
            </span>
            <span v-if="this.$store.state.language == 'HR'">
            Zapamti
            </span>  
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>

</template>

<script>
import dayjs from 'dayjs'
import TaskTypeService from '@/services/TaskTypeService'
import TaskDepartmentService from '@/services/TaskDepartmentService'
import UserService from '@/services/UserService'
import TaskService from '@/services/TaskService'
import {checkPartners} from '../MainLedger/mainledgerHelper'

export default {
  name: 'taskcreateemployee',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      menu: false,
      menu2: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      dialog1: false,
      dialog2: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      task: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        First: '',
        Last: '',
        EmployeeId: 0,
        Employeeemail: '',
        Employeehashtag: '',
        partnerId: 0,
        partnerName: '',
        partnerNameShort: '',
        taskTypeID: 0,
        taskTypeDescription: '',
        taskDepartmentID: 0,
        taskDepartmentDescription: '',
        taskShortDescription: '',
        taskDetailDescription: '',
        taskOpenDate: null,
        taskOpenTime: null,
        taskForDate: null,
        taskForTime: null,
        taskAlarmDate: null,
        taskAlarmTime: null,
        taskFor1UserId: 0,
        taskFor1Useremail: '',
        taskFor2UserId: 0,
        taskFor2Useremail: '',
        taskFor3UserId: 0,
        taskFor3Useremail: '',
        taskFor4UserId: 0,
        taskFor4Useremail: '',
        taskFor5UserId: 0,
        taskFor5Useremail: '',
        taskClosedDate: null,
        taskClosedTime: null,
        taskClosedUserId: 0,
        taskClosed: false,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: '',
        LastUpdate: null
      },
      temptask: {},
      task2: {},
      error: null,
      localError: false,
      newUser: {},
      confpassword: '',
      size: 'sm',
      privitems: [
        { text: '1 max privileges', value: 1 },
        { text: '2 medium to max privileges', value: 2 },
        { text: '3 medium privileges', value: 3 },
        { text: '4 low to midium privileges', value: 4 },
        { text: '5 low privileges', value: 5 }
      ],
      required: (value) => !!value || 'Required.',
      partners: [],
      employes: [],
      tasktypes: [],
      taskdepartments: [],
      users: [],
      CompanyId: 0,
      selectPa: {},
      selectEm: {},
      selectTT: {},
      selectTD: {},
      selectUs: {},
      mainQuery: {
        CompanyId: 1
      }
    }
  },
  components: {
  },
  async mounted () {
    // need five arrays with partners, employes, tasktype, taskdepartment, users
    if (this.$store.state.companyid) {
      this.CompanyId = await this.$store.state.companyid
      this.mainQuery.CompanyId = await this.$store.state.companyid
    }
    if (this.CompanyId > 0) {
      await checkPartners(0)
      if (this.$store.state.partnerStore) {
        this.partners = this.$store.state.partnerStore
      }
      //this.partners = (await PartnerService.index()).data.partners
      this.tasktypes = (await TaskTypeService.choose(this.mainQuery)).data.taskTypes
      this.taskdepartments = (await TaskDepartmentService.choose(this.mainQuery)).data.taskDepartments
      this.users = (await UserService.choose(this.mainQuery)).data.users
      // console.log(this.partners)
      // console.log(this.tasktypes)
      // console.log(this.taskdepartments)
      // console.log(this.users)
    }
    if (this.$store.state.user.id) {
      this.task.UserId = this.$store.state.user.id
    }
    if (this.$store.state.user.email) {
      this.task.email = this.$store.state.user.email
    }
    if (this.$store.state.user.First) {
      this.task.First = this.$store.state.user.First
    }
    if (this.$store.state.user.Last) {
      this.task.Last = this.$store.state.user.Last
    }
    if (this.$store.state.companyid) {
      this.task.CompanyId = this.$store.state.companyid
    }
    if (this.$store.state.companyName) {
      this.task.CompanyNameDatabase = this.$store.state.companyName
    }
    if (this.$store.state.busniessYear) {
      this.task.BusinessYear = this.$store.state.busniessYear
    }
    if (this.$store.state.employeeActiv) {
      this.task.EmployeeId = this.$store.state.employeeActiv.id
      this.task.Employeeemail = this.$store.state.employeeActiv.eemail
      this.task.Employeehashtag = this.$store.state.employeeActiv.hashtag
    }
    let locDate1 = new Date()
    locDate1.getDate()
    locDate1.getMonth() + 1
    locDate1.getFullYear()
    this.task.taskOpenDate = dayjs().format('YYYY-MM-DD')
  },
  computed: {
    formIsValid () {
      return this.task.email !== '' &&
        this.task.First !== '' &&
        this.task.Last !== '' &&
        this.task.activ !== ''
    }
  },
  methods: {
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async register () {
      try {
        // write new task to database
        this.localError = false
        if (this.selectPa.id) {
          this.task.partnerId = this.selectPa.id
        }
        if (this.selectPa.partnerName) {
          this.task.partnerName = this.selectPa.partnerName
        }
        if (this.selectTT.id) {
          this.task.taskTypeID = this.selectTT.id
        }
        if (this.selectTT.taskTypeDescription) {
          this.task.taskTypeDescription = this.selectTT.taskTypeDescription
        }
        if (this.selectTD.id) {
          this.task.taskDepartmentID = this.selectTD.id
        }
        if (this.selectTD.taskDepartmentDescription) {
          this.task.taskDepartmentDescription = this.selectTD.taskDepartmentDescription
        }
        if (this.selectUs.id) {
          this.task.taskFor1UserId = this.selectUs.id
          this.task2 = Object.assign({}, this.task)
          this.task2.UserId = this.selectUs.id
          this.task2.First = this.selectUs.First
          this.task2.Last = this.selectUs.Last
          this.task2.For5UserUserId = this.$store.state.user.id
        }
        if (this.selectUs.email) {
          this.task.taskFor1Useremail = this.selectUs.email
          this.task2.email = this.selectUs.email
          this.task2.taskFor5Useremail = this.$store.state.user.email
        }
        await TaskService.post(this.task)
        if (this.task2.UserId) {
          await TaskService.post(this.task2)
        }

        this.$router.push({
          name: 'employeeview'
        })
      } catch (error) {
        this.error = 'Greska u konekciji na bazu'
      }
    },
    close () {
      this.show = false
    },
    async tasklist () {
      try {
        await this.$router.push({
          name: 'employeeview'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
