<template>
  <div>
      
      <v-row no-gutters >
        <v-col cols="9">
          <p class="headline text--primary">
            {{document.partnerName}}
          </p>
        </v-col>
        <v-col cols="3">
            <v-btn text color="blue" @click="showDialog" class="mr-1">
             Detaljno
            </v-btn>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12">
            <span >{{document.partnerStreet}} {{document.partnerBuildingNameNumber}}</span>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
            <span >{{document.partnerPostcode}} {{document.partnerCity}}, {{document.partnerState}}, {{lang.VATID}}{{document.partnerVATID}}</span>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="document.partnerVATID ==='00000000000'">
        <v-col cols="12">
           {{document.partnerSecretaryFirst}} {{document.partnerSecretaryLast}} {{document.partnerSecretaryPhone}} {{document.partneremail}} 
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
            <span >{{document.partneremail}} <span v-if="document.partnerPhones">,{{document.partnerPhones}}</span> </span>
        </v-col>
      </v-row>

       <v-dialog v-model="dialog"  width="500">
          <v-card>
            <PartnerViewSmall />
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialog = false"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>

  </div>
</template>

<script>
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import PartnerViewSmall from '../Partner/PartnerViewSmall.vue'

export default {
  data() {
    return {
      document: {},
      locCreatedAt: '',
      locUpdatedAt: '',
      lang: {},
      langC: {},
      dialog: false
    }
  },
  mounted() {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    console.log('View card2 this.$store.state.documentActivHead', this.$store.state.documentActivHead.partnerVATID)
    if (this.$store.state.documentActivHead) {
      this.document = this.$store.state.documentActivHead
    }
  },
  methods: {
    showDialog () {
      this.dialog = !this.dialog
    },
  },
  components: {
    PartnerViewSmall
  }
}
</script>

<style>

</style>