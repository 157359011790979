<template>
 <v-container fluid class="ma-2">
   <v-row no-gutters justify="center" class="pa-0">
        <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
          <v-card>
            <v-card-title primary class="title">
              {{lang.Price}}
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemPrice}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemPrice}} {{item.ItemHomeCurrency}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemVAT1Percentage}}                     
                </v-flex>
                <v-flex xs3 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemVAT1Percentage}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemDirectDuty1}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemDirectDuty1}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemDirectDuty2}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemDirectDuty2}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemPriceWithVAT}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemPriceWithVAT}} {{item.ItemHomeCurrency}}</span>
                </v-flex>
              </v-row>

              <v-card-title primary class="title">
                {{lang.SupplierData}}
              </v-card-title>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemSuppBasePrice}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemSuppBasePrice}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemSuppDiscount}}%              
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemSuppDiscount}}%</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemSuppPrice}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemSuppPrice}}</span>
                </v-flex>
              </v-row>
                            
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemSuppCode}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{ item.ItemSuppCode ? localName(item.ItemSuppCode) : '' }}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemMargin}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemMargin}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                  {{lang.ItemMaxDiscount}}                   
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemMaxDiscount}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemDiscountAmount1}}                    
                </v-flex>
                <v-flex xs3 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemDiscountAmount1}}</span>
                </v-flex>
                <v-flex xs3 class="py-0 px-2">
                    {{lang.ItemDiscountPercentage1}}                    
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemDiscountPercentage1}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemDiscountAmount2}}                    
                </v-flex>
                <v-flex xs3 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemDiscountAmount2}}</span>
                </v-flex>
                <v-flex xs3 class="py-0 px-2">
                    {{lang.ItemDiscountPercentage2}}                    
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemDiscountPercentage2}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ItemDiscountAmount3}}                    
                </v-flex>
                <v-flex xs3 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemDiscountAmount3}}</span>
                </v-flex>
                <v-flex xs3 class="py-0 px-2">
                    {{lang.ItemDiscountPercentage3}}                    
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    <span style="font-weight:bold">{{item.itemDiscountPercentage3}}</span>
                </v-flex>
              </v-row>
            </v-card-text>
            </v-card>
        </v-col>
   </v-row>
  </v-container>
</template>

<script>
import langEn from './itemDescEn'
import langHr from './itemDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import ItemService from '@/services/ItemService'
import documentLocalName from '@/snippets/documentLocalName'

export default {
  name: 'itemview',
  data () {
    return {
      lang: {},
      langC: {},
      menu: false,
      modal: false,
      msg: '',
      valid: true,
      samoZaProbu: 0,
      item: {},
      error: null,
      localError: false,
      privitems: [
        { text: 'EUR', value: 'EUR' },
        { text: 'Kn', value: 'Kn' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' }
      ],
      privGroups: []
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.itemid) {
      try {
        const dbItem = await ItemService.show(this.$store.state.itemid)
        this.item = dbItem.data.item
      } catch (error) {
        // eslint-disable-next-line
        console.log('error read from datebase')
      }
    }
  },
  methods: {
    localName(nameToFormat) {
      return documentLocalName(nameToFormat)
    },
  },
  computed: {
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
