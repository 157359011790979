<template>
 <v-container fluid grid-list-md>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <v-btn color="primary" @click="warehousemark" class="mr-2">
            {{this.lang.Warehouse}}
          </v-btn>
          <v-btn color="primary" @click="warehouseuser" class="mr-2">
            {{this.lang.WarehouseUser}}
          </v-btn>
          <v-btn color="primary" @click="user" class="mr-2" v-if="admin">
            {{this.lang.Users}}
          </v-btn> 
          <v-btn color="primary" @click="bankaccount" class="mr-2" >
            {{this.lang.BankAccount}}
          </v-btn> 
          <v-btn color="primary" @click="calendar" class="mr-2" >
            {{this.lang.Calendar}}
          </v-btn>
          <v-btn color="primary" @click="termsofuse" class="mr-2" >
            {{this.lang.Terms}}
          </v-btn> 
          <v-btn color="primary" @click="docdescs" class="mr-2" >
            {{this.lang.DocDesc}}
          </v-btn> 
           <v-btn color="primary" @click="vehicles" class="mr-2" >
            {{this.lang.Vehicles}}
          </v-btn>
        </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-btn color="primary" @click="company" class="mr-2" v-if="fullAdmin">
            {{this.lang.Company}}
          </v-btn> 
          <v-btn color="primary" @click="companybusinessyear" class="mr-2" v-if="fullAdmin">
            {{this.lang.CompanyBusinessYear}}
          </v-btn> 
          <v-btn color="primary" @click="companyuser" class="mr-2" v-if="admin || fullAdmin">
            {{this.lang.CompanyUsers}}
          </v-btn>
          <v-btn color="primary" @click="cardtype" class="mr-2" v-if="admin || fullAdmin">
            {{this.lang.CardType}}
          </v-btn>
          <v-btn text color="primary" @click="updatedocdoc" class="mr-2" v-if="fullAdmin">
            Kalendar napuni 2024
          </v-btn>
          <v-btn text color="primary" @click="updategrupe" class="mr-2" v-if="fullAdmin">
            Up.grupe u doc.det.
          </v-btn> 
        </v-col>      
     </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-btn color="primary" @click="companycreateuser" class="mr-2" v-if="admin">
            Company
          </v-btn> 
          <v-btn color="primary" @click="companyedit" class="mr-2" v-if="admin">
            Logo
          </v-btn> 
        </v-col>      
     </v-row>
    <v-row no-gutters v-if="showTerms">    
      <v-col cols="12" sm="10" md="10" lg="10" xl="10">
        <CompTermsOfUse />
      </v-col>
    </v-row> 
    <v-row no-gutters v-if="showVehicle">    
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <Vehicle />
      </v-col>
    </v-row>
    <v-row no-gutters v-if="showDocDesc">    
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <DocDesc />
      </v-col>
    </v-row> 
  </v-container>
</template>

<script>
import langEn from './mainDescEN'
import langHr from './mainDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import DocumentService from '@/services/DocumentService'
import DocumentDetailService from '@/services/DocumentDetailService'
import CompTermsOfUse from '@/components/Companies/CompTermsOfUse'
import Vehicle from '@/components/Vehicle/VehicleIndex'
import DocDesc from '@/components/DocDesc/DocDescIndex'

export default {
  name: 'appsettings',
  data () {
    return {
      menu: false,
      modal: false,
      msg: '',
      valid: true,
      account: { },
      error: null,
      localError: false,
      privGroups: [],
      lang: {},
      langC: {},
      saving: false,
      admin: false,
      showTerms: false,
      showBeton: false,
      showTvrtka: false,
      showKorisnik: false,
      showObjekt: false,
      showVehicle: false,
      showDocDesc: false,
      mainQuery: {},
      userIsBeton: false,
      fullAdmin: false
    }
  },
  components: {
    CompTermsOfUse,
    Vehicle,
    DocDesc
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }

      if (this.$store.state.user.Admin) {
        this.fullAdmin = this.$store.state.user.Admin
      }
      if (this.$store.state.user.admin2) {
        this.admin = this.$store.state.user.admin2
      }
      this.userIsBeton = this.$store.state.user.beton ? this.$store.state.user.beton : false
  },
  methods: {
    termsofuse(){
      this.showTerms = !this.showTerms
    },
    vehicles() {
      this.showVehicle = !this.showVehicle
    },
    docdescs() {
      this.showDocDesc = !this.showDocDesc
    },
    async warehousemark () {
      try {
        await this.$router.push({
          name: 'warehousemark'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async warehouseuser () {
      try {
        await this.$router.push({
          name: 'warehouseuser'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async user () {
      try {
        await this.$router.push({
          name: 'user'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async bankaccount () {
      try {
        await this.$router.push({
          name: 'bankaccount'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async calendar () {
      try {
        await this.$router.push({
          name: 'calendar'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async company () {
      try {
        await this.$router.push({
          name: 'company'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async company () {
      try {
        await this.$router.push({
          name: 'company'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async companycreateuser () {
      try {
        await this.$router.push({
          name: 'companycreateuser'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async companyedit () {
      try {
        await this.$router.push({
          name: 'companyedit'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async companybusinessyear () {
      try {
        await this.$router.push({
          name: 'companybusinessyear'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async companyuser () {
      try {
        await this.$router.push({
          name: 'companyuser'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async cardtype () {
      try {
        await this.$router.push({
          name: 'cardtype'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async updatedocdoc () {
      try {
        await DocumentService.updatedocdoc()
        alert('Kalendar napunjen!')
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async updategrupe () {
      try {
        const itemsLoc = this.$store.state.itemStore ? this.$store.state.itemStore : []
        this.mainQuery.BusinessYear = this.$store.state.businessYear
        const needUpdate = []
        this.mainQuery.CompanyId = this.$store.state.companyid
        this.mainQuery.docFromDate = new Date(new Date().getFullYear(), 0, 1)
        this.mainQuery.docToDate = new Date(new Date().getFullYear(), 11, 31)


        await DocumentDetailService.salesana(this.mainQuery)
        .then(res => {
          const itemDetails = res.data.document ? res.data.document : []
          console.log('Sve stavke u dok.detalis ', itemDetails)
          if (itemDetails) {
            itemDetails.map(item=> {
              if (!item.itemGroup || !item.itemGroupName) {
                let addItem = true
                if (needUpdate) {
                  const marked = needUpdate.find(nitem => {nitem.itemID === item.itemID})
                  if (marked) {
                    addItem = false
                  }
                }
                if (addItem) {
                  const haveItem = itemsLoc.find(fitem => fitem.itemID === item.itemID)
                  const newItem = {}
                  newItem.CompanyId = this.$store.state.companyid
                  newItem.BusinessYear = this.$store.state.businessYear
                  newItem.itemID = item.itemID
                  newItem.GroupId = haveItem.GroupId
                  newItem.GroupName = haveItem.GroupName
                  needUpdate.push (newItem)
                }
              }
            })
          }
          console.log('Trebaju update  ', needUpdate)
          if (needUpdate) {
            needUpdate.map(async(upitem) => {
              await DocumentDetailService.updateitems(upitem)
            })
          }
          console.log('Update done  !!')
        })
        .catch((err) => {
          console.log(err)
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },

  },
  computed: {
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
