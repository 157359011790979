<template>
  <v-container fluid >
    <v-row>
      <v-col cols="12" sm="4" xs="4" md="4" lg="4" xl="4" class="mb-4">
        <h2>Opz stat</h2>
      </v-col>

      <v-col cols="12" sm="3" xs="3" md="3"  lg="3" xl="3">
        <v-btn fab dark fixed right class="indigo"  @click="newdocument">
              <v-icon dark>mdi-plus</v-icon>
          </v-btn>
      </v-col>
    </v-row>

    <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>

    <v-row no-gutters class="pa-0">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <OpzstatHeadList :key="refresh"/>
      </v-col>
    </v-row>    
  </v-container>
</template>

<script>

import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import OpzstatHeadList from './OpzstatHeadList'

export default {
  name: 'opzstathead',
  data () {
    return {
      msg: '',
      lang: {},
      langC: {},
      locMonths: [],
      locYears: [],
      monthSelected: null,
      yearSelected: null,
      empSelected: null,
      emplys: [],
      newWH: {},
      newWorkHours: [],
      mainQuery: {},
      days:[],
      refresh: 0,
      saving: false,
      postNew: false,
      workDays: 0,
      holiDays: 0,
      salarieID: null,
      updateObj: {},
      modal1: false,
      dialog1: false,
      joppddate: null,
      joppd: 0
    }
  },
  beforeMount() {

  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.langC = commHr
    }
    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    if (this.$store.state.businessYear) {
      this.mainQuery.businessYear = this.$store.state.businessYear
    }

  },
  methods: {
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
    async newdocument () {
      try {
        await this.$store.dispatch('seteditItem', 0)
        await this.$router.push({
          name: 'opzstatheaddetail'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },

  },
  computed: {

  },
  watch: {

  },
  components: {
    OpzstatHeadList,
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
