<template>
  <v-container fluid grid-list-md>
    <v-layout row>
      <v-flex xs12 sm12 md8 lg8 xl8>
        <h1>{{this.$store.state.documentActivLoc}}</h1>
      </v-flex>
      <v-flex xs6 sm6 md6 lg6 xl6 class="text-right">
          <v-btn fab dark fixed right class="indigo"  @click="newdocument">
              <v-icon dark>mdi-plus</v-icon>
          </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  name: 'documentproductionheader',
  data () {
    return {
      lang: {},
      langC: {},
      msg: '',
      documentName: '',
      documentNameLoc: '',
      routeto: 1
    }
  },
  mounted () {
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    if (this.$store.state.documentActiv === 'WorkingOrder') {
      this.routeto = 2
    }
    // if (this.$store.state.language === "EN") {
    //   this.lang = ledgerEN
    //   this.langC = commEn
    // }
    // if (this.$store.state.language === "HR") {
    //   this.lang = ledgerHR
    //   this.langC = commHr
    // }
  },
  methods: {
    async newdocument () {
      try {
        await this.$router.push({
          name: 'documentcreate'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  computed: {
  },
  watch: {

  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>