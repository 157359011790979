<template>
  <v-container fluid grid-list-md>
      <v-row>
      <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
        <h2>{{head1}}</h2>
      </v-col>
    </v-row>
        <v-row v-if="showCalendar1"> 
        <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9">
          <Calendar />       
        </v-col>
        <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
         <v-row>
          <v-col cols="12" xs="12">
 
            <v-btn
            text
            color="primary"
            @click="newTask">
            {{langC.NewTask}}
          </v-btn>

          </v-col>
         </v-row>
         <v-row>
            <v-col cols="12" xs="12" class="mt-2">
              <TaskToDoList />
            </v-col> 
         </v-row>
             
        </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
        <div v-if="showChart1">
          <Chart1 />
        </div>
      </v-col>
       <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
        <div v-if="showChart2">
          <Chart3 />
        </div>
      </v-col>
    </v-row>


    <v-row>
        <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <div v-if="showSales1">
          <DocumentDashboardTotals />
        </div>
      </v-col>
    </v-row>
  </v-container>
 
</template>

<script>

import TaskService from '@/services/TaskService'
import UserService from '@/services/UserService'
import DocumentDashboardTotals from './Document/DocumentDashboardTotals'
import Chart1 from './Charts/Chart1'
import Chart3 from './Charts/Chart3'
import Calendar from './Calendar/CalendarDash'
import TaskToDoList from './Task/TaskToDoList'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"

export default {
  name: 'home',
  data () {
    return {
      msg: '',
      haveAlarms: false,
      haveTasks: false,
      tasksToday: [],
      tasksWeek: [],
      tasksAlarms: [],
      items: [
      ],
      weektask: [
      ],
      alarms: [
      ],
      users: [ ],
      selectUser: {},
      dateToday: null,
      dateToday1: null,
      dateToday2: null,
      dateToday3: null,
      dateToday4: null,
      dateToday5: null,
      dateToday6: null,
      dateToday7: null,
      dateToday8: null,
      dateToday9: null,
      dateToday10: null,
      dateToday11: null,
      dateToday12: null,
      dayToday1: null,
      dayToday2: null,
      dayToday3: null,
      dayToday4: null,
      dayToday5: null,
      dayToday6: null,
      dayToday7: null,
      dayToday8: null,
      dayToday9: null,
      dayToday10: null,
      dayToday11: null,
      dayToday12: null,
      dayTask1: [],
      dayTask2: [],
      dayTask3: [],
      dayTask4: [],
      dayTask5: [],
      dayTask6: [],
      dayTask7: [],
      dayTask8: [],
      dayTask9: [],
      dayTask10: [],
      dayTask11: [],
      dayTask12: [],
      haveDate: null,
      today: null,
      tomorrow: null,
      mainQuery: {
        CompanyId: 1,
        UserId: 1
      },
      showChart1: false, 
      showChart2: false,
      showTask1: false, 
      showTask2: false, 
      showSales1: false, 
      showCalendar1: false,
      head1: '',
      langC: {},
      haveIt: null
    }
  },
  components: {
    Chart1,
    Chart3,
    DocumentDashboardTotals,
    Calendar,
    TaskToDoList
  },
  async mounted () {
    try {
      let findUser = true
      if (this.$store.state.language === "EN") {
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = await this.$store.state.companyid
      }

      this.mainQuery.UserId = this.$store.state.user && this.$store.state.user.id ? await this.$store.state.user.id : findUser =false
      if (findUser) {
      this.users = (await UserService.choose(this.mainQuery)).data.users
      this.haveIt = this.users.filter(user => user.email === this.$store.state.user.email)
      }
      const currentUser = this.haveIt && this.haveIt.length> 0 ? this.haveIt[0] : {}
      if (currentUser) {
        this.showChart1 = currentUser.chart1 ? currentUser.chart1 : false
        this.showChart2 = currentUser.chart2 ? currentUser.chart2 : false
        this.showTask1 = currentUser.task1 ? currentUser.task1 : false
        this.showTask2 = currentUser.task2 ? currentUser.task2 : false
        this.showSales1 = currentUser.salesNumbers1 ? currentUser.salesNumbers1 : false
        this.showCalendar1 = currentUser.calendar1 ? currentUser.calendar1 : false
      }
      this.head1 = this.$store.state.companyActiv && this.$store.state.companyActiv.CompanyName ? this.$store.state.companyActiv.CompanyName : ' '
      this.head1 = this.head1 + (this.$store.state.businessYear ? ', ' + this.$store.state.businessYear : '')

      // this.showSales1 = false
      // this.showChart1 = false
      // this.showChart2 = false
    }
    catch(err) {
      console.log(err)
    }
  },
  methods: {
    navigateTo (taskid) {
      this.$store.dispatch('seteditItem', 1)
      this.$store.dispatch('setTaskid', taskid)
      this.$store.dispatch('setretPath', 'home')
      this.$router.push({
        name: 'taskcreate'
      }).catch(err => {
          // eslint-disable-next-line
          console.log(err);
        })
    },
    newTask () {
      this.$store.dispatch('seteditItem', 0)
      this.$store.dispatch('setretPath', 'home')
      this.$router.push({
        name: 'taskcreate'
      }).catch(err => {
          // eslint-disable-next-line
          console.log(err);
        })
    },
    async showTasks() {
      try {
        const dbTask = await TaskService.tasktoday(this.mainQuery)
        this.tasksToday = dbTask.data.task.concat(this.mainQuery)
        const dbTask2 = await TaskService.taskweek(this.mainQuery)
        this.tasksWeek = dbTask2.data.task
        const dbTask3 = await TaskService.taskalarmopen(this.mainQuery)
        this.tasksAlarms = dbTask3.data.task.concat()
        
        if (this.tasksAlarms.length > 0) {
          this.haveAlarms = true
        }
        if (this.tasksToday.length > 0) {
          this.haveTasks = true
        }

        this.dateToday = new Date()
        this.dateToday1.setDate(this.dateToday.getDate() + 1)
        this.dateToday2.setDate(this.dateToday.getDate() + 2)
        this.dateToday3.setDate(this.dateToday.getDate() + 3)
        this.dateToday4.setDate(this.dateToday.getDate() + 4)
        this.dateToday5.setDate(this.dateToday.getDate() + 5)
        this.dateToday6.setDate(this.dateToday.getDate() + 6)
        this.dateToday7.setDate(this.dateToday.getDate() + 7)
        this.dateToday8.setDate(this.dateToday.getDate() + 8)
        this.dateToday9.setDate(this.dateToday.getDate() + 9)
        this.dateToday10.setDate(this.dateToday.getDate() + 10)
        this.dateToday11.setDate(this.dateToday.getDate() + 11)
        this.dateToday12.setDate(this.dateToday.getDate() + 12)

        this.dayToday1 = this.dateToday1
        this.dayToday2 = this.dateToday2
        this.dayToday3 = this.dateToday3
        this.dayToday4 = this.dateToday4
        this.dayToday5 = this.dateToday5
        this.dayToday6 = this.dateToday6
        this.dayToday7 = this.dateToday7
        this.dayToday8 = this.dateToday8
        this.dayToday9 = this.dateToday9
        this.dayToday10 = this.dateToday10
        this.dayToday11 = this.dateToday11
        this.dayToday12 = this.dateToday12
        this.today = new Date()
        this.tomorrow.setDate(this.dateToday.getDate() + 1)

        let tempDay1 = []
        let tempDay2 = []
        let tempDay3 = []
        let tempDay4 = []
        let tempDay5 = []
        let tempDay6 = []
        let tempDay7 = []
        let tempDay8 = []
        let tempDay9 = []
        let tempDay10 = []
        let tempDay11 = []
        let tempDay12 = []
        this.tasksWeek.forEach(function (task) {
          let dateC = ''
          for (let dayComp = 1; dayComp <= 12; dayComp++) {
           // dateC = moment().add(dayComp, 'days').format('YYYY-MM-DD')
            dateC.setDate(this.dateToday.getDate() + dayComp)
            if (dateC === task.taskForDate) {
              if (dayComp === 1) {
                tempDay1.push(task)
              }
              if (dayComp === 2) {
                tempDay2.push(task)
              }
              if (dayComp === 3) {
                tempDay3.push(task)
              }
              if (dayComp === 4) {
                tempDay4.push(task)
              }
              if (dayComp === 5) {
                tempDay5.push(task)
              }
              if (dayComp === 6) {
                tempDay6.push(task)
              }
              if (dayComp === 7) {
                tempDay7.push(task)
              }
              if (dayComp === 8) {
                tempDay8.push(task)
              }
              if (dayComp === 9) {
                tempDay9.push(task)
              }
              if (dayComp === 10) {
                tempDay10.push(task)
              }
              if (dayComp === 11) {
                tempDay11.push(task)
              }
              if (dayComp === 12) {
                tempDay12.push(task)
              }
            }
          }
          // this.haveDate = task.createdAt
        })
        this.dayTask1 = tempDay1.concat()
        this.dayTask2 = tempDay2.concat()
        this.dayTask3 = tempDay3.concat()
        this.dayTask4 = tempDay4.concat()
        this.dayTask5 = tempDay5.concat()
        this.dayTask6 = tempDay6.concat()
        this.dayTask7 = tempDay7.concat()
        this.dayTask8 = tempDay8.concat()
        this.dayTask9 = tempDay9.concat()
        this.dayTask10 = tempDay10.concat()
        this.dayTask11 = tempDay11.concat()
        this.dayTask12 = tempDay12.concat()
      } catch (error) {
        // eslint-disable-next-line
        console.log('error read from database')
    }

    },
    analysis () {
      this.$store.dispatch('setretPath', 'home')
      this.$router.push({
        name: 'taskanalysis'
      }).catch(err => {
          // eslint-disable-next-line
          console.log(err);
        })
    },
    viewLast50 () {
      this.$store.dispatch('setTaskViewType', 2)
      this.$router.push({
        name: 'task'
      }).catch(err => {
          // eslint-disable-next-line
          console.log(err);
        })
    },
    localDate(dateToFormat) {
      return dateToFormat.toLocaleDateString('de-DE', {year: 'numeric', month: '2-digit', day:'2-digit'});
      //return this.$moment(dateToFormat).format('DD.MM.YYYY')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#dateheader {
  color:aliceblue;
  text-align: center;
  font-weight: bold;
}
#calendarcolum {
  padding-left: 2px;
  padding-right: 3px;
}
#fullhome {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
