const docHR = {
  docsmall: 'Oznaka opisa',
  DocDescList: 'Lista opisa',
  Description: 'Novi opis',
  EditDocDesc: 'Uredi opis',
  DocDescListEmpty: 'Lista vozila je prazna',
  DocDesc: 'Opis *',
  Doc: 'Naziv dokumenta',
  DocLoc: 'Naziv dokumenta',
  docOnly: 'Koristi se u dokumentu',
}

export default docHR