<template>
      <v-layout row style="border-bottom:solid 1px;">
        <v-flex xs5>
            <h4><b><i>{{comp.CompanyName}}</i></b></h4>
            {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}<br />                
            {{comp.CompanyPostcode}} {{comp.CompanyCity}}<br /> 
            OIB: {{comp.CompanyVATID}}
        </v-flex>
        <v-flex xs3>
          <h2> {{(showOpen ? (this.$store.state.language == 'EN' ? 'Open card': 'Otvorene stavke') : (this.$store.state.language == 'EN' ? 'Card': 'Kartica'))}}  {{!short ? '' : (type === 1 ? (this.$store.state.language == 'EN' ? ' Suppliers': ' dobavljač')  : (this.$store.state.language == 'EN' ? ' buyer': ' kupac') ) }}</h2>
          <span v-if="addHeader"><h2>{{addHeader}}</h2></span>
          <p class="text-md-left">
              {{(par && par.partnerName ? par.partnerName : '')}}<br />
              OIB: {{par.partnerVATID}}<br />
          </p>
      </v-flex>
        <v-flex xs 4>
          <p class="text-md-left">
              Datum izvještaja: {{PrintDate}}<br />
              Telefon: {{comp.CompanyPhones}}<br />
              email: {{comp.Companyemail}}<br />
          </p>
        </v-flex>
  </v-layout>
</template>

<script>
import ledgerEN from '../MainBook/mainbookDescEn'
import ledgerHR from '../MainBook/mainbookDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dayjs from 'dayjs'

export default {
  name: 'mainbookcompheader',
  props: ['type', 'par', 'addHeader', 'showOpen', 'short'],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      PrintDate: null,
    }
  },
  components: {

  },
  created() {
  },
  mounted () {
     if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
      this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
      this.PrintDate = dayjs().format('DD.MM.YYYY, H:mm')

  }
}

</script>
