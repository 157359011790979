<template>
<div>
  <v-container fluid grid-list-md>

  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="users"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="50"
    :mobile-breakpoint="0" 
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{documentNameLoc}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">

          <v-card>
            <v-card-title>
              <span class="headline">Stavka kartice</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-row>
                    <v-col cols="12" xs="6" sm="6" md="6"  lg="6" xl="6" class="pr-1">
                     <v-text-field
                        dense
                        label="Vrsta"
                        v-model="editedItem.BookName"
                        outlined
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" class="pr-1">
                     <v-text-field
                        dense
                        label="Broj dok"
                        v-model="editedItem.itInvoice"
                        outlined
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4" class="pr-1">
                     <v-text-field
                        v-if="useURA"
                        dense
                        label="Broj URE"
                        v-model="editedItem.invoiceInNumber"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4" class="pr-1">
                     <v-text-field
                        v-if="!useURA"
                        dense
                        label="Broj IRE"
                        v-model="editedItem.invoiceOutNumber"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4" class="pr-1">
                     <v-text-field
                        dense
                        label="Posl.god."
                        v-model="editedItem.BusinessYearInvoice"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4" class="pr-1">
                     <v-text-field
                        dense
                        label="Datum knjiženja"
                        v-model="BookingDate"
                        outlined
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4" class="pr-1">
                     <v-text-field
                        dense
                        label="Datum dokumenta"
                        v-model="InvoiceDate"
                        outlined
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4" class="pr-1">
                     <v-text-field
                        dense
                        label="Datum dospijeća"
                        v-model="InvoicePaymentDeadlineDate"
                        outlined
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pr-1">
                     <v-text-field
                        dense
                        label="Opis"
                        v-model="editedItem.Description"
                        outlined
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4" class="pr-1">
                     <v-text-field
                        dense
                        label="Konto"
                        v-model="editedItem.account4"
                        outlined
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4" class="pr-1">
                     <v-text-field
                        dense
                        label="Duguje"
                        v-model="editedItem.Debit"
                        outlined
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4" class="pr-1">
                     <v-text-field
                        dense
                        label="Potražuje"
                        v-model="editedItem.Credit"
                        outlined
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4" class="pr-1">
                     <v-text-field
                        dense
                        label="SALDO"
                        v-model="editedItem.CreditDebit"
                        outlined
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1 mr-3"  @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn color="success"  @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.itInvoice`]="{ item }">
        <span>{{ parseInt(item.itInvoice) }}</span>
    </template>
    <template v-slot:[`item.invoiceInNumber`]="{ item }">
        <span v-if="item.invoiceInNumber !== 0">{{ parseInt(item.invoiceInNumber) }}</span>
    </template>
    <template v-slot:[`item.invoiceOutNumber`]="{ item }">
        <span v-if="item.invoiceOutNumber !== 0">{{ parseInt(item.invoiceOutNumber) }}</span>
    </template>
    <template v-slot:[`item.Debit`]="{ item }">
        <span>{{ localMoney(item.Debit) }}</span>
    </template>
      <template v-slot:[`item.Credit`]="{ item }">
        <span>{{ localMoney(item.Credit) }}</span>
    </template>
      <template v-slot:[`item.CreditDebit`]="{ item }">
        <span>{{ localMoney(item.CreditDebit) }}</span>
    </template>

    <template v-slot:[`item.BookingDate`]="{ item }">
        <span>{{ localDate(item.BookingDate) }}</span>
    </template>
    <template v-slot:[`item.InvoiceDate`]="{ item }">
        <span>{{ localDate(item.InvoiceDate) }}</span>
    </template>
      <template v-slot:[`item.InvoicePaymentDeadlineDate`]="{ item }">
        <span>{{ localDate(item.InvoicePaymentDeadlineDate) }}</span>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:no-data>
      Nema stavaka za povezaivanje
    </template>
  </v-data-table>
  </v-container>
  </div>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import moneyFormat from '@/snippets/moneyFormat'
import MainLedgerDetailService from '../../services/MainLedgerDetailService'
import { round, doZeros} from '@/snippets/allSnippets'
import dayjs from 'dayjs'

  export default {
    name: 'mainledgercard11connectcrud',
    props: ['items', 'head', 'partner'],
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      modal1: false,
      dialog1: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
      },
      dialog: false,
      headers: [],
      users: [],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      privitems: [],
      valid: true,
      useURA: true,
      BookingDate: '',
      InvoiceDate: '',
      InvoicePaymentDeadlineDate: '',
      newItems: [],
      documentNameLoc: '',
      removeItems: [],
      forUpdate: []
    }),

    computed: {

    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },
    components: {

    },

    mounted() {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }

    this.users = [...this.items]

    this.PrintDate = dayjs(new Date()).format('llll')
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    if (this.$store.state.partnerStore) {
      this.partners = this.$store.state.partnerStore
    }
    const ureTable = [
        { text: 'Vrsta', align: 'start', value: 'BookName'},
        { text: 'Broj dok.', value: 'itInvoice' },
        { text: 'VEZA', value: 'invoiceInNumber' },
        { text: 'Godina', value: 'BusinessYearInvoice' },
        {text: this.lang.Actions, value: 'action', sortable: false },
        { text: 'Orig.račun', value: 'InvoiceNumber' },
        { text: 'Datum knjiženja', value: 'BookingDate' },
        { text: 'Datum dokumenta', value: 'InvoiceDate' },
        { text: 'Datum dospijeća', value: 'InvoicePaymentDeadlineDate' },
        { text: 'Opis', value: 'Description' },
        { text: 'Konto', value: 'account4' },
        { text: 'Duguje', value: 'Debit' },
        { text: 'Potražuje', value: 'Credit' },
        { text: 'Saldo', value: 'CreditDebit' },
        
    ]
     const ireTable = [
        { text: 'Vrsta', align: 'start', value: 'BookName'},
        { text: 'Broj dok.', value: 'itInvoice' },
        { text: 'VEZA', value: 'invoiceOutNumber' },
        { text: 'Godina', value: 'BusinessYearInvoice' },
        {text: this.lang.Actions, value: 'action', sortable: false },
        { text: 'Datum knjiženja', value: 'BookingDate' },
        { text: 'Datum dokumenta', value: 'InvoiceDate' },
        { text: 'Datum dospijeća', value: 'InvoicePaymentDeadlineDate' },
        { text: 'Opis', value: 'Description' },
        { text: 'Konto', value: 'account4' },
        { text: 'Duguje', value: 'Debit' },
        { text: 'Potražuje', value: 'Credit' },
        { text: 'Saldo', value: 'CreditDebit' },
    ]
    this.headers = this.head.fromAccount && this.head.fromAccount.substr(0,2) === '22' ? [...ureTable] : [...ireTable]
    this.useURA = this.head.fromAccount && this.head.fromAccount.substr(0,2) === '22' ? true : false
    this.documentNameLoc = 'Povezivanje proknjiženih stavaka: ' + this.partner.partnerName +  ', OIB:'+ this.partner.partnerVATID

    this.showDialog = true
    this.indxTo = null
    },

    created () {
      this.initialize()
    },

    methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      localMoney(moneyTo) {
        return moneyFormat(moneyTo)
      },
      async initialize () {
        try {
          this.errorMatch = false
          this.editedItem = Object.assign({}, this.defaultItem)
        } catch (err) {
          this.error = err
        }
      },
      editItem (item) {
        
        this.newItems.length = 0
        this.errorMatch = false
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.BookingDate = item.BookingDate ? this.localDate(item.BookingDate) : ''
        this.InvoiceDate = item.InvoiceDate ? this.localDate(item.InvoiceDate) : ''
        this.InvoicePaymentDeadlineDate = item.InvoicePaymentDeadlineDate ? this.localDate(item.InvoicePaymentDeadlineDate) : ''
        this.dialog = true
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
      checkMatch () {
        if (this.editedItem.password && this.confpassword &&  this.confpassword === this.editedItem.password) {
          this.errorMatch = null
          this.save()
        } else {
          this.errorMatch = 'Error. Passoword and Confirmation Password are NOT equal!'
          this.valid = false 
        }
      },

      async save () {
        try {
           let doneOK = false
           this.removeItems.length = 0
           this.forUpdate.length = 0
           if (this.editedIndex > -1) {
              this.editedItem.newID = this.useURA ? doZeros(round(this.editedItem.invoiceInNumber,0).toString(), round(this.editedItem.BusinessYear,0).toString()) : doZeros(round(this.editedItem.invoiceOutNumber,0).toString(), round(this.editedItem.BusinessYear,0).toString())
              this.users[this.editedIndex] = this.editedItem
              console.log('this.editedItem', this.editedItem)
              console.log('this.users', this.users)
              
              const result = [...this.users.reduce((r, o) => {
              const key = o.newID;
              
              const item = r.get(key) || Object.assign({}, o, {
                  Debit: 0,
                  Credit: 0
                });
                
                item.Debit += o.Debit;
                item.Credit += o.Credit;

                return r.set(key, item);
              }, new Map).values()];
              console.log('result', result)
          
              result.map(r => {

                if (round(Math.abs(r.Debit)-Math.abs(r.Credit) , 2) === 0) {
                  this.removeItems = this.users.filter(ff => ff.newID !== r.newID )
                  this.users.map(f => {
                    if (f.newID === r.newID) {
                      const forUpdateObj = {...f}
                      delete forUpdateObj.newID
                      delete forUpdateObj.pDebit
                      delete forUpdateObj.pCredit
                      delete forUpdateObj.itBookName
                      delete forUpdateObj.CreditDebit
                      delete forUpdateObj.itInvoice
                      delete forUpdateObj.locBookName
                      forUpdateObj.LastUpdateBy = this.$store.state.user.email ? this.$store.state.user.email : 'test@cegrupa.com'
                      forUpdateObj.LastUpdate = new Date()
                      this.forUpdate.push(forUpdateObj)
                    }
                  })
                }
              })
              console.log('forUpdate', this.forUpdate)
              if (this.forUpdate && this.forUpdate.length > 0) {
                let results = this.forUpdate.map(async(forU) => {
                    await MainLedgerDetailService.put(forU)
                  })
                  results = await Promise.all(results)
                  
                //await MainLedgerDetailService.saveitems(this.forUpdate)
              }

              // if (this.removeItems && this.removeItems.length > 0) {
                this.users = [...this.removeItems]
              // }
              doneOK = true
            }
            if (doneOK) {
              this.initialize()
              this.close()
            }
        } catch(err) {
          this.error = err
          
        }
       
      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      }
    },
  }
</script>