import {dynamicSortMultiple} from '@/snippets/allSnippets'
import SalarieService from '@/services/SalarieService'
import store from '@/store/store'

async function freeCompensation (companyid,fromDate, toDate, typeId, userId) {

  const notax = store.state.joppdNotax
  const newObj = {}
  const empF =[]
  newObj.CompanyId = companyid
  // newObj.fromDate = '2024-01-01' 
  // newObj.toDate = '2024-12-31'
  // newObj.typeId = 1
  newObj.fromDate = fromDate
  newObj.toDate = toDate
  newObj.typeId = typeId
  newObj.userId = userId && userId.id ? userId.id : null
  await SalarieService.choosedetail(newObj)
    .then(res => {
      const salH = res && res.data && res.data.salaries ? res.data.salaries : null
      if (salH) {
        salH.map(head=> {
          if (head && head.SalarieDetails) {
            const BrojJoppd = head.BrojJoppd
            const JoppdDate = head.JoppdDate
            const JoppdPoslanDate = head.JoppdPoslanDate

            head.SalarieDetails.map (det => {
              const oneNotaxaddition = det.FinAdd1_TypeId ? notax.find(notax1 => (parseInt(notax1.value) === det.FinAdd1_TypeId )) : null
              const twoNotaxaddition = det.FinAdd2_TypeId ? notax.find(notax2 => (parseInt(notax2.value) === det.FinAdd2_TypeId )) : null
              const threeNotaxaddition = det.FinAdd3_TypeId ? notax.find(notax3 => (parseInt(notax3.value) === det.FinAdd3_TypeId )) : null
              const fourNotaxaddition = det.FinAdd4_TypeId ? notax.find(notax4 => (parseInt(notax4.value) === det.FinAdd4_TypeId )) : null
              const FinAdd1_Amount = det.FinAdd1_Amount ? det.FinAdd1_Amount : 0
              const FinAdd2_Amount = det.FinAdd2_Amount ? det.FinAdd2_Amount : 0
              const FinAdd3_Amount = det.FinAdd3_Amount ? det.FinAdd3_Amount : 0
              const FinAdd4_Amount = det.FinAdd4_Amount ? det.FinAdd4_Amount : 0
              

              if (FinAdd1_Amount !== 0) {
                const haveEmp = {}
                haveEmp.eemail = det.eemail ? det.eemail : ''
                haveEmp.First = det.First ? det.First : ''
                haveEmp.Last = det.Last ? det.Last : ''
                haveEmp.VatID = det.VatID ? det.VatID : null
                haveEmp.Order = det.Order ? det.Order : null
                haveEmp.Month = det.Month ? det.Month : null
                haveEmp.Year = det.Year ? det.Year : null
                haveEmp.BrojJoppd = BrojJoppd
                haveEmp.JoppdDate = JoppdDate
                haveEmp.JoppdPoslanDate = JoppdPoslanDate
                haveEmp.text = oneNotaxaddition && oneNotaxaddition.text ? oneNotaxaddition.text : null
                haveEmp.value = oneNotaxaddition && oneNotaxaddition.value ? oneNotaxaddition.value : null
                haveEmp.amount = FinAdd1_Amount
                empF.push(haveEmp)
              }
              if (FinAdd2_Amount !== 0) {
                const haveEmp = {}
                haveEmp.eemail = det.eemail ? det.eemail : ''
                haveEmp.First = det.First ? det.First : ''
                haveEmp.Last = det.Last ? det.Last : ''
                haveEmp.VatID = det.VatID ? det.VatID : null
                haveEmp.Order = det.Order ? det.Order : null
                haveEmp.Month = det.Month ? det.Month : null
                haveEmp.Year = det.Year ? det.Year : null
                haveEmp.BrojJoppd = BrojJoppd
                haveEmp.JoppdDate = JoppdDate
                haveEmp.JoppdPoslanDate = JoppdPoslanDate
                haveEmp.text = twoNotaxaddition && twoNotaxaddition.text ? twoNotaxaddition.text : null
                haveEmp.value = twoNotaxaddition && twoNotaxaddition.value ? twoNotaxaddition.value : null
                haveEmp.amount = FinAdd2_Amount
                empF.push(haveEmp)
              }
              if (FinAdd3_Amount !== 0) {
                const haveEmp = {}
                haveEmp.eemail = det.eemail ? det.eemail : ''
                haveEmp.First = det.First ? det.First : ''
                haveEmp.Last = det.Last ? det.Last : ''
                haveEmp.VatID = det.VatID ? det.VatID : null
                haveEmp.Order = det.Order ? det.Order : null
                haveEmp.Month = det.Month ? det.Month : null
                haveEmp.Year = det.Year ? det.Year : null
                haveEmp.BrojJoppd = BrojJoppd
                haveEmp.JoppdDate = JoppdDate
                haveEmp.JoppdPoslanDate = JoppdPoslanDate
                haveEmp.text = threeNotaxaddition && threeNotaxaddition.text ? threeNotaxaddition.text : null
                haveEmp.value = threeNotaxaddition && threeNotaxaddition.value ? threeNotaxaddition.value : null
                haveEmp.amount = FinAdd3_Amount
                empF.push(haveEmp)
              }
              if (FinAdd4_Amount !== 0) {
                const haveEmp = {}
                haveEmp.eemail = det.eemail ? det.eemail : ''
                haveEmp.First = det.First ? det.First : ''
                haveEmp.Last = det.Last ? det.Last : ''
                haveEmp.VatID = det.VatID ? det.VatID : null
                haveEmp.Order = det.Order ? det.Order : null
                haveEmp.Month = det.Month ? det.Month : null
                haveEmp.Year = det.Year ? det.Year : null
                haveEmp.BrojJoppd = BrojJoppd
                haveEmp.JoppdDate = JoppdDate
                haveEmp.JoppdPoslanDate = JoppdPoslanDate
                haveEmp.text = fourNotaxaddition && fourNotaxaddition.text ? fourNotaxaddition.text : null
                haveEmp.value = fourNotaxaddition && fourNotaxaddition.value ? fourNotaxaddition.value : null
                haveEmp.amount = FinAdd4_Amount
                empF.push(haveEmp)
              }
            }) 
          }
        })
        empF.sort(dynamicSortMultiple("VatID", "value"))
      }
      
    })
    .catch(err => {
      console.log('no salaries ', err)
      return err
    })
    return empF
}

export {freeCompensation}

