const docHR = {
  User: 'Korisnik',
  Users: 'Korisnici',
  AddUser: 'Dodaj novog korisnika',
  email: 'email',
  password: 'password',
  ConfirmPassword: 'Potvrdi Password',
  First: 'Ime',
  Last: 'Prezime',
  StartFrom: 'Radi od',
  Activ: 'Aktivan ',
  Admin: 'Adm.Full',
  AccessLevel: 'Razina pristupa (1 max, 5 min)',
  UserEmpty: 'Baza korisnika je prazna',
  Actions: 'Akcije',
  NewUser: 'Novi Korisnik',
  EditUser: 'Uredi Korisnika',
  Priv1: '1 najveća prava',
  Priv2: '2 srednja do najveća prva',
  Priv3: '3 srednja prava',
  Priv4: '4 niska do srednja prava',
  Priv5: '5 najniža prava',
  VATID: 'OIB',
  sales1: 'Prodaja',
  sales2: 'Sakrij Dob.',
  purchase1: 'Nabava',
  purchase2: 'Nabava Prof',
  crm1: 'CRM',
  crm2: 'CRM Prof',
  finance1: 'Financije',
  finance2: 'Fin.Prof',
  finance3: 'Fin.Nap',
  finance4: 'Fin.Salda K.',
  hr1: 'Ljudski resursi',
  hr2: 'Ljudski resursi Prof',
  admin1: 'Adm.Firma',
  admin2: 'Admin Firma Prof',
  prod1: 'Proizvod.',
  prod2: 'Proiz. Prof',
  privatePartner: 'Privatni Part',
  chart1: 'Graf 1',
  chart2: 'Graf 2',
  chart3: 'Graf 3',
  chart4: 'Graf 4',
  salesNumbers1: 'Prodaja1',
  salesNumbers2: 'Prodaja2',
  calendar1: 'Kalend.1',
  calendar2: 'Kalend.2',
  task1: 'Zadaci 1',
  task2: 'Zadaci 2',
  chat1: 'Chat 1',
  chat2: 'Chat 2',
  vehicles: 'Vozila'
}

export default docHR