<template>
  <div class="travelorderprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ledgerEN from './travelorderDescEn'
import ledgerHR from './travelorderDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'

export default {
  name: 'travelorderprint',
  props: ['trvl', 'all'],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    
  },
  methods: {
    async print () {
      await this.initDocument()
      await this.addHeaderNoLogo()
      await this.addLine()
      await this.addHeader()
      await this.addLine()
      if (this.all === 2) {
        await this.addBody()
        await this.addLine()
      }
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 35, 20, 20, 20 ]
      this.content = []
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 500,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '30%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: 'OIB:' + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text:  'NALOG ZA SLUŽBENO PUTOVANJE\n', fontSize: 14, bold: true },
                  { text:  'Broj: ' + parseInt(this.trvl.TravelOrderId) +  'Od datuma: ' + dayjs(this.trvl.OrderDate).format('DD.MM.YYYY'), fontSize:10, bold: true}
                ]   
              },
              {
                // % width
                width: '20%',
                text: [                 
                  { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addHeader () {
        const docPart = {
        columns: [
              {
                width: '15%',
                fontSize: 12,
                alignment: 'left',
                text: this.trvl.eFirst
              },
              {
                width: '15%',
                fontSize: 12,
                alignment: 'left',
                text: this.trvl.eLast
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: 'OIB:' + this.trvl.eVatID +  ' \n' 
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: 'Na radnom mjestu: ' + this.trvl.WorkPlace
              },
            ]
          }
        this.content.push(docPart)
        this.addEmptyRow()

        const docPart2 = {
        columns: [
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.TravelDate + ': ' + dayjs(this.trvl.TravelDate).format('DD.MM.YYYY')
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.TravelTo + ': ' + this.trvl.TravelTo
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.TravelTask + ': ' + this.trvl.TravelTask
              },
            ]
        }
        this.content.push(docPart2)
        const docPart3 = {
        columns: [
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.TravelVeichle + ': ' + this.trvl.TravelVeichle
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.TravelVeichleBrand + ': ' + this.trvl.TravelVeichleBrand
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.TravelVeichleReg + ': ' + this.trvl.TravelVeichleReg
              },
            ]
        }
        this.content.push(docPart3)

        const docPart4 = {
        columns: [
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.TravelLastDays + ': ' + parseInt(this.trvl.TravelLastDays) + ', ' + this.lang.TravelLastDaysLett + ': ' + this.trvl.TravelLastDaysLett
              },
            ]
        }
        this.content.push(docPart4)

        const docPart5 = {
        columns: [
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.ExpensesForwardHomeC + ': ' + this.trvl.ExpensesForwardHomeC 
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.ExpensesForwardForeC + ': ' + this.trvl.ExpensesForwardForeC
              },
              {
                width: '35%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.ReportInDays + ' dana: ' + parseInt(this.trvl.ReportInDays) + ' \n' + 'treba obaviti obračun ovog putovanja i podnijeti pismeno izvješće o obavljenom zadatku.'
              },
            ]
        }
        this.content.push(docPart5)

        this.addEmptyRow()

        const docPart6 = {
        columns: [
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: '' 
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: 'M.P.'
              },
              {
                width: '35%',
                fontSize: 8,
                alignment: 'left',
                text: 'Odobrio: ' + this.trvl.ApprovedByFirst + ' ' + this.trvl.ApprovedByLast + ' \n' + this.trvl.ApprovedByemail
              },
            ]
        }
        this.content.push(docPart6)
        this.addEmptyRow()
        
    },
    addBody() {
       const docPart01 = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '30%',
                text: [
                  { text: ' ', fontSize: 10, bold: true },
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text:  'OBRAČUN PUTNIH TROŠKOVA', fontSize: 12, bold: true },
                ]   
              },

            ],
            // optional space between columns
            columnGap: 10
          }
      this.content.push(docPart01)
      const docPart = {
        columns: [
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinTravel1 + ' ' +this.trvl.FinTravel1
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.Travel2Date + ': ' + (this.trvl.Travel2Date ? dayjs(this.trvl.Travel2Date).format('DD.MM.YYYY') : '' )
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.Travel3Date + ': ' + (this.trvl.Travel3Date ? dayjs(this.trvl.Travel3Date).format('DD.MM.YYYY') : '') +  ' \n' 
              },
            ]
          }
        this.content.push(docPart)
        this.addEmptyRow()

        const docPart02 = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '75%',
                text: [
                  { text: '1.Obračun dnevnica ', fontSize: 12, bold: true },
                ]
              },
              {
                // % width
                width: '15%',
                text: [
                  { text:  '', fontSize: 12, bold: true },
                ]   
              },

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart02)
        console.log('this.trvl.FinStartTime ', this.trvl.FinStartTime )
        const docPart2 = {
        columns: [
              {
                width: '18%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinStartDate + ' \n' + (this.trvl.FinStartDate ? dayjs(this.trvl.FinStartDate).format('DD.MM.YYYY') : ' ')
              },
              {
                width: '12%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinStartTime + ' \n' + (this.trvl.FinStartTime ? this.trvl.FinStartTime.substring(0,5) : ' ')
              },
              {
                width: '18%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinEndDate + ' \n' + (this.trvl.FinEndDate ? dayjs(this.trvl.FinEndDate).format('DD.MM.YYYY') : ' ')
              },
              {
                width: '12%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinEndTime + ' \n' + (this.trvl.FinEndTime ? (this.trvl.FinEndTime.substring(0,5)) : ' ')
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinHours + ' \n' + this.formatPrice(this.trvl.FinHours)
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinDays + ' \n' + this.formatPrice(this.trvl.FinDays)
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinDaysAmount + ' \n' + this.formatPrice(this.trvl.FinDaysAmount)
              },
              {
                width: '10%',
                fontSize: 10,
                bold: true,
                alignment: 'left',
                text: this.lang.FinDaysTotal + ' \n' + this.formatPrice(this.trvl.FinDaysTotal)
              },
            ]
          }
        this.content.push(docPart2)


       const docPart21 = {
        columns: [
              {
                width: '18%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '12%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '18%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '12%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: ''
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinDaysEUR + ' \n' + this.formatPrice(this.trvl.FinDaysEUR)
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinDaysAmountEUR + ' \n' + this.formatPrice(this.trvl.FinDaysAmountEUR)
              },
              {
                width: '10%',
                fontSize: 10,
                bold: true,
                alignment: 'left',
                text: this.lang.FinDaysTotalEUR + ' \n' + this.formatPrice(this.trvl.FinDaysTotalEUR)
              },
            ]
          }
        this.content.push(docPart21)

        this.addEmptyRow()


        const docPart03 = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '75%',
                text: [
                  { text: '2.Obračun prijevoznih troškova ', fontSize: 12, bold: true },
                ]
              },
              {
                // % width
                width: '15%',
                text: [
                  { text:  '', fontSize: 12, bold: true },
                ]   
              },

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart03)

        const docPart3 = {
        columns: [
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinTrStart + ' ' + this.formatPrice(this.trvl.FinTrStart) 
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinTrEnd + ': ' + this.formatPrice(this.trvl.FinTrEnd) 
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.CostByKm + ': ' + this.formatPrice(this.trvl.CostByKm) 
              },
            ]
          }
        this.content.push(docPart3)
        this.addEmptyRow()

        const docPart4 = {
        columns: [
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinRel1From +  ' \n' + this.trvl.FinRel1From +  ' \n' + this.trvl.FinRel2From +  ' \n' + this.trvl.FinRel3From +  ' \n' + this.trvl.FinRel4From
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinRel1To +  ' \n' + this.trvl.FinRel1To +  ' \n' + this.trvl.FinRel2To +  ' \n' + this.trvl.FinRel3To +  ' \n' + this.trvl.FinRel4To
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinRel1Km +  ' \n' + this.trvl.FinRel1Km +  ' \n' + this.trvl.FinRel2Km +  ' \n' + this.trvl.FinRel3Km +  ' \n' + this.trvl.FinRel4Km
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinRel1KmKn +  ' \n' + this.trvl.FinRel1KmKn +  ' \n' + this.trvl.FinRel2KmKn +  ' \n' + this.trvl.FinRel3KmKn +  ' \n' + this.trvl.FinRel4KmKn
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinRel1KmEur +  ' \n' + this.trvl.FinRel1KmEur +  ' \n' + this.trvl.FinRel2KmEur +  ' \n' + this.trvl.FinRel3KmEur +  ' \n' + this.trvl.FinRel4KmEur
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinRel1Total +  ' \n' + this.trvl.FinRel1Total +  ' \n' + this.trvl.FinRel2Total +  ' \n' + this.trvl.FinRel3Total +  ' \n' + this.trvl.FinRel4Total
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinRel1TotalEur +  ' \n' + this.trvl.FinRel1TotalEur +  ' \n' + this.trvl.FinRel2TotalEur +  ' \n' + this.trvl.FinRel3TotalEur +  ' \n' + this.trvl.FinRel4TotalEur
              },
            ]
          }
        this.content.push(docPart4)

        const docPart41 = {
          columns: [
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                bold: true,
                text: this.lang.CostingTravles
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                bold: true,
                text: this.trvl.FinRel5Km
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '8%',
                fontSize: 8,
                bold: true,
                alignment: 'left',
                text: this.trvl.FinRel5Total
              },
              {
                width: '8%',
                fontSize: 8,
                bold: true,
                alignment: 'left',
                text: this.trvl.FinRel5TotalEur
              },
            ]
          }
        this.content.push(docPart41)
        this.addEmptyRow()

        const docPart05 = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '75%',
                text: [
                  { text: '3.Obračun ostalih troškova ', fontSize: 12, bold: true },
                ]
              },
              {
                // % width
                width: '15%',
                text: [
                  { text:  '', fontSize: 12, bold: true },
                ]   
              },

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart05)
        const docPart5 = {
        columns: [
              {
                width: '80%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinOther1 +  ' \n' + this.trvl.FinOther1 +  ' \n' + this.trvl.FinOther2 +  ' \n' + this.trvl.FinOther3 +  ' \n' + this.trvl.FinOther4 +  ' \n' 
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinOther1Kn +  ' \n' + this.formatPrice(this.trvl.FinOther1Kn) +  ' \n' + this.formatPrice(this.trvl.FinOther2Kn) +  ' \n' + this.formatPrice(this.trvl.FinOther3Kn) +  ' \n' + this.formatPrice(this.trvl.FinOther4Kn) +  ' \n' 
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinOther1Eur +  ' \n' + this.formatPrice(this.trvl.FinOther1Eur) +  ' \n' + this.formatPrice(this.trvl.FinOther2Eur) +  ' \n' + this.formatPrice(this.trvl.FinOther3Eur) +  ' \n' + this.formatPrice(this.trvl.FinOther4Eur) +  ' \n' 
              },
            ]
          }
        this.content.push(docPart5)
        const docPart51 = {
        columns: [
              {
                width: '80%',
                fontSize: 8,
                alignment: 'left',
                bold: true,
                text: this.lang.OtherExpTotal
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                bold: true,
                text: this.formatPrice(this.trvl.FinOther5Kn)
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'left',
                bold: true,
                text: this.formatPrice(this.trvl.FinOther5Eur)
              },
            ]
          }
        this.content.push(docPart51)
        this.addEmptyRow()


        const docPart06 = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '75%',
                text: [
                  { text: '4.Obračun predujma i nastalih troškova ', fontSize: 12, bold: true },
                ]
              },
              {
                // % width
                width: '15%',
                text: [
                  { text:  '', fontSize: 12, bold: true },
                ]   
              },

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart06)
        const docPart6 = {
        columns: [
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinTotal +  ' \n' + this.trvl.FinTotal +  ' \n' + this.formatPrice(this.trvl.FinTotalKn) + ' ' + this.trvl.HomeCurrency +  ' \n' + this.formatPrice(this.trvl.FinTotalEur) + ' ' + this.trvl.ForeignCurrency
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.ExpensesForwardHome +  ' \n \n' + this.formatPrice(this.trvl.ExpensesForwardHomeC) + ' ' + this.trvl.HomeCurrency +  ' \n' + this.formatPrice(this.trvl.ExpensesForwardForeC) + ' ' + this.trvl.ForeignCurrency
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinPayReturn +  ' \n' + this.trvl.FinPayReturn +  ' \n' + this.formatPrice(this.trvl.FinPayReturnKn) + ' ' + this.trvl.HomeCurrency +  ' \n' + this.formatPrice(this.trvl.FinPayReturnEur) + ' ' + this.trvl.ForeignCurrency
              },
            ]
          }
        this.content.push(docPart6)
        this.addEmptyRow()

        const docPart07 = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '75%',
                text: [
                  { text: '5.Izvješće sa službenog putovanja ', fontSize: 12, bold: true },
                ]
              },
              {
                // % width
                width: '15%',
                text: [
                  { text:  '', fontSize: 12, bold: true },
                ]   
              },

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart07)

        const docPart7 = {
        columns: [
              {
                width: '90%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.FinReport +  ' \n' + this.trvl.FinReport
              },
            ]
          }
        this.content.push(docPart7)
        this.addEmptyRow()

        const docPart8 = {
        columns: [
              {
                width: '90%',
                fontSize: 8,
                alignment: 'left',
                text: 'Potvrđujem da je službeno putovanje prema ovom nalogu obavljeno i isplata se može obaviti. ' + (this.trvl.FinEndPlace ? this.trvl.FinEndPlace : '') + ' ' + (this.trvl.FinEndDate2 ? dayjs(this.trvl.FinEndDate2).format('DD.MM.YYYY') : '')
              },
            ]
          }
        this.content.push(docPart8)
        this.addEmptyRow()



        const docPart9 = {
        columns: [
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: 'Podnositelj obračuna: \n' + (this.trvl.eFirst ? this.trvl.eFirst : '') + ' ' + (this.trvl.eLast ? this.trvl.eLast : '')
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: 'Pregledao likvidator: \n' + (this.trvl.FirstControl ? this.trvl.FirstControl : '') + ' ' + (this.trvl.LastControl ? this.trvl.LastControl : '')
              },
              {
                width: '30%',
                fontSize: 8,
                alignment: 'left',
                text: 'Nalogodavac isplate: \n' + (this.trvl.FirstCFO ? this.trvl.FirstCFO : '') + ' ' + (this.trvl.LastCFO ? this.trvl.LastCFO : '')
              },
            ]
          }
        this.content.push(docPart9)
        this.addEmptyRow()
        this.addEmptyRow()

    }

  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>