<template>
  <div>

  </div>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './vehicleDescEn'
import langHr from './vehicleDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'
// import { parseNum } from '@/snippets/allSnippets'

export default {
  name: 'vehicleprint',
  props: [],
  data () {
    return {
      lang: {},
      langC: {},
      msg: '',
      documentName: '',
      documentName2: '',
      document: {},
      havePath: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locDocument: {},
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      comp: {},
      mainQuery: {},
      ana: {},
      empl:[],
      total: 0
    }
  },
  mounted () {


  },
  methods: {
    async print () {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.BusinessYear = this.$store.state.businessYear
      }
      if (this.$store.state.language === 'EN') {
        this.lang = {...langEn}
        this.langC = {...commEn}
      }
      if (this.$store.state.language === 'HR') {
        this.lang = {...langHr}
        this.langC = {...commHr}
      }
      this.printDoc()
    },

    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
     addLineDash () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1,
                dash: { length: 1 },
              }
            ]
          }
      this.content.push(docPart)
    },
    printDoc () {
      this.comp = this.$store.state.companyActiv
      this.ana = this.$store.state.printObj ? this.$store.state.printObj : {}
      console.log('this.ana ', this.ana)

      this.initDocument()
      this.addHead()
      this.addLine()
      // this.addItemHead()
      // this.addLine()
      // map array 
      // new page, not first time 
      // if (this.ana.head1) {
      //   this.ana.head1.map(item => {
      //     this.addItem(item)
      //     this.total += item.Iznos
      //   })
      // }
      // this.addLine()
      // this.addFooter()

      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 40 ]
      this.content = []
    },
    skipNewPage() {
      const docPart01 = {
      columns: [
          {
            width: '40%',
            fontSize: 7,
            bold: true,
            alignment: 'left',
            text: '  ',
            pageBreak: 'after'
          },
        ]
      }
      this.content.push(docPart01)
    },
  
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addHead() {
      const docPart = {
          columns: [
            {
              // % width
              width: '35%',
              text: [
                { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.Companyemail ? 'email:' +  this.comp.Companyemail + ' \n' : ' ') , fontSize: 8 },
                { text: 'OIB:' + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '45%',
              text: [
                { text: 'Lista virmana \n', fontSize: 18, bold: true },
                { text: 'za ' + (this.ana.Month ? this.ana.Month + '/' : '') + (this.ana.Year ? this.ana.Year + ', ('  : '') + (this.ana.Order ? this.ana.Order + ')' : '') , fontSize: 12, bold: true },
                { text: (this.ana.head3 ? this.ana.head3 + ' \n' : ''), fontSize: 8, bold: true },
                { text: (this.ana.head4 ? this.ana.head4 + ' \n' : ''), fontSize: 8, bold: true },
                { text: (this.ana.head5 ? this.ana.head5 + ' \n' : ''), fontSize: 8, bold: true },
              ]
            },
            {
              // % width
              width: '20%',
              text: [                 
                { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },
    addItemHead() {
      const docPart = {
          columns: [
            {
              // % width
              width: '20%',
              text: [
                { text: 'Platitelj' + ' \n', fontSize: 8 },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '30%',
              text: [
                { text: 'Opis plaćanja' + '\n', fontSize: 8 },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '12%',
              text: [
                { text: 'Iznos \n', fontSize: 12, bold: true, alignment: 'right' },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '2%',
              text: [
                { text: '\n', fontSize: 12, bold: true, alignment: 'right' },
              ]
            },
            {
              // % width
              width: '30%',
              text: [     
                { text: 'Primatelj \n', fontSize: 8 },
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },

    addItem(item) {
      const docPart = {
          columns: [
            {
              // % width
              width: '20%',
              text: [
                { text: (item.PlatiteljIBAN ? item.PlatiteljIBAN : ' ') + ' \n', fontSize: 8 },
                { text: (item.PlatiteljModel ? item.PlatiteljModel : ' ') + (item.PlatiteljPoziv ? '  ' + item.PlatiteljPoziv : ' ') + ' \n', fontSize: 8 },
                { text:  ' \n', fontSize: 8 },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '30%',
              text: [
                { text: (item.Opis1 ? item.Opis1 + ' ' : '') + '\n', fontSize: 8 },
                { text: (item.Opis2 ? item.Opis2 + ' ' : '') + '\n', fontSize: 8 },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '12%',
              text: [
                { text: (item.Iznos ? this.formatPrice(item.Iznos) + ' ' : '') + '\n', fontSize: 12, bold: true, alignment: 'right' },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '2%',
              text: [
                { text: '\n', fontSize: 12, bold: true, alignment: 'right' },
              ]
            },
            {
              // % width
              width: '30%',
              text: [     
                { text: (item.Primatelj1 ? item.Primatelj1 : ' ') + (item.PrimateljIBAN ? item.PrimateljIBAN : ' ') + ' \n', fontSize: 8 },
                { text: (item.PrimateljModel ? item.PrimateljModel : ' ') + (item.PrimateljPoziv ? '  ' + item.PrimateljPoziv : ' ') + ' \n', fontSize: 8 },
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },
    addFooter() {
      const docPart = {
          columns: [
            {
              // % width
              width: '38%',
              text: [
                { text: ' \n', fontSize: 8, bold: true },
              ]
            },
            {
              // % width
              width: '10%',
              text: [
                { text: 'Ukupno: ' + ' \n', fontSize: 12, bold: true },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '12%',
              text: [
                { text: (this.total ?  this.formatPrice(this.total) + ' ' : '') + '\n', fontSize: 12, bold: true, alignment: 'right' },
              ]
            },
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },

    addSignature () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                 // { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________  \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },

    addEmpty () {
      const docPart = {
        
          }
      this.content.push(docPart)
    },

  },
  computed: {
  },
  watch: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
