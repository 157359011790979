import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('opzstatitems', {
      params: query
    })
  },
  indexlist(search) {
    return Api().get('opzstatitems/list', {
      params: search
    })
  },
  choose (query) {
    return Api().get('opzstatitems/choose', {
      params: query
    })
  },
  show (id) {
    return Api().get(`opzstatitems/${id}`)
  },
  post (newopzstatitems) {
    return Api().post('opzstatitems', newopzstatitems)
  },
  put (opzstatitems) {
    return Api().put(`opzstatitems/${opzstatitems.id}`, opzstatitems)
  },
  delete (id) {
    return Api().delete(`opzstatitems/delete/${id}`)
  },
  saveitems (newitems) {
    return Api().post('opzstatitems/saveitems', newitems)
  },
}