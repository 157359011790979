<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="auto"
    >
      <v-card>
        <v-card-title class="headline">{{lang.SelectWarehouse}}</v-card-title>
        <v-card-text>
          <v-data-table
            dense
            :headers="headers"
            :items="warehouses"
            sort-by="WareHouseId"
            class="elevation-1"
            @click:row="(item) => warehouseSel(item)"
            :mobile-breakpoint="0"
            disable-pagination
            :hide-default-footer="true"
          >
          </v-data-table>

        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click="dialogSt">{{langC.Cancel}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>

import langEn from './warehouseDescEn'
import langHr from './warehouseDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"

export default {
  name: '',
  props: [],
  data: ()=> ({
    dialog: true,
    lang: {},
    langC: {},
    warehouses: [],
    headers:[]
  }),
  mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = langEn
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = langHr
      this.langC = commHr;
    }
    this.warehouses = this.$store.state.warehouseState ? this.$store.state.warehouseState : []
    this.headers.push(    
      {text: this.lang.WareHouseId, value: 'WareHouseId', width: '40px'},      
      {text: this.lang.WareHouseName, value: 'WareHouseName', width: '150px', align: 'left'},
    )
  },
  methods: {
    warehouseSel(item) {
      if (item.WareHouseId) {
        this.$store.dispatch('setWareHouseMarkid', item.WareHouseId)
      }
      this.$emit('closedDialog', true)
      this.dialog = false
    },
    dialogSt() {
      this.dialog = !this.dialog
      this.$emit('closedDialog', true)
    }
  },
  computed: {
  },
  watch: {

  },
  components: {

  }

}

</script>