import langEn from '../components/mainDescEN'
import langHr from '../components/mainDescHR'
import store from '../store/store'

const documentLocalName = (inputN) => {
  const result = inputN && inputN.length > 0 ? inputN.includes(", ") : null
  const inputName= result ? inputN.substr(0, inputN.indexOf(', ')) : inputN 
  const inputNr = result ? inputN.substr(inputN.indexOf(', '), inputN.length) : ''
  
  let lang = {}
  if (store.state.language === 'EN') {
    lang = langEn
  }
  if (store.state.language === 'HR') {
    lang = langHr
  }
  let localName = ''
  switch (inputName) {
    case 'InquirySales':
      localName = lang.InquirySales
      break
    case 'QuoteSales':
      localName = lang.QuoteSales
      break
    case 'OrderSales':
      localName = lang.OrderSales
      break;
    case 'DeliveryNoteSales':
      localName = lang.DeliveryNoteSales
      break;
    case 'InternalDeliveryNotes':
      localName = lang.InternalDeliveryNotes
      break;
    case 'ServiceNotes':
      localName = lang.ServiceNotes
      break;
    case 'InvoiceAdvance':
      localName = lang.InvoiceAdvance
      break;
    case 'InvoiceSales':
      localName = lang.InvoiceSales
      break;
    case 'ContractSales':
      localName = lang.ContractSales
      break;
    case 'WorkingOrder':
      localName = lang.WorkingOrder
      break;
    case 'POS':
      localName = lang.POS
      break;
    case 'SaleAnalysis':
      localName = lang.SaleAnalysis
      break;
    case 'RequestPurchase':
      localName = lang.RequestPurchase
      break;
    case 'InquiryPurchase':
      localName = lang.InquiryPurchase
      break;
    case 'OrderPurchase':
      localName = lang.OrderPurchase
      break;
    case 'ReceiptPurchase':
      localName = lang.ReceiptPurchase
      break;
    case 'ReturnPurchase':
      localName = lang.ReturnPurchase
      break;
    case 'Inventory':
      localName = lang.Inventory
      break;
    case 'PurchaseAnalysis':
      localName = lang.PurchaseAnalysis
      break;
    default:
      localName = ''
  }
  return localName + inputNr
}

export default documentLocalName