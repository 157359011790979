<template>
  <div class="warehousestatelist">
    <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
      <v-card>
        <v-card-title>
          {{lang.SellerCard}}
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"
          >
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
          <v-card-text>
            <span v-if="totalSupp"> {{lang.ItemSuppPriceAmount}}: {{localMoney(totalSupp ) }},</span>
            <span v-if="totalMargin"> {{lang.ItemMarginAmount}}: {{localMoney(totalMargin ) }},</span>
            <span v-if="totalNetto"> {{lang.ItemBaseAmountNoVAT}}: {{localMoney(totalNetto ) }},</span>
            <span v-if="totalVAT"> {{lang.ItemVAT1Amount}}: {{localMoney(totalVAT ) }},</span> 
            <span v-if="totalTotal"> {{lang.ItemFullTotal}}: {{localMoney(totalTotal ) }}</span>            
          </v-card-text>
      </v-card>
  </div>
</template>

<script>
// import axios from 'axios'
import DocumentService from '@/services/DocumentService'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import documentLocalName from '@/snippets/documentLocalName'
import documentCurrency from '@/snippets/documentCurrency'
import dayjs from 'dayjs'
import moneyFormat from '@/snippets/moneyFormat'

export default {
  name: 'documentwarehousestate',
  data () {    
    return {
      items: [],
      documentName: '',
      documentNameLoc: '',
      msg: '',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: '',
        databaseDocID: 0
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
      documentAna: {
        CompanyId: 0,
        BusinessYear: 0,
        UserId: 0,
        partnerId: 0,
        documentName: '',
        docFromDate: null,
        docToDate: null,
        itemID: null,
        warehouseID: null
      },
      totalPcs: 0,
      totalSupp: 0,
      totalMargin: 0,
      totalNetto: 0,
      totalVAT: 0,
      totalDirect: 0, 
      totalTotal: 0,
      defCurr: 'Kn'
    }
  },
  
  async mounted () {
    try {
     
        // do analysis from database
        if (this.$store.state.language === 'EN') {
          this.lang = langEn
          this.langC = commEn
        }
        if (this.$store.state.language === 'HR') {
          this.lang = langHr
          this.langC = commHr
        }
        this.defCurr = documentCurrency()
        this.headers.push(
          {text: this.lang.documentDate1, value: 'documentDate', width: '110px'},
          {text: this.lang.documentId, value: 'documentId', width: '80px'},
          {text: this.langC.DocumentName, value: 'documentName', width: '80px'},
          {text: this.lang.partnerName, value: 'partnerNameDatabase', width: '150px'},
          {text: this.lang.ItemPcsMinus, value: 'itemPcsMinus', type: 'decimal', width: '100px'},
          {text: this.lang.ItemSuppPrice, value: 'itemSuppPrice', type: 'decimal', width: '100px'},
          {text: this.lang.ItemMargin, value: 'itemMargin', type: 'decimal', width: '60px'},
          {text: this.lang.ItemPrice, value: 'itemPrice', type: 'decimal', width: '100px'},
          {text: this.lang.ItemPriceWithVAT, value: 'itemPriceWithVAT', type: 'decimal', width: '100px'},
          {text: this.lang.TotalMinus, value: 'TotalPlus', type: 'decimal', width: '100px'},
          {text: this.lang.id, value: 'id', width: '50px'}
        )
        
        // do analysis from database
        this.localError = false
        this.error = null
        this.items = []
        if (this.$store.state.documentAna.CompanyId) {
          this.documentAna.CompanyId = this.$store.state.documentAna.CompanyId
        }
        if (this.$store.state.documentAna.BusinessYear) {
          this.documentAna.BusinessYear = this.$store.state.documentAna.BusinessYear
        }
        if (this.$store.state.documentAna.itemID) {
          this.documentAna.itemID = this.$store.state.documentAna.itemID
        }
        this.documentAna.docFromDate = this.$store.state.documentAna.docFromDate ? this.$store.state.documentAna.docFromDate : dayjs([this.documentAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
        this.documentAna.docToDate = this.$store.state.documentAna.docToDate ? this.$store.state.documentAna.docToDate : dayjs([this.documentAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
        this.saving = true
        const tempItems = (await DocumentService.documentitemcard(this.documentAna)).data.document
        
        this.saving = false
        let iid = 0
        this.totalPcs = 0
        this.totalSupp = 0
        this.totalMargin = 0
        this.totalNetto = 0
        this.totalVAT = 0
        this.totalDirect = 0
        this.totalTotal = 0

        if (tempItems) {
          tempItems.map(item => {
            const newEntry = {}

            if (parseFloat(item.itemPcsMinus) !== 0) {         
              newEntry.itemID = item.itemID ? item.itemID : ''
              newEntry.itemName = item.itemName ? item.itemName : ''
              newEntry.documentDate = item.documentDate ? item.documentDate : ''
              newEntry.documentName = item.documentName ? documentLocalName(item.documentName) : ''
              newEntry.documentId = item.documentId ? item.documentId : ''
              newEntry.partnerNameDatabase = item.partnerNameDatabase ? item.partnerNameDatabase : ''
              newEntry.itemPcsMinus = item.itemPcsMinus ? parseFloat(item.itemPcsMinus) : 0
              newEntry.itemSuppBasePrice = item.itemSuppBasePrice ? parseFloat(item.itemSuppBasePrice) : 0
              newEntry.itemSuppDiscount = item.itemSuppDiscount ? parseFloat(item.itemSuppDiscount) : 0
              newEntry.itemSuppPrice = item.itemSuppPrice ? parseFloat(item.itemSuppPrice) : 0
              newEntry.itemMargin = item.itemMargin ? parseFloat(item.itemMargin) : 0
              newEntry.itemPrice = item.itemPrice ? parseFloat(item.itemPrice) : 0
              newEntry.itemPriceWithVAT = item.itemPriceWithVAT ? parseFloat(item.itemPriceWithVAT) : 0
              
              this.totalPcs += newEntry.itemPcsMinus
              newEntry.TotalPlus = this.totalPcs
        
              if (parseFloat(item.itemSuppPriceAmount) !== 0 ) {
                this.totalSupp += parseFloat(item.itemSuppPriceAmount)
              }
              if (parseFloat(item.itemMarginAmount) !== 0 ) {
                this.totalMargin += parseFloat(item.itemMarginAmount)
              }
              if (newEntry.itemPrice) {
                this.totalNetto += (newEntry.itemPcsMinus) * (newEntry.itemPrice)
              }
              // if (item.itemVAT1Amount) {
              //   this.totalVAT += (item.itemVAT1Amount)
              // }
              if (newEntry.itemPriceWithVAT) {
                this.totalTotal += (newEntry.itemPcsMinus) * (newEntry.itemPriceWithVAT)
              }
              iid += 1
              newEntry.id = iid
              this.items.push(newEntry)
            }
          })
          this.totalSupp = this.round(this.totalSupp, 2)
          this.totalMargin = this.round(this.totalMargin, 2)
          this.totalNetto = this.round(this.totalNetto, 2)
          this.totalVAT = this.round((this.totalTotal - this.totalNetto), 2)
          this.totalTotal = this.round(this.totalTotal, 2)
        }
        this.typeAna = 4
      } catch (error) {
        this.error = 'Greska u konekciji na bazu' + error.detail
      }      
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    navigateTo () {
    },
     round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
  },
  computed: {

  },
  watch: {

  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>


