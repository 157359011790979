<template>
  <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <h2 class="text-xs-left">Uvjeti korištenja</h2>
          
Sljedeće su uvjeti zakonitog ugovora između vas i CE grupa d.o.o.-a. Pristupom, pretraživanjem i/ili upotrebom ovih web stranica, vi potvrđujete da ste pročitali, razumjeli i složili se da će vas ovi uvjeti obvezivati, te da ćete postupati u skladu sa svim primjenjivim zakonima i pravilima, uključujući kontrolne zakone i pravila za izvoz i reizvoz. Ako se ne slažete s ovim uvjetima, nemojte koristiti ove web stranice.

<h2 class="text-xs-left">Roboot.net je trgovačko ime usluge koju vam pruža CE grupa d.o.o. </h2>
<br/>
<br/>
CE grupa d.o.o. Ulica Franje Petračića 6, 10000 Zagreb, Hrvatska, OIB:81175275570 u obavljanju svoje djelatnosti, prikuplja i obrađuje osobne podatke poštujući sve relevantne zakone i propise.
Mi u CE grupa d.o.o. obavezujemo se da ćemo čuvati privatnost i tajnost osobnih podataka svih naših posjetilaca i kupaca, u skladu sa Zakonom o elektroničkoj trgovini (Narodne novine br. 173/2003 , 67/2008 i 36/2009).
<br/><br/>
Molimo vas da ovu Izjavu pročitate pažljivo kako biste saznali kako skupljamo, obrađujemo, štitimo ili na bilo koji drugi način koristimo vaše osobne podatke.
Obvezujemo se primjenjivati maksimalne mjere sigurnosti u svrhu zaštite interesa kupaca i sprječavanja eventualnih zlouporaba informacija. U procesu realizacije kupovine koristit ćemo samo informacije koje su nužne. Svi zaposleni u CE grupa d.o.o. i poslovni partneri ugovorno su obvezni poštovati načela zaštite privatnosti i tajnosti podataka. Prikupljamo samo neophodne, osnovne podatke o kupcima/korisnicima i obavještavamo posjetioce i kupce o načinu korištenja tih podataka. Redovno dajemo kupcima mogućnost izbora o upotrebi njihovih podataka, uključujući mogućnost odluke žele li ili ne da se njihovo ime ukloni s lista koje se koriste za marketinške kampanje.
<br/>
<br/>
Podaci su spremljeni u informatičkom sustavu tvrtke CE grupa d.o.o. i čuvaju se na sigurnom mjestu i dostupni su samo zaposlenima CE grupa d.o.o. kojima su ti podaci nužni za pružanje kvalitetne usluge našim kupcima. Upotreba podataka ograničena je na komunikaciju s kupcima u svrhu obavljanja prodajnih transakcija, i obavještavanje korisnika o novostima i pogodnostima koje su im dostupne putem naše web stranice.
CE grupa d.o.o. garantira da osobni podaci korisnika CE grupa d.o.o. neće nikada biti dati na uvid i upotrebu trećoj strani, bez prethodne izričite suglasnosti korisnika. Ovo se ne odnosi na zahtjeve za uvid u podatke od strane ovlaštenih službi izvršne državne vlasti Republike Hrvatske za potrebe kontrole poslovanja.
<br/>
<br/>
Prikupljamo samo one osobne podatke koje nam dobrovoljno dostavite i samo za svrhe navedene u ovim pravilima zaštite osobnih podataka. Ne zahtijevamo od Vas da nam pošaljete te podatke kako bismo vam omogućili pristup na naše stranice i ne tražimo da otkrijete više podataka no što je doista potrebno za sudjelovanje u nekoj aktivnosti na našim stranicama.
Registracijom na internet stranici https://cegrupa.com, kupnjom kao gost ili pristupanjem samoj stranici kupac daje suglasnost da CE grupa d.o.o. obrađuje osobne podatke navedene u registracijskoj formi, podatke o narudžbama, podatke dostupne u procesu plaćanja usluge i druge podatke. Isti podaci koriste se u svrhu sklapanja ugovora, te u svrhu upoznavanja CE grupa d.o.o. s kupovnim navikama kupaca, kao i u informativne svrhe, te svrhe promidžbe usluga i proizvoda prodavatelja.
<br/>
<br/>
CE grupa d.o.o. se obvezuje čuvati privatnost osobnih podataka svih Kupaca, te će s istima postupati u skladu sa Zakonom o zaštiti osobnih podataka, odnosno drugim primjenjivim propisima.
Registracijom potvrđujete točnost i potpunost u obrascu navedenih podataka i dajete izričitu suglasnost da CE grupa d.o.o. može, u skladu za odredbama (NN br. 103/2003 i 118/2006) i Zakona o elektroničkoj trgovini(NN br. br. 173/2003 , 67/2008 i 36/2009), obrađivati osobne podatke naznačene u obrascu za potrebe svojih evidencija i pružanja usluga obavještavanja o novim proizvodima i uslugama.
<br/>
<h4>Voditelj obrade osobnih podataka</h4>
CE grupa d.o.o. je voditelj obrade osobnih podataka u skladu sa zakonom i propisima o zaštiti osobnih podataka. CE grupa d.o.o. kao voditelj obrade osobnih podataka određuje svrhu i sredstva obrade osobnih podataka i odgovoran je za čuvanje i korištenje osobnih podataka u papirnatom i/ili elektroničkom obliku. Za sva pitanja vezana za obradu osobnih podataka ili ostvarivanje prava na zaštitu osobnih podataka možete nas kontaktirati na e-mail: danijela@cegrupa.com.
<br/>
<h4>Osobni podaci koje prikupljamo</h4>
Osobni podatak jest svaki podatak koji se odnosi na pojedinca čiji je identitet poznat ili se može utvrditi. Pojedinac čiji se identitet može utvrditi jest osoba koja se može identificirati izravno ili neizravno, osobito uz pomoć identifikatora kao što su ime, identifikacijski broj, podaci o lokaciji, mrežni identifikator ili uz pomoć jednog ili više čimbenika svojstvenih za fizički, fiziološki, genetski, mentalni, ekonomski, kulturni ili socijalni identitet tog pojedinca.
Vaše osobne podatke prikupljamo samo kada nam svojom voljom date takve informacije. Podatke o Vama prikupljamo kada ispunite web obrazac ili prilikom prijave na newsletter.
Trebali biste imati na umu da se neosobne informacije i podaci mogu automatski prikupljati putem naših Internet servera ili korištenjem “kolačića”.
Primjeri informacija o Vašem korištenju stranice uključuju: najposjećenije i najgledanije stranice i poveznice na našoj web stranici, broj ispunjenih obrazaca, vrijeme provedeno na stranici, najpopularnije ključne riječi koji korisnike vode do naše stranice, vaša IP adresa, informacije koje se skupljaju putem kolačića, podaci vašeg uređaja poput postavki hardware-a, sistemske aktivnosti, vrste pretraživača itd.
<br/><br/>
<h4>Za potrebe poslovanja CE grupa d.o.o. prikuplja sljedeće korisničke podatke:</h4>
• Ime i prezime<br/>
• Adresu i mjesto stanovanja<br/>
• E-mail adresu<br/>
• Kontaktni telefonski broj<br/>
Za točnost unesenih podataka koje su unijeli korisnici ne odgovaramo.<br/>
<br/><br/>
<h4>Svrha obrade osobnih podataka</h4>
Razlozi zbog kojih obrađujemo osobne podatke radi sklapanja i ispunjenja ugovornih obveza su:<br/>
• Otvaranje korisničkih profila na web stranici<br/>
• Obrada narudžbi putem online trgovine i isporuka/dostava naručenih proizvoda<br/>
• Prijenos komercijalnih komunikacija putem elektroničke pošte, mobilnih telefona ili tradicionalnom poštom odnosno kurirskom dostavom (unutar granica Vaše suglasnosti)<br/>
• Pružanje odgovora na upite koje nam postavite ili na Vaše savjete, komentare, prigovore i sl.<br/>
• Rješavanje svih sporova ili pritužbi koji se odnose na ili proizlaze iz aktivnosti CE grupa d.o.o.<br/>
• Promocije naših usluga i iskazivanja namjere sklapanja ugovora<br/>
• Prodajnih i marketinških aktivnosti CE grupa d.o.o.<br/>
<br/><br/>
<h4>Pravna osnova obrade osobnih podataka</h4>
Kupac unosom svojih osobnih podataka te potvrdom (klikom) o prihvaćanju Uvjeta kupnje i ove Izjave o privatnosti sklapa ugovorni odnos koji je temelj za kupnju proizvoda i usluga po izboru kupca na Internet stranici http://CE grupa.com, a koji je sadržan u Uvjetima kupnje te je stoga obrada tih osobnih podataka zakonita jer se poduzimaju radnje na zahtjev Kupca radi realizacije kupnje proizvoda i usluga po narudžbi Kupca.
Primanje marketinških kampanja na e-mail (newsletter)
Ako se odlučite prijaviti na našu newsletter listu, e-mail adresa koju nam pošaljete prosljeđuje se softverskoj platformi koja nam pruža usluge e-mail marketinga. Email adresa koju šaljete neće biti pohranjena u vlastitoj bazi podataka ili na nekom od naših internih računalnih sustava.
Vaša adresa e-pošte ostaje unutar baze podataka navedene platforme dokle god nastavljamo koristiti usluge te platforme za email marketing ili dok ne zatražite odjavu s newsletter liste. To možete učiniti tako što ćete otkazati pretplatu pomoću linkova za otkazivanje pretplate koji se nalaze u svim newsletterima koje vam šaljemo ili tako da zatražite odjavu putem naše kontakt email adrese.
Korisnik koji se prijavljuje za primanje obavijesti o proizvodima i akcijama unosom svojih podataka na Internet stranici https://cegrupa.com te dvostrukom potvrdom ispravnosti email adrese, daje privolu za obradu svojih osobnih podataka.
<br/><br/>
<h4>Komuniciranje podataka prema trećim stranama</h4>
CE grupa d.o.o. neće dijeliti osobne podatke Kupca odnosno Korisnika s drugim stranama osim u slučajevima navedenim u sljedećim točkama i u situacijama kada to pozitivni propisi zahtijevaju.
CE grupa d.o.o. će kada to zahtijeva realizacija ugovora za kupnju proizvoda ili usluga po narudžbi Kupca podijeliti osobne podatke Kupca s:
• Pružateljima usluga distribucije robe s kojima ima trajni ugovor te u tu svrhu za ispunjavanje narudžbi, isporuku paketa, slanje zemaljske pošte i e-mail pošte. Pružatelj usluga distribucije može od Kupca zatražiti na uvid njegovu osobnu iskaznicu u trenutku isporuke paketa prilikom osobnog preuzimanja robe, a sve u svrhu realizacije usluge isporuke paketa i evidencije o tome tko je preuzeo paket. Ako Kupac odbije dati ove podatke, paket mu neće biti uručen. Pružatelj usluge distribucije može na e-mail adresu Kupca poslati link za praćenje statusa pošiljke.
<br/><br/>
<h4>Obrada osobnih podataka u procesu plaćanja kreditnim i debitnim karticama</h4>
CE grupa d.o.o. u trenutku plaćanja na Internet stranici https://cegrupa.com, kao uvjet plaćanja proizvoda i usluga kreditnim ili debitnim karticama traži privolu Kupca za aktivaciju procesa plaćanja putem Corvuspay-a, ugovornog partnera CE grupa d.o.o. i Izvršitelja obrade osobnih podataka. U tu svrhu osobni podaci Kupca (ime i prezime Kupca, adresa Kupca, podaci s kartice Kupca) su privremeno pohranjeni kod CorvusPay-a, koji te podatke pohranjuje u skladu s PCI DSS certifikacijom, najvišim stupnjem zaštite i čuvanja povjerljivih podataka.
Djelatnici CE grupa d.o.o. internet trgovine ni u jednom trenutku nemaju pristup broju Vaše kreditne kartice, dostupan im je samo broj autorizirane transakcije. Podaci o karticama ne pohranjuju se u našem sustavu već se broj kreditne kartice direktno provjerava i autorizira u autorizacijskom centru kartične kuće. Broj kreditne kartice nemojte upisivati nigdje osim u za to predviđeno polje, ne šaljite ga e-mailom niti putem formulara za kontakt.
CE grupa d.o.o. ne preuzima odgovornost za eventualne izravne ili neizravne štete koje Kupci mogu pretrpjeti zbog privremene, djelomične ili potpune nedostupnosti CorvusPay™ usluge.
<br/><br/>
<h4>Brisanje osobnih podataka (pravo na zaborav, povlačenje privole)</h4>
U slučaju da Kupac ne želi da prodavatelj više na bilo koji način obrađuje podatke istoga, te zahtijeva brisanje podataka o istome, mora to prodavatelju javiti, i to putem e-mail poruke na e-mail adresu danijela@cegrupa.com
<br/><br/>
<h4>Pravo na prigovor</h4>
Ako unatoč svim poduzetim mjerama za zaštitu osobnih podataka smatrate da imate osnove za prigovor, javite se Voditelju obrade osobnih podataka na mail adresu danijela@cegrupa.com
Prigovor na obradu Vaših osobnih podataka možete podnijeti i nadzornom tijelu Agenciji za zaštitu osobnih podataka (AZOP).
<br/>
<br/>
<h4>Pristup i ispravak osobnim podacima</h4>
Kupac i Korisnik u svakom trenutku ima mogućnost pristupa svojim osobnim podacima po registraciji na stranici te pristupom na “Pojedinosti o kupcu" gdje Kupac može revidirati svoje osobne podatke koje je podijelio s CE grupa d.o.o. Kupac i Korisnik može zatražiti i dobiti od CE grupa d.o.o. cjelovitu informaciju o osobnim podacima koji su pohranjeni, kao i ispravak istih slanjem e-mail poruke na mail adresu Voditelja obrade osobnih podataka: danijela@cegrupa.com
<br/>
<br/>
<h4>Razdoblje pohranjivanja osobnih podataka</h4>
Vaše podatke ćemo čuvati onoliko dugo koliko je potrebno za ostvarenje svrhe radi koje ih obrađujemo. Kriterij na temelju kojega određujemo razdoblje čuvanja osobnih podataka je upravo svrha prikupljanja i vremenski rok u kojem želite da Vam pružamo naše usluge.
<br/>
<br/>
<h4>Sigurnosne mjere zaštite osobnih podataka – enkripcija</h4>
Vaši podaci i sve stranice http://cegrupa.com internet trgovine zaštićene su korištenjem Secure Socket Layer (SSL) protokola koji koristi sha256 sigurnosni algoritam. SSL enkripcija šifrira podatke radi sprječavanja neovlaštenog pristupa prilikom njihovog prijenosa.
<br/>
<br/>
<h4>Kršenje privatnosti podataka</h4>
O svakom kršenju privatnosti podataka iz naše baze ili baze bilo kojeg od naših partnera obavijestit ćemo sve relevantne osobe i nadležna tijela u roku od 72 sata od prekršaja, ako je očigledno da su ukradeni podaci spremljeni na način da se može otkriti identitet vlasnika podataka.
<br/>
<br/>
<h4>Promjene izjave o privatnosti</h4>
Ova izjava o privatnosti može se s vremena na vrijeme mijenjati u skladu sa zakonodavstvom ili razvojem industrije. Nećemo eksplicitno obavijestiti naše klijente ili korisnike web stranica o tim promjenama. Umjesto toga, preporučujemo da povremeno provjeravate ovu stranicu za sve izmjene izjave.
<br/>
<br/>
<h4>Odricanje odgovornosti</h4>
CE grupa d.o.o. NI U KOM SLUČAJU NIJE NIKOMU ODGOVORNA ZA BILO KAKVU IZRAVNU, NEIZRAVNU, POSEBNU ILI POSLJEDIČNU ŠTETU OD UPOTREBE OVE (roboot.net, cegrupa.com, virtualniured.com, ceusluge.com) ILI DRUGIH WEB STRANICA, UKLJUČUJUĆI, BEZ OGRANIČENJA, GUBITAK DOBITI, PREKID U RADU, GUBITAK PROGRAMA ILI DRUGIH PODATAKA NA VAŠIM SISTEMIMA ZA OBRADU PODATAKA ILI DRUGAČIJE, ČAK I KAD SMO IZRIJEKOM OBAVIJEŠTENI O TAKVIM ŠTETAMA. SVE INFORMACIJE CE grupa d.o.o. DOSTAVLJA SAMO NA BAZI "KAKO JE". CE grupa d.o.o. NE DAJE NIKAKVA JAMSTVA, IZRAVNA ILI POSREDNA, UKLJUČUJUĆI POSREDNA JAMSTVA PROĐE NA TRŽIŠTU I PRIKLADNOSTI ZA NEKU ODREĐENU SVRHU.
<br/>
<br/>
<h4>Izmjena podataka</h4>
CE grupa d.o.o. može u bilo koje vrijeme promijeniti ove uvjete i ažurirati ove informacije. Upotrebom ovih web stranica, vi se slažete da ćete biti obvezani i s takvim promjenama, pa stoga trebate povremeno posjetiti ovu stranicu da odredite trenutne uvjete koji vas obvezuju.
<br/>
<br/>
<h4>Nadogradnja sustava</h4>
Roboot.net može biti promijenjen bez prethodne obavijesti. CE grupa d.o.o. može također u bilo koje vrijeme napraviti poboljšanja i/ili promjene na proizvodima i/ili programima opisanim u ovim informacijama, bez prethodne obavijesti.
<br/>
<br/>
<h4>Arhiva podataka</h4>
Roboot.net posjeduje automatski sustav zaštite podataka izradom dnevne kopije (backup) svih podataka. Backup se čuva na posebnom mjestu i dostupan je samo administratoru sustava.
<br/>
<br/>
<h4>Vaša privola</h4>
Korištenjem ove web stranice dajete privolu na ove uvjete.  Ako se ne slažete s ovim uvjetima, nemojte koristiti ovu web stranicu.

        </v-col>
      </v-row>
  </v-container>
</template>


<script>


export default {
  name: '',
  props: [],
  data: ()=> ({
    
  }),
  mounted () {

  },
  methods: {

  },
  computed: {
  },
  watch: {

  },
  components: {

  }

}

</script>